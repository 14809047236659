import { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getStorage } from '../../../common/storage';
import MYOCorporateRateForm from './new-screen/MYOCorporateRateForm';
import MYOCorporateRateSeasonalForm from './new-screen/MYOCorporateRateSeasonalForm';
import NegotiatedRateForm from './new-screen/NegotiatedRateForm';
import NegotiatedRateFormSeasonalForm from './new-screen/NegotiatedRateFormSeasonalForm';
import RateManagementSetUp from './new-screen/RateManagementSetUp';

const RateHome: React.FC<any> = ({ location }): JSX.Element => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [selectedInventory, setSelectedInventory] = useState<any>(null);
  const [inventoryId, setInventoryId] = useState<number | null>(null);
  const [propertyId, setPropertyId] = useState<number | null>(null);
  // const [hideRateList, setHideRateList] = useState<boolean>(false);
  const [rateId, setRateId] = useState<any>(null);
  const [rateType, setRateType] = useState<any>(null);
  const [myoCorSeasonIndex, setMyoCorSeasonIndex] = useState<any>(null);
  const [myoCorSeasonName, setMyoCorSeasonName] = useState<any>("");
  const [clientId, setClientId] = useState<any>("");
  const [seasonalRateId, setSeasonalRateId] = useState<any>("");
  const [allInOnePropertyBreadCrumb, setAllInOnePropertyBreadCrumb] = useState<boolean>(false);

  // ////////////////////////////////////////////////////////////////////////////////////////////////
  // const { getRateByPropertyNInventoryId } = useStoreActions<any>((actions) => ({
  //   getRateByPropertyNInventoryId: actions.rate.getRateByPropertyNInventoryId,
  // }));

  // //////////////////////////////////////////////////////////////////////////////////////////////////////
  // const { getRateByPropertyNInventoryIdSuccess, getRateByPropertyNInventoryIdError } = useStoreState<any>((state) => ({
  //   getRateByPropertyNInventoryIdSuccess: state.rate.getRateByPropertyNInventoryIdSuccess,
  //   getRateByPropertyNInventoryIdError: state.rate.getRateByPropertyNInventoryIdError,

  // }));

  // const reload = useCallback(() => {
  //   setTimeout(() => {
  //     if (propertyId) {
  //       const params: any = {};
  //       params.propertyId = propertyId;
  //       params.inventoryId = inventoryId;
  //       // getRateByPropertyNInventoryId(params);
  //     }
  //     setFormOpen(false);
  //     setHideRateList(true);
  //   }, 1000)
  // }, [inventoryId, propertyId])

  /////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (location.state?.propertyId && location.state?.inventoryId) {
      setPropertyId(location.state?.propertyId?._id ? location.state?.propertyId?._id : location.state?.propertyId);
      setInventoryId(location.state?.inventoryId);
    }
  }, [location]);

  /**
   * special navigate 
   */
  useEffect(() => {
    if (location.state?.allInOnePropertyBreadCrumb) {
      setAllInOnePropertyBreadCrumb(location.state?.allInOnePropertyBreadCrumb);
    }
  }, [location.state?.allInOnePropertyBreadCrumb]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (propertyId) {
      const params: any = {};
      params.propertyId = propertyId;
      params.inventoryId = inventoryId;
      // getRateByPropertyNInventoryId(params);
    }

  }, [inventoryId, propertyId]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  // useEffect(() => {
  //   if (getRateByPropertyNInventoryIdSuccess) {

  //     if (getRateByPropertyNInventoryIdSuccess.data.length > 0) {
  //       setHideRateList(true);
  //     } else {
  //       setHideRateList(false);
  //     }
  //   }
  //   if (getRateByPropertyNInventoryIdError) {
  //     message.error(getRateByPropertyNInventoryIdError.message)
  //   }

  // }, [getRateByPropertyNInventoryIdError, getRateByPropertyNInventoryIdSuccess]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const inventory = getStorage('myo_selected_inventory');
    setSelectedInventory(inventory);
  }, [])

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div >
      <div >
        {!selectedInventory && <p className="text-2xl mb-10"></p>}
        {selectedInventory && <Breadcrumb className="mb-10">
          <Breadcrumb.Item>
            {
              allInOnePropertyBreadCrumb ? <Link to={{ pathname: "/admin/brand-property-management" }}>Property Management</Link> : <Link to={{ pathname: "/admin/property-management", state: { selectedProperty: location.state?.selectedProperty, isBack: true } }}>Property Management</Link>
            }
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={{ pathname: "/admin/property-management", state: { selectedProperty: location.state?.selectedProperty, allInOnePropertyBreadCrumb : allInOnePropertyBreadCrumb } }}>{location.state?.selectedProperty.propertyName} - {location.state?.selectedProperty.city}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={{ pathname: "/admin/inventory-management", state: { allInOnePropertyBreadCrumb : allInOnePropertyBreadCrumb, selectedInventory: null, propertyId: location.state?.selectedProperty._id, selectedBrand: location.state?.selectedBrand } }}>Inventory Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={{ pathname: "/admin/inventory-management", state: { allInOnePropertyBreadCrumb : allInOnePropertyBreadCrumb, selectedInventory: selectedInventory, selectedBrand: location.state?.selectedBrand } }}>{selectedInventory?.moderate?.details?.new_value ? selectedInventory?.moderate?.details?.new_value : selectedInventory?.accommodationDetail}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            <Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); }}>Rate Management</Link>
          </Breadcrumb.Item>
          {/* {formOpen && <Breadcrumb.Item>New Rate</Breadcrumb.Item>} */}
        </Breadcrumb>}

        {/* {hideRateList && <Button hidden={formOpen} onClick={() => { setFormOpen(true) }} className="btn_design m-1 text-white">Create New Rate</Button>}
        <Button hidden={!formOpen} onClick={() => { setFormOpen(false); setHideRateList(true) }} className="btn_design m-1 text-white">Show Rate List</Button> */}
      </div>
      <div className="text-center">
        {!formOpen && <RateManagementSetUp setSeasonalRateId={setSeasonalRateId} formOpen={formOpen} setFormOpen={setFormOpen} propertyId={propertyId} inventoryId={inventoryId} selectedInventory={selectedInventory} setRateId={setRateId} setRateType={setRateType} setMyoCorSeasonIndex={setMyoCorSeasonIndex} setMyoCorSeasonName={setMyoCorSeasonName} setClientId={setClientId} />}
      </div>
      {/* {!formOpen && hideRateList && <RateForm propertyId={propertyId} inventoryId={inventoryId} />} */}
      {formOpen && rateType === "StandardSeasonal" && <MYOCorporateRateSeasonalForm setFormOpen={setFormOpen} propertyId={propertyId} inventoryId={inventoryId} rateId={rateId} myoCorSeasonIndex={myoCorSeasonIndex} myoCorSeasonName={myoCorSeasonName} selectedInventory={selectedInventory} />}
      {formOpen && rateType === "StandardSeasonalCreate" && <MYOCorporateRateSeasonalForm setFormOpen={setFormOpen} propertyId={propertyId} inventoryId={inventoryId} rateId={rateId} myoCorSeasonIndex={-2} selectedInventory={selectedInventory} />}
      {formOpen && rateType === "Standard" && <MYOCorporateRateForm setFormOpen={setFormOpen} propertyId={propertyId} inventoryId={inventoryId} rateId={rateId} selectedInventory={selectedInventory} />}
      {formOpen && rateType === "NegotiatedRate" && <NegotiatedRateForm setFormOpen={setFormOpen} propertyId={propertyId} inventoryId={inventoryId} rateId={rateId} clientId={null} selectedInventory={selectedInventory} />}
      {formOpen && rateType === "NegotiatedRateUpdate" && <NegotiatedRateForm setFormOpen={setFormOpen} propertyId={propertyId} inventoryId={inventoryId} rateId={rateId} clientId={clientId} selectedInventory={selectedInventory} />}
      {formOpen && rateType === "NegotiatedRateSeasonalUpdate" && <NegotiatedRateFormSeasonalForm setFormOpen={setFormOpen} propertyId={propertyId} inventoryId={inventoryId} rateId={rateId} clientId={clientId} seasonalRateId={seasonalRateId} seasonName={myoCorSeasonName} selectedInventory={selectedInventory} />}
      {formOpen && rateType === "NegotiatedRateSeasonalCreate" && <NegotiatedRateFormSeasonalForm setFormOpen={setFormOpen} propertyId={propertyId} inventoryId={inventoryId} rateId={rateId} clientId={clientId} seasonalRateId={null} seasonName={null} selectedInventory={selectedInventory} />}
    </div>
  )
};
export default RateHome;