import { message, DatePicker } from 'antd';
import Select from 'react-select'
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import { Container, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { BookingForm } from '../../../common/interfaces';
import { BookMarkStyles, searchButton3 } from '../../../common/components-style';
import BookMarkIcon from '../../../images/icons/svg/bookmark.svg';
import CalenderIcon from '../../../images/icons/svg/calender.svg';
import { ErrorMessage } from '@hookform/error-message';
import { setStorage } from '../../../common/storage';
import searchIcon from "../../../images/icons/svg/outside/search.svg";


// const layout = {
// 	labelCol: { span: 13 },
// 	wrapperCol: { span: 34 },
// };


const { RangePicker } = DatePicker;

const BookingFilterPage: React.FC<any> = ({ programmeId, loadLocation, isProgramme }): JSX.Element => {
	// const [form] = Form.useForm();
	const { formState: { errors }, control, watch, handleSubmit } = useForm<BookingForm>();
	const [loading] = useState<boolean>(false);
	const [locationList, setLocationList] = useState<any>([]);
	// const [clientId, setClientId] = useState<any>(null);
	// const [locations, setLocations] = useState<any>();
	// const [isResult, setIsResult] = useState<boolean>(false);
	// const [roomTypeList, setRoomTypeList] = useState<any>([]);
	const minDate = moment(new Date());
	// const [building, setBuilding] = useState<any>();
	// const { push } = useHistory();
	const [isManualBooking, setIsManualBooking] = useState<boolean>(false);
	const [locationId, setLocationId] = useState<any>();
	const [checkInDate, setCheckInDate] = useState<any>();
	const [checkOutDate, setCheckOutDate] = useState<any>();
	// const [inventories, setInventories] = useState<any>([]);
	// const [messages, setMessages] = useState<any>();
	// const [personCount, setPersonCount] = useState<any>();
	// const [roomType, setRoomType] = useState<any>();
	// const [resultLength, setResultLength] = useState<any>();
	// const [noOfNights, setNoOfNights] = useState<any>();
	// const [otherLocation, setOtherLocation] = useState<any>();
	const [selectedLocation, setSelectedLocation] = useState<any>();

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {
		getClientLocationByProgrammeId,
	} = useStoreActions<any>((actions) => ({
		getClientLocationByProgrammeId: actions.client.getClientLocationByProgrammeId,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {
		getClientLocationByProgrammeIdSuccess,
		getClientLocationByProgrammeIdError,
	} = useStoreState<any>((state) => ({
		getClientLocationByProgrammeIdSuccess: state.client.getClientLocationByProgrammeIdSuccess,
		getClientLocationByProgrammeIdError: state.client.getClientLocationByProgrammeIdError,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (programmeId) {
			getClientLocationByProgrammeId(programmeId);
			setLocationList([]);
		}
	}, [programmeId, getClientLocationByProgrammeId]);

	///////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getClientLocationByProgrammeIdSuccess) {
			if (getClientLocationByProgrammeIdSuccess.data === null) {
				message.warning("No Data Found");
			} else {
				const location = getClientLocationByProgrammeIdSuccess.data;
				const locationListTemp: any = [];
				_.map(location, (element) =>
					locationListTemp.push(element)
				);

				if (isProgramme === 'MYO Plus') {
					locationListTemp.push({ _id: null, locationName: '- Select other location -' });
				}
				setLocationList(locationListTemp);
			}
		}
		if (getClientLocationByProgrammeIdError) {
			message.error(getClientLocationByProgrammeIdError.message);
		}
	}, [getClientLocationByProgrammeIdSuccess, getClientLocationByProgrammeIdError, programmeId, isProgramme]);


	// ////////////////////////////////////////////////////////////////////////////////////////////////////
	// useEffect(() => {
	// 	if (location?.propLocationId) {
	// 		const params: any = {};
	// 		params.locationId = location?.propLocationId;
	// 		params.checkInDate = location?.checkIn;
	// 		params.checkOutDate = location?.checkOut;
	// 		// form.setFieldsValue({
	// 		// 	fromDate: [moment(new Date(params.checkInDate)), moment(new Date(params.checkOutDate))],
	// 		// 	location: location?.propLocationId
	// 		// })
	// 		let locations = _.first(_.filter(locationList, location => (location._id === location?.propLocationId)));
	// 		if (locations) {
	// 			const buildingListTemp: any = [];
	// 			_.map(locations.building, (element) =>
	// 				buildingListTemp.push({ id: element._id, name: element.propertyName, position: { lat: Number(element.latitude), lng: Number(element.longitude) }, address: element.address1 })
	// 			);
	// 			setBuilding(buildingListTemp);
	// 			setLocations({ lat: Number(location.latitude), lng: Number(location.longitude), locationName: location.locationName });
	// 		}
	// 		getAvailabilityAndRateByLocation(params);
	// 	} else {
	// 		if (location?.placeId) {
	// 			const params: any = {};
	// 			params.placeId = location?.placeId;
	// 			params.checkInDate = location?.checkIn;
	// 			params.checkOutDate = location?.checkOut;
	// 			params.countryCode = location?.countryCode;
	// 			params.center_latitude = location?.lat;
	// 			params.center_longitude = location?.lng;

	// 			setIsManualBooking(true);
	// 			// form.setFieldsValue({
	// 			// 	fromDate: [moment(new Date(params.checkInDate)), moment(new Date(params.checkOutDate))],
	// 			// 	location: 'null',
	// 			// })
	// 			getAvailabilityAndRateByOtherLocation(params);
	// 		}

	// 	}

	// }, [checkInDate, checkOutDate, getAvailabilityAndRateByLocation, getAvailabilityAndRateByOtherLocation, isManualBooking, location, locationId, locationList, personCount, roomType]);


	// //////////////////////////////////////////////////////////////////////////////////////////////
	// useEffect(() => {
	// 	if (getAvailabilityAndRateByLocationSuccess) {
	// 		setResultLength(getAvailabilityAndRateByLocationSuccess.data.length);
	// 		if (getAvailabilityAndRateByLocationSuccess.data.length === 0) {
	// 			setMessages("No Result Found");

	// 		} else {
	// 			setIsResult(true);
	// 			const booking = getAvailabilityAndRateByLocationSuccess.data;
	// 			setInventories(booking);
	// 			// if (form.getFieldValue('fromDate')) {
	// 			// 	const date1 = new Date(form.getFieldValue('fromDate')[0] ? moment(form.getFieldValue('fromDate')[0]._d) : checkInDate);
	// 			// 	const date2 = new Date(form.getFieldValue('fromDate')[1] ? moment(form.getFieldValue('fromDate')[1]._d).format('YYYY-MM-DD') : checkOutDate);
	// 			// 	const Difference_In_Time = date2.getTime() - date1.getTime();

	// 			// 	const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
	// 			// 	setNoOfNights(Difference_In_Days);
	// 			// }
	// 		}
	// 	}
	// 	if (getAvailabilityAndRateByLocationError) {
	// 		message.error(getAvailabilityAndRateByLocationError.message)
	// 	}
	// 	setLoading(false);
	// }, [checkInDate, checkOutDate, getAvailabilityAndRateByLocationError, getAvailabilityAndRateByLocationSuccess]);

	// //////////////////////////////////////////////////////////////////////////////////////////////
	// useEffect(() => {
	// 	if (getAvailabilityAndRateByOtherLocationSuccess) {
	// 		setResultLength(getAvailabilityAndRateByOtherLocationSuccess.data.length);
	// 		if (getAvailabilityAndRateByOtherLocationSuccess.data.length === 0) {
	// 			setMessages("No Result Found");

	// 		} else {
	// 			setIsResult(true);
	// 			const booking = getAvailabilityAndRateByOtherLocationSuccess.data;
	// 			setInventories(booking);
	// 			const buildingListTemp: any = [];
	// 			const buildingData: any = [];
	// 			_.map(booking, (element) =>
	// 				buildingListTemp.push(element.property)
	// 			);
	// 			_.map(buildingListTemp, (element) =>
	// 				buildingData.push({ id: element._id, name: element.propertyName, position: { lat: Number(element.latitude), lng: Number(element.longitude) }, address: element.address1 })
	// 			);
	// 			setBuilding(buildingData);
	// 			// if (form.getFieldValue('fromDate')) {
	// 			// 	const date1 = new Date(form.getFieldValue('fromDate')[0] ? moment(form.getFieldValue('fromDate')[0]._d) : checkInDate);
	// 			// 	const date2 = new Date(form.getFieldValue('fromDate')[1] ? moment(form.getFieldValue('fromDate')[1]._d).format('YYYY-MM-DD') : checkOutDate);
	// 			// 	const Difference_In_Time = date2.getTime() - date1.getTime();

	// 			// 	const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
	// 			// 	setNoOfNights(Difference_In_Days);
	// 			// }

	// 		}
	// 	}
	// 	if (getAvailabilityAndRateByOtherLocationError) {
	// 		message.error(getAvailabilityAndRateByOtherLocationError.message)
	// 	}
	// 	setLoading(false);
	// }, [checkInDate, checkOutDate, getAvailabilityAndRateByOtherLocationError, getAvailabilityAndRateByOtherLocationSuccess]);

	const renderCorporateLocationNew = useCallback(() => {
		if (locationList) {
			return locationList.map((acc: any, key: number) => {
				return ({
					key: key,
					value: `${acc._id}`,
					label: acc.locationName,
					loc: acc
				})
			});
		}

	}, [locationList]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// const setLocationData = useCallback((location) => {
	// 	setOtherLocation(location);
	// 	// SetValue({
	// 	// 	otherLocation: location?.address1
	// 	// });
	// 	// setLocations({ lat: Number(location.lat), lng: Number(location.lng), locationName: location.address1 });
	// 	// setLocations(null);
	// }, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const onLocationChange = useCallback((val) => {
		setSelectedLocation(val.loc);
		setLocationId(val.value);
		if (val.value === 'null') {
			const params: any = {};
			params.isCountryLocation = true;
			loadLocation(params);
		} else {
			setIsManualBooking(false);
		}
	}, [loadLocation]);


	/////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const onFromDateChange = useCallback((dates, dateStrings) => {
		setCheckInDate(moment(new Date(dateStrings[0])));
		setCheckOutDate(moment(new Date(dateStrings[1])));
		setStorage('makeBookingCheckInDate', moment(new Date(dateStrings[0])));
		setStorage('makeBookingCheckOutDate', moment(new Date(dateStrings[1])));
		// const params: any = {};
		// const date: any = watch('fromDate');
		// if (locationId !== 'null') {

		// 	params.locationId = watch('location') ? watch('location') : locationId;
		// 	params.checkInDate = date[0] ? moment(date[0]).format('YYYY-MM-DD') : dateStrings[0];
		// 	params.checkOutDate = date[1] ? moment(date[1]).format('YYYY-MM-DD') : dateStrings[1];
		// 	params.location = selectedLocation;

		// } else {

		// }
		// loadLocation(params);
	}, []);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	const disabledFromDate = useCallback((d) => {
		if (d == null) {
			return null;
		}
		return (
			(minDate != null && !d.isAfter(minDate) && d.isBefore(minDate, 'day'))
		);
	}, [minDate]);


	// ////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// const onSearchResult = useCallback((value) => {
	// 	if (locationId !== 'null') {
	// 		setLoading(true);
	// 		const params: any = {};
	// 		params.locationId = value.location ? value.location : locationId;
	// 		params.checkInDate = value.fromDate[0] ? moment(value.fromDate[0]._d).format('YYYY-MM-DD') : checkInDate;
	// 		params.checkOutDate = value.fromDate[1] ? moment(value.fromDate[1]._d).format('YYYY-MM-DD') : checkOutDate;
	// 		getAvailabilityAndRateByLocation(params);

	// 	} else {
	// 		setLoading(true);
	// 		const params: any = {};
	// 		params.placeId = otherLocation.placeId;
	// 		params.checkInDate = value.fromDate[0] ? moment(value.fromDate[0]._d).format('YYYY-MM-DD') : checkInDate;
	// 		params.checkOutDate = value.fromDate[1] ? moment(value.fromDate[1]._d).format('YYYY-MM-DD') : checkOutDate;
	// 		params.countryCode = otherLocation.countryCode;
	// 		params.center_latitude = otherLocation.lat;
	// 		params.center_longitude = otherLocation.lng;
	// 		getAvailabilityAndRateByOtherLocation(params);
	// 	}

	// }, [checkInDate, checkOutDate, getAvailabilityAndRateByLocation, getAvailabilityAndRateByOtherLocation, locationId, otherLocation]);



	// ////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// const onselectCompanyLocation = useCallback(() => {
	// 	// setIsManualBooking(false);

	// 	const params: any = {};
	// 	params.isCountryLocation = true;
	// 	loadLocation(params);

	// }, [loadLocation]);


	const onSearchResult = useCallback((value) => {
		const params: any = {};
		const date: any = watch('fromDate');
		const loc: any = watch('location');
		if (locationId !== 'null') {

			params.locationId = loc ? loc?.value : locationId;
			params.checkInDate = date[0] ? moment(date[0]).format('YYYY-MM-DD') : moment(checkInDate).format('YYYY-MM-DD');
			params.checkOutDate = date[1] ? moment(date[1]).format('YYYY-MM-DD') : moment(checkOutDate).format('YYYY-MM-DD');
			params.location = selectedLocation;
			setStorage('makeBookingProgramSearch', params);
		}
		loadLocation(params);
	}, [checkInDate, checkOutDate, loadLocation, locationId, selectedLocation, watch]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className="booking-form text-center">

			<Container style={{ maxWidth: '1675px' }} >
				<div >
					<Row style={{
						position: 'fixed',
						left: '6vw',
						top: '10vh',
						width: '100%',
						paddingTop: '5vh',
						paddingLeft: '1.5vw',
						zIndex: 20,
						backgroundColor: '#fff',
					}} >
						<Form onSubmit={handleSubmit(onSearchResult)} >
							<Col md={8}>
								<Row style={{
									height: "64px",
									gap: " 0px ",
									borderRadius: "25px 25px 25px 25px",
									opacity: "0px ",
									backgroundColor: "#35BDB4"
								}} >
									<Col md={3} style={{ width: isManualBooking ? '17%' : '50%', marginLeft: isManualBooking ? '0px' : '-275px' }}>

										{!isManualBooking &&
											<Controller
												name="location"
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<div style={{
														width: '321px',
														height: '44px',
														marginLeft: '285px',
														marginTop: "10px"
													}}>
														<Select
															components={{ DropdownIndicator: () => null }}
															isSearchable
															placeholder={<div className='flex justify-start '>
																<div style={{ marginRight: '6%' }} >
																	<img style={{ width: '21px', height: '26px' }} src={BookMarkIcon} alt='book mark ' />
																</div>
																<div className="font-light">
																	Select company location
																</div>
															</div>}
															options={renderCorporateLocationNew()}
															className={`${errors.location ? 'is-invalid' : ''} font-light`}
															// onChange={onLocationChange}
															onChange={(value) => {
																onLocationChange(value);
																field.onChange(value);
															}}
															styles={BookMarkStyles}
														/>
													</div>
												}
											/>
										}

										<ErrorMessage
											errors={errors}
											name="location"
											render={({ message }) => <div style={{ marginLeft: '-25px' }} className="invalid-feedback-custom">Please select company location!</div>} />



									</Col>
									<Col md={4} style={{ marginLeft: isManualBooking ? '-175px' : '-530px' }}>
										<Controller
											name="fromDate"
											defaultValue={[checkInDate, checkOutDate]}
											control={control}

											render={({ field }) =>
												<div style={{
													borderRadius: '11px',
													marginLeft: '650px',
													width: '321px',
													height: '44px',
													display: 'flex',
													alignItems: 'center',
													border: '1px solid hsl(0, 0%, 70%)',
													paddingLeft: '10px',
													marginTop: '10px',
													backgroundColor: '#FFFFFF',
												}}>
													<div>
														<img style={{ width: '26px', height: '25.32px' }} src={CalenderIcon} alt="" />
													</div>
													<div>
														<RangePicker
															autoFocus={false}
															bordered={false}
															className="font-light"
															suffixIcon={<div></div>}
															style={{
																border: 'none',
																fontWeight: 300,
																fontSize: '20px',
															}}
															placeholder={["Check-in", "Check-out"]}
															onChange={onFromDateChange}
															disabledDate={disabledFromDate}
															defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD')]}
														/>
													</div>
												</div>
											}
										/>

									</Col>
									{/* 
								<Col md={3} style={{ width: '0%', marginLeft: '150px' }}>
									{
										!loading && <Button type="submit" id='edit-button' style={formButton} >Search</Button>
									}
									{
										loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
									}
								</Col> */}

									<Col md={3} style={{

										marginTop: '10px',
										marginLeft: '693px'
									}}>
										{
											!loading && <Button type="submit" style={searchButton3} id='search-button' >
												<div className="flex justify-center" style={{
													marginTop: '-10px',

												}}>
													<div >
														<img src={searchIcon} className="searchIcon" alt="" />
													</div>
													<div style={{ marginTop: '15px' }}>Search</div>
												</div>

											</Button>
										}
										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={4}></Col>

							{/* <Col md={12} >
								<Row className='formSpace' style={{ marginBottom: '25px' }}>
									<Col md={2} style={{ marginLeft: '0px', marginTop: '5px' }}>
										<p style={{ fontWeight: 'bold' }}>Make a booking</p>
									</Col>
									<Col md={3} style={{ width: isManualBooking ? '17%' : '50%', marginLeft: isManualBooking ? '0px' : '-275px' }}>

										{!isManualBooking &&
											<Controller
												name="location"
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<div style={{
														width: '321px',
														height: '44px',
														marginLeft: '240px',
													}}>
														<Select
															components={{ DropdownIndicator: () => null }}
															isSearchable
															placeholder={<div className='flex justify-start '>
																<div style={{ marginRight: '6%' }} >
																	<img style={{ width: '21px', height: '26px' }} src={BookMarkIcon} alt='book mark ' />
																</div>
																<div className="font-light">
																	Select company location
																</div>
															</div>}
															options={renderCorporateLocationNew()}
															className={`${errors.location ? 'is-invalid' : ''} font-light`}
															// onChange={onLocationChange}
															onChange={(value) => {
																onLocationChange(value);
																field.onChange(value);
															}}
															styles={BookMarkStyles}
														/>
													</div>
												}
											/>
										}

										<ErrorMessage
											errors={errors}
											name="location"
											render={({ message }) => <div style={{ marginLeft: '-25px' }} className="invalid-feedback-custom">Please select company location!</div>} />


									</Col>

									<Col md={4} style={{ marginLeft: isManualBooking ? '-175px' : '-445px' }}>
										<Controller
											name="fromDate"
											defaultValue={[checkInDate, checkOutDate]}
											control={control}

											render={({ field }) =>
												<div style={{
													borderRadius: '11px',
													marginLeft: '280px',
													width: '321px',
													height: '44px',
													display: 'flex',
													alignItems: 'center',
													border: '1px solid hsl(0, 0%, 70%)',
													paddingLeft: '10px',

												}}>
													<div>
														<img style={{ width: '26px', height: '25.32px' }} src={CalenderIcon} alt="" />
													</div>
													<div>
														<RangePicker
															autoFocus={false}
															bordered={false}
															className="font-light"
															suffixIcon={<div></div>}
															style={{
																border: 'none',
																fontWeight: 300,
																fontSize: '20px',
															}}
															placeholder={["Check-in", "Check-out"]}
															onChange={onFromDateChange}
															disabledDate={disabledFromDate}
															defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD')]}
														/>
													</div>
												</div>
											}
										/>

									</Col>
									<Col md={3} style={{ width: '0%', marginLeft: '150px' }}>
										{
											!loading && <Button type="submit" id='edit-button' style={formButton} >Search</Button>
										}
										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col> */}
						</Form>

						<div style={{ marginTop: '-35px' }}>

							<Row className=''>
								<Col md={6} >
									{/* {isManualBooking &&
										<Button variant="link" className="text-right text-blue-400" onClick={() => onselectCompanyLocation()} style={{ marginRight: '60px' }}>Select company location</Button>
									} */}
								</Col>
								<Col md={6} style={{ textAlign: 'left' }}>
								</Col>
							</Row>
						</div>
					</Row>
				</div>

			</Container>

			{/* <Container style={{ maxWidth: '1675px' }}>
				<div >
					<Card id='booking-engine'>
						<Card.Body>
							<Row>
								<Form>
									<Row className='formSpace' style={{ marginBottom: '25px' }}>
										<Col md={2} style={{ marginLeft: '-10px' }}>
											<p style={{ fontWeight: 'bold' }}>Make a booking</p>
										</Col>

										<Col md={5} style={{ width: isManualBooking ? '20%' : '60%', marginLeft: isManualBooking ? '-25px' : '-200px' }}>
											{!isManualBooking && <Controller
												name="location"
												defaultValue={''}
												control={control}

												render={({ field }) =>
													<Select className={`${errors.location ? 'is-invalid' : ''}`} onChange={onLocationChange} placeholder="Select company location" style={{ width: '30%' }} >
														{renderCorporateLocation()}
													</Select>}
											/>}
											{isManualBooking && <Controller
												name="otherLocation"
												defaultValue={''}
												control={control}

												render={({ field }) =>
													<GooglePlaces className="form-control" onPlaceSelect={(location) => setLocationData(location)} />}
											/>}
										</Col>
										<Col md={5} style={{ marginLeft: isManualBooking ? '-90px' : '-300px' }}>
											<Controller
												name="fromDate"
												defaultValue={[checkInDate, checkOutDate]}
												control={control}
												render={({ field }) =>
													<RangePicker autoFocus={true} placeholder={["Check-in", "Check-out"]} onChange={onFromDateChange} disabledDate={disabledFromDate} defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD')]} />
												}
											/>

										</Col>
									</Row>
								</Form>
								<div style={{ marginTop: '-40px' }}>
									<Row>
										<Col>
											{isManualBooking &&
												<Button variant="link" className="text-right text-blue-400" onClick={() => onselectCompanyLocation()} style={{ marginRight: '600px' }} >Select company location</Button>
											}
										</Col>
									</Row>
								</div>
							</Row>
						</Card.Body>
					</Card>
				</div>
			</Container> */}

			{/* <div className=" inline-block m-1 text-justify items-center shadow-lg p-3 rounded-2xl bordered" style={{ width: '100%' }}>
				<p style={{ marginBottom: '15px' }} className='font-medium text-lg'>{clientList?.client?.companyName}</p>
				<Form name="horizontal_login" {...layout} layout="inline" form={form} onFinish={onSearchResult} style={{ marginBottom: '143px' }}>
					<Row >
						<Form.Item
							name="location"
						>
							{!isManualBooking && <Select style={{ width: '200px' }} onChange={onLocationChange} placeholder="Select company location">
								{renderCorporateLocation()}
							</Select>}
						</Form.Item>

						<Form.Item
							name="otherLocation"
						>
							{isManualBooking && <GooglePlaces onPlaceSelect={(location) => setLocationData(location)} />}
						</Form.Item>
						<Form.Item
							name="fromDate"
						>
							<RangePicker autoFocus={true} placeholder={["Check-in", "Check-out"]} onChange={onFromDateChange} defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD')]} disabledDate={disabledFromDate} />
						</Form.Item>
						<Form.Item style={{ marginTop: "-10px" }}>
							<Button loading={loading} className="rounded-full  font-bold  view-book ml-5" htmlType="submit" icon={<SearchOutlined />} style={{ width: "200px", height: "55px", fontSize: "15px" }}>Search</Button>
						</Form.Item>
					</Row>
				</Form>
				<div style={{ marginTop: '-148px' }}>
					<Row>
						<Col>
							{isManualBooking &&
								<Button type="link" className="text-right text-blue-400" onClick={() => onselectCompanyLocation()} style={{ marginLeft: '30px' }}>Select company location</Button>
							}
						</Col>
					</Row>
				</div>
			</div> */}

			{/* {
				isProgramme === 'MYO' &&
				<MyoCorporatePage loadLocation={loadLocation} programmeId={programmeId} isProgramme={isProgramme} />
			} */}
		</div>
	)
};

export default BookingFilterPage;