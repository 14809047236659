import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { Button, message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import ViewEdit from '../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import Permission from '../../../../../images/icons/svg/outside/Icon-Function-Permission.svg';
import ProgrammePermissionModel from './ProgrammePermissionModel'
import ProgrammeUserForm from './ProgrammeUserForm';
import { capitalizeRole, getLoggedUserType } from '../../../../../common/functions';
import ProgrammeUserView from './ProgrammeUserView';
import { UserGroups, UserProfile } from '../../../../../common/constants';
import { $mysaMegenta, $mysaTeal, $mysaYellow, overlayTriggerDelay, resendButton, routingButton, tableUserStyles } from '../../../../../common/components-style';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

const ProgrammeUsersList: React.FC<any> = ({ clientId, programmeId, selectedClient, selectedProgramme, setFormOpen, refreshProgrammes, setSelectedProgramme }): JSX.Element => {
	const [selectedUser, setSelectedUser] = useState(null);
	const [loadModel, setLoadModel] = useState(false)
	const [corporateProgrammes, setCorporateProgrammes] = useState<any>([]);
	const [showForm, setShowForm] = useState(false);
	const [userMappingData, setUserMappingData] = useState([]);
	const [showView, setShowView] = useState(false);
	const [clientIds, setClientIds] = useState<any>(null);

	const { onResendPassword, getUserMapping, resetAuth, getCorporateProgrammeById } = useStoreActions<any>((actions) => ({
		resetAuth: actions.auth.resetAuth,
		onResendPassword: actions.auth.onResendPassword,
		getUserMapping: actions.user.getUserMapping,
		getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
	}));

	const { resendPassword, resendPasswordError, getUserMappingSuccess, getCorporateProgrammeByIdSuccess } = useStoreState<any>((state) => ({
		resendPassword: state.auth.resendPassword,
		resendPasswordError: state.auth.resendPasswordError,
		getUserMappingSuccess: state.user.getUserMappingSuccess,
		getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
	}));

	useEffect(() => {
		setClientIds(clientId?._id ? clientId?._id : clientId);
		if (clientId?._id ? clientId?._id : clientId) {
			const id = clientId?._id ? clientId?._id : clientId;
			getUserMapping({ clientId: id });
		}
		if (programmeId) {
			getCorporateProgrammeById(programmeId);
		}
	}, [clientId, getCorporateProgrammeById, getUserMapping, programmeId]);

	useEffect(() => {
		if (resendPassword) {
			message.success('password resend successful, Please check the email');
			resetAuth();
		}

		if (resendPasswordError) {
			message.error(resendPasswordError.message);
			resetAuth();
		}

		if (getCorporateProgrammeByIdSuccess) {
			const programmeListTemp: Array<Object> = [];
			programmeListTemp.push(getCorporateProgrammeByIdSuccess.data);
			setCorporateProgrammes(programmeListTemp);
		}

	}, [resendPassword, resetAuth, resendPasswordError, getCorporateProgrammeByIdSuccess]);

	const reload = useCallback(() => {
		setTimeout(() => getUserMapping({ clientIds }), 1000)
		setShowForm(false);
	}, [clientIds, getUserMapping])

	const data = useMemo(() => {
		if (getUserMappingSuccess) {
			if (getUserMappingSuccess.data) {
				const { result, poolUsers } = getUserMappingSuccess.data;
				setUserMappingData(result);

				const mappedData = _.map(result, (val) => {
					const corporateProgramme = _.filter(val.clients, (a) => a.clientId === clientIds).shift()?.corporateProgramme;
					const userStatus = poolUsers[val?.user?.email]?.UserStatus
					return {
						key: val?.user?._id,
                        userId: val?.user?._id,
                        firstName: val?.user?.firstName,
                        lastName: val?.user?.lastName,
                        email: val?.user?.email,
                        phone: val?.user?.phone,
                        mobile: val?.user?.mobile,
                        position: val?.user?.position,
                        userProfile: val?.user?.userProfile,
                        userStatus: userStatus,
                        data: val,
                        corporateProgramme

					}
				});

				let filterData: any = [];
				filterData = _.map(mappedData, (val) => {
					const arrId = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?._id || ''
					const access = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.access || ''
					const role = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.role || ''
					const isFirstUser = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.isFirstUser
					const corporateProgrammeId = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.programmeId

					return {
						key: val.key,
						userId: val.userId,
						firstName: val.firstName,
						lastName: val.lastName,
						email: val.email,
						phone: val.phone,
						mobile: val.mobile,
						position: val.position,
						userProfile: val.userProfile,
						userStatus: val.userStatus,
						data: val.data,
						role,
						arrId,
						access,
						isFirstUser,
						corporateProgramme: val.corporateProgramme,
						corporateProgrammeId,
						userType: capitalizeRole(role),

					}
				})

				filterData = _.filter(filterData, (a) => a.corporateProgrammeId === programmeId && a.role !== UserGroups.traveler && a.role !== UserGroups.booker && a.role!==UserGroups.user);
				return filterData;
			}
		}
		return []
	}, [clientIds, getUserMappingSuccess, programmeId])

	const columns = [

		{ name: 'First name', selector: row => row.firstName, maxWidth: '110px' },
		{ name: 'Last name', selector: row => row.lastName, maxWidth: '110px' },
		{ name: 'Email', selector: row => row.email, minWidth: '110px' },
		{ name: 'User type', selector: row => row.userType, minWidth: '110px' },
		{
			name: 'Status',
			// minWidth: '230px',
			cell: (row) => {
				let color = "";
				if (row.userStatus !== 'CONFIRMED') color = $mysaYellow;
				if (row.userStatus === 'CONFIRMED') color = $mysaTeal;
				if (row.userStatus === 'FORCE_CHANGE_PASSWORD') color = $mysaMegenta;

				return (

					<span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>
						{row.userStatus} {row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => onResendPassword({ email: row.email })} className="ml-2 mt-1" >Resend</Button>}
					</span>
					// <span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} >{row.userStatus} {row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button onClick={() => onResendPassword({ email: row.email })} style={resendButton} id='resend-button' >Resend</Button>}</span>
					// <>
					// 	<span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>{row.userStatus}</span>
					// 	{row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => onResendPassword({ email: row.email })} className="ml-5" >Resend</Button>}
					// </>
				)
			}
		},
		{
			name: 'User profile',
			// minWidth: '120px',
			// button: true,
			cell: (row) => {
				let color = "";
				if (row.userProfile !== UserProfile.COMPLETED) color = $mysaMegenta;
				if (row.userProfile === UserProfile.COMPLETED) color = $mysaTeal;

				return (
					<span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.userProfile}</span>
				);

			}
		},
		{
			name: 'Action',
			button: true,
			cell: (row) => (
				<>
					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
					>
						<img

							className='iconSizes' src={ViewEdit} alt="" onClick={() => { setSelectedUser(row); setShowView(true) }} />
					</OverlayTrigger>

					{(getLoggedUserType() !== UserGroups.super_admin) && <OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>Permission</Tooltip>}

					>
						<img className='iconSizes' src={Permission} alt="" onClick={() => { setLoadModel(true); setSelectedUser(row) }} />
					</OverlayTrigger>}
				</>
			)

		},
	];
	// const columns = [

	// 	{ title: 'First name', dataIndex: 'firstName', key: 'fName' },
	// 	{ title: 'Last name', dataIndex: 'lastName', key: 'lName' },
	// 	{ title: 'Email', dataIndex: 'email', key: 'email' },
	// 	{ title: 'User type', dataIndex: 'userType', key: 'userType' },
	// 	{
	// 		title: 'Status',
	// 		dataIndex: '',
	// 		key: 'userStatus  ',
	// 		render: (data: any) => {

	// 			return (<div className={`${data.userStatus === 'CONFIRMED' ? 'text-green-500' : 'text-yellow-500'}`}>
	// 				{data.userStatus} {data.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button onClick={() => onResendPassword({ email: data.email })} className="ml-2 mysa-blue text-white" size="small">Resend</Button>}
	// 			</div>)
	// 		},
	// 	},
	// 	{
	// 		title: 'User profile',
	// 		dataIndex: '',
	// 		key: 'userProfile  ',
	// 		render: (data: any) => {

	// 			return (<div className={`${data.userProfile === UserProfile.COMPLETED ? 'text-green-500' : 'text-red-500'}`}>
	// 				{data.userProfile}
	// 			</div>)
	// 		},
	// 	},
	// 	{
	// 		title: 'Action',
	// 		dataIndex: '',
	// 		key: 'status',
	// 		render: (data: any) => {
	// 			return (<div>
	// 				<Button onClick={() => { setSelectedUser(data); setShowView(true) }} className="border-none" ><EyeTwoTone /></Button>
	// 				<Button onClick={() => { setLoadModel(true); setSelectedUser(data) }} className="border-none" ><UnlockTwoTone /></Button>
	// 			</div>)
	// 		},
	// 	},
	// ];

	return (
		<div>


			{/* <Row>
				<Col xs={9}>
					{selectedClient && <Breadcrumb className="mb-10">
						<Breadcrumb.Item>
							<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
						</Breadcrumb.Item>
						{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
					</Breadcrumb>}

				</Col>
				<Col xs={3} className="text-right">
					<div className="text-right" style={{ position: "fixed", zIndex: 9999, top: "110px", right: "22px", width: "100%", maxWidth: "80%", padding: "0 15px", cursor: "pointer" }}>
						{corporateProgrammes[0]?.isActivated === true ? <Button style={routingButton} id='routing-button' hidden={showForm || showView} className='float-right mb-3' onClick={() => { setSelectedUser(null); setShowForm(true) }}><b>New User</b></Button>
							: !showForm && <div style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold', color: '#fAC90E', marginLeft: '-100px' }} >The programme must be activated before users can be created </div>
						}
						
					</div>
				</Col>
			</Row> */}
			{/* <div>

				<div style={{ marginTop: "65px" }}>
					{showForm && <ProgrammeUserForm selectedUser={selectedUser} clientId={clientId} programmeId={programmeId} close={() => setShowForm(false)} reload={reload} />}
					{showView && <ProgrammeUserView selectedUser={selectedUser} close={() => setShowView(false)} reload={reload} />}
					{showForm || showView || <DataTable
						columns={columns}
						data={data}
						pagination
						striped={true}
						customStyles={tableUserStyles}
						theme='mysaTable'
						fixedHeader={true}
						noDataComponent={'Updating records'}
					/>}
					{loadModel && <ProgrammePermissionModel clientId={clientId} programmeId={programmeId} userMappingData={userMappingData} corporateProgrammes={corporateProgrammes} selectedUser={selectedUser} onClose={() => { setLoadModel(false); getUserMapping({ clientId }) }} />}
				</div>
			</div> */}


			<div style={{ marginTop: "-2%", paddingTop: "4px" }}>
				<Row>

					<Col xs={9}></Col>
					<Col xs={3} className="ml-auto edit_btn_wpr">
						<div className="text-right" >
							{corporateProgrammes[0]?.isActivated === true ? <Button style={routingButton} id='routing-button' hidden={showForm || showView} className='float-right mb-3' onClick={() => { setSelectedUser(null); setShowForm(true) }}><b>New Admin</b></Button>
								: !showForm && <div style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold', color: '#fAC90E', marginLeft: '-100px' }} >The programme must be activated before users can be created </div>
							}
						</div>
					</Col>
				</Row>


				<div style={{ marginTop: "65px" }}>
					{showForm && <ProgrammeUserForm selectedUser={selectedUser} clientId={clientId} programmeId={programmeId} close={() => setShowForm(false)} reload={reload} />}
					{showView && <ProgrammeUserView selectedUser={selectedUser} close={() => setShowView(false)} reload={reload} />}
					{showForm || showView || <DataTable
						columns={columns}
						data={data}
						pagination
						striped={true}
						customStyles={tableUserStyles}
						theme='mysaTable'
						fixedHeader={true}
						noDataComponent={'Updating records'}
					/>}
					{loadModel && <ProgrammePermissionModel clientId={clientIds} programmeId={programmeId} userMappingData={userMappingData} corporateProgrammes={corporateProgrammes} selectedUser={selectedUser} onClose={() => { setLoadModel(false); getUserMapping({ clientIds }) }} />}
				</div>
			</div>
		</div>
	)
}

export default ProgrammeUsersList
