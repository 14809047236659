import { ErrorMessage } from "@hookform/error-message";
import { DatePicker, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { formButton } from "../../../../../common/components-style";
import dayjs from 'dayjs';

import type { RangePickerProps } from 'antd/es/date-picker';
import moment from "moment";
import { REQUEST_BOOKING_STATUS } from "../../../../../common/constants";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";

type TAcceptBooking = {
    yesNo: String,
    offerExpires: String,
    time: String
};

type TAcceptBookingView = {
    data: any,
    _id: String | undefined,
    _reqGroupId: String | undefined,
    _reqBrandId: String | undefined,
    _reqPropertyId: String | undefined,
    _reqInventoryId: String | undefined,
    hideShowViewComponent: ((value: boolean) => void)
};

const OfferedExpires: React.FunctionComponent<TAcceptBookingView> = ({ _id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, hideShowViewComponent }) => {

    //////////// form handle ///////////////////////
    const { register, handleSubmit, formState: { errors }, control, reset, setValue } = useForm<TAcceptBooking>();

    ///////////////////////////global action///////////////////////////////////
    const { updateRequestStatus, setReset, getMessageThreadById } = useStoreActions<any>((actions) => ({
        updateRequestStatus: actions.booking.updateRequestStatus,
        getMessageThreadById: actions.booking.getMessageThreadById,
        setReset: actions.booking.setReset,
    }));

    /////////////////// global state ////////////////
    const { getUpdateRequestStatusSuccess, getUpdateRequestStatusError, getMessageThreadByIdSuccess } = useStoreState<any>((actions) => ({
        getMessageThreadByIdSuccess: actions.booking.getMessageThreadByIdSuccess,
        getUpdateRequestStatusSuccess: actions.booking.getUpdateRequestStatusSuccess,
        getUpdateRequestStatusError: actions.booking.getUpdateRequestStatusError,
    }));

    ////////////////// local state //////////////
    const [loading, setLoading] = useState<boolean>(false);
    const [hideShowView, setHideShowView] = useState<boolean>(false);
    const [data, setData] = useState<any>();

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };

    useEffect(() => {
        if (_id && _reqBrandId && _reqGroupId && _reqInventoryId && _reqPropertyId) {
            const payload = {
                _id: _id,
                _reqBrandId: _reqBrandId,
                _reqGroupId: _reqGroupId,
                _reqInventoryId: _reqInventoryId,
                _reqPropertyId: _reqPropertyId
            }
            getMessageThreadById(payload);

        }
    }, [_id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, getMessageThreadById])

    //////////////////////////////////
    useEffect(() => {
        if (getMessageThreadByIdSuccess) {
            setData(getMessageThreadByIdSuccess.data);
            if (Boolean(data?.expiryDetails?.offerExtended)) {
                setHideShowView(true);
            };
            if (data?.expiryDetails?.offerExpiresAt) {
                setValue('offerExpires', String(moment(data?.expiryDetails?.offerExpiresAt).format('YYYY-MM-DD')));
                setValue('time', String(data?.expiryDetails?.offerExpiresAt?.substring(11, 19)));
            }
        }

        if (getUpdateRequestStatusSuccess) {
            setLoading(false);
            setReset();
            reset();
            toast.success("Accept booking data updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            hideShowViewComponent(false);
        }

        if (getUpdateRequestStatusError) {
            setLoading(false);
            setReset();
            toast.error(getUpdateRequestStatusError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [_id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, data?.expiryDetails?.offerExpiresAt, data?.expiryDetails?.offerExtended, getMessageThreadByIdSuccess, getUpdateRequestStatusError, getUpdateRequestStatusSuccess, hideShowViewComponent, reset, setReset, setValue]);

    //////////////// submit /////////
    const onSubmit = (values) => {
        setLoading(true);
        const time = values?.time.length === 8 ? `${moment(values?.offerExpires).format('YYYY-MM-DD')}T${values?.time}.000Z` : `${moment(values?.offerExpires).format('YYYY-MM-DD')}T${moment(values?.time).format('HH:mm')}:00.000Z`
        
        const isBefore = moment(data?.checkInDate).isSameOrAfter(time);

        if(!isBefore){
            toast.error('Expired date should to be less then check-in date', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setLoading(false);
            return;
        }
        
        const payload = {
            _id: _id,
            _reqGroupId: _reqGroupId,
            _reqBrandId: _reqBrandId,
            _reqPropertyId: _reqPropertyId,
            _reqInventoryId: _reqInventoryId,
            checkInDate : data?.checkInDate,
            checkOutDate : data?.checkOutDate,
            propertyId : data?.property?.propertyId,
            bookingStatus: REQUEST_BOOKING_STATUS.OFFERED,
            expiryDetails: {
                offerExpiresAt: time,
                offerExtended: true
            }
        }
        updateRequestStatus(payload);
    }

    return (<>
        <Form >
            <Row className='formSpace'>
                <Col md={5} style={{ textAlign: 'right' }}></Col>
                <Col md={7} style={{ textAlign: 'left' }}>
                    <Row className='formSpace'>
                        <Col md={4} >  <Form.Label>Offer expires</Form.Label></Col>
                        <Col md={8} >
                            <Controller
                                name="offerExpires"
                                control={control}
                                rules={
                                    { required: true }
                                }
                                render={({ field }) =>
                                    <DatePicker defaultValue={moment(data?.expiryDetails?.offerExpiresAt).add(1, 'days')} format="YYYY-MM-DD" disabledDate={disabledDate} className={`form-control ${errors.offerExpires ? 'is-invalid' : ''}`} onChange={(date) => field.onChange(date)} />}
                            />

                            <ErrorMessage
                                errors={errors}
                                name="offerExpires"
                                render={({ message }) => <div className="invalid-feedback-custom">Please select offer expires!</div>}
                            />
                        </Col>
                    </Row>
                    <Row className='formSpace'>
                        <Col md={4} >  <Form.Label>Time</Form.Label></Col>
                        <Col md={8} >
                            <Controller
                                name="time"
                                control={control}
                                rules={
                                    { required: true }
                                }
                                render={({ field }) =>
                                    <TimePicker defaultValue={data?.expiryDetails?.offerExpiresAt ? moment(data?.expiryDetails?.offerExpiresAt?.substring(11, 19), 'HH:mm') : moment()} format="HH:mm" className={`form-control ${errors.offerExpires ? 'is-invalid' : ''}`} onChange={(time) => field.onChange(time)} />

                                } />
                            <ErrorMessage
                                errors={errors}
                                name="time"
                                render={({ message }) => <div className="invalid-feedback-custom">Please select time!</div>}
                            />
                        </Col>
                    </Row>
                    <Row className='formSpace'>
                        <Col md={12} >
                            {!loading &&
                                <Button id='approve-button' style={formButton} onClick={() => handleSubmit(onSubmit)()}><b>Submit</b></Button>
                            }
                            {
                                loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                            }
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Form>
    </>);
};

export default OfferedExpires;