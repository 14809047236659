
import React, { } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import EnergyView from './EnergyView';
import ConsumableView from './ConsumableView';
import ConsiderationView from './ConsiderationView';

const SustainabilityView: React.FC<any> = ({ propertyId }): JSX.Element => {
    return (
        <>
            <Tabs
                defaultActiveKey="Energy"
                id="uncontrolled-tab-example"
                className='underline_tabs'
            >
                <Tab eventKey="Energy" title="Energy">
                    <EnergyView propertyId={propertyId} />
                </Tab>
                <Tab eventKey="Consumables and Materials" title="Consumables and Materials">
                    <ConsumableView propertyId={propertyId} />
                </Tab>
                <Tab eventKey="Other considerations" title="Other considerations">
                    <ConsiderationView propertyId={propertyId} />
                </Tab>


            </Tabs>

        </>
    );

}

export default SustainabilityView;