import React, { useEffect, useState } from "react"
import { $mysaMegenta, $mysaRed, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, tableStyles } from "../../../../../common/components-style";
import DataTable, { createTheme } from 'react-data-table-component';
import { getStorage } from "../../../../../common/storage";
import { useStoreActions, useStoreState } from "easy-peasy";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ViewEdit from '../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { REQUEST_BOOKING_STATUS } from "../../../../../common/constants";
import { TRequestBookingView } from "../../../../../common/types";
import moment from "moment";
import RequestBookingSuperAdminHomeView from "./RequestBookingSuperAdminHomeView";
import RequestBookingOfferedHomeSuperAdminView from "./RequestBookingOfferedHomeSuperAdminView";
import { useLocation } from "react-router-dom";

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const RequestBookingSuperAdminHome: React.FunctionComponent = () => {

	///////////// data get from local storage database from browser ////////////////
	const userId = getStorage('myo_logged_user')?.userData?._id;

	///////////////////////////global action///////////////////////////////////
	const { superAdminRequestBooking } = useStoreActions<any>((actions) => ({
		superAdminRequestBooking: actions.booking.superAdminRequestBooking
	}));

	/////////////////// global state ////////////////
	const { getSuperAdminRequestBookingSuccess } = useStoreState<any>((status) => ({
		getSuperAdminRequestBookingSuccess: status.booking.getSuperAdminRequestBookingSuccess
	}));

	///////////////// local state ////////////////
	const [data, setData] = useState<any[]>([]);
	const [hideShowView, setHideShowView] = useState<Boolean>(false);
	const [viewComponentData, setViewComponentData] = useState<TRequestBookingView>();
	const [status, setStatus] = useState<String>("");
	const [hideShowViewExtendOffer, setHideShowViewExtendOffer] = useState<Boolean>(false);
	// const [status, setStatus] = useState<String>("");
	const location = useLocation<LocationState>();
	//////////// action hook //////////////
	interface LocationState {
		_id: string;
		_reqGroupId: string;
		_reqBrandId: string;
		_reqPropertyId: string;
		_reqInventoryId: string;
	  }


	  useEffect(() => {
		if (location.state) {
		  const {
			_id,
			_reqGroupId,
			_reqBrandId,
			_reqPropertyId,
			_reqInventoryId,
		  } = location.state;
	
		  const data: TRequestBookingView = {
			_id,
			_reqGroupId,
			_reqBrandId,
			_reqPropertyId,
			_reqInventoryId,
			hideShowViewComponent: () => { }
		  };
	
		  setViewComponentData(data); 
		  setHideShowView(true);
		}
	  }, [location]);

	useEffect(() => {
		if (userId) {
			const payload = {
				requestUserId: userId
			}

			superAdminRequestBooking(payload);

		}
	}, [superAdminRequestBooking, userId]);


	///////////////// data fetch and handle hook /////////////////
	useEffect(() => {

		if (getSuperAdminRequestBookingSuccess?.data) {

			setData(getSuperAdminRequestBookingSuccess.data);
		}

	}, [getSuperAdminRequestBookingSuccess]);

	/////////////////////////////////////////////////////////////////////////////
	const hideShowViewComponent = (value: boolean) => {
		setHideShowView(value);
		setHideShowViewExtendOffer(false);
		if (userId) {
			const payload = {
				requestUserId: userId
			}

			superAdminRequestBooking(payload);

		}
	}

	const columns = [
		{
			name: 'Programme',
			selector: row => row.programmeName,
		},
		// {
		// 	name: 'Location',
		// 	selector: row => row.location.locationName,
		// },
		// {
		// 	name: 'Enquiry No.',
		// 	width: "250px",
		// 	selector: row => row.inventory._reqInventoryId,
		// },
		{
			name: 'Country',
			selector: row => row.property.countryName,
		},
		{
			name: 'City',
			selector: row => row.property.cityName,
		},
		{
			name: 'Property',
			selector: row => row.property.propertyName,
		},

		{
			name: 'Brand',
			selector: row => row.brandName,
		},
		{
			name: 'Received',
			width: "150px",
			selector: row => moment(row.requestedDate).format('D MMM YYYY'),
		},
		{
			name: 'Time',
			width: "100px",
			selector: row => moment(row.requestedDate).format('HH:mm'),
		},
		{
			name: 'Check-in',
			width: "150px",
			selector: row => moment(row.checkInDate).format('D MMM YYYY'),
		},
		{
			name: 'Check-out',
			width: "150px",
			selector: row => moment(row.checkOutDate).format('D MMM YYYY'),
		},
		{
			name: 'Accommodation',
			selector: row => row.inventory.inventoryName,
		},
		{
			name: 'Status',
			width: "100px",
			cell: (row) => {
				let color = "";
				if (row.requestStatus === REQUEST_BOOKING_STATUS.PENDING) color = $mysaYellow;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.NOT_AVAILABLE) color = $mysaRed;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.AVAILABLE) color = $mysaTeal;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED) color = $mysaMegenta;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.ALT_OFFER) color = $mysaTeal;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.DECLINED) color = $mysaRed;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.EXPIRED) color = $mysaRed;

				return (
					<span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.requestStatus}</span>
				)
			}
		},
		// {
		// 	name: 'Expiry Date',
		// 	selector: row => row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED? moment(row.expiryDetails?.offerExpiresAt).format('D MMM YYYY'):'',
		// },
		// {
		// 	name: 'Expiry Time',
		// 	selector: row => row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED?moment(row.expiryDetails?.offerExpiresAt).format('HH:mm'):'',
		// },
		{
			name: 'Action',
			button: true,
			cell: (row: any) => {
				return (<>
					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
					>
						{/* <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
							const data: TRequestBookingView = {
								_id: row?._id,
								_reqGroupId: row?._reqGroupId,
								_reqBrandId: row?._reqBrandId,
								_reqPropertyId: row?.property?._reqPropertyId,
								_reqInventoryId: row?.inventory?._reqInventoryId,
								hideShowViewComponent: () => { }
							}
							setViewComponentData(data);
							setStatus(row.requestStatus);
							hideShowViewComponent(true);
						}} /> */}

						<img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
							const data: TRequestBookingView = {
								_id: row?._id,
								_reqGroupId: row?._reqGroupId,
								_reqBrandId: row?._reqBrandId,
								_reqPropertyId: row?.property?._reqPropertyId,
								_reqInventoryId: row?.inventory?._reqInventoryId,
								hideShowViewComponent: () => { }
							}
							setViewComponentData(data);
							setStatus(row.requestStatus);
					
							if ((row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED || row.requestStatus === REQUEST_BOOKING_STATUS.ALT_OFFER)) {
								hideShowViewComponent(false);
								setHideShowViewExtendOffer(true);
							}
							else {
								hideShowViewComponent(true);
								setHideShowViewExtendOffer(false);
							}
						}} />
					</OverlayTrigger>

				</>)
			},
		},
	];
	return (
		<>
			{
				!hideShowView && !hideShowViewExtendOffer && <div className='brnd_tbl' style={{ marginTop: '50px' }}>
					<DataTable
						columns={columns}
						data={data}
						pagination
						striped={true}
						customStyles={tableStyles}
						theme='mysaTable'
						fixedHeader={true}
						noDataComponent={'Updating records'}
					/>
				</div>
			}
			{/* 
			{
				hideShowView && <RequestBookingSuperAdminHomeView hideShowViewComponent={hideShowViewComponent} _id={viewComponentData?._id} _reqBrandId={viewComponentData?._reqBrandId} _reqGroupId={viewComponentData?._reqGroupId} _reqInventoryId={viewComponentData?._reqInventoryId} _reqPropertyId={viewComponentData?._reqPropertyId} />
			} */}


			{
				hideShowView && <RequestBookingSuperAdminHomeView status={status} hideShowViewComponent={hideShowViewComponent} _id={viewComponentData?._id} _reqBrandId={viewComponentData?._reqBrandId} _reqGroupId={viewComponentData?._reqGroupId} _reqInventoryId={viewComponentData?._reqInventoryId} _reqPropertyId={viewComponentData?._reqPropertyId} />
			}

			{hideShowViewExtendOffer && <RequestBookingOfferedHomeSuperAdminView status={status} hideShowViewComponent={hideShowViewComponent} _id={viewComponentData?._id} _reqBrandId={viewComponentData?._reqBrandId} _reqGroupId={viewComponentData?._reqGroupId} _reqInventoryId={viewComponentData?._reqInventoryId} _reqPropertyId={viewComponentData?._reqPropertyId} />
			}

		</>
	)
}

export default RequestBookingSuperAdminHome;