import React from 'react'

const AccessDenied = () => {
	return (
		<div className="text-red-700">
				Unfortunately you do not have access to this page. Please contact your administrator(s) if you require access
		</div>
	)
}

export default AccessDenied;