import { useCallback, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { BrandInvitationStatus, UserGroups } from '../../../common/constants';
import { getLoggedUserType } from '../../../common/functions';
import jwt from 'jsonwebtoken';
import { Link } from 'react-router-dom';
import { $mysaMegenta, $mysaTeal, $mysaYellow, formButton, mysaTableTheme, overlayTriggerDelay, resendButton, selectStyles, selectStylesLittleRect, tableStyles } from '../../../common/components-style';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import Archive from '../../../images/icons/svg/outside/Icon-Function-Archive.svg';
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import DataTable, { createTheme } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { brandInvite } from '../../../common/interfaces';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const BrandInvitationList: React.FC<any> = (): JSX.Element => {
    ////////////////////////////////Declaration//////////////////////////////////////////
    const { register, handleSubmit, formState: { errors }, reset } = useForm<brandInvite>();

    /////////////////////////////////////// manage local state /////////////////////////////////////////
    const [data, setData] = useState([]);
    const [brandOptions, setBrandOptions] = useState<any>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [loadingFirstTime, setLoadingFirstTime] = useState<boolean>(false);
    const [archivePopop, setArchivePopop] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [brandName, setBrandName] = useState<string>('');
    const [checkInDate] = useState<any>('');
    const [checkOutDate] = useState<any>('');
    const [brandId, setBrandId] = useState<String>("");
    const [brandStatus, setBrandStatus] = useState<String>("");
    const [brandArchiveId, setBrandArchiveId] = useState<String>("");

    ///////////////////////////////////// global action manage /////////////////////////////////////////
    const { onLoadBrandSignUp, onCreateBrandSignUp, onUpdateBrandSignup } = useStoreActions<any>((actions) => ({
        onLoadBrandSignUp: actions.brand.onLoadBrandSignUp,
        onCreateBrandSignUp: actions.brand.onCreateBrandSignUp,
        onUpdateBrandSignup: actions.brand.onUpdateBrandSignup,
    }));

    /////////////////////////////////// global state manage ////////////////////////////////////////////
    const { loadBrandSignUpSuccess, createBrandSignUpSuccess, createBrandSignUpError, updateBrandSignUpInviteSuccess } = useStoreState<any>((state) => ({
        loadBrandSignUpSuccess: state.brand.loadBrandSignUpSuccess,
        createBrandSignUpSuccess: state.brand.createBrandSignUpSuccess,
        createBrandSignUpError: state.brand.createBrandSignUpError,
        updateBrandSignUpInviteSuccess: state.brand.updateBrandSignUpSuccess,
    }));

    useEffect(() => {
        const payload = {
            start_date: checkInDate,
            end_date: checkOutDate,
            brand_id: brandId,
            sign_up_status: brandStatus,
        }
        onLoadBrandSignUp(payload);
    }, [brandId, brandStatus, checkInDate, checkOutDate, onLoadBrandSignUp]);

    //////////////////////////////////////// customize data without accepted status and invite create and success manage //////////////////////////////////////
    useEffect(() => {
        if (loadBrandSignUpSuccess) {
            const brandListTemp: any = [];
            let rowData: any = [];
            loadBrandSignUpSuccess.data.forEach((brand: any) => {
                if (brand.signUpStatus !== 'accepted' && brand.signUpStatus !== 'rejected' && brand.signUpStatus !== brandStatus) {
                    rowData.push({
                        _id: brand._id,
                        brandName: brand.brandName,
                        createdAt: brand.createdAt,
                        name: brand.name,
                        email: brand.email,
                        signUpStatus: brand.signUpStatus,
                        token: brand.token,
                        // certificateId: brand.certificateId,
                        certificateMyoManagementID: brand.certificateMyoManagementID,
                    });
                }

                if (brand.signUpStatus === brandStatus) {
                    rowData.push({
                        _id: brand._id,
                        brandName: brand.brandName,
                        createdAt: brand.createdAt,
                        name: brand.name,
                        email: brand.email,
                        signUpStatus: brand.signUpStatus,
                        token: brand.token,
                        // certificateId: brand.certificateId,
                        certificateMyoManagementID: brand.certificateMyoManagementID,
                    });
                }

                ///////// brand menu filter
                if (brand.signUpStatus !== 'accepted') {
                    if (!loadingFirstTime) {
                        brandListTemp.push(
                            {
                                value: brand._id,
                                label: brand.brandName,
                            }
                        )
                    }
                }
            });

            if (!loadingFirstTime) {
                setBrandOptions(brandListTemp);
            }

            setLoadingFirstTime(true);

            setData(rowData);
        }

        if (updateBrandSignUpInviteSuccess) {
            const payload = {
                start_date: checkInDate,
                end_date: checkOutDate,
                brand_id: brandId,
                sign_up_status: brandStatus,
            }
            onLoadBrandSignUp(payload);
        }

        if (createBrandSignUpSuccess) {
            setLoading(false);
            setOpenModal(false);
            const payload = {
                start_date: '',
                end_date: '',
                brand_id: '',
                sign_up_status: '',
            }
            onLoadBrandSignUp(payload);
            reset({
                brandName: '',
                name: '',
                email: ''
            }, {
                keepValues: false,
            })
        }

        if (createBrandSignUpError) {
            toast.error(createBrandSignUpError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setLoading(false);
        }
    }, [brandId, brandStatus, checkInDate, checkOutDate, createBrandSignUpError, createBrandSignUpSuccess, loadBrandSignUpSuccess, loadingFirstTime, onLoadBrandSignUp, reset, updateBrandSignUpInviteSuccess]);

    //////////////////////////////////////// invite submit part ////////////////////////////////////////
    const onInvite = useCallback(() => {
        setLoading(true);
        const jwtToken = jwt.sign({ name, email }, 'myo');
        onCreateBrandSignUp({ name, email, token: jwtToken, brandName });
    }, [brandName, email, name, onCreateBrandSignUp]);

    //////////////////////////////////////////////////// table column ///////////////////////////////////
    const columns = [
        {
            name: 'Brand',
            selector: row => row.brandName,
        },
        {
            name: 'Date Sent',
            sortable: true,
            selector: row => row.createdAt,
            cell: (row) => {
                return (<div>{moment(row.createdAt).format("DD-MM-YYYY")}</div>);
            }
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Status',
            cell: (row) => {
                let color = "";
                let text = "";
                if (row.signUpStatus === BrandInvitationStatus.sent) {
                    color = $mysaYellow
                    text = "OutStanding";
                };
                if (row.signUpStatus === BrandInvitationStatus.rejected) {
                    color = $mysaTeal;
                    text = "Declined";
                };
                if (row.signUpStatus === BrandInvitationStatus.pending) {
                    color = $mysaMegenta;
                    text = "Pending";
                };

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{text}</span>
                )
            }
        },
        {
            name: 'Action',
            button: false,
            cell: (row: any) => {

                if (row.signUpStatus === 'pending' || row.signUpStatus === 'rejected') {
                    return (
                        <>
                            <OverlayTrigger
                                placement="top"
                                delay={overlayTriggerDelay}
                                overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
                            >
                                <Link style={{ marginLeft: 30 }} to={`/admin/dashboard/new-sign-up/form?token=${row.token}&_id=${row._id}&certificateMyoManagementID=${row?.certificateMyoManagementID}&email=${encodeURIComponent(row.email)}&signUpStatus=${row.signUpStatus}&brand=${row.brandName}`} >
                                    <img className='iconSizes' src={ViewEdit} alt="" />
                                </Link>

                            </OverlayTrigger>
                        </>

                    )
                } else {
                    return (<>
                        <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
                        >
                            <img className='iconSizes' src={Archive} alt="" onClick={() => {
                                setBrandArchiveId(row._id);
                                setArchivePopop(true);
                            }} />
                        </OverlayTrigger>
                        <Button style={resendButton} id='resend-button' onClick={() => {
                            onUpdateBrandSignup({ id: row._id, signUpStatus: 'sent' });
                            toast.success(`Brand invite resent`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: 'foo-bar'
                            });
                        }} className="ml-2 mt-1" >Resend</Button>
                    </>)
                }
            },
        },
    ];

    // const onFromDateChange = useCallback((dates, dateStrings) => {
    //     dateStrings[0] !== '' ? setCheckInDate(new Date(dateStrings[0])) : setCheckInDate('');
    //     dateStrings[1] !== '' ? setCheckOutDate(new Date(dateStrings[1])) : setCheckOutDate('');
    // }, []);

    //////////////////////////////////////////////////////////////////////////////////////
    const onChangeBrand = useCallback((data: any) => {

        if (data?.value) {
            setBrandId(data.value);
        } else {
            setBrandId('');
        }

    }, [])

    const onChangeBrandStatus = useCallback((data: any) => {
        if (data?.value) {
            setBrandStatus(data.value);
        } else {
            setBrandStatus('');
        }

    }, []);

    const onArchive = () => {
        onUpdateBrandSignup({ id: brandArchiveId, signUpStatus: 'rejected' });
    };

    return (

        <>
            {getLoggedUserType() === UserGroups.super_admin && <>
                <Row className="text-center">
                    <Col xs={2}>
                        <Select
                            isClearable
                            className='search_bar select_btn'
                            placeholder={<div>Search Brand</div>}
                            options={brandOptions}
                            styles={selectStylesLittleRect}
                            isSearchable={true}
                            onChange={onChangeBrand}
                        />
                    </Col>
                    <Col xs={2}>
                        <Select
                            isClearable
                            className='search_bar select_btn'
                            placeholder={<div className="text-left">Select Status</div>}
                            options={[
                                {
                                    value: BrandInvitationStatus.sent,
                                    label: 'OutStanding',
                                },
                                {
                                    value: BrandInvitationStatus.rejected,
                                    label: 'Declined',
                                },
                                {
                                    value: BrandInvitationStatus.pending,
                                    label: 'Pending',
                                }
                            ]}
                            styles={selectStyles}
                            isSearchable={false}
                            onChange={onChangeBrandStatus}
                        />
                    </Col>
                    <Col xs={8} className="text-right">
                        <Button className='btn-design' id='routing-button' onClick={() => { setOpenModal(true); setEmail(''); setName(''); setBrandName(''); }} ><b>Invite New Brand</b></Button>
                    </Col>
                </Row>

            </>}
            <div style={{ marginTop: '40px' }}>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    striped={true}
                    customStyles={tableStyles}
                    theme='mysaTable'
                    fixedHeader={true}
                    noDataComponent={'Updating records'}
                />
            </div>

            <Modal
                show={openModal}
                onHide={() => { setOpenModal(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onInvite)}>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                        <Form.Label><span className='requiredMark'>*</span> Brand name</Form.Label>
                                    </Col>
                                    <Col md={8} style={{ textAlign: 'left' }}>
                                        <Form.Control className={`${errors.brandName ? 'is-invalid' : ''}`} defaultValue={brandName} {...register("brandName", { required: true })} onChange={(e) => setBrandName(e.target.value)} />
                                        <div className="invalid-feedback">Please input brand name!</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                        <Form.Label><span className='requiredMark'>*</span> Name</Form.Label>
                                    </Col>
                                    <Col md={8} style={{ textAlign: 'left' }}>
                                        <Form.Control className={`${errors.name ? 'is-invalid' : ''}`} defaultValue={name} {...register("name", { required: true })} onChange={(e) => setName(e.target.value)} />
                                        <div className="invalid-feedback">Please input name!</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                        <Form.Label><span className='requiredMark'>*</span> Email</Form.Label>
                                    </Col>
                                    <Col md={8} style={{ textAlign: 'left' }}>
                                        <Form.Control className={`${errors.email ? 'is-invalid' : ''}`} defaultValue={email} {...register("email", { required: true })} onChange={(e) => setEmail(e.target.value)} />
                                        <div className="invalid-feedback">Please input email!</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace '>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={8} >
                                        <div className='btn_align'>
                                        {
                                            !loading && <>
                                                <Button id='approve-button' style={formButton} onClick={() => setOpenModal(false)}>Cancel</Button>
                                                <Button type="submit" id='edit-button' style={{
                                                    height: "40px",
                                                    width: '160px',
                                                    borderRadius: 30,
                                                    paddingLeft: "30px",
                                                    paddingRight: "30px",
                                                    marginRight: '20px',
                                                    marginBottom: '30px'
                                                }}>Invite</Button></>
                                           
                                        }
                                        {
                                            loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                        }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <SweetAlert
                show={archivePopop}
                warning
                showCancel={true}
                focusCancelBtn={true}
                confirmBtnText="Yes archive it!"
                confirmBtnBsStyle="danger"
                title="Are you sure you want to archive this invitation?"
                onConfirm={() => {
                    onArchive();
                    setArchivePopop(false);
                }}
                onCancel={() => {
                    setArchivePopop(false);
                }}
            >
                You will not be able to recover this brand!
            </SweetAlert>
        </>
    );
};

export default BrandInvitationList;