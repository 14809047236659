/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import {call} from '../common/axios';

export default {
  brandList: null,
  brandListError: null,
  roleUsersList: null,
  roleUsersListError: null,

  // actions
  setBrandList: action((state, payload) => ({ brandList: payload })),
  setBrandListError: action((state, payload) => ({ brandListError: payload })),
  setRoleUsersList: action((state, payload) => ({ roleUsersList: payload })),
  setRoleUsersListError: action((state, payload) => ({ roleUsersListError: payload })),

  // thunks
  loadBrandList: thunk(async (actions: any, payload:any) => {
    try {
      const result = await call('get', '/brand-sv/brand/list', null, null);
      actions.setBrandList(result.data);
    } catch (error:any) {
      actions.setBrandListError(error.response.data);
    }
  }),

  loadRoleUsersList: thunk(async (actions: any, payload:any) => {
    try {
      const result = await call('get', '/user-sv/users/by-role', null, payload);
      actions.setRoleUsersList(result.data);
    } catch (error:any) {
      actions.setRoleUsersList(error.response.data);
    }
  }),

}