

import React, { useState } from 'react';

import {Col, Nav, Row, Tab } from 'react-bootstrap';

import { navContentStyle, navStyle, tabDivStyle } from '../../../common/components-style';
import ProgrammeDetailsForm from './ProgrammeDetailsForm';
import { getStorage } from '../../../common/storage';
import ProgrammePermission from './ProgrammePermissions';
import PaymentPermission from './PaymentPermission';


const ProgrammeHomePage: React.FC = (): JSX.Element => {

    const [tabKey, setTabKey] = useState<any>('detail');
    const programmeId = getStorage('programmeId');

    const selectedClient = getStorage('programmeData')?.clientId;


    return (
        <div style={tabDivStyle}>
            <Tab.Container defaultActiveKey="detail" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                <Row >
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                            <div style={{ marginTop: '0%' }}>
                                <Nav.Item>
                                    <Nav.Link eventKey="detail">Details</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="permission">Programme permissions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="payment">Payment Management</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="activation">Programme Activation</Nav.Link>
                                </Nav.Item>
                            </div>
                        </Nav>
                    </Col>


                    <Col sm={10}>
                        <Tab.Content >
                            <Tab.Pane eventKey="detail" >
                                <div style={navContentStyle}>
                                    {/* {selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refershProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
										
									</Breadcrumb>} */}
                                    {<ProgrammeDetailsForm programmeId={programmeId} selectedClient={selectedClient} />}
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="permission" >
								<div style={navContentStyle}>
									{/* {selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
									</Breadcrumb>} */}
									{programmeId && <ProgrammePermission programmeId={programmeId} selectedClient={selectedClient} />}
								</div>
							</Tab.Pane>

                            <Tab.Pane eventKey="payment" >
								<div style={navContentStyle}>
									{/* {selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
									</Breadcrumb>} */}
									{programmeId && <PaymentPermission programmeId={programmeId} selectedClient={selectedClient} />}
								</div>
							</Tab.Pane>
                            <Tab.Pane eventKey="activation" >
								<div style={navContentStyle}>
									{/* {selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
									</Breadcrumb>} */}
									{/* {programmeId && <ProgrammeActivation programmeId={programmeId} selectedClient={selectedClient} />} */}
								</div>
							</Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
};

export default ProgrammeHomePage;