import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../../../../images/Circle.svg';
import { ReactComponent as Completed } from '../../../../../images/Approved.svg';
import _ from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';

const AuditSection: React.FC<any> = ({ values, surveyId = "", onSectionClick, index, className, selected, sectionId }): JSX.Element => {
  const [isCompleted, setIsCompleted] = useState<any>(false);
  const [answerCount, setAnswerCount] = useState<number>(0);
  const [questionCount, setQuestionCount] = useState<number>(0);
  const [isSectionComplete, setIsSectionComplete] = useState<any>(false);
  const [isViewed, setIsViewed] = useState<any>(false);

  const getAuditSurveys = useStoreActions<any>((actions) => actions.myoManagementModel.getAuditSurveys);
  const getAuditSurveysSuccess = useStoreState<any>((state) => state.myoManagementModel.getAuditSurveysSuccess);

  useEffect(() => {
    if (surveyId) {
      getAuditSurveys({
        security: {
          apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
          user: "superadmin@mysa.global"
        },
        certificatesurveyid: surveyId
      });
    }

  }, [getAuditSurveys, surveyId])

  useEffect(() => {
    if (getAuditSurveysSuccess) {
      const sectionData = _.filter(getAuditSurveysSuccess.certificateSurvey.survey.sections, (survey) => survey.id === values.id).shift();
      const isFilled = _.filter(sectionData.questions, q => !q.answer).length;
      setIsCompleted(isFilled === 0)
      let question = 0;
      let answer = 0;
      sectionData.questions.forEach(q => {
        question++;

         if (q.answer !== null) {
          ++answer;
        }
      });

      setAnswerCount(answer);
      setQuestionCount(question);
      const dataGen = getAuditSurveysSuccess.certificateSurvey.survey.sections[0];
      const secData = _.filter(dataGen.questions, q => !q.answer).length;
      let completed: boolean = false;
      completed = (secData !== 0);
      if ((index === 1 && completed) || (index === 2 && completed)) {
        setIsSectionComplete(true);
      } else {
        setIsSectionComplete(false);
      }
      const section = _.filter(getAuditSurveysSuccess.certificateSurvey.survey.sections, (survey) => survey.id === sectionId).shift();
      if (sectionId) {
        if ((index === 0 && section.name === 'General Information' && selected)) {
          setIsViewed(true);
        } else if (index === 1 && section.name === 'Brand Information' && selected) {
          setIsViewed(true);
        } else if (index === 2 && section.name === 'Property Information' && selected) {
          setIsViewed(true);
        } else {
          setIsViewed(false);
        }
      }
    }
  }, [getAuditSurveysSuccess, index, sectionId, selected, values])

  return (

    <div className='inline-block  mx-3 my-4 ' >
      <div onClick={() => isSectionComplete ? '' : onSectionClick(values, index)} className={isSectionComplete ? `audit-certificate flex items-center shadow-xl p-3 rounded-2xl disabled cursor-not-allowed ${className}` : `audit-certificate flex items-center shadow-xl p-3 rounded-2xl cursor-pointer  ${className}`} style={isViewed ? { width: '350px', backgroundColor: 'rgb(186 230 253)' } : { width: '350px' }} >
        <div className="mr-5">
          {!isCompleted && <Logo width="36px" height="36px" fill="#05B3ED" />}
          {isCompleted && <Completed width="36px" height="36px" fill="#05d1c1" />}
        </div>
        <div>
          <p className="text-xl">{values.name}</p>
          <p className="text-blue-500 font-bold">({answerCount} / {questionCount})</p>
        </div>
      </div>
    </div>
  )
};

export default AuditSection;