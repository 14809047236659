import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Breadcrumb, Button } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { EnvironmentOutlined } from "@ant-design/icons";
import { formButton4, formButton5, } from '../../../../../common/components-style';
import { useHistory, } from 'react-router-dom';
import { Menu } from 'antd';
import Select from "react-select";



import ProgrammeSinglePropertyView from './ProgrammeSingleProperty';
import { formatPhoneNumber, isBooker, isProgramAdmin, isTraveller } from '../../../../../common/functions';
import ProgrammeRatesListView from './ProgrammeRatesLsit';
import ProgrammeAuditView from './ProgrammeAuditView';
import SustainabilityView from './SustainabilityView';
import { BrandRfpPropertyEvaluationStatus } from '../../../../../common/constants';


const statusList = [{ label: (<b>{`Live`}</b>), value: 'activated' },
{ label: (<b>{`Offline`}</b>), value: 'accepted', },
{ label: (<b>{`Archive`}</b>), value: 'archive', },
]

const ProgrammePropertyDetailsToAccess: React.FC<any> = ({ propertyId, rfpId, data, tableData }): JSX.Element => {


    ///////////////////////////////////////// local state manage /////////////////////////////////////////
    const [menuKey, setMenuKey] = useState<any>('property');
    const [property, setProperty] = useState<any>();
    const [amenitiesList, setAmenitiesList] = useState<any>();
    const [enableRatesScreen, setEnableRatesScreen] = useState<boolean>(false);
    const [rfpProperty, setRfpProperty] = useState<any>();
    const [inventoryRates, setInventoryRates] = useState<any>([]);

    const [rates, setRates] = useState<any>('');
    const [status, setStatus] = useState<any>(data);
    // const [id, setId] = useState<any>(propertyId);
    // const [rfpProcessId, setRfpProcessId] = useState<any>(rfpId);
    const [statusName, setStatusName] = useState<any>();

    ////////////////// url manage ///////////

    let history = useHistory();

    const selectStyles = {
        control: (base, state) => ({
            ...base,
            // background: "#FBC91D",
            height: "40px",
            width: '180px',
            textAlign: "center",
            // paddingLeft: "10px",
            color: "#212121",
            borderRadius: 100,
            borderColor: "#FBC91D",
            boxShadow: '0 3px 10px #fac90e !important',

            "&:hover": {
                borderColor: "#FBC91D",
            },
        }),
        menu: (base) => ({
            ...base,
            hyphens: "auto",
            marginTop: 0,
            borderRadius: "14px",
            textAlign: "center",
            zIndex: 3
        }),
        menuList: (base) => ({
            ...base,
            padding: "10px",
            color: "#000000",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#000000"
        })
    };
    /////////////////////////////////// global action manage ////////////////////////////////////////////
    const { propertyDetailed, getAmenityList,
        getPropertyInventoryRatesForRFP, acceptPropertyForRFP } = useStoreActions<any>(
            (actions) => ({
                propertyDetailed: actions.property.propertyDetailed,
                getAmenityList: actions.property.getAmenityList,
                acceptPropertyRateForRFP: actions.rfpProcess.acceptPropertyRateForRFP,
                declinePropertyRateForRFP: actions.rfpProcess.declinePropertyRateForRFP,
                // propertyInventoryRates: actions.rfpProcess.propertyInventoryRates,
                getPropertyInventoryRatesForRFP: actions.rfpProcess.getPropertyInventoryRatesForRFP,
                acceptPropertyForRFP: actions.rfpProcess.acceptPropertyForRFP,


            })
        );

    /////////////////////////////////// global state manage ////////////////////////////////////////////
    const { getPropertyDetailedSuccess, getAmenityListSuccess, getPropertyInventoryRatesForRFPSuccess, acceptPropertyForRFPSuccess
    } = useStoreState<any>(
        (state) => ({
            getPropertyDetailedSuccess: state.property.getPropertyDetailedSuccess,
            getAmenityListSuccess: state.property.getAmenityListSuccess,
            getPropertyInventoryRatesForRFPSuccess: state.rfpProcess.getPropertyInventoryRatesForRFPSuccess,
            acceptPropertyForRFPSuccess: state.rfpProcess.acceptPropertyForRFPSuccess,

        })
    );



    const changeStatus = (value) => {
        setStatusName(value.value);
        let text = "";
        if (value.value === BrandRfpPropertyEvaluationStatus.ACCEPTED) {

            text = "Offline";
        }
        if (value.value === BrandRfpPropertyEvaluationStatus.ACTIVATED) {
            text = 'Live';

        }

        if (value.value === BrandRfpPropertyEvaluationStatus.ARCHIVE) {
            text = 'Archive';

        }
        const type = {
            value: value.value,
            label: (<b>{text}</b>)
        }
        setStatus(type);
    }
    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (propertyId) {
            const payload = {
                propertyId: propertyId
            }
            propertyDetailed(payload);
            getAmenityList();
        }
    }, [getAmenityList, propertyDetailed, propertyId]);

    ////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        const params = {
            brandId: property?.brandId,
            propertyId: propertyId,
            rfpProcessId: rfpId,
            cityName: property?.city
        }
        if (property?.brandId) {
            getPropertyInventoryRatesForRFP(params);
        }


    }, [getPropertyInventoryRatesForRFP, property, property?.brandId, property?.city, propertyId, rfpId])

    const redirectProperty = useCallback(() => {
        history.push({
            pathname: `/admin/corporate-programme-management`,
            state: {
                cityName: property?.city,
                rfpId: rfpId,
            }
        })
        // history.push('/admin/property-management', { brandId: brandId })
    }, [history, property?.city, rfpId])

    ////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (acceptPropertyForRFPSuccess) {
            // toast.success("Property status updated successfully", {
            // 	position: toast.POSITION.BOTTOM_RIGHT,
            // 	className: 'foo-bar'
            // });
            redirectProperty();
        }

    }, [acceptPropertyForRFPSuccess, redirectProperty]);
    useEffect(() => {
        // if (getPropertyInventoryRatesSuccess) {
        //     setInventoryRates(getPropertyInventoryRatesSuccess.data?.property?.inventory);
        // }
        if (getPropertyInventoryRatesForRFPSuccess) {
            setInventoryRates(getPropertyInventoryRatesForRFPSuccess.data?.inventories);
            setRfpProperty(getPropertyInventoryRatesForRFPSuccess.data);
        }
    }, [getPropertyInventoryRatesForRFPSuccess])
    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertyDetailedSuccess) {

            setProperty(getPropertyDetailedSuccess.data);
        }

        if (getAmenityListSuccess) {
            setAmenitiesList(getAmenityListSuccess.data);
        }

    }, [getAmenityListSuccess, getPropertyDetailedSuccess]);

    ///////////////////////// menu change ///////////////////////////////////
    const menuChange = useCallback((value) => {
        setMenuKey(value.key);
    }, []);

    const selectStatusRender = useMemo(() => {
        return (
            <>
                <Select
                    styles={selectStyles}
                    options={statusList}
                    isDisabled={(isProgramAdmin()) ? false : true}
                    // placeholder="All Countries"
                    onChange={changeStatus}
                    value={status}
                // className="new_drpdwn"
                />

            </>
        )
    }, [status])


    const redirectOperator = useCallback(() => {
        history.push({
            pathname: `/admin/corporate-programme-management`,
            state: {
                propertyId: property?._id,
                rfpId: rfpId,

                defaultActiveKey: 'operators'
            }
        })
        // history.push('/admin/property-management', { brandId: brandId })
    }, [history, property?._id, rfpId])


    const updateProperty = useCallback((data) => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brand?._id,
            propertyId: property?._id,
            propertyStatus: statusName,
            cityName: property?.city,

        }
        acceptPropertyForRFP(payload);
    }, [acceptPropertyForRFP, property?._id, property?.brand?._id, property?.city, rfpId, statusName]);


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <Row style={{ marginTop: '-30px' }}>
                {/* <Col sm={2}>

                </Col> */}
                <Col sm={12}>
                    {/* <Tab.Content > */}


                    <Row>
                        <Col md={6}>
                            <Breadcrumb className='bc_unset'>
                                <Breadcrumb.Item style={{ fontSize: '24px' }} onClick={() => {
                                    history.push({
                                        pathname: `/admin/corporate-programme-management`,
                                        state: {
                                            cityName: property?.city,
                                            rfpId: rfpId,
                                            filterProgramme: true,
                                            //   defaultActiveKey: 'propertyAssessment'
                                        }
                                    })
                                }}>{'All property'}</Breadcrumb.Item>
                                <Breadcrumb.Item style={{ fontSize: '24px', cursor: 'pointer' }} active onClick={() => {
                                    setEnableRatesScreen(false);
                                }}>{property?.propertyName} - {property?.city} by {property?.brand?.name}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>


                        <Col md={3} style={{ marginLeft: '300px' }} >
                            {selectStatusRender}
                        </Col>
                        {(isProgramAdmin()) &&

                            <Col md={2} className='edit_btn_wpr2' style={{ marginLeft: '-180px' }} >
                                <Button

                                    type="submit"
                                    id="approve-button"
                                    style={formButton4}
                                    onClick={updateProperty}
                                >
                                    Update
                                </Button>

                            </Col>}

                    </Row>
                    <Row >
                        <Col md={12}>
                            <div className='icn_txt' style={{ marginTop: '-5px' }}><EnvironmentOutlined /> {`${property?.address1} ${property?.address2}, ${property?.city} - ${property?.postcode}`}</div>
                            <div className='icn_txt' style={{ marginTop: '-5px', marginLeft: '5px' }}> T: {formatPhoneNumber(property?.telephone)}</div>
                            <div className='icn_txt' style={{ marginTop: '5px', marginLeft: '5px' }}>
                                <Button
                                    type="submit"
                                    id="approve-button"
                                    style={formButton5}
                                    onClick={redirectOperator}
                                >
                                    View Operator
                                </Button>
                            </div>
                        </Col>
                    </Row>




                    {
                        !enableRatesScreen && <div>


                            <div className='mt-4'>
                                <Menu onClick={menuChange} mode="horizontal" selectedKeys={[menuKey]} className="w-full new_tab ">
                                    <Menu.Item key="property" >
                                        <span>Property</span>
                                    </Menu.Item>
                                    <Menu.Item key="rates" >
                                        <span>Rates</span>
                                    </Menu.Item>
                                    <Menu.Item key="audit" >
                                        <span>Audit</span>
                                    </Menu.Item>
                                    <Menu.Item key="sustainability" >
                                        <span>Sustainability</span>
                                    </Menu.Item>
                                </Menu>
                            </div>
                            <div className="new_tab_ctnt new_tab_ctnt2" style={{ width: '1475px' }} >
                                <div className='w-full' >
                                    {(menuKey === 'property') && <ProgrammeSinglePropertyView property={property} amenitiesList={amenitiesList} />}
                                    {(menuKey === 'rates') && <ProgrammeRatesListView setRates={setRates} setEnableRatesScreen={setEnableRatesScreen} inventoryRates={inventoryRates} property={property} />}
                                    {(menuKey === 'audit') && <ProgrammeAuditView property={property} />}
                                    {(menuKey === 'sustainability') && <SustainabilityView propertyId={propertyId}></SustainabilityView>}

                                </div>
                            </div>
                        </div>
                    }



                    {/* </Tab.Content> */}
                </Col>
            </Row>

        </>
    );
}

export default ProgrammePropertyDetailsToAccess;