import React from "react";
import { Form } from "react-bootstrap";

type TInputC = {
    name: string;
    register?: any;
    onKeyUp?: (e: React.ChangeEvent<HTMLInputElement>) => void
};

const InputC: React.FunctionComponent<TInputC> = ({ register, name, onKeyUp }) => {

    return (<>
        <Form.Control
            type="text"
            placeholder="Last Name or Email"
            {...register && {...register(`${name}`, { required: false })}}
            onKeyUp={onKeyUp}
        />
    </>);
}

export default InputC;