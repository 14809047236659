import React, { useCallback, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getStorage } from '../../../common/storage';
import LocationList from './LocationList';
import LocationForm from './form/LocationForm';
import { toast } from 'react-toastify';
import { Breadcrumb, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { $mysaBlack, loginButton } from '../../../common/components-style';

const ButtonStyle = {
	...loginButton,
	color: $mysaBlack,
	fontWeight: 'bold'
} as React.CSSProperties;

const LocationHome: React.FC<any> = ({ location }): JSX.Element => {
	const [formOpen, setFormOpen] = useState<boolean>(false);
	const [locations, setLocations] = useState<Array<Object> | null>([]);
	const [locationId, setLocationId] = useState<any>(null);
	const [selectedLocation, setSelectedLocation] = useState<any>(null);
	const [selectedProgramme, setSelectedProgramme] = useState<any>(null);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { getClientLocationByProgrammeId } = useStoreActions<any>((actions) => ({
		getClientLocationByProgrammeId: actions.client.getClientLocationByProgrammeId,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { getClientLocationByProgrammeIdSuccess, getClientLocationByProgrammeIdError } = useStoreState<any>((state) => ({
		getClientLocationByProgrammeIdSuccess: state.client.getClientLocationByProgrammeIdSuccess,
		getClientLocationByProgrammeIdError: state.client.getClientLocationByProgrammeIdError,
	}));

	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		const programme = getStorage('myo_selected_programme');
		setSelectedProgramme(programme);
	}, [])


	////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadForm = useCallback((data) => {
		setFormOpen(true);
		if (data) {
			setLocationId(data.key);
		}
	}, []);

	const reload = useCallback(() => {
		setTimeout(() => getClientLocationByProgrammeId(location.state?.programmeId ? location.state?.programmeId : location.state?.selectedProgramme.key), 1000);
		setFormOpen(false);
	}, [getClientLocationByProgrammeId, location])

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (!location.state?.selectedLocation) {
			if (location.state?.programmeId) {
				getClientLocationByProgrammeId(location.state?.programmeId);
			}
		}
	}, [getClientLocationByProgrammeId, location]);


	///////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (location.state?.setSelectedLocation) {
			loadForm(location.state?.setSelectedLocation);
			setSelectedLocation(location.state?.setSelectedLocation);
		}
	}, [loadForm, location])

	//////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getClientLocationByProgrammeIdSuccess) {
			setLocations(getClientLocationByProgrammeIdSuccess.data);
		}
		if (getClientLocationByProgrammeIdError) {
			toast.success(getClientLocationByProgrammeIdError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

	}, [location, getClientLocationByProgrammeIdSuccess, getClientLocationByProgrammeIdError])


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const refreshLocations = useCallback(() => {
		if (location.state?.programmeId) {
			getClientLocationByProgrammeId(location.state?.programmeId);
		}
		if (location.state?.selectedProgramme) {
			getClientLocationByProgrammeId(location.state?.selectedProgramme.key);
		}

	}, [getClientLocationByProgrammeId, location]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (

		<div className="programme-home">
			<div className="flex justify-between">
				{!selectedProgramme && <p className="text-2xl mb-10"></p>}
				{selectedProgramme && <Breadcrumb className="mb-10" hidden={formOpen}>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/client-management", state: { selectedClient: location.state?.selectedClient } }}>Client Management</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/programme-management", state: { selectedProgramme: selectedProgramme, isBack: true } }}>{location.state?.selectedClient.companyName}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/programme-management", state: { selectedProgramme: selectedProgramme } }}>{selectedProgramme?.programmeName}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item active>
						<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshLocations(); setSelectedLocation(null) }}>Location Management</Link>
					</Breadcrumb.Item>
				</Breadcrumb>}
				<Button style={ButtonStyle} className='btn_design m-1' hidden={formOpen} onClick={() => { setFormOpen(true); setLocationId(null) }} >Create New Location</Button>
			</div>
			{!formOpen && <LocationList loadForm={loadForm} locations={locations} onLocationSelect={(data) => { setSelectedLocation(data) }} />}
			{formOpen && <LocationForm selectedClient={location.state?.selectedClient} locationId={locationId} setFormOpen={setFormOpen} selectedProgramme={selectedProgramme} refreshLocations={refreshLocations} setSelectedLocation={setSelectedLocation} selectedLocation={selectedLocation} formOpen={formOpen} setLocationId={setLocationId} reload={reload} />}
		</div>
	)
};

export default LocationHome;