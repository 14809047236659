
import { Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getStorage } from '../../../common/storage';
import AvailabilityList from './AvailabilityList';

const AvailabilityHome: React.FC<any> = ({ location }): JSX.Element => {
	const [selectedInventory, setSelectedInventory] = useState<any>(null);
	const [allInOnePropertyBreadCrumb, setAllInOnePropertyBreadCrumb] = useState<boolean>(false);
	/**
	 * special navigate 
	 */
	useEffect(() => {
		if (location.state?.allInOnePropertyBreadCrumb) {
			setAllInOnePropertyBreadCrumb(location.state?.allInOnePropertyBreadCrumb);
		}
	}, [location.state?.allInOnePropertyBreadCrumb]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		const inventory = getStorage('myo_selected_inventory');
		setSelectedInventory(inventory);
	}, [])
	return (
		<div className="property-form">
			<div className="flex justify-between">
				{selectedInventory && <Breadcrumb className="mb-10 breadcrumb" >
					{/* <Breadcrumb.Item>
						<Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: location.state?.selectedBrand } }}>Brand Management</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
					<Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: location.state?.selectedBrand } }}>{location.state?.selectedBrand.name}</Link>
					</Breadcrumb.Item> */}
					<Breadcrumb.Item className='breadcrumb-item'>
						{
							allInOnePropertyBreadCrumb ? <Link to={{ pathname: "/admin/brand-property-management" }}>Property Management</Link> : <Link to={{ pathname: "/admin/property-management", state: { selectedProperty: location.state?.selectedProperty, isBack: true } }}>Property Management</Link>
						}

					</Breadcrumb.Item>
					<Breadcrumb.Item className='breadcrumb-item'>
						<Link to={{ pathname: "/admin/property-management", state: { allInOnePropertyBreadCrumb : allInOnePropertyBreadCrumb, selectedProperty: location.state?.selectedProperty } }}>
							{location.state?.selectedProperty.propertyName} - {location.state?.selectedProperty.city}
						</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item className='breadcrumb-item'>
						<Link to={{ pathname: "/admin/inventory-management", state: { allInOnePropertyBreadCrumb : allInOnePropertyBreadCrumb, selectedInventory: null, propertyId: location.state?.selectedProperty._id, selectedBrand: location.state?.selectedBrand } }}>Inventory Management</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item className='breadcrumb-item active'>
						<b><Link to={{ pathname: "/admin/inventory-management", state: { allInOnePropertyBreadCrumb : allInOnePropertyBreadCrumb, selectedInventory: selectedInventory, selectedBrand: location.state?.selectedBrand } }}>{selectedInventory?.moderate?.details?.new_value ? selectedInventory?.moderate?.details?.new_value : selectedInventory?.accommodationDetail}</Link></b>
					</Breadcrumb.Item>
					{/* <Breadcrumb.Item>Availability Management</Breadcrumb.Item> */}
				</Breadcrumb>}
			</div>
			<AvailabilityList selectedProperty={location.state?.selectedProperty} selectedInventory={selectedInventory} />
		</div>
	)
};

export default AvailabilityHome;