import ReactDOM from 'react-dom';
import App from './App';
import { StoreProvider } from 'easy-peasy';
import store from './store';
import './css/index.scss';
import './css/index.css';
import { MyoProvider } from './context';
import { ToastContainer } from 'react-toastify';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

ReactDOM.render(
  <StoreProvider store={store}>
    <MyoProvider>
    <App />
    <ToastContainer theme='dark'/>
    </MyoProvider>
  </StoreProvider >,
  document.getElementById('root')
);
