import { Row, Col, Container, Form, Breadcrumb } from 'react-bootstrap';
import { FC, } from "react";
import moment from "moment";
import { Link } from 'react-router-dom';

const SubmittedBrandAdminBrandDetailPage: FC<any> = ({ programmeData, data }): JSX.Element => {
    return (

        <>
            <Container >
                <Row style={{
                    marginBottom: '1.5rem',
                    marginTop: '20px',
                }} >
                    <Col xs={12}><div className="content-title">
                        <Breadcrumb>
                            <Breadcrumb.Item style={{
                                fontWeight: 100,
                            }} >
          
                                <Link to={{ pathname: "/admin/brand-rfp-management", state: { selectedBrandId: programmeData?.brandId, defaultKey: 'submittedRFPs' } }}>{'SubmittedRFPs'}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {programmeData?.companyName}{' - '}{programmeData?.programmeName} {' - '} {moment(programmeData?.startDate).format('DD MMM YYYY')}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div></Col>
                </Row>

                <div className="jumbotron">
                    <Form>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                    <Form.Label>Introduction</Form.Label>
                                </Col>
                                <Col md={9} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data && data?.overviewForInterview}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                    <Form.Label>Company name</Form.Label>
                                </Col>
                                <Col md={9} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data && data?.companyName}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                    <Form.Label>Name of the programme</Form.Label>
                                </Col>
                                <Col md={9} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data && data?.programmeName}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                    <Form.Label>Start Date</Form.Label>
                                </Col>
                                <Col md={9} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data && moment(data.startDate).format("D MMM YYYY")}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                    <Form.Label>End Date</Form.Label>
                                </Col>
                                <Col md={9} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data && moment(data?.endDate).format("D MMM YYYY")}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                    <Form.Label>Question submit date</Form.Label>
                                </Col>
                                <Col md={9} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data && moment(data?.questionSubmitDate).format("D MMM YYYY")}</p>
                                </Col>
                            </Row>


                        </Col>
                    </Form>
                </div>

            </Container>
        </>
    )
};

export default SubmittedBrandAdminBrandDetailPage;