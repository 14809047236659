import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Row, Container, Image, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import bookingdetails from "../../../../images/icons/svg/bookingdetails.svg";
import moment from "moment-timezone";


import momentTimezone from "moment-timezone";

import { daysBetween, isBooker } from "../../../../common/functions";
import { $mysaTeal, $mysaYellow, formButton, slideImageStyle } from "../../../../common/components-style";
import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { travelerOptionForm } from "../../../../common/interfaces";
import { toast } from "react-toastify";


const TravelerOptionsTravelerView: React.FC<any> = ({ details }) => {

    /**
     * redirect hooks
     */
    const { push } = useHistory();



    /**
* moment
*/

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue,
        register
    } = useForm<travelerOptionForm>();

    /**
     * local state management
     */
    const history = useHistory();


    const [bookingList, setBookingList] = useState<any>();
    const [imageList, setImageList] = useState<any>();
    /**
     * react life cycle 
     */



    const {

        getFreshAvailabilityAndRateByPropertyIdAndInventoryId,
        updateTravelerOptionMessageThread
    } = useStoreActions<any>((actions) => ({
        getFreshAvailabilityAndRateByPropertyIdAndInventoryId: actions.booking.getFreshAvailabilityAndRateByPropertyIdAndInventoryId,
        updateTravelerOptionMessageThread: actions.booking.updateTravelerOptionMessageThread
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess,
        getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError,
        updateTravelerOptionMessageThreadSuccess,
        updateTravelerOptionMessageThreadError
    } = useStoreState<any>((state) => ({
        getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess: state.booking.getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess,
        getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError: state.booking.getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError,
        updateTravelerOptionMessageThreadSuccess: state.booking.updateTravelerOptionMessageThreadSuccess,
        updateTravelerOptionMessageThreadError: state.booking.updateTravelerOptionMessageThreadError
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (details) {
            const data: any = {};

            data.mPropertyId = details?.propertyId?._id;
            data.inventoryId = details?.inventoryId?._id;
            data.checkInDate = moment(new Date(details?.checkInDate)).format('YYYY-MM-DD');
            data.checkOutDate = moment(new Date(details?.checkOutDate)).format('YYYY-MM-DD');

            getFreshAvailabilityAndRateByPropertyIdAndInventoryId(data);
        }


    }, [details, getFreshAvailabilityAndRateByPropertyIdAndInventoryId]);

    //////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess) {
            if (getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess?.data) {
                const book: Array<object> = [];
                const image: Array<object> = [];
                const bed: Array<object> = [];
                const amenity: Array<object> = [];
                book.push(getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess?.data);

                _.map(getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyImage.image, element => {
                    image.push(element)
                })
                _.map(getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyInventory.bedType, element => {
                    bed.push(element.name)
                });
                if (getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyAmenity) {
                    _.map(getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyAmenity.amenity, element => {
                        amenity.push(element)
                        // setShowAmenity(true);
                        // setAmenityList(amenity);
                    });
                }
                setBookingList(getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess?.data);
                setImageList(image);
            }
        }
        if (getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError) {
            //message.error(getAvailabilityAndRateByPropertyIdAndInventoryIdError.message);
        }
    }, [getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess, getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError]);

    const BackToList = useCallback(() => {
        history.push('/admin/traveler-booking-reports', { tabKey: 'options' })

    }, [history])

    useEffect(() => {
        if (updateTravelerOptionMessageThreadSuccess) {
            toast.success('Traveler option updated successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            BackToList();
        }
        toast.error(updateTravelerOptionMessageThreadError?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
        });

    }, [BackToList, updateTravelerOptionMessageThreadError?.message, updateTravelerOptionMessageThreadSuccess]);


    const onSubmitOption = useCallback((values) => {

        let status;
        if (values?.isSuitable === 'suitable') {
            status = 'approved'
        } else {
            status = 'declined'
        }
        const payload = {
            _id: details?.key,
            propertyId: details?.propertyId?._id,
            direction: 'traveler_to_booker',
            messageText: values.message,
            status: status,
        }

        updateTravelerOptionMessageThread(payload);
    }, [details?.key, details?.propertyId, updateTravelerOptionMessageThread]);

    return (<>
        <Row >
            <Col md={6}>

                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/traveler-booking-reports", state: { tabKey: 'options' } }} >Options</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{bookingList?.property?.propertyName} by {bookingList?.property?.brandId?.name}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>

        </Row>
        <Row className="mt-5">
            <Col md={6} xs={12} className="text-center">

                <Image width={300} src={bookingList?.propertyImage?.image[0]?.url} alt={bookingList?.propertyImage?.image[0]?.url} thumbnail={true} style={slideImageStyle}>
                </Image>

                {/* <img width={'100%'} src={singleBooking?.inventoryInfo?.inventoryImage} alt={singleBooking?.inventoryInfo?.inventoryImage} style={{ borderRadius: 40, height: '70%' }} /> */}
            </Col>
            <Col md={6} xs={12}>
                <Container fluid className="p-0">
                    <div className="jumbotron" style={{ marginTop: "0px" }}>
                        <div className="text-center ">
                            {/* <h1>Booking Cancelled</h1> */}
                            <div style={{ fontSize: '20px', marginTop: 35 }}>
                                <Row>


                                    <Form onSubmit={handleSubmit(onSubmitOption)}  >
                                        <Row>
                                            {/* <Col md={1}></Col> */}
                                            <Col md={8} style={{ textAlign: 'left', fontWeight: 'bold' }}>


                                                <Row className='formSpace'>

                                                    <Col md={12} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                                        {/* <Col md={8} style={{ textAlign: 'left' }}> */}

                                                        {/* <Form.Check type="radio" value={'booker'} label="Booker" {...register("userType")} /> */}
                                                        <Form.Check type="radio"  {...register("isSuitable")} value={'suitable'} label="This property is suitable" />
                                                        <Form.Check type="radio"  {...register("isSuitable")} value={'notSuitable'} label="This property is not suitable" />

                                                        {/* </Col> */}
                                                    </Col>
                                                    {/* <Col md={4}></Col> */}
                                                </Row>


                                                <Row className='formSpace'>
                                                    {/* <Col md={4} style={{ textAlign: 'right' }}>
                                      </Col> */}
                                                    <Col md={12} style={{ textAlign: 'left' }}>
                                                        <Form.Control as="textarea" {...register("message")}
                                                            rows={3} placeholder="Message" />
                                                        <div className="invalid-feedback">Please add message!</div>
                                                    </Col>
                                                </Row>


                                                <Row className='formSpace'>
                                                    {/* <Col md={4} style={{ textAlign: 'right' }}>
                                        </Col> */}
                                                    <Col md={12} style={{ textAlign: 'left' }}>
                                                        {
                                                            <Button type="submit" className="import" id="import-button" style={formButton} >Send</Button>

                                                        }

                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>
                                    </Form>
                                </Row>

                                <div>

                                </div>
                            </div>
                        </div>

                    </div>
                </Container>

            </Col>
        </Row >
        <Row style={{ marginTop: '-250px' }}>
            <Col md={6} xs={6} >
                <div>
                    <p style={{ fontSize: '26px', fontWeight: 'bold' }}>{bookingList?.property?.propertyName} {'by'}<Button id='approve-button' style={{ width: '190px', marginLeft: '15px' }} onClick={() => {
                        const param = {
                            data: bookingList,
                            image: imageList,
                            details: details,
                            isBookerPage: false
                        };
                        push("/admin/traveler-property-page", { query: param });
                    }} ><b>View Property</b>   </Button> </p>

                </div>
                <div>
                    <p style={{ fontSize: '22px', fontWeight: 'bold' }}>{bookingList?.property?.brandId?.name} </p>
                </div>
                <div>
                    <p style={{ fontSize: '20px' }}>{bookingList?.property?.address1} </p>
                </div>
                <div>
                    <p style={{ fontSize: '20px' }}>{details?.inventoryId?.accommodationDetail} </p>
                </div>
            </Col>

        </Row >

        <Row className="mt-5">
            <Col md={6} xs={6} >
                <div>
                    <p style={{ fontSize: '26px', fontWeight: 'bold' }}>{'Current Property Status:'} <span style={{ fontSize: '26px', fontWeight: 'bold', color: bookingList?.isAvailable === true ? $mysaTeal : $mysaYellow }}>{bookingList?.isAvailable === true ? 'Live Booking' : 'Request availability'} </span></p>
                </div>

            </Col>

        </Row >

        <Row className="mt-5">
            <Container fluid className="p-0">
                <div className="jumbotron" style={{ marginTop: "-20px" }}>
                    <div className="d-flex flex-row">
                        <div className="p-2">  <img width={'100%'} src={bookingdetails} alt={`option details`} /></div>
                        <div className="p-2" style={{ fontSize: '20px' }}> <strong> Option Details</strong>
                            {details?.status !== 'pending' && <strong style={{ marginLeft: "80px", color: '#2DBDB6' }}> {details?.status === 'approved' ? 'Approved' : details?.status === 'declined' ? 'Declined' : ''}</strong>}
                        </div>
                    </div>
                    <div className="mt-3">
                        <Row>
                            <Col md={6} xs={6}>
                                {/* <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>First Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{details?.travelerId?.firstName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Second Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{details?.travelerId?.lastName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Email Address</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{details?.travelerId?.email}</Col>
                                </Row> */}
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Date sent</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{moment(details?.createdAt).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Time sent</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{momentTimezone(details?.createdAt).format('HH:mm')}</Col>
                                </Row>
                            </Col>

                            <Col md={6} xs={6}>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in </strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{
                                        moment(details?.checkInDate).format('D MMM YYYY')
                                    }</Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-out </strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{moment(details?.checkOutDate).format('D MMM YYYY')
                                    }</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>No of Nights</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{daysBetween(new Date(bookingList?.checkIn), new Date(bookingList?.checkOut))}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Average Nightly Rate (ex Tax)</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{`${bookingList?.rateAndPolicy?.dailyRate[0]?.rate} (${bookingList?.property?.currency})`}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Total Rate (ex Tax)</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{`${bookingList?.rateAndPolicy?.finalRate} (${bookingList?.property?.currency})`}</Col>
                                </Row>





                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <Row style={{ marginTop: '10px' }}>
                <Col md={6}>
                    <Button id='edit-button' style={{ width: '190px', marginLeft: '15px' }} onClick={BackToList} >
                        <b>Close</b>
                    </Button>
                </Col>
                <Col md={6}>
                </Col>
            </Row>

            {/* <CancelBookingWithoutCharge cancelBoxDisplay={cancelBoxDisplay} ActionNo={BackToList} ActionYes={cancelPending} loading={loading} /> */}
        </Row >




    </>)

};

export default TravelerOptionsTravelerView;