import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import { useHistory, useLocation } from 'react-router-dom';
import { $mysaMegenta, $mysaRed, $mysaTeal, formButton, mysaTableTheme, overlayTriggerDelay, tableStyles } from '../../../../../common/components-style';
import { BrandRfpPropertyEvaluationStatus, CommonAnswer } from '../../../../../common/constants';
import { toast } from 'react-toastify';
import File from '../../../../../images/icons/svg/outside/file-icon.svg';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

/////////////////////////// table button style ///////////////////////////////////////////
// const tableButtonStyle = {
//   background: '#FBC91D',
//   border: '1px solid #FBC91D',
//   borderRadius: 15,
//   height: '40px',
//   paddingLeft: '20px',
//   paddingRight: '20px',
//   color: '#212121',
// }

const NegotiatedPropertyList: React.FC<any> = ({ rfpProcess, selectCityName }): JSX.Element => {
  //////////////////// local state management 
  const [tableData, setTableData] = useState<any>();
  const [oldCity, setOldCity] = useState<string>();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [propertyAcceptInformation, setPropertyAcceptInformation] = useState<any>();
  const [respondProperty, setRespondProperty] = useState<string>('');
  const [showComment, setShowComment] = useState<boolean>(false);
  const [propertyData, setPropertyData] = useState<any>();
  const [loadingMessage, setLoadingMessage] = useState<boolean>(true);
  const [propertyRespondedComment, setPropertyRespondedComment] = useState<any>();
  ////////// routing ///////

  const history = useHistory();
  const location = useLocation<any>();

  /////////////////////////////////// global action manage ////////////////////////////////////////////
  const { programmePropertyList, reviewPropertyForRFP, getPropertyAcceptDeclineOfRFPByPropertyId, respondCreateUpdatePropertyAcceptDeclineOfRFP } = useStoreActions<any>(
    (actions) => ({
      programmePropertyList:
        actions.rfpProcess.programmePropertyList,
      getPropertyAcceptDeclineOfRFPByPropertyId: actions.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyId,
      respondCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.respondCreateUpdatePropertyAcceptDeclineOfRFP,
      reviewPropertyForRFP: actions.rfpProcess.reviewPropertyForRFP,
    })
  );

  /////////////////////////////////// global state manage ////////////////////////////////////////////
  const { reviewPropertyForRFPSuccess, reviewPropertyForRFPError, getProgrammePropertyListSuccess, getPropertyAcceptDeclineOfRFPByPropertyIdSuccess, respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess, respondCreateUpdatePropertyAcceptDeclineOfRFPError } = useStoreState<any>(
    (state) => ({
      getProgrammePropertyListSuccess:
        state.rfpProcess.getProgrammePropertyListSuccess,
      getProgrammePropertyListError:
        state.rfpProcess.getProgrammePropertyListError,
      getPropertyAcceptDeclineOfRFPByPropertyIdSuccess: state.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,
      respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
      respondCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.respondCreateUpdatePropertyAcceptDeclineOfRFPError,
      reviewPropertyForRFPSuccess: state.rfpProcess.reviewPropertyForRFPSuccess,
      reviewPropertyForRFPError: state.rfpProcess.reviewPropertyForRFPError,
    })
  );


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const callApi = useCallback((data) => {
    setShowMessage(true);
    const payload = {
      rfpId: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
      propertyId: data?.propertyId
    }
    if (data?.propertyId) {
      setLoadingMessage(true);
      getPropertyAcceptDeclineOfRFPByPropertyId(payload)
    }
  }, [getPropertyAcceptDeclineOfRFPByPropertyId, location.state?.rfpId, rfpProcess?._id]);

  //////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getPropertyAcceptDeclineOfRFPByPropertyIdSuccess) {
      setLoadingMessage(false);
      setPropertyAcceptInformation(getPropertyAcceptDeclineOfRFPByPropertyIdSuccess?.data);
    }
  }, [getPropertyAcceptDeclineOfRFPByPropertyIdSuccess])

  /////////////////////////////////////////////////////////////////////////////////////////
  const callApiList = useCallback(() => {
    if (selectCityName) {
      const payload = {
        rfp_id: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
        city_name: selectCityName
      };
      // if (selectCityName !== oldCity) {
      programmePropertyList(payload);
      // setOldCity(selectCityName);
      // }
    }
  }, [location.state?.rfpId, programmePropertyList, rfpProcess?._id, selectCityName]);


  /////////////////////////add review comment//////////////////////////////////////////////////////////////
  useEffect(() => {
    if (respondCreateUpdatePropertyAcceptDeclineOfRFPError) {
      toast.error(respondCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

    if (respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {

      toast.success("Property respond added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });

      if (respondProperty === CommonAnswer.YES) {
        const payload = {
          rfpId: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
          brandId: propertyData?.property?.brandId?._id,
          propertyId: propertyData?.propertyId,
          propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
          cityName: propertyData?.property?.city,
          isClient: true
        }
        reviewPropertyForRFP(payload);
      } else {
        setShowMessage(false);
      }


    }
  }, [location.state?.rfpId, propertyData?.property?.brandId?._id, propertyData?.property?.city, propertyData?.propertyId, respondCreateUpdatePropertyAcceptDeclineOfRFPError, respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess, respondProperty, reviewPropertyForRFP, rfpProcess?._id]);

  /////////////////////////review property success//////////////////////////////////////////////////////////////
  useEffect(() => {
    if (reviewPropertyForRFPSuccess) {
      toast.success("Property review successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      setShowMessage(false);
      callApiList();
    }

    if (reviewPropertyForRFPError) {
      toast.error(reviewPropertyForRFPError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

  }, [callApiList, reviewPropertyForRFPError, reviewPropertyForRFPSuccess]);

  // load from state from url
  useEffect(() => {
    if (location.state?.cityName && location.state?.rfpId) {
      const payload = {
        rfp_id: location.state?.rfpId,
        city_name: location.state?.cityName
      };
      programmePropertyList(payload);
      setOldCity(location.state?.cityName);
    }
  }, [location.state?.cityName, location.state?.rfpId, programmePropertyList]);


  // load initialize
  useEffect(() => {
    if (selectCityName) {
      const payload = {
        rfp_id: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
        city_name: selectCityName
      };
      if (selectCityName !== oldCity) {
        programmePropertyList(payload);
        setOldCity(selectCityName);
      }
    }
  }, [location.state?.rfpId, oldCity, programmePropertyList, rfpProcess?._id, selectCityName]);



  // load initialize
  useEffect(() => {
    if (getProgrammePropertyListSuccess) {
      const data = _.filter(getProgrammePropertyListSuccess?.data, list => ((list.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW || list.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED)));
      setTableData(data);
    }
  }, [getProgrammePropertyListSuccess]);


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSaveResponse = useCallback((value) => {
    // console.log(respondProperty, 'respondProperty')
    const payload = {
      rfpId: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
      brandId: propertyData?.property?.brandId?._id,
      propertyId: propertyData?.propertyId,
      doYouWishToRespondProperty: value,
    }
    respondCreateUpdatePropertyAcceptDeclineOfRFP(payload);
  }, [respondCreateUpdatePropertyAcceptDeclineOfRFP, location.state?.rfpId, propertyData?.property?.brandId?._id, propertyData?.propertyId, rfpProcess?._id])


  const onSubmitResponse = useCallback((value) => {
    setRespondProperty(value);
    if (value === CommonAnswer.NO) {
      // setShowMessage(false);
      setShowComment(false);
      onSaveResponse(value);
    } else {
      setShowComment(true);
    }
  }, [onSaveResponse]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  const onSubmitResponseOfProperty = useCallback(() => {
    const payload = {
      rfpId: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
      brandId: propertyData?.property?.brandId?._id,
      propertyId: propertyData?.propertyId,
      doYouWishToRespondProperty: respondProperty,
      // respondPropertyComment: propertyRespondedComment,
      clientRespondComment: {
        messageText: propertyRespondedComment,
        messageTime: new Date()
      }
    }
    // debugger
    // console.log(payload, 'payload');
    respondCreateUpdatePropertyAcceptDeclineOfRFP(payload);
  }, [location.state?.rfpId, rfpProcess?._id, propertyData?.property?.brandId?._id, propertyData?.propertyId, respondProperty, propertyRespondedComment, respondCreateUpdatePropertyAcceptDeclineOfRFP]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      name: 'Property',
      selector: row => row.property?.propertyName,
    },
    {
      name: 'Brand',
      selector: row => row.property?.brandId.name,
    },
    {
      name: 'Status',
      wrap: true,
      cell: (row: any) => {
        let color = "";

        let text = "";
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED) {
          color = $mysaTeal;
          text = "Response";
        }
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.COMPLETE) {
          text = BrandRfpPropertyEvaluationStatus.COMPLETE;
          color = $mysaTeal;
        }

        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.INCOMPLETE) {
          color = $mysaMegenta;
          text = BrandRfpPropertyEvaluationStatus.INCOMPLETE;
        }

        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED) {
          color = $mysaRed;
          text = BrandRfpPropertyEvaluationStatus.DECLINED;
        }
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) {
          color = $mysaTeal;
          text = BrandRfpPropertyEvaluationStatus.ACCEPTED;
        }
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW) {
          color = $mysaMegenta;
          text = 'Awaiting';
        }
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.PART_ACCEPTED) {
          color = $mysaTeal;
          text = BrandRfpPropertyEvaluationStatus.PART_ACCEPTED;
        }

        let isAcceptedInventory = false;
        // for (const inventory of row?.inventories) {
        if (row.inventories.length === 1 && row?.inventories[0].rfpPropertyRateStatus === 'accepted') {
          isAcceptedInventory = true;
        } else {
          isAcceptedInventory = false;
          // break;
        }
        // }

        return (
          <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize' >{text}
            {/* old logic {((row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && !row?.isAmendedRateForProperty && !row.isNegotiateProperty) || (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && row?.isNegotiateProperty && row?.inventories.length === 1 && !row?.isAmendedRateForProperty) || (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && !row?.isNegotiateProperty && row?.inventories.length === 1 && !row?.isAmendedRateForProperty)) && */}
            {
              // ((row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && !row?.isAmendedRateForProperty && !row.isNegotiateProperty) || (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && !row?.isAmendedRateForProperty && !row.isNegotiateProperty) || ((row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && isAcceptedInventory && !row?.isAmendedRateForProperty && !row.isNegotiateProperty) || (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && isAcceptedInventory && !row?.isAmendedRateForProperty && !row.isNegotiateProperty))) &&
              // <Button onClick={() => { callApi(row); setPropertyData(row); }} className="btn-comment ml-2 mt-1" >Comments</Button>


              (
                (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && !row?.isNegotiateProperty && !row?.isAmendedRateForProperty && isAcceptedInventory)
                || (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && !row?.isNegotiateProperty && !row.isAmendedRateForProperty && isAcceptedInventory)
                || (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && !row?.isNegotiateProperty && !row?.isAmendedRateForProperty) ||
                (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && !row?.isNegotiateProperty && !row.isAmendedRateForProperty)

              ) &&

              <OverlayTrigger
                placement="top"
                delay={overlayTriggerDelay}
                overlay={<Tooltip id='tooltip-table-top'>View Comments</Tooltip>}
              >
                <img className='iconSizes4 ' src={File} alt="" style={{ marginLeft: '80px', marginTop: '-19px' }} onClick={() => { callApi(row); setPropertyData(row); setShowComment(false) }} />
              </OverlayTrigger>
            }
          </span>


        )
      }
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <>
            <Button className='btn-viewProperty' onClick={() => {
              history.push({
                pathname: `/admin/client-proposal-management/negotiationProperty/${row.property?._id}/${location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id}`
              });
            }}><b>View Property</b></Button>
          </>
        );
      }
    }
  ];

  return (
    <>
      {tableData?.length !== 0 && <div className='mt-2'>
        <DataTable
          columns={columns}
          data={tableData}
          pagination
          striped={true}
          customStyles={tableStyles}
          theme='mysaTable'
          fixedHeader={true}
          noDataComponent={'Updating records'}
        />
      </div>}

      <Row>
        {(selectCityName && tableData?.length === 0) &&


          <Row className='text-center mt-5' >

            {/* <Col md={12}  >
              <h4>  {getStorage('selectedFilterClient')?.label} {getStorage('selectedProgramsOption')?.label} programme</h4>
            </Col> */}



          </Row>
        }

        {(selectCityName && tableData?.length === 0) &&

          <Row className=' mt-3' >

            <Col md={12} >
              <h2 className='text-center'>You have no current RFP to assess</h2>
            </Col>


          </Row>
        }
      </Row>


      {<Modal
        show={showMessage}
        scrollable={true}
        onHide={() => { setShowMessage(false); }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={false}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          {
            !loadingMessage && propertyAcceptInformation?.clarificationComment &&
            <Row>
              <Col md={10}><div style={{ textAlign: 'left' }}>

                <Form.Control as="textarea" rows={5} disabled
                  // placeholder="please provide comments"
                  name='clarificationComment'
                  defaultValue={propertyAcceptInformation?.clarificationComment}
                />
              </div></Col>
              <Col md={1}></Col>
            </Row>}

          {!loadingMessage && propertyAcceptInformation?.propertyReviewComment &&

            <Row>
              <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                <Form.Control as="textarea" rows={5} disabled
                  // placeholder="please provide comments"
                  name='propertyReviewComment'
                  defaultValue={propertyAcceptInformation?.propertyReviewComment}
                />
              </div></Col>
              <Col md={1}></Col>
            </Row>}

          {/* {propertyAcceptInformation?.respondPropertyComment && <Row>
            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

              <Form.Control as="textarea" rows={5} disabled
                // placeholder="please provide comments"
                name='propertyResponseComment'
                defaultValue={propertyAcceptInformation?.respondPropertyComment}
              />
            </div></Col>
            <Col md={1}></Col>
          </Row>}


          {propertyAcceptInformation?.brandRespondPropertyComment && <Row>
            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

              <Form.Control as="textarea" rows={5} disabled
                // placeholder="please provide comments"
                name='brandRespondPropertyComment'
                defaultValue={propertyAcceptInformation?.brandRespondPropertyComment}
              />
            </div></Col>
            <Col md={1}></Col>
          </Row>} */}

          {
            !loadingMessage && propertyAcceptInformation?.messageThread?.length > 0 && propertyAcceptInformation?.messageThread?.map((values, key) => {
              return (<div key={key}>
                {/* <p style={{ marginBottom: '0px' }}>{(key === 0) ? (values?.direction === 'booker_to_property') ? 'Booker (original message)' : 'Property (original message)' : (values?.direction === 'booker_to_property') ? 'Booker (further message)' : 'Property (further message)'}</p> */}
                {/* <p className="lead">{values?.messageText}</p> */}

                {values?.clientRespondComment && <Row>
                  <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                    <Form.Control as="textarea" rows={5} disabled
                      // placeholder="please provide comments"
                      name='propertyResponseComment'
                      defaultValue={values?.clientRespondComment?.messageText}
                    />
                  </div></Col>
                  <Col md={1}></Col>
                </Row>}


                {values?.brandRespondComment && <Row>
                  <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                    <Form.Control as="textarea" rows={5} disabled
                      // placeholder="please provide comments"
                      name='brandRespondPropertyComment'
                      defaultValue={values?.brandRespondComment?.messageText}
                    />
                  </div></Col>
                  <Col md={1}></Col>
                </Row>}

              </div>)
            })
          }

          {
            loadingMessage && <div style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="warning" />
            </div>
          }

          {/* {!loadingMessage && propertyAcceptInformation?.inventories[0]?.negotiationComment && <Row>
            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

              <Form.Control as="textarea" rows={5} disabled
                // placeholder="please provide comments"
                name='negotiationComment'
                defaultValue={propertyAcceptInformation?.inventories[0]?.negotiationComment}
              />
            </div></Col>
            <Col md={1}></Col>
          </Row>} */}

          {/* {propertyAcceptInformation?.inventories[0]?.inventoryAmendedRateComment && <Row>
            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

              <Form.Control as="textarea" rows={5} disabled
                // placeholder="please provide comments"
                name='inventoryAmendedRateComment'
                defaultValue={propertyAcceptInformation?.inventories[0]?.inventoryAmendedRateComment}
              />
            </div></Col>
            <Col md={1}></Col>
          </Row>} */}

          {!loadingMessage && (propertyData?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW) &&
            <Row className='formSpace'>

              <Col style={{ textAlign: 'center' }}>
                <Button style={formButton} onClick={() => { setShowMessage(false); }} id='edit-button' >Close</Button>
              </Col>
            </Row>
          }

          {
            !loadingMessage && ((propertyData?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && propertyAcceptInformation?.messageThread.length === 0) || (propertyData?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && propertyAcceptInformation?.messageThread?.length !== 0 && propertyAcceptInformation?.messageThread[propertyAcceptInformation?.messageThread.length - 1].brandRespondComment?.messageText)) &&
            <Row className='mt-3'>
              <div style={{ fontSize: '16px' }}>Do you wish to respond to the property?
                <span style={{ marginLeft: '2%' }}>
                  <Form.Check
                    inline
                    value={'Yes'}
                    label={'Yes'}
                    type="radio"
                    name='wishToRespondProperty'
                    onChange={(value) => {
                      onSubmitResponse(value.target.value);
                    }}
                  />
                  <Form.Check
                    inline
                    value={'No'}
                    label={'No'}
                    type="radio"
                    name='wishToRespondProperty'
                    onChange={(value) => {
                      onSubmitResponse(value.target.value);
                    }}
                  />
                  {/* {
                    propertyAcceptInformation?.doYouWishToRespondProperty === 'Yes'
                      ?
                      <Form.Check
                        inline
                        value={'Yes'}
                        label={'Yes'}
                        type="radio"
                        defaultChecked
                        name='wishToRespondProperty'
                        onChange={(value) => {
                          onSubmitResponse(value.target.value);
                        }}
                      />
                      :
                      <Form.Check
                        inline
                        value={'Yes'}
                        label={'Yes'}
                        type="radio"
                        name='wishToRespondProperty'
                        onChange={(value) => {
                          onSubmitResponse(value.target.value);
                        }}
                      />
                  }

                  {
                    propertyAcceptInformation?.doYouWishToRespondProperty === 'No'
                      ?
                      <Form.Check
                        inline
                        value={'No'}
                        label={'No'}
                        type="radio"
                        defaultChecked
                        name='wishToRespondProperty'
                        onChange={(value) => {
                          onSubmitResponse(value.target.value);
                        }}
                      />
                      :
                      <Form.Check
                        inline
                        value={'No'}
                        label={'No'}
                        type="radio"
                        name='wishToRespondProperty'
                        onChange={(value) => {
                          onSubmitResponse(value.target.value);
                        }}
                      />
                  } */}

                </span>
              </div>
            </Row>}

          {(showComment) && <Row>
            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

              <Form.Control as="textarea" rows={5}
                // placeholder="please provide comments"
                name='propertyResponseComment'
                // defaultValue={propertyAcceptInformation?.respondPropertyComment}
                onChange={(e) => {
                  setPropertyRespondedComment(e.target.value);
                }}
              />
            </div></Col>
            <Col md={1}></Col>
          </Row>}

          {(showComment) &&
            <Row className='formSpace'>
              <Col md={6} style={{ textAlign: 'left' }}>
                <Button id='approve-button' style={formButton} onClick={() => { onSubmitResponseOfProperty() }} >Submit</Button>
              </Col>
              <Col md={6} style={{ textAlign: 'right' }}>
                <Button style={formButton} onClick={() => { setShowMessage(false); }} id='edit-button' >Close</Button>
              </Col>
            </Row>
          }


          {/* <Row>
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col >
                                    <div style={{ textAlign: 'center' }}>
                                        <Button onClick={() => { setShowMessage(false); }} type="submit" id='edit-button' style={{
                                            height: "40px",
                                            width: '220px',
                                            borderRadius: 30,
                                            paddingLeft: "30px",
                                            paddingRight: "30px",
                                            marginRight: '20px'
                                        }}>Close</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row> */}
        </Modal.Body>
      </Modal>}
    </>
  );
}

export default NegotiatedPropertyList;