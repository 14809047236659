import { CheckOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { getLoggedUserType } from '../../../../../../common/functions';

const QCurrency: React.FC<any> = ({ data, evidence, onAnswer, signUpStatus }): JSX.Element => {
	const [answer, setAnswer] = useState(moment());
	const [loading, setLoading] = useState(false);
	const [userRole, setUserRole] = useState<any>();
	// const userRole = getLoggedUserType();

	useEffect(() => {
		if (getLoggedUserType() !== null) {
			setUserRole(getLoggedUserType());
		}
		setAnswer(moment(data.answer?.answer));
	}, [data.answer?.answer]);

	const answerQuestion = useCallback(() => {
		const disabled = ( userRole === 'super_admin') ? false : signUpStatus === 'accepted' ? true : answer ? false : true;
		if (disabled) return false;

		onAnswer(answer.format(), data.id);
		setLoading(true);
		setTimeout(() => setLoading(false), 3000);
	}, [userRole, signUpStatus, answer, onAnswer, data]);

	return (
		// <div className="inline-block m-5 float-left relative">
		//   <Card
		//     hoverable
		//     headStyle={{ textAlign: 'center' }}
		//     bodyStyle={{ height: 200 }}
		//     style={{ width: 250, height: 310 }}
		//     title="Date"
		//     actions={[
		//       <Button type={data.answer?.answer ? 'dashed' : 'primary'} onClick={answerQuestion} shape="round" icon={<CheckOutlined />} size="small" />,
		//     ]}
		//   >
		//     <p className="mb-3">{data?.description}</p>
		//     <DatePicker allowClear={false} placeholder="Please pick a date" onChange={(date: any) => setAnswer(date)} format={"LL"} value={answer} />
		//   </Card>
		//   {evidence && <div className="rings" />}
		// </div>
		<div className="inline-block  m-5 w-full pr-10">
			<div className="bg-white p-5 rounded-lg">
				<p className="text-lg mb-3">{data?.description}</p>
				<DatePicker allowClear={false} placeholder="Please pick a date" onChange={(date: any) => setAnswer(date)} format={"LL"} value={answer} />
				<Button type="primary" ghost={data.answer?.answer ? false : true} block onClick={answerQuestion} icon={<CheckOutlined />} className="rounded-xl mt-3" loading={loading}>
					Submit
				</Button>
			</div>
		</div>
	)
};

export default QCurrency;