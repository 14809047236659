import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useStoreState } from "easy-peasy";
import _ from "lodash";
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import { Button} from 'react-bootstrap';
import {
  tableStyles,
} from "../../../common/components-style";
import moment from "moment";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";

const ActiveRfpList: React.FC<any> = (): JSX.Element => {
  const history = useHistory();
  const [activeRfpData, setActiveRfpData] = useState<any>([]);
  const [visibleData, setVisibleData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(7);
  const [isExpanded, setIsExpanded] = useState(false);


  useEffect(() => {
    if (activeRfpData && activeRfpData?.length > 0) {
    setVisibleData(activeRfpData.slice(0,7)); 
    }else
    {
    setVisibleData([]);
    }
  }, [activeRfpData]);


const loadMoreData = () => {
  if (activeRfpData && activeRfpData?.length > 0) {
  const newIndex = currentIndex + 7;
  setVisibleData(activeRfpData.slice(0, newIndex));
  setCurrentIndex(newIndex);
  }
};

const RFPActiveHandler = () => {
  if (activeRfpData && activeRfpData?.length > 0) {
  if (isExpanded) {
    setVisibleData(activeRfpData.slice(0, 7));
    setCurrentIndex(7);
  } else {
    setVisibleData(activeRfpData);
    setCurrentIndex(activeRfpData.length);
  }
  setIsExpanded(!isExpanded);
}
};

  const { superAdminDashboardSuccessRfpManagement, superAdminDashboardErrorRfpManagement} = useStoreState<any>(
    (state) => {
      return {
        superAdminDashboardSuccessRfpManagement: state.DashboardModel.superAdminDashboardSuccessRfpManagement,
        superAdminDashboardErrorRfpManagement: state.DashboardModel.superAdminDashboardErrorRfpManagement,
      }
    }
  );
  useEffect(() => {
    if (superAdminDashboardErrorRfpManagement) {
      toast.error(superAdminDashboardErrorRfpManagement.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    if (superAdminDashboardSuccessRfpManagement) {
      setActiveRfpData(superAdminDashboardSuccessRfpManagement?.data?.getAllActiveRFPCount);
    }
  }, [superAdminDashboardErrorRfpManagement, superAdminDashboardSuccessRfpManagement]);

  const handleOpen = useCallback(
    (data) => {
      setTimeout(() => {
        history.push({
          pathname: `admin/rfp-management`,
          state: {
            tabKey: 'submission',
            programmeId: data?.programmeId,
            selectedClient: data?.selectedClient,
            selectedProgramme: data?.programme,
            rfpProcessId:data?.key,
            rfpEndDate:data?.endDate,
            rfpStartDate:data?.startDate,
            formOpen:true,
            rfpStatus:data?.rfpStatus
          }
        });
      }, 500);
    },
    [history]
  );

  const columns = [
    {
      name: "Client",
      cell: (row) => (
          row.selectedClient?.companyName
      ),
    },
    {
      name: "Programme",
      cell: (row) => (
          row.programmeName
      ),
    },
    {
        name: 'Start Date',
        selector: (row) => moment(row.startDate).format("D MMMM YYYY"),
    },
    {
        name: 'Question Date',
        selector: (row) => moment(row.questionSubmitDate).format("D MMMM YYYY"),
    },
    {
      name: 'End Date',
      cell: (row: any) => {
        const endDate = moment(row.endDate).startOf('day');
        const today = moment(new Date()).startOf('day');
        const diffInDays = endDate.diff(today, 'days');

        if (diffInDays === 0) {
          return (
            <strong className="mysa-pink-text">
              {endDate.format("D MMMM YYYY")}
            </strong>
          );
        } else if (diffInDays > 0 && diffInDays <= 3) {
          return (
            <strong className="mysa-green-text">
              {endDate.format("D MMMM YYYY")}
            </strong>
          );
        } else {
          return endDate.format("D MMMM YYYY");
        }
      },
    },
    {
      name: "Locations",
      cell: (row) =>  row.locations
    },
    {
        name: "Brands",
        cell: (row) =>  row.brandCount
      },
      {
        name: "Properties",
        cell: (row) =>  row.propertyCount
      },
    {
      name: 'Action',
      button: false,
      cell: (row: any) => {
              return (
              <>
            <img
              className="iconSizes mr-2"
              src={ViewEdit}
              alt=""
              onClick={() => {
                handleOpen(row);
              }}
            />
              </>)
      },
      width: '100px',
  },
  ];
    return (
    <>
      <div className="tbl_accr_itm mysa-green superadmin-table">
        <div className="tbl_hdr mysa-green">
          <h5 className="m-0 fw-bold text-white">Active RFPs</h5>
          <button className="text-white pointer" onClick={RFPActiveHandler}>
            Expand
            <span>
              <img src={isExpanded  ? minus : plus} alt="icon" />
            </span>
          </button>
        </div>
        <div className="acrd_tbl">
          <DataTable
            columns={columns}
            data={visibleData}
            striped={true}
            customStyles={tableStyles}
            theme="mysaTable"
            fixedHeader={true}
            noDataComponent={"Updating records"}
            conditionalRowStyles={[
              {
                when: (row) => true,
                classNames: ['row-item'],
              },
            ]}
          />
          {currentIndex < activeRfpData?.length && (
            <div className="more-data-section">
              <Button onClick={loadMoreData} className="more_button">More</Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ActiveRfpList;
