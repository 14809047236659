import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { getStorage } from '../../../common/storage';
import { getLoggedUserType } from '../../../common/functions';
import { UserGroups } from '../../../common/constants';
// const socket = io(process.env.REACT_APP_API_URL?? '');

const ProgrammeNotificationList: React.FC<any> = (): JSX.Element => {
    const [notification, setNotification] = useState<any>([]);



    const { programmeDashboardDetails } = useStoreActions<any>((actions) => ({
        programmeDashboardDetails: actions.DashboardModel.programmeDashboardDetails,

    }));

    // const [socketNotification, setSocketNotification] = useState<any>();
    const { programmeDashboardSuccess, programmeDashboardError } = useStoreState<any>((state) => ({
        programmeDashboardSuccess: state.DashboardModel.programmeDashboardSuccess,
        programmeDashboardError: state.DashboardModel.programmeDashboardError
    }));


    useEffect(() => {
        if (getStorage('programmeData')) {
            const data = {
                id: getStorage('programmeData').clientId?._id,
            }
            if (getLoggedUserType() !== UserGroups.super_admin) {
                if (getStorage('programmeData').clientId?._id) {
                    programmeDashboardDetails(data);
                }

            }
        }
    }, [programmeDashboardDetails]);

    useEffect(() => {
        if (programmeDashboardError) {
            toast.error(programmeDashboardError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (programmeDashboardSuccess) {
            setNotification(programmeDashboardSuccess?.data?.notification);
        }
    }, [programmeDashboardError, programmeDashboardSuccess]);


    return (
        <>
            <ul className='ntf_list m-0'>
                {
                    notification.map((notification, index) => {
                        return <li key={index} className='ntf_list_item'>
                            <div>
                                {ReactHtmlParser(notification.text)}
                            </div>
                            <div>
                                {notification.updatedAt ? moment(notification.updatedAt).format('DD-MMM-YYYY') : moment().format('DD-MMM-YYYY')}
                            </div>
                        </li>
                    })}
            </ul>
        </>

    )
}

export default ProgrammeNotificationList;