import { Breadcrumb, Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { FC, useCallback, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable from 'react-data-table-component';
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { BrandRfpPropertyEvaluationStatus, rfpPropertyStatusToInventory } from "../../../../common/constants";
import { $mysaBlue, $mysaMegenta, $mysaRed, $mysaTeal, $mysaYellow, overlayTriggerDelay, tableStyles } from "../../../../common/components-style";
import _ from 'lodash';
import { Link } from 'react-router-dom';
import RfpRateForm from './SubmittedRfpRateForm';
import { getStorage } from '../../../../common/storage';
import File from '../../../../images/icons/svg/outside/file-icon.svg';
import { toast } from 'react-toastify';

const BrandAdminSubmittedRfpPropertyRatesList: FC<any> = ({ property, myRfpsData, setComponentName, setDefaultTab, setDefaultTabProperty }) => {
    const [showReviewMessage, setShowReviewMessage] = useState<boolean>(false);
    const [inventoryData, setInventoryData] = useState<any>(null);
    const [formEnable, setFormEnable] = useState(false);
    const [inventory, setInventory] = useState<any>(null);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const myoSelectedMyRfp = getStorage("myo_selected_my_rfp");
    const myoSelectedMyRfpProperty = getStorage("myo_selected_my_rfp_property");
    const [propertyAcceptInformation, setPropertyAcceptInformation] = useState<any>(null);
    const [inventoryReviewComment, setInventoryReviewComment] = useState<any>();
    const [inventoryComment, setInventoryComment] = useState<any>(null);
    const [negotiationComment, setNegotiationComment] = useState<any>(null);
    const [declineComment, setDeclineComment] = useState<any>(null);
    const [inventoryMessage, setInventoryMessage] = useState<any>([]);
    const [isAmended, setIsAmended] = useState<boolean>(false);
    const [inventoryAmendedRateComment, setInventoryAmendedRateComment] = useState<any>(null);
    const [inventoryAmendedSecondRateComment, setInventoryAmendedSecondRateComment] = useState<any>(null);
    const [inventoryList, setInventoryList] = useState<any>();

    //state
    const {
        getPropertyRfpInventorySuccess,
        getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPError,
        reviewPropertyForRFPSuccess,
        reviewPropertyForRFPError,
        acceptPropertyRateForRFPSuccess,
        acceptPropertyRateForRFPError
    } = useStoreState<any>((state) => ({
        getPropertyRfpInventorySuccess: state.rfpProcess.getPropertyRfpInventorySuccess,
        getPropertyAcceptDeclineOfRFPByPropertyIdSuccess: state.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPError,
        reviewPropertyForRFPSuccess: state.rfpProcess.reviewPropertyForRFPSuccess,
        reviewPropertyForRFPError: state.rfpProcess.reviewPropertyForRFPError,
        acceptPropertyRateForRFPSuccess: state.rfpProcess.acceptPropertyRateForRFPSuccess,
        acceptPropertyRateForRFPError: state.rfpProcess.acceptPropertyRateForRFPError,
    }));

    //actions
    const {
        propertyRfpInventory,
        getPropertyAcceptDeclineOfRFPByPropertyId,
        reviewCreateUpdatePropertyAcceptDeclineOfRFP,
        reviewPropertyForRFP,
        acceptPropertyForRFP,
        acceptPropertyRateForRFP
    } = useStoreActions<any>((actions) => ({
        propertyRfpInventory: actions.rfpProcess.propertyRfpInventory,
        getPropertyAcceptDeclineOfRFPByPropertyId: actions.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyId,
        reviewCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFP,
        reviewPropertyForRFP: actions.rfpProcess.reviewPropertyForRFP,
        acceptPropertyForRFP: actions.rfpProcess.acceptPropertyForRFP,
        acceptPropertyRateForRFP: actions.rfpProcess.acceptPropertyRateForRFP
    }));


    useEffect(() => {
        const params: any = {};
        params.propertyId = property?.id.toString();
        params.rfpId = myoSelectedMyRfp?.rfpId.toString();
        params.brandRfpId = myoSelectedMyRfp?.key.toString();
        params.cityName = myoSelectedMyRfpProperty?.clientLocation.toString();
        propertyRfpInventory(params);
    }, [myoSelectedMyRfp?.key, myoSelectedMyRfp?.rfpId, myoSelectedMyRfpProperty?.clientLocation, property?.id, propertyRfpInventory]);



    useEffect(() => {
        // console.log(propertyData, 'propertyId');
        const payload = {
            rfpId: myoSelectedMyRfp?.rfpId.toString(),
            propertyId: property?.id.toString()
        }
        if (property?.id) {
            getPropertyAcceptDeclineOfRFPByPropertyId(payload);
        }

    }, [getPropertyAcceptDeclineOfRFPByPropertyId, myoSelectedMyRfp?.rfpId, property?.id]);

    const callApi = useCallback((data) => {
        setShowMessage(true);
        const payload = {
            rfpId: myoSelectedMyRfp?.rfpId.toString(),
            propertyId: property?.id.toString()
        }
        if (property?.id.toString()) {
            getPropertyAcceptDeclineOfRFPByPropertyId(payload)
        }
    }, [getPropertyAcceptDeclineOfRFPByPropertyId, myoSelectedMyRfp?.rfpId, property?.id]);



    const callDataList = useCallback(() => {
        const params: any = {};
        if (property) {
            params.propertyId = property?.id.toString();
            params.rfpId = myoSelectedMyRfp?.rfpId.toString();
            params.brandRfpId = myoSelectedMyRfp?.key.toString();
            params.cityName = myoSelectedMyRfpProperty?.clientLocation.toString();
            propertyRfpInventory(params);
        }
    }, [myoSelectedMyRfp?.key, myoSelectedMyRfp?.rfpId, myoSelectedMyRfpProperty?.clientLocation, property, propertyRfpInventory])

    useEffect(() => {
        if (getPropertyAcceptDeclineOfRFPByPropertyIdSuccess) {
            // console.log(getPropertyAcceptDeclineOfRFPByPropertyIdSuccess?.data, 'Data');
            setPropertyAcceptInformation(getPropertyAcceptDeclineOfRFPByPropertyIdSuccess?.data);
        }
    }, [getPropertyAcceptDeclineOfRFPByPropertyIdSuccess])

    useEffect(() => {
        if (propertyAcceptInformation) {

            propertyAcceptInformation?.inventories?.forEach((inv) => {
                if (inv.inventoryId.toString() === inventoryData?.key.toString()) {
                    setInventoryComment(inv?.inventoryReviewComment);
                    setNegotiationComment(inv?.negotiationComment);
                    setDeclineComment(inv?.inventoryDeclineComment);
                    setInventoryAmendedRateComment(inv?.inventoryAmendedRateComment);
                    setInventoryAmendedSecondRateComment(inv?.inventoryAmendedSecondRateComment);
                    setInventoryMessage(inv?.inventoryMessageThread);
                }
            })

        }
    }, [inventoryData?.key, propertyAcceptInformation])


    // console.log(negotiationComment, 'negotiationComment');
    // useEffect(() => {
    //     if (propertyAcceptInformation) {
    //         propertyAcceptInformation?.inventories?.forEach((inv) => {
    //             if (inv.inventoryId === rates?.inventory) {
    //                 setNegotiationComment(inv?.negotiationComment);
    //                 setDeclineComment(inv?.inventoryDeclineComment);
    //                 setInventoryAmendedRateComment(inv?.inventoryAmendedRateComment);
    //                 setInventoryAmendedSecondRateComment(inv?.inventoryAmendedSecondRateComment);
    //             }

    //         })

    //     }
    // }, [propertyAcceptInformation, rates?.inventory])


    useEffect(() => {
        if (getPropertyRfpInventorySuccess?.data) {
            const lists = _.map(getPropertyRfpInventorySuccess?.data, invent => {
                return {
                    key: invent?.inventory?._id,
                    section: invent?.inventory?.accommodationConfiguration?.name,
                    accommodationDetail: invent?.inventory?.accommodationDetail,
                    status: invent?.rfpPropertyRateStatus,
                    inventory: invent?.inventory
                }
            });
            const data = _.filter(lists, ele => ((ele.status === rfpPropertyStatusToInventory.REVIEW)));
            if (data) {
                setIsAmended(true);
            } else {
                setIsAmended(false);
            }
            setInventoryList(lists);
        }
    }, [getPropertyRfpInventorySuccess?.data])

    //Table columns/////////////////////////////////////////////////////////////////////////////////////////
    const columns1 = [
        {
            name: 'Configuration',
            selector: row => row.section,
        },
        {
            name: 'Additional Detail',
            selector: row => row.accommodationDetail,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: (data: any) => {
                const { status } = data;
                let color = "text-red-500 uppercase font-bold";

                if (status === rfpPropertyStatusToInventory.PROPOSED) color = $mysaYellow;
                if (status === rfpPropertyStatusToInventory.INCOMPLETE) color = $mysaMegenta;
                if (status === rfpPropertyStatusToInventory.ACCEPTED) color = $mysaTeal;;
                if (status === rfpPropertyStatusToInventory.DECLINED) color = $mysaRed;
                if (status === rfpPropertyStatusToInventory.REVIEW) color = $mysaYellow;
                if (status === rfpPropertyStatusToInventory.REVIEWED) color = $mysaBlue;
                if (status === rfpPropertyStatusToInventory.NEGOTIATE) color = $mysaYellow;
                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize' >{status}</span>
                )
            }
        },
        {
            name: 'Action',
            button: true,
            wrap: true,
            minWidth: '300px',
            cell: (data: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                    >
                        <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                            setFormEnable(true);
                            setInventory(data);
                        }} />
                    </OverlayTrigger>

                    {((property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.PART_ACCEPTED && data.status === rfpPropertyStatusToInventory.DECLINED) ||
                        (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && data.status === rfpPropertyStatusToInventory.REVIEW) ||
                        (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.PART_ACCEPTED && data.status === rfpPropertyStatusToInventory.REVIEW)
                        || (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.PART_ACCEPTED && data.status === rfpPropertyStatusToInventory.REVIEWED) ||
                        (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && data.status === rfpPropertyStatusToInventory.REVIEWED) ||
                        (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && data.status === rfpPropertyStatusToInventory.REVIEWED) ||
                        (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && data.status === rfpPropertyStatusToInventory.DECLINED) ||
                        (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && data.status === rfpPropertyStatusToInventory.DECLINED) ||
                        (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && data.status === rfpPropertyStatusToInventory.ACCEPTED)
                        || data.status === rfpPropertyStatusToInventory.REVIEW || data.status === rfpPropertyStatusToInventory.REVIEWED 
                        || (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED && data.status === rfpPropertyStatusToInventory.DECLINED)
                        // || data.status === rfpPropertyStatusToInventory.DECLINED https://mysa-group.atlassian.net/browse/ST-759
                    )
                        && <OverlayTrigger


                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-table-top'>View Comments</Tooltip>}
                        >
                            <img className='iconSizes4' src={File} alt="" style={{ marginRight: '-10px' }} onClick={() => {
                                // setShowMessage(true);
                                callApi(data);
                                setInventoryData(data);
                            }} />
                        </OverlayTrigger>}
                </>)
            }
        },

    ];

    const backToProperty = useCallback(() => {
        setInventory(null);
        setFormEnable(false);
        const params: any = {};
        if (property) {
            params.propertyId = property?.id.toString();
            params.rfpId = myoSelectedMyRfp.rfpId.toString();
            params.brandRfpId = myoSelectedMyRfp.key.toString();
            params.cityName = myoSelectedMyRfpProperty.clientLocation.toString();
            propertyRfpInventory(params);
        }
    }, [myoSelectedMyRfp.key, myoSelectedMyRfp.rfpId, myoSelectedMyRfpProperty.clientLocation, property, propertyRfpInventory])


    ///////////////on review inventory
    const onReviewInventory = useCallback(() => {
        const payload = {
            rfpId: myoSelectedMyRfp?.rfpId.toString(),
            brandId: myoSelectedMyRfp?.brandId.toString(),
            propertyId: property?.id,
            inventoryId: inventoryData?.key,
            inventoryReviewComment: inventoryReviewComment,
        }
        reviewCreateUpdatePropertyAcceptDeclineOfRFP(payload);
    }, [inventoryData?.key, inventoryReviewComment, myoSelectedMyRfp?.brandId, myoSelectedMyRfp?.rfpId, property?.id, reviewCreateUpdatePropertyAcceptDeclineOfRFP]);

    /////////////////////////review property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (acceptPropertyRateForRFPSuccess) {
            callDataList();
            // if (property.evaluationStatus === BrandRfpPropertyEvaluationStatus.PART_ACCEPTED) {
            // toast.success("Inventory reviewed successfully", {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            //     className: 'foo-bar'
            // });
            // backToProperty();
            // } else {
            const payload = {
                rfpId: myoSelectedMyRfp?.rfpId.toString(),
                brandId: myoSelectedMyRfp?.brandId.toString(),
                propertyId: property?.id,
                cityName: property?.city,
                isAmendedRateForProperty: true,
                propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEWED,
                isClient: false
            }
            acceptPropertyForRFP(payload);
            const data = _.filter(inventoryList, ele => ((ele.status === rfpPropertyStatusToInventory.REVIEW)));
            if (data?.length !== 0) {
                toast.success("Inventory reviewed successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });

                backToProperty();
            } else {
                const payload = {
                    rfpId: myoSelectedMyRfp?.rfpId.toString(),
                    brandId: myoSelectedMyRfp?.brandId.toString(),
                    propertyId: property?.id,
                    propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEWED,
                    cityName: property?.city,
                    isAmendedRateForProperty: true,
                    isClient:false

                }
                reviewPropertyForRFP(payload);
            }

            callDataList();
            // }

        }

        if (acceptPropertyRateForRFPError) {
            toast.error(acceptPropertyRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (reviewPropertyForRFPSuccess) {
            toast.success("Property reviewed successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            backToProperty();
        }

        if (reviewPropertyForRFPError) {
            toast.error(reviewPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [acceptPropertyForRFP, acceptPropertyRateForRFPError, acceptPropertyRateForRFPSuccess, backToProperty, callDataList, inventoryList, myoSelectedMyRfp?.brandId, myoSelectedMyRfp?.rfpId, property?.city, property?.id, reviewPropertyForRFP, reviewPropertyForRFPError, reviewPropertyForRFPSuccess]);


    ///////////////on review inventory
    const onReviewRate = useCallback((inv) => {
        // callDataList();
        const payload = {
            rfpId: myoSelectedMyRfp?.rfpId.toString(),
            brandId: myoSelectedMyRfp?.brandId.toString(),
            propertyId: property?.id,
            propertyInventoryId: inv?.key,
            propertyInventoryStatus: rfpPropertyStatusToInventory.REVIEWED,
            cityName: property?.city,
        }
        acceptPropertyRateForRFP(payload);
        callDataList();

    }, [acceptPropertyRateForRFP, callDataList, myoSelectedMyRfp?.brandId, myoSelectedMyRfp?.rfpId, property?.city, property?.id]);

    ///////////////on submit inventory
    const onSubmit = useCallback((inv) => {
        const payload = {
            rfpId: myoSelectedMyRfp?.rfpId.toString(),
            brandId: myoSelectedMyRfp?.brandId.toString(),
            propertyId: property?.id,
            propertyInventoryId: inv?.key,
            propertyInventoryStatus: rfpPropertyStatusToInventory.REVIEWED,
            cityName: property?.city,
        }
        acceptPropertyRateForRFP(payload);

    }, [acceptPropertyRateForRFP, myoSelectedMyRfp?.brandId, myoSelectedMyRfp?.rfpId, property?.city, property?.id]);

    /////////////////////////review inventory success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(reviewCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Inventory review comment added successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setShowMessage(false);
        }

    }, [reviewCreateUpdatePropertyAcceptDeclineOfRFPError, reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess]);


    return (
        <div>
            <Row className="justify-content-between " style={{
                marginBottom: '1.5rem ',
                marginTop: '22px',
            }} >
                <Col md={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => {
                            // updateBrandRFPData({
                            //     ...reduxBrandDetail,
                            //     defaultActiveKey: "myRFPs",
                            //     showMyRfpBrandEvaluationPage: false,
                            //     showHomePage: true,
                            //     showMyRfpHomePage: true,
                            // })
                        }}>

                            <Link to={{ pathname: "/admin/brand-rfp-management", state: { selectedBrandId: myRfpsData?.brandId, defaultKey: 'submittedRFPs' } }}>    {myRfpsData?.companyName}{' - '}{myRfpsData?.programmeName}</Link>
                        </Breadcrumb.Item  >
                        <Breadcrumb.Item onClick={() => {
                            setDefaultTab('properties')
                            setComponentName('singleMyRfp');
                            setDefaultTabProperty('properties');
                        }}>
                            Properties
                        </Breadcrumb.Item  >
                        {
                            !inventory && <Breadcrumb.Item active>
                                {property.building} - {property.city}
                            </Breadcrumb.Item  >
                        }
                        {
                            inventory && <Breadcrumb.Item onClick={() => {
                                backToProperty();
                            }}>
                                {property.building} - {property.city}
                            </Breadcrumb.Item  >
                        }
                        {
                            inventory &&
                            <Breadcrumb.Item active>
                                {inventory?.accommodationDetail}
                            </Breadcrumb.Item  >
                        }

                    </Breadcrumb>
                </Col>

            </Row>


            {
                !formEnable && <DataTable
                    columns={columns1}
                    data={inventoryList}
                    pagination
                    responsive
                    customStyles={tableStyles}
                    theme='mysaTable'
                />
            }
            {
                formEnable && <RfpRateForm inventory={inventory} backToProperty={backToProperty} onReviewRate={onReviewRate} onSubmit={onSubmit} property={property} inventoryMessage={inventoryMessage} />
            }

            {((property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.PART_ACCEPTED && inventoryData?.status === rfpPropertyStatusToInventory.DECLINED) ||
                (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && inventoryData?.status === rfpPropertyStatusToInventory.DECLINED)             
            ) && <Modal
                show={showMessage}
                onHide={() => { setShowMessage(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>

                        {
                            negotiationComment &&
                            <Row>
                                <Col md={10}><div style={{ textAlign: 'left' }}>

                                    <Form.Control as="textarea" rows={5}
                                        // placeholder="please provide comments"
                                        name='negotiationComment'
                                        defaultValue={negotiationComment}
                                    />
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>}
                        <Row>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col >
                                        <div style={{ textAlign: 'center' }}>
                                            <Button onClick={() => { setShowMessage(false); }} type="submit" id='edit-button' style={{
                                                height: "40px",
                                                width: '220px',
                                                borderRadius: 30,
                                                paddingLeft: "30px",
                                                paddingRight: "30px",
                                                marginRight: '20px'
                                            }}>Close</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Modal.Body>
                </Modal>}


            {((property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && inventoryData?.status === rfpPropertyStatusToInventory.REVIEW) || (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.PART_ACCEPTED && inventoryData?.status === rfpPropertyStatusToInventory.REVIEW)) && <Modal Modal
                show={showMessage}
                onHide={() => { setShowMessage(false); setNegotiationComment(null); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {/* <Row>
                        <Col md={10}><div style={{ textAlign: 'left' }}>
                            <p>The rates for this property has been accepted. Please see below comments that require your consideration.</p>
                        </div></Col>
                        <Col md={1}></Col>
                    </Row> */}

                    {
                        negotiationComment &&
                        <Row>
                            <Col md={10}><div style={{ textAlign: 'left' }}>

                                <Form.Control as="textarea" rows={5}
                                    // placeholder="please provide comments"
                                    name='negotiationComment'
                                    defaultValue={negotiationComment}
                                />
                            </div></Col>
                            <Col md={1}></Col>
                        </Row>}

                    {showReviewMessage &&
                        <div>
                            <Row>
                                <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                    <Form.Control as="textarea" rows={5}
                                        placeholder="please provide comments"
                                        name='inventoryReviewComment'
                                        // value={''}
                                        onChange={(e) => {
                                            setInventoryReviewComment(e.target.value);
                                        }}
                                    />
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>
                            {isAmended && <Row>
                                <Col md={10}><div style={{ textAlign: 'left' }}>
                                    <p>If rates are to be amended, please complete this via the edit button in the 'Action' column after saving this message.</p>
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>}
                            <Row>
                                <Col md={10}>
                                    <Row className='formSpace'>
                                        <Col >
                                            <div style={{ textAlign: 'center' }}>
                                                <Button onClick={() => { onReviewInventory() }} type="submit" id='approve-button' style={{
                                                    height: "40px",
                                                    width: '220px',
                                                    borderRadius: 30,
                                                    paddingLeft: "30px",
                                                    paddingRight: "30px",
                                                    marginRight: '20px'
                                                }}>Save</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                        </div>


                    }
                    {((!showReviewMessage && !inventoryComment && inventoryList.length !== 1 && inventoryData?.status !== rfpPropertyStatusToInventory.REVIEW)) && <Row >
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col >
                                    <div style={{ textAlign: 'center', }}>
                                        <Button onClick={() => { setShowReviewMessage(true); }} type="submit" id='edit-button' style={{
                                            height: "40px",
                                            width: '220px',
                                            borderRadius: 30,
                                            paddingLeft: "30px",
                                            paddingRight: "30px",
                                            marginRight: '20px'
                                        }}>Reply</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row>

                    }


                    {inventoryData?.status === rfpPropertyStatusToInventory.REVIEW &&
                        <Row>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col >
                                        <div style={{ textAlign: 'center' }}>
                                            <Button onClick={() => { setShowMessage(false); setNegotiationComment(null); setInventoryMessage([]) }} type="submit" id='edit-button' style={{
                                                height: "40px",
                                                width: '220px',
                                                borderRadius: 30,
                                                paddingLeft: "30px",
                                                paddingRight: "30px",
                                                marginRight: '20px'
                                            }}>Close</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    }
                </Modal.Body>
            </Modal>}

            {((inventoryData?.status === rfpPropertyStatusToInventory.DECLINED) || (inventoryData?.status === rfpPropertyStatusToInventory.REVIEWED)
                || (inventoryData?.status === rfpPropertyStatusToInventory.REVIEW)
                || (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && inventoryData?.status === rfpPropertyStatusToInventory.ACCEPTED)
                || (property?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && inventoryData?.status === rfpPropertyStatusToInventory.REVIEWED)
            ) && <Modal
                show={showMessage}
                onHide={() => { setShowMessage(false); setNegotiationComment(null); setDeclineComment(null); setInventoryMessage([]); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            (((inventoryData?.status === rfpPropertyStatusToInventory.REVIEW) || (inventoryData?.status === rfpPropertyStatusToInventory.REVIEWED) || (inventoryData?.status === rfpPropertyStatusToInventory.DECLINED) || (inventoryData?.status === rfpPropertyStatusToInventory.ACCEPTED) ) && negotiationComment) &&
                            <Row>
                                <Col md={10}><div style={{ textAlign: 'left' }}>

                                    <Form.Control as="textarea" rows={5}
                                        // placeholder="please provide comments"
                                        name='negotiationComment'
                                        defaultValue={negotiationComment}
                                    />
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>}

                        {inventoryComment &&
                            <Row>
                                <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                    <Form.Control as="textarea" rows={5}
                                        // placeholder="please provide comments"
                                        name='inventoryReviewComment'
                                        defaultValue={inventoryComment}
                                    />
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>}
                        {inventoryAmendedRateComment && <Row>
                            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                <Form.Control as="textarea" rows={5}
                                    // placeholder="please provide comments"
                                    name='inventoryAmendedRateComment'
                                    defaultValue={inventoryAmendedRateComment}
                                />
                            </div></Col>
                            <Col md={1}></Col>
                        </Row>}

                        {inventoryAmendedSecondRateComment && <Row>
                            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                <Form.Control as="textarea" rows={5}
                                    // placeholder="please provide comments"
                                    name='inventoryAmendedSecondRateComment'
                                    defaultValue={inventoryAmendedSecondRateComment}
                                />
                            </div></Col>
                            <Col md={1}></Col>
                        </Row>}
                        {inventoryMessage?.length !== 0 && inventoryMessage.map((values, key) => {
                            return (<div key={key}>

                                {values?.clientRespondComment && <Row>
                                    <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                        <Form.Control as="textarea" rows={5} disabled
                                            name='propertyResponseComment'
                                            defaultValue={values?.clientRespondComment?.messageText}
                                        />
                                    </div></Col>
                                    <Col md={1}></Col>
                                </Row>}


                                {values?.brandRespondComment && <Row>
                                    <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                        <Form.Control as="textarea" rows={5} disabled
                                            name='brandRespondPropertyComment'
                                            defaultValue={values?.brandRespondComment?.messageText}
                                        />
                                    </div></Col>
                                    <Col md={1}></Col>
                                </Row>}

                            </div>)
                        })
                        }

                        {(declineComment && inventoryData?.status === rfpPropertyStatusToInventory.DECLINED) && <Row>
                            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                <Form.Control as="textarea" rows={5}
                                    // placeholder="please provide comments"
                                    name='declineComment'
                                    defaultValue={declineComment}
                                />
                            </div></Col>
                            <Col md={1}></Col>
                        </Row>}

                        <Row>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col >
                                        <div style={{ textAlign: 'center' }}>
                                            <Button onClick={() => { setShowMessage(false); setDeclineComment(null); setNegotiationComment(null); setInventoryMessage([]) }} type="submit" id='edit-button' style={{
                                                height: "40px",
                                                width: '220px',
                                                borderRadius: 30,
                                                paddingLeft: "30px",
                                                paddingRight: "30px",
                                                marginRight: '20px'
                                            }}>Close</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Modal.Body>
                </Modal>}


        </div >
    )
}

export default BrandAdminSubmittedRfpPropertyRatesList;