import React from 'react';
import { ReactComponent as Logo } from '../../../../../images/Circle.svg';

const AuditCertificate: React.FC<any> = ({ values, onCertificateClick, index, className }): JSX.Element => {

  return (
    <div className="inline-block m-5">
      <div onClick={() => onCertificateClick(values, index) } className={`audit-certificate flex w-96 items-center shadow-xl p-3 rounded-2xl cursor-pointer ${className}`}>
        <div className="mr-5">
          <Logo width="36px" height="36px" fill="#05B3ED"/>
        </div>
          <div>
            <p className="text-xl">{values.name}</p>
            <p className="text-blue-500 font-bold">Certificate</p>
          </div>
      </div>
    </div>
  )
};

export default AuditCertificate;