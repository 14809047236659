import { message, } from "antd";
import React, { useCallback, useEffect, useState } from "react";


import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";

import moment from 'moment';

import { Row, Col, Image, Breadcrumb } from "react-bootstrap";

import mysaQR from "../../../../images/mysa-audits.png";

import checkInIcon from "../../../../images/icons/svg/outside/icon-check-in.svg";
import checkOutIcon from "../../../../images/icons/svg/outside/icon-check-out.svg";
import InfoIcon from "../../../../images/icons/svg/outside/Icon-Information.svg";
// import calendarIcon from "../../../images/icons/svg/outside/icon-calendar.svg";
import shareIcon from "../../../../images/icons/svg/outside/icon-share.svg";



import Fancybox from "../../../common/fancyBox";
import BookPropertyMap from "../../../common/BookPropertyMap";
import { Link } from "react-router-dom";
import { isBooker } from "../../../../common/functions";



const PropertyPage: React.FC<any> = ({ location }): JSX.Element => {

  const [bookingList, setBookingList] = useState<any>();
  const [imageList, setImageList] = useState<any>();
  const [propertyImageList, setPropertyImageList] = useState<any>();
  const [amenityList, setAmenityList] = useState<any>([]);
  const [guestComfortAmenityList, setGuestComfortAmenityList] = useState<any>([]);
  const [InventoryAmenityList, setInventoryAmenityList] = useState<any>([]);
  const [locations, setLocations] = useState<any>(null);
  const [building, setBuilding] = useState<any>(null);

  const [travelMode, setTravelMode] = useState<any>("WALKING");
  const [distance, setDistance] = useState<any>("0.0 km");
  const [duration, setDuration] = useState<any>("00 mins");
  const [checkInDate, setCheckInDate] = useState<any>("");
  const [checkOutDate, setCheckOutDate] = useState<any>("");
  const [noOfNights, setNoOfNights] = useState<any>();

  const [data, setData] = useState<any>([]);
  const [certCopyUrl, setCertCopyUrl] = useState('');

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {

    getClientLocationById,

    getAuditCertificates,

  } = useStoreActions<any>((actions) => ({

    getClientLocationById: actions.client.getClientLocationById,

    getAuditCertificates: actions.audit.getAuditCertificates,

  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {

    getClientLocationByIdSuccess,
    getClientLocationByIdError,

    getAuditCertificatesSuccess,

  } = useStoreState<any>((state) => ({

    getClientLocationByIdSuccess: state.client.getClientLocationByIdSuccess,
    getClientLocationByIdError: state.client.getClientLocationByIdError,


  }));
  ////////////////////////////////////////////////////////////////////////////////////////////////////



  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (location?.state?.query?.locationId) {
      if (location?.state?.query?.locationId) {
        getClientLocationById(location?.state?.query?.locationId);
      }
    }
  }, [getClientLocationById, location?.state?.query?.locationId]);



  useEffect(() => {
    if (getAuditCertificatesSuccess && getAuditCertificatesSuccess.certificates.length) {
      const issuedCertificate = getAuditCertificatesSuccess.certificates.find(certificate => certificate.issued);
      if (issuedCertificate) {
        setData(issuedCertificate);
      }
    } else {
      setData([]);
    }
  }, [getAuditCertificatesSuccess]);


  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {

    const book: Array<object> = [];
    book.push(location?.state?.query.data);
    setLocations({
      lat: Number(
        location?.state?.query.data?.property.latitude
      ),
      lng: Number(
        location?.state?.query.data?.property.longitude
      ),
      buildingName:
        location?.state?.query.data?.property.propertyName,
    })
    setBuilding({
      lat: Number(
        location?.state?.query.data?.property.latitude
      ),
      lng: Number(
        location?.state?.query.data?.property.longitude
      ),
      buildingName:
        location?.state?.query.data?.property.propertyName,
      propertyId:
        location?.state?.query.data?.property._id,
      toEmail:
        location?.state?.query.data?.property.systemNotificationEmail,
      address:
        location?.state?.query.data?.property.address1,
    });

    setBookingList(book);

    //   }
    // }

  }, [location?.state?.query.data]);


  useEffect(() => {

    const guest: Array<object> = [];
    const bed: Array<object> = [];
    const amenity: Array<object> = [];
    const inventory: Array<object> = [];
    const propImage: Array<object> = [];

    _.map(
      location?.state?.query.data
        .propertyInventory.bedType,
      (element) => {
        bed.push(element.name);
      }
    );
    if (
      location?.state?.query.data
        .propertyAmenity
    ) {
      _.map(
        location?.state?.query.data
          .propertyAmenity.amenity,
        (element) => {
          amenity.push(element);
          setAmenityList(amenity);
        }
      );
    }

    if (
      location?.state?.query.data
        .inventoryAmenity
    ) {
      _.map(
        location?.state?.query.data
          .inventoryAmenity.amenity,
        (element) => {
          inventory.push(element);
          setInventoryAmenityList(inventory);
        }
      );
    }

    if (
      location?.state?.query.data
        .guestComfortAmenity
    ) {
      _.map(
        location?.state?.query.data
          .guestComfortAmenity.amenities,
        (element) => {
          guest.push(element);
          setGuestComfortAmenityList(guest);
        }
      );
    }

    setPropertyImageList(propImage);
    //   }
    // }

  }, [location?.state?.query.data]);
  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {


    const image: Array<object> = [];




    _.map(
      location?.state?.query.data?.inventoryImage?.image,
      (element) => {
        image.push(element);
      }
    );
    _.map(
      location?.state?.query.data?.propertyImage?.image,
      (element) => {
        image.push(element);
      }
    );


    setImageList(image);

    //   }
    // }

  }, [location?.state?.query.data]);
  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getClientLocationByIdSuccess) {
      const locationData = getClientLocationByIdSuccess.data;
      if (locationData) {
        setLocations({
          lat: Number(locationData?.latitude),
          lng: Number(locationData?.longitude),
          locationName: locationData?.locationName,
        });
      }

    }
    if (getClientLocationByIdError) {
      message.error(getClientLocationByIdError.message);
    }
  }, [getClientLocationByIdError, getClientLocationByIdSuccess]);


  useEffect(() => {
    if (data && typeof data === 'object' && !Array.isArray(data)) {
      const certificateId = data.id;
      const certCopyUrl = process.env.REACT_APP_AUDIT_API_URL + `certificate/stamp/${certificateId}`;
      setCertCopyUrl(certCopyUrl);
    } else {
      setCertCopyUrl('');
    }
  }, [data]);




  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderImage = useCallback(() => {
    if (imageList?.length >= 1 || propertyImageList?.length >= 1) {
      return (


        <div className="relative">
          <Fancybox>
            <p>
              {
                imageList[0] ?
                  (
                    <a
                      className="fancyBtn"
                      data-fancybox="gallery"
                      href={imageList[0]?.url}
                    >
                      <img alt="Property Images" src={imageList[0]?.url} style={{
                        display: "none"
                      }} />
                      {/* <span>
                  <img src={searchIcon} className="icon" alt="" />
                </span> */}
                      View All
                    </a>
                  ) : (
                    <a
                      className="fancyBtn"
                      data-fancybox="gallery"
                      href={propertyImageList[0]?.url}
                    >
                      <img alt="Property Images" src={propertyImageList[0]?.url} style={{
                        display: "none"
                      }} />

                      View All
                    </a>
                  )
              }
              {imageList?.map((curElemImg, index) => (
                <>
                  {index !== 0 && (
                    <a
                      key={index}
                      data-fancybox="gallery"
                      href={curElemImg.url}
                      style={{ display: "none" }}
                    >
                      <img alt="Inventary Images" src={curElemImg.url} />
                    </a>
                  )}
                </>
              ))}
              {propertyImageList?.map((curElemPropImg, index) => (
                <>
                  {imageList[0] ? (
                    <a
                      key={index}
                      data-fancybox="gallery"
                      href={curElemPropImg.url}
                      style={{ display: "none" }}
                    >
                      <img alt="Property Images" src={curElemPropImg.url} />
                    </a>
                  ) : (
                    <>
                      {index !== 0 && (
                        <a
                          key={index}
                          data-fancybox="gallery"
                          href={curElemPropImg.url}
                          style={{ display: "none" }}
                        >
                          <img alt="Inventary Images" src={curElemPropImg.url} />
                        </a>
                      )}
                    </>
                  )
                  }
                </>
              ))}
            </p>
          </Fancybox>
          <Row
            style={{
              marginTop: "10px",
            }}
          >
            <Col md={6}>
              {
                imageList[0] ?
                  (
                    <div key={imageList[0]?._id}>
                      <div className="card2  property-item bg-auto">
                        <Image
                          src={imageList[0]?.url}
                          alt="avatar"
                          className="property-image rounded-2xl"
                        ></Image>
                      </div>
                    </div>
                  ) :
                  (
                    <div key={propertyImageList[0]?._id}>
                      <div className="card2  property-item bg-auto">
                        <Image
                          src={propertyImageList[0]?.url}
                          alt="avatar"
                          className="property-image rounded-2xl"
                        ></Image>
                      </div>
                    </div>
                  )
              }


            </Col>
            <Col md={3} style={{ marginInlineStart: "0px" }}>
              <div key={imageList[1]?._id}>
                <div className="card2  property-item bg-auto">
                  <Image
                    src={imageList[1]?.url}
                    alt="avatar"
                    className="property-img rounded-2xl"
                  ></Image>
                </div>
              </div>
            </Col>
            <Col md={3} style={{ marginInlineStart: "0px" }}>
              <div key={imageList[2]?._id}>
                <div className="card2  property-item bg-auto">
                  <Image
                    src={imageList[2]?.url}
                    alt="avatar"
                    className="property-img rounded-2xl"
                  ></Image>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }, [imageList, propertyImageList]);



  // useEffect(() => {

  //   const clientId = getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('programmeData')?.clientId._id ? getStorage('programmeData')?.clientId._id :
  //     getStorage('selectedFilterClient')?.value ? getStorage('selectedFilterClient')?.value : '';
  //   const programmeId = getStorage('makeBookingProgram')?.value ? getStorage('makeBookingProgram')?.value : getStorage('selectedProgramsOption')?.value ? getStorage('programmeId') : getStorage('programmeData')?._id;
  //   const bookingUserId = getStorage('myo_logged_user').userData._id;

  //   const payload = {
  //     clientId: clientId,
  //     programmeId: programmeId,
  //     bookerUserId: bookingUserId,
  //     propertyId: location?.state?.query.data?.property?._id,
  //     inventoryId: location?.state?.query.data?.propertyInventory?._id,
  //     hashGet: location?.state?.query?.data?.hashGet,
  //     cacheKey: location?.state?.query?.data?.cacheKey,
  //     ...location?.state?.requestId && { requestId: location?.state?.requestId },
  //     bookingStatus: LIVE_BOOKING_STATUS.UP_COMING
  //   }

  //   secureAvailabilityAndRate(payload);

  // }, [location?.state?.query.data?.cacheKey, location?.state?.query.data?.hashGet, location?.state?.query.data?.property?._id, location?.state?.query.data?.propertyInventory?._id, location?.state?.requestId, secureAvailabilityAndRate]);

  // useEffect(() => {
  //   const selectedTravellerInfo = { value: getStorage('myo_logged_user')?.userData?._id, label: getStorage('myo_logged_user')?.userData?.email };
  //   if (getAddTravelerToSecureRateKeySuccess?.data) {
  //     push('/admin/booking-payment', { query: location?.state?.query, selectTraveller: selectedTravellerInfo, secureRateKeyId: secureKey });
  //     // setLoading(false);
  //   }

  //   if (getSecureAvailabilityAndRateSuccess?.data) {
  //     setSecureKey(getSecureAvailabilityAndRateSuccess?.data._id);
  //   }

  //   if (getAddTravelerToSecureRateKeyError) {
  //     // setLoading(false);
  //     toast.error(getAddTravelerToSecureRateKeyError?.message, {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       className: 'foo-bar'
  //     });
  //   }

  // }, [getAddTravelerToSecureRateKeyError, getAddTravelerToSecureRateKeySuccess?.data, getSecureAvailabilityAndRateSuccess?.data, location?.state?.query, push, secureKey]);




  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onChangeTravelMode = useCallback((value) => {
    if (value) {
      setTravelMode(value);
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onResponse = useCallback((event) => {
    setDuration(event.routes[0].legs[0].duration.text);
    setDistance(event.routes[0].legs[0].distance.text);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderBuildingAmenity = useCallback(() => {
    if (amenityList) {
      return amenityList.map((acc: any, key: number) => {
        return (
          <Row style={{ marginBottom: "20px" }}>
            <Col md={2}>
              <div style={{ textAlign: "right" }}>
                <Image src={acc.iconLocation} alt="" width={40} />
              </div>
            </Col>
            <Col md={10}>
              <div style={{ marginTop: "8px", marginLeft: "-10px" }}>
                <p>{acc.name}</p>
              </div>
            </Col>
          </Row>
        );
      });
    }
  }, [amenityList]);

  const renderComfortAmenity = useCallback(() => {
    if (guestComfortAmenityList) {
      return guestComfortAmenityList.map((acc: any, key: number) => {
        return (
          <Row style={{ marginBottom: "20px" }}>
            <Col md={2}>
              <div style={{ textAlign: "right" }}>
                <Image src={acc.iconLocation} alt="" width={40} />
              </div>
            </Col>
            <Col md={10}>
              <div style={{ marginTop: "8px", marginLeft: "-10px" }}>
                <p>{acc.name}</p>
              </div>
            </Col>
          </Row>
        );
      });
    }
  }, [guestComfortAmenityList]);

  const renderSpaceAmenity = useCallback(() => {
    if (InventoryAmenityList) {
      return InventoryAmenityList.map((acc: any, key: number) => {
        return (
          <Row style={{ marginBottom: "20px" }}>
            <Col md={2}>
              <div style={{ textAlign: "right" }}>
                <Image src={acc.iconLocation} alt="" width={40} />
              </div>
            </Col>
            <Col md={10}>
              <div style={{ marginTop: "8px", marginLeft: "-10px" }}>
                <p>{acc.name}</p>
              </div>
            </Col>
          </Row>
        );
      });
    }
  }, [InventoryAmenityList]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderBooking = useCallback(() => {
    return bookingList && bookingList.map((acc: any, key: number) => {
      return (
        <div className="propertyInfo" key={key}>
          <div
            className="propertyDetail"
            style={{
              width: "100%",
              background: "rgb(242, 242, 242)",
              color: "rgb(0, 0, 0)",
              borderRadius: "30px",
              border: "none",
              marginBottom: "20px",
              padding: "2.5rem",
            }}
          >
            <div
              className="propertyDetailWrap"
              style={{
                display: "flex",
              }}
            >
              <div
                className="propertyDetailImg"
                style={{ marginRight: "20px", flex: "0 0 120px" }}
              >
                <Image
                  src={acc.property.brandId.image}
                  alt="avatar"
                  style={{
                    height: "120px",
                    aspectRatio: "1/1",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                ></Image>
              </div>
              <div className="propertyDetailInfo flex-grow">
                <div
                  className="propertyDetailName"
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    lineHeight: "42px",
                  }}
                >
                  {acc.property.propertyName}
                </div>
                <div
                  className="propertyDetailSubName"
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    lineHeight: "32px",
                    marginBottom: "5px",
                  }}
                >
                  by {acc.property.brandId.name}
                </div>
                <div
                  className=""
                  style={{
                    fontSize: "26px",
                    fontWeight: "normal",
                    lineHeight: "36px",
                    marginTop: "0px",
                  }}
                >
                  {acc.propertyInventory.accommodationDetail}
                </div>
                <div
                  className="propertyDetailAddress"
                  style={{
                    fontSize: "18px",
                    lineHeight: "28px",
                    margin: "8px 0 18px",
                  }}
                >
                  {" "}
                  <strong>{acc.property?.propertyType?.name}</strong>{" "}
                  {acc.property?.address1} {acc.property?.city}{" "}
                  {acc.property?.postcode}
                </div>
                <div className="checkinDetails flex align-items-center mb-2">
                  <img
                    className="icon"
                    src={checkInIcon}
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                  <div className="d-flex align-items-center flex-grow flex-wrap" style={{ fontSize: "22px", lineHeight: "32px" }}>
                    <span
                      style={{
                        flex: "0 0 150px",
                      }}
                    >{"Check-in time  "}</span>
                    <span className="font-bold ml-3">
                      {checkInDate}
                    </span>
                    <span className="font-bold ml-3">
                      {acc.propertyCheckInProcess?.checkInTime}
                    </span>
                  </div>
                </div>
                <div className="checkOutDetails flex align-items-center">
                  <img
                    className="icon"
                    src={checkOutIcon}
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                  <div className="d-flex align-items-center flex-grow flex-wrap" style={{ fontSize: "22px", lineHeight: "32px" }}>
                    <span
                      style={{
                        flex: "0 0 150px",
                      }}
                    >{"Check-out time  "}</span>
                    <span className="font-bold ml-3">
                      {checkOutDate}
                    </span>
                    <span className="font-bold ml-3">
                      {acc.propertyCheckInProcess?.checkOutTime}
                    </span>
                  </div>
                </div>
              </div>
              <div className="propertyDetailPriceSide text-right">
                <div className="propertyShareLink flex justify-end">
                  <img
                    className="icon"
                    src={shareIcon}
                    alt=""
                    style={{ width: "25px", height: "25px" }}
                  />
                  <div
                    className=""
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      lineHeight: "28px",
                    }}
                  >
                    Share with traveller
                  </div>
                </div>
                <div className="priceList">
                  <span className="font-bold" style={{ fontSize: "26px" }}>
                    {" "}
                    {acc.property?.currency}{" "}
                    {(Number(acc.rateAndPolicy?.finalRate) / location?.state?.query.details?.noOfNights).toFixed(0)}
                  </span>
                  <br />
                  <span className="" style={{ fontSize: "15px" }}>
                    {" "}
                    {"Total "}
                    {acc.property?.currency} {acc.rateAndPolicy?.finalRate}
                  </span>

                  <img
                    className=""
                    src={InfoIcon}
                    alt=""
                    style={{
                      display: "inline-block",
                      width: "35px",
                      height: "35px",
                    }}
                  />
                </div>

              </div>
            </div>
          </div>

          {/* Property Map */}
          <div
            className="propertyMap"
            style={{
              marginBottom: "2rem",
            }}
          >
            <div>
              <p
                className=""
                style={{
                  fontSize: "32px",
                  fontWeight: "bold",
                  lineHeight: "42px",
                }}
              >
                Location
              </p>
            </div>
            <div className="flex align-items-center mb-3">
              <div>
                Distance : {distance} | {duration}
              </div>
              <div className="travel-mode p-0">
                <span
                  onClick={() => {
                    onChangeTravelMode("WALKING");
                  }}
                  className={`${travelMode === "WALKING" ? "travel-mode__selected" : ""
                    }`}
                >
                  Walking
                </span>
                <span
                  onClick={() => {
                    onChangeTravelMode("DRIVING");
                  }}
                  className={`${travelMode === "DRIVING" ? "travel-mode__selected" : ""
                    }`}
                >
                  Driving
                </span>
                <span
                  onClick={() => {
                    onChangeTravelMode("TRANSIT");
                  }}
                  className={`${travelMode === "TRANSIT" ? "travel-mode__selected" : ""
                    }`}
                >
                  Public transport
                </span>
              </div>
            </div>
            <BookPropertyMap
              googleMapURL={process.env.REACT_APP_MAP_URL}
              loadingElement={
                <div style={{ height: `100%`, width: `100%` }} />
              }
              containerElement={
                <div style={{ height: `350px`, width: `100%` }} />
              }
              mapElement={<div style={{ height: `100%`, width: `100%` }} />}
              location={locations}
              building={building}
              travelMode={travelMode}
              onResponse={onResponse}
            />
          </div>

          {/* The Living Space */}
          <div className="aboutFisher" id="about-brand">
            <div>
              <div className="">
                <p
                  className=""
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    lineHeight: "42px",
                  }}
                >
                  The Living Space
                </p>
              </div>
              <div className="">
                {/* <p style={{ marginBottom: "0px" }}>
                  {acc.propertyInventory?.description}
                </p> */}
                <div dangerouslySetInnerHTML={{ __html: acc.propertyInventory?.description }}></div>
              </div>
            </div>
          </div>

          {/* About Property */}
          <div className="aboutProperty" id="about-property">
            <div>
              <div className="">
                <p
                  className=""
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    lineHeight: "42px",
                  }}
                >
                  About The Property{" "}
                </p>
              </div>
              <div className="" style={{ marginTop: "0px" }}>
                {/* <p style={{ marginBottom: "0px" }}> */}

                <div dangerouslySetInnerHTML={{ __html: acc.property.description }}>
                </div>
                {/* </p> */}
              </div>
            </div>
          </div>
          {/* About Fisher Apartment */}
          <div className="aboutFisher" id="about-brand">
            <div>
              <div className="">
                <p
                  className=""
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    lineHeight: "42px",
                  }}
                >
                  About {acc.property.brandId.name}{" "}
                </p>
              </div>
              <div className="">
                {/* <p style={{ marginBottom: "0px" }}>
                  {acc.property?.brandId?.description}
                </p> */}
                <div dangerouslySetInnerHTML={{ __html: acc.property?.brandId?.description }}></div>
              </div>
            </div>
          </div>

          <div></div>
          {/* Audits */}
          <div
            className="mysaAudits flex align-items-center"
            id="mysa-audits"
          >
            <div
              className="mysa-audit-qr"
              style={{
                background: "#fff",
                padding: "13px",
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                borderRadius: "22px",
                marginRight: "30px",
                border: "1px solid #b8b7b7",

              }}
            >
              {certCopyUrl ?
                <img src={certCopyUrl} alt="QR code" width={380} height={380} /> :
                <img src={mysaQR} alt="QR code" width={380} height={380} />
              }
            </div>
            <div className="mysa-audits-content">
              <div className="">
                <p
                  className=""
                  style={{
                    fontSize: "36px",
                    fontWeight: "bold",
                    lineHeight: "44px",
                    marginBottom: "30px",
                  }}
                >
                  mysaSure Verification
                </p>
              </div>
              <ul
                style={{
                  padding: "0",
                }}
              >
                <li
                  style={{
                    display: "flex",
                    fontFamily: "Open Sans",
                    fontSize: "18px",
                    fontWeight: "normal",
                    lineHeight: "22px",
                  }}
                >
                  <span
                    className="essentialHeadList"
                    style={{
                      fontWeight: "bold",
                      flex: "0 0 200px",
                    }}
                  >
                    Premium Certificate
                  </span>{" "}
                  <span className="essentialListItem flex-grow">
                    {data?.name}
                  </span>
                </li>
                <li
                  style={{
                    display: "flex",
                    fontFamily: "Open Sans",
                    fontSize: "18px",
                    fontWeight: "normal",
                    lineHeight: "22px",
                  }}
                >
                  <span
                    className="essentialHeadList"
                    style={{
                      fontWeight: "bold",
                      flex: "0 0 200px",
                    }}
                  >
                    Verified
                  </span>{" "}
                  <span className="essentialListItem flex-grow">
                    {data?.issuedOn
                      ? moment(data?.issuedOn).format('DD MMM YYYY')
                      : "Not Verified"
                    }
                  </span>
                </li>

              </ul>
            </div>
          </div>

        </div>
      );
    });
  }, [bookingList, building, certCopyUrl, checkInDate, checkOutDate, data?.issuedOn, data?.name, distance, duration, location?.state?.query.details?.noOfNights, locations, onChangeTravelMode, onResponse, travelMode]);


  const renderBooking2 = useCallback(() => {
    if (bookingList) {
      return bookingList.map((acc: any, key: number) => {
        return (
          <div className="propertyInfo" key={key}>
            {/* Essential Info */}
            <div
              className="essentialInfo"
              style={{
                background: "transparent",
                padding: "30px",
                marginBottom: "30px",
                borderRadius: "30px",
                border: "1px solid #00000066",
              }}
            >
              <div className="">
                <p
                  className=""
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    lineHeight: "40px",
                    marginBottom: "30px",
                  }}
                >
                  Essential Information
                </p>
              </div>
              <ul
                style={{
                  padding: "0",
                }}
              >
                {acc.guestComfortAmenity?.areFacilitiesAndPublicAreaAccessible && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Disabled Access
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.guestComfortAmenity?.areFacilitiesAndPublicAreaAccessible}
                    </span>
                  </li>
                )}
                {acc.propertyInventory?.areAllApartmentsNonSmoking && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Non-smoking
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.propertyInventory?.areAllApartmentsNonSmoking}
                    </span>
                  </li>
                )}
                {acc.propertyInventory?.whatBestDescribeYourApartments && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Kitchen
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.propertyInventory?.whatBestDescribeYourApartments}
                    </span>
                  </li>
                )}
                {acc.propertyInventory?.bedType && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                      flexWrap: "wrap"
                    }}
                  >
                    {acc.propertyInventory?.bedType.map((curbedtype, index) => {
                      return (
                        <div className="bedroomEssInfo" key={index} style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flex: "0 0 100%"
                        }}>
                          <span
                            className="essentialHeadList"
                            style={{ flex: "0 0 70%", fontWeight: "bold" }}
                          >
                            Bedroom {index + 1}
                          </span>
                          <span
                            className="essentialListItem"
                            style={{ flex: "0 0 30%" }}
                          >
                            {curbedtype.name}
                          </span>
                        </div>
                      )

                    })
                    }
                  </li>
                )}
                {acc.guestComfortAmenity?.detailsOfThePillows && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Pillow types(s)
                    </span>
                    <div
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.guestComfortAmenity?.detailsOfThePillows.map((curpillow, index) => {
                        return (
                          <span key={index} style={{ display: "block" }}>{curpillow}</span>
                        )

                      })
                      }
                    </div>
                  </li>
                )}
                {acc.propertyInventory?.noOfBathrooms && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      No of bathrooms
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.propertyInventory?.noOfBathrooms}
                    </span>
                  </li>
                )}
                {acc.propTransport?.doYouProvideOnSiteCarParking && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Car parking
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.propTransport?.doYouProvideOnSiteCarParking}
                    </span>
                  </li>
                )}
                {acc.guestComfortAmenity?.carParkingBays && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Disabled car parking
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.guestComfortAmenity?.carParkingBays}
                    </span>
                  </li>
                )}
                {acc.propTransport?.ifYouDoNotProvideOnSiteCarParking && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Nearest public car park
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.propTransport?.ifYouDoNotProvideOnSiteCarParking}
                    </span>
                  </li>
                )}
                {acc.guestComfortAmenity?.convenienceFoodStore && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Nearest convenience store
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.guestComfortAmenity?.convenienceFoodStore}mins
                    </span>
                  </li>
                )}
                {acc.propertyInventory?.noOfRollOutBedsAvailable > 0 && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Roll-out bed available ({acc.propertyInventory?.noOfRollOutBedsAvailable})
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      €{acc.propertyInventory?.extraCostPerBed}pn
                    </span>
                  </li>
                )}
                {acc.propertyInventory?.noOfCotsAvailable > 0 && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Cot Available ({acc.propertyInventory?.noOfCotsAvailable})
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      €{acc.propertyInventory?.extraCostOfCot}pn
                    </span>
                  </li>
                )}
                {acc.guestComfortAmenity?.nearestGym && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Nearest Gym
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.guestComfortAmenity?.nearestGym}mins
                    </span>
                  </li>
                )}
                {acc.guestComfortAmenity?.gymPayAsYouGo && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Gym pay as you go
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.guestComfortAmenity?.gymPayAsYouGo}
                    </span>
                  </li>
                )}
                {acc.guestComfortAmenity?.gymShortTermMember && (
                  <li
                    style={{
                      display: "flex",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                    }}
                  >
                    <span
                      className="essentialHeadList"
                      style={{ flex: "0 0 70%", fontWeight: "bold" }}
                    >
                      Gym short term membership
                    </span>
                    <span
                      className="essentialListItem"
                      style={{ flex: "0 0 30%" }}
                    >
                      {acc.guestComfortAmenity?.gymShortTermMember}
                    </span>
                  </li>
                )}
              </ul>
            </div>
            {/* Comfort Building Space */}
            <div
              className="comfortBuilding"
              style={{
                background: "#f2f2f2",
                padding: "30px",
                marginBottom: "30px",
                borderRadius: "30px",
              }}
            >
              <div className="">
                <p
                  className=""
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    lineHeight: "42px",
                    marginBottom: "30px",
                  }}
                >
                  Amenities/Comfort{" "}
                </p>
              </div>
              {renderComfortAmenity()}
              {renderBuildingAmenity()}
              {renderSpaceAmenity()}
            </div>
          </div>
        );
      });
    }
  }, [
    bookingList,
    renderBuildingAmenity,
    renderComfortAmenity,
    renderSpaceAmenity,
  ]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      <Row >
        <Col md={6}>

          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={{ pathname: (location?.state?.query?.isBookerPage && !isBooker()) ? "/admin/corporate-booking-reports" : (location?.state?.query?.isBookerPage && isBooker()) ? "/admin/booker-booking-reports" : "/admin/traveler-booking-reports", state: { tabKey: location?.state?.query?.isBookerPage ? 'travelerOptions' : 'options', isShowPage: true } }} >Options</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{location?.state?.query?.details?.propertyId?.propertyName} by {location?.state?.query.details?.propertyId?.brandId?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>

      </Row>
      <div
        className="flex align-items-center justify-content-end"
        style={{ marginBottom: "40px" }}
      >

      </div>

      <div style={{ marginBottom: "30px" }}>{renderImage()}</div>

      <Row>
        <Col md={12} lg={8}>
          <div>{renderBooking()}</div>
        </Col>
        <Col md={12} lg={4}>
          <div>{renderBooking2()}</div>
        </Col>
      </Row>

      {/* {isShowTravelerProfile && (
        <>
          <TravellerProfileSearch />
        </>
      )} */}
    </div>
  );
};

export default PropertyPage;
