import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { navStyle, tabDivStyle } from "../../../../common/components-style";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import BookingSuperAdmin from "../bookings/super-admin/BookingSuperAdmin";
import RequestBookingSuperAdminHome from "../request-booking/super-admin/RequestBookingSuperAdminHome";

const SuperAdminBookingReports: React.FunctionComponent = () => {

    // url manage
    const location = useLocation<any>();

    ////////////////////////// local state manage ///////////////////////////////////////////////
    //filter program
    const [tabKey, setTabKey] = useState<any>(location.state?.tabKey ? location.state?.tabKey : 'bookings');

    return (
        <div style={tabDivStyle}>
            <Tab.Container defaultActiveKey="bookings" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                <Row >
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                            <div style={{ marginTop: '0%' }}>
                                <Nav.Item>
                                    <Nav.Link eventKey="bookings">Bookings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="requestBooking">Request Bookings</Nav.Link>
                                </Nav.Item>
                            </div>
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content >
                            <Tab.Pane eventKey="bookings" >
                                <BookingSuperAdmin />
                            </Tab.Pane>
                            <Tab.Pane eventKey="requestBooking" >
                                <RequestBookingSuperAdminHome />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );

};

export default SuperAdminBookingReports;