import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Row, Container, Image } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import bookingdetails from "../../../../../../../images/icons/svg/bookingdetails.svg";
import moment from "moment-timezone";
import { daysBetween } from "../../../../../../../common/functions";
import CancelBookingWithoutCharge from "../../../../components/CancelBookingWithoutCharge";
import { LIVE_BOOKING_STATUS } from "../../../../../../../common/constants";
import { toast } from "react-toastify";
import { slideImageStyle } from "../../../../../../../common/components-style";

const BookingSIngleView: React.FunctionComponent = () => {

    /**
     * redirect hooks
     */
    const { push } = useHistory();

    /**
     * get param 
     */
    const { id }: any = useParams();

    /**
     * global actions
     */
    const { getBookingById, bookingStatus } = useStoreActions<any>((actions) => ({
        getBookingById: actions.booking.getBookingById,
        bookingStatus: actions.booking.bookingStatus
    }));

    /**
     * global status
     */
    const { getBookingByIdSuccess, getBookingStatusSuccess, getBookingStatusError } = useStoreState<any>((state) => ({
        getBookingByIdSuccess: state.booking.getBookingByIdSuccess,
        getBookingStatusSuccess: state.booking.getBookingStatusSuccess,
        getBookingStatusError: state.booking.getBookingStatusError,
    }));

    /**
     * local state management
     */
    const [singleBooking, setSingleBooking] = useState<any>('');
    const [cancelBoxDisplay, setCancelBoxDisplay] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    /**
     * react life cycle 
     */
    useEffect(() => {
        if (id) {
            getBookingById({ bookingID: id });
        }
    }, [getBookingById, id]);

    /**
     * back to the list page
     */
    const BackToList = useCallback(() => {
        push(`/admin/super-admin-booking-reports`);
    }, [push])

    /**
     * response booking manage
     */
    useEffect(() => {
        if (getBookingByIdSuccess?.data) {
            setSingleBooking(getBookingByIdSuccess.data);
        }

        if (getBookingStatusSuccess?.data) {
            setLoading(false);
            BackToList();
        }

        if (getBookingStatusError) {
            setLoading(false);
            toast.error(getBookingStatusError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }, [BackToList, getBookingByIdSuccess, getBookingStatusError, getBookingStatusSuccess]);

    /**
     * cancel button show hide
     */
    const CancelBtnAct = () => {
        setCancelBoxDisplay(true);
    }

    /**
     * cancel pending
     */
    const cancelPending = () => {
        setLoading(true);

        const payload = {
            clientId: singleBooking?.clientId?._id,
            programmeId: singleBooking?.programmeId?._id,
            brandId: singleBooking?.brandId?._id,
            // inventoryId: singleBooking?.inventoryId?._id,
            propertyId: singleBooking?.propertyId?._id,
            bookerId: singleBooking?.bookerUserId?._id,
            bookingStatus: LIVE_BOOKING_STATUS.CANCEL_PENDING,
            id:singleBooking?.liveBookingId,
            cancelRequestDate: new Date()
        }

        bookingStatus(payload);
    };


    return (<>
        <Row >
            <Col md={6}>

                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/super-admin-booking-reports" }} >Booking Detail</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{singleBooking?.propertyId?.propertyName}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col md={6}>
                <Button id='blue-button' style={{ width: '190px', marginLeft: '50px' }} >
                    <b>Message Property</b>
                </Button>

                {
                    (singleBooking?.bookingStatus !== LIVE_BOOKING_STATUS.CANCELLED) &&

                    <Button id='edit-button' style={{ width: '190px', marginLeft: '50px' }} >
                        <b>Amend</b>
                    </Button>

                }
                {
                    singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.UP_COMING &&

                    <Button id='megenta-button' style={{ width: '190px', marginLeft: '50px' }} onClick={CancelBtnAct}>
                        <b>Cancel</b>
                    </Button>

                }
            </Col>
        </Row>
        <Row className="mt-5">
            <Col md={3} xs={12} className="text-center">
                <Image width={300} src={singleBooking?.inventoryInfo?.inventoryImage} alt={singleBooking?.inventoryInfo?.inventoryImage} style={slideImageStyle}>
                </Image>
                {/* <img width={'100%'} src={singleBooking?.inventoryInfo?.inventoryImage} alt={singleBooking?.inventoryInfo?.inventoryImage} style={{ borderRadius: 40, height: '70%' }} /> */}
            </Col>
            <Col md={6} xs={12}>
                <div className="">
                    <p style={{ fontSize: '25px' }}>Booking Ref. <strong>{singleBooking?.liveBookingId}</strong></p>
                    <p style={{ fontSize: '30px' }}><strong>{singleBooking?.propertyId?.propertyName}</strong> <span style={{ fontSize: '20px' }}>by {singleBooking?.brandId?.name}</span></p>
                    <p style={{ fontSize: '22px' }}><strong>{singleBooking?.propertyInfo?.propertyType}</strong> <span style={{ fontSize: '20px' }}>{`${singleBooking?.propertyId?.address1} ${singleBooking?.propertyId?.address2}`}</span></p>
                    <p style={{ fontSize: '20px' }}>{`${singleBooking?.inventoryInfo?.accommodationConfiguration}`}</p>
                </div>
            </Col>
        </Row >

        {/* cancel when status changed as an cancel */}
        {
            singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED && <Row className="mt-5">
                <Container fluid className="p-0">
                    <div className="jumbotron" style={{ marginTop: "0px" }}>
                    <div className="text-center" style={{ fontSize: '26px', }}>
                        <Row>
                                <Col md={2}></Col>
                                <Col md={8}>
                                    <strong>Booking Cancelled </strong>
                                </Col>
                                <Col md={2}>
                                </Col>
                            </Row>
                            <div className="text-center" style={{ fontSize: '26px', marginTop: 35 }}>
                                <Row>
                                    <Col md={2}></Col>
                                    <Col md={8}>
                                        <strong>
                                            Nights charged {(typeof singleBooking?.numberOfNightWithCharge === 'undefined' ? '0' : `${singleBooking?.numberOfNightWithCharge}`)}
                                        </strong>
                                    </Col>
                                    <Col md={2}></Col>
                                </Row>

                                <div>

                                </div>
                            </div>
                        </div>

                    </div>
                </Container>
            </Row>
        }
        <Row className="mt-5">
            <Container fluid className="p-0">
                <div className="jumbotron" style={{ marginTop: "-20px" }}>
                    <div className="d-flex flex-row">
                        <div className="p-2">  <img width={'100%'} src={bookingdetails} alt={`booking details`} /></div>
                        <div className="p-2"> <h3> Booking Details</h3></div>
                    </div>
                    <div className="mt-3">
                        <Row>
                            <Col md={6} xs={6}>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>First Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.travelerId?.firstName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Second Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.travelerId?.lastName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Email Address</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.travelerId?.email}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Booker First Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookerUserId?.firstName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Booker Second Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookerUserId?.lastName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Booker Email</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookerUserId?.email}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Date Booked</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{moment(singleBooking?.bookingDate).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Company</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.clientId?.companyName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Programme</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.programmeId?.programmeName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Accommodation</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.inventoryInfo?.accommodationDetail}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Number of Beds</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.inventoryInfo?.noOfBedrooms}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in Method</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.propertyInfo?.propertyCheckInProcess?.checkInProcess?.name}</Col>
                                </Row>
                            </Col>

                            <Col md={6} xs={6}>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>No of Nights</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{daysBetween(new Date(singleBooking.checkInDate), new Date(singleBooking.checkOutDate))}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Average Nightly Rate (ex Tax)</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{`${singleBooking?.bookingRateAndPolicy?.dailyRate[0]?.rate} (${singleBooking?.propertyId?.currency})`}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Total Rate (ex Tax)</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{`${singleBooking?.bookingRateAndPolicy?.finalRate} (${singleBooking?.propertyId?.currency})`}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED ? `${LIVE_BOOKING_STATUS.CANCELLED}` : moment(singleBooking?.checkInDate).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in Charge Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED && (typeof singleBooking?.checkInChargeDate === 'undefined') ? `${LIVE_BOOKING_STATUS.CANCELLED}` : singleBooking?.bookingStatus !== LIVE_BOOKING_STATUS.CANCELLED ? '-' : moment(singleBooking?.checkInChargeDate).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in Time</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking.propertyInfo?.propertyCheckInProcess?.checkInTime}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-out Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED ? `${LIVE_BOOKING_STATUS.CANCELLED}` : moment(singleBooking?.checkOutDate).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-out Charge Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED && (typeof singleBooking?.checkOutChargeDate === 'undefined') ? `${LIVE_BOOKING_STATUS.CANCELLED}` : singleBooking?.bookingStatus !== LIVE_BOOKING_STATUS.CANCELLED ? '-' : moment(singleBooking?.checkOutChargeDate).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-out Time</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking.propertyInfo?.propertyCheckInProcess?.checkOutTime}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Payment Method</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>Not Yet</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Type of Booking</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingType?.replace('_', " ")}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <Row style={{ marginTop: '10px' }}>
                <Col md={6}>
                    <Button id='edit-button' style={{ width: '190px', marginLeft: '15px' }} onClick={BackToList} >
                        <b>Close</b>
                    </Button>
                </Col>
                <Col md={6}>
                </Col>
            </Row>
            <CancelBookingWithoutCharge cancelBoxDisplay={cancelBoxDisplay} ActionNo={BackToList} ActionYes={cancelPending} loading={loading} />
        </Row >
    </>)

};

export default BookingSIngleView;