import React, { useEffect, useState } from "react"
import { $mysaMegenta, $mysaRed, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, tableStyles } from "../../../../../common/components-style";
import DataTable, { createTheme } from 'react-data-table-component';
import { getStorage } from "../../../../../common/storage";
import { useStoreActions, useStoreState } from "easy-peasy";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ViewEdit from '../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { REQUEST_BOOKING_STATUS } from "../../../../../common/constants";
import RequestBookingCorporateHomeView from "./RequestBookingCorporateHomeView";
import { TRequestBookingView } from "../../../../../common/types";
import moment from "moment";
import momentTimezone from "moment-timezone";
import File from '../../../../../images/icons/svg/outside/file-icon.svg';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const RequestBookingCorporaterHome: React.FunctionComponent = () => {

	///////////// data get from local storage database from browser ////////////////
	const clientId = getStorage('selectedFilterClient')?.value ? getStorage('selectedFilterClient')?.value : getStorage('programmeData')?.clientId?._id;
	const programId = getStorage('programmeData') ? getStorage('programmeData')?._id: getStorage('selectedProgramsOption')?.value ;
	const userId = getStorage('myo_logged_user')?.userData?._id;

	///////////////////////////global action///////////////////////////////////
	const { getRequestBookingListByBooker } = useStoreActions<any>((actions) => ({
		getRequestBookingListByBooker: actions.booking.getRequestBookingListByBooker
	}));

	/////////////////// global state ////////////////
	const { getRequestBookingListByBookerSuccess } = useStoreState<any>((actions) => ({
		getRequestBookingListByBookerSuccess: actions.booking.getRequestBookingListByBookerSuccess
	}));

	///////////////// local state ////////////////
	const [data, setData] = useState<any>();
	const [hideShowView, setHideShowView] = useState<Boolean>(false);
	const [viewComponentData, setViewComponentData] = useState<TRequestBookingView>();
	const [status, setStatus] = useState<String>("");
	//////////// action hook //////////////
	useEffect(() => {
		if (userId && programId && clientId) {
			const payload = {
				clientId: clientId,
				programmeId: programId,
				requestUserId: userId
			}

			getRequestBookingListByBooker(payload);

		}
	}, [clientId, getRequestBookingListByBooker, programId, userId]);

	///////////////// data fetch and handle hook /////////////////
	useEffect(() => {

		if (getRequestBookingListByBookerSuccess) {
			setData(getRequestBookingListByBookerSuccess.data)
		}

	}, [getRequestBookingListByBookerSuccess]);

	/////////////////////////////////////////////////////////////////////////////
	const hideShowViewComponent = (value: boolean) => {
		setHideShowView(value);

		if (userId && programId && clientId) {
			const payload = {
				clientId: clientId,
				programmeId: programId,
				requestUserId: userId
			}

			getRequestBookingListByBooker(payload);

		}
	}

	const columns = [
		{
			name: 'Traveller Name',
			selector: row => row.travelerName,
		},
		// {
		// 	name: 'Enquiry No.',
		// 	selector: row => row.inventory._reqInventoryId,
		// },
		{
			name: 'Sent',
			selector: row => moment(row.requestedDate).format('D MMM YYYY'),
		},
		{
			name: 'Time',
			selector: row => moment(row.requestedDate).format('HH:mm'),
		},
		{
			name: 'Property Time',
			cell: (row) => {
				return (
					<span>{momentTimezone(row.requestedDate).tz(row?.property?.timezone).format('HH:mm')} <span style={{ fontSize: '12px' }}>({row?.property?.timezone})</span></span>
				)
			}
		},
		{
			name: 'Location',
			selector: row => row.location.locationName,
		},
		{
			name: 'Property',
			selector: row => row.property.propertyName,
		},
		{
			name: 'Accommodation',
			selector: row => row.inventory.inventoryName,
		},
		{
			name: 'Status',
			cell: (row) => {
				let color = "";
				let text = row.requestStatus;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.PENDING) color = $mysaYellow;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED) color = $mysaTeal;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.DECLINED) color = $mysaRed;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.EXPIRED) color = $mysaRed;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.ALT_OFFER) color = $mysaMegenta;

				if (row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED)
					text = "Available";

				return (
					<span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{text}</span>
				)
			}
		},
		{
			name: 'Expiry Date',
			selector: row => (row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED || row.requestStatus === REQUEST_BOOKING_STATUS.ALT_OFFER) ? moment(row.expiryDetails?.offerExpiresAt).format('D MMM YYYY') : '',
		},
		{
			name: 'Expiry Time',
			selector: row => (row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED || row.requestStatus === REQUEST_BOOKING_STATUS.ALT_OFFER)?moment(row.expiryDetails?.offerExpiresAt).format('HH:mm'):'',
		},
		{
			name: 'Action',
			button: true,
			cell: (row: any) => {
				return (<>
					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
					>
						<img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
							const data: TRequestBookingView = {
								_id: row?._id,
								_reqGroupId: row?._reqGroupId,
								_reqBrandId: row?._reqBrandId,
								_reqPropertyId: row?.property?._reqPropertyId,
								_reqInventoryId: row?.inventory?._reqInventoryId,
								hideShowViewComponent: () => { }
							}
							setViewComponentData(data);
							setStatus(row.requestStatus);
							hideShowViewComponent(true);
						}} />
					</OverlayTrigger>
					{
						row?.messageThread[row?.messageThread.length - 1]?.direction === 'property_to_booker' && <OverlayTrigger
							placement="top"
							delay={overlayTriggerDelay}
							overlay={<Tooltip id='tooltip-table-top'>Message Received From Property</Tooltip>}
						>
							<img className='iconSizes4' src={File} alt="" />
						</OverlayTrigger>
					}

				</>)
			},
		},
	];

	return (
		<>
			{
				!hideShowView && <div className='brnd_tbl' style={{ marginTop: '50px' }}>
					<DataTable
						columns={columns}
						data={data}
						pagination
						striped={true}
						customStyles={tableStyles}
						theme='mysaTable'
						fixedHeader={true}
						noDataComponent={'Updating records'}
					/>
				</div>
			}

			{
				hideShowView && <RequestBookingCorporateHomeView status={status} hideShowViewComponent={hideShowViewComponent} _id={viewComponentData?._id} _reqBrandId={viewComponentData?._reqBrandId} _reqGroupId={viewComponentData?._reqGroupId} _reqInventoryId={viewComponentData?._reqInventoryId} _reqPropertyId={viewComponentData?._reqPropertyId} />
			}
		</>
	)
}

export default RequestBookingCorporaterHome;