import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { QuestionTypes } from '../../../../../../common/constants';
import { ReactComponent as Completed } from '../../../../../../images/Approved.svg';
import { ReactComponent as Circle } from '../../../../../../images/Circle.svg';
import QAddress from './QAddress';
import QContactList from './QContactList';
import QCurrency from './QCurrency';
import QDate from './QDate';
import QDocument from './Qdocument';
import QImage from './QImage';
import QManyOf from './QManyOf';
import QNumber from './QNumber';
import QOneOf from './QOneOf';
import QRate from './QRate';
import QReadDoc from './QReadDoc';
import QText from './QText';
import QUserDefinedList from './QUserDefinedList';
import QVideo from './QVideo';
import QYesNo from './QYesNo';
import { toast } from 'react-toastify';

const QuestionsHome: React.FC<any> = ({ surveyId, signUpStatus = '', sectionId, showButtons = false, buttonData = {}, completed }): JSX.Element => {
	const [surveys, setSurveys] = useState<any>([]);
	const [sectionsData, setSectionsData] = useState<any>(null);

	const { getAuditSurveys, answerQuestion, answerQuestionEvidence, resetAudit } = useStoreActions<any>((actions) => ({
		getAuditSurveys: actions.myoManagementModel.getAuditSurveys,
		answerQuestion: actions.myoManagementModel.answerQuestion,
		answerQuestionEvidence: actions.myoManagementModel.answerQuestionEvidence,
		resetAudit: actions.myoManagementModel.resetAudit
	}));

	const { getAuditSurveysSuccess, auditAnswerSuccess, auditEvidenceSuccess } = useStoreState<any>((state) => ({
		getAuditSurveysSuccess: state.myoManagementModel.getAuditSurveysSuccess,
		auditAnswerSuccess: state.myoManagementModel.myoAnswerSuccess,
		auditEvidenceSuccess: state.myoManagementModel.myoEvidenceSuccess,
	}));

	const loadSurvey = useCallback(() => {
		getAuditSurveys({
			security: {
				apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
				user: "superadmin@mysa.global"
			},
			certificatesurveyid: surveyId
		});
	}, [getAuditSurveys, surveyId]);

	useEffect(() => {
		loadSurvey();
	}, [loadSurvey]);

	useEffect(() => {
		if (getAuditSurveysSuccess && getAuditSurveysSuccess.status.errorCode === 0) {
			setSurveys(getAuditSurveysSuccess.certificateSurvey);

		}
	}, [getAuditSurveysSuccess, showButtons]);

	useEffect(() => {
		if (sectionId) {
			const data = _.filter(surveys.survey?.sections, (survey) => survey.id === sectionId).shift();
			setSectionsData(data)
		}
	}, [sectionId, surveys]);

	useEffect(() => {
		if (auditAnswerSuccess) {
			const count = _.filter(sectionsData?.questions, (a) => !a.answer).length - 1;

			if (count === 0) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
			toast.success("Answer submitted", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetAudit();
			loadSurvey();
		}

		if (auditEvidenceSuccess) {
			if (auditEvidenceSuccess.status.errorCode !== 0) {
				toast.error(auditEvidenceSuccess.status.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
			} else {
				const count = _.filter(sectionsData?.questions, (a) => !a.answer).length - 1;

				if (count === 0) {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
				toast.error('Answer submitted', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				loadSurvey();
				resetAudit();
			}
		}

	}, [auditAnswerSuccess, auditEvidenceSuccess, loadSurvey, resetAudit, sectionsData?.questions]);

	const onAnswer = useCallback((answer, qId, na = null) => {
		answerQuestion({
			security: {
				apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
				user: "superadmin@mysa.global"
			},
			answer,
			certificatesurveyid: surveyId,
			questionID: qId,
			na,
		})
		// onUpdateBrandSignup({ id: buttonData.signUpId, signUpStatus: 'pending' });


	}, [answerQuestion, surveyId]);


	const onEvidenceUpload = useCallback((file, qId) => {
		const formData = new FormData();
		const apiKey: any = process.env.REACT_APP_MYO_MANAGEMENT_API_KEY;
		formData.append('APIKey', apiKey);
		formData.append('user', 'superadmin@mysa.global');
		formData.append('questionID', qId);
		formData.append('CertificateSurveyID', surveyId);
		formData.append('file', file);
		answerQuestionEvidence(formData);

	}, [answerQuestionEvidence, surveyId]);


	return (
		<div className="flex">
			<div className="bg-blue-100 w-full p-5">
				<div>
					<Circle width="36px" height="36px" fill="#05B3ED" className="float-left" />
					<p className="pt-2 pl-10 text-gray-400 font-bold">TO DO</p>
					{sectionsData && <div>
						{
							sectionsData.questions.map((q, key) => {
								if (!q.answer) {
									switch (q.type) {
										case QuestionTypes.yes_no:
											return <div key={key}>
												<QYesNo data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.text:
											return <div key={key}>
												<QText data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.number:
											return <div key={key}>
												<QNumber data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.currency:
											return <div key={key}>
												<QCurrency data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.date:
											return <div key={key}>
												<QDate data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.one_of:
											return <div key={key}>
												<QOneOf data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.many_of:
											return <div key={key}>
												<QManyOf data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.rate:
											return <div key={key}>
												<QRate data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.read_doc:
											return <div key={key}>
												<QReadDoc data={q} onAnswer={onAnswer} />
											</div>
										// case QuestionTypes.sign:
										//   return <div key={key}>
										//     <QSign data={q} />
										//   </div>
										case QuestionTypes.contact_list:
											return <div key={key}>
												<QContactList data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.user_defined_list:
											return <div key={key}>
												<QUserDefinedList data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.image:
											return <div key={key}>
												<QImage data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.video:
											return <div key={key}>
												<QVideo data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.document:
											return <div key={key}>
												<QDocument data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.address:
											return <div key={key}>
												<QAddress data={q} onAnswer={onAnswer} />
											</div>
										default:
											return <div key={key}></div>;
									}
								}else{
									return <></>
								}
							})
						}
					</div>}
				</div>
			</div>
			<div className="bg-blue-200 w-full p-5">
				<div>
					<Completed width="36px" height="36px" fill="#05d1c1" className="float-left" />
					<p className="pt-2 pl-10 text-gray-400 font-bold">COMPLETED</p>
					{sectionsData && <div>
						{
							sectionsData.questions.map((q, key) => {
								if (q.answer) {
									switch (q.type) {
										case QuestionTypes.yes_no:
											return <div key={key}>
												<QYesNo data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										case QuestionTypes.text:
											return <div key={key}>
												<QText data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										case QuestionTypes.number:
											return <div key={key}>
												<QNumber data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										case QuestionTypes.currency:
											return <div key={key}>
												<QCurrency data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} />
											</div>
										case QuestionTypes.date:
											return <div key={key}>
												<QDate data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} />
											</div>
										case QuestionTypes.one_of:
											return <div key={key}>
												<QOneOf data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} />
											</div>
										case QuestionTypes.many_of:
											return <div key={key}>
												<QManyOf data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										case QuestionTypes.rate:
											return <div key={key}>
												<QRate data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} />
											</div>
										case QuestionTypes.read_doc:
											return <div key={key}>
												<QReadDoc data={q} onAnswer={onAnswer} />
											</div>
										// case QuestionTypes.sign:
										//   return <div key={key}>
										//     <QSign data={q} />
										//   </div>
										case QuestionTypes.contact_list:
											return <div key={key}>
												<QContactList data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.user_defined_list:
											return <div key={key}>
												<QUserDefinedList data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										case QuestionTypes.image:
											return <div key={key}>
												<QImage data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.video:
											return <div key={key}>
												<QVideo data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.document:
											return <div key={key}>
												<QDocument data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.address:
											return <div key={key}>
												<QAddress data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										default:
											return <div key={key}></div>;
									}
								}else{
									return <></>
								}
							})
						}
					</div>}
				</div>
			</div>


			{/* {showButtons && <ActionsButtons surveys={surveys} buttonData={buttonData} />} */}

		</div>
	)
};

export default QuestionsHome;