import { Button, Form, Input, InputNumber, message, Switch } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const BedTypeForm: React.FC<any> = ({ setFormOpen, bedTypeDetails, bedTypeId }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [status, setStatus] = useState<string>('active');
  const [checkValue, setCheckValue] = useState<boolean>(true);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const statusChange = useCallback((value) => {
    setCheckValue(value);
    if (value) {
      setStatus('active');
    }
    else {
      setStatus('inactive');
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (bedTypeId) {
      setCheckValue((bedTypeDetails.status === 'active') ? true : false);
      setStatus(bedTypeDetails.status)
      form.setFieldsValue({
        name: bedTypeDetails.name,
        description: bedTypeDetails.description || '',
        occupancy: bedTypeDetails.occupancy,
        status: bedTypeDetails.status,

      })
    } else {
      setStatus('active');
      setCheckValue(true);
    }
  }, [bedTypeDetails, bedTypeId, form]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { createBedType, updateBedType, getBedTypeList } = useStoreActions<any>((actions) => ({
    createBedType: actions.inventory.createBedType,
    updateBedType: actions.inventory.updateBedType,
    getBedTypeList: actions.inventory.getBedTypeList,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    createBedTypeError,
    createBedTypeSuccess,
    updateBedTypeError,
    updateBedTypeSuccess,
    getBedTypeListSuccess,
    getBedTypeListError
  } = useStoreState<any>((state) => ({
    createBedTypeSuccess: state.inventory.createBedTypeSuccess,
    createBedTypeError: state.inventory.createBedTypeError,
    updateBedTypeError: state.inventory.updateBedTypeError,
    updateBedTypeSuccess: state.inventory.updateBedTypeSuccess,
    getBedTypeListSuccess: state.inventory.getBedTypeListSuccess,
    getBedTypeListError: state.inventory.getBedTypeListError,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getBedTypeList();
  }, [getBedTypeList]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getBedTypeListSuccess) {

    }
    if (getBedTypeListError) {
      message.error(getBedTypeListError.message)
    }
  }, [getBedTypeListError, getBedTypeListSuccess]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createBedTypeSuccess) {
      message.success('BedType created successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getBedTypeList();
    }

    if (updateBedTypeSuccess) {
      message.success('BedType updated successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getBedTypeList();
    }

    if (createBedTypeError) {
      message.error(createBedTypeError.message)
    }

    if (updateBedTypeError) {
      message.error(updateBedTypeError.message)
    }
    setLoading(false);
  }, [createBedTypeSuccess, createBedTypeError, updateBedTypeSuccess, updateBedTypeError, getBedTypeList, setFormOpen]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = useCallback((values) => {
    setLoading(true);
    if (bedTypeId) {
      values.id = bedTypeId;
      const data = {
        id: bedTypeId,
        name: values.name,
        occupancy: values.occupancy,
        description: values.description,
        status: values.status ? status : 'inactive'
      }
      updateBedType(data);

    } else {
      values.status = status;
      createBedType(values);
    }

  }, [bedTypeId, createBedType, status, updateBedType]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="user-Form w-1/2">
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please enter a name!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Occupancy"
          name="occupancy"
          rules={[
            { required: true, message: 'Please enter a occupancy!' },
          ]}
        >
          <InputNumber min={1} className='w-full' />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          label="Status"
          name="status">
          <Switch size="small" checked={checkValue} onChange={statusChange} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={loading} type="primary" htmlType="submit">
            {bedTypeId ? 'Update' : 'Create'}
          </Button>
          {/* <Button onClick={() => setFormOpen(false)} className="ml-5">
            Close
          </Button> */}
        </Form.Item>

      </Form>
    </div>
  )
};

export default BedTypeForm;