import { message, DatePicker } from "antd";
import Select from "react-select";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import BookingGoogleMap from "../../common/BookingGoogleMap";


import { toast } from "react-toastify";
import bedroom from "../../../images/icons/svg/outside/Icon-Bed.svg";
import bathroom from "../../../images/icons/svg/outside/Icon-Bath.svg";
import BookMarkIcon from "../../../images/icons/svg/bookmark.svg";
import FilterIcon from "../../../images/icons/svg/filter.svg";
import { useHistory } from "react-router-dom";
import { AvailabilityHashGet, UserGroups } from "../../../common/constants";
import photo from "../../../images/icons/photo.png";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
  Card,
  Spinner,
  Modal,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { BookingForm } from "../../../common/interfaces";
import {
  overlayTriggerDelay,
  requestButton,
  shareButton,
  toolTipStyle,
  viewButton,
  formButton,
  travelerScreen,
  BookMarkStyles,
  RoomTypeStyles,
  PriceStyles,
  searchButton2,
} from "../../../common/components-style";
import InfoIcon from "../../../images/icons/svg/outside/Icon-Information.svg";
import CrossIcon from "../../../images/icons/svg/cross.svg";
import CalenderIcon from "../../../images/icons/svg/calender.svg";
import TravellerProfile from "./TravellerProfile";
import ShowCommuteMap from "../../common/ShowCommuteMap";
import MyoBookingGoogleMap from "../../common/MyoBookingGoogleMap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Virtual, Navigation, Pagination, A11y } from "swiper";
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import GooglePlaces from "../../common/GooglePlaces";
import BookingRequestComponent from "./BookingRequestComponent";
import searchIcon from "../../../images/icons/svg/outside/search.svg";
import DebounceSearch from "../../common/DebounceSearch";
import { getStorage } from "../../../common/storage";
import { corporateUser, getMailDomain } from "../../../common/functions";
import _ from "lodash";

// install Virtual module
SwiperCore.use([Virtual, Navigation, Pagination]);


const { RangePicker } = DatePicker;

const BookingList: React.FC<any> = ({
  programmeId,
  bookingParams,
  location,
}): JSX.Element => {
  // const [form] = Form.useForm();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    register
  } = useForm<BookingForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const [locationList, setLocationList] = useState<any>([]);
  // const [clientId, setClientId] = useState<any>(null);
  const [locations, setLocations] = useState<any>();
  const [locationsShowCommute, setLocationsShowCommute] = useState<any>();
  const [isResult, setIsResult] = useState<boolean>(false);
  const [roomTypeList, setRoomTypeList] = useState<any>([]);
  const minDate = moment(new Date());
  const [building, setBuilding] = useState<any>([]);
  const [activeResultTab, setActiveResultTab] = useState<any>([]);
  const { push } = useHistory();
  const [isManualBooking, setIsManualBooking] = useState<boolean>(false);
  const [locationId, setLocationId] = useState<any>();
  const [checkInDate, setCheckInDate] = useState<any>();
  const [checkOutDate, setCheckOutDate] = useState<any>();
  const [inventories, setInventories] = useState<any>([]);
  const [messages, setMessages] = useState<any>();
  const [personCount] = useState<any>();
  const [roomType, setRoomType] = useState<any>();
  const [resultLength, setResultLength] = useState<any>();
  const [noOfNights, setNoOfNights] = useState<any>(0);
  const [otherLocation, setOtherLocation] = useState<any>('');
  const [requestAvailability, setRequestAvailability] =
    useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [isShowTravelerProfile, setShowTravelerProfile] =
    useState<boolean>(false);
  const [travelMode, setTravelMode] = useState<any>("");
  const [showShareProperty, setShowShareProperty] = useState<boolean>(false);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [optionData, setOptionData] = useState<any>();
  const [priceList] = useState<any>([
    { value: "price_asc", label: "Sort by Price" },
    { value: "distance_dsc", label: "Sort by Distance" },
  ]);
  const [selectedPriceList, setSelectedPriceList] = useState<string>();
  const [showCommute, setShowCommute] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [commuteBuilding, setCommuteBuilding] = useState<any>();
  const [requestData, setRequestData] = useState<Array<any>>([]);
  const [shareData, setShareData] = useState<Array<object>>([]);
  const [distance, setDistance] = useState<any>("0.0 km");
  const [duration, setDuration] = useState<any>("00 mins");
  const [cityList, setCityList] = useState<any>([]);
  const [cityName, setCityName] = useState<any>();
  const [countryCode, setCountryCode] = useState<any>();
  const [countryList, setCountryList] = useState<any>([]);
  const [countryName, setCountryName] = useState<any>();
  // const [requestMinActive, setRequestMinActive] = useState<boolean>(false);
  const [sharePropMinActive, setSharePropMinActive] = useState<boolean>(false);
  const [showCommuteMap, setShowCommuteMap] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [userMappingData, setUserMappingData] = useState<any>();
  const [travelerData, setTravelerData] = useState<any>();
  const [programmerUserDomains, setProgrammerUserDomains] = useState<Array<string>>([]);
  const [travelerUserId, setTravelerUserId] = useState('');

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    getClientLocationByProgrammeId,
    getRoomTypeList,
    getAvailabilityAndRateByLocation,
    getAvailabilityAndRateByOtherLocation,
    getAllPropertyCities,
    getAllPropertyCountries,
    getUserMapping,
    getCorporateProgrammeById,
    createUser,
    createUserMapping, updateUserMapping,
    createTravelerOption,

  } = useStoreActions<any>((actions) => ({
    getClientLocationByProgrammeId:
      actions.client.getClientLocationByProgrammeId,
    getRoomTypeList: actions.inventory.getRoomTypeList,
    getAvailabilityAndRateByLocation:
      actions.booking.getAvailabilityAndRateByLocation,
    getAvailabilityAndRateByOtherLocation:
      actions.booking.getAvailabilityAndRateByOtherLocation,
    getAllPropertyCities: actions.property.getAllPropertyCities,
    getAllPropertyCountries: actions.property.getAllPropertyCountries,
    getUserMapping: actions.user.getUserMapping,
    getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
    createUser: actions.user.createUser,
    createUserMapping: actions.user.createUserMapping,
    updateUserMapping: actions.user.updateUserMapping,
    createTravelerOption: actions.booking.createTravelerOption,

  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    getClientLocationByProgrammeIdSuccess,
    getClientLocationByProgrammeIdError,
    getRoomTypeListSuccess,
    getRoomTypeListError,
    getAvailabilityAndRateByLocationSuccess,
    getAvailabilityAndRateByLocationError,
    getAvailabilityAndRateByOtherLocationSuccess,
    getAvailabilityAndRateByOtherLocationError,
    getAllPropertyCitiesSuccess,
    getAllPropertyCitiesError,
    getAllPropertyCountriesSuccess,
    getAllPropertyCountriesError,
    getUserMappingSuccess,
    getCorporateProgrammeByIdSuccess,
    userCreate,
    updateUserMappingSuccess,
    createUserMappingSuccess,
    createTravelerOptionSuccess,
    userCreateError
  } = useStoreState<any>((state) => ({
    userCreate: state.user.userCreate,
    updateUserMappingSuccess: state.user.updateUserMappingSuccess,
    createUserMappingSuccess: state.user.createUserMappingSuccess,
    getClientLocationByProgrammeIdSuccess:
      state.client.getClientLocationByProgrammeIdSuccess,
    getClientLocationByProgrammeIdError:
      state.client.getClientLocationByProgrammeIdError,
    getRoomTypeListSuccess: state.inventory.getRoomTypeListSuccess,
    getRoomTypeListError: state.inventory.getRoomTypeListError,
    getAvailabilityAndRateByLocationSuccess:
      state.booking.getAvailabilityAndRateByLocationSuccess,
    getAvailabilityAndRateByLocationError:
      state.booking.getAvailabilityAndRateByLocationError,
    getAvailabilityAndRateByOtherLocationSuccess:
      state.booking.getAvailabilityAndRateByOtherLocationSuccess,
    getAvailabilityAndRateByOtherLocationError:
      state.booking.getAvailabilityAndRateByOtherLocationError,
    getAllPropertyCitiesSuccess: state.property.getAllPropertyCitiesSuccess,
    getAllPropertyCitiesError: state.property.getAllPropertyCitiesError,
    getAllPropertyCountriesSuccess:
      state.property.getAllPropertyCountriesSuccess,
    getAllPropertyCountriesError: state.property.getAllPropertyCountriesError,
    createTravelerOptionSuccess: state.booking.createTravelerOptionSuccess,
    getUserMappingSuccess: state.user.getUserMappingSuccess,
    getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
    userCreateError: state.user.userCreateError
  }));

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getRoomTypeList();
  }, [getRoomTypeList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getAllPropertyCountries();
  }, [getAllPropertyCountries]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAllPropertyCountriesSuccess) {
      setCountryList(getAllPropertyCountriesSuccess.data);
    }
    if (getAllPropertyCountriesError) {
      toast.error(getAllPropertyCountriesError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }, [getAllPropertyCountriesError, getAllPropertyCountriesSuccess]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAllPropertyCitiesSuccess) {
      setCityList(getAllPropertyCitiesSuccess.data);
    }
    if (getAllPropertyCitiesError) {
      toast.error(getAllPropertyCitiesError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }, [getAllPropertyCitiesError, getAllPropertyCitiesSuccess]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (bookingParams) {
      if (bookingParams?.locationId) {
        const params: any = {};
        params.locationId = bookingParams?.locationId;
        params.checkInDate = bookingParams?.checkInDate;
        params.checkOutDate = bookingParams?.checkOutDate;
        getAvailabilityAndRateByLocation(params);
      } else {
        const params: any = {};
        params.placeId = bookingParams.placeId;
        params.checkInDate = bookingParams?.checkInDate;
        params.checkOutDate = bookingParams?.checkOutDate;
        params.countryCode = bookingParams?.countryCode;
        params.center_latitude = bookingParams?.center_latitude;
        params.center_longitude = bookingParams?.center_longitude;
        params.myoCorporate = true;
        params.filter_no_bedroom = bookingParams?.roomType?.value
        getAvailabilityAndRateByOtherLocation(params);
      }
    }
  }, [
    bookingParams,
    getAvailabilityAndRateByLocation,
    getAvailabilityAndRateByOtherLocation,
  ]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const programmeIds = programmeId || location?.state?.query?.programmeId;
    if (programmeIds) {
      getClientLocationByProgrammeId(programmeIds);
      setLocationList([]);
    }
  }, [programmeId, getClientLocationByProgrammeId, location]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getClientLocationByProgrammeIdSuccess) {
      if (getClientLocationByProgrammeIdSuccess.data === null) {
        message.warning("No Data Found");
      } else {
        const location = getClientLocationByProgrammeIdSuccess.data;
        const locationListTemp: any = [];
        _.map(location, (element) => locationListTemp.push(element));

        if (bookingParams?.isProgramme === "MYO Plus") {
          locationListTemp.push({
            _id: null,
            locationName: "- Select other location -",
          });
        }

        setLocationList(locationListTemp);
      }
    }
    if (getClientLocationByProgrammeIdError) {
      toast.error(getClientLocationByProgrammeIdError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }, [
    getClientLocationByProgrammeIdSuccess,
    getClientLocationByProgrammeIdError,
    programmeId,
    bookingParams,
  ]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (location) {
      if (location?.state?.query?.propLocationId) {
        const params: any = {};
        params.locationId = location?.state?.query?.propLocationId;
        params.checkInDate = location?.state?.query?.checkIn;
        params.checkOutDate = location?.state?.query?.checkOut;
        let locations = _.first(
          _.filter(
            locationList,
            (location) =>
              location._id === location?.state?.query?.propLocationId
          )
        );
        if (locations) {
          const buildingListTemp: any = [];
          _.map(locations.building, (element) =>
            buildingListTemp.push({
              id: element._id,
              name: element.propertyName,
              position: {
                lat: Number(element.latitude),
                lng: Number(element.longitude),
              },
              address: element.address1,
            })
          );
          setBuilding(buildingListTemp);
          setLocations({
            lat: Number(location.latitude),
            lng: Number(location.longitude),
            locationName: location.locationName,
          });
        }
        getAvailabilityAndRateByLocation(params);
      } else {
        if (location?.state?.query?.placeId) {
          const params: any = {};
          params.placeId = location?.state?.query?.placeId;
          params.checkInDate = location?.state?.query?.checkIn;
          params.checkOutDate = location?.state?.query?.checkOut;
          params.countryCode = location?.state?.query?.countryCode;
          params.center_latitude = location?.state?.query?.lat;
          params.center_longitude = location?.state?.query?.lng;
          params.myoCorporate = location?.state?.query?.myoCorporate;
          setIsManualBooking(true);
          getAvailabilityAndRateByOtherLocation(params);
        }
      }
    }
  }, [
    getAvailabilityAndRateByLocation,
    getAvailabilityAndRateByOtherLocation,
    isManualBooking,
    location,
    locationId,
    locationList,
    personCount,
    roomType,
    setValue,
  ]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getRoomTypeListSuccess) {
      const roomsConf = [{ label: "All", value: 0 },];
      for (const rooms of getRoomTypeListSuccess.data) {
        if (rooms.name.trim() !== `Studio/Open plan apartment`) {
          const room = {
            label: rooms.name,
            value: rooms.name.trim() === 'One bedroom' ? 1 : rooms.name.trim() === 'Two bedroom' ? 2 : rooms.name.trim() === 'Three bedroom' ? 3 : rooms.name.trim() === 'Studio/Open plan apartment' ? 100 : 4
          };
          roomsConf.push(room);
        }
      }

      const roomList = roomsConf.sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
      setRoomTypeList(roomList);
    }
    if (getRoomTypeListError) {
      message.error(getRoomTypeListError.message);
    }
  }, [getRoomTypeListError, getRoomTypeListSuccess, getRoomTypeList]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAvailabilityAndRateByLocationSuccess) {
      setLoading(false);
      setResultLength(getAvailabilityAndRateByLocationSuccess.data.length);
      if (getAvailabilityAndRateByLocationSuccess.data.length === 0) {
        setMessages("No Result Found");
        setInventories(getAvailabilityAndRateByLocationSuccess?.data);
        setBuilding([]);
      } else {
        setIsResult(true);
        const booking = getAvailabilityAndRateByLocationSuccess.data;
        setInventories(booking);

        let location = _.first(
          _.filter(
            locationList,
            (location) => location._id === bookingParams?.locationId
          )
        );
        if (location) {
          const buildingListTemp: any = [];
          _.map(location.building, (element) =>
            buildingListTemp.push({
              id: element._id,
              name: element.propertyName,
              position: {
                lat: Number(element.latitude),
                lng: Number(element.longitude),
              },
              address: element.address1,
            })
          );

          setBuilding(buildingListTemp);
          setLocations({
            lat: Number(location.latitude),
            lng: Number(location.longitude),
            locationName: location.locationName,
          });

          if (watch("fromDate")) {
            const date: any = watch("fromDate");
            const date1 = new Date(checkInDate ? checkInDate : date[0]);
            const date2 = new Date(checkOutDate ? checkOutDate : date[1]);
            const Difference_In_Time = date2.getTime() - date1.getTime();

            const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            setNoOfNights(Difference_In_Days);
          }


        }
      }
    }
    if (getAvailabilityAndRateByLocationError) {
      setLoading(false);
      setTimeout(() => {
        toast.error(getAvailabilityAndRateByLocationError.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }, 1000);
    }
  }, [bookingParams, checkInDate, checkOutDate, getAvailabilityAndRateByLocationError, getAvailabilityAndRateByLocationSuccess, getAvailabilityAndRateByOtherLocationSuccess?.data, locationList, watch]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAvailabilityAndRateByOtherLocationSuccess) {
      setLoading(false);
      setResultLength(getAvailabilityAndRateByOtherLocationSuccess.data.length);
      if (getAvailabilityAndRateByOtherLocationSuccess.data.length === 0) {
        setMessages("No Result Found");
        setInventories(getAvailabilityAndRateByOtherLocationSuccess?.data);
        setBuilding([]);
      } else {
        setIsResult(true);
        const booking = getAvailabilityAndRateByOtherLocationSuccess.data;
        if (selectedPriceList === "price_asc") {
          const shorting = booking.sort((a, b) => (a.rateAndPolicy.finalRate > b.rateAndPolicy.finalRate) ? 1 : -1);
          setInventories(shorting);
        } else {
          const shorting = booking.sort((a, b) => (a.property.distanceFromLocation > b.property.distanceFromLocation) ? 1 : -1);
          setInventories(shorting);
        }
        const buildingListTemp: any = [];
        const buildingData: any = [];
        _.map(booking, (element) => buildingListTemp.push(element.property));

        _.map(buildingListTemp, (element) =>
          buildingData.push({
            id: element._id,
            name: element.propertyName,
            position: {
              lat: Number(element.latitude),
              lng: Number(element.longitude),
            },
            address: element.address1,
          })
        );

        setBuilding(buildingData);
        if (watch("fromDate")) {
          const date: any = watch("fromDate");
          const date1 = new Date(checkInDate ? checkInDate : date[0]);
          const date2 = new Date(checkOutDate ? checkOutDate : date[1]);
          const Difference_In_Time = date2.getTime() - date1.getTime();

          const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
          setNoOfNights(Difference_In_Days);
        }
      }
      if (selectedPriceList === "price_asc" && inventories) {
        const shorting = inventories.sort((a, b) => (a.rateAndPolicy.finalRate > b.rateAndPolicy.finalRate) ? 1 : -1);
        setInventories(shorting);
      }
    }
    if (getAvailabilityAndRateByOtherLocationError) {
      setLoading(false);
      toast.error(getAvailabilityAndRateByOtherLocationError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }, [
    selectedPriceList,
    inventories,
    checkInDate,
    checkOutDate,
    getAvailabilityAndRateByOtherLocationError,
    getAvailabilityAndRateByOtherLocationSuccess,
    watch,
  ]);


  //////////////////////////////////////////////////////////////////
  useEffect(() => {
    let getOffsetVal = document.getElementsByClassName("result-item") as HTMLCollectionOf<HTMLElement>;
    let itemArr: any = [];
    window.addEventListener("scroll", function (event) {
      let activeResult = document.querySelectorAll(".result-item.active");
      let windowScroll = window.pageYOffset + 300;
      for (let i = 0; i < getOffsetVal.length; i++) {
        if (getOffsetVal[i].offsetTop <= windowScroll && getOffsetVal[i].offsetTop + getOffsetVal[i].clientHeight > windowScroll) {
          getOffsetVal[i].classList.add("active");
        } else {
          getOffsetVal[i].classList.remove("active");
          itemArr = [];
        }
      }
      for (let i = 0; i <= activeResult.length; i++) {
        const id = activeResult[i]?.getAttribute("data-id");
        id && itemArr.push(id);
      }
      setActiveResultTab(itemArr);
    })

  }, [])
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (getAvailabilityAndRateByLocationSuccess) {
      if (getAvailabilityAndRateByLocationSuccess.data.length === 0) {
        setMessages("No Result Found");
        setInventories(getAvailabilityAndRateByLocationSuccess?.data);
        setBuilding([]);
      } else {
        const booking = getAvailabilityAndRateByLocationSuccess.data;
        setInventories(booking);
        // const buildingListTemp: any = [];
        // const buildingData: any = [];
        // _.map(booking, (element) => buildingListTemp.push(element.property));
        // _.map(buildingListTemp, (element) => {
        //   if (activeResultTab?.length > 0) {
        //     activeResultTab.forEach((item, index) => {
        //       if (element._id === item) {
        //         buildingData.push({
        //           id: element._id,
        //           name: element.propertyName,
        //           position: {
        //             lat: Number(element.latitude),
        //             lng: Number(element.longitude),
        //           },
        //           address: element.address1,
        //         })
        //       }
        //     })
        //   }
        //   else {
        //     buildingData.push({
        //       id: element._id,
        //       name: element.propertyName,
        //       position: {
        //         lat: Number(element.latitude),
        //         lng: Number(element.longitude),
        //       },
        //       address: element.address1,
        //     })
        //   }
        // }
        // );
        // setBuilding(buildingData);
      }
      if (selectedPriceList === "price_asc" && inventories) {
        const shorting = inventories.sort((a, b) => (a.rateAndPolicy.finalRate > b.rateAndPolicy.finalRate) ? 1 : -1);
        setInventories(shorting);
      } else {
        const shorting = inventories.sort((a, b) => (a.property.distanceFromLocation > b.property.distanceFromLocation) ? 1 : -1);
        setInventories(shorting);
      }
    };
  }, [inventories, selectedPriceList, getAvailabilityAndRateByLocationSuccess, getAvailabilityAndRateByOtherLocationSuccess?.data]);


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (getAvailabilityAndRateByOtherLocationSuccess) {
      if (getAvailabilityAndRateByOtherLocationSuccess.data.length === 0) {
        setMessages("No Result Found");
        setInventories(getAvailabilityAndRateByOtherLocationSuccess.data);
        setBuilding([]);
      } else {
        const booking = getAvailabilityAndRateByOtherLocationSuccess.data;
        if (selectedPriceList === "price_asc") {
          const shorting = booking.sort((a, b) => (a.rateAndPolicy.finalRate > b.rateAndPolicy.finalRate) ? 1 : -1);
          setInventories(shorting);
        } else {
          const shorting = booking.sort((a, b) => (a.property.distanceFromLocation > b.property.distanceFromLocation) ? 1 : -1);
          setInventories(shorting);
        }
      }
    };
  }, [inventories, selectedPriceList, getAvailabilityAndRateByOtherLocationSuccess]);

  /**
   * map refresh
   */
  useEffect(() => {
    const buildingListTemp: any = [];
    const buildingData: any = [];
    _.map(inventories, (element) => buildingListTemp.push(element.property));
    _.map(buildingListTemp, (element) => {
      if (activeResultTab?.length > 0) {
        activeResultTab.forEach((item, index) => {
          if (element._id === item) {
            buildingData.push({
              id: element._id,
              name: element.propertyName,
              position: {
                lat: Number(element.latitude),
                lng: Number(element.longitude),
              },
              address: element.address1,
            })
          }
        })
      }
      else {
        if (buildingData.length < 3) {
          buildingData.push({
            id: element._id,
            name: element.propertyName,
            position: {
              lat: Number(element.latitude),
              lng: Number(element.longitude),
            },
            address: element.address1,
          });
        }
      }
    }
    );
    setBuilding(buildingData);
  }, [activeResultTab, inventories])

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (inventories && selectedPriceList !== 'distance_dsc') {
      const booking = inventories.sort((a, b) => (a.rateAndPolicy.finalRate > b.rateAndPolicy.finalRate) ? 1 : -1);
      setInventories(booking);
    } else {
      if (locations) {
        const shorting = inventories.sort((a, b) => (a.property.distanceFromLocation > b.property.distanceFromLocation) ? 1 : -1);
        setInventories(shorting);
      }
    }
  }, [inventories, selectedPriceList, locations])

  const renderCorporateLocationNew = useCallback(() => {
    if (locationList) {
      return locationList.map((acc: any, key: number) => {
        return {
          key: key,
          value: `${acc._id}`,
          label: acc.locationName,
          loc: acc,
        };
      });
    }
  }, [locationList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onLocationChange = useCallback(
    (val) => {
      setLocationId(val.value);
      setSelectedLocation(val.loc);
      if (val.value === "null") {
        setIsManualBooking(true);
      } else {
        setIsManualBooking(false);
        let location = _.first(
          _.filter(locationList, (location) => location._id === val.value)
        );
        if (location) {
          const buildingListTemp: any = [];
          _.map(location.building, (element) =>
            buildingListTemp.push({
              id: element._id,
              name: element.propertyName,
              position: {
                lat: Number(element.latitude),
                lng: Number(element.longitude),
              },
              address: element.address1,
            })
          );
        }
      }
    },
    [locationList]
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onPriceChange = useCallback(
    (price) => {
      setSelectedPriceList(price.value);
      if (price) {
        if ((bookingParams?.isProgramme === "MYO" || location?.state?.query?.isProgramme === "MYO")) {
          if (price.value === "price_asc") {
            setShowCommuteMap(false);
            setOtherLocation('');
            setLocations('');
            const params: any = {};
            params.placeId = bookingParams.placeId;
            params.checkInDate = bookingParams?.checkInDate;
            params.checkOutDate = bookingParams?.checkOutDate;
            params.countryCode = bookingParams?.countryCode;
            params.center_latitude = bookingParams?.center_latitude;
            params.center_longitude = bookingParams?.center_longitude;
            params.myoCorporate = true;
            getAvailabilityAndRateByOtherLocation(params);
          }
        } else {
          const params: any = {};
          params.locationId = watch("location") ? watch("location") : locationId;
          params.checkInDate = bookingParams?.checkInDate
            ? bookingParams.checkInDate
            : checkInDate;
          params.checkOutDate = bookingParams.checkOutDate
            ? bookingParams.checkOutDate
            : checkOutDate;
          params.sort = price.value;
          // params.filter_acc_type = roomType;
          params.filter_no_bedroom = roomType;
          // params.filter_persons = personCount;
          getAvailabilityAndRateByLocation(params);
          if (price.value === "price_asc" && inventories) {
            const shorting = inventories.sort((a, b) => (a.rateAndPolicy.finalRate > b.rateAndPolicy.finalRate) ? 1 : -1);
            setInventories(shorting);
          }
        }
      }

    },
    [bookingParams, location?.state?.query?.isProgramme, getAvailabilityAndRateByOtherLocation, inventories, watch, locationId, checkInDate, checkOutDate, roomType, getAvailabilityAndRateByLocation]
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onAccommodationChange = useCallback((acc) => {
    if (acc) {
      if (acc.value === 100) {
        acc.value = 1;
      }
      // let room = _.first(_.filter(roomTypeList, location => (location._id === acc.value)))?.occupancy;
      setRoomType(acc.value);
      if (watch("location")) {
        const params: any = {};
        if (acc.value !== 0) {
          params.filter_no_bedroom = acc.value;
        }
        params.locationId = watch("location") ? watch("location") : locationId;
        params.checkInDate = bookingParams.checkInDate
          ? bookingParams.checkInDate
          : checkInDate;
        params.checkOutDate = bookingParams.checkOutDate
          ? bookingParams.checkOutDate
          : checkOutDate;
        // params.filter_acc_type = "One bed rooms";
        getAvailabilityAndRateByLocation(params);
      } else {
        const params: any = {};
        if (acc.value !== 0) {
          params.filter_no_bedroom = acc.value;
        }
        const date: any = watch('fromDate');
        params.placeId = watch("city") ? watch("city") : cityName;
        params.checkInDate = checkInDate ? checkInDate : date[0];
        params.checkOutDate = checkOutDate ? checkOutDate : date[1];
        params.countryCode = watch("country") ? watch("country") : countryCode;
        params.center_latitude = 0;
        params.center_longitude = 0;
        params.myoCorporate = true;
        getAvailabilityAndRateByOtherLocation(params);
      }

    }
  },
    [bookingParams, checkInDate, checkOutDate, cityName, countryCode, getAvailabilityAndRateByLocation, getAvailabilityAndRateByOtherLocation, locationId, watch]
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onFromDateChange = useCallback((dates, dateStrings) => {
    setCheckInDate(dateStrings[0]);
    setCheckOutDate(dateStrings[1]);
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const disabledFromDate = useCallback(
    (d) => {
      if (d == null) {
        return null;
      }
      return (
        minDate != null && !d.isAfter(minDate) && d.isBefore(minDate, "day")
      );
    },
    [minDate]
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onBooking = useCallback(
    (value) => {
      let params: any = {};
      params.programmeId = programmeId;
      params.isProgramme = bookingParams?.isProgramme;
      params.noOfNights = noOfNights;
      if (bookingParams) {
        params.from = bookingParams.checkInDate;
        params.end = bookingParams.checkOutDate;
        params.countryCode = watch("country")
          ? watch("country")
          : bookingParams?.countryCode;
        params.placeId = watch("city") ? watch("city") : bookingParams?.placeId;
      } else {
        params.locationId = location?.state?.query?.propLocationId;
        params.from = location?.state?.query?.checkIn;
        params.end = location?.state?.query?.checkOut;
      }
      if (watch("location")) {
        if (value) {
          params.cacheKey = value.cacheKey;
          params.propertyId = value.property._id;
          params.inventoryId = value.propertyInventory._id;
          params.location = bookingParams
            ? bookingParams.location
            : selectedLocation;
          params.locationId = watch("location")
            ? watch("location")
            : locationId;
          params.hashGet = AvailabilityHashGet.SEARCH_RESPONSE;

          push("/admin/property-page", { query: params });
        }
      } else {
        if (value) {
          params.cacheKey = value.cacheKey;
          params.propertyId = value.property._id;
          params.inventoryId = value.propertyInventory._id;
          params.country = bookingParams ? bookingParams.country : countryName;
          // params.placeId = watch('city') ? watch('city') : cityName;
          // params.checkInDate = checkInDate ? checkInDate : value.fromDate[0];
          // params.checkOutDate = checkOutDate ? checkOutDate : value.fromDate[1];
          // params.countryCode = watch('country') ? watch('country') : countryCode;
          params.center_latitude = 0;
          params.center_longitude = 0;
          params.myoCorporate = true;

          //  params.from = form.getFieldValue('fromDate')[0] ? moment(form.getFieldValue('fromDate')[0]?._d).format('YYYY-MM-DD') : checkInDate;
          // params.end = form.getFieldValue('fromDate')[1] ? moment(form.getFieldValue('fromDate')[1]?._d).format('YYYY-MM-DD') : checkOutDate;
          params.hashGet = AvailabilityHashGet.SEARCH_RESPONSE_OTHER;
          // const query = `?cacheKey=${cacheKey}&propertyId=${propertyId}&inventoryId=${inventoryId}&checkIn=${from}&checkOut=${end}&placeId=${placeId}&countryCode=${countryCode}&latitude=${latitude}&longitude=${longitude}&hashGet=${hashGet}`
          // push('/admin/property-page' + query);
          push("/admin/property-page", { query: params });
        }
      }
    },
    [bookingParams, countryName, location?.state?.query?.checkIn, location?.state?.query?.checkOut, location?.state?.query?.propLocationId, locationId, noOfNights, programmeId, push, selectedLocation, watch]
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onRequest = useCallback(
    (value) => {
      /**
       * generate query params
       */
      let params: any = {};
      params.programmeId = programmeId;
      params.isProgramme = bookingParams?.isProgramme;
      params.noOfNights = noOfNights;
      if (bookingParams) {
        params.from = bookingParams.checkInDate;
        params.end = bookingParams.checkOutDate;
        params.countryCode = watch("country")
          ? watch("country")
          : bookingParams?.countryCode;
        params.placeId = watch("city") ? watch("city") : bookingParams?.placeId;
      } else {
        params.locationId = location?.state?.query?.propLocationId;
        params.from = location?.state?.query?.checkIn;
        params.end = location?.state?.query?.checkOut;
      }
      if (watch("location")) {
        if (value) {
          params.cacheKey = value.cacheKey;
          params.propertyId = value.property._id;
          params.inventoryId = value.propertyInventory._id;
          params.location = bookingParams
            ? bookingParams.location
            : selectedLocation;
          params.locationId = watch("location")
            ? watch("location")
            : locationId;
          params.hashGet = AvailabilityHashGet.SEARCH_RESPONSE;
        }
      } else {
        if (value) {
          params.cacheKey = value.cacheKey;
          params.propertyId = value.property._id;
          params.inventoryId = value.propertyInventory._id;
          params.country = bookingParams ? bookingParams.country : countryName;
          params.center_latitude = 0;
          params.center_longitude = 0;
          params.myoCorporate = true;
          params.hashGet = AvailabilityHashGet.SEARCH_RESPONSE_OTHER;
        }
      }

      /**
       * generate booking request 
       */
      setRequestAvailability(true);
      if (requestData.length === 0) {
        const availableRequest = {
          requestData: value,
          query: params
        }
        setRequestData([availableRequest]);
      } else {
        let isNotAvailable = true;
        for (const checkValue of requestData) {
          if (checkValue.requestData.propertyInventory._id === value.propertyInventory._id) {
            isNotAvailable = false;
            break;
          }
        }
        if (isNotAvailable) {
          const availableRequest = {
            requestData: value,
            query: params
          }
          const data = [...requestData, availableRequest];
          setRequestData(data);
        }
      }
    },
    [bookingParams, countryName, location, locationId, noOfNights, programmeId, requestData, selectedLocation, watch]
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onRemoveList = useCallback(
    (data) => {
      const isRemove = requestData.filter(
        (prop: any) => prop.requestData.property._id !== data.property._id
      );
      setRequestData(isRemove);
    },
    [requestData]
  );

  const onShareProperty = useCallback(
    (value) => {
      let params: any = {};
      params.programmeId = programmeId;
      params.isProgramme = bookingParams?.isProgramme;
      params.noOfNights = noOfNights;
      if (bookingParams) {
        params.from = bookingParams.checkInDate;
        params.end = bookingParams.checkOutDate;
        params.countryCode = watch("country")
          ? watch("country")
          : bookingParams?.countryCode;
        params.placeId = watch("city") ? watch("city") : bookingParams?.placeId;
      } else {
        params.locationId = location?.state?.query?.propLocationId;
        params.from = location?.state?.query?.checkIn;
        params.end = location?.state?.query?.checkOut;
      }
      if (watch("location")) {
        if (value) {
          params.cacheKey = value.cacheKey;
          params.propertyId = value.property._id;
          params.inventoryId = value.propertyInventory._id;
          params.location = bookingParams
            ? bookingParams.location
            : selectedLocation;
          params.locationId = watch("location")
            ? watch("location")
            : locationId;
          params.hashGet = AvailabilityHashGet.SEARCH_RESPONSE;
        }
      } else {
        if (value) {
          params.cacheKey = value.cacheKey;
          params.propertyId = value.property._id;
          params.inventoryId = value.propertyInventory._id;
          params.country = bookingParams ? bookingParams.country : countryName;
          params.center_latitude = 0;
          params.center_longitude = 0;
          params.myoCorporate = true;
          params.hashGet = AvailabilityHashGet.SEARCH_RESPONSE_OTHER;
        }
      }
      setShowShareProperty(true);
      if (shareData.length === 0) {
        const available = {
          requestData: value,
          query: params
        }
        setShareData([available]);
      } else {

        const available = {
          requestData: value,
          query: params
        }
        const data = [...shareData, available];
        setRequestData(data);

        setShareData(data);
      }
    },
    [bookingParams, countryName, location?.state?.query?.checkIn, location?.state?.query?.checkOut, location?.state?.query?.propLocationId, locationId, noOfNights, programmeId, selectedLocation, shareData, watch]
  );


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onShareRemoveList = useCallback(
    (data) => {
      const isRemove = shareData.filter(
        (prop: any) => prop?.requestData?.property._id !== data.property._id
      );
      setShareData(isRemove);
    },
    [shareData]
  );

  const onTravelerProfile = useCallback((value) => {
    setShowTravelerProfile(true);
  }, []);


  const setLocationData = useCallback((location) => {
    setOtherLocation(location);
    setShowCommute(false);
    setShowCommuteMap(true);
    setTravelMode("WALKING");
    // setCommuteBuilding('');
    // setCommuteBuilding(commuteBuilding);
    setLocations({ lat: Number(location.lat), lng: Number(location.lng), locationName: location.address1 });
    setLocationsShowCommute({ lat: Number(location.lat), lng: Number(location.lng), locationName: location.address1 });

    if (selectedPriceList !== "price_asc") {
      const params: any = {};
      params.placeId = bookingParams.placeId;
      params.checkInDate = bookingParams?.checkInDate;
      params.checkOutDate = bookingParams?.checkOutDate;
      params.countryCode = bookingParams?.countryCode;
      params.center_latitude = Number(location.lat);
      params.center_longitude = Number(location.lng);
      params.myoCorporate = true;
      getAvailabilityAndRateByOtherLocation(params);
    }
  }, [bookingParams, getAvailabilityAndRateByOtherLocation, selectedPriceList]);


  const setLocationDataShowCommute = useCallback((location) => {

    setLocationsShowCommute({ lat: Number(location.lat), lng: Number(location.lng), locationName: location.address1 });
    setShowCommuteMap(true);
  }, []);


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onShowCommute = useCallback((value) => {
    if (bookingParams?.isProgramme === "MYO" ||
      location?.state?.query?.isProgramme === "MYO") {
      setShowMessage(true);
    } else {
      setShowCommute(true);
    }
    setTravelMode("WALKING");
    // setCommuteBuilding('');
    setCommuteBuilding(value);

    if (otherLocation) {
      setShowCommuteMap(true);
    } else {
      setShowCommuteMap(false);
    }
    // setLocations({
    //   lat: Number(value?.property?.latitude),
    //   lng: Number(value?.property?.longitude),
    //   locationName: value?.property?.propertyName,
    // });
  }, [otherLocation, bookingParams?.isProgramme, location?.state?.query?.isProgramme]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onChangeTravelMode = useCallback((value) => {
    if (value) {
      setTravelMode(value);
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onResponse = useCallback((event) => {
    setDuration(event.routes[0].legs[0].duration.text);
    setDistance(event.routes[0].legs[0].distance.text);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSearchResult = useCallback((value) => {
    if (value.location) {
      setLoading(true);
      const params: any = {};
      if (roomType !== 0) {
        params.filter_no_bedroom = roomType;
      }
      params.locationId = locationId ? locationId : value.location;
      params.checkInDate = checkInDate ? checkInDate : value.fromDate[0];
      params.checkOutDate = checkOutDate ? checkOutDate : value.fromDate[1];
      getAvailabilityAndRateByLocation(params);
      setInventories([]);
      setBuilding([]);

      // if (watch("fromDate")) {
      //   const date: any = watch("fromDate");
      //   const date1 = new Date(checkInDate ? checkInDate : date[0]);
      //   const date2 = new Date(checkOutDate ? checkOutDate : date[1]);
      //   const Difference_In_Time = date2.getTime() - date1.getTime();
      //   const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      //   setNoOfNights(Difference_In_Days);
      // }
    } else {
      setLoading(true);
      const params: any = {};
      // params.placeId = otherLocation.placeId;
      // params.checkInDate = value.fromDate[0] ? value.fromDate[0] : checkInDate;
      // params.checkOutDate = value.fromDate[1] ? value.fromDate[1] : checkOutDate;
      // params.countryCode = otherLocation.countryCode;
      // params.center_latitude = otherLocation.lat;
      // params.center_longitude = otherLocation.lng;
      if (roomType !== 0) {
        params.filter_no_bedroom = roomType;
      }
      params.placeId = cityName ? cityName : value.city;
      params.checkInDate = checkInDate ? checkInDate : value.fromDate[0];
      params.checkOutDate = checkOutDate ? checkOutDate : value.fromDate[1];
      params.countryCode = countryCode ? countryCode : value.country;
      params.center_latitude = 0;
      params.center_longitude = 0;
      params.myoCorporate = true;
      getAvailabilityAndRateByOtherLocation(params);
      setInventories([]);
      setBuilding([]);
      // if (watch("fromDate")) {
      //   const date: any = watch("fromDate");
      //   const date1 = new Date(checkInDate ? checkInDate : date[0]);
      //   const date2 = new Date(checkOutDate ? checkOutDate : date[1]);
      //   const Difference_In_Time = date2.getTime() - date1.getTime();
      //   const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      //   setNoOfNights(Difference_In_Days);
      // }
    }


  },
    [checkInDate, checkOutDate, cityName, countryCode, getAvailabilityAndRateByLocation, getAvailabilityAndRateByOtherLocation, locationId, roomType]
  );

  const getImageUrl = useCallback((images) => {
    const imageUrl = images.filter((value) => value?.primaryImage);
    if (imageUrl.length > 0) {
      return imageUrl[0].url;
    } else {
      return images[0].url;
    }
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderBookingData = useCallback(() => {
    // style={{ borderTopLeftRadius: '28px', width: '390px', borderTopRightRadius: '28px', borderBottomLeftRadius: '28px', borderBottomRightRadius: '28px', marginInline: '40px', marginLeft: '-15px', marginBottom: '25px', marginTop: '14px', backgroundColor: '#F2F2F2' }}
    if (inventories) {
      return inventories.map((acc: any, key: number) => {
        return (
          <Col md={6} xl={4} className="mb-4" key={key}>
            <div
              data-id={acc?.property?._id}
              className="card result-item m-0"
              style={{
                borderTopLeftRadius: "28px",
                borderTopRightRadius: "28px",
                borderBottomLeftRadius: "28px",
                borderBottomRightRadius: "28px",
                backgroundColor: "#F2F2F2",
              }}
            >
              {acc.propertyImage?.image ? (
                <Image
                  src={getImageUrl(acc?.propertyImage?.image)}
                  alt="avatar"
                  className="res-image"
                  onClick={() => { setShowCommute(false); setValue('otherLocation', '') }}
                ></Image>
              ) : (
                <Image src={photo} alt="avatar" className="res-image"
                  onClick={() => { setShowCommute(false); setValue('otherLocation', '') }}
                ></Image>
              )}
              <div className="card-body pl-4" style={{ display: "flex", flexDirection: "column" }}>

                <div className="bookingListContGroup">
                  <div
                    className="font-bold text-left"
                    style={{ fontSize: "22px" }}
                  >
                    {acc.property.propertyName}
                  </div>
                  <div className=" text-left" style={{ fontSize: "14px" }}>
                    {" "}
                    <span style={{ fontWeight: 700 }}>
                      {" "}
                      {acc.property?.propertyType?.name}
                    </span>{" "}
                    <span style={{ fontWeight: 400 }}>
                      {" by "} {acc?.property?.brandId?.name}
                    </span>
                  </div>
                  <Card.Subtitle
                    className="mb-2 d-flex align-items-center justify-between"
                    style={{ marginTop: "10px", textAlign: "left" }}
                  >
                    <span
                      style={{
                        color: "#000000",
                        fontSize: "12px",
                        textAlign: "left"
                      }}
                    > {acc.propertyInventory?.accommodationDetail} </span>
                    <span
                      style={{
                        color: "#000000",
                        textDecoration: "underline",
                        fontSize: "12px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        textDecorationColor: "#e80f8b",
                      }}
                      onClick={() => onShowCommute(acc)}
                    >
                      Show commute
                    </span>
                  </Card.Subtitle>
                  <div style={{ paddingTop: "0px" }} className="text-left">
                    <Button
                      id="selfCheckIn-button"
                      className="checkin-reception bookingCheckinProcess"
                      style={{
                        border: "none",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      {/* <Col md={4}> */}
                      <div className="flex align-items-center justify-centern">
                        <img
                          style={{
                            marginRight: "7px",
                            height: "26px",
                            width: "26px",
                          }}
                          src={
                            acc.propertyCheckInProcess?.checkInProcess
                              ?.iconLocation
                          }
                          alt="icon"
                        ></img>
                        <p
                          style={{
                            fontSize: "12px",
                            margin: "0px",
                          }}
                        >
                          {acc.propertyCheckInProcess?.checkInProcess?.name}
                        </p>
                      </div>
                      {/* </Col> */}
                    </Button>
                  </div>
                  <div className="flex justify-between align-items-end mb-3">
                    <div>
                      <div className="flex align-items-center">
                        <img className="icon" src={bedroom} alt="" />
                        <div
                          style={{
                            fontSize: "12px",
                            marginTop: "0px",
                          }}
                        >
                          {acc.propertyInventory.noOfBedrooms} Bed
                        </div>
                      </div>
                      <div className="flex align-items-center">
                        <img className="icon" src={bathroom} alt="" />
                        <div
                          style={{
                            fontSize: "12px",
                            marginTop: "0px",
                          }}
                        >
                          {acc.propertyInventory.noOfBathrooms} Bathroom
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="text-right font-bold price"
                        style={{ fontSize: "22px" }}
                      >
                        {acc.property?.currency}{" "}
                        {(
                          Number(acc.rateAndPolicy?.finalRate) / noOfNights
                        ).toFixed(0)}
                      </div>
                      <div
                        className="text-left flex align-items-center"
                        style={{ fontSize: "12px", fontWeight: 400 }}
                      >
                        {"Total "}
                        {acc.property?.currency}{" "}
                        {Number(acc.rateAndPolicy?.finalRate)}
                        <OverlayTrigger
                          placement="top"
                          delay={overlayTriggerDelay}
                          overlay={
                            <Tooltip id="tooltip-table-top" style={toolTipStyle}>
                              This rate does not include any local or national
                              taxes, or any other additional charges.
                            </Tooltip>
                          }
                        >
                          <img className="icon" src={InfoIcon} alt="" />
                        </OverlayTrigger>
                        {/* <img className='icon' src={InfoIcon} alt=""  /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bookingListBtnGroup">
                  <Row style={{ marginTop: "0px" }}>
                    <div style={{ paddingTop: "0px" }} className="text-center">
                      <Button
                        style={viewButton}
                        id="routing-button"
                        className="bookingViewProperty"
                        onClick={() => onBooking(acc)}
                      >
                        View Property
                      </Button>
                    </div>
                  </Row>
                  <Row>
                    <Col className="flex justify-between" span={24}>
                      {/* <div style={{ paddingTop: '35px' }} className="text-center" >
                      <Button id='share-button' style={shareButton} >Share Property</Button>
                    </div> */}
                      <div
                        style={{
                          paddingTop: "15px",
                          flex: "0 0 50%",
                          paddingRight: "8px",
                        }}
                        className="text-center"
                      >
                        <OverlayTrigger
                          placement="top"
                          delay={overlayTriggerDelay}
                          overlay={
                            <Tooltip id="tooltip-table-top" style={toolTipStyle}>
                              Click to share with traveller
                            </Tooltip>
                          }
                        >
                          <Button
                            onClick={() => {
                              onShareProperty(acc);
                            }}
                            id="share-button"
                            className="bookingShareProperty"
                            style={shareButton}
                          >
                            Share Property
                          </Button>
                        </OverlayTrigger>
                      </div>

                      <div
                        style={{
                          paddingTop: "15px",
                          flex: "0 0 50%",
                          paddingLeft: "8px",
                        }}
                        className="text-center"
                      >
                        {acc.isAvailable && (
                          <Button
                            id="approve-button"
                            style={requestButton}
                            className="bookingBookNow"
                            onClick={() => onTravelerProfile(acc)}
                          >
                            Book Now
                          </Button>
                        )}

                        {!acc.isAvailable && (
                          <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={
                              <Tooltip
                                id="tooltip-table-top"
                                style={toolTipStyle}
                              >
                                Click to request availability
                              </Tooltip>
                            }
                          >
                            <Button
                              id="resend-button"
                              style={requestButton}
                              className="bookingRequestAvailability"
                              onClick={() => onRequest(acc)}
                            >
                              Request Availability
                            </Button>
                          </OverlayTrigger>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                <Row style={{ marginTop: "10px" }}>
                  {/* <div style={{ paddingTop: '0px', marginBottom: '-15px' }} className="text-center" >
                  <p style={{ fontWeight: 'bold', fontSize: '12px', cursor: 'pointer' }} onClick={() => setTravelMode('TRANSIT')}>Show commute</p>
                </div>
                <div id='show-commute' className="text-center" >
                </div> */}
                </Row>
              </div>
            </div>
          </Col>
        );
      });
    }
  }, [getImageUrl, inventories, noOfNights, onBooking, onRequest, onShareProperty, onShowCommute, onTravelerProfile, setValue]);

  const renderShare = useCallback(() => {
    if (shareData) {
      return shareData?.map((acc: any, key: number) => {
        return (
          <SwiperSlide key={key}>
            <Card style={travelerScreen}>
              <div id="request-availability">
                <Image
                  style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                  src={acc?.requestData?.propertyImage?.image[0].url}
                  alt="avatar"
                  className="shareAvilBoxImage"
                ></Image>
                <div className="">
                  <div
                    style={{
                      position: "absolute",
                      top: "15px",
                      right: "15px",
                    }}
                  >
                    <img
                      src={CrossIcon}
                      alt=""
                      onClick={() => onShareRemoveList(acc?.requestData)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <Card.Body>
                    <div
                      className="font-bold"
                      style={{
                        fontSize: "18px",
                        textAlign: "start",
                        marginBottom: "10px",
                      }}
                    >
                      {acc?.requestData?.property.propertyName}
                    </div>
                    <div>
                      <div
                        className="text-left font-bold price"
                        style={{ fontSize: "18px" }}
                      >
                        {acc?.requestData?.property?.currency}{" "}
                        {(
                          Number(acc?.requestData?.rateAndPolicy?.finalRate) / noOfNights
                        ).toFixed(0)}
                      </div>
                      <div
                        className="text-left flex align-items-center"
                        style={{
                          fontSize: "14px",
                          marginBottom: "12px",
                          fontWeight: 400,
                        }}
                      >
                        {"Total "}
                        {acc?.requestData?.property?.currency}{" "}
                        {Number(acc?.requestData?.rateAndPolicy?.finalRate)}
                        {/* <img className='icon' src={InfoIcon} alt=""  /> */}
                      </div>
                    </div>
                  </Card.Body>
                </div>
              </div>
            </Card>
          </SwiperSlide>
        );
      });
    }
  }, [noOfNights, onShareRemoveList, shareData]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderCountry = useCallback(() => {
    if (countryList) {
      return countryList.map((acc: any, key: number) => {
        return {
          key: key,
          value: `${acc._id?.countryCode}`,
          label: acc._id?.country,
          country: acc._id,
        };
      });
    }
  }, [countryList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const changeHandler = useCallback(
    (val) => {
      if (val) {
        setCountryName(val.country);
        setCountryCode(val.value);
        getAllPropertyCities(val.value);
      }
    },
    [getAllPropertyCities]
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderCity = useCallback(() => {
    if (cityList) {
      return cityList.map((acc: any, key: number) => {
        return {
          key: key,
          value: `${acc._id}`,
          label: acc._id,
        };
      });
    }
  }, [cityList]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onCityChange = useCallback((val) => {
    setCityName(val.value);
  }, []);




  useEffect(() => {
    getUserMapping({});
  }, [getUserMapping]);

  useEffect(() => {

    if (getUserMappingSuccess) {
      const { result, poolUsers } = getUserMappingSuccess.data;

      /**
       * corporate users
       */
      const corporateUserList = corporateUser(result, poolUsers);

      const programmeId = getStorage('makeBookingProgram')?.value ? getStorage('makeBookingProgram')?.value : getStorage('selectedProgramsOption')?.value ? getStorage('programmeId') : getStorage('programmeData')?._id;
      const filterData = _.filter(corporateUserList, (a) => a.corporateProgrammeId === programmeId && a.role === UserGroups.traveler);
      setUserMappingData(filterData);

    }

  }, [getUserMappingSuccess]);


  async function fetchUserList(email) {
    const param = email === '' ? null : email;

    const regEx = new RegExp(param, 'gi');
    const filterData = _.filter(userMappingData, (a) => a.email.match(regEx));

    setTravelerData(filterData);
    return filterData.map((user) => ({
      label: user.email,
      value: user.email,
      user
    }));
  }



  useEffect(() => {
    if (programmeId) {
      getCorporateProgrammeById(programmeId);
    }
  }, [getCorporateProgrammeById, programmeId]);

  useEffect(() => {
    if (getCorporateProgrammeByIdSuccess) {
      setProgrammerUserDomains(getCorporateProgrammeByIdSuccess.data?.companyDomains);
    }
  }, [getCorporateProgrammeByIdSuccess]);


  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {

    // user create success
    if (userCreate) {
      if (userCreate.data.data) {
        // create mapping
        createUserMapping({
          userId: userCreate?.data?.user?._id,
          email: userCreate?.data?.user?.email,
          clientId: getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('programmeData')?.clientId?._id,
          programmeId,
          role: UserGroups.traveler
        })
        const payload = {
          travelerId: userCreate?.data?.user?._id,
          clientId: getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('programmeData')?.clientId?._id,
          bookerUserId: getStorage('myo_logged_user')?.userData?._id,
          selectedOptions: optionData,
          bookerFirstName: getStorage('myo_logged_user')?.userData?.firstName,
          bookerLastName: getStorage('myo_logged_user')?.userData?.lastName,
          travelerFirstName: userCreate?.data?.user?.firstName,
          travelerLastName: userCreate?.data?.user?.lastName,
          toEmail: userCreate?.data?.user?.email,
          isOldUser: false
        }
        createTravelerOption(payload);
      } else {
        //update mapping
        updateUserMapping({
          userId: userCreate?.data?.user?._id,
          client: {
            clientId: getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('programmeData')?.clientId?._id,
          },
          corporateProgramme: {
            programmeId,
            role: UserGroups.traveler
          }

        })
        const payload = {
          travelerId: userCreate?.data?.user?._id,
          clientId: getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('programmeData')?.clientId?._id,
          bookerUserId: getStorage('myo_logged_user')?.userData?._id,
          selectedOptions: optionData,
          bookerFirstName: getStorage('myo_logged_user')?.userData?.firstName,
          bookerLastName: getStorage('myo_logged_user')?.userData?.lastName,
          travelerFirstName: userCreate?.data?.user?.firstName,
          travelerLastName: userCreate?.data?.user?.lastName,
          toEmail: email,
          isOldUser: false
        }
        createTravelerOption(payload);
      }

    }

    // update user mapping success 
    if (updateUserMappingSuccess || createUserMappingSuccess) {


    }




  }, [createTravelerOption, createUserMapping, createUserMappingSuccess, email, optionData, programmeId, travelerUserId, updateUserMapping, updateUserMappingSuccess, userCreate]);
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {

    // user create success
    if (createTravelerOptionSuccess) {
      toast.success('Traveler option created successfully', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setShowShareProperty(false);
    }

  }, [createTravelerOptionSuccess]);


  const onSubmitOption = useCallback((values) => {

    // check domain
    // setStorage('travelerForm', true);
    const buildingData: any = [];

    _.map(shareData, (data: any) =>
      buildingData.push({
        country: data?.requestData?.property?.country,
        city: data?.requestData?.property?.city,
        checkInDate: moment(data?.requestData?.checkIn).format('YYYY-MM-DD'),
        checkOutDate: moment(data?.requestData?.checkOut).format('YYYY-MM-DD'),
        propertyId: data?.requestData?.property?._id,
        propertyInventoryId: data?.requestData?.propertyInventory?._id,
        messageThread: [{
          direction: 'booker_to_traveler',
          messageText: values.message
        }],
        query: data?.query
      })
    );
    setOptionData(buildingData);
    if (showProfile) {

      const getDomainValidation = getMailDomain(showProfile ? values.email : email, programmerUserDomains);

      if (getDomainValidation) {
        const value = {
          userGroup: UserGroups.user,
          email: showProfile ? values.email : email,
          firstName: values.firstName,
          lastName: values.lastName
        }


        createUser(value);
      } else {
        toast.error('Invalid company domain. please check your e-mail address domain with company domains', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
        });
      }
    } else {
      const payload = {
        travelerId: travelerUserId,

        clientId: getStorage('selectedFilterClient')?.value ? getStorage('selectedFilterClient')?.value : getStorage('programmeData')?.clientId?._id,
        bookerUserId: getStorage('myo_logged_user')?.userData?._id,
        selectedOptions: buildingData,
        bookerFirstName: getStorage('myo_logged_user')?.userData?.firstName,
        bookerLastName: getStorage('myo_logged_user')?.userData?.lastName,
        travelerFirstName: values.firstName,
        toEmail: email,
        isOldUser: true
      }

      createTravelerOption(payload);

    }

  }, [createTravelerOption, createUser, email, programmerUserDomains, shareData, showProfile, travelerUserId]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="booking-form text-center">
      <Container style={{ maxWidth: "1675px" }}>
        <div>
          <Row
            style={{
              position: "fixed",
              left: "6.9vw",
              top: "70px",
              width: "100%",
              padding: '5vh 1.5vw',
              zIndex: 20,
              backgroundColor: "#fff",
            }}
          >
            <Form onSubmit={handleSubmit(onSearchResult)}>

              <Row  >
                <Col md={8} style={{ width: '72%', marginLeft: '-20px' }}>
                  <Row style={{
                    height: "64px",
                    gap: " 0px ",
                    borderRadius: "25px 25px 25px 25px",
                    opacity: "0px ",
                    backgroundColor: "#35BDB4"
                  }}>
                    <Col
                      md={
                        bookingParams?.isProgramme === "MYO Plus" ||
                          bookingParams?.isProgramme === "Specified only" ||
                          location?.state?.query?.isProgramme === "MYO Plus" ||
                          location?.state?.query?.isProgramme === "Specified only"
                          ? 3
                          : 2
                      }
                    >
                      {(bookingParams?.isProgramme === "MYO Plus" ||
                        bookingParams?.isProgramme === "Specified only" ||
                        location?.state?.query?.isProgramme === "MYO Plus" ||
                        location?.state?.query?.isProgramme ===
                        "Specified only") && (
                          <Controller
                            name="location"
                            defaultValue={
                              bookingParams
                                ? bookingParams.locationId
                                : location?.state?.query?.propLocationId
                            }
                            control={control}
                            render={({ field }) => (
                              <div
                                style={{
                                  width: "321px",
                                  height: "44px",
                                  marginTop: '0px',
                                  marginLeft: '10px'
                                }}
                              >
                                <Select
                                  components={{ DropdownIndicator: () => null }}
                                  isSearchable
                                  placeholder={
                                    <div className="flex justify-start ">
                                      <div style={{ marginRight: "6%" }}>
                                        <img
                                          style={{ width: "21px", height: "26px" }}
                                          src={BookMarkIcon}
                                          alt="book mark "
                                        />
                                      </div>
                                      <div className="font-light">
                                        Select company location
                                      </div>
                                    </div>
                                  }
                                  options={renderCorporateLocationNew()}
                                  defaultValue={{
                                    value: bookingParams
                                      ? bookingParams?.locationId
                                      : location?.state?.query?.propLocationId,
                                    label: bookingParams
                                      ? bookingParams?.location?.locationName
                                      : location?.state?.query?.locationData
                                        ?.locationName,
                                  }}
                                  className={`${errors.location ? "is-invalid" : ""
                                    } font-light`}
                                  onChange={onLocationChange}
                                  styles={BookMarkStyles}
                                />
                              </div>
                            )}
                          />
                        )}

                      {(bookingParams?.isProgramme === "MYO" ||
                        location?.state?.query?.isProgramme === "MYO") && (
                          <Controller
                            name="country"
                            control={control}
                            defaultValue={bookingParams?.countryCode}
                            render={({ field }) => (
                              <div
                                style={{
                                  width: "200px",
                                  height: "44px",
                                  marginTop: "10px",
                                  marginLeft: "10px"
                                }}
                              >
                                <Select
                                  components={{ DropdownIndicator: () => null }}

                                  defaultValue={{
                                    value: bookingParams
                                      ? bookingParams?.countryCode
                                      : location?.state?.query?.countryData
                                        ?.countryCode,
                                    label: bookingParams
                                      ? bookingParams?.country?.country
                                      : location?.state?.query?.countryData?.country,
                                  }}
                                  isSearchable
                                  placeholder={
                                    <div className="flex justify-start ">
                                      <div style={{ marginRight: "6%" }}>
                                        <img
                                          style={{ width: "21px", height: "26px" }}
                                          src={BookMarkIcon}
                                          alt="book mark "
                                        />
                                      </div>
                                      <div className="font-light">Select Country</div>
                                    </div>
                                  }
                                  options={renderCountry()}
                                  onChange={changeHandler}
                                  className={`${errors.country ? "is-invalid" : ""
                                    } font-light`}
                                  styles={BookMarkStyles}
                                />
                              </div>
                            )}
                          />
                        )}

                    </Col>
                    {(bookingParams?.isProgramme === "MYO" ||
                      location?.state?.query?.isProgramme === "MYO") && (
                        <Col md={2}>
                          <Controller
                            name="city"
                            defaultValue={bookingParams?.placeId}
                            control={control}
                            render={({ field }) => (
                              <div
                                style={{
                                  width: "180px",
                                  height: "44px",
                                  marginTop: "10px",
                                  marginLeft: "0px"
                                }}
                              >
                                <Select
                                  components={{ DropdownIndicator: () => null }}
                                  defaultValue={{
                                    value: bookingParams
                                      ? bookingParams?.placeId
                                      : location?.state?.query?.placeId,
                                    label: bookingParams
                                      ? bookingParams?.placeId
                                      : location?.state?.query?.placeId,
                                  }}
                                  isSearchable
                                  placeholder={
                                    <div className="flex justify-start ">
                                      <div style={{ marginRight: "6%" }}>
                                        <img
                                          style={{ width: "21px", height: "26px" }}
                                          src={BookMarkIcon}
                                          alt="book mark "
                                        />
                                      </div>
                                      <div className="font-light">Select city</div>
                                    </div>
                                  }
                                  options={renderCity()}
                                  className={`${errors.city ? "is-invalid" : ""
                                    } font-light`}
                                  onChange={onCityChange}
                                  styles={BookMarkStyles}
                                />
                              </div>
                            )}
                          />
                        </Col>
                      )}


                    <Col
                      md={
                        bookingParams?.isProgramme === "MYO Plus" ||
                          bookingParams?.isProgramme === "Specified only" ||
                          location?.state?.query?.isProgramme === "MYO Plus" ||
                          location?.state?.query?.isProgramme === "Specified only"
                          ? 3
                          : 2
                      }
                    >
                      {(bookingParams?.isProgramme === "MYO Plus" ||
                        bookingParams?.isProgramme === "Specified only" ||
                        bookingParams?.isProgramme === "MYO" ||
                        location?.state?.query?.isProgramme === "MYO Plus" ||
                        location?.state?.query?.isProgramme === "Specified only" ||
                        location?.state?.query?.isProgramme === "MYO") && (
                          <Controller
                            name="fromDate"
                            defaultValue={[
                              bookingParams
                                ? bookingParams?.checkInDate
                                : location?.state?.query?.checkIn,
                              bookingParams
                                ? bookingParams?.checkOutDate
                                : location?.state?.query?.checkOut,
                            ]}
                            control={control}
                            render={({ field }) => (
                              <div
                                style={{
                                  borderRadius: "11px",
                                  marginLeft: '-30px',
                                  width: "240px",
                                  height: "44px",
                                  display: "flex",
                                  alignItems: "center",
                                  border: "1px solid hsl(0, 0%, 70%)",
                                  paddingLeft: "10px",
                                  marginTop: '10px',
                                  backgroundColor: '#FFFFFF',
                                }}
                              >
                                <div>
                                  <img
                                    style={{ width: "26px", height: "25.32px" }}
                                    src={CalenderIcon}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <RangePicker
                                    autoFocus={false}
                                    bordered={false}
                                    className="font-light"
                                    suffixIcon={<div></div>}
                                    style={{
                                      border: "none",
                                      fontWeight: 300,
                                      fontSize: "20px",
                                    }}
                                    placeholder={["Check-in", "Check-out"]}
                                    onChange={onFromDateChange}
                                    disabledDate={disabledFromDate}
                                    defaultValue={[
                                      moment(
                                        new Date(
                                          bookingParams
                                            ? bookingParams?.checkInDate
                                            : location?.state?.query?.checkIn
                                        ),
                                        "YYYY-MM-DD"
                                      ),
                                      moment(
                                        new Date(
                                          bookingParams
                                            ? bookingParams?.checkOutDate
                                            : location?.state?.query?.checkOut
                                        ),
                                        "YYYY-MM-DD"
                                      ),
                                    ]}
                                  />
                                </div>
                              </div>
                            )}
                          />
                        )}
                    </Col>

                    <Col md={2}>
                      {(bookingParams?.isProgramme === "MYO Plus" ||
                        bookingParams?.isProgramme === "Specified only" ||
                        bookingParams?.isProgramme === "MYO" ||
                        location?.state?.query?.isProgramme === "MYO Plus" ||
                        location?.state?.query?.isProgramme === "Specified only" ||
                        location?.state?.query?.isProgramme === "MYO") && (
                          <Controller
                            name="accommodation"
                            defaultValue={""}
                            control={control}
                            render={({ field }) => (
                              <div
                                style={{
                                  marginLeft: "0px",
                                  width: "220px",
                                  marginTop: "10px"

                                }}
                              >
                                <Select
                                  styles={RoomTypeStyles}
                                  className={`${errors.accommodation ? "is-invalid " : ""
                                    } font-light`}
                                  options={roomTypeList}
                                  onChange={onAccommodationChange}
                                  isSearchable={true}
                                  defaultValue={{
                                    value: bookingParams?.roomType?.value,

                                    label: bookingParams?.roomType?.label
                                  }}
                                  placeholder={
                                    <div className="flex justify-start ">
                                      <div style={{ marginRight: "6%" }}>
                                        <img
                                          style={{ width: "21px", height: "26px" }}
                                          src={FilterIcon}
                                          alt="book mark "
                                        />
                                      </div>
                                      <div className="font-light">
                                        Accommodation Type
                                      </div>
                                    </div>
                                  }
                                  components={{ DropdownIndicator: () => null }}
                                />
                              </div>
                            )}
                          />
                        )}
                    </Col>

                    <Col md={2} style={{

                      marginTop: '10px',
                      marginLeft: '0px'
                    }}>
                      {!loading && (

                        <Button type="submit" style={searchButton2} id='search-button' >
                          <div className="flex justify-center" style={{

                            marginTop: '-10px',

                          }}>
                            <div >
                              <img src={searchIcon} className="searchIcon" alt="" />
                            </div>
                            <div style={{ marginTop: '15px' }}>Search</div>
                          </div>

                        </Button>
                      )}
                      {loading && (
                        <div style={{ textAlign: "center" }}>
                          <Spinner animation="border" variant="warning" />
                        </div>
                      )}
                    </Col>

                    <Col md={2}>
                      <Controller
                        name="price"
                        defaultValue={""}
                        control={control}
                        render={({ field }) => (
                          <div
                            style={{
                              marginLeft: "-20px",
                              width: "210px",
                              height: "44px",
                              marginTop: "10px"
                            }}
                          >
                            <Select
                              defaultValue={[priceList[0]]}
                              styles={PriceStyles}
                              className={`${errors.price ? "is-invalid " : ""
                                } font-light`}
                              options={priceList}
                              onChange={onPriceChange}
                              // placeholder={
                              //   <div className="flex justify-start ">

                              //     <div className="font-light">
                              //       Price & Distance
                              //     </div>
                              //   </div>
                              // }

                              placeholder={
                                <div className="flex justify-start ">
                                  <div style={{ marginRight: "6%" }}>
                                    <img
                                      style={{ width: "21px", height: "26px" }}
                                      src={FilterIcon}
                                      alt="book mark"
                                    />
                                  </div>
                                  <div className="font-light">
                                    Accommodation Type
                                  </div>
                                </div>
                              }
                              components={{ DropdownIndicator: () => null }}
                            />
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col md={4} >
                </Col>
              </Row>

              {/* <Row className="">
                <Col
                  md={
                    bookingParams?.isProgramme === "MYO Plus" ||
                      bookingParams?.isProgramme === "Specified only" ||
                      location?.state?.query?.isProgramme === "MYO Plus" ||
                      location?.state?.query?.isProgramme === "Specified only"
                      ? 3
                      : 2
                  }
                >
                  {(bookingParams?.isProgramme === "MYO Plus" ||
                    bookingParams?.isProgramme === "Specified only" ||
                    location?.state?.query?.isProgramme === "MYO Plus" ||
                    location?.state?.query?.isProgramme ===
                    "Specified only") && (
                      <Controller
                        name="location"
                        defaultValue={
                          bookingParams
                            ? bookingParams.locationId
                            : location?.state?.query?.propLocationId
                        }
                        control={control}
                        render={({ field }) => (
                          <div
                            style={{
                              width: "321px",
                              height: "44px",

                            }}
                          >
                            <Select
                              components={{ DropdownIndicator: () => null }}
                              isSearchable
                              placeholder={
                                <div className="flex justify-start ">
                                  <div style={{ marginRight: "6%" }}>
                                    <img
                                      style={{ width: "21px", height: "26px" }}
                                      src={BookMarkIcon}
                                      alt="book mark "
                                    />
                                  </div>
                                  <div className="font-light">
                                    Select company location
                                  </div>
                                </div>
                              }
                              options={renderCorporateLocationNew()}
                              defaultValue={{
                                value: bookingParams
                                  ? bookingParams?.locationId
                                  : location?.state?.query?.propLocationId,
                                label: bookingParams
                                  ? bookingParams?.location?.locationName
                                  : location?.state?.query?.locationData
                                    ?.locationName,
                              }}
                              className={`${errors.location ? "is-invalid" : ""
                                } font-light`}
                              onChange={onLocationChange}
                              styles={BookMarkStyles}
                            />
                          </div>
                        )}
                      />
                    )}

                  {(bookingParams?.isProgramme === "MYO" ||
                    location?.state?.query?.isProgramme === "MYO") && (
                      <Controller
                        name="country"
                        control={control}
                        defaultValue={bookingParams?.countryCode}
                        render={({ field }) => (
                          <div
                            style={{
                              width: "221px",
                              height: "44px",

                            }}
                          >
                            <Select
                              components={{ DropdownIndicator: () => null }}

                              defaultValue={{
                                value: bookingParams
                                  ? bookingParams?.countryCode
                                  : location?.state?.query?.countryData
                                    ?.countryCode,
                                label: bookingParams
                                  ? bookingParams?.country?.country
                                  : location?.state?.query?.countryData?.country,
                              }}
                              isSearchable
                              placeholder={
                                <div className="flex justify-start ">
                                  <div style={{ marginRight: "6%" }}>
                                    <img
                                      style={{ width: "21px", height: "26px" }}
                                      src={BookMarkIcon}
                                      alt="book mark "
                                    />
                                  </div>
                                  <div className="font-light">Select Country</div>
                                </div>
                              }
                              options={renderCountry()}
                              onChange={changeHandler}
                              className={`${errors.country ? "is-invalid" : ""
                                } font-light`}
                              styles={BookMarkStyles}
                            />
                          </div>
                        )}
                      />
                    )}

                </Col>
                {(bookingParams?.isProgramme === "MYO" ||
                  location?.state?.query?.isProgramme === "MYO") && (
                    <Col md={2}>
                      <Controller
                        name="city"
                        defaultValue={bookingParams?.placeId}
                        control={control}
                        render={({ field }) => (
                          <div
                            style={{
                              width: "221px",
                              height: "44px",

                            }}
                          >
                            <Select
                              components={{ DropdownIndicator: () => null }}
                              defaultValue={{
                                value: bookingParams
                                  ? bookingParams?.placeId
                                  : location?.state?.query?.placeId,
                                label: bookingParams
                                  ? bookingParams?.placeId
                                  : location?.state?.query?.placeId,
                              }}
                              isSearchable
                              placeholder={
                                <div className="flex justify-start ">
                                  <div style={{ marginRight: "6%" }}>
                                    <img
                                      style={{ width: "21px", height: "26px" }}
                                      src={BookMarkIcon}
                                      alt="book mark "
                                    />
                                  </div>
                                  <div className="font-light">Select city</div>
                                </div>
                              }
                              options={renderCity()}
                              className={`${errors.city ? "is-invalid" : ""
                                } font-light`}
                              onChange={onCityChange}
                              styles={BookMarkStyles}
                            />
                          </div>
                        )}
                      />
                    </Col>
                  )}


                <Col
                  md={
                    bookingParams?.isProgramme === "MYO Plus" ||
                      bookingParams?.isProgramme === "Specified only" ||
                      location?.state?.query?.isProgramme === "MYO Plus" ||
                      location?.state?.query?.isProgramme === "Specified only"
                      ? 3
                      : 2
                  }
                >
                  {(bookingParams?.isProgramme === "MYO Plus" ||
                    bookingParams?.isProgramme === "Specified only" ||
                    bookingParams?.isProgramme === "MYO" ||
                    location?.state?.query?.isProgramme === "MYO Plus" ||
                    location?.state?.query?.isProgramme === "Specified only" ||
                    location?.state?.query?.isProgramme === "MYO") && (
                      <Controller
                        name="fromDate"
                        defaultValue={[
                          bookingParams
                            ? bookingParams?.checkInDate
                            : location?.state?.query?.checkIn,
                          bookingParams
                            ? bookingParams?.checkOutDate
                            : location?.state?.query?.checkOut,
                        ]}
                        control={control}
                        render={({ field }) => (
                          <div
                            style={{
                              borderRadius: "11px",
                              // marginLeft: '280px',
                              width: "271px",
                              height: "44px",
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid hsl(0, 0%, 70%)",
                              paddingLeft: "10px",
                            }}
                          >
                            <div>
                              <img
                                style={{ width: "26px", height: "25.32px" }}
                                src={CalenderIcon}
                                alt=""
                              />
                            </div>
                            <div>
                              <RangePicker
                                autoFocus={false}
                                bordered={false}
                                className="font-light"
                                suffixIcon={<div></div>}
                                style={{
                                  border: "none",
                                  fontWeight: 300,
                                  fontSize: "20px",
                                }}
                                placeholder={["Check-in", "Check-out"]}
                                onChange={onFromDateChange}
                                disabledDate={disabledFromDate}
                                defaultValue={[
                                  moment(
                                    new Date(
                                      bookingParams
                                        ? bookingParams?.checkInDate
                                        : location?.state?.query?.checkIn
                                    ),
                                    "YYYY-MM-DD"
                                  ),
                                  moment(
                                    new Date(
                                      bookingParams
                                        ? bookingParams?.checkOutDate
                                        : location?.state?.query?.checkOut
                                    ),
                                    "YYYY-MM-DD"
                                  ),
                                ]}
                              />
                            </div>
                          </div>
                        )}
                      />
                    )}
                </Col>

                <Col md={3}>
                  {(bookingParams?.isProgramme === "MYO Plus" ||
                    bookingParams?.isProgramme === "Specified only" ||
                    bookingParams?.isProgramme === "MYO" ||
                    location?.state?.query?.isProgramme === "MYO Plus" ||
                    location?.state?.query?.isProgramme === "Specified only" ||
                    location?.state?.query?.isProgramme === "MYO") && (
                      <Controller
                        name="accommodation"
                        defaultValue={""}
                        control={control}
                        render={({ field }) => (
                          <div
                            style={{
                              marginLeft: "50px",
                              width: "271px",

                            }}
                          >
                            <Select
                              styles={RoomTypeStyles}
                              className={`${errors.accommodation ? "is-invalid " : ""
                                } font-light`}
                              options={roomTypeList}
                              onChange={onAccommodationChange}
                              isSearchable={true}
                              placeholder={
                                <div className="flex justify-start ">
                                  <div style={{ marginRight: "6%" }}>
                                    <img
                                      style={{ width: "21px", height: "26px" }}
                                      src={FilterIcon}
                                      alt="book mark "
                                    />
                                  </div>
                                  <div className="font-light">
                                    Accommodation Type
                                  </div>
                                </div>
                              }
                              components={{ DropdownIndicator: () => null }}
                            />
                          </div>
                        )}
                      />
                    )}
                </Col>

                <Col md={3} style={{ width: "0%" }}>
                  {!loading && (
                    <Button type="submit" id="edit-button" style={formButton}>
                      Search
                    </Button>
                  )}
                  {loading && (
                    <div style={{ textAlign: "center" }}>
                      <Spinner animation="border" variant="warning" />
                    </div>
                  )}
                </Col>
              </Row> */}
            </Form>
          </Row>
        </div>
      </Container>
      {isResult && (
        <div
          style={{ marginRight: "20px", marginLeft: "28px", marginTop: "130px", }}
        >
          <div>
            <Row style={{
              marginTop: '130px',
              // position: "fixed",
              // left: "6.9vw",
              // top: "35px",

              // width: "100%",
              // padding: '5vh 1.5vw',
              // zIndex: 25,
              // backgroundColor: "#fff",

            }}

            >
              <Col md={2}>
                <p className=" mb-0" style={{
                  fontWeight: "bold", marginLeft: "-70px",
                  marginTop: "10px",
                  fontSize: "20px"
                }}>
                  {"Showing"} {resultLength} {"options"}
                </p>
              </Col>
              {/* <Col md={1}>
                <div
                  className="font-light"
                  style={{
                    marginLeft: "0px",
                    marginTop: "10px",
                  }}
                >
                  Sort by
                </div>
              </Col> */}
              {/* <Col md={3}>
                <Controller
                  name="price"
                  defaultValue={""}
                  control={control}
                  render={({ field }) => (
                    <div
                      style={{
                        marginLeft: "30px",
                        width: "321px",
                        height: "44px",
                      }}
                    >
                      <Select
                        defaultValue={[priceList[0]]}
                        styles={PriceStyles}
                        className={`${errors.price ? "is-invalid " : ""
                          } font-light`}
                        options={priceList}
                        onChange={onPriceChange}
                        placeholder={
                          <div className="flex justify-start ">

                            <div className="font-light">
                              Price & Distance
                            </div>
                          </div>
                        }
                        components={{ DropdownIndicator: () => null }}
                      />
                    </div>
                  )}
                />
              </Col> */}
              <Col md={3}>
                {
                  selectedPriceList && selectedPriceList !== "price_asc" && (bookingParams?.isProgramme === "MYO" ||
                    location?.state?.query?.isProgramme === "MYO") && (<div>
                      <Controller
                        name="otherLocation"
                        // defaultValue={''}
                        control={control}
                        render={({ field }) =>
                          <>
                            <GooglePlaces style={
                              {
                                backgroundColor: 'white', borderRadius: '11px', paddingLeft: '50px',
                                height: '46px'
                              }
                            } onPlaceSelect={(location) => {
                              setLocationData(location);
                            }} />
                          </>
                        }
                      />
                    </div>)}
              </Col>
            </Row>

          </div>
          {/* <Container style={{ maxWidth: '1710px' }} > */}
          <div className="card-container">
            <Row>
              <div className="search-list mt-5 ">
                <div>
                  <Row >
                    <Col xl={9} md={8}>
                      <Row>{renderBookingData()}</Row>
                    </Col>
                    <Col xl={3} md={4}>
                      {!showCommute &&
                        (bookingParams?.isProgramme === "MYO Plus" ||
                          bookingParams?.isProgramme === "Specified only" ||
                          location?.state?.query?.isProgramme === "MYO Plus" ||
                          location?.state?.query?.isProgramme ===
                          "Specified only") && (
                          <Row style={{
                            position: "sticky",
                            top: "265px"
                          }}>
                            <Col
                              className=""
                              style={{ marginBottom: "5px", marginTop: "14px" }}
                            >
                              <BookingGoogleMap
                                googleMapURL={process.env.REACT_APP_MAP_URL}
                                loadingElement={
                                  <div
                                    style={{ height: `100%`, width: "100%" }}
                                  />
                                }
                                containerElement={
                                  <div
                                    style={{
                                      height: "100%",
                                      width: `100%`,
                                      aspectRatio: "1/0.5",
                                    }}
                                  />
                                }
                                mapElement={
                                  <div
                                    style={{ height: `200%`, width: `100%` }}
                                  />
                                }
                                location={locations}
                                building={building}
                              // travelMode={travelMode}
                              />
                            </Col>
                          </Row>
                        )}

                      {!showCommute &&
                        (bookingParams?.isProgramme === "MYO" ||
                          location?.state?.query?.isProgramme === "MYO") && (
                          <Row style={{
                            position: "sticky",
                            top: "265px"
                          }}>
                            <Col
                              className="TestClass2"
                              style={{ marginBottom: "5px", marginTop: "14px" }}
                            >
                              <MyoBookingGoogleMap
                                googleMapURL={process.env.REACT_APP_MAP_URL}
                                loadingElement={
                                  <div
                                    style={{ height: `100%`, width: "100%" }}
                                  />
                                }
                                containerElement={
                                  <div
                                    style={{
                                      height: "100%",
                                      width: `100%`,
                                      aspectRatio: "1/0.5",
                                    }}
                                  />
                                }
                                mapElement={
                                  <div
                                    style={{ height: `200%`, width: `100%` }}
                                  />
                                }
                                location={locations}
                                building={building}
                              // travelMode={travelMode}
                              />
                            </Col>
                          </Row>
                        )}
                    </Col>
                  </Row>
                </div>
              </div>

              {showShareProperty && (
                <div
                  className={`${shareData.length !== 0 && "sharePopertyBox"} ${sharePropMinActive ? "sharePopertyBoxMinimize" : ""}`}
                  style={{
                    marginBottom: "5px",
                    textAlign: "center",
                  }}
                >
                  {shareData.length !== 0 && (
                    <div
                      className="sharePropertyHeader"
                      style={{
                        backgroundColor: "#908A8A",
                        margin: "0 -12px 40px",
                        color: "#fff",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <h6
                        style={{
                          flexGrow: 1,
                          textAlign: "left",
                          color: "#fff",
                          margin: "0",
                        }}
                      >
                        Share Property {shareData.length}
                      </h6>
                      <p
                        style={{
                          cursor: "pointer",
                          fontSize: "45px",
                          lineHeight: "0",
                          margin: "0",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "8px",
                          marginRight: "20px"
                        }}
                        onClick={(e) => { setSharePropMinActive(!sharePropMinActive) }}
                      >
                        -
                      </p>
                      <p
                        style={{
                          display: "table",
                          margin: "0 0 0 auto",
                          cursor: "pointer",
                        }}
                        onClick={() => { setSharePropMinActive(false); setShareData([]) }}
                      >
                        X
                      </p>
                    </div>
                  )}
                  <div className={`${sharePropMinActive ? "minimizeModal" : ""}`}>
                    <Row style={{ padding: "0 40px" }}>
                      <Col md={8}>
                        <div className="bookingListSwiper">
                          <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={20}
                            slidesPerView={3}
                            navigation
                          >
                            {renderShare()}
                          </Swiper>
                        </div>
                      </Col>
                      <Col md={4}>
                        {shareData.length !== 0 && (
                          <Row>

                            {/* <Form>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Control
                                      type="email"
                                      placeholder="Enter email"
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicTextarea"
                                  >
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      placeholder="Message"
                                    />
                                  </Form.Group>
                                  <Button id="approve-button" style={formButton}>
                                    Send
                                  </Button>
                                </Form> */}

                            <Form onSubmit={handleSubmit(onSubmitOption)} >
                              <Row>
                                <Col md={1}></Col>
                                <Col md={11} style={{ marginLeft: '45px' }}>
                                  <Row className='formSpace'>

                                    <Col md={12} style={{ textAlign: 'left' }} >
                                      <Controller
                                        name="email"
                                        control={control}
                                        defaultValue={email}
                                        // rules={{
                                        //   required: email ? false : true
                                        // }}
                                        render={({ field }) =>

                                          <DebounceSearch
                                            value={email}
                                            placeholder="Email address of traveler"
                                            fetchOptions={fetchUserList}
                                            onChange={(newValue) => {
                                              setEmail(newValue);
                                              setTravelerUserId("");
                                              setValue("firstName", "");
                                              setValue("lastName", "");
                                            }}

                                            onSelect={(newValue, options) => {
                                              setEmail(newValue);
                                              setTravelerUserId(options?.user.userId);
                                              setValue("firstName", options.user.firstName);
                                              setValue("lastName", options.user.lastName);
                                              setShowProfile(false);
                                            }}
                                            style={{
                                              width: '100%',
                                            }}
                                            containerClass={`${errors.email ? 'is-invalid' : ''}`}
                                          />
                                        }
                                      />
                                      {errors.email && <div className="invalid-feedback-custom">Please input email!</div>}
                                      {travelerData?.length === 0 && <div className="invalid-feedback-custom">Traveller not known</div>}
                                    </Col>

                                  </Row>
                                  <Row style={{ marginLeft: '-55px' }} >
                                    <Col md={6} >
                                      <Card.Subtitle className="mb-2" style={{ marginTop: '5%' }}><span style={{ color: '#E7008C', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowProfile(true)}>+ Add a new traveller</span></Card.Subtitle>
                                    </Col>
                                    <Col md={6}>
                                      {/* <Card.Subtitle className="mb-2" style={{ marginTop: '5%',marginLeft:'-10px' }}><span style={{ color: '#000000', textDecoration: 'underline', cursor: 'pointer' }}>+ Add a new traveller</span></Card.Subtitle> */}
                                    </Col>

                                  </Row>
                                  {showProfile && <Row className='formSpace'>
                                    {/* <Col md={4} style={{ textAlign: 'right' }}>
                                      </Col> */}
                                    <Col md={12} style={{ textAlign: 'left' }}>
                                      <Form.Control
                                        type='email' className={`${errors.email ? 'is-invalid' : ''}`} {...register("email", { required: true })}
                                        placeholder="Email address" />
                                      <div className="invalid-feedback">Please input valid email!</div>
                                    </Col>
                                  </Row>}
                                  {showProfile && <Row className='formSpace'>
                                    {/* <Col md={4} style={{ textAlign: 'right' }}>
                                      </Col> */}
                                    <Col md={12} style={{ textAlign: 'left' }}>
                                      <Form.Control className={`${errors.firstName ? 'is-invalid' : ''}`} {...register("firstName", { required: true })}
                                        placeholder="First name" />
                                      <div className="invalid-feedback">Please input first name!</div>
                                    </Col>
                                  </Row>}
                                  {showProfile && <Row className='formSpace'>
                                    {/* <Col md={4} style={{ textAlign: 'right' }}>
                                      </Col> */}
                                    <Col md={12} style={{ textAlign: 'left' }}>
                                      <Form.Control className={`${errors.lastName ? 'is-invalid' : ''}`} {...register("lastName", { required: true })}
                                        placeholder="Second name" />
                                      <div className="invalid-feedback">Please input last name!</div>
                                    </Col>
                                  </Row>}

                                  <Row className='formSpace'>
                                    {/* <Col md={4} style={{ textAlign: 'right' }}>
                                      </Col> */}
                                    <Col md={12} style={{ textAlign: 'left' }}>
                                      <Form.Control className={`${errors.message ? 'is-invalid' : ''}`} as="textarea"
                                        rows={3} placeholder="Message" {...register("message", { required: true })} />
                                      <div className="invalid-feedback">Please add message!</div>
                                    </Col>
                                  </Row>


                                  <Row className='formSpace'>
                                    {/* <Col md={4} style={{ textAlign: 'right' }}>
                                        </Col> */}
                                    <Col md={12} style={{ textAlign: 'left' }}>
                                      {
                                        <Button type="submit" id='approve-button' style={formButton} >Send</Button>

                                      }

                                    </Col>
                                  </Row>
                                </Col>

                              </Row>
                            </Form>

                          </Row>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </Row>
          </div>

          {/* </Container> */}
        </div>
      )}
      {!isResult && (
        <div>
          <div className="text-center font-medium mb-0 text-3xl mt-5 pl-4">
            <p>{messages}</p>
          </div>
        </div>
      )}

      {isShowTravelerProfile && <TravellerProfile />}
      <Modal
        show={showMessage}
        onHide={() => { setShowMessage(false); setShowCommuteMap(false) }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={false}
      >
        <Modal.Header closeButton>
          <h1>{commuteBuilding?.property?.propertyName}</h1>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}><div style={{ textAlign: 'left' }}>	<p>Please enter the specific key location</p></div></Col>
          </Row>
          <Row>
            <Col md={6}>
              {
                !otherLocation && <GooglePlaces style={
                  {
                    backgroundColor: 'white', borderRadius: '11px', paddingLeft: '50px',
                    height: '46px'
                  }
                } onPlaceSelect={(location) => {
                  setLocationDataShowCommute(location);
                }} />
              }
              {
                otherLocation && <p style={{ marginLeft: '5px', fontSize: '20px' }}>{otherLocation?.address1}</p>
              }

            </Col>
            <Col md={6}>
              {showCommuteMap && (
                <div >
                  <div className="travel-mode text-left">
                    <div className="mb-3 ml-2">
                      Distance : {distance} | {duration}
                    </div>
                    <span
                      onClick={() => {
                        onChangeTravelMode("WALKING");
                      }}
                      className={`${travelMode === "WALKING"
                        ? "travel-mode__selected"
                        : ""
                        }`}
                    >
                      Walking
                    </span>
                    <span
                      onClick={() => {
                        onChangeTravelMode("DRIVING");
                      }}
                      className={`${travelMode === "DRIVING"
                        ? "travel-mode__selected"
                        : ""
                        }`}
                    >
                      Driving
                    </span>
                    <span
                      onClick={() => {
                        onChangeTravelMode("TRANSIT");
                      }}
                      className={`${travelMode === "TRANSIT"
                        ? "travel-mode__selected"
                        : ""
                        }`}
                    >
                      Public transport
                    </span>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {showCommuteMap && (
                <Row style={{
                  position: "sticky",
                  top: "265px"
                }}>
                  <Col
                    className=""
                    style={{ marginBottom: "-150px", marginTop: "14px" }}
                  >
                    <ShowCommuteMap
                      googleMapURL={process.env.REACT_APP_MAP_URL}
                      loadingElement={
                        <div
                          style={{ height: `90%`, width: "100%" }}
                        />
                      }
                      containerElement={
                        <div
                          style={{
                            height: "90%",
                            width: `100%`,
                            aspectRatio: "1/0.5",
                          }}
                        />
                      }
                      mapElement={
                        <div
                          style={{ height: `80%`, width: `100%` }}
                        />
                      }
                      location={locationsShowCommute}
                      commuteBuilding={commuteBuilding}
                      travelMode={travelMode}
                      onResponse={onResponse}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>


      {
        requestAvailability && <BookingRequestComponent showShareProperty={sharePropMinActive} setRequestAvailability={setRequestAvailability} setRequestData={setRequestData} requestData={requestData} onRemoveList={onRemoveList} noOfNights={noOfNights} />
      }
    </div>
  );
};

export default BookingList;
