import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { $mysaBlack, $mysaYellow } from '../../../../common/components-style';
import { useForm } from 'react-hook-form';
import { GenericStandardRateForm } from '../../../../common/interfaces';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { getLoggedUserType, getUserAccess } from '../../../../common/functions';
import { RateStatus, UserGroups } from '../../../../common/constants';
import _ from 'lodash';

/////////////////////////////// details block style////////////////////////////////////////
const headStyle = { fontSize: '17px', fontWeight: 'bold', padding: '12%', marginTop: '5%', width: '200px' } as React.CSSProperties;
const headBorderStyle = { borderLeft: '2px solid #C4C4C4', marginRight: '3%' } as React.CSSProperties;
const bodyTextStyle = { fontSize: '18px', padding: '5%', textAlign: 'center' } as React.CSSProperties;
const bodyTextStyleOne = { fontSize: '18px', padding: '5%', textAlign: 'center', marginTop: '6%', paddingTop: '10%', marginBottom: '11%' } as React.CSSProperties;
const bodyTextInsideStyle = { marginTop: '15%' } as React.CSSProperties;

const formButton = {
    height: "50px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    marginRight: '20px',
    border: '1px solid #ffffff',
    background: $mysaYellow,
    color: $mysaBlack
} as React.CSSProperties;

const NegotiatedRateForm: React.FC<any> = ({ propertyId, inventoryId, setFormOpen, rateId, clientId, selectedInventory }): JSX.Element => {
    const { register, handleSubmit, formState: { errors } } = useForm<GenericStandardRateForm>();
    const [currency, setCurrency] = useState<any>();
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedClientOption, setSelectedClientOption] = useState<any>();
    const [clientOption, setClientOption] = useState([]);
    const [rateListId, setRateListId] = useState<any>();
    const [clientRateDetails, setClientRateDetails] = useState<any>();
    // const [clientDefault, setClientDefault] = useState([]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getProperty, createCorporateStandardRate, getClientListRate, getRateByPropertyNInventoryId, getSeasonalStandardRateByClientId, updateCorporateStandardRate, updateCorporateRateStatus } = useStoreActions<any>((actions) => ({
        getProperty: actions.property.getProperty,
        createCorporateStandardRate: actions.rate.createCorporateStandardRate,
        getClientListRate: actions.client.getClientList,
        getRateByPropertyNInventoryId: actions.rate.getRateByPropertyNInventoryId,
        getSeasonalStandardRateByClientId: actions.rate.getSeasonalStandardRateByClientId,
        updateCorporateStandardRate: actions.rate.updateCorporateStandardRate,
        updateCorporateRateStatus: actions.rate.updateCorporateRateStatus,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getPropertySuccess, createCorporateStandardRateSuccess, createCorporateStandardRateError, getClientListSuccessRate, getRateByPropertyNInventoryIdSuccess, seasonalStandardRateByClientIdSuccess, updateCorporateStandardRateSuccess, updateCorporateStandardRateError, updateCorporateRateStatusSuccess, updateCorporateRateStatusError } = useStoreState<any>((state) => ({
        getPropertySuccess: state.property.getPropertySuccess,
        createCorporateStandardRateSuccess: state.rate.createCorporateStandardRateSuccess,
        createCorporateStandardRateError: state.rate.createCorporateStandardRateError,
        getClientListSuccessRate: state.client.getClientListSuccess,
        getRateByPropertyNInventoryIdSuccess: state.rate.getRateByPropertyNInventoryIdSuccess,
        seasonalStandardRateByClientIdSuccess: state.rate.seasonalStandardRateByClientIdSuccess,
        seasonalStandardRateByClientIdError: state.rate.seasonalStandardRateByClientIdError,
        updateCorporateStandardRateSuccess: state.rate.updateCorporateStandardRateSuccess,
        updateCorporateStandardRateError: state.rate.updateCorporateStandardRateError,
        updateCorporateRateStatusSuccess: state.rate.updateCorporateRateStatusSuccess,
        updateCorporateRateStatusError: state.rate.updateCorporateRateStatusError
    }));

    useEffect(() => {
        if (getPropertySuccess) {
            setCurrency(getPropertySuccess.data?.currency);
        }
        if (createCorporateStandardRateSuccess) {
            setLoading(false);
            toast.success("Client negotiated rate created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setFormOpen(false);
        }
        if (createCorporateStandardRateError) {
            setLoading(false);
            toast.error(createCorporateStandardRateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (updateCorporateStandardRateSuccess) {
            setLoading(false);
            toast.success("Client negotiated rate update successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            const params: any = {};
            params.propertyId = propertyId;
            params.inventoryId = inventoryId;
            params.clientId = clientId;
            getSeasonalStandardRateByClientId(params);
        }
        if (updateCorporateStandardRateError) {
            setLoading(false);
            toast.error(updateCorporateStandardRateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (getClientListSuccessRate) {
            let userData: any = [];
            const userClient = getUserAccess('client');
            const userClientIds = _.map(userClient, (a) => a.clientId);
            const mappedData = _.map(getClientListSuccessRate.data, (client) => {
                return {
                    key: client._id,
                    companyLogo: client.companyLogo,
                    companyName: client.companyName,
                    city: client.city,
                    country: client.country,
                    status: client.status,
                    clientAdmin: client.clientAdmin,
                }
            });
            let filteredData = _.filter(mappedData, (a) => userClientIds.includes(a.key));
            userData = getLoggedUserType() === UserGroups.super_admin ? mappedData : filteredData;

            let clients: any = [];
            userData.forEach((client: any) => {
                let data = { value: client.key, label: client.companyName };
                clients.push(data);
            });
            setClientOption(clients);
        }

        if (getRateByPropertyNInventoryIdSuccess) {
            setRateListId(getRateByPropertyNInventoryIdSuccess.data[0]._id);
        }

        if (seasonalStandardRateByClientIdSuccess) {
            setClientRateDetails(seasonalStandardRateByClientIdSuccess.data?.corporateRate[0]);
        }
    }, [clientId, createCorporateStandardRateError, createCorporateStandardRateSuccess, getClientListSuccessRate, getPropertySuccess, getRateByPropertyNInventoryIdSuccess, getSeasonalStandardRateByClientId, inventoryId, propertyId, seasonalStandardRateByClientIdSuccess, setFormOpen, updateCorporateStandardRateError, updateCorporateStandardRateSuccess]);

    useEffect(() => {

        if (clientId) {
            setEditEnable(false);
            const params: any = {};
            params.propertyId = propertyId;
            params.inventoryId = inventoryId;
            params.clientId = clientId;
            getSeasonalStandardRateByClientId(params);
        } else {
            setEditEnable(true);
        }

        (async function () {
            if (propertyId) {
                await getProperty(propertyId)
            }
            const params: any = {};
            params.propertyId = propertyId;
            params.inventoryId = inventoryId;
            getRateByPropertyNInventoryId(params);
        }());
        getClientListRate();
    }, [clientId, getClientListRate, getProperty, getRateByPropertyNInventoryId, getSeasonalStandardRateByClientId, inventoryId, propertyId, rateId]);


    useEffect(() => {
        if (updateCorporateRateStatusSuccess) {
            setLoading(false);
            toast.success("Negotiated rate status updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (updateCorporateRateStatusError) {
            setLoading(false);
            toast.error(updateCorporateRateStatusError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        setEditEnable(false);
    }, [updateCorporateRateStatusError, updateCorporateRateStatusSuccess]);


    const changeFormEditable = () => {
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    };

    const onFinish = (value) => {
        if (clientId) {
            const payload = {
                id: rateId,
                corporateRateId: clientRateDetails?._id,
                standardRate: {
                    oneTo7: value.standardRateOneTo7 ? value.standardRateOneTo7 : 0,
                    eightTo14: value.standardRateEightTo14 ? value.standardRateEightTo14 : 0,
                    fifteenTo28: value.standardRateFifteenTo28 ? value.standardRateFifteenTo28 : 0,
                    twentyNineTo90: value.standardRateTwentyNineTo90 ? value.standardRateTwentyNineTo90 : 0,
                    ninetyOnePlus: value.standardRateNinetyOnePlus,
                },
                cancellationPolicy: {
                    oneTo7: {
                        cutOffDates: value.cancellationPolicyOneTo7CutOffDates ? value.cancellationPolicyOneTo7CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyOneTo7PenaltyDates ? value.cancellationPolicyOneTo7PenaltyDates : 0
                    },
                    eightTo14: {
                        cutOffDates: value.cancellationPolicyEightTo14CutOffDates ? value.cancellationPolicyEightTo14CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyEightTo14PenaltyDates ? value.cancellationPolicyEightTo14PenaltyDates : 0
                    },
                    fifteenTo28: {
                        cutOffDates: value.cancellationPolicyFifteenTo28CutOffDates ? value.cancellationPolicyFifteenTo28CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyFifteenTo28PenaltyDates ? value.cancellationPolicyFifteenTo28PenaltyDates : 0
                    },
                    twentyNineTo90: {
                        cutOffDates: value.cancellationPolicyTwentyNineTo90CutOffDates ? value.cancellationPolicyTwentyNineTo90CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyTwentyNineTo90PenaltyDates ? value.cancellationPolicyTwentyNineTo90PenaltyDates : 0
                    },
                    ninetyOnePlus: {
                        cutOffDates: value.cancellationPolicyNinetyOnePlusCutOffDates,
                        penaltyDates: value.cancellationPolicyNinetyOnePlusPenaltyDates
                    }
                }
            }
            setLoading(true);
            updateCorporateStandardRate(payload);
        } else {
            const payload = {
                id: rateListId,
                corporateClientId: selectedClientOption,
                standardRate: {
                    oneTo7: value.standardRateOneTo7 ? value.standardRateOneTo7 : 0,
                    eightTo14: value.standardRateEightTo14 ? value.standardRateEightTo14 : 0,
                    fifteenTo28: value.standardRateFifteenTo28 ? value.standardRateFifteenTo28 : 0,
                    twentyNineTo90: value.standardRateTwentyNineTo90 ? value.standardRateTwentyNineTo90 : 0,
                    ninetyOnePlus: value.standardRateNinetyOnePlus,
                },
                cancellationPolicy: {
                    oneTo7: {
                        cutOffDates: value.cancellationPolicyOneTo7CutOffDates ? value.cancellationPolicyOneTo7CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyOneTo7PenaltyDates ? value.cancellationPolicyOneTo7PenaltyDates : 0
                    },
                    eightTo14: {
                        cutOffDates: value.cancellationPolicyEightTo14CutOffDates ? value.cancellationPolicyEightTo14CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyEightTo14PenaltyDates ? value.cancellationPolicyEightTo14PenaltyDates : 0
                    },
                    fifteenTo28: {
                        cutOffDates: value.cancellationPolicyFifteenTo28CutOffDates ? value.cancellationPolicyFifteenTo28CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyFifteenTo28PenaltyDates ? value.cancellationPolicyFifteenTo28PenaltyDates : 0
                    },
                    twentyNineTo90: {
                        cutOffDates: value.cancellationPolicyTwentyNineTo90CutOffDates ? value.cancellationPolicyTwentyNineTo90CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyTwentyNineTo90PenaltyDates ? value.cancellationPolicyTwentyNineTo90PenaltyDates : 0
                    },
                    ninetyOnePlus: {
                        cutOffDates: value.cancellationPolicyNinetyOnePlusCutOffDates,
                        penaltyDates: value.cancellationPolicyNinetyOnePlusPenaltyDates
                    }
                }
            }
            setLoading(true);
            createCorporateStandardRate(payload);
        }

    }


    const changeClient = (selectedClient) => {
        setSelectedClientOption(selectedClient.value);
    }

    const approveStatus = useCallback(() => {
        setLoading(true);
        const data = {
            id: rateId,
            corporateRateId: clientRateDetails?._id,
            rateStatus: RateStatus.published

        }
        updateCorporateRateStatus(data);

    }, [clientRateDetails, rateId, updateCorporateRateStatus]);


    return (
        <>
            <Row className='mt-5'>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}>Client Negotiated Rate <span style={{ fontSize: '20px', fontWeight: 'normal' }}>{currency}</span></div>
            </Row>
            <Row className='mt-5'>
                <Col md={1}>
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Choose client</span>
                </Col>
                <Col md={3}>
                    <Select name='client' placeholder="Select client" options={clientOption} onChange={changeClient} defaultValue={{ value: clientRateDetails?.client?._id, label: clientRateDetails?.client?.companyName }} />
                </Col>
                <Col md={6}></Col>
            </Row>
            <div className='mt-5'>
                <Form onSubmit={handleSubmit(onFinish)}>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '5%' }}>Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}>
                                <span style={headBorderStyle}></span><span>1-7 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span> 8-14 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span> 15-28 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span> 29-89 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span> 90+ nights</span>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '4%', marginBottom: '10px' }}>Standard Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.standardRate?.oneTo7}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.standardRate?.oneTo7} min={0} className={`${errors.standardRateOneTo7 ? 'is-invalid' : ''}`} {...register("standardRateOneTo7", { required: selectedInventory?.minimumStayed > 7 ? false : true })} disabled={selectedInventory?.minimumStayed > 7 ? true : false} />}

                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.standardRate?.eightTo14}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.standardRate?.eightTo14} min={0} className={`${errors.standardRateEightTo14 ? 'is-invalid' : ''}`} {...register("standardRateEightTo14", { required: selectedInventory?.minimumStayed > 14 ? false : true })} disabled={selectedInventory?.minimumStayed > 14 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.standardRate?.fifteenTo28}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.standardRate?.fifteenTo28} min={0} className={`${errors.standardRateFifteenTo28 ? 'is-invalid' : ''}`} {...register("standardRateFifteenTo28", { required: selectedInventory?.minimumStayed > 28 ? false : true })} disabled={selectedInventory?.minimumStayed > 28 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.standardRate?.twentyNineTo90}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.standardRate?.twentyNineTo90} min={0} className={`${errors.standardRateTwentyNineTo90 ? 'is-invalid' : ''}`} {...register("standardRateTwentyNineTo90", { required: selectedInventory?.minimumStayed > 90 ? false : true })} disabled={selectedInventory?.minimumStayed > 90 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.standardRate?.ninetyOnePlus}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.standardRate?.ninetyOnePlus} min={0} className={`${errors.standardRateNinetyOnePlus ? 'is-invalid' : ''}`} {...register("standardRateNinetyOnePlus", { required: true })} />}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation notice (nights)</div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.cancellationPolicy?.oneTo7?.cutOffDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.cancellationPolicy?.oneTo7?.cutOffDates} min={0} className={`${errors.cancellationPolicyOneTo7CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7CutOffDates", { required: selectedInventory?.minimumStayed > 7 ? false : true })} disabled={selectedInventory?.minimumStayed > 7 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.cancellationPolicy?.eightTo14?.cutOffDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.cancellationPolicy?.eightTo14?.cutOffDates} min={0} className={`${errors.cancellationPolicyEightTo14CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14CutOffDates", { required: selectedInventory?.minimumStayed > 14 ? false : true })} disabled={selectedInventory?.minimumStayed > 14 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.cancellationPolicy?.fifteenTo28?.cutOffDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.cancellationPolicy?.fifteenTo28?.cutOffDates} min={0} className={`${errors.cancellationPolicyFifteenTo28CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28CutOffDates", { required: selectedInventory?.minimumStayed > 28 ? false : true })} disabled={selectedInventory?.minimumStayed > 28 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.cancellationPolicy?.twentyNineTo90?.cutOffDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.cancellationPolicy?.twentyNineTo90?.cutOffDates} min={0} className={`${errors.cancellationPolicyTwentyNineTo90CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90CutOffDates", { required: selectedInventory?.minimumStayed > 90 ? false : true })} disabled={selectedInventory?.minimumStayed > 90 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.cancellationPolicy?.ninetyOnePlus?.cutOffDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.cancellationPolicy?.ninetyOnePlus?.cutOffDates} min={0} className={`${errors.cancellationPolicyNinetyOnePlusCutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusCutOffDates", { required: true })} />}
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation charge (nights)</div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.cancellationPolicy?.oneTo7?.penaltyDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.cancellationPolicy?.oneTo7?.penaltyDates} min={0} className={`${errors.cancellationPolicyOneTo7PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7PenaltyDates", { required: selectedInventory?.minimumStayed > 7 ? false : true })} disabled={selectedInventory?.minimumStayed > 7 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.cancellationPolicy?.eightTo14?.penaltyDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.cancellationPolicy?.eightTo14?.penaltyDates} min={0} className={`${errors.cancellationPolicyEightTo14PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14PenaltyDates", { required: selectedInventory?.minimumStayed > 14 ? false : true })} disabled={selectedInventory?.minimumStayed > 14 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.cancellationPolicy?.fifteenTo28?.penaltyDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.cancellationPolicy?.fifteenTo28?.penaltyDates} min={0} className={`${errors.cancellationPolicyFifteenTo28PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28PenaltyDates", { required: selectedInventory?.minimumStayed > 28 ? false : true })} disabled={selectedInventory?.minimumStayed > 28 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.cancellationPolicy?.twentyNineTo90?.penaltyDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.cancellationPolicy?.twentyNineTo90?.penaltyDates} min={0} className={`${errors.cancellationPolicyTwentyNineTo90PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90PenaltyDates", { required: selectedInventory?.minimumStayed > 90 ? false : true })} disabled={selectedInventory?.minimumStayed > 90 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{clientRateDetails?.cancellationPolicy?.ninetyOnePlus?.penaltyDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={clientRateDetails?.cancellationPolicy?.ninetyOnePlus?.penaltyDates} min={0} className={`${errors.cancellationPolicyNinetyOnePlusPenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusPenaltyDates", { required: true })} />}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col md={12}>
                            {!loading && !editEnable && rateId && getLoggedUserType() === UserGroups.super_admin &&
                                <Button id='approve-button' style={formButton} onClick={approveStatus} >Approve</Button>
                            }
                            {!loading && editEnable && !rateId && <Button type="submit" style={formButton}><b>Save</b></Button>}
                            {!loading && editEnable && rateId && <Button type="submit" style={formButton}><b>Update</b></Button>}
                            {!loading && !editEnable && <Button style={formButton} onClick={changeFormEditable}><b>Edit</b></Button>}
                            {loading && <Spinner animation="border" variant="warning" />}
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default NegotiatedRateForm;