import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { $mysaBlack, $mysaYellow } from '../../../../common/components-style';
import { useForm } from 'react-hook-form';
import { GenericStandardRateForm } from '../../../../common/interfaces';
import { toast } from 'react-toastify';

/////////////////////////////// details block style////////////////////////////////////////
const headStyle = { fontSize: '17px', fontWeight: 'bold', padding: '12%', marginTop: '5%', width: '200px' } as React.CSSProperties;
const headBorderStyle = { borderLeft: '2px solid #C4C4C4', marginRight: '3%' } as React.CSSProperties;
const bodyTextStyle = { fontSize: '18px', padding: '5%', textAlign: 'center' } as React.CSSProperties;
const bodyTextStyleOne = { fontSize: '18px', padding: '5%', textAlign: 'center', marginTop: '6%', paddingTop: '10%', marginBottom: '11%' } as React.CSSProperties;
const bodyTextInsideStyle = { marginTop: '15%' } as React.CSSProperties;

const formButton = {
    height: "50px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    marginRight: '20px',
    border: '1px solid #ffffff',
    background: $mysaYellow,
    color: $mysaBlack
} as React.CSSProperties;

const MYOCorporateRateForm: React.FC<any> = ({ propertyId, inventoryId, setFormOpen, rateId, selectedInventory }): JSX.Element => {
    const { register, handleSubmit, formState: { errors } } = useForm<GenericStandardRateForm>();
    const [currency, setCurrency] = useState<any>();
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [myoCorporateData, setMYOCorporateData] = useState<any>(null);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getProperty, createGenericRate, getRateByPropertyNInventoryIdMYO, updateGenericStandardRate } = useStoreActions<any>((actions) => ({
        getProperty: actions.property.getProperty,
        createGenericRate: actions.rate.createGenericRate,
        getRateByPropertyNInventoryIdMYO: actions.rate.getRateByPropertyNInventoryId,
        updateGenericStandardRate: actions.rate.updateGenericStandardRate,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getPropertySuccess, createRateSuccess, createRateError, getRateByPropertyNInventoryIdSuccessMYO, updateGenericStandardRateSuccess, updateGenericStandardRateError } = useStoreState<any>((state) => ({
        getPropertySuccess: state.property.getPropertySuccess,
        createRateSuccess: state.rate.createRateSuccess,
        createRateError: state.rate.createRateError,
        getRateByPropertyNInventoryIdSuccessMYO: state.rate.getRateByPropertyNInventoryIdSuccess,
        updateGenericStandardRateSuccess: state.rate.updateGenericStandardRateSuccess,
        updateGenericStandardRateError: state.rate.updateGenericStandardRateError,
    }));


    useEffect(() => {

        if (rateId) {
            setEditEnable(false);
            const params: any = {};
            params.propertyId = propertyId;
            params.inventoryId = inventoryId;
            getRateByPropertyNInventoryIdMYO(params);
        } else {
            setEditEnable(true);
        }

        (async function () {
            if (propertyId) {
                await getProperty(propertyId)
            }
        }())
    }, [getProperty, getRateByPropertyNInventoryIdMYO, inventoryId, propertyId, rateId]);

    useEffect(() => {
        if (getPropertySuccess) {
            setCurrency(getPropertySuccess.data?.currency);
        }
        if (createRateSuccess) {
            setLoading(false);
            toast.success("MYO corporate rate created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setFormOpen(false);
        }
        if (createRateError) {
            setLoading(false);
            toast.error(createRateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (updateGenericStandardRateSuccess) {
            setLoading(false);
            toast.success("MYO corporate rate updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            const params: any = {};
            params.propertyId = propertyId;
            params.inventoryId = inventoryId;
            getRateByPropertyNInventoryIdMYO(params);
        }

        if (updateGenericStandardRateError) {
            setLoading(false);
            toast.error(updateGenericStandardRateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (getRateByPropertyNInventoryIdSuccessMYO) {
            setMYOCorporateData(getRateByPropertyNInventoryIdSuccessMYO.data[0])
        }
    }, [createRateError, createRateSuccess, getPropertySuccess, getRateByPropertyNInventoryIdMYO, getRateByPropertyNInventoryIdSuccessMYO, inventoryId, propertyId, setFormOpen, updateGenericStandardRateError, updateGenericStandardRateSuccess]);

    const changeFormEditable = useCallback(() => {
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);

    const onFinish = (value) => {
        if (rateId) {
            const payload = {
                id: rateId,
                standardRate: {
                    oneTo7: value.standardRateOneTo7 ? value.standardRateOneTo7 : myoCorporateData?.genericRate?.standardRate?.oneTo7,
                    eightTo14: value.standardRateEightTo14 ? value.standardRateEightTo14 : myoCorporateData?.genericRate?.standardRate?.eightTo14,
                    fifteenTo28: value.standardRateFifteenTo28 ? value.standardRateFifteenTo28 : myoCorporateData?.genericRate?.standardRate?.fifteenTo28,
                    twentyNineTo90: value.standardRateTwentyNineTo90 ? value.standardRateTwentyNineTo90 : myoCorporateData?.genericRate?.standardRate?.twentyNineTo90,
                    ninetyOnePlus: value.standardRateNinetyOnePlus ? value.standardRateNinetyOnePlus : myoCorporateData?.genericRate?.standardRate?.ninetyOnePlus,
                },
                cancellationPolicy: {
                    oneTo7: {
                        cutOffDates: value.cancellationPolicyOneTo7CutOffDates ? value.cancellationPolicyOneTo7CutOffDates : myoCorporateData?.genericRate?.cancellationPolicy?.oneTo7?.cutOffDates,
                        penaltyDates: value.cancellationPolicyOneTo7PenaltyDates ? value.cancellationPolicyOneTo7PenaltyDates : myoCorporateData?.genericRate?.cancellationPolicy?.oneTo7?.penaltyDates
                    },
                    eightTo14: {
                        cutOffDates: value.cancellationPolicyEightTo14CutOffDates ? value.cancellationPolicyEightTo14CutOffDates : myoCorporateData?.genericRate?.cancellationPolicy?.eightTo14?.cutOffDates,
                        penaltyDates: value.cancellationPolicyEightTo14PenaltyDates ? value.cancellationPolicyEightTo14PenaltyDates : myoCorporateData?.genericRate?.cancellationPolicy?.eightTo14?.penaltyDates
                    },
                    fifteenTo28: {
                        cutOffDates: value.cancellationPolicyFifteenTo28CutOffDates ? value.cancellationPolicyFifteenTo28CutOffDates : myoCorporateData?.genericRate?.cancellationPolicy?.fifteenTo28?.cutOffDates,
                        penaltyDates: value.cancellationPolicyFifteenTo28PenaltyDates ? value.cancellationPolicyFifteenTo28PenaltyDates : myoCorporateData?.genericRate?.cancellationPolicy?.fifteenTo28?.penaltyDates
                    },
                    twentyNineTo90: {
                        cutOffDates: value.cancellationPolicyTwentyNineTo90CutOffDates ? value.cancellationPolicyTwentyNineTo90CutOffDates : myoCorporateData?.genericRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates,
                        penaltyDates: value.cancellationPolicyTwentyNineTo90PenaltyDates ? value.cancellationPolicyTwentyNineTo90PenaltyDates : myoCorporateData?.genericRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates
                    },
                    ninetyOnePlus: {
                        cutOffDates: value.cancellationPolicyNinetyOnePlusCutOffDates ? value.cancellationPolicyNinetyOnePlusCutOffDates : myoCorporateData?.genericRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates,
                        penaltyDates: value.cancellationPolicyNinetyOnePlusPenaltyDates ? value.cancellationPolicyNinetyOnePlusPenaltyDates : myoCorporateData?.genericRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates
                    }
                }
            }
            setLoading(true);
            updateGenericStandardRate(payload);
        } else {
            const payload = {
                propertyId: propertyId,
                propertyInventory: inventoryId,
                standardRate: {
                    oneTo7: value.standardRateOneTo7 ? value.standardRateOneTo7 : 0,
                    eightTo14: value.standardRateEightTo14 ? value.standardRateEightTo14 : 0,
                    fifteenTo28: value.standardRateFifteenTo28 ? value.standardRateFifteenTo28 : 0,
                    twentyNineTo90: value.standardRateTwentyNineTo90 ? value.standardRateTwentyNineTo90 : 0,
                    ninetyOnePlus: value.standardRateNinetyOnePlus,
                },
                cancellationPolicy: {
                    oneTo7: {
                        cutOffDates: value.cancellationPolicyOneTo7CutOffDates ? value.cancellationPolicyOneTo7CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyOneTo7PenaltyDates ? value.cancellationPolicyOneTo7PenaltyDates : 0
                    },
                    eightTo14: {
                        cutOffDates: value.cancellationPolicyEightTo14CutOffDates ? value.cancellationPolicyEightTo14CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyEightTo14PenaltyDates ? value.cancellationPolicyEightTo14PenaltyDates : 0
                    },
                    fifteenTo28: {
                        cutOffDates: value.cancellationPolicyFifteenTo28CutOffDates ? value.cancellationPolicyFifteenTo28CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyFifteenTo28PenaltyDates ? value.cancellationPolicyFifteenTo28PenaltyDates : 0
                    },
                    twentyNineTo90: {
                        cutOffDates: value.cancellationPolicyTwentyNineTo90CutOffDates ? value.cancellationPolicyTwentyNineTo90CutOffDates : 0,
                        penaltyDates: value.cancellationPolicyTwentyNineTo90PenaltyDates ? value.cancellationPolicyTwentyNineTo90PenaltyDates : 0
                    },
                    ninetyOnePlus: {
                        cutOffDates: value.cancellationPolicyNinetyOnePlusCutOffDates,
                        penaltyDates: value.cancellationPolicyNinetyOnePlusPenaltyDates
                    }
                }
            }
            setLoading(true);
            createGenericRate(payload);
        }
    }

    return (
        <>
            <Row className='mt-5'>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}> MYO Corporate Rate <span style={{ fontSize: '20px', fontWeight: 'normal' }}>{currency}</span></div>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}> Exclusive of taxes </div>
            </Row>
            <div className='mt-5'>
                <Form onSubmit={handleSubmit(onFinish)}>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}>
                                <span style={headBorderStyle}></span><span >1-7 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 8-14 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 15-28 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 29-89 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 90+ nights</span>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '4%', marginBottom: '10px' }}>Standard Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 7 ? 'N/A' : myoCorporateData?.genericRate?.standardRate?.oneTo7}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 7 ? '' : myoCorporateData?.genericRate?.standardRate?.oneTo7} min={0} className={`${errors.standardRateOneTo7 ? 'is-invalid' : ''}`} {...register("standardRateOneTo7",
                                    { required: selectedInventory?.minimumStayed > 7 ? false : selectedInventory?.maximumStayed <= 7 ? true : selectedInventory?.maximumStayed <= 14 ? true : selectedInventory?.maximumStayed <= 28 ? true : selectedInventory?.maximumStayed <= true ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 7 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? false : false} />}

                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 14 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : myoCorporateData?.genericRate?.standardRate?.eightTo14}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 14 ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? '' : myoCorporateData?.genericRate?.standardRate?.eightTo14} min={0} className={`${errors.standardRateEightTo14 ? 'is-invalid' : ''}`} {...register("standardRateEightTo14",
                                    { required: selectedInventory?.minimumStayed > 14 ? false : selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? true : selectedInventory?.maximumStayed <= 28 ? true : selectedInventory?.maximumStayed <= 90 ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 14 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? false : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 28 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : myoCorporateData?.genericRate?.standardRate?.fifteenTo28}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 28 ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? '' : myoCorporateData?.genericRate?.standardRate?.fifteenTo28} min={0} className={`${errors.standardRateFifteenTo28 ? 'is-invalid' : ''}`} {...register("standardRateFifteenTo28",
                                    { required: selectedInventory?.minimumStayed > 28 ? false : selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? false : selectedInventory?.maximumStayed <= 28 ? true : selectedInventory?.maximumStayed <= 90 ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 28 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? false : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 90 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? 'N/A' : myoCorporateData?.genericRate?.standardRate?.twentyNineTo90}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 90 ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? '' : myoCorporateData?.genericRate?.standardRate?.twentyNineTo90} min={0} className={`${errors.standardRateTwentyNineTo90 ? 'is-invalid' : ''}`} {...register("standardRateTwentyNineTo90",
                                    { required: selectedInventory?.minimumStayed > 90 ? false : selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? false : selectedInventory?.maximumStayed <= 28 ? false : selectedInventory?.maximumStayed <= 90 ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 90 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? false : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{(selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? 'N/A' : myoCorporateData?.genericRate?.standardRate?.ninetyOnePlus}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={(selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? '' : myoCorporateData?.genericRate?.standardRate?.ninetyOnePlus} min={0} className={`${errors.standardRateNinetyOnePlus ? 'is-invalid' : ''}`} {...register("standardRateNinetyOnePlus",
                                    { required: selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? false : selectedInventory?.maximumStayed <= 28 ? false : selectedInventory?.maximumStayed <= 90 ? false : true })}
                                    disabled={(selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? true : false} />}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation notice (nights)</div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 7 ? 'N/A' : myoCorporateData?.genericRate?.cancellationPolicy?.oneTo7?.cutOffDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 7 ? '' : myoCorporateData?.genericRate?.cancellationPolicy?.oneTo7?.cutOffDates} min={0} className={`${errors.cancellationPolicyOneTo7CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7CutOffDates",
                                    { required: selectedInventory?.minimumStayed > 7 ? false : selectedInventory?.maximumStayed <= 7 ? true : selectedInventory?.maximumStayed <= 14 ? true : selectedInventory?.maximumStayed <= 28 ? true : selectedInventory?.maximumStayed <= 90 ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 7 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28 ? false : selectedInventory?.maximumStayed <= 90) ? false : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 14 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : myoCorporateData?.genericRate?.cancellationPolicy?.eightTo14?.cutOffDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 14 ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? '' : myoCorporateData?.genericRate?.cancellationPolicy?.eightTo14?.cutOffDates} min={0} className={`${errors.cancellationPolicyEightTo14CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14CutOffDates",
                                    { required: selectedInventory?.minimumStayed > 14 ? false : selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? true : selectedInventory?.maximumStayed <= 28 ? true : selectedInventory?.maximumStayed <= 90 ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 14 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? false : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 28 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : myoCorporateData?.genericRate?.cancellationPolicy?.fifteenTo28?.cutOffDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 28 ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? '' : myoCorporateData?.genericRate?.cancellationPolicy?.fifteenTo28?.cutOffDates} min={0} className={`${errors.cancellationPolicyFifteenTo28CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28CutOffDates",
                                    { required: selectedInventory?.minimumStayed > 28 ? false : selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? false : selectedInventory?.maximumStayed < 28 ? true : selectedInventory?.maximumStayed <= 90 ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 28 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed < 28) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? false : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 90 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? '' : myoCorporateData?.genericRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 90 ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? '' : myoCorporateData?.genericRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates} min={0} className={`${errors.cancellationPolicyTwentyNineTo90CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90CutOffDates",
                                    { required: selectedInventory?.minimumStayed > 90 ? false : selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? false : selectedInventory?.maximumStayed <= 28 ? false : selectedInventory?.maximumStayed <= 90 ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 90 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? false : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{(selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? 'N/A' : myoCorporateData?.genericRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={(selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? '' : myoCorporateData?.genericRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates} min={0} className={`${errors.cancellationPolicyNinetyOnePlusCutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusCutOffDates",
                                    { required: selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? false : selectedInventory?.maximumStayed <= 28 ? false : selectedInventory?.maximumStayed <= 90 ? false : true })}
                                    disabled={(selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? true : false} />}
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation charge (nights)</div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 7 ? 'N/A' : myoCorporateData?.genericRate?.cancellationPolicy?.oneTo7?.penaltyDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 7 ? '' : myoCorporateData?.genericRate?.cancellationPolicy?.oneTo7?.penaltyDates} min={0} className={`${errors.cancellationPolicyOneTo7PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7PenaltyDates",
                                    { required: selectedInventory?.minimumStayed > 7 ? false : selectedInventory?.maximumStayed <= 7 ? true : selectedInventory?.maximumStayed <= 14 ? true : selectedInventory?.maximumStayed <= 28 ? true : selectedInventory?.maximumStayed <= 90 ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 7 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? false : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 14 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : myoCorporateData?.genericRate?.cancellationPolicy?.eightTo14?.penaltyDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 14 ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? '' : myoCorporateData?.genericRate?.cancellationPolicy?.eightTo14?.penaltyDates} min={0} className={`${errors.cancellationPolicyEightTo14PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14PenaltyDates",
                                    { required: selectedInventory?.minimumStayed > 14 ? false : selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? true : selectedInventory?.maximumStayed <= 28 ? true : selectedInventory?.maximumStayed <= 90 ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 14 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? false : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 28 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : myoCorporateData?.genericRate?.cancellationPolicy?.fifteenTo28?.penaltyDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 28 ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? '' : myoCorporateData?.genericRate?.cancellationPolicy?.fifteenTo28?.penaltyDates} min={0} className={`${errors.cancellationPolicyFifteenTo28PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28PenaltyDates",
                                    { required: selectedInventory?.minimumStayed > 28 ? false : selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? false : selectedInventory?.maximumStayed <= 28 ? true : selectedInventory?.maximumStayed <= 90 ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 28 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? false : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? false : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{selectedInventory?.minimumStayed > 90 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? 'N/A' : myoCorporateData?.genericRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={selectedInventory?.minimumStayed > 90 ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? '' : myoCorporateData?.genericRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates} min={0} className={`${errors.cancellationPolicyTwentyNineTo90PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90PenaltyDates",
                                    { required: selectedInventory?.minimumStayed > 90 ? false : selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? false : selectedInventory?.maximumStayed <= 28 ? false : selectedInventory?.maximumStayed <= 90 ? true : true })}
                                    disabled={selectedInventory?.minimumStayed > 90 ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? false : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{(selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? 'N/A' : myoCorporateData?.genericRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={(selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? '' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? '' : myoCorporateData?.genericRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates} min={0} className={`${errors.cancellationPolicyNinetyOnePlusPenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusPenaltyDates",
                                    { required: selectedInventory?.maximumStayed <= 7 ? false : selectedInventory?.maximumStayed <= 14 ? false : selectedInventory?.maximumStayed <= 28 ? false : selectedInventory?.maximumStayed <= 90 ? false : true })}
                                    disabled={(selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? true : false} />}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col md={12}>
                            {!loading && editEnable && !rateId && <Button type="submit" style={formButton}><b>Save</b></Button>}
                            {!loading && editEnable && rateId && <Button type="submit" style={formButton}><b>Update</b></Button>}
                            {!loading && !editEnable && <Button style={formButton} onClick={changeFormEditable}><b>Edit</b></Button>}
                            {loading && <Spinner animation="border" variant="warning" />}
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default MYOCorporateRateForm;