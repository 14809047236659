import { FileImageOutlined, FilePdfOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { message, Modal, Upload } from 'antd';
import React, { useCallback, useState } from 'react';
import { QuestionTypes } from '../../../../../../common/constants';
const { Dragger } = Upload;

const QEvidence: React.FC<any> = ({ data, onEvidenceUpload }): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);
  
  const onChange = useCallback(({ file }) => {
    let upload = true;

    switch (data.evidencetype) {
      case QuestionTypes.image:
        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
          message.error('Wrong type of file');
          upload = false
        }
        break;
      case QuestionTypes.video:
        if (!['video/mp4'].includes(file.type)) {
          message.error('Wrong type of file');
          upload = false
        }
        break;
      case QuestionTypes.document:
        if (!['application/pdf'].includes(file.type)) {
          message.error('Wrong type of file');
          upload = false
        }
        break;
      default:
        break;
    }

    !upload || onEvidenceUpload(file, data.id);
  }, [data, onEvidenceUpload]);

  const loadIcon = useCallback(() => {
    switch (data.answer?.evidence?.mimetype) {
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        return <FileImageOutlined onClick={() => setVisible(true)} style={{ fontSize: '32px', color: '#08c' }} />
      case "video/mp4":
        return <VideoCameraOutlined onClick={() => setVisible(true)} style={{ fontSize: '32px', color: '#08c' }} />
      case "application/pdf":
        return <a href={data.answer?.evidence?.url} target="_new"><FilePdfOutlined style={{ fontSize: '32px', color: '#08c' }} /></a>
      default:
        return <></>;
    }
  }, [data.answer?.evidence?.mimetype, data.answer?.evidence?.url]);

  return (
    <div className="inline-block w-full float-left">
      <Dragger
        className=""
        beforeUpload={file => { return false }}
        onChange={onChange}
        itemRender={item => <></>}
      >
        <p className="ant-upload-drag-icon">
          {data.type === QuestionTypes.image && <FileImageOutlined />}
          {data.type === QuestionTypes.video && <VideoCameraOutlined />}
          {data.type === QuestionTypes.document && <FilePdfOutlined />}
        </p>
        <p className="ant-upload-hint">Click or drag file to this area to upload</p>

      </Dragger>
      <div className=" p-3">
        {loadIcon()}
      </div>
      <Modal width={700} visible={visible} title="Image Evidence" onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
        {data.type === QuestionTypes.image && <img alt="evidence" src={data.answer?.evidence?.url || ""} />}
        {data.type === QuestionTypes.video &&
          <video width="700" height="240" poster="/images/w3schools_green.jpg" controls>
            <source src={data.answer?.evidence?.url || ""} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        }
      </Modal>
    </div>
  )
};

export default QEvidence;