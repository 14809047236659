import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { loginButton } from '../../common/components-style';
import { LoginForm } from '../../common/interfaces';
import { getStorage, clearStorage } from '../../common/storage';
import logo from '../../images/logoNew.png';

const ResetPassword: React.FC = (): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { register, watch, handleSubmit, formState: { errors } } = useForm<LoginForm>();

  const { onReset } = useStoreActions<any>((actions) => ({
    onReset: actions.auth.onReset,
  }));
  const { resetSuccess, resetError } = useStoreState<any>((state) => ({
    resetSuccess: state.auth.resetSuccess,
    resetError: state.auth.resetError,
  }));

  useEffect(() => {
    setLoading(false);
    if (resetError) {
      // message.error(resetError.message.message);
      toast.error(resetError.message.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
    if (resetSuccess) {
      // message.success('Password reset success. Redirecting .....');
      toast.success("Password reset success. Redirecting .....", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      setTimeout(() => {
        clearStorage('myo_reset_session_data');
        history.push('/');
      }, 500);
    }

  }, [history, resetError, resetSuccess]);

  const onFinish = useCallback((values) => {
    delete values.rePassword;
    const storage = getStorage('myo_reset_session_data');
    if (storage) {

      const attributes = JSON.parse(storage.ChallengeParameters.userAttributes);
      const session = storage.Session;
      const data = { ...values, session, email: attributes.email };
      onReset(data);
      setLoading(true);
    } else {
      // message.warn('Session expired!');
      toast.warn("Session expired!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [onReset]);

  return (
    <div className="mysa-login flex-col flex h-screen items-center justify-center" >
      <Card className="px-5 shadow-2xl" style={{
        position: 'absolute',
        width: '458px',
        height: '411px',
        left: '731px',
        top: '338px',
        background: '#FFFFFF',
        // boxShadow: ' 0px 8px 8px rgba(0, 0, 0, 0.6)',
        borderRadius: '33px'
      }}>
        <div className="w-64 m-auto " style={{
          paddingBottom: '0rem',
          paddingTop: '0rem'
        }}>
          <img src={logo} alt="logo" />
        </div>
        <>

          <Container>
            <div >
              <Form onSubmit={handleSubmit(onFinish)} style={{
                paddingBottom: '4rem',
                paddingTop: '0rem'
              }}>
                <Row className='formSpaceLogin '>
                  <Col md={12} style={{ textAlign: 'left' }} >
                    <Form.Control type='password' placeholder="New password" className={`${errors.newPassword ? 'is-invalid' : ''}`}  {...register("newPassword", {
                      required: true,
                      minLength: {
                        value: 8,
                        message: 'Minimum length is 8'
                      }
                    })}
                      onFocus={(e) => e.target.removeAttribute('readonly')}
                    />
                    <div className="invalid-feedback" >Please input your Password!</div>
                  </Col>
                </Row>
                <Row className='formSpaceLogin '>
                  <Col md={12} style={{ textAlign: 'left' }} >
                    <Form.Control type='password' placeholder="Confirm password" className={`${errors.rePassword ? 'is-invalid' : ''}`}  {...register("rePassword", {
                      required: true,
                      validate: (value) => {
                        if (value === watch('newPassword')) {
                          return true;
                        }
                        return Promise.reject(toast.error('Passwords do not match!', {
                          position: toast.POSITION.BOTTOM_RIGHT,
                          className: 'foo-bar'
                        }));
                      }
                    },
                    )} />
                    <div className="invalid-feedback" >Please input your confirm Password!</div>
                  </Col>
                </Row>
                <Row className='formSpaceLogin '> 

                  <Col md={12} style={{ textAlign: 'center' }} >
                    {!loading && <Button type="submit" id='login-button' style={loginButton} >Change Password</Button>}
                  </Col>
                  <a className="block" style={{ textAlign: 'right', marginLeft: '-15px', marginTop: '25px' }} href="/">Login</a>

                  {
                    loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                  }
                </Row>
              </Form>
            </div>
          </Container>
        </>
      </Card>
      {/* <Card className="px-5 shadow-2xl" style={{ width: 400 }}>
        <div className="w-64 m-auto py-3">
          <img src={logo} alt="logo" />
        </div>
        <>
          <Form onFinish={onFinish}>
            <Form.Item
              name="newPassword"
              hasFeedback
              rules={[{ required: true, message: 'Please input your Password!' }]}
            >
              <Input
                type="password"
                placeholder="New password"
              />
            </Form.Item>
            <Form.Item
              name="rePassword"
              hasFeedback
              rules={[
                { required: true, message: 'Please input your confirm Password!' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Passwords do not match!');
                  },
                }),
              ]}
            >
              <Input
                type="password"
                placeholder="Confirm password"
              />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} block type="primary" htmlType="submit" className="login-btn">
                Change Password
              </Button>
              <a className="block float-right mt-2 " href="/">Login</a>
            </Form.Item>
          </Form>
        </>
      </Card> */}

    </div >
  )
};

export default ResetPassword;