import { EditOutlined } from '@ant-design/icons';
import { Button, message, Switch, Table } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useEffect, useMemo, useCallback } from 'react';
import { UserGroups } from '../../../common/constants';
import { capitalizeRole, getLoggedUserType, getLoggedUserEmail } from '../../../common/functions';

const UserList: React.FC<any> = ({ loadForm, brandList }): JSX.Element => {
  const { loadUsers, onResendPassword } = useStoreActions<any>((actions) => ({
    loadUsers: actions.user.loadUsers,
    onResendPassword: actions.auth.onResendPassword,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { userList, userListError, resendPassword, resendPasswordError } = useStoreState<any>((state) => ({
    userList: state.user.userList,
    userListError: state.user.userListError,
    resendPassword: state.auth.resendPassword,
    resendPasswordError: state.auth.resendPasswordError,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userListError) {
      message.error(userListError.message);
    }

    if (resendPassword) {
      message.success('password resend successful, Please check the email');
    }

    if (resendPasswordError) {
      message.error(resendPasswordError.message);
    }

  }, [resendPassword, resendPasswordError, userListError]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadBrand = useCallback((email: string) => {
    if (brandList) {
      return _.filter(brandList.data, branVal => _.find(branVal.brandUsers, value => value.email === email)).shift()?.name
    }
  }, [brandList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const data = useMemo(() => {

    if (userList) {
      const data = userList.data.users;
      let includeList: Array<string> = [];


      switch (getLoggedUserType()) {
        case UserGroups.super_admin:
          includeList = [UserGroups.chief_brand_admin, UserGroups.brand_admin, UserGroups.chief_property_admin, UserGroups.property_admin];
          break;
        case UserGroups.chief_brand_admin:
          includeList = [UserGroups.brand_admin, UserGroups.chief_property_admin];
          break;
        case UserGroups.chief_property_admin:
          includeList = [UserGroups.property_admin];
          break;
        default:
          includeList = [];
          break;
      }


      const mappedData = _.map(data, (val) => {

        return {
          key: val.Username,
          fName: val['custom:firstName'],
          lName: val['custom:lastName'],
          email: val.email,
          status: val.Enabled,
          userStatus: val.UserStatus,
          userType: capitalizeRole(val['custom:userGroup']),
          role: val['custom:userGroup'],
          brand: loadBrand(val.email),
          data: val
        }
      });

      let filterBrand = loadBrand(getLoggedUserEmail());

      let filteredData = _.filter(mappedData, value => _.includes(includeList, value.role) && value.brand === filterBrand);

      if (getLoggedUserType() === UserGroups.super_admin) {
        filteredData = _.filter(mappedData, value => _.includes(includeList, value.role));

      }

      return filteredData
    }
    return
  }, [loadBrand, userList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [

    { title: 'Brand', dataIndex: 'brand', key: 'brand' },
    { title: 'First name', dataIndex: 'fName', key: 'fName' },
    { title: 'Last name', dataIndex: 'lName', key: 'lName' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'User type', dataIndex: 'userType', key: 'userType' },
    {
      title: 'Status',
      dataIndex: '',
      key: 'userStatus  ',
      render: (data:any) => {

        return (<div className={`${data.userStatus === 'CONFIRMED' ? 'text-green-500' : 'text-yellow-500'}`}>
          {data.userStatus} { data.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button onClick={() => onResendPassword({ email: data.email })} className="ml-2 mysa-blue text-white" size="small">Resend</Button> }
        </div>)
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'status',
      render: (data: any) => {
        return (<div>
          <Button onClick={() => { loadForm(data.data) }} className="border-none" ><EditOutlined /></Button>
          <Switch size="small" checked={data.status} />
        </div>)
      },
    },
  ];

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      <Table columns={columns} dataSource={data} />
    </div>
  )
};

export default UserList;