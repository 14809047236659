import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from 'antd';

import { useStoreActions, useStoreState } from 'easy-peasy';
import { Row, Col, Container, Form, Button, Spinner } from 'react-bootstrap';
import { formButton } from '../../../../common/components-style';
import { Controller, useForm } from 'react-hook-form';
import { BrandSustainabilityFormInput } from '../../../../common/interfaces';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
// import { HelpModal } from '../../../common/HelpModal';
import { CommonAnswer, UserAccess } from '../../../../common/constants';
import { canBrandModuleWrite } from '../../../../common/functions';

const plainOptions = [{ label: 'Oven', value: 'Oven', },
{ label: 'Dishwasher', value: 'Dishwasher' }, { label: 'Washing machine', value: 'Washing machine', },
{ label: 'Fridge/Freezer', value: 'Fridge/Freezer' }, { label: 'None of them yet', value: 'None of them yet', },

]

const plainOptions2 = [{ label: 'Paint', value: 'Paint', },
{ label: 'Office supplies - pads, pens etc', value: 'Office supplies - pads, pens etc' }, { label: 'Light bulbs', value: 'Light bulbs', },
{ label: 'Cleaning supplies', value: 'Cleaning supplies' }, { label: 'Paper products - toilet paper, public notices etc', value: 'Paper products - toilet paper, public notices etc', },
{ label: 'None of them yet', value: 'None of them yet', },
]
const BrandSustainability: React.FC<any> = ({ brandId }): JSX.Element => {

    ///////////////////// initialize form ////////////////////////////////////// 
    const { register, handleSubmit, formState: { errors }, control } = useForm<BrandSustainabilityFormInput>();

    ///////////////////////// local state manage ////////////////////////////////////
    const [title, setTitle] = useState('');
    const [brandSustainabilityData, setBrandSustainabilityData] = useState<any>();
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [brandSustainabilityId, setBrandSustainabilityId] = useState<any>(null);
    const [havePolicyInYourPropertiesForReplaceAppliances, setHavePolicyInYourPropertiesForReplaceAppliances] = useState<boolean>(false);
    const [doYouUseProductsInSustainablePackage, setDoYouUseProductsInSustainablePackage] = useState<boolean>(false);
    const [charities, setCharities] = useState<boolean>(false);


    /////////////////////////// global state manage (actions)//////////////////////////////////////////////////////
    const { getBrandSustainabilityByBrandId, createBrandSustainability, onloadBrand, updateBrandSustainability } = useStoreActions<any>((actions) => ({
        getBrandSustainabilityByBrandId: actions.brand.getBrandSustainabilityByBrandId,
        createBrandSustainability: actions.brand.createBrandSustainability,
        updateBrandSustainability: actions.brand.updateBrandSustainability,
        onloadBrand: actions.brand.onloadBrand,

    }));

    /////////////////////////// global state manage (state)//////////////////////////////////////////////////////
    const {

        loadBrandSuccess, getBrandSustainabilityByBrandIdSuccess,
        // getBrandSustainabilityByBrandIdError,
        createBrandSustainabilitySuccess, createBrandSustainabilityError,
        updateBrandSustainabilitySuccess, updateBrandSustainabilityError
    } = useStoreState<any>((state) => ({


        loadBrandSuccess: state.brand.loadBrandSuccess,


        getBrandSustainabilityByBrandIdSuccess: state.brand.getBrandSustainabilityByBrandIdSuccess,
        // getBrandSustainabilityByBrandIdError: state.brand.getBrandSustainabilityByBrandIdError,
        createBrandSustainabilitySuccess: state.brand.createBrandSustainabilitySuccess,
        createBrandSustainabilityError: state.brand.createBrandSustainabilityError,
        updateBrandSustainabilitySuccess: state.brand.updateBrandSustainabilitySuccess,
        updateBrandSustainabilityError: state.brand.updateBrandSustainabilityError,

    }));

    //////////////////////////////// call apis using actions //////////////////////////////////////////////////////
    useEffect(() => {
        if (brandId) {
            getBrandSustainabilityByBrandId(brandId);
            onloadBrand(brandId);
        }

    }, [brandId, getBrandSustainabilityByBrandId, onloadBrand]);



    const scrollToTop = () => {
        // messagesRef?.current?.scrollIntoView(({ behavior: "smooth", block: "start", inline: "start" }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    //////////////////////////////// get api response using state //////////////////////////////////////////////////////
    useEffect(() => {
        if (loadBrandSuccess) {

            setTitle(loadBrandSuccess.data.name);

        }

        if (getBrandSustainabilityByBrandIdSuccess) {
            if (getBrandSustainabilityByBrandIdSuccess.data) {
                setBrandSustainabilityId(getBrandSustainabilityByBrandIdSuccess.data._id);
                setBrandSustainabilityData(getBrandSustainabilityByBrandIdSuccess.data);


                if (getBrandSustainabilityByBrandIdSuccess.data?.havePolicyInYourPropertiesForReplaceAppliances === CommonAnswer.YES) {
                    setHavePolicyInYourPropertiesForReplaceAppliances(true);
                } else {
                    setHavePolicyInYourPropertiesForReplaceAppliances(false);
                }
                if (getBrandSustainabilityByBrandIdSuccess.data?.doYouUseProductsInSustainablePackage === CommonAnswer.YES) {
                    setDoYouUseProductsInSustainablePackage(true);
                } else {
                    setDoYouUseProductsInSustainablePackage(false);
                }

                if (getBrandSustainabilityByBrandIdSuccess.data?.doYouWorkWithAnyLocalCharities === CommonAnswer.YES) {
                    setCharities(true);
                } else {
                    setCharities(false);
                }

            }
        }

        if (createBrandSustainabilitySuccess) {
            setLoading(false);
            toast.success("Brand sustainability created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            scrollToTop();
            getBrandSustainabilityByBrandId(brandId);
        }

        if (createBrandSustainabilityError) {
            setLoading(false);
            toast.error(createBrandSustainabilityError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (updateBrandSustainabilitySuccess) {
            setLoading(false);
            toast.success('Brand sustainability  update successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            scrollToTop();
            getBrandSustainabilityByBrandId(brandId);
        }

        if (updateBrandSustainabilityError) {
            setLoading(false);
            toast.error(updateBrandSustainabilityError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [brandId, createBrandSustainabilityError, createBrandSustainabilitySuccess, getBrandSustainabilityByBrandId, getBrandSustainabilityByBrandIdSuccess, loadBrandSuccess, updateBrandSustainabilityError, updateBrandSustainabilitySuccess]);

    //////////////////////////////////whatYouProvideForTheGuest validation manage ///////////////////////////
    const onChangeAppliances = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setHavePolicyInYourPropertiesForReplaceAppliances(true);
        } else {
            setHavePolicyInYourPropertiesForReplaceAppliances(false);
        }
    }, []);

    //////////////////////////////////whatYouProvideForTheGuest validation manage ///////////////////////////
    const onChangePackage = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setDoYouUseProductsInSustainablePackage(true);
        } else {
            setDoYouUseProductsInSustainablePackage(false);
        }
    }, []);

    /////////////////////////////////////////////////////////////
    const onChangeCharities = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setCharities(true);
        } else {
            setCharities(false);
        }
    }, []);

    //////////////////////////////////////////////// Submit ///////////////////////////////////////////////////
    const onFinish = useCallback((values) => {
        if (!brandId) {
            toast.error('Please create an brand', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        setLoading(true);
        const createUpdateValues = {
            brandId: brandId,
            havePolicyInYourPropertiesForReplaceAppliances: values.havePolicyInYourPropertiesForReplaceAppliances,
            whichOfFollowingRecentlyReplaced: values.whichOfFollowingRecentlyReplaced,
            doYouUseProductsInSustainablePackage: values.doYouUseProductsInSustainablePackage,
            whichOfFollowingProductPurchased: values.whichOfFollowingProductPurchased,
            doYouUtilizeConferenceCallForStaffInterviews: values.doYouUtilizeConferenceCallForStaffInterviews,
            doYouProvideElectronicContractsForStaff: values.doYouProvideElectronicContractsForStaff,
            doYouWorkWithAnyLocalCharities: values.doYouWorkWithAnyLocalCharities,
            pleaseAdviceWhichCharitiesYouWork: values.pleaseAdviceWhichCharitiesYouWork,

        }

        if (brandSustainabilityId) {
            Object.assign(createUpdateValues, { brandSustainabilityId });
            delete createUpdateValues.brandId;
            updateBrandSustainability(createUpdateValues)
        } else {
            createBrandSustainability(createUpdateValues);
        }


    }, [brandId, brandSustainabilityId, createBrandSustainability, updateBrandSustainability]);

    const changeFormEditable = useCallback(() => {
        scrollToTop();
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);

    /////////////// appliances /////////////////
    const renderAppliances = useCallback(() => {
        if (plainOptions) {

            return plainOptions.map((at: any, key: number) => {
                return (
                    <Row className="mt-3" key={key}>
                        <Col md={2}>

                        </Col>
                        <Col md={10} >
                            <div style={{ marginTop: "2%", marginLeft: '-21%' }}>
                                <Checkbox value={`${at.value}`}></Checkbox> <span style={{ marginLeft: '3%' }}>{at.label}</span>
                            </div>
                        </Col>
                    </Row>
                );
            });
        }
    }, []);


    /////////////// package /////////////////
    const renderPackage = useCallback(() => {
        if (plainOptions2) {

            return plainOptions2.map((at: any, key: number) => {
                return (
                    <Row className="mt-3" key={key}>
                        <Col md={2}>

                        </Col>
                        <Col md={10} >
                            <div style={{ marginTop: "2%", marginLeft: '-21%' }}>
                                <Checkbox value={`${at.value}`}></Checkbox> <span style={{ marginLeft: '3%' }}>{at.label}</span>
                            </div>
                        </Col>
                    </Row>
                );
            });
        }
    }, []);



    const renderShowRecentPlace = useCallback(() => {

        if (brandSustainabilityData?.whichOfFollowingRecentlyReplaced) {

            return brandSustainabilityData?.whichOfFollowingRecentlyReplaced.map((at: any, key: number) => {
                return (
                    <>
                        <Row className="mt-3" key={key}>
                     
                            <Col md={10}>
                                <div style={{ marginLeft: '-2%' }}>
                                    <span style={{ marginLeft: '2%' }}>{at}</span>
                                </div>
                            </Col>
                        </Row>
                    </>
                );
            });
        }
    }, [brandSustainabilityData?.whichOfFollowingRecentlyReplaced]);



    const renderShowProductPurchased = useCallback(() => {
        if (brandSustainabilityData?.whichOfFollowingProductPurchased) {

            return brandSustainabilityData?.whichOfFollowingProductPurchased.map((at: any, key: number) => {
                return (
                    <>
                        <Row className="mt-3" key={key}>
                      
                            <Col md={10}>
                                <div style={{ marginLeft: '-2%' }}>
                                    <span style={{ marginLeft: '2%' }}>{at}</span>
                                </div>
                            </Col>
                        </Row>
                    </>
                );
            });
        }
    }, [brandSustainabilityData?.whichOfFollowingProductPurchased]);

    return (

        <div style={{ marginTop: "-2%", paddingTop: "4px" }}>
            {
                title && <Row>
                    <Col xs={9}><div className="content-title">{title}</div></Col>
                    <Col xs={3} style={{ textAlign: "right" }}>
                        <div className='d-flex align-items-center justify-content-end gx-2'>
                            {/* <HelpModal /> */}
                            {
                                !loading && !editEnable && brandId && canBrandModuleWrite(brandId, UserAccess.brand_access.details.code) && <Button id='edit-button' className="mb-0" style={formButton} onClick={changeFormEditable} >Edit</Button>
                            }</div>
                    </Col>
                </Row>
            }
            {/* <div className='edit_btn_wpr'>
                <div className='d-flex align-items-center justify-content-end gx-2'>
                    <HelpModal />
                    {
                        !loading && !editEnable && <Button id='edit-button' className='mb-0' style={formButton} onClick={changeFormEditable} >Edit</Button>
                    }
                </div>
            </div> */}
            <Container fluid className="p-0">
                <div className="jumbotron">
                    <Form onSubmit={handleSubmit(onFinish)}>
                        <Row>
                            <Col md={12}>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you have a policy in place to replace old appliances with energy saving appliances in your properties?
                                        </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{brandSustainabilityData?.havePolicyInYourPropertiesForReplaceAppliances}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="havePolicyInYourPropertiesForReplaceAppliances"
                                                defaultValue={brandSustainabilityData?.havePolicyInYourPropertiesForReplaceAppliances}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            brandSustainabilityData?.havePolicyInYourPropertiesForReplaceAppliances === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="havePolicyInYourPropertiesForReplaceAppliances"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAppliances(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="havePolicyInYourPropertiesForReplaceAppliances"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAppliances(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            brandSustainabilityData?.havePolicyInYourPropertiesForReplaceAppliances === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="havePolicyInYourPropertiesForReplaceAppliances"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAppliances(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="havePolicyInYourPropertiesForReplaceAppliances"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAppliances(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="havePolicyInYourPropertiesForReplaceAppliances"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>




                                {havePolicyInYourPropertiesForReplaceAppliances && <Row className="formSpace">
                                    <Col md={6} style={{ textAlign: "right" }}>
                                        <Form.Label className="mt-3">{editEnable && <span className='requiredMark'>*</span>} Which of the following have you recently replaced?
                                        </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: "left" }}>
                                        {!editEnable && renderShowRecentPlace()}
                                        {editEnable && (
                                            <Controller
                                                name="whichOfFollowingRecentlyReplaced"
                                                defaultValue={brandSustainabilityData?.whichOfFollowingRecentlyReplaced}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) => (
                                                    <>
                                                        <Checkbox.Group defaultValue={brandSustainabilityData?.whichOfFollowingRecentlyReplaced} style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
                                                            {renderAppliances()}
                                                        </Checkbox.Group>
                                                    </>
                                                )}
                                            />
                                        )}
                                        {editEnable && errors.whichOfFollowingRecentlyReplaced && (
                                            <div className="invalid-feedback-custom">
                                                Please select a options!
                                            </div>
                                        )}
                                    </Col>
                                </Row>}


                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you use biogradable, non-toxic products in sustainable packaging?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{brandSustainabilityData?.doYouUseProductsInSustainablePackage}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouUseProductsInSustainablePackage"
                                                defaultValue={brandSustainabilityData?.doYouUseProductsInSustainablePackage}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            brandSustainabilityData?.doYouUseProductsInSustainablePackage === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouUseProductsInSustainablePackage"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePackage(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouUseProductsInSustainablePackage"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePackage(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            brandSustainabilityData?.doYouUseProductsInSustainablePackage === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouUseProductsInSustainablePackage"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePackage(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouUseProductsInSustainablePackage"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePackage(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouUseProductsInSustainablePackage"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>


                                {doYouUseProductsInSustainablePackage && <Row className="formSpace">
                                    <Col md={6} style={{ textAlign: "right" }}>
                                        <Form.Label className="mt-3"> {editEnable && <span className='requiredMark'>*</span>}Which of the following biodegradable, non-toxic products are you purchasing in sustainable packaging?

                                        </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: "left" }}>
                                        {!editEnable && renderShowProductPurchased()}
                                        {editEnable && (
                                            <Controller
                                                name="whichOfFollowingProductPurchased"
                                                defaultValue={brandSustainabilityData?.whichOfFollowingProductPurchased}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) => (
                                                    <>
                                                        <Checkbox.Group defaultValue={brandSustainabilityData?.whichOfFollowingProductPurchased} style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
                                                            {renderPackage()}
                                                        </Checkbox.Group>
                                                    </>
                                                )}
                                            />
                                        )}
                                        {editEnable && errors.whichOfFollowingProductPurchased && (
                                            <div className="invalid-feedback-custom">
                                                Please select a options!
                                            </div>
                                        )}
                                    </Col>
                                </Row>}

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you utilise conference calls for initial staff interviews when recruiting?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{brandSustainabilityData?.doYouUtilizeConferenceCallForStaffInterviews}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouUtilizeConferenceCallForStaffInterviews"
                                                defaultValue={brandSustainabilityData?.doYouUtilizeConferenceCallForStaffInterviews}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            brandSustainabilityData?.doYouUtilizeConferenceCallForStaffInterviews === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouUtilizeConferenceCallForStaffInterviews"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouUtilizeConferenceCallForStaffInterviews"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            brandSustainabilityData?.doYouUtilizeConferenceCallForStaffInterviews === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouUtilizeConferenceCallForStaffInterviews"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouUtilizeConferenceCallForStaffInterviews"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouUtilizeConferenceCallForStaffInterviews"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>





                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you provide electronic contracts for staff rather than providing paper contracts?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{brandSustainabilityData?.doYouProvideElectronicContractsForStaff}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouProvideElectronicContractsForStaff"
                                                defaultValue={brandSustainabilityData?.doYouProvideElectronicContractsForStaff}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            brandSustainabilityData?.doYouProvideElectronicContractsForStaff === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideElectronicContractsForStaff"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideElectronicContractsForStaff"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            brandSustainabilityData?.doYouProvideElectronicContractsForStaff === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideElectronicContractsForStaff"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideElectronicContractsForStaff"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouProvideElectronicContractsForStaff"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you work with any local charities?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{brandSustainabilityData?.doYouWorkWithAnyLocalCharities}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouWorkWithAnyLocalCharities"
                                                defaultValue={brandSustainabilityData?.doYouWorkWithAnyLocalCharities}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            brandSustainabilityData?.doYouWorkWithAnyLocalCharities === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouWorkWithAnyLocalCharities"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouWorkWithAnyLocalCharities"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeCharities(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            brandSustainabilityData?.doYouWorkWithAnyLocalCharities === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouWorkWithAnyLocalCharities"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeCharities(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouWorkWithAnyLocalCharities"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeCharities(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouWorkWithAnyLocalCharities"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                {charities && <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{<span className='requiredMark'>*</span>}Please advise which charities you work with?</Form.Label>
                                    </Col>

                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{brandSustainabilityData?.pleaseAdviceWhichCharitiesYouWork}</p>}

                                        {editEnable && <Form.Control as="textarea" rows={4} placeholder={"please enter a text"} className={`${errors.pleaseAdviceWhichCharitiesYouWork ? 'is-invalid' : ''}`} defaultValue={brandSustainabilityData?.pleaseAdviceWhichCharitiesYouWork} {...register("pleaseAdviceWhichCharitiesYouWork", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input overview!</div>}
                                    </Col>
                                </Row>}

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!loading && editEnable && canBrandModuleWrite(brandId, UserAccess.brand_access.details.code) && brandId &&
                                            <Button type="submit" id='approve-button' style={formButton}> {brandSustainabilityId ? 'Update' : 'Create'} </Button>
                                        }

                                        {
                                            loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </div>
    );
};

export default BrandSustainability;