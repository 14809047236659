import React, { useCallback, useEffect, useState } from "react"
import { $mysaMegenta, $mysaRed, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, selectStylesLittleRect, tableStyles } from "../../../../../common/components-style";
import DataTable, { createTheme } from 'react-data-table-component';
import { getStorage } from "../../../../../common/storage";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ViewEdit from '../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { REQUEST_BOOKING_STATUS } from "../../../../../common/constants";
import RequestBookingHomeOperatorView from "./RequestBookingHomeOperatorView";
import { TRequestBookingView } from "../../../../../common/types";
import moment from "moment";
import RequestBookingOfferedHomeOperatorView from "./RequestBookingOfferedHomeOperatorView";
import Select from 'react-select';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const RequestBookingOperatorHome: React.FunctionComponent = () => {

	///////////// data get from local storage database from browser ////////////////
	const myoLoggedUserPermission = getStorage('myo_logged_user_permission');
	const brandId = myoLoggedUserPermission?.brandData[0]?._id;

	///////////////////////////global action///////////////////////////////////
	const { getRequestBookingListByBrand, getLocationListByBrand, getPropertyListByBrand } = useStoreActions<any>((actions) => ({
		getRequestBookingListByBrand: actions.booking.getRequestBookingListByBrand,
		getLocationListByBrand: actions.booking.getLocationListByBrand,
		getPropertyListByBrand: actions.booking.getPropertyListByBrand,
	}));

	/////////////////// global state ////////////////
	const { getRequestBookingListByBrandSuccess, getLocationListByBrandSuccess, getPropertyListByBrandSuccess } = useStoreState<any>((actions) => ({
		getRequestBookingListByBrandSuccess: actions.booking.getRequestBookingListByBrandSuccess,
		getLocationListByBrandSuccess: actions.booking.getLocationListByBrandSuccess,
		getPropertyListByBrandSuccess: actions.booking.getPropertyListByBrandSuccess
	}));

	///////////////// local state ////////////////
	const [data, setData] = useState<any>();
	const [hideShowView, setHideShowView] = useState<Boolean>(false);
	const [propertyHideShow, setPropertyHideShow] = useState<Boolean>(true);
	const [hideShowViewExtendOffer, setHideShowViewExtendOffer] = useState<Boolean>(false);
	const [viewComponentData, setViewComponentData] = useState<TRequestBookingView>();
	const [status, setStatus] = useState<String>("");
	const [locationOptions, setLocationOptions] = useState<any>([]);
	const [propertiesOptions, setPropertiesOptions] = useState<any>([]);
	const [propertyId, setPropertyId] = useState<String>("");
	const [locationId, setLocationId] = useState<String>("");
	//////////// action hook //////////////
	useEffect(() => {
		if (brandId) {
			const payload = {
				brandId: brandId
			}

			getRequestBookingListByBrand(payload);
			getLocationListByBrand(payload);
		}
	}, [brandId, getLocationListByBrand, getRequestBookingListByBrand]);

	///////////////// data fetch and handle hook /////////////////
	useEffect(() => {

		if (getRequestBookingListByBrandSuccess) {
			setData(getRequestBookingListByBrandSuccess.data)
		}

	}, [getRequestBookingListByBrandSuccess]);

	useEffect(() => {

		if (getLocationListByBrandSuccess) {
			const Locations: any = [];
			for (const loc of getLocationListByBrandSuccess.data) {
				Locations.push({
					value: loc,
					label: loc,
				})
			}
			setLocationOptions(Locations);
		}

	}, [getLocationListByBrandSuccess]);

	useEffect(() => {

		if (getPropertyListByBrandSuccess) {
			const properties: any = [];
			for (const prop of getPropertyListByBrandSuccess.data) {
				properties.push({
					value: prop.propertyId,
					label: prop.propertyName,
				})
			}
			setPropertiesOptions(properties);
		}

	}, [getPropertyListByBrandSuccess]);

	/////////////////////////////////////////////////////////////////////////////
	const hideShowViewComponent = (value: boolean) => {
		setHideShowView(value);
		setHideShowViewExtendOffer(false);
		if (brandId) {
			const payload = {
				brandId: brandId
			}

			getRequestBookingListByBrand(payload);

		}
	}

	const columns = [
		{
			name: 'Client',
			selector: row => row.clientName,
		},
		{
			name: 'Location',
			selector: row => row.location.locationName,
		},
		{
			name: 'Property',
			selector: row => row.property.propertyName,
		},
		{
			name: 'Received',
			selector: row => moment(row.requestedDate).format('D MMM YYYY'),
		},
		{
			name: 'Time',
			selector: row => moment(row.requestedDate).format('HH:mm'),
		},
		{
			name: 'Check-in',
			selector: row => moment(row.checkInDate).format('D MMM YYYY'),
		},
		{
			name: 'Check-out',
			selector: row => moment(row.checkOutDate).format('D MMM YYYY'),
		},
		{
			name: 'Status',
			cell: (row) => {
				let color = "";
				if (row.requestStatus === REQUEST_BOOKING_STATUS.PENDING) color = $mysaYellow;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.NOT_AVAILABLE) color = $mysaRed;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.AVAILABLE) color = $mysaTeal;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED) color = $mysaMegenta;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.ALT_OFFER) color = $mysaTeal;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.DECLINED) color = $mysaRed;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.EXPIRED) color = $mysaRed;

				return (
					<span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.requestStatus}</span>
				)
			}
		},
		{
			name: 'Expiry Date',
			selector: row => (row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED || row.requestStatus === REQUEST_BOOKING_STATUS.ALT_OFFER) ? moment(row.expiryDetails?.offerExpiresAt).format('D MMM YYYY') : '',
		},
		{
			name: 'Expiry Time',
			selector: row => (row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED || row.requestStatus === REQUEST_BOOKING_STATUS.ALT_OFFER) ? moment(row.expiryDetails?.offerExpiresAt).format('HH:mm') : '',
		},
		{
			name: 'Action',
			button: true,
			cell: (row: any) => {
				return (<>
					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
					>
						<img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
							const data: TRequestBookingView = {
								_id: row?._id,
								_reqGroupId: row?._reqGroupId,
								_reqBrandId: row?._reqBrandId,
								_reqPropertyId: row?.property?._reqPropertyId,
								_reqInventoryId: row?.inventory?._reqInventoryId,
								hideShowViewComponent: () => { }
							}
							setViewComponentData(data);
							setStatus(row.requestStatus);
							if (row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED) {
								hideShowViewComponent(false);
								setHideShowViewExtendOffer(true);
							} else if (row.requestStatus === REQUEST_BOOKING_STATUS.ALT_OFFER) {
								setHideShowViewExtendOffer(true);
								// hideShowViewComponent(false);
							}
							else {
								hideShowViewComponent(true);
								setHideShowViewExtendOffer(false);
							}
						}} />
					</OverlayTrigger>

				</>)
			},
		},
	];

	const onChangeLocation = useCallback((value) => {
		if (value) {
			setPropertyHideShow(false);
			setLocationId(value.value);
			const payload = {
				brandId: brandId,
				locationId: value.value
			};
			getPropertyListByBrand(payload);
			const payload1 = {
				brandId: brandId,
				locationId: value.value,
				propertyId: propertyId,
			};
			getRequestBookingListByBrand(payload1);
		} else {
			setLocationId('');
			setPropertyHideShow(true);
			const payload1 = {
				brandId: brandId,
			};
			getRequestBookingListByBrand(payload1);
		}
	}, [brandId, getPropertyListByBrand, getRequestBookingListByBrand, propertyId])

	const onChangeProperty = useCallback((value) => {
		if (value) {
			setPropertyId(value.value);
			const payload = {
				brandId: brandId,
				locationId: locationId,
				propertyId: value.value,
			};
			getRequestBookingListByBrand(payload);
		} else {
			setPropertyHideShow(true);
			setPropertyId('');
			const payload = {
				brandId: brandId,
				locationId: locationId
			};
			getRequestBookingListByBrand(payload);
		}
	}, [brandId, getRequestBookingListByBrand, locationId]);

	return (
		<>
			{
				!hideShowView && !hideShowViewExtendOffer &&
				<>
					<Row className="text-center">
						<Col xs={2}>
							<Select
								isClearable
								className='search_bar select_btn'
								placeholder={<div>Select Location</div>}
								options={locationOptions}
								styles={selectStylesLittleRect}
								onChange={onChangeLocation}
							/>
						</Col>
						<Col xs={2}>
							<Select
								isClearable
								className='search_bar select_btn'
								placeholder={<div>Select Property</div>}
								options={propertiesOptions}
								styles={selectStylesLittleRect}
								isDisabled={Boolean(propertyHideShow)}
								onChange={onChangeProperty}
							/>
						</Col>
						<Col xs={8} className="text-right">

						</Col>
					</Row>

					<div className='brnd_tbl' style={{ marginTop: '50px' }}>

						<DataTable
							columns={columns}
							data={data}
							pagination
							striped={true}
							customStyles={tableStyles}
							theme='mysaTable'
							fixedHeader={true}
							noDataComponent={'Updating records'}
						/>
					</div>
				</>

			}

			{
				hideShowView && <RequestBookingHomeOperatorView status={status} hideShowViewComponent={hideShowViewComponent} _id={viewComponentData?._id} _reqBrandId={viewComponentData?._reqBrandId} _reqGroupId={viewComponentData?._reqGroupId} _reqInventoryId={viewComponentData?._reqInventoryId} _reqPropertyId={viewComponentData?._reqPropertyId} />
			}

			{
				hideShowViewExtendOffer && <RequestBookingOfferedHomeOperatorView status={status} hideShowViewComponent={hideShowViewComponent} _id={viewComponentData?._id} _reqBrandId={viewComponentData?._reqBrandId} _reqGroupId={viewComponentData?._reqGroupId} _reqInventoryId={viewComponentData?._reqInventoryId} _reqPropertyId={viewComponentData?._reqPropertyId} />
			}

		</>
	)
}

export default RequestBookingOperatorHome;