import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { $mysaBlack, $mysaYellow, overlayTriggerDelay } from '../../../../common/components-style';
import { useForm, Controller } from 'react-hook-form';
import { SeasonalRateForm } from '../../../../common/interfaces';
import { toast } from 'react-toastify';
import { DatePicker } from 'antd';
import moment from 'moment';
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { ErrorMessage } from '@hookform/error-message';
import { getStorage } from '../../../../common/storage';
import Archive from '../../../../images/icons/svg/outside/Icon-Function-Archive.svg';


/////////////////////////////// details block style////////////////////////////////////////
const headStyle = { fontSize: '17px', fontWeight: 'bold', padding: '12%', marginTop: '5%', width: '200px' } as React.CSSProperties;
const headBorderStyle = { borderLeft: '2px solid #C4C4C4', marginRight: '3%' } as React.CSSProperties;
const bodyTextStyleOne = { fontSize: '18px', padding: '5%', textAlign: 'center', marginTop: '7%', paddingTop: '10%', marginBottom: '11%' } as React.CSSProperties;

const formButton = {
    height: "40px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    marginRight: '20px',
    border: '1px solid #ffffff',
    background: $mysaYellow,
    color: $mysaBlack
} as React.CSSProperties;

const { RangePicker } = DatePicker;

const SubmittedRfpRateSeasonalForm: React.FC<any> = ({ seasonalRate, inventory, callRfps, isSeasonalHere }): JSX.Element => {
    ///////////////// form initialize //////////
    const { register, handleSubmit, formState: { errors }, control, reset } = useForm<SeasonalRateForm>();
    //////////// local state manage ///////////////////
    const [editEnable, setEditEnable] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [dateUpdate, setDateUpdate] = useState<boolean>(false);
    ////////////// local storage access ////////////////
    const myoSelectedBrand = getStorage("myo_selected_my_rfp_brand");
    const myoSelectedMyRfp = getStorage("myo_selected_my_rfp");
    const myoSelectedMyRfpProperty = getStorage("myo_selected_my_rfp_property");

    /////////////////////////// global action ////////////////////////
    const { createUpdatePropertySeasonalRateForRFP, deletePropertySeasonalRateForRFP } = useStoreActions<any>((actions) => ({
        createUpdatePropertySeasonalRateForRFP: actions.rfpProcess.createUpdatePropertySeasonalRateForRFP,
        deletePropertySeasonalRateForRFP: actions.rfpProcess.deletePropertySeasonalRateForRFP,
    }));

    /////////////////////////// global state ////////////////////////
    const { getCreateUpdatePropertySeasonalRateForRFPSuccess, getCreateUpdatePropertySeasonalRateForRFPError, getDeletePropertySeasonalRateForRFPSuccess, getDeletePropertySeasonalRateForRFPError } = useStoreState<any>((state) => ({
        getCreateUpdatePropertySeasonalRateForRFPSuccess: state.rfpProcess.getCreateUpdatePropertySeasonalRateForRFPSuccess,
        getCreateUpdatePropertySeasonalRateForRFPError: state.rfpProcess.getCreateUpdatePropertySeasonalRateForRFPError,
        getDeletePropertySeasonalRateForRFPSuccess: state.rfpProcess.getDeletePropertySeasonalRateForRFPSuccess,
        getDeletePropertySeasonalRateForRFPError: state.rfpProcess.getDeletePropertySeasonalRateForRFPError,
    }));

    //////////// check apis responses /////////////////
    useEffect(() => {
        reset();
        if (seasonalRate) {
            setEditEnable(true);
        } else {
            setEditEnable(false);
        }
        if (getCreateUpdatePropertySeasonalRateForRFPSuccess) {
            toast.success("Seasonal rate submitted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setLoading(false);
            callRfps();
        }

        if (getCreateUpdatePropertySeasonalRateForRFPError) {
            setLoading(false);
            toast.error(getCreateUpdatePropertySeasonalRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (getDeletePropertySeasonalRateForRFPSuccess) {
            toast.success("Seasonal rate deleted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            callRfps();

        }

        if (getDeletePropertySeasonalRateForRFPError) {
            setLoading(false);
            toast.error(getDeletePropertySeasonalRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [callRfps, getCreateUpdatePropertySeasonalRateForRFPError, getCreateUpdatePropertySeasonalRateForRFPSuccess, getDeletePropertySeasonalRateForRFPError, getDeletePropertySeasonalRateForRFPSuccess, reset, seasonalRate]);


    //////////////////// delete api /////////////
    const seasonalRfpDelete = useCallback(() => {
        const payload = {
            rfpProcessId: myoSelectedMyRfp.rfpId,
            brandId: myoSelectedBrand,
            propertyId: myoSelectedMyRfpProperty.id,
            propertyInventoryId: inventory.key,
            seasonalRateId: seasonalRate._id,
        }

        deletePropertySeasonalRateForRFP(payload);
    }, [deletePropertySeasonalRateForRFP, inventory.key, myoSelectedBrand, myoSelectedMyRfp.rfpId, myoSelectedMyRfpProperty.id, seasonalRate._id])

    const changeFormEditable = useCallback(() => {
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onFinish = (value) => {

        if (seasonalRate) {
            const payload = {
                rfpProcessId: myoSelectedMyRfp.rfpId,
                brandId: myoSelectedBrand,
                propertyId: myoSelectedMyRfpProperty.id,
                propertyInventoryId: inventory.key,
                seasonalRateElement: {
                    original: {
                        _id: seasonalRate?.original?._id,
                        fromDate: dateUpdate ? moment(value.fromToDate[0]._d).format('YYYY-MM-DD') : seasonalRate?.original?.fromDate,
                        toDate: dateUpdate ? moment(value.fromToDate[1]._d).format('YYYY-MM-DD') : seasonalRate?.original?.toDate,
                        oneTo7: value.oneTo7,
                        eightTo14: value.eightTo14,
                        fifteenTo28: value.fifteenTo28,
                        twentyNineTo90: value.twentyNineTo90,
                        ninetyOnePlus: value.ninetyOnePlus,
                    },
                    old: {
                        _id: seasonalRate?.old?._id,
                        fromDate: dateUpdate ? moment(value.fromToDate[0]._d).format('YYYY-MM-DD') : seasonalRate?.old?.fromDate,
                        toDate: dateUpdate ? moment(value.fromToDate[1]._d).format('YYYY-MM-DD') : seasonalRate?.old?.toDate,
                        oneTo7: value.oneTo7,
                        eightTo14: value.eightTo14,
                        fifteenTo28: value.fifteenTo28,
                        twentyNineTo90: value.twentyNineTo90,
                        ninetyOnePlus: value.ninetyOnePlus,
                    }
                }
            }
            setLoading(true);
            createUpdatePropertySeasonalRateForRFP(payload);
        } else {
            const payload = {
                rfpProcessId: myoSelectedMyRfp.rfpId,
                brandId: myoSelectedBrand,
                propertyId: myoSelectedMyRfpProperty.id,
                propertyInventoryId: inventory.key,
                seasonalRateElement: {
                    original: {
                        fromDate: dateUpdate ? moment(value.fromToDate[0]._d).format('YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD'),
                        toDate: dateUpdate ? moment(value.fromToDate[1]._d).format('YYYY-MM-DD') : moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD'),
                        oneTo7: value.oneTo7,
                        eightTo14: value.eightTo14,
                        fifteenTo28: value.fifteenTo28,
                        twentyNineTo90: value.twentyNineTo90,
                        ninetyOnePlus: value.ninetyOnePlus,
                    },
                    old: {
                        fromDate: dateUpdate ? moment(value.fromToDate[0]._d).format('YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD'),
                        toDate: dateUpdate ? moment(value.fromToDate[1]._d).format('YYYY-MM-DD') : moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD'),
                        oneTo7: value.oneTo7,
                        eightTo14: value.eightTo14,
                        fifteenTo28: value.fifteenTo28,
                        twentyNineTo90: value.twentyNineTo90,
                        ninetyOnePlus: value.ninetyOnePlus,
                    },
                }
            }
            setLoading(true);
            createUpdatePropertySeasonalRateForRFP(payload);
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    const onFinishAmendSeasonal = (value) => {

        if (seasonalRate) {
            const payload = {
                rfpProcessId: myoSelectedMyRfp.rfpId,
                brandId: myoSelectedBrand,
                propertyId: myoSelectedMyRfpProperty.id,
                propertyInventoryId: inventory.key,
                seasonalRateElement: {
                    original: {
                        _id: seasonalRate?.original?._id,
                        fromDate: dateUpdate ? moment(value.fromToDate[0]._d).format('YYYY-MM-DD') : seasonalRate?.original?.fromDate,
                        toDate: dateUpdate ? moment(value.fromToDate[1]._d).format('YYYY-MM-DD') : seasonalRate?.original?.toDate,
                        oneTo7: value.oneTo7,
                        eightTo14: value.eightTo14,
                        fifteenTo28: value.fifteenTo28,
                        twentyNineTo90: value.twentyNineTo90,
                        ninetyOnePlus: value.ninetyOnePlus,
                    },
                    old: {
                        _id: seasonalRate?.old?._id,
                        fromDate: dateUpdate ? moment(value.fromToDate[0]._d).format('YYYY-MM-DD') : seasonalRate?.old?.fromDate,
                        toDate: dateUpdate ? moment(value.fromToDate[1]._d).format('YYYY-MM-DD') : seasonalRate?.old?.toDate,
                        oneTo7: seasonalRate?.old?.oneTo7,
                        eightTo14: seasonalRate?.old?.eightTo14,
                        fifteenTo28: seasonalRate?.old?.fifteenTo28,
                        twentyNineTo90: seasonalRate?.old?.twentyNineTo90,
                        ninetyOnePlus: seasonalRate?.old?.ninetyOnePlus,
                    },
                }
            }
            setLoading(true);
            createUpdatePropertySeasonalRateForRFP(payload);
        } else {
            const payload = {
                rfpProcessId: myoSelectedMyRfp.rfpId,
                brandId: myoSelectedBrand,
                propertyId: myoSelectedMyRfpProperty.id,
                propertyInventoryId: inventory.key,
                seasonalRateElement: {
                    original: {
                        fromDate: dateUpdate ? moment(value.fromToDate[0]._d).format('YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD'),
                        toDate: dateUpdate ? moment(value.fromToDate[1]._d).format('YYYY-MM-DD') : moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD'),
                        oneTo7: value.oneTo7,
                        eightTo14: value.eightTo14,
                        fifteenTo28: value.fifteenTo28,
                        twentyNineTo90: value.twentyNineTo90,
                        ninetyOnePlus: value.ninetyOnePlus,
                    },
                    old: {
                        fromDate: dateUpdate ? moment(value.fromToDate[0]._d).format('YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD'),
                        toDate: dateUpdate ? moment(value.fromToDate[1]._d).format('YYYY-MM-DD') : moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD'),
                        oneTo7: value.oneTo7,
                        eightTo14: value.eightTo14,
                        fifteenTo28: value.fifteenTo28,
                        twentyNineTo90: value.twentyNineTo90,
                        ninetyOnePlus: value.ninetyOnePlus,
                    },
                }
            }
            setLoading(true);
            createUpdatePropertySeasonalRateForRFP(payload);
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onFromDateChange = useCallback(() => {
        setDateUpdate(true);
    }, []);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            {((inventory?.status !== 'review' && isSeasonalHere === false)) && <div className='mt-5'>
                <Form onSubmit={handleSubmit(onFinish)}>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '5%' }}>Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}>
                                <span style={headBorderStyle}></span><span >1-7 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 8-14 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 15-28 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 29-89 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 90+ nights</span>
                            </div>
                        </Col>
                        <Col md={4} >
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3%', width: '200px', marginTop: '2px' }}><span style={headBorderStyle}></span>
                                <span > Dates</span>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '3%', marginBottom: '10px' }}>Standard Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.old?.oneTo7} min={0} className={`${errors.oneTo7 ? 'is-invalid' : ''}`} {...register("oneTo7", { required: true })} disabled={editEnable} />

                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.old?.eightTo14} min={0} className={`${errors.eightTo14 ? 'is-invalid' : ''}`} {...register("eightTo14", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.old?.fifteenTo28} min={0} className={`${errors.fifteenTo28 ? 'is-invalid' : ''}`} {...register("fifteenTo28", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.old?.twentyNineTo90} min={0} className={`${errors.twentyNineTo90 ? 'is-invalid' : ''}`} {...register("twentyNineTo90", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.old?.ninetyOnePlus} min={0} className={`${errors.ninetyOnePlus ? 'is-invalid' : ''}`} {...register("ninetyOnePlus", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={3} >
                            {
                                <Controller
                                    name="fromToDate"
                                    defaultValue={[moment(new Date(seasonalRate?.old?.fromDate), 'YYYY-MM-DD'), moment(new Date(new Date(seasonalRate?.old?.toDate).getFullYear(), new Date(seasonalRate?.old?.toDate).getMonth(), new Date(seasonalRate?.old?.toDate).getDate()), 'YYYY-MM-DD')]}
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <div className='custom-form-control'>
                                                <RangePicker
                                                    disabled={editEnable}
                                                    autoFocus={false}
                                                    bordered={false}
                                                    className="font-light"
                                                    suffixIcon={<div></div>}
                                                    style={{
                                                        border: 'none',
                                                        fontWeight: 300,
                                                        fontSize: '20px',
                                                    }}
                                                    placeholder={["Check-in", "Check-out"]}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                        onFromDateChange();
                                                    }}
                                                    defaultValue={seasonalRate?.old?.fromDate ? [moment(new Date(seasonalRate?.old?.fromDate), 'YYYY-MM-DD'), moment(new Date(new Date(seasonalRate?.old?.toDate).getFullYear(), new Date(seasonalRate?.old?.toDate).getMonth(), new Date(seasonalRate?.old?.toDate).getDate()), 'YYYY-MM-DD')] : [moment(new Date(), 'YYYY-MM-DD'), moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD')]}
                                                />

                                                <ErrorMessage
                                                    errors={errors}
                                                    name="fromToDate"
                                                    render={({ message }) => <div className="invalid-feedback-custom">Please select date!</div>} />
                                            </div>
                                        </>
                                    )}
                                />
                            }

                        </Col>
                        <Col md={1} >
                            <div style={{ ...bodyTextStyleOne }}>
                                {!loading && !editEnable && <Button type="submit" style={formButton}><b>Save</b></Button>}
                                {loading && <Spinner animation="border" variant="warning" />}
                                {
                                    editEnable && <div>
                                        <span style={{ position: 'absolute' }}>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={overlayTriggerDelay}
                                                overlay={<Tooltip id='tooltip-table-top'>Edit</Tooltip>}
                                            >
                                                <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                                                    changeFormEditable();
                                                }} />
                                            </OverlayTrigger>
                                        </span>
                                        <span>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={overlayTriggerDelay}
                                                overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
                                            >
                                                <img className='iconSizes' src={Archive} alt="" onClick={() => {
                                                    seasonalRfpDelete();
                                                }} />
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                }

                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>}

            {((inventory?.status === 'review' && isSeasonalHere === true)) && <div className='mt-5'>
                <Form onSubmit={handleSubmit(onFinishAmendSeasonal)}>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '5%' }}>Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}>
                                <span style={headBorderStyle}></span><span >1-7 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 8-14 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 15-28 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 29-89 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 90+ nights</span>
                            </div>
                        </Col>
                        <Col md={4} >
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3%', width: '200px', marginTop: '2px' }}><span style={headBorderStyle}></span>
                                <span > Dates</span>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '3%', marginBottom: '10px' }}>Standard Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.original?.oneTo7} min={0} className={`${errors.oneTo7 ? 'is-invalid' : ''}`} {...register("oneTo7", { required: true })} disabled={editEnable} />

                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.original?.eightTo14} min={0} className={`${errors.eightTo14 ? 'is-invalid' : ''}`} {...register("eightTo14", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.original?.fifteenTo28} min={0} className={`${errors.fifteenTo28 ? 'is-invalid' : ''}`} {...register("fifteenTo28", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.original?.twentyNineTo90} min={0} className={`${errors.twentyNineTo90 ? 'is-invalid' : ''}`} {...register("twentyNineTo90", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.original?.ninetyOnePlus} min={0} className={`${errors.ninetyOnePlus ? 'is-invalid' : ''}`} {...register("ninetyOnePlus", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={3} >
                            {
                                <Controller
                                    name="fromToDate"
                                    defaultValue={[moment(new Date(seasonalRate?.original?.fromDate), 'YYYY-MM-DD'), moment(new Date(new Date(seasonalRate?.original?.toDate).getFullYear(), new Date(seasonalRate?.original?.toDate).getMonth(), new Date(seasonalRate?.original?.toDate).getDate()), 'YYYY-MM-DD')]}
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <div className='custom-form-control'>
                                                <RangePicker
                                                    disabled={editEnable}
                                                    autoFocus={false}
                                                    bordered={false}
                                                    className="font-light"
                                                    suffixIcon={<div></div>}
                                                    style={{
                                                        border: 'none',
                                                        fontWeight: 300,
                                                        fontSize: '20px',
                                                    }}
                                                    placeholder={["Check-in", "Check-out"]}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                        onFromDateChange();
                                                    }}
                                                    defaultValue={seasonalRate?.original?.fromDate ? [moment(new Date(seasonalRate?.original?.fromDate), 'YYYY-MM-DD'), moment(new Date(new Date(seasonalRate?.original?.toDate).getFullYear(), new Date(seasonalRate?.toDate).getMonth(), new Date(seasonalRate?.original?.toDate).getDate()), 'YYYY-MM-DD')] : [moment(new Date(), 'YYYY-MM-DD'), moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD')]}
                                                />

                                                <ErrorMessage
                                                    errors={errors}
                                                    name="fromToDate"
                                                    render={({ message }) => <div className="invalid-feedback-custom">Please select date!</div>} />
                                            </div>
                                        </>
                                    )}
                                />
                            }

                        </Col>
                        <Col md={1} >
                            <div style={{ ...bodyTextStyleOne }}>
                                {!loading && !editEnable && <Button type="submit" style={formButton}><b>Save</b></Button>}
                                {loading && <Spinner animation="border" variant="warning" />}
                                {
                                    editEnable && <div>
                                        <span style={{ position: 'absolute' }}>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={overlayTriggerDelay}
                                                overlay={<Tooltip id='tooltip-table-top'>Edit</Tooltip>}
                                            >
                                                <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                                                    changeFormEditable();
                                                }} />
                                            </OverlayTrigger>
                                        </span>
                                        <span>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={overlayTriggerDelay}
                                                overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
                                            >
                                                <img className='iconSizes' src={Archive} alt="" onClick={() => {
                                                    seasonalRfpDelete();
                                                }} />
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                }

                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>}

            {((isSeasonalHere === false)) && <div className='mt-5'>
                <Form onSubmit={handleSubmit(onFinishAmendSeasonal)}>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '5%' }}>Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}>
                                <span style={headBorderStyle}></span><span >1-7 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 8-14 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 15-28 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 29-89 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 90+ nights</span>
                            </div>
                        </Col>
                        <Col md={4} >
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3%', width: '200px', marginTop: '2px' }}><span style={headBorderStyle}></span>
                                <span > Dates</span>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '3%', marginBottom: '10px' }}>Standard Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.original?.oneTo7} min={0} className={`${errors.oneTo7 ? 'is-invalid' : ''}`} {...register("oneTo7", { required: true })} disabled={editEnable} />

                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.original?.eightTo14} min={0} className={`${errors.eightTo14 ? 'is-invalid' : ''}`} {...register("eightTo14", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.original?.fifteenTo28} min={0} className={`${errors.fifteenTo28 ? 'is-invalid' : ''}`} {...register("fifteenTo28", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.original?.twentyNineTo90} min={0} className={`${errors.twentyNineTo90 ? 'is-invalid' : ''}`} {...register("twentyNineTo90", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={seasonalRate?.original?.ninetyOnePlus} min={0} className={`${errors.ninetyOnePlus ? 'is-invalid' : ''}`} {...register("ninetyOnePlus", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={3} >
                            {
                                <Controller
                                    name="fromToDate"
                                    defaultValue={[moment(new Date(seasonalRate?.original?.fromDate), 'YYYY-MM-DD'), moment(new Date(new Date(seasonalRate?.original?.toDate).getFullYear(), new Date(seasonalRate?.original?.toDate).getMonth(), new Date(seasonalRate?.original?.toDate).getDate()), 'YYYY-MM-DD')]}
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <div className='custom-form-control'>
                                                <RangePicker
                                                    disabled={editEnable}
                                                    autoFocus={false}
                                                    bordered={false}
                                                    className="font-light"
                                                    suffixIcon={<div></div>}
                                                    style={{
                                                        border: 'none',
                                                        fontWeight: 300,
                                                        fontSize: '20px',
                                                    }}
                                                    placeholder={["Check-in", "Check-out"]}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                        onFromDateChange();
                                                    }}
                                                    defaultValue={seasonalRate?.original?.fromDate ? [moment(new Date(seasonalRate?.original?.fromDate), 'YYYY-MM-DD'), moment(new Date(new Date(seasonalRate?.original?.toDate).getFullYear(), new Date(seasonalRate?.original?.toDate).getMonth(), new Date(seasonalRate?.original?.toDate).getDate()), 'YYYY-MM-DD')] : [moment(new Date(), 'YYYY-MM-DD'), moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD')]}
                                                />

                                                <ErrorMessage
                                                    errors={errors}
                                                    name="fromToDate"
                                                    render={({ message }) => <div className="invalid-feedback-custom">Please select date!</div>} />
                                            </div>
                                        </>
                                    )}
                                />
                            }

                        </Col>
                        <Col md={1} >
                            <div style={{ ...bodyTextStyleOne }}>
                                {!loading && !editEnable && <Button type="submit" style={formButton}><b>Save</b></Button>}
                                {loading && <Spinner animation="border" variant="warning" />}
                                {
                                    editEnable && <div>
                                        <span style={{ position: 'absolute' }}>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={overlayTriggerDelay}
                                                overlay={<Tooltip id='tooltip-table-top'>Edit</Tooltip>}
                                            >
                                                <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                                                    changeFormEditable();
                                                }} />
                                            </OverlayTrigger>
                                        </span>
                                        <span>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={overlayTriggerDelay}
                                                overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
                                            >
                                                <img className='iconSizes' src={Archive} alt="" onClick={() => {
                                                    seasonalRfpDelete();
                                                }} />
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                }

                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>}
        </>
    )
}

export default SubmittedRfpRateSeasonalForm;