import { Breadcrumb, Button } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import InventoryForm from './form/InventoryForm';
import InventoryList from './InventoryList';
import { Link } from 'react-router-dom';
import { routingButton } from '../../../common/components-style';
import { getStorage } from '../../../common/storage';
import { toast } from 'react-toastify';


const InventoryHome: React.FC<any> = ({ location }): JSX.Element => {
	const [formOpen, setFormOpen] = useState<boolean>(false);
	const [inventoryId, setInventoryId] = useState<number | null>(null);
	const [inventoryList, setInventoryList] = useState<any>([]);
	const [propertyId, setPropertyId] = useState<number | null>(null);
	// const [selectedInventory, setSelectedInventory] = useState<any>(null);
	const [selectedProperty, setSelectedProperty] = useState<any>(null);
	const [hideButton, setHideButton] = useState<boolean>(false);
	const [offeredRoom, setOfferedRoom] = useState<number>(1);
	const [allInOnePropertyBreadCrumb, setAllInOnePropertyBreadCrumb] = useState<boolean>(false);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { updateInventory, resetInventory, getInventoryList, getInventoryByPropertyId, getProperty } = useStoreActions<any>((actions) => ({
		updateInventory: actions.inventory.updateInventory,
		resetInventory: actions.inventory.resetInventory,
		getInventoryList: actions.inventory.getInventoryList,
		getInventoryByPropertyId: actions.inventory.getInventoryByPropertyId,
		getProperty: actions.property.getProperty,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { removeInventorySuccess, updateInventoryError, getInventoryListSuccess, getGetInventoryListError, getInventoryByPropertyIdSuccess, getPropertySuccess } = useStoreState<any>((state) => ({
		getInventoryListSuccess: state.inventory.getInventoryListSuccess,
		getGetInventoryListError: state.inventory.getGetInventoryListError,
		removeInventorySuccess: state.inventory.removeInventorySuccess,
		updateInventoryError: state.inventory.updateInventoryError,
		getInventoryByPropertyIdSuccess: state.inventory.getInventoryByPropertyIdSuccess,
		getPropertySuccess: state.property.getPropertySuccess
	}))

	/**
	 * special navigate 
	 */
	useEffect(() => {
		if (location.state?.allInOnePropertyBreadCrumb) {
			setAllInOnePropertyBreadCrumb(location.state?.allInOnePropertyBreadCrumb);
		}
	}, [location.state?.allInOnePropertyBreadCrumb]);

	useEffect(() => {
		const property = getStorage('myo_selected_property');
		setSelectedProperty(property);
	}, [])

	////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (location.state?.propertyId) {
			const params: any = {};
			params.propertyId = location.state?.propertyId;
			getInventoryList(params);
		}

	}, [getInventoryList, location]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadForm = useCallback((data) => {
		setFormOpen(true);
		setInventoryId(data.key ? data.key : data._id);
		setPropertyId(data.propertyId ? data.propertyId : data?.propertyId?._id);
	}, []);


	const loadData = useCallback((data) => {
		setInventoryId(data._id);
		setPropertyId(data.propertyId);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (selectedProperty) {
			getProperty(selectedProperty._id)
		}
	}, [getProperty, selectedProperty]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getPropertySuccess) {
			const data = getPropertySuccess.data;

			if (data?.noOfRoomTypesOffered === undefined) {
				if (data?.moderate?.noOfRoomTypesOffered?.new_value) {
					setOfferedRoom(data?.moderate?.noOfRoomTypesOffered?.new_value);
				} else {
					setOfferedRoom(1);
				}
			} else {
				if (data?.moderate?.noOfRoomTypesOffered?.new_value) {
					setOfferedRoom(data?.moderate?.noOfRoomTypesOffered?.new_value);
				} else {
					setOfferedRoom(data?.noOfRoomTypesOffered);
				}
			}
		}

	}, [getPropertySuccess]);


	////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (selectedProperty) {
			getInventoryByPropertyId(selectedProperty._id);
		}

	}, [getInventoryByPropertyId, selectedProperty]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (getInventoryByPropertyIdSuccess) {
			if (getInventoryByPropertyIdSuccess.data.length === offeredRoom) {
				setHideButton(true);
			} else {
				setHideButton(false);
			}
		}
	}, [getInventoryByPropertyIdSuccess, offeredRoom]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (location.state?.selectedInventory) {
			loadForm(location.state?.selectedInventory);
			// setSelectedInventory(location?.state?.selectedInventory);
		}
	}, [loadForm, location])


	////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (getInventoryListSuccess) {
			setInventoryList(getInventoryListSuccess.data.inventory);
		}
		if (getGetInventoryListError) {
			toast.error(getGetInventoryListError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});

		}
	}, [getGetInventoryListError, getInventoryListSuccess]);


	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	const refreshInventories = useCallback(() => {
		const params: any = {};
		if (location.state?.propertyId) {
			params.propertyId = location.state?.propertyId;
		}
		if (location.state?.selectedInventory) {
			params.propertyId = location.state?.selectedInventory.propertyId;
		}
		getInventoryList(params);
	}, [getInventoryList, location]);



	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (removeInventorySuccess) {
			toast.success("Inventory removed successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetInventory();
			refreshInventories();
		}
		if (updateInventoryError) {
			toast.error(updateInventoryError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
	}, [removeInventorySuccess, updateInventoryError, resetInventory, refreshInventories]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////
	const removeData = useCallback((values) => {
		const data = {
			id: values.key,
			isDeleted: true
		}
		updateInventory(data);

	}, [updateInventory]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className="inventory-home">
			<div className="flex justify-between">
				{!selectedProperty && <p className="text-2xl mb-10"></p>}
				{/* {selectedProperty && <Breadcrumb className="mb-10">
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: location.state?.selectedBrand } }}>{location.state?.selectedBrand?.name}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/property-management", state: { selectedProperty: location.state?.selectedBrand, isBack: true } }}>Property Management</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/property-management", state: { selectedProperty: selectedProperty } }}>{selectedProperty?.building} - {selectedProperty?.city}</Link></Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshInventories(); setSelectedInventory(null) }}>Inventory Management</Link>
					</Breadcrumb.Item>
					{selectedInventory && <Breadcrumb.Item>{selectedInventory.configuration}</Breadcrumb.Item>}
					{(formOpen && !selectedInventory) && <Breadcrumb.Item>New Inventory</Breadcrumb.Item>}
				</Breadcrumb>} */}

				{/* brand to property with inventory */}
				{!formOpen && selectedProperty && !allInOnePropertyBreadCrumb && <Breadcrumb className="mb-10">
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: location.state?.selectedBrand } }}>{location.state?.selectedBrand?.name}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/property-management", state: { selectedProperty: selectedProperty, isBack: true, allInOnePropertyBreadCrumb : allInOnePropertyBreadCrumb } }}>Property Management</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/property-management", state: { selectedProperty: selectedProperty, allInOnePropertyBreadCrumb : allInOnePropertyBreadCrumb } }}>{selectedProperty?.propertyName} - {selectedProperty?.city}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item active={(formOpen) ? false : true}>
						<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshInventories(); }}>Inventory Management</Link>
					</Breadcrumb.Item>
					{/* {(formOpen && !selectedInventory) && <Breadcrumb.Item active>New Inventory</Breadcrumb.Item>} */}
				</Breadcrumb>}

				{/* direct property management */}
				{!formOpen && selectedProperty && allInOnePropertyBreadCrumb && <Breadcrumb className="mb-10">
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/brand-property-management" }}>Property Management</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/property-management", state: { allInOnePropertyBreadCrumb : allInOnePropertyBreadCrumb, selectedProperty: selectedProperty } }}>{selectedProperty?.propertyName} - {selectedProperty?.city}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item active={(formOpen) ? false : true}>
						<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshInventories(); }}>Inventory Management</Link>
					</Breadcrumb.Item>
					{/* {(formOpen && !selectedInventory) && <Breadcrumb.Item active>New Inventory</Breadcrumb.Item>} */}
				</Breadcrumb>}

				{
					!hideButton ? <Button hidden={formOpen} onClick={() => { setFormOpen(true); setInventoryId(null); setPropertyId(null); }} id='routing-button' style={routingButton} className="btn btn-primary"><b>Create New Inventory</b></Button>
						: !formOpen && <div style={{ fontSize: '20px', marginTop: '0px', fontWeight: 'bold', color: '#fAC90E' }} >You have reached your maximum inventory </div>
				}
			</div>
			{!formOpen && <InventoryList loadForm={loadForm} removeData={removeData} inventoryList={inventoryList} onInventorySelect={(data) => { }} />}
			{formOpen && <InventoryForm allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} inventoryId={inventoryId} propertyId={propertyId} setFormOpen={setFormOpen} selectedProperty={selectedProperty} selectedBrand={location.state?.selectedBrand} loadData={loadData} setHideButton={setHideButton} />}
		</div>
	)
};

export default InventoryHome;