import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from 'antd';

import { useStoreActions, useStoreState } from 'easy-peasy';
import { Row, Col, Container, Form, Button, Spinner } from 'react-bootstrap';
import { formButton } from '../../../../common/components-style';
import { Controller, useForm } from 'react-hook-form';
import { PropertySustainabilityFormInput } from '../../../../common/interfaces';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
// import { HelpModal } from '../../../common/HelpModal';
import { CommonAnswer, UserAccess } from '../../../../common/constants';
import { canBrandPropertyModuleWrite } from '../../../../common/functions';
import BreadCrumbPage from '../../../common/BreadCrumbPage';

const plainOptions = [{ label: 'Tram', value: 'Tram', },
{ label: 'Bus', value: 'Bus' }, { label: 'Underground/Subway', value: 'Underground/Subway', },
{ label: 'Train', value: 'Train' },

]


const PropertySustainability: React.FC<any> = ({ allInOnePropertyBreadCrumb, brandId, setFormOpen, propertyId, selectedBrand,tab }): JSX.Element => {

    ///////////////////// initialize form ////////////////////////////////////// 
    const { register, handleSubmit, formState: { errors }, control } = useForm<PropertySustainabilityFormInput>();

    ///////////////////////// local state manage ////////////////////////////////////
    const [propertySustainabilityData, setPropertySustainabilityData] = useState<any>();
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [propertySustainabilityId, setPropertySustainabilityId] = useState<any>(null);
    const [energySupplied, setEnergySupplied] = useState<boolean>(false);
    const [isGuestTravel, setIsGuestTravel] = useState<boolean>(false);
    const [naturalFibre, setNaturalFibre] = useState<boolean>(false);
    const [provideDigitalMeans, setProvideDigitalMeans] = useState<boolean>(false);
    const [transport, setTransport] = useState<boolean>(false);
    const [motionSense, setMotionSense] = useState<boolean>(false);
    const [propertyData, setPropertyData] = useState<any>();
    const [propertyIndependentBody, setPropertyIndependentBody] = useState<boolean>(false);

    /////////////////////////// global state manage (actions)//////////////////////////////////////////////////////
    const { getPropertySustainabilityByPropertyId, createPropertySustainability, updatePropertySustainability, getProperty } = useStoreActions<any>((actions) => ({
        getPropertySustainabilityByPropertyId: actions.property.getPropertySustainabilityByPropertyId,
        createPropertySustainability: actions.property.createPropertySustainability,
        updatePropertySustainability: actions.property.updatePropertySustainability,
        getProperty: actions.property.getProperty,
    }));

    /////////////////////////// global state manage (state)//////////////////////////////////////////////////////
    const {

        getPropertySustainabilityByPropertyIdSuccess,
        createPropertySustainabilitySuccess, createPropertySustainabilityError,
        updatePropertySustainabilitySuccess, updatePropertySustainabilityError,
        getPropertySuccess
    } = useStoreState<any>((state) => ({
        getPropertySustainabilityByPropertyIdSuccess: state.property.getPropertySustainabilityByPropertyIdSuccess,
        createPropertySustainabilitySuccess: state.property.createPropertySustainabilitySuccess,
        createPropertySustainabilityError: state.property.createPropertySustainabilityError,
        updatePropertySustainabilitySuccess: state.property.updatePropertySustainabilitySuccess,
        updatePropertySustainabilityError: state.property.updatePropertySustainabilityError,
        getPropertySuccess: state.property.getPropertySuccess,
    }));

    //////////////////////////////// call apis using actions //////////////////////////////////////////////////////
    useEffect(() => {
        if (propertyId) {
            getProperty(propertyId);
            getPropertySustainabilityByPropertyId(propertyId);
        }

    }, [getProperty, getPropertySustainabilityByPropertyId, propertyId]);



    const scrollToTop = () => {
        // messagesRef?.current?.scrollIntoView(({ behavior: "smooth", block: "start", inline: "start" }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    //////////////////////////////// get api response using state //////////////////////////////////////////////////////
    useEffect(() => {


        if (getPropertySuccess) {
            setPropertyData(getPropertySuccess.data);

        }

        if (getPropertySustainabilityByPropertyIdSuccess) {
            if (getPropertySustainabilityByPropertyIdSuccess.data) {
                setPropertySustainabilityId(getPropertySustainabilityByPropertyIdSuccess.data._id);
                setPropertySustainabilityData(getPropertySustainabilityByPropertyIdSuccess.data);


                if (getPropertySustainabilityByPropertyIdSuccess.data?.isYourEnergySuppliedFromSources === CommonAnswer.YES) {
                    setEnergySupplied(true);
                } else {
                    setEnergySupplied(false);
                }


                if (getPropertySustainabilityByPropertyIdSuccess.data?.doYouProvideNaturalFibreSheet === CommonAnswer.YES) {
                    setNaturalFibre(true);
                } else {
                    setNaturalFibre(false);
                }


                if (getPropertySustainabilityByPropertyIdSuccess.data?.areGuestsToTravelAnotherBuilding === CommonAnswer.YES) {
                    setIsGuestTravel(true);
                } else {
                    setIsGuestTravel(false);
                }
                if (getPropertySustainabilityByPropertyIdSuccess.data?.doYouHaveMotionSensing === CommonAnswer.YES) {
                    setMotionSense(true);
                } else {
                    setMotionSense(false);
                }

                if (getPropertySustainabilityByPropertyIdSuccess.data?.doYouProvideDigitalMeansForGuest === CommonAnswer.YES) {
                    setProvideDigitalMeans(true);
                } else {
                    setProvideDigitalMeans(false);
                }

                if (getPropertySustainabilityByPropertyIdSuccess.data?.isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody === CommonAnswer.YES) {
                    setPropertyIndependentBody(true);
                } else {
                    setPropertyIndependentBody(false);
                }

                if (getPropertySustainabilityByPropertyIdSuccess.data?.isYourBuildingClosePublicTransport === CommonAnswer.YES) {
                    setTransport(true);
                } else {
                    setTransport(false);
                }

            }
        }

        if (createPropertySustainabilitySuccess) {
            setLoading(false);
            toast.success("Property sustainability created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            scrollToTop();
            getPropertySustainabilityByPropertyId(propertyId);
        }

        if (createPropertySustainabilityError) {
            setLoading(false);
            toast.error(createPropertySustainabilityError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (updatePropertySustainabilitySuccess) {
            setLoading(false);
            toast.success('Property sustainability update successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            scrollToTop();
            getPropertySustainabilityByPropertyId(propertyId);
        }

        if (updatePropertySustainabilityError) {
            setLoading(false);
            toast.error(updatePropertySustainabilityError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [createPropertySustainabilityError, createPropertySustainabilitySuccess, getPropertySuccess, getPropertySustainabilityByPropertyId, getPropertySustainabilityByPropertyIdSuccess, propertyId, updatePropertySustainabilityError, updatePropertySustainabilitySuccess]);

    //////////////////////////////////whatYouProvideForTheGuest validation manage ///////////////////////////
    const onChangeEnergy = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setEnergySupplied(true);
        } else {
            setEnergySupplied(false);
        }
    }, []);


    const onChangeTravel = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setIsGuestTravel(true);
        } else {
            setIsGuestTravel(false);
        }
    }, []);

    const onChangeDigitalMeans = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setProvideDigitalMeans(true);
        } else {
            setProvideDigitalMeans(false);
        }
    }, []);

    //////////////////////////////////whatYouProvideForTheGuest validation manage ///////////////////////////
    const onChangeFibre = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setNaturalFibre(true);
        } else {
            setNaturalFibre(false);
        }
    }, []);
    //////////////////////////////////whatYouProvideForTheGuest validation manage ///////////////////////////
    const onChangeTransport = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setTransport(true);
        } else {
            setTransport(false);
        }
    }, []);

    /////////////////////////////////////////////////////////////
    const onChangeMotion = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setMotionSense(true);
        } else {
            setMotionSense(false);
        }
    }, []);

    /////////////////////////////////////////////////////////////
    const onChangePropertyIndependentBody = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setPropertyIndependentBody(true);
        } else {
            setPropertyIndependentBody(false);
        }
    }, []);

    //////////////////////////////////////////////// Submit ///////////////////////////////////////////////////
    const onFinish = useCallback((values) => {
        if (!propertyId) {
            toast.error('Please create an property', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        setLoading(true);
        const createUpdateValues = {
            propertyId: propertyId,
            doYouProvideConsumableProducts: values.doYouProvideConsumableProducts,
            doYouProvideSustainableTeaOptions: values.doYouProvideSustainableTeaOptions,
            doYouProvideNaturalBiodegradablePackage: values.doYouProvideNaturalBiodegradablePackage,
            doYouProvideNaturalFibreSheet: values.doYouProvideNaturalFibreSheet,
            pleaseProvideDetailsForNaturalFibre: values.pleaseProvideDetailsForNaturalFibre,
            doYouDisplayProminentOptions: values.doYouDisplayProminentOptions,
            doYouOperateKeylessSystemWithPlasticCards: values.doYouOperateKeylessSystemWithPlasticCards,
            areGuestsToTravelAnotherBuilding: values.areGuestsToTravelAnotherBuilding,
            wouldGuestAbleToWalkToBuilding: values.wouldGuestAbleToWalkToBuilding,
            doYouDisplayRemindersBeforeTheyLeave: values.doYouDisplayRemindersBeforeTheyLeave,
            doYouProvideDigitalMeansForGuest: values.doYouProvideDigitalMeansForGuest,
            pleaseProvideDetailsForTechnology: values.pleaseProvideDetailsForTechnology,
            doYouHaveMotionSensing: values.doYouHaveMotionSensing,
            pleaseProvideDetailsForMotion: values.pleaseProvideDetailsForMotion,
            isYourEnergySuppliedFromSources: values.isYourEnergySuppliedFromSources,
            pleaseProvideDetailsForEnergy: values.pleaseProvideDetailsForEnergy,
            whatIsYourEnergyPerformanceCertificate: values.whatIsYourEnergyPerformanceCertificate,
            doesYourAccommodationHaveEnergyEfficient: values.doesYourAccommodationHaveEnergyEfficient,
            areAppliancesLeftOccupied: values.areAppliancesLeftOccupied,
            isYourBuildingClosePublicTransport: values.isYourBuildingClosePublicTransport,
            whatIsPublicTransportEasyReach: values.whatIsPublicTransportEasyReach,
            isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody: values.isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody,
            pleaseAdviceWhichIndependentBody: values.pleaseAdviceWhichIndependentBody
        }

        if (propertySustainabilityId) {
            Object.assign(createUpdateValues, { propertySustainabilityId });
            delete createUpdateValues.propertyId;
            updatePropertySustainability(createUpdateValues)
        } else {
            createPropertySustainability(createUpdateValues);
        }


    }, [createPropertySustainability, propertyId, propertySustainabilityId, updatePropertySustainability]);

    const changeFormEditable = useCallback(() => {
        scrollToTop();
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);

    /////////////// transport /////////////////
    const renderTransport = useCallback(() => {
        if (plainOptions) {

            return plainOptions.map((at: any, key: number) => {
                return (
                    <Row className="mt-3" key={key}>
                        <Col md={2}>

                        </Col>
                        <Col md={10} >
                            <div style={{ marginTop: "2%", marginLeft: '-21%' }}>
                                <Checkbox value={`${at.value}`}></Checkbox> <span style={{ marginLeft: '3%' }}>{at.label}</span>
                            </div>
                        </Col>
                    </Row>
                );
            });
        }
    }, []);

    /////////////////////////////////////////////////////////////////////////////////////////////
    const renderShowTransport = useCallback(() => {

        if (propertySustainabilityData?.whatIsPublicTransportEasyReach) {

            return propertySustainabilityData?.whatIsPublicTransportEasyReach.map((at: any, key: number) => {
                return (
                    <>
                        <Row className="mt-3" key={key}>

                            <Col md={10}>
                                <div style={{ marginLeft: '-2%' }}>
                                    <span style={{ marginLeft: '2%' }}>{at}</span>
                                </div>
                            </Col>
                        </Row>
                    </>
                );
            });
        }
    }, [propertySustainabilityData?.whatIsPublicTransportEasyReach]);


    return (

        <div style={{ marginTop: "-2%", paddingTop: "4px" }}>
             <div className="content-title fixed_title">
            {
                <BreadCrumbPage allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} tab={tab} isPageName="Property" selectedBrand={selectedBrand} propertyData={propertyData} setFormOpen={setFormOpen} ></BreadCrumbPage>
            }
            <div className='edit_btn_wpr'>
                <div className='d-flex align-items-center justify-content-end gx-2'>
                    {/* <HelpModal /> */}
                    {
                        !loading && !editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) && <Button id='edit-button' className='mb-0' style={formButton} onClick={changeFormEditable} >Edit</Button>
                    }
                </div>
            </div>
            </div>
            <Container fluid className="p-0">
                <div className="jumbotron">
                    <Form onSubmit={handleSubmit(onFinish)}>
                        <Row>
                            <Col md={12}>


                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Is the property approved or accredited for sustainability by an independent body?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody"
                                                defaultValue={propertySustainabilityData?.isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePropertyIndependentBody(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePropertyIndependentBody(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePropertyIndependentBody(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePropertyIndependentBody(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                {propertyIndependentBody && <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Please advise which independent body</Form.Label>
                                    </Col>

                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.pleaseAdviceWhichIndependentBody}</p>}

                                        {editEnable && <Form.Control as="textarea" rows={4} placeholder={"please enter a text"} className={`${errors.pleaseAdviceWhichIndependentBody ? 'is-invalid' : ''}`} defaultValue={propertySustainabilityData?.pleaseAdviceWhichIndependentBody} {...register("pleaseAdviceWhichIndependentBody", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input details!</div>}
                                    </Col>
                                </Row>}
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you provide consumable products in alternative packaging to plastic?
                                        </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.doYouProvideConsumableProducts}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouProvideConsumableProducts"
                                                defaultValue={propertySustainabilityData?.doYouProvideConsumableProducts}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.doYouProvideConsumableProducts === 'Yes-all'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes-all'}
                                                                    name="doYouProvideConsumableProducts"
                                                                    label={'Yes-all'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes-all'}
                                                                    name="doYouProvideConsumableProducts"
                                                                    label={'Yes-all'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.doYouProvideConsumableProducts === 'Yes-some'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes-some'}
                                                                    name="doYouProvideConsumableProducts"
                                                                    label={'Yes-some'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes-some'}
                                                                    name="doYouProvideConsumableProducts"
                                                                    label={'Yes-some'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.doYouProvideConsumableProducts === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideConsumableProducts"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideConsumableProducts"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouProvideConsumableProducts"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you provide sustainable tea and coffee options?
                                        </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.doYouProvideSustainableTeaOptions}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouProvideSustainableTeaOptions"
                                                defaultValue={propertySustainabilityData?.doYouProvideSustainableTeaOptions}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.doYouProvideSustainableTeaOptions === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideSustainableTeaOptions"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideSustainableTeaOptions"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.doYouProvideSustainableTeaOptions === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideSustainableTeaOptions"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideSustainableTeaOptions"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouProvideSustainableTeaOptions"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>}Do you provide natural and organic free-trade soaps and shampoos from certified green manufacturers in biodegradable packaging?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.doYouProvideNaturalBiodegradablePackage}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouProvideNaturalBiodegradablePackage"
                                                defaultValue={propertySustainabilityData?.doYouProvideNaturalBiodegradablePackage}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.doYouProvideNaturalBiodegradablePackage === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideNaturalBiodegradablePackage"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideNaturalBiodegradablePackage"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.doYouProvideNaturalBiodegradablePackage === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideNaturalBiodegradablePackage"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideNaturalBiodegradablePackage"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouProvideNaturalBiodegradablePackage"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>}Do you provide natural fibre sheets and furniture?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.doYouProvideNaturalFibreSheet}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouProvideNaturalFibreSheet"
                                                defaultValue={propertySustainabilityData?.doYouProvideNaturalFibreSheet}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.doYouProvideNaturalFibreSheet === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideNaturalFibreSheet"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeFibre(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideNaturalFibreSheet"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeFibre(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.doYouProvideNaturalFibreSheet === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideNaturalFibreSheet"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeFibre(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideNaturalFibreSheet"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeFibre(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouProvideNaturalFibreSheet"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>


                                {naturalFibre && <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Please provide details</Form.Label>
                                    </Col>

                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.pleaseProvideDetailsForNaturalFibre}</p>}

                                        {editEnable && <Form.Control as="textarea" rows={4} placeholder={"please enter a text"} className={`${errors.pleaseProvideDetailsForNaturalFibre ? 'is-invalid' : ''}`} defaultValue={propertySustainabilityData?.pleaseProvideDetailsForNaturalFibre} {...register("pleaseProvideDetailsForNaturalFibre", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input overview!</div>}
                                    </Col>
                                </Row>}

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you display, in a prominent position, options to recycle waste products?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.doYouDisplayProminentOptions}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouDisplayProminentOptions"
                                                defaultValue={propertySustainabilityData?.doYouDisplayProminentOptions}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.doYouDisplayProminentOptions === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouDisplayProminentOptions"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouDisplayProminentOptions"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.doYouDisplayProminentOptions === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouDisplayProminentOptions"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouDisplayProminentOptions"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouDisplayProminentOptions"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you operate a keyless system with plastic cards?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.doYouOperateKeylessSystemWithPlasticCards}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouOperateKeylessSystemWithPlasticCards"
                                                defaultValue={propertySustainabilityData?.doYouOperateKeylessSystemWithPlasticCards}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.doYouOperateKeylessSystemWithPlasticCards === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouOperateKeylessSystemWithPlasticCards"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouOperateKeylessSystemWithPlasticCards"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.doYouOperateKeylessSystemWithPlasticCards === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouOperateKeylessSystemWithPlasticCards"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouOperateKeylessSystemWithPlasticCards"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouOperateKeylessSystemWithPlasticCards"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Are guests expected to travel to another building to collect keys?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.areGuestsToTravelAnotherBuilding}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="areGuestsToTravelAnotherBuilding"
                                                defaultValue={propertySustainabilityData?.areGuestsToTravelAnotherBuilding}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.areGuestsToTravelAnotherBuilding === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="areGuestsToTravelAnotherBuilding"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTravel(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="areGuestsToTravelAnotherBuilding"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTravel(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.areGuestsToTravelAnotherBuilding === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="areGuestsToTravelAnotherBuilding"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTravel(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="areGuestsToTravelAnotherBuilding"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTravel(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="areGuestsToTravelAnotherBuilding"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>


                                {isGuestTravel &&

                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Would the guest be able to walk to this building in less then 2 minutes?</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{propertySustainabilityData?.wouldGuestAbleToWalkToBuilding}</p>}
                                            {editEnable &&
                                                <Controller
                                                    name="wouldGuestAbleToWalkToBuilding"
                                                    defaultValue={propertySustainabilityData?.wouldGuestAbleToWalkToBuilding}
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field }) =>
                                                        <>
                                                            {
                                                                propertySustainabilityData?.wouldGuestAbleToWalkToBuilding === 'Yes'
                                                                    ?
                                                                    <Form.Check
                                                                        inline
                                                                        value={'Yes'}
                                                                        name="wouldGuestAbleToWalkToBuilding"
                                                                        label={'Yes'}
                                                                        type="radio"
                                                                        defaultChecked
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                    />
                                                                    :
                                                                    <Form.Check
                                                                        inline
                                                                        value={'Yes'}
                                                                        name="wouldGuestAbleToWalkToBuilding"
                                                                        label={'Yes'}
                                                                        type="radio"
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                    />
                                                            }

                                                            {
                                                                propertySustainabilityData?.wouldGuestAbleToWalkToBuilding === 'No'
                                                                    ?
                                                                    <Form.Check
                                                                        inline
                                                                        value={'No'}
                                                                        name="wouldGuestAbleToWalkToBuilding"
                                                                        label={'No'}
                                                                        type="radio"
                                                                        defaultChecked
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                    />
                                                                    :
                                                                    <Form.Check
                                                                        inline
                                                                        value={'No'}
                                                                        name="wouldGuestAbleToWalkToBuilding"
                                                                        label={'No'}
                                                                        type="radio"
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                    />
                                                            }

                                                        </>
                                                    }
                                                />}
                                            {editEnable &&
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="wouldGuestAbleToWalkToBuilding"
                                                    render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                        </Col>
                                    </Row>}

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you display reminders, in prominent positions, to remind guests to switch off lighting, heating, air conditioning etc before they leave the property?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.doYouDisplayRemindersBeforeTheyLeave}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouDisplayRemindersBeforeTheyLeave"
                                                defaultValue={propertySustainabilityData?.doYouDisplayRemindersBeforeTheyLeave}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.doYouDisplayRemindersBeforeTheyLeave === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouDisplayRemindersBeforeTheyLeave"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouDisplayRemindersBeforeTheyLeave"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.doYouDisplayRemindersBeforeTheyLeave === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouDisplayRemindersBeforeTheyLeave"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouDisplayRemindersBeforeTheyLeave"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouDisplayRemindersBeforeTheyLeave"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you provide digital means for guests to control heating remotely?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.doYouProvideDigitalMeansForGuest}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouProvideDigitalMeansForGuest"
                                                defaultValue={propertySustainabilityData?.doYouProvideDigitalMeansForGuest}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.doYouProvideDigitalMeansForGuest === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideDigitalMeansForGuest"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDigitalMeans(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideDigitalMeansForGuest"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDigitalMeans(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.doYouProvideDigitalMeansForGuest === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideDigitalMeansForGuest"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDigitalMeans(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideDigitalMeansForGuest"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDigitalMeans(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouProvideDigitalMeansForGuest"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                {provideDigitalMeans && <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Please provide details are what technology is provided</Form.Label>
                                    </Col>

                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.pleaseProvideDetailsForTechnology}</p>}

                                        {editEnable && <Form.Control as="textarea" rows={4} placeholder={"please enter a text"} className={`${errors.pleaseProvideDetailsForTechnology ? 'is-invalid' : ''}`} defaultValue={propertySustainabilityData?.pleaseProvideDetailsForTechnology} {...register("pleaseProvideDetailsForTechnology", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input overview!</div>}
                                    </Col>
                                </Row>}

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you have motion sensing outside and inside the property?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.doYouHaveMotionSensing}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouHaveMotionSensing"
                                                defaultValue={propertySustainabilityData?.doYouHaveMotionSensing}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.doYouHaveMotionSensing === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouHaveMotionSensing"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeMotion(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouHaveMotionSensing"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeMotion(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.doYouHaveMotionSensing === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouHaveMotionSensing"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeMotion(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouHaveMotionSensing"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeMotion(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouHaveMotionSensing"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                {motionSense && <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Please provide details</Form.Label>
                                    </Col>

                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.pleaseProvideDetailsForMotion}</p>}

                                        {editEnable && <Form.Control as="textarea" rows={4} placeholder={"please enter a text"} className={`${errors.pleaseProvideDetailsForMotion ? 'is-invalid' : ''}`} defaultValue={propertySustainabilityData?.pleaseProvideDetailsForMotion} {...register("pleaseProvideDetailsForMotion", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input details!</div>}
                                    </Col>
                                </Row>}

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Is your energy supplied from 100% renewable sources?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.isYourEnergySuppliedFromSources}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="isYourEnergySuppliedFromSources"
                                                defaultValue={propertySustainabilityData?.isYourEnergySuppliedFromSources}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.isYourEnergySuppliedFromSources === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isYourEnergySuppliedFromSources"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeEnergy(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isYourEnergySuppliedFromSources"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeEnergy(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.isYourEnergySuppliedFromSources === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isYourEnergySuppliedFromSources"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeEnergy(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isYourEnergySuppliedFromSources"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeEnergy(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="isYourEnergySuppliedFromSources"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                {energySupplied && <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Please provide details</Form.Label>
                                    </Col>

                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.pleaseProvideDetailsForEnergy}</p>}

                                        {editEnable && <Form.Control as="textarea" rows={4} placeholder={"please enter a text"} className={`${errors.pleaseProvideDetailsForEnergy ? 'is-invalid' : ''}`} defaultValue={propertySustainabilityData?.pleaseProvideDetailsForEnergy} {...register("pleaseProvideDetailsForEnergy", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input overview!</div>}
                                    </Col>
                                </Row>}

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{<span className='requiredMark'>*</span>}What is your Energy Performance Certificate / ESOS certificate rating? If you don’t know, please advise 'Not available’</Form.Label>
                                    </Col>

                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.whatIsYourEnergyPerformanceCertificate}</p>}

                                        {editEnable && <Form.Control as="textarea" rows={4} placeholder={"please enter a text"} className={`${errors.whatIsYourEnergyPerformanceCertificate ? 'is-invalid' : ''}`} defaultValue={propertySustainabilityData?.whatIsYourEnergyPerformanceCertificate} {...register("whatIsYourEnergyPerformanceCertificate", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input overview!</div>}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Does your accommodation have energy efficient shower heads?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.doesYourAccommodationHaveEnergyEfficient}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doesYourAccommodationHaveEnergyEfficient"
                                                defaultValue={propertySustainabilityData?.doesYourAccommodationHaveEnergyEfficient}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.doesYourAccommodationHaveEnergyEfficient === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doesYourAccommodationHaveEnergyEfficient"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doesYourAccommodationHaveEnergyEfficient"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.doesYourAccommodationHaveEnergyEfficient === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doesYourAccommodationHaveEnergyEfficient"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doesYourAccommodationHaveEnergyEfficient"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doesYourAccommodationHaveEnergyEfficient"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Are appliances left on standby when the accommodation is not occupied?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.areAppliancesLeftOccupied}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="areAppliancesLeftOccupied"
                                                defaultValue={propertySustainabilityData?.areAppliancesLeftOccupied}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.areAppliancesLeftOccupied === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="areAppliancesLeftOccupied"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="areAppliancesLeftOccupied"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.areAppliancesLeftOccupied === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="areAppliancesLeftOccupied"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="areAppliancesLeftOccupied"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="areAppliancesLeftOccupied"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Is your building close to public transport?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertySustainabilityData?.isYourBuildingClosePublicTransport}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="isYourBuildingClosePublicTransport"
                                                defaultValue={propertySustainabilityData?.isYourBuildingClosePublicTransport}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertySustainabilityData?.isYourBuildingClosePublicTransport === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isYourBuildingClosePublicTransport"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTransport(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isYourBuildingClosePublicTransport"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTransport(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            propertySustainabilityData?.isYourBuildingClosePublicTransport === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isYourBuildingClosePublicTransport"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTransport(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isYourBuildingClosePublicTransport"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTransport(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="isYourBuildingClosePublicTransport"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>


                                {transport && <Row className="formSpace">
                                    <Col md={6} style={{ textAlign: "right" }}>
                                        <Form.Label className="mt-3">{editEnable && <span className='requiredMark'>*</span>} Which transport options are available?
                                        </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: "left" }}>
                                        {!editEnable && renderShowTransport()}
                                        {editEnable && (
                                            <Controller
                                                name="whatIsPublicTransportEasyReach"
                                                defaultValue={propertySustainabilityData?.whatIsPublicTransportEasyReach}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) => (
                                                    <>
                                                        <Checkbox.Group defaultValue={propertySustainabilityData?.whatIsPublicTransportEasyReach} style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
                                                            {renderTransport()}
                                                        </Checkbox.Group>
                                                    </>
                                                )}
                                            />
                                        )}
                                        {editEnable && errors.whatIsPublicTransportEasyReach && (
                                            <div className="invalid-feedback-custom">
                                                Please select a options!
                                            </div>
                                        )}
                                    </Col>
                                </Row>}





                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!loading && editEnable && propertyId &&
                                            <Button type="submit" id='approve-button' style={formButton}> {propertySustainabilityId ? 'Update' : 'Create'} </Button>
                                        }

                                        {
                                            loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </div>
    );
};

export default PropertySustainability;