
import React, { useCallback, useEffect, useState } from 'react';
import { DirectionsRenderer, GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs, } from "react-google-maps";
// import markerIcon from '../../images/icons/marker.png';
import markerIcon from '../../images/icons/svg/outside/Icon-Location-Finder-M.svg';
import propertyDirectIcon from '../../images/icons/svg/outside/Icon-Location-Finder-K.svg';

const BookingGoogleMap: React.FC<any> = ({ location, building, setCoords, travelMode, onResponse }): JSX.Element => {
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [locationName, setLocationName] = useState<any>('');
  const [activeMarker, setActiveMarker] = useState(null);
  const [buildingLat, setBuildingLat] = useState<number>(0);
  const [buildingLng, setBuildingLng] = useState<number>(0);
  const destination = { lat: buildingLat, lng: buildingLng };
  const [directions, setDirections] = useState<any>({});
  const origin = { lat: lat, lng: lng };
  const DirectionsService = new google.maps.DirectionsService();

  const icon = {
    url: markerIcon,
    scaledSize: new google.maps.Size(45, 45)
  };


  const buildingDirectIcon = {
    url: propertyDirectIcon,
    scaledSize: new google.maps.Size(45, 45)
  };

  DirectionsService.route(
    {
      origin: origin,
      destination: destination,
      travelMode: travelMode
    },
    (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        setDirections(result);
        onResponse(result)
      }
    }
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (location) {
      setLat(location.lat);
      setLng(location.lng);
      setBuildingLat(building.lat);
      setBuildingLng(building.lng);
      setLocationName(location.locationName);
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }

  }, [building, location]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onDragEnd = useCallback(({ latLng }) => {
    setLat(latLng.lat());
    setLng(latLng.lng());
    setCoords({ lat: latLng.lat(), lng: latLng.lng() })
  }, [setCoords]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="google-map">
      <GoogleMap
        key={Math.random()}
        defaultZoom={10}
        defaultCenter={{ lat, lng }}
        onClick={() => setActiveMarker(null)}
      >
        {location && <Marker draggable onDragEnd={onDragEnd} position={{ lat, lng }} label={locationName} icon={icon} />}
        {building && building.map(({ id, name, position, address }) => {
          return (
            <Marker
              key={id}
              position={position}
              onClick={() => handleActiveMarker(id)}
              icon={buildingDirectIcon}
            >
              {activeMarker === id ? (
                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  <div>
                    <p>{name}</p>
                    <p>{address}</p>
                  </div>
  
                </InfoWindow>
              ) : null}
            </Marker>
          )
        })}
        <DirectionsRenderer
          directions={directions}
        />
      </GoogleMap>
    </div>
  )
};

export default withScriptjs(withGoogleMap(BookingGoogleMap));