/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';

export default {
  getLoadingOverlay: false,

  // actions
  loadingOverlay: action((state, payload) => ({ getLoadingOverlay: payload })),

  // thunks
  setLoadingOverlay: thunk(async (actions: any, payload: any) => {
    actions.loadingOverlay(payload);
  })
};
