import React, { } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import { $mysaBlue, $mysaMegenta, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, tableStyles } from '../../../../../../common/components-style';
import rate from '../../../../../../images/icons/svg/rate-table-header-icon.svg';
import ViewEdit from '../../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { rfpPropertyStatusToInventory } from '../../../../../../common/constants';
////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const ClientRatesListView: React.FC<any> = ({ setEnableRatesScreen, setRates, inventoryRates, property }): JSX.Element => {

  const secondColumn = () => {
    return (<>
      1 - 7 <img src={rate} alt="rate" />
    </>)
  };

  const thirdColumn = () => {
    return (<>
      8-14 <img src={rate} alt="rate" />
    </>)
  };

  const forthColumn = () => {
    return (<>
      15-28 <img src={rate} alt="rate" />
    </>)
  };

  const fifthColumn = () => {
    return (<>
      29-90 <img src={rate} alt="rate" />
    </>)
  };

  const sixthColumn = () => {
    return (<>
      91+ <img src={rate} alt="rate" />
    </>)
  };

  const columns = [
    {
      name: '',
      selector: row => row.inventoryDetails[0].room_type.name,
    },

    {
      name: secondColumn(),
      cell: (row) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>{property.currency} {row.rateRFP.corporateRate.standardRate.oneTo7 || row.rateRFP.corporateRate.standardOldRate.oneTo7}</div>
            <div style={{ fontSize: '12px' }}>{property.currency} {row?.AverageRateRFP?.oneTo7}</div>
          </div>
        )
      }
    },
    {
      name: thirdColumn(),
      cell: (row) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>{property.currency} {row.rateRFP.corporateRate.standardRate.eightTo14 || row.rateRFP.corporateRate.standardOldRate.eightTo14}</div>
            <div style={{ fontSize: '12px' }}>{property.currency} {row?.AverageRateRFP?.eightTo14}</div>
          </div>
        )
      }
    },
    {
      name: forthColumn(),
      cell: (row) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>{property.currency} {row.rateRFP.corporateRate.standardRate.fifteenTo28 || row.rateRFP.corporateRate.standardOldRate.fifteenTo28}</div>
            <div style={{ fontSize: '12px' }}>{property.currency} {row?.AverageRateRFP?.fifteenTo28}</div>
          </div>
        )
      }
    },
    {
      name: fifthColumn(),
      cell: (row) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>{property.currency} {row.rateRFP.corporateRate.standardRate.twentyNineTo90 || row.rateRFP.corporateRate.standardOldRate.twentyNineTo90}</div>
            <div style={{ fontSize: '12px' }}>{property.currency} {row?.AverageRateRFP?.twentyNineTo90}</div>
          </div>
        )
      }
    },
    {
      name: sixthColumn(),
      cell: (row) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>{property.currency} {row.rateRFP.corporateRate.standardRate.ninetyOnePlus || row.rateRFP.corporateRate.standardOldRate.ninetyOnePlus}</div>
            <div style={{ fontSize: '12px' }}>{property.currency} {row?.AverageRateRFP?.ninetyOnePlus}</div>
          </div>
        )
      }
    },
    {
      name: 'Status',
      cell: (row) => {
        let color = "";
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.PROPOSED) color = $mysaYellow;
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.ACCEPTED) color = $mysaTeal;
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.DECLINED) color = $mysaMegenta;
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.REVIEW) color = $mysaYellow;
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.REVIEWED) color = $mysaBlue;
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.NEGOTIATE) color = $mysaYellow;
        return (
          <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.rfpPropertyRateStatus}</span>
        )
      }
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <>
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
            >
              <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                setRates(row);
                setEnableRatesScreen(true);
              }} />
            </OverlayTrigger>
          </>
        );
      }
    }
  ];

  return (
    <>
      <div className='mt-3'>
        <DataTable
          columns={columns}
          data={inventoryRates}
          pagination
          striped={true}
          customStyles={tableStyles}
          theme='mysaTable'
          fixedHeader={true}
          noDataComponent={'Updating records'}
        />
        <div style={{ marginTop: "110px", opacity: '0.5' }}>Rates displayed against the average of all properties submitted</div>
      </div>
    </>
  );
}

export default ClientRatesListView;