import { FC, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { navContentStyle, tabDivStyle, navStyle } from "../../../../common/components-style";
import SubmittedBrandAdminBrandEvaluationList from "./SubmittedRfpBrandAdminBrandEvaluationList";
import BrandAdminSubmittedRfpPropertiesHomePage from "./BrandAdminSubmittedRfpPropertiesHomePage";
import SubmittedRfpSubmission from "./SubmittedRfpSubmission";
import BrandAdminSubmittedPropertyEvaluationList from "./BrandAdminSubmittedRfpPropertyEvaluation";
import BrandAdminSubmittedRfpPropertyRatesList from "./BrandAdminSubmittedRfpPropertyRates";
import SubmittedBrandAdminBrandDetailPage from "./SubmittedBrandAdminBrandDetailPage";


const BrandSubmittedRfpDetails: FC<any> = (): JSX.Element => {
    const location: any = useLocation();
    const [componentName, setComponentName] = useState(location?.state?.component ? location?.state?.component : 'singleMyRfp');
    const [defaultTab, setDefaultTab] = useState<any>(location?.state?.defaultKey ? location?.state?.defaultKey : 'detail');
    const [defaultTabProperty, setDefaultTabProperty] = useState<any>(location?.state?.defaultKey ? location?.state?.defaultKey : 'properties');
    const [property, setProperty] = useState(location?.state?.property ? location?.state?.property : null);


    return (
        <div style={tabDivStyle} >

            {componentName === 'singleMyRfp' &&
                <Tab.Container defaultActiveKey={defaultTab} key={defaultTab} activeKey={defaultTab} onSelect={(tab) => setDefaultTab(tab)} >
                    <Row >
                        <Col >
                            <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                                <Nav.Item>
                                    <Nav.Link eventKey="detail">Detail</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="properties">Properties</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="brandEvaluation">Brand Evaluation</Nav.Link>
                                </Nav.Item>
                               
                                <Nav.Item>
                                    <Nav.Link eventKey="submissionManagement">Submission Management</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={10} >
                            <Tab.Content>
                            <Tab.Pane eventKey="detail" >
                                    <div style={navContentStyle}>
                                        <SubmittedBrandAdminBrandDetailPage
                                            programmeData={location?.state?.programmeData ? location?.state?.programmeData : location?.state}
                                            data={location?.state?.programmeData ? location?.state?.programmeData : location?.state} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="brandEvaluation" >
                                    <div style={navContentStyle}>
                                        <SubmittedBrandAdminBrandEvaluationList
                                            programmeData={location?.state?.programmeData ? location?.state?.programmeData : location?.state}
                                            data={location?.state?.programmeData ? location?.state?.programmeData : location?.state} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="properties" >
                                    <div style={navContentStyle}>
                                        <BrandAdminSubmittedRfpPropertiesHomePage
                                            setComponentName={setComponentName}
                                            setProperty={setProperty}
                                            setDefaultTab={setDefaultTab}
                                            data={location?.state?.programmeData ? location?.state?.programmeData : location?.state}
                                        />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="submissionManagement" >
                                    <div style={navContentStyle}>
                                        <SubmittedRfpSubmission myRfpsData={location?.state?.programmeData ? location?.state?.programmeData : location?.state} />
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            }
            {
                componentName === 'Properties' && <Tab.Container defaultActiveKey={defaultTabProperty} key={defaultTabProperty} activeKey={defaultTabProperty} onSelect={(tab) => setDefaultTabProperty(tab)}>
                    <Row>
                        <Col sm={2} >
                            <Nav variant="pills" className="flex-column secondry-menu " style={navStyle}>
                                <Nav.Item
                                // onClick={() => setFormOpen(false)}
                                >
                                    <Nav.Link eventKey="properties">Proposed Rates</Nav.Link>
                                </Nav.Item>
                                <Nav.Item
                                // onClick={() => setMyRfpFormOpen(false)}
                                >
                                    <Nav.Link eventKey="propertyEvaluation">Property Evaluation</Nav.Link>
                                </Nav.Item>

                                {/* <Nav.Item>
                                    <Nav.Link eventKey="submissionManagement">Submission Management</Nav.Link>
                                </Nav.Item> */}
                            </Nav>
                        </Col>
                        <Col sm={10} >
                            <Tab.Content>
                                <Tab.Pane eventKey="propertyEvaluation" >
                                    <div style={navContentStyle}>
                                        <BrandAdminSubmittedPropertyEvaluationList setDefaultTabProperty={setDefaultTabProperty} programmeData={location?.state?.programmeData ? location?.state?.programmeData : location?.state} setComponentName={setComponentName} setDefaultTab={setDefaultTab} property={property} myRfpsData={location?.state?.programmeData ? location?.state?.programmeData : location?.state} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="properties" >
                                    <div style={navContentStyle}>
                                        <BrandAdminSubmittedRfpPropertyRatesList setDefaultTabProperty={setDefaultTabProperty} setComponentName={setComponentName} setDefaultTab={setDefaultTab} property={property} myRfpsData={location?.state?.programmeData ? location?.state?.programmeData : location?.state} />
                                    </div>
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="submissionManagement" >
                                    <div style={navContentStyle}>
                                        <RfpSubmission myRfpsData={ location?.state?.programmeData ? location?.state?.programmeData : location?.state} />
                                    </div>
                                </Tab.Pane> */}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            }
        </div>
    )
}


export default BrandSubmittedRfpDetails;