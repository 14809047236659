import { FC, useEffect, useState } from "react";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import DataTable from "react-data-table-component";
import { tableStyles, $mysaTeal, $mysaMegenta, selectStyles, $mysaYellow } from "../../../../common/components-style";
import { Col, Row } from "react-bootstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import { BrandRfpPropertyEvaluationStatus, brandRfpStatus } from "../../../../common/constants";
import Select from 'react-select';
import { toast } from "react-toastify";

const SubmissionOverview: FC<any> = ({ programmeId, rfpProcessId, selectedClient, selectedProgramme, startDate ,selectedClientNames}) => {
    const [selectedClientName, setSelectedClientName] = useState<string>("");
    const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");
    const [brandOption, setBrandOption] = useState<Array<any>>([]);
    const [locationOption, setLocationOption] = useState<Array<any>>([]);
    const [submissionData, setSubmissionData] = useState<Array<any>>([]);
    const [submissionDataTemp, setSubmissionDataTemp] = useState<Array<any>>([]);
    const [brandId, setBrandId] = useState<String>("");
    const [locationId, setLocationId] = useState<String>("");
    ///////////////////////////////////////////////////////////////////////////////////////

    const {
        getBrandLocationByRfpId,
        getSubmissionOverviewByRfpId
    } = useStoreActions<any>((actions) => ({
        getBrandLocationByRfpId: actions.rfpProcess.getBrandLocationByRfpId,
        getSubmissionOverviewByRfpId: actions.rfpProcess.getSubmissionOverviewByRfpId,
    }));

    const {
        getCorporateProgrammeByIdSuccess,
        getBrandLocationByRfpIdSuccess,
        getBrandLocationByRfpIdError,
        getSubmissionOverviewByRfpIdSuccess,
        getSubmissionOverviewByRfpIdError
    } = useStoreState<any>((state) => ({
        getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
        getBrandLocationByRfpIdSuccess: state.rfpProcess.getBrandLocationByRfpIdSuccess,
        getBrandLocationByRfpIdError: state.rfpProcess.getBrandLocationByRfpIdError,
        getSubmissionOverviewByRfpIdSuccess: state.rfpProcess.getSubmissionOverviewByRfpIdSuccess,
        getSubmissionOverviewByRfpIdError: state.rfpProcess.getSubmissionOverviewByRfpIdError,
    }));

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const payload = {
            rfpProcessId: rfpProcessId
        }
        getBrandLocationByRfpId(payload);
        getSubmissionOverviewByRfpId(payload);
    }, [getBrandLocationByRfpId, getSubmissionOverviewByRfpId, rfpProcessId]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if(selectedProgramme){
            setSelectedProgrammeName(selectedProgramme?.programmeName);
        }
        if(selectedClientNames){
            setSelectedClientName(selectedClientNames);
        }

        if (getBrandLocationByRfpIdSuccess?.data) {
            if (getBrandLocationByRfpIdSuccess?.data.brands) {
                const options = getBrandLocationByRfpIdSuccess?.data.brands.map((value) => {
                    return {
                        value: `${value.brand._id}`,
                        label: `${value.brand.name}`,
                    };
                });

                // console.log(options,'options');
                options.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    } else if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                setBrandOption(options);
            }

            if (getBrandLocationByRfpIdSuccess?.data.locations) {
                const options = getBrandLocationByRfpIdSuccess?.data.locations.map((value) => {
                    return {
                        value: `${value.id}`,
                        label: `${value.city}`,
                    };
                });
                const filterOptions = options.map(JSON.stringify).reverse() // convert to JSON string the array content, then reverse it (to check from end to begining)
                    .filter((item, index, arr) => arr.indexOf(item, index + 1) === -1) // check if there is any occurence of the item in whole array
                    .reverse().map(JSON.parse) // revert it to original state

                setLocationOption(filterOptions);
            }
        }


        if (getSubmissionOverviewByRfpIdSuccess?.data) {
            // console.log(getSubmissionOverviewByRfpIdSuccess?.data, 'getSubmissionOverviewByRfpIdSuccess?.data');
            getSubmissionOverviewByRfpIdSuccess?.data.sort((a, b) => {
                if (a.brand_name < b.brand_name) {
                    return -1;
                } else if (a.brand_name > b.brand_name) {
                    return 1;
                }
                return 0;
            });

            setSubmissionData(getSubmissionOverviewByRfpIdSuccess?.data);
            setSubmissionDataTemp(getSubmissionOverviewByRfpIdSuccess?.data);
        }

        if (getBrandLocationByRfpIdError) {
            toast.error(getBrandLocationByRfpIdError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (getSubmissionOverviewByRfpIdError) {
            toast.error(getSubmissionOverviewByRfpIdError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [getBrandLocationByRfpIdError, getBrandLocationByRfpIdSuccess?.data, getSubmissionOverviewByRfpIdError, getSubmissionOverviewByRfpIdSuccess?.data, selectedClientNames, selectedProgramme]);

    ////////////////// filter list ///////////////
    useEffect(() => {

        let data;
        if (brandId && !locationId) {
            data = submissionDataTemp.filter((value) => { return value.brand_id === brandId });
            setSubmissionData(data);
        } else if (locationId && !brandId) {
            data = submissionDataTemp.filter((value) => { return value.city_id === locationId });
            setSubmissionData(data);
        } else if (brandId && locationId) {
            data = submissionDataTemp.filter((value) => { return value.city_id === locationId && value.brand_id === brandId });
            setSubmissionData(data);
        } else {
            setSubmissionData(submissionDataTemp);
        }

    }, [brandId, locationId, submissionDataTemp]);

    /////////////////////////////////////////////////
    const onChangeBrand = (value) => {
        if (value?.value) {
            setBrandId(value?.value);
        } else {
            setBrandId("");
        }
    };


    /////////////////////////////////////////////////
    const onChangeLocation = (value) => {
        if (value?.value) {
            setLocationId(value?.value);
        } else {
            setLocationId("");
        }
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const columns = [
        {
            name: 'Brand',
            selector: row => row.brand_name,
        },
        {
            name: 'Property',
            selector: row => row.property_name,
        },
        {
            name: 'Client Location',
            selector: row => row.city_name,
        },
        {
            name: 'Property Status',
            cell: (row) => {
                let color = "";
                if (row.property_status.toString() === BrandRfpPropertyEvaluationStatus.COMPLETE.toString()) {
                    color = $mysaTeal;
                };
                if (row.property_status.toString() === BrandRfpPropertyEvaluationStatus.INCOMPLETE.toString()) {
                    color = $mysaMegenta;
                };
                if (row.property_status.toString() === BrandRfpPropertyEvaluationStatus.IN_PROGRESS.toString()) {
                    color = $mysaYellow;
                };

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.property_status}</span>
                )
            }
        },
        {
            name: 'Brand Submission Status',
            cell: (row) => {
                let color = "";
                if (row.brand_submission_status.toString() === brandRfpStatus.COMPLETED.toString() || row.brand_submission_status.toString() === brandRfpStatus.SUBMITTED.toString()) {
                    color = $mysaTeal;
                };
                if (row.brand_submission_status.toString() === brandRfpStatus.INCOMPLETE.toString()) {
                    color = $mysaMegenta;
                };

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.brand_submission_status}</span>
                )
            }
        },
    ];


    return (
        <>
            {rfpProcessId &&
                <RFPBreadCrumbPage isPageName="RFP" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClientName} selectedProgrammeName={selectedProgrammeName} rfpProcessId={rfpProcessId} programmeId={programmeId} startDate={startDate}></RFPBreadCrumbPage>
            }

            <Row style={{ marginBottom: '2%', marginTop: '4%', paddingLeft: "10px" }}>
                <Col md={2}>
                    <Select
                        className="select_btn search_bar"
                        isClearable
                        placeholder={<div>Brand</div>}
                        options={brandOption}
                        styles={selectStyles}
                        isSearchable={true}
                        onChange={onChangeBrand}
                    />
                </Col>
                <Col md={2}>
                    <Select
                        className="select_btn search_bar"
                        isClearable
                        placeholder={<div>Location</div>}
                        options={locationOption}
                        styles={selectStyles}
                        isSearchable={true}
                        onChange={onChangeLocation}
                    />
                </Col>
                <Col md={4}></Col>
            </Row>
            <DataTable
                columns={columns}
                data={submissionData}
                pagination
                responsive
                customStyles={tableStyles}
                striped={true}
                theme='mysaTable'
                fixedHeader={true}
            />
        </>
    );

}

export default SubmissionOverview