import React from 'react'
import { Spin, AutoComplete } from 'antd';
import debounce from 'lodash/debounce';

const DebounceSearch: React.FC<any> = ({placeholder, fetchOptions, debounceTimeout = 1000, ...props}): JSX.Element => {
	
	const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout]);
	
	return (
		<div>
      <AutoComplete
        options={options}
        {...props}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        // onSelect={onSelect}
        onSearch={debounceFetcher}
        // onChange={onChange}
        placeholder={placeholder?placeholder:""}
      />
		</div>
	)
}

export default DebounceSearch;
