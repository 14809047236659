import {  message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';

import { Row, Col, Image } from 'react-bootstrap';

import checkInIcon from '../../../images/icons/svg/outside/icon-check-in.svg';
import checkOutIcon from '../../../images/icons/svg/outside/icon-check-out.svg';

const TravellerProfile: React.FC<any> = ({ params }): JSX.Element => {
   const [bookingList, setBookingList] = useState<any>();
   const [imageList, setImageList] = useState<any>();
   const [checkInDate, setCheckInDate] = useState<any>("");
   const [checkOutDate, setCheckOutDate] = useState<any>("");
   const [noOfNights, setNoOfNights] = useState<any>();
   ////////////////////////////////////////////////////////////////////////////////////////////////////
   const {
      getAvailabilityAndRateByPropertyIdAndInventoryId,
   } = useStoreActions<any>((actions) => ({
      getAvailabilityAndRateByPropertyIdAndInventoryId: actions.booking.getAvailabilityAndRateByPropertyIdAndInventoryId,
   }));

   ////////////////////////////////////////////////////////////////////////////////////////////////////
   const {
      getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess,
      getAvailabilityAndRateByPropertyIdAndInventoryIdError,
   } = useStoreState<any>((state) => ({
      getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess: state.booking.getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess,
      getAvailabilityAndRateByPropertyIdAndInventoryIdError: state.booking.getAvailabilityAndRateByPropertyIdAndInventoryIdError,

   }));

   ////////////////////////////////////////////////////////////////////////////////////////////////////
   useEffect(() => {
      if (params?.state?.query?.hashGet) {
         setNoOfNights(params?.state?.query?.noOfNights);
         const data: any = {};
         data.cacheKey = params?.state?.query?.cacheKey;
         data.propertyId = params?.state?.query?.propertyId;
         data.inventoryId = params?.state?.query?.inventoryId;
         data.hashGet = params?.state?.query?.hashGet;
         if (params?.state?.query?.requestBookingId) {
            data.requestBookingId = params?.state?.query?.requestBookingId;
            data._reqGroupId = params?.state?.query?._reqGroupId;
            data._reqBrandId = params?.state?.query?._reqBrandId;
            data._reqPropertyId = params?.state?.query?._reqPropertyId;
            data._reqInventoryId = params?.state?.query?._reqInventoryId;
            data.checkInDate = params?.state?.query?.checkInDate;
            data.checkOutDate = params?.state?.query?.checkOutDate;
          }
         getAvailabilityAndRateByPropertyIdAndInventoryId(data);
      }

   }, [getAvailabilityAndRateByPropertyIdAndInventoryId, params]);


   useEffect(() => {

      if (params?.state?.query?.from) {
         let dateText = params?.state?.query?.from;
         let splitText = dateText.split("-");
         let date = new Date(dateText);
         let getMonth = date.toLocaleString('default', { month: 'short' });
         splitText.splice(1, 1, getMonth);
         let arrayReverse = splitText.reverse();
         let getFinalResult = arrayReverse.join(" ");
         setCheckInDate(getFinalResult);
      }
      if (params?.state?.query?.end) {
         let dateText2 = params?.state?.query?.end;
         let splitText2 = dateText2.split("-");
         let date2 = new Date(dateText2);
         let getMonth2 = date2.toLocaleString('default', { month: 'short' });
         splitText2.splice(1, 1, getMonth2);
         let arrayReverse = splitText2.reverse();
         let getFinalResult2 = arrayReverse.join(" ");
         setCheckOutDate(getFinalResult2)
      }
   }, [params]);

   //////////////////////////////////////////////////////////////////////////////////////////////
   useEffect(() => {
      if (getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess) {
         if (getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data) {
            const book: Array<object> = [];
            const image: Array<object> = [];
            const bed: Array<object> = [];
            book.push(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data);

            _.map(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.inventoryImage.image, element => {
               image.push(element)
            })
            _.map(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyInventory.bedType, element => {
               bed.push(element.name)
            });
            setBookingList(book);
            setImageList(image);
         }
      }
      if (getAvailabilityAndRateByPropertyIdAndInventoryIdError) {
         message.error(getAvailabilityAndRateByPropertyIdAndInventoryIdError.message);
      }
   }, [getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess, getAvailabilityAndRateByPropertyIdAndInventoryIdError]);

   ////////////////////////////////////////////////////////////////////////////////////////////////////////////
   const renderImage = useCallback((acc) => {
      if (imageList.length >= 1) {
         return (

            <div>
               <Row >
                  <Col md={12} >
                     <div key={imageList[0]?._id}>
                        <div className="card2  traveler-item bg-auto" >
                           <Image src={imageList[0]?.url} alt="avatar" className='traveler-img rounded-2xl'>
                           </Image>
                        </div>
                     </div>

                     <Row >
                        <Col md={12} id="about-brand" style={{ marginTop: '30px', height: 'auto' }}>
                           <div style={{ marginLeft: '-60px', }}>
                              <div className="">
                                 {/* <div className="" style={{ fontSize: "32px", fontWeight: 'bold', lineHeight: '63px', marginTop: '-40px' }}>{acc.property.propertyName}   by {acc.property.brandId.name}</div> */}
                                 <div
                                    className="propertyDetailName"
                                    style={{
                                       fontSize: "32px",
                                       fontWeight: "bold",
                                       lineHeight: "42px",
                                    }}
                                 >
                                    {acc.property.propertyName}
                                 </div>
                                 <div
                                    className="propertyDetailSubName"
                                    style={{
                                       fontSize: "22px",
                                       fontWeight: "bold",
                                       lineHeight: "32px",
                                       marginBottom: "5px",
                                    }}
                                 >
                                    by {acc.property.brandId.name}
                                 </div>
                              </div>
                              {/* <div className="" style={{ fontSize: "18px", fontWeight: 'bold', lineHeight: '38px', marginTop: '0px' }}> {acc.property?.propertyType?.name} </div> */}
                              <div
                                 className="propertyDetailAddress"
                                 style={{
                                    fontSize: "18px",
                                    lineHeight: "28px",
                                    margin: "8px 0 18px",
                                 }}
                              >
                                 {" "}
                                 <strong>{acc.property?.propertyType?.name}</strong>{" "}

                              </div>

                              <div
                                 className="propertyDetailAddress"
                                 style={{
                                    fontSize: "18px",
                                    lineHeight: "28px",
                                    margin: "8px 0 18px",
                                 }}
                              >
                                 {" "}

                                 {acc.property?.address1} {acc.property?.city}{" "}
                                 {acc.property?.postcode}
                              </div>
                              {/* <Row>
                                 <Col md={8}>
                                    <div className="" style={{ fontSize: "22px", fontWeight: 'normal', lineHeight: '30px', marginTop: '0px' }}> {acc.property?.address1} {acc.property?.city} {acc.property?.postcode} </div>
                                 </Col>

                              </Row> */}

                              <div className='' style={{ marginTop: '20px' }}>
                                 <Image src={acc.property.brandId.image} alt="avatar" style={{ height: '165px', aspectRatio: '1/1', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >
                                 </Image>
                              </div>
                              {/* <div className="" style={{ fontSize: "26px", fontWeight: 'bold', lineHeight: '44px', marginTop: '0px' }}> {acc.propertyInventory.accommodationConfiguration.name} </div> */}
                              <div
                                 className=""
                                 style={{
                                    fontSize: "26px",
                                    fontWeight: "normal",
                                    lineHeight: "36px",
                                    marginTop: "0px",
                                 }}
                              >
                                 {acc.propertyInventory.accommodationDetail}
                              </div>
                              {/* <Row>
                                 <Col md={6}>
                                    <img className='icon' src={renderIcon(acc.propertyCheckInProcess.checkInProcess._id)} alt="" />
                                 </Col>
                                 <Col md={6} style={{ marginLeft: '-125px', marginTop: '5px' }}>
                                    <div ><span className="" style={{ fontSize: "16px", fontWeight: 'bold', lineHeight: '35px' }}>{acc.propertyCheckInProcess?.checkInProcess?.name}</span>
                                       <br />
                                       <span >{acc.propertyCheckInProcess?.additionalInformation}</span> </div>
                                 </Col>
                              </Row> */}

                              {/* <Row>
                                 <Col md={4}>
                                    <img className='icon' src={checkInIcon} alt="" />
                                 </Col>
                                 <Col md={8} style={{ marginLeft: '-60px', marginTop: '0px' }}>
                                    <div ><span className="" style={{ fontSize: "16px", lineHeight: '55px' }}>{'Check-in time  '} <span className="font-bold ml-3">{acc.propertyCheckInProcess?.checkInTime}</span></span>
                                    </div>
                                 </Col>
                              </Row> */}
                              <div className="checkinDetails flex align-items-center mb-2">
                                 <img
                                    className="icon"
                                    src={checkInIcon}
                                    alt=""
                                    style={{ width: "40px", height: "40px" }}
                                 />
                                 <div className="d-flex align-items-center flex-grow flex-wrap" style={{ fontSize: "22px", lineHeight: "32px" }}>
                                    <span
                                       style={{
                                          flex: "0 0 150px",
                                       }}
                                    >{"Check-in time  "}</span>
                                    <span className="font-bold ml-3">
                                       {checkInDate}
                                    </span>
                                    <span className="font-bold ml-3">
                                       {acc.propertyCheckInProcess?.checkInTime}
                                    </span>
                                 </div>
                              </div>
                              {/* <Row>
                                 <Col md={4}>
                                    <img className='icon' src={checkOutIcon} alt="" />
                                 </Col>
                                 <Col md={8} style={{ marginLeft: '-60px', marginTop: '0px' }}>
                                    <div ><span className="" style={{ fontSize: "16px", lineHeight: '55px' }}>{'Check-out time  '} <span className="font-bold ml-3">{acc.propertyCheckInProcess?.checkOutTime}</span></span>
                                       <br />
                                    </div>
                                 </Col>
                              </Row> */}
                              <div className="checkOutDetails flex align-items-center">
                                 <img
                                    className="icon"
                                    src={checkOutIcon}
                                    alt=""
                                    style={{ width: "40px", height: "40px" }}
                                 />
                                 <div className="d-flex align-items-center flex-grow flex-wrap" style={{ fontSize: "22px", lineHeight: "32px" }}>
                                    <span
                                       style={{
                                          flex: "0 0 150px",
                                       }}
                                    >{"Check-out time  "}</span>
                                    <span className="font-bold ml-3">
                                       {checkOutDate}
                                    </span>
                                    <span className="font-bold ml-3">
                                       {acc.propertyCheckInProcess?.checkOutTime}
                                    </span>
                                 </div>
                              </div>
                              <Row style={{ textAlign: 'center' }}>
                                 <div id='profile-divider' ></div>
                              </Row>

                              <Row className="">
                                 <Col className="flex justify-between" md={24}>
                                    <div>
                                       <div className="" style={{ fontSize: "20px", lineHeight: '63px', }}>{acc.property?.currency}{" "}
                                          {(Number(acc.rateAndPolicy?.finalRate) / noOfNights).toFixed(0)} {' x '} {noOfNights} {'nights'}</div>
                                    </div>
                                    <div style={{ marginTop: '20px' }}>

                                       <div className="" style={{ fontSize: "20px", lineHeight: '32px', marginLeft: '60px' }}> {acc.property?.currency} {acc.rateAndPolicy?.finalRate}</div>
                                    </div>
                                 </Col>
                              </Row>

                              <Row style={{ textAlign: 'center' }}>
                                 <div id='profile-divider' ></div>
                              </Row>
                              <Row className="">
                                 <Col className="flex justify-between" md={24}>
                                    <div>
                                       <div className="" style={{ fontSize: "20px", lineHeight: '63px', fontWeight: 'bold' }}>{'Total'}</div>
                                    </div>
                                    <div style={{ marginTop: '20px' }}>

                                       <div className="" style={{ fontSize: "20px", lineHeight: '32px', marginLeft: '60px', fontWeight: 'bold' }}> {acc.property?.currency} {acc.rateAndPolicy?.finalRate}</div>
                                    </div>
                                 </Col>
                              </Row>
                           </div>
                        </Col>

                     </Row>
                  </Col>
                  {/* <Col md={8} id="about-brand" style={{ height: '500px', marginTop: '1px' }}>
                     <Row>
                        <TravellerProfileForm />
                     </Row>

                  </Col> */}
                  {/* <Col  md={2}>
                  </Col> */}

               </Row>
            </div>

         )
      }
   }, [checkInDate, checkOutDate, imageList, noOfNights]);

  

   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   const renderBooking = useCallback(() => {
      if (bookingList) {
         return bookingList.map((acc: any, key: number) => {
            return (
               <div key={key}>
                  {renderImage(acc)}
               </div >
            )
         });
      }
   }, [bookingList, renderImage]);

   //////////////////////////////////////////////////////////////////////////////////////////////////////////
   return (
      <div>
         {renderBooking()}
      </div >
   )
};

export default TravellerProfile;