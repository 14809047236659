import { } from 'antd';
import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Row, Col, Container, Form, Button, Card, Spinner } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { createTravelerButton, travelerScreen } from '../../../common/components-style';
import { TravelerProfileFormInput } from '../../../common/interfaces';
import PhoneInput from 'react-phone-input-2';
import shareIcon from '../../../images/icons/svg/outside/icon-share.svg';
import { formatPhoneNumber } from '../../../common/functions';
import { PhoneNumberUtil } from "google-libphonenumber";
import { toast } from "react-toastify";
import { getStorage } from '../../../common/storage';

const TravellerProfileForm: React.FC<any> = ({ location, showTravellerFormAndHide }): JSX.Element => {

   /**
    * global action
    */

   ////////////////////////////////////////////////////////////////////////////////////////////////////
   const { createUser, createUserMapping, updateAccess, sendTravelerAdminWelcomeEmail } = useStoreActions<any>((actions) => ({
      createUser: actions.user.createUser,
      createUserMapping: actions.user.createUserMapping,
      updateAccess: actions.user.updateAccess,
      sendTravelerAdminWelcomeEmail: actions.client.sendTravelerAdminWelcomeEmail
   }));

   /**
 * global state
 */

   ////////////////////////////////////////////////////////////////////////////////////////////////////
   const { userCreate, userCreateError, createUserMappingSuccess, createUserMappingError, updateAccessSuccess, updateAccessError } = useStoreState<any>((state) => ({
      userCreate: state.user.userCreate,
      userCreateError: state.user.userCreateError,
      createUserMappingSuccess: state.user.createUserMappingSuccess,
      createUserMappingError: state.user.createUserMappingError,
      updateAccessSuccess: state.user.updateAccessSuccess,
      updateAccessError: state.user.updateAccessError,
   }));


   /**
    * local state
    */
   const { register, handleSubmit, formState: { errors }, control } = useForm<TravelerProfileFormInput>();
   const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);
   const [loading, setLoading] = useState<boolean>(false);

   /**
    * validate phone number
    */

   let validatePhoneNumber = (inputNumber: string) => {
      let number = formatPhoneNumber(inputNumber);

      let valid = false;
      let isPossible = false;
      try {
         const phoneUtil = PhoneNumberUtil.getInstance();
         isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number));
         valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
      } catch (e) {
         valid = false;
      }

      if (isPossible && valid) {
         setValidPhoneNumber(true);
         return true;
      } else {
         setValidPhoneNumber(false);
         return false;
      }
   };

   /**
    * react lifecycle side effect
    */
   useEffect(() => {

      if (updateAccessSuccess?.data) {
         toast.success('Traveller created successfully');
         setLoading(false);
         showTravellerFormAndHide(false);
      }

      if (updateAccessError) {
         toast.error(updateAccessError.message);
         setLoading(false);
      }

   }, [updateAccessSuccess, updateAccessError, showTravellerFormAndHide]);

   useEffect(() => {

      if (createUserMappingSuccess?.data) {
         const getIdToMapping = createUserMappingSuccess?.data.clients[0].corporateProgramme[0]._id;

         /**
          * modify access
          */
         const payload = {
            id: getIdToMapping,
            userId: createUserMappingSuccess?.data.userId,
            accessType: "programme",
            access: [{ module: "CAN_BOOK_FOR_SELF", read: false, write: true, notification: true }],
            isUserDisable: false
         }

         updateAccess(payload);
      }

      if (createUserMappingError) {
         toast.error(createUserMappingError.message);
         setLoading(false);
      }

   }, [createUserMappingSuccess, createUserMappingError, updateAccess]);


   useEffect(() => {
      if (userCreate?.data) {

         const userId = userCreate.data.user._id;
         const email = userCreate.data.user.email;

         const ClientId = getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('selectedFilterClient')?.value;
         const programmeId = getStorage('makeBookingProgram')?.value ? getStorage('makeBookingProgram')?.value : getStorage('selectedProgramsOption')?.value ? getStorage('programmeId') : getStorage('programmeData')?._id;
         const programmeName = getStorage('makeBookingProgram')?.label ? getStorage('makeBookingProgram')?.label : getStorage('selectedProgramsOption')?.label ? getStorage('programmeData')?.programmeName : getStorage('programmeData')?.programmeName;


         /**
          * add user mapping
          */
         const payload = {
            clientId: ClientId,
            email: email,
            programmeId: programmeId,
            role: 'traveler',
            userId: userId
         };

         createUserMapping(payload);

         const data = {
            firstName: userCreate.data.user?.firstName,
            lastName: userCreate.data.user?.lastName,
            programmeName: programmeName,
            email: userCreate.data.user.email
         }
         sendTravelerAdminWelcomeEmail(data);
      }

      if (userCreateError) {
         toast.error(userCreateError.message);
         setLoading(false);
      }

   }, [createUserMapping, sendTravelerAdminWelcomeEmail, userCreate, userCreateError]);

   /**
    * submit data
    */
   const onSubmit = (values) => {

      if (!validPhoneNumber) {
         setLoading(false);
         toast.error("Please enter valid phone number!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
         });
         return;
      }

      setLoading(true);
      /**
       * send request to create user
       */
      Object.assign(values, { userGroup: 'user' });
      createUser(values);
   }

   //////////////////////////////////////////////////////////////////////////////////////////////////////////
   return (
      <>
         <Card style={travelerScreen}>
            <Card.Body>
               <Container>
                  <div  >
                     <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                           <Col md={6}>
                              <div style={{ marginBottom: '10px' }}>
                                 <img className='icon' src={shareIcon} alt="" />
                                 <div className="" style={{ fontSize: "26px", marginTop: '-40px', fontWeight: 'bold', lineHeight: '32px', marginLeft: '60px' }}>Traveller Details</div>
                              </div>
                           </Col>
                           <Col md={6}>
                              <div style={{ marginTop: "15px", textAlign: 'center', marginLeft: '75px' }}>
                                 <span
                                    onClick={() => { showTravellerFormAndHide(false); }}
                                    style={{
                                       color: "#22AEE2",
                                       textDecoration: "underline",
                                       fontSize: "16px",
                                       fontWeight: "bold",
                                       textDecorationColor: "#22AEE2",
                                       cursor: "pointer",
                                    }}
                                 >
                                    Back to options
                                 </span>
                              </div>
                           </Col>
                        </Row>

                        <Row>
                           <Col md={12}>
                              <Row className='formSpace'>
                                 <Col md={5} >
                                    <Row>
                                       <Form.Label><span className='requiredMark'>*</span> First Name</Form.Label>
                                    </Row>
                                    <Row>
                                       <Form.Control className={`${errors.firstName ? 'is-invalid' : ''}`} defaultValue={''} {...register("firstName", { required: true })} />
                                       <div className="invalid-feedback">Please first name!</div>
                                    </Row>

                                 </Col>
                                 <Col md={5} style={{ marginInlineStart: '25px' }}>
                                    <Row>
                                       <Form.Label><span className='requiredMark'>*</span> Last Name</Form.Label>
                                    </Row>
                                    <Row>
                                       <Form.Control className={`${errors.lastName ? 'is-invalid' : ''}`} defaultValue={''} {...register("lastName", { required: true })} />
                                       <div className="invalid-feedback">Please last name!</div>
                                    </Row>

                                 </Col>
                              </Row>
                              <Row className='formSpace'>
                                 <Col md={5} >
                                    <Row>

                                       <Form.Label><span className='requiredMark'>*</span> Email address</Form.Label>
                                    </Row>
                                    <Row>
                                       <Form.Control type='email' className={`${errors.email ? 'is-invalid' : ''}`} defaultValue={''} {...register("email", { required: true })} />
                                       <div className="invalid-feedback">Please input email address!</div>
                                    </Row>

                                 </Col>
                                 <Col md={5} style={{ marginInlineStart: '25px' }}>

                                    <Row>

                                       <Form.Label><span className='requiredMark'>*</span> Department</Form.Label>
                                    </Row>
                                    <Row>
                                       <Form.Control className={`${errors.department ? 'is-invalid' : ''}`} defaultValue={''} {...register("department", { required: true })} />
                                       <div className="invalid-feedback">Please input department!</div>
                                    </Row>
                                 </Col>
                              </Row>

                              <Row className='formSpace'>
                                 <Col md={5} >

                                    <Row>
                                       <Form.Label><span className='requiredMark'>*</span>Position</Form.Label>
                                    </Row>
                                    <Row>
                                       <Form.Control className={`${errors.position ? 'is-invalid' : ''}`} defaultValue={''} {...register("position", { required: true })} />
                                       <div className="invalid-feedback">Please input position!</div>
                                    </Row>

                                 </Col>
                                 <Col md={5} style={{ marginInlineStart: '25px' }}>
                                    <Row>

                                       <Form.Label><span className='requiredMark'>*</span> Telephone Number</Form.Label>
                                    </Row>
                                    <Row>
                                       <Controller
                                          name="telephone"
                                          control={control}
                                          defaultValue={''}
                                          rules={{
                                             required: true
                                          }}
                                          render={({ field }) =>
                                             <PhoneInput isValid={(inputNumber: string, country: any) => {
                                                // const phoneLength = Math.ceil((countries.filter((val: any) => val.dialCode === country.dialCode)[0] as any)?.format.length / 2);
                                                // setLength(phoneLength);
                                                return validatePhoneNumber(inputNumber);
                                             }} placeholder='' containerClass={`${errors.telephone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); }} />}
                                       />
                                       {errors.telephone && <div className="invalid-feedback-custom">Please input validate phone number!</div>}
                                    </Row>

                                 </Col>
                              </Row>



                           </Col>
                           <Col md={1}></Col>
                        </Row>

                        <Row className='formSpace'>
                           {
                              !loading && <Button type="submit" id='routing-button' style={createTravelerButton} >Create</Button>
                           }
                           {
                              loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                           }
                        </Row>
                     </Form>
                  </div>
               </Container>
            </Card.Body>
         </Card>
      </>


   )
};

export default TravellerProfileForm;