import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import PhoneInput from "react-phone-input-2";
import { useStoreActions, useStoreState } from "easy-peasy";
import { getStorage, setStorage } from "../../../../common/storage";
import { UserProfile } from "../../../../common/constants";
import { useForm, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { updateUserDetails } from "../../../../common/interfaces";
import { formButton } from "../../../../common/components-style";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { formatPhoneNumber } from "../../../../common/functions";

const MyProfile: React.FC<any> = ({
  refreshLocalState,
  hideProfileModal,
}): JSX.Element => {
  ////////////////////////////////Declaration//////////////////////////////////////////
  const { register, handleSubmit, setValue, formState: { errors }, control } = useForm<updateUserDetails>();

  //////////////////////////////////Local state manage//////////////////////////////////////////
  const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>();


  ///////////////////////////////////Global state manage//////////////////////////////////////////////

  const { updateUser } = useStoreActions<any>((actions) => ({
    updateUser: actions.user.updateUser,
  }));

  const { myProfileUpdate, myProfileUpdateError } = useStoreState<any>(
    (state) => ({
      myProfileUpdate: state.user.userUpdate,
      myProfileUpdateError: state.user.userUpdateError,
    })
  );

  //////////////////////////////////////Local storage manage////////////////////////////////////////////////

  const localStorage = useCallback(
    (values) => {
      const myoLoggedUser = getStorage("myo_logged_user");
      let myoLoggedUserRefresh = {
        ...myoLoggedUser,
        userData: values,
      };
      setStorage("myo_logged_user", myoLoggedUserRefresh);
      refreshLocalState();
    },
    [refreshLocalState]
  );

  ///////////////////////////////////////User update response manage//////////////////////////////////////////

  useEffect(() => {
    if (myProfileUpdate) {
      setLoading(false);
      localStorage(myProfileUpdate.data);
      toast.success("My profile updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      hideProfileModal();
      window.location.reload();
    }
    if (myProfileUpdateError) {
      setLoading(false);
      toast.error(myProfileUpdateError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [myProfileUpdate, myProfileUpdateError, hideProfileModal, localStorage]);

  ////////////////////////////////////////Form field manage/////////////////////////////////////////

  useEffect(() => {
    const { userData } = getStorage("myo_logged_user");
    setValue('email', userData?.email);
    setValue('firstName', userData?.firstName);
    setValue('lastName', userData?.lastName);
    setValue('position', userData?.position);
    setValue('phone', userData?.phone);
    setValue('mobile', userData?.phone);
    setUserData(userData);
  }, [setValue]);

  //////////////////////////////////////////From submit manage//////////////////////////////////////////
  const onSubmit = async (values) => {
    setLoading(true);
    if (!validPhoneNumber) {
      toast.error('Please enter valid phone number!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      return;
    }

    if (!validatePhoneNumber(values?.phone)) {
      toast.error('Please enter valid phone number!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      return;
    }

    const updateUserDetails = {
      email: userData?.email,
      firstName: values.firstName,
      lastName: values.lastName,
      position: values.position,
      phone: values.phone,
      mobile: values.mobile,
      userProfile: UserProfile.COMPLETED,
    };
    updateUser(updateUserDetails);
  };


  const validatePhoneNumber = (inputNumber: string) => {
    let number = formatPhoneNumber(inputNumber)

    let valid = false;
    let isPossible = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number))
      valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
    } catch (e) {
      valid = false;
    }

    if (isPossible && valid) {

      setValidPhoneNumber(true);
      return true;
    } else {
      setValidPhoneNumber(false);
      return false;
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={1}></Col>
          <Col md={10}>
            <Row className='formSpace'>
              <Col md={4} style={{ textAlign: 'right' }}>
                <Form.Label><span className='requiredMark'>*</span> Email</Form.Label>
              </Col>
              <Col md={8} style={{ textAlign: 'left' }}>
                <Form.Control className={`${errors.email ? 'is-invalid' : ''}`} {...register("email", { required: false })} readOnly />
                <div className="invalid-feedback">Please input email!</div>
              </Col>
            </Row>
          </Col>
          <Col md={1}></Col>
        </Row>
        <Row>
          <Col md={1}></Col>
          <Col md={10}>
            <Row className='formSpace'>
              <Col md={4} style={{ textAlign: 'right' }}>
                <Form.Label><span className='requiredMark'>*</span> First name</Form.Label>
              </Col>
              <Col md={8} style={{ textAlign: 'left' }}>
                <Form.Control className={`${errors.firstName ? 'is-invalid' : ''}`} {...register("firstName", { required: true })} />
                <div className="invalid-feedback">Please input first name!</div>
              </Col>
            </Row>
          </Col>
          <Col md={1}></Col>
        </Row>
        <Row>
          <Col md={1}></Col>
          <Col md={10}>
            <Row className='formSpace'>
              <Col md={4} style={{ textAlign: 'right' }}>
                <Form.Label><span className='requiredMark'>*</span> Last name</Form.Label>
              </Col>
              <Col md={8} style={{ textAlign: 'left' }}>
                <Form.Control className={`${errors.lastName ? 'is-invalid' : ''}`} {...register("lastName", { required: true })} />
                <div className="invalid-feedback">Please input Last name!</div>
              </Col>
            </Row>
          </Col>
          <Col md={1}></Col>
        </Row>
        <Row>
          <Col md={1}></Col>
          <Col md={10}>
            <Row className='formSpace'>
              <Col md={4} style={{ textAlign: 'right' }}>
                <Form.Label><span className='requiredMark'>*</span> Job title</Form.Label>
              </Col>
              <Col md={8} style={{ textAlign: 'left' }}>
                <Form.Control className={`${errors.position ? 'is-invalid' : ''}`} {...register("position", { required: true })} />
                <div className="invalid-feedback">Please input job title!</div>
              </Col>
            </Row>
          </Col>
          <Col md={1}></Col>
        </Row>
        <Row>
          <Col md={1}></Col>
          <Col md={10}>
            
        <Row className='formSpace'>
          <Col md={4} style={{ textAlign: 'right' }}>
            <Form.Label><span className='requiredMark'>*</span> Phone</Form.Label>
          </Col>
          <Col md={8} style={{ textAlign: 'left' }}>

            {
              <Controller
                name="phone"
                control={control}

                rules={{
                  required: true,
                }}
                render={({ field }) =>
                  <PhoneInput
                    placeholder=''
                    isValid={(inputNumber: string) => {
                      return validatePhoneNumber(
                        inputNumber,

                      );
                    }}

                    containerClass={`${errors.phone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); }} />}
              />
            }
            {<div className="invalid-feedback">Please input phone!</div>}
          </Col>
        </Row>
            {/* <Row className='formSpace'>
              <Col md={4} style={{ textAlign: 'right' }}>
                <Form.Label><span className='requiredMark'>*</span> Phone</Form.Label>
              </Col>
              <Col md={8} style={{ textAlign: 'left' }}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: true,
               
                  }}
                  render={({ field }) =>
                    <PhoneInput
                      placeholder=''
                      isValid={(inputNumber: string) => {

                        return validatePhoneNumber(
                          inputNumber,

                        );
                      }}
                      containerClass={`${errors.phone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); }} />}
                />
                <div className="invalid-feedback">Please input phone number!</div>
              </Col>
            </Row> */}
          </Col>
          <Col md={1}></Col>
        </Row>


        <Row>
          <Col md={1}></Col>
          <Col md={10}>
          <Row className='formSpace'>
          <Col md={4} style={{ textAlign: 'right' }}>
            <Form.Label><span className='requiredMark'>*</span> Mobile no (optional)</Form.Label>
          </Col>
          <Col md={8} style={{ textAlign: 'left' }}>

            {
              <Controller
                name="mobile"
                control={control}

                // rules={{
                //   required: true,
                // }}
                render={({ field }) =>
                  <PhoneInput
                    placeholder=''
                    // isValid={(inputNumber: string) => {
                    //   return validatePhoneNumber(
                    //     inputNumber,

                    //   );
                    // }}

                    containerClass={`${errors.mobile? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); }} />}
              />
            }
            {/* {<div className="invalid-feedback">Please input mobile!</div>} */}
          </Col>
        </Row>
            {/* <Row className='formSpace'>
              <Col md={4} style={{ textAlign: 'right' }}>
                <Form.Label> Mobile no (optional)</Form.Label>
              </Col>
              <Col md={8} style={{ textAlign: 'left' }}>
                <Controller
                  name="mobile"
                  control={control}

                  render={({ field }) =>
                    <PhoneInput
                      placeholder=''
                      containerClass={`${errors.mobile ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); }} />}
                />

              </Col>
            </Row> */}
            <Row className='formSpace'>
              <Col md={4} style={{ textAlign: 'right' }}>
              </Col>
              <Col md={8} style={{ textAlign: 'left' }}>
                {
                  !loading && <>
                    <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                    <Button id='edit-button' style={formButton} onClick={hideProfileModal} >Close</Button>
                  </>
                }
                {
                  loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                }
              </Col>
            </Row>
          </Col>
          <Col md={1}></Col>
        </Row>
      </Form>
    </>
  );
};

export default MyProfile;
