import { Button } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import UserForm from './UserForm';
import UserList from './UserList';


const UserHome: React.FC = (): JSX.Element => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { loadBrandList } = useStoreActions<any>((actions) => ({
    loadBrandList: actions.common.loadBrandList,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { brandList } = useStoreState<any>((state) => ({
    brandList: state.common.brandList,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    loadBrandList();
  }, [loadBrandList, formOpen]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadForm = useCallback((data) => {
    setUser(data);
    setFormOpen(true);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      <div className="flex justify-between">
        <p className="text-2xl mb-10">User Management</p>
        <Button hidden={formOpen} onClick={() => loadForm(null)} className="mysa-blue text-white">Create New</Button>
        <Button hidden={!formOpen} onClick={() => setFormOpen(false)} className="mysa-blue text-white">Show List</Button>
      </div>
      {!formOpen && <UserList key={Math.random} loadForm={loadForm} brandList={brandList} />}
      {formOpen && <UserForm key={Math.random} user={user} setFormOpen={setFormOpen} brandList={brandList} />}
    </div>
  )
};

export default UserHome;