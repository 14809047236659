import { message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';

import { Button, Col, Nav, Row, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { $mysaBlack, loginButton, navContentStyle, navStyle, tabDivStyle } from '../../../../common/components-style';
import { getStorage } from '../../../../common/storage';
import { UserGroups } from '../../../../common/constants';
import { getLoggedUserType } from '../../../../common/functions';
import ClientList from '../ClientList';
import ClientForm from '../form/ClientForm';
import ActiveRFPsListPage from './ActiveRFPsListPage';
import ProposalManagementPage from './ProposalManagementPage';

const ButtonStyle = {
    ...loginButton,
    color: $mysaBlack,
    fontWeight: 'bold'
} as React.CSSProperties;

const NewClientHomePage: React.FC = (): JSX.Element => {
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [clientList, setClientList] = useState<any>([]);
    const [clientId, setClientId] = useState<any>(null);
    const [selectedClient, setSelectedClient] = useState<any>(null);
    const [selectedClients, setSelectedClients] = useState<any>(null);
    const [tabKey, setTabKey] = useState<any>('client');
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const getClientList = useStoreActions<any>((actions) => actions.client.getClientList);
    const updateClient = useStoreActions<any>((actions) => actions.client.updateClient);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const getClientListSuccess = useStoreState<any>((state) => state.client.getClientListSuccess);
    const getClientListError = useStoreState<any>((state) => state.client.getClientListError);
    const updateClientSuccess = useStoreState<any>((state) => state.client.updateClientSuccess);

    ////////////////////////////////////////////////////////////////////////////////////////////
    const loadForm = useCallback((data) => {
        setFormOpen(true);
        const client = getStorage('myo_selected_client');
        setSelectedClient(client);
        setSelectedClients(client);
        if (data !== null) {
            setClientId(data.key);
        } else {
            setClientId(null);
        }
    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        getClientList();
    }, [getClientList]);

    //////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getClientListSuccess) {
            const client = getClientListSuccess.data;
            setClientList(client);

        }
        if (getClientListError) {
            message.error(getClientListError.message);
        }
    }, [getClientListError, getClientListSuccess, clientList]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const refreshClients = useCallback(() => {
        getClientList();
    }, [getClientList]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (updateClientSuccess) {
            //   setLoading(false);
            getClientList();
            toast.success("Client updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [getClientList, updateClientSuccess]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onArchive = useCallback((data) => {
        // setLoading(true);
        if (data) {
            const value = {
                id: data.key,
                status: 'inactive'
            }
            updateClient(value);
        }

    }, [updateClient]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const activateProgramme = useCallback((data) => {
        if (data) {
            if (data.status === 'inactive') {
                const value = {
                    id: data.key,
                    status: 'active'
                }
                updateClient(value);
            }
        }

    }, [updateClient]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    // return (
    // 	<>
    // 		<div className="content-title mysa-blue-text fz-20" style={{ marginTop: "-6px", marginBottom: "16px" }}>
    // 			Client Management
    // 		</div>
    // 		<div className="flex justify-end ">
    // 			{/* <p className="text-2xl mb-10">Client Management</p> */}
    // 			{getLoggedUserType() === UserGroups.super_admin && <Button className='btn_design m-1' style={ButtonStyle} hidden={formOpen} onClick={() => { loadForm(null); setFormOpen(true); setSelectedClients(null); setClientId(null) }} >Create New Client</Button>}
    // 			{/* <Button hidden={!formOpen} onClick={() => { setFormOpen(false); getClientList(query); }} className="mysa-blue w-48 text-white">Show Client List</Button> */}
    // 		</div>
    // 		{!formOpen && <ClientList loadForm={loadForm} onClientSelect={(data) => { setSelectedClients(data) }} onArchive={onArchive} activateProgramme={activateProgramme} />}
    // 		{formOpen && <ClientForm setFormOpen={setFormOpen} clientId={clientId} selectedClient={selectedClient} selectedClients={selectedClients} formOpen={formOpen} setSelectedClients={setSelectedClients} refreshClients={refreshClients} setSelectedClient={setSelectedClient} />}
    // 	</>
    // )


    return (
        <div style={tabDivStyle}>
            <Tab.Container defaultActiveKey="client" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                <Row >
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                            <div style={{ marginTop: '0%' }}>
                                <Nav.Item>
                                    <Nav.Link eventKey="client">Client Listing</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="activeRFPs">Active RFPs</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="proposal">Proposal Management</Nav.Link>
                                </Nav.Item>

                            </div>
                        </Nav>
                    </Col>

                    <Col sm={10}>
                        <Tab.Content >
                            <Tab.Pane eventKey="client" >
                                <div style={navContentStyle}>
                                    <div className="flex justify-end ">
                                        {/* <p className="text-2xl mb-10">Client Management</p> */}
                                        {getLoggedUserType() === UserGroups.super_admin && <Button className='btn_design m-1' style={ButtonStyle} hidden={formOpen} onClick={() => { loadForm(null); setFormOpen(true); setSelectedClients(null); setClientId(null) }} >Create New Client</Button>}
                                        {/* <Button hidden={!formOpen} onClick={() => { setFormOpen(false); getClientList(query); }} className="mysa-blue w-48 text-white">Show Client List</Button> */}
                                    </div>
                                    {!formOpen && <ClientList loadForm={loadForm} onClientSelect={(data) => { setSelectedClients(data) }} onArchive={onArchive} activateProgramme={activateProgramme} />}
                                    {formOpen && <ClientForm setFormOpen={setFormOpen} clientId={clientId} selectedClient={selectedClient} selectedClients={selectedClients} formOpen={formOpen} setSelectedClients={setSelectedClients} refreshClients={refreshClients} setSelectedClient={setSelectedClient} />}

                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="activeRFPs" >
                                <div style={navContentStyle}>
                                    <ActiveRFPsListPage></ActiveRFPsListPage>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="proposal" >
                                <div style={navContentStyle}>
                                    <ProposalManagementPage></ProposalManagementPage>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
};

export default NewClientHomePage;