import React from 'react';
import { Col,Row, Tab } from 'react-bootstrap';
import { navContentStyle, tabDivStyle } from '../../../../common/components-style';
import ClientDetailsForm from './ClientDetailsForm';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ClientForm: React.FC<any> = ({ formOpen, clientId, selectedClient, selectedClients, setFormOpen, setSelectedClients, refreshClients, setSelectedClient }): JSX.Element => {

  return (
    <>
      <div style={tabDivStyle}>
        <Tab.Container defaultActiveKey="details">
          <Row >
            {/* <Col sm={2}>
              <Nav variant="pills" className="flex-column" style={navStyle}>
                <div style={{ marginTop: '0%' }}>
                  <Nav.Item>
                    <Nav.Link eventKey="details">Details</Nav.Link>
                  </Nav.Item>
                </div>
              </Nav>
            </Col> */}
            <Col sm={12}>
              {/* <Tab.Content >
                <Tab.Pane eventKey="details" > */}
                  <div style={navContentStyle}>

                    <Breadcrumb className="mb-5 ml-10 client_hdng">
                      {selectedClient && <Breadcrumb.Item>
                        <Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); setSelectedClients(null); refreshClients(); setSelectedClient(null) }}>Client Management</Link>
                      </Breadcrumb.Item>}
                      {selectedClients && <Breadcrumb.Item active>{selectedClients.companyName} </Breadcrumb.Item>}
                      {(formOpen && !selectedClients) && <Breadcrumb.Item active>New client</Breadcrumb.Item>}
                    </Breadcrumb>
                        
                    <ClientDetailsForm clientId={clientId} setFormOpen={setFormOpen} />
                  </div>
                {/* </Tab.Pane>
              </Tab.Content> */}
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  )
};

export default ClientForm;