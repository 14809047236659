
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { UserGroups, UserAccess } from '../../../../../common/constants';
import { canBrandPropertyModuleWrite, getLoggedUserType, getMailDomain, setAutoBrandReadAccess } from '../../../../../common/functions';
import DebounceSearch from '../../../../common/DebounceSearch'
import { Row, Col, Container, Form, Button, Spinner } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { formButton } from '../../../../../common/components-style';
import { toast } from 'react-toastify';
import { BrandUserFormInput } from '../../../../../common/interfaces';
// const layout = {
// 	labelCol: { span: 4 },
// 	wrapperCol: { span: 16 },
// };

// const tailLayout = {
// 	wrapperCol: { offset: 4, span: 16 },
// };

const PropertyUserForm: React.FC<any> = ({ brandId, propertyId, selectedUser, close, reload }): JSX.Element => {
	// const [form] = Form.useForm();
	// const [title, setTitle] = useState('');
	const { register, handleSubmit, setValue, reset, formState: { errors }, control } = useForm<BrandUserFormInput>();
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState<boolean>(false);
	const [brandDomains, setBrandDomains] = useState<Array<string>>([]);
	const [propertyData, setPropertyData] = useState<any>();

	const updateUser = useStoreActions<any>((actions) => actions.user.updateUser);
	const createUser = useStoreActions<any>((actions) => actions.user.createUser);
	const updateAccess = useStoreActions<any>((actions) => actions.user.updateAccess);
	const resetUser = useStoreActions<any>((actions) => actions.user.resetUser);
	const createUserMapping = useStoreActions<any>((actions) => actions.user.createUserMapping);
	const updateUserMapping = useStoreActions<any>((actions) => actions.user.updateUserMapping);
	const onloadBrand = useStoreActions<any>((actions) => actions.brand.onloadBrand);

	const userUpdate = useStoreState<any>((state) => state.user.userUpdate);
	const userCreate = useStoreState<any>((state) => state.user.userCreate);
	const userCreateError = useStoreState<any>((state) => state.user.userCreateError);
	const userUpdateError = useStoreState<any>((state) => state.user.userUpdateError);
	const updateAccessSuccess = useStoreState<any>((state) => state.user.updateAccessSuccess);
	const createUserMappingSuccess = useStoreState<any>((state) => state.user.createUserMappingSuccess);
	const updateUserMappingSuccess = useStoreState<any>((state) => state.user.updateUserMappingSuccess);
	const loadBrandSuccess = useStoreState<any>((state) => state.brand.loadBrandSuccess);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (brandId) {
			onloadBrand(brandId);
		}
	}, [brandId, onloadBrand]);

	useEffect(() => {
		if (loadBrandSuccess) {
			setBrandDomains(loadBrandSuccess.data?.allowedDomains);
		}
	}, [loadBrandSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		//user update success
		if (userUpdate) {
			updateAccess({
				id: selectedUser.arrId,
				userId: selectedUser.userId,
				role: UserGroups.user,
				accessType: "brandRole"
			});
			// form.resetFields();
			reload();
			reset();
			setLoading(false);
			// message.success("User updated successfully");
			toast.success("User updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			})
			resetUser();
		}
		// user create success
		if (userCreate) {
			if (userCreate.data.data) {
				// create mapping
				createUserMapping({
					userId: userCreate.data.user._id,
					email: userCreate.data.user.email,
					brandId,
					propertyId,
					role: UserGroups.user
				})
			} else {
				//update mapping
				updateUserMapping({
					userId: userCreate.data.user._id,
					properties: propertyId,
					brand: {
						brandId,
						role: UserGroups.user
					}
				})
			}
			// form.resetFields();
			resetUser();
			reset();
			setLoading(false);
			// reload();
		}

		// update user mapping success 
		if (updateUserMappingSuccess || createUserMappingSuccess) {
			const arrId = _.filter(updateUserMappingSuccess?.data?.brands, (a) => a.brandId === brandId).shift()?._id || ''
			const id = createUserMappingSuccess?.data?.brands[0]?._id || arrId;
			const userId = createUserMappingSuccess?.data?.userId || updateUserMappingSuccess?.data?.userId;
			updateAccess(setAutoBrandReadAccess(id, userId));
			// message.success("User created successfully");
			toast.success("User created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			})
			resetUser();
			reload();
		}

		// user create error
		if (userCreateError) {
			// message.error(userCreateError.message);
			toast.error(userCreateError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetUser();
			setLoading(false);
		}

		// access update success
		if (updateAccessSuccess) {
			reload();
			resetUser();
			setLoading(false);
			// message.success('Permission updated successfully');
			toast.success("Permission updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			})
		}

		// user update error
		if (userUpdateError) {
			// message.error(userUpdateError.message);
			toast.error(userUpdateError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setLoading(false);
			resetUser();
			setLoading(false);
		}

	}, [reload, userUpdate, setLoading, userUpdateError, updateAccessSuccess, selectedUser, updateAccess, resetUser, userCreate, userCreateError, updateUserMapping, brandId, updateUserMappingSuccess, createUserMappingSuccess, createUserMapping, propertyId, reset]);


	// useEffect(() => {
	// 	if (selectedUser) {

	// 		// form.setFieldsValue({
	// 		// 	email: selectedUser.email,
	// 		// 	firstName: selectedUser.firstName,
	// 		// 	lastName: selectedUser.lastName,
	// 		// 	phone: selectedUser.phone || '',
	// 		// 	mobile: selectedUser.mobile || '',
	// 		// 	position: selectedUser.position || '',
	// 		// 	isAdmin: selectedUser.role === UserGroups.brand_admin ? true : false
	// 		// });
	// 	}
	// }, [selectedUser]);



	const onFinish = useCallback((values) => {

		// check domain
		const getDomainValidation = getMailDomain(email, brandDomains);

		if (getDomainValidation) {
			setLoading(true);

			values.email = email;
			if (selectedUser) {
				delete values.isAdmin;

				updateUser(values);
			} else {
				if (propertyData?.data?.length !== 0) {
					toast.warning('This user has already been created', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'foo-bar'
					});
				} else {
					delete values.isAdmin;
					values.userGroup = UserGroups.user
					values.telephone = values.phone;
					delete values.phone;
					createUser(values);
				}

			}
		} else {
			toast.error('Invalid brand domain. please check your e-mail address domain with brand domains', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

	}, [email, brandDomains, selectedUser, updateUser, propertyData?.data?.length, createUser]);



	async function fetchUserList(email) {
		const encodedString = encodeURIComponent(email);
		const param = email === '' ? null : encodedString;
		return fetch(`${process.env.REACT_APP_API_URL}user-sv/users/search?search=${param}`)
			.then((response: any) => response.json())
			.then((body) =>
				body.data.map((user) => ({
					label: user.email,
					value: user.email,
					user
				})),
			);
	}

	useEffect(() => {
		const encodedString = encodeURIComponent(email);
		const param = email === '' ? null : encodedString;
		const data = fetch(`${process.env.REACT_APP_API_URL}user-sv/users/search?search=${param}`)
			.then((response: any) => response.json())
			.then((body) => {
				setPropertyData(body);
				body.data.map((user) => ({
					label: user.email,
					value: user.email,
					user
				}))
			}


			)


	}, [email]);

	return (
		<>
			{
				// title && <Row>
				// 	<Col xs={12}><div className="content-title">{title} - {propertyData?.country}</div></Col>
				// </Row>
			}
			<Container>
				<div className="jumbotron" style={{ width: '104%' }}>
					<Form onSubmit={handleSubmit(onFinish)}>
						<Row>
							<Col md={1}></Col>
							<Col md={10} style={{ marginLeft: '-200px' }}>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label><span className='requiredMark'>*</span> Email</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										<Controller
											name="email"
											control={control}
											defaultValue={selectedUser?.email}
											rules={{
												required: email ? false : true
											}}
											render={({ field }) =>

												<DebounceSearch
													value={email}
													placeholder="search users"
													fetchOptions={fetchUserList}
													onChange={(newValue) => {
														setEmail(newValue);
														setValue("firstName", "");
														setValue("lastName", "");
													}}
													disabled={selectedUser ? true : false}
													onSelect={(newValue, options) => {
														setEmail(newValue);
														setValue("firstName", options.user.firstName);
														setValue("lastName", options.user.lastName);
													}}
													style={{
														width: '100%',
													}}

													containerClass={`${errors.email ? 'is-invalid' : ''}`}
												/>
											}
										/>
										{errors.email && <div className="invalid-feedback-custom">Please input email!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label><span className='requiredMark'>*</span> First name</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										<Form.Control className={`${errors.firstName ? 'is-invalid' : ''}`} defaultValue={selectedUser?.firstName} {...register("firstName", { required: true })} />
										<div className="invalid-feedback">Please input first name!</div>
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label> <span className='requiredMark'>*</span> Last name</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										<Form.Control className={`${errors.lastName ? 'is-invalid' : ''}`} defaultValue={selectedUser?.lastName} {...register("lastName", { required: true })} />
										<div className="invalid-feedback">Please input last name!</div>
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										<Form.Check disabled={brandId && selectedUser?.isFirstUser && getLoggedUserType() !== UserGroups.super_admin} type="checkbox" label="Set as admin" {...register("isAdmin", { required: false })} />
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!loading && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.users.code) &&
											<Button type="submit" id='approve-button' style={formButton} >Create</Button>
										}

										{!loading &&
											<Button id='edit-button' style={formButton} onClick={() => { close(); }}>Close</Button>
										}
										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={1}></Col>
						</Row>
					</Form>
				</div>
			</Container>

		</>

	)
}

export default PropertyUserForm;