import { action, thunk } from 'easy-peasy';
import { call } from '../common/axios';
// import { io } from "socket.io-client";
// const socket = io(process.env.REACT_APP_API_URL?? '');



// eslint-disable-next-line import/no-anonymous-default-export
export default {
    //state
    //getting rfp list
    getRfpProcessListSuccess: null,
    getRfpProcessListLoading: false,
    getRfpProcessListError: null,
    //creating rfp list
    createRfpProcessSuccessResponse: null,
    createRfpProcessLoadingResponse: null,
    createRfpProcessErrorResponse: null,
    //deleting rfp list
    deleteRfpProcessSuccessResponse: null,
    deleteRfpProcessLoadingResponse: null,
    deleteRfpProcessErrorResponse: null,
    // update rfp process
    updateRfpProcessSuccessResponse: null,
    updateRfpProcessLoadingResponse: null,
    updateRfpProcessErrorResponse: null,
    // get rfp process by id
    rfpProcessByIdSuccess: null,
    // rfpProcessByIdLoading: false,
    rfpProcessByIdError: null,
    // get rfp process by programme id 
    rfpProcessByProgrammeIdSuccess: null,
    rfpProcessByProgrammeIdLoading: false,
    rfpProcessByProgrammeIdError: null,

    ///////////////////////////////////////// corporate property management ///////////////////////
    rfpProcessByProgramIdAndStatusSuccess: null,
    rfpProcessByProgramIdAndStatusError: null,

    // brand rfp management 

    // get myrfp for brandAdmins
    myRfpProcessListSuccess: null,
    myRfpProcessListLoading: false,
    myRfpProcessListError: null,

    // get a brand rfp by id
    brandRfpProcessByIdSuccess: null,
    brandRfpProcessByIdLoading: false,
    brandRfpProcessByIdError: null,

    // create a brand rfp
    createBrandRfpProcessSuccessResponse: null,
    createBrandRfpProcessLoadingResponse: null,
    createBrandRfpProcessErrorResponse: null,

    // update brand rfp
    updateBrandRfpProcessSuccessResponse: null,
    updateBrandRfpProcessLoadingResponse: null,
    updateBrandRfpProcessErrorResponse: null,

    // delete a brand rfp
    deleteBrandRfpProcessSuccessResponse: null,
    deleteBrandRfpProcessLoadingResponse: null,
    deleteBrandRfpProcessErrorResponse: null,

    // get a brand rfp locations by brand rfp id
    brandRfpProcessLocationsByIdSuccess: null,
    brandRfpProcessLocationsByIdLoading: false,
    brandRfpProcessLocationsByIdError: null,

    brandRfpProcessSelectedLocationsById: null,
    brandRfpProcessSelectedLocationsByIdLoading: false,
    brandRfpProcessSelectedLocationsByIdError: null,

    listBrandQuestionsByRfpIdSuccess: null,
    listBrandQuestionsByRfpIdError: null,

    ///////////// BrandRFPRate By InventoryId ///////
    getBrandRFPRateByInventoryIdSuccess: null,
    getBrandRFPRateByInventoryIdError: null,

    ///////////// createUpdatePropertyStandardRateForRFP ///////
    getCreateUpdatePropertyStandardRateForRFPSuccess: null,
    getCreateUpdatePropertyStandardRateForRFPError: null,

    ///////////// createUpdatePropertySeasonalRateForRFP ///////
    getCreateUpdatePropertySeasonalRateForRFPSuccess: null,
    getCreateUpdatePropertySeasonalRateForRFPError: null,

    ///////////// deletePropertySeasonalRateForRFP ///////
    getDeletePropertySeasonalRateForRFPSuccess: null,
    getDeletePropertySeasonalRateForRFPError: null,

    ///////////// RFPSubmission ///////
    getRFPSubmissionSuccess: null,
    getRFPSubmissionError: null,

    ///////////// get brand location by rfp id ///////
    getBrandLocationByRfpIdSuccess: null,
    getBrandLocationByRfpIdError: null,

    ///////////// get brand location by rfp id ///////
    getSubmissionOverviewByRfpIdSuccess: null,
    getSubmissionOverviewByRfpIdError: null,

    ///////////// RFPAdminReview ///////
    getRFPAdminReviewSuccess: null,
    getRFPAdminReviewError: null,
    getRFPAdminFilterReviewSuccess: null,
    getRFPAdminExReviewSuccess: null,
    getRFPAdminFilterReviewError: null,

    ///////////// RFPAdminReview Submit ///////
    getRFPAdminReviewSubmitSuccess: null,
    getRFPAdminReviewSubmitError: null,

    ///////////// RFPAdminReview Status update ///////
    getRFPAdminReviewStatusUpdateSuccess: null,
    getRFPAdminReviewStatusUpdateError: null,

    ///////////// RFPAdminReview release update///////
    getRFPAdminReviewReleaseUpdateSuccess: null,
    getRFPAdminReviewReleaseUpdateError: null,


    getCorporateProgrammePropertyByCitySuccess: null,
    getCorporateProgrammePropertyByCityError: null,


    // object to store brandRfp Details 
    brandRfpDetails: {
        showHomePage: false,
        selectedBrandId: "",
        showRfpDetailFormFirstPage: false,
        showRfpDetailFormSecondPage: false,
        selectedMyRFPFormOpen: false,
        showAvailableRfpList: false,
        showMyRfpList: false,
        formData: {},
        rfpDetailSelectedCity: "",
        showMyRfpHomePage: true,
        showMyRfpBrandEvaluationPage: false,
        defaultActiveKey: "corporateRFPS",
        showMyRfpLocationFormFirstPage: false,
        showMyRfpLocationFormSecondPage: false,
        showDecline: true
    },

    // get rfp locations by program id
    rfpLocationsByProgramIdSuccess: null,
    // rfpLocationsByProgramIdLoading: false,
    rfpLocationsByProgramIdError: null,

    updateBrandRfpProcessSuccess: null,
    updateBrandRfpProcessError: null,

    updateBrandQuestionsSuccess: null,
    updateBrandQuestionsError: null,

    getBrandRfpStatusCheckSuccess: null,
    getBrandRfpStatusCheckSError: null,

    getBrandSubmissionSuccess: null,
    getBrandSubmissionError: null,

    getPropertySubmissionSuccess: null,
    getPropertySubmissionError: null,

    getProgrammePropertyListSuccess: null,
    getProgrammePropertyListError: null,

    getCorporateProgrammePropertyListByRfpIdSuccess: null,
    getCorporateProgrammePropertyListByRfpIdError: null,

    getCostComparisonSuccess: null,
    getCostComparisonError: null,

    getPropertyInventoryRatesSuccess: null,
    getPropertyInventoryRatesError: null,


    acceptPropertyRateForRFPSuccess: null,
    acceptPropertyRateForRFPError: null,
    declinePropertyRateForRFPSuccess: null,
    declinePropertyRateForRFPError: null,

    proposedPropertyRateForRFPSuccess: null,
    proposedPropertyRateForRFPError: null,

    negotiatePropertyRateForRFPSuccess: null,
    negotiatePropertyRateForRFPError: null,

    acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess: null,
    acceptCreateUpdatePropertyAcceptDeclineOfRFPError: null,

    respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess: null,
    respondCreateUpdatePropertyAcceptDeclineOfRFPError: null,

    declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess: null,
    declineCreateUpdatePropertyAcceptDeclineOfRFPError: null,

    reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess: null,
    reviewCreateUpdatePropertyAcceptDeclineOfRFPError: null,

    negotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess: null,
    negotiateCreateUpdatePropertyAcceptDeclineOfRFPError: null,

    partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess: null,
    partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError: null,

    getPropertyAcceptDeclineOfRFPByPropertyIdSuccess: null,
    getPropertyAcceptDeclineOfRFPByPropertyIdError: null,

    ///////////// property-evaluation-status-update///////
    getPropertyEvaluationStatusUpdateSuccess: null,
    getPropertyEvaluationStatusUpdateError: null,

    getPropertyRfpInventorySuccess: null,
    getPropertyRfpInventoryError: null,

    getUpdateReportStatusSuccess: null,
    getUpdateReportStatusSuccessError: null,



    getPropertyInventoryRatesForRFPSuccess: null,
    getPropertyInventoryRatesForRFPError: null,

    acceptPropertyForRFPSuccess: null,
    acceptPropertyForRFPError: null,

    declinePropertyForRFPSuccess: null,
    declinePropertyForRFPError: null,

    reviewPropertyForRFPSuccess: null,
    reviewPropertyForRFPError: null,

    partAcceptedPropertyForRFPSuccess: null,
    partAcceptedPropertyForRFPError: null,

    getPropertySuccess: null,
    getPropertyError: null,

    getSurveyCreateUpdateDeleteSuccess: null,
    getSurveyCreateUpdateDeleteError: null,

    getSurveyStatusCheckSuccess: null,
    getSurveyStatusCheckError: null,



    getAllCreatedRfpProcessListSuccess: null,
    getAllCreatedRfpProcessListError: null,

    getAllSubmittedBrandRfpProcessListSuccess: null,
    getAllSubmittedBrandRfpProcessListError: null,
    propertyCheckByLocationSuccess: null,
    propertyCheckByLocationError: null,

    getLocationOverViewListSuccess: null,
    getLocationOverViewListError: null,
    getNegotiationListSuccess: null,
    getNegotiationListError: null,
    addAccountManagerForClientSuccess: null,
    addAccountManagerForClientError: null,
    //actions

    setGetAllSubmittedBrandRfpProcessListSuccess: action((state, payload) => ({ getAllSubmittedBrandRfpProcessListSuccess: payload })),
    setGetAllSubmittedBrandRfpProcessListError: action((state, payload) => ({ getAllSubmittedBrandRfpProcessListError: payload })),


    setPropertyCheckByLocationSuccess: action((state, payload) => ({ propertyCheckByLocationSuccess: payload })),
    setPropertyCheckByLocationError: action((state, payload) => ({ propertyCheckByLocationError: payload })),


    setGetAllCreatedRfpProcessListSuccess: action((state, payload) => ({ getAllCreatedRfpProcessListSuccess: payload })),
    setGetAllCreatedRfpProcessListError: action((state, payload) => ({ getAllCreatedRfpProcessListError: payload })),

    setSurveyCreateUpdateDeleteSuccess: action((state, payload) => ({ getSurveyCreateUpdateDeleteSuccess: payload })),
    setSurveyCreateUpdateDeleteError: action((state, payload) => ({ getSurveyCreateUpdateDeleteError: payload })),

    setUpdateReportStatusSuccess: action((state, payload) => ({ getUpdateReportStatusSuccess: payload })),
    setUpdateReportStatusSuccessError: action((state, payload) => ({ getUpdateReportStatusSuccessError: payload })),
    //property-evaluation-status-update
    setPropertyEvaluationStatusUpdateSuccess: action((state, payload) => ({ getPropertyEvaluationStatusUpdateSuccess: payload })),
    setPropertyEvaluationStatusUpdateError: action((state, payload) => ({ getPropertyEvaluationStatusUpdateError: payload })),
    //getting rfp process list actions
    setRfpProcessList: action((state, payload) => ({ getRfpProcessListSuccess: payload })),
    setRfpProcessListLording: action((state, payload) => ({ getRfpProcessListLoading: payload })),
    setRfpProcessListError: action((state, payload) => ({ getRfpProcessListError: payload })),
    //creating rfp process list actions
    createRfpProcessSuccess: action((state, payload) => ({ createRfpProcessSuccessResponse: payload })),
    createRfpProcessError: action((state, payload) => ({ createRfpProcessErrorResponse: payload })),
    createRfpProcessLoading: action((state, payload) => ({ createRfpProcessLoadingResponse: payload })),
    //deleting rfp process actions
    deleteRfpProcessSuccess: action((state, payload) => ({ deleteRfpProcessSuccessResponse: payload })),
    deleteRfpProcessLoading: action((state, payload) => ({ deleteRfpProcessLoadingResponse: payload })),
    deleteRfpProcessError: action((state, payload) => ({ deleteRfpProcessErrorResponse: payload })),
    // update rfp process
    updateRfpProcessSuccess: action((state, payload) => ({ updateRfpProcessSuccessResponse: payload })),
    updateRfpProcessLoading: action((state, payload) => ({ updateRfpProcessLoadingResponse: payload })),
    updateRfpProcessError: action((state, payload) => ({ updateRfpProcessErrorResponse: payload })),
    // get rfp process by id
    getRfpProcessByIdSuccess: action((state, payload) => ({ rfpProcessByIdSuccess: payload })),
    // getRfpProcessByIdLoading: action((state, payload) => ({ rfpProcessByIdLoading: payload })),
    getRfpProcessByIdError: action((state, payload) => ({ rfpProcessByIdError: payload })),
    // get rfp process by programme id
    getRfpProcessByProgrammeIdSuccess: action((state, payload) => ({ rfpProcessByProgrammeIdSuccess: payload })),
    getRfpProcessByProgrammeIdLoading: action((state, payload) => ({ rfpProcessByProgrammeIdLoading: payload })),
    getRfpProcessByProgrammeIdError: action((state, payload) => ({ rfpProcessByProgrammeIdError: payload })),

    ///////////////////////////////////////// corporate property management ///////////////////////
    getRFPProcessByProgramIdAndStatusSuccess: action((state, payload) => ({ rfpProcessByProgramIdAndStatusSuccess: payload })),
    getRFPProcessByProgramIdAndStatusError: action((state, payload) => ({ rfpProcessByProgramIdAndStatusError: payload })),

    // brand rfp management
    // get myrfp for brandAdmins
    getMyRfpProcessListSuccess: action((state, payload) => ({ myRfpProcessListSuccess: payload })),
    getMyRfpProcessListLoading: action((state, payload) => ({ myRfpProcessListLoading: payload })),
    getMyRfpProcessListError: action((state, payload) => ({ myRfpProcessListError: payload })),

    // get a brand rfp by id
    getBrandRfpProcessByIdSuccess: action((state, payload) => ({ brandRfpProcessByIdSuccess: payload })),
    getBrandRfpProcessByIdLoading: action((state, payload) => ({ brandRfpProcessByIdLoading: payload })),
    getBrandRfpProcessByIdError: action((state, payload) => ({ brandRfpProcessByIdError: payload })),

    // create a brand rfp
    createBrandRfpProcessSuccess: action((state, payload) => ({ createBrandRfpProcessSuccessResponse: payload })),
    createBrandRfpProcessError: action((state, payload) => ({ createBrandRfpProcessErrorResponse: payload })),
    createBrandRfpProcessLoading: action((state, payload) => ({ createBrandRfpProcessLoadingResponse: payload })),

    // update brand rfp
    // updateBrandRfpProcessSuccess: action((state, payload) => ({ updateBrandRfpProcessSuccessResponse: payload })),
    // updateBrandRfpProcessError: action((state, payload) => ({ updateBrandRfpProcessErrorResponse: payload })),
    updateBrandRfpProcessLoading: action((state, payload) => ({ updateBrandRfpProcessLoadingResponse: payload })),

    updateBrandRfpProcessSuccessNew: action((state, payload) => ({ updateBrandRfpProcessSuccess: payload })),
    updateBrandRfpProcessSuccessNewError: action((state, payload) => ({ updateBrandRfpProcessError: payload })),

    SetUpdateBrandQuestionsSuccess: action((state, payload) => ({ updateBrandQuestionsSuccess: payload })),
    SetUpdateBrandQuestionsError: action((state, payload) => ({ updateBrandQuestionsError: payload })),

    // delete a brand rfp
    deleteBrandRfpProcessSuccess: action((state, payload) => ({ deleteBrandRfpProcessSuccessResponse: payload })),
    deleteBrandRfpProcessLoading: action((state, payload) => ({ deleteBrandRfpProcessLoadingResponse: payload })),
    deleteBrandRfpProcessError: action((state, payload) => ({ deleteBrandRfpProcessErrorResponse: payload })),

    // object to store brandRfp Details
    // update brandRfpDetails state
    setBrandRfpDetails: action((state, payload) => {
        return ({ brandRfpDetails: payload });
    }),

    // get rfp locations by program id
    getRfpProcessLocationsByProgramIdSuccess: action((state, payload) => ({ rfpLocationsByProgramIdSuccess: payload })),
    // getRfpProcessLocationsByProgramIdLoading: action((state, payload) => ({ rfpLocationsByProgramIdLoading: payload })),
    getRfpProcessLocationsByProgramIdError: action((state, payload) => ({ rfpLocationsByProgramIdError: payload })),

    // get brand rfp locations by program id
    getBrandRfpProcessLocationsByIdSuccess: action((state, payload) => ({ brandRfpProcessLocationsByIdSuccess: payload })),
    getBrandRfpProcessLocationsByIdLoading: action((state, payload) => ({ brandRfpProcessLocationsByIdLoading: payload })),
    getBrandRfpProcessLocationsByIdError: action((state, payload) => ({ brandRfpProcessLocationsByIdError: payload })),


    SetGetLocationOverViewListSuccess: action((state, payload) => ({ getLocationOverViewListSuccess: payload })),

    SetGetLocationOverViewListError: action((state, payload) => ({ getLocationOverViewListError: payload })),

    SetGetNegotiationListSuccess: action((state, payload) => ({ getNegotiationListSuccess: payload })),

    SetGetNegotiationListError: action((state, payload) => ({ getNegotiationListSuccess: payload })),

    getBrandRfpProcessSelectedLocationsByIdSuccess: action((state, payload) => ({ brandRfpProcessSelectedLocationsByIdSuccess: payload })),
    getBrandRfpProcessSelectedLocationsByIdLoading: action((state, payload) => ({ brandRfpProcessSelectedLocationsByIdLoading: payload })),
    getBrandRfpProcessSelectedLocationsByIdError: action((state, payload) => ({ brandRfpProcessSelectedLocationsByIdError: payload })),

    SetListBrandQuestionsByRfpIdSuccess: action((state, payload) => ({ listBrandQuestionsByRfpIdSuccess: payload })),
    SetListBrandQuestionsByRfpIdError: action((state, payload) => ({ listBrandQuestionsByRfpIdError: payload })),

    ///////////// BrandRFPRate By InventoryId ///////
    setBrandRFPRateByInventoryIdSuccess: action((state, payload) => ({ getBrandRFPRateByInventoryIdSuccess: payload })),
    setBrandRFPRateByInventoryIdError: action((state, payload) => ({ getBrandRFPRateByInventoryIdError: payload })),

    ///////////// createUpdatePropertyStandardRateForRFP ///////
    setCreateUpdatePropertyStandardRateForRFPSuccess: action((state, payload) => ({ getCreateUpdatePropertyStandardRateForRFPSuccess: payload })),
    setCreateUpdatePropertyStandardRateForRFPError: action((state, payload) => ({ getCreateUpdatePropertyStandardRateForRFPError: payload })),

    ///////////// createUpdatePropertySeasonalRateForRFP ///////
    setCreateUpdatePropertySeasonalRateForRFPSuccess: action((state, payload) => ({ getCreateUpdatePropertySeasonalRateForRFPSuccess: payload })),
    setCreateUpdatePropertySeasonalRateForRFPError: action((state, payload) => ({ getCreateUpdatePropertySeasonalRateForRFPError: payload })),

    ///////////// DeletePropertySeasonalRateForRFP ///////
    setDeletePropertySeasonalRateForRFPSuccess: action((state, payload) => ({ getDeletePropertySeasonalRateForRFPSuccess: payload })),
    setDeletePropertySeasonalRateForRFPError: action((state, payload) => ({ getDeletePropertySeasonalRateForRFPError: payload })),

    ///////////// RFPSubmission ///////
    setAddAccountManagerForClientSuccess: action((state, payload) => ({ addAccountManagerForClientSuccess: payload })),
    setAddAccountManagerForClientError: action((state, payload) => ({ addAccountManagerForClientError: payload })),

    setRFPSubmissionSuccess: action((state, payload) => ({ getRFPSubmissionSuccess: payload })),
    setRFPSubmissionError: action((state, payload) => ({ getRFPSubmissionError: payload })),

    setBrandLocationByRfpIdSuccess: action((state, payload) => ({ getBrandLocationByRfpIdSuccess: payload })),
    setBrandLocationByRfpIdError: action((state, payload) => ({ getBrandLocationByRfpIdError: payload })),

    setSubmissionOverviewByRfpIdSuccess: action((state, payload) => ({ getSubmissionOverviewByRfpIdSuccess: payload })),
    setSubmissionOverviewByRfpIdError: action((state, payload) => ({ getSubmissionOverviewByRfpIdError: payload })),

    ///////////// RFPAdminReview ///////
    setRFPAdminReviewSuccess: action((state, payload) => ({ getRFPAdminReviewSuccess: payload })),
    setRFPAdminReviewError: action((state, payload) => ({ getRFPAdminReviewError: payload })),
    setRFPAdminFilterReviewSuccess: action((state, payload) => ({ getRFPAdminFilterReviewSuccess: payload })),
    setRFPAdminExcReviewSuccess: action((state, payload) => ({ getRFPAdminExReviewSuccess: payload })),
    setRFPAdminFilterReviewError: action((state, payload) => ({ getRFPAdminFilterReviewError: payload })),

    ///////////// RFPAdminReview Submit ///////
    setRFPAdminReviewSubmitSuccess: action((state, payload) => ({ getRFPAdminReviewSubmitSuccess: payload })),
    setRFPAdminReviewSubmitError: action((state, payload) => ({ getRFPAdminReviewSubmitError: payload })),

    ///////////// RFPAdminReview Status update ///////
    setRFPAdminReviewStatusUpdateSuccess: action((state, payload) => ({ getRFPAdminReviewStatusUpdateSuccess: payload })),
    setRFPAdminReviewStatusUpdateError: action((state, payload) => ({ getRFPAdminReviewStatusUpdateError: payload })),

    ///////////// RFPAdminReview release update///////
    setRFPAdminReviewReleaseUpdateSuccess: action((state, payload) => ({ getRFPAdminReviewReleaseUpdateSuccess: payload })),
    setRFPAdminReviewReleaseUpdateError: action((state, payload) => ({ getRFPAdminReviewReleaseUpdateError: payload })),

    setBrandRfpStatusCheckSuccess: action((state, payload) => ({ getBrandRfpStatusCheckSuccess: payload })),
    setBrandRfpStatusCheckSError: action((state, payload) => ({ getBrandRfpStatusCheckSError: payload })),

    setBrandSubmissionSuccess: action((state, payload) => ({ getBrandSubmissionSuccess: payload })),
    setBrandSubmissionError: action((state, payload) => ({ getBrandSubmissionError: payload })),

    setPropertySubmissionSuccess: action((state, payload) => ({ getPropertySubmissionSuccess: payload })),
    setPropertySubmissionError: action((state, payload) => ({ getPropertySubmissionError: payload })),

    setProgrammePropertyListSuccess: action((state, payload) => ({ getProgrammePropertyListSuccess: payload })),
    setProgrammePropertyListError: action((state, payload) => ({ getProgrammePropertyListError: payload })),

    setGetCorporateProgrammePropertyListByRfpIdSuccess: action((state, payload) => ({ getCorporateProgrammePropertyListByRfpIdSuccess: payload })),
    setGetCorporateProgrammePropertyListByRfpIdSuccessError: action((state, payload) => ({ getCorporateProgrammePropertyListByRfpIdError: payload })),

    setCostComparisonSuccess: action((state, payload) => ({ getCostComparisonSuccess: payload })),
    setCostComparisonError: action((state, payload) => ({ getCostComparisonError: payload })),

    setGetCorporateProgrammePropertyByCitySuccess: action((state, payload) => ({ getCorporateProgrammePropertyByCitySuccess: payload })),
    setGetCorporateProgrammePropertyByCityError: action((state, payload) => ({ getCorporateProgrammePropertyByCityError: payload })),

    setPropertyInventoryRatesSuccess: action((state, payload) => ({ getPropertyInventoryRatesSuccess: payload })),
    setPropertyInventoryRatesError: action((state, payload) => ({ getPropertyInventoryRatesError: payload })),

    setAcceptPropertyRateForRFPSuccess: action((state, payload) => ({ acceptPropertyRateForRFPSuccess: payload })),
    setAcceptPropertyRateForRFPError: action((state, payload) => ({ acceptPropertyRateForRFPError: payload })),

    setDeclinePropertyRateForRFPSuccess: action((state, payload) => ({ declinePropertyRateForRFPSuccess: payload })),
    setDeclinePropertyRateForRFPError: action((state, payload) => ({ declinePropertyRateForRFPError: payload })),


    setProposedPropertyRateForRFPSuccess: action((state, payload) => ({ proposedPropertyRateForRFPSuccess: payload })),
    setProposedPropertyRateForRFPError: action((state, payload) => ({ proposedPropertyRateForRFPError: payload })),

    setNegotiatePropertyRateForRFPSuccess: action((state, payload) => ({ negotiatePropertyRateForRFPSuccess: payload })),
    setNegotiatePropertyRateForRFPError: action((state, payload) => ({ negotiatePropertyRateForRFPError: payload })),

    setRespondCreateUpdatePropertyAcceptDeclineOfRFPSuccess: action((state, payload) => ({ respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess: payload })),
    setRespondCreateUpdatePropertyAcceptDeclineOfRFPError: action((state, payload) => ({ respondCreateUpdatePropertyAcceptDeclineOfRFPError: payload })),

    setAcceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess: action((state, payload) => ({ acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess: payload })),
    setAcceptCreateUpdatePropertyAcceptDeclineOfRFPError: action((state, payload) => ({ acceptCreateUpdatePropertyAcceptDeclineOfRFPError: payload })),

    setDeclineCreateUpdatePropertyAcceptDeclineOfRFPSuccess: action((state, payload) => ({ declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess: payload })),
    setDeclineCreateUpdatePropertyAcceptDeclineOfRFPError: action((state, payload) => ({ declineCreateUpdatePropertyAcceptDeclineOfRFPError: payload })),

    setReviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess: action((state, payload) => ({ reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess: payload })),
    setReviewCreateUpdatePropertyAcceptDeclineOfRFPError: action((state, payload) => ({ reviewCreateUpdatePropertyAcceptDeclineOfRFPError: payload })),

    setNegotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess: action((state, payload) => ({ negotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess: payload })),
    setNegotiateCreateUpdatePropertyAcceptDeclineOfRFPError: action((state, payload) => ({ negotiateCreateUpdatePropertyAcceptDeclineOfRFPError: payload })),

    setPartAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess: action((state, payload) => ({ partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess: payload })),
    setPartAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError: action((state, payload) => ({ partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError: payload })),

    setGetPropertyAcceptDeclineOfRFPByPropertyIdSuccess: action((state, payload) => ({ getPropertyAcceptDeclineOfRFPByPropertyIdSuccess: payload })),
    setGetPropertyAcceptDeclineOfRFPByPropertyIdError: action((state, payload) => ({ getPropertyAcceptDeclineOfRFPByPropertyIdError: payload })),
    setPropertyRfpInventorySuccess: action((state, payload) => ({ getPropertyRfpInventorySuccess: payload })),
    setPropertyRfpInventoryError: action((state, payload) => ({ getPropertyRfpInventoryError: payload })),


    setGetPropertyInventoryRatesForRFPSuccess: action((state, payload) => ({ getPropertyInventoryRatesForRFPSuccess: payload })),
    setGetPropertyInventoryRatesForRFPError: action((state, payload) => ({ getPropertyInventoryRatesForRFPError: payload })),

    setAcceptPropertyForRFPSuccess: action((state, payload) => ({ acceptPropertyForRFPSuccess: payload })),
    setAcceptPropertyForRFPError: action((state, payload) => ({ acceptPropertyForRFPError: payload })),

    setDeclinePropertyForRFPSuccess: action((state, payload) => ({ declinePropertyForRFPSuccess: payload })),
    setDeclinePropertyForRFPError: action((state, payload) => ({ declinePropertyForRFPError: payload })),

    setReviewPropertyForRFPSuccess: action((state, payload) => ({ reviewPropertyForRFPSuccess: payload })),
    setReviewPropertyForRFPError: action((state, payload) => ({ reviewPropertyForRFPError: payload })),

    setPartAcceptedPropertyForRFPSuccess: action((state, payload) => ({ partAcceptedPropertyForRFPSuccess: payload })),
    setPartAcceptedPropertyForRFPError: action((state, payload) => ({ partAcceptedPropertyForRFPError: payload })),
    // setUpdateBrandRfpProcessInventoryStatusSuccess: action((state, payload) => ({ updateBrandRfpProcessInventoryStatusSuccess: payload })),
    // setUpdateBrandRfpProcessInventoryStatusError: action((state, payload) => ({ updateBrandRfpProcessInventoryStatusError: payload })),

    setGetPropertySuccess: action((state, payload) => ({ getPropertySuccess: payload })),
    setGetPropertyError: action((state, payload) => ({ getPropertyError: payload })),

    setSurveyStatusCheckSuccess: action((state, payload) => ({ getSurveyStatusCheckSuccess: payload })),
    setSurveyStatusCheckError: action((state, payload) => ({ getSurveyStatusCheckError: payload })),

    /**
     * declineOpenBrandRfp
     */
    getDeclineOpenBrandRfpSuccess: null,
    getDeclineOpenBrandRfpError: null,

    setDeclineOpenBrandRfpSuccess: action((state, payload) => ({ getDeclineOpenBrandRfpSuccess: payload })),
    setDeclineOpenBrandRfpError: action((state, payload) => ({ getDeclineOpenBrandRfpError: payload })),

    declineOpenBrandRfp: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', '/rfpProcess-sv/brand-rfpProcess/declineOpenBrandRfp', payload, null);
            if (result.data.status === 400) {
                actions.setDeclineOpenBrandRfpError(result.data);
            } else {
                actions.setDeclineOpenBrandRfpSuccess(result.data);
            }
        } catch (error: any) {
            actions.setDeclineOpenBrandRfpError(error.response);
        }

    }),
    // survey status check
    surveyStatusCheck: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', '/rfpProcess-sv/myo-survey-check-status', payload, null);
            if (result.data.status === 400) {
                actions.setSurveyStatusCheckError(result.data);
            } else {
                actions.setSurveyStatusCheckSuccess(result.data);
            }
        } catch (error: any) {
            actions.setSurveyStatusCheckError(error.response);
        }

    }),

    getProperty: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/get-property?brandRfpId=${payload?.brandRfpId}&rfpId=${payload?.rfpId}&propertyId=${payload?.propertyId}&cityName=${payload?.cityName}`, null, null);
            actions.setGetPropertySuccess(result.data);
        } catch (error: any) {
            actions.setGetPropertyError(error.response.data);
        }
    }
    ),

    propertyRfpInventory: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/get-property-inventory?propertyId=${payload.propertyId}&brandRfpId=${payload.brandRfpId}&rfpId=${payload.rfpId}&cityName=${payload.cityName}`, null, null);
            actions.setPropertyRfpInventorySuccess(result.data);
        } catch (error: any) {
            actions.setPropertyRfpInventoryError(error.response.data)
        }

    }),

    //property-evaluation-status-update
    propertyEvaluationStatusUpdate: thunk(async (actions: any, payload: any) => {
        try {
            actions.createRfpProcessLoading(true);
            const result = await call('patch', '/rfpProcess-sv/brand-rfpProcess/property-evaluation-status-update', payload, null);
            actions.setPropertyEvaluationStatusUpdateSuccess(result.data);
        } catch (error: any) {
            actions.setPropertyEvaluationStatusUpdateError(error.response.data);
        }

    }),
    //thunks
    brandRfpStatusCheck: thunk(async (actions: any, payload: any) => {
        try {
            actions.createRfpProcessLoading(true);
            const result = await call('post', '/rfpProcess-sv/brand-rfp-status-check', payload, null);
            actions.setBrandRfpStatusCheckSuccess(result.data);
        } catch (error: any) {
            actions.setBrandRfpStatusCheckSError(error.response.data);
        }

    }),
    //getting rfp process list
    getRfpProcessList: thunk(async (actions: any, payload: any) => {
        actions.setRfpProcessListLording(true);
        try {
            const result = await call('get', '/rfpProcess-sv/rfpProcess', payload, null);
            actions.setRfpProcessList(result.data);
            actions.setRfpProcessListLording(false);
        } catch (error: any) {
            actions.setRfpProcessListError(error.response.data)
            actions.setRfpProcessListLording(false);
        }

    }),
    //creating a rfp process
    createRfpProcess: thunk(async (actions: any, payload: any) => {
        try {
            actions.createRfpProcessLoading(true);
            const result = await call('post', '/rfpProcess-sv/rfpProcess', payload, null);
            actions.createRfpProcessSuccess(result.data);
            actions.createRfpProcessLoading(false);
        } catch (error: any) {
            actions.createRfpProcessLoading(false);
            actions.createRfpProcessError(error.response.data);
        }

    }),
    //deleting a rfp process
    deleteRfpProcess: thunk(async (actions: any, payload: any) => {
        try {
            actions.deleteRfpProcessLoading(true);
            const result = await call('delete', `/rfpProcess-sv/rfpProcess`, payload, null);
            actions.deleteRfpProcessLoading(false);
            actions.deleteRfpProcessSuccess(result.data);
        } catch (error: any) {
            actions.deleteRfpProcessLoading(false);
            actions.deleteRfpProcessError(error.response.data);
        }
    }),
    //updating rfp process
    updateRfpProcess: thunk(async (actions: any, payload: any) => {
        try {
            actions.updateRfpProcessLoading(true);
            const result = await call('patch', `/rfpProcess-sv/rfpProcess`, payload, null);
            actions.updateRfpProcessSuccess(result.data);
            actions.updateRfpProcessLoading(false);
        }
        catch (error: any) {
            actions.updateRfpProcessLoading(false);
            actions.updateRfpProcessError(error.response.data);
        }
    }),
    // get rfp process by id
    getRfpProcessById: thunk(async (actions: any, payload: any) => {
        // actions.getRfpProcessByIdLoading(true);
        try {
            const result = await call('get', `/rfpProcess-sv/rfpProcess/${payload}`, null, null);
            // actions.getRfpProcessByIdLoading(false);
            actions.getRfpProcessByIdSuccess(result.data);
        } catch (error: any) {
            // actions.getRfpProcessByIdLoading(false);
            actions.getRfpProcessByIdError(error.response.data);
        }
    }
    ),
    // get rfp process by programme id
    getRfpProcessByProgrammeId: thunk(async (actions: any, payload: any) => {
        actions.getRfpProcessByProgrammeIdLoading(true);
        try {
            const result = await call('get', `/rfpProcess-sv/rfpProcess/programme/${payload}?RFPStatus=&UserType=`, null, null);
            actions.getRfpProcessByProgrammeIdLoading(false);
            actions.getRfpProcessByProgrammeIdSuccess(result.data);
        } catch (error: any) {
            actions.getRfpProcessByProgrammeIdLoading(false);
            actions.getRfpProcessByProgrammeIdError(error.response.data);
        }
    }
    ),

    ///////////////////////////////////////// corporate property management ///////////////////////
    rfpProcessByProgrammeIdAndStatus: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/rfpProcess/programme/${payload.programmeId}?RFPStatus=${payload.RFPStatus}&UserType=${payload.UserType}&searchType=${payload.searchType}`, null, null);
            actions.getRFPProcessByProgramIdAndStatusSuccess(result.data);
        } catch (error: any) {
            actions.getRFPProcessByProgramIdAndStatusError(error.response.data);
        }
    }
    ),

    // brand rfp management
    getMyRfpProcessList: thunk(async (actions: any, payload: any) => {
        actions.getMyRfpProcessListLoading(true);
        try {
            const result = await call('get', `/rfpProcess-sv/brand-rfpProcess/${payload}`, null, null);
            actions.getMyRfpProcessListLoading(true);
            actions.getMyRfpProcessListSuccess(result.data);
        } catch (error: any) {
            actions.getMyRfpProcessListError(error.response.data);
        }
    }
    ),

    // brand rfp process by id
    getBrandRfpProcessById: thunk(async (actions: any, payload: any) => {
        actions.getBrandRfpProcessByIdLoading(true);
        try {
            const result = await call('get', `/rfpProcess-sv/brand-rfpProcess/rfpId/${payload}`, null, null);
            actions.getBrandRfpProcessByIdLoading(false);
            actions.getBrandRfpProcessByIdSuccess(result.data);
        } catch (error: any) {
            actions.getBrandRfpProcessByIdLoading(false);
            actions.getBrandRfpProcessByIdError(error.response.data);
        }
    }
    ),

    //  create a brand rfp
    createBrandRfpProcess: thunk(async (actions: any, payload: any) => {
        actions.createBrandRfpProcessLoading(true);
        try {
            const result = await call('post', '/rfpProcess-sv/brand-rfpProcess', payload, null);
            actions.createBrandRfpProcessSuccess(result.data);
            actions.createBrandRfpProcessLoading(false);
        } catch (error: any) {
            actions.createBrandRfpProcessError(error.response.data);
            actions.createBrandRfpProcessLoading(false);
        }
    }
    ),

    // update a brand rfp
    updateBrandRfpProcess: thunk(async (actions: any, payload: any) => {
        actions.updateBrandRfpProcessLoading(true);
        try {
            const result = await call('patch', '/rfpProcess-sv/brand-rfpProcess', payload, null);
            actions.updateBrandRfpProcessSuccessNew(result.data);
            actions.updateBrandRfpProcessLoading(false);
        } catch (error: any) {
            actions.updateBrandRfpProcessSuccessNewError(error.response.data);
            actions.updateBrandRfpProcessLoading(false);
        }
    }
    ),

    updateProperty: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', '/property-sv/property', payload, null);
            actions.setUpdateProperty(result.data);
        } catch (error: any) {
            actions.setUpdatePropertyError(error.response.data);
        }
    }),

    // delete a brand rfp
    deleteBrandRfpProcess: thunk(async (actions: any, payload: any) => {
        actions.deleteBrandRfpProcessLoading(true);
        try {
            const result = await call('delete', `/rfpProcess-sv/brand-rfpProcess`, payload, null);
            actions.deleteBrandRfpProcessSuccess(result?.data?.data);
            actions.deleteBrandRfpProcessLoading(false);
        } catch (error: any) {
            actions.deleteBrandRfpProcessError(error.response.data);
            actions.deleteBrandRfpProcessLoading(false);

        }
    }),

    // get rfp locations by program id
    getRfpLocationsByProgramId: thunk(async (actions: any, payload: any) => {
        // actions.getRfpProcessLocationsByProgramIdLoading(true);
        try {
            const result = await call('get', `/rfpProcess-sv/rfpProcess/locations/${payload}`, null, null);
            // actions.getRfpProcessLocationsByProgramIdLoading(false);
            actions.getRfpProcessLocationsByProgramIdSuccess(result.data);
        } catch (error: any) {
            // actions.getRfpProcessLocationsByProgramIdLoading(false);
            actions.getRfpProcessLocationsByProgramIdError(error.response.data);
        }
    }
    ),

    // get rfp locations by brand rfp id
    getRfpLocationsByBrandRfpId: thunk(async (actions: any, payload: any) => {
        actions.getBrandRfpProcessLocationsByIdLoading(true);
        try {
            const result = await call('get', `/rfpProcess-sv/brand-rfpProcess/locations/${payload.id}/${payload.rfpId}`, null, null);
            actions.getBrandRfpProcessLocationsByIdLoading(false);
            actions.getBrandRfpProcessLocationsByIdSuccess(result.data);
        } catch (error: any) {
            actions.getBrandRfpProcessLocationsByIdLoading(false);
            actions.getBrandRfpProcessLocationsByIdError(error.response.data);

        }
    }),


    getLocationOverViewList: thunk(async (actions: any, payload: any) => {

        try {
            const result = await call('get', `/rfpProcess-sv/brand-rfpProcess/location/${payload.clientId}/${payload.programmeId}`, null, null);

            actions.SetGetLocationOverViewListSuccess(result.data);
        } catch (error: any) {

            actions.SetGetLocationOverViewListError(error.response.data);

        }
    }),


    getNegotiationList: thunk(async (actions: any, payload: any) => {

        try {
            const result = await call('get', `/rfpProcess-sv/brand-rfpProcess/negotiation/${payload.clientId}/${payload.programmeId}`, null, null);

            actions.SetGetNegotiationListSuccess(result.data);
        } catch (error: any) {

            actions.SetGetNegotiationListError(error.response.data);

        }
    }),

    getBrandRfpProcessSelectedLocationsById: thunk(async (actions: any, payload: any) => {
        actions.getBrandRfpProcessSelectedLocationsByIdLoading(true);
        try {
            const result = await call('get', `/rfpProcess-sv/brand-rfpProcess/selectedLocations/${payload.id}`, null, { payload });
            actions.getBrandRfpProcessSelectedLocationsByIdLoading(false);
            actions.getBrandRfpProcessSelectedLocationsByIdSuccess(result.data);
        } catch (error: any) {
            actions.getBrandRfpProcessSelectedLocationsByIdLoading(false);
            actions.getBrandRfpProcessSelectedLocationsByIdError(error.response.data);
        }
    }),

    updateBrandQuestions: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', '/rfpProcess-sv/brand-rfpProcess/brandQuestions', payload, null);
            actions.SetUpdateBrandQuestionsSuccess(result.data);
        } catch (error: any) {
            actions.SetUpdateBrandQuestionsError(error.response.data);
        }
    }),

    // list BrandQuestions By RfpId///////////////////////////////
    listBrandQuestionsByRfpId: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/brand-rfpProcess/listBrandQuestions/${payload}`, null, null);
            actions.SetListBrandQuestionsByRfpIdSuccess(result.data);
        } catch (error: any) {
            actions.SetListBrandQuestionsByRfpIdError(error.response.data);
        }
    }
    ),
    ///////////// BrandRFPRate By InventoryId ///////
    brandRFPRateByInventoryId: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/getBrandRFPRateByInventoryId`, payload, null);
            actions.setBrandRFPRateByInventoryIdSuccess(result.data);
        } catch (error: any) {
            actions.setBrandRFPRateByInventoryIdError(error.response.data);
        }
    }
    ),

    ///////////// createUpdatePropertyStandardRateForRFP ///////
    createUpdatePropertyStandardRateForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/createUpdatePropertyStandardRateForRFP`, payload, null);
            actions.setCreateUpdatePropertyStandardRateForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setCreateUpdatePropertyStandardRateForRFPError(error.response.data);
        }
    }
    ),

    ///////////// createUpdatePropertySeasonalRateForRFP ///////
    createUpdatePropertySeasonalRateForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/createUpdatePropertySeasonalRateForRFP`, payload, null);
            actions.setCreateUpdatePropertySeasonalRateForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setCreateUpdatePropertySeasonalRateForRFPError(error.response.data);
        }
    }
    ),

    ///////////// deletePropertySeasonalRateForRFP ///////
    deletePropertySeasonalRateForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('delete', `/rfpProcess-sv/brand-rfpProcess/deletePropertySeasonalRateForRFP`, payload, null);
            actions.setDeletePropertySeasonalRateForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setDeletePropertySeasonalRateForRFPError(error.response.data);
        }
    }
    ),

    ///////////// RFP Submission ///////
    submitRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/rfp-submission`, payload, null);
            if (payload.type === 'RFP_SUBMISSION') {
                actions.setRFPSubmissionSuccess(result.data);

                //TODO socket Notification
                // let notificationData = `<b> The RFP for ${payload?.email} has been Submitted </b>`;
                //    await socket.emit("send_message", notificationData);
            }
            if (payload.type === 'BRAND_SURVEY_SECTION_DETAIL_SUBMISSION') {
                actions.setBrandSubmissionSuccess(result.data);
            }
            if (payload.type === 'PROPERTY_SURVEY_SECTION_DETAIL_SUBMISSION') {
                actions.setPropertySubmissionSuccess(result.data);
            }
        } catch (error: any) {
            if (payload.type === 'RFP_SUBMISSION') {
                actions.setRFPSubmissionError(error.response.data);
            }
            if (payload.type === 'BRAND_SURVEY_SECTION_DETAIL_SUBMISSION') {
                actions.setBrandSubmissionError(error.response.data);
            }
            if (payload.type === 'PROPERTY_SURVEY_SECTION_DETAIL_SUBMISSION') {
                actions.setPropertySubmissionError(error.response.data);
            }
        }
    }
    ),


    ///////////// add account Manager///////
    addAccountManagerForClient: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/rfp-accountManager`, payload, null);
            actions.setAddAccountManagerForClientSuccess(result.data);
        } catch (error: any) {
            actions.setAddAccountManagerForClientError(error.response.data);
        }
    }
    ),

    ///////////// get brand location by rfp id ///////
    getBrandLocationByRfpId: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/get-brand-location-by-rfp-id/${payload.rfpProcessId}`, null, null);
            actions.setBrandLocationByRfpIdSuccess(result.data);
        } catch (error: any) {
            actions.setBrandLocationByRfpIdError(error.response.data);
        }
    }
    ),

    ///////////// get submission overview by rfp id ////////
    getSubmissionOverviewByRfpId: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/get-submission-overview-by-rfp-id/${payload.rfpProcessId}`, null, null);
            actions.setSubmissionOverviewByRfpIdSuccess(result.data);
        } catch (error: any) {
            actions.setSubmissionOverviewByRfpIdError(error.response.data);
        }
    }
    ),

    ///////////// get submission overview by rfp id ////////
    getRFPAdminReview: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/RFPAdminReview/${payload.rfpProcessId}`, null, null);
            actions.setRFPAdminReviewSuccess(result.data);
        } catch (error: any) {
            actions.setRFPAdminReviewError(error.response.data);
        }
    }
    ),

    getRFPAdminFilterReview: thunk(async (actions: any, payload: any) => {
        try {
            let result;
            if (payload.reviewType && payload.locationName) {
                result = await call('get', `/rfpProcess-sv/RFPAdminReview/${payload.rfpProcessId}?reviewType=${payload.reviewType}&locationName=${payload.locationName}`, null, null);
                actions.setRFPAdminFilterReviewSuccess(result.data);
            } else if (payload.reviewType) {
                result = await call('get', `/rfpProcess-sv/RFPAdminReview/${payload.rfpProcessId}?reviewType=${payload.reviewType}`, null, null);
                actions.setRFPAdminFilterReviewSuccess(result.data);
                actions.setRFPAdminExcReviewSuccess(result.data);
            } else {
                result = await call('get', `/rfpProcess-sv/RFPAdminReview/${payload.rfpProcessId}`, null, null);
                actions.setRFPAdminFilterReviewSuccess(result.data);
            }
        } catch (error: any) {
            actions.setRFPAdminFilterReviewError(error.response.data);
        }
    }
    ),

    ///////////// RFPAdminReview Submit ///////
    RFPAdminReviewSubmit: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/RFPAdminReview/upsert`, payload, null);
            actions.setRFPAdminReviewSubmitSuccess(result.data);
        } catch (error: any) {
            // actions.setRFPAdminReviewSubmitError(error.response.data);
        }
    }
    ),

    ///////////// RFPAdminReview Status update ///////
    RFPAdminReviewStatusUpdate: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/rfpProcess-sv/RFPAdminReview/statusUpdate`, payload, null);
            actions.setRFPAdminReviewStatusUpdateSuccess(result.data);
        } catch (error: any) {
            // actions.setRFPAdminReviewStatusUpdateError(error.response.data);
        }
    }
    ),

    ///////////// RFPAdminReview release update///////
    RFPAdminReviewReleaseUpdate: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/rfpProcess-sv/RFPAdminReview/releaseUpdate`, payload, null);
            actions.setRFPAdminReviewReleaseUpdateSuccess(result.data);
        } catch (error: any) {
            // actions.setRFPAdminReviewReleaseUpdateError(error.response.data);
        }
    }
    ),

    // get rfp related properties
    programmePropertyList: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/get-programme-property-list`, payload, null);
            actions.setProgrammePropertyListSuccess(result.data);
        } catch (error: any) {
            actions.setProgrammePropertyListError(error.response.data);
        }
    }
    ),

    // get rfp related properties
    getCorporateProgrammePropertyByRfpId: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/get-corporateProgrammeByRfpId`, payload, null);
            actions.setGetCorporateProgrammePropertyListByRfpIdSuccess(result.data);
        } catch (error: any) {
            actions.setGetCorporateProgrammePropertyListByRfpIdError(error.response.data);
        }
    }
    ),

    // get cost comparison
    getCostComparison: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/cost-comparison/${payload.rfp_id}?cityName=${payload.cityName}&roomType=${payload.roomType}&checkIn=${payload.checkIn}&fullyManageByThirdParty=${payload.fullyManageByThirdParty}&propertyTypeIds=${payload.propertyTypeIds}`, null, null);
            actions.setCostComparisonSuccess(result.data);
        } catch (error: any) {
            actions.setCostComparisonError(error.response.data);
        }
    }
    ),

    // get cost comparison
    getCorporateProgrammePropertyByCity: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/getCorporateProgrammePropertyByCity/${payload.rfp_id}?countryCode=${payload.countryCode}&cityName=${payload.cityName}&checkIns=${payload.checkIns}&propertyTypeIds=${payload.propertyTypeIds}`, null, null);
            actions.setGetCorporateProgrammePropertyByCitySuccess(result.data);
        } catch (error: any) {
            actions.setGetCorporateProgrammePropertyByCityError(error.response.data);
        }
    }
    ),

    // get property inventory rate 
    propertyInventoryRates: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/property-inventory-rates/${payload.rfp_id}/${payload.propertyId}`, null, null);
            actions.setPropertyInventoryRatesSuccess(result.data);
        } catch (error: any) {
            actions.setPropertyInventoryRatesError(error.response.data);
        }
    }
    ),

    ///////////// acceptPropertyRateForRFP ///////
    acceptPropertyRateForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/rfpProcess-sv/brand-rfpProcess/updateBrandRfpProcessInventoryStatus`, payload, null);
            actions.setAcceptPropertyRateForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setAcceptPropertyRateForRFPError(error.response.data);
        }
    }
    ),


    ///////////// DeclinePropertyRateForRFP ///////
    declinePropertyRateForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/rfpProcess-sv/brand-rfpProcess/updateBrandRfpProcessInventoryStatus`, payload, null);
            actions.setDeclinePropertyRateForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setDeclinePropertyRateForRFPError(error.response.data);
        }
    }
    ),

    ///////////// ProposedPropertyRateForRFP ///////
    proposedPropertyRateForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/rfpProcess-sv/brand-rfpProcess/updateBrandRfpProcessInventoryStatus`, payload, null);
            actions.setProposedPropertyRateForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setProposedPropertyRateForRFPError(error.response.data);
        }
    }
    ),


    ///////////// negotiatePropertyRateForRFP ///////
    negotiatePropertyRateForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/rfpProcess-sv/brand-rfpProcess/updateBrandRfpProcessInventoryStatus`, payload, null);
            actions.setNegotiatePropertyRateForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setNegotiatePropertyRateForRFPError(error.response.data);
        }
    }
    ),

    /////////////acceptCreateUpdatePropertyAcceptDeclineOfRFP ///////
    respondCreateUpdatePropertyAcceptDeclineOfRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/createUpdatePropertyAcceptDeclineOfRFP`, payload, null);
            actions.setRespondCreateUpdatePropertyAcceptDeclineOfRFPSuccess(result.data);
        } catch (error: any) {
            actions.setRespondCreateUpdatePropertyAcceptDeclineOfRFPError(error.response.data);
        }
    }
    ),
    /////////////acceptCreateUpdatePropertyAcceptDeclineOfRFP ///////
    acceptCreateUpdatePropertyAcceptDeclineOfRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/createUpdatePropertyAcceptDeclineOfRFP`, payload, null);
            actions.setAcceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess(result.data);
        } catch (error: any) {
            actions.setAcceptCreateUpdatePropertyAcceptDeclineOfRFPError(error.response.data);
        }
    }
    ),


    /////////////declineCreateUpdatePropertyAcceptDeclineOfRFP ///////
    declineCreateUpdatePropertyAcceptDeclineOfRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/createUpdatePropertyAcceptDeclineOfRFP`, payload, null);
            actions.setDeclineCreateUpdatePropertyAcceptDeclineOfRFPSuccess(result.data);
        } catch (error: any) {
            actions.setDeclineCreateUpdatePropertyAcceptDeclineOfRFPError(error.response.data);
        }
    }
    ),

    /////////////reviewCreateUpdatePropertyAcceptDeclineOfRFP ///////
    reviewCreateUpdatePropertyAcceptDeclineOfRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/createUpdatePropertyAcceptDeclineOfRFP`, payload, null);
            actions.setReviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess(result.data);
        } catch (error: any) {
            actions.setReviewCreateUpdatePropertyAcceptDeclineOfRFPError(error.response.data);
        }
    }
    ),


    /////////////negotiateCreateUpdatePropertyAcceptDeclineOfRFP ///////
    negotiateCreateUpdatePropertyAcceptDeclineOfRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/createUpdatePropertyAcceptDeclineOfRFP`, payload, null);
            actions.setNegotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess(result.data);
        } catch (error: any) {
            actions.setNegotiateCreateUpdatePropertyAcceptDeclineOfRFPError(error.response.data);
        }
    }
    ),

    /////////////partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP ///////
    partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/createUpdatePropertyAcceptDeclineOfRFP`, payload, null);
            actions.setPartAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess(result.data);
        } catch (error: any) {
            actions.setPartAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError(error.response.data);
        }
    }
    ),

    // getPropertyAcceptDeclineOfRFPByPropertyId
    getPropertyAcceptDeclineOfRFPByPropertyId: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/brand-rfpProcess/getPropertyAcceptDeclineOfRFPByPropertyId/${payload.rfpId}/${payload.propertyId}`, null, null);
            actions.setGetPropertyAcceptDeclineOfRFPByPropertyIdSuccess(result.data);
        } catch (error: any) {
            // actions.setGetPropertyAcceptDeclineOfRFPByPropertyIdError(error.response.data);
        }
    }
    ),

    updateReportStatus: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/rfpProcess/update-report-status`, payload, null);
            actions.setUpdateReportStatusSuccess(result.data);
        } catch (error: any) {
            actions.setUpdateReportStatusSuccessError(error.response.data);
        }
    }
    ),


    ////////////////////getPropertyInventoryRatesForRFP///////////////////////////////////////////////////
    getPropertyInventoryRatesForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/rfpProcess-sv/get-rfp-property-inventory-rates?brandId=${payload.brandId}&propertyId=${payload.propertyId}&rfpProcessId=${payload.rfpProcessId}&cityName=${payload.cityName}`, null, null);
            actions.setGetPropertyInventoryRatesForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setGetPropertyInventoryRatesForRFPError(error.response.data)
        }

    }),


    /////////////acceptPropertyForRFP ///////
    acceptPropertyForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/rfpProcess-sv/brand-rfpProcess/updateBrandRfpProcessPropertyStatus`, payload, null);
            actions.setAcceptPropertyForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setAcceptPropertyForRFPError(error.response.data);
        }
    }
    ),



    /////////////declinePropertyForRFP ///////
    declinePropertyForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/rfpProcess-sv/brand-rfpProcess/updateBrandRfpProcessPropertyStatus`, payload, null);
            actions.setDeclinePropertyForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setDeclinePropertyForRFPError(error.response.data);
        }
    }
    ),


    /////////////reviewPropertyForRFP ///////
    reviewPropertyForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/rfpProcess-sv/brand-rfpProcess/updateBrandRfpProcessPropertyStatus`, payload, null);
            actions.setReviewPropertyForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setReviewPropertyForRFPError(error.response.data);
        }
    }
    ),

    ///////////// partAcceptedPropertyForRFP ///////
    partAcceptedPropertyForRFP: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/rfpProcess-sv/brand-rfpProcess/updateBrandRfpProcessPropertyStatus`, payload, null);
            actions.setPartAcceptedPropertyForRFPSuccess(result.data);
        } catch (error: any) {
            actions.setPartAcceptedPropertyForRFPError(error.response.data);
        }
    }
    ),

    surveyCreateUpdateDelete: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/myo-survey-create-update-delete`, payload, null);
            actions.setSurveyCreateUpdateDeleteSuccess(result.data);
        } catch (error: any) {
            actions.setSurveyCreateUpdateDeleteError(error.response.data);
        }
    }
    ),


    //getting rfp process list
    getAllCreatedRfpProcessList: thunk(async (actions: any, payload: any) => {

        try {
            const result = await call('get', '/rfpProcess-sv/rfpProcess/list', payload, null);
            actions.setGetAllCreatedRfpProcessListSuccess(result.data);

        } catch (error: any) {
            actions.setGetAllCreatedRfpProcessListError(error.response.data)

        }

    }),


    //getting rfp process list
    getAllSubmittedBrandRfpProcessList: thunk(async (actions: any, payload: any) => {

        try {
            const result = await call('get', '/rfpProcess-sv/brand-rfpProcess/submitted', payload, null);
            actions.setGetAllSubmittedBrandRfpProcessListSuccess(result.data);

        } catch (error: any) {
            actions.setGetAllSubmittedBrandRfpProcessListError(error.response.data)

        }

    }),

    // propertyCheckByLocation
    propertyCheckByLocation: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/rfpProcess-sv/brand-rfpProcess/propertyCheckByLocation`, payload, null);
            actions.setPropertyCheckByLocationSuccess(result.data);
        } catch (error: any) {
            actions.setPropertyCheckByLocationError(error.response.data);
        }
    }
    ),
    // /////////////updateBrandRfpProcessInventoryStatus ///////
    // updateBrandRfpProcessInventoryStatus: thunk(async (actions: any, payload: any) => {
    //     try {
    //         const result = await call('patch', `/rfpProcess-sv/brand-rfpProcess/updateBrandRfpProcessInventoryStatus`, payload, null);
    //         actions.setUpdateBrandRfpProcessInventoryStatusSuccess(result.data);
    //     } catch (error: any) {
    //         actions.setUpdateBrandRfpProcessInventoryStatusError(error.response.data);
    //     }
    // }
    // ),
}

