import { Breadcrumb, Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { FC, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable from 'react-data-table-component';
import { tableStyles, overlayTriggerDelay, formButton } from '../../../../common/components-style';
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RFPSubmission } from '../../../../common/interfaces';
import { getStorage, setStorage } from '../../../../common/storage';
import { toast } from "react-toastify";
// import { getLoggedUserType } from '../../../../common/functions';
import { UserGroups } from '../../../../common/constants';
import { getMailDomain } from '../../../../common/functions';

const checkBoxStyle = { display: 'inline', top: '-3px', marginLeft: '10px', position: 'relative' } as React.CSSProperties;

const BrandAdminBrandEvaluationList: FC<any> = ({ programmeData, data }) => {
    //////////////// form initialize ///////////////////
    const { register, handleSubmit, formState: { errors } } = useForm<RFPSubmission>();

    /////////////////////////////////////////////////////////////////////////
    const history = useHistory();
    ///////////////////////////////////////////////////////////////////////////////////
    const [surveys, setSurveys] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [brandDetailsConfirmationBtn] = useState<boolean>(true);
    const [brandPropertiesIsCompleted] = useState<boolean>(true);
    const [btnEnable, setBtnEnable] = useState<boolean>(false);

    ////////////// local storage ////////////////////////
    const myoSelectedMyRfp = getStorage("myo_selected_my_rfp");
    const myoBrandEvaluationTemp = getStorage("myo_brand_evaluation_tem");
    const myoSelectedBrand = getStorage("myo_selected_my_rfp_brand");
    const isBrand = getStorage('isBrand');

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    const { viewCertificate, submitRFP, getRfpLocationsByBrandRfpId } = useStoreActions<any>((actions) => ({
        viewCertificate: actions.myoManagementModel.viewCertificate,
        submitRFP: actions.rfpProcess.submitRFP,
        getRfpLocationsByBrandRfpId: actions.rfpProcess.getRfpLocationsByBrandRfpId,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getViewCertificateSuccess, getBrandSubmissionSuccess, getBrandSubmissionError } = useStoreState<any>((state) => ({
        getViewCertificateSuccess: state.myoManagementModel.getViewCertificateSuccess,
        getBrandSubmissionSuccess: state.rfpProcess.getBrandSubmissionSuccess,
        getBrandSubmissionError: state.rfpProcess.getBrandSubmissionError,
        // brandRfpProcessLocationsByIdSuccess: state.rfpProcess.brandRfpProcessLocationsByIdSuccess,
    }));

    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const params: any = {};
        params.id = data?.key;
        params.rfpId = data?.rfpId;

        getRfpLocationsByBrandRfpId(params);

        viewCertificate({
            security: {
                apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
                user: "superadmin@mysa.global"
            },
            id: programmeData?.certificateIdMYOManagement
        });
    }, [data?.key, data?.rfpId, getRfpLocationsByBrandRfpId, programmeData?.certificateIdMYOManagement, viewCertificate]);

    ///////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getBrandSubmissionSuccess) {
            setLoading(false);
            toast.success("Your confirmation has been registered", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            const payload = getStorage('myo_brand_evaluation_tem_payload');
            setStorage('myo_brand_evaluation_tem', payload);
        }

        if (getBrandSubmissionError) {
            setLoading(false);
            toast.error(getBrandSubmissionError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [getBrandSubmissionError, getBrandSubmissionSuccess]);


    useEffect(() => {
        if (getViewCertificateSuccess) {
            const result = getViewCertificateSuccess?.certificate?.surveys.filter(value => value.survey?.survey_type === 'BRAND_SURVEY');
            setSurveys(result);

            // check all sections are complete
            // let isComplete = false;
            // if (getViewCertificateSuccess?.certificate?.surveys) {
            //     getViewCertificateSuccess?.certificate?.surveys.forEach(element => {
            //         if (element.isCompleted && element.isStarted && element.survey.survey_type === 'BRAND_SURVEY') {
            //             isComplete = true;
            //         } else {
            //             if (element.survey.survey_type === 'BRAND_SURVEY') {
            //                 isComplete = false;
            //                 return 0;
            //             }
            //         }
            //     });
            //     setBrandDetailsConfirmationBtn(isComplete);
            // }

        }
    }, [getViewCertificateSuccess]);

    // useEffect(() => {

    //     if (brandRfpProcessLocationsByIdSuccess?.data) {
    //         let inComplete = true;
    //         let complete = false;
    //         for (let index = 0; index < brandRfpProcessLocationsByIdSuccess?.data.length; index++) {
    //             if (brandRfpProcessLocationsByIdSuccess?.data[index].evaluationStatus.toString() === 'incomplete') {
    //                 inComplete = false;
    //             } else {
    //                 complete = true;
    //             }
    //         }
    //         const isCompleted = (complete && inComplete) ? true : false;
    //         setBrandPropertiesIsCompleted(isCompleted);
    //     }
    // }, [brandRfpProcessLocationsByIdSuccess?.data]);

    const columns1 = [
        {
            name: 'Section',
            selector: row => row.survey?.name,
        },
        {
            name: 'Status',
            cell: (data: any) => {
                const { isApproved, isCompleted, isStarted } = data;

                let color = "";
                let status = "";

                if ((!isApproved && isCompleted && !isStarted)) {
                    color = 'font-bold uppercase text-red-500';
                    status = 'Wrong Submitted';
                }
                if ((isApproved && isCompleted && isStarted)) {
                    color = 'font-bold uppercase text-green-500';
                    status = 'Approved';
                }
                if ((!isApproved && isCompleted && isStarted)) {
                    color = 'font-bold uppercase text-green-500';
                    status = 'Complete';
                }
                if ((!isApproved && !isCompleted && isStarted)) {
                    color = 'font-bold uppercase text-yellow-500';
                    status = 'Started';
                }
                if ((!isApproved && !isCompleted && !isStarted)) {
                    color = 'font-bold uppercase text-red-500';
                    status = 'Not yet started';
                }

                return (
                    <span style={{
                        fontWeight: 'bold',
                    }} className={color}>{status}</span>
                )
            }
        },
        {
            name: 'Action',
            button: true,
            wrap: true,
            minWidth: '300px',
            cell: (data: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                    >
                        <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                            history.push({
                                pathname: '/admin/myo-questions-form',
                                search: `surveyID=${data?.id}`,
                                state: { selectedBrandId: programmeData?.brandId, defaultKey: 'myRFPs', data: data, programmeData: programmeData, component: 'brand' }
                            });
                        }} />
                    </OverlayTrigger>

                </>)
            }
        },

    ];

    ////////////// form submission ///////////
    const onSubmit = (value: RFPSubmission) => {
        setLoading(true);

        // check domain
        const domains = getStorage('domains');
		const getDomainValidation = getMailDomain(value.email, domains);

		if (getDomainValidation) {
            if (moment(myoSelectedMyRfp.endDate).add(1, 'days').isBefore(moment(new Date()))) {
                toast.warning('Your RFP cannot be submitted as this RFP is now closed', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                setLoading(false);
            } else {
                const payload = {
                    type: 'BRAND_SURVEY_SECTION_DETAIL_SUBMISSION',
                    rfpProcessId: myoSelectedMyRfp.rfpId,
                    brandId: myoSelectedBrand,
                    confirmedBy: value.confirmedBy,
                    email: value.email,
                    brandRfpId: myoSelectedMyRfp?.key.toString(),
                    myoCertificateId: myoSelectedMyRfp?.certificateIdMYOManagement?.toString(),
                }
                setStorage('myo_brand_evaluation_tem_payload', payload);
                submitRFP(payload);
            }
        }else{
            toast.error('Invalid brand domain. please check your e-mail address domain with brand domains', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
            setLoading(false);
        }
    }

    return (
        <div>
            <Row style={{
                marginBottom: '1.5rem',
                marginTop: '20px',
            }} >
                <Col xs={12}><div className="content-title">
                    <Breadcrumb>
                        <Breadcrumb.Item style={{

                            fontWeight: 100,
                        }} >
                            <Link to={{ pathname: "/admin/brand-rfp-management", state: { selectedBrandId: programmeData?.brandId, defaultKey: 'myRFPs' } }}>{'ActiveRFPs'}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {programmeData?.companyName}{' - '}{programmeData?.programmeName} {' - '} {moment(programmeData?.startDate).format('DD MMM YYYY')}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div></Col>
            </Row>

            <DataTable
                columns={columns1}
                data={surveys}
                pagination
                responsive
                customStyles={tableStyles}
                theme='mysaTable'
                className='brndTbl'
            />

            <Row>
                <Col style={{
                    marginTop: '50px',
                    marginLeft: '25%',
                    width: '756px',
                    height: 'auto',
                    background: 'rgba(0, 0, 0, 0.05)'
                }} sm={6} >
                    <div style={{
                        marginLeft: '30px',
                    }}>
                        <Form onSubmit={handleSubmit(onSubmit)} style={{ margin: 'auto' }} >
                            <h1 style={{
                                fontWeight: 'bold',
                                fontSize: '19px',
                                marginTop: '40px',
                                textAlign: 'left'
                            }} >
                                I confirm that all our brand and property details are correct in Myo and all required surveys for our brand and properties have been completed to the best of our knowledge {(brandDetailsConfirmationBtn && brandPropertiesIsCompleted) ? <>
                                    {
                                        (myoBrandEvaluationTemp !== null || myoBrandEvaluationTemp !== null) || myoSelectedMyRfp?.brandSurveyDetailSubmission !== undefined ? <Form.Check
                                            style={checkBoxStyle}
                                            type={`checkbox`}
                                            defaultChecked={true}
                                            disabled={true}
                                        /> : <Form.Check
                                            style={checkBoxStyle}
                                            type={`checkbox`}
                                            onChange={(value) => {
                                                setBtnEnable(value.target.checked);
                                            }}
                                        />
                                    }

                                </> : <Form.Check
                                    style={checkBoxStyle}
                                    type={`checkbox`}
                                    disabled={true}
                                />}
                            </h1>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                    <Form.Label>Confirmed by</Form.Label>
                                </Col>
                                <Col md={8} style={{ textAlign: 'left' }}>
                                    <Form.Control defaultValue={(myoBrandEvaluationTemp?.confirmedBy) ? `${myoBrandEvaluationTemp?.confirmedBy}` : (myoSelectedMyRfp?.brandSurveyDetailSubmission?.confirmedBy) ? `${myoSelectedMyRfp?.brandSurveyDetailSubmission?.confirmedBy}` : ``} className={`${errors.confirmedBy ? 'is-invalid' : ''}`}  {...register("confirmedBy", { required: true })} />
                                    <div className="invalid-feedback">Please input confirmedBy!</div>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                    <Form.Label>Email</Form.Label>
                                </Col>
                                <Col md={8} style={{ textAlign: 'left' }}>
                                    <Form.Control type="email" defaultValue={(myoBrandEvaluationTemp?.email) ? `${myoBrandEvaluationTemp?.email}` : (myoSelectedMyRfp?.brandSurveyDetailSubmission?.email) ? `${myoSelectedMyRfp?.brandSurveyDetailSubmission?.email}` : ``} className={`${errors.email ? 'is-invalid' : ''}`}  {...register("email", { required: true })} />
                                    <div className="invalid-feedback">Please input email!</div>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                </Col>
                                <Col md={8} style={{ textAlign: 'right' }}>
                                    {!loading && isBrand === UserGroups.brand_admin && <Button id='approve-button' className="mr-btn" type="submit" style={formButton} disabled={!btnEnable} >{myoBrandEvaluationTemp || myoSelectedMyRfp?.brandSurveyDetailSubmission ? `Confirmed` : `Confirm`}</Button>
                                    }
                                    {
                                        loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                    }
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                        </Form>
                    </div>

                </Col>

            </Row>
        </div>
    )
}

export default BrandAdminBrandEvaluationList;