import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useStoreState } from "easy-peasy";
import _ from "lodash";
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import { Button} from 'react-bootstrap';
import {
  tableStyles,
} from "../../../common/components-style";
import moment from "moment";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";

const RFPReportList: React.FC<any> = (): JSX.Element => {
  const history = useHistory();
  const [rfpReportData, setRfpReportData] = useState<any>([]);
  const [visibleData, setVisibleData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(7);
  const [isExpanded, setIsExpanded] = useState(false);


  useEffect(() => {
    if (rfpReportData && rfpReportData?.length > 0) {
      setVisibleData(rfpReportData.slice(0, 7)); 
    } else {
      setVisibleData([]);
    }
  }, [rfpReportData]);


const loadMoreData = () => {
  if (rfpReportData && rfpReportData?.length > 0) {
    const newIndex = currentIndex + 7;
    setVisibleData(rfpReportData.slice(0, newIndex));
    setCurrentIndex(newIndex);
  } 
};

const RfpReportHandler = () => {
  if (rfpReportData && rfpReportData?.length > 0) {
  if (isExpanded) {
    setVisibleData(rfpReportData.slice(0, 7));
    setCurrentIndex(7);
  } else {
    setVisibleData(rfpReportData);
    setCurrentIndex(rfpReportData.length);
  }
  setIsExpanded(!isExpanded);
}
};

  const { superAdminDashboardSuccessProposalManagement, superAdminDashboardErrorProposalManagement} = useStoreState<any>(
    (state) => {
      return {
        superAdminDashboardSuccessProposalManagement: state.DashboardModel.superAdminDashboardSuccessProposalManagement,
        superAdminDashboardErrorProposalManagement: state.DashboardModel.superAdminDashboardErrorProposalManagement,
      }
    }
  );
  useEffect(() => {
    if (superAdminDashboardErrorProposalManagement) {
      toast.error(superAdminDashboardErrorProposalManagement.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    if (superAdminDashboardSuccessProposalManagement) {
      setRfpReportData(superAdminDashboardSuccessProposalManagement?.data?.getRFPReportDataCount?.results);
    }
  }, [superAdminDashboardErrorProposalManagement, superAdminDashboardSuccessProposalManagement]);

  const handleOpen = useCallback(
    (data) => {
      setTimeout(() => {
        history.push({
          pathname: `admin/rfp-management`,
          state: { 
            tabKey: 'mysa',
            programmeId: data?.programme?._id, 
            selectedClient: { key: data?.client?._id, companyName: data?.client?.companyName },
            selectedProgramme: data?.programme,
            rfpProcessId:data?.id,
            rfpEndDate:data?.rfpEndDate,
            rfpStartDate:data?.rfpStartDate,
            formOpen:true,
            rfpStatus:data?.rfpStatus
          }
        });
      }, 500);
    },
    [history]
  );

  const columns = [
    {
      name: "Client",
      cell: (row) => (
        row.client?.companyName
      ),
    },
    {
      name: "Programme",
      cell: (row) => row.programme?.programmeName
    },
    {
        name: 'End Date',
        selector: (row) => moment(row.rfpEndDate).format("D MMMM YYYY"),
    },
    {
      name: "Incomplete",
      cell: (row) => <span className={row.incompleteCount > 0 ? 'mysa-text-bold mysa-pink-text' : ''}>
      {row.incompleteCount}
    </span>
    },
    {
        name: "Await Approval",
        cell: (row) =>  <span className={row.completedCount > 0 ? 'mysa-text-bold mysa-pink-text' : ''}>{row.completedCount}
                       </span>
      },
      {
        name: "Approved",
        cell: (row) => <span className={row.activatedCount > 0 ? 'mysa-text-bold mysa-green-text' : ''}> {row.activatedCount}
                       </span>
      },
      {
        name: "Release Ready",
        cell: (row) => <span className={row.readyForRelease == "Yes" ? 'mysa-text-bold mysa-green-text' : 'mysa-text-bold mysa-pink-text'}> {row.readyForRelease}
                       </span>
      },
    {
      name: 'Action',
      button: false,
      cell: (row: any) => {
              return (
              <>
            <img
              className="iconSizes mr-2"
              src={ViewEdit}
              alt=""
              onClick={() => {
                handleOpen(row);
              }}
            />
              </>)
      },
  },
  ];
    return (
    <>
      <div className="tbl_accr_itm mysa-blue superadmin-table">
        <div className="tbl_hdr mysa-blue">
          <h5 className="m-0 fw-bold text-white">RFP Reports</h5>
          <button className="text-white pointer" onClick={RfpReportHandler}>
            Expand
            <span>
              <img src={isExpanded  ? minus : plus} alt="icon" />
            </span>
          </button>
        </div>
        <div className="acrd_tbl">
          <DataTable
            columns={columns}
            data={visibleData}
            striped={true}
            customStyles={tableStyles}
            theme="mysaTable"
            fixedHeader={true}
            noDataComponent={"Updating records"}
            conditionalRowStyles={[
              {
                when: (row) => true,
                classNames: ['row-item'],
              },
            ]}
          />
          {currentIndex < rfpReportData?.length && (
            <div className="more-data-section">
              <Button onClick={loadMoreData} className="more_button">More</Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RFPReportList;
