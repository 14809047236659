
import React, { useCallback, useEffect, useState } from 'react';
import BookingFilterPage from './BookingFilterPage';
import BookingList from './BookingList';
import BookingPage from './BookingPage';
import MyoCorporatePage from './MyoCorporatePage';
import { getStorage } from '../../../common/storage';


const BookingHome: React.FC<any> = ({ location }): JSX.Element => {
	const [bookingOpen, setBookingOpen] = useState<boolean>(false);
	const [bookingFilterOpen, setBookingFilterOpen] = useState<boolean>(false);
	const [programmeId, setProgrammeId] = useState<any>();
	const [clientList, setClientList] = useState<any>();
	const [bookingParams, setBookingParams] = useState<any>();
	const [isProgramme, setIsProgramme] = useState<any>();
	const [isKeyLocation, setIsKeyLocation] = useState<boolean>(false);
	const [isCountryLocation, setIsCountryLocation] = useState<boolean>(false);
	const [myoParams, setMyoParams] = useState<any>();
	////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadForm = useCallback((val, client) => {
		setBookingOpen(true);
		setClientList(client);
		setProgrammeId(val.value);
		if (val?.prog?.programmeType?._id === '61ee44a04f7e849ad0d44b75') {
			setIsProgramme('MYO');
		} else if (val?.prog?.programmeType?._id === '604886a3e6d541b546d3d87c') {
			setIsProgramme('MYO Plus');
		} else if (val?.prog?.programmeType?._id === '604886a3e6d541b546d3d879') {
			setIsProgramme('Specified only');
		}

	}, []);


	

	useEffect(() => {
		setProgrammeId(getStorage('programmeId'));
		if(getStorage('programmeData')){
			setBookingOpen(true);
			if (getStorage('programmeData')?.programmeType?._id === '61ee44a04f7e849ad0d44b75') {
				setIsProgramme('MYO');
			} else if (getStorage('programmeData')?.programmeType?._id === '604886a3e6d541b546d3d87c') {
				setIsProgramme('MYO Plus');
			} else if (getStorage('programmeData')?.programmeType?._id === '604886a3e6d541b546d3d879') {
				setIsProgramme('Specified only');
			}
		}
	
	}, [])

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadLocation = useCallback((params) => {
		params.isProgramme = isProgramme;
		setIsKeyLocation(false);
		if (params.isCountryLocation) {
			setIsCountryLocation(params.isCountryLocation);
			setBookingFilterOpen(false);

			const paramsData: any = {};
			setIsProgramme('MYO');
			paramsData.myoProgramme = 'MYO';
			paramsData.myoPlusProgramme = 'MYO Plus';
			setMyoParams(paramsData);
		} else {
			setBookingFilterOpen(true);
			setBookingParams(params);
		}
	}, [isProgramme]);


	////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadData = useCallback((params) => {
		params.isProgramme = isProgramme;
		setIsCountryLocation(false);
		if (params.isKeyLocation) {
			setIsKeyLocation(params.isKeyLocation);
			setBookingFilterOpen(false);
			setIsProgramme('MYO Plus');
		} else {
			setBookingFilterOpen(true);
			setBookingParams(params);

		}
	}, [isProgramme]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className="booking-home">
			{!bookingOpen && !location?.state?.query?.bookingFilterOpen && <BookingPage loadForm={loadForm} />}
			{/* {bookingOpen && !bookingFilterOpen && <BookingFilterPage loadLocation={loadLocation} programmeId={programmeId} clientList={clientList} isProgramme={isProgramme} />} */}

			{((bookingOpen && !bookingFilterOpen && (isProgramme === 'MYO Plus' || isProgramme === 'Specified only')) || isKeyLocation) && <BookingFilterPage loadLocation={loadLocation} programmeId={programmeId} isProgramme={isProgramme} />}
			{((bookingOpen && !bookingFilterOpen && isProgramme === 'MYO') || isCountryLocation) && <MyoCorporatePage loadData={loadData} programmeId={programmeId} myoParams={myoParams} />}
			{(location?.state?.query?.bookingFilterOpen || bookingFilterOpen) && <BookingList programmeId={programmeId} clientList={clientList} bookingParams={bookingParams} location={location} />}
		</div>
	)
};

export default BookingHome;