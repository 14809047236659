import { FC, useCallback, useEffect } from "react";
import { Row, Col, Form, Button, Breadcrumb } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
// import GooglePlaces from "../../../common/GooglePlaces";
import GoogleMap from "../../../common/GoogleMap";


// corporate client setup will collect these details for the form details
const BrandAdminRfpLocationForm: FC<any> = ({ locationData }) => {
	// const [location, setLocation] = useState<any>();
	const history = useHistory();
	// const [reduxBrandDetail, setReduxBrandDetail] = useState<ReduxBrandDetail>();
	//actions
	// const {
	// 	updateBrandRFPData,
	// } = useStoreActions<any>((actions) => ({

	// 	updateBrandRFPData: actions.rfpProcess.setBrandRfpDetails,

	// }));

	//state
	// const {
	// 	brandRfpDetails,
	// } = useStoreState<any>((state) => ({
	// 	brandRfpDetails: state.rfpProcess.brandRfpDetails,
	// }));



	// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// useEffect(() => {
	// 	setReduxBrandDetail(brandRfpDetails)
	// }, [brandRfpDetails])

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// useEffect(() => {
	// 	setReduxBrandDetail(data);
	// }, [data])


	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (locationData?.rfpDetailSelectedCityDetails) {

			// setLocation({ lat: Number(locationData?.rfpDetailSelectedCityDetails[0]?.locations[0].latitude), lng: Number(locationData?.rfpDetailSelectedCityDetails[0]?.locations[0].longitude) });

		}
	}, [locationData?.rfpDetailSelectedCityDetails])

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderClientLocationList = useCallback(() => {
		if (locationData?.rfpDetailSelectedCityDetails) {
			return locationData?.rfpDetailSelectedCityDetails[0].locations.map((location) => {
				return (
					<div key={location._id} className="jumbotron">
						<Row>
							<Col md={6} >
								{/* {!editEnable && <p className="lead">{brandData?.totalNumberOfManagedInventoryUnits}</p>} */}
								{/* {editEnable && <GooglePlaces className="form-control" onPlaceSelect={(location) => setLocationData(location)} />} */}
								<div style={{ marginTop: '10px' }}></div>
								<div style={{
								}}>
									<GoogleMap
										googleMapURL={process.env.REACT_APP_MAP_URL}
										loadingElement={<div style={{ height: `100%` }} />}
										containerElement={<div style={{ height: `350px` }} />}
										mapElement={<div style={{ height: `100%` }} />}
										location={{ lat: Number(location.latitude), lng: Number(location.longitude) }}
									// setCoords={setCoords}
									/>
								</div>
							</Col>

							<Col md={6}>
								<Row className='formSpace'>
									<Col md={5} style={{ textAlign: 'right' }}>
										<Form.Label>Name of the location</Form.Label>
									</Col>
									<Col md={7} style={{ textAlign: 'left' }}>
										<p className="lead">{location.locationName}</p>
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={5} style={{ textAlign: 'right' }}>
										<Form.Label>Location address</Form.Label>
									</Col>
									<Col md={7} style={{ textAlign: 'left' }}>
										<p className="lead">{location.address1 + ' ' + location.postcode} </p>
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={5} style={{ textAlign: 'right' }}>
										<Form.Label>Approximate location spend</Form.Label>
									</Col>
									<Col md={7} style={{ textAlign: 'left' }}>
										{/* <p className="lead">{reduxBrandDetail?.rfpDetailSelectedCityDetails[0]?.locations?.reduce((acc, element) => { return element.approximateLocationSpend + acc }, 0)}</p> */}
										<p className="lead">{location?.approximateLocationSpend}</p>
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={5} style={{ textAlign: 'right' }}>
										<Form.Label>Currency</Form.Label>
									</Col>
									<Col md={7} style={{ textAlign: 'left' }}>
										<p className="lead">{location?.currency}</p>
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={5} style={{ textAlign: 'right' }}>
										<Form.Label>Approximate no. of nights</Form.Label>
									</Col>
									<Col md={7} style={{ textAlign: 'left' }}>
										<p className="lead">{location?.approximateNoOfNights}{` nights`}</p>
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={5} style={{ textAlign: 'right' }}>
										<Form.Label>Average length of stay</Form.Label>
									</Col>
									<Col md={7} style={{ textAlign: 'left' }}>
										<p className="lead">{location?.averageLengthOfStay}{` nights`}</p>
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={5} style={{ textAlign: 'right' }}>
										<Form.Label>Minimum stay requirement</Form.Label>
									</Col>
									<Col md={7} style={{ textAlign: 'left' }}>
										<p className="lead">{location?.minimumStay}{` nights`}</p>
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={5} style={{ textAlign: 'right' }}>
										<Form.Label>Expected maximum stay</Form.Label>
									</Col>
									<Col md={7} style={{ textAlign: 'left' }}>
										<p className="lead">{location?.expectedMaximumStay}{` nights`}</p>
									</Col>
								</Row>

							</Col>
						</Row>
					</div>
				)
			}
			)
		}
	}, [locationData])


	const onCloseLocation = useCallback(() => {
		const params: any = {};
		params.defaultKey = 'corporateRFPS';
		params.showLocationPage = true;
		params.data = locationData;
		setTimeout(() => {
			history.push(`/admin/brand-rfp-management`, { query: params });
		}, 500);
	}, [history, locationData])


	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<>
			<Row style={{
				marginBottom: '1.5rem',
				marginTop: '20px',
			}} >
				<Col xs={12}><div className="content-title">
					<Breadcrumb>
						<Breadcrumb.Item style={{ fontWeight: 100 }}

							onClick={() => { }}
						>
							<Link to={{ pathname: "/admin/brand-rfp-management", state: { showLocationPage: true, data: locationData?.data, defaultKey: 'corporateRFPS' } }}>{locationData?.data?.companyName}{' - '}{locationData?.data?.programmeName}</Link>
						</Breadcrumb.Item  >
						<Breadcrumb.Item active >
							{locationData?.rfpDetailSelectedCity}
						</Breadcrumb.Item  >
					</Breadcrumb>
				</div></Col>
			</Row>
			<div >
				<Form>

					<Row>
						{/* <Col md={6}> */}
						{
							renderClientLocationList()
						}


						{/* </Col> */}

					</Row>

					<Button className="edit_btn px-5" onClick={() => { onCloseLocation() }}>Close</Button>
				</Form>
			</div>
		</>
	)
};

export default BrandAdminRfpLocationForm;