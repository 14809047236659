import _ from 'lodash';
import React, { useCallback } from 'react';
// import Autocomplete from 'react-google-autocomplete';


import Autocomplete, {
  ReactGoogleAutocomplete,
} from "react-google-autocomplete";

const AutocompleteTS: React.FC<ReactGoogleAutocomplete> = Autocomplete as React.FC<ReactGoogleAutocomplete>;

type TGooglePlaces = {
  onPlaceSelect: (data: any) => void;
  style?: any
};

const GooglePlaces: React.FC<TGooglePlaces> = ({ onPlaceSelect, style }): JSX.Element => {

  const onPlaceSelected = useCallback(({ place_id, geometry, address_components, formatted_address }) => {

    const data = {
      placeId: place_id,
      lat: geometry.location.lat(),
      lng: geometry.location.lng(),
      country: _.filter(address_components, (val) => val.types.includes('country')).shift()?.long_name,
      postcode: _.filter(address_components, (val) => val.types.includes('postal_code')).shift()?.long_name,
      countryCode: _.filter(address_components, (val) => val.types.includes('country')).shift()?.short_name,
      address1: formatted_address,
      city: _.filter(address_components, (val) => val.types.includes('administrative_area_level_2')).shift()?.long_name,
    }
    onPlaceSelect(data);
  }, [onPlaceSelect]);


  return (
    <div>
      <AutocompleteTS
        onPlaceSelected={onPlaceSelected}
        style={{...style}}
        types={['establishment', 'geocode']}
      />

    </div>
  )
};

export default GooglePlaces;