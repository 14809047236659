import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { $mysaMegenta, formButton } from "../../../../../common/components-style";
import { REQUEST_BOOKING_STATUS } from "../../../../../common/constants";
import { TRequestBookingView } from "../../../../../common/types";
import OfferedExpires from "./OfferedExpires";

type TMessage = {
    messageText: String
};

const RequestBookingOfferedHomeOperatorView: React.FunctionComponent<TRequestBookingView> = ({ status, _id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, hideShowViewComponent }) => {

    //////////// form handle ///////////////////////
    const { register, handleSubmit, formState: { errors }, reset } = useForm<TMessage>();

    ///////////////////////////global action///////////////////////////////////
    const { getMessageThreadById, updateMessageThread, setReset } = useStoreActions<any>((actions) => ({
        getMessageThreadById: actions.booking.getMessageThreadById,
        updateMessageThread: actions.booking.updateMessageThread,
        setReset: actions.booking.setReset,
    }));

    /////////////////// global state ////////////////
    const { getMessageThreadByIdSuccess, getUpdateMessageThreadSuccess } = useStoreState<any>((actions) => ({
        getMessageThreadByIdSuccess: actions.booking.getMessageThreadByIdSuccess,
        getUpdateMessageThreadSuccess: actions.booking.getUpdateMessageThreadSuccess
    }));

    ///////////////// local state ////////////////
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [viewTextArea, setViewTextArea] = useState<boolean>(false);
    const [extendedOfferHide, setExtendedOfferHide] = useState<boolean>(false);

    //////////// action hook //////////////
    useEffect(() => {
        if (_id && _reqBrandId && _reqGroupId && _reqInventoryId && _reqPropertyId) {
            const payload = {
                _id: _id,
                _reqBrandId: _reqBrandId,
                _reqGroupId: _reqGroupId,
                _reqInventoryId: _reqInventoryId,
                _reqPropertyId: _reqPropertyId
            }
            getMessageThreadById(payload);

        }
    }, [_id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, getMessageThreadById]);

    ///////////////// data fetch and handle hook /////////////////
    useEffect(() => {

        if (getMessageThreadByIdSuccess) {
            setData(getMessageThreadByIdSuccess.data)
        }

        if (getUpdateMessageThreadSuccess) {
            toast.success("Message send successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setLoading(false);
            setViewTextArea(false);
            if (_id && _reqBrandId && _reqGroupId && _reqInventoryId && _reqPropertyId) {
                const payload = {
                    _id: _id,
                    _reqBrandId: _reqBrandId,
                    _reqGroupId: _reqGroupId,
                    _reqInventoryId: _reqInventoryId,
                    _reqPropertyId: _reqPropertyId
                }
                getMessageThreadById(payload);
            }
            reset();
            setReset(false);
        }

    }, [_id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, getMessageThreadById, getMessageThreadByIdSuccess, getUpdateMessageThreadSuccess, reset, setReset]);

    ///////////////////////////////////////////////////////
    const onSubmit = useCallback((value) => {
        setLoading(true);
        const payload = {
            _id: _id,
            _reqGroupId: _reqGroupId,
            _reqBrandId: _reqBrandId,
            _reqPropertyId: _reqPropertyId,
            _reqInventoryId: _reqInventoryId,
            newMessage: {
                direction: "property_to_booker",
                messageText: value.messageText
            }
        }
        updateMessageThread(payload);
    }, [_id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, updateMessageThread]);

    return (<>
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to={{ pathname: "/admin/brand-property-booking-reports", state : {tabKey : 'requestBooking'} }} >Request Bookings</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{data?.clientName}</Breadcrumb.Item>
            <Breadcrumb.Item active>{data?._reqInventoryId}</Breadcrumb.Item>
        </Breadcrumb>
        <Container fluid className='p-0'>
            <div className="jumbotron" style={{ marginTop: "65px" }}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Company Name</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data?.clientName}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Name of programme</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data?.programmeName}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Accommodation Requested</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data?.inventory?.inventoryName}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Check-in</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{moment(data?.checkInDate).format('D MMM YYYY')}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Check-out</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{moment(data?.checkOutDate).format('D MMM YYYY')}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Messages</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    {
                                        data?.messageThread.map((values, key) => {
                                            return (<div key={key}>
                                                <p style={{ marginBottom: '0px' }}>{ (key === 0) ? (values?.direction === 'booker_to_property') ? 'Booker (original message)' : 'Property (original message)' : (values?.direction === 'booker_to_property') ? 'Booker (further message)' : 'Property (further message)'}</p>
                                                <p className="lead">{values?.messageText}</p>
                                            </div>)
                                        })
                                    }
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}></Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    {viewTextArea && <Form.Control placeholder="Message to Booker" rows={5} as={`textarea`} className={`${errors.messageText ? 'is-invalid' : ''}`}  {...register("messageText", { required: true })} />}
                                    {viewTextArea && <div className="invalid-feedback">Please input message!</div>}
                                </Col>
                            </Row>
                            {
                                data?.expiryDetails && <>
                                    <Row className='formSpace'>
                                        <Col md={5} style={{ textAlign: 'right' }}>
                                            <Form.Label>Offer Expires</Form.Label>
                                        </Col>
                                        <Col md={7} style={{ textAlign: 'left' }}>
                                            <p style={{ color: $mysaMegenta, fontWeight: 'bold' }} className="lead">{moment(data?.expiryDetails?.offerExpiresAt).format('D MMM YYYY HH:mm')}</p>
                                        </Col>
                                    </Row>
                                </>
                            }
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    {!loading &&
                                        <Button id='approve-button' style={{ ...formButton, width: '200px' }} onClick={() => { setExtendedOfferHide(true) }}><b>Extend Offer</b></Button>
                                    }
                                    {!loading && !viewTextArea &&
                                        <Button id='blue-button' style={{ ...formButton, width: '200px', marginLeft: '15px' }} onClick={() => { setViewTextArea(true) }}><b>Message Booker</b></Button>
                                    }
                                    {!loading && viewTextArea && <Button type="submit" id='approve-button' style={formButton} ><b>Submit</b></Button>}
                                    {!loading && <Button id='edit-button' style={{ ...formButton, marginLeft: '15px' }} onClick={() => {
                                        hideShowViewComponent(false);
                                    }}><b>Close</b></Button>}
                                    {
                                        loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                    }
                                </Col>
                            </Row>
                            {
                                extendedOfferHide && <OfferedExpires data={data} hideShowViewComponent={hideShowViewComponent} _id={_id} _reqBrandId={_reqBrandId} _reqGroupId={_reqGroupId} _reqInventoryId={_reqInventoryId} _reqPropertyId={_reqPropertyId} />
                            }

                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Form>
            </div>
        </Container>
    </>)
};

export default RequestBookingOfferedHomeOperatorView;