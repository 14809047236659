import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import Select from 'react-select';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getLoggedUserType, getUserAccess } from '../../../../../common/functions';
import { ProgrammeStatus, RfpProcessStatus, UserGroups } from '../../../../../common/constants';
import { clearStorage, getStorage, setStorage } from '../../../../../common/storage';
import _ from 'lodash';


const ClientFilterProgram: React.FC<any> = ({ setFilterProgramManage, programsSelectManageStatus }): JSX.Element => {
    ///////////////////////////////////// local state manage //////////////////////////////////////////
    const [clientOption, setClientOption] = useState([]);
    const [selectedClientOption, setSelectedClientOption] = useState<any>();
    const [programsOption, setProgramsOption] = useState([]);
    const [selectedProgramsOption, setSelectedProgramsOption] = useState<any>();
    const [rfpProcess, setRfpProcess] = useState<any>([]);
    const [programsSelectManage, setProgramsSelectManage] = useState(programsSelectManageStatus);
    // const [loading, setLoading] = useState(false);
    const [activeRfpProgramme, setActiveRfpProgramme] = useState<any>([]);

    ///////////////////////////////////// global action manage /////////////////////////////////////////
    const { clientRfpOnly, getProgrammesByClientId, getProcessByProgrammeIdAndStatus, getRfpProcessByProgrammeId } = useStoreActions<any>((actions) => ({
        clientRfpOnly: actions.client.clientRfpOnly,
        getProgrammesByClientId: actions.client.programmesByClientId,
        getProcessByProgrammeIdAndStatus: actions.rfpProcess.rfpProcessByProgrammeIdAndStatus,
        getRfpProcessByProgrammeId: actions.rfpProcess.getRfpProcessByProgrammeId,
    }));

    /////////////////////////////////// global state manage ////////////////////////////////////////////
    const { rfpProcessByProgrammeIdSuccess, getClientRfpOnlySuccess, programmesByClientIdSuccess, programmesByClientIdError, rfpProcessByProgramIdAndStatusSuccess, rfpProcessByProgramIdAndStatusError } = useStoreState<any>((state) => ({
        getClientRfpOnlySuccess: state.client.getClientRfpOnlySuccess,
        programmesByClientIdSuccess: state.client.programmesByClientIdSuccess,
        programmesByClientIdError: state.client.programmesByClientIdError,
        rfpProcessByProgramIdAndStatusSuccess: state.rfpProcess.rfpProcessByProgramIdAndStatusSuccess,
        rfpProcessByProgramIdAndStatusError: state.rfpProcess.rfpProcessByProgramIdAndStatusError,
        rfpProcessByProgrammeIdSuccess: state.rfpProcess.rfpProcessByProgrammeIdSuccess,
    }));

    //////////////////////////////////////// call actions /////////////////////////////////////////////
    useEffect(() => {
        clientRfpOnly();
    }, [clientRfpOnly]);

    ///////////////////////////////////////// use state //////////////////////////////////////////////
    useEffect(() => {

        if (programsSelectManageStatus) {
            clearStorage('filterProgramClient');
            clearStorage('filterProgram');
            clearStorage('selectedRFPProcess');
        } else {
            setClientOption(getStorage('filterProgramClient'));
            setProgramsOption(getStorage('filterProgram'));
            setRfpProcess(getStorage('selectedRFPProcess'));
            setSelectedClientOption(getStorage('selectedFilterClient'));
            setSelectedProgramsOption(getStorage('selectedProgramsOption'));
        }

        if (getClientRfpOnlySuccess) {
            let userData: any = [];
            const userClient = getUserAccess('client');
            const userClientIds = _.map(userClient, (a) => a.clientId);
            const mappedData = _.map(getClientRfpOnlySuccess.data, (client) => {
                return {
                    key: client._id,
                    companyLogo: client.companyLogo,
                    companyName: client.companyName,
                    city: client.city,
                    country: client.country,
                    status: client.status,
                    clientAdmin: client.clientAdmin,
                }
            });

            let filteredData = _.filter(mappedData, (a) => userClientIds.includes(a.key));
            userData = getLoggedUserType() === UserGroups.super_admin ? mappedData : filteredData;

            let clients: any = [];
            userData.forEach((client: any) => {
                let data = { value: client.key, label: client.companyName };
                clients.push(data);
            });
            setClientOption(clients);
            setStorage('filterProgramClient', clients);

            if (getLoggedUserType() === UserGroups.user) {
                const payload = {
                    clientId: clients[0]?.value,
                    status: ProgrammeStatus.ACCEPTED
                }
                getProgrammesByClientId(payload);
                setStorage('selectedFilterClient', clients[0]);
                setProgramsSelectManage(false);
            }
        }

        if (programmesByClientIdSuccess) {
            let programmes: any = [];
            programmesByClientIdSuccess.data.forEach((program: any) => {
                let data = { value: program._id, label: program.programmeName };
                programmes.push(data);
            });
            setProgramsOption(programmes);
            setStorage('filterProgram', programmes);
            // setLoading(false);
        }

        if (programmesByClientIdError) {
            // setLoading(false);
        }

        if (rfpProcessByProgramIdAndStatusSuccess) {
            if (rfpProcessByProgramIdAndStatusSuccess.data.length !== 0) {
                setRfpProcess(rfpProcessByProgramIdAndStatusSuccess.data[0]);
                setStorage('selectedRFPProcess', rfpProcessByProgramIdAndStatusSuccess.data[0]);
                setFilterProgramManage(true);
            } else {
                // setFilterProgramManage(true);
            }
            // setLoading(false);
            // setFilterProgramManage(true); // demo purpose
        }

        // if (rfpProcessByProgramIdAndStatusError) {
        //     // setLoading(false);
        // }

    }, [getClientRfpOnlySuccess, getProgrammesByClientId, programmesByClientIdError, programmesByClientIdSuccess, programsSelectManageStatus, rfpProcessByProgramIdAndStatusSuccess, setFilterProgramManage]);


    useEffect(() => {

        if (rfpProcessByProgrammeIdSuccess) {
            const programme = rfpProcessByProgrammeIdSuccess?.data;

            const data = _.filter(programme, ele => ((ele?.releasedStatus === true)));
            setActiveRfpProgramme(data);
        }


    }, [rfpProcessByProgrammeIdSuccess]);

    ////////////////////////////////////////// select client id and call programs/////////////////////////////////////////
    const changeClient = (selectedClient) => {
        setProgramsOption([]);
        clearStorage('filterProgram');
        setRfpProcess('');
        clearStorage('selectedRFPProcess');
        const payload = {
            clientId: selectedClient.value,
            status: ProgrammeStatus.ACCEPTED
        }
        getProgrammesByClientId(payload);
        setStorage('selectedFilterClient', selectedClient);
        setSelectedProgramsOption('');
        setStorage('selectedProgramsOption', '');
        setProgramsSelectManage(false);
        // setLoading(true);
    }


    useEffect(() => {
        if (getStorage('programmeId')) {
            const payload = {
                programmeId: getStorage('programmeId'),
                RFPStatus: RfpProcessStatus.INPROGRESS,
                UserType: getLoggedUserType() === UserGroups.super_admin ? UserGroups.super_admin : UserGroups.corporate_admin
            }

            getRfpProcessByProgrammeId(getStorage('programmeId'));

            getProcessByProgrammeIdAndStatus(payload);
        }
    }, [getProcessByProgrammeIdAndStatus, getRfpProcessByProgrammeId])

    ////////////////////////////////////////// select program id and call rfp process /////////////////////////////////////////
    const changePrograms = (selectedProgram) => {
        if (selectedProgram?.value) {
            const payload = {
                programmeId: selectedProgram?.value,
                RFPStatus: RfpProcessStatus.INPROGRESS,
                UserType: getLoggedUserType() === UserGroups.super_admin ? UserGroups.super_admin : UserGroups.corporate_admin
            }
            setStorage('selectedProgramsOption', selectedProgram);
            getProcessByProgrammeIdAndStatus(payload);
            getRfpProcessByProgrammeId(selectedProgram?.value);
        }


        // setLoading(true);
    }


    return (<>
        <div className="site-card-border-less-wrapper mb-10">
            <Card bordered={true} >
                {
                    getLoggedUserType() === UserGroups.super_admin && activeRfpProgramme?.length >= 1 ?
                        <Row>
                            <Col span={6}>
                                <Select placeholder="Select client" value={selectedClientOption} options={clientOption} onChange={changeClient} />
                            </Col>
                            <Col span={1}></Col>
                            <Col span={6}>
                                <Select placeholder="Select programme" value={selectedProgramsOption} options={programsOption} onChange={changePrograms} isDisabled={programsSelectManage} />
                            </Col>
                            <Col span={1}></Col>

                        </Row>
                        :
                        <Row>
                            {activeRfpProgramme?.length >= 1 &&
                                <Col span={6}>
                                    <Select placeholder="Select programme" value={selectedProgramsOption} options={programsOption} onChange={changePrograms} isDisabled={programsSelectManage} />
                                </Col>}
                            <Col span={1}></Col>

                        </Row>
                }


                {activeRfpProgramme?.length === 0 &&


                    <Row className='text-center' >

                        <Col md={12} style={{ marginLeft: '380px' }} >
                            <h4>  {getStorage('selectedFilterClient')?.label} {getStorage('selectedProgramsOption')?.label} programme</h4>
                        </Col>



                    </Row>
                }

                {activeRfpProgramme?.length === 0 &&


                    <Row className=' mt-5' >

                        <Col md={12} style={{ marginLeft: '380px' }} >
                            <h2 className='text-center'>You have no current RFP to assess</h2>
                        </Col>


                    </Row>
                }
            </Card>
        </div>
        {/* {
            loading && <Spin size="large" style={{ marginTop: '10%', marginLeft: '50%' }} />
        } */}
    </>);
}

export default ClientFilterProgram;
