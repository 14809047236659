import { } from "antd";
import React, {  } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { formButton } from "../../../../../common/components-style";
import { formatPhoneNumber } from "../../../../../common/functions";

const ProgrammeUserView: React.FC<any> = ({
	selectedUser,
	close,
}): JSX.Element => {

	return (
		<Container>
			<div className="jumbotron" style={{ width: '100%' }}>
				<Form >
					<Row>
						<Col md={1}></Col>
						<Col md={10}>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label> Email</Form.Label>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>
									<p className="lead">{selectedUser?.email}</p>
								</Col>
							</Row>
						</Col>
						<Col md={1}></Col>
					</Row>
					<Row>
						<Col md={1}></Col>
						<Col md={10}>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label> First name</Form.Label>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>
									<p className="lead">{selectedUser?.firstName}</p>
								</Col>
							</Row>
						</Col>
						<Col md={1}></Col>
					</Row>
					<Row>
						<Col md={1}></Col>
						<Col md={10}>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label> Last name</Form.Label>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>
									<p className="lead">{selectedUser?.lastName}</p>
								</Col>
							</Row>
						</Col>
						<Col md={1}></Col>
					</Row>
					<Row>
						<Col md={1}></Col>
						<Col md={10}>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label> Job title</Form.Label>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>
									<p className="lead">{selectedUser?.position}</p>
								</Col>
							</Row>
						</Col>
						<Col md={1}></Col>
					</Row>

					<Row>
						<Col md={1}></Col>
						<Col md={10}>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label> Phone</Form.Label>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>
									<p className="lead">{formatPhoneNumber(selectedUser?.phone)}</p>
								</Col>
							</Row>
						</Col>
						<Col md={1}></Col>
					</Row>
					<Row>
						<Col md={1}></Col>
						<Col md={10}>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label>Mobile</Form.Label>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>
									<p className="lead">{formatPhoneNumber(selectedUser?.mobile)}</p>
								</Col>
							</Row>
						</Col>
						<Col md={1}></Col>
					</Row>



					<Row>
						<Col md={1}></Col>
						<Col md={10}>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>

									{
										<Button id='edit-button' style={formButton} onClick={() => { close(); }}>Close</Button>
									}

								</Col>

							</Row>
						</Col>
						<Col md={1}></Col>
					</Row>
				</Form>
			</div>
		</Container>
	);
};
export default ProgrammeUserView;
