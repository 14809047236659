import { Breadcrumb, Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { FC, useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { formButton, overlayTriggerDelay, tableStyles } from "../../../../common/components-style";
import { Link, useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { RFPSubmission } from '../../../../common/interfaces';
import { useForm } from 'react-hook-form';
import { getStorage } from '../../../../common/storage';
import { toast } from "react-toastify";
// import { getLoggedUserType } from '../../../../common/functions';
import { UserGroups } from '../../../../common/constants';
import { getMailDomain } from '../../../../common/functions';

const checkBoxStyle = { display: 'inline', top: '-3px', marginLeft: '10px', position: 'relative' } as React.CSSProperties;

const BrandAdminPropertyEvaluationList: FC<any> = ({ programmeData, property, myRfpsData, setComponentName, setDefaultTab, setDefaultTabProperty }) => {
    //////////////// form initialize ///////////////////
    const { register, handleSubmit, formState: { errors } } = useForm<RFPSubmission>();

    ////////////// local storage ////////////////////////
    const myoSelectedMyRfpProperty = getStorage("myo_selected_my_rfp_property");
    const myoSelectedMyRfp = getStorage("myo_selected_my_rfp");
    // const myoPropertyEvaluationTemprary = getStorage("myo_property_evaluation_tem" + myoSelectedMyRfpProperty.city.toString() + myoSelectedMyRfp.rfpId.toString());
    const myoSelectedBrand = getStorage("myo_selected_my_rfp_brand");
    // const selectedProperty = getStorage("myo_selected_my_rfp_property");
    const isBrand = getStorage('isBrand');

    const history = useHistory();
    ///////////////////////////////////////////////////////////////////////////////////
    const [surveys, setSurveys] = useState<any>();
    const [inventory, setInventory] = useState<any>();
    // const [myoPropertyEvaluationTemp, setMyoPropertyEvaluationTemp] = useState<any>();
    const [propertySurveyDetailSubmission, setPropertySurveyDetailSubmission] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [propertyDetailsConfirmationBtn] = useState<boolean>(true);
    const [propertyInventoryIsEnable] = useState<boolean>(true);
    const [btnEnable, setBtnEnable] = useState<boolean>(false);

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getProperty, propertyRfpInventory, viewCertificate, submitRFP } = useStoreActions<any>((actions) => ({
        viewCertificate: actions.myoManagementModel.viewCertificate,
        submitRFP: actions.rfpProcess.submitRFP,
        // propertyEvaluationStatusUpdate: actions.rfpProcess.propertyEvaluationStatusUpdate,
        propertyRfpInventory: actions.rfpProcess.propertyRfpInventory,
        getProperty: actions.rfpProcess.getProperty,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getPropertySuccess, getViewCertificateSuccess, getPropertySubmissionSuccess, getPropertySubmissionError } = useStoreState<any>((state) => ({
        getViewCertificateSuccess: state.myoManagementModel.getViewCertificateSuccess,
        getPropertySubmissionSuccess: state.rfpProcess.getPropertySubmissionSuccess,
        getPropertySubmissionError: state.rfpProcess.getPropertySubmissionError,
        getPropertyEvaluationStatusUpdateSuccess: state.rfpProcess.getPropertyEvaluationStatusUpdateSuccess,
        getPropertyEvaluationStatusUpdateError: state.rfpProcess.getPropertyEvaluationStatusUpdateError,
        getPropertySuccess: state.rfpProcess.getPropertySuccess,
    }));

    useEffect(() => {
        const payload = {
            brandRfpId: myoSelectedMyRfp.key.toString(),
            rfpId: myoSelectedMyRfp.rfpId.toString(),
            propertyId: property.id.toString(),
            cityName: myoSelectedMyRfpProperty.city.toString()
        };
        getProperty(payload);
    }, [getProperty, myoSelectedMyRfp.key, myoSelectedMyRfp.rfpId, myoSelectedMyRfpProperty.city, property.id]);

    ///////////////////////////////////////////////////////////////////
    useEffect(() => {
        //get inventory
        const payload: any = {};
        payload.propertyId = property.id.toString();
        payload.rfpId = myoSelectedMyRfp.rfpId.toString();
        payload.brandRfpId = myoSelectedMyRfp.key.toString();
        payload.cityName = myoSelectedMyRfpProperty.clientLocation.toString();
        propertyRfpInventory(payload);

        // set property submission
        // myoSelectedMyRfp?.brandSpecificLocations.forEach(brandSpecificLocation => {
        //     brandSpecificLocation?.cities.forEach(city => {
        //         if (city?.cityName.trim().toString() === myoSelectedMyRfpProperty.city.trim().toString()) {
        //             city?.properties.forEach(proty => {
        //                 if(proty?.propertyId.toString() === property.id.toString() && proty?.propertySurveyDetailSubmission){
        //                     setPropertySurveyDetailSubmission(proty?.propertySurveyDetailSubmission);
        //                 }
        //             });
        //         }
        //     });

        // });

        // if (property.id.toString() === myoPropertyEvaluationTemprary?.propertyId.toString()) {
        //     setMyoPropertyEvaluationTemp(myoPropertyEvaluationTemprary);
        // }

    }, [myoSelectedMyRfp.key, myoSelectedMyRfp.rfpId, myoSelectedMyRfpProperty.clientLocation, property.id, propertyRfpInventory]);

    ///////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (getPropertySubmissionSuccess) {
            setLoading(false);
            toast.success("Your confirmation has been registered", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setBtnEnable(false);
            const payload = {
                brandRfpId: myoSelectedMyRfp.key.toString(),
                rfpId: myoSelectedMyRfp.rfpId.toString(),
                propertyId: property.id.toString(),
                cityName: myoSelectedMyRfpProperty.city.toString()
            };
            getProperty(payload);
            setDefaultTab('properties');
            setComponentName('singleMyRfp');
            setDefaultTabProperty('properties');
            // if (propertyDetailsConfirmationBtn && (myoPropertyEvaluationTemp) ? true : (propertySurveyDetailSubmission) ? true : false) {
            //     const payload = {
            //         id: myoSelectedMyRfp?.key,
            //         rfpId: myoSelectedMyRfp?.rfpId,
            //         propertyId: selectedProperty?.id,
            //         cityName: selectedProperty?.city
            //     }
            //     propertyEvaluationStatusUpdate(payload);
            // }
        }


        if (getPropertySubmissionError) {
            setLoading(false);
            toast.error(getPropertySubmissionError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

        }

        if (getPropertySuccess) {
            setPropertySurveyDetailSubmission(getPropertySuccess.data?.propertySurveyDetailSubmission);
        }

    }, [getProperty, getPropertySubmissionError, getPropertySubmissionSuccess, getPropertySuccess, myoSelectedMyRfp.key, myoSelectedMyRfp.rfpId, myoSelectedMyRfpProperty.city, property.id, setComponentName, setDefaultTab, setDefaultTabProperty]);

    ////////////// form submission ///////////
    const onSubmit = (value: RFPSubmission) => {
        setLoading(true);

        // check domain
        const domains = getStorage('domains');
        const getDomainValidation = getMailDomain(value.email, domains);
        if (getDomainValidation) {
            const payload = {
                type: 'PROPERTY_SURVEY_SECTION_DETAIL_SUBMISSION',
                rfpProcessId: myoSelectedMyRfp.rfpId,
                brandId: myoSelectedBrand,
                confirmedBy: value.confirmedBy,
                email: value.email,
                propertyId: property.id.toString(),
                brandRfpId: myoSelectedMyRfp.key.toString(),
                cityName: myoSelectedMyRfpProperty.clientLocation.toString(),
                myoCertificateId: myoSelectedMyRfpProperty?.myoCertificateId
            }
            submitRFP(payload);
        } else {
            toast.error('Invalid brand domain. please check your e-mail address domain with brand domains', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setLoading(false);
        }
    }

    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        viewCertificate({
            security: {
                apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
                user: "superadmin@mysa.global"
            },
            id: myoSelectedMyRfpProperty?.myoCertificateId
        });
    }, [myoSelectedMyRfpProperty?.myoCertificateId, viewCertificate]);


    useEffect(() => {
        if (getViewCertificateSuccess) {
            const result = getViewCertificateSuccess?.certificate?.surveys.filter(value => value.survey?.survey_type === 'PROPERTY_SURVEY');
            setSurveys(result);

            // check all sections are complete
            // let isComplete = true;
            // let complete = false;
            // if (getViewCertificateSuccess?.certificate?.surveys) {
            //     getViewCertificateSuccess?.certificate?.surveys.forEach(element => {
            //         if (element.isCompleted && element.isStarted && element.survey.survey_type === 'PROPERTY_SURVEY') {
            //             complete = true;
            //         } else {
            //             if (element.survey.survey_type === 'PROPERTY_SURVEY') {
            //                 isComplete = false;
            //             }
            //         }
            //     });

            //     const isCompleted = (complete && isComplete) ? true : false;

            //     setPropertyDetailsConfirmationBtn(isCompleted);
            // } else {
            //     setPropertyDetailsConfirmationBtn(true);
            // }
        }

        // if (propertyDetailsConfirmationBtn && (myoPropertyEvaluationTemp) ? true : (propertySurveyDetailSubmission) ? true : false) {
        //     // const payload = {
        //     //     id: myoSelectedMyRfp?.key,
        //     //     rfpId: myoSelectedMyRfp?.rfpId,
        //     //     propertyId: selectedProperty?.id,
        //     //     cityName: selectedProperty?.city
        //     // }
        //     // propertyEvaluationStatusUpdate(payload);
        // }

    }, [getViewCertificateSuccess]);

    // useEffect(() => {

    //     if (getPropertyRfpInventorySuccess?.data) {
    //         let inComplete = true;
    //         let complete = false;
    //         getPropertyRfpInventorySuccess.data.forEach(element => {
    //             if (element.rfpPropertyRateStatus === 'incomplete') {
    //                 inComplete = false
    //             } else {
    //                 complete = true;
    //             }
    //         });

    //         const isCompleted = (complete && inComplete) ? true : false;

    //         setPropertyInventoryIsEnable(isCompleted);
    //     }
    // }, [getPropertyRfpInventorySuccess, getPropertyRfpInventorySuccess?.data])

    const columns1 = [
        {
            name: 'Section',
            selector: row => row.survey?.name,
        },
        {
            name: 'Status',
            cell: (data: any) => {
                const { isApproved, isCompleted, isStarted } = data;

                let color = "";
                let status = "";


                if ((!isApproved && isCompleted && !isStarted)) {
                    color = 'font-bold uppercase text-red-500';
                    status = 'Wrong Submitted';
                }
                if ((isApproved && isCompleted && isStarted)) {
                    color = 'font-bold uppercase text-green-500';
                    status = 'Approved';
                }
                if ((!isApproved && isCompleted && isStarted)) {
                    color = 'font-bold uppercase text-green-500';
                    status = 'Complete';
                }
                if ((!isApproved && !isCompleted && isStarted)) {
                    color = 'font-bold uppercase text-yellow-500';
                    status = 'Started';
                }
                if ((!isApproved && !isCompleted && !isStarted)) {
                    color = 'font-bold uppercase text-red-500';
                    status = 'Not yet started';
                }

                return (
                    <span style={{
                        fontWeight: 'bold',
                    }} className={color}>{status}</span>
                )
            }
        },
        {
            name: 'Action',
            button: true,
            wrap: true,
            minWidth: '300px',
            cell: (data: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                    >
                        <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                            history.push({
                                pathname: '/admin/myo-questions-form',
                                search: `surveyID=${data?.id}`,
                                state: {
                                    selectedBrandId: myRfpsData?.brandId,
                                    defaultKey: 'properties',
                                    data: data,
                                    myRfpsData: myRfpsData,
                                    component: 'property',
                                    inventory: inventory,
                                    property: property,
                                    programmeData: programmeData
                                }
                            });
                        }} />
                    </OverlayTrigger>

                </>)
            }
        },

    ];

    return (
        <div>
            <Row style={{
                marginBottom: '1.5rem',
                marginTop: '20px',
            }} >
                <Col xs={12}><div className="content-title">
                    <Breadcrumb>
                        <Breadcrumb.Item style={{

                            fontWeight: 100,
                        }} >
                            <Link to={{ pathname: "/admin/brand-rfp-management", state: { selectedBrandId: myRfpsData?.brandId, defaultKey: 'myRFPs' } }}>    {myRfpsData?.companyName}{' - '}{myRfpsData?.programmeName}</Link>
                        </Breadcrumb.Item  >
                        <Breadcrumb.Item style={{

                            fontWeight: 100,
                        }} onClick={() => {
                            setDefaultTab('properties');
                            setComponentName('singleMyRfp');
                            setDefaultTabProperty('properties');

                        }}>
                            Properties
                        </Breadcrumb.Item  >
                        {
                            !inventory && <Breadcrumb.Item>
                                {property.building} - {property.city}
                            </Breadcrumb.Item  >
                        }
                        {
                            inventory && <Breadcrumb.Item onClick={() => {
                                setInventory(null);
                            }}>
                                {property.building} - {property.city}
                            </Breadcrumb.Item  >
                        }
                        {
                            inventory &&
                            <Breadcrumb.Item active>
                                {inventory?.section}
                            </Breadcrumb.Item  >
                        }

                    </Breadcrumb>
                </div></Col>
            </Row>

            <DataTable
                columns={columns1}
                data={surveys}
                pagination
                responsive
                customStyles={tableStyles}
                theme='mysaTable'
                className='brndTbl'
            />
            <Row>
                <Col style={{
                    marginTop: '50px',
                    marginLeft: '25%',
                    width: '756px',
                    height: 'auto',
                    background: 'rgba(0, 0, 0, 0.05)'
                }} sm={6} >
                    <div style={{
                        marginLeft: '30px',
                    }}>


                        <Form onSubmit={handleSubmit(onSubmit)} style={{ margin: 'auto' }} >
                            <h1 style={{
                                fontWeight: 'bold',
                                fontSize: '19px',
                                marginTop: '40px',
                                textAlign: 'left'
                            }} >
                                I confirm that any survey(s) for this property have been completed and we also wish to submit the relevant rates for each inventory for the client's consideration. {(propertyDetailsConfirmationBtn && propertyInventoryIsEnable) ? <>
                                    {
                                        propertySurveyDetailSubmission !== undefined && propertySurveyDetailSubmission !== '' ? <Form.Check
                                            style={checkBoxStyle}
                                            type={`checkbox`}
                                            defaultChecked={true}
                                            disabled={true}
                                        /> :
                                            <Form.Check
                                                style={checkBoxStyle}
                                                type={`checkbox`}
                                                onChange={(value) => {
                                                    setBtnEnable(value.target.checked);
                                                }}
                                            />
                                    } </> : <Form.Check
                                    style={checkBoxStyle}
                                    type={`checkbox`}
                                    disabled={true}
                                />}
                            </h1>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                    <Form.Label>Confirmed by</Form.Label>
                                </Col>
                                <Col md={8} style={{ textAlign: 'left' }}>
                                    <Form.Control defaultValue={(propertySurveyDetailSubmission?.confirmedBy) ? `${propertySurveyDetailSubmission?.confirmedBy}` : ``} className={`${errors.confirmedBy ? 'is-invalid' : ''}`}  {...register("confirmedBy", { required: true })} />
                                    <div className="invalid-feedback">Please input confirmedBy!</div>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                    <Form.Label>Email</Form.Label>
                                </Col>
                                <Col md={8} style={{ textAlign: 'left' }}>
                                    <Form.Control type="email" defaultValue={(propertySurveyDetailSubmission?.email) ? `${propertySurveyDetailSubmission?.email}` : ``} className={`${errors.email ? 'is-invalid' : ''}`}  {...register("email", { required: true })} />
                                    <div className="invalid-feedback">Please input email!</div>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={3} style={{ textAlign: 'right' }}>
                                </Col>
                                <Col md={8} style={{ textAlign: 'right' }}>
                                    {!loading && isBrand === UserGroups.brand_admin && <Button id='approve-button' className="mr-btn" type="submit" style={formButton} disabled={!btnEnable}>{(propertySurveyDetailSubmission !== undefined && propertySurveyDetailSubmission !== '') ? `Confirmed` : `Confirm`} </Button>
                                    }
                                    {
                                        loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                    }
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                        </Form>
                    </div>

                </Col>

            </Row>
        </div>
    )
}

export default BrandAdminPropertyEvaluationList;