import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { navStyle, tabDivStyle } from "../../../../common/components-style";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import ClientFilterProgram from "../../corporate-programme-management/common-views/client-filter-programme/ClientFilterProgram";
import RequestBookingCorporateHome from "../request-booking/corporate/RequestBookingCorporateHome";
import { getStorage } from "../../../../common/storage";
import BookingCorporate from "../bookings/corporate/BookingCorporate";
import TravelerOptionsBooker from "../traveler-options/TravelerOptionsBooker";

const CorporateBookingReports: React.FunctionComponent = () => {

    // url manage
    const location = useLocation<any>();

    ////////////////////////// local state manage ///////////////////////////////////////////////

    //storage 
    const client = getStorage('selectedFilterClient')?.label?getStorage('selectedFilterClient')?.label:getStorage('programmeData')?.clientId?.companyName;
    const program = getStorage('selectedProgramsOption')?.label?getStorage('selectedProgramsOption')?.label:getStorage('programmeData')?.programmeName;

    //filter program
    const [filterProgramme, setFilterProgramme] = useState(location.state?.filterProgramme);
    const [tabKey, setTabKey] = useState<any>(location.state?.tabKey ? location.state?.tabKey : 'bookings');

    return (
        <>
            {
                (filterProgramme) ? <div style={tabDivStyle}>
                    <Tab.Container defaultActiveKey="bookings" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                        <Row >
                            <Col sm={2}>
                                <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                                    <div style={{ marginTop: '0%' }}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="bookings">Bookings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="requestBooking">Request Bookings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="travelerOptions">Traveler Options</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="awaitingApproval">Awaiting Approval</Nav.Link>
                                        </Nav.Item>
                                    </div>
                                </Nav>
                            </Col>
                            <Col sm={10}>
                                <Tab.Content >
                                    <Tab.Pane eventKey="bookings" >
                                        <Row >
                                            <Col md={12}>
                                                <div className='content-title mysa-blue-text'>
                                                    <Link to='/admin/corporate-booking-reports' className="fw-sb mysa-blue-text">{`${client ? client : ''} - ${program ? program : program}`}</Link>
                                                </div>
                                            </Col>
                                        </Row>
                                        <BookingCorporate />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="requestBooking" >
                                        <Row >
                                            <Col md={12}>
                                                <div className='content-title mysa-blue-text'>
                                                    <Link to='/admin/corporate-booking-reports' className="fw-sb mysa-blue-text">{`${client ? client : ''} - ${program ? program : program}`}</Link>
                                                </div>
                                            </Col>
                                        </Row>
                                        <RequestBookingCorporateHome />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="travelerOptions" >
                                     
                                     <TravelerOptionsBooker locationData={location}/>
                                 </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div> : <ClientFilterProgram setFilterProgramme={setFilterProgramme} programsSelectManageStatus={true} searchType="bookingReport"></ClientFilterProgram>
            }
        </>
    );

};

export default CorporateBookingReports;