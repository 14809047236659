import React, { useCallback, useEffect, useState } from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import { $mysaBlue, $mysaMegenta, $mysaOrange, $mysaRed, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, tableStyles } from "../../../../../common/components-style";
import { Col, OverlayTrigger, Row, Tooltip, Form } from "react-bootstrap";
import ViewEdit from '../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { useStoreActions, useStoreState } from "easy-peasy";
import InputC from "../../components/InputC";
import { useForm, SubmitHandler } from "react-hook-form";
import SelectC from "../../components/SelectC";
import ButtonC from "../../components/ButtonC";
import moment from "moment-timezone";
import { LIVE_BOOKING_STATUS } from "../../../../../common/constants";
import { useHistory } from "react-router-dom";

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

/**
 * form type
 */
export type TBookingSuperAdminForm = {
    client?: string;
    programme?: string;
    country?: string;
    city?: string;
    search?: string;
    brand?: string;
    property?: string;
    status?: string;
};

const BookingSuperAdmin: React.FunctionComponent = () => {

    /**
     * redirect hooks
     */
    const { push } = useHistory();

    /**
     * init form
     */
    const { register, handleSubmit } = useForm<TBookingSuperAdminForm>();

    /**
     * global actions
     */
    const { superAdminReport } = useStoreActions<any>((actions) => ({
        superAdminReport: actions.booking.superAdminReport
    }));

    /**
     * global status
     */
    const { getSuperAdminReportSuccess } = useStoreState<any>((actions) => ({
        getSuperAdminReportSuccess: actions.booking.getSuperAdminReportSuccess
    }));


    /**
     * local state management
     */
    const [countryList, setCountryList] = useState<Array<{
        value: string;
        label: string;
    }>>([]);
    const [cityList, setCityList] = useState<Array<{
        value: string;
        label: string;
    }>>([]);
    const [brandList, setBrandList] = useState<Array<{
        value: string;
        label: string;
    }>>([]);
    const [propertyList, setPropertyList] = useState<Array<{
        value: string;
        label: string;
    }>>([]);
    const [clientList, setClientList] = useState<Array<{
        value: string;
        label: string;
    }>>([]);
    const [programmeList, setProgrammeList] = useState<Array<{
        value: string;
        label: string;
    }>>([]);
    const [status, setStatus] = useState<Array<{
        value: string;
        label: string;
    }>>([
        {
            value: LIVE_BOOKING_STATUS.UP_COMING,
            label: 'Upcoming'
        },
        {
            value: LIVE_BOOKING_STATUS.CURRENT,
            label: 'Current'
        },
        {
            value: LIVE_BOOKING_STATUS.COMPLETE,
            label: 'Complete'
        },
        {
            value: LIVE_BOOKING_STATUS.CANCELLED,
            label: 'Cancelled'
        },
    ]);
    const [data, setData] = useState<any[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTable, setLoadingTable] = useState<boolean>(false);

    /**
     * side effect react life cycle
     */
    useEffect(() => {

        superAdminReport({
            client: "",
            programme: "",
            country: "",
            city: "",
            search: "",
            brand: "",
            property: "",
            status: ""
        });

        setLoadingTable(true);

    }, [superAdminReport]);

    /**
     * response manage using react life cycle
     */
    useEffect(() => {

        if (getSuperAdminReportSuccess?.data) {

            //table data
            setData(getSuperAdminReportSuccess?.data)

            //list out country
            const countryL: Array<{
                value: string;
                label: string;
            }> = [];

            //list out city
            const cityL: Array<{
                value: string;
                label: string;
            }> = [];

            //list out brand
            const brandL: Array<{
                value: string;
                label: string;
            }> = [];

            //list out property
            const propertyL: Array<{
                value: string;
                label: string;
            }> = [];

            //list out client
            const clientL: Array<{
                value: string;
                label: string;
            }> = [];

            //list out programme
            const programmeL: Array<{
                value: string;
                label: string;
            }> = [];


            for (const booking of getSuperAdminReportSuccess?.data) {
                //country
                const country: {
                    value: string;
                    label: string;
                } = {
                    value: booking.property.country,
                    label: booking.property.country,
                };

                if (!countryL?.some((country) => country.value === booking.property.country)) {
                    countryL.push(country);
                }

                //city
                const city: {
                    value: string;
                    label: string;
                } = {
                    value: booking.property.city,
                    label: booking.property.city,
                };

                if (!cityL?.some((city) => city.value === booking.property.city)) {
                    cityL.push(city);
                }

                //brand
                const brand: {
                    value: string;
                    label: string;
                } = {
                    value: booking.brand._id,
                    label: booking.brand.name,
                };

                if (!brandL?.some((brand) => brand.value === booking.brand._id)) {
                    brandL.push(brand);
                }

                //property
                const property: {
                    value: string;
                    label: string;
                } = {
                    value: booking.property._id,
                    label: booking.property.propertyName,
                };

                if (!propertyL?.some((property) => property.value === booking.property._id)) {
                    propertyL.push(property);
                }

                //client
                const client: {
                    value: string;
                    label: string;
                } = {
                    value: booking.client._id,
                    label: booking.client.companyName,
                };

                if (!clientL?.some((client) => client.value === booking.client._id)) {
                    clientL.push(client);
                }

                //programme
                const programme: {
                    value: string;
                    label: string;
                } = {
                    value: booking.programme._id,
                    label: booking.programme.programmeName,
                };

                if (!programmeL?.some((programme) => programme.value === booking.programme._id)) {
                    programmeL.push(programme);
                }
            }
            setCountryList(countryL);
            setCityList(cityL);
            setBrandList(brandL);
            setPropertyList(propertyL);
            setClientList(clientL);
            setProgrammeList(programmeL);
            setLoading(false);
            setLoadingTable(false);

        }

    }, [getSuperAdminReportSuccess]);

    const columns = [
        {
            name: 'First Name',
            selector: row => row.traveler.firstName,
        },
        {
            name: 'Second Name',
            selector: row => row.traveler.lastName,
        },
        {
            name: 'Email',
            selector: row => row.traveler.email,
        },
        {
            name: 'Brand',
            selector: row => row.brand.name,
        },
        {
            name: 'Property',
            selector: row => row.property.propertyName,
        },
        {
            name: 'City',
            selector: row => row.property.city,
        },
        {
            name: 'Check-in',
            selector: row => moment(row.checkInDate).format('D MMM YY'),
        },
        {
            name: 'Status',
            cell: (row) => {
                let color = "";
                let status = "";
                if (row.bookingStatus === LIVE_BOOKING_STATUS.UP_COMING) { color = $mysaYellow; status = 'Upcoming' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.AMEND_PENDING) { color = $mysaMegenta; status = 'Amend Pending' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED) { color = $mysaRed; status = 'Cancelled' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.CANCEL_PENDING) { color = $mysaOrange; status = 'Cancel pending' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.COMPLETE) { color = $mysaBlue; status = 'Complete' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.CURRENT) { color = $mysaTeal; status = 'Current' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.AWAIT_INVOICE) { color = $mysaTeal; status = 'Await Invoice' };
                

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{status}</span>
                )
            }
        },
        {
            name: 'Action',
            button: true,
            cell: (row: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                    >
                        <>
                            <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                                push(`/admin/super-admin-booking-report/${row.bookingReference}`);
                            }} />
                            {/* {
                                row.bookingStatus !== LIVE_BOOKING_STATUS.UP_COMING && <img className='iconSizes' src={Message} alt="" onClick={() => {

                                }} />
                            } */}
                        </>
                    </OverlayTrigger>

                </>)
            },
        },
    ];

    /**
     * filter table
     */
    const onSubmit: SubmitHandler<TBookingSuperAdminForm> = useCallback(({ client, programme, search, brand, property, country, city }) => {

        superAdminReport({
            client: client,
            programme: programme,
            country: country,
            city: city,
            search: search,
            brand: brand,
            property: property,
            status: ""
        });

        setLoading(true);
    }, [superAdminReport]);

    /**
     * trigger status
     */
    const statusTrigger = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {

        superAdminReport({
            client: "",
            programme: "",
            country: "",
            city: "",
            search: "",
            brand: "",
            property: "",
            status: e.currentTarget.value
        });

        setLoadingTable(true);
    }, [superAdminReport])

    return (
        <>
            <Row className="text-center mt-4">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col xs={2}>
                            <SelectC name="client" register={register} data={clientList} />
                        </Col>
                        <Col xs={2}>
                            <SelectC name="programme" register={register} data={programmeList} />
                        </Col>
                        <Col xs={8}></Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={2}>
                            <SelectC name="country" register={register} data={countryList} />
                        </Col>
                        <Col xs={2}>
                            <SelectC name="city" register={register} data={cityList} />
                        </Col>
                        <Col xs={4}>
                            <InputC name="search" register={register} />
                        </Col>
                        <Col xs={2}>
                            <ButtonC loading={loading} id="blue-button" label="Search" />
                        </Col>
                        <Col xs={2}>
                            <SelectC name="status" register={register} data={status} onChange={statusTrigger} />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={2}>
                            <SelectC name="brand" register={register} data={brandList} />
                        </Col>
                        <Col xs={2}>
                            <SelectC name="property" register={register} data={propertyList} />
                        </Col>
                        <Col xs={8}></Col>
                    </Row>
                </Form>
            </Row>
            <div className='brnd_tbl' style={{ marginTop: '10px' }}>

                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    striped={true}
                    progressPending={loadingTable}
                    customStyles={tableStyles}
                    theme='mysaTable'
                    fixedHeader={true}
                    noDataComponent={'Updating records'}
                />
            </div>
        </>
    );
};

export default BookingSuperAdmin;