import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import { $mysaRed, $mysaTeal, $mysaYellow, mysaTableTheme, tableStyles } from '../../../../../common/components-style';
import Select from "react-select";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { BrandRfpPropertyEvaluationStatus } from '../../../../../common/constants';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');


/////////////////////////// table button style ///////////////////////////////////////////

const tableButtonStyle = {
  background: '#FBC91D',
  border: '1px solid #FBC91D',
  borderRadius: 15,
  height: '40px',
  paddingLeft: '20px',
  paddingRight: '20px',
  color: '#212121',
}

///////////////////////////////// dropdown style ///////////////////////////////////////

const selectStyles = {
  control: (base, state) => ({
    ...base,
    height: "47px",
    paddingLeft: "10px",
    color: "#212121",
    borderRadius: 15,
    boxShadow: " 0px 0px 8px 2px rgba(0, 0, 0, 0.22)",
    "&:hover": {
      border: 0,
    },
  }),
  menu: (base) => ({
    ...base,
    hyphens: "auto",
    marginTop: 0,
    borderRadius: "14px",
    textAlign: "left",
    zIndex: 3
  }),
  menuList: (base) => ({
    ...base,
    padding: "10px",
    color: "#000000",
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#000000"
  })
};

const management = [
  {
    value: "no",
    label: (<b>Building Managed By Brand</b>)
  },
  {
    value: "yes",
    label: (<b>Building Managed By 3rd Party</b>)
  }
];

const nights = [
  {
    value: "oneTo7",
    label: (<b>1-7 nights</b>)
  },
  {
    value: "eightTo14",
    label: (<b>8-14 nights</b>)
  },
  {
    value: "fifteenTo28",
    label: (<b>15-28 nights</b>)
  },
  {
    value: "twentyNineTo90",
    label: (<b>29-90 nights</b>)
  },
  {
    value: "ninetyOnePlus",
    label: (<b>91+ nights</b>)
  }
];



const ClientCostComparison: React.FC<any> = ({ rfpProcess, selectCityName }): JSX.Element => {

  ////////// routing ///////
  const history = useHistory();
  const location = useLocation<any>();

  const [propertyTypes, setPropertyTypes] = useState<any>([]);
  const [checkIn, setCheckIn] = useState<any>([]);
  const [rooms, setRooms] = useState<any>([{
    value: '61deb0c929ffed00139f51eb',
    label: (<b>1 bedroom</b>)
  }]);

  const [roomType, setRoomType] = useState<any>();
  const [propsType, setPropsType] = useState<any>([]);
  const [selectCheckIn, setSelectCheckIn] = useState<any>();
  const [fullyManageByThirdParty, setFullyManageByThirdParty] = useState<any>();
  const [rateValue, setRateValue] = useState<any>('oneTo7');
  const [properties, setProperties] = useState<any>([]);
  const [propertyTypeIds, setPropertyTypeIds] = useState<any>([]);
  const [roomTypeId, setRoomTypeId] = useState<any>();
  const [disableAllManagement, setDisableAllManagement] = useState<boolean>(true);
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getPropertyTypes, getCheckInProcessDetail, getRoomTypeList, getCostComparison } = useStoreActions<any>((actions) => ({
    getPropertyTypes: actions.property.getPropertyTypes,
    getCheckInProcessDetail: actions.property.getCheckInProcessDetail,
    getRoomTypeList: actions.inventory.getRoomTypeList,
    getCostComparison: actions.rfpProcess.getCostComparison,
  }));

  const {
    getPropertyTypesSuccess,
    getCheckInProcessListSuccess,
    getRoomTypeListSuccess,
    getCostComparisonSuccess
  } = useStoreState<any>((state) => ({
    getPropertyTypesSuccess: state.property.getPropertyTypesSuccess,
    getCheckInProcessListSuccess: state.property.getCheckInProcessListSuccess,
    getRoomTypeListSuccess: state.inventory.getRoomTypeListSuccess,
    getCostComparisonSuccess: state.rfpProcess.getCostComparisonSuccess,
  }));
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////// initialize 
  useEffect(() => {
    getPropertyTypes();
    getCheckInProcessDetail();
    getRoomTypeList();
  }, [getCheckInProcessDetail, getPropertyTypes, getRoomTypeList]);

  ////////////// initialize property results
  useEffect(() => {

    if (getPropertyTypesSuccess) {
      const types = getPropertyTypesSuccess.data;
      const proType: any = []
      const ids: any = [];

      types.forEach((propertyType) => {
        if (propertyType.name.trim() === 'Aparthotel') {
          const type = {
            value: propertyType._id,
            label: (<b>{propertyType.name}</b>)
          }
          proType.push(type);
        }
        if (propertyType.name.trim() === 'Apartments - building fully managed by our brand' || propertyType.name.trim() === 'Apartments - building managed by a third party') {
          if (ids?.length === 0) {
            const type = {
              value: 'NO_ID',
              label: (<b>{`Serviced Apartment (Corporate Housing)`}</b>)
            }
            proType.push(type);
          }
          ids.push(propertyType._id);
        }
        if (propertyType.name.trim() === 'Self contained single building') {
          const type = {
            value: propertyType._id,
            label: (<b>{`Self Contained Single Property`}</b>)
          }
          proType.push(type);
        }
      });
      setPropertyTypeIds(ids);
      setPropertyTypes(proType);
    }

    if (getCheckInProcessListSuccess) {
      const checkIn = getCheckInProcessListSuccess.data;
      const check: any = [];
      checkIn.forEach((checkValue) => {
        if (checkValue?.alternativeCheckInProcess?.length === 0) {
          const checkn = {
            label: (<hr></hr>),
            options: [{
              value: checkValue?._id,
              label: (<b>{checkValue?.name}</b>)
            }]
          }
          check.push(checkn);
        } else {

          const alternativeCheckInTemp: any = [];
          checkValue?.alternativeCheckInProcess.forEach(alternativeCheckIn => {
            const alterCheckkn = {
              value: alternativeCheckIn?._id,
              label: (<b>{alternativeCheckIn?.name}</b>)
            }
            alternativeCheckInTemp.push(alterCheckkn);
          });

          const checkn = {
            label: checkValue?.name,
            options: alternativeCheckInTemp
          };
          check.push(checkn);
        }
      });
      setCheckIn(check);
    }

  }, [getPropertyTypesSuccess, getCheckInProcessListSuccess]);

  ////////////// initialize inventory results
  useEffect(() => {

    if (getRoomTypeListSuccess) {
      const roomsList = getRoomTypeListSuccess.data;
      const roomTypes: any = [];
      roomsList.forEach((room) => {
        const type = {
          value: room._id,
          label: (<b>{room.name.trim()}</b>)
        }
        roomTypes.push(type);
      });

      setRoomType(roomTypes);
      // setRooms(roomType);
    }

  }, [getRoomTypeListSuccess]);

  // load table data
  useEffect(() => {
    if (location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id && selectCityName) {

      const payload = {
        rfp_id: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
        cityName: selectCityName,
        roomType: roomTypeId ? roomTypeId : (rooms[0]?.value),
        checkIn: (selectCheckIn) ? selectCheckIn : '',
        propertyTypeIds: (propsType.length !== 0) ? (propsType[0] === 'NO_ID') ? propertyTypeIds : propsType : '',
        fullyManageByThirdParty: (fullyManageByThirdParty) ? fullyManageByThirdParty : ''
      };
      getCostComparison(payload);
    }

  }, [fullyManageByThirdParty, getCostComparison, location.state?.rfpId, propertyTypeIds, propsType, rfpProcess?._id, roomTypeId, rooms, selectCheckIn, selectCityName]);

  useEffect(() => {

    if (getCostComparisonSuccess) {

      const getCostComparison = _.filter(getCostComparisonSuccess?.data, list => ((list.evaluationStatus !== BrandRfpPropertyEvaluationStatus.INCOMPLETE)));
      setProperties(getCostComparison);

      // const getCostComparison = getCostComparisonSuccess.data;
      // console.log(getCostComparisonSuccess.data, 'data');
      // setProperties(getCostComparison);
    }

  }, [getCostComparisonSuccess])


  const changeProperty = (value) => {
    const ids: any = [];
    if (value && value.value === 'NO_ID') {
      setDisableAllManagement(false);
    } else {
      setDisableAllManagement(true);
    }

    if (value) {
      ids.push(value.value);
      setPropsType(ids);
    } else {
      setPropsType([]);
    }
  }

  const changeCheckIn = (value) => {
    setSelectCheckIn(value ? value.value : '');
  }

  const changeRoomType = value => {
    setRoomTypeId(value.value);
  }

  const changeManagement = value => {
    setFullyManageByThirdParty(value ? value.value : '');
  }

  const changeRate = value => {
    setRateValue(value.value);
  }

  /////// return rates
  const rateFilter = (value) => {

    switch (rateValue) {
      case 'oneTo7': return value.corporateRate.standardRate.oneTo7;
      case 'eightTo14': return value.corporateRate.standardRate.eightTo14;
      case 'fifteenTo28': return value.corporateRate.standardRate.fifteenTo28;
      case 'twentyNineTo90': return value.corporateRate.standardRate.twentyNineTo90;
      case 'ninetyOnePlus': return value.corporateRate.standardRate.ninetyOnePlus;
      default: return value.corporateRate.standardRate.oneTo7;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      name: 'Property',
      sortable: true,
      reorder: true,
      selector: row => row.property.propertyName,
    },
    {
      name: 'Brand',
      sortable: true,
      reorder: true,
      selector: row => row.property.brand.name,
    },
    {
      name: 'Rate',
      sortable: true,
      reorder: true,
      selector: row => row.property.currency + " " + rateFilter(row.property.inventory[0]),
    },

    {
      name: 'Status',
      wrap: true,
      cell: (row: any) => {
        let color = "";

        let text = "";
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED) {
          color = $mysaYellow;
          text = "Negotiation ";
        }
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.COMPLETE) {
          text = "To assess";
          color = $mysaTeal;
        }

        // if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.INCOMPLETE) {
        //   color = $mysaMegenta;
        //   text = BrandRfpPropertyEvaluationStatus.INCOMPLETE;
        // }

        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED) {
          color = $mysaRed;
          text = BrandRfpPropertyEvaluationStatus.DECLINED;
        }
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) {
          color = $mysaTeal;
          text = BrandRfpPropertyEvaluationStatus.ACCEPTED;
        }
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW) {
          color = $mysaYellow;
          text = 'Negotiation ';
        }
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.PART_ACCEPTED) {
          color = $mysaTeal;
          text = BrandRfpPropertyEvaluationStatus.PART_ACCEPTED;
        }


        // }

        return (
          <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize' >{text}
          </span>


        )
      }
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <>
            <Button style={tableButtonStyle} onClick={() => {
              history.push({
                pathname: `/admin/client-proposal-management/property/${row.propertyId}/${location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id}`
              });
            }}><b>View Property</b></Button>
          </>
        );
      }
    }
  ];

  return (
    <>
      <Row >
        <Col md={2}>
          <Select
            placeholder={<b>All Property Type</b>}
            isClearable={true}
            styles={selectStyles}
            defaultValue={propertyTypes[0]}
            options={propertyTypes}
            onChange={changeProperty}
          />
        </Col>
        <Col md={2}>
          {
            disableAllManagement ? <Select
              placeholder={<b>All Management</b>}
              styles={selectStyles}
              value={''}
              isDisabled={true}
            /> : <Select
              placeholder={<b>All Management</b>}
              isClearable={true}
              styles={selectStyles}
              options={management}
              onChange={changeManagement}
            />
          }

        </Col>
        <Col md={4}>
          <Select
            placeholder={<b>All Check-in</b>}
            defaultValue={checkIn[0]}
            isClearable={true}
            styles={selectStyles}
            options={checkIn}
            onChange={changeCheckIn}
          />

        </Col>
        <Col md={2}>
          <Select
            defaultValue={rooms[0]}
            styles={selectStyles}
            options={roomType}
            onChange={changeRoomType}
          />
        </Col>
        <Col md={2}>
          <Select
            defaultValue={nights[0]}
            styles={selectStyles}
            options={nights}
            onChange={changeRate}
          />
        </Col>
      </Row>
      <div className='mt-3'>
        <DataTable
          columns={columns}
          data={properties}
          pagination
          striped={true}
          customStyles={tableStyles}
          theme='mysaTable'
          fixedHeader={true}
          noDataComponent={'Updating records'}
        />
      </div>
    </>
  );
}

export default ClientCostComparison;