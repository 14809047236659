import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import { useHistory, useLocation } from 'react-router-dom';
import { $mysaBlue, $mysaMegenta, $mysaRed, $mysaTeal, $mysaYellow, formButton, mysaTableTheme, overlayTriggerDelay, tableStyles } from '../../../../../common/components-style';
import { BrandRfpPropertyEvaluationStatus, PropertyInventoryStatus } from '../../../../../common/constants';
import File from '../../../../../images/icons/svg/outside/file-icon.svg';
import { toast } from 'react-toastify';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

/////////////////////////// table button style ///////////////////////////////////////////
// const tableButtonStyle = {
//   background: '#FBC91D',
//   border: '1px solid #FBC91D',
//   borderRadius: 15,
//   height: '40px',
//   paddingLeft: '20px',
//   paddingRight: '20px',
//   color: '#212121',
// }

const DeclinedPropertyList: React.FC<any> = ({ rfpProcess, selectCityName }): JSX.Element => {
  //////////////////// local state management 
  const [tableData, setTableData] = useState<any>();
  const [oldCity, setOldCity] = useState<string>();
  const [propertyDetail, setPropertyDetail] = useState<any>();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [propertyAcceptInformation, setPropertyAcceptInformation] = useState<any>();
  const [inventoryRates, setInventoryRates] = useState<any>([]);

  ////////// routing ///////

  const history = useHistory();
  const location = useLocation<any>();

  /////////////////////////////////// global action manage ////////////////////////////////////////////
  const { programmePropertyList, getPropertyAcceptDeclineOfRFPByPropertyId, acceptPropertyRateForRFP, acceptPropertyForRFP, getPropertyInventoryRatesForRFP } = useStoreActions<any>(
    (actions) => ({
      programmePropertyList:
        actions.rfpProcess.programmePropertyList,
      getPropertyAcceptDeclineOfRFPByPropertyId: actions.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyId,
      acceptPropertyRateForRFP: actions.rfpProcess.acceptPropertyRateForRFP,
      acceptPropertyForRFP: actions.rfpProcess.acceptPropertyForRFP,
      getPropertyInventoryRatesForRFP: actions.rfpProcess.getPropertyInventoryRatesForRFP,
    })
  );

  /////////////////////////////////// global state manage ////////////////////////////////////////////
  const { getProgrammePropertyListSuccess, getPropertyAcceptDeclineOfRFPByPropertyIdSuccess, acceptPropertyRateForRFPSuccess, acceptPropertyRateForRFPError,
    acceptPropertyForRFPSuccess, acceptPropertyForRFPError, getPropertyInventoryRatesForRFPSuccess } = useStoreState<any>(
      (state) => ({
        getProgrammePropertyListSuccess:
          state.rfpProcess.getProgrammePropertyListSuccess,
        getProgrammePropertyListError:
          state.rfpProcess.getProgrammePropertyListError,
        getPropertyAcceptDeclineOfRFPByPropertyIdSuccess: state.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,
        acceptPropertyRateForRFPSuccess: state.rfpProcess.acceptPropertyRateForRFPSuccess,
        acceptPropertyRateForRFPError: state.rfpProcess.acceptPropertyRateForRFPError,
        getPropertyInventoryRatesForRFPSuccess: state.rfpProcess.getPropertyInventoryRatesForRFPSuccess,
        acceptPropertyForRFPSuccess: state.rfpProcess.acceptPropertyForRFPSuccess,
        acceptPropertyForRFPError: state.rfpProcess.acceptPropertyForRFPError,
      })
    );

  // load from state from url
  useEffect(() => {
    if (location.state?.cityName && location.state?.rfpId) {
      const payload = {
        rfp_id: location.state?.rfpId,
        city_name: location.state?.cityName
      };
      programmePropertyList(payload);
      setOldCity(location.state?.cityName);
    }
  }, [location.state?.cityName, location.state?.rfpId, programmePropertyList]);


  // load initialize
  useEffect(() => {
    if (selectCityName) {
      const payload = {
        rfp_id: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
        city_name: selectCityName
      };
      if (selectCityName !== oldCity) {
        programmePropertyList(payload);
        setOldCity(selectCityName);
      }
    }
  }, [location.state?.rfpId, oldCity, programmePropertyList, rfpProcess?._id, selectCityName]);

  // load initialize
  useEffect(() => {
    if (getProgrammePropertyListSuccess) {
      const data = _.filter(getProgrammePropertyListSuccess?.data, list => ((list.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED)));
      setTableData(data);
    }
  }, [getProgrammePropertyListSuccess]);


  //////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getPropertyAcceptDeclineOfRFPByPropertyIdSuccess) {
      setPropertyAcceptInformation(getPropertyAcceptDeclineOfRFPByPropertyIdSuccess?.data);
    }
  }, [getPropertyAcceptDeclineOfRFPByPropertyIdSuccess])


  ///////////////////////////////////////////////////////////////////////////////////////////
  const callData = useCallback((data) => {
    const params = {
      brandId: data?.property?.brandId?._id,
      propertyId: data?.propertyId,
      rfpProcessId: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
      cityName: data?.property?.city
    }
    if (data?.property?.brandId?._id) {

      setTimeout(() =>
        getPropertyInventoryRatesForRFP(params)
        , 1000);

    }


  }, [getPropertyInventoryRatesForRFP, location.state?.rfpId, rfpProcess?._id]);


  ////////////////////////accept rate ///////////////////////////////////
  const onReactiveProperty = useCallback((data) => {
    // const payload = {
    //   rfpId: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
    //   brandId: data?.property?.brandId?._id,
    //   propertyId: data?.propertyId,
    //   propertyInventoryId: inventoryRates[0]?.inventory,
    //   propertyInventoryStatus: PropertyInventoryStatus.PROPOSED,
    //   cityName: data?.property?.city,

    // }
    if (inventoryRates.length === 1) {
      // if(inventoryRates)
      const payload = {
        rfpId: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
        brandId: data?.property?.brandId?._id,
        propertyId: data?.propertyId,
        propertyInventoryId: inventoryRates[0]?.inventory,
        propertyInventoryStatus: PropertyInventoryStatus.PROPOSED,
        cityName: data?.property?.city,

      }
      acceptPropertyRateForRFP(payload);
    } else {
      const payload = {
        rfpId: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
        brandId: propertyDetail?.property?.brandId?._id,
        propertyId: propertyDetail?.propertyId,
        propertyStatus: BrandRfpPropertyEvaluationStatus.COMPLETE,
        cityName: propertyDetail?.property?.city,
        isClient: true
      }
      acceptPropertyForRFP(payload);
    }

  }, [acceptPropertyForRFP, acceptPropertyRateForRFP, inventoryRates, location.state?.rfpId, propertyDetail?.property?.brandId?._id, propertyDetail?.property?.city, propertyDetail?.propertyId, rfpProcess?._id]);
  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    // if (getPropertyInventoryRatesSuccess) {
    //     setInventoryRates(getPropertyInventoryRatesSuccess.data?.property?.inventory);
    // }
    if (getPropertyInventoryRatesForRFPSuccess) {


      if (getPropertyInventoryRatesForRFPSuccess?.data) {
        if (propertyDetail) {
          onReactiveProperty(propertyDetail);
        }

        setInventoryRates(getPropertyInventoryRatesForRFPSuccess?.data?.inventories);
      }

    }
  }, [getPropertyInventoryRatesForRFPSuccess, onReactiveProperty, propertyDetail])


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const callApi = useCallback((data) => {
    setShowMessage(true);
    const payload = {
      rfpId: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
      propertyId: data?.propertyId
    }
    if (data?.propertyId) {
      getPropertyAcceptDeclineOfRFPByPropertyId(payload)
    }
  }, [getPropertyAcceptDeclineOfRFPByPropertyId, location.state?.rfpId, rfpProcess?._id]);


  ///////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (acceptPropertyRateForRFPSuccess) {
      const payload = {
        rfpId: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
        brandId: propertyDetail?.property?.brandId?._id,
        propertyId: propertyDetail?.propertyId,
        propertyStatus: BrandRfpPropertyEvaluationStatus.COMPLETE,
        cityName: propertyDetail?.property?.city,
        isClient: true
      }
      acceptPropertyForRFP(payload);
    }

    if (acceptPropertyRateForRFPError) {

      toast.error(acceptPropertyRateForRFPError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });

    }
  }, [acceptPropertyForRFP, acceptPropertyRateForRFPError, acceptPropertyRateForRFPSuccess, location.state?.rfpId, propertyDetail?.property?.brandId?._id, propertyDetail?.property?.city, propertyDetail?.propertyId, rfpProcess?._id]);


  const callApiList = useCallback(() => {

    if (selectCityName) {
      const payload = {
        rfp_id: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
        city_name: selectCityName
      };
      // if (selectCityName !== oldCity) {
      programmePropertyList(payload);
      // setOldCity(selectCityName);
      // }
    }
  }, [location.state?.rfpId, programmePropertyList, rfpProcess?._id, selectCityName]);

  /////////////////////////accept property success//////////////////////////////////////////////////////////////
  useEffect(() => {
    if (acceptPropertyForRFPSuccess) {
      toast.success("Property reactivated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      callApiList();
    }

    if (acceptPropertyForRFPError) {
      toast.error(acceptPropertyForRFPError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

  }, [acceptPropertyForRFPError, acceptPropertyForRFPSuccess, callApiList]);

  // console.log(propertyAcceptInformation?.messageThread,'&& propertyAcceptInformation?.messageThread')
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      name: 'Property',
      selector: row => row.property?.propertyName,
    },
    {
      name: 'Brand',
      selector: row => row.property?.brandId.name,
    },
    {
      name: 'Status',
      cell: (row: any) => {

        let color = "";

        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.COMPLETE) color = $mysaTeal;
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.INCOMPLETE) color = $mysaMegenta;
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED) color = $mysaRed;
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) color = $mysaTeal;
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW) color = $mysaYellow;
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED) color = $mysaBlue;
        if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.PART_ACCEPTED) color = $mysaTeal;
        return (
          <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize' >{row.evaluationStatus}


            {((row.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED)) &&
              <OverlayTrigger
                placement="top"
                delay={overlayTriggerDelay}
                overlay={<Tooltip id='tooltip-table-top'>View Comments</Tooltip>}
              >
                <img className='iconSizes4 ' src={File} alt="" style={{ marginLeft: '80px', marginTop: '-19px' }} onClick={() => { callApi(row); }} />
              </OverlayTrigger>
            }
          </span>
        )
      }
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <>
            <Button className='btn-viewProperty' onClick={() => {
              history.push({
                pathname: `/admin/client-proposal-management/declinedProperty/${row.property?._id}/${location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id}`
              });
            }}><b>View Property</b></Button>

            <Button className='btn-approval ml-5' onClick={() => { callData(row); setPropertyDetail(row); }}>Reactivate</Button>
          </>
        );
      }
    }
  ];

  return (
    <>
      <div className='mt-3'>
        <DataTable
          columns={columns}
          data={tableData}
          pagination
          striped={true}
          customStyles={tableStyles}
          theme='mysaTable'
          fixedHeader={true}
          noDataComponent={'Updating records'}
        />
      </div>



      {<Modal
        show={showMessage}
        onHide={() => { setShowMessage(false) }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={false}
        scrollable={true}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          {
            propertyAcceptInformation?.clarificationComment &&
            <Row>
              <Col md={10}><div style={{ textAlign: 'left' }}>

                <Form.Control as="textarea" rows={5} disabled
                  // placeholder="please provide comments"
                  name='clarificationComment'
                  defaultValue={propertyAcceptInformation?.clarificationComment}
                />
              </div></Col>
              <Col md={1}></Col>
            </Row>}

          {propertyAcceptInformation?.propertyReviewComment &&
            <Row>
              <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                <Form.Control as="textarea" rows={5} disabled
                  // placeholder="please provide comments"
                  name='propertyReviewComment'
                  defaultValue={propertyAcceptInformation?.propertyReviewComment}
                />
              </div></Col>
              <Col md={1}></Col>
            </Row>}

          {/* {propertyAcceptInformation?.respondPropertyComment && <Row>
            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

              <Form.Control as="textarea" rows={5} disabled
                // placeholder="please provide comments"
                name='propertyResponseComment'
                defaultValue={propertyAcceptInformation?.respondPropertyComment}
              />
            </div></Col>
            <Col md={1}></Col>
          </Row>}


          {propertyAcceptInformation?.brandRespondPropertyComment && <Row>
            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

              <Form.Control as="textarea" rows={5} disabled
                // placeholder="please provide comments"
                name='brandRespondPropertyComment'
                defaultValue={propertyAcceptInformation?.brandRespondPropertyComment}
              />
            </div></Col>
            <Col md={1}></Col>
          </Row>} */}

          {
            propertyAcceptInformation?.messageThread.length !== 0 && propertyAcceptInformation?.messageThread.map((values, key) => {
              return (<div key={key}>
                {/* <p style={{ marginBottom: '0px' }}>{(key === 0) ? (values?.direction === 'booker_to_property') ? 'Booker (original message)' : 'Property (original message)' : (values?.direction === 'booker_to_property') ? 'Booker (further message)' : 'Property (further message)'}</p> */}
                {/* <p className="lead">{values?.messageText}</p> */}

                {values?.clientRespondComment && <Row>
                  <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                    <Form.Control as="textarea" rows={5} disabled
                      // placeholder="please provide comments"
                      name='propertyResponseComment'
                      defaultValue={values?.clientRespondComment?.messageText}
                    />
                  </div></Col>
                  <Col md={1}></Col>
                </Row>}


                {values?.brandRespondComment && <Row>
                  <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                    <Form.Control as="textarea" rows={5} disabled
                      // placeholder="please provide comments"
                      name='brandRespondPropertyComment'
                      defaultValue={values?.brandRespondComment?.messageText}
                    />
                  </div></Col>
                  <Col md={1}></Col>
                </Row>}

              </div>)
            })
          }
          {propertyAcceptInformation?.declineComment && <Row>
            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

              <Form.Control as="textarea" rows={5} disabled
                // placeholder="please provide comments"
                name='declineComment'
                defaultValue={propertyAcceptInformation?.declineComment}
              />
            </div></Col>
            <Col md={1}></Col>
          </Row>}

          {propertyAcceptInformation?.messageThread.length !== 0 && ((propertyAcceptInformation?.messageThread[propertyAcceptInformation?.messageThread.length - 1].clientRespondComment?.messageText) || propertyAcceptInformation?.declineComment) &&
            <Row className='formSpace'>

              <Col style={{ textAlign: 'center' }}>
                <Button style={formButton} onClick={() => { setShowMessage(false); }} id='edit-button' >Close</Button>
              </Col>
            </Row>
          }
        </Modal.Body>
      </Modal>}
    </>
  );
}

export default DeclinedPropertyList;