import React, { } from 'react';

import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
const BrandHelpPage: React.FC = (): JSX.Element => {

    const url = "https://vimeo.com/821939708/ea99f66715?share=copy";

    return (
        <>
            {<div>
                <div >
                    <p className='mysa-green-text  fw-bold' style={{ fontSize: '28px' }}>  Welcome to the myo help page. Here, you will find useful information and help videos to support you in managing your portal.
                    </p>
                </div>
                <div>
                    <p className='mysa-green-text  fw-bold' style={{ fontSize: '28px' }} >
                        New videos and guides will be added periodically.
                    </p>
                </div>
                <div className='row mt-5'>
                    <div className='col-sm-6'>
                        <div className='dash_wel fw-bold'>
                            <p style={{ fontSize: '28px' }}>Getting started video </p>
                            <a target="_new" className="flex items-center" href={url}>

                                <img className='iconSizes ml-3' style={{ marginTop: '-5px' }} src={ViewEdit} alt="" />
                                {/* <img className='iconSizes' src={ViewEdit} alt="" /> */}
                            </a>

                        </div>
                    </div>


                </div>





            </div>}
        </>
    )
};

export default BrandHelpPage;