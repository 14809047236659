import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { $mysaMegenta, $mysaRed, $mysaTeal, formButton } from "../../../../../common/components-style";
import SweetAlert from 'react-bootstrap-sweetalert';
import { REQUEST_BOOKING_STATUS } from "../../../../../common/constants";
import { TRequestBookingView } from "../../../../../common/types";

type TMessage = {
    messageText: String
};

const RequestBookingTravelerHomeView: React.FunctionComponent<TRequestBookingView> = ({ status, _id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, hideShowViewComponent }) => {

    /**
     * redirect hooks
     */
    const { push } = useHistory();

    //////////// form handle ///////////////////////
    const { register, handleSubmit, formState: { errors }, reset } = useForm<TMessage>();

    ///////////////////////////global action///////////////////////////////////
    const { getMessageThreadById, updateMessageThread, setReset, updateRequestStatus } = useStoreActions<any>((actions) => ({
        getMessageThreadById: actions.booking.getMessageThreadById,
        updateMessageThread: actions.booking.updateMessageThread,
        setReset: actions.booking.setReset,
        updateRequestStatus: actions.booking.updateRequestStatus,
    }));

    /////////////////// global state ////////////////
    const { getMessageThreadByIdSuccess, getUpdateMessageThreadSuccess, getUpdateRequestStatusSuccess, getUpdateRequestStatusError } = useStoreState<any>((actions) => ({
        getMessageThreadByIdSuccess: actions.booking.getMessageThreadByIdSuccess,
        getUpdateMessageThreadSuccess: actions.booking.getUpdateMessageThreadSuccess,
        getUpdateRequestStatusSuccess: actions.booking.getUpdateRequestStatusSuccess,
        getUpdateRequestStatusError: actions.booking.getUpdateRequestStatusError,
    }));

    ///////////////// local state ////////////////
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [viewTextArea, setViewTextArea] = useState<boolean>(false);
    const [archivePopop, setArchivePopop] = useState<boolean>(false);

    //////////// action hook //////////////
    useEffect(() => {
        if (_id && _reqBrandId && _reqGroupId && _reqInventoryId && _reqPropertyId) {
            const payload = {
                _id: _id,
                _reqBrandId: _reqBrandId,
                _reqGroupId: _reqGroupId,
                _reqInventoryId: _reqInventoryId,
                _reqPropertyId: _reqPropertyId
            }
            getMessageThreadById(payload);

        }
    }, [_id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, getMessageThreadById]);

    ///////////////// data fetch and handle hook /////////////////
    useEffect(() => {

        if (getMessageThreadByIdSuccess) {
            setData(getMessageThreadByIdSuccess.data)
        }

        if (getUpdateMessageThreadSuccess) {
            toast.success("Message send successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setLoading(false);
            setViewTextArea(false);
            if (_id && _reqBrandId && _reqGroupId && _reqInventoryId && _reqPropertyId) {
                const payload = {
                    _id: _id,
                    _reqBrandId: _reqBrandId,
                    _reqGroupId: _reqGroupId,
                    _reqInventoryId: _reqInventoryId,
                    _reqPropertyId: _reqPropertyId
                }
                getMessageThreadById(payload);
            }
            reset();
            setReset(false);
        }

        if (getUpdateRequestStatusSuccess) {
            setLoading(false);
            hideShowViewComponent(false);
        }

        if (getUpdateRequestStatusError) {
            setLoading(false);
            setReset();
            toast.error(getUpdateRequestStatusError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [_id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, getMessageThreadById, getMessageThreadByIdSuccess, getUpdateMessageThreadSuccess, getUpdateRequestStatusError, getUpdateRequestStatusSuccess, hideShowViewComponent, reset, setReset]);

    ///////////////////////////////////////////////////////
    const onSubmit = useCallback((value) => {
        setLoading(true);
        const payload = {
            _id: _id,
            _reqGroupId: _reqGroupId,
            _reqBrandId: _reqBrandId,
            _reqPropertyId: _reqPropertyId,
            _reqInventoryId: _reqInventoryId,
            newMessage: {
                direction: "booker_to_property",
                messageText: value.messageText
            }
        }
        updateMessageThread(payload);
    }, [_id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, updateMessageThread]);

    return (<>
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to={{ pathname: "/admin/traveler-booking-reports" }} >Request Bookings</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{data?.travelerName}</Breadcrumb.Item>
            <Breadcrumb.Item active>{data?._reqInventoryId}</Breadcrumb.Item>
        </Breadcrumb>
        <Container fluid className='p-0'>
            <div className="jumbotron" style={{ marginTop: "65px" }}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Traveler Name</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data?.travelerName}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Location</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data?.location?.locationName}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Property</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data?.property?.propertyName}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Accommodation Requested</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{data?.inventory?.inventoryName}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Check-in</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{moment(data?.checkInDate).format('D MMM YYYY')}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Check-out</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{moment(data?.checkOutDate).format('D MMM YYYY')}</p>
                                </Col>
                            </Row>
                            {
                                status === REQUEST_BOOKING_STATUS.ALT_OFFER && <>
                                    {/* new ui start*/}
                                    <Row className='formSpace'>
                                        <Col md={5} style={{ textAlign: 'right' }}>
                                            <Form.Label>Status</Form.Label>
                                        </Col>
                                        <Col md={7} style={{ textAlign: 'left' }}>
                                            <p style={{ color: $mysaMegenta, fontWeight: 'bold' }} className="lead">{`ALT OFFER`}</p>
                                        </Col>
                                    </Row>
                                    <Row className='formSpace'>
                                        <Col md={5} style={{ textAlign: 'right' }}>
                                            <Form.Label>Alternative Offer</Form.Label>
                                        </Col>
                                        <Col md={4} style={{ textAlign: 'left' }}>
                                            <p className="lead">{data?.inventory?.inventoryName}</p>
                                        </Col>
                                        <Col md={1} style={{ textAlign: 'right' }}>
                                            <Form.Label>View</Form.Label>
                                        </Col>
                                        <Col md={2} style={{ textAlign: 'left' }}>
                                            <p className="lead">Send To Traveler</p>
                                        </Col>
                                    </Row>
                                    <Row className='formSpace'>
                                        <Col md={5} style={{ textAlign: 'right' }}>
                                            <Form.Label>Rate Offered</Form.Label>
                                        </Col>
                                        <Col md={7} style={{ textAlign: 'left' }}>
                                            <p className="lead">£{data?.reducedOrRequestedAccommodationRateAndCurrency?.rate}</p>
                                        </Col>
                                    </Row>
                                </>
                            }
                            {
                                status === REQUEST_BOOKING_STATUS.EXPIRED && <>
                                    {/* new ui start*/}
                                    <Row className='formSpace'>
                                        <Col md={5} style={{ textAlign: 'right' }}>
                                            <Form.Label>Status</Form.Label>
                                        </Col>
                                        <Col md={7} style={{ textAlign: 'left' }}>
                                            <p style={{ color: $mysaRed, fontWeight: 'bold' }} className="lead">{`EXPIRED`}</p>
                                        </Col>
                                    </Row>
                                </>
                            }

                            {/* new ui end*/}
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Messages</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    {
                                        data?.messageThread.map((values, key) => {
                                            return (<div key={key}>
                                                <p style={{ marginBottom: '0px' }}>{(key === 0) ? (values?.direction === 'booker_to_property') ? 'Booker (original message)' : 'Property (original message)' : (values?.direction === 'booker_to_property') ? 'Booker (further message)' : 'Property (further message)'}</p>
                                                <p className="lead">{values?.messageText}</p>
                                            </div>)
                                        })
                                    }
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}></Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    {viewTextArea && <Form.Control placeholder="Message to Property" rows={5} as={`textarea`} className={`${errors.messageText ? 'is-invalid' : ''}`}  {...register("messageText", { required: true })} />}
                                    {viewTextArea && <div className="invalid-feedback">Please input message!</div>}
                                </Col>
                            </Row>
                            {
                                status === REQUEST_BOOKING_STATUS.OFFERED && <>
                                    <Row className='formSpace'>
                                        <Col md={5} style={{ textAlign: 'right' }}>
                                            <Form.Label>Status</Form.Label>
                                        </Col>
                                        <Col md={7} style={{ textAlign: 'left' }}>
                                            <p style={{ color: $mysaTeal, fontWeight: 'bold' }} className="lead">{`AVAILABLE`}</p>
                                        </Col>
                                    </Row>
                                </>
                            }
                            {
                                data?.expiryDetails && <>
                                    <Row className='formSpace'>
                                        <Col md={5} style={{ textAlign: 'right' }}>
                                            <Form.Label>Offer Expires</Form.Label>
                                        </Col>
                                        <Col md={7} style={{ textAlign: 'left' }}>
                                            <p style={{ color: $mysaMegenta, fontWeight: 'bold' }} className="lead">{moment(data?.expiryDetails?.offerExpiresAt).format('D MMM YYYY h:mm')}</p>
                                        </Col>
                                    </Row>
                                </>
                            }
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    {/* after OFFERED */}
                                    {!loading && (status === REQUEST_BOOKING_STATUS.OFFERED || status === REQUEST_BOOKING_STATUS.AVAILABLE || status === REQUEST_BOOKING_STATUS.ALT_OFFER) &&
                                        <Button id='approve-button' style={{ ...formButton, marginRight: '15px' }} onClick={() => {
                                            const param = data.query;
                                            Object.assign(param, { inventoryId: data.inventory.inventoryId });
                                            Object.assign(param, { requestBookingId: data._id });
                                            Object.assign(param, { _reqGroupId: data._reqGroupId });
                                            Object.assign(param, { _reqBrandId: data._reqBrandId });
                                            Object.assign(param, { _reqPropertyId: data._reqPropertyId });
                                            Object.assign(param, { _reqInventoryId: data._reqInventoryId });
                                            Object.assign(param, { checkInDate: data.checkInDate });
                                            Object.assign(param, { checkOutDate: data.checkOutDate });
                                            push("/admin/property-page", { query: param });
                                        }}><b>Book</b></Button>
                                    }
                                    {!loading && (status === REQUEST_BOOKING_STATUS.OFFERED || status === REQUEST_BOOKING_STATUS.AVAILABLE || status === REQUEST_BOOKING_STATUS.ALT_OFFER) &&
                                        <Button id='megenta-button' style={{ ...formButton, marginLeft: '15px' }} onClick={() => { setArchivePopop(true); }}><b>Decline</b></Button>
                                    }


                                    {/* after OFFERED */}
                                    {!loading && !viewTextArea && status === REQUEST_BOOKING_STATUS.OFFERED &&
                                        <Button id='blue-button' style={{ ...formButton, width: '190px', marginLeft: '15px' }} onClick={() => { setViewTextArea(true) }}><b>Message Property</b></Button>
                                    }
                                    {!loading && viewTextArea && status === REQUEST_BOOKING_STATUS.OFFERED && <Button type="submit" id='approve-button' style={{ ...formButton, marginLeft: '15px' }} ><b>Submit</b></Button>}
                                    {!loading && status === REQUEST_BOOKING_STATUS.OFFERED && <Button id='edit-button' style={{ ...formButton }} onClick={() => {
                                        hideShowViewComponent(false);
                                    }}><b>Close</b></Button>}

                                    {/* other status  */}
                                    {!loading && !viewTextArea && status !== REQUEST_BOOKING_STATUS.OFFERED &&
                                        <Button id='approve-button' style={{ ...formButton, width: '190px' }} onClick={() => { setViewTextArea(true) }}><b>Message Property</b></Button>
                                    }
                                    {!loading && viewTextArea && status !== REQUEST_BOOKING_STATUS.OFFERED && <Button type="submit" id='approve-button' style={formButton} ><b>Submit</b></Button>}
                                    {!loading && status !== REQUEST_BOOKING_STATUS.OFFERED && <Button id='edit-button' style={{ ...formButton, marginLeft: '15px' }} onClick={() => {
                                        hideShowViewComponent(false);
                                    }}><b>Close</b></Button>}


                                    {
                                        loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Form>
            </div>
        </Container>

        <SweetAlert
            show={archivePopop}
            warning
            showCancel={true}
            focusCancelBtn={true}
            confirmBtnText="Yes decline it!"
            confirmBtnBsStyle="danger"
            title="Are you sure you want to decline this booking request?"
            onConfirm={() => {
                const payload = {
                    _id: _id,
                    _reqGroupId: _reqGroupId,
                    _reqBrandId: _reqBrandId,
                    _reqPropertyId: _reqPropertyId,
                    _reqInventoryId: _reqInventoryId,
                    propertyId: data?.property?.propertyId,
                    bookingStatus: REQUEST_BOOKING_STATUS.DECLINED
                }
                updateRequestStatus(payload);
                setArchivePopop(false);
            }}
            onCancel={() => {
                setArchivePopop(false);
            }}
        >
        </SweetAlert>
    </>)
};

export default RequestBookingTravelerHomeView;