import { Tab, Row, Col, Nav } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from 'react-router-dom';
import AuditHome from '../../property-management/form/audit/AuditHome';
import BrandUsersList from './brand-users/BrandUsersList';
import BrandDetailsForm from './BrandDetailsForm';
import { getLoggedUserType, isBrandModuleAvailable } from '../../../../common/functions';
import { UserAccess, UserGroups } from '../../../../common/constants';
import AccessDenied from '../../../common/AccessDenied';
import BrandFinance from './BrandFinance';
import { navContentStyle, navStyle, tabDivStyle } from '../../../../common/components-style';
import BrandSustainability from './BrandSustainability';

const BrandForm: React.FC<any> = ({ setFormOpen, brandId, extId, clearStore ,location}): JSX.Element => {
	const history = useHistory();
	const [tabKey, setTabKey] = useState<any>('details');

	const redirectProperty = () => {
		history.push('/admin/property-management', { brandId: brandId })
	}
	useEffect(() => {
        if (location?.state?.defaultKey) {
            setTabKey(location?.state?.defaultKey);
        }
    }, [location?.state]);
	////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div style={tabDivStyle}>
			<Tab.Container defaultActiveKey="details" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
				<Row >
					<Col sm={2}>
						<Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
							<div style={{ marginTop: '0%' }}>
								<Nav.Item>
									<Nav.Link eventKey="details">Brand Details</Nav.Link>
								</Nav.Item>
								{brandId && <Nav.Item><Nav.Link eventKey="brandUsers">Brand Users</Nav.Link></Nav.Item>}
								{brandId && <Nav.Item><Nav.Link eventKey="brandSustainability">Brand Sustainability</Nav.Link></Nav.Item>}
								{brandId && getLoggedUserType() === UserGroups.super_admin && <Nav.Item><Nav.Link eventKey="brandSurvey">Brand Survey</Nav.Link></Nav.Item>}
								{brandId && <Nav.Item><Nav.Link eventKey="brandFinance">Brand Finance</Nav.Link></Nav.Item>}
								{brandId && <Nav.Item><Nav.Link eventKey="PropertyManagement" onClick={redirectProperty}>Property Management</Nav.Link></Nav.Item>}
							</div>
						</Nav>
					</Col>
					<Col sm={10}>
						<Tab.Content >
							<Tab.Pane eventKey="details" >
								<div style={navContentStyle}>
								{/* <div> */}
									{isBrandModuleAvailable(brandId, UserAccess.brand_access.details.code) ? <BrandDetailsForm brandId={brandId} clearStore={clearStore} setFormOpen={setFormOpen} /> : <AccessDenied />}
								</div>
							</Tab.Pane>
							{brandId && <Tab.Pane eventKey="brandUsers">
								<div style={navContentStyle}>
									{isBrandModuleAvailable(brandId, UserAccess.brand_access.users.code) ? <BrandUsersList brandId={brandId} /> : <AccessDenied />}
								</div>
							</Tab.Pane>}
							{brandId && <Tab.Pane eventKey="brandSustainability">
								<div style={navContentStyle}>
									{isBrandModuleAvailable(brandId, UserAccess.brand_access.details.code) ? <BrandSustainability setFormOpen={setFormOpen} brandId={brandId} /> : <AccessDenied />}
								</div>
							</Tab.Pane>}
							{brandId && getLoggedUserType() === UserGroups.super_admin && <Tab.Pane eventKey="brandSurvey">
								<div style={navContentStyle}>
									{isBrandModuleAvailable(brandId, UserAccess.brand_access.audit.code) ? <AuditHome extId={null} clientId={extId} /> : <AccessDenied />}
								</div>
							</Tab.Pane>}
							{brandId && <Tab.Pane eventKey="brandFinance">
								<div style={navContentStyle}>
									{isBrandModuleAvailable(brandId, UserAccess.brand_access.details.code) ? <BrandFinance setFormOpen={setFormOpen} brandId={brandId} /> : <AccessDenied />}
								</div>
							</Tab.Pane>}
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		</div>
	)
};

export default BrandForm;