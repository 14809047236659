import React, { useCallback, useEffect, useState } from 'react';
// import { Card, Typography } from 'antd';

import { Col, Row } from 'react-bootstrap';
// import social from '../../../../../../images/icons/svg/social-icon.svg'
import locations from '../../../../../images/icons/svg/location.svg';
import global from '../../../../../images/icons/svg/global.svg';
import fb from '../../../../../images/icons/fb.png';
import instagram from '../../../../../images/icons/instagram.png';
import linkdin from '../../../../../images/icons/linkdin.png';
import youtube from '../../../../../images/icons/youtube.png';
import twitter from '../../../../../images/icons/twitter.png';
import email from '../../../../../images/icons/svg/email.svg';
import phone from '../../../../../images/icons/svg/phone.svg';
import calculator from '../../../../../images/calculator.png'
import building from '../../../../../images/building.png';
import calender from '../../../../../images/calender.png';
import moment from 'moment';
import { Link } from 'react-router-dom';
import GoogleMap from '../../../../common/GoogleMap';


// const { Text } = Typography

// const cardStyle = {
//     width: 300,
//     textAlign: 'center' as const,
//     background: '#F2F2F2',
//     borderRadius: '30px',
// };


const ProgrammeAboutDetails: React.FC<any> = ({ brand }): JSX.Element => {
    /////////// local state manage /////////
    const [location, setLocation] = useState<any>({ lat: Number(brand?.latitude), lng: Number(brand?.longitude) });

    useEffect(() => {
        if (brand) {
            setLocation({ lat: Number(brand?.latitude), lng: Number(brand?.longitude) });
        }
    }, [brand]);

    const apartment = useCallback(() => {
        let apartmentCounting = 0;
        brand?.properties?.forEach(property => {
            apartmentCounting += property?.inventories?.length;
        });

        return apartmentCounting;
    }, [brand]);

    return (
        <>
            {/* <Row className="mb-10">
                <Col md={2}>
                    <Card style={cardStyle}>
                        <Text strong className="text-3xl">{moment(brand?.yearCommencedTrading).format('YYYY')}</Text>
                        <p style={{ fontSize: '18px' }}>Years Trading</p>
                    </Card>
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                    <Card style={cardStyle}>
                        <Text strong className="text-3xl" >{brand?.properties?.length}</Text>
                        <p style={{ fontSize: '18px' }}>Total Properties</p>
                    </Card>
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                    <Card style={cardStyle}>
                        <Text strong className="text-3xl">{apartment()}</Text>
                        <p style={{ fontSize: '18px' }}>Total Apartments</p>
                    </Card>
                </Col>
                <Col span={4}></Col>
            </Row> */}

            {/* Stats Cards */}
            <div className='row gtr-3 about_stats'>
                <div className='col-md-4 col-sm-6'>
                    <div className='stat_crd'>
                        <img src={calender} alt='icon' />
                        <div className='stat_ctnt'>
                            <div className='nmbr'>
                                {moment(brand?.yearCommencedTrading).format('YYYY')}
                            </div>
                            <h6>Years Trading</h6>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 col-sm-6'>
                    <div className='stat_crd'>
                        <img src={building} alt='icon' />
                        <div className='stat_ctnt'>
                            <div className='nmbr'>
                                {brand?.properties?.length}
                            </div>
                            <h6>Total Properties</h6>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 col-sm-6'>
                    <div className='stat_crd'>
                        <img src={calculator} alt='icon' />
                        <div className='stat_ctnt'>
                            <div className='nmbr'>
                                {apartment()}
                            </div>
                            <h6>Total Apartments</h6>
                        </div>
                    </div>
                </div>
            </div>

            <Row className="mt-4">
                <Col md={6}>
                    <div className='about_box'>
                        <div className='about_txt'>
                            <h3 className="fz-24 mb-4">About us</h3>
                            <div style={{ fontSize: '18px', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: brand?.description }} />
                        </div>
                        <div className='cntct_box'>
                            <div className='cntct_box_hdr mb-5'>
                                <h3 className="fz-24 mb-0">Contact</h3>
                                <ul className='social_links'>
                                    <li>
                                        <Link to=""><img src={fb} alt="" /></Link>
                                    </li>
                                    <li>
                                        <Link to=""><img src={instagram} alt="" /></Link>
                                    </li>
                                    <li>
                                        <Link to=""><img src={twitter} alt="" /></Link>
                                    </li>
                                    <li>
                                        <Link to=""><img src={linkdin} alt="" /></Link>
                                    </li>
                                    <li>
                                        <Link to=""><img src={youtube} alt="" /></Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='cntct_box_ctnt'>
                                <ul className='cntct_box_list'>
                                    <li>
                                        <img src={locations} alt="location" />
                                        <div>{`${brand?.address1} ${brand?.address2}`}</div>
                                    </li>
                                    <li>
                                        <Link to={`${brand?.allowedDomains}`} target={`_blank`}>
                                            <img src={global} alt="global" />
                                            <div>{`${brand?.allowedDomains}`}</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <a href={`mailto:${brand?.headOfficeTelephone}`}>
                                            <img src={email} alt="email" />
                                            <div></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`tel:${brand?.headOfficeTelephone}`}>
                                            <img src={phone} alt="phone" />
                                            <div>{`${brand?.headOfficeTelephone}`}</div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <h3 className="fz-24 mb-4">Head Office</h3>
                    <GoogleMap
                        googleMapURL={process.env.REACT_APP_MAP_URL}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `500px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        location={location}
                    />
                </Col>
            </Row>
        </>
    );

}

export default ProgrammeAboutDetails;