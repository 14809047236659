
export const setStorage = (name: string, value: any) => {
  localStorage.setItem(name, JSON.stringify(value))
}

export const getStorage = (name: string) => {
  const data = localStorage.getItem(name);
  return data ? JSON.parse(data) : null;
}

export const clearStorage = (name: string) => {
  localStorage.removeItem(name)
}


export const emptyStorage = () => {
  localStorage.clear();
}