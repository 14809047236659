import { CheckOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

const MYOQManyOf: React.FC<any> = ({ data, onAnswer, signUpStatus, completed }): JSX.Element => {
	const [answer, setAnswer] = useState<any>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setAnswer(data.answer?.answer.split(';') || []);
	}, [data.answer?.answer]);

	const onChange = useCallback((val) => {
		setAnswer(val);
	}, []);

	const answerQuestion = useCallback(() => {
		const disabled = signUpStatus === 'accepted' ? true : completed ? true : answer.length ? false : true;

		if (disabled) return false;

		const ans = answer.toString().split(',').join(';');
		onAnswer(ans, data.id);
		setLoading(true);
		setTimeout(() => setLoading(false), 3000);
	}, [answer, data, onAnswer, signUpStatus, completed]);


	return (
		<div className="inline-block  m-5 w-full pr-10">
			<div className="bg-white p-5 rounded-lg">
				<p className="text-lg mb-3">{data?.description}</p>
				<Checkbox.Group value={answer} onChange={(val) => onChange(val)} disabled={completed ? true : false} >
					{
						data.options.split(';').map((o, i) => (
							<Row key={i}>
								<Col span={24}>
									<Checkbox value={i.toString()}>{o}</Checkbox>
								</Col>
							</Row>
						))
					}
				</Checkbox.Group>
				<Button type="primary" ghost={answer ? false : true} block onClick={answerQuestion} icon={<CheckOutlined />} className="rounded-xl mt-3" loading={loading}>
					{data.answer?.answer ? 'Update' : 'Submit'}
				</Button>
			</div>
		</div>
	)
};

export default MYOQManyOf;