import React, { useState } from 'react';
import ClientFilterProgram from './common-views/client-filter-program/ClientFilterProgram';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { navStyle, tabDivStyle } from '../../../common/components-style';
import PropertyAssessment from './property-assessment/PropertyAssessment';
import { useLocation } from 'react-router-dom';
import ExecutiveReview from './executive-review/ExecutiveReview';
import DeclinedProperties from './property-assessment/DeclinedProperties';
import NegotiatedProperties from './property-assessment/NegotiatedPropertie';
import { getStorage } from '../../../common/storage';


const ClientRfpManagementHome: React.FC<any> = (): JSX.Element => {

  // url manage
  const location = useLocation<any>();

  ////////////////////////// local state manage ///////////////////////////////////////////////
  //filter program
  const [filterProgramManage, setFilterProgramManage] = useState(location.state?.filterProgramManage);
  const [defaultActiveKey, setDefaultActiveKey] = useState(location.state?.defaultActiveKey ? location.state?.defaultActiveKey : 'executiveReview');
  const [startDate, setStartDate] = useState(location.state?.startDate);

  return (
    <>
      {
        (filterProgramManage) ?
          <div style={tabDivStyle}>
            <Tab.Container defaultActiveKey={'executiveReview'} key={defaultActiveKey} activeKey={defaultActiveKey} onSelect={(tab) => setDefaultActiveKey(tab)} >
              <Row >
                <Col sm={2}>
                  <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                    <div style={{ marginTop: '0%' }}>
                      <Nav.Item><Nav.Link eventKey="executiveReview">Executive Review</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link eventKey="propertyAssessment">Property Assessment</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link eventKey="negotiations">Negotiations</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link eventKey="declinedProperties">Declined Properties</Nav.Link></Nav.Item>
                    </div>
                  </Nav>
                </Col>
                <Col sm={10}>
                  <Tab.Content >
                    <Tab.Pane eventKey="executiveReview">
                      <div style={{ marginTop: "6px" }}>
                        <ExecutiveReview rfpId={location?.state?.rfpProcessId} programmeName={location?.state?.programmeName ? location?.state?.programmeName : getStorage('programmeData')?.programmeName} clientName={location?.state?.clientName ? location?.state?.clientName : getStorage('programmeData')?.clientId?.companyName} startDate={startDate} />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="propertyAssessment" >
                      <div style={{ marginTop: "6px" }}>
                        <PropertyAssessment programmeId={location?.state?.programmeId} programmeName={location?.state?.programmeName ? location?.state?.programmeName : getStorage('programmeData')?.programmeName} clientName={location?.state?.clientName ? location?.state?.clientName : getStorage('programmeData')?.clientId?.companyName} startDate={startDate} />
                      </div>
                    </Tab.Pane>
                    {<Tab.Pane eventKey="negotiations">
                      <div style={{ marginTop: "6px" }}>
                        <NegotiatedProperties programmeId={location?.state?.programmeId} programmeName={location?.state?.programmeName ? location?.state?.programmeName : getStorage('programmeData')?.programmeName} clientName={location?.state?.clientName ? location?.state?.clientName : getStorage('programmeData')?.clientId?.companyName} startDate={startDate} />
                      </div>
                    </Tab.Pane>}
                    {<Tab.Pane eventKey="declinedProperties">
                      <div style={{ marginTop: "6px" }}>
                        <DeclinedProperties programmeId={location?.state?.programmeId} programmeName={location?.state?.programmeName ? location?.state?.programmeName : getStorage('programmeData')?.programmeName} clientName={location?.state?.clientName ? location?.state?.clientName : getStorage('programmeData')?.clientId?.companyName} startDate={startDate} />
                      </div>
                    </Tab.Pane>}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
          : <ClientFilterProgram setFilterProgramManage={setFilterProgramManage} programsSelectManageStatus={true} ></ClientFilterProgram>
      }
    </>
  )
}

export default ClientRfpManagementHome;