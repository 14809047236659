import React, { useCallback } from 'react';
// import { Divider } from 'antd';
import { Card, Col, Container, Form, Row, Image, Button } from 'react-bootstrap';

import shareIcon from '../../../../images/icons/svg/outside/share-icon.svg';
import cardIcon from '../../../../images/icons/svg/outside/card-icon.svg';
import cardsIcon from '../../../../images/icons/svg/outside/cards-icon.svg';
import globalPay from '../../../../images/icons/svg/outside/globalpay.svg';
import secure from '../../../../images/icons/svg/outside/secure.svg';

import { useHistory } from 'react-router-dom';
import { $mysaYellow, bookingScreen, routingButton } from '../../../../common/components-style';
// import TravelerHeaderPage from '../booking-management/TravelerHeaderPage';

const button = {
    ...routingButton,
    width: '100%',
    background: $mysaYellow,
    fontSize: '20px'
}

const TravellerProfilePaymentInformationEdit: React.FC<any> = ({location,bookingID}): JSX.Element => {
    const { push } = useHistory();


    //////////////////////////////////////////////////////////////////////////////////////
    const onComplete = useCallback(() => {
        push('/admin/booker-confirmation-page', { query: location?.state?.query, bookingID: bookingID});
   
    }, [bookingID, location?.state?.query, push]);

    return (
        <>
            <Card style={bookingScreen}>
                <Card.Body>
                    <Container style={{ marginLeft: '30px', }}>
                        <Card.Title style={{ fontSize: '30px', fontWeight: 'bold', marginTop: '30px' }}>
                            <Row>
                                <Col md={1}>
                                    <Image src={shareIcon} />
                                </Col>
                                <Col md={11} style={{ marginLeft: '-45px' }}>
                                    Traveler Details
                                </Col>
                            </Row>
                        </Card.Title>
                        <div style={{ marginTop: '4%' }}>
                            <Card.Subtitle className="mb-2 text-muted"><span className='requiredMark'>*</span><span style={{ color: '#000000' }}>Search Travellers</span></Card.Subtitle>
                            <Form>
                                <Row>
                                    <Col md={8}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control placeholder="Search by name or by email" style={{ height: '60px' }} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Card.Subtitle className="mb-2" style={{ marginTop: '5%' }}><span style={{ color: '#000000', textDecoration: 'underline', cursor: 'pointer' }}>+ Add a new traveller</span></Card.Subtitle>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Container>
                    <Container style={{ marginLeft: '30px' }}>
                        <Card.Title style={{ fontSize: '30px', fontWeight: 'bold', marginTop: '30px' }}>
                            <Row>
                                <Col md={1}>
                                    <Image src={cardIcon} />
                                </Col>
                                <Col md={5} style={{ marginLeft: '-45px' }}>
                                    Payment Information
                                </Col>
                                <Col md={6} >
                                    <Image src={cardsIcon} />
                                </Col>
                            </Row>
                        </Card.Title>
                        <div style={{ marginRight: '8%', marginTop: '50px' }}>
                            <Form>
                                <Row>
                                    <Form.Group className="mb-3">
                                        <Card.Subtitle className="mb-2 text-muted"><span className='requiredMark'>*</span><span style={{ color: '#000000' }}>Cardholder name</span></Card.Subtitle>
                                        <Form.Control style={{ height: '60px' }} />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col md={5}>
                                        <Form.Group className="mb-3" >
                                            <Card.Subtitle className="mb-2 text-muted"><span className='requiredMark'>*</span><span style={{ color: '#000000' }}>Card number</span></Card.Subtitle>
                                            <Form.Control style={{ height: '60px' }} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={1}> </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" >
                                            <Card.Subtitle className="mb-2 text-muted"><span className='requiredMark'>*</span><span style={{ color: '#000000' }}>Expiry</span></Card.Subtitle>
                                            <Form.Control style={{ height: '60px' }} placeholder="MM / YY" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={1}> </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3" >
                                            <Card.Subtitle className="mb-2 text-muted"><span className='requiredMark'>*</span><span style={{ color: '#000000' }}>CVC</span></Card.Subtitle>
                                            <Form.Control style={{ height: '60px' }} placeholder="CVC" />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group className="mb-3" >
                                    <Form.Check type="checkbox" label="Save card details for next time" />
                                </Form.Group>
                            </Form>
                        </div>
                        <div style={{ padding: '30px' }}>
                            <Row>
                                <Col md={2}>
                                    <Image src={globalPay} />
                                </Col>
                                <Col md={2}>
                                    <Image src={secure} />
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <div style={{ padding: '30px' }}>
                                Lorem ipsum dolor sit amet, consecte adipiscing elit. Mauris convallis ligula est, vel varius massa in rhoncus non. In vitae ligula vestibulum. Terms & Conditions consecte adipiscing elit.
                            </div>
                        </Row>
                        <Row>
                            <Col md={11}>
                                <Button onClick={() => onComplete()} id='routing-button' style={button} ><b>Complete Booking</b></Button>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </>
    );

}

export default TravellerProfilePaymentInformationEdit;