import { Form, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';

import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
// import { getStorage } from '../../../common/storage';
// import { getLoggedUserEmail } from '../../../common/functions';

import {Row, Col, Image } from 'react-bootstrap';

import checkInIcon from '../../../images/icons/svg/outside/icon-check-in.svg';
import checkOutIcon from '../../../images/icons/svg/outside/icon-check-out.svg';

import keyIcon from '../../../images/icons/svg/outside/Icon-Key-Collection.svg';
import selfIcon from '../../../images/icons/svg/outside/Icon-Self-Check-In.svg';
import meetIcon from '../../../images/icons/svg/outside/Icon-Meet-And-Greet.svg';
import hourIcon from '../../../images/icons/svg/outside/Icon-24-Hour.svg';
import dayIcon from '../../../images/icons/svg/outside/Icon-Daytime-Collection.svg';
import digitalIcon from '../../../images/icons/svg/outside/Icon-Digital-Check-in.svg';





const TravellerProfile: React.FC<any> = ({ location }): JSX.Element => {
   // const { push } = useHistory();
   // const [showMap, setShowMap] = useState<boolean>(false);
   const [form] = Form.useForm();
   const [bookingList, setBookingList] = useState<any>();
   const [imageList, setImageList] = useState<any>();
   // const [amenityList, setAmenityList] = useState<any>([]);
   // const [showAmenity, setShowAmenity] = useState<boolean>(false);
   // const [locations, setLocations] = useState<any>();
   // const [building, setBuilding] = useState<any>();
   // const [travelMode, setTravelMode] = useState<any>('WALKING');
   // const [distance, setDistance] = useState<any>('0.0 km');
   // const [duration, setDuration] = useState<any>('00 mins');
   // const messagesRef = useRef<HTMLDivElement | null>(null);


   ////////////////////////////////////////////////////////////////////////////////////////////////////
   const {
      getAvailabilityAndRateByPropertyIdAndInventoryId,
   } = useStoreActions<any>((actions) => ({
      getAvailabilityAndRateByPropertyIdAndInventoryId: actions.booking.getAvailabilityAndRateByPropertyIdAndInventoryId
   }));

   ////////////////////////////////////////////////////////////////////////////////////////////////////
   const {
      getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess,
      getAvailabilityAndRateByPropertyIdAndInventoryIdError,
      getClientLocationByIdSuccess,
      getClientLocationByIdError,
      sendContactMailSuccess,
      sendContactMailError

   } = useStoreState<any>((state) => ({
      getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess: state.booking.getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess,
      getAvailabilityAndRateByPropertyIdAndInventoryIdError: state.booking.getAvailabilityAndRateByPropertyIdAndInventoryIdError,
      getClientLocationByIdSuccess: state.client.getClientLocationByIdSuccess,
      getClientLocationByIdError: state.client.getClientLocationByIdError,
      sendContactMailSuccess: state.booking.sendContactMailSuccess,
      sendContactMailError: state.booking.sendContactMailError
   }));

   ////////////////////////////////////////////////////////////////////////////////////////////////////
   useEffect(() => {
      if (location?.state?.query?.hashGet) {
         const data: any = {};
         data.cacheKey = location?.state?.query?.cacheKey;
         data.propertyId = location?.state?.query?.propertyId;
         data.inventoryId = location?.state?.query?.inventoryId;
         data.hashGet = location?.state?.query?.hashGet;
         getAvailabilityAndRateByPropertyIdAndInventoryId(data);
      }


   }, [getAvailabilityAndRateByPropertyIdAndInventoryId, location]);

   //////////////////////////////////////////////////////////////////////////////////////////////
   useEffect(() => {
      if (getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess) {
         if (getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data) {
            const book: Array<object> = [];
            const image: Array<object> = [];
            const bed: Array<object> = [];
            const amenity: Array<object> = [];
            book.push(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data);
            // setBuilding({
            //    lat: Number(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.property.latitude),
            //    lng: Number(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.property.longitude),
            //    buildingName: getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.property.propertyName,
            //    propertyId: getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.property._id,
            //    toEmail: getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.property.systemNotificationEmail,
            //    address: getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.property.address1
            // });
            _.map(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyImage.image, element => {
               image.push(element)
            })
            _.map(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyInventory.bedType, element => {
               bed.push(element.name)
            });
            if (getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyAmenity) {
               _.map(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyAmenity.amenity, element => {
                  amenity.push(element)
                  // setShowAmenity(true);
                  // setAmenityList(amenity);
               });
            }
            setBookingList(book);
            setImageList(image);
         }
      }
      if (getAvailabilityAndRateByPropertyIdAndInventoryIdError) {
         message.error(getAvailabilityAndRateByPropertyIdAndInventoryIdError.message);
      }
   }, [getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess, getAvailabilityAndRateByPropertyIdAndInventoryIdError]);

   //////////////////////////////////////////////////////////////////////////////////////////////
   useEffect(() => {
      // if (getClientLocationByIdSuccess) {
      //    const locationData = getClientLocationByIdSuccess.data;
      //    setLocations({ lat: Number(locationData.latitude), lng: Number(locationData.longitude), locationName: locationData.locationName });
      // }
      if (getClientLocationByIdError) {
         message.error(getClientLocationByIdError.message);
      }
   }, [getClientLocationByIdSuccess, getClientLocationByIdError, form]);

   //////////////////////////////////////////////////////////////////////////////////////////////
   useEffect(() => {
      if (sendContactMailSuccess) {
         message.success('Your message has been sent successfully.');
      }
      if (sendContactMailError) {
         message.error(sendContactMailError.message);
      }
   }, [sendContactMailSuccess, sendContactMailError]);

   

  


   const renderIcon = useCallback((id) => {
      if (id) {
        let icon = "";
        if (id === '6214e3d09a97d2001e1d9650') icon = hourIcon;
        if (id === '625841f1a5000100136ab8e1') icon = keyIcon;
        if (id === '625841a0a5000100136ab8dc') icon = selfIcon;
        if (id === '61fd013a4cb2fc00137fe213') icon = meetIcon;
        if (id === '61fd011d4cb2fc00137fe20f') icon = dayIcon;
        if (id === '61f7767e4cb2fc00137fe110') icon = hourIcon;
        if (id === '62584216a5000100136ab8e5') icon = digitalIcon;
        return (icon)
  
  
      }
    }, [])

   ////////////////////////////////////////////////////////////////////////////////////////////////////
   // const renderImageList = useCallback(() => {
   //    if (imageList) {
   //       return imageList.map((img: any, key: number) => {
   //          return (
   //             <div key={key} >

   //                <div>
   //                   <Image src={img.url}>
   //                   </Image>
   //                </div>
   //                <div>{img?.caption}</div>

   //             </div>
   //          )
   //       });
   //    }
   // }, [imageList]);


   ////////////////////////////////////////////////////////////////////////////////////////////////////////////
   const renderImage = useCallback((acc) => {
      if (imageList.length >= 1) {
         return (

            <div>
               <Row >
                  <Col md={12} >
                     <div key={imageList[0]?._id}>
                        <div className="card2  traveler-item bg-auto" >
                           <Image src={imageList[0]?.url} alt="avatar" className='traveler-img rounded-2xl'>
                           </Image>
                        </div>
                     </div>

                     <Row >
                        <Col md={12} id="about-brand" style={{ marginTop: '30px', height:'400px' }}>
                           <div style={{ marginLeft: '-60px', }}>
                              <div className="">
                                 <div className="" style={{ fontSize: "32px", fontWeight: 'bold', lineHeight: '63px', marginTop: '-40px' }}>{acc.property.propertyName}</div>
                              </div>
                              <div className="" style={{ fontSize: "18px", fontWeight: 'bold', lineHeight: '38px', marginTop: '0px' }}> {acc.property?.propertyType?.name} </div>

                              <Row>
                                 <Col md={8}>
                                    <div className="" style={{ fontSize: "22px", fontWeight: 'normal', lineHeight: '30px', marginTop: '0px' }}> {acc.property?.address1} {acc.property?.city} {acc.property?.postcode} </div>
                                 </Col>

                              </Row>

                              <div className='' style={{ marginTop: '20px' }}>
                                 <Image src={acc.property.brandId.image} alt="avatar" style={{ height: '165px', aspectRatio: '1/1', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >
                                 </Image>
                              </div>
                              <div className="" style={{ fontSize: "26px", fontWeight: 'bold', lineHeight: '44px', marginTop: '0px' }}> {acc.propertyInventory.accommodationConfiguration.name} </div>

                              <Row>
                                 <Col md={6}>
                                    <img className='icon' src={renderIcon(acc.propertyCheckInProcess.checkInProcess._id)} alt="" />
                                 </Col>
                                 <Col md={6} style={{ marginLeft: '-125px', marginTop: '5px' }}>
                                    <div ><span className="" style={{ fontSize: "16px", fontWeight: 'bold', lineHeight: '35px' }}>{acc.propertyCheckInProcess?.checkInProcess?.name}</span>
                                       <br />
                                       <span >{acc.propertyCheckInProcess?.additionalInformation}</span> </div>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col md={4}>
                                    <img className='icon' src={checkInIcon} alt="" />
                                 </Col>
                                 <Col md={8} style={{ marginLeft: '-60px', marginTop: '0px' }}>
                                    <div ><span className="" style={{ fontSize: "16px", lineHeight: '55px' }}>{'Check-in time  '} <span className="font-bold ml-3">{acc.propertyCheckInProcess?.checkInTime}</span></span>
                                    </div>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col md={4}>
                                    <img className='icon' src={checkOutIcon} alt="" />
                                 </Col>
                                 <Col md={8} style={{ marginLeft: '-60px', marginTop: '0px' }}>
                                    <div ><span className="" style={{ fontSize: "16px", lineHeight: '55px' }}>{'Check-out time  '} <span className="font-bold ml-3">{acc.propertyCheckInProcess?.checkOutTime}</span></span>
                                       <br />
                                    </div>
                                 </Col>
                              </Row>

                              <Row style={{ textAlign: 'center' }}>
                                 <div id='profile-divider' ></div>
                              </Row>

                              <Row className="">
                                 <Col className="flex justify-between" md={24}>
                                    <div>
                                       <div className="" style={{ fontSize: "20px", lineHeight: '63px', }}>{'USD 169 x 37 nights'}</div>
                                    </div>
                                    <div style={{ marginTop: '20px' }}>

                                       <div className="" style={{ fontSize: "20px", lineHeight: '32px', marginLeft: '60px' }}>{'USD 5,140'}</div>
                                    </div>
                                 </Col>
                              </Row>

                              <Row style={{ textAlign: 'center' }}>
                                 <div id='profile-divider' ></div>
                              </Row>
                              <Row className="">
                                 <Col className="flex justify-between" md={24}>
                                    <div>
                                       <div className="" style={{ fontSize: "20px", lineHeight: '63px', fontWeight: 'bold' }}>{'Total'}</div>
                                    </div>
                                    <div style={{ marginTop: '20px' }}>

                                       <div className="" style={{ fontSize: "20px", lineHeight: '32px', marginLeft: '60px', fontWeight: 'bold' }}>{'USD 5,140'}</div>
                                    </div>
                                 </Col>
                              </Row>
                           </div>
                        </Col>

                     </Row>
                  </Col>
                  {/* <Col md={8} id="about-brand" style={{ height: '500px', marginTop: '1px' }}>
                     <Row>
                        <TravellerProfileForm />
                     </Row>

                  </Col> */}
                  {/* <Col  md={2}>
                  </Col> */}

               </Row>
            </div>

         )
      }
   }, [imageList, renderIcon]);

   // ////////////////////////////////////////////////////////////////////////////////////////////////////////////
   // const renderAmenities = useCallback(() => {
   //    if (amenityList) {

   //       return amenityList.map((acc: any, key: number) => {
   //          return (
   //             <Col>
   //                <div key={key} className='' style={{ marginInline: '1px', marginBottom: '5px' }} >
   //                   <Row>
   //                      <div>
   //                         <div className="amenity-block text-center">
   //                            <Image src={acc.iconLocation} alt="avatar"  >
   //                            </Image>
   //                            <p className="mt-3">{acc.name}</p>
   //                         </div>
   //                      </div>
   //                   </Row>
   //                </div>
   //             </Col>
   //          )
   //       })
   //    }
   // }, [amenityList]);

   // ////////////////////////////////////////////////////////////////////////////////////////////////////
   // const onChangeTravelMode = useCallback((value) => {
   //    if (value) {
   //       setTravelMode(value);
   //    }
   // }, []);

   // ////////////////////////////////////////////////////////////////////////////////////////////////////
   // const onResponse = useCallback((event) => {
   //    setDuration(event.routes[0].legs[0].duration.text);
   //    setDistance(event.routes[0].legs[0].distance.text);
   // }, []);

   // ////////////////////////////////////////////////////////////////////////////////////////////////////
   // const sendEmail = useCallback((value) => {
   //    const { userData } = getStorage('myo_logged_user');
   //    if (building.propertyId) {
   //       const data = {
   //          propertyId: building.propertyId,
   //          message: value.message,
   //          propertyName: building.buildingName,
   //          bookerName: userData.firstName + ' ' + userData.lastName,
   //          bookerEmail: getLoggedUserEmail(),
   //          toEmail: building.toEmail
   //       }
   //       onSendContactMail(data);
   //    }
   // }, [building, onSendContactMail]);

   // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   // const scrollToBottom = () => {
   //    messagesRef?.current?.scrollIntoView(({ behavior: "smooth", block: "nearest", inline: "nearest" }));
   // };

   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   const renderBooking = useCallback(() => {
      if (bookingList) {
         return bookingList.map((acc: any, key: number) => {
            return (
               <div key={key}>
                  {renderImage(acc)}
               </div >
            )
         });
      }
   }, [bookingList, renderImage]);

   //////////////////////////////////////////////////////////////////////////////////////////////////////////
   return (
      <div>
         {renderBooking()}
      </div >
   )
};

export default TravellerProfile;