import { CheckOutlined } from '@ant-design/icons';
import { Button, Rate } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

const MYOQRate: React.FC<any> = ({ data, evidence, onAnswer, signUpStatus }): JSX.Element => {
	const [answer, setAnswer] = useState<number>(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setAnswer(data.answer?.answer);
	}, [data.answer?.answer]);

	const answerSet = useCallback((val) => {
		setAnswer(val);
	}, []);

	const answerQuestion = useCallback(() => {
		const disabled = signUpStatus === 'accepted';
		if (disabled) return false;

		setLoading(true);
		setTimeout(() => setLoading(false), 3000);
		onAnswer(answer.toString(), data.id)
	}, [onAnswer, answer, data, signUpStatus]);

	return (
		<div className="inline-block  m-5 w-full pr-10">
			<div className="bg-white p-5 rounded-lg">
				<p className="text-lg mb-3">{data?.description}</p>
				<Rate value={answer} onChange={(val) => answerSet(val)} />
				<Button type="primary" ghost={answer ? false : true} block onClick={answerQuestion} icon={<CheckOutlined />} className="rounded-xl mt-3" loading={loading}>
					Submit
				</Button>
			</div>
		</div>
	)
};

export default MYOQRate;