import React, { useCallback, useEffect, useState } from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import { $mysaBlue, $mysaMegenta, $mysaOrange, $mysaRed, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, tableStyles } from "../../../../../common/components-style";
import { Col, OverlayTrigger, Row, Tooltip, Form } from "react-bootstrap";
import ViewEdit from '../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { useStoreActions, useStoreState } from "easy-peasy";
import { useForm, SubmitHandler } from "react-hook-form";
import SelectC from "../../components/SelectC";
import { getStorage } from "../../../../../common/storage";
import moment from "moment-timezone";
import { LIVE_BOOKING_STATUS } from "../../../../../common/constants";
import { useHistory } from "react-router-dom";
import InputC from "../../components/InputC";
import ButtonC from "../../components/ButtonC";
import Message from '../../../../../images/icons/svg/message.svg';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

/**
 * form type
 */
export type TBrandAndPropertyForm = {
    property?: string;
    search?: string;
    status?: string;
    brandId?: string;
};

const BrandAndProperty: React.FunctionComponent = () => {

    /**
     * redirect hooks
     */
    const { push } = useHistory();

    /**
     * init form
     */
    const { register, handleSubmit } = useForm<TBrandAndPropertyForm>();

    /**
     * global actions
     */
    const { brandAndPropertyAdminReport } = useStoreActions<any>((actions) => ({
        brandAndPropertyAdminReport: actions.booking.brandAndPropertyAdminReport
    }));

    /**
     * global status
     */
    const { getBrandAndPropertyAdminReportSuccess } = useStoreState<any>((status) => ({
        getBrandAndPropertyAdminReportSuccess: status.booking.getBrandAndPropertyAdminReportSuccess
    }));


    /**
     * local state management
     */
    const [propertyList, setPropertyList] = useState<Array<{
        value: string;
        label: string;
    }>>([]);

    const [status, setStatus] = useState<Array<{
        value: string;
        label: string;
    }>>([
        {
            value: LIVE_BOOKING_STATUS.UP_COMING,
            label: 'Upcoming'
        },
        {
            value: LIVE_BOOKING_STATUS.CURRENT,
            label: 'Current'
        },
        {
            value: LIVE_BOOKING_STATUS.AWAIT_INVOICE,
            label: 'Await Invoice'
        },
        {
            value: LIVE_BOOKING_STATUS.COMPLETE,
            label: 'Complete'
        },
        {
            value: LIVE_BOOKING_STATUS.CANCELLED,
            label: 'Cancelled'
        },
    ]);
    const [data, setData] = useState<any[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTable, setLoadingTable] = useState<boolean>(false);

    /**
     * side effect react life cycle
     */
    useEffect(() => {

        const { brandData } = getStorage('myo_logged_user_permission');

        brandAndPropertyAdminReport({
            brandId: brandData[0]?._id,
            property: "",
            search: "",
            status: ""
        });

        setLoadingTable(true);

    }, [brandAndPropertyAdminReport]);

    /**
     * response manage using react life cycle
     */
    useEffect(() => {

        if (getBrandAndPropertyAdminReportSuccess?.data) {

            //table data
            setData(getBrandAndPropertyAdminReportSuccess?.data);

            //list out city
            const propertyL: Array<{
                value: string;
                label: string;
            }> = [];

            for (const booking of getBrandAndPropertyAdminReportSuccess?.data) {

                //property
                const property: {
                    value: string;
                    label: string;
                } = {
                    value: booking.property._id,
                    label: booking.property.city + ' - ' + booking.property.propertyName,
                };

                if (!propertyL?.some((property) => property.value === booking.property._id)) {

                    propertyL.push(property);
                }

            }
            const propList = propertyL.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });

            setPropertyList(propList);
            setLoadingTable(false);
            setLoading(false);
        }

    }, [getBrandAndPropertyAdminReportSuccess]);

    const columns = [
        {
            name: 'City',
            selector: row => row.property.city,
        },
        {
            name: 'Property',
            selector: row => row.property.propertyName,
        },
        {
            name: 'First Name',
            selector: row => row.traveler.firstName,
        },
        {
            name: 'Second Name',
            selector: row => row.traveler.lastName,
        },
        {
            name: 'Client',
            selector: row => row.client.companyName,
        },
        {
            name: 'Programme',
            selector: row => row.programme.programmeName,
        },


 
        {
            name: 'Check-in',
            selector: row => row?.acceptChange ? moment(row?.newCheckInAmendDate).format('D MMM YY') : moment(row?.checkInDate).format('D MMM YY'),
        },
        {
            name: 'Status',
            cell: (row) => {
                let color = "";
                let status = "";
                if (row.bookingStatus === LIVE_BOOKING_STATUS.UP_COMING) { color = $mysaYellow; status = 'Upcoming' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.AMEND_PENDING) { color = $mysaMegenta; status = 'Amend Pending' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED) { color = $mysaRed; status = 'Cancelled' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.CANCEL_PENDING) { color = $mysaOrange; status = 'Cancel pending' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.COMPLETE) { color = $mysaBlue; status = 'Complete ' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.CURRENT) { color = $mysaTeal; status = 'Current ' };
                if (row.bookingStatus === LIVE_BOOKING_STATUS.AWAIT_INVOICE) { color = $mysaTeal; status = 'Await Invoice' };
                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{status}</span>
                )
            }
        },
        {
            name: 'Action',
            // button: true,
            cell: (row: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                    >
                        <>
                            <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                                push(`/admin/brand-property-booking-report/${row.bookingReference}`);
                            }} />
                            {
                                ((row.bookingStatus === LIVE_BOOKING_STATUS.AMEND_PENDING && !row?.acceptChange) || row.bookingStatus === LIVE_BOOKING_STATUS.CANCEL_PENDING) && <img className='iconSizes' src={Message} alt="" onClick={() => {

                                }}  />
                            }
                        </>
                    </OverlayTrigger>

                </>)
            },
        },
    ];

    /**
     * filter table
     * @description currently this function no use any where
     */
    const onSubmit: SubmitHandler<TBrandAndPropertyForm> = useCallback(({ property, search }) => {

        const { brandData } = getStorage('myo_logged_user_permission');

        brandAndPropertyAdminReport({
            brandId: brandData[0]?._id,
            property: '',
            search: search,
            status: ""
        });

        setLoading(true);
    }, [brandAndPropertyAdminReport]);

    /**
     * trigger status
     */
    const statusTrigger = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { brandData } = getStorage('myo_logged_user_permission');

        brandAndPropertyAdminReport({
            brandId: brandData[0]?._id,
            property: "",
            search: "",
            status: e.currentTarget.value
        });

        setLoadingTable(true);
    }, [brandAndPropertyAdminReport]);

    const propertyTrigger = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { brandData } = getStorage('myo_logged_user_permission');

        brandAndPropertyAdminReport({
            brandId: brandData[0]?._id,
            property: e.currentTarget.value,
            search: "",
            status: ""
        });

        setLoadingTable(true);
    }, [brandAndPropertyAdminReport]);

    return (
        <>
            <Row className="text-center ">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col xs={2}>
                            <SelectC name="property" register={register} data={propertyList} onChange={propertyTrigger} />
                        </Col>
                        <Col xs={6}>
                            <InputC name="search" register={register} />
                        </Col>
                        <Col xs={2}>
                            <ButtonC loading={loading} id="blue-button" label="Search" />
                        </Col>
                        <Col xs={2}>
                            <SelectC name="status" register={register} data={status} onChange={statusTrigger} />
                        </Col>
                    </Row>
                </Form>
            </Row>
            <div className='brnd_tbl' style={{ marginTop: '10px' }}>

                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    striped={true}
                    progressPending={loadingTable}
                    customStyles={tableStyles}
                    theme='mysaTable'
                    fixedHeader={true}
                    noDataComponent={'Updating records'}
                />
            </div>
        </>
    );
};

export default BrandAndProperty;