import React, { useCallback, useEffect, useState } from 'react';
import logo from "../../../images/logoNew.png";
import { getStorage, setStorage } from '../../../common/storage';
import statusImg from "../../../images/dashboard/status.png";
import home from "../../../images/dashboard/home.svg";
import calendar from "../../../images/dashboard/calendar.svg";
import ActiveRfps from './ActiveRfps';
import BrandInvitation from './BrandInvitation';
import IncompleteBrands from './IncompleteBrands';
import RFPInvite from './RFPInvite';
import RFPReport from './RFPReport';
import ActiveRfp from './ActiveRfp';
import RequestBookingSuperAdmin from './RequestBookingSuperAdmin';
import BookingAmend from './BookingAmend';
import BookingCancel from './BookingCancel';
import IncompleteProperties from './IncompleteProperties';
import StatsCards from './StatsCards';
import StatsCardsBrand from './StatsCardsBrand';
import StatsCardsSuperAdmin from './StatsCardSuperAdmin';
import ProprtyAuditChart from './PropertyAuditsChart';
import BrandCard from './BrandCard';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import moment from 'moment';
import { BrandStatus, RfpProcessStatus, TravelerPermissions, UserGroups } from '../../../common/constants';
import { $mysaTeal, $mysaYellow, $mysaMegenta, dashboardButton } from '../../../common/components-style';
import NotificationList from './Notification';
import { getLoggedUserType, getUserAccess, isBookerHome, isProgrammeHome, isTravelerHome } from '../../../common/functions';
import RequestBookings from './RequestBookings';
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import StatsCardClient from './StatsCardClient';
import LocationOverViewList from './LocationOverview';
import NegotiationList from './Negotiation';
import SelectC from '../booking-report/components/SelectC';
import { Button, Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import ProgrammeNotificationList from './ProgrammeNotification';
import MyoCorporatePage from './travelerBooking/MyoCorporatePage';
import BookingList from './travelerBooking/BookingList';
import BrandForm from '../brand-management/form/BrandForm';
import RequestBookingSuperAdminHome from '../booking-report/request-booking/super-admin/RequestBookingSuperAdminHome';

const Home: React.FC<any> = ({ location }): JSX.Element => {
	const [fName, setFName] = useState(null);
	const [lName, setLName] = useState(null);
	const [notification, setNotification] = useState(false);
	const [booking, setBooking] = useState(false);
	const [activeRfp, setActiveRfp] = useState(false);
	const [brandStatus, setBrandStatus] = useState<any>();
	const [brandColor, setBrandColor] = useState('$mysaMegenta');
	const [brandName, setBrandName] = useState(false);
	const [userProfile, setUserProfile] = useState(null);
	const [myoSelectedClientId, setMyoSelectedClientId] = useState<any>(null);
	const [programmes, setProgrammes] = useState<any>([]);
	const [programmeData, setProgrammeData] = useState<any>([]);
	const [programmeList, setProgrammeList] = useState<any>();
	const [showDashboard, setShowDashboard] = useState<boolean>(getStorage('moreThanOneProgramme') ? getStorage('moreThanOneProgramme') : getStorage('showDashboard'));
	const [showProgrammeDashboard, setShowProgrammeDashboard] = useState<boolean>(false);
	const [permission, setPermission] = useState<any>();
	const [bookingFilterOpen, setBookingFilterOpen] = useState<boolean>(false);
	const [isProgramme, setIsProgramme] = useState<any>();
	const [bookingParams, setBookingParams] = useState<any>();
	const [myoParams, setMyoParams] = useState<any>();
	// setStorage('moreThanOneProgramme', false);
	const [activeRfpProgramme, setActiveRfpProgramme] = useState<any>([]);
	const history = useHistory();
	const [activeTab, setActiveTab] = useState('Onboarding');
	const isSuperAdmin = getLoggedUserType() === UserGroups.super_admin;

	const handleTabClick = (tabName: string) => {
		setActiveTab(tabName);

	  };
	  
	const { getRfpProcessByProgrammeId, getCompanyPermissionByProgrammeId, brandDashboardDetails,superadminDashboardDetailsOnboarding,superadminDashboardDetailsRfpManagement,superadminDashboardDetailsProposalManagement,superadminDashboardDetailsBookingManagement, getRequestBookingListByBrand, getCorporateProgrammeById, getCorporateProgrammeByClient } = useStoreActions<any>((actions) => ({
		brandDashboardDetails: actions.DashboardModel.brandDashboardDetails,
		superadminDashboardDetailsOnboarding : actions.DashboardModel.superadminDashboardDetailsOnboarding,
		superadminDashboardDetailsRfpManagement : actions.DashboardModel.superadminDashboardDetailsRfpManagement,
		superadminDashboardDetailsProposalManagement: actions.DashboardModel.superadminDashboardDetailsProposalManagement,
		superadminDashboardDetailsBookingManagement : actions.DashboardModel.superadminDashboardDetailsBookingManagement,
		getRequestBookingListByBrand: actions.booking.getRequestBookingListByBrand,
		getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
		getCorporateProgrammeByClient: actions.client.getCorporateProgrammeByClient,
		getCompanyPermissionByProgrammeId: actions.client.getCompanyPermissionByProgrammeId,
		getRfpProcessByProgrammeId: actions.rfpProcess.getRfpProcessByProgrammeId,
	}));
	const { rfpProcessByProgrammeIdSuccess, getCompanyPermissionByProgrammeIdSuccess, brandDashboardSuccess, brandDashboardError, getRequestBookingListByBrandSuccess, getCorporateProgrammeByIdSuccess, getCorporateProgrammeByClientSuccess } = useStoreState<any>((state) => ({
		brandDashboardSuccess: state.DashboardModel.brandDashboardSuccess,
		brandDashboardError: state.DashboardModel.brandDashboardError,
		getRequestBookingListByBrandSuccess: state.booking.getRequestBookingListByBrandSuccess,
		getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByClientSuccess: state.client.getCorporateProgrammeByClientSuccess,
		getCompanyPermissionByProgrammeIdSuccess: state.client.getCompanyPermissionByProgrammeIdSuccess,
		rfpProcessByProgrammeIdSuccess: state.rfpProcess.rfpProcessByProgrammeIdSuccess,
	}));

	useEffect(() => {
         let  data ;
		if (location?.state?.query?.bookingFilterOpen) {
			setBookingFilterOpen(location?.state?.query?.bookingFilterOpen);
		} else {
			setBookingFilterOpen(false);
		}
		const { brandData } = getStorage('myo_logged_user_permission');
		if (brandData?.length > 0) {
			const myoSelectedBrand = brandData[0]?._id;
			if (myoSelectedBrand) {
				 data = {
					id: myoSelectedBrand,
				}
				if (!isSuperAdmin) {
					if (myoSelectedBrand) {
						brandDashboardDetails(data);
					}
					getRequestBookingListByBrand({ brandId: myoSelectedBrand })
				}
			}
		}
		// else 
		// {
		// 	if (isSuperAdmin) {
		// 	superadminDashboardDetailsOnboarding();
		// 	}
		// }
	}, [brandDashboardDetails, getRequestBookingListByBrand, location?.state?.query?.bookingFilterOpen]);


	useEffect(() => {
		if (isSuperAdmin)
		{
		if (activeTab === 'Onboarding') {
			superadminDashboardDetailsOnboarding();
		} else if (activeTab === 'RFP Management') {
			superadminDashboardDetailsRfpManagement();
		} else if (activeTab === 'Proposal Management') {
			superadminDashboardDetailsProposalManagement();
		} else if (activeTab === 'Booking Management') {
			superadminDashboardDetailsBookingManagement();
		}
	}
	}, [activeTab, 
		superadminDashboardDetailsOnboarding, 
		superadminDashboardDetailsRfpManagement, 
		superadminDashboardDetailsProposalManagement, 
		superadminDashboardDetailsBookingManagement]);
	

	useEffect(() => {
		const clients = getUserAccess("clients");
		if (clients?.length > 0) {
			const selectedClientId = clients[0]?.clientId;
			setMyoSelectedClientId(selectedClientId);
			setProgrammes(clients[0]?.corporateProgramme);

			if (clients[0]?.corporateProgramme.length === 1) {
				setStorage('moreThanOneProgramme', true);
				setStorage('programmeId', clients[0]?.corporateProgramme[0]?.programmeId);
				setShowDashboard(true);
			}

		}

	}, []);

	// console.log(myoSelectedClientId, 'myoSelectedClientId');

	useEffect(() => {
		// if (programmes) {
		// 	const programmeId = programmes[0]?.programmeId;
		// 	if (programmeId) {
		// 		getCorporateProgrammeById(programmeId);
		// 		const params: any = {};
		// 		params.clientId = myoSelectedClientId;
		// 		params.programmeId = programmeId
		// 		getCompanyPermissionByProgrammeId(params);
		// 	}

		// } else {
		if (getStorage('programmeId')) {
			getCorporateProgrammeById(getStorage('programmeId'));
			// history.push('/admin');
			if (myoSelectedClientId) {
				const params: any = {};
				params.clientId = myoSelectedClientId;
				params.programmeId = getStorage('programmeId')
				getCompanyPermissionByProgrammeId(params);
			}
		}


		if (getStorage('programmeId')) {
			getRfpProcessByProgrammeId(getStorage('programmeId'));
		}
		// }

	}, [getCompanyPermissionByProgrammeId, getCorporateProgrammeById, getRfpProcessByProgrammeId, myoSelectedClientId]);

	useEffect(() => {
		if (getCorporateProgrammeByIdSuccess) {
			const programme = getCorporateProgrammeByIdSuccess?.data;
			setProgrammeList(programme);
			setStorage('programmeData', getCorporateProgrammeByIdSuccess?.data);
		}
		if (rfpProcessByProgrammeIdSuccess) {
			const programme = rfpProcessByProgrammeIdSuccess?.data;

			const data = _.filter(programme, ele => ((ele?.releasedStatus === true)));
			setActiveRfpProgramme(data);
		}


	}, [getCorporateProgrammeByIdSuccess, rfpProcessByProgrammeIdSuccess]);

	useEffect(() => {
		if (getCompanyPermissionByProgrammeIdSuccess) {
			const permission = getCompanyPermissionByProgrammeIdSuccess?.data;
			setPermission(permission);
		}

	}, [getCompanyPermissionByProgrammeIdSuccess, getCorporateProgrammeByIdSuccess]);

	useEffect(() => {
		if (brandDashboardError) {
			toast.error(brandDashboardError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
		if (brandDashboardSuccess) {
			setBrandName(brandDashboardSuccess?.data?.brand?.name);
			if (brandDashboardSuccess?.data?.brand?.brandStatus === BrandStatus.incomplete) {
				setBrandColor($mysaMegenta);
				setBrandStatus("Incomplete");

			}
			else if (brandDashboardSuccess?.data?.brand?.brandStatus === BrandStatus.pending) {
				setBrandColor($mysaYellow);
				setBrandStatus("Pending");

			}
			else if (brandDashboardSuccess?.data?.brand?.brandStatus === BrandStatus.accepted) {
				setBrandColor($mysaTeal);
				setBrandStatus("Accepted");

			}
			else if (brandDashboardSuccess?.data?.brand?.brandStatus === BrandStatus.archive) {
				setBrandColor($mysaMegenta);
				setBrandStatus("Archive");

			}
			else if (brandDashboardSuccess?.data?.brand?.brandStatus === BrandStatus.review) {
				setBrandColor($mysaYellow);
				setBrandStatus("Review");

			}
		}
	}, [brandDashboardError, brandDashboardSuccess, getRequestBookingListByBrandSuccess]);

	useEffect(() => {
		const { userData } = getStorage("myo_logged_user");
		setFName(userData.firstName);
		setLName(userData.lastName);
		setUserProfile(userData.userProfile);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (myoSelectedClientId) {
			const params: any = {};
			params.clientId = myoSelectedClientId;

			getCorporateProgrammeByClient(params);
		}
	}, [getCorporateProgrammeByClient, myoSelectedClientId]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getCorporateProgrammeByClientSuccess) {
			const programmeIds = _.map(programmes, (a) => a.programmeId);
			const mappedData = _.map(getCorporateProgrammeByClientSuccess?.data, (val) => {
				return {
					label: val.programmeName,
					value: val._id,
					data: val
				}
			});

			let filteredData = _.filter(mappedData, (a) => programmeIds.includes(a.value));

			setProgrammeData(filteredData);
		}

	}, [getCorporateProgrammeByClientSuccess, programmes])




	const ntfHandler = () => {
		setNotification(!notification);
		if (activeRfp || booking) {
			setActiveRfp(false);
			setBooking(false);
		}
	}


	const bkgHandler = () => {
		setBooking(!booking);
		if (activeRfp || notification ) {
			setActiveRfp(false);
			setNotification(false);
		}
	}

	const brandInvitationHandler = () => {
		if (activeRfp || notification || booking) {
			setActiveRfp(false);
			setNotification(false);
			setBooking(false);
		}
	}


	const programmeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.value) {

			setStorage('moreThanOneProgramme', true);
			setStorage('programmeId', e.currentTarget.value);
			setShowDashboard(true);
			history.push('/admin');
		}
	}, [history])

	useEffect(() => {

		const paramsData: any = {};
		paramsData.myoProgramme = 'MYO';
		paramsData.myoPlusProgramme = 'MYO Plus';
		setMyoParams(paramsData);
		// setProgrammeId(getStorage('programmeId'));
		if (programmeList) {
			// setBookingOpen(true);
			if (programmeList?.programmeType?._id === '61ee44a04f7e849ad0d44b75') {
				setIsProgramme('MYO');
			} else if (programmeList?.programmeType?._id === '604886a3e6d541b546d3d87c') {
				setIsProgramme('MYO Plus');
			} else if (programmeList?.programmeType?._id === '604886a3e6d541b546d3d879') {
				setIsProgramme('Specified only');
			}
		}

	}, [programmeList])

	const loadData = useCallback((params) => {
		params.isProgramme = isProgramme;
		// setIsCountryLocation(false);
		if (params.isKeyLocation) {
			// setIsKeyLocation(params.isKeyLocation);
			setBookingFilterOpen(false);
			setIsProgramme('MYO Plus');
		} else {
			setBookingFilterOpen(true);
			setBookingParams(params);

		}
	}, [isProgramme]);

	return (
		<>

			{
				myoSelectedClientId && showDashboard && isBookerHome() && !isProgrammeHome() && !isTravelerHome() && (!bookingFilterOpen) &&
				<div className='dash_cnt'>
					<div className='row'>
						<div className='col-sm-6'>
							<div className='dash_wel fw-bold mysa-pink-text'>
								Welcome to your {programmeList?.programmeName} Dashboard
							</div>
						</div>
					</div>

					{/* breadcrumb */}
					<div className='dash_bredcrumb'>
						<div className='lft_ctnt d-flex'>
							<div className='icn_btn'>
								<img src={home} alt={home} />
								<div>Home  {'>'}  <span style={{ fontWeight: 800 }}> {programmeList?.programmeName}</span></div>
							</div>

						</div>
						<div className='rgt_ctnt'>
							<div className='icn_btn'>
								<img src={calendar} alt={calendar} />
								<b className='text-white'>{moment(new Date()).format('dddd, DD MMMM  YYYY')}</b>
							</div>
						</div>
					</div>

					{<MyoCorporatePage loadData={loadData} programmeId={getStorage('programmeId')} myoParams={myoParams} />
					}

					{<div className='row'>
						<div className='col-md-9' style={{ width: '91%' }}>
							<div className='tbl_accr mt-5'>
								<div >

									<div>
									</div>


									<div>
										<div className='tbl_accr_itm'>
											<div className='tbl_hdr'>
												<h5 className='m-0 fw-bold text-white'>Notifications</h5>

												<button className='text-white' onClick={ntfHandler}>Expand
													<span>
														<img src={notification ? minus : plus} alt="icon" />
													</span>
												</button>
											</div>
											<div>
												<ProgrammeNotificationList />

											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

						{/* chart box */}
						<div className='col-md-3' >


						</div>
					</div>}
				</div>}

			{
				myoSelectedClientId && showDashboard && isBookerHome() && !isProgrammeHome() && !isTravelerHome() &&
				<div>
					{(bookingFilterOpen) && <div className='dash_cnt' style={{
						position: "fixed",
						left: "3.9vw",
						top: "70px",
						width: "100%",
						padding: '5vh 1.5vw',
						zIndex: 20,
						backgroundColor: "#fff",
					}} >
						<div className='row'>
							<div className='col-sm-6'>
								<div className='dash_wel fw-bold mysa-pink-text'>
									Welcome to your {programmeList?.programmeName} Dashboard
								</div>
							</div>
						</div>

						{/* breadcrumb */}
						<div className='dash_bredcrumb' style={{ width: '1870px' }}>
							<div className='lft_ctnt d-flex'>
								<div className='icn_btn'>
									<img src={home} alt={home} />
									<div>Home  {'>'}  <span style={{ fontWeight: 800 }}> {programmeList?.programmeName}</span></div>
								</div>
							</div>
							<div className='rgt_ctnt'>
								<div className='icn_btn'>
									<img src={calendar} alt={calendar} />
									<b className='text-white'>{moment(new Date()).format('dddd, DD MMMM  YYYY')}</b>
								</div>
							</div>
						</div>




					</div>}

					{(bookingFilterOpen) && <BookingList programmeId={getStorage('programmeId')} bookingParams={bookingParams} location={location} />}
				</div>

			}

			{
				myoSelectedClientId && showDashboard && (permission?.travelerPermissions === TravelerPermissions.CAN_BOOK_FOR_SELF) && isTravelerHome() && !isProgrammeHome() && !isBookerHome() && (!bookingFilterOpen) &&
				<div className='dash_cnt'>
					<div className='row'>
						<div className='col-sm-6'>
							<div className='dash_wel fw-bold mysa-pink-text'>
								Welcome to your {programmeList?.programmeName} Dashboard
							</div>
						</div>
					</div>

					{/* breadcrumb */}
					<div className='dash_bredcrumb'>
						<div className='lft_ctnt d-flex'>
							<div className='icn_btn'>
								<img src={home} alt={home} />
								<div>Home  {'>'}  <span style={{ fontWeight: 800 }}> {programmeList?.programmeName}</span></div>
							</div>

						</div>
						<div className='rgt_ctnt'>
							<div className='icn_btn'>
								<img src={calendar} alt={calendar} />
								<b className='text-white'>{moment(new Date()).format('dddd, DD MMMM  YYYY')}</b>
							</div>
						</div>
					</div>

					{<MyoCorporatePage loadData={loadData} programmeId={getStorage('programmeId')} myoParams={myoParams} />
					}

					{<div className='row'>
						<div className='col-md-9' style={{ width: '91%' }}>
							<div className='tbl_accr mt-5'>
								<div >

									<div>
									</div>


									<div>
										<div className='tbl_accr_itm'>
											<div className='tbl_hdr'>
												<h5 className='m-0 fw-bold text-white'>Notifications</h5>

												<button className='text-white' onClick={ntfHandler}>Expand
													<span>
														<img src={notification ? minus : plus} alt="icon" />
													</span>
												</button>
											</div>
											<div>
												<ProgrammeNotificationList />

											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

						{/* chart box */}
						<div className='col-md-3' >


						</div>
					</div>}
				</div>}
			{
				myoSelectedClientId && showDashboard && (permission?.travelerPermissions === TravelerPermissions.CAN_BOOK_FOR_SELF) && isTravelerHome() && !isBookerHome() && !isProgrammeHome() &&


				<div>
					{(bookingFilterOpen) && <div className='dash_cnt' style={{
						position: "fixed",
						left: "3.9vw",
						top: "70px",
						width: "100%",
						padding: '5vh 1.5vw',
						zIndex: 20,
						backgroundColor: "#fff",
					}} >
						<div className='row'>
							<div className='col-sm-6'>
								<div className='dash_wel fw-bold mysa-pink-text'>
									Welcome to your {programmeList?.programmeName} Dashboard
								</div>
							</div>
						</div>

						{/* breadcrumb */}
						<div className='dash_bredcrumb' style={{ width: '1870px' }}>
							<div className='lft_ctnt d-flex'>
								<div className='icn_btn'>
									<img src={home} alt={home} />
									<div>Home  {'>'}  <span style={{ fontWeight: 800 }}> {programmeList?.programmeName}</span></div>
								</div>

							</div>
							<div className='rgt_ctnt'>
								<div className='icn_btn'>
									<img src={calendar} alt={calendar} />
									<b className='text-white'>{moment(new Date()).format('dddd, DD MMMM  YYYY')}</b>
								</div>
							</div>
						</div>
					</div>}

					{(bookingFilterOpen) && <BookingList programmeId={getStorage('programmeId')} bookingParams={bookingParams} location={location} />}
				</div>

			}
			{
				myoSelectedClientId && showDashboard && (permission?.travelerPermissions === TravelerPermissions.CAN_VIEW_BOOKING) && isTravelerHome() && !isProgrammeHome() && !isBookerHome() &&

				<div className='dash_cnt'>
					<div className='row'>
						<div className='col-sm-6'>
							<div className='dash_wel fw-bold mysa-pink-text'>
								Welcome to your {programmeList?.programmeName} Dashboard
							</div>
						</div>
						{/* <div className='col-sm-6'>
							<div className='dash_wel' >
								<Button style={dashboardButton} id='dashboard-button' onClick={() => { setShowProgrammeDashboard(true) }}><b>Programme Dashboard</b></Button>
							</div>
						</div> */}

					</div>

					{/* breadcrumb */}
					<div className='dash_bredcrumb'>
						<div className='lft_ctnt d-flex'>
							<div className='icn_btn'>
								<img src={home} alt={home} />
								<div>Home  {'>'}  <span style={{ fontWeight: 800 }}> {programmeList?.programmeName}</span></div>
							</div>
							{/* <div className='pop_btn'>
								Property View
							</div> */}
						</div>
						<div className='rgt_ctnt'>
							<div className='icn_btn'>
								<img src={calendar} alt={calendar} />
								<b className='text-white'>{moment(new Date()).format('dddd, DD MMMM  YYYY')}</b>
							</div>
						</div>
					</div>

					<div className="waiting-inner mt-5">
						<h2><span className="">Hello </span> {fName} </h2>


						<div className='waiting-inner mt-5 '>

							<h5>
								Details of your current,upcoming or past bookings can be accessed by clicking on the 'Booking Management' menu option.
							</h5>
						</div>
						<div className='waiting-inner mt-3'>

							<h5>
								You can view properties included in your company's programme by accessing the 'Programme Information' option.
							</h5>
						</div>


					</div>

					{/* Stats Cards */}
					{/* <StatsCards /> */}

					{/* Accordion and charts */}
					<div className='row'>
						<div className='col-md-9'>
							<div className='tbl_accr mt-5'>
								<div >
									{/* <div>
										<LocationOverViewList />
									</div> */}
									<div>
									</div>

									{/* <div>
										<RequestBookings bkgHandler={bkgHandler} booking={booking} />
									</div> */}
									{/* <div >
										<NegotiationList />
									</div> */}
									<div>
										<div className='tbl_accr_itm'>
											<div className='tbl_hdr'>
												<h5 className='m-0 fw-bold text-white'>Notifications</h5>

												<button className='text-white' onClick={ntfHandler}>Expand
													<span>
														<img src={notification ? minus : plus} alt="icon" />
													</span>
												</button>
											</div>
											<div>
												<ProgrammeNotificationList />

											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

						{/* chart box */}
						<div className='col-md-3' >
							{/* <div style={{ marginLeft: '113px' }}>
								<StatsCardClient />
							</div> */}

						</div>
					</div>
				</div>
			}


			{
				(myoSelectedClientId && showDashboard && !showProgrammeDashboard && isProgrammeHome()) && activeRfpProgramme?.length >= 1 &&

				<div className='dash_cnt'>
					<div className='row'>
						<div className='col-sm-6'>
							<div className='dash_wel fw-bold mysa-pink-text'>
								Welcome to your {programmeList?.programmeName} Dashboard
							</div>
						</div>
						<div className='col-sm-6'>
							<div className='dash_wel' >
								<Button style={dashboardButton} id='dashboard-button' onClick={() => { setShowProgrammeDashboard(true) }}><b>Programme Dashboard</b></Button>
							</div>
						</div>

					</div>

					{/* breadcrumb */}
					<div className='dash_bredcrumb'>
						<div className='lft_ctnt d-flex'>
							<div className='icn_btn'>
								<img src={home} alt={home} />
								<div>Home  {'>'}  <span style={{ fontWeight: 800 }}> {programmeList?.programmeName}</span></div>
							</div>
							{/* <div className='pop_btn'>
								Property View
							</div> */}
						</div>
						<div className='rgt_ctnt'>
							<div className='icn_btn'>
								<img src={calendar} alt={calendar} />
								<b className='text-white'>{moment(new Date()).format('dddd, DD MMMM  YYYY')}</b>
							</div>
						</div>
					</div>

					{/* Stats Cards */}
					{/* <StatsCards /> */}

					{/* Accordion and charts */}
					<div className='row'>
						<div className='col-md-9'>
							<div className='tbl_accr mt-4'>
								<div >
									<div>
										<LocationOverViewList />
									</div>
									<div>
									</div>

									{/* <div>
										<RequestBookings bkgHandler={bkgHandler} booking={booking} />
									</div> */}
									<div >
										<NegotiationList />
									</div>
									<div>
										<div className='tbl_accr_itm' style={{ backgroundColor: "#1db2ec" }}>
											<div className='tbl_hdr' style={{ backgroundColor: "#1db2ec" }}>
												<h5 className='m-0 fw-bold text-white'>Notifications</h5>

												<button className='text-white' onClick={ntfHandler}>Expand
													<span>
														<img src={notification ? minus : plus} alt="icon" />
													</span>
												</button>
											</div>
											<div>
												<ProgrammeNotificationList />

											</div>
										</div>

									</div>

								</div>
							</div>
						</div>

						{/* chart box */}
						<div className='col-md-3' >
							<div style={{ marginLeft: '113px' }}>
								<StatsCardClient />
							</div>

						</div>
					</div>
				</div>
			}


			{programmeData?.length > 1 && myoSelectedClientId && !showDashboard && <div>
				<div className="waiting-inner ">
					<h2><span className="">Hello </span> {fName} </h2>
					<div className='waiting-inner mt-5 text-center'>
						<h2><span className="color-blue">Welcome</span> to <span className='color-yellow'>Myo</span> </h2>

					</div>

					<div className='waiting-inner mt-5  text-center'>

						<h2>
							the booking and Management platform for serviced accommodation.
						</h2>
					</div>
					<div className='waiting-inner mt-5  text-center'>

						<h2>
							Please choose below which programme you wish to gain access to.
						</h2>
					</div>
					<div className='waiting-inner mt-5 center'>
						<Row>
							<Col md={4}>
								{/* <SelectC name={'programme'} data={programmes}></SelectC> */}
							</Col>
							<Col md={4}>
								<SelectC name={'programme'} data={programmeData} onChange={programmeChange}></SelectC>
							</Col>
							<Col md={4}>
								{/* <SelectC name={'programme'} data={programmes}></SelectC> */}
							</Col>
						</Row>


					</div>

				</div>

				{/* <div className="waiting-sec">
					<div className='waiting-inner '>
						<h2><span className='color-yellow'>Coming soon</span></h2>
						<h6>
							Your 'Home Dashboard' to help you navigate all you activities and actions'
						</h6>
					</div>
				</div> */}
			</div>}



			{(showProgrammeDashboard) && <div>

				<div className='row'>
					<div className='col-sm-6'>
						<div className='dash_wel fw-bold mysa-pink-text '>
							<h2><span className="color-blue">Welcome</span> to <span className='color-yellow'>Myo</span> </h2>
						</div>
					</div>
					<div className='col-sm-6' >
						<div className='dash_wel'>
							<Button style={dashboardButton} id='dashboard-button' onClick={() => { setShowDashboard(true); setMyoSelectedClientId(getStorage('programmeData').clientId?._id); setShowProgrammeDashboard(false); }}><b>Rfp Dashboard</b></Button>
						</div>
					</div>

				</div>

				<div className='waiting-inner mt-5  text-center'>

					<h2>
						Dashboard currently under construction
					</h2>
				</div>
			</div>}

			{( myoSelectedClientId && showDashboard && activeRfpProgramme?.length===0 )&&<div>
				<div className='waiting-inner mt-5  text-center'>

					<h2><span className="color-blue">Welcome</span> to <span className='color-yellow'>Myo</span> </h2>

					<h2>
						Dashboard currently under construction
					</h2>
				</div>
			</div>}


			{userProfile === 'INCOMPLETE' && !myoSelectedClientId && <div>
				<div className="waiting-sec">
					<div className='waiting-inner '>
						<h2><span className="color-blue">Welcome</span> to <span className='color-yellow'>Myo</span> {fName} {lName}!</h2>
						<h6>
							Please use the left navigation menu to manage your brand and properties.
						</h6>
					</div>
				</div>
				<div className="waiting-sec">
					<div className='waiting-inner'>
						<h2> Complete Your Profile</h2>
						<div className='logo-wrp'>
							<img src={logo} alt="logo" />
						</div>
						<h6>
							First things first. Please click on the avatar in the top right of
							this page to complete your profile.
						</h6>
					</div>
				</div>
				{/* <div className="waiting-sec">
					<div className='waiting-inner '>
						<h2><span className='color-yellow'>Coming soon</span></h2>
						<h6>
							Your 'Home Dashboard' to help you navigate all you activities and actions'
						</h6>
					</div>
				</div> */}
			</div>}

			{/* {userProfile === 'COMPLETED' && 
			<div>
				<div className="waiting-sec">
					<div className='waiting-inner '>
						<h2><span className="color-blue">Welcome</span> to <span className='color-yellow'>Myo</span> {fName} {lName}!</h2>
						<h6>
							Please use the left navigation menu to manage your brand and properties
						</h6>
					</div>
				</div>

				<div className="waiting-sec">
					<div className='waiting-inner '>
						<h2><span className='color-yellow'>Coming soon</span></h2>
						<h6>
							Your 'Home Dashboard' to help you navigate all you activities and actions'
						</h6>
					</div>
				</div>
			</div>} */}

			{userProfile === 'COMPLETED' && !myoSelectedClientId &&
				<div className='dash_cnt'>
					<div className='row'>
						<div className='col-sm-6'>
							<div className='dash_wel fw-bold mysa-pink-text'>
							{isSuperAdmin ? 'Welcome Super Admin' : `Welcome ${brandName}`}
							</div>
						</div>
						{!isSuperAdmin && (
						<div className='col-sm-6 text-right'>
							<div className='dash_status_card' style={{ backgroundColor: brandColor }}>

								<img src={statusImg} alt="status" />
								<div className='dash_status_cnt'>
									{brandStatus}
									<span>Brand Status</span>
								</div>

							</div>
						</div>
                           )}
					</div>

					{/* breadcrumb */}
					<>
				{isSuperAdmin ? (
					
					<div className='dash_bredcrumb'>
					<div className='lft_ctnt d-flex'>
						<ul className='dashboard_bredcrumb'>
						<li><a href='#'><img src={home} alt='home' />Home {'>'}</a></li>
						<li>Overview</li>
						<li className={activeTab === 'Onboarding' ? 'active' : ''}><a onClick={() => handleTabClick('Onboarding')}>Onboarding</a></li>
						<li className={activeTab === 'RFP Management' ? 'active' : ''}><a onClick={() => handleTabClick('RFP Management')}>RFP Management</a></li>
						<li className={activeTab === 'Proposal Management' ? 'active' : ''}><a onClick={() => handleTabClick('Proposal Management')}>Proposal Management</a></li>
						<li className={activeTab === 'Booking Management' ? 'active' : ''}><a onClick={() => handleTabClick('Booking Management')}>Booking Management</a></li>
						</ul>
					</div>
					<div className='rgt_ctnt'>
						<div className='icn_btn'>
						<img src={calendar} alt='calendar' />
						<b className='text-white'>{moment(new Date()).format('dddd, DD MMMM YYYY')}</b>
						</div>
					</div>
					</div>
				) : (
					<div className='dash_bredcrumb'>
					<div className='lft_ctnt d-flex'>
						<div className='icn_btn'>
						<img src={home} alt='home' />
						<div>Home {'>'} <span style={{ fontWeight: 800 }}>Brand Overview</span></div>
						</div>
						{/* <div className='pop_btn'>
						Property View
						</div> */}
					</div>
					<div className='rgt_ctnt'>
						<div className='icn_btn'>
						<img src={calendar} alt='calendar' />
						<b className='text-white'>{moment(new Date()).format('dddd, DD MMMM YYYY')}</b>
						</div>
					</div>
					</div>
				)}
    </>
					{/* Stats Cards */}
					{/* <StatsCards /> */}
					{(!isSuperAdmin) ? <StatsCardsBrand/> : <StatsCardsSuperAdmin activeTab = {activeTab }/>}

					{/* Accordion and charts */}
					<div className='row'>
					<div className={!isSuperAdmin ? 'col-md-9' : 'col-md-12'}>
							<div className='tbl_accr mt-4'>
								<div >

								{!isSuperAdmin && (
									<>
									<div>
										<div className='tbl_accr_itm'>
										<div className='tbl_hdr'>
											<h5 className='m-0 fw-bold text-white'>Notifications</h5>
											<button className='text-white' onClick={ntfHandler}>
											Expand
											<span>
												<img src={notification ? minus : plus} alt="icon" />
											</span>
											</button>
										</div>
										<div>
											<NotificationList />
										</div>
										</div>
									</div>
									
									<div>
									</div>

									<div>
										<RequestBookings bkgHandler={bkgHandler} booking={booking} />
									</div>
									</>
									)}
									<div>
									{isSuperAdmin ? (
										<>
											{activeTab === 'Onboarding' && (
											<>
												<BrandInvitation />
												{<IncompleteBrands/>}
												<IncompleteProperties />
											</>
											)}
											{activeTab === 'RFP Management' && (
											<>
												<RFPInvite />
												<ActiveRfp />
											</>
											)}
											{activeTab === 'Proposal Management' && (
											<>
												<RFPReport />
											</>
											)}
											{activeTab === 'Booking Management' && (
											<>
											    <RequestBookingSuperAdmin />
												<BookingAmend />
												<BookingCancel />
											</>
											)}
										</>
										) : (
										<ActiveRfps />
										)}
									</div>
								</div>
							</div>
						</div>
						{/* chart box */}
						<div className='col-md-3'>
						{/* {(getLoggedUserType() !== UserGroups.super_admin) ?<BrandCard /> : <ProprtyAuditChart /> } */}
						{(!isSuperAdmin) ?<BrandCard /> : null }
						{/* {getLoggedUserType() === UserGroups.super_admin && (
							<div className='chart_bx' style={{ marginTop: "40px" }}>
								<div className='chart_hdg mysa-yellow'>
									Request Bookings
								</div> */}
								{/* <div className='chart_body'>
									<DashChart data={requestChart} color={requestChartColor} />
									<ul className='chartInfo m-0 p-0'>
										<li>
											<p className='mb-0'><span className='mysa-green dot'></span> Booked</p>
											<span>25%</span>
										</li>
										<li>
											<p className='mb-0'><span className='mysa-gray dot'></span> Not offered</p>
											<span>45%</span>
										</li>
										<li>
											<p className='mb-0'><span className='bg-danger dot'></span>Declined</p>
											<span>30%</span>
										</li>
									</ul>
								</div> */}
							{/* </div>
			                )} */}
						</div>

					</div>
				</div>
			}

		</>
	)
};

export default Home;