import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import DataTable, { createTheme } from 'react-data-table-component';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import { BrandStatus, PropertyStatus, UserGroups } from '../../../common/constants';
import { getLoggedUserType } from '../../../common/functions';
import { setStorage } from '../../../common/storage';
import { mysaTableTheme, tableStyles, overlayTriggerDelay, $mysaTeal, $mysaYellow, $mysaMegenta, selectStylesLittleRect } from '../../../common/components-style';
import Archive from '../../../images/icons/svg/outside/Icon-Function-Archive.svg';
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import SweetAlert from 'react-bootstrap-sweetalert';
import CircleIcon from '../../../images/icons/svg/circle.svg';
const selectStyles = {
	control: (base, state) => ({
		...base,
		background: "#FBC91D",
		height: "50px",
		paddingLeft: "10px",
		color: "#212121",
		borderRadius: 15,
		borderColor: "#F0F0F0",
		boxShadow: state.isFocused ? null : null,
		"&:hover": {
			borderColor: "#212121",
		},
	}),
	menu: (base) => ({
		...base,
		hyphens: "auto",
		marginTop: 0,
		borderRadius: "14px",
		textAlign: "left",
		zIndex: 3
	}),
	menuList: (base) => ({
		...base,
		padding: "10px",
		color: "#000000",
	}),
	dropdownIndicator: base => ({
		...base,
		color: "#000000"
	})
};

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const fallbackUrl = 'https://res.cloudinary.com/mysa/image/upload/v1613451891/common/no-image-1771002-1505134_sqlz99.webp';

const BrandList: React.FC<any> = ({ loadForm, manageBrandInvitationDisplay }): JSX.Element => {
	const [options, setOptions] = useState<any>([]);
	const [data, setData] = useState<any>([]);
	const [brandId, setBrandId] = useState<String>("");
	const [brandStatus, setBrandStatus] = useState<String>("");
	const [propertyStatus, setPropertyStatus] = useState<String>("");
	const [archivePopop, setArchivePopop] = useState<boolean>(false);
	const [activatePopop, setActivatePopop] = useState<boolean>(false);
	const [brandArchive, setBrandArchive] = useState<any>("");
	const [userData, setUserData] = useState<any>(null);
	const [myoManagementReference, setMyoManagementReference] =
		useState<string>();

	const { getBrandListNewVersion, onApprovalBrand, updateMYOManagementClient, getUserMapping } = useStoreActions<any>((actions) => ({
		getBrandListNewVersion: actions.brand.getBrandListNewVersion,
		onApprovalBrand: actions.brand.onApprovalBrand,
		updateMYOManagementClient: actions.myoManagementModel.updateMYOManagementClient,
		getUserMapping: actions.user.getUserMapping,
		// onloadBrand: actions.brand.onloadBrand,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { getBrandListNewVersionSuccess, getBrandListNewVersionError, approvalBrandSuccess, getUserMappingSuccess } = useStoreState<any>((state) => ({
		getBrandListNewVersionSuccess: state.brand.getBrandListNewVersionSuccess,
		getBrandListNewVersionError: state.brand.getBrandListNewVersionError,
		approvalBrandSuccess: state.brand.approvalBrandSuccess,
		getUserMappingSuccess: state.user.getUserMappingSuccess,
		// brandListSuccess: state.brand.brandListSuccess,
		// brandListError: state.brand.brandListError,
		// loadBrandSuccess: state.brand.loadBrandSuccess,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const callBrandList = useCallback(() => {
		const payload = {
			brand_id: brandId,
			brand_status: brandStatus,
			property_status: propertyStatus,
		}
		getBrandListNewVersion(payload)
	}, [brandId, brandStatus, getBrandListNewVersion, propertyStatus]);

	useEffect(() => {
		callBrandList();
	}, [brandId, brandStatus, callBrandList, propertyStatus]);

	useEffect(() => {
		if (brandId) {
			setTimeout(() => getUserMapping({ brandId }), 1000);

		}
	}, [brandId, getUserMapping]);


	useEffect(() => {
		if (getUserMappingSuccess) {
			const { result } = getUserMappingSuccess.data;
			const mappedData = _.map(result, (val) => {
				const firstUser =
					_.filter(val.brands, (a) => a.brandId === brandId).shift()
						?.isFirstUser || false;
				if (val.user) {
					return {
						userId: val.user._id,
						firstName: val.user.firstName,
						lastName: val.user.lastName,
						email: val.user.email,
						phone: val.user.phone,
						mobile: val.user.mobile,
						position: val.user.position,
						data: val,
						isFirstUser: firstUser,
					};
				}
			});

			let filterData = _.first(
				_.filter(mappedData, (list) => list?.isFirstUser === true)
			);
			if (filterData) {
				setUserData(filterData);
			}
		}
	}, [brandId, getUserMappingSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getBrandListNewVersionError) {
			toast.error(getBrandListNewVersionError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
		if (getBrandListNewVersionSuccess) {

			const mappedData: any = _.map(getBrandListNewVersionSuccess.data, (val) => {

				if (brandStatus !== 'archive') {
					if (val?.brandStatus !== 'archive') {
						return {
							key: val._id,
							extId: null,
							image: val.image,
							name: val.name,
							brandStatus: val.brandStatus,
							countPending: val.countPending,
							countIncomplete: val.countIncomplete,
							myoManagementReference: val?.myoManagementReference

						}
					}
				} else {
					if (val.brandStatus === 'archive') {
						return {
							key: val._id,
							extId: null,
							image: val.image,
							name: val.name,
							brandStatus: val.brandStatus,
							countPending: val.countPending,
							countIncomplete: val.countIncomplete,
							myoManagementReference: val?.myoManagementReference
						}
					}
				}

			});
			var filtered = mappedData.filter(function (el) {
				return el != null;
			});
			if (filtered) {
				setData(filtered);
			}
			if (options.length === 0 && getLoggedUserType() === UserGroups.super_admin) {
				const brandListTemp: any = [];
				_.map(filtered, (element) =>
					brandListTemp.push(
						{
							value: element?.key,
							label: element?.name,
						}
					)
				);


				setOptions(brandListTemp);
			}
		}
		if (approvalBrandSuccess) {

			if (userData) {
				updateMYOManagementClient({
					security: {
						apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
						user: "superadmin@mysa.global",
					},
					client: {
						id: myoManagementReference,
						parentID: "",
						type: "Brand Client",
						name: approvalBrandSuccess?.data?.name,
						brandStatus: approvalBrandSuccess?.data?.brandStatus,
						contact: userData?.firstName + " " + userData?.lastName,
						email: userData?.email,
						phone: approvalBrandSuccess?.data?.headOfficeTelephone,
						externalID: approvalBrandSuccess?.data?._id,
						managedExternally: false,
						contactInfo: {
							name: userData?.firstName + " " + userData?.lastName,
							email: userData?.email,
							// name: responsiblePersonName,
							// email: responsiblePersonEmail,
						},
					},
				});
			}

			// //////////////// myo management api call /////////
			// updateMYOManagementClient({
			// 	security: {
			// 		apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
			// 		user: "superadmin@mysa.global",
			// 	},
			// 	client: {
			// 		id: approvalBrandSuccess?.data?.myoManagementReference,
			// 		parentID: "",
			// 		type: "Brand Client",
			// 		name: approvalBrandSuccess?.data?.name,
			// 		brandStatus: approvalBrandSuccess?.data?.brandStatus,
			// 		contact: userData?.firstName + " " + userData?.lastName,
			// 		email: userData?.email,
			// 		phone: approvalBrandSuccess?.data?.headOfficeTelephone,
			// 		externalID: approvalBrandSuccess?.data?._id,
			// 		managedExternally: false,
			// 		contactInfo: {
			// 			name: userData?.firstName + " " + userData?.lastName,
			// 			email: userData?.email,

			// 		},
			// 	},
			// });
			callBrandList();
		}
	}, [getBrandListNewVersionError, getBrandListNewVersionSuccess, options, approvalBrandSuccess, brandStatus, callBrandList, updateMYOManagementClient, userData?.firstName, userData?.lastName, userData?.email, myoManagementReference, userData]);

	// //////////////////////////////////////////////////////////////////////////////////////////////////
	// const data: any = useMemo(() => {
	// 	if (brandListSuccess) {
	// 		const userBrand = getUserAccess('brand');
	// 		const userBrandIds = _.map(userBrand, (a) => a.brandId);
	// 		const mappedData = _.map(brandListSuccess.data, (val) => {
	// 			return {
	// 				key: val._id,
	// 				extId: val.auditReference,
	// 				name: val.name,
	// 				logo: val.image,
	// 				status: val.status,
	// 				brandStatus: val.brandStatus
	// 			}
	// 		});

	// 		let filteredData = _.filter(mappedData, (a) => userBrandIds.includes(a.key));

	// 		if (getLoggedUserType() === UserGroups.super_admin) {
	// 			const brandListTemp: any = [];
	// 			_.map(mappedData, (element) =>
	// 				brandListTemp.push(
	// 					{
	// 						value: element.key,
	// 						label: element.name,
	// 					}
	// 				)
	// 			);
	// 			setOptions(brandListTemp);
	// 		}
	// 		return getLoggedUserType() === UserGroups.super_admin ? mappedData : filteredData;
	// 	} else {
	// 		if (loadBrandSuccess) {
	// 			let brandList: any = [];
	// 			brandList.push(loadBrandSuccess.data);
	// 			const dataList = _.map(brandList, (val) => {
	// 				return {
	// 					key: val._id,
	// 					extId: val.auditReference,
	// 					name: val.name,
	// 					logo: val.image,
	// 					status: val.status,
	// 					brandStatus: val.brandStatus,
	// 				}
	// 			});
	// 			return dataList;

	// 		}
	// 	}
	// 	return
	// }, [brandListSuccess, loadBrandSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const columns = [
		{
			name: 'Logo',
			cell: (row) => (
				<Image width={60} height={60} src={row.image} onError={(e: any) => { e.target.src = fallbackUrl; }} />
			),
		},
		{
			name: 'Name',
			selector: row => row.name,
		},
		{
			name: 'Status',
			cell: (row) => {
				let color = "";
				if (row.brandStatus === BrandStatus.incomplete) color = $mysaMegenta;
				if (row.brandStatus === BrandStatus.pending) color = $mysaYellow;
				if (row.brandStatus === BrandStatus.accepted) color = $mysaTeal;
				if (row.brandStatus === BrandStatus.archive) color = $mysaMegenta;
				if (row.brandStatus === BrandStatus.review) color = $mysaYellow;

				return (
					<span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.brandStatus}</span>
				)
			}
		},
		{
			name: 'Pending Properties',
			selector: row => row.countPending,

		},
		{
			name: 'Incomplete Properties',
			selector: row => row.countIncomplete,

		},
		{
			name: 'Action',
			button: true,
			cell: (row: any) => {
				return (<>
					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
					>
						<img className='iconSizes' src={ViewEdit} alt="" onClick={() => { setStorage('myo_selected_brand', row); loadForm(row.key, row.extId) }} />
					</OverlayTrigger>

					{
						getLoggedUserType() === UserGroups.super_admin && <OverlayTrigger
							placement="top"
							delay={overlayTriggerDelay}
							overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
						>
							<img className='iconSizes' src={Archive} alt="" onClick={() => {
								const data = {
									id: row?.key,
									brandStatus: BrandStatus.archive,
									myoManagementReference: row?.myoManagementReference
								}
								setBrandArchive(data);
								setArchivePopop(true);
							}} />
						</OverlayTrigger>
					}

					{
						row.brandStatus === BrandStatus.archive && <OverlayTrigger
							placement="top"
							delay={overlayTriggerDelay}
							overlay={<Tooltip id='tooltip-table-top'>Activate</Tooltip>}
						>
							<img className='iconSizes' src={CircleIcon} alt="" onClick={() => {
								const data = {
									id: row?.key,
									brandStatus: BrandStatus.reActivate,
									myoManagementReference: row?.myoManagementReference
								}
								setBrandArchive(data);
								setActivatePopop(true);
							}} />
						</OverlayTrigger>
					}

				</>)
			},
		},
	];

	//////////////////////////////////////////////////////////////////////////////////////
	const onChangeBrand = useCallback((data: any) => {

		if (data?.value) {
			setBrandId(data.value);
		} else {
			setBrandId('');
		}

	}, [])

	const onChangeBrandStatus = useCallback((data: any) => {
		if (data?.value) {
			setBrandStatus(data.value);
		} else {
			setBrandStatus('');
		}

	}, [])

	const onChangePropertyStatus = useCallback((data: any) => {
		if (data?.value) {
			setPropertyStatus(data.value);
		} else {
			setPropertyStatus('');
		}

	}, [])


	const onApproveArchiveBrand = useCallback((data: any) => {
		if (data) {
			setBrandId(data?.id);
			setMyoManagementReference(data?.myoManagementReference);
			setTimeout(() => getUserMapping({ brandId: data?.id }), 1000);
			setTimeout(() => onApprovalBrand(data), 2000);
		}


	}, [getUserMapping, onApprovalBrand])

	////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<>
			<div className="content-title mysa-blue-text fz-20" style={{ marginTop: "-6px", marginBottom: "16px" }}>
				Brand Management
			</div>
			{getLoggedUserType() === UserGroups.super_admin && <>
				<Row className="text-center">
					<Col xs={2}>
						<Select
							className='search_bar select_btn'
							isClearable
							placeholder={<div>Search Brand</div>}
							options={options}
							styles={selectStylesLittleRect}
							isSearchable={true}
							onChange={onChangeBrand}
						/>
					</Col>
					<Col xs={2}>
						<div style={{ textAlign: 'right', marginTop: 10 }}>
							<b>Brand Status</b>
						</div>
					</Col>
					<Col xs={2}>
						<Select
							className='select_btn'
							isClearable
							placeholder={<div>Select</div>}
							options={[
								{
									value: BrandStatus.incomplete,
									label: 'Incomplete',
								},
								{
									value: BrandStatus.pending,
									label: 'Pending',
								},
								{
									value: BrandStatus.accepted,
									label: 'Accepted',
								},
								{
									value: BrandStatus.archive,
									label: 'Archive',
								},
								{
									value: BrandStatus.review,
									label: 'Review',
								}
							]}
							styles={selectStyles}
							isSearchable={false}
							onChange={onChangeBrandStatus}
						/>
					</Col>
					{/* <div> */}
					<Col xs={2}>
						<div style={{ textAlign: 'right', marginTop: 10 }}>
							<b>Property Status</b>
						</div>
					</Col>
					<Col xs={2}>
						<Select
							className='select_btn'
							isClearable
							placeholder={<div>Select</div>}
							options={[
								{
									value: PropertyStatus.awaiting,
									label: 'Incomplete',
								},
								{
									value: PropertyStatus.pending,
									label: 'Pending',
								}
							]}
							styles={selectStyles}
							isSearchable={false}
							onChange={onChangePropertyStatus}
						/>
					</Col>
					{/* </div> */}

					{/* <Col xs={4} className="text-right">
						<Button style={routingButton} id='routing-button-blue' className='btn_design' onClick={() => { manageBrandInvitationDisplay(true); }}><b>Brand Invitation</b></Button>
					</Col> */}
				</Row>

			</>}
			<div className='brnd_tbl' style={{ marginTop: '50px' }}>
				<DataTable
					columns={columns}
					data={data}
					pagination
					striped={true}
					customStyles={tableStyles}
					theme='mysaTable'
					fixedHeader={true}
					noDataComponent={'Updating records'}
				/>
			</div>

			<SweetAlert
				show={archivePopop}
				warning
				showCancel={true}
				focusCancelBtn={true}
				confirmBtnText="Yes archive it!"
				confirmBtnBsStyle="danger"
				title="Are you sure you wish to archive this brand?"
				onConfirm={() => {
					onApproveArchiveBrand(brandArchive);

					setArchivePopop(false);
				}}
				onCancel={() => {
					setArchivePopop(false);
				}}
			>
			</SweetAlert>

			<SweetAlert
				show={activatePopop}
				warning
				showCancel={true}
				focusCancelBtn={true}
				confirmBtnText="Yes activate it!"
				confirmBtnBsStyle="danger"
				title="Are you sure you wish to re-activate’ this brand"
				onConfirm={() => {
					onApproveArchiveBrand(brandArchive);
					// onApprovalBrand(brandArchive);
					setActivatePopop(false);
				}}
				onCancel={() => {
					setActivatePopop(false);
				}}
			>
			</SweetAlert>
		</>
	)
};

export default BrandList;