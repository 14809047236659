import { Row } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Col, Form, Spinner } from "react-bootstrap";

type TViewComments = {
    rfpId: string;
    propertyId: string;
    inventoryId: string;
};

const ViewComments: React.FunctionComponent<TViewComments> = ({
    rfpId,
    propertyId,
    inventoryId
}) => {
    /**
     * local state manage
     */
    const [viewComment, setViewComment] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [declineComment, setDeclineComment] = useState<any>();
    const [negotiationComment, setNegotiationComment] = useState<any>();
    const [inventoryAmendedRateComment, setInventoryAmendedRateComment] = useState<any>();
    const [inventoryAmendedSecondRateComment, setInventoryAmendedSecondRateComment] = useState<any>();
    const [inventoryMessage, setInventoryMessage] = useState<any>([]);

    /**
     * global action manage
     */
    const { getPropertyAcceptDeclineOfRFPByPropertyId } = useStoreActions<any>(
        (actions) => ({
            getPropertyAcceptDeclineOfRFPByPropertyId:
                actions.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyId,
        })
    );

    /**
     * global state manage
     */
    const { getPropertyAcceptDeclineOfRFPByPropertyIdSuccess } =
        useStoreState<any>((state) => ({
            getPropertyAcceptDeclineOfRFPByPropertyIdSuccess:
                state.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,
        }));

    /**
     * chat data fetch
     */
    useEffect(() => {
        setLoading(true);
        const payload = {
            rfpId: rfpId,
            propertyId: propertyId,
        };
        if (propertyId) {
            getPropertyAcceptDeclineOfRFPByPropertyId(payload);
        }
    }, [getPropertyAcceptDeclineOfRFPByPropertyId, propertyId, rfpId]);

    /**
     * set received chat data
     */
    useEffect(() => {
        if (getPropertyAcceptDeclineOfRFPByPropertyIdSuccess) {
            setLoading(false);
            setViewComment(getPropertyAcceptDeclineOfRFPByPropertyIdSuccess?.data);

        }
    }, [getPropertyAcceptDeclineOfRFPByPropertyIdSuccess]);


    useEffect(() => {
        if (viewComment) {
            viewComment?.inventories?.forEach((inv) => {
                if (inv.inventoryId === inventoryId) {
                    setNegotiationComment(inv?.negotiationComment);
                    setDeclineComment(inv?.inventoryDeclineComment);
                    setInventoryAmendedRateComment(inv?.inventoryAmendedRateComment);
                    setInventoryAmendedSecondRateComment(inv?.inventoryAmendedSecondRateComment);
                    setInventoryMessage(inv?.inventoryMessageThread);
                }

            })

        }
    }, [inventoryId, viewComment])

    return (
        <>
            {declineComment && (
                <Row>
                    <Col md={10}>
                        <div style={{ textAlign: "left" }}>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                disabled
                                name="dec"
                                defaultValue={declineComment}
                            />
                        </div>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            )}
            {negotiationComment && (
                <Row>
                    <Col md={10}>
                        <div style={{ textAlign: "left" }}>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                disabled
                                name="negotiationComment"
                                defaultValue={negotiationComment}
                            />
                        </div>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            )}


            {inventoryAmendedRateComment && (
                <Row>
                    <Col md={10}>
                        <div style={{ textAlign: "left", marginTop: "15px" }}>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                disabled
                                name="inventoryAmendedRateComment"
                                defaultValue={
                                    inventoryAmendedRateComment
                                }
                            />
                        </div>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            )}
            {inventoryAmendedSecondRateComment && (
                <Row>
                    <Col md={10}>
                        <div style={{ textAlign: "left", marginTop: "15px" }}>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                disabled
                                name="inventoryAmendedSecondRateComment"
                                defaultValue={
                                    inventoryAmendedSecondRateComment
                                }
                            />
                        </div>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            )}
            {inventoryMessage.length !== 0 &&
                inventoryMessage.map((values, key) => {
                    return (
                        <div key={key}>
                            {values?.clientRespondComment && (
                                <Row>
                                    <Col md={10}>
                                        <div style={{ textAlign: "left", marginTop: "15px" }}>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                disabled
                                                name="propertyResponseComment"
                                                defaultValue={values?.clientRespondComment?.messageText}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={1}></Col>
                                </Row>
                            )}

                            {values?.brandRespondComment && (
                                <Row>
                                    <Col md={10}>
                                        <div style={{ textAlign: "left", marginTop: "15px" }}>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                disabled
                                                name="brandRespondPropertyComment"
                                                defaultValue={values?.brandRespondComment?.messageText}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={1}></Col>
                                </Row>
                            )}
                        </div>
                    );
                })}
            {/* {viewComment?.declineComment && (
                <Row>
                    <Col md={10}>
                        <div style={{ textAlign: "left", marginTop: "15px" }}>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                disabled
                                name="inventoryAmendedSecondRateComment"
                                defaultValue={viewComment?.declineComment}
                            />
                        </div>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            )} */}
            {loading && (
                <div style={{ textAlign: "center" }}>
                    <Spinner animation="border" variant="warning" />
                </div>
            )}
        </>
    );
};

export default ViewComments;
