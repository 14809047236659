import { Avatar, Button, Drawer, Form, Input, Space, Table } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const MYOQUserDefinedListComp: React.FC<any> = ({ options, answer, data, onAnswer, signUpStatus, completed }): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState([]);
  const [fields, setFields] = useState(null);
  const [columns, setColumns] = useState([]);
  const [form] = Form.useForm();
  const [selected, setSelected] = useState(false);
  const [rowIndex, setRowIndex] = useState<any>(null);

  const onDelete = useCallback((e, i) => {
    e.stopPropagation();

    const newArr = [...dataSource];
    newArr.splice(i, 1);
    const arr = Object.values(newArr);

    setDataSource(arr);
  }, [dataSource]);

  useEffect(() => {
    if (options) {
      const optionArr = options.split(';');
      const columnTemp: any = [];
      const fieldsTemp: any = [];

      _.each(optionArr, (o, i) => {
        columnTemp.push({
          title: o,
          dataIndex: (i + 1).toString(),
          key: o + 'Key',
          render: (text, record, index) => {
            return <>{record[i]}</>
          }
        });

        fieldsTemp.push(
          <Form.Item
            label={o}
            name={"Field" + (i + 1)}
            rules={[{}]}
          >
            <Input placeholder="" disabled={completed ? true : false} />
          </Form.Item>
        );
      });
      columnTemp.push({
        title: 'Action',
        key: 'operation',
        fixed: 'right',
        width: 100,
        render: (d, i, index) => <Button onClick={(e) => onDelete(e, index)}>Delete</Button>,
      });
      setFields(fieldsTemp);
      setColumns(columnTemp);

    }
  }, [completed, onDelete, options]);

  useEffect(() => {
    if (answer) {
      const contacts = answer.split('\n');
      const arr: any = _.map(contacts, (o) => {
        var fields = o.split(";");

        return fields;
      })

      setDataSource(arr);
    }

  }, [answer]);

  const onFinish = useCallback((values: any) => {

    let val: string[] = Object.values(values);
    let arr: any = [...dataSource];

    if (selected) {
      arr.splice(rowIndex, 1, val as never);
      setDataSource(arr);
    } else {
      arr.push(val as never);
      setDataSource(arr);
    }
    form.resetFields()
    setSelected(false)


  }, [dataSource, form, rowIndex, selected]);

  const answerQuestion = useCallback(() => {
    let res: any = _.map(dataSource, (o: any) => {
      return o.join(';')
    });
    res = res.join("\n");

    onAnswer(res, data.id);
    setVisible(false);
  }, [data, dataSource, onAnswer]);

  const onRowClick = useCallback((row, i) => {
    setSelected(true);
    setRowIndex(i);
    let val = {};
    val["Field0"] = (i || 0).toString();
    _.each(row, (o, index) => { val["Field" + (index + 1)] = o; })
    form.setFieldsValue(val);
  }, [form]);

  const avatars = useMemo(() => {
    if (answer) {
      return <Button type="primary">View</Button>
    } else {
      return <Button className="p-0" type="link"> <span className="underline"> Please click here to add your information</span></Button>
    }

  }, [answer]);

  return (
    <div className="inline-block  float-left relative">
      <div onClick={() => setVisible(true)}>
        <Avatar.Group maxCount={3} >
          {avatars}
        </Avatar.Group>
      </div>
      <Drawer
        title={data.id === '84ac369b-aee3-4c42-91a3-2b69d6ca0e52' ? <p>Property List<br></br>
          To add your properties, enter your information into the fields below. Click "Add Property" to save an entry. Repeat for each of your properties then click "Save" when you have added all your properties
        </p> : data.id === '5d0f8abd-b2f1-4bc6-8661-6738e33ec49c' ? 'Please list the associations with you are registered with' : data.id === 'ae910282-7faa-4f4b-a324-c4bac5c53afa' ? 'Please list the accreditation bodies' : ''}
        width={720}
        visible={visible}
        onClose={() => setVisible(false)}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right', }}>
            <Button onClick={() => setVisible(false)} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button disabled={signUpStatus === 'accepted' ? true : completed ? true : false} onClick={answerQuestion} type="primary">
              Save
            </Button>
          </div>
        }
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
          layout="vertical"
        >
          {fields}
          <Form.Item>
            <Space>
              {selected && data.id === '84ac369b-aee3-4c42-91a3-2b69d6ca0e52' && <Button type="primary" htmlType="submit" disabled={completed ? true : false}>
                Update property
              </Button>}
              {selected && data.id !== '84ac369b-aee3-4c42-91a3-2b69d6ca0e52' && <Button type="primary" htmlType="submit" disabled={completed ? true : false}>
                Update
              </Button>}
              {!selected && data.id === '84ac369b-aee3-4c42-91a3-2b69d6ca0e52' && <Button type="primary" htmlType="submit" disabled={completed ? true : false}>
                Add property
              </Button>}
              {!selected && data.id !== '84ac369b-aee3-4c42-91a3-2b69d6ca0e52' && <Button type="primary" htmlType="submit" disabled={completed ? true : false}>
                Add
              </Button>}
            </Space>
          </Form.Item>
        </Form>
        <Table
          key={Math.random()}
          onRow={(r, i): any => ({ onClick: () => onRowClick(r, i) })}
          dataSource={dataSource}
          columns={columns}
        />
      </Drawer>
    </div>
  )
};

export default MYOQUserDefinedListComp;