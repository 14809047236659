import { Button, Divider } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import MYOAuditSection from './../questions/MYOAuditSection';
import MYOQuestionsHome from './../questions/myoaudit/MYOQuestionsHome';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MYOSignUpFormHome: React.FC<any> = (): JSX.Element => {
  const location: any = useLocation();
  const history = useHistory();
  const query = useQuery();
  const surveyID = query.get('surveyID');
  const [sectionId, setSectionId] = useState<any>(null);
  const [sections, setSections] = useState<any>([]);
  const [emptyQuestions, setEmptyQuestions] = useState<any>(true);
  const [sectionLength, setSectionLength] = useState<number>(0);
  const [completedCount, setCompletedCount] = useState<number>(0);
  const [showSubmit, setShowSubmit] = useState<boolean>(true);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [hideButton, setHideButton] = useState<boolean>(false);
  const { getMYOSurveys, submitSurvey } = useStoreActions<any>((actions) => ({
    getMYOSurveys: actions.myoManagementModel.getMYOSurveys,
    submitSurvey: actions.myoManagementModel.submitSurvey
  }));

  const { getMYOSurveysSuccess, submitSurveySuccess, submitSurveyError } = useStoreState<any>((state) => ({
    getMYOSurveysSuccess: state.myoManagementModel.getMYOSurveysSuccess,
    submitSurveySuccess: state.myoManagementModel.submitSurveySuccess,
    submitSurveyError: state.myoManagementModel.submitSurveyError
  }));

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getMYOSurveys({
      security: {
        apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
        user: "superadmin@mysa.global"
      },
      certificatesurveyid: surveyID
    });
  }, [getMYOSurveys, surveyID]);


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getMYOSurveysSuccess?.certificateSurvey) {
      setSections(getMYOSurveysSuccess?.certificateSurvey?.survey.sections);
      setSectionLength(getMYOSurveysSuccess?.certificateSurvey?.survey.sections.length)
    }
  }, [getMYOSurveysSuccess]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSectionClick = useCallback((section) => {
    setEmptyQuestions(false);
    setSectionId(section.id);
    setIsSelected(true);
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadSections = useMemo(() => {
    return sections.map((val, key) => {
      return <MYOAuditSection onSectionClick={onSectionClick} surveyId={surveyID} values={val} index={key} selected={isSelected} sectionId={sectionId} />
    })
  }, [isSelected, onSectionClick, sectionId, sections, surveyID]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // const loadSurvey = useCallback(() => {
  //   getMYOSurveys({
  //     security: {
  //       apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
  //       user: "superadmin@mysa.global"
  //     },
  //     certificatesurveyid: surveyID
  //   });
  // }, [surveyID]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (submitSurveySuccess && submitSurveySuccess.status.errorCode === 0) {

      toast.success("Survey submitted Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });

      if (location?.state?.component === 'brand') {
        history.push({
          pathname: '/admin/brand-rfp/brandMyRfp',
          state: location?.state?.programmeData
        });
      } else {
        history.push({
          pathname: '/admin/brand-rfp/brandMyRfp',
          state: {
            programmeData: location?.state?.programmeData,
            component: 'Properties',
            defaultKey: 'propertyEvaluation',
            selectedBrandId: location?.state?.myRfpsData?.brandId,
            data: location?.state?.data,
            myRfpsData: location?.state?.myRfpsData,
            inventory: location?.state?.inventory,
            property: location?.state?.property,
          }
        });
      }

    }
    if (submitSurveyError) {
      toast.error(submitSurveyError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [history, location, submitSurveyError, submitSurveySuccess])

  ////////////////////////////////////////////////////////////////////////////////////
  const submitSurveys = useCallback(() => {
    const post = {
      security: {
        apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
        user: "superadmin@mysa.global"
      },
      ID: surveyID,
    }
    submitSurvey(post)
  }, [submitSurvey, surveyID]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getMYOSurveysSuccess) {
      if (getMYOSurveysSuccess.certificate.issued || getMYOSurveysSuccess.certificateSurvey.isCompleted) {
        setShowSubmit(false);
      } else {
        setShowSubmit(true);
      }
      let sectionCount: number = 0;
      sections.forEach((val, key) => {
        const sectionData = _.filter(getMYOSurveysSuccess.certificateSurvey.survey.sections, (survey) => survey.id === val.id).shift();
        let completed: boolean = true;
        sectionData.questions.forEach(q => {
          completed = !(q.answer === null) && completed;

        });

        if (completed) {
          ++sectionCount;
        }
      });
      setCompletedCount(sectionCount);
    }
  }, [getMYOSurveysSuccess, sections])


  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if ((completedCount === 0 || null) || (sectionLength === 0 || null) || (completedCount !== sectionLength)) {
      setHideButton(true);
    }
    else{
      setHideButton(false);
    }
  }, [completedCount, sectionLength])

  ////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      <Row style={{
        marginBottom: '1.5rem',
        marginTop: '20px',
      }} >
        <Col xs={12}><div className="content-title">
          {
            location?.state?.component === 'brand' ?
              <Breadcrumb>
                <Breadcrumb.Item style={{

                  fontWeight: 100,
                }} >
                  <Link to={{ pathname: "/admin/brand-rfp-management" }}>{'ActiveRFPs'}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item style={{

                  fontWeight: 100,
                }} >
                  <Link to={{ pathname: "/admin/brand-rfp/brandMyRfp", state: location?.state?.programmeData }}> {location?.state?.programmeData?.companyName}{' - '}{location?.state?.programmeData?.programmeName} {' - '} {moment(location?.state?.programmeData?.startDate).format('DD MMM YYYY')}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {location?.state?.data?.survey?.name}
                </Breadcrumb.Item>
              </Breadcrumb>
              :
              <Breadcrumb>
                <Breadcrumb.Item style={{

                  fontWeight: 100,
                }} >
                  <Link to={{ pathname: "/admin/brand-rfp-management" }}>{'ActiveRFPs'}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item style={{

                  fontWeight: 100,
                }} >
                  <Link to={{ pathname: "/admin/brand-rfp/brandMyRfp", state: { programmeData: location?.state?.programmeData, defaultKey: 'properties' } }}> {location?.state?.programmeData?.companyName}{' - '}{location?.state?.programmeData?.programmeName} {' - '} {moment(location?.state?.programmeData?.startDate).format('DD MMM YYYY')}</Link>
                </Breadcrumb.Item  >
                <Breadcrumb.Item onClick={() => {
                  // setDefaultTab('properties')
                  // setComponentName('singleMyRfp');
                }}
                  style={{

                    fontWeight: 100,
                  }} >
                  <Link to={{ pathname: "/admin/brand-rfp/brandMyRfp", state: { programmeData: location?.state?.programmeData, component: 'singleMyRfp', defaultKey: 'properties' } }}> Properties</Link>

                </Breadcrumb.Item  >
                {
                  !location?.state?.inventory && <Breadcrumb.Item style={{

                    fontWeight: 100,
                  }} >
                    <Link to={{
                      pathname: "/admin/brand-rfp/brandMyRfp",
                      state: {
                        programmeData: location?.state?.programmeData,
                        component: 'Properties',
                        defaultKey: 'propertyEvaluation',
                        selectedBrandId: location?.state?.myRfpsData?.brandId,
                        data: location?.state?.data,
                        myRfpsData: location?.state?.myRfpsData,
                        inventory: location?.state?.inventory,
                        property: location?.state?.property,
                      }
                    }}> {location?.state?.property.building} - {location?.state?.property.city}</Link>

                  </Breadcrumb.Item  >
                }
                <Breadcrumb.Item active>
                  {location?.state?.data?.survey?.name}
                </Breadcrumb.Item>

              </Breadcrumb>
          }

        </div></Col>
      </Row>
      {/* <div className="fixed w-full z-10 bg-white" >
        <div className="h-20 flex items-center justify-between">
          <img width="120" src={logo} alt="logo" className="ml-10" />
          <div className="mr-5">
          </div>
        </div>
        <div className="flex flex-row justify-around">
          <div className="mysa-yellow w-full h-2"></div>
          <div className="mysa-green w-full"></div>
          <div className="mysa-blue w-full"></div>
        </div>
      </div> */}

      <div className="pt-5">
        <div className="survey-section">
          <div className="mt-1">
            <div className="flex justify-between">

              {/* <div className=" inline-block m-5 text-justify items-center shadow-xl p-3 rounded-2xl" style={{ width: '65%' }}>
                <div className='mr-5'>
                  <p >
                    Thank you for applying to have your properties added to Myo, the direct corporate booking platform.
                    This application should take no longer than ten minutes to complete the brand information and no longer than two minutes per property.
                    Please ensure each question is answered correctly as this cannot be changed once submitted. You may part complete the application and return to it at a later stage, however,
                    your application will not be assessed until all questions are submitted.
                  </p>
                  <p style={{ marginTop: '15px' }}>
                    Please begin by completing the General Information section.
                  </p>
                </div>
              </div> */}
              <div style={{ width: '65%' }}></div>
              <div className="m-1">
                {<Button onClick={() => hideButton ? '' : submitSurveys()}
                  className={!hideButton ? 'rounded-2xl text-center font-medium text-lg mysa-blue text-white' : 'rounded-2xl text-center font-medium text-lg bg-gray-200 hover:bg-gray-200 hover:border-gray-200 hover:text-black  focus:text-black focus:border-gray-200  focus:bg-gray-200  cursor-not-allowed'} style={{ height: '100px', marginTop: '15px', width: '335px' }}>
                  <div className="">
                    <div>
                      <p>Submit Survey <br></br> <span> Completed Sections ({completedCount}/{sectionLength})</span>
                      </p>
                    </div>
                  </div>
                </Button>}
              </div>
            </div>
          </div>
        </div>

        <div className="sections-section" style={{ marginLeft: "30px" }}>
          <div className="">
            {loadSections}
          </div>
        </div>
        <div className="questions-section">
          <Divider plain></Divider>
          <div className="">
            {surveyID && !emptyQuestions && < MYOQuestionsHome emptyQuestions={emptyQuestions} sectionId={sectionId} surveyId={surveyID} completed={!showSubmit} />}
          </div>
        </div>

        {/* {isSelected && <div className="sections-section">
          <Divider plain></Divider>
          <div className="">
            {loadSections}
          </div>
        </div>} */}
      </div>
    </div>
  )
}

export default MYOSignUpFormHome;

