import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import { capitalizeRole, canBrandPropertyModuleWrite } from '../../../../../common/functions';
import PropertyUserForm from './PropertyUserForm';
import { UserGroups, UserAccess, UserProfile } from '../../../../../common/constants';
import PermissionModel from '../../../brand-management/form/brand-users/PermissionModel';
import PropertyUserView from './PropertyUserView';
import { $mysaMegenta, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, resendButton, routingButton, tableUserStyles } from '../../../../../common/components-style';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ViewEdit from '../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import Lock from '../../../../../images/icons/svg/outside/Icon-Function-Permission.svg';
import { toast } from 'react-toastify';
import BreadCrumbPage from '../../../../common/BreadCrumbPage';
// import { HelpModal } from '../../../../common/HelpModal';


////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const PropertyUsersList: React.FC<any> = ({ allInOnePropertyBreadCrumb, brandId, propertyId, selectedBrand, setFormOpen,tab }): JSX.Element => {
	// const [title, setTitle] = useState('');
	const [propertyData, setPropertyData] = useState<any>();
	const [selectedUser, setSelectedUser] = useState(null);
	const [loadModel, setLoadModel] = useState(false)
	const [brandProperties, setBrandProperties] = useState([]);
	const [userMappingData, setUserMappingData] = useState([]) 
	const [showForm, setShowForm] = useState(false);
	const [showView, setShowView] = useState(false);

	const { onResendPassword, getUserMapping, resetAuth, getBrandProperties, getProperty } = useStoreActions<any>((actions) => ({
		resetAuth: actions.auth.resetAuth,
		onResendPassword: actions.auth.onResendPassword,
		getUserMapping: actions.user.getUserMapping,
		getBrandProperties: actions.property.getBrandProperties,
		getProperty: actions.property.getProperty,
	}));

	const { resendPassword, resendPasswordError, getUserMappingSuccess, getBrandPropertiesSuccess, getPropertySuccess } = useStoreState<any>((state) => ({
		resendPassword: state.auth.resendPassword,
		resendPasswordError: state.auth.resendPasswordError,
		getUserMappingSuccess: state.user.getUserMappingSuccess,
		getBrandPropertiesSuccess: state.property.getBrandPropertiesSuccess,
		getPropertySuccess: state.property.getPropertySuccess,
	}));

	useEffect(() => {
		getUserMapping({ brandId });
		getProperty(propertyId);
		getBrandProperties(brandId);
	}, [brandId, getBrandProperties, getProperty, getUserMapping, propertyId]);

	useEffect(() => {
		if (resendPassword) {
			toast.success("password resend successful, Please check the email", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetAuth()
		}

		if (resendPasswordError) {
			toast.error(resendPasswordError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetAuth();
		}

		if (getBrandPropertiesSuccess) {
			setBrandProperties(getBrandPropertiesSuccess?.data)
		}

		if (getPropertySuccess) {
			setPropertyData(getPropertySuccess.data);
			// setTitle(getPropertySuccess.data?.propertyName);
		}

	}, [resendPassword, resetAuth, resendPasswordError, getBrandPropertiesSuccess, getPropertySuccess]);

	const reload = useCallback(() => {
		setTimeout(() => getUserMapping({ brandId }), 1000);
		setShowForm(false);
	}, [brandId, getUserMapping])

	const data = useMemo(() => {
		if (getUserMappingSuccess) {
			const { result, poolUsers } = getUserMappingSuccess.data;
			setUserMappingData(result);
			const mappedData = _.map(result, (val) => {
				const role = _.filter(val.brands, (a) => a.brandId === brandId).shift()?.role || ''
				const arrId = _.filter(val.brands, (a) => a.brandId === brandId).shift()?._id || ''
				const access = _.filter(val.brands, (a) => a.brandId === brandId).shift()?.access || ''
				const properties = _.filter(val.brands, (a) => a.brandId === brandId).shift()?.properties || []
				const userStatus = poolUsers[val?.user?.email]?.UserStatus

				return {
					userId: val?.user?._id,
					firstName: val?.user?.firstName,
					lastName: val?.user?.lastName,
					email: val?.user?.email,
					phone: val?.user?.phone,
					mobile: val?.user?.mobile,
					position: val?.user?.position,
					userProfile: val?.user?.userProfile,
					userStatus: userStatus,
					userType: capitalizeRole(role),
					properties,
					data: val,
					role,
					arrId,
					access
				}
			});
			let filterData: any = [];
			filterData = _.filter(mappedData, (a) => a.role === UserGroups.user);
			filterData = _.filter(filterData, (a) =>
				// _.some(a.access, (x) => [
				// 	UserAccess.brand_access.details.code,
				// 	UserAccess.brand_access.audit.code,
				// 	UserAccess.brand_access.bookings.code,
				// 	UserAccess.brand_access.reporting.code,
				// 	UserAccess.brand_access.tenders.code,
				// 	UserAccess.brand_access.users.code
				// ].includes(x.module)) && 
				(a.properties.includes(propertyId) || _.some(a.access, (b) => (b.module.split('_')[0] === propertyId) && (b.write === true))));

			return filterData;

		}

		return []
	}, [brandId, getUserMappingSuccess, propertyId])

	const columns = [

		{ name: 'First name', selector: row => row.firstName, minWidth: '110px' },
		{ name: 'Last name', selector: row => row.lastName, minWidth: '110px' },
		{ name: 'Email', selector: row => row.email, minWidth: '250px' },
		{ name: 'User type', selector: row => row.userType, minWidth: '110px' },
		{
			name: 'Status',
			minWidth: '230px',
			cell: (row) => {
				let color = "";
				if (row.userStatus !== 'CONFIRMED') color = $mysaYellow;
				if (row.userStatus === 'CONFIRMED') color = $mysaTeal;
				if (row.userStatus === 'FORCE_CHANGE_PASSWORD') color = $mysaMegenta;

				return (
					// <span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} >{row.userStatus} {row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button onClick={() => onResendPassword({ email: row.email })} style={resendButton} id='resend-button' >Resend</Button>}</span>
					// <>
					// 	<span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>{row.userStatus}</span>
					// 	{row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => onResendPassword({ email: row.email })} className="ml-5" >Resend</Button>}
					// </>
					<span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>
						{row.userStatus} {row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => onResendPassword({ email: row.email })} className="ml-2 mt-1" >Resend</Button>}
					</span>
				)
			}
		},
		{
			name: 'User profile',
			minWidth: '120px',
			cell: (row) => {
				let color = "";
				if (row.userProfile !== UserProfile.COMPLETED) color = $mysaMegenta;
				if (row.userProfile === UserProfile.COMPLETED) color = $mysaTeal;

				return (
					<span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.userProfile}</span>
				);

			}
		},
		{
			name: 'Action',
			button: true,
			cell: (row) => (
				<>
					{row.role === UserGroups.brand_admin && <OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
					>
						<img
							style={!(row.role === UserGroups.brand_admin || !canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.users.code)) ?
								{ marginLeft: '-2px' } : { marginLeft: '-32px' }}
							className='iconSizes' src={ViewEdit} alt="" onClick={() => { setSelectedUser(row); setShowView(true) }} />
					</OverlayTrigger>}
					{row.role !== UserGroups.brand_admin && <OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
					>
						<img
							style={!(row.role === UserGroups.brand_admin || !canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.users.code)) ?
								{ marginLeft: '-2px' } : { marginLeft: '-32px' }}
							className='iconSizes' src={ViewEdit} alt="" onClick={() => { setSelectedUser(row); setShowView(true) }} />
					</OverlayTrigger>}

					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>Permission</Tooltip>}
					>
						<img className='iconSizes' src={Lock} alt="" hidden={row.role === UserGroups.brand_admin || !canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.users.code)} onClick={() => { setLoadModel(true); setSelectedUser(row) }} />
					</OverlayTrigger>

				</>
			)

		},
	];
	return (
		<div>
			<div className="content-title fixed_title" style={{paddingRight: "35px"}}>
		{
				<BreadCrumbPage tab={tab} allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} isPageName="Property" selectedBrand={selectedBrand} propertyData={propertyData} setFormOpen={setFormOpen} ></BreadCrumbPage>
			}
			<div className='ml-auto' style={{position:"relative", zIndex:9999}}>
			<div className='d-flex align-items-center justify-content-end gx-2'>
						{/* <HelpModal /> */}
				{canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.users.code) && <Button style={routingButton} id='routing-button' hidden={showForm || showView} className='float-right' onClick={() => { setSelectedUser(null); setShowForm(true) }}><b>New User</b></Button>}
				</div>

			</div>
					</div>
			{/* <Row>
				{ *
					// title && <Row>
					// 	<Col xs={12}><div className="content-title">{title} - {propertyData?.city}</div></Col>
					// </Row>


				// 
				 <Col xs={9}> </Col>
				<Col xs={3} className="text-right">
					
				</Col> */}
			{/* </Row> */}

			{/* <Button type="primary" hidden={!showForm} className='float-right' onClick={() => setShowForm(false)}>User List</Button> */}
			{showForm && <PropertyUserForm selectedUser={selectedUser} brandId={brandId} propertyId={propertyId} close={() => setShowForm(false)} reload={reload} />}
			{showView && <PropertyUserView selectedUser={selectedUser} close={() => setShowView(false)} reload={reload} />}
			{showForm || showView ||
				<div style={{marginTop:"60px"}}>
					<DataTable
						columns={columns}
						data={data}
						pagination
						striped={true}
						customStyles={tableUserStyles}
						theme='mysaTable'
						fixedHeader={true}
						noDataComponent={'Updating records'}
					/>
				</div>
			}
			{loadModel && <PermissionModel brandProperties={brandProperties} brandId={brandId} userMappingData={userMappingData} selectedUser={selectedUser} onClose={() => { setLoadModel(false); getUserMapping({ brandId }) }} />}
		</div>
	)
}

export default PropertyUsersList
