import React, { useState, useCallback } from 'react'
import { Radio, Checkbox } from 'antd';

const TreeNode: React.FC<any> = ({ text, code, hide = false, isCompany = false }): JSX.Element => {
	// const [read, setRead] = useState(true);
	// const [write, setWrite] = useState(false);
	const [notification, setNotification] = useState(true);

	const onChangeRadio = useCallback(({ target }, code) => {

		const RadioEle: any = document.getElementsByName(code);

		const readElm = RadioEle[0]?.parentElement;
		const writeElm = RadioEle[1]?.parentElement;
		const readElmChild = readElm.children[0];
		const writeElmChild = writeElm.children[0];

		if (target.value === '0') {
			readElm.classList.add('ant-radio-checked');
			writeElm.classList.remove('ant-radio-checked');
			readElmChild.setAttribute("data-checked", true);
			writeElmChild.setAttribute("data-checked", false);
		} else if (target.value === '1') {
			readElm.classList.remove('ant-radio-checked');
			writeElm.classList.add('ant-radio-checked');
			writeElmChild.setAttribute("data-checked", true);
			readElmChild.setAttribute("data-checked", false);
		}

	}, [])

	const onChangeCheckbox = useCallback(({ target }) => {
		setNotification(target.checked)

	}, [])

	return (
		<div className="pb-5">
			<div className="font-bold">{text}</div>
			{!hide && <div>
				<Radio.Group name={`r_${code}`} onChange={(e) => onChangeRadio(e, `r_${code}`)}>
					<Radio value="0" className="italic">{isCompany ? 'Access' : 'Read'}</Radio>
					<Radio value="1" className="italic">{isCompany ? 'Admin' : 'Write'}</Radio>
				</Radio.Group>|
				<label onClick={e => e.stopPropagation()}>
					<Checkbox id={`c_${code}`} onChange={onChangeCheckbox} value={notification} checked={notification} className="ml-5 italic" >{isCompany ? 'View' : 'Notification'}</Checkbox>
				</label>
			</div>}
		</div>
	)
}

export default TreeNode
