import React, { useEffect, useMemo, useState } from 'react';

import DataTable from 'react-data-table-component';



import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import { $mysaMegenta, $mysaTeal, tableStyles } from '../../../common/components-style';
import { getStorage } from '../../../common/storage';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { BrandRfpPropertyEvaluationStatus } from '../../../common/constants';


const NegotiationList: React.FC<any> = (): JSX.Element => {

    const [activeRfp, setActiveRfp] = useState(false);



    const [myRfpProcessesList, setMyRfpProcessesList] = useState<any>([]);
    const clientId = getStorage('programmeData')?.clientId?._id;
    const programmeId = getStorage('programmeData')?._id;

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getNegotiationList
    } = useStoreActions<any>((actions) => ({
        getNegotiationList: actions.rfpProcess.getNegotiationList

    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getNegotiationListSuccess
    } = useStoreState<any>((state) => ({

        getNegotiationListSuccess: state.rfpProcess.getNegotiationListSuccess
    }));


    ////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const params: any = {};
        params.clientId = clientId;
        params.programmeId = programmeId;

        getNegotiationList(params);
    }, [clientId, getNegotiationList, programmeId]);

    ////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (getNegotiationListSuccess) {
            setMyRfpProcessesList(getNegotiationListSuccess?.data)
        }

    }, [getNegotiationListSuccess]);




    const rfpHandler = () => {
        setActiveRfp(!activeRfp);
    }

    const data = useMemo(() => {
        return myRfpProcessesList?.map((rfpData) => {

            return {
                key: rfpData?.propertyId._id,
                propertyName: rfpData?.propertyName,
                countryName: rfpData?.countryName,
                cityName: rfpData?.cityName,
                status: rfpData?.status,
                brandName: rfpData?.brandName
            }
        });

    }, [myRfpProcessesList]);


    const columns: any = [

        {
            name: 'Country',
            cell: (row) => {
                return (
                    <span style={{ fontWeight: 'bold' }} className='text-capitalize' > {row.countryName}</span >)
            }
        },
        {
            name: 'City',
            selector: row => {
                return (
                    <span style={{ fontWeight: 'bold' }} className='text-capitalize'>{row.cityName}</span>)
            }
        },
        {
            name: 'Brand',
            selector: row => {
                return (
                    <span style={{ fontWeight: 'bold' }} className='text-capitalize'>{row.brandName}</span>)
            }
        },
        {
            name: 'Property',
            selector: row => {
                return (
                    <span style={{ fontWeight: 'bold' }} className='text-capitalize'>{row.propertyName}</span>)
            }
        }, {
            name: 'Status',
            cell: (row) => {
                let color = "";
                let text = "";
                if (row.status === BrandRfpPropertyEvaluationStatus.REVIEW) {
                    color = $mysaMegenta;
                    text = "Awaiting";
                }

                if (row.status === BrandRfpPropertyEvaluationStatus.REVIEWED) {
                    color = $mysaTeal;
                    text = "Response";
                }
                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{text}</span>
                )
            }
        },
    ];
    return (
        <>
            <div className='tbl_accr_itm' style={{ backgroundColor: "#FBC91D" }}>
                <div className='tbl_hdr' style={{ backgroundColor: "#FBC91D" }}>
                    <h5 className='m-0 fw-bold text-white'>Negotiations</h5>
                    <button className='text-white pointer' onClick={rfpHandler}>Expand
                        <span>
                            <img src={activeRfp ? minus : plus} alt="icon" />
                        </span>
                    </button>
                </div>
                <div className='acrd_tbl'>
                    <DataTable
                        columns={columns}
                        data={data}
                        //   striped={true}
                        customStyles={tableStyles}
                        theme='mysaTable'
                        fixedHeader={true}
                        noDataComponent={'Updating records'}
                    />
                </div>
            </div>
        </>
    )
}

export default NegotiationList;