import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropertyManagementForm from './PropertyManagementForm';


const PropertyManagementList: React.FC<any> = (): JSX.Element => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [propertyManagementList, setPropertyManagementList] = useState<any>([]);
  const [propertyManagementDetails, setPropertyManagementDetails] = useState<any>(null);
  const [propertyManagementId, setPropertyManagementId] = useState<number | null>(null);

  ////////////////////////////////////////////////////////////////////////////////////////////
  const loadForm = useCallback((data) => {
    setFormOpen(true);
    setPropertyManagementDetails(data);
    if (data !== null) {
      setPropertyManagementId(data.key);
    } else {
      setPropertyManagementId(null);
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getPropertyManagementList, updatePropertyManagement } = useStoreActions<any>((actions) => ({
    getPropertyManagementList: actions.property.getPropertyManagementList,
    updatePropertyManagement: actions.property.updatePropertyManagement,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    getPropertyManagementListSuccess,
    getPropertyManagementListError,
    updatePropertyManagementError,
    removePropertyManagementSuccess
  } = useStoreState<any>((state) => ({
    getPropertyManagementListSuccess: state.property.getPropertyManagementListSuccess,
    getPropertyManagementListError: state.property.getPropertyManagementListError,
    updatePropertyManagementError: state.property.updatePropertyManagementError,
    removePropertyManagementSuccess: state.property.removePropertyManagementSuccess,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getPropertyManagementList();
  }, [getPropertyManagementList]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getPropertyManagementListSuccess) {
      setPropertyManagementList(getPropertyManagementListSuccess.data);
    }
    if (getPropertyManagementListError) {
      message.error(getPropertyManagementListError.message)
    }

    if (removePropertyManagementSuccess) {
      message.success('Property management removed successfully');
      getPropertyManagementList();
    }

    if (updatePropertyManagementError) {
      message.error(updatePropertyManagementError.message)
    }

  }, [getPropertyManagementList, getPropertyManagementListError, getPropertyManagementListSuccess, removePropertyManagementSuccess, updatePropertyManagementError]);
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const removeData = useCallback((values) => {
    const data = {
      id: values.key,
      isDeleted: true
    }
    updatePropertyManagement(data);

  }, [updatePropertyManagement]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name', outerWidth: '75px' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Status',
      dataIndex: '',
      key: 'status  ',
      render: (data: any) => {

        return (<div className={`${data.status === 'active' ? 'text-green-500' : 'text-red-500'}`}>
          {data.status}
        </div>)
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'status',
      render: (data: any) => {
        return (<div>
          <Button onClick={() => loadForm(data)} className="border-none" ><EditOutlined /></Button>
          <Button onClick={() => removeData(data)} className="border-none" ><DeleteOutlined /></Button>
        </div>)
      },
    },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const data = useMemo(() => {
    return _.map(propertyManagementList, property => {
      return {
        key: property._id,
        name: property.name,
        description: property.description,
        status: property.status
      }
    })
  }, [propertyManagementList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      <div className="flex justify-between">
        <p className="text-2xl mb-10">Property Management Details</p>
        <Button hidden={formOpen} onClick={() => { loadForm(null) }} className="btn_design m-1 text-white">Create New</Button>
        <Button hidden={!formOpen} onClick={() => setFormOpen(false)} className="btn_design m-1 text-white">Show List</Button>
      </div>
      {formOpen && < PropertyManagementForm setFormOpen={setFormOpen} propertyManagementDetails={propertyManagementDetails} propertyManagementId={propertyManagementId} />}
      <div className="mb-1" hidden={formOpen}>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  )
};

export default PropertyManagementList;