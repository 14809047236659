import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';



const EnergyView: React.FC<any> = ({ propertyId }): JSX.Element => {
    const [energyData, setEnergyData] = useState<any>([]);

    /////////////////////////// global state manage (actions)//////////////////////////////////////////////////////
    const { getPropertySustainabilityByPropertyId } = useStoreActions<any>((actions) => ({
        getPropertySustainabilityByPropertyId: actions.property.getPropertySustainabilityByPropertyId,
    }));

    /////////////////////////// global state manage (state)//////////////////////////////////////////////////////
    const {
        getPropertySustainabilityByPropertyIdSuccess,
    } = useStoreState<any>((state) => ({
        getPropertySustainabilityByPropertyIdSuccess: state.property.getPropertySustainabilityByPropertyIdSuccess,
    }));


    //////////////////////////////// call apis using actions //////////////////////////////////////////////////////
    useEffect(() => {
        if (propertyId) {
            getPropertySustainabilityByPropertyId(propertyId);
        }

    }, [getPropertySustainabilityByPropertyId, propertyId]);


    //////////////////////////////// get api response using state //////////////////////////////////////////////////////
    useEffect(() => {

        if (getPropertySustainabilityByPropertyIdSuccess) {
            if (getPropertySustainabilityByPropertyIdSuccess.data) {
                const data = getPropertySustainabilityByPropertyIdSuccess?.data;
                const energyListTemp: any = [
                    { label: 'Reminders, in prominent positions, to remind guests to switch off lighting, heating, air conditioning etc before they leave the property', value: data?.doYouDisplayRemindersBeforeTheyLeave, number: "odd" },
                    { label: 'Digital means for guests to control heating remotely', value: data?.doYouProvideDigitalMeansForGuest, number: "even" },
                    { label: 'Motion sensing outside and inside the property', value: data?.doYouHaveMotionSensing, number: "odd" },
                    { label: 'Details', value: data?.pleaseProvideDetailsForMotion, number: "even" },
                    { label: 'Energy supplied from 100% renewable sources', value: data?.isYourEnergySuppliedFromSources, number: data?.pleaseProvideDetailsForMotion?"odd":"even" },
                    { label: 'Details', value: data?.pleaseProvideDetailsForEnergy, number: data?.isYourEnergySuppliedFromSources?"even":"odd" },
                    { label: 'Energy Performance Certificate / ESOS certificate rating', value: data?.whatIsYourEnergyPerformanceCertificate, number: "odd" },
                    { label: 'Energy efficient shower heads', value: data?.doesYourAccommodationHaveEnergyEfficient, number: "even" },
                    { label: 'Appliances left on standby when the accommodation is not occupied', value: data?.areAppliancesLeftOccupied, number: "odd" },
                ];

                setEnergyData(energyListTemp);
            }
        }



    }, [getPropertySustainabilityByPropertyIdSuccess]);


    return (
        <>
            {/* <ul className="">
                <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}><p >Reminders, in prominent positions, to remind guests to switch off lighting, heating, air conditioning etc before they leave the property </p>
                    <span>Yes</span></li>
                <li className="list-group-item d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Digital means for guests to control heating remotely
                    <span>Yes</span>
                </li>
                <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Motion sensing outside and inside the property
                    <span>Yes</span></li>
                <li className="list-group-item d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Details
                    <span>Lighting</span></li>
                <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Energy supplied from 100% renewable sources
                    <span>Yes</span></li>
                <li className="list-group-item d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Details
                    <span>Solar</span></li>
                <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Energy Performance Certificate / ESOS certificate rating
                    <span>8</span></li>
                <li className="list-group-item d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Energy efficient shower heads
                    <span>Yes</span></li>
                <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Appliances left on standby when the accommodation is not occupied
                    <span>No</span></li>
            </ul> */}

            <ul className=''>
                {
                    energyData.map((data, index) => {
                        return <div>{data?.value && <li className={data?.number === 'odd' ? "list-group-item list-group-item-dark d-flex justify-content-between align-items-center" : "list-group-item d-flex justify-content-between align-items-center"} style={{ height: "50px", padding: "1rem" }}>{data?.label}
                            <span>{data?.value}</span></li>}
                        </div>

                    })}
            </ul>
        </>


    );

}

export default EnergyView;