import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getStorage } from "../../../../common/storage";
import GoogleMap from "../../../common/GoogleMap";
import MyVerticallyCenteredModal from "./SubmiitedRfpVerticallyCenteredModal";


// import MyVerticallyCenteredModal from "../MyVerticallyCenteredModal";

const BrandAdminSubmittedLocationMap: FC<any> = ({ setShowLocations, setRefreshPropertiesList, setSelectedCity, selectedCity, setComponentName, setDefaultTab, data, selectedCityDetails }) => {


    // const [location, setLocation] = useState<any>();
    const [filterBrandProperties, setFilterBrandProperties] = useState<any>();
    const [cityList, setCityList] = useState<any>([]);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [isLoadProperty, setIsLoadProperty] = useState<boolean>(false);

    //actions
    const {
        getBrandProperties,
        // updateBrandRFPData,
        getAllPropertyCitiesByCountryNBrand
    } = useStoreActions<any>((actions) => ({
        getBrandProperties: actions.property.getBrandProperties,
        // updateBrandRFPData: actions.rfpProcess.setBrandRfpDetails,
        getAllPropertyCitiesByCountryNBrand: actions.property.getAllPropertyCitiesByCountryNBrand,
    }));

    //state
    const {
        getBrandPropertiesSuccess,
        getBrandPropertiesError,
        // brandRfpDetails,
        updateBrandRfpProcessSuccess,
        updateBrandRfpProcessError,
        getAllPropertyCitiesByCountryNBrandSuccess,
        getAllPropertyCitiesByCountryNBrandError

    } = useStoreState<any>((state) => ({
        getBrandPropertiesSuccess: state.property.getBrandPropertiesSuccess,
        getBrandPropertiesError: state.property.getBrandPropertiesError,
        // brandRfpDetails: state.rfpProcess.brandRfpDetails,
        updateBrandRfpProcessSuccess: state.rfpProcess.updateBrandRfpProcessSuccess,
        updateBrandRfpProcessError: state.rfpProcess.updateBrandRfpProcessError,
        getAllPropertyCitiesByCountryNBrandSuccess: state.property.getAllPropertyCitiesByCountryNBrandSuccess,
        getAllPropertyCitiesByCountryNBrandError: state.property.getAllPropertyCitiesByCountryNBrandError,

    }));




    // const [reduxBrandDetail, setReduxBrandDetail] = useState<ReduxBrandDetail>(brandRfpDetails);
    const [selectedCityRfpLocationsDetails] = useState<any>(selectedCityDetails?.rfpDetailSelectedCityDetails[0]?.locations);


    useEffect(() => {
        // if (brandRfpDetails) {
        //     setReduxBrandDetail(brandRfpDetails);
        // }

        if (updateBrandRfpProcessSuccess) {
            toast.success("Property updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });


        }
        if (updateBrandRfpProcessError) {
            toast.error(updateBrandRfpProcessError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [updateBrandRfpProcessError, updateBrandRfpProcessSuccess]);

    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // useEffect(() => {

    //     if (selectedCityRfpLocationsDetails) {
    //         // setLocation({ lat: Number(selectedCityRfpLocationsDetails[0]?.latitude), lng: Number(selectedCityRfpLocationsDetails[0]?.longitude) });
    //     }
    // }, []);

    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (data?.brandId) {
            getBrandProperties(data?.brandId);
        }

        // if (isLoadProperty) {
        // }
    }, [data, getBrandProperties, isLoadProperty, selectedCityDetails])
    // getBrandProperties


    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (selectedCityDetails?.selectedMyRfpCountryCode) {
            const params: any = {};
            params.countryCode = selectedCityDetails?.selectedMyRfpCountryCode;
            params.brandId = data?.brandId;
            getAllPropertyCitiesByCountryNBrand(params);
        }
    }, [data, getAllPropertyCitiesByCountryNBrand, selectedCityDetails])

    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getAllPropertyCitiesByCountryNBrandSuccess) {
            const filterCities = getAllPropertyCitiesByCountryNBrandSuccess.data.filter((acc) => acc._id).map(data => ({
                ...data,
                selected: false,
            }));

            setCityList(filterCities);
        }
        if (getAllPropertyCitiesByCountryNBrandError?.message) {
            toast.error(getAllPropertyCitiesByCountryNBrandError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [getAllPropertyCitiesByCountryNBrandError?.message, getAllPropertyCitiesByCountryNBrandSuccess])


    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const cities = getStorage('filtered_Cities');
        if (getBrandPropertiesSuccess?.data) {
            const filteredProperties = getBrandPropertiesSuccess?.data.filter((property) => _.includes(cities, property.city)).map(data => ({
                ...data,
                selected: false,
            }));
            setFilterBrandProperties(filteredProperties);

        }
        if (getBrandPropertiesError?.message) {
            toast.error(getBrandPropertiesError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [getBrandPropertiesError?.message, getBrandPropertiesSuccess?.data])


    const renderClientLocationList = useCallback(() => {
        if (selectedCityRfpLocationsDetails?.length > 0) {
            return selectedCityRfpLocationsDetails.map(location => (

                <div key={location._id} className="jumbotron" >
                    <Row>
                  

                    <Col md={6}>
                        <Row className='formSpace'>
                            {/* <Col md={5} style={{ textAlign: 'right' }}>
                                <Form.Label></Form.Label>
                            </Col> */}
                            {/* <Col md={7} style={{ textAlign: 'left' }}> */}
                                <div style={{
                                    //    border:'1px solid red'
                                }}>
                                    <GoogleMap
                                        googleMapURL={process.env.REACT_APP_MAP_URL}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `350px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                        location={{ lat: Number(location.latitude), lng: Number(location.longitude) }}
                                    // setCoords={setCoords}
                                    />
                                </div>
                            {/* </Col> */}
                        </Row>
                    </Col>

                    <Col md={6}>
                        <Row className='formSpace'>
                            <Col md={5} style={{ textAlign: 'right' }}>
                                <Form.Label>Name of the location</Form.Label>
                            </Col>
                            <Col md={7} style={{ textAlign: 'left' }}>
                                <p className="lead">{location.locationName}</p>
                            </Col>
                        </Row>
                        <Row className='formSpace'>
                            <Col md={5} style={{ textAlign: 'right' }}>
                                <Form.Label>Location address</Form.Label>
                            </Col>
                            <Col md={7} style={{ textAlign: 'left' }}>
                                <p className="lead">{location.address1 + ' ' + location.postcode}</p>
                            </Col>
                        </Row>
                        <Row className='formSpace'>
                            <Col md={5} style={{ textAlign: 'right' }}>
                                <Form.Label>Approximate location spend</Form.Label>
                            </Col>
                            <Col md={7} style={{ textAlign: 'left' }}>
                                <p className="lead">{location?.approximateLocationSpend}</p>
                            </Col>
                        </Row>
                        <Row className='formSpace'>
                            <Col md={5} style={{ textAlign: 'right' }}>
                                <Form.Label>Currency</Form.Label>
                            </Col>
                            <Col md={7} style={{ textAlign: 'left' }}>
                                <p className="lead">{location?.currency}</p>
                            </Col>
                        </Row>

                        <Row className='formSpace'>
                            <Col md={5} style={{ textAlign: 'right' }}>
                                <Form.Label>Approximate no. of nights</Form.Label>
                            </Col>
                            <Col md={7} style={{ textAlign: 'left' }}>
                                <p className="lead">{location?.approximateNoOfNights}</p>
                            </Col>
                        </Row>

                        <Row className='formSpace'>
                            <Col md={5} style={{ textAlign: 'right' }}>
                                <Form.Label>Average length of stay</Form.Label>
                            </Col>
                            <Col md={7} style={{ textAlign: 'left' }}>
                                <p className="lead">{location?.averageLengthOfStay}{` nights`}</p>
                            </Col>
                        </Row>

                        <Row className='formSpace'>
                            <Col md={5} style={{ textAlign: 'right' }}>
                                <Form.Label>Minimum stay requirement</Form.Label>
                            </Col>
                            <Col md={7} style={{ textAlign: 'left' }}>
                                <p className="lead">{location?.minimumStay}{` nights`}</p>
                            </Col>
                        </Row>

                        <Row className='formSpace'>
                            <Col md={5} style={{ textAlign: 'right' }}>
                                <Form.Label>Expected maximum stay</Form.Label>
                            </Col>
                            <Col md={7} style={{ textAlign: 'left' }}>
                                <p className="lead">{location?.expectedMaximumStay}{` nights`}</p>
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                </div>
            ))
        }
    }, [selectedCityRfpLocationsDetails])


    return (
        <div>
            <Row style={{
                marginBottom: '1.5rem',
                marginTop: '20px',
            }} >
                <Col xs={12}><div className="content-title">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => {
                            // updateBrandRFPData({
                            //     ...reduxBrandDetail,
                            //     defaultActiveKey: "myRFPs",
                            //     showMyRfpBrandEvaluationPage: false,
                            //     showHomePage: true,
                            //     showMyRfpHomePage: true,
                            // })
                        }} style={{

                            fontWeight: 100,
                        }} >
                            <Link to={{ pathname: "/admin/brand-rfp-management", state: { selectedBrandId: data?.brandId, defaultKey: 'submittedRFPs' } }}>{'SubmittedRFPs'}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => { setSelectedCity(""); setShowLocations(false) }} >
                            {data?.companyName}{' - '}{data?.programmeName}  {' - '} {moment(data?.startDate).format('DD MMM YYYY')}
                        </Breadcrumb.Item  >
                        <Breadcrumb.Item onClick={() => { setSelectedCity(""); setShowLocations(true); setDefaultTab('properties'); setComponentName('singleMyRfp'); }}>
                            Locations
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {
                                selectedCity
                            }
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div></Col>
            </Row>
            <div >
                <Form>
                    <Row>
                        {/* <Col style={{  }} md={6}> */}
                        {
                            renderClientLocationList()
                        }
                        {/* <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label></Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <div style={{
                                        //    border:'1px solid red'
                                    }}>
                                        <GoogleMap
                                            googleMapURL={process.env.REACT_APP_MAP_URL}
                                            loadingElement={<div style={{ height: `100%` }} />}
                                            containerElement={<div style={{ height: `350px` }} />}
                                            mapElement={<div style={{ height: `100%` }} />}
                                            location={location}
                                        // setCoords={setCoords}
                                        />
                                    </div>
                                </Col>
                            </Row> */}

                        {/* <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Approximate location spend</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{selectedCityRfpLocationsDetails.reduce((acc, element) => { return element.approximateLocationSpend + acc }, 0)}</p>
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Currency</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{selectedCityRfpLocationsDetails[0]?.currency}</p>
                                </Col>
                            </Row>

                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Approximate no. of nights</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{selectedCityRfpLocationsDetails[0]?.approximateNoOfNights}</p>
                                </Col>
                            </Row>

                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Average length of stay</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{selectedCityRfpLocationsDetails[0]?.averageLengthOfStay}{` nights`}</p>
                                </Col>
                            </Row>

                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Minimum stay requirement</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{selectedCityRfpLocationsDetails[0]?.minimumStay}{` nights`}</p>
                                </Col>
                            </Row>

                            <Row className='formSpace'>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    <Form.Label>Expected maximum stay</Form.Label>
                                </Col>
                                <Col md={7} style={{ textAlign: 'left' }}>
                                    <p className="lead">{selectedCityRfpLocationsDetails[0]?.expectedMaximumStay}{` nights`}</p>
                                </Col>
                            </Row> */}
                        {/* </Col> */}
                    </Row>
                </Form>
                <Row>
                    <Col md={9} className='flex justify-center mt-10 '  >
                        <Button id='edit-button' onClick={() => setModalShow(true)} >Select Properties</Button>
                    </Col>
                </Row>
                <MyVerticallyCenteredModal
                    setSelectedCity={setSelectedCity}
                    setShowLocations={setShowLocations}
                    filterBrandProperties={filterBrandProperties}
                    show={modalShow}
                    onHide={() => { setModalShow(false); setIsLoadProperty(false) }}
                    setRefreshPropertiesList={setRefreshPropertiesList}
                    selectedCity={selectedCity}
                    cityList={cityList}
                    // reduxBrandDetail={reduxBrandDetail}
                    rfpData={data}
                    selectedCityDetails={selectedCityDetails}
                    isLoadProperty={isLoadProperty}
                    setIsLoadProperty={setIsLoadProperty}
                    setComponentName={setComponentName}
                    setDefaultTab={setDefaultTab}
                />
            </div>
        </div>
    )
}

export default BrandAdminSubmittedLocationMap;