import { Button, Input, message, Modal } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import jwt from 'jsonwebtoken';
import React, { useCallback, useEffect, useState } from 'react';
import '../home.scss';
import InviteCard from './InviteCard';


const SignupList: React.FC<any> = (): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [data, setData] = useState<[]>([]);
  const [brandName, setBrandName] = useState<string>('');

  const { onLoadBrandSignup, onCreateBrandSignup } = useStoreActions<any>((actions) => ({
    onLoadBrandSignup: actions.brand.onLoadBrandSignUp,
    onCreateBrandSignup: actions.brand.onCreateBrandSignup,
  }));

  const { loadBrandsignupSuccess, createBrandSignupSuccess, createBrandSignupError } = useStoreState<any>((state) => ({
    loadBrandsignupSuccess: state.brand.loadBrandSignUpSuccess,
    createBrandSignupSuccess: state.brand.createBrandSignupSuccess,
    createBrandSignupError: state.brand.createBrandSignupError,
  }));

  const onInvite = useCallback(() => {
    setLoading(true);
    const jwtToken = jwt.sign({ name, email }, 'myo');
    onCreateBrandSignup({ name, email, token: jwtToken, brandName });
  }, [brandName, email, name, onCreateBrandSignup]);

  useEffect(() => {
    const payload = {
      start_date : '',
      end_date : '',
      brand_id : '',
      sign_up_status : '',
  }
    onLoadBrandSignup(payload);
  }, [onLoadBrandSignup]);

  useEffect(() => {
    if (loadBrandsignupSuccess) {
      setData(loadBrandsignupSuccess.data)
    }

    if (createBrandSignupSuccess) {
      setLoading(false);
      setOpenModal(false);
      const payload = {
        start_date : '',
        end_date : '',
        brand_id : '',
        sign_up_status : '',
    }
      onLoadBrandSignup(payload);
    }

    if (createBrandSignupError) {
      message.error(createBrandSignupError.message);
      setLoading(false);
    }

  }, [createBrandSignupError, createBrandSignupSuccess, loadBrandsignupSuccess, onLoadBrandSignup]);

  return (
    <div className="SignupList">
      <Button type="primary" className="mb-5" onClick={() => { setOpenModal(true); setEmail(''); setName(''); setBrandName('') }}>New Invite</Button>
      <div className="flex card-container">
        <div className="bg-yellow-200 flex-1 p-5">
          <p className="font-bold text-lg text-gray-600">Invitation sent</p>
          <hr />
          <div className="">
            {
              data.map((i: any) => {
                if (i.signUpStatus === 'sent') {
                  return <InviteCard color="bg-yellow-400 border-yellow-600" data={i} />
                }
                return null
              })
            }
          </div>

        </div>
        <div className="bg-blue-200 flex-1 p-5">
          <p className="font-bold text-lg text-gray-600">Invitation pending</p>
          <hr />
          <div>
            {
              data.map((i: any) => {
                if (i.signUpStatus === 'pending') {
                  return <InviteCard color="bg-blue-400 border-blue-600" data={i}/>
                }
                return null
              })
            }
          </div>
        </div>
        <div className="bg-green-200 flex-1 p-5">
          <p className="font-bold text-lg text-gray-600">Signup accepted</p>
          <hr />
          <div>
            {
              data.map((i: any) => {
                if (i.signUpStatus === 'accepted') {
                  return <InviteCard color="bg-green-400 border-green-600" data={i}/>
                }
                return null
              })
            }
          </div>
        </div>
        <div className="bg-red-200 flex-1 p-5">
          <p className="font-bold text-lg text-gray-600">Signup rejected</p>
          <hr />
          <div>
            {
              data.map((i: any) => {
                if (i.signUpStatus === 'rejected') {
                  return <InviteCard color="bg-red-400 border-red-600" data={i}/>
                }
                return null
              })
            }
          </div>
        </div>
      </div>

      {/* ///////////////////////////////////////////////////////////// */}

      <>
        <Modal
          title="New Invite"
          visible={openModal}
          footer={[
            <Button key="submit" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>,
            <Button key="submit" loading={loading} onClick={() => onInvite()} type="primary" disabled={email === '' || name === '' || brandName === ''}>
              Invite
            </Button>,
          ]}
        >
          <Input className="mb-3" placeholder="Brand name" value={brandName} onChange={(e) => setBrandName(e.target.value)} />
          <Input className="mb-3" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
          <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Modal>
      </>
    </div>
  )
}

export default SignupList;