import React, {useEffect, useState } from 'react';
import { Typography, Card } from 'antd';

import Slider from "react-slick";
import { Col, Row, Image, Form } from 'react-bootstrap';
// import bed from '../../../../../../images/icons/svg/outside/bed.svg';

import moment from 'moment';
import { Settings } from '../../../../../common/components-style';
import GoogleMap from '../../../../common/GoogleMap';



const { Text } = Typography;

const imageStyle = {
    textAlign: 'center' as const,
    height: '200px',
    background: '#F2F2F2',
    marginRight: '10px',
    borderRadius: '30px',
}

/////////////////////////////// details block style////////////////////////////////////////
const headStyle = { textAlign: 'center' as const, fontSize: '18px' };
const headStyleFirstCol = { fontSize: '18px', paddingLeft: '28px' };
const dataStyle = { textAlign: 'center' as const };
const infoCardStyle = { borderRadius: '20px' };

const ProgrammeSinglePropertyView: React.FC<any> = ({ property, amenitiesList }): JSX.Element => {
    /////////// local state manage /////////
    const [location, setLocation] = useState<any>({ lat: Number(property?.latitude), lng: Number(property?.longitude) });
     const [amenities, setAmenities] = useState<Array<any>>([]);

    useEffect(() => {
        if (property) {
            setLocation({ lat: Number(property?.latitude), lng: Number(property?.longitude) });
        }
        const amenitiesIds : any = [];

        property?.amenities?.amenity.forEach(element => {
            amenitiesIds.push(element);
        });
        property?.guest_comfort?.amenity.forEach(element => {
            amenitiesIds.push(element);
        });
         setAmenities(amenitiesIds);
    }, [property]);

    // const studio = () => {
    //     let counting = 0;
    //     property?.inventories?.forEach(value => {
    //         if ('Studio/Open plan apartment' === value?.room_type?.name.trim()) {
    //             counting += 1;
    //         }
    //     });

    //     return counting;
    // };

    // const bedRoomOne = () => {
    //     let counting = 0;
    //     property?.inventories?.forEach(value => {
    //         if ('1 bedroom' === value?.room_type?.name.trim()) {
    //             counting += 1;
    //         }
    //     });

    //     return counting;
    // };

    // const bedRoomTwo = () => {
    //     let counting = 0;
    //     property?.inventories?.forEach(value => {
    //         if ('2 bedroom' === value?.room_type?.name.trim()) {
    //             counting += 1;
    //         }
    //     });

    //     return counting;
    // };

    // const bedRoomThreePlus = () => {
    //     let counting = 0;
    //     property?.inventories?.forEach(value => {
    //         if ('3 bedroom' === value?.room_type?.name) {
    //             counting += 1;
    //         }
    //         if ('More than 3 bedrooms' === value?.room_type?.name.trim()) {
    //             counting += 1;
    //         }
    //     });

    //     return counting;
    // };

    const minNight = () => {
        let minimumStayed = 0;
        let firstLoading = false;
        property?.inventories?.forEach(value => {
            if (value?.minimumStayed) {
                if (!firstLoading) {
                    minimumStayed = value?.minimumStayed;
                    firstLoading = true;
                } else {
                    if (minimumStayed > value?.minimumStayed) {
                        minimumStayed = value?.minimumStayed;
                    }
                }
            }
        });

        return minimumStayed;
    }

    const getPropertyType = () => {
        let type = '';
        if (property?.property_type?.name.trim() === 'Apartments - building fully managed by our brand') {
            type = 'Apartments';

        } else if (property?.property_type?.name.trim() === 'Apartments - building managed by a third party') {
            type = 'Apartments';

        } else if (property?.property_type?.name.trim() === 'Aparthotel') {
            type = property?.property_type?.name;

        } else {
            type = 'Self contained';
        }

        return type;
    }

    const managingProcess = () => {
        let type = '';
        if (property?.property_type?.name.trim() === 'Apartments - building managed by a third party') {
            type = 'No';

        } else {
            type = 'Yes';
        }

        return type;
    }

    const inventoryImages = () => {
        let images : Array<any> = [];
        property?.inventories.forEach(inventory => {
            const temImage = inventory?.images?.[0]?.image?.map((value) => {
                return (
                    <div style={imageStyle}>
                        <Image width={310} src={value.url} alt={value.caption} style={{ borderRadius: '30px', height: 200 }}>
                        </Image>
                    </div>
                )
            });
            images = [...images, ...temImage]
        });
        return images;
    }

    const calculateAccoum = (inventories : Array<any>) => {
        let accoum = 0;
        inventories?.forEach((value)=>{
            accoum = accoum + value?.noOfApartments;
        });
        return accoum;
    }


    return (
  
            <div style={{width:'100%'}} >
                <Row>
                    <Col md={12}>
                        <Slider {...Settings}>
                            {
                                property?.images?.[0]?.image?.map((value) => {
                                    return (
                                        <div style={imageStyle}>
                                            <Image width={325} src={value.url} alt={value.caption} style={{ borderRadius: '30px', height: 200 }}>
                                            </Image>
                                        </div>
                                    )
                                })
                            }
                            {inventoryImages()}
                        </Slider>
                    </Col>
                </Row>
               
                <Row className='mt-4 tble_rw'>
                    
                    <Col md={2} style={headStyleFirstCol}>
                        <Text strong> Property type</Text>
                    </Col>
                    <Col md={2} style={headStyle}>
                        <Text strong> Accommodations</Text>
                    </Col>
                    <Col md={3} style={headStyle}>
                        <Text strong> Property 100% Managed</Text>
                    </Col>
                    <Col md={2} style={headStyle}>
                        <Text strong> Minimum Stay</Text>
                    </Col>
                    <Col md={3} style={headStyle}>
                        <Text strong> Check-in process</Text>
                    </Col>
                </Row>
                <Row className='mt-3' style={{ background: '#F2F2F2', height: '63px', padding: '15px' }}>
                    <Col md={2}>
                        <div >
                            <Text strong style={{ fontSize: '18px' }}> {getPropertyType()}</Text>
                        </div>
                    </Col>
                    <Col md={2} style={dataStyle}>
                        <div style={{ marginRight: '7%' }} >
                            <Text > {calculateAccoum(property?.inventories)}</Text>
                        </div>
                    </Col>
                    <Col md={3} style={dataStyle}>
                        <div  >
                            <Text > {managingProcess()}</Text>
                        </div>
                    </Col>
                    <Col md={2} style={dataStyle}>
                        <div >
                            <Text > {minNight()} Nights</Text>
                        </div>
                    </Col>
                    <Col md={3} style={dataStyle}>
                        <div  >
                            <Text > {property?.check_in?.check_in_process?.name}</Text>
                        </div>
                    </Col>
                </Row>

                {/* Map */}
                <Row  className='mt-5'>
                    <Col md={6}>
                        <GoogleMap
                            googleMapURL={process.env.REACT_APP_MAP_URL}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `480px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            location={location}
                        />
                    </Col>
                    <Col md={6}>
                        <Card className='bx_card' bordered={false} style={infoCardStyle}>                           
                            <h3 className='fz-24 mb-3'> Property Description</h3>
                            <Text strong><div dangerouslySetInnerHTML={{ __html: property?.description }} /></Text>                                
                        </Card>
                    </Col>
                </Row>
                
                {/* New Two Column */}
                <Row className='mt-5'>
                <Col md={6}>
                    <div className='grey_bx'>
                        <Row>
                            <Col md={7}>
                                <p>Year property built</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{moment(property?.yearPropertyWasBuilt).format('YYYY')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>Date of last communal area renovation</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{moment(property?.whenWereCommunalAreasLastRenovated).format('YYYY')}</p>
                            </Col>
                        </Row>
                        {/* <Row className='mt-4'>
                            <Col md={7}>
                                <p>Does the property comply with all local and notional disability law?</p>
                            </Col>
                            <Col md={5}>
                                <Form.Check
                                    inline
                                    label="Yes"
                                    type="checkbox"
                                    checked={property?.guest_comfort?.localAndNationalDisabilityLaw === "Yes" ? true : false}
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    type="checkbox"
                                    checked={property?.guest_comfort?.localAndNationalDisabilityLaw === "No" ? true : false}
                                />
                            </Col>
                        </Row> */}
                        <Row >
                            <Col md={7}>
                                <p>Are facilities and public areas accessible by people with physical disabilities?</p>
                            </Col>
                            <Col md={5}>
                                <Form.Check
                                    inline
                                    label="Yes"
                                    type="checkbox"
                                    checked={property?.guest_comfort?.areFacilitiesAndPublicAreaAccessible === "Yes" ? true : false}
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    type="checkbox"
                                    checked={property?.guest_comfort?.areFacilitiesAndPublicAreaAccessible === "No" ? true : false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>Regularity mattress is changed</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{property?.guest_comfort?.changeMattresses} years</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={7}>
                                <p>Bedding provided</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{property?.guest_comfort?.doYouProvide?property?.guest_comfort?.doYouProvide?.toString():''}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>Frequency bedding is renewed (months)</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{property?.guest_comfort?.renewBeddingMonths}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>Frequency pillows are renewed (months)</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{property?.guest_comfort?.renewPillowsMonths}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>Type of pillows provided</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{
                                    property?.guest_comfort?.detailsOfThePillows.map((value) => {
                                        return (<span>{value} ,</span>)
                                    })
                                }</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>Nearest convenience food store (mins)</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{property?.guest_comfort?.convenienceFoodStore}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>Nearest gymnasium (mins)</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{property?.guest_comfort?.nearestGym}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>Gymnasium short term membership?</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{property?.guest_comfort?.gymShortTermMember}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>Gymnasium pay-as-you-go option?</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{property?.guest_comfort?.gymPayAsYouGo}</p>
                            </Col>
                        </Row>
                    </div>   
                </Col>
                 <Col md={6}>          
                    <div className='strok_bx'>
                        <h3 className='fz-32 mb-3'>Transport</h3>
                        <Row>
                            <Col md={7}>
                              <p>On-site car parking</p>
                            </Col>
                            <Col md={5}>
                                <Form.Check
                                    inline
                                    label="Yes"
                                    type="checkbox"
                                    checked={property?.transport?.doYouProvideOnSiteCarParking === "Yes" ? true : false}
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    type="checkbox"
                                    checked={property?.transport?.doYouProvideOnSiteCarParking === "No" ? true : false}
                                />
                            </Col>
                        </Row>
                        {
                            (property?.transport?.doYouProvideOnSiteCarParking === "No") && <Row>
                                <Col md={7}>
                                <p>Distance to the nearest car park (mins)</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{property?.transport?.ifYouDoNotProvideOnSiteCarParking}</p>
                                </Col>
                            </Row>
                        }
                        {
                            (property?.transport?.doYouProvideOnSiteCarParking === "Yes") && <Row>
                                <Col md={7}>
                                    <p>Included in the rate?</p>
                                </Col>
                                <Col md={5}>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        type="checkbox"
                                        checked={property?.transport?.includedInTheRate === "Yes" ? true : false}
                                    />
                                    <Form.Check
                                        inline
                                        label="No"
                                        type="checkbox"
                                        checked={property?.transport?.includedInTheRate === "No" ? true : false}
                                    />
                                </Col>
                            </Row>
                        }
                        {
                            (property?.transport?.doYouProvideOnSiteCarParking === "Yes" && property?.transport?.includedInTheRate === "No") && <Row>
                                <Col md={7}>
                                    <p>cost of car parking</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{property?.transport?.costOfCarParking} GBP</p>
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col md={7}>
                                <p>Does the property provide disabled car parking bays?</p>
                            </Col>
                            <Col md={5}>
                                <Form.Check
                                    inline
                                    label="Yes"
                                    type="checkbox"
                                    checked={property?.guest_comfort?.carParkingBays === "Yes" ? true : false}
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    type="checkbox"
                                    checked={property?.guest_comfort?.carParkingBays === "No" ? true : false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>Nearest airport</p>
                            </Col>
                            <Col md={5}>
                                <p className='fw-rg'>{property?.transport?.nearestAirport}</p>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={7}>
                                <p>Distance to airport</p>
                            </Col>
                            <Col md={5}>
                               <p className='fw-rg'> {property?.transport?.distanceToTheAirport} km</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                </Row>

                    <div className='aprt_bx mt-5'>
                        <h3 className='fz-32 mb-3'>Building amenities</h3>                           
                        <Row>
                            {
                                amenities && amenities?.map((value, key) => {

                                    // let checked = false;

                                    // for (let i = 0; i < amenities?.length; i++) {
                                    //     if (amenities[i] === value?._id) {
                                    //         checked = true;
                                    //         break;
                                    //     } else {
                                    //         checked = false;
                                    //     }
                                    // }

                                    return (
                                        <Col md={4} key={key}>
                                            <Row className='mt-3'>
                                                <Col md={2}>
                                                    <img src={value.iconLocation} alt={value.name} />
                                                </Col>
                                                {/* <Col md={2}>
                                                    <div style={{ marginTop: '9px' }}>
                                                        <Form.Check
                                                            inline
                                                            type="checkbox"
                                                            checked={checked}
                                                        />
                                                    </div>
                                                </Col> */}
                                                <Col md={8}>
                                                    <div style={{ marginTop: '9px', marginLeft: '-4%' }}>
                                                        {value.name}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    );
                                })
                            }

                        </Row>
                    </div>
            </div>
     
    );
}

export default ProgrammeSinglePropertyView;

