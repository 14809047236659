import { CheckOutlined } from '@ant-design/icons';
import { Button, Radio } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

const MYOQOneOf: React.FC<any> = ({ data, onAnswer, signUpStatus }): JSX.Element => {
	const [answer, setAnswer] = useState<string>('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setAnswer(data.answer?.answer);
	}, [data.answer?.answer]);

	const onChange = useCallback((e) => {
		const val = e.target.value.toString();
		setAnswer(val);
	}, []);

	const answerQuestion = useCallback(() => {
		const disabled = signUpStatus === 'accepted' ? true : answer ? false : true
		if (disabled) return false;

		onAnswer(answer, data.id);
		setLoading(true);
		setTimeout(() => setLoading(false), 3000);
	}, [answer, data, onAnswer, signUpStatus]);

	return (
		<div className="inline-block  m-5 w-full pr-10">
			<div className="bg-white p-5 rounded-lg">
				<p className="text-lg mb-3">{data?.description}</p>
				<Radio.Group value={answer} onChange={onChange} >
					{
						data.options.split(';').map((o, i) => (<Radio key={i} value={i.toString()} className="block" >
							{o}
						</Radio>))
					}
				</Radio.Group>
				<Button type="primary" ghost={answer ? false : true} block onClick={answerQuestion} icon={<CheckOutlined />} className="rounded-xl mt-3" loading={loading}>
					Submit
				</Button>
			</div>
		</div>
	)
};

export default MYOQOneOf;