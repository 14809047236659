import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, OverlayTrigger, Tooltip, Row, Col, Modal } from 'react-bootstrap';
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';


import DataTable, { createTheme } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { corporateUser, isProgramAdmin } from '../../../common/functions';
import { UserGroups, UserProfile } from '../../../common/constants';
import { $mysaMegenta, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, resendButton, routingButton, tableUserStyles } from '../../../common/components-style';
import ProgrammePermissionModel from '../programme-management/form/programme-users/ProgrammePermissionModel';
import _ from 'lodash';
import TravelerUserForm from './Form/TravelerUserForm';
import TravelerUserView from './Form/TravelerUserView';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { getStorage, setStorage } from '../../../common/storage';
// import { HelpModal } from '../../../../common/HelpModal';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const TravelerUserPage: React.FunctionComponent<any> = ({ programme }) => {
    const [selectedUser, setSelectedUser] = useState<any>();
    const [loadModel, setLoadModel] = useState(false)

    const [userMappingData, setUserMappingData] = useState<Array<any>>([]);
    const [showForm, setShowForm] = useState(false);
    const [showView, setShowView] = useState(false);

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const [programmerUserDomains, setProgrammerUserDomains] = useState<Array<string>>([]);
    const [finalModal, setFinalModal] = useState<boolean>(false);
    const [errorRecord, setErrorRecord] = useState<Array<any>>([]);
    const [userData, setUserData] = useState<any>([]);


    const { onResendPassword, getUserMapping, resetAuth, getCorporateProgrammeById, createTravelerInformation, sendTravelerAdminWelcomeEmail, resetUser, getTravelerInformation, createTravelerUser, createTravelerUserMapping, updateTravelerUserMapping } = useStoreActions<any>((actions) => ({
        resetAuth: actions.auth.resetAuth,
        onResendPassword: actions.auth.onResendPassword,
        getUserMapping: actions.user.getUserMapping,

        createTravelerInformation: actions.client.createTravelerInformation,
        getTravelerInformation: actions.client.getTravelerInformation,
        createTravelerUser: actions.user.createUser,
        createTravelerUserMapping: actions.user.createUserMapping,
        updateTravelerUserMapping: actions.user.updateUserMapping,
        resetUser: actions.user.resetUser,
        sendTravelerAdminWelcomeEmail: actions.client.sendTravelerAdminWelcomeEmail,
        getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
    }));

    const { getTravelerInformationSuccess, getCorporateProgrammeByIdSuccess, createTravelerInformationSuccess, travelerUserCreate, userCreateError, createUserMappingSuccess, updateUserMappingSuccess, resendPassword, resendPasswordError, getUserMappingSuccess, updateAccessSuccess } = useStoreState<any>((state) => ({
        resendPassword: state.auth.resendPassword,
        resendPasswordError: state.auth.resendPasswordError,
        getUserMappingSuccess: state.user.getUserMappingSuccess,
        updateAccessSuccess: state.user.updateAccessSuccess,
        createTravelerInformationSuccess: state.client.createTravelerInformationSuccess,
        getTravelerInformationSuccess: state.client.getTravelerInformationSuccess,
        travelerUserCreate: state.user.userCreate,
        userCreateError: state.user.userCreateError,
        createUserMappingSuccess: state.user.createUserMappingSuccess,
        updateUserMappingSuccess: state.user.updateUserMappingSuccess,
        getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
    }));


    const callApi = useCallback((user) => {

        if (user) {
            let newData = user.map(obj => {
                let newObj = {};
                for (let key in obj) {
                    let newKey = key.replace(/\s+/g, '');
                    newObj[newKey] = obj[key];
                }
                return newObj;
            });
            const payload = {
                clientId: programme?.clientId?._id,
                programmeId: programme?._id,
                users: newData
            }
            setTimeout(() => createTravelerInformation(payload), 1000)
        }

    }, [createTravelerInformation, programme?._id, programme?.clientId?._id])



    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            const binaryStr = reader.result;

            if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);

                callApi(json);
            } else if (file.name.endsWith('.csv')) {
                Papa.parse(binaryStr, {
                    header: true,
                    complete: (results) => {

                        callApi(results.data);
                    }
                });

            } else {
                console.error('Unsupported file format');
            }


        };
        reader.readAsBinaryString(file);

    }, [callApi]);

    const { open } = useDropzone({ onDrop });

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (programme?._id) {
            getCorporateProgrammeById(programme?._id);
        }
        if (programme?.clientId?._id) {
            getTravelerInformation(programme?.clientId?._id)
        }

    }, [getTravelerInformation, getCorporateProgrammeById, programme?._id, programme?.clientId?._id]);

    useEffect(() => {
        if (getCorporateProgrammeByIdSuccess) {
            setProgrammerUserDomains(getCorporateProgrammeByIdSuccess.data?.companyDomains);
        }
        if (getTravelerInformationSuccess) {
            const data = getTravelerInformationSuccess.data;
            const filterData = _.filter(data?.programme, (a) => a.programmeId === programme?._id);
     
            setUserData(filterData[0]?.users);
        }
    }, [getTravelerInformationSuccess, getCorporateProgrammeByIdSuccess, programme?._id]);


    useEffect(() => {
        if (createTravelerInformationSuccess) {
            if (createTravelerInformationSuccess?.data) {
                if (programme?.clientId?._id) {
                    getTravelerInformation(programme?.clientId?._id)
                }
                setOpenModal(false);
                setUploadModal(true);
            }
        }

    }, [createTravelerInformationSuccess, getTravelerInformation, programme?.clientId?._id]);


    const reload = useCallback(() => {
        setTimeout(() => getUserMapping({ clientId: programme?.clientId?._id }), 1000);
        setShowForm(false);
    }, [getUserMapping, programme?.clientId?._id])

    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        // user create success
        if (getStorage('travelerImport')) {
            if (travelerUserCreate) {
                if (travelerUserCreate.data.data) {

                    // create mapping
                    createTravelerUserMapping({
                        userId: travelerUserCreate.data.user._id,
                        email: travelerUserCreate.data.user.email,
                        clientId: programme?.clientId?._id,
                        programmeId: programme?._id,
                        role: UserGroups.traveler,
                    })

                    const payload = {
                        firstName: travelerUserCreate.data.user?.firstName,
                        lastName: travelerUserCreate.data.user?.lastName,
                        programmeName: programme?.programmeName,

                        email: travelerUserCreate.data.user.email
                    }
                    sendTravelerAdminWelcomeEmail(payload);
                } else {
                    //update mapping
                    updateTravelerUserMapping({
                        userId: travelerUserCreate.data.user._id,
                        client: {
                            clientId: programme?.clientId?._id,
                        },
                        corporateProgramme: {
                            programmeId: programme?._id,
                            role: UserGroups.traveler,
                        }

                    })

                    const payload = {
                        firstName: travelerUserCreate.data.user?.firstName,
                        lastName: travelerUserCreate.data.user?.lastName,

                        programmeName: programme?.programmeName,
                        email: travelerUserCreate.data.user.email
                    }
                    sendTravelerAdminWelcomeEmail(payload);
                }

                resetUser();
                // setLoading(false);
                reload();
            }
        }

        // update user mapping success 
        if (updateUserMappingSuccess || createUserMappingSuccess) {

            resetUser();
        }

        // user create error
        if (userCreateError) {
            toast.error(userCreateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetUser();
            // setLoading(false);
        }
    }, [createTravelerUserMapping, createUserMappingSuccess, programme?._id, programme?.clientId?._id, programme?.programmeName, reload, resetUser, sendTravelerAdminWelcomeEmail, travelerUserCreate, updateTravelerUserMapping, updateUserMappingSuccess, userCreateError]);


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const insertMultipleRecords = useCallback(async (records) => {

        for (let i = 0; i < records.length; i++) {
            await createTravelerUser(records[i]);
        }
        setUploadModal(false);
        setFinalModal(true);

    }, [createTravelerUser]);

    ////////////////////////////////////////////////////////////////
    const handleInsertRecords = useCallback(async (userList) => {
        const records: any = [];

        for (let i = 0; i < userList.length; i++) {
            records.push({ email: userList[i].email, firstName: userList[i].firstName, lastName: userList[i].lastName, userGroup: UserGroups.user });
        }

        await insertMultipleRecords(records);
    }, [insertMultipleRecords]);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    const startUpload = useCallback(() => {
        setStorage('travelerImport', true);
        const userDetails: any = [];
        const errorData: any = [];
        if (userData) {

            userData.forEach(record => {
                const emailDomain = record.email.split('@')[1];
                const isDomainMatch = programmerUserDomains.includes(emailDomain);
                const isNameEmpty = !record.firstName || !record.lastName;

                if (isDomainMatch && !isNameEmpty) {
                    userDetails.push(record);
                } else {
                    errorData.push(record);
                }
            });

        }

        handleInsertRecords(userDetails);
        setErrorRecord(errorData);
    }, [handleInsertRecords, programmerUserDomains, userData]);



    useEffect(() => {
        getUserMapping({});
    }, [getUserMapping]);

    useEffect(() => {

        if (getUserMappingSuccess) {
            const { result, poolUsers } = getUserMappingSuccess.data;

            /**
             * corporate users
             */
            const corporateUserList = corporateUser(result, poolUsers);
            setUserMappingData(corporateUserList);
        }

    }, [getUserMappingSuccess]);

    useEffect(() => {
        if (resendPassword) {
            // message.success('password resend successful, Please check the email');
            toast.success("password resend successful, Please check the email", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            })
            resetAuth();
        }

        if (resendPasswordError) {
            // message.error(resendPasswordError.message);
            toast.error(resendPasswordError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetAuth();
        }

        // if (updateAccessSuccess) {
        //     // resetUser();
        //     toast.success("disable user successfully", {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         className: 'foo-bar'
        //     });

        // }


    }, [resendPassword, resetAuth, resendPasswordError, updateAccessSuccess]);


    const data: any = useMemo(() => {

        let filterData: any = [];
        filterData = _.filter(userMappingData, (a) => a.corporateProgrammeId === programme?._id && a.role === UserGroups.traveler);




        const uniqueUserIds = new Set();
        const uniqueRecords: any = [];

        filterData.forEach((record: any) => {
            if (!uniqueUserIds.has(record.userId)) {
                uniqueUserIds.add(record.userId);
                uniqueRecords.push(record);
            }
        });

        return uniqueRecords;
        // return filterData;

    }, [programme?._id, userMappingData])


    const columns = [


        { name: 'First name', selector: row => row.firstName, },
        { name: 'Last name', selector: row => row.lastName, },
        { name: 'Email', selector: row => row.email, },
        { name: 'User type', selector: row => row.role, },
        {
            name: 'Status',

            cell: (row) => {
                let color = "";
                if (row.userStatus !== 'CONFIRMED') color = $mysaYellow;
                if (row.userStatus === 'CONFIRMED') color = $mysaTeal;
                if (row.userStatus === 'FORCE_CHANGE_PASSWORD') color = $mysaMegenta;
                if (row.status === 'inactive') color = $mysaMegenta;

                return (

                    <span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>
                        {
                            (row.status === 'inactive') && 'DISABLED'
                        }
                        {
                            (row.status !== 'inactive') && `${row.userStatus}`
                        }
                        {row.status !== 'inactive' && row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => onResendPassword({ email: row.email })} className="ml-2 mt-1" >Resend</Button>}
                    </span>
                    // <span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} >{row.userStatus} {row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button onClick={() => onResendPassword({ email: row.email })} style={resendButton} id='resend-button' >Resend</Button>}</span>
                    // <>
                    // 	<span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>{row.userStatus}</span>
                    // 	{row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => onResendPassword({ email: row.email })} className="ml-5" >Resend</Button>}
                    // </>
                )
            }
        },
        {
            name: 'User profile',

            cell: (row) => {
                let color = "";
                if (row.userProfile !== UserProfile.COMPLETED) color = $mysaMegenta;
                if (row.userProfile === UserProfile.COMPLETED) color = $mysaTeal;

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.userProfile}</span>
                );

            }
        },
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                <>
                    {<OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
                    >
                        <img
                            style={{ marginLeft: '-32px' }}
                            className='iconSizes' src={ViewEdit} alt="" onClick={() => { setSelectedUser(row); setShowView(true) }} />
                    </OverlayTrigger>}


                    {/* {
                        isProgramAdmin() && <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
                        >
                            <img className='iconSizes' src={Archive} alt="" onClick={() => {
                                const data = {
                                    id: selectedUser.arrId,
                                    userId: selectedUser.userId,
                                    isUserDisable: true
                                }

                                updateAccess(data);
                            }} />
                        </OverlayTrigger>
                    } */}
                    {/* {<OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>Permission</Tooltip>}

                    >
                        <img className='iconSizes' src={Permission} alt="" onClick={() => { setLoadModel(true); setSelectedUser(row) }} />
                    </OverlayTrigger>} */}
                </>
            )

        },
    ];


    const columns1 = [
        { name: 'First name', selector: row => row.firstName, },
        { name: 'Last name', selector: row => row.lastName, },
        { name: 'Email', selector: row => row.email, },


    ];

    return (
        <>

            <div style={{ marginTop: "-2%", paddingTop: "4px" }}>
                <Row>
                    <Col xs={9}>{programme?.programmeName && <div className="content-title">{programme?.programmeName}</div>}</Col>
                    <Col xs={3} className="text-right">
                        <div className='d-flex align-items-center justify-content-end gx-2'>

                            {isProgramAdmin() && <Button style={routingButton} id='routing-button' hidden={showForm || showView} className='float-right' onClick={() => { setSelectedUser(null); setShowForm(true) }}><b>New Traveler</b></Button>}
                            {isProgramAdmin() && <Button hidden={!showForm} id="import-button"
                                style={{ marginRight: '175px', }}
                                className="import" onClick={() => setOpenModal(true)}>Import</Button>}
                        </div>

                    </Col>
                </Row>


                {/* <Button type="primary" hidden={!showForm} className='float-right' onClick={() => setShowForm(false)}>User List</Button> */}
                {showForm && <TravelerUserForm selectedUser={selectedUser} clientId={programme?.clientId?._id} programmeId={programme?._id} programmeName={programme?.programmeName} close={() => setShowForm(false)} reload={reload} />}
                {showView && <TravelerUserView selectedUser={selectedUser} close={() => setShowView(false)} reload={reload} />}
                {showForm || showView ||
                    <div className='mt-4 brandUser_table'>
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            striped={true}
                            customStyles={tableUserStyles}
                            theme='mysaTable'
                            fixedHeader={true}
                            noDataComponent={'Updating records'}
                        />
                    </div>
                }
                {loadModel && <ProgrammePermissionModel clientId={selectedUser?.clientId} programmeId={selectedUser?.corporateProgrammeId} userMappingData={userMappingData} corporateProgrammes={selectedUser?.corporateProgramme} selectedUser={selectedUser} onClose={() => { setLoadModel(false); }} />}
            </div>

            <Modal
                show={openModal}
                onHide={() => { setOpenModal(false); }}
                width={750}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ textAlign: 'center' }}>
                        <h3>
                            Import file from computer
                        </h3>
                        <div>
                            <p>
                                Upload any CSV,XLS or XLSX files with first name, last name and email address.Email addresses must match the company domains
                                registered in 'Programme Details'
                            </p>
                        </div>
                        <Col >

                            <Button style={routingButton} id='routing-button' onClick={open}><b>Upload file</b></Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>


            <Modal
                show={uploadModal}
                onHide={() => { setUploadModal(false); }}
                width={750}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ textAlign: 'center' }}>
                        <h3 >
                            Start Upload
                        </h3>
                        <div>
                            <p>
                                We will notify you when the upload is complete
                            </p>
                        </div>
                        <Col >

                            <Button style={routingButton} id='routing-button' onClick={() => { startUpload(); }} ><b>Start</b></Button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>


            <Modal
                show={finalModal}
                onHide={() => { setFinalModal(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {
                        errorRecord?.length === 0 ?
                            <Row style={{ textAlign: 'center' }}>
                                <h3 >
                                    Your upload is complete.
                                </h3>
                                <div>
                                    <p>
                                        There were 0 errors.
                                    </p>
                                </div>
                                <Col >

                                    <Button style={routingButton} id='routing-button' onClick={() => { setFinalModal(false); }} ><b>Close</b></Button>
                                </Col>
                            </Row> :
                            <Row style={{ textAlign: 'center' }}>
                                <h3 >
                                    Your upload is complete.
                                </h3>
                                <div>
                                    <p>
                                        There were {errorRecord.length} errors.
                                    </p>
                                </div>
                                <Col >

                                    <Button style={routingButton} id='routing-button' onClick={() => { setFinalModal(false); }} ><b>Close</b></Button>
                                </Col>


                                <div className='mt-4 brandUser_table'>
                                    <DataTable
                                        columns={columns1}
                                        data={errorRecord}
                                        pagination
                                        striped={true}
                                        customStyles={tableUserStyles}
                                        theme='mysaTable'
                                        fixedHeader={true}
                                        noDataComponent={'Updating records'}
                                    />
                                </div>
                            </Row>}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TravelerUserPage
