import { Breadcrumb, Col, Row, Tab } from 'react-bootstrap';
import React, { useState } from 'react';
import { UserAccess } from '../../../../common/constants';
import { isCorporateProgrammeModuleAvailable } from '../../../../common/functions';
import AccessDenied from '../../../common/AccessDenied';
import LocationDetailsForm from './LocationDetail';
import { navContentStyle, tabDivStyle } from '../../../../common/components-style';
import { Link } from 'react-router-dom';

const LocationForm: React.FC<any> = ({ locationId, selectedProgramme, selectedClient, setFormOpen, refreshLocations, setSelectedLocation, selectedLocation, formOpen, reload }): JSX.Element => {

	const [tabKey, setTabKey] = useState<any>('details');

	return (
		<div style={tabDivStyle}>
			<Tab.Container defaultActiveKey="details" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
				<Row >
					{/* <Col sm={2}>
						<Nav variant="pills" className="flex-column" style={navStyle}>
							<div style={{ marginTop: '0%' }}>
								<Nav.Item>
									<Nav.Link eventKey="details">Details</Nav.Link>
								</Nav.Item>
							</div>
						</Nav>
					</Col> */}
					<Col sm={12}>
						<Tab.Content >
							<Tab.Pane eventKey="details" >
								<div style={navContentStyle}>
									<div className="flex justify-between">
										{!selectedProgramme && <p className="text-2xl mb-10"></p>}
										{selectedProgramme && <Breadcrumb className="client_hdng mb-10 ml-10">
											<Breadcrumb.Item>
												<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
											</Breadcrumb.Item>
											<Breadcrumb.Item>
												<Link to={{ pathname: "/admin/programme-management", state: { selectedProgramme: selectedProgramme, isBack: true } }}>{selectedClient.companyName}</Link>
											</Breadcrumb.Item>
											<Breadcrumb.Item>
												<Link to={{ pathname: "/admin/programme-management", state: { selectedProgramme: selectedProgramme } }}>{selectedProgramme.programmeName}</Link>
											</Breadcrumb.Item>
											<Breadcrumb.Item>
												<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshLocations(); setSelectedLocation(null) }}>Location Management</Link>
											</Breadcrumb.Item>
											{selectedLocation && <Breadcrumb.Item active>{selectedLocation.locationName}</Breadcrumb.Item>}
											{(formOpen && !selectedLocation) && <Breadcrumb.Item active>New location</Breadcrumb.Item>}
										</Breadcrumb>}
									</div>
									{isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <LocationDetailsForm locationId={locationId} selectedProgramme={selectedProgramme} reload={reload} /> : <AccessDenied />}
								</div>
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		</div>
	)
};

export default LocationForm;