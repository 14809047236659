import { createStore } from 'easy-peasy';

import authModel from './models/AuthModel';
import brandModal from './models/BrandModal';
import commonModel from './models/CommonModel';
import userModel from './models/UserModel';
import propertyModel from './models/PropertyModel';
import auditModel from './models/AuditModel';
import ClientModel from './models/ClientModel';
import InventoryModel from './models/InventoryModel';
import RateModel from './models/RateModel';
import BookingModel from './models/BookingModel';
import RfpProcessModel from "./models/RfpProcessModel";
import MYOManagementModel from "./models/MYOManagementModel";
import DashboardModel from "./models/DashboardModel";
import OverlaySpinnerModel from "./models/OverlaySpinnerModel";

const store = createStore({
  common: commonModel,
  user: userModel,
  auth: authModel,
  brand: brandModal,
  property: propertyModel,
  audit: auditModel,
  client: ClientModel,
  inventory: InventoryModel,
  rate: RateModel,
  booking: BookingModel,
  rfpProcess: RfpProcessModel,
  myoManagementModel: MYOManagementModel,
  DashboardModel: DashboardModel,
  OverlaySpinner: OverlaySpinnerModel
});


export default store;
