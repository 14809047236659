import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { setStorage } from '../../../common/storage';
import { $mysaMegenta, $mysaTeal, $mysaYellow, overlayTriggerDelay, tableStyles } from '../../../common/components-style';
import DataTable from 'react-data-table-component';
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import Archive from '../../../images/icons/svg/outside/Icon-Function-Archive.svg';
import { InventoryStatus } from '../../../common/constants';
import SweetAlert from 'react-bootstrap-sweetalert';

const InventoryList: React.FC<any> = ({ loadForm, removeData, inventoryList, onInventorySelect }): JSX.Element => {
  const [archivePopop, setArchivePopop] = useState<boolean>(false);
  const [activeData, setActiveData] = useState<any>();

  const columns = [
    { name: 'Configuration', selector: row => row.configuration },
    { name: 'Additional detail', selector: row => row.accommodationDetail },
    { name: 'No of bathrooms', selector: row => row.bathrooms },
    { name: 'No of apartments', selector: row => row.apartments },
    {
      name: 'Status',
      cell: (row) => {
        let color = "";
        if (row.status === InventoryStatus.PENDING) color = $mysaYellow;
        if (row.status === InventoryStatus.APPROVED) color = $mysaTeal;
        if (row.status === InventoryStatus.INCOMPLETE) color = $mysaMegenta;
        if (row.status === InventoryStatus.REVIEW) color = $mysaYellow;
        return (
          <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.status}</span>
        )
      }
    },
    // { name: 'Status', selector: row => row.propertyStatus },
    {
      name: 'Action',
      button: true,
      cell: (row: any) => {
        return (<>
          <OverlayTrigger
            placement="top"
            delay={overlayTriggerDelay}
            overlay={<Tooltip id='tooltip-table-top'>View/Edit</Tooltip>}
          >
            <img onClick={() => { loadForm(row); setStorage('myo_selected_inventory', row); onInventorySelect(row) }} className='iconSizes' src={ViewEdit} alt="" />
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            delay={overlayTriggerDelay}
            overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
          >
            <img onClick={() => { setActiveData(row); setArchivePopop(true); }} className='iconSizes' src={Archive} alt="" />
          </OverlayTrigger>

        </>)
      },
    },
  ];
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const data = useMemo(() => {
    return _.map(inventoryList, inventory => {
      return {
        key: inventory?._id,
        configuration: inventory?.accommodationConfiguration?.name,
        accommodationDetail: inventory?.moderate?.details?.new_value ? inventory?.moderate?.details?.new_value : inventory?.accommodationDetail,
        bathrooms: inventory.noOfBathrooms,
        apartments: inventory.noOfApartments,
        propertyName: inventory?.propertyId?.propertyName,
        propertyId: inventory?.propertyId?._id,
        status: inventory?.status,
        minimumStayed: inventory?.minimumStayed
      }
    })

  }, [inventoryList]);

  // return (
  //   <div className="inventory-list">
  //     <Table columns={columns} dataSource={data} />
  //   </div>
  // )
  return (
    <>
      <div className='mt-3'>
        <DataTable
          columns={columns}
          data={data}
          pagination
          striped={true}
          customStyles={tableStyles}
          theme='mysaTable'
          fixedHeader={true}
          noDataComponent={'Updating records'}
        />
      </div>

      <SweetAlert
        show={archivePopop}
        warning
        showCancel={true}
        focusCancelBtn={true}
        confirmBtnText="Yes archive it!"
        confirmBtnBsStyle="danger"
        title="Are you sure you wish to delete this inventory?"
        onConfirm={() => {
          removeData(activeData)
          setArchivePopop(false);
        }}
        onCancel={() => {
          setArchivePopop(false);
        }}
      >
      </SweetAlert>
    </>
  )
};

export default InventoryList;