import { CheckOutlined } from '@ant-design/icons';
import { Button, InputNumber } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

const MYOQNumber: React.FC<any> = ({ data, onAnswer, signUpStatus, completed }): JSX.Element => {
	const [answer, setAnswer] = useState<number>(0);
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setAnswer(data.answer?.answer)
	}, [data.answer?.answer]);

	const answerQuestion = useCallback(() => {
		const disabled = signUpStatus === 'accepted' ? true : completed ? true : answer ? false : true
		if (disabled) return false;

		onAnswer(answer?.toString(), data.id);
		setLoading(true);
		setTimeout(() => setLoading(false), 3000);
	}, [answer, data, onAnswer, signUpStatus, completed]);

	return (
		<div className="inline-block  m-5 w-full pr-10">
			<div className="bg-white p-5 rounded-lg">
				<p className="text-lg mb-3">{data?.description}</p>
				<InputNumber value={answer} onChange={(val: any) => setAnswer(val)} className='w-full' placeholder="Please enter your answer" readOnly={completed ? true : false} />
				<Button type="primary" ghost={answer ? false : true} block onClick={answerQuestion} icon={<CheckOutlined />} className="rounded-xl mt-3" loading={loading}>
					{data.answer?.answer ? 'Update' : 'Submit'}
				</Button>
			</div>
		</div>
	)
};

export default MYOQNumber;