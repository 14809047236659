///////////////////////////////////////////// bootstrap ////////////////////////////////////

import { StylesConfig } from "react-select";
import BookMarkIcon from '../images/icons/svg/bookmark.svg';
import FilterIcon from '../images/icons/svg/filter.svg';
///////////////get display sizes ///////////////////
const { innerHeight: height } = window;

////////////////// theme color ///////////////////////////
export const $mysaBlack = '#000000';
export const $mysaBlue = '#19AFE6';
export const $mysaTeal = '#2DBDB6';
export const $mysaYellow = '#fAC90E';
export const $mysaMegenta = '#E7008C';
export const $mysaRed = '#FF0000';
export const $mysaGrey = '#F2F2F2';
export const $mysaGreyDark = '#00000021';
// 3px solid #00000021
export const $mysaWhite = '#FFFFFF';
export const $mysaOrange = '#F37112';

//////////////////// searchable dropdown color ////////////////////////
const searchableDropDownFont = $mysaBlack;
const searchableDropDownBackground = $mysaWhite;
const searchableDropDownBorder = $mysaBlack;

////////////////////table color /////////////////////
const themeColor = $mysaGrey;
const stripedBackground = $mysaGrey;
const headerCellBackground = $mysaBlack; //$mysaGrey;
const headerCellFont = $mysaWhite;//$mysaBlack;

////////////////////// tooltip //////////////////////////
export const overlayTriggerDelay = { show: 50, hide: 10 };

/////////////// side bar tooltip style ///////////////////
export const toolTipStyle = {
    marginLeft: '20px',
    fontWeight: 'bold'
} as React.CSSProperties;

/////////////////////side bar icon style /////////////////////////
export const iconDivStyle = {
    width: '40px',
    display: 'block',
    margin: 'auto',
    paddingBottom: '10px'
} as React.CSSProperties;

export const iconDivSelectedStyle = {
    width: '60px',
    height: '40px',
    display: 'block',
    paddingBottom: '10px',
    borderLeft: '7px solid #FBC91D',
    position: 'relative',
    marginBottom: '10px',

} as React.CSSProperties;

export const iconImageSelectedStyle = {
    position: 'absolute',
    right: '0',
    height: '40px',


} as React.CSSProperties;


///////////////// side bar style ////////////////////////////
export const sideMenuStyle = {
    height: height,
    background: "#5f5f5f",
    width: '80px',
    paddingTop: '30px',
    position: 'fixed'
} as React.CSSProperties;

/////////////////// searchable dropdown style ////////////////////////////
export const selectStylesLittleRect = {
    control: (base, state) => ({
        ...base,
        height: "50px",
        paddingLeft: "10px",
        background: searchableDropDownBackground,
        color: searchableDropDownFont,
        borderRadius: 11,
        textAlign: "left",
        borderColor: searchableDropDownBorder,
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: searchableDropDownBorder
        },
    }),
    menu: (base) => ({
        ...base,
        hyphens: "auto",
        marginTop: 0,
        borderRadius: '11px',
        textAlign: "left",
        zIndex: 9999
    }),
    menuList: (base) => ({
        ...base,
        padding: "10px",
        color: searchableDropDownFont
    }),
};

/////////////////// searchable dropdown style ////////////////////////////
export const selectBookingStylesLittleRect = {
    control: (base, state) => ({
        ...base,
        height: "34px",
        width: '300px',
        paddingLeft: "10px",
        background: searchableDropDownBackground,
        color: searchableDropDownFont,
        borderRadius: 11,
        borderColor: $mysaGrey,
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: $mysaGrey
        }
    }),
    menu: (base) => ({
        ...base,
        hyphens: "auto",
        marginTop: 0,
        borderRadius: '11px',
        textAlign: "left",
        zIndex: 9999
    }),
    menuList: (base) => ({
        ...base,
        padding: "10px",
        color: searchableDropDownFont,
    })
};

export const selectStyles = {
    control: (base, state) => ({
        ...base,
        height: "50px",
        paddingLeft: "10px",
        background: searchableDropDownBackground,
        color: searchableDropDownFont,
        borderRadius: 30,
        borderColor: searchableDropDownBorder,
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: searchableDropDownBorder
        }
    }),
    menu: (base) => ({
        ...base,
        hyphens: "auto",
        marginTop: 0,
        borderRadius: '15px',
        textAlign: "left",
        zIndex: 9999
    }),
    menuList: (base) => ({
        ...base,
        padding: "10px",
        color: searchableDropDownFont,
    })
};


export const selectStyles2 = {
    control: (base, state) => ({
        ...base,
        height: "50px",
        paddingLeft: "10px",
        background: searchableDropDownBackground,
        color: searchableDropDownFont,
        borderRadius: 30,
        borderColor: searchableDropDownBorder,
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: searchableDropDownBorder
        }
    }),
    menu: (base) => ({
        ...base,
        hyphens: "auto",
        marginTop: 0,
        borderRadius: '15px',
        textAlign: "left",
        zIndex: 9999
    }),
    menuList: (base) => ({
        ...base,
        padding: "10px",
        color: searchableDropDownFont,
    })
};

//////////////////////////// routing button style //////////////////////////
export const routingButton = {
    height: "50px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
} as React.CSSProperties;

///////////////////////// table style /////////////////////////////
export const mysaTableTheme = {
    divider: {
        default: themeColor,
    },
};

export const tableStyles = {

    rows: {
        style: {
            minHeight: '72px',
            '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: $mysaGrey,
            },
        },
        stripedStyle: {
            background: stripedBackground,
        },
    },
    headRow: {
        style: {
            padding: '16px',
            background: headerCellBackground,
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            background: headerCellBackground,
            // borderRadius: 30,
            fontSize: '19px',
            color: headerCellFont,
            '&:not(:last-of-type)': {
                borderRight: `3px solid ${$mysaGrey}`,
            },
            '&:first-of-type': {
                paddingLeft: '0px',
            },
            borderRight: 'none',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            fontSize: '16px',
            '&:first-of-type': {
                paddingLeft: '16px',
            },
        },
    },
    pagination: {
        style: {
            marginTop: '40px',
            borderTop: 'none'
        },
    }
}

// export const tableStyles = {
//     rows: {
//         style: {
//             minHeight: '72px', // override the row height
//         },
//         stripedStyle: {
//             background: stripedBackground,
//         }
//     },
//     headCells: {
//         style: {
//             paddingLeft: '8px', // override the cell padding for head cells
//             paddingRight: '8px',
//             background: headerCellBackground,
//             fontSize: '19px',
//             color: headerCellFont
//         },
//     },
//     cells: {
//         style: {
//             paddingLeft: '8px', // override the cell padding for data cells
//             paddingRight: '8px',
//             fontSize: '16px',
//         },
//     },
// };



//////////////////////////// resend button style //////////////////////////
export const resendButton = {
    height: "35px",
    width: "110px",
    borderRadius: 30,
    paddingLeft: "25px",
    paddingRight: "25px",
    fontSize: '14px'
};


///////////////brand and property user table style ///////////////////////////////////////

export const tableUserStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
            '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: $mysaGrey,
            },
        },
        stripedStyle: {
            background: stripedBackground,
        }
    },
    headRow: {
        style: {
            padding: '16px',
            background: headerCellBackground,
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            background: headerCellBackground,
            fontSize: '16px',
            color: headerCellFont,
            '&:not(:last-of-type)': {
                borderRight: `3px solid ${$mysaGrey}`,
            },
            '&:first-of-type': {
                paddingLeft: '0px',
            },
            borderRight: 'none',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            fontSize: '14px',
            '&:first-of-type': {
                paddingLeft: '16px',
            },
        },
    },
    pagination: {
        style: {
            marginTop: '40px',
            borderTop: 'none'
        },
    }
};

//////////////////// tabs style ///////////////////////
export const tabDivStyle = { marginLeft: '-40px', marginTop: '-38px' } as React.CSSProperties;
export const navStyle = {
    width: '250px',
    background: $mysaGrey,
    height: height * 1.5,
    paddingTop: '25px',
    position: 'fixed',
} as React.CSSProperties;

export const navStyleNew = {
    width: '250px',
    background: $mysaGrey,
    // height: height * 1.5,
    paddingTop: '25px',
    position: 'fixed',
    zIndex: 999
} as React.CSSProperties;

export const BrandNavStyle = {
    width: '197px',
    background: $mysaGrey,
    height: height * 1.5,
    paddingTop: '25px',
    position: 'fixed',
} as React.CSSProperties;


export const navContentStyle = {
    paddingTop: '2%',
} as React.CSSProperties;


//////////////////////////// form button style //////////////////////////
export const formButton = {
    height: "40px",
    width: '150px',
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: '30px'
} as React.CSSProperties;

export const formButton2 = {
    height: "40px",
    width: '150px',
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: '30px',
    marginLeft: '20px'
} as React.CSSProperties;


export const formButton3 = {
    height: "40px",
    width: '205px',
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: '30px',
    marginLeft: '20px'
} as React.CSSProperties;

export const formButton4 = {
    height: "40px",
    // width: '205px',
    borderRadius: 30,
    paddingLeft: "70px",
    paddingRight: "70px",
    marginBottom: '30px',
    // marginLeft: '20px'
} as React.CSSProperties;

export const formButton5 = {
    height: "40px",
    // width: '205px',
    borderRadius: 30,
    paddingLeft: "50px",
    paddingRight: "50px",
    marginBottom: '30px',
    marginLeft: '0px'
} as React.CSSProperties;

export const loginButton = {
    height: "49px",
    width: '328px',
    borderRadius: 44,
    left: '793px',
    top: '617px',
    background: '#FBC91D',
    border: '1px solid #FBC91D'
} as React.CSSProperties;

//////////////////////////// form button style //////////////////////////
export const imageButton = {
    height: "40px",
    width: '171px',
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    marginRight: '20px'
} as React.CSSProperties;


export const viewButton = {
    height: "50px",
    width: '100%',
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    fontWeight: 'bold'
} as React.CSSProperties;

export const createTravelerButton = {
    height: "50px",
    width: "870px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    fontWeight: 'bold'
} as React.CSSProperties;

export const calendarButton = {
    height: "50px",
    width: "100%",
    borderRadius: "11px",
    paddingLeft: "30px",
    paddingRight: "30px",
    // background: #FFFFFF;
    // border: 1px solid #999999;
} as React.CSSProperties;

export const headerButton = {
    height: "50px",
    width: "350px",
    borderRadius: "11px",
    paddingLeft: "30px",
    paddingRight: "30px",
    // background: #FFFFFF;
    // border: 1px solid #999999;
} as React.CSSProperties;

export const bookProperty = {
    height: "50px",
    width: "100%",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    fontWeight: 'bold',
    // textAlign:'center'

} as React.CSSProperties;


export const shareButton = {
    height: "50px",
    width: "160px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    fontSize: '12px',
    fontWeight: 'bold'
} as React.CSSProperties;

export const requestButton = {
    height: "50px",
    width: "180px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    fontSize: '12px',
    fontWeight: 'bold'
} as React.CSSProperties;

export const dashboardButton = {
    height: "50px",
    width: "250px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: $mysaTeal
} as React.CSSProperties;


export const searchButton = {
    height: "50px",
    width: "220px",
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: $mysaYellow
} as React.CSSProperties;


export const searchButton2 = {
    height: "50px",
    width: "180px",
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: $mysaYellow
} as React.CSSProperties;


export const searchButton5 = {
    height: "40px",
    width: "300px",
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: $mysaMegenta,
    marginLeft: '425px',
    marginTop: '15px',
} as React.CSSProperties;

export const searchButton3 = {
    height: "50px",
    width: "285px",
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: $mysaYellow
} as React.CSSProperties;

export const selfCheckInButton = {
    height: "30px",
    width: "auto",
    borderRadius: '22px',
    paddingLeft: "30px",
    paddingRight: "30px",
    marginRight: '20px',
    fontSize: '12px',
    fontWeight: 'bold'
} as React.CSSProperties;

/////////////////////side bar icon style /////////////////////////
export const markerStyle = {
    width: '48px',
    height: '48px'
} as React.CSSProperties;

export const Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export const slideImageStyle = {
    textAlign: 'center' as const,
    height: '200px',
    background: '#F2F2F2',
    marginRight: '10px',
};

export const bookingScreen = {
    width: '100%',
    background: $mysaGrey,
    color: $mysaBlack,
    borderRadius: '30px',
    border: '1px solid #000000',
    boxShadow: '1px 1px',
    height: '400px'
    // width: 900px;
}



export const paymentScreen = {
    width: '100%',
    background: $mysaGrey,
    color: $mysaBlack,
    borderRadius: '30px',
    border: '1px solid #000000',
    boxShadow: '1px 1px',
    height: 'auto'
    // width: 900px;
}
export const travelerScreen = {
    width: '100%',
    background: $mysaGrey,
    color: $mysaBlack,
    borderRadius: '30px',
    border: 'none',
    marginBottom: '20px',
    // boxShadow: '1px 1px' 
}

export const BookMarkStyles: StylesConfig = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', borderRadius: '11px', padding: '3px' }),
    option: (styles, { isSelected }) => {

        return {
            ...styles,
            background: isSelected ? 'rgba(251, 201, 29, 0.43)' : 'white',
            color: 'black',
            borderRadius: '11px',
            ':hover': {
                background: 'rgba(251, 201, 29, 0.43)',
            }
        }
    },
    menu: (styles) => ({
        ...styles,
        textAlign: 'left',
        borderRadius: '11px',
        overflow: 'hidden',

    }),
    input: (styles) => ({ ...styles, }),
    singleValue: (styles) => ({
        ...styles,
        display: 'flex',

        ':before': {
            content: '" "',
            background: `url(${BookMarkIcon}) no-repeat`,

            padding: 0,
            marginTop: '0px',
            display: 'inline-block',

            width: '21px',
            height: '26px',
            marginRight: '10px'
        },
    }),

};

export const RoomTypeStyles: StylesConfig = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', borderRadius: '11px', padding: '3px' }),
    option: (styles, { isSelected }) => {

        return {
            ...styles,
            background: isSelected ? 'rgba(251, 201, 29, 0.43)' : 'white',
            color: 'black',
            borderRadius: '11px',
            ':hover': {
                background: 'rgba(251, 201, 29, 0.43)',
            }
        }
    },
    menu: (styles) => ({
        ...styles,
        textAlign: 'left',
        borderRadius: '11px',
        overflow: 'hidden',

    }),
    input: (styles) => ({ ...styles }),
    singleValue: (styles) => ({
        ...styles,
        display: 'flex',
        alignItems: 'center',

        ':before': {
            content: '" "',
            background: `url(${FilterIcon}) no-repeat`,

            padding: 0,
            marginTop: '0px',
            display: 'inline-block',

            width: '25px',
            height: '21px',
            marginRight: '10px'
        },
    }),

};



export const PriceStyles: StylesConfig = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', borderRadius: '11px', padding: '3px' }),
    option: (styles, { isSelected }) => {

        return {
            ...styles,
            background: isSelected ? 'rgba(251, 201, 29, 0.43)' : 'white',
            color: 'black',
            borderRadius: '11px',
            ':hover': {
                background: 'rgba(251, 201, 29, 0.43)',
            }
        }
    },
    menu: (styles) => ({
        ...styles,
        textAlign: 'left',
        borderRadius: '11px',
        overflow: 'hidden',

    }),
    input: (styles) => ({ ...styles }),
    singleValue: (styles) => ({
        ...styles,
        display: 'flex',
        alignItems: 'center',

        ':before': {
            content: '" "',
            background: `no-repeat`,

            padding: 0,
            marginTop: '0px',
            display: 'inline-block',

            width: '25px',
            height: '21px',
            marginRight: '10px'
        },
    }),

};