import { useStoreActions, useStoreState } from 'easy-peasy';
import { Checkbox } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import {  UserAccess } from '../../../../common/constants';
import { canBrandModuleWrite, formatPhoneNumber, getMailDomain } from '../../../../common/functions';
import { Row, Col, Form, Button, Spinner, Container } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { formButton } from '../../../../common/components-style';
import { BrandFinanceForm } from '../../../../common/interfaces';
import { PhoneNumberUtil } from 'google-libphonenumber';
// import { HelpModal } from '../../../common/HelpModal';
const plainOptions = ['Head Office', 'Individual Properties'];

const BrandFinance: React.FC<any> = ({ setFormOpen, brandId }): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const [title, setTitle] = useState('');
	const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<BrandFinanceForm>();
	const [brandFinanceId, setBrandFinanceId] = useState<string>();
	const [isHeadOffice, setIsHeadOffice] = useState<boolean>(false);
	const [brandData, setBrandData] = useState<any>();
	// const [userData, setUserData] = useState<any>({});
	const [brandFinanceList, setBrandFinanceList] = useState<any>({});
	const [invoiceIssuing, setInvoiceIssuing] = useState<Array<string>>([]);
	const [phone, setPhone] = useState<string>('');
	const [brandDomains, setBrandDomains] = useState<Array<string>>([]);
	const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);
	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { createBrandFinanceDetail, updateBrandFinanceDetail, getBrandFinanceDetailByBrandId, resetBrand, onloadBrand, getUserMapping } = useStoreActions<any>((actions) => ({
		createBrandFinanceDetail: actions.brand.createBrandFinanceDetail,
		updateBrandFinanceDetail: actions.brand.updateBrandFinanceDetail,
		getBrandFinanceDetailByBrandId: actions.brand.getBrandFinanceDetailByBrandId,
		resetBrand: actions.brand.resetBrand,
		onloadBrand: actions.brand.onloadBrand,
		getUserMapping: actions.user.getUserMapping,
		// onUpdateBrand: actions.brand.onUpdateBrand,

	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {
		createBrandFinanceSuccess,
		createBrandFinanceError,
		updateBrandFinanceSuccess,
		updateBrandFinanceError,
		getBrandFinanceDetailSuccess,
		getBrandFinanceDetailError,
		loadBrandSuccess,
		getUserMappingSuccess
	} = useStoreState<any>((state) => ({
		createBrandFinanceSuccess: state.brand.createBrandFinanceSuccess,
		createBrandFinanceError: state.brand.createBrandFinanceError,
		updateBrandFinanceSuccess: state.brand.updateBrandFinanceSuccess,
		updateBrandFinanceError: state.brand.updateBrandFinanceError,
		getBrandFinanceDetailSuccess: state.brand.getBrandFinanceDetailSuccess,
		getBrandFinanceDetailError: state.brand.getBrandFinanceDetailError,
		loadBrandSuccess: state.brand.loadBrandSuccess,
		getUserMappingSuccess: state.user.getUserMappingSuccess,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (brandId) {
			getBrandFinanceDetailByBrandId(brandId);
		}
	}, [brandId, getBrandFinanceDetailByBrandId]);


	useEffect(() => {
		if (brandId) {
			onloadBrand(brandId);
			getUserMapping({ brandId });
		}
	}, [brandId, getUserMapping, onloadBrand]);

	//////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getBrandFinanceDetailSuccess) {
			const brandFinance = getBrandFinanceDetailSuccess.data;
			if (brandFinance) {
				const params: any = {};
				params.emailAddressForSubmission = brandFinance.emailAddressForSubmission || '';
				params.nameOfContact = brandFinance.nameOfContact || '';
				params.phone = brandFinance.phone || '';
				params.invoiceIssuing = brandFinance.invoiceIssuing;
				setBrandFinanceList(params);
				setBrandFinanceId(brandFinance._id);
				setInvoiceIssuing(brandFinance.invoiceIssuing);
				let includeList: Array<string> = ['Head Office'];
				const isFilter = _.first(_.filter(brandFinance.invoiceIssuing, value => _.includes(includeList, value)));
				if (isFilter) {
					setIsHeadOffice(true);
				} else {
					setIsHeadOffice(false);
				}
			}
		}
		if (getBrandFinanceDetailError) {
			toast.error(getBrandFinanceDetailError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (loadBrandSuccess) {
			setBrandData(loadBrandSuccess.data);
			setTitle(loadBrandSuccess.data.name);
			setBrandDomains(loadBrandSuccess.data?.allowedDomains);
		}

		// if (getUserMappingSuccess) {
		// 	// const { result } = getUserMappingSuccess.data;
		// 	// const mappedData = _.map(result, (val) => {
		// 	// 	const firstUser = _.filter(val.brands, (a) => a.brandId === brandId).shift()?.isFirstUser || false
		// 	// 	if (val.user) {
		// 	// 		return {
		// 	// 			userId: val.user._id,
		// 	// 			firstName: val.user.firstName,
		// 	// 			lastName: val.user.lastName,
		// 	// 			email: val.user.email,
		// 	// 			phone: val.user.phone,
		// 	// 			mobile: val.user.mobile,
		// 	// 			position: val.user.position,
		// 	// 			data: val,
		// 	// 			isFirstUser: firstUser

		// 	// 		}
		// 	// 	}

		// 	// });
		// 	// let filterData = _.first(_.filter(mappedData, list => list?.isFirstUser === true));
		// 	// if (filterData) {
		// 	// 	setUserData(filterData);
		// 	// }
		// }
	}, [brandData, brandId, getBrandFinanceDetailError, getBrandFinanceDetailSuccess, getUserMappingSuccess, loadBrandSuccess]);

	///////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (createBrandFinanceSuccess) {
			toast.success("Brand finance created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			})
			resetBrand();
			setLoading(false);
			setEditEnable(false);
			getBrandFinanceDetailByBrandId(brandId);
		}

		if (updateBrandFinanceSuccess) {

			toast.success("Brand finance updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			})
			resetBrand();
			setLoading(false);
			setEditEnable(false);
			getBrandFinanceDetailByBrandId(brandId);
		}

		if (createBrandFinanceError) {
			toast.error(createBrandFinanceError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetBrand();
			setLoading(false);
		}

		if (updateBrandFinanceError) {
			toast.error(updateBrandFinanceError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetBrand();
			setLoading(false);
		}

	}, [brandId, createBrandFinanceError, createBrandFinanceSuccess, getBrandFinanceDetailByBrandId, resetBrand, updateBrandFinanceError, updateBrandFinanceSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onCheckChange = useCallback((list) => {
		let includeList: Array<string> = ['Head Office'];
		const isFilter = _.first(_.filter(list, value => _.includes(includeList, value)));
		if (isFilter) {
			setIsHeadOffice(true);
			setValue("emailAddressForSubmission", brandFinanceList ? brandFinanceList.emailAddressForSubmission : '');
			setValue("nameOfContact", brandFinanceList ? brandFinanceList.nameOfContact : '');
			setValue("phone", brandFinanceList?.phone ? brandFinanceList?.phone : '');
		} else {
			setIsHeadOffice(false);
			setValue("emailAddressForSubmission", '');
			setValue("nameOfContact", '');
			setValue("phone", '');
			// reset();
			setPhone('');

		}
		setInvoiceIssuing(list);
	}, [brandFinanceList, setValue]);


	const validatePhoneNumber = useCallback((inputNumber: string) => {
		let number = formatPhoneNumber(inputNumber)

		let valid = false;
		let isPossible = false;
		try {
			const phoneUtil = PhoneNumberUtil.getInstance();
			isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number))
			valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
		} catch (e) {
			valid = false;
		}

		if (isPossible && valid) {

			setValidPhoneNumber(true);
			return true;
		} else {
			setValidPhoneNumber(false);
			return false;
		}
	}, []);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const onSubmit = useCallback((values) => {

		if (isHeadOffice) {
			if (!validPhoneNumber) {
				toast.error('Please enter valid phone number!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}
		}


		if (values.emailAddressForSubmission) {
			// check domain
			const getDomainValidation = getMailDomain(values.emailAddressForSubmission, brandDomains);
			if (!getDomainValidation) {
				toast.error('Invalid brand domain. please check your e-mail address domain with brand domains', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}
		}

		setLoading(true);
		if (brandId) {
			if (brandFinanceId) {
				values.id = brandFinanceId;
				if (isHeadOffice) {
					if (!validatePhoneNumber(values?.phone)) {
						toast.error('Please enter valid phone number!', {
							position: toast.POSITION.BOTTOM_RIGHT,
							className: 'foo-bar'
						});
						return;
					}
				}


				const data = {
					id: brandFinanceId,
					invoiceIssuing: values.invoiceIssuing,
					emailAddressForSubmission: values.emailAddressForSubmission,
					nameOfContact: values.nameOfContact,
					phone: values.phone

				}

				updateBrandFinanceDetail(data);
			} else {
				// let brandStatus: any;
				// let brandFinanceData: any;

				// if (_.includes(values.invoiceIssuing, 'Head Office')) {
				// 	brandFinanceData = {
				// 		invoiceIssuing: values.invoiceIssuing,
				// 		emailAddressForSubmission: values.emailAddressForSubmission,
				// 		nameOfContact: values.nameOfContact,
				// 		phone: values.phone

				// 	}
				// } else if (_.includes(values.invoiceIssuing, 'Individual Properties')) {
				// 	brandFinanceData = {
				// 		invoiceIssuing: values.invoiceIssuing
				// 	}
				// }

				// if (isBrandComplete(brandData, userData, brandFinanceData)) {
				// 	brandStatus = BrandStatus.pending;
				// } else {
				// 	brandStatus = BrandStatus.incomplete;
				// }

				// onUpdateBrand({ id: brandId, brandStatus: brandStatus });
				const data = {
					brandId: brandId,
					invoiceIssuing: invoiceIssuing,
					emailAddressForSubmission: values.emailAddressForSubmission,
					nameOfContact: values.nameOfContact,
					phone: phone
				}
				createBrandFinanceDetail(data);
			}
		}

	}, [brandDomains, brandFinanceId, brandId, createBrandFinanceDetail, invoiceIssuing, isHeadOffice, phone, updateBrandFinanceDetail, validPhoneNumber, validatePhoneNumber]);


	const changeFormEditable = useCallback(() => {
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);



	////////////////////////////////////////////////////////////////////////////////////////////////////
	return (

		<div style={{marginTop:"-2%",paddingTop:"4px"}}>
			{
				title && <Row>
					<Col xs={9}><div className="content-title">{title}</div></Col>
					<Col xs={3} style={{textAlign:"right"}}>
					<div className='d-flex align-items-center justify-content-end gx-2'>
						{/* <HelpModal /> */}
					{
						!loading && !editEnable && brandId && canBrandModuleWrite(brandId, UserAccess.brand_access.details.code) && <Button id='edit-button' className="mb-0" style={formButton} onClick={changeFormEditable} >Edit</Button>
					}</div>
					</Col>
				</Row>
			}
			<Container fluid className='p-0' style={{marginTop:"65px"}}>
				<div className="jumbotron">
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Col md={1}></Col>
							<Col md={10}>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Invoice issuing (Payment of booking fees)</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">
											<Checkbox.Group value={invoiceIssuing} options={plainOptions}>
											</Checkbox.Group>
										</p>}
										{editEnable &&
											<Controller
												name="invoiceIssuing"
												defaultValue={brandFinanceList?.invoiceIssuing}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<Checkbox.Group onChange={(value) => {
														onCheckChange(value);
														field.onChange(value);
													}} value={invoiceIssuing} options={plainOptions}>
													</Checkbox.Group>
												}
											/>}
										{editEnable &&
											<ErrorMessage
												errors={errors}
												name="invoiceIssuing"
												render={({ message }) => <div className="invalid-feedback-custom">Please select invoice issuing!</div>} />}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && isHeadOffice && <span className='requiredMark'>*</span>} Email address for submission</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{brandFinanceList?.emailAddressForSubmission}</p>}
										{editEnable && <Form.Control type='email' className={`${errors.emailAddressForSubmission ? 'is-invalid' : ''}`} defaultValue={brandFinanceList?.emailAddressForSubmission} {...register("emailAddressForSubmission",
											{
												required: (isHeadOffice) ? true : false,
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: "Please input correct email address for submission!"
												}
											})} />}
										{editEnable && <div className="invalid-feedback">Please input email address for submission!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && isHeadOffice && <span className='requiredMark'>*</span>} Name of contact</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{brandFinanceList?.nameOfContact}</p>}
										{editEnable && <Form.Control className={`${errors.nameOfContact ? 'is-invalid' : ''}`} defaultValue={brandFinanceList?.nameOfContact} {...register("nameOfContact", { required: (isHeadOffice) ? true : false })} />}
										{editEnable && <div className="invalid-feedback">Please input name of contact!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && isHeadOffice && <span className='requiredMark'>*</span>} Phone</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && brandFinanceList?.phone && <p className="lead">{formatPhoneNumber(brandFinanceList?.phone)}</p>}
										{editEnable &&
											<Controller
												name="phone"
												control={control}
												defaultValue={brandFinanceList?.phone}
												rules={{
													required: (isHeadOffice) ? true : false,
													// maxLength: {
													// 	value: length,
													// 	message: "Maximum length is " + length + "."
													// },
													// minLength: {
													// 	value: length,
													// 	message: "Minimum length is " + length + "."
													// },
													// validate: () => {
													// 	if (isHeadOffice) {
													// 		if (validPhoneNumber) {
													// 			return true;
													// 		}
													// 		return Promise.reject(toast.error('Please enter valid phone number!', {
													// 			position: toast.POSITION.BOTTOM_RIGHT,
													// 			className: 'foo-bar'
													// 		}));
													// 	}
													// 	return;
													// }
													// validate: () => validPhoneNumber || 'Please enter valid number'
												}}
												render={({ field }) =>

													<PhoneInput
														isValid={(inputNumber: string) => {
															if (isHeadOffice) {
																return isHeadOffice ? validatePhoneNumber(inputNumber) : false;
															}
															return true;
														}}
														containerClass={`${errors.phone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); setPhone(phone); }} placeholder='' />}
											/>
										}
										{editEnable && <div className="invalid-feedback">Please input phone number!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!loading && !brandFinanceId && editEnable && brandId && canBrandModuleWrite(brandId, UserAccess.brand_access.details.code) &&
											<Button type="submit" id='approve-button' style={formButton}>Create</Button>
										}
										{!loading && brandFinanceId && editEnable && brandId && canBrandModuleWrite(brandId, UserAccess.brand_access.details.code) &&
											<Button type="submit" id='approve-button' style={formButton}>Update</Button>
										}										
										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={1}></Col>
						</Row>
					</Form>
				</div>
			</Container>
		</div>
	)
};

export default BrandFinance;