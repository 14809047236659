import { message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { formButton } from '../../../../../common/components-style';
import { UserAccess, UserGroups } from '../../../../../common/constants';
import { canCorporateProgrammeModuleWrite, getMailDomain } from '../../../../../common/functions';
import { BrandUserFormInput } from '../../../../../common/interfaces';
import DebounceSearch from '../../../../common/DebounceSearch'
import SweetAlert from 'react-bootstrap-sweetalert';



const ProgrammeUserForm: React.FC<any> = ({ clientId, selectedUser, close, reload, programmeId }): JSX.Element => {
  const { register, handleSubmit, setValue, reset,watch, formState: { errors }, control } = useForm<BrandUserFormInput>();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [programmerUserDomains, setProgrammerUserDomains] = useState<Array<string>>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const updateUser = useStoreActions<any>((actions) => actions.user.updateUser);
  const createUser = useStoreActions<any>((actions) => actions.user.createUser);
  const updateAccess = useStoreActions<any>((actions) => actions.user.updateAccess);
  const resetUser = useStoreActions<any>((actions) => actions.user.resetUser);
  const createUserMapping = useStoreActions<any>((actions) => actions.user.createUserMapping);
  const updateUserMapping = useStoreActions<any>((actions) => actions.user.updateUserMapping);
  const getCorporateProgrammeById = useStoreActions<any>((actions) => actions.client.getCorporateProgrammeById);

  const userUpdate = useStoreState<any>((state) => state.user.userUpdate);
  const userCreate = useStoreState<any>((state) => state.user.userCreate);
  const userCreateError = useStoreState<any>((state) => state.user.userCreateError);
  const userUpdateError = useStoreState<any>((state) => state.user.userUpdateError);
  const updateAccessSuccess = useStoreState<any>((state) => state.user.updateAccessSuccess);
  const createUserMappingSuccess = useStoreState<any>((state) => state.user.createUserMappingSuccess);
  const updateUserMappingSuccess = useStoreState<any>((state) => state.user.updateUserMappingSuccess);
  const getCorporateProgrammeByIdSuccess = useStoreState<any>((state) => state.client.getCorporateProgrammeByIdSuccess);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (programmeId) {
      getCorporateProgrammeById(programmeId);
    }
  }, [getCorporateProgrammeById, programmeId]);

  useEffect(() => {
    if (getCorporateProgrammeByIdSuccess) {
      setProgrammerUserDomains(getCorporateProgrammeByIdSuccess.data?.companyDomains);
    }
  }, [getCorporateProgrammeByIdSuccess]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    //user update success
    if (userUpdate) {
      updateAccess({
        id: selectedUser.arrId,
        userId: selectedUser.userId,
        role: UserGroups.user,
        accessType: "programmeRole"
      });
      reset();
      // reload();
      setLoading(false);
      toast.success("User updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      // resetUser();
    }
    // user create success
    if (userCreate) {
      if (userCreate.data.data) {

        // create mapping
        createUserMapping({
          userId: userCreate.data.user._id,
          email: userCreate.data.user.email,
          clientId,
          programmeId,
          role: watch('isAdmin') === true ? UserGroups.programme_admin : UserGroups.user,
        })
      } else {
        //update mapping
        updateUserMapping({
          userId: userCreate.data.user._id,
          client: {
            clientId,
          },
          corporateProgramme: {
            programmeId,
            role: watch('isAdmin') === true ? UserGroups.programme_admin : UserGroups.user,
          }

        })
      }
      reset();
      // resetUser();
      setLoading(false);
      // reload();
    }

    // update user mapping success 
    if (updateUserMappingSuccess || createUserMappingSuccess) {
      toast.success("User created successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      // resetUser();
      reload();
    }

    // user create error
    if (userCreateError) {
      toast.error(userCreateError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      // resetUser();
      setLoading(false);
    }

    // access update success
    if (updateAccessSuccess) {
      reload();
      // resetUser();
      setLoading(false)
      message.success('Permission updated successfully');
    }

    // user update error
    if (userUpdateError) {
      toast.error(userUpdateError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      setLoading(false);
      // resetUser();
      setLoading(false);
    }

  }, [reload, userUpdate, setLoading, userUpdateError, updateAccessSuccess, selectedUser, updateAccess, resetUser, userCreate, userCreateError, updateUserMapping, updateUserMappingSuccess, createUserMappingSuccess, createUserMapping, clientId, programmeId, reset, watch]);




  const onSubmit = useCallback((values) => {
    if (watch('isAdmin') === false) {
      setShowPopup(true);
  } else{

 
    // check domain
    const getDomainValidation = getMailDomain(email, programmerUserDomains);

    if (getDomainValidation) {
      setLoading(true);
      values.userGroup = UserGroups.user
      values.email = email;
      if (selectedUser) {
        delete values.isAdmin;
        updateUser(values);
      } else {
        values.telephone = values.phone;
        delete values.phone;
        delete values.isAdmin;
        createUser(values);
      }
    } else {
      toast.error('Invalid company domain. please check your e-mail address domain with company domains', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }

  }, [watch, email, programmerUserDomains, selectedUser, updateUser, createUser]);

  async function fetchUserList(email) {
    const param = email === '' ? null : email;
    return fetch(`${process.env.REACT_APP_API_URL}user-sv/users/search/byClient?search=${param}&clientId=${clientId}`)
      .then((response: any) => response.json())
      .then((body) =>
        body.data.map((user) => ({
          label: user.email,
          value: user.email,
          user
        })),
      );
  }

  return (


    <>
      <Container>
        <div className="jumbotron" >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={1}></Col>
              <Col md={10} style={{ marginLeft: '-200px' }}>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label><span className='requiredMark'>*</span> Email</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={selectedUser?.email}
                      rules={{
                        required: email ? false : true
                      }}
                      render={({ field }) =>

                        <DebounceSearch
                          value={email}
                          placeholder="search users"
                          fetchOptions={fetchUserList}
                          onChange={(newValue) => {
                            setEmail(newValue);
                            setValue("firstName", "");
                            setValue("lastName", "");
                          }}
                          disabled={selectedUser ? true : false}
                          onSelect={(newValue, options) => {
                            setEmail(newValue);
                            setValue("firstName", options.user.firstName);
                            setValue("lastName", options.user.lastName);
                          }}
                          style={{
                            width: '100%',
                          }}

                          containerClass={`${errors.email ? 'is-invalid' : ''}`}
                        />
                      }
                    />
                    {errors.email && <div className="invalid-feedback-custom">Please input email!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label><span className='requiredMark'>*</span> First name</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <Form.Control className={`${errors.firstName ? 'is-invalid' : ''}`} defaultValue={selectedUser?.firstName} {...register("firstName", { required: true })} />
                    <div className="invalid-feedback">Please input first name!</div>
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label> <span className='requiredMark'>*</span> Last name</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <Form.Control className={`${errors.lastName ? 'is-invalid' : ''}`} defaultValue={selectedUser?.lastName} {...register("lastName", { required: true })} />
                    <div className="invalid-feedback">Please input last name!</div>
                  </Col>
                </Row>
                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        <Form.Check type="checkbox" label="Set as Administrator" {...register("isAdmin", { required: false })} />
                                    </Col>
                                </Row>

                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!loading && canCorporateProgrammeModuleWrite(clientId, programmeId, UserAccess.programme_access.programmeUsers.code) &&
                      <Button type="submit" id='approve-button'  className="mr-4" style={formButton} >Create</Button>

                    }
                    {!loading &&
                      <Button id='edit-button' style={formButton} onClick={() => { close(); }}>Close</Button>
                    }
                    {
                      loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                    }
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Form>
        </div>
      </Container>
      <SweetAlert
                show={showPopup}
                warning
                showCancel={true}
                focusCancelBtn={true}
                showConfirm={false}
                // confirmBtnText="Yes activate it!"
                // confirmBtnBsStyle="danger"
                title="If you do not wish to provide Administrator permissions, please create a Company User, Booker or Traveller in the relevant section."
                onConfirm={() => {
                    setShowPopup(false);
                }}
                onCancel={() => {
                    setShowPopup(false);
                }}
            >
            </SweetAlert>
    </>
  )
}

export default ProgrammeUserForm;