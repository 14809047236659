/* eslint-disable react/jsx-no-target-blank */
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

const MYOQReadDoc: React.FC<any> = ({ data, evidence, onAnswer }): JSX.Element => {
  const [answer, setAnswer] = useState<any>('');

  useEffect(() => {
    setAnswer(data.answer?.answer === 'True' ? true : false);
  }, [data.answer?.answer]);

  const onChange = useCallback((e) => {
    const value = e.target.checked;
    if (data.answer?.answer !== 'True') {
      setAnswer(value);
      onAnswer(_.capitalize(value.toString()), data.id);
    }
  }, [data, onAnswer]);

  return (
    <div className="inline-block  m-5 w-full pr-10">
      <div className="bg-white p-5 rounded-lg relative">
        <p className="text-lg mb-3">{data?.description}</p>
        <a className="absolute right-5 top-3" href={`https://mysasure-bucket.s3.eu-west-2.amazonaws.com/${data.options}`} target="_blank" >
          <Button type="primary" className="rounded-xl mt-3" ghost={answer ? false : true}>
            <DownloadOutlined />
            Download
          </Button>
        </a>
        <div className="mt-16">
          <Checkbox onChange={onChange} checked={answer} >Click to confirm you have read the document.</Checkbox>
        </div>
      </div>
    </div>
  )
};

export default MYOQReadDoc;