import { Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import { setStorage } from '../../../common/storage';
import { ProgramCreateForm } from '../../../common/interfaces';

import { formButton } from '../../../common/components-style';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { formatPhoneNumber } from '../../../common/functions';
const { Option } = Select;

const ProgrammeDetailsForm: React.FC<any> = ({ programmeId, selectedClient }): JSX.Element => {
    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<ProgramCreateForm>();
    const [programData, setProgramData] = useState<any>();
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>();
    const [mysaPhone, setMysaPhone] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [programmeTypeList, setProgrammeTypeList] = useState<any>();
    const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getClientList, resetClient, getProgrammeType, getCorporateProgrammeById, updateCorporateProgramme, } = useStoreActions<any>((actions) => ({
        getProgrammeType: actions.client.getProgrammeType,
        getClientList: actions.client.getClientList,
        getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
        updateCorporateProgramme: actions.client.updateCorporateProgramme,
        resetClient: actions.client.resetClient,

    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {

        getProgrammeTypeSuccess,

        getCorporateProgrammeByIdSuccess,
        updateCorporateProgrammeSuccess,
        updateCorporateProgrammeError,


    } = useStoreState<any>((state) => ({

        getProgrammeTypeSuccess: state.client.getProgrammeTypeSuccess,
        getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
        updateCorporateProgrammeSuccess: state.client.updateCorporateProgrammeSuccess,
        updateCorporateProgrammeError: state.client.updateCorporateProgrammeError,

    }));


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        getClientList();
        getProgrammeType();
        // if (selectedClient) {
        //     setValue('clientId', selectedClient?.key);
        //     setValue('companyName', selectedClient?.companyName);
        //     setValue('programmeType', '');
        //     setValue('bookerDomains', '');
        //     setValue('companyDomains', '');
        // }
    }, [getClientList, getProgrammeType, selectedClient, setValue]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (programmeId) {
            getCorporateProgrammeById(programmeId);
        }
    }, [getCorporateProgrammeById, programmeId]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (getProgrammeTypeSuccess) {
            setProgrammeTypeList(getProgrammeTypeSuccess.data);
        }
    }, [getProgrammeTypeSuccess]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getCorporateProgrammeByIdSuccess) {
            const {
                programmeName,
                programmeType,
                clientContact,
                email,
                telephone,
                companyDomains,
                mysaOwner,
                mysaOwnerEmail,
                mysaOwnerTelephone,
                clientId,
                bookerDomains
            } = getCorporateProgrammeByIdSuccess.data;

            setProgramData(getCorporateProgrammeByIdSuccess?.data);
            setValue('programmeName', programmeName);
            setValue('programmeType', programmeType?._id);
            setValue('clientContact', clientContact);
            setValue('email', email);
            setValue('telephone', telephone);
            setValue('companyDomains', companyDomains);
            setValue('mysaOwner', mysaOwner);
            setValue('mysaOwnerEmail', mysaOwnerEmail);
            setValue('mysaOwnerTelephone', mysaOwnerTelephone);
            setValue('clientId', clientId?._id);
            setValue('companyName', clientId?.companyName);
            setValue('bookerDomains', bookerDomains);

            setStorage('myo_selected_programme', getCorporateProgrammeByIdSuccess?.data)
        }

    }, [getCorporateProgrammeByIdSuccess, setValue]);


    const scrollToTop = () => {
        // messagesRef?.current?.scrollIntoView(({ behavior: "smooth", block: "start", inline: "start" }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {




        if (updateCorporateProgrammeSuccess) {

            setLoading(false);
            toast.success("Corporate programme updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetClient();
            setEditEnable(false);
            scrollToTop();
            getCorporateProgrammeById(updateCorporateProgrammeSuccess?.data?._id);
        }
        if (updateCorporateProgrammeError) {
            setLoading(false);
            toast.error(updateCorporateProgrammeError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetClient();
            setEditEnable(false);
            getCorporateProgrammeById(programmeId);
        }


    }, [getCorporateProgrammeById, programmeId, resetClient, updateCorporateProgrammeError, updateCorporateProgrammeSuccess]);



    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderProgrammeType = useCallback(() => {
        if (programmeTypeList) {
            return programmeTypeList.map((val: any, key: number) => {
                return (
                    <Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
                )
            });
        }

    }, [programmeTypeList]);




    const validatePhoneNumber = useCallback((inputNumber: string) => {
        let number = formatPhoneNumber(inputNumber)

        let valid = false;
        let isPossible = false;
        try {
            const phoneUtil = PhoneNumberUtil.getInstance();
            isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number))
            valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
        } catch (e) {
            valid = false;
        }

        if (isPossible && valid) {
            setValidPhoneNumber(true);
            // validPhoneNumber = true;
            return true;
        } else {
            setValidPhoneNumber(false);
            return false;
        }
    }, [])

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onFinish = useCallback((values) => {
        setLoading(true);

        if (!validPhoneNumber) {
            setLoading(false);
            toast.error('Please enter valid phone number!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            return;
        }
        delete values.companyName;
        if (programmeId) {

            if (!validatePhoneNumber(values?.telephone)) {
                setLoading(false);
                toast.error('Please enter valid phone number!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                return;
            }

            if (!validatePhoneNumber(values?.mysaOwnerTelephone)) {
                setLoading(false);
                toast.error('Please enter valid phone number!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                return;
            }
            delete values.clientId;
            values.id = programmeId;
            if (phone) {
                values.telephone = phone;
            }

            if (mysaPhone) {
                values.mysaOwnerTelephone = mysaPhone;
            }

            updateCorporateProgramme(values);
        }

    }, [mysaPhone, phone, programmeId, updateCorporateProgramme, validPhoneNumber, validatePhoneNumber]);




    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <div className="ml-auto edit_btn_wpr" >
                {
                    !loading && !editEnable && <Button id='edit-button' style={formButton} onClick={() => { setEditEnable(true) }} >Edit</Button>
                }
            </div>
            <div>
                <div className="jumbotron" >
                    <Form onSubmit={handleSubmit(onFinish)}>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Company name</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{selectedClient?.companyName}</p>}
                                        {editEnable &&

                                            <Form.Control className={`${errors.clientId ? 'is-invalid' : ''}`} {...register("companyName", { required: true })} disabled={true} />
                                        }
                                        {editEnable && errors.clientId && <div className="invalid-feedback-custom">Please select a companyName!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Name of programme</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{programData?.programmeName}</p>}
                                        {editEnable && <Form.Control className={`${errors.programmeName ? 'is-invalid' : ''}`} {...register("programmeName", { required: true })} disabled={true} />}
                                        {editEnable && <div className="invalid-feedback">Please input programme name!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Programme type</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{programData?.programmeType?.name}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="programmeType"
                                                control={control}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field }) =>

                                                    <Select defaultValue={String(field.value)} disabled={true} onChange={(value) => field.onChange(value)} style={{ width: '100%' }}>
                                                        {renderProgrammeType()}
                                                    </Select>
                                                }
                                            />}
                                        {editEnable && errors.clientId && <div className="invalid-feedback-custom">Please select a programme type!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Chief Programme Admin</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{programData?.clientContact}</p>}
                                        {editEnable && <Form.Control className={`${errors.clientContact ? 'is-invalid' : ''}`} {...register("clientContact", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input name!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Email address</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{programData?.email}</p>}
                                        {editEnable && <Form.Control type='email' className={`${errors.email ? 'is-invalid' : ''}`} {...register("email", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input valid email!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Telephone no</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && programData?.telephone && <p className="lead">{formatPhoneNumber(programData?.telephone)}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="telephone"
                                                control={control}
                                                defaultValue={programData?.telephone}
                                                rules={{
                                                    required: true,

                                                }}
                                                render={({ field }) =>
                                                    <PhoneInput
                                                        placeholder=''
                                                        isValid={(inputNumber: string) => {

                                                            return validatePhoneNumber(
                                                                inputNumber
                                                            );
                                                        }}

                                                        containerClass={`${errors.telephone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); setPhone(phone); }} />}
                                            />
                                        }
                                        {editEnable && <div className="invalid-feedback">Please input telephone no!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Company domains</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">
                                            {
                                                programData?.companyDomains?.map((value, key: number) => {
                                                    return (
                                                        <span>{value}, </span>
                                                    );
                                                })
                                            }
                                        </p>}
                                        {editEnable &&
                                            <Controller
                                                name="companyDomains"
                                                defaultValue={programData?.companyDomains}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    pattern: {
                                                        value: /((ftp|http|https):\/\/)?([a-z0-9]+\.)?([a-z0-9][a-z0-9-]*)?((\.[a-z]{2,6})|(\.[a-z]{2,6})(\.[a-z]{2,6}))$/,
                                                        message: "Please enter valid domain name"
                                                    }
                                                }}
                                                render={({ field }) =>
                                                    <Select defaultValue={programData?.companyDomains} onChange={(value) => field.onChange(value)} mode="tags" className={`${errors.companyDomains ? 'is-invalid' : ''}`} options={[]} style={{ width: '100%' }}  >
                                                    </Select>
                                                }
                                            />}
                                        {editEnable && <div className="invalid-feedback">Please enter company domain!</div>}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>}  Booker domains</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">
                                            {
                                                programData?.bookerDomains?.map((value, key: number) => {
                                                    return (
                                                        <span>{value}, </span>
                                                    );
                                                })
                                            }
                                        </p>}
                                        {editEnable &&
                                            <Controller
                                                name="bookerDomains"
                                                defaultValue={programData?.bookerDomains}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    pattern: {
                                                        value: /((ftp|http|https):\/\/)?([a-z0-9]+\.)?([a-z0-9][a-z0-9-]*)?((\.[a-z]{2,6})|(\.[a-z]{2,6})(\.[a-z]{2,6}))$/,
                                                        message: "Please enter valid domain name"
                                                    }
                                                }}
                                                render={({ field }) =>
                                                    <Select defaultValue={programData?.bookerDomains} onChange={(value) => field.onChange(value)} mode="tags" className={`${errors.bookerDomains ? 'is-invalid' : ''}`} options={[]} style={{ width: '100%' }}  >
                                                    </Select>
                                                }
                                            />}
                                        {editEnable && <div className="invalid-feedback">Please enter booker domain!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Mysa owner</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{programData?.mysaOwner}</p>}
                                        {editEnable && <Form.Control disabled={true} className={`${errors.mysaOwner ? 'is-invalid' : ''}`} {...register("mysaOwner", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input owner name!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Mysa owner email</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{programData?.mysaOwnerEmail}</p>}
                                        {editEnable && <Form.Control disabled={true} type='email' className={`${errors.mysaOwnerEmail ? 'is-invalid' : ''}`} {...register("mysaOwnerEmail", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input valid email!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Mysa owner telephone</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && programData?.mysaOwnerTelephone && <p className="lead">{formatPhoneNumber(programData?.mysaOwnerTelephone)}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="mysaOwnerTelephone"
                                                control={control}
                                                defaultValue={programData?.mysaOwnerTelephone}
                                                rules={{
                                                    required: true,

                                                }}
                                                render={({ field }) =>
                                                    <PhoneInput
                                                        disabled={true}
                                                        placeholder=''
                                                        isValid={(inputNumber: string) => {

                                                            return validatePhoneNumber(
                                                                inputNumber
                                                            );
                                                        }}

                                                        containerClass={`${errors.telephone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); setMysaPhone(phone); }} />}
                                            />
                                        }
                                        {editEnable && <div className="invalid-feedback">Please input telephone no!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>


                                        {!loading && editEnable &&
                                            <Button type="submit" id='approve-button' style={formButton} > {programmeId ? 'Update' : 'Create'}</Button>
                                        }

                                        {
                                            loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
};

export default ProgrammeDetailsForm;