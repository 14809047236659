
import React, { useCallback } from 'react';
import {
   Container,
   Form,
   Row,
   Col,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "antd";

import Select from "react-select";
import BookMarkIcon from '../../../../images/icons/svg/bookmark.svg';
import FilterIcon from '../../../../images/icons/svg/filter.svg';
import CalenderIcon from '../../../../images/icons/svg/calender.svg';
import moment from 'moment';
import { BookingForm } from '../../../../common/interfaces';
import { BookMarkStyles, RoomTypeStyles } from '../../../../common/components-style';

const { RangePicker } = DatePicker;


const ConfirmationHeaderPage: React.FC<any> = ({ bookingParams }): JSX.Element => {
   const {
      formState: { errors },
      control,
   } = useForm<BookingForm>();
   // const [locationList, setLocationList] = useState<any>([]);
   // const [isManualBooking, setIsManualBooking] = useState<boolean>(false);
   // const [cityList, setCityList] = useState<any>([]);
   // const [cityName, setCityName] = useState<any>();
   // const [countryCode, setCountryCode] = useState<any>();
   // const [countryList, setCountryList] = useState<any>([]);
   // const [countryName, setCountryName] = useState<any>();
   // const [checkInDate, setCheckInDate] = useState<any>();
   // const [checkOutDate, setCheckOutDate] = useState<any>();
   const minDate = moment(new Date());
   // const [roomType, setRoomType] = useState<any>();
   // const [loading, setLoading] = useState<boolean>(false);
   // const [locationId, setLocationId] = useState<any>();

   // const bedList = [
   //    { label: "All", value: 0 },
   //    { label: "1 Bed", value: 1 },
   //    { label: "2 Beds", value: 2 },
   //    { label: "3 Beds ", value: 3 },
   //    { label: "4 Beds", value: 4 },
   // ];

   ////////////////////////////////////////////////////////////////////////////////////////////////////

   // const onAccommodationChange = useCallback(
   //    (acc) => {
   //       if (acc) {
   //          setRoomType(acc.value);

   //       }
   //    },
   //    [bookingParams, locationId, watch]
   // );



   // const renderCorporateLocationNew = useCallback(() => {
   //    if (locationList) {
   //       return locationList.map((acc: any, key: number) => {
   //          return {
   //             key: key,
   //             value: `${acc._id}`,
   //             label: acc.locationName,
   //             loc: acc,
   //          };
   //       });
   //    }
   // }, [locationList]);

   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   // const onLocationChange = useCallback(
   //    (val) => {
   //       if (val.value === "null") {
   //          setIsManualBooking(true);
   //       } else {
   //          setIsManualBooking(false);
   //          let location = _.first(
   //             _.filter(locationList, (location) => location._id === val.value)
   //          );
   //          if (location) {
   //             const buildingListTemp: any = [];
   //             _.map(location.building, (element) =>
   //                buildingListTemp.push({
   //                   id: element._id,
   //                   name: element.propertyName,
   //                   position: {
   //                      lat: Number(element.latitude),
   //                      lng: Number(element.longitude),
   //                   },
   //                   address: element.address1,
   //                })
   //             );
   //          }
   //       }
   //    },
   //    [locationList]
   // );

   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   // const renderCountry = useCallback(() => {
   //    if (countryList) {
   //       return countryList.map((acc: any, key: number) => {
   //          return {
   //             key: key,
   //             value: `${acc._id?.countryCode}`,
   //             label: acc._id?.country,
   //             country: acc._id,
   //          };
   //       });
   //    }
   // }, [countryList]);

   // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   // const changeHandler = useCallback(
   //    (val) => {
   //       if (val) {
   //          setCountryName(val.country);
   //          setCountryCode(val.value);
   //          getAllPropertyCities(val.value);
   //       }
   //    },
   //    [getAllPropertyCities]
   // );

   // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   // const renderCity = useCallback(() => {
   //    if (cityList) {
   //       return cityList.map((acc: any, key: number) => {
   //          return {
   //             key: key,
   //             value: `${acc._id}`,
   //             label: acc._id,
   //          };
   //       });
   //    }
   // }, [cityList]);

   // //////////////////////////////////////////////////////////////////////////////////////////////////////////
   // const onCityChange = useCallback((val) => {
   //    setCityName(val.value);
   // }, []);


   /////////////////////////////////////////////////////////////////////////////////////////////////////////////
   // const onFromDateChange = useCallback((dates, dateStrings) => {
   //    setCheckInDate(dateStrings[0]);
   //    setCheckOutDate(dateStrings[1]);
   // }, []);



   ///////////////////////////////////////////////////////////////////////////////////////////////////////////
   const disabledFromDate = useCallback(
      (d) => {
         if (d == null) {
            return null;
         }
         return (
            minDate != null && !d.isAfter(minDate) && d.isBefore(minDate, "day")
         );
      },
      [minDate]
   );


   //////////////////////////////////////////////////////////////////////////////////////////////////////////
   return (
      <div >
         <Container style={{ maxWidth: "1675px" }}>
            <div>
               {/* <Row
                  style={{
                     position: "fixed",
                     left: "6.9vw",
                     top: "70px",
                     width: "100%",
                     padding: '5vh 1.5vw',
                     zIndex: 20,
                     backgroundColor: "#fff",
                  }}
               > */}
               {/* <Row style={{
                    marginTop:'80px'
                }} > */}
               <Row>

                  <Form>
                     <Row className="formSpace">
                        <Col
                           md={
                              bookingParams?.isProgramme === "MYO Plus" ||
                                 bookingParams?.isProgramme === "Specified only"
                                 ? 3
                                 : 2
                           }
                        >
                           {(bookingParams?.isProgramme === "MYO Plus" ||
                              bookingParams?.isProgramme === "Specified only") && (
                                 <Controller
                                    name="location"
                                    // defaultValue={bookingParams?.state?.query?.locationId}
                                    control={control}
                                    render={({ field }) => (
                                       <div
                                          style={{
                                             width: "321px",
                                             height: "44px",
                                          }}
                                       >
                                          <Select
                                             components={{ DropdownIndicator: () => null }}
                                             isSearchable
                                             // isDisabled={true}
                                             placeholder={
                                                <div className="flex justify-start ">
                                                   <div style={{ marginRight: "6%" }}>
                                                      <img
                                                         style={{ width: "21px", height: "26px" }}
                                                         src={BookMarkIcon}
                                                         alt="book mark "
                                                      />
                                                   </div>
                                                   <div className="font-light">
                                                      Select company location
                                                   </div>
                                                </div>
                                             }
                                             // options={renderCorporateLocationNew()}
                                             // defaultValue={{
                                             //    value: bookingParams?.state?.query?.locationId,
                                             //    label: bookingParams?.state?.query?.locationName,
                                             // }}
                                             className={`${errors.location ? "is-invalid" : ""
                                                } font-light`}
                                             // onChange={onLocationChange}
                                             styles={BookMarkStyles}
                                          />
                                       </div>
                                    )}
                                 />
                              )}

                           {bookingParams?.isProgramme === "MYO" && (
                              <Controller
                                 name="country"
                                 control={control}
                                 defaultValue={bookingParams?.countryCode}
                                 render={({ field }) => (
                                    <div
                                       style={{
                                          width: "221px",
                                          height: "44px",
                                          // marginLeft: '240px',
                                       }}
                                    >
                                       <Select
                                          components={{ DropdownIndicator: () => null }}
                                          // defaultValue={bookingParams?.countryCode}
                                          // defaultValue={{
                                          //    value: bookingParams?.state?.query?.countryCode,

                                          //    label: bookingParams?.state?.query?.country?.country,
                                          // }}
                                          isSearchable
                                          // isDisabled={true}
                                          placeholder={
                                             <div className="flex justify-start ">
                                                <div style={{ marginRight: "6%" }}>
                                                   <img
                                                      style={{ width: "21px", height: "26px" }}
                                                      src={BookMarkIcon}
                                                      alt="book mark "
                                                   />
                                                </div>
                                                <div className="font-light">Select Country</div>
                                             </div>
                                          }
                                          // options={renderCountry()}
                                          // onChange={changeHandler}
                                          className={`${errors.country ? "is-invalid" : ""
                                             } font-light`}
                                          styles={BookMarkStyles}
                                       />
                                    </div>
                                 )}
                              />
                           )}
                           {/* {isManualBooking && <Controller
                    name="otherLocation"
                    defaultValue={''}
                    control={control}
                    render={({ field }) =>
                      <div style={{
                        width: '321px',
                      }}>
                        <GooglePlaces className="form-control" onPlaceSelect={(location) => setLocationData(location)} />
                      </div>
                    }
                  />} */}
                        </Col>
                        {(bookingParams?.isProgramme === "MYO") && (
                           <Col md={2}>
                              <Controller
                                 name="city"
                                 // defaultValue={bookingParams?.state?.query?.placeId}
                                 control={control}
                                 render={({ field }) => (
                                    <div
                                       style={{
                                          width: "221px",
                                          height: "44px",
                                          // marginLeft: '240px',
                                       }}
                                    >
                                       <Select
                                          components={{ DropdownIndicator: () => null }}
                                          // defaultValue={{
                                          //    value: bookingParams?.state?.query?.placeId,
                                          //    label: bookingParams?.state?.query?.placeId,
                                          // }}
                                          isSearchable
                                          // isDisabled={true}
                                          placeholder={
                                             <div className="flex justify-start ">
                                                <div style={{ marginRight: "6%" }}>
                                                   <img
                                                      style={{ width: "21px", height: "26px" }}
                                                      src={BookMarkIcon}
                                                      alt="book mark "
                                                   />
                                                </div>
                                                <div className="font-light">Select city</div>
                                             </div>
                                          }
                                          // options={renderCity()}
                                          className={`${errors.city ? "is-invalid" : ""
                                             } font-light`}
                                          // onChange={onCityChange}
                                          styles={BookMarkStyles}
                                       />
                                    </div>
                                 )}
                              />
                           </Col>
                        )}

                        {/* style={{ marginLeft: isManualBooking ? '-200px' : '-500px' }} */}
                        <Col
                           md={
                              bookingParams?.isProgramme === "MYO Plus" ||
                                 bookingParams?.isProgramme === "Specified only"
                                 ? 3
                                 : 2
                           }
                        >
                           {(bookingParams?.isProgramme === "MYO Plus" ||
                              bookingParams?.isProgramme === "Specified only" ||
                              bookingParams?.isProgramme === "MYO") && (
                                 <Controller
                                    name="fromDate"
                                    // defaultValue={[
                                    //    bookingParams?.from
                                    //    ,
                                    //    bookingParams?.end,
                                    // ]}
                                    control={control}
                                    render={({ field }) => (
                                       <div
                                          style={{
                                             borderRadius: "11px",
                                             // marginLeft: '280px',
                                             width: "271px",
                                             height: "44px",
                                             display: "flex",
                                             alignItems: "center",
                                             border: "1px solid hsl(0, 0%, 70%)",
                                             paddingLeft: "10px",
                                          }}
                                       >
                                          <div>
                                             <img
                                                style={{ width: "26px", height: "25.32px" }}
                                                src={CalenderIcon}
                                                alt=""
                                             />
                                          </div>
                                          <div>
                                             <RangePicker
                                                // autoFocus={false}
                                                bordered={false}
                                                className="font-light"
                                                suffixIcon={<div></div>}
                                                style={{
                                                   border: "none",
                                                   fontWeight: 300,
                                                   fontSize: "20px",
                                                }}
                                                placeholder={["Check-in", "Check-out"]}
                                                // onChange={onFromDateChange}
                                                disabledDate={disabledFromDate}
                                                disabled={true}
                                             // defaultValue={[
                                             //    moment(
                                             //       new Date(
                                             //          bookingParams?.from

                                             //       ),
                                             //       "YYYY-MM-DD"
                                             //    ),
                                             //    moment(
                                             //       new Date(bookingParams?.end

                                             //       ),
                                             //       "YYYY-MM-DD"
                                             //    ),
                                             // ]}
                                             />
                                          </div>
                                       </div>
                                    )}
                                 />
                              )}
                        </Col>
                        <Col md={3}>
                           {(bookingParams?.isProgramme === "MYO Plus" ||
                              bookingParams?.isProgramme === "Specified only" ||
                              bookingParams?.isProgramme === "MYO") && (
                                 <Controller
                                    name="accommodation"
                                    defaultValue={""}
                                    control={control}
                                    render={({ field }) => (
                                       <div
                                          style={{
                                             marginLeft: "50px",
                                             width: "271px",
                                          }}
                                       >
                                          <Select
                                             styles={RoomTypeStyles}
                                             className={`${errors.accommodation ? "is-invalid " : ""
                                                } font-light`}
                                             // options={bedList}
                                             // onChange={onAccommodationChange}
                                             isSearchable={true}
                                             // isDisabled={true}
                                             placeholder={
                                                <div className="flex justify-start ">
                                                   <div style={{ marginRight: "6%" }}>
                                                      <img
                                                         style={{ width: "21px", height: "26px" }}
                                                         src={FilterIcon}
                                                         alt="book mark "
                                                      />
                                                   </div>
                                                   <div className="font-light">
                                                      Accommodation Type
                                                   </div>
                                                </div>
                                             }
                                             components={{ DropdownIndicator: () => null }}
                                          />
                                       </div>
                                    )}
                                 />
                              )}
                        </Col>
                     </Row>
                  </Form>
               </Row>
            </div>
         </Container>
      </div>

   )
};

export default ConfirmationHeaderPage;