import { message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import calendarIcon from '../../../../../../../images/icons/svg/outside/icon-calendar.svg';
import checkOutIcon from '../../../../../../../images/icons/svg/outside/icon-check-out.svg';
import checkInIcon from '../../../../../../../images/icons/svg/outside/icon-check-in.svg';
import arrowIcon from '../../../../../../../images/icons/svg/outside/icon-arrrow.png';

import moment from 'moment-timezone';
import { daysBetween } from '../../../../../../../common/functions';
import { travelerScreen } from '../../../../../../../common/components-style';
import { useParams } from 'react-router-dom';

const BookingConfirmationPage: React.FC<any> = ({ location }): JSX.Element => {

    const [bookingList, setBookingList] = useState<any>();
    const [liveBooking, setLiveBooking] = useState<any>();
    const { id }: any = useParams();

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getAvailabilityAndRateByPropertyIdAndInventoryId,
        getBookingById,
        sendOperatorBookingConfirmationEmail,
        sendBookerBookingConfirmationEmail,
        sendTravelerBookingConfirmationEmail
    } = useStoreActions<any>((actions) => ({
        getAvailabilityAndRateByPropertyIdAndInventoryId: actions.booking.getAvailabilityAndRateByPropertyIdAndInventoryId,
        getBookingById: actions.booking.getBookingById,
        sendOperatorBookingConfirmationEmail: actions.booking.sendOperatorBookingConfirmationEmail,
        sendBookerBookingConfirmationEmail: actions.booking.sendBookerBookingConfirmationEmail,
        sendTravelerBookingConfirmationEmail: actions.booking.sendTravelerBookingConfirmationEmail
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess,
        getAvailabilityAndRateByPropertyIdAndInventoryIdError,
        getBookingByIdSuccess,
        getBookingByIdError,
    } = useStoreState<any>((state) => ({
        getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess: state.booking.getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess,
        getAvailabilityAndRateByPropertyIdAndInventoryIdError: state.booking.getAvailabilityAndRateByPropertyIdAndInventoryIdError,
        getBookingByIdSuccess: state.booking.getBookingByIdSuccess,
        getBookingByIdError: state.booking.getBookingByIdError,
        sendBookingConfirmationEmailSuccess: state.booking.sendBookingConfirmationEmailSuccess,
        sendBookingConfirmationEmailError: state.booking.sendBookingConfirmationEmailError,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const data: any = {};
        // if (location?.state?.query) {

        //     data.cacheKey = location?.state?.query?.cacheKey;
        //     data.propertyId = location?.state?.query?.propertyId;
        //     data.inventoryId = location?.state?.query?.inventoryId;
        //     data.hashGet = location?.state?.query?.hashGet;
        //     if (location?.state?.query?.requestBookingId) {
        //         data.requestBookingId = location?.state?.query?.requestBookingId;
        //         data._reqGroupId = location?.state?.query?._reqGroupId;
        //         data._reqBrandId = location?.state?.query?._reqBrandId;
        //         data._reqPropertyId = location?.state?.query?._reqPropertyId;
        //         data._reqInventoryId = location?.state?.query?._reqInventoryId;
        //         data.checkInDate = location?.state?.query?.checkInDate;
        //         data.checkOutDate = location?.state?.query?.checkOutDate;
        //     }
        //     getAvailabilityAndRateByPropertyIdAndInventoryId(data);
        // }



        if (id) {

            const payload = {
                bookingID: id
            };

            getBookingById(payload);
        }



    }, [getBookingById, id, location]);



    //////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess) {
            if (getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data) {
                const book: Array<object> = [];
                const image: Array<object> = [];
                const bed: Array<object> = [];

                book.push(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data);

                _.map(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyImage.image, element => {
                    image.push(element)
                })
                _.map(getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyInventory.bedType, element => {
                    bed.push(element.name)
                });

                setBookingList(book);

            }
        }

        if (getBookingByIdSuccess?.data) {
            const data = getBookingByIdSuccess?.data;
            setLiveBooking(data);
            //   need to call booking confirmation email


            const book: Array<object> = [];
            book.push(data);
            setBookingList(book);

        }
        //     if (data?.bookerUserId?.email === data?.travelerId?.email) {
        //         const payload = {
        //             bookingId: data?.liveBookingId,
        //             emailType: '',
        //             firstName: data?.travelerId?.firstName,
        //             lastName: data?.travelerId?.lastName,
        //             propertyName: data?.propertyId?.propertyName,
        //             brandName: data?.brandId?.name,
        //             propertyLocation: data?.propertyId?.city,
        //             clientName: data?.clientId?.companyName,
        //             // toEmail:'bavathuja2011@gmail.com',
        //             toEmail: data?.bookerUserId?.email
        //         }

        //         sendTravelerBookingConfirmationEmail(payload);

        //     } else {
        //         const payload = {
        //             bookingId: data?.liveBookingId,
        //             emailType: '',
        //             firstName: data?.travelerId?.firstName,
        //             lastName: data?.travelerId?.lastName,
        //             propertyName: data?.propertyId?.propertyName,
        //             brandName: data?.brandId?.name,
        //             propertyLocation: data?.propertyId?.city,
        //             clientName: data?.clientId?.companyName,
        //             // toEmail:'bavathuja2011@gmail.com',
        //             toEmail: data?.travelerId?.email
        //         }

        //         sendTravelerBookingConfirmationEmail(payload);
        //         if (isBooker()) {
        //             const payload = {
        //                 bookingId: data?.liveBookingId,
        //                 emailType: 'Booker',
        //                 firstName: data?.travelerId?.firstName,
        //                 lastName: data?.travelerId?.lastName,
        //                 propertyName: data?.propertyId?.propertyName,
        //                 brandName: data?.brandId?.name,
        //                 propertyLocation: data?.propertyId?.city,
        //                 clientName: data?.clientId?.companyName,
        //                 bookerName: data?.bookerUserId?.firstName,
        //                 // toEmail:'bavathuja2011@gmail.com',
        //                 toEmail: data?.bookerUserId?.email
        //             }

        //             sendBookerBookingConfirmationEmail(payload);
        //         }
        //         if (!isBooker()) {
        //             const payload = {
        //                 bookingId: data?.liveBookingId,
        //                 emailType: 'Corporator',
        //                 firstName: data?.travelerId?.firstName,
        //                 lastName: data?.travelerId?.lastName,
        //                 propertyName: data?.propertyId?.propertyName,
        //                 brandName: data?.brandId?.name,
        //                 propertyLocation: data?.propertyId?.city,
        //                 clientName: data?.clientId?.companyName,
        //                 bookerName: data?.bookerUserId?.firstName,
        //                 // toEmail:'bavathuja2011@gmail.com',
        //                 toEmail: data?.bookerUserId?.email
        //             }

        //             sendBookerBookingConfirmationEmail(payload);
        //         }


        //     }

        //     const payload = {
        //         bookingId: data?.liveBookingId,
        //         firstName: data?.bookerUserId?.firstName,
        //         lastName: data?.bookerUserId?.lastName,
        //         propertyName: data?.propertyId?.propertyName,
        //         brandName: data?.brandId?.name,
        //         propertyLocation: data?.propertyId?.city,
        //         clientName: data?.clientId?.companyName,
        //         // toEmail:'bavathuja2011@gmail.com',
        //         toEmail: data?.propertyId?.systemNotificationEmail
        //     }

        //     sendOperatorBookingConfirmationEmail(payload);
        //  }

        if (getAvailabilityAndRateByPropertyIdAndInventoryIdError) {
            message.error(getAvailabilityAndRateByPropertyIdAndInventoryIdError.message);
        }

        if (getBookingByIdError) {
            message.error(getBookingByIdError.message);
        }
    }, [getBookingByIdSuccess, getBookingByIdError, getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess, getAvailabilityAndRateByPropertyIdAndInventoryIdError, sendBookerBookingConfirmationEmail, sendOperatorBookingConfirmationEmail, sendTravelerBookingConfirmationEmail]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderBooking = useCallback(() => {
        if (bookingList) {
            return bookingList.map((acc: any, key: number) => {
                return (

                    <div key={key}>
                        <div>
                            {/* <Row>
                        <ConfirmationHeaderPage bookingParams={location?.state?.query} />
                     </Row> */}
                            {/* <Row style={{
                                marginTop: '80px'
                            }} >
                                <hr style={{
                                    width: '90vw'
                                }} />
                            </Row> */}
                            {/* <Row style={{
                        marginTop: '80px',
                        width: '95%',
                        marginLeft: '5px'
                     }} >
                        <Divider plain></Divider>
                     </Row> */}
                            <Row>
                                <Col md={12}  >
                                    <Card style={{ border: 'none' }}>
                                        <Container>
                                            <div className=' justify-center items-center' style={{ textAlign: 'center' }}>
                                                <div>
                                                    <div className="" style={{ fontSize: "24px", fontWeight: 'bold', lineHeight: '33px', }}>{'Booking number '} {liveBooking?.liveBookingId}</div>
                                                </div>

                                                <div>
                                                    <div className="" style={{ fontSize: "52px", fontWeight: 'bold', lineHeight: '60px', }}>{acc?.brandId?.name} {' has confirmed your booking'}</div>
                                                </div>

                                                <div>
                                                    <div className="" style={{ fontSize: "20px", lineHeight: '33px', }}> {`A confirmation has been sent to ${liveBooking?.travelerId?.email}`}</div>
                                                </div>
                                                {/* <Row>
                                       <Col md={4}>
                                       </Col>
                                       <Col md={4} className='' >
                                          <Image src={acc.property.brandId.image} alt="avatar" style={{ marginTop: '25px', height: '165px', aspectRatio: '1/1', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >
                                          </Image>
                                       </Col>
                                       <Col md={4}>
                                       </Col>
                                    </Row> */}
                                                <div
                                                    className=""
                                                    style={{
                                                        backgroundPosition: 'center',
                                                        marginBottom: '10px'
                                                    }}
                                                >
                                                    <img src={acc.brandId.image} alt="brand" style={{ display: 'inline' }} />
                                                </div>
                                            </div>
                                        </Container>
                                    </Card>
                                </Col>
                            </Row>
                        </div>


                        <div className=' '>
                            <Row >
                                <Col md={6} id="about-brand" style={{ textAlign: 'center' }}>
                                    <Card style={travelerScreen}>
                                        <Container>
                                            <Card.Title style={{ fontSize: '30px', fontWeight: 'bold', }}>
                                                {/* <Row>
                                       <Col md={1}>
                                          <Image src={calendarIcon} />
                                       </Col>
                                       <Col md={11} style={{ fontSize: "26px", fontWeight: 'bold', lineHeight: '32px', marginLeft: '-215px' }}>
                                          Booking Details
                                       </Col>
                                    </Row> */}
                                                <Row style={{ marginLeft: '-50px' }}>
                                                    <div className="checkinDetails flex align-items-center mb-2 " >
                                                        <img
                                                            className="icon"
                                                            src={calendarIcon}
                                                            alt=""
                                                            style={{ width: "40px", height: "40px" }}
                                                        />
                                                        <div className="" style={{ fontSize: "22px", lineHeight: "32px" }}>
                                                            <span
                                                                style={{
                                                                    flex: "0 0 150px",
                                                                }}
                                                            >{"   Booking Details"}</span>

                                                        </div>
                                                    </div>
                                                </Row>

                                            </Card.Title>
                                            {/* <Row className="">
                                    <div style={{ marginBottom: '10px' }}>
                                       <img className='icon' src={calendarIcon} alt="" />
                                       <div className="" style={{ fontSize: "26px", marginTop: '-40px', fontWeight: 'bold', lineHeight: '32px', marginLeft: '-460px' }}>Booking Details</div>
                                    </div>
                                 </Row> */}

                                            <Row style={{ marginLeft: '-128px' }}>
                                                <Form>
                                                    <Row>
                                                        <Col md={1}></Col>
                                                        <Col md={10}>
                                                            <Row className='formSpace'>
                                                                <Col md={4} style={{ textAlign: 'left' }}>
                                                                    <p> Booking number</p>
                                                                </Col>
                                                                <Col md={8} style={{ textAlign: 'left' }}>
                                                                    <p className="confirmation">{liveBooking?.liveBookingId}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={1}></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={1}></Col>
                                                        <Col md={10}>
                                                            <Row className='formSpace'>
                                                                <Col md={4} style={{ textAlign: 'left' }}>
                                                                    <p>Lead Guest</p>
                                                                </Col>
                                                                <Col md={8} style={{ textAlign: 'left' }}>
                                                                    <p className="confirmation">{`${liveBooking?.travelerId?.firstName} ${liveBooking?.travelerId?.lastName} `}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={1}></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={1}></Col>
                                                        <Col md={10}>
                                                            <Row className='formSpace'>
                                                                <Col md={4} style={{ textAlign: 'left' }}>
                                                                    <p>Property</p>
                                                                </Col>
                                                                <Col md={8} style={{ textAlign: 'left' }}>
                                                                    <p className="confirmation">{acc?.propertyId?.propertyName}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={1}></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={1}></Col>
                                                        <Col md={10}>
                                                            <Row className='formSpace'>
                                                                <Col md={4} style={{ textAlign: 'left' }}>
                                                                    <p> Apartment Type</p>
                                                                </Col>
                                                                <Col md={8} style={{ textAlign: 'left' }}>
                                                                    <p className="confirmation">{acc.inventoryInfo.accommodationConfiguration}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={1}></Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={1}></Col>
                                                        <Col md={10}>
                                                            <Row className='formSpace'>
                                                                <Col md={4} style={{ textAlign: 'left' }}>
                                                                    <p> Management</p>
                                                                </Col>
                                                                <Col md={8} style={{ textAlign: 'left' }}>
                                                                    <p className="confirmation"> {acc?.brandId?.name}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={1}></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={1}></Col>
                                                        <Col md={10}>
                                                            <Row className='formSpace'>
                                                                <Col md={4} style={{ textAlign: 'left' }}>
                                                                    <p>No of Guest</p>
                                                                </Col>
                                                                <Col md={8} style={{ textAlign: 'left' }}>
                                                                    <p className="confirmation">{liveBooking?.inventoryId?.noOfGuests} guests</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={1}></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={1}></Col>
                                                        <Col md={10}>
                                                            <Row className='formSpace'>
                                                                <Col md={4} style={{ textAlign: 'left' }}>
                                                                    <p>Date of stay</p>
                                                                </Col>
                                                                <Col md={8} style={{ textAlign: 'left' }}>
                                                                    <p className="confirmation">{`${moment(liveBooking?.checkInDate).format('dddd DD MMM')} - ${moment(liveBooking?.checkOutDate).format('dddd DD MMM')}`}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={1}></Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={1}></Col>
                                                        <Col md={10}>
                                                            <Row className='formSpace'>
                                                                <Col md={4} style={{ textAlign: 'left' }}>
                                                                    <p>Duration</p>
                                                                </Col>
                                                                <Col md={8} style={{ textAlign: 'left' }}>
                                                                    <p className="confirmation">{daysBetween(new Date(liveBooking?.checkInDate), new Date(liveBooking?.checkOutDate))} nights</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={1}></Col>
                                                    </Row>



                                                    <Row>
                                                        <Col md={1}></Col>
                                                        <Col md={10}>
                                                            <Row className='formSpace'>
                                                                <Col md={4} style={{ textAlign: 'left' }}>
                                                                    <p>Rate per night</p>
                                                                </Col>
                                                                <Col md={8} style={{ textAlign: 'left' }}>
                                                                    <p className="confirmation">{liveBooking?.propertyId?.currency} {liveBooking?.bookingRateAndPolicy?.dailyRate[0]?.rate}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={1}></Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={1}></Col>
                                                        <Col md={10}>
                                                            <Row className='formSpace'>
                                                                <Col md={4} style={{ textAlign: 'left' }}>
                                                                    <p>Total cost of stay</p>
                                                                </Col>
                                                                <Col md={8} style={{ textAlign: 'left' }}>
                                                                    <p className="confirmation">{liveBooking?.propertyId?.currency} {liveBooking?.bookingRateAndPolicy?.finalRate}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={1}></Col>
                                                    </Row>
                                                </Form>
                                            </Row>
                                        </Container>
                                    </Card>
                                    {/* <div style={{ marginLeft: '-50px' }}>




                           </div> */}
                                </Col>
                                <Col id="about-brand" style={{ marginInlineStart: '25px', textAlign: 'center' }}>
                                    <div style={{ marginLeft: '-50px' }}>
                                        <Card style={travelerScreen}>
                                            <Container>
                                                {/* <Row className="">
                                    <div style={{ marginBottom: '10px' }}>
                                       <img className='icon' src={checkInIcon} alt="" />
                                       <div className="" style={{ fontSize: "26px", marginTop: '-40px', fontWeight: 'bold', lineHeight: '32px', marginLeft: '-380px' }}>Check-in and policies</div>
                                    </div>
                                 </Row> */}
                                                <Card.Title style={{ fontSize: '30px', fontWeight: 'bold', }}>
                                                    {/* <Row>
                                          <Col md={1}>
                                             <Image src={checkInIcon} />
                                          </Col>
                                          <Col md={11} style={{ fontSize: "26px", fontWeight: 'bold', lineHeight: '32px', marginLeft: '-185px' }}>
                                             Check-in and policies
                                          </Col>
                                       </Row> */}

                                                    <div className="checkinDetails flex align-items-center mb-2 ml-5">
                                                        <img
                                                            className="icon"
                                                            src={checkInIcon}
                                                            alt=""
                                                            style={{ width: "40px", height: "40px" }}
                                                        />
                                                        <div className="" style={{ fontSize: "22px", lineHeight: "32px" }}>
                                                            <span
                                                                style={{
                                                                    flex: "0 0 150px",
                                                                }}
                                                            >{"  Check-in and policies"}</span>

                                                        </div>
                                                    </div>
                                                </Card.Title>

                                                <div id="confirmation-checkIn">
                                                    <div className="checkinDetails flex align-items-center mt-2 mb-2 ml-5">
                                                        <img
                                                            className="icon"
                                                            src={acc.propertyInfo?.propertyCheckInProcess?.checkInProcess
                                                                ?.iconLocation}
                                                            alt=""
                                                            style={{ width: "40px", height: "40px" }}
                                                        />
                                                        <div className="d-flex align-items-center flex-grow flex-wrap" style={{ fontSize: "22px", lineHeight: "32px" }}>


                                                            <span className="font-bold ml-2" style={{ textAlign: 'initial' }}>
                                                                {acc.propertyInfo?.propertyCheckInProcess?.checkInProcess?.name}
                                                            </span>
                                                            <br />
                                                            <span className="font-bold ml-3" style={{ textAlign: 'initial' }}>
                                                                {acc.propertyInfo?.propertyCheckInProcess?.additionalInformation}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <Row>
                                          <Col md={4}>
                                             <img className='icon' src={renderIcon(acc.propertyCheckInProcess.checkInProcess._id)} alt="" />
                                          </Col>
                                          <Col md={8} style={{ marginLeft: '-170px', marginTop: '5px', marginInlineStart: '-155px' }}>
                                             <div ><span className="" style={{ fontSize: "26px", fontWeight: 'bold', lineHeight: '35px' }}>{acc.propertyCheckInProcess?.checkInProcess?.name}</span>
                                                <br />
                                                <span style={{ marginInlineStart: '-372px' }} >{acc.propertyCheckInProcess?.additionalInformation}</span> </div>
                                          </Col>
                                       </Row> */}

                                                    {/* <Row>
                                          <Col md={4}>
                                             <img className='icon' src={checkInIcon} alt="" />
                                          </Col>
                                          <Col md={8} style={{ marginLeft: '-170px', marginTop: '0px', marginInlineStart: '-255px' }}>
                                             <div ><span className="" style={{ fontSize: "26px", lineHeight: '55px' }}>{'Check-in time  '} <span className="font-bold ml-3">{acc.propertyCheckInProcess?.checkInTime}</span></span>
                                             </div>
                                          </Col>
                                       </Row> */}
                                                    <div className="checkinDetails flex align-items-center mb-2 ml-5">
                                                        <img
                                                            className="icon"
                                                            src={checkInIcon}
                                                            alt=""
                                                            style={{ width: "40px", height: "40px" }}
                                                        />
                                                        <div className="d-flex align-items-center flex-grow flex-wrap" style={{ fontSize: "22px", lineHeight: "32px" }}>
                                                            <span
                                                                style={{
                                                                    flex: "0 0 150px",
                                                                }}
                                                            >{" Check-in time"}</span>
                                                            <span className="font-bold ml-3">
                                                                {/* {checkInDate} */}
                                                            </span>
                                                            <span className="font-bold ml-3">
                                                                {acc.propertyInfo?.propertyCheckInProcess?.checkInTime}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <Row>
                                          <Col md={4}>
                                             <img className='icon' src={checkOutIcon} alt="" />
                                          </Col>
                                          <Col md={8} style={{ marginLeft: '-170px', marginTop: '0px', marginInlineStart: '-255px' }}>
                                             <div ><span className="" style={{ fontSize: "26px", lineHeight: '55px' }}>{'Check-out time  '} <span className="font-bold ml-3">{acc.propertyCheckInProcess?.checkOutTime}</span></span>
                                                <br />
                                             </div>
                                          </Col>
                                       </Row> */}
                                                    <div className="checkOutDetails flex align-items-center ml-5">
                                                        <img
                                                            className="icon"
                                                            src={checkOutIcon}
                                                            alt=""
                                                            style={{ width: "40px", height: "40px" }}
                                                        />
                                                        <div className="d-flex align-items-center flex-grow flex-wrap" style={{ fontSize: "22px", lineHeight: "32px" }}>
                                                            <span className="ml-2"

                                                            >{"  Check-out time"}</span>
                                                            <span className="font-bold ml-3">
                                                                {/* {checkOutDate} */}
                                                            </span>
                                                            <span className="font-bold ml-3">
                                                                {acc.propertyInfo?.propertyCheckInProcess?.checkOutTime}
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <Row>
                                                    <div className=" mt-2 ml-8" style={{ textAlign: 'left' }} >
                                                        <div className="" style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '22px' }} > {'Cancellation Policy: '} </div>
                                                        <div style={{ fontSize: '16px', lineHeight: '22px', }} >
                                                            {'Cancellation notice period '}{acc.bookingRateAndPolicy?.cancellationPolicy?.cutOffDates}  {' nights'}
                                                        </div>
                                                        <div style={{ fontSize: '16px', lineHeight: '22px', }}>
                                                            {'Cancellation charge '} {acc.bookingRateAndPolicy?.cancellationPolicy?.penaltyDates} {' nights stay'}
                                                        </div>
                                                    </div>
                                                </Row>
                                            </Container>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </div >
                        <div>
                            <Row>
                                <Col md={6}>
                                    <Row className="">
                                        <div style={{}}>
                                            <img className='icon2' src={arrowIcon} alt="" />
                                            <div className="" style={{ fontSize: "26px", marginTop: '-40px', fontWeight: 'bold', lineHeight: '32px', marginLeft: '50px' }}>Next Steps</div>
                                        </div>
                                    </Row>

                                    <Row>
                                        <div className='mt-5'>
                                            <p>
                                                Lorem ipsum dolor sit amet, eum quem praesent id, ea vix adipiscing voluptatum delicatissimi. Te eos quaeque democritum dissentiet, et cibo mundi partiendo est. Vide platonem per ea. Ad eum porro sapientem maiestatis.
                                            </p>
                                        </div>
                                    </Row>

                                    <Row>
                                        <div className='mt-2'>
                                            <div >
                                                In the meantime if you have any queries regarding the booking or need to get in contact with {acc.property?.brandId?.name} please see below or call +{liveBooking?.brandId?.headOfficeTelephone}
                                            </div>
                                        </div>
                                    </Row>

                                    {/* <Row>
                              <div className='mt-2'>
                                 <div style={{ fontSize: "18px", fontWeight: 'bold', lineHeight: '25px', }}>
                                    Send a message to {acc.property?.brandId?.name}
                                 </div>
                              </div>
                           </Row>


                           <Row>
                              <Col >
                                 <div className="">
                                    <Form >

                                       <Row className='formSpace'>
                                          <Col md={6} style={{ textAlign: 'center' }}>
                                             <Form.Control as="textarea" rows={5} />
                                          </Col>
                                       </Row>
                                       <Row className='formSpace'>
                                          <Col md={6} style={{ textAlign: 'right' }}>
                                             <Button id='routing-button' style={viewButton} >Send now</Button>
                                          </Col>
                                       </Row>
                                    </Form>
                                 </div>
                              </Col>
                           </Row> */}
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>

                        </div>
                    </div >
                )
            });
        }
    }, [bookingList, liveBooking]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            {renderBooking()}
        </div >
    )
};

export default BookingConfirmationPage;