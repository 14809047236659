import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { $mysaBlack, $mysaYellow } from '../../../../common/components-style';
import { toast } from 'react-toastify';
import { getStorage } from '../../../../common/storage';
import { useForm } from 'react-hook-form';
import { GenericStandardRateForm } from '../../../../common/interfaces';
import RateSeasonalForm from './RateSeasonalForm';
import { CommonAnswer } from '../../../../common/constants';



/////////////////////////////// details block style////////////////////////////////////////
const headStyle = { fontSize: '17px', fontWeight: 'bold', padding: '12%', marginTop: '5%', width: '200px' } as React.CSSProperties;
const headBorderStyle = { borderLeft: '2px solid #C4C4C4', marginRight: '3%' } as React.CSSProperties;
const bodyTextStyleOne = { fontSize: '18px', padding: '5%', textAlign: 'center', marginTop: '6%', paddingTop: '10%', marginBottom: '11%' } as React.CSSProperties;

const formButton = {
    height: "50px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    marginRight: '20px',
    border: '1px solid #ffffff',
    background: $mysaYellow,
    color: $mysaBlack
} as React.CSSProperties;

const RfpRateForm: React.FC<any> = ({ inventory, backToProperty, onReviewRate, onSubmit, property }): JSX.Element => {
    ///////////////// form initialize //////////
    const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm<GenericStandardRateForm>();
    //////////// local state manage ///////////////////
    const [editEnable, setEditEnable] = useState<boolean>(true);
    const [enableRateForm, setEnableRateForm] = useState<string>('');
    const [enableExtraSeasonalRateForm, setEnableExtraSeasonalRateForm] = useState<string>('');
    const [isAmendRate, setIsAmendRate] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [rateData, setRateData] = useState<any>([]);
    const [seasonalRateData, setSeasonalRateData] = useState<any>([]);
    const [isRateHere, setIsRateHere] = useState<boolean>(false);
    const [isSeasonalHere, setIsSeasonalHere] = useState<boolean>(false);
    const [isYesCheck, setIsYesCheck] = useState<boolean>(false);
    const [isNoCheck, setIsNoCheck] = useState<boolean>(false);
    const [currency, setCurrency] = useState<any>();

    ////////////// local storage access ////////////////
    const myoSelectedBrand = getStorage("myo_selected_my_rfp_brand");
    const myoSelectedMyRfp = getStorage("myo_selected_my_rfp");
    const myoSelectedMyRfpProperty = getStorage("myo_selected_my_rfp_property");

    /////////////////////////// global action ////////////////////////
    const { getBrandRFPRateByInventoryId, createUpdatePropertyStandardRateForRFP, getProperty } = useStoreActions<any>((actions) => ({
        getBrandRFPRateByInventoryId: actions.rfpProcess.brandRFPRateByInventoryId,
        createUpdatePropertyStandardRateForRFP: actions.rfpProcess.createUpdatePropertyStandardRateForRFP,
        getProperty: actions.property.getProperty,
    }));

    /////////////////////////// global state ////////////////////////
    const { getBrandRFPRateByInventoryIdSuccess, getPropertySuccess, getBrandRFPRateByInventoryIdError, getCreateUpdatePropertyStandardRateForRFPSuccess, getCreateUpdatePropertyStandardRateForRFPError } = useStoreState<any>((state) => ({
        getBrandRFPRateByInventoryIdSuccess: state.rfpProcess.getBrandRFPRateByInventoryIdSuccess,
        getBrandRFPRateByInventoryIdError: state.rfpProcess.getBrandRFPRateByInventoryIdError,
        getCreateUpdatePropertyStandardRateForRFPSuccess: state.rfpProcess.getCreateUpdatePropertyStandardRateForRFPSuccess,
        getCreateUpdatePropertyStandardRateForRFPError: state.rfpProcess.getCreateUpdatePropertyStandardRateForRFPError,
        getPropertySuccess: state.property.getPropertySuccess,
    }));

    //////////////////////////////////
    const callRfps = useCallback(() => {
        const payload = {
            rfpProcessId: myoSelectedMyRfp.rfpId,
            brandId: myoSelectedBrand,
            propertyId: myoSelectedMyRfpProperty.id,
            propertyInventoryId: inventory?.key
        };

        getBrandRFPRateByInventoryId(payload);
    }, [getBrandRFPRateByInventoryId, inventory?.key, myoSelectedBrand, myoSelectedMyRfp.rfpId, myoSelectedMyRfpProperty.id])

    ////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (property?.id) {
            getProperty(property?.id)
        }
    }, [getProperty, property?.id]);


    useEffect(() => {
        if (getPropertySuccess) {
            const data = getPropertySuccess.data;
            setCurrency(data.currency);

        }


    }, [getPropertySuccess]);
    ///////////////// initialize api calls //////////////////
    useEffect(() => {
        setIsSeasonalHere(false);
        reset();
        callRfps();
    }, [callRfps, reset]);

    //////////// check apis responses /////////////////
    useEffect(() => {
        if (getBrandRFPRateByInventoryIdSuccess?.data) {
            setIsRateHere(true);
            setEditEnable(true);
            setRateData(getBrandRFPRateByInventoryIdSuccess?.data);
            /**
             * first form
             */
            setValue('standardRateOneTo7', rateData?.corporateRate?.standardOldRate?.oneTo7);
            setValue('standardRateEightTo14', rateData?.corporateRate?.standardOldRate?.eightTo14);
            setValue('standardRateFifteenTo28', rateData?.corporateRate?.standardOldRate?.fifteenTo28);
            setValue('standardRateTwentyNineTo90', rateData?.corporateRate?.standardOldRate?.twentyNineTo90);
            setValue('standardRateNinetyOnePlus', rateData?.corporateRate?.standardOldRate?.ninetyOnePlus);
            setValue('cancellationPolicyOneTo7CutOffDates', rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDatesOld);
            setValue('cancellationPolicyEightTo14CutOffDates', rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDatesOld);
            setValue('cancellationPolicyFifteenTo28CutOffDates', rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDatesOld);
            setValue('cancellationPolicyTwentyNineTo90CutOffDates', rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDatesOld);
            setValue('cancellationPolicyNinetyOnePlusCutOffDates', rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDatesOld);
            setValue('cancellationPolicyOneTo7PenaltyDates', rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDatesOld);
            setValue('cancellationPolicyEightTo14PenaltyDates', rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDatesOld);
            setValue('cancellationPolicyFifteenTo28PenaltyDates', rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDatesOld);
            setValue('cancellationPolicyTwentyNineTo90PenaltyDates', rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDatesOld);
            setValue('cancellationPolicyNinetyOnePlusPenaltyDates', rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDatesOld);

            ////////////////// seasonal component //////////
            if (rateData?.corporateRate?.seasonalRate) {
                const seasonalComponent = rateData?.corporateRate?.seasonalRate.map((val, index) => {
                    return (
                        <div key={index}>
                            <RateSeasonalForm seasonalRate={val} inventory={inventory} callRfps={callRfps} isSeasonalHere={isSeasonalHere} />
                        </div>
                    );
                });
                setSeasonalRateData(seasonalComponent);
            }
            setIsYesCheck(false);
            setIsNoCheck(false);
        }

        if (getBrandRFPRateByInventoryIdError) {
            setIsRateHere(false);
            setEditEnable(false);
        }

        if (getCreateUpdatePropertyStandardRateForRFPSuccess) {
            const payload = {
                rfpProcessId: myoSelectedMyRfp.rfpId,
                brandId: myoSelectedBrand,
                propertyId: myoSelectedMyRfpProperty.id,
                propertyInventoryId: inventory.key
            };
            getBrandRFPRateByInventoryId(payload);
            toast.success("Rate submitted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setIsYesCheck(false);
            setIsNoCheck(false);
            setLoading(false);
        }

        if (getCreateUpdatePropertyStandardRateForRFPError) {
            setLoading(false);
            toast.error(getCreateUpdatePropertyStandardRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [callRfps, getBrandRFPRateByInventoryId, getBrandRFPRateByInventoryIdError, getBrandRFPRateByInventoryIdSuccess?.data, getCreateUpdatePropertyStandardRateForRFPError, getCreateUpdatePropertyStandardRateForRFPSuccess, inventory, isSeasonalHere, myoSelectedBrand, myoSelectedMyRfp.rfpId, myoSelectedMyRfpProperty.id, rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDatesOld, rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDatesOld, rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDatesOld, rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDatesOld, rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDatesOld, rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDatesOld, rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDatesOld, rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDatesOld, rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDatesOld, rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDatesOld, rateData?.corporateRate?.seasonalRate, rateData?.corporateRate?.standardOldRate?.eightTo14, rateData?.corporateRate?.standardOldRate?.fifteenTo28, rateData?.corporateRate?.standardOldRate?.ninetyOnePlus, rateData?.corporateRate?.standardOldRate?.oneTo7, rateData?.corporateRate?.standardOldRate?.twentyNineTo90, setValue]);

    ///////////////////// rate create and update form ///////////////
    const onFinish = (value) => {
        const payload = {
            rfpProcessId: myoSelectedMyRfp.rfpId,
            brandId: myoSelectedBrand,
            propertyId: myoSelectedMyRfpProperty.id,
            propertyInventoryId: inventory.key,
            brandRfpId: myoSelectedMyRfp?.key,
            cityName: myoSelectedMyRfpProperty?.clientLocation,
            corporateRate: {
                standardRate: {
                    oneTo7: Number(value.standardRateOneTo7),
                    eightTo14: Number(value.standardRateEightTo14),
                    fifteenTo28: Number(value.standardRateFifteenTo28),
                    twentyNineTo90: Number(value.standardRateTwentyNineTo90),
                    ninetyOnePlus: Number(value.standardRateNinetyOnePlus),
                },
                standardOldRate: {
                    oneTo7: Number(value.standardRateOneTo7),
                    eightTo14: Number(value.standardRateEightTo14),
                    fifteenTo28: Number(value.standardRateFifteenTo28),
                    twentyNineTo90: Number(value.standardRateTwentyNineTo90),
                    ninetyOnePlus: Number(value.standardRateNinetyOnePlus),
                },
                cancellationPolicy: {
                    oneTo7: {
                        cutOffDates: Number(value.cancellationPolicyOneTo7CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyOneTo7PenaltyDates),
                        cutOffDatesOld: Number(value.cancellationPolicyOneTo7CutOffDates),
                        penaltyDatesOld: Number(value.cancellationPolicyOneTo7PenaltyDates)
                    },
                    eightTo14: {
                        cutOffDates: Number(value.cancellationPolicyEightTo14CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyEightTo14PenaltyDates),
                        cutOffDatesOld: Number(value.cancellationPolicyEightTo14CutOffDates),
                        penaltyDatesOld: Number(value.cancellationPolicyEightTo14PenaltyDates)
                    },
                    fifteenTo28: {
                        cutOffDates: Number(value.cancellationPolicyFifteenTo28CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyFifteenTo28PenaltyDates),
                        cutOffDatesOld: Number(value.cancellationPolicyFifteenTo28CutOffDates),
                        penaltyDatesOld: Number(value.cancellationPolicyFifteenTo28PenaltyDates)
                    },
                    twentyNineTo90: {
                        cutOffDates: Number(value.cancellationPolicyTwentyNineTo90CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyTwentyNineTo90PenaltyDates),
                        cutOffDatesOld: Number(value.cancellationPolicyTwentyNineTo90CutOffDates),
                        penaltyDatesOld: Number(value.cancellationPolicyTwentyNineTo90PenaltyDates)
                    },
                    ninetyOnePlus: {
                        cutOffDates: Number(value.cancellationPolicyNinetyOnePlusCutOffDates),
                        penaltyDates: Number(value.cancellationPolicyNinetyOnePlusPenaltyDates),
                        cutOffDatesOld: Number(value.cancellationPolicyNinetyOnePlusCutOffDates),
                        penaltyDatesOld: Number(value.cancellationPolicyNinetyOnePlusPenaltyDates)
                    }
                }
            }
        }
        createUpdatePropertyStandardRateForRFP(payload);
        setLoading(true);
    }


    ///////////////////// rate create and update form ///////////////
    const onFinishAmendedRate = (value) => {
        const payload = {
            rfpProcessId: myoSelectedMyRfp.rfpId,
            brandId: myoSelectedBrand,
            propertyId: myoSelectedMyRfpProperty.id,
            propertyInventoryId: inventory.key,
            brandRfpId: myoSelectedMyRfp?.key,
            cityName: myoSelectedMyRfpProperty?.clientLocation,
            corporateRate: {
                standardRate: {
                    oneTo7: Number(value.standardRateOneTo7),
                    eightTo14: Number(value.standardRateEightTo14),
                    fifteenTo28: Number(value.standardRateFifteenTo28),
                    twentyNineTo90: Number(value.standardRateTwentyNineTo90),
                    ninetyOnePlus: Number(value.standardRateNinetyOnePlus),
                },
                standardOldRate: {
                    oneTo7: Number(rateData?.corporateRate?.standardOldRate?.oneTo7),
                    eightTo14: Number(rateData?.corporateRate?.standardOldRate?.eightTo14),
                    fifteenTo28: Number(rateData?.corporateRate?.standardOldRate?.fifteenTo28),
                    twentyNineTo90: Number(rateData?.corporateRate?.standardOldRate?.twentyNineTo90),
                    ninetyOnePlus: Number(rateData?.corporateRate?.standardOldRate?.ninetyOnePlus),
                },

                cancellationPolicy: {
                    oneTo7: {
                        cutOffDates: Number(value.cancellationPolicyOneTo7CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyOneTo7PenaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDatesOld)
                    },
                    eightTo14: {
                        cutOffDates: Number(value.cancellationPolicyEightTo14CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyEightTo14PenaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDatesOld)
                    },
                    fifteenTo28: {
                        cutOffDates: Number(value.cancellationPolicyFifteenTo28CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyFifteenTo28PenaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDatesOld)
                    },
                    twentyNineTo90: {
                        cutOffDates: Number(value.cancellationPolicyTwentyNineTo90CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyTwentyNineTo90PenaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDatesOld)
                    },
                    ninetyOnePlus: {
                        cutOffDates: Number(value.cancellationPolicyNinetyOnePlusCutOffDates),
                        penaltyDates: Number(value.cancellationPolicyNinetyOnePlusPenaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDatesOld)
                    }
                }
            }
        }

        createUpdatePropertyStandardRateForRFP(payload);
        setLoading(true);
    }

    //////////// seasonal form enable part //////////
    const enableSeasonalForm = useCallback((value) => {
        setEnableRateForm(value);
    }, []);

    //////////// extra seasonal form enable part //////////
    const extraEnableSeasonalForm = useCallback((value) => {
        setEnableExtraSeasonalRateForm(value);
    }, []);

    const onAmendRate = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setIsSeasonalHere(true);
        } else {
            setIsSeasonalHere(false);
        }
        setIsAmendRate(value);
    }, []);



    return (
        <>
            {inventory?.status !== 'review' &&
                <Row className='mt-5'>
                    <div style={{ fontSize: '16px' }}> Please complete your proposed standard rate below and ‘Save’. <br></br>
                        Once you have completed this, you will be asked if you would also like to propose any  seasonal rate variations.</div>
                </Row>}
            {inventory?.status === 'review' &&
                <Row className='mt-5'>
                    <div style={{ fontSize: '16px' }}> Submitted rates
                    </div>
                </Row>}
            {inventory?.status !== 'review' && <div className='mt-5'>
                <Form onSubmit={handleSubmit(onFinish)}>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Rate {'(' + currency + ')'}</div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}>
                                <span style={headBorderStyle}></span><span >1-7 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 8-14 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 15-28 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 29-89 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 90+ nights</span>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '4%', marginBottom: '10px' }}>Standard Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardOldRate?.oneTo7} min={0} className={`${errors.standardRateOneTo7 ? 'is-invalid' : ''}`} {...register("standardRateOneTo7", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardOldRate?.eightTo14} min={0} className={`${errors.standardRateEightTo14 ? 'is-invalid' : ''}`} {...register("standardRateEightTo14", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardOldRate?.fifteenTo28} min={0} className={`${errors.standardRateFifteenTo28 ? 'is-invalid' : ''}`} {...register("standardRateFifteenTo28", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardOldRate?.twentyNineTo90} min={0} className={`${errors.standardRateTwentyNineTo90 ? 'is-invalid' : ''}`} {...register("standardRateTwentyNineTo90", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardOldRate?.ninetyOnePlus} min={0} className={`${errors.standardRateNinetyOnePlus ? 'is-invalid' : ''}`} {...register("standardRateNinetyOnePlus", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation notice (nights)</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyOneTo7CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7CutOffDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyEightTo14CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14CutOffDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyFifteenTo28CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28CutOffDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyTwentyNineTo90CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90CutOffDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyNinetyOnePlusCutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusCutOffDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation charge (nights)</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyOneTo7PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7PenaltyDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyEightTo14PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14PenaltyDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyFifteenTo28PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28PenaltyDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyTwentyNineTo90PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90PenaltyDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyNinetyOnePlusPenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusPenaltyDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col md={12}>
                            {!loading && !editEnable && <Button type="submit" style={formButton} ><b>Save</b></Button>}
                            {!loading && editEnable && <Button style={formButton} onClick={() => {
                                setEditEnable(false);
                            }}><b>Edit</b></Button>}
                            {loading && <Spinner animation="border" variant="warning" />}
                        </Col>
                    </Row>
                </Form>
            </div>}

            {/* submitted rates*/}

            {inventory?.status === 'review' && <div className='mt-5'>
                <Form onSubmit={handleSubmit(onFinish)}>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}>
                                <span style={headBorderStyle}></span><span >1-7 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 8-14 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 15-28 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 29-89 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 90+ nights</span>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '4%', marginBottom: '10px' }}>Standard Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.oneTo7} min={0} className={`${errors.standardRateOneTo7 ? 'is-invalid' : ''}`} {...register("standardRateOneTo7", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.eightTo14} min={0} className={`${errors.standardRateEightTo14 ? 'is-invalid' : ''}`} {...register("standardRateEightTo14", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.fifteenTo28} min={0} className={`${errors.standardRateFifteenTo28 ? 'is-invalid' : ''}`} {...register("standardRateFifteenTo28", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.twentyNineTo90} min={0} className={`${errors.standardRateTwentyNineTo90 ? 'is-invalid' : ''}`} {...register("standardRateTwentyNineTo90", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.ninetyOnePlus} min={0} className={`${errors.standardRateNinetyOnePlus ? 'is-invalid' : ''}`} {...register("standardRateNinetyOnePlus", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation notice (nights)</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDates} min={0} className={`${errors.cancellationPolicyOneTo7CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7CutOffDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDates} min={0} className={`${errors.cancellationPolicyEightTo14CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14CutOffDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDates} min={0} className={`${errors.cancellationPolicyFifteenTo28CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28CutOffDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates} min={0} className={`${errors.cancellationPolicyTwentyNineTo90CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90CutOffDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates} min={0} className={`${errors.cancellationPolicyNinetyOnePlusCutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusCutOffDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation charge (nights)</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDates} min={0} className={`${errors.cancellationPolicyOneTo7PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7PenaltyDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDates} min={0} className={`${errors.cancellationPolicyEightTo14PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14PenaltyDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDates} min={0} className={`${errors.cancellationPolicyFifteenTo28PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28PenaltyDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates} min={0} className={`${errors.cancellationPolicyTwentyNineTo90PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90PenaltyDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates} min={0} className={`${errors.cancellationPolicyNinetyOnePlusPenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusPenaltyDates", { required: true })} disabled={editEnable} />
                            </div>
                        </Col>
                    </Row>
                    {/* <Row style={{ marginTop: '20px' }}>
                        <Col md={12}>
                            {!loading && !editEnable && <Button type="submit" style={formButton} ><b>Save</b></Button>}
                            {!loading && editEnable && <Button style={formButton} onClick={() => {
                                setEditEnable(false);
                            }}><b>Edit</b></Button>}
                            {loading && <Spinner animation="border" variant="warning" />}
                        </Col>
                    </Row> */}
                </Form>
            </div>}
            {
                isRateHere && rateData?.corporateRate?.seasonalRate.length === 0 && inventory?.status !== 'review' && <Row className='mt-5'>
                    <div style={{ fontSize: '16px' }}>Do you wish to propose any seasonal rates?
                        <span style={{ marginLeft: '2%' }}>
                            <Form.Check
                                inline
                                value={'Yes'}
                                label={'Yes'}
                                type="radio"
                                name='need_seasonal_rate'
                                checked={isYesCheck}
                                onChange={(value) => {
                                    enableSeasonalForm(value.target.value);
                                    setIsYesCheck(true);
                                }}
                            />
                            <Form.Check
                                inline
                                value={'No'}
                                label={'No'}
                                type="radio"
                                checked={isNoCheck}
                                name='need_seasonal_rate'
                                onChange={(value) => {
                                    enableSeasonalForm(value.target.value);
                                    backToProperty();
                                    setIsNoCheck(true);
                                }}
                            />
                        </span>
                    </div>


                </Row>

            }


            {isRateHere && rateData?.corporateRate?.seasonalRate.length === 0
                && enableRateForm === 'Yes' && inventory?.status !== 'review' && <RateSeasonalForm seasonalRate={''} inventory={inventory} callRfps={callRfps} isSeasonalHere={isSeasonalHere} />
            }

            {
                isRateHere && rateData?.corporateRate?.seasonalRate.length !== 0 && seasonalRateData

            }

            {
                isRateHere && rateData?.corporateRate?.seasonalRate.length !== 0
                && inventory?.status !== 'review' && <Row className='mt-5'>
                    <div style={{ fontSize: '16px' }}>Do you wish to propose any further seasonal rates?
                        <span style={{ marginLeft: '2%' }}>
                            <Form.Check
                                inline
                                value={'Yes'}
                                label={'Yes'}
                                type="radio"
                                checked={isYesCheck}
                                name='need_seasonal_rate'
                                onChange={(value) => {
                                    setIsYesCheck(true);
                                    extraEnableSeasonalForm(value.target.value);
                                }}
                            />
                            <Form.Check
                                inline
                                value={'No'}
                                label={'No'}
                                type="radio"
                                name='need_seasonal_rate'
                                checked={isNoCheck}
                                onChange={(value) => {
                                    extraEnableSeasonalForm(value.target.value);
                                    backToProperty();
                                    setIsNoCheck(true);
                                }}
                            />
                        </span>
                    </div>
                </Row>
            }

            {isRateHere && rateData?.corporateRate?.seasonalRate.length !== 0
                && enableExtraSeasonalRateForm === 'Yes' && inventory?.status !== 'review' && <RateSeasonalForm seasonalRate={''} inventory={inventory} callRfps={callRfps} isSeasonalHere={isSeasonalHere} />
            }

            {inventory?.status === 'review' && <Row className='mt-5'>
                <div style={{ fontSize: '16px' }}>Do you wish to amend these rates?
                    <span style={{ marginLeft: '2%' }}>
                        <Form.Check
                            inline
                            value={'Yes'}
                            label={'Yes'}
                            type="radio"
                            name='amendRate'
                            onChange={(value) => {
                                onAmendRate(value.target.value);
                            }}
                        />
                        <Form.Check
                            inline
                            value={'No'}
                            label={'No'}
                            type="radio"
                            name='amendRate'
                            onChange={(value) => {
                                onAmendRate(value.target.value);
                                // backToProperty();
                            }}
                        />
                    </span>
                </div>

                {
                    isAmendRate === CommonAnswer.NO &&
                    <Row style={{ marginTop: '20px' }}>
                        <Col md={12}>
                            <Button type="submit" style={formButton} onClick={() => {
                                onReviewRate(inventory);
                            }} ><b>Save</b></Button>

                        </Col>
                    </Row>}


                {
                    isAmendRate === CommonAnswer.YES &&
                    <div className='mt-5'>
                        <Form onSubmit={handleSubmit(onFinishAmendedRate)}>
                            <Row style={{ background: '#F2F2F2' }}>
                                <Col md={3}>
                                    <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Rate</div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}>
                                        <span style={headBorderStyle}></span><span >1-7 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 8-14 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 15-28 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 29-89 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 90+ nights</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '4%', marginBottom: '10px' }}>Standard Rate</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.oneTo7} min={0} className={`${errors.standardRateOneTo7 ? 'is-invalid' : ''}`} {...register("standardRateOneTo7", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.eightTo14} min={0} className={`${errors.standardRateEightTo14 ? 'is-invalid' : ''}`} {...register("standardRateEightTo14", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.fifteenTo28} min={0} className={`${errors.standardRateFifteenTo28 ? 'is-invalid' : ''}`} {...register("standardRateFifteenTo28", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.twentyNineTo90} min={0} className={`${errors.standardRateTwentyNineTo90 ? 'is-invalid' : ''}`} {...register("standardRateTwentyNineTo90", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.ninetyOnePlus} min={0} className={`${errors.standardRateNinetyOnePlus ? 'is-invalid' : ''}`} {...register("standardRateNinetyOnePlus", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ background: '#F2F2F2' }}>
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation notice (nights)</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDates} min={0} className={`${errors.cancellationPolicyOneTo7CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7CutOffDates", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDates} min={0} className={`${errors.cancellationPolicyEightTo14CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14CutOffDates", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDates} min={0} className={`${errors.cancellationPolicyFifteenTo28CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28CutOffDates", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates} min={0} className={`${errors.cancellationPolicyTwentyNineTo90CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90CutOffDates", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates} min={0} className={`${errors.cancellationPolicyNinetyOnePlusCutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusCutOffDates", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation charge (nights)</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDates} min={0} className={`${errors.cancellationPolicyOneTo7PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7PenaltyDates", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDates} min={0} className={`${errors.cancellationPolicyEightTo14PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14PenaltyDates", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDates} min={0} className={`${errors.cancellationPolicyFifteenTo28PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28PenaltyDates", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates} min={0} className={`${errors.cancellationPolicyTwentyNineTo90PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90PenaltyDates", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates} min={0} className={`${errors.cancellationPolicyNinetyOnePlusPenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusPenaltyDates", { required: true })} disabled={editEnable} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '20px' }}>
                                <Col md={12}>
                                    {!loading && !editEnable && <Button type="submit" style={formButton} ><b>Save</b></Button>}
                                    {!loading && editEnable && <Button style={formButton} onClick={() => {
                                        setEditEnable(false);
                                    }}><b>Edit</b></Button>}
                                    {loading && <Spinner animation="border" variant="warning" />}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                }
            </Row>
            }

            {isRateHere && rateData?.corporateRate?.seasonalRate.length === 0
                && enableRateForm === 'Yes' && inventory?.status === 'review' && isAmendRate === 'Yes' && <RateSeasonalForm seasonalRate={''} inventory={inventory} callRfps={callRfps} isSeasonalHere={isSeasonalHere} />
            }

            {
                isRateHere && rateData?.corporateRate?.seasonalRate.length !== 0 && inventory?.status === 'review' && isAmendRate === 'Yes' && seasonalRateData

            }

            {
                isRateHere && rateData?.corporateRate?.seasonalRate.length !== 0
                && inventory?.status === 'review' && isAmendRate === 'Yes' && <Row className='mt-5'>
                    <div style={{ fontSize: '16px' }}>Do you wish to propose any further seasonal rates?
                        <span style={{ marginLeft: '2%' }}>
                            <Form.Check
                                inline
                                value={'Yes'}
                                label={'Yes'}
                                type="radio"
                                name='need_seasonal_rate'
                                onChange={(value) => {
                                    extraEnableSeasonalForm(value.target.value);
                                }}
                            />
                            <Form.Check
                                inline
                                value={'No'}
                                label={'No'}
                                type="radio"
                                name='need_seasonal_rate'
                                onChange={(value) => {
                                    extraEnableSeasonalForm(value.target.value);
                                    // backToProperty();
                                }}
                            />
                        </span>
                    </div>
                </Row>
            }

            {isRateHere && rateData?.corporateRate?.seasonalRate.length !== 0
                && enableExtraSeasonalRateForm === 'Yes' && inventory?.status === 'review' && isAmendRate === 'Yes' && <RateSeasonalForm seasonalRate={''} inventory={inventory} callRfps={callRfps} isSeasonalHere={isSeasonalHere} />
            }

            {
                isAmendRate === CommonAnswer.YES &&
                <Row style={{ marginTop: '20px' }}>
                    <Col md={12}>
                        <Button type="submit" style={formButton} onClick={() => {
                            onSubmit(inventory);
                        }} ><b>Submit</b></Button>

                    </Col>
                </Row>}

        </>
    )
}

export default RfpRateForm;