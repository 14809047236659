import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SelectC from "../booking-report/components/SelectC";
import InputC from "../booking-report/components/InputC";
import DataTable, { createTheme } from 'react-data-table-component';
import { $mysaMegenta, $mysaYellow, mysaTableTheme, tableStyles, $mysaTeal, resendButton, overlayTriggerDelay } from "../../../common/components-style";
import { UserProfile } from "../../../common/constants";
import { useStoreActions, useStoreState } from "easy-peasy";
import ButtonC from "../booking-report/components/ButtonC";
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import UserView from "./components/UserView";
import ProgrammePermissionModel from "../programme-management/form/programme-users/ProgrammePermissionModel";
import Lock from '../../../images/icons/svg/outside/Icon-Function-Permission.svg';
////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');


type TAdminCorporateUser = {
    corporateUsers: Array<any>
};

const AdminCorporateUser: React.FunctionComponent<TAdminCorporateUser> = ({ corporateUsers }) => {

    /**
     * local state
     */
    const [userList, setUserList] = useState<Array<any>>([]);
    const [clientsList, setClientsList] = useState<Array<any>>([]);
    const [clientId, setClientId] = useState<String>('');
    const [search, setSearch] = useState<String>('');
    const [status, setStatus] = useState<String>('');
    const [userModelShow, setUserModelShow] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<any>();
    const [loadModel, setLoadModel] = useState<boolean>(false)

    /**
     * global action
     */
    const { getClientList } = useStoreActions<any>((actions) => ({
        getClientList: actions.client.getClientList,
    }));

    /**
     * global state
     */
    const { getClientListSuccess } = useStoreState<any>((state) => ({
        getClientListSuccess: state.client.getClientListSuccess,
    }));


    useEffect(() => {
        // set user list
        setUserList(corporateUsers);

        getClientList();

    }, [corporateUsers, getClientList]);

    useEffect(() => {

        if (getClientListSuccess?.data) {

            const clientListArray: Array<any> = [];

            for (const client of getClientListSuccess.data) {
                const clientList = {
                    value: client._id,
                    label: client.companyName,
                };
                clientListArray.push(clientList);
            }

            setClientsList(clientListArray);
        }

    }, [getClientListSuccess]);

    
    const onSubmit = useCallback(() => {
        let filterUser: any = corporateUsers;

        if (status === 'CONFIRMED') {
            filterUser = filterUser.filter((user) => user.userStatus === 'CONFIRMED' && user.status === 'active');
        }
        if (status === 'UNCONFIRMED') {
            filterUser = filterUser.filter((user) => user.userStatus !== 'CONFIRMED');
        }
        if (search) {
            filterUser = filterUser.filter((user) => user.firstName === search || user.lastName === search || user.email === search);
        }
        if (clientId) {
            filterUser = filterUser.filter((user) => user.clientId === clientId);
        }

        setUserList(filterUser);
    }, [corporateUsers, status, search, clientId]);
    /**
     * filter User
     */
    useEffect(() => {
        onSubmit()
    }, [onSubmit, status]);

 

    const columns = [
        {
            name: 'Last Name',
            selector: row => row.lastName,
        },
        {
            name: 'First Name',
            selector: row => row.firstName,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Client Name',
            selector: row => row.clientName,
        },
        {
            name: 'User Type',
            selector: row => row.userType,
        },
        {
            name: 'Status',
            // minWidth: '180px',
            // maxWidth: '250px',
            cell: (row) => {
                let color = "";
                if (row.userStatus !== 'CONFIRMED') color = $mysaYellow;
                if (row.userStatus === 'CONFIRMED') color = $mysaTeal;
                if (row.userStatus === 'FORCE_CHANGE_PASSWORD') color = $mysaMegenta;
                if (row.status === 'inactive') color = $mysaMegenta;

                return (

                    <span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>
                        {
                            (row.status === 'inactive') && 'DISABLED'
                        }
                        {
                            (row.status !== 'inactive') && `${row.userStatus}`
                        }
                        {row.status !== 'inactive' && row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => { }} className="ml-2 mt-1" >Resend</Button>}
                    </span>
                )
            }
        },
        {
            name: 'User profile',
            // minWidth: '120px',
            // button: true,
            cell: (row) => {
                let color = "";
                if (row.userProfile !== UserProfile.COMPLETED) color = $mysaMegenta;
                if (row.userProfile === UserProfile.COMPLETED) color = $mysaTeal;

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.userProfile}</span>
                );

            }
        },
        {
            name: 'Action',
            button: true,
            cell: (row: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                    >
                        <img className='iconSizes' src={ViewEdit} alt="" onClick={() => { setSelectedUser(row); setUserModelShow(true); }} />
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>Permission</Tooltip>}

                    >
                        <img className='iconSizes' src={Lock} alt="" onClick={() => { setSelectedUser(row); setLoadModel(true); }} />
                    </OverlayTrigger>
                </>)
            },
        },
    ];

    return (<>
        <Row className="text-center" style={{ marginTop: '-25px' }}>
            <Row>
                <Col xs={3}>
                    <SelectC name="Clients" data={clientsList} onChange={(e) => { setClientId(e.currentTarget.value) }} />
                </Col>
                <Col xs={3}>
                    <InputC name="search" onKeyUp={(e) => { setSearch(e.currentTarget.value) }} />
                </Col>
                <Col xs={3}>
                    <ButtonC loading={false} id="blue-button" label="Search" onClick={onSubmit} />
                </Col>
                <Col xs={3}>
                    <SelectC name="Status" data={[
                        {
                            value: 'CONFIRMED',
                            label: 'CONFIRMED',
                        },
                        {
                            value: 'UNCONFIRMED',
                            label: 'UNCONFIRMED',
                        }
                    ]} onChange={(e) => { setStatus(e.currentTarget.value) }} />
                </Col>
            </Row>
        </Row>
        <div className='brnd_tbl' style={{ marginTop: '20px' }}>

            <DataTable
                columns={columns}
                data={userList}
                pagination
                striped={true}
                customStyles={tableStyles}
                theme='mysaTable'
                fixedHeader={true}
                noDataComponent={'Updating records'}
            />
        </div>
        <Modal
            show={userModelShow}
            onHide={() => setUserModelShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {selectedUser?.clientName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserView selectedUser={selectedUser} />
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ width: '150px' }} id="edit-button" onClick={() => setUserModelShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
        {loadModel && <ProgrammePermissionModel hideUpdate={false} clientId={selectedUser?.clientId} programmeId={selectedUser?.corporateProgrammeId} userMappingData={corporateUsers} corporateProgrammes={selectedUser?.corporateProgramme} selectedUser={selectedUser} onClose={() => { setLoadModel(false); }} />}
    </>);
};

export default AdminCorporateUser;