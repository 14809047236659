import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
const InviteCard: React.FC<any> = ({ color = '', data }): JSX.Element => {
	return (
		<div className={`${color} my-2 mr-1 p-3 border-l-4 `}>
			<Link to={`/admin/dashboard/new-sign-up/form?token=${data.token}&_id=${data._id}&certificateId=${data.certificateId}&email=${data.email}&signUpStatus=${data.signUpStatus}&brand=${data.brandName}`} style={{ color: 'inherit', textDecoration: 'inherit'}}>
				<p>{data.brandName}</p>
				<p >{data.name}</p>
				<p className="">{data.email}</p>
				<p className="italic text-gray-600">{moment(data.createdAt).format("DD-MM-YYYY")}</p>
		</Link>
			</div>
	)
}

export default InviteCard
