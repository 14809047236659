import React, { useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import RequestBookingOperatorHome from "../request-booking/brand-and-property/RequestBookingOperatorHome";
import { navStyle, tabDivStyle } from "../../../../common/components-style";
import BrandAndProperty from "../bookings/brand-and-property/brandAndProperty";
import { Link } from "react-router-dom";
import { getStorage } from "../../../../common/storage";

const BrandAndPropertyReport: React.FC<any> = ({ location }): JSX.Element => {

    const [tabKey, setTabKey] = useState<any>(location.state?.tabKey ?? 'propertyBookings');
    const { brandData } = getStorage('myo_logged_user_permission');

    
    return (
        <>
            <div style={tabDivStyle}>
                <Tab.Container defaultActiveKey="propertyBookings" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                    <Row >
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                                <div style={{ marginTop: '0%' }}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="propertyBookings">Property Bookings</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="requestBooking">Request Bookings</Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content >
                                <Tab.Pane eventKey="propertyBookings" >
                                    {/* <Row >
                                        <Col md={12}>
                                            <div className='content-title mysa-blue-text'>
                                                <Link to='/admin/brand-property-booking-reports' className="fw-sb mysa-blue-text">{`${brandData[0]?.name} - All Property`}</Link>
                                            </div>
                                        </Col>
                                    </Row> */}
                                    <BrandAndProperty />
                                </Tab.Pane>
                                <Tab.Pane eventKey="requestBooking" >
                                    <Row style={{marginBottom:15}}>
                                        <Col md={12}>
                                            <div className='content-title mysa-blue-text'>
                                                <Link to='/admin/brand-property-booking-reports' className="fw-sb mysa-blue-text">{`${brandData[0]?.name} - All Property`}</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                    <RequestBookingOperatorHome />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}

export default BrandAndPropertyReport;