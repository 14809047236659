import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AccommodationForm from './AccommodationForm';


const AccommodationList: React.FC<any> = (): JSX.Element => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [accommodationList, setAccommodationList] = useState<any>([]);
  const [accommodationDetails, setAccommodationDetails] = useState<any>(null);
  const [accommodationId, setAccommodationId] = useState<number | null>(null);

  ////////////////////////////////////////////////////////////////////////////////////////////
  const loadForm = useCallback((data) => {
    setFormOpen(true);
    setAccommodationDetails(data);
    if (data !== null) {
      setAccommodationId(data.key);
    } else {
      setAccommodationId(null);
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getAccommodationList, updateAccommodation } = useStoreActions<any>((actions) => ({
    getAccommodationList: actions.inventory.getAccommodationList,
    updateAccommodation: actions.inventory.updateAccommodation,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    getAccommodationListSuccess,
    getAccommodationListError,
    updateAccommodationError,
    removeAccommodationSuccess
  } = useStoreState<any>((state) => ({
    getAccommodationListSuccess: state.inventory.getAccommodationListSuccess,
    getAccommodationListError: state.inventory.getAccommodationListError,
    updateAccommodationError: state.inventory.updateAccommodationError,
    removeAccommodationSuccess: state.inventory.removeAccommodationSuccess,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getAccommodationList();
  }, [getAccommodationList]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAccommodationListSuccess) {
      setAccommodationList(getAccommodationListSuccess.data);
    }
    if (getAccommodationListError) {
      message.error(getAccommodationListError.message)
    }

    if (removeAccommodationSuccess) {
      message.success('accommodation removed successfully');
      getAccommodationList();
    }

    if (updateAccommodationError) {
      message.error(updateAccommodationError.message)
    }

  }, [getAccommodationListError, getAccommodationListSuccess, removeAccommodationSuccess, updateAccommodationError, getAccommodationList]);
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const removeData = useCallback((values) => {
    const data = {
      id: values.key,
      isDeleted: true
    }
    updateAccommodation(data);

  }, [updateAccommodation]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name', outerWidth: '75px' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Status',
      dataIndex: '',
      key: 'status  ',
      render: (data: any) => {

        return (<div className={`${data.status === 'active' ? 'text-green-500' : 'text-red-500'}`}>
          {data.status}
        </div>)
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'status',
      render: (data: any) => {
        return (<div>
          <Button onClick={() => loadForm(data)} className="border-none" ><EditOutlined /></Button>
          <Button onClick={() => removeData(data)} className="border-none" ><DeleteOutlined /></Button>
        </div>)
      },
    },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const data = useMemo(() => {
    return _.map(accommodationList, accommodation => {
      return {
        key: accommodation._id,
        name: accommodation.name,
        description: accommodation.description,
        status: accommodation.status
      }
    })
  }, [accommodationList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      <div className="flex justify-between">
        <p className="text-2xl mb-10">Accommodation Details</p>
        <Button hidden={formOpen} onClick={() => { loadForm(null) }} className="btn_design m-1 text-white">Create New</Button>
        <Button hidden={!formOpen} onClick={() => setFormOpen(false)} className="btn_design m-1 text-white">Show List</Button>
      </div>
      {formOpen && <AccommodationForm setFormOpen={setFormOpen} accommodationDetails={accommodationDetails} accommodationId={accommodationId} />}
      <div className="mb-1" hidden={formOpen}>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  )
};

export default AccommodationList;