import { CheckOutlined, CloseOutlined, VerticalLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { getLoggedUserType } from '../../../../../../common/functions';

const QYesNo: React.FC<any> = ({ data, onAnswer, signUpStatus, completed }): JSX.Element => {
	const [loadingNa, setLoadingNa] = useState<boolean>(false);
	const [loadingYes, setLoadingYes] = useState<boolean>(false);
	const [loadingNo, setLoadingNo] = useState<boolean>(false);
	const [userRole, setUserRole] = useState<any>('');

	let yesType = true;
	let noType = true;
	let na = true;
	useEffect(() => {
		if (getLoggedUserType() !== null) {
			setUserRole(getLoggedUserType());
		}

	}, []);

	if (data?.answer) {
		if (data?.answer?.answer === 'True')
			noType = false;
		else
			yesType = false;
		na = data?.answer?.na;
	}

	const answer = useCallback((result) => {

		const disabled = (userRole === 'super_admin') ? false : signUpStatus === 'accepted' ? true : completed ? true : false;

		if (disabled) return false;

		switch (result) {
			case "na":
				setLoadingNa(true);
				setTimeout(() => setLoadingNa(false), 3000)
				break;
			case "False":
				setLoadingNo(true);
				setTimeout(() => setLoadingNo(false), 3000)
				break;
			case "True":
				setLoadingYes(true);
				setTimeout(() => setLoadingYes(false), 3000)
				break;
			default:
				break;
		}

		let na = false;
		let results = result;

		if (result === "na") {
			na = true;
			results = "False"
		}
		onAnswer(results, data.id, na);
	}, [completed, data.id, onAnswer, signUpStatus, userRole]);



	return (
		<div className="inline-block m-5 w-full pr-10">
			<div className="bg-white p-5 rounded-lg">
				<p className="text-lg">{data?.description}</p>
				<div className="flex w-full justify-evenly mt-5">
					{data.mandatory || <Button type="primary" block ghost={na ? false : true} onClick={() => answer("na")} icon={<VerticalLeftOutlined />} className="mx-1 rounded-xl" loading={loadingNa} >
						N/A
					</Button>}
					<Button type="primary" ghost={!na && yesType ? false : true} block onClick={() => answer("True")} icon={<CheckOutlined />} className="mx-1 rounded-xl" loading={loadingYes}>
						Yes
					</Button>
					<Button block type="primary" ghost={!na && noType ? false : true} onClick={() => answer("False")} icon={<CloseOutlined />} className="mx-1 rounded-xl" loading={loadingNo}>
						No
					</Button>
				</div>
			</div>
		</div >
	)
};

export default QYesNo;