import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Button, Breadcrumb, Tab, Nav, Form, Modal } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { EnvironmentOutlined } from "@ant-design/icons";
import ClientSinglePropertyView from './views/ClientSinglePropertyView';
import { formButton, navStyle, tabDivStyle } from '../../../../../common/components-style';
import { useHistory, useParams } from 'react-router-dom';
import { Menu } from 'antd';
import ClientRatesListView from './views/ClientRatesListView';
import ClientAuditView from './views/ClientAuditView';
import ClientPropertyRateDetails from './views/ClientPropertyRateDetails';
import { CommonAnswer, PropertyInventoryStatus, BrandRfpPropertyEvaluationStatus } from '../../../../../common/constants';
import { toast } from 'react-toastify';
import SustainabilityView from './views/SustainabilityView';
import ViewComments from './components/ViewComments';


const ClientDeclinedPropertyDetailsToAccess: React.FC<any> = (): JSX.Element => {

    ///////////////////////////////////////// local state manage /////////////////////////////////////////
    const [menuKey, setMenuKey] = useState('property');
    const [property, setProperty] = useState<any>();
    const [amenitiesList, setAmenitiesList] = useState<any>();
    const [enableRatesScreen, setEnableRatesScreen] = useState<boolean>(false);
    const [rates, setRates] = useState<any>('');
    const [inventoryRates, setInventoryRates] = useState<any>([]);
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [showOption, setShowOption] = useState<boolean>(false);
    const [doYouWishDeclineProperty, setDoYouWishDeclineProperty] = useState<any>();
    const [isAcceptancePropertyBasedClarification, setIsAcceptancePropertyBasedClarification] = useState<any>();
    const [isAllInventoryAccepted, setIsAllInventoryAccepted] = useState<boolean>(false);
    const [doYouWishOpenNegotiationProperty, setDoYouWishOpenNegotiationProperty] = useState<any>();
    const [showAcceptanceClarification, setShowAcceptanceClarification] = useState<boolean>(false);
    const [declineComment, setDeclineComment] = useState<any>();
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [negotiationComment, setNegotiationComment] = useState<any>();
    const [clarificationComment, setClarificationComment] = useState<any>();
    const [rfpProperty, setRfpProperty] = useState<any>();
    const [showViewComment, setShowViewComment] = useState<boolean>(false);

    ////////////////// url manage ///////////
    let { propertyId, rfpId } = useParams<any>();
    let history = useHistory();

    /////////////////////////////////// global action manage ////////////////////////////////////////////
    const { propertyDetailed, getAmenityList, acceptPropertyRateForRFP, declinePropertyRateForRFP,
        getPropertyInventoryRatesForRFP, declinePropertyForRFP, acceptCreateUpdatePropertyAcceptDeclineOfRFP, declineCreateUpdatePropertyAcceptDeclineOfRFP,
        reviewCreateUpdatePropertyAcceptDeclineOfRFP, acceptPropertyForRFP, reviewPropertyForRFP, partAcceptedPropertyForRFP, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP } = useStoreActions<any>(
            (actions) => ({
                propertyDetailed: actions.property.propertyDetailed,
                getAmenityList: actions.property.getAmenityList,
                acceptPropertyRateForRFP: actions.rfpProcess.acceptPropertyRateForRFP,
                declinePropertyRateForRFP: actions.rfpProcess.declinePropertyRateForRFP,
                // propertyInventoryRates: actions.rfpProcess.propertyInventoryRates,
                getPropertyInventoryRatesForRFP: actions.rfpProcess.getPropertyInventoryRatesForRFP,
                declinePropertyForRFP: actions.rfpProcess.declinePropertyForRFP,
                acceptPropertyForRFP: actions.rfpProcess.acceptPropertyForRFP,
                reviewPropertyForRFP: actions.rfpProcess.reviewPropertyForRFP,
                partAcceptedPropertyForRFP: actions.rfpProcess.partAcceptedPropertyForRFP,
                acceptCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFP,
                declineCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.declineCreateUpdatePropertyAcceptDeclineOfRFP,
                reviewCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFP,
                partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP,

            })
        );

    /////////////////////////////////// global state manage ////////////////////////////////////////////
    const { getPropertyDetailedSuccess, getAmenityListSuccess, acceptPropertyRateForRFPSuccess, acceptPropertyRateForRFPError,
        declinePropertyRateForRFPSuccess, declinePropertyRateForRFPError, getPropertyInventoryRatesForRFPSuccess,
        declinePropertyForRFPSuccess, declinePropertyForRFPError, reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPError, acceptPropertyForRFPSuccess, acceptPropertyForRFPError,
        reviewPropertyForRFPSuccess, reviewPropertyForRFPError,
        acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess, acceptCreateUpdatePropertyAcceptDeclineOfRFPError,
        declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess, declineCreateUpdatePropertyAcceptDeclineOfRFPError,
        partAcceptedPropertyForRFPSuccess, partAcceptedPropertyForRFPError,
        partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError,
    } = useStoreState<any>(
        (state) => ({
            getPropertyDetailedSuccess: state.property.getPropertyDetailedSuccess,
            getAmenityListSuccess: state.property.getAmenityListSuccess,

            acceptPropertyRateForRFPSuccess: state.rfpProcess.acceptPropertyRateForRFPSuccess,
            acceptPropertyRateForRFPError: state.rfpProcess.acceptPropertyRateForRFPError,

            declinePropertyRateForRFPSuccess: state.rfpProcess.declinePropertyRateForRFPSuccess,
            declinePropertyRateForRFPError: state.rfpProcess.declinePropertyRateForRFPError,

            // getPropertyInventoryRatesSuccess: state.rfpProcess.getPropertyInventoryRatesSuccess,
            getPropertyInventoryRatesForRFPSuccess: state.rfpProcess.getPropertyInventoryRatesForRFPSuccess,

            declinePropertyForRFPSuccess: state.rfpProcess.declinePropertyForRFPSuccess,
            declinePropertyForRFPError: state.rfpProcess.declinePropertyForRFPError,

            reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            reviewCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPError,

            acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            acceptCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFPError,

            declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            declineCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.declineCreateUpdatePropertyAcceptDeclineOfRFPError,

            acceptPropertyForRFPSuccess: state.rfpProcess.acceptPropertyForRFPSuccess,
            acceptPropertyForRFPError: state.rfpProcess.acceptPropertyForRFPError,

            reviewPropertyForRFPSuccess: state.rfpProcess.reviewPropertyForRFPSuccess,
            reviewPropertyForRFPError: state.rfpProcess.reviewPropertyForRFPError,

            partAcceptedPropertyForRFPSuccess: state.rfpProcess.partAcceptedPropertyForRFPSuccess,
            partAcceptedPropertyForRFPError: state.rfpProcess.partAcceptedPropertyForRFPError,

            partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError,


        })
    );


    const redirectProperty = useCallback(() => {
        history.push({
            pathname: `/admin/client-proposal-management`,
            state: {
                cityName: property?.city,
                rfpId: rfpId,
                filterProgramManage: true,
                defaultActiveKey: 'declinedProperties'
            }
        })
        // history.push('/admin/property-management', { brandId: brandId })
    }, [history, property?.city, rfpId])

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (propertyId) {
            const payload = {
                propertyId: propertyId
            }
            propertyDetailed(payload);
            getAmenityList();
        }
    }, [getAmenityList, propertyDetailed, propertyId]);

    ////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        // const payload = {
        //     rfp_id: rfpId,
        //     propertyId: propertyId
        // }
        // propertyInventoryRates(payload);
        const params = {
            brandId: property?.brandId,
            propertyId: propertyId,
            rfpProcessId: rfpId,
            cityName: property?.city
        }
        if (property?.brandId) {
            getPropertyInventoryRatesForRFP(params);
        }


    }, [getPropertyInventoryRatesForRFP, property?.brandId, property?.city, propertyId, rfpId])

    useEffect(() => {
        // if (getPropertyInventoryRatesSuccess) {
        //     setInventoryRates(getPropertyInventoryRatesSuccess.data?.property?.inventory);
        // }
        if (getPropertyInventoryRatesForRFPSuccess) {
            setInventoryRates(getPropertyInventoryRatesForRFPSuccess.data?.inventories);
            setRfpProperty(getPropertyInventoryRatesForRFPSuccess.data);
        }
    }, [getPropertyInventoryRatesForRFPSuccess])

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertyDetailedSuccess) {

            setProperty(getPropertyDetailedSuccess.data);
        }

        if (getAmenityListSuccess) {
            setAmenitiesList(getAmenityListSuccess.data);
        }

    }, [getAmenityListSuccess, getPropertyDetailedSuccess]);


    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (acceptPropertyRateForRFPSuccess) {
            toast.success("Rate accepted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEnableRatesScreen(false);
            const params = {
                brandId: property?.brandId,
                propertyId: propertyId,
                rfpProcessId: rfpId,
                cityName: property?.city
            }
            if (rfpId) {
                getPropertyInventoryRatesForRFP(params);
            }
        }

        if (declinePropertyRateForRFPSuccess) {
            toast.success("Rate declined successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            const params = {
                brandId: property?.brandId,
                propertyId: propertyId,
                rfpProcessId: rfpId,
                cityName: property?.city
            }
            if (rfpId) {
                getPropertyInventoryRatesForRFP(params);
            }
            setEnableRatesScreen(false);
        }
        if (acceptPropertyRateForRFPError) {

            toast.error(acceptPropertyRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEnableRatesScreen(false);
        }

        if (declinePropertyRateForRFPError) {
            toast.error(declinePropertyRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEnableRatesScreen(false);
        }


    }, [acceptPropertyRateForRFPError, acceptPropertyRateForRFPSuccess, declinePropertyRateForRFPError, declinePropertyRateForRFPSuccess, getPropertyInventoryRatesForRFP, property?.brandId, property?.city, propertyId, rfpId]);


    //// decline property//////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (declinePropertyForRFPSuccess) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                doYouWishDeclineProperty: doYouWishDeclineProperty,
                declineComment: declineComment,
            }

            declineCreateUpdatePropertyAcceptDeclineOfRFP(payload);

        }
        if (declinePropertyForRFPError) {
            toast.error(declinePropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (declineCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(declineCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property declined successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            redirectProperty();
        }


    }, [declineComment, declineCreateUpdatePropertyAcceptDeclineOfRFP, declineCreateUpdatePropertyAcceptDeclineOfRFPError, declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess, declinePropertyForRFPError, declinePropertyForRFPSuccess, doYouWishDeclineProperty, property?.brandId, propertyId, redirectProperty, rfpId]);

    /////////////////////////accept property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (acceptPropertyForRFPSuccess) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                doYouWishDeclineProperty: doYouWishDeclineProperty,
                isAcceptancePropertyBasedClarification: isAcceptancePropertyBasedClarification,
            }
            acceptCreateUpdatePropertyAcceptDeclineOfRFP(payload);
        }

        if (acceptPropertyForRFPError) {
            toast.error(acceptPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (acceptCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(acceptCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property accepted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            redirectProperty();
        }
    }, [acceptCreateUpdatePropertyAcceptDeclineOfRFP, acceptCreateUpdatePropertyAcceptDeclineOfRFPError, acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess, acceptPropertyForRFPError, acceptPropertyForRFPSuccess, doYouWishDeclineProperty, isAcceptancePropertyBasedClarification, property?.brandId, propertyId, redirectProperty, rfpId]);


    /////////////////////////review property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (reviewPropertyForRFPSuccess) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                doYouWishDeclineProperty: doYouWishDeclineProperty,
                isAcceptancePropertyBasedClarification: isAcceptancePropertyBasedClarification,
                clarificationComment: clarificationComment,
                doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission: doYouWishOpenNegotiationProperty,
                negotiationComment: negotiationComment,
            }

            reviewCreateUpdatePropertyAcceptDeclineOfRFP(payload);

        }

        if (reviewPropertyForRFPError) {
            toast.error(reviewPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(reviewCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property review successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            redirectProperty();
        }


    }, [clarificationComment, doYouWishDeclineProperty, doYouWishOpenNegotiationProperty, isAcceptancePropertyBasedClarification, negotiationComment, property?.brandId, propertyId, redirectProperty, reviewCreateUpdatePropertyAcceptDeclineOfRFP, reviewCreateUpdatePropertyAcceptDeclineOfRFPError, reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess, reviewPropertyForRFPError, reviewPropertyForRFPSuccess, rfpId]);


    /////////////////////////part accepted property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (partAcceptedPropertyForRFPSuccess) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                doYouWishDeclineProperty: doYouWishDeclineProperty,
                doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission: doYouWishOpenNegotiationProperty,
                negotiationComment: negotiationComment,
                isAcceptancePropertyBasedClarification: isAcceptancePropertyBasedClarification,
            }

            partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP(payload);

        }

        if (partAcceptedPropertyForRFPError) {
            toast.error(partAcceptedPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            if (doYouWishOpenNegotiationProperty === CommonAnswer.NO && isAcceptancePropertyBasedClarification === CommonAnswer.NO) {
                toast.success("Property part accepted successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            } else {
                toast.success("Property review successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
            redirectProperty();
        }


    }, [doYouWishDeclineProperty, doYouWishOpenNegotiationProperty, isAcceptancePropertyBasedClarification, negotiationComment, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess, partAcceptedPropertyForRFPError, partAcceptedPropertyForRFPSuccess, property?.brandId, propertyId, redirectProperty, rfpId]);




    ///////////////////////// menu change ///////////////////////////////////
    const menuChange = useCallback((value) => {
        setMenuKey(value.key);
    }, []);

    /////////////////////////accept rate ///////////////////////////////////
    const onAcceptRate = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyInventoryId: rates?.inventory,
            propertyInventoryStatus: PropertyInventoryStatus.ACCEPTED,
            cityName: property?.city,

        }
        acceptPropertyRateForRFP(payload);
    }, [acceptPropertyRateForRFP, property?.brandId, property?.city, propertyId, rates?.inventory, rfpId]);

    ///////////////////////// decline rate ///////////////////////////////////
    const onDeclineRate = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyInventoryId: rates?.inventory,
            propertyInventoryStatus: PropertyInventoryStatus.DECLINED,
            cityName: property?.city,

        }
        declinePropertyRateForRFP(payload);
    }, [declinePropertyRateForRFP, property?.brandId, property?.city, propertyId, rates?.inventory, rfpId]);

    //////////// accept or decline //////////
    const onChangePropertyOption = useCallback((value) => {
        setDoYouWishDeclineProperty(value);
    }, []);

    ////////////  //////////
    const onSubmitClarification = useCallback((value) => {
        setIsAcceptancePropertyBasedClarification(value);
    }, []);

    //////////// accept or decline //////////
    const onOpenNegotiation = useCallback((value) => {
        setDoYouWishOpenNegotiationProperty(value);
    }, []);


    //////////// declineProperty //////////
    const declineProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyStatus: BrandRfpPropertyEvaluationStatus.DECLINED,
            cityName: property?.city,
            isAllInventoryAccepted: isAllInventoryAccepted,
            isClient: true
        }
        declinePropertyForRFP(payload);
    }, [declinePropertyForRFP, isAllInventoryAccepted, property?.brandId, property?.city, propertyId, rfpId]);

    //////////// accept Property //////////
    const acceptProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyStatus: BrandRfpPropertyEvaluationStatus.ACCEPTED,
            cityName: property?.city,
            isClient: true
        }
        acceptPropertyForRFP(payload);
    }, [acceptPropertyForRFP, property?.brandId, property?.city, propertyId, rfpId]);

    //////////// reviewProperty //////////
    const onReviewProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
            cityName: property?.city,
            isAllInventoryAccepted: isAllInventoryAccepted,
            isNegotiation: true,
            isNegotiateProperty: true,
            isClient: true
        }
        reviewPropertyForRFP(payload);
    }, [isAllInventoryAccepted, property?.brandId, property?.city, propertyId, reviewPropertyForRFP, rfpId]);

    //////////// part accepted //////////
    const onSubmitNegotiation = useCallback(() => {
        setShowAcceptanceClarification(true);
    }, []);

    //////////// part accept Property //////////
    const partAcceptProperty = useCallback(() => {
        if (doYouWishOpenNegotiationProperty === CommonAnswer.NO && isAcceptancePropertyBasedClarification === CommonAnswer.NO) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyStatus: BrandRfpPropertyEvaluationStatus.PART_ACCEPTED,
                cityName: property?.city,
                isAllInventoryAccepted: isAllInventoryAccepted,
                isNegotiation: false

            }
            partAcceptedPropertyForRFP(payload);
        } else {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
                cityName: property?.city,
                isAllInventoryAccepted: isAllInventoryAccepted,
                isNegotiation: false

            }
            partAcceptedPropertyForRFP(payload);
        }
    }, [doYouWishOpenNegotiationProperty, isAcceptancePropertyBasedClarification, isAllInventoryAccepted, partAcceptedPropertyForRFP, property?.brandId, property?.city, propertyId, rfpId]);



    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const filteredRfpProcessList = inventoryRates?.filter(process => process.rfpPropertyRateStatus === PropertyInventoryStatus.PROPOSED);
        if (filteredRfpProcessList?.length > 0) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }

        const filteredAcceptInventoryList = inventoryRates?.filter(process => process.rfpPropertyRateStatus === PropertyInventoryStatus.DECLINED);
        if (filteredAcceptInventoryList?.length > 0) {
            setIsAllInventoryAccepted(false);
        } else {
            setIsAllInventoryAccepted(true);
        }

    }, [inventoryRates]);


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <div style={tabDivStyle}>
                <Tab.Container defaultActiveKey="property" >
                    <Row >
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                                <div style={{ marginTop: '0%' }}>
                                    <Nav.Item><Nav.Link eventKey="property">Property</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="RFPSurvey">Property Survey  </Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="operator" onClick={() => {
                                        history.push({
                                            pathname: `/admin/client-proposal-management/operator/${propertyId}/${rfpId}`
                                        })
                                    }}>Operator</Nav.Link></Nav.Item>
                                </div>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content >
                                <Tab.Pane eventKey="property" >

                                    <Row>
                                        <Col md={7}>
                                            <Breadcrumb className='bc_unset'>
                                                <Breadcrumb.Item style={{ fontSize: '24px' }} onClick={() => {
                                                    history.push({
                                                        pathname: `/admin/client-proposal-management`,
                                                        state: {
                                                            cityName: property?.city,
                                                            rfpId: rfpId,
                                                            filterProgramManage: true,
                                                            defaultActiveKey: 'declinedProperties'
                                                        }
                                                    })
                                                }}>{property?.city}</Breadcrumb.Item>
                                                <Breadcrumb.Item style={{ fontSize: '24px', cursor: 'pointer' }} active onClick={() => {
                                                    setEnableRatesScreen(false);
                                                }}>{`${property?.propertyName} by ${property?.brand?.name}`}</Breadcrumb.Item>
                                                {enableRatesScreen && <Breadcrumb.Item style={{ fontSize: '24px', cursor: 'pointer' }} active >{`${rates?.inventoryDetails[0]?.accommodationDetail}`} </Breadcrumb.Item>}
                                            </Breadcrumb>
                                        </Col>

                                        <Col md={5}>

                                            {!enableRatesScreen && (rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.COMPLETE || rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED || rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.INCOMPLETE) &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-approval btn_design mr-0' hidden={disabledButton} onClick={() => { setShowOption(true); setShowMessage(true); }}><b>Approve/Decline Property</b></Button>
                                                    {/* <Button style={{ ...buttonStyle, background: '#4ABEB4', color: '#ffffff' }}><b>Decline <CloseOutlined /></b></Button> */}
                                                </div>
                                            }

                                            {(rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) && !enableRatesScreen &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-approval btn_design' ><b>Accepted Property </b> </Button>
                                                </div>
                                            }

                                            {(rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED) && !enableRatesScreen &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-decline btn_design'><b>Declined Property </b></Button>
                                                </div>
                                            }
                                            {(rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW) && !enableRatesScreen &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-review btn_design'  ><b>Review Property</b></Button>
                                                </div>
                                            }

                                            <div style={{ textAlign: 'right' }}>
                                                {(enableRatesScreen && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.PROPOSED) && <Button className='btn-approval mr-8' onClick={() => { onAcceptRate() }}><b>Accept Rates </b> </Button>}
                                                {(enableRatesScreen && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.PROPOSED) && <Button className='btn-decline btn_design' onClick={() => { onDeclineRate() }} ><b>Decline Rates </b></Button>}
                                            </div>


                                            {(enableRatesScreen && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.ACCEPTED) &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-comment btn_design mr-2' onClick={() => { setShowViewComment(true); }} ><b>View Comments </b></Button>
                                                    <Button className='btn-approval btn_design' ><b>Accepted </b> </Button>
                                                </div>
                                            }

                                            {(enableRatesScreen && rates.rfpPropertyRateStatus === PropertyInventoryStatus.DECLINED) &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-comment btn_design mr-2' onClick={() => { setShowViewComment(true); }} ><b>View Comments </b></Button>
                                                    <Button className='btn-decline btn_design'  ><b>Declined </b></Button>
                                                </div>
                                            }

                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={12}>
                                            <div className='icn_txt' style={{ marginTop: '-5px' }}><EnvironmentOutlined /> {`${property?.address1} ${property?.address2}, ${property?.city} - ${property?.postcode}`}</div>
                                        </Col>
                                    </Row>


                                    <Modal
                                        show={showMessage}
                                        onHide={() => { setShowMessage(false); setShowOption(false); setDoYouWishDeclineProperty(null); setIsAcceptancePropertyBasedClarification(null); setDoYouWishOpenNegotiationProperty(null); }}
                                        size="xl"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered={false}
                                    >
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>

                                            {showOption &&
                                                <Row className=''>
                                                    <div style={{ fontSize: '16px' }}>Do you wish to decline this property?
                                                        <span style={{ marginLeft: '2%' }}>
                                                            <Form.Check
                                                                inline
                                                                value={'Yes'}
                                                                label={'Yes'}
                                                                type="radio"
                                                                name='doYouWishDeclineProperty'
                                                                onChange={(value) => {
                                                                    onChangePropertyOption(value.target.value);
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value={'No'}
                                                                label={'No'}
                                                                type="radio"

                                                                name='doYouWishDeclineProperty'
                                                                onChange={(value) => {
                                                                    onChangePropertyOption(value.target.value);
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>}

                                            {doYouWishDeclineProperty === CommonAnswer.YES &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>
                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"
                                                                name='declineComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setDeclineComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-decline' onClick={() => { declineProperty() }} ><b>Decline Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                            {(doYouWishDeclineProperty === CommonAnswer.NO && !isAllInventoryAccepted) &&
                                                <Row className='mt-3'>
                                                    <div style={{ fontSize: '16px' }}>Do you wish to open up negotiation with the property in relation to the rate submission?
                                                        <span style={{ marginLeft: '2%' }}>
                                                            <Form.Check
                                                                inline
                                                                value={'Yes'}
                                                                label={'Yes'}
                                                                type="radio"
                                                                name='doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission'
                                                                onChange={(value) => {
                                                                    onOpenNegotiation(value.target.value);
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value={'No'}
                                                                label={'No'}
                                                                type="radio"

                                                                name='doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission'
                                                                onChange={(value) => {
                                                                    onOpenNegotiation(value.target.value);
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>}

                                            {
                                                !isAllInventoryAccepted && (doYouWishOpenNegotiationProperty === CommonAnswer.NO || doYouWishOpenNegotiationProperty === CommonAnswer.YES) &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>
                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"

                                                                name='negotiationComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setNegotiationComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>
                                                    {!showAcceptanceClarification && <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { onSubmitNegotiation(); }} >Submit</Button>
                                                        </Col>
                                                    </Row>}
                                                </div>
                                            }

                                            {((doYouWishDeclineProperty === CommonAnswer.NO && isAllInventoryAccepted) || (showAcceptanceClarification && !isAllInventoryAccepted)) &&
                                                <Row className='mt-3'>
                                                    <div style={{ fontSize: '16px' }}>Is acceptance of the property based on any factors or further clarification?
                                                        <span style={{ marginLeft: '2%' }}>
                                                            <Form.Check
                                                                inline
                                                                value={'Yes'}
                                                                label={'Yes'}
                                                                type="radio"
                                                                name='isAcceptancePropertyBasedClarification'
                                                                onChange={(value) => {
                                                                    onSubmitClarification(value.target.value);
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value={'No'}
                                                                label={'No'}
                                                                type="radio"

                                                                name='isAcceptancePropertyBasedClarification'
                                                                onChange={(value) => {
                                                                    onSubmitClarification(value.target.value);
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>}





                                            {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.YES &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>


                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"
                                                                name='clarificationComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setClarificationComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { onReviewProperty() }} >Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }

                                            {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && showAcceptanceClarification && !isAllInventoryAccepted && (doYouWishOpenNegotiationProperty === CommonAnswer.NO) &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <p>Click to accept the property for the inventories accepted above</p>
                                                    </Row>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-approval' onClick={() => { partAcceptProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }

                                            {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && showAcceptanceClarification && !isAllInventoryAccepted && (doYouWishOpenNegotiationProperty === CommonAnswer.YES) &&
                                                <div>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { partAcceptProperty() }} >Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }


                                            {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && isAllInventoryAccepted &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-approval' onClick={() => { acceptProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </Modal.Body>
                                    </Modal>



                                    {
                                        !enableRatesScreen && <div>


                                            <div className='mt-4'>
                                                <Menu onClick={menuChange} mode="horizontal" selectedKeys={[menuKey]} className="w-full new_tab ">
                                                    <Menu.Item key="property" >
                                                        <span>Property</span>
                                                    </Menu.Item>
                                                    <Menu.Item key="rates" >
                                                        <span>Rates</span>
                                                    </Menu.Item>
                                                    <Menu.Item key="audit" >
                                                        <span>Audit</span>
                                                    </Menu.Item>
                                                    <Menu.Item key="sustainability" >
                                                        <span>Sustainability</span>
                                                    </Menu.Item>
                                                </Menu>
                                            </div>

                                            <div className="new_tab_ctnt new_tab_ctnt2" >
                                                <div className='w-full' >
                                                    {(menuKey === 'property') && <ClientSinglePropertyView property={property} amenitiesList={amenitiesList} />}
                                                    {(menuKey === 'rates') && <ClientRatesListView setRates={setRates} setEnableRatesScreen={setEnableRatesScreen} inventoryRates={inventoryRates} property={property} />}
                                                    {(menuKey === 'audit') && <ClientAuditView property={property} />}
                                                    {(menuKey === 'sustainability') && <SustainabilityView />}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        enableRatesScreen && <div>
                                            <Row>
                                                <Col md={12}>
                                                    <div className='mt-5  w-full'>
                                                        <ClientPropertyRateDetails rates={rates} property={property} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    }

                                    {/* {
                                            doYouWishDeclineProperty === CommonAnswer.YES &&
                                            <div>
                                                <Row className='mt-5'>
                                                    <Col md={6} style={{ textAlign: 'left' }}>
                                                        <Form.Control as="textarea" rows={5}
                                                            placeholder="please provide comments"
                                                            name='declineComment'
                                                            // value={''}
                                                            onChange={(e) => {
                                                                setDeclineComment(e.target.value);
                                                            }}
                                                        />


                                                    </Col>
                                                </Row>

                                                <Row className='formSpace'>
                                                    <Col md={6} style={{ textAlign: 'left' }}>
                                                        <Button className='btn-decline' onClick={() => { declineProperty() }} ><b>Decline Property <CloseOutlined /></b></Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        } */}

                                    {/* {doYouWishDeclineProperty === CommonAnswer.NO && !isAllInventoryAccepted &&
                                            <Row className='mt-5'>
                                                <div style={{ fontSize: '16px' }}>Do you wish to open up negotiation with the property in relation to the above rate submission??
                                                    <span style={{ marginLeft: '2%' }}>
                                                        <Form.Check
                                                            inline
                                                            value={'Yes'}
                                                            label={'Yes'}
                                                            type="radio"
                                                            name='doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission'
                                                            onChange={(value) => {
                                                                onOpenNegotiation(value.target.value);
                                                            }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            value={'No'}
                                                            label={'No'}
                                                            type="radio"

                                                            name='doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission'
                                                            onChange={(value) => {
                                                                onOpenNegotiation(value.target.value);
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </Row>} */}


                                    {/* {
                                            !isAllInventoryAccepted && (doYouWishOpenNegotiationProperty === CommonAnswer.NO || doYouWishOpenNegotiationProperty === CommonAnswer.YES) &&
                                            <div>
                                                <Row className='mt-5'>
                                                    <Col md={6} style={{ textAlign: 'left' }}>


                                                        <Form.Control as="textarea" rows={5}
                                                            placeholder="please provide comments"

                                                            name='negotiationComment'
                                                            // value={''}
                                                            onChange={(e) => {
                                                                setNegotiationComment(e.target.value);
                                                            }}
                                                        />


                                                    </Col>
                                                </Row>
                                                {!showAcceptanceClarification && <Row className='formSpace'>
                                                    <Col md={6} style={{ textAlign: 'left' }}>
                                                        <Button id='approve-button' style={formButton} onClick={() => { onSubmitNegotiation(); }} >Submit</Button>
                                                    </Col>
                                                </Row>}
                                            </div>
                                        } */}


                                    {/* {((doYouWishDeclineProperty === CommonAnswer.NO && isAllInventoryAccepted) || (showAcceptanceClarification && !isAllInventoryAccepted)) &&
                                            <Row className='mt-5'>
                                                <div style={{ fontSize: '16px' }}>Is acceptance of the property based on any factors or further clarification?
                                                    <span style={{ marginLeft: '2%' }}>
                                                        <Form.Check
                                                            inline
                                                            value={'Yes'}
                                                            label={'Yes'}
                                                            type="radio"
                                                            name='isAcceptancePropertyBasedClarification'
                                                            onChange={(value) => {
                                                                onSubmitClarification(value.target.value);
                                                            }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            value={'No'}
                                                            label={'No'}
                                                            type="radio"

                                                            name='isAcceptancePropertyBasedClarification'
                                                            onChange={(value) => {
                                                                onSubmitClarification(value.target.value);
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </Row>} */}

                                    {/* {
                                            isAcceptancePropertyBasedClarification === CommonAnswer.YES &&
                                            <div>
                                                <Row className='mt-5'>
                                                    <Col md={6} style={{ textAlign: 'left' }}>


                                                        <Form.Control as="textarea" rows={5}
                                                            placeholder="please provide comments"
                                                            name='clarificationComment'
                                                            // value={''}
                                                            onChange={(e) => {
                                                                setClarificationComment(e.target.value);
                                                            }}
                                                        />


                                                    </Col>
                                                </Row>

                                                <Row className='formSpace'>
                                                    <Col md={6} style={{ textAlign: 'left' }}>
                                                        <Button id='approve-button' style={formButton} onClick={() => { onReviewProperty() }} >Submit</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        } */}


                                    {/* {
                                            isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && showAcceptanceClarification && !isAllInventoryAccepted && (doYouWishOpenNegotiationProperty === CommonAnswer.NO) &&
                                            <div>
                                                <Row className='formSpace'>
                                                    <p>Click to accept the property for the inventories accepted above</p>
                                                </Row>
                                                <Row className='formSpace'>
                                                    <Col md={6} style={{ textAlign: 'left' }}>
                                                        <Button className='btn-approval' onClick={() => { partAcceptProperty() }} ><b>Accept Property <CheckOutlined /></b></Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        } */}

                                    {/* {
                                            isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && showAcceptanceClarification && !isAllInventoryAccepted && (doYouWishOpenNegotiationProperty === CommonAnswer.YES) &&
                                            <div>

                                                <Row className='formSpace'>
                                                    <Col md={6} style={{ textAlign: 'left' }}>
                                                        <Button id='approve-button' style={formButton} onClick={() => { partAcceptProperty() }} >Submit</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        } */}
                                    {/* 
                                        {
                                            isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && isAllInventoryAccepted &&
                                            <div>
                                                <Row className='formSpace'>
                                                    <Col md={6} style={{ textAlign: 'left' }}>
                                                        <Button className='btn-approval' onClick={() => { acceptProperty() }} ><b>Accept Property <CheckOutlined /></b></Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        } */}
                                </Tab.Pane>
                                {<Tab.Pane eventKey="RFPSurvey">
                                    <div>

                                    </div>
                                </Tab.Pane>}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>

            <Modal
                show={showViewComment}
                onHide={() => { setShowViewComment(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ViewComments rfpId={rfpId} propertyId={propertyId} inventoryId={rates?.inventory} />
                    <Row className='formSpace'>
                        <Col md={6} style={{ textAlign: 'left' }}>

                        </Col>
                        <Col md={6} style={{ textAlign: 'right' }}>
                            <Button style={formButton} onClick={() => { setShowViewComment(false); }} id='edit-button' >Close</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </>
    );
}

export default ClientDeclinedPropertyDetailsToAccess;