import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Button, Col, Modal, OverlayTrigger, Row, Tooltip, Form } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { $mysaBlue, $mysaMegenta, $mysaRed, $mysaTeal, $mysaYellow, overlayTriggerDelay, tableStyles } from '../../../../common/components-style';
import { BrandRfpPropertyEvaluationStatus } from '../../../../common/constants';
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import File from '../../../../images/icons/svg/outside/file-icon.svg';
import moment from 'moment';
import { setStorage } from '../../../../common/storage';
import { Link } from 'react-router-dom';
import BrandAdminRfpForm from './BrandAdminRfpForm';
import BrandAdminLocationMap from './BrandAdminLocationMapping';
import { toast } from 'react-toastify';


const BrandAdminMyRfpPropertiesHomePage: FC<any> = ({ setComponentName, setProperty, setDefaultTab, data }) => {

    const [showLocations, setShowLocations] = useState<boolean>(false);
    const [selectedCity, setSelectedCity] = useState<string>('');
    const [addedBrandRfpProperties, setAddedBrandRfpProperties] = useState<any[]>([]);
    const [refreshPropertiesList, setRefreshPropertiesList] = useState<boolean>(false);
    const [selectedCityDetails, setSelectedCityDetails] = useState<any>();
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showReviewMessage, setShowReviewMessage] = useState<boolean>(false);
    const [propertyData, setPropertyData] = useState<any>();
    const [propertyAcceptInformation, setPropertyAcceptInformation] = useState<any>();
    const [propertyReviewComment, setPropertyReviewComment] = useState<any>();
    const [isNegotiation, setIsNegotiation] = useState<boolean>(false);
    const [isPropertyReview, setIsPropertyReview] = useState<boolean>(false);
    //state
    const {
        brandRfpProcessLocationsByIdSuccess,
        reviewPropertyForRFPSuccess,
        reviewPropertyForRFPError,
        getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPError,
        acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        acceptCreateUpdatePropertyAcceptDeclineOfRFPError
    } = useStoreState<any>((state) => ({

        brandRfpProcessLocationsByIdSuccess: state.rfpProcess.brandRfpProcessLocationsByIdSuccess,
        getPropertyAcceptDeclineOfRFPByPropertyIdSuccess: state.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,
        reviewPropertyForRFPSuccess: state.rfpProcess.reviewPropertyForRFPSuccess,
        reviewPropertyForRFPError: state.rfpProcess.reviewPropertyForRFPError,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPError,
        acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        acceptCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFPError,
    }));

    //actions
    const {
        getRfpLocationsByBrandRfpId,
        getPropertyAcceptDeclineOfRFPByPropertyId,
        reviewPropertyForRFP,
        reviewCreateUpdatePropertyAcceptDeclineOfRFP,
        acceptCreateUpdatePropertyAcceptDeclineOfRFP
    } = useStoreActions<any>((actions) => ({
        getPropertyAcceptDeclineOfRFPByPropertyId: actions.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyId,
        getRfpLocationsByBrandRfpId: actions.rfpProcess.getRfpLocationsByBrandRfpId,
        reviewPropertyForRFP: actions.rfpProcess.reviewPropertyForRFP,
        reviewCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFP,
        acceptCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFP,
    }));


    useEffect(() => {
        setTimeout(() => {
            if (refreshPropertiesList) {
                const params: any = {};
                params.id = data?.key;
                params.rfpId = data?.rfpId;

                getRfpLocationsByBrandRfpId(params);
                setShowLocations(false);
                setSelectedCity('');
            }
            setRefreshPropertiesList(false);
        }, 3000)
    }, [data?.key, data?.rfpId, getRfpLocationsByBrandRfpId, refreshPropertiesList]);

    useEffect(() => {
        // console.log(propertyData, 'propertyId');
        const payload = {
            rfpId: data?.rfpId,
            propertyId: propertyData?.id
        }

        if (propertyData?.id) {
            getPropertyAcceptDeclineOfRFPByPropertyId(payload)
        }

    }, [data?.rfpId, getPropertyAcceptDeclineOfRFPByPropertyId, propertyData?.id]);


    useEffect(() => {
        if (getPropertyAcceptDeclineOfRFPByPropertyIdSuccess) {
            const data = getPropertyAcceptDeclineOfRFPByPropertyIdSuccess?.data;
            if ( data?.clarificationComment) {
                setIsNegotiation(true);
            } else {
                setIsNegotiation(false);
            }
            if (data?.propertyReviewComment) {
                setIsPropertyReview(true);
            } else {
                setIsPropertyReview(false);
            }
            setPropertyAcceptInformation(getPropertyAcceptDeclineOfRFPByPropertyIdSuccess?.data);
        }
    }, [getPropertyAcceptDeclineOfRFPByPropertyIdSuccess])

    useEffect(() => {
        setTimeout(() => {
            if (data?.key) {
                const params: any = {};
                params.id = data?.key;
                params.rfpId = data?.rfpId;

                getRfpLocationsByBrandRfpId(params);
                setShowLocations(false);
                setSelectedCity('');
            }
        }, 500)
    }, [data?.key, data?.rfpId, getRfpLocationsByBrandRfpId]);

    useEffect(() => {
        if (brandRfpProcessLocationsByIdSuccess?.data) {
            setAddedBrandRfpProperties([...brandRfpProcessLocationsByIdSuccess?.data]);
        }
    }, [brandRfpProcessLocationsByIdSuccess?.data]);

    /////////////////////////review property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (reviewPropertyForRFPSuccess) {
            const payload = {
                rfpId: data?.rfpId,
                brandId: data?.brandId,
                propertyId: propertyData?.id,
                propertyReviewComment: propertyReviewComment,
            }

            reviewCreateUpdatePropertyAcceptDeclineOfRFP(payload);

        }

        if (reviewPropertyForRFPError) {
            toast.error(reviewPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(reviewCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property reviewed successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setRefreshPropertiesList(true);
        }


    }, [data?.brandId, data?.rfpId, propertyData?.id, propertyReviewComment, reviewCreateUpdatePropertyAcceptDeclineOfRFP, reviewCreateUpdatePropertyAcceptDeclineOfRFPError, reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess, reviewPropertyForRFPError, reviewPropertyForRFPSuccess]);

    /////////////////////////add review comment//////////////////////////////////////////////////////////////
    useEffect(() => {

        if (acceptCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(acceptCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property review comment added successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setRefreshPropertiesList(true);
        }
    }, [acceptCreateUpdatePropertyAcceptDeclineOfRFPError, acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess]);


    const memoizedRfpProcessData = useMemo(() => {
        return addedBrandRfpProperties.map((property) => {
            return {
                clientLocation: property.cityName,
                id: property.id,
                building: property.propertyName,
                evaluationStatus: property.evaluationStatus,
                city: property.city,
                myoCertificateId: property?.myoCertificateId
            }
        })
    }, [addedBrandRfpProperties]);

    //Table columns/////////////////////////////////////////////////////////////////////////////////////////
    const columns1 = [
        {
            name: 'Building',
            selector: row => row.building,
        }, {
            name: 'City',
            selector: row => row.city,
        }, {
            name: 'Client Location',
            selector: row => row.clientLocation,
        },
        {
            name: 'Status',
            cell: (data: any) => {
                const { evaluationStatus } = data;
                let color = "";

                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.COMPLETE) color = $mysaTeal;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.INCOMPLETE) color = $mysaMegenta;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED) color = $mysaRed;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) color = $mysaTeal;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW) color = $mysaYellow;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED) color = $mysaBlue;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.PART_ACCEPTED) color = $mysaTeal;
                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize' >{evaluationStatus}</span>
                )
            }
        },
        {
            name: 'Action',
            button: true,
            wrap: true,
            minWidth: '300px',
            cell: (row: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                    >
                        <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                            setProperty(row);
                            setStorage("myo_selected_my_rfp_property", row);
                            setComponentName('Properties');
                            // setMyRfpsData(data);
                        }} />
                        {/* <img className='iconSizes' src={ViewEdit} alt="" onClick={() => loadForm(data)} /> */}
                    </OverlayTrigger>
                    {/* TODO: need to check if we need to add a icon for the remove */}
                    {(row.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED || row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW || row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED) && <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View Comments</Tooltip>}
                    >
                        <img className='iconSizes4' src={File} alt="" style={{ marginRight: '-10px' }} onClick={() => {
                            setShowMessage(true);
                            setPropertyData(row);
                        }} />
                    </OverlayTrigger>}
                </>)
            }
        },

    ];

    const handleLocationSelect = useCallback(() => {
        setShowLocations(true);
    }, []);

    const onReviewProperty = useCallback(() => {
        const payload = {
            rfpId: data?.rfpId,
            brandId: data?.brandId,
            propertyId: propertyData?.id,
            propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEWED,
            cityName: propertyData?.city,
            isClient:false
        }
        reviewPropertyForRFP(payload);
    }, [data?.brandId, data?.rfpId, propertyData?.city, propertyData?.id, reviewPropertyForRFP]);


    const onAddedComment = useCallback(() => {
        const payload = {
            rfpId: data?.rfpId,
            brandId: data?.brandId,
            propertyId: propertyData?.id,
            propertyReviewComment: propertyReviewComment,
        }
        acceptCreateUpdatePropertyAcceptDeclineOfRFP(payload);
    }, [data?.brandId, data?.rfpId, propertyData?.id, propertyReviewComment, acceptCreateUpdatePropertyAcceptDeclineOfRFP])


    useEffect(() => {
        window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            {(!showLocations && !selectedCity) &&
                <>
                    <Row style={{
                        marginBottom: '1.5rem'
                    }} >
                        <Col xs={10}><div className="content-title">
                            <Breadcrumb>
                                <Breadcrumb.Item style={{
                                    fontWeight: 100,
                                }} >
                                    <Link to={{ pathname: "/admin/brand-rfp-management", state: { selectedBrandId: data?.brandId, defaultKey: 'myRFPs' } }}>{'ActiveRFPs'}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {data?.companyName}{' - '} {data?.programmeName}{' - '}   {moment(data?.startDate).format('DD MMM YYYY')}
                                </Breadcrumb.Item  >

                            </Breadcrumb>
                        </div></Col>

                    </Row>
                    <Row>
                        <Col xs={12} >
                            <div className="content-title">

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex bfr' md={12} style={{ marginTop: "46px" }}>
                            <h5 style={{ fontSize: '24px', fontWeight: 700, marginTop: "4px" }} className="pe-4">View locations to include properties for submission</h5>
                            <Button onClick={handleLocationSelect} id='edit-button'>View Locations</Button>
                        </Col>
                    </Row>
                    <Row style={{
                        marginTop: '80px'
                    }} >
                        <Col>
                            <div>
                                <DataTable
                                    columns={columns1}
                                    data={memoizedRfpProcessData}
                                    pagination
                                    responsive
                                    customStyles={tableStyles}
                                    theme='mysaTable'
                                />
                            </div>
                        </Col>
                    </Row>
                </>
            }

            {
                showLocations && <BrandAdminRfpForm fromPropertiesPage={true} setShowLocations={setShowLocations} setSelectedCity={setSelectedCity} data={data} setSelectedCityDetails={setSelectedCityDetails} setComponentName={setComponentName} setDefaultTab={setDefaultTab} />

            }
            {
                (!showLocations && selectedCity) && <BrandAdminLocationMap
                    selectedCity={selectedCity}
                    setSelectedCity={setSelectedCity}
                    setShowLocations={setShowLocations}
                    setRefreshPropertiesList={setRefreshPropertiesList}
                    data={data}
                    setComponentName={setComponentName}
                    setDefaultTab={setDefaultTab}
                    selectedCityDetails={selectedCityDetails}
                />
            }



            {propertyData?.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED && <Modal
                show={showMessage}
                onHide={() => { setShowMessage(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={10}><div style={{ textAlign: 'left' }}>

                            <Form.Control as="textarea" rows={5}
                                // placeholder="please provide comments"
                                name='declinedComment'
                                defaultValue={propertyAcceptInformation?.declineComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col >
                                    <div style={{ textAlign: 'center' }}>
                                        <Button onClick={() => { setShowMessage(false); }} type="submit" id='edit-button' style={{
                                            height: "40px",
                                            width: '220px',
                                            borderRadius: 30,
                                            paddingLeft: "30px",
                                            paddingRight: "30px",
                                            marginRight: '20px'
                                        }}>Close</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Modal.Body>
            </Modal>}



            {propertyData?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && <Modal
                show={showMessage}
                onHide={() => { setShowMessage(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={10}><div style={{ textAlign: 'left' }}>

                            <Form.Control as="textarea" rows={5}
                                // placeholder="please provide comments"
                                name='clarificationComment'
                                defaultValue={propertyAcceptInformation?.clarificationComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>
                    <Row>
                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                            <Form.Control as="textarea" rows={5}
                                // placeholder="please provide comments"
                                name='propertyReviewComment'
                                defaultValue={propertyAcceptInformation?.propertyReviewComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col >
                                    <div style={{ textAlign: 'center' }}>
                                        <Button onClick={() => { setShowMessage(false); }} type="submit" id='edit-button' style={{
                                            height: "40px",
                                            width: '220px',
                                            borderRadius: 30,
                                            paddingLeft: "30px",
                                            paddingRight: "30px",
                                            marginRight: '20px'
                                        }}>Close</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Modal.Body>
            </Modal>}

            {propertyData?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && <Modal
                show={showMessage}
                onHide={() => { setShowMessage(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={10}><div style={{ textAlign: 'left' }}>
                            <p>The rates for this property has been accepted. Please see below comments that require your consideration.</p>
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>

                    <Row>
                        <Col md={10}><div style={{ textAlign: 'left' }}>

                            <Form.Control as="textarea" rows={5}
                                // placeholder="please provide comments"
                                name='clarificationComment'
                                defaultValue={propertyAcceptInformation?.clarificationComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>

                    <Row>
                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                            <Form.Control as="textarea" rows={5} disabled
                                // placeholder="please provide comments"
                                name='propertyReviewComment'
                                defaultValue={propertyAcceptInformation?.propertyReviewComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col >
                                    <div style={{ textAlign: 'center' }}>
                                        <Button onClick={() => { setShowMessage(false); }} type="submit" id='edit-button' style={{
                                            height: "40px",
                                            width: '220px',
                                            borderRadius: 30,
                                            paddingLeft: "30px",
                                            paddingRight: "30px",
                                            marginRight: '20px'
                                        }}>Close</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                    {(showReviewMessage && !isPropertyReview) &&
                        <div>
                            <Row>
                                <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                    <Form.Control as="textarea" rows={5}
                                        placeholder="please provide comments"
                                        name='propertyReviewComment'
                                        // value={''}
                                        onChange={(e) => {
                                            setPropertyReviewComment(e.target.value);
                                        }}
                                    />
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>

                            <Row>
                                <Col md={10}>
                                    <Row className='formSpace'>
                                        <Col >
                                            <div style={{ textAlign: 'center' }}>
                                                <Button onClick={() => { (isNegotiation && !isPropertyReview) ? onAddedComment() : onReviewProperty(); }} type="submit" id='approve-button' style={{
                                                    height: "40px",
                                                    width: '220px',
                                                    borderRadius: 30,
                                                    paddingLeft: "30px",
                                                    paddingRight: "30px",
                                                    marginRight: '20px'
                                                }}>Save</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                        </div>


                    }
                    {(!isPropertyReview && !isNegotiation) && <Row >
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col >
                                    <div style={{ textAlign: 'center', }}>
                                        <Button onClick={() => { setShowReviewMessage(true); }} type="submit" id='edit-button' style={{
                                            height: "40px",
                                            width: '220px',
                                            borderRadius: 30,
                                            paddingLeft: "30px",
                                            paddingRight: "30px",
                                            marginRight: '20px'
                                        }}>Reply</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row>

                    }
                </Modal.Body>
            </Modal>}


        </div>
    )
}
export default BrandAdminMyRfpPropertiesHomePage;   