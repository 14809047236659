import React from 'react';
import { Route } from 'react-router-dom';


const PublicRoutes:React.FC<any> = ({ component: Component, ...rest }):JSX.Element => (
  <>
    <Route {...rest} render={(props) => (<Component {...props} />)} key={Math.random()} />
  </>
);

export default PublicRoutes;
