import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import rate1to7 from '../../../../../../images/icons/svg/rate-table-header-icon.svg';
// import rate8to14 from '../../../../../../images/icons/svg/rates/dropDownMoon8to14.svg';
// import rate15to25 from '../../../../../../images/icons/svg/rates/dropDownMoon15to25.svg';
// import rate29to90 from '../../../../../../images/icons/svg/rates/dropDownMoon29to90.svg';
// import rate90plus from '../../../../../../images/icons/svg/rates/dropDownMoon90plus.svg';
import moment from 'moment';
import { rfpPropertyStatusToInventory } from '../../../../../../common/constants';

/////////////////////////////// details block style////////////////////////////////////////
// const headStyle = { padding: '12%' } as React.CSSProperties;
const bodyTextStyleOne = { fontSize: '16px', padding: '20px 30px' } as React.CSSProperties;

const ClientPropertyRateDetails: React.FC<any> = ({ rates, property, inventoryMessage }): JSX.Element => {

    const [seasonalRate, setSeasonalRate] = useState<Array<any>>([]);

    useEffect(() => {
        // console.log(property, 'property')
        // console.log(rates, 'rates')
        setSeasonalRate(rates?.rateRFP?.corporateRate?.seasonalRate);
    }, [property, rates])

    return (<>
        <div>


            {rates?.rfpPropertyRateStatus !== rfpPropertyStatusToInventory.ACCEPTED &&
                <div className='new_tab_ctnt prt_tbl' style={{ padding: '40px !important', marginTop: '25px' }}>

                    <Row className='tble_rw mb-3'>
                        <Col span={9}>
                            <div>
                                <h3 className='fz-32'>Proposed rates</h3>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>
                                1-7 <img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>8-14<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>15-28<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>29-90<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>91+<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ background: '#e9e9e9' }}>
                        <Col span={9}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Standard Rate</div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardOldRate?.oneTo7}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardOldRate?.eightTo14}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardOldRate?.fifteenTo28}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardOldRate?.twentyNineTo90}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardOldRate?.ninetyOnePlus}</p>
                            </div>
                        </Col>
                    </Row>

                    {seasonalRate.map((rate, index) => {
                        return (
                            <Row style={(seasonalRate.length % 2 === 0) ? { background: '#e9e9e9' } : { background: '' }}>
                                <Col span={9}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Seasonal Rate {index + 1}</div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.oneTo7}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.eightTo14}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.fifteenTo28}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.twentyNineTo90}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.ninetyOnePlus}</p>
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}

                    <Row style={(seasonalRate.length % 2 === 0) ? { background: '' } : { background: '#e9e9e9' }}>
                        <Col span={9}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Cancellation Period (Nights)</div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDatesOld}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDatesOld}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDatesOld}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDatesOld}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDatesOld}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row style={(seasonalRate.length % 2 === 0) ? { background: '#e9e9e9' } : { background: '' }}>
                        <Col span={9}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Cancellation Charge (Nights)</div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDatesOld}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDatesOld}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDatesOld}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDatesOld}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDatesOld}</p>
                            </div>
                        </Col>
                    </Row>
                </div>}

            {
                rates?.rfpPropertyRateStatus === rfpPropertyStatusToInventory.ACCEPTED &&
                <div className='new_tab_ctnt prt_tbl' style={{ padding: '40px !important', marginTop: '25px' }}>
                    {/* <h3 className='fz-32'>Proposed rates</h3> */}
                    <Row className='tble_rw mb-3'>
                        <Col span={9}>
                            <div>
                                <h3 className='fz-32 mb-4'>Proposed rates</h3>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>
                                1-7 <img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>8-14<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>15-28<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>29-90<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>91+<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ background: '#e9e9e9' }}>
                        <Col span={9}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Standard Rate</div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardRate?.oneTo7}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardRate?.eightTo14}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardRate?.fifteenTo28}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardRate?.twentyNineTo90}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardRate?.ninetyOnePlus}</p>
                            </div>
                        </Col>
                    </Row>

                    {seasonalRate.map((rate, index) => {
                        return (
                            <Row style={(seasonalRate.length % 2 === 0) ? { background: '#e9e9e9' } : { background: '' }}>
                                <Col span={9}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Seasonal Rate {index + 1}</div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.oneTo7}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.eightTo14}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.fifteenTo28}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.twentyNineTo90}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.ninetyOnePlus}</p>
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}

                    <Row style={(seasonalRate.length % 2 === 0) ? { background: '' } : { background: '#e9e9e9' }}>
                        <Col span={9}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Cancellation Period (Nights)</div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row style={(seasonalRate.length % 2 === 0) ? { background: '#e9e9e9' } : { background: '' }}>
                        <Col span={9}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Cancellation Charge (Nights)</div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates}</p>
                            </div>
                        </Col>
                    </Row>
                </div>}

            {
                ((((Number(rates?.rateRFP?.corporateRate?.standardRate?.oneTo7) !== 0) &&
                    (rates?.rfpPropertyRateStatus === rfpPropertyStatusToInventory.REVIEWED && Number(rates?.rateRFP?.corporateRate?.standardOldRate?.oneTo7) !== Number(rates?.rateRFP?.corporateRate?.standardRate?.oneTo7))))
                    || ((rates?.rfpPropertyRateStatus === rfpPropertyStatusToInventory.REVIEW) && (Number(rates?.rateRFP?.corporateRate?.standardOldRate?.oneTo7) !== Number(rates?.rateRFP?.corporateRate?.standardRate?.oneTo7)) && (inventoryMessage?.length !== 0))) &&
                <div className='new_tab_ctnt prt_tbl' style={{ padding: '40px !important', marginTop: '25px' }}>

                    <Row className='tble_rw mb-3'>
                        <Col span={9}>
                            <div>
                                <h3 className='fz-32'>New proposed rates</h3>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>
                                1-7 <img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>8-14<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>15-28<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>29-90<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                        <Col span={3} >
                            <div className='tbl-hdr arw'>91+<img src={rate1to7} alt="rate" />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ background: '#e9e9e9' }}>
                        <Col span={9}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Standard Rate</div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardRate?.oneTo7}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardRate?.eightTo14}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardRate?.fifteenTo28}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardRate?.twentyNineTo90}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{property.currency} {rates?.rateRFP?.corporateRate?.standardRate?.ninetyOnePlus}</p>
                            </div>
                        </Col>
                    </Row>

                    {seasonalRate.map((rate, index) => {
                        return (
                            <Row style={(seasonalRate.length % 2 === 0) ? { background: '#e9e9e9' } : { background: '' }}>
                                <Col span={9}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Seasonal Rate {index + 1}</div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.oneTo7}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.eightTo14}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.fifteenTo28}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.twentyNineTo90}</p>
                                    </div>
                                </Col>
                                <Col span={3} >
                                    <div style={bodyTextStyleOne}>
                                        <p>{rate?.ninetyOnePlus}</p>
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}

                    <Row style={(seasonalRate.length % 2 === 0) ? { background: '' } : { background: '#e9e9e9' }}>
                        <Col span={9}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Cancellation Period (Nights)</div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row style={(seasonalRate.length % 2 === 0) ? { background: '#e9e9e9' } : { background: '' }}>
                        <Col span={9}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 30px' }}>Cancellation Charge (Nights)</div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates}</p>
                            </div>
                        </Col>
                        <Col span={3} >
                            <div style={bodyTextStyleOne}>
                                <p>{rates?.rateRFP?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates}</p>
                            </div>
                        </Col>
                    </Row>
                </div>}

            <div className='row mt-5'>
                <div className='col-md-6'>
                    <div className="grey_bx prt_grey_bx mt-0">
                        <h3 className='fz-32 mb-4'>Detail</h3>
                        <Row>
                            <Col span={12} style={{ fontWeight: 'bold' }}>Kitchen</Col>
                            <Col span={12}>{rates?.inventoryDetails[0]?.whatBestDescribeYourApartments}</Col>
                        </Row>
                        {
                            rates?.inventoryDetails[0]?.bed_type.map((value, index) => {
                                return (
                                    <Row style={{ marginTop: '3%' }}>
                                        <Col span={12} style={{ fontWeight: 'bold' }}> Bed type (Bedroom {index + 1})</Col>
                                        <Col span={12}>{value.name}</Col>
                                    </Row>
                                );
                            })
                        }
                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}> Individual apartments last renovated </Col>
                            <Col span={12}>{moment(rates?.inventoryDetails[0]?.whereWereIndividualApartments).format('YYYY')}</Col>
                        </Row>
                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}> Are all apartments non-smoking?</Col>
                            <Col span={12}>{rates?.inventoryDetails[0]?.areAllApartmentsNonSmoking}</Col>
                        </Row>
                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}> Apartments suitable for people with physical disabilities?</Col>
                            <Col span={12}>{rates?.inventoryDetails[0]?.howManyApartmentsAreSuitable}</Col>
                        </Row>
                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}> Total no. of apartments</Col>
                            <Col span={12}>{rates?.inventoryDetails[0]?.noOfApartments}</Col>
                        </Row>
                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}> No of guests </Col>
                            <Col span={12}>{rates?.inventoryDetails[0]?.noOfGuests}</Col>
                        </Row>
                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}> No of bedrooms </Col>
                            <Col span={12}>{rates?.inventoryDetails[0]?.noOfBedrooms}</Col>
                        </Row>
                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}> No of bathrooms </Col>
                            <Col span={12}>{rates?.inventoryDetails[0]?.noOfBathrooms}</Col>
                        </Row>
                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}> No of roll-out beds available </Col>
                            <Col span={12}>{rates?.inventoryDetails[0]?.noOfRollOutBedsAvailable}</Col>
                        </Row>
                        {
                            rates?.inventoryDetails[0]?.noOfRollOutBedsAvailable !== 0 &&
                            <Row style={{ marginTop: '3%' }}>
                                <Col span={12} style={{ fontWeight: 'bold' }}> Extra cost per bed  </Col>
                                <Col span={12}>{property?.currency} {rates?.inventoryDetails[0]?.extraCostPerBed}</Col>
                            </Row>}
                        {
                            rates?.inventoryDetails[0]?.noOfRollOutBedsAvailable !== 0 &&
                            <Row style={{ marginTop: '3%' }}>
                                <Col span={12} style={{ fontWeight: 'bold' }}> Type of cost </Col>
                                <Col span={12}>{rates?.inventoryDetails[0]?.costTypeBed}</Col>
                            </Row>}

                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}>No of cots available </Col>
                            <Col span={12}>{rates?.inventoryDetails[0]?.noOfCotsAvailable}</Col>
                        </Row>

                        {rates?.inventoryDetails[0]?.noOfCotsAvailable !== 0 && <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}>Extra cost per cot  </Col>
                            <Col span={12}>{property?.currency} {rates?.inventoryDetails[0]?.extraCostOfCot}</Col>
                        </Row>}
                        {rates?.inventoryDetails[0]?.noOfCotsAvailable !== 0 && <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}>Type of cost </Col>
                            <Col span={12}>{rates?.inventoryDetails[0]?.costTypeCot}</Col>
                        </Row>}

                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}>Minimum stay </Col>
                            <Col span={12}>{rates?.inventoryDetails[0]?.minimumStayed}</Col>
                        </Row>
                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}>Television type </Col>
                            <Col span={12}>{
                                rates?.inventoryDetails[0]?.inventoryAmenities?.provideInformationTvs?.map((value) => {
                                    return (
                                        <span>{value}, </span>
                                    );
                                })
                            }</Col>
                        </Row>
                        <Row style={{ marginTop: '3%' }}>
                            <Col span={12} style={{ fontWeight: 'bold' }}>Television size </Col>
                            <Col span={12}>{
                                `${rates?.inventoryDetails[0]?.inventoryAmenities?.sizeOfTv?.text} ${rates?.inventory?.inventoryAmenities?.sizeOfTv?.size}`
                            }</Col>
                        </Row>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='strok_bx prt_grey_bx'>
                        <h3 className='fz-32 mb-4'>Apartment amenities</h3>
                        {
                            rates?.inventoryDetails[0]?.inventoryAmenities?.amenities?.map((value) => {
                                return (
                                    <div className='icn_list'>
                                        <img style={{
                                            width: '50px',
                                        }} src={value.iconLocation} alt={value.name} />
                                        {value.name}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </>)

}

export default ClientPropertyRateDetails;