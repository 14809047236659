import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useStoreState } from "easy-peasy";
import _ from "lodash";
import { brandRfpStatus } from "../../../common/constants";
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import {
  tableStyles,
  $mysaMegenta,
  $mysaTeal,
  $mysaYellow,
} from "../../../common/components-style";
import moment from "moment";
import { setStorage } from "../../../common/storage";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";

const ActiveRfpsList: React.FC<any> = (): JSX.Element => {
  const history = useHistory();
  const [myRFPs, setmyRFPs] = useState<any>([]);
  const [activeRfp, setActiveRfp] = useState(false);

  const { brandDashboardSuccess, brandDashboardError } = useStoreState<any>(
    (state) => ({
      brandDashboardSuccess: state.DashboardModel.brandDashboardSuccess,
      brandDashboardError: state.DashboardModel.brandDashboardError,
    })
  );

  useEffect(() => {
    if (brandDashboardError) {
      toast.error(brandDashboardError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    if (brandDashboardSuccess) {
      setmyRFPs(brandDashboardSuccess?.data?.rfpList?.myRFPs);
    }
  }, [brandDashboardError, brandDashboardSuccess]);

  const rfpHandler = () => {
    setActiveRfp(!activeRfp);
  };

  const handleOpen = useCallback(
    (data) => {
      let path = "/admin/brand-rfp/brandMyRfp";
      if (data.brandRfpStatus === "submitted") {
        path = "admin/brand-submittedRfp/brandSubmittedRfp";
      }
      setTimeout(() => {
        history.push({
          pathname: `${path}`,
          state: data,
        });
      }, 500);

      // updateBrandRFPData({ ...reduxBrandDetail, showHomePage: false, myRfpFormDetail: data, formData: data, showMyRfpBrandEvaluationPage: true, showMyRfpHomePage: false });
    },
    [history]
  );

  const columns = [
    {
      name: "",
      cell: (row: any) => {
        return (
          <>
            {/* <a href= {`${process.env.REACT_APP_MYO_CORPORATE_URL}admin/brand-rfp/brandMyRfp`}>
                            <img className='iconSizes' src={ViewEdit} alt="" />  
                        </a>  */}
            <img
              className="iconSizes mr-2"
              src={ViewEdit}
              alt=""
              onClick={() => {
                handleOpen(row);
                setStorage("myo_selected_my_rfp", row);
              }}
            />
          </>
        );
      },
    },
    {
      name: "Client",
      cell: (row) => row.companyName,
    },
    {
      name: "Programme",
      selector: (row) => row.programmeName,
    },
    {
      name: "Due Date",
      selector: (row) => moment(row.endDate).format("D MMMM YYYY"),
    },
    {
      name: "Status",
      cell: (row) => {
        let color = "";
        let text = "";
        if (row.brandRfpStatus === brandRfpStatus.SUBMITTED) {
          color = $mysaYellow;
          if ((row.releasedStatus && row.reviewedProperty !== row.totalProperty) || row.reviewedProperty === 0) {
            text = "Under Review";
          } else if ((row.reviewedProperty === row.totalProperty) && row.releasedStatus) {
            color = $mysaTeal;
            text = "Review Complete";
          }
          else {
            color = $mysaTeal;
            text = row.brandRfpStatus;
          }
        }
        if (row.brandRfpStatus === brandRfpStatus.COMPLETED) {
          color = $mysaYellow;
          text = row.brandRfpStatus;
        }

        if (row.brandRfpStatus === brandRfpStatus.INCOMPLETE) {
          color = $mysaMegenta;
          text = row.brandRfpStatus;
        }
        return (
          <span
            style={{ color: color, fontWeight: "bold" }}
            className="text-capitalize"
          >
            {text}
          </span>
        );
      },
    },
  ];
  return (
    <>
      <div className="tbl_accr_itm" style={{ backgroundColor: "#E80F8B" }}>
        <div className="tbl_hdr" style={{ backgroundColor: "#E80F8B" }}>
          <h5 className="m-0 fw-bold text-white">Active RFPs</h5>
          <button className="text-white pointer" onClick={rfpHandler}>
            Expand
            <span>
              <img src={activeRfp ? minus : plus} alt="icon" />
            </span>
          </button>
        </div>
        <div className="acrd_tbl">
          <DataTable
            columns={columns}
            data={myRFPs}
            striped={true}
            customStyles={tableStyles}
            theme="mysaTable"
            fixedHeader={true}
            noDataComponent={"Updating records"}
          />
        </div>
      </div>
    </>
  );
};

export default ActiveRfpsList;
