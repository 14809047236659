import { Select } from 'antd';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import currency from '../../../../common/currency.json';
import React, { useCallback, useEffect, useState } from 'react';
import { UserAccess } from '../../../../common/constants';
import { canCorporateProgrammeModuleWrite } from '../../../../common/functions';
import GoogleMap from "../../../common/GoogleMap";
import GooglePlaces from "../../../common/GooglePlaces";
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { formButton } from '../../../../common/components-style';

const { Option } = Select;



const LocationDetailsForm: React.FC<any> = ({ locationId, selectedProgramme, reload }): JSX.Element => {
	const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<any>();
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const [locationSingleData, setLocationSingleData] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);
	const [location, setLocation] = useState<any>(null);
	const [coordinates, setCoordinates] = useState<any>(null);


	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { resetClient, getClientLocationById, createClientLocation, updateClientLocation } = useStoreActions<any>((actions) => ({
		getClientLocationById: actions.client.getClientLocationById,
		resetClient: actions.client.resetClient,
		createClientLocation: actions.client.createClientLocation,
		updateClientLocation: actions.client.updateClientLocation,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {
		getClientLocationByIdSuccess,
		createClientLocationSuccess,
		createClientLocationError,
		updateClientLocationSuccess,
		updateClientLocationError,
	} = useStoreState<any>((state) => ({
		getClientLocationByIdSuccess: state.client.getClientLocationByIdSuccess,
		createClientLocationSuccess: state.client.createClientLocationSuccess,
		createClientLocationError: state.client.createClientLocationError,
		updateClientLocationSuccess: state.client.updateClientLocationSuccess,
		updateClientLocationError: state.client.updateClientLocationError,
	}));


	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (locationId) {
			getClientLocationById(locationId);
		}
	}, [getClientLocationById, locationId]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getClientLocationByIdSuccess) {
			const {
				locationName,
				address1,
				address2,
				city,
				country,
				latitude,
				longitude,
				postcode,
				approximateLocationSpend,
				approximateNoOfNights,
				minimumStay,
				expectedMaximumStay,
				averageLengthOfStay,
				currency,
				countryCode
			} = getClientLocationByIdSuccess.data;

			setLocationSingleData(getClientLocationByIdSuccess.data);

			setValue('locationName', locationName);
			setValue('address1', address1);
			setValue('address2', address2);
			setValue('city', city);
			setValue('country', country);
			setValue('latitude', latitude);
			setValue('longitude', longitude);
			setValue('postcode', postcode);
			setValue('approximateLocationSpend', approximateLocationSpend);
			setValue('approximateNoOfNights', approximateNoOfNights);
			setValue('minimumStay', minimumStay);
			setValue('expectedMaximumStay', expectedMaximumStay);
			setValue('averageLengthOfStay', averageLengthOfStay);
			setValue('countryCode', countryCode);
			setValue('currency', currency);

			setLocation({
				lat: Number(latitude),
				lng: Number(longitude),
			});
		}


	}, [getClientLocationByIdSuccess, setValue]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (createClientLocationSuccess) {
			setLoading(false);
			toast.success("Location created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			reload();
			resetClient();
		}
		if (createClientLocationError) {
			setLoading(false);
			toast.error(createClientLocationError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetClient();
		}
		if (updateClientLocationSuccess) {
			setLoading(false);
			toast.success("Location updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			reload();
			resetClient();
		}
		if (updateClientLocationError) {
			setLoading(false);
			toast.error(updateClientLocationError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetClient();
		}

	}, [createClientLocationError, createClientLocationSuccess, reload, resetClient, updateClientLocationError, updateClientLocationSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const setCoords = useCallback((coords) => {
		setCoordinates({ lat: coords.lat, lng: coords.lng });
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const setLocationData = useCallback((location) => {
		setValue('country', location?.country);
		setValue('location', location.address1);
		setValue('postcode', location?.postcode);

		setLocation(location);
	},
		[setValue]
	);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderCurrency = useCallback(() => {
		if (currency) {
			return currency.map((cur: any, key: number) => {
				return (
					<Option key={key} value={`${cur.cc}`} >{cur.name} <span className="text-blue-400 font-bold ml-2">({cur.symbol}) - {cur.cc}</span></Option>
				)
			});
		}

	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onFinish = useCallback((values) => {
		setLoading(true);
		delete values.location;
		if (coordinates) {
			values.latitude = coordinates.lat.toString();
			values.longitude = coordinates.lng.toString();
		} else {
			values.latitude = location.lat.toString();
			values.longitude = location.lng.toString();
			values.countryCode = location.countryCode;
		}
	
		if (locationId) {
			values.id = locationId;
			const data = {
				id: locationId,
				locationName: values.locationName,
				address1: values.address1,
				address2: values.address2,
				city: values.city.trim(),
				country: values.country.trim(),
				postcode: values.postcode,
				latitude: values.latitude,
				longitude: values.longitude,
				approximateLocationSpend: values.approximateLocationSpend,
				approximateNoOfNights: values.approximateNoOfNights,
				minimumStay: values.minimumStay,
				expectedMaximumStay: values.expectedMaximumStay,
				averageLengthOfStay: values.averageLengthOfStay,
				currency: values.currency
			};
			updateClientLocation(data);
		} else {
			values.clientId = selectedProgramme.clientId?._id ? selectedProgramme.clientId?._id : selectedProgramme.clientId;
			values.corporateProgrammeId = selectedProgramme._id ? selectedProgramme._id : selectedProgramme?.key;
			createClientLocation(values);
		}

	}, [coordinates, createClientLocation, location, locationId, selectedProgramme, updateClientLocation]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div>
			{/* <div className="text-right" style={{ position: "fixed", zIndex: 9999, top: "110px", right: "22px", width: "100%", maxWidth: "80%", padding: "0 15px" }}>
				{
					!loading && !editEnable && canCorporateProgrammeModuleWrite(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) && <Button id='edit-button' style={formButton} onClick={() => { setEditEnable(true) }} >Edit</Button>
				}
			</div> */}

			<div className="ml-auto edit_btn_wpr" >
				{
					!loading && !editEnable && canCorporateProgrammeModuleWrite(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) && <Button id='edit-button' style={formButton} onClick={() => { setEditEnable(true) }} >Edit</Button>
				}
			</div>
			<div style={{ paddingLeft: "40px" }}>
				<div className="jumbotron">
					<Form onSubmit={handleSubmit(onFinish)}>
						<Row>
							<Col md={1}></Col>
							<Col md={10}>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Location name</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.locationName}</p>}
										{editEnable && <Form.Control className={`${errors.locationName ? 'is-invalid' : ''}`} {...register("locationName", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input location name!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>Location address</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.address1}</p>}
										{editEnable && <GooglePlaces onPlaceSelect={(location) => setLocationData(location)} />}
										<div style={{ marginTop: '10px' }}></div>
										<GoogleMap
											googleMapURL={process.env.REACT_APP_MAP_URL}
											loadingElement={<div style={{ height: `100%` }} />}
											containerElement={<div style={{ height: `350px` }} />}
											mapElement={<div style={{ height: `100%` }} />}
											location={location}
											setCoords={setCoords}
										/>
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Address1</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.address1}</p>}
										{editEnable && <Form.Control className={`${errors.address1 ? 'is-invalid' : ''}`} {...register("address1", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input address1</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>Address2</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.address2}</p>}
										{editEnable && <Form.Control className={`${errors.address2 ? 'is-invalid' : ''}`} {...register("address2")} />}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} City</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.city}</p>}
										{editEnable && <Form.Control className={`${errors.city ? 'is-invalid' : ''}`} {...register("city", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input city</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Postal code</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.postcode}</p>}
										{editEnable && <Form.Control className={`${errors.postcode ? 'is-invalid' : ''}`} {...register("postcode", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input postcode</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Country</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.country}</p>}
										{editEnable && <Form.Control className={`${errors.country ? 'is-invalid' : ''}`} {...register("country", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input country</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Approximate location spend</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.approximateLocationSpend}</p>}
										{editEnable && <Form.Control type='number' min={0} className={`${errors.approximateLocationSpend ? 'is-invalid' : ''}`} {...register("approximateLocationSpend", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input  approximate location spend</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>}Currency</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.currency}</p>}
										{editEnable &&
											<Controller
												name="currency"
												control={control}
												rules={{
													required: true
												}}
												render={({ field }) =>

													<Select showSearch style={{ width: '100%' }} value={field.value} onChange={(value) => {
														field.onChange(value);
													}}>
														{renderCurrency()}
													</Select>

												}
											/>}
										{editEnable && errors.currency && <div className="invalid-feedback-custom">Please select currency!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Approximate no of nights</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.approximateNoOfNights}</p>}
										{editEnable && <Form.Control type='number' min={0} className={`${errors.approximateNoOfNights ? 'is-invalid' : ''}`} {...register("approximateNoOfNights", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input  Approximate no of nights</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Minimum stay requirement</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.minimumStay}</p>}
										{editEnable && <Form.Control type='number' min={0} className={`${errors.minimumStay ? 'is-invalid' : ''}`} {...register("minimumStay", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input  Minimum stay requirement</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Expected maximum stay</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.expectedMaximumStay}</p>}
										{editEnable && <Form.Control type='number' min={0} className={`${errors.expectedMaximumStay ? 'is-invalid' : ''}`} {...register("expectedMaximumStay", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input  Expected maximum stay</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Average length of stay</Form.Label>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{locationSingleData?.averageLengthOfStay}</p>}
										{editEnable && <Form.Control type='number' min={0} className={`${errors.averageLengthOfStay ? 'is-invalid' : ''}`} {...register("averageLengthOfStay", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input  Average length of stay</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={4} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={8} style={{ textAlign: 'left' }}>
										{!loading && editEnable && canCorporateProgrammeModuleWrite(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) &&
											<Button type="submit" id='approve-button' style={formButton} > {locationId ? 'Update' : 'Create'}</Button>
										}

										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={1}></Col>
						</Row>
					</Form>
				</div>
			</div>
		</div>
	)
};

export default LocationDetailsForm;