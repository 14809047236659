
import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs, } from "react-google-maps";
// import markerIcon from '../../images/icons/marker.png';
import markerIcon from '../../images/icons/svg/outside/Icon-Location-Finder-M.svg';
import propertyDirectIcon from '../../images/icons/svg/outside/Icon-Location-Finder-K.svg';

const MyoBookingGoogleMap: React.FC<any> = ({ location, building, }): JSX.Element => {
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [activeMarker, setActiveMarker] = useState(null);
  const [locationName, setLocationName] = useState<any>('');

  const icon = {
    url: markerIcon,
    scaledSize: new google.maps.Size(45, 45)
  };


  const buildingDirectIcon = {
    url: propertyDirectIcon,
    scaledSize: new google.maps.Size(45, 45)
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (building) {
      setLat(building[0]?.position?.lat);
      setLng(building[0]?.position?.lng);
      if (location) {
        setLat(location?.lat);
        setLng(location?.lng);
        setLocationName(location?.locationName);
      }
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }

  }, [building, location]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onDragEnd = useCallback(({ latLng }) => {
    setLat(latLng.lat());
    setLng(latLng.lng());

  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="google-map">
      <GoogleMap
        key={Math.random()}
        defaultZoom={13}
        defaultCenter={{ lat, lng }}
        onClick={() => setActiveMarker(null)}
      >
        {/* {building && <Marker draggable onDragEnd={onDragEnd} position={{ lat, lng }} icon={icon} />} */}
        {location && <Marker draggable onDragEnd={onDragEnd} position={{ lat, lng }} icon={icon} >
          <InfoWindow onCloseClick={() => setActiveMarker(null)}>
            <div>
              <p>{locationName}</p>
            </div>

          </InfoWindow>
        </Marker>}
        {building && building.map(({ id, name, position, address }) => (
          <Marker
            key={id}
            position={position}
            onClick={() => handleActiveMarker(id)}
            icon={buildingDirectIcon}
          >
            {activeMarker === id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>
                  <p>{name}</p>
                  <p>{address}</p>
                </div>

              </InfoWindow>
            ) : null}
          </Marker>
        ))}

      </GoogleMap>
    </div>
  )
};

export default withScriptjs(withGoogleMap(MyoBookingGoogleMap));