/* eslint-disable import/no-anonymous-default-export */

import { action, thunk } from 'easy-peasy';
import { callMYOManagement } from '../common/axios';

export default {
  createMYOManagementClientSuccess: null,
  createMYOManagementClientError: null,
  reAssignCertificateMYOManagementSuccess: null,
  reAssignCertificateMYOManagementError: null,
  updateMYOManagementClientSuccess: null,
  updateMYOManagementClientError: null,
  createMYOManagementBuildingSuccess: null,
  createMYOManagementBuildingError: null,
  createCertificateTemplateSuccess: null,
  createCertificateTemplateError: null,
  updateCertificateTemplateSuccess: null,
  updateCertificateTemplateError: null,
  deleteCertificateTemplateSuccess: null,
  deleteCertificateTemplateError: null,
  getUpdateCertificateTemplateSuccess: null,
  getUpdateCertificateTemplateError: null,
  getTemplateSuccess: null,
  getTemplateError: null,
  getTemplateDataSuccess: null,
  getTemplateDataError: null,
  getCreateSurveySuccess: null,
  getCreateSurveyError: null,
  getUpdateSurveySuccess: null,
  getUpdateSurveyError: null,
  getCreateCertificateSuccess: null,
  getCreateCertificateError: null,
  getViewCertificateSuccess: null,
  getViewCertificateError: null,
  getMYOCertificateSuccess: null,
  getMYOCertificateError: null,
  getMYOSurveysSuccess: null,
  getMYOSurveysError: null,
  submitSurveySuccess: null,
  submitSurveyError: null,
  myoAnswerSuccess: null,
  myoAnswerError: null,
  myoEvidenceSuccess: null,
  myoEvidenceError: null,
  getSubmittedSurveySuccess: null,
  getSubmittedSurveyError: null,

  // audit
  getAuditCertificateSuccess: null,
  getAuditCertificateError: null,
  getAuditSurveysSuccess: null,
  getAuditSurveysError: null,
  reset: false,
  // actions
  setCreateClient: action((state, payload) => ({ createMYOManagementClientSuccess: payload })),
  setCreateClientError: action((state, payload) => ({ createMYOManagementClientError: payload })),
  setReAssignCertificateMYOManagementSuccess: action((state, payload) => ({ reAssignCertificateMYOManagementSuccess: payload })),
  setReAssignCertificateMYOManagementError: action((state, payload) => ({ reAssignCertificateMYOManagementError: payload })),
  setUpdateMYOManagementClientSuccess: action((state, payload) => ({ updateMYOManagementClientSuccess: payload })),
  setUpdateMYOManagementClientError: action((state, payload) => ({ updateMYOManagementClientError: payload })),
  setCreateBuilding: action((state, payload) => ({ createMYOManagementBuildingSuccess: payload })),
  setCreateBuildingError: action((state, payload) => ({ createMYOManagementBuildingError: payload })),
  setCreateCertificateTemplateSuccess: action((state, payload) => ({ createCertificateTemplateSuccess: payload })),
  setCreateCertificateTemplateError: action((state, payload) => ({ createCertificateTemplateError: payload })),
  setUpdateCertificateTemplateSuccess: action((state, payload) => ({ updateCertificateTemplateSuccess: payload })),
  setUpdateCertificateTemplateError: action((state, payload) => ({ updateCertificateTemplateError: payload })),
  setDeleteCertificateTemplateSuccess: action((state, payload) => ({ deleteCertificateTemplateSuccess: payload })),
  setDeleteCertificateTemplateError: action((state, payload) => ({ deleteCertificateTemplateError: payload })),
  setUpdateCertificateTemplateToRfpSuccess: action((state, payload) => ({ getUpdateCertificateTemplateSuccess: payload })),
  setUpdateCertificateTemplateToRfpError: action((state, payload) => ({ getUpdateCertificateTemplateError: payload })),
  setTemplateSuccess: action((state, payload) => ({ getTemplateSuccess: payload })),
  setTemplateError: action((state, payload) => ({ getTemplateError: payload })),
  setTemplateDataSuccess: action((state, payload) => ({ getTemplateDataSuccess: payload })),
  setTemplateDataError: action((state, payload) => ({ getTemplateDataError: payload })),
  setCreateSurveySuccess: action((state, payload) => ({ getCreateSurveySuccess: payload })),
  setCreateSurveyError: action((state, payload) => ({ getCreateSurveyError: payload })),
  setUpdateSurveySuccess: action((state, payload) => ({ getUpdateSurveySuccess: payload })),
  setUpdateSurveyError: action((state, payload) => ({ getUpdateSurveyError: payload })),
  setCreateCertificateSuccess: action((state, payload) => ({ getCreateCertificateSuccess: payload })),
  setCreateCertificateError: action((state, payload) => ({ getCreateCertificateError: payload })),
  setViewCertificateSuccess: action((state, payload) => ({ getViewCertificateSuccess: payload })),
  setViewCertificateError: action((state, payload) => ({ getViewCertificateError: payload })),
  setGetMYOCertificateSuccess: action((state, payload) => ({ getMYOCertificateSuccess: payload })),
  setGetMYOCertificateError: action((state, payload) => ({ getMYOCertificateError: payload })),
  setGetMYOSurveysSuccess: action((state, payload) => ({ getMYOSurveysSuccess: payload })),
  setGetMYOSurveysError: action((state, payload) => ({ getMYOSurveysError: payload })),
  setSubmitSurveySuccess: action((state, payload) => ({ submitSurveySuccess: payload })),
  setSubmitSurveyError: action((state, payload) => ({ submitSurveyError: payload })),
  setMYOAnswerSuccess: action((state, payload) => ({ myoAnswerSuccess: payload })),
  setMYOAnswerError: action((state, payload) => ({ myoAnswerError: payload })),
  setMYOEvidenceSuccess: action((state, payload) => ({ myoEvidenceSuccess: payload })),
  setMYOEvidenceError: action((state, payload) => ({ myoEvidenceError: payload })),

  setGetSubmittedSurveySuccess: action((state, payload) => ({ getSubmittedSurveySuccess: payload })),
  setGetSubmittedSurveyError: action((state, payload) => ({ getSubmittedSurveyError: payload })),

  ////////////////// audit
  setGetAuditCertificateSuccess: action((state, payload) => ({ getAuditCertificateSuccess: payload })),
  setGetAuditCertificateError: action((state, payload) => ({ getAuditCertificateError: payload })),
  setGetAuditSurveysSuccess: action((state, payload) => ({ getAuditSurveysSuccess: payload })),
  setGetAuditSurveysError: action((state, payload) => ({ getAuditSurveysError: payload })),
  resetMyo: action((state, payload) => ({ reset: true })),
  resetAudit: action((state, payload) => ({ reset: true })),
  // thunks
  createMYOManagementClient: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'Client/Create', payload, null);
      actions.setCreateClient(result.data);
    } catch (error: any) {
      actions.setCreateClientError(error.response.data);
    }
  }),


  reAssignCertificateMYOManagement: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'certificate/reassign', payload, null);
      actions.setReAssignCertificateMYOManagementSuccess(result.data);
    } catch (error: any) {
      // actions.setReAssignCertificateError(error.response.data);
    }
  }),
  updateMYOManagementClient: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'Client/Update', payload, null);
      actions.setUpdateMYOManagementClientSuccess(result.data);
    } catch (error: any) {
      actions.setUpdateMYOManagementClientError(error.response.data);
    }
  }),


  createMYOManagementBuilding: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'Building/Create', payload, null);
      actions.setCreateBuilding(result.data);
    } catch (error: any) {
      actions.setCreateBuildingError(error.response.data);
    }
  }),


  updateMYOManagementBuilding: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'Building/Update', payload, null);
      actions.setUpdateBuilding(result.data);
    } catch (error: any) {
      // actions.setUpdateBuildingError(error.response.data);
    }
  }),


  createCertificateTemplate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'Certificate/template/create', payload, null);
      actions.setCreateCertificateTemplateSuccess(result.data);
    } catch (error: any) {
      actions.setCreateCertificateTemplateError(error.response.data);
    }
  }),


  updateCertificateTemplate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'Certificate/template/update', payload, null);
      actions.setUpdateCertificateTemplateSuccess(result.data);
    } catch (error: any) {
      actions.setUpdateCertificateTemplateError(error.response.data);
    }
  }),



  deleteCertificateTemplate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'Certificate/template/delete', payload, null);
      actions.setDeleteCertificateTemplateSuccess(result.data);
    } catch (error: any) {
      actions.setDeleteCertificateTemplateError(error.response.data);
    }
  }),

  updateCertificateTemplateToRfp: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'Certificate/template/update', payload, null);
      actions.setUpdateCertificateTemplateToRfpSuccess(result.data);
    } catch (error: any) {
      actions.setUpdateCertificateTemplateToRfpError(error.response.data);
    }
  }),

  getTemplate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'Certificate/template/get', payload, null);
      actions.setTemplateSuccess(result.data);
    } catch (error: any) {
      actions.setTemplateError(error.response.data);
    }
  }),

  getTemplateData: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'Certificate/template/get', payload, null);
      actions.setTemplateDataSuccess(result.data);
    } catch (error: any) {
      actions.setTemplateDataError(error.response.data);
    }
  }),

  createSurvey: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'survey/Create', payload, null);
      actions.setCreateSurveySuccess(result.data);
    } catch (error: any) {
      actions.setCreateSurveyError(error.response.data);
    }
  }),


  updateSurvey: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'survey/update', payload, null);
      actions.setUpdateSurveySuccess(result.data);
    } catch (error: any) {
      actions.setUpdateSurveyError(error.response.data);
    }
  }),


  createCertificate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'certificate/create', payload, null);
      actions.setCreateCertificateSuccess(result.data);
    } catch (error: any) {
      actions.setCreateCertificateError(error.response.data);
    }
  }),


  viewCertificate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'certificate/view', payload, null);
      actions.setViewCertificateSuccess(result.data);
    } catch (error: any) {
      actions.setViewCertificateError(error.response.data);
    }
  }),


  getMYOCertificate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'todo/certificate', payload, null);
      actions.setGetMYOCertificateSuccess(result.data);
    } catch (error: any) {
      actions.setGetMYOCertificateError(error.response.data);
    }
  }),


  getMYOSurveys: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'todo/survey', payload, null);
      actions.setGetMYOSurveysSuccess(result.data);
    } catch (error: any) {
      actions.setGetMYOSurveysError(error.response.data);
    }
  }),


  submitSurvey: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'survey/submit', payload, null);
      actions.setSubmitSurveySuccess(result.data);
    } catch (error: any) {
      actions.setSubmitSurveyError(error.response.data);
    }
  }),
  answerQuestion: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'survey/answer', payload, null);
      actions.setMYOAnswerSuccess(result.data);
    } catch (error: any) {
      actions.setMYOAnswerError(error.response.data);
    }
  }),


  answerQuestionEvidence: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'survey/venusaddevidence', payload, null);
      actions.setMYOEvidenceSuccess(result.data);
    } catch (error: any) {
      actions.setMYOEvidenceError(error.response.data);
    }
  }),

  SurveySubmission: thunk(async (actions: any, payload: any) => {
    try {
      await callMYOManagement('post', `certificate/survey-submission`, payload, null);
    } catch (error: any) {

    }
  }
  ),

  /////////////////// audit
  getAuditCertificate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'todo/certificate', payload, null);
      actions.setGetAuditCertificateSuccess(result.data);
    } catch (error: any) {
      actions.setGetAuditCertificateError(error.response.data);
    }
  }),
  getAuditSurveys: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callMYOManagement('post', 'todo/survey', payload, null);
      actions.setGetAuditSurveysSuccess(result.data);
    } catch (error: any) {
      actions.setGetAuditSurveysError(error.response.data);
    }
  }),

  getSubmittedSurvey: thunk(async (actions: any, payload: any) => {
    try {
      
      const result = await callMYOManagement('post', 'todo/survey/submitted', payload, null);
      actions.setGetSubmittedSurveySuccess(result.data);
    } catch (error: any) {
      actions.setGetSubmittedSurveyError(error.message);
    }
  }),
}