/* eslint-disable import/no-anonymous-default-export */

import { action, thunk } from 'easy-peasy';
import {callAudit } from '../common/axios';

export default {
  createAuditClientSuccess: null,
  createAuditClientError: null,
  updateAuditClientSuccess: null,
  updateAuditClientError: null,
  createAuditBuildingSuccess: null,
  createAuditBuildingError: null,
  updateAuditBuildingSuccess: null,
  updateAuditBuildingError: null,
  getAuditBuildingSuccess: null,
  getAuditBuildingError: null,
  getAuditCertificatesSuccess: null,
  getAuditCertificatesError: null,
  getAuditPropertyCertificatesSuccess: null,
  getAuditPropertyCertificatesError: null,
  getAuditCertificateSuccess: null,
  getAuditCertificateError: null,
  getAuditSurveysSuccess: null,
  getAuditSurveysError: null,
  auditAnswerSuccess: null,
  auditAnswerError: null,
  auditEvidenceSuccess: null,
  auditEvidenceError: null,
  reAssignCertificateSuccess: null,
  reAssignCertificateError: null,
  submitSurveySuccess: null,
  submitSurveyError: null,
  getSectionSuccess: null,
  getSectionError: null,
  reset: false,

  // actions
  setCreateClient: action((state, payload) => ({ createAuditClientSuccess: payload })),
  setCreateClientError: action((state, payload) => ({ createAuditClientError: payload })),
  setUpdateClient: action((state, payload) => ({ updateAuditClientSuccess: payload })),
  setUpdateClientError: action((state, payload) => ({ updateAuditClientError: payload })),
  resetAudit: action((state, payload) => ({ reset: true })),
  setCreateBuilding: action((state, payload) => ({ createAuditBuildingSuccess: payload })),
  setCreateBuildingError: action((state, payload) => ({ createAuditBuildingError: payload })),
  setUpdateBuilding: action((state, payload) => ({ updateAuditBuildingSuccess: payload })),
  setUpdateBuildingError: action((state, payload) => ({ updateAuditBuildingError: payload })),
  setGetBuilding: action((state, payload) => ({ getAuditBuildingSuccess: payload })),
  setGetBuildingError: action((state, payload) => ({ getAuditBuildingError: payload })),
  setGetSectionSuccess: action((state, payload) => ({ getSectionSuccess: payload })),
  setGetSectionError: action((state, payload) => ({ getSectionError: payload })),
  setGetAuditCertificatesSuccess: action((state, payload) => ({ getAuditCertificatesSuccess: payload })),
  setGetAuditCertificatesError: action((state, payload) => ({ getAuditCertificatesError: payload })),
  setGetAuditPropertyCertificatesSuccess: action((state, payload) => ({ getAuditPropertyCertificatesSuccess: payload })),
  setGetAuditPropertyCertificatesError: action((state, payload) => ({ getAuditPropertyCertificatesError: payload })),
  setGetAuditCertificateSuccess: action((state, payload) => ({ getAuditCertificateSuccess: payload })),
  setGetAuditCertificateError: action((state, payload) => ({ getAuditCertificateError: payload })),
  setGetAuditSurveysSuccess: action((state, payload) => ({ getAuditSurveysSuccess: payload })),
  setGetAuditSurveysError: action((state, payload) => ({ getAuditSurveysError: payload })),
  setAuditAnswerSuccess: action((state, payload) => ({ auditAnswerSuccess: payload })),
  setAuditAnswerError: action((state, payload) => ({ auditAnswerError: payload })),
  setAuditEvidenceSuccess: action((state, payload) => ({ auditEvidenceSuccess: payload })),
  setAuditEvidenceError: action((state, payload) => ({ auditEvidenceError: payload })),
  setReAssignCertificateSuccess: action((state, payload) => ({ reAssignCertificateSuccess: payload })),
  setReAssignCertificateError: action((state, payload) => ({ reAssignCertificateError: payload })),
  setSubmitSurveySuccess: action((state, payload) => ({ submitSurveySuccess: payload })),
  setSubmitSurveyError: action((state, payload) => ({ submitSurveyError: payload })),

  // thunks
  createAuditClient: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'Client/Create', payload, null);
      actions.setCreateClient(result.data);
    } catch (error:any) {
      actions.setCreateClientError(error.response.data);
    }
  }),

  updateAuditClient: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'Client/Update', payload, null);
      actions.setUpdateClient(result.data);
    } catch (error: any) {
      actions.setUpdateClientError(error.response.data);
    }
  }),

  createAuditBuilding: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'Building/Create', payload, null);
      actions.setCreateBuilding(result.data);
    } catch (error:any) {
      actions.setCreateBuildingError(error.response.data);
    }
  }),

  updateAuditBuilding: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'Building/Update', payload, null);
      actions.setUpdateBuilding(result.data);
    } catch (error:any) {
      // actions.setUpdateBuildingError(error.response.data);
    }
  }),

  getAuditBuilding: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'Building/get', payload, null);
      actions.setGetBuilding(result.data);
    } catch (error:any) {
      actions.setGetBuildingError(error.response.data);
    }
  }),

  getAuditCertificates: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'Certificate/Search', payload, null);
      actions.setGetAuditCertificatesSuccess(result.data);
    } catch (error:any) {
      actions.setGetAuditCertificatesError(error.response.data);
    }
  }),

  getAuditPropertyCertificates: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'Certificate/Property/Search', payload, null);
      actions.setGetAuditPropertyCertificatesSuccess(result.data);
      
    } catch (error:any) {
      actions.setGetAuditPropertyCertificatesError(error.response.data);
    }
  }),

  getSection: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'survey/section', payload, null);
      actions.setGetSectionSuccess(result.data);
    } catch (error:any) {
      actions.setGetSectionError(error);
    }
  }),

  getAuditCertificate: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'todo/certificate', payload, null);
      actions.setGetAuditCertificateSuccess(result.data);
    } catch (error:any) {
      actions.setGetAuditCertificateError(error.response.data);
    }
  }),

  getAuditSurveys: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'todo/survey', payload, null);
      actions.setGetAuditSurveysSuccess(result.data);
    } catch (error:any) {
      actions.setGetAuditSurveysError(error.response.data);
    }
  }),

  answerQuestion: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'survey/answer', payload, null);
      actions.setAuditAnswerSuccess(result.data);
    } catch (error:any) {
      actions.setAuditAnswerError(error.response.data);
    }
  }),

  answerQuestionEvidence: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'survey/venusaddevidence', payload, null);
      actions.setAuditEvidenceSuccess(result.data);
    } catch (error:any) {
      actions.setAuditEvidenceError(error.response.data);
    }
  }),

  reAssignCertificate: thunk(async (actions: any, payload:any) => {
    try {
      const result = await callAudit('post', 'certificate/reassign', payload, null);
      actions.setReAssignCertificateSuccess(result.data);
    } catch (error:any) {
      // actions.setReAssignCertificateError(error.response.data);
    }
  }),

  submitSurvey: thunk(async (actions: any, payload: any) => {
    try {
      const result = await callAudit('post', 'survey/submit', payload, null);
      actions.setSubmitSurveySuccess(result.data);
    } catch (error: any) {
      actions.setSubmitSurveyError(error.response.data);
    }
  })

}