import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Button, Col, Modal, OverlayTrigger, Row, Tooltip, Form } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { $mysaBlue, $mysaMegenta, $mysaRed, $mysaTeal, $mysaYellow, formButton, overlayTriggerDelay, tableStyles } from '../../../../common/components-style';
import { BrandRfpPropertyEvaluationStatus } from '../../../../common/constants';
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import File from '../../../../images/icons/svg/outside/file-icon.svg';
import moment from 'moment';
import { setStorage } from '../../../../common/storage';
import { Link } from 'react-router-dom';
import BrandAdminRfpForm from './BrandAdminSubmittedRfpForm';
import BrandAdminLocationMap from './SubmittedRfpBrandAdminLocationMapping';
import { toast } from 'react-toastify';



const BrandAdminSubmittedRfpPropertiesHomePage: FC<any> = ({ setComponentName, setProperty, setDefaultTab, data }) => {

    const [showLocations, setShowLocations] = useState<boolean>(false);
    const [selectedCity, setSelectedCity] = useState<string>('');
    const [addedBrandRfpProperties, setAddedBrandRfpProperties] = useState<any[]>([]);
    const [refreshPropertiesList, setRefreshPropertiesList] = useState<boolean>(false);
    const [selectedCityDetails, setSelectedCityDetails] = useState<any>();
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showDeclinedMessage, setShowDeclinedMessage] = useState<boolean>(false);
    const [showReviewMessage, setShowReviewMessage] = useState<boolean>(false);
    const [propertyData, setPropertyData] = useState<any>();
    const [propertyAcceptInformation, setPropertyAcceptInformation] = useState<any>();
    const [propertyReviewComment, setPropertyReviewComment] = useState<any>();
    // const [isNegotiation, setIsNegotiation] = useState<boolean>(false);
    const [isPropertyReview, setIsPropertyReview] = useState<boolean>(false);
    // const [checkInventoryCount, setCheckInventoryCount] = useState<boolean>(false);
    const [brandRespondComment, setBrandRespondComment] = useState<any>();


    //state
    const {
        brandRfpProcessLocationsByIdSuccess,
        reviewPropertyForRFPSuccess,
        reviewPropertyForRFPError,
        getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPError,
        acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        acceptCreateUpdatePropertyAcceptDeclineOfRFPError,
        getPropertyRfpInventorySuccess

    } = useStoreState<any>((state) => ({
        getPropertyRfpInventorySuccess: state.rfpProcess.getPropertyRfpInventorySuccess,
        brandRfpProcessLocationsByIdSuccess: state.rfpProcess.brandRfpProcessLocationsByIdSuccess,
        getPropertyAcceptDeclineOfRFPByPropertyIdSuccess: state.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,
        reviewPropertyForRFPSuccess: state.rfpProcess.reviewPropertyForRFPSuccess,
        reviewPropertyForRFPError: state.rfpProcess.reviewPropertyForRFPError,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPError,
        acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        acceptCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFPError,
    }));

    //actions
    const {
        getRfpLocationsByBrandRfpId,
        getPropertyAcceptDeclineOfRFPByPropertyId,
        reviewPropertyForRFP,
        reviewCreateUpdatePropertyAcceptDeclineOfRFP,
        acceptCreateUpdatePropertyAcceptDeclineOfRFP,
        propertyRfpInventory
    } = useStoreActions<any>((actions) => ({
        getPropertyAcceptDeclineOfRFPByPropertyId: actions.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyId,
        getRfpLocationsByBrandRfpId: actions.rfpProcess.getRfpLocationsByBrandRfpId,
        reviewPropertyForRFP: actions.rfpProcess.reviewPropertyForRFP,
        reviewCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFP,
        acceptCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFP,
        propertyRfpInventory: actions.rfpProcess.propertyRfpInventory,
    }));


    useEffect(() => {
        if (propertyData) {
            const params: any = {};
            params.propertyId = propertyData?.id?.toString();
            params.rfpId = data?.rfpId.toString();
            params.brandRfpId = data?.key.toString();
            params.cityName = propertyData?.clientLocation.toString();
            propertyRfpInventory(params);
        }

    }, [data?.key, data?.rfpId, propertyData, propertyRfpInventory]);

    //////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertyRfpInventorySuccess?.data) {
            // if (getPropertyRfpInventorySuccess?.data?.length === 1) {
            //     setCheckInventoryCount(true);
            // } else {
            //     setCheckInventoryCount(false);
            // }

        }
    }, [getPropertyRfpInventorySuccess?.data])

    useEffect(() => {
        setTimeout(() => {
            if (refreshPropertiesList) {
                const params: any = {};
                params.id = data?.key;
                params.rfpId = data?.rfpId;

                getRfpLocationsByBrandRfpId(params);

                setShowLocations(false);
                setSelectedCity('');
            }
            setRefreshPropertiesList(false);
        }, 3000)
    }, [getRfpLocationsByBrandRfpId, refreshPropertiesList, data]);

    useEffect(() => {
        setTimeout(() => {
            const payload = {
                rfpId: data?.rfpId,
                propertyId: propertyData?.id
            }
            if (propertyData?.id) {
                getPropertyAcceptDeclineOfRFPByPropertyId(payload)
            }
        }, 500)

    }, [data?.rfpId, getPropertyAcceptDeclineOfRFPByPropertyId, propertyData?.id]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const callApi = useCallback(() => {
        const payload = {
            rfpId: data?.rfpId,
            propertyId: propertyData?.id
        }
        if (propertyData?.id) {
            getPropertyAcceptDeclineOfRFPByPropertyId(payload)
        }
    }, [data?.rfpId, getPropertyAcceptDeclineOfRFPByPropertyId, propertyData?.id]);

    useEffect(() => {
        if (getPropertyAcceptDeclineOfRFPByPropertyIdSuccess) {
            const data = getPropertyAcceptDeclineOfRFPByPropertyIdSuccess?.data;
            // if (data?.clarificationComment) {
            //     setIsNegotiation(true);
            // } else {
            //     setIsNegotiation(false);
            // }

            if (data?.propertyReviewComment) {
                setIsPropertyReview(true);
            } else {
                setIsPropertyReview(false);
            }

            setPropertyAcceptInformation(getPropertyAcceptDeclineOfRFPByPropertyIdSuccess?.data);
        }
    }, [data?.propertyReviewComment, getPropertyAcceptDeclineOfRFPByPropertyIdSuccess])

    useEffect(() => {
        setTimeout(() => {
            if (data?.key) {
                const params: any = {};
                params.id = data?.key;
                params.rfpId = data?.rfpId;

                getRfpLocationsByBrandRfpId(params);
                setShowLocations(false);
                setSelectedCity('');
            }
        }, 500)
    }, [data?.key, data?.rfpId, getRfpLocationsByBrandRfpId]);

    useEffect(() => {
        if (brandRfpProcessLocationsByIdSuccess?.data) {
            setAddedBrandRfpProperties([...brandRfpProcessLocationsByIdSuccess?.data]);
        }
    }, [brandRfpProcessLocationsByIdSuccess?.data]);

    /////////////////////////review property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (reviewPropertyForRFPSuccess) {
            const payload = {
                rfpId: data?.rfpId,
                brandId: data?.brandId,
                propertyId: propertyData?.id,
                // propertyReviewComment: propertyReviewComment,
                // brandRespondPropertyComment: brandRespondComment
                brandRespondComment: {
                    messageText: brandRespondComment || propertyReviewComment,
                    messageTime: new Date()
                }

            }
            reviewCreateUpdatePropertyAcceptDeclineOfRFP(payload);

        }

        if (reviewPropertyForRFPError) {
            toast.error(reviewPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(reviewCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property reviewed successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setRefreshPropertiesList(true);
            callApi();
            setShowMessage(false);

        }


    }, [brandRespondComment, callApi, data?.brandId, data?.rfpId, propertyData?.id, propertyReviewComment, reviewCreateUpdatePropertyAcceptDeclineOfRFP, reviewCreateUpdatePropertyAcceptDeclineOfRFPError, reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess, reviewPropertyForRFPError, reviewPropertyForRFPSuccess]);

    /////////////////////////add review comment//////////////////////////////////////////////////////////////
    useEffect(() => {

        if (acceptCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(acceptCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property review comment added successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setRefreshPropertiesList(true);
            callApi();
            setShowMessage(false);
        }
    }, [acceptCreateUpdatePropertyAcceptDeclineOfRFPError, acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess, callApi]);


    const memoizedRfpProcessData = useMemo(() => {
        return addedBrandRfpProperties.map((property) => {
            return {
                clientLocation: property.cityName,
                inventories: property.inventories,
                id: property.id,
                building: property.propertyName,
                evaluationStatus: property.evaluationStatus,
                city: property.city,
                myoCertificateId: property?.myoCertificateId,
                isNegotiateProperty: property?.isNegotiateProperty,
                isAmendedRateForProperty: property?.isAmendedRateForProperty
            }
        })
    }, [addedBrandRfpProperties]);


    //Tables columns/////////////////////////////////////////////////////////////////////////////////////////
    const columns1 = [
        {
            name: 'Building',
            selector: row => row.building,
        }, {
            name: 'City',
            selector: row => row.city,
        }, {
            name: 'Client Location',
            selector: row => row.clientLocation,
        },
        {
            name: 'Status',
            cell: (data: any) => {
                const { evaluationStatus } = data;
                let color = "";

                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.COMPLETE) color = $mysaTeal;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.INCOMPLETE) color = $mysaMegenta;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED) color = $mysaRed;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) color = $mysaTeal;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW) color = $mysaYellow;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED) color = $mysaBlue;
                if (evaluationStatus === BrandRfpPropertyEvaluationStatus.PART_ACCEPTED) color = $mysaTeal;
                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize' >{evaluationStatus}</span>
                )
            }
        },
        {
            name: 'Action',
            button: true,
            wrap: true,
            minWidth: '300px',
            cell: (row: any) => {


                let isAcceptedInventory = false;
                // for (const inventory of row?.inventories) {
                if (row.inventories.length === 1 && row?.inventories[0].rfpPropertyRateStatus === 'accepted') {
                    isAcceptedInventory = true;
                } else {
                    isAcceptedInventory = false;
                    // break;
                }
                // }

                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                    >
                        <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                            setProperty(row);
                            setStorage("myo_selected_my_rfp_property", row);
                            setComponentName('Properties');
                            // setMyRfpsData(data);
                        }} />
                        {/* <img className='iconSizes' src={ViewEdit} alt="" onClick={() => loadForm(data)} /> */}
                    </OverlayTrigger>
                    {/* TODO: need to check if we need to add a icon for the remove */}
                    {((row.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED && isAcceptedInventory) ||
                        (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && !row?.isNegotiateProperty && !row?.isAmendedRateForProperty && isAcceptedInventory)
                        || (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && !row?.isNegotiateProperty && !row.isAmendedRateForProperty && isAcceptedInventory)
                        || (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && !row?.isNegotiateProperty && !row?.isAmendedRateForProperty) ||
                        (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && !row?.isNegotiateProperty && !row.isAmendedRateForProperty)
                        || (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED )
                    )

                        && <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-table-top'>View Comments</Tooltip>}
                        >
                            <img className='iconSizes4' src={File} alt="" style={{ marginRight: '-10px' }} onClick={() => {
                                setShowMessage(true);
                                setPropertyData(row);
                            }} />
                        </OverlayTrigger>}

                    {/* {(row.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED) && <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View brand comment</Tooltip>}
                    >
                        <img className='iconSizes4' src={File} alt="" style={{ marginRight: '-40px', marginLeft: '20px' }} onClick={() => {
                            setShowMessage(true);
                            setPropertyData(row);
                        }} />
                    </OverlayTrigger>} */}
                </>)
            }
        },

    ];

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleLocationSelect = useCallback(() => {
        setShowLocations(true);
    }, []);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onReviewProperty = useCallback(() => {
        const payload = {
            rfpId: data?.rfpId,
            brandId: data?.brandId,
            propertyId: propertyData?.id,
            propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEWED,
            cityName: propertyData?.city,
            isClient: false
        }
        reviewPropertyForRFP(payload);
    }, [data?.brandId, data?.rfpId, propertyData?.city, propertyData?.id, reviewPropertyForRFP]);


    // const onAddedComment = useCallback(() => {
    //     const payload = {
    //         rfpId: data?.rfpId,
    //         brandId: data?.brandId,
    //         propertyId: propertyData?.id,
    //         propertyReviewComment: propertyReviewComment,
    //     }
    //     acceptCreateUpdatePropertyAcceptDeclineOfRFP(payload);
    // }, [acceptCreateUpdatePropertyAcceptDeclineOfRFP, data?.brandId, data?.rfpId, propertyData?.id, propertyReviewComment])


    useEffect(() => {
        window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (showMessage) {
            const payload = {
                rfpId: data?.rfpId,
                propertyId: propertyData?.id
            }

            if (propertyData?.id) {
                ;
                getPropertyAcceptDeclineOfRFPByPropertyId(payload)
            }
        }
    }, [data?.rfpId, getPropertyAcceptDeclineOfRFPByPropertyId, propertyData?.id, showMessage]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            {(!showLocations && !selectedCity) &&
                <>
                    <Row style={{
                        marginBottom: '1.5rem'
                    }} >
                        <Col xs={10}><div className="content-title">
                            <Breadcrumb>
                                <Breadcrumb.Item style={{
                                    fontWeight: 100,
                                }} >
                                    <Link to={{ pathname: "/admin/brand-rfp-management", state: { selectedBrandId: data?.brandId, defaultKey: 'submittedRFPs' } }}>{'SubmittedRFPs'}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {data?.companyName}{' - '} {data?.programmeName}{' - '}   {moment(data?.startDate).format('DD MMM YYYY')}
                                </Breadcrumb.Item  >

                            </Breadcrumb>
                        </div></Col>

                    </Row>
                    <Row>
                        <Col xs={12} >
                            <div className="content-title">

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex bfr' md={12} style={{ marginTop: "46px" }}>
                            <h5 style={{ fontSize: '24px', fontWeight: 700, marginTop: "4px" }} className="pe-4">View locations to include properties for submission</h5>
                            <Button onClick={handleLocationSelect} id='edit-button'>View Locations</Button>
                        </Col>
                    </Row>
                    <Row style={{
                        marginTop: '80px'
                    }} >
                        <Col>
                            <div>
                                <DataTable
                                    columns={columns1}
                                    data={memoizedRfpProcessData}
                                    pagination
                                    responsive
                                    customStyles={tableStyles}
                                    theme='mysaTable'
                                />
                            </div>
                        </Col>
                    </Row>
                </>
            }

            {
                showLocations && <BrandAdminRfpForm fromPropertiesPage={true} setShowLocations={setShowLocations} setSelectedCity={setSelectedCity} data={data} setSelectedCityDetails={setSelectedCityDetails} setComponentName={setComponentName} setDefaultTab={setDefaultTab} />

            }
            {
                (!showLocations && selectedCity) && <BrandAdminLocationMap
                    selectedCity={selectedCity}
                    setSelectedCity={setSelectedCity}
                    setShowLocations={setShowLocations}
                    setRefreshPropertiesList={setRefreshPropertiesList}
                    data={data}
                    setComponentName={setComponentName}
                    setDefaultTab={setDefaultTab}
                    selectedCityDetails={selectedCityDetails}
                />
            }



            {propertyData?.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED && <Modal
                show={showMessage}
                onHide={() => { setShowMessage(false); }}
                scrollable={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {
                        propertyAcceptInformation?.clarificationComment &&
                        <Row>
                            <Col md={10}><div style={{ textAlign: 'left' }}>

                                <Form.Control as="textarea" rows={5} disabled
                                    // placeholder="please provide comments"
                                    name='clarificationComment'
                                    defaultValue={propertyAcceptInformation?.clarificationComment}
                                />
                            </div></Col>
                            <Col md={1}></Col>
                        </Row>}
                    {
                        propertyAcceptInformation?.propertyReviewComment &&
                        <Row>
                            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                <Form.Control as="textarea" rows={5} disabled
                                    // placeholder="please provide comments"
                                    name='propertyReviewComment'
                                    defaultValue={propertyAcceptInformation?.propertyReviewComment}
                                />
                            </div></Col>
                            <Col md={1}></Col>
                        </Row>}
                    {/* 
                    {propertyAcceptInformation?.respondPropertyComment && <Row>
                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                            <Form.Control as="textarea" rows={5} disabled
                                // placeholder="please provide comments"
                                name='respondPropertyComment'
                                defaultValue={propertyAcceptInformation?.respondPropertyComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>}

                    {propertyAcceptInformation?.brandRespondPropertyComment && <Row>
                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                            <Form.Control as="textarea" rows={5} disabled
                                // placeholder="please provide comments"
                                name='brandRespondPropertyComment'
                                defaultValue={propertyAcceptInformation?.brandRespondPropertyComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>} */}


                    {
                        propertyAcceptInformation?.messageThread.length !== 0 && propertyAcceptInformation?.messageThread.map((values, key) => {
                            return (<div key={key}>
                                {/* <p style={{ marginBottom: '0px' }}>{(key === 0) ? (values?.direction === 'booker_to_property') ? 'Booker (original message)' : 'Property (original message)' : (values?.direction === 'booker_to_property') ? 'Booker (further message)' : 'Property (further message)'}</p> */}
                                {/* <p className="lead">{values?.messageText}</p> */}

                                {values?.clientRespondComment && <Row>
                                    <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                        <Form.Control as="textarea" rows={5} disabled
                                            // placeholder="please provide comments"
                                            name='respondPropertyComment'
                                            defaultValue={values?.clientRespondComment?.messageText}
                                        />
                                    </div></Col>
                                    <Col md={1}></Col>
                                </Row>}


                                {values?.brandRespondComment && <Row>
                                    <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                        <Form.Control as="textarea" rows={5} disabled
                                            // placeholder="please provide comments"
                                            name='brandRespondPropertyComment'
                                            defaultValue={values?.brandRespondComment?.messageText}
                                        />
                                    </div></Col>
                                    <Col md={1}></Col>
                                </Row>}

                            </div>)
                        })
                    }
                    {
                        propertyAcceptInformation?.declineComment &&
                        <Row>
                            <Col md={10}><div style={{ textAlign: 'left' }}>

                                <Form.Control as="textarea" rows={5} disabled
                                    // placeholder="please provide comments"
                                    name='declinedComment'
                                    defaultValue={propertyAcceptInformation?.declineComment}
                                />
                            </div></Col>
                            <Col md={1}></Col>
                        </Row>}
                    <Row>
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col >
                                    <div style={{ textAlign: 'center' }}>
                                        <Button onClick={() => { setShowMessage(false); }} type="submit" id='edit-button' style={{
                                            height: "40px",
                                            width: '220px',
                                            borderRadius: 30,
                                            paddingLeft: "30px",
                                            paddingRight: "30px",
                                            marginRight: '20px'
                                        }}>Close</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Modal.Body>
            </Modal>}


            {propertyAcceptInformation?.declineComment && <Modal
                show={showDeclinedMessage}
                onHide={() => { setShowDeclinedMessage(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={10}><div style={{ textAlign: 'left' }}>

                            <Form.Control as="textarea" rows={5} disabled
                                // placeholder="please provide comments"
                                name='declinedComment'
                                defaultValue={propertyAcceptInformation?.declineComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col >
                                    <div style={{ textAlign: 'center' }}>
                                        <Button onClick={() => { setShowMessage(false); }} type="submit" id='edit-button' style={{
                                            height: "40px",
                                            width: '220px',
                                            borderRadius: 30,
                                            paddingLeft: "30px",
                                            paddingRight: "30px",
                                            marginRight: '20px'
                                        }}>Close</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Modal.Body>
            </Modal>}



            {(propertyData?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED) && <Modal
                show={showMessage}
                scrollable={true}
                onHide={() => { setShowMessage(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>

                    {
                        propertyAcceptInformation?.clarificationComment &&
                        <Row>
                            <Col md={10}><div style={{ textAlign: 'left' }}>

                                <Form.Control as="textarea" rows={5} disabled
                                    // placeholder="please provide comments"
                                    name='clarificationComment'
                                    defaultValue={propertyAcceptInformation?.clarificationComment}
                                />
                            </div></Col>
                            <Col md={1}></Col>
                        </Row>}
                    {
                        propertyAcceptInformation?.propertyReviewComment &&
                        <Row>
                            <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                <Form.Control as="textarea" rows={5} disabled
                                    // placeholder="please provide comments"
                                    name='propertyReviewComment'
                                    defaultValue={propertyAcceptInformation?.propertyReviewComment}
                                />
                            </div></Col>
                            <Col md={1}></Col>
                        </Row>}

                    {/* {propertyAcceptInformation?.respondPropertyComment && <Row>
                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                            <Form.Control as="textarea" rows={5} disabled
                                // placeholder="please provide comments"
                                name='respondPropertyComment'
                                defaultValue={propertyAcceptInformation?.respondPropertyComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>}

                    {propertyAcceptInformation?.brandRespondPropertyComment && <Row>
                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                            <Form.Control as="textarea" rows={5} disabled
                                // placeholder="please provide comments"
                                name='brandRespondPropertyComment'
                                defaultValue={propertyAcceptInformation?.brandRespondPropertyComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>} */}



                    {
                        propertyAcceptInformation?.messageThread.length !== 0 && propertyAcceptInformation?.messageThread.map((values, key) => {
                            return (<div key={key}>
                                {/* <p style={{ marginBottom: '0px' }}>{(key === 0) ? (values?.direction === 'booker_to_property') ? 'Booker (original message)' : 'Property (original message)' : (values?.direction === 'booker_to_property') ? 'Booker (further message)' : 'Property (further message)'}</p> */}
                                {/* <p className="lead">{values?.messageText}</p> */}

                                {values?.clientRespondComment && <Row>
                                    <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                        <Form.Control as="textarea" rows={5} disabled
                                            // placeholder="please provide comments"
                                            name='respondPropertyComment'
                                            defaultValue={values?.clientRespondComment?.messageText}
                                        />
                                    </div></Col>
                                    <Col md={1}></Col>
                                </Row>}


                                {values?.brandRespondComment && <Row>
                                    <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                        <Form.Control as="textarea" rows={5} disabled
                                            // placeholder="please provide comments"
                                            name='brandRespondPropertyComment'
                                            defaultValue={values?.brandRespondComment?.messageText}
                                        />
                                    </div></Col>
                                    <Col md={1}></Col>
                                </Row>}

                            </div>)
                        })
                    }
                    <Row>
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col >
                                    <div style={{ textAlign: 'center' }}>
                                        <Button onClick={() => { setShowMessage(false); }} type="submit" id='edit-button' style={{
                                            height: "40px",
                                            width: '220px',
                                            borderRadius: 30,
                                            paddingLeft: "30px",
                                            paddingRight: "30px",
                                            marginRight: '20px'
                                        }}>Close</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Modal.Body>
            </Modal>}

            {propertyData?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && <Modal
                show={showMessage}
                onHide={() => { setShowMessage(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
                scrollable={true}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {/* <Row>
                        <Col md={10}><div style={{ textAlign: 'left' }}>
                            <p>The rates for this property has been accepted. Please see below comments that require your consideration.</p>
                        </div></Col>
                        <Col md={1}></Col>
                    </Row> */}

                    {propertyAcceptInformation?.clarificationComment && <Row>
                        <Col md={10}><div style={{ textAlign: 'left' }}>

                            <Form.Control as="textarea" rows={5} disabled
                                // placeholder="please provide comments"
                                name='clarificationComment'
                                defaultValue={propertyAcceptInformation?.clarificationComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>}

                    {/* {propertyAcceptInformation?.clarificationComment && <Row className='formSpace'>

                        <Col md={6} style={{ textAlign: 'center' }}>
                            <Button style={formButton} onClick={() => { setShowMessage(false); }} id='edit-button' >Close</Button>
                        </Col>
                    </Row>} */}
                    {propertyAcceptInformation?.propertyReviewComment && <Row>
                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                            <Form.Control as="textarea" rows={5} disabled
                                // placeholder="please provide comments"
                                name='propertyReviewComment'
                                defaultValue={propertyAcceptInformation?.propertyReviewComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>}

                    {/* {propertyAcceptInformation?.respondPropertyComment && <Row>
                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                            <Form.Control as="textarea" rows={5} disabled
                                // placeholder="please provide comments"
                                name='respondPropertyComment'
                                defaultValue={propertyAcceptInformation?.respondPropertyComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>} */}

                    {
                        propertyAcceptInformation?.messageThread.length !== 0 && propertyAcceptInformation?.messageThread.map((values, key) => {
                            return (<div key={key}>
                                {/* <p style={{ marginBottom: '0px' }}>{(key === 0) ? (values?.direction === 'booker_to_property') ? 'Booker (original message)' : 'Property (original message)' : (values?.direction === 'booker_to_property') ? 'Booker (further message)' : 'Property (further message)'}</p> */}
                                {/* <p className="lead">{values?.messageText}</p> */}

                                {values?.clientRespondComment && <Row>
                                    <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                        <Form.Control as="textarea" rows={5} disabled
                                            // placeholder="please provide comments"
                                            name='respondPropertyComment'
                                            defaultValue={values?.clientRespondComment?.messageText}
                                        />
                                    </div></Col>
                                    <Col md={1}></Col>
                                </Row>}
                                {values?.brandRespondComment && <Row>
                                    <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                        <Form.Control as="textarea" rows={5} disabled
                                            // placeholder="please provide comments"
                                            name='brandRespondPropertyComment'
                                            defaultValue={values?.brandRespondComment?.messageText}
                                        />
                                    </div></Col>
                                    <Col md={1}></Col>
                                </Row>}


                            </div>)
                        })
                    }

                    {/* {propertyAcceptInformation?.brandRespondPropertyComment && <Row>
                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                            <Form.Control as="textarea" rows={5} disabled
                                // placeholder="please provide comments"
                                name='brandRespondPropertyComment'
                                defaultValue={propertyAcceptInformation?.brandRespondPropertyComment}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>} */}

                    {
                        propertyAcceptInformation?.messageThread.length !== 0 && (!propertyAcceptInformation?.messageThread[propertyAcceptInformation?.messageThread.length - 1]?.clientRespondComment && propertyAcceptInformation?.propertyReviewComment) &&
                        <Row>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col >
                                        <div style={{ textAlign: 'center' }}>
                                            <Button onClick={() => { setShowMessage(false); }} type="submit" id='edit-button' style={{
                                                height: "40px",
                                                width: '220px',
                                                borderRadius: 30,
                                                paddingLeft: "30px",
                                                paddingRight: "30px",
                                                marginRight: '20px'
                                            }}>Close</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>}


                    {propertyAcceptInformation?.messageThread.length !== 0 && propertyAcceptInformation?.messageThread[propertyAcceptInformation?.messageThread.length - 1].clientRespondComment?.messageText &&

                        <div>
                            <Row>
                                <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                    <Form.Control as="textarea" rows={5}
                                        placeholder="please provide comments"
                                        name='brandRespondPropertyComment'
                                        // value={''}
                                        onChange={(e) => {
                                            setBrandRespondComment(e.target.value);
                                        }}
                                    />
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>


                            <Row className='formSpace'>
                                <Col md={6} style={{ textAlign: 'left' }}>
                                    <Button id='approve-button' style={formButton} onClick={() => { onReviewProperty() }} >Submit</Button>
                                </Col>
                                <Col md={6} style={{ textAlign: 'right' }}>
                                    <Button style={formButton} onClick={() => { setShowMessage(false); }} id='edit-button' >Close</Button>
                                </Col>
                            </Row>
                        </div>

                    }

                    {propertyAcceptInformation?.clarificationComment && propertyAcceptInformation?.messageThread?.length === 0 &&

                        <div>
                            <Row>
                                <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                    <Form.Control as="textarea" rows={5}
                                        placeholder="please provide comments"
                                        name='brandRespondPropertyComment'
                                        // value={''}
                                        onChange={(e) => {
                                            setBrandRespondComment(e.target.value);
                                        }}
                                    />
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>


                            <Row className='formSpace'>
                                <Col md={6} style={{ textAlign: 'left' }}>
                                    <Button id='approve-button' style={formButton} onClick={() => { onReviewProperty() }} >Submit</Button>
                                </Col>
                                <Col md={6} style={{ textAlign: 'right' }}>
                                    <Button style={formButton} onClick={() => { setShowMessage(false); }} id='edit-button' >Close</Button>
                                </Col>
                            </Row>
                        </div>

                    }

                    {(showReviewMessage && !isPropertyReview) &&
                        <div>
                            <Row>
                                <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                    <Form.Control as="textarea" rows={5}
                                        placeholder="please provide comments"
                                        name='propertyReviewComment'
                                        // value={''}
                                        onChange={(e) => {
                                            setPropertyReviewComment(e.target.value);
                                        }}
                                    />
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>

                            {/* {isAmended && <Row>
                                <Col md={10}><div style={{ textAlign: 'left' }}>
                                    <p>If rates are to be amended, please complete this via the edit button in the 'Action' column after saving this message.</p>
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>} */}

                            {/* <Row>
                                <Col md={10}>
                                    <Row className='formSpace'>
                                        <Col >
                                            <div style={{ textAlign: 'center' }}>
                                                <Button onClick={() => { ((isNegotiation === true) && (isPropertyReview === false) && (checkInventoryCount === false)) ? onAddedComment() : onReviewProperty(); }} type="submit" id='approve-button' style={{
                                                    height: "40px",
                                                    width: '220px',
                                                    borderRadius: 30,
                                                    paddingLeft: "30px",
                                                    paddingRight: "30px",
                                                    marginRight: '20px'
                                                }}>Submit</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1}></Col>
                            </Row> */}

                            <Row className='formSpace'>
                                <Col md={6} style={{ textAlign: 'left' }}>
                                    <Button style={formButton} onClick={() => { onReviewProperty(); }} type="submit" id='approve-button'>Submit</Button>
                                </Col>
                                <Col md={6} style={{ textAlign: 'right' }}>
                                    <Button style={formButton} onClick={() => { setShowMessage(false); }} id='edit-button' >Close</Button>
                                </Col>
                            </Row>
                        </div>


                    }
                    {/* (!showReviewMessage && !isNegotiation) */}
                    {/* {(!isPropertyReview && !showReviewMessage) && <Row >
                        <Col md={10}>
                            <Row className='formSpace'>
                                <Col >
                                    <div style={{ textAlign: 'center', }}>
                                        <Button onClick={() => { setShowReviewMessage(true); }} type="submit" id='edit-button' style={{
                                            height: "40px",
                                            width: '220px',
                                            borderRadius: 30,
                                            paddingLeft: "30px",
                                            paddingRight: "30px",
                                            marginRight: '20px'
                                        }}>Reply</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </Row>

                    } */}
                </Modal.Body>
            </Modal>}


        </div >
    )
}
export default BrandAdminSubmittedRfpPropertiesHomePage;   