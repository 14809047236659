import { Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row, Image, Button, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import { UserGroups } from '../../../../common/constants';
import { getLoggedUserType } from '../../../../common/functions';
import { ClientCreateForm } from '../../../../common/interfaces';
import ImageUpload from '../../../common/imageUpload';
import temImg from '../../../../images/icons/svg/temImage.svg';
import { formButton } from '../../../../common/components-style';
import { toast } from 'react-toastify';

const ClientDetailsForm: React.FC<any> = ({ clientId }): JSX.Element => {
  const { register, watch, handleSubmit, formState: { errors }, control, setValue } = useForm<ClientCreateForm>();
  const [editEnable, setEditEnable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState();
  const [logoUpdated, setLogoUpdated] = useState(false);
  const [domainList, setDomainList] = useState<any>(null);
  const [createdClientId, setCreatedClientId] = useState(null);
  const [auditReference, setAuditReference] = useState<string>();
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [phone, setPhone] = useState<string>();
  const [clientData, setClientData] = useState<ClientCreateForm>();

  ///////////////////////////////////////////////////////////////////////////////////////////
  const getImageBlob = useCallback((imageBlob) => {
    setLogo(imageBlob);
    setValue('companyLogo', imageBlob);
    setLogoUpdated(true);
  }, [setValue]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    getClientData,
    createClient,
    updateClient,
    getClientList,
    // createAuditClient,
    // updateAuditClient,
    // resetAudit,
    resetClient
  } = useStoreActions<any>((actions) => ({
    getClientData: actions.client.getClientData,
    createClient: actions.client.createClient,
    updateClient: actions.client.updateClient,
    getClientList: actions.client.getClientList,
    // createAuditClient: actions.audit.createAuditClient,
    // updateAuditClient: actions.audit.updateAuditClient,
    // resetAudit: actions.audit.resetAudit,
    resetClient: actions.client.resetClient,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {

    getClientDataSuccess,
    getClientDataError,
    createClientSuccess,
    createClientError,
    updateClientSuccess,
    updateClientError,
    createAuditClientSuccess
  } = useStoreState<any>((state) => ({
    getClientDataSuccess: state.client.getClientDataSuccess,
    getClientDataError: state.client.getClientDataError,
    updateClientError: state.client.updateClientError,
    createClientError: state.client.createClientError,
    createClientSuccess: state.client.createClientSuccess,
    updateClientSuccess: state.client.updateClientSuccess,
    getClientListSuccess: state.client.getClientListSuccess,
    getClientListError: state.client.getClientListError,
    createAuditClientSuccess: state.audit.createAuditClientSuccess,

  }));



  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (clientId) {
      setEditEnable(false);
    } else {
      setEditEnable(true);
    }
    if (clientId) {
      getClientData(clientId);
    }
  }, [getClientData, clientId]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getClientDataSuccess) {
      const client = getClientDataSuccess.data;
      setClientData(getClientDataSuccess.data);
      const data = _.filter(client.users, user => (user['custom:userGroup'] === UserGroups.corporate_admin));
      data.forEach(user => {
        setEmail(user.email);
        setFirstName(user['custom:firstName']);
        setPhone(user['custom:telephone'])
      }
      );
      setAuditReference(client.externalId);
      setValue('companyName', client.companyName);
      setValue('companyLogo', client.companyLogo);
      setValue('address1', client.address1);
      setValue('address2', client.address2);
      setValue('city', client.city);
      setValue('country', client.country);
      setValue('postcode', client.postcode);
      setValue('latitude', client.latitude);
      setValue('longitude', client.longitude);
      setValue('domainName', client.domainName);
    }
    if (getClientDataError) {
      toast.error(getClientDataError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
      resetClient();
    }

  }, [getClientDataSuccess, getClientDataError, email, firstName, phone, auditReference, createClientError, resetClient, setValue]);


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createClientSuccess) {
      setCreatedClientId(createClientSuccess.data._id)
      getClientData(createClientSuccess.data._id);
      setEditEnable(false);
      setLoading(false);
      // createAuditClient({
      //   security: {
      //     apiKey: process.env.REACT_APP_AUDIT_API_KEY,
      //     user: "superadmin@mysa.global"
      //   },
      //   client: {
      //     id: "",
      //     parentID: "",
      //     type: "Corporate Client",
      //     name: watch('companyName'),
      //     phone: "",
      //     externalID: createClientSuccess.data._id,
      //     managedExternally: false,
      //     contactInfo: {
      //       name: "mysa",
      //       email: "superadmin@mysa.global"
      //     }
      //   }
      // });
      toast.success("Client created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
      resetClient();
      // getClientList();
    }
    if (createAuditClientSuccess) {
      if (createAuditClientSuccess.clientID) {
        updateClient({ id: createdClientId, externalId: createAuditClientSuccess.clientID });
        resetClient();
        // resetAudit();
      }
    }
    if (createClientError) {
      toast.error(createClientError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
      setLoading(false);
    }

    if (updateClientSuccess) {
      setLoading(false);
      getClientData(updateClientSuccess.data._id);
      setEditEnable(false);
      toast.success("Client updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
      resetClient();
      // getClientList();
    }
    if (updateClientError) {
      toast.error(updateClientError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
      setLoading(false);
      resetClient();
    }
  }, [resetClient, createClientSuccess, createClientError, updateClientError, updateClientSuccess, createdClientId, createAuditClientSuccess, getClientList, updateClient, watch, getClientData]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const domainChange = useCallback((value) => {
    setDomainList(value);
  }, []);


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSubmit = useCallback((values) => {
    setLoading(true);

    if (clientId) {
      values.id = clientId;
      const data = {
        id: clientId,
        companyName: values.companyName,
        companyLogo: logoUpdated ? logo : values.companyLogo,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        country: values.country,
        postcode: values.postcode,
        latitude: values.latitude,
        longitude: values.longitude,
        domainName: values.domainName,
      }
      updateClient(data);
      // updateAuditClient({
      //   security: {
      //     apiKey: process.env.REACT_APP_AUDIT_API_KEY,
      //     user: "superadmin@mysa.global"
      //   },
      //   client: {
      //     id: auditReference,
      //     parentID: "",
      //     type: "Corporate Client",
      //     name: watch('companyName'),
      //     contact: firstName,
      //     email: email,
      //     phone: phone,
      //     managedExternally: false,
      //     contactInfo: {
      //       name: firstName,
      //       email: email
      //     }
      //   }
      // });
    } else {
      values.companyLogo = logo;
      values.domainName = domainList;
      createClient(values);
    }

  }, [clientId, logoUpdated, logo, updateClient, domainList, createClient]);
  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div style={{paddingLeft:"40px"}}>
        <div className='edit_btn_wpr'>
        {
            !loading && !editEnable && getLoggedUserType() === UserGroups.super_admin  && <Button id='edit-button' style={formButton} onClick={()=>{setEditEnable(true)}} >Edit</Button>
          }
        </div>
        <div className="jumbotron" style={{marginTop:"90px"}}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className='formSpace'>
                  <Col md={3} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Company name</Form.Label>
                  </Col>
                  <Col md={9} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{clientData?.companyName}</p>}
                    {editEnable && <Form.Control className={`${errors.companyName ? 'is-invalid' : ''}`} {...register("companyName", { required: true })} />}
                    {editEnable && <div className="invalid-feedback">Please input company name!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={3} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Company logo</Form.Label>
                  </Col>
                  <Col md={9} style={{ textAlign: 'left' }}>
                    {!editEnable && <Image width={100} src={String(clientData?.companyLogo ? clientData?.companyLogo : temImg)} thumbnail={true} />}
                    {editEnable &&
                      <Controller
                        name="companyLogo"
                        control={control}
                        rules={{
                          required: clientData?.companyLogo ? false : true
                        }}
                        render={({ field }) =>
                          <ImageUpload getImageBlob={getImageBlob} crop={false} type="image" value={String(field.value ? field.value : temImg)} disabled={false} />
                        }
                      />}
                    {editEnable && errors.companyLogo && <div className="invalid-feedback-custom">Please select logo!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={3} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Domain name</Form.Label>
                  </Col>
                  <Col md={9} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{clientData?.domainName}</p>}
                    {editEnable &&
                      <Controller
                        name="domainName"
                        control={control}
                        rules={{
                          required: true,
                          pattern :  /((ftp|http|https):\/\/)?([a-z0-9]+\.)?([a-z0-9][a-z0-9-]*)?((\.[a-z]{2,6})|(\.[a-z]{2,6})(\.[a-z]{2,6}))$/
                        }}
                        render={({ field }) =>

                          domainList ?
                            <Select key={domainList ? `tags${String(domainList)}` : 'empty'} mode="tags" style={{ width: '100%' }} defaultValue={domainList} onChange={(value) => {
                              domainChange(value);
                              field.onChange(value);
                            }}>
                            </Select>
                            :
                            <Select mode="tags" style={{ width: '100%' }} onChange={(value) => {
                              domainChange(value);
                              field.onChange(value);
                            }}>
                            </Select>
                        }
                      />}
                    {editEnable && errors.domainName && <div className="invalid-feedback-custom">Please input company domain!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={3} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Address1</Form.Label>
                  </Col>
                  <Col md={9} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{clientData?.address1}</p>}
                    {editEnable && <Form.Control className={`${errors.address1 ? 'is-invalid' : ''}`} {...register("address1", { required: true })} />}
                    {editEnable && <div className="invalid-feedback">Please input address1!</div>}
                  </Col>
                </Row>
           
                <Row className='formSpace'>
                  <Col md={3} style={{ textAlign: 'right' }}>
                    <Form.Label>Address2</Form.Label>
                  </Col>
                  <Col md={9} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{clientData?.address2}</p>}
                    {editEnable && <Form.Control className={`${errors.address2 ? 'is-invalid' : ''}`} {...register("address2", { required: false })} />}
                    {editEnable && <div className="invalid-feedback">Please input address2!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={3} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} City</Form.Label>
                  </Col>
                  <Col md={9} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{clientData?.city}</p>}
                    {editEnable && <Form.Control className={`${errors.city ? 'is-invalid' : ''}`} {...register("city", { required: true })} />}
                    {editEnable && <div className="invalid-feedback">Please input city!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={3} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Postal/Zip code</Form.Label>
                  </Col>
                  <Col md={9} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{clientData?.postcode}</p>}
                    {editEnable && <Form.Control className={`${errors.postcode ? 'is-invalid' : ''}`} {...register("postcode", { required: true })} />}
                    {editEnable && <div className="invalid-feedback">Please input postal code!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={3} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Country</Form.Label>
                  </Col>
                  <Col md={9} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{clientData?.country}</p>}
                    {editEnable && <Form.Control className={`${errors.country ? 'is-invalid' : ''}`} {...register("country", { required: true })} />}
                    {editEnable && <div className="invalid-feedback">Please input postal country!</div>}
                  </Col>
                </Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!loading && editEnable && getLoggedUserType() === UserGroups.super_admin &&
											<Button type="submit" id='approve-button' style={formButton} > {clientId ? 'Update' : 'Create'}</Button>
										}
										
										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Form>
        </div>

      </div>
    </>
  )
};

export default ClientDetailsForm;