import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { loginButton } from '../../common/components-style';
import { LoginForm } from '../../common/interfaces';
import logo from '../../images/logoNew.png';

const ForgotPassword: React.FC = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm<LoginForm>();

  const { onForgot, loadUsers } = useStoreActions<any>((actions) => ({
    onForgot: actions.auth.onForgot,
    loadUsers: actions.user.loadUsers,
  }));

  const { forgotSuccess, forgotError, userList, userListError } = useStoreState<any>((state) => ({
    forgotSuccess: state.auth.forgotSuccess,
    forgotError: state.auth.forgotError,
    userList: state.user.userList,
    userListError: state.user.userListError,
  }));


  useEffect(() => {
    if (userListError) {
      // message.error(userListError.message);
      toast.error(userListError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
    if (userList) {
      const user = userList.data;
      const userData = _.filter(user.users, (a) => a.email === email).shift()?.poolData || [];
      if (userData.UserStatus === 'FORCE_CHANGE_PASSWORD') {
        toast.warn("User not recognized. Please contact your admin to create or re-issue your invite!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
        });
      } else {
        if (email) {
          let values: any = {};
          values.email = email;
          onForgot(values);
        }

      }
    }

  }, [email, onForgot, userList, userListError]);


  useEffect(() => {
    setLoading(false);
    if (forgotError) {
      // message.error(forgotError.message.message);
      toast.error(forgotError.message.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
    if (forgotSuccess) {
      // message.success('Please check your email for verification cade. Redirecting .....');
      toast.success("Please check your email for verification code", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      setTimeout(() => {
        history.push('/confirm-forgot-password', { email });
      }, 500);
    }

  }, [history, forgotError, forgotSuccess, email]);


  const checkExist = useCallback(() => {
    loadUsers();
  }, [loadUsers])

  const onFinish = useCallback((values) => {
    setLoading(true);
    setEmail(values.email);
    checkExist();
    // onForgot(values);
  }, [checkExist]);

  return (
    <div className="mysa-login flex-col flex h-screen items-center justify-center" >
      <Card className="px-5 shadow-2xl" style={{
        position: 'absolute',
        width: '458px',
        height: '411px',
        left: '731px',
        top: '338px',
        background: '#FFFFFF',
        // boxShadow: ' 0px 8px 8px rgba(0, 0, 0, 0.6)',
        borderRadius: '33px'
      }}>
        <div className="w-64 m-auto " style={{
          paddingBottom: '0rem',
          paddingTop: '0rem'
        }}>
          <img src={logo} alt="logo" />
        </div>
        <>

          <Container>
            <div  >
              <Form onSubmit={handleSubmit(onFinish)} style={{
                paddingBottom: '4rem',
                paddingTop: '0rem'
              }}>
                <Row className='formSpaceLogin'>

                  <Col md={12} style={{ textAlign: 'left' }}>
                    <Form.Control type="email" placeholder="Email" className={`${errors.email ? 'is-invalid' : ''}`}  {...register("email", { required: true })} />
                    <div className="invalid-feedback">Please input your email!</div>
                  </Col>
                </Row>

                <Row className='formSpaceLogin '>

                  <Col md={12} style={{ textAlign: 'center' }} >
                    {!loading && <Button type="submit" id='login-button' style={loginButton} >Send</Button>}
                  </Col>
                  <a className="block" style={{ textAlign: 'right', marginLeft: '-15px', marginTop: '25px' }} href="/">Login</a>

                  {
                    loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                  }
                </Row>

              </Form>
            </div>
          </Container>
        </>
      </Card>
      {/* <Card className="px-5 shadow-2xl" style={{ width: 400 }}>
        <div className="w-64 m-auto py-3">
          <img src={logo} alt="logo" />
        </div>
        <>
          <Form onFinish={onFinish}>
            <Form.Item
              className="mb-2"
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: "email", message: 'Please input valid email!' }
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item>
              <Button loading={loading} block type="primary" htmlType="submit" className="login-btn">
                Send
              </Button>
              <a className="block float-right mt-2 " href="/">Login</a>
            </Form.Item>
          </Form>
        </>
      </Card>
      <div className="flex flex-row justify-around" style={{ width: 400 }}>
        <div className="mysa-yellow w-full h-1"></div>
        <div className="mysa-green w-full"></div>
        <div className="mysa-blue w-full"></div>
      </div>
      <span className="text-white text-xs mt-1">All right reserved @{new Date().getFullYear()} MYSA Global Limited</span> */}
    </div>
  )
};

export default ForgotPassword;