import { Calendar, Row, Col, Form, Select, Badge, message, InputNumber, Radio } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment'
import { DatePicker } from 'antd';
import { toast } from 'react-toastify';
import { formButton } from '../../../common/components-style';
import { Button } from 'react-bootstrap';

const { RangePicker } = DatePicker;
// const { Option } = Select;

const AvailabilityList: React.FC<any> = ({ selectedProperty, selectedInventory }): JSX.Element => {
  const [form] = Form.useForm();
  const [accommodationForm] = Form.useForm();
  // const [loading, setLoading] = useState<boolean>(false);
  // const [inventoryList, setInventoryList] = useState<any>([]);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedDateCustom, setSelectedDateCustom] = useState<any>(null);
  const [mode, setMode] = useState<any>('month');
  const [availabilityList, setAvailabilityList] = useState<any>([]);
  const [availabilityId, setAvailabilityId] = useState<any>();
  const [showDate, setShowDate] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  /////////////////////////////////////////////////////////////////////////////////////////////
  moment.updateLocale('en', {
    weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  });

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getInventoryByPropertyId, getAvailabilityList, createAvailability } = useStoreActions<any>((actions) => ({
    getInventoryByPropertyId: actions.inventory.getInventoryByPropertyId,
    getAvailabilityList: actions.inventory.getAvailabilityList,
    createAvailability: actions.inventory.createAvailability
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getInventoryByPropertyIdSuccess, getInventoryByPropertyIdError
    , getAvailabilityListSuccess, getAvailabilityListError, createAvailabilitySuccess, createAvailabilityError } = useStoreState<any>((state) => ({
      getInventoryByPropertyIdSuccess: state.inventory.getInventoryByPropertyIdSuccess,
      getInventoryByPropertyIdError: state.inventory.getInventoryByPropertyIdError,
      getAvailabilityListSuccess: state.inventory.getAvailabilityListSuccess,
      getAvailabilityListError: state.inventory.getAvailabilityListError,
      createAvailabilitySuccess: state.inventory.createAvailabilitySuccess,
      createAvailabilityError: state.inventory.createAvailabilityError
    }));

  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (selectedProperty) {
      getInventoryByPropertyId(selectedProperty._id);
    }
  }, [getInventoryByPropertyId, selectedProperty]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getInventoryByPropertyIdSuccess) {
      // setInventoryList(getInventoryByPropertyIdSuccess.data);
      accommodationForm.setFieldsValue({
        accommodation: (selectedInventory?.key) ? selectedInventory?.key : selectedInventory?._id
      })
    }
    if (getInventoryByPropertyIdError) {
      // message.error(getInventoryByPropertyIdError.message)
      toast.error(getInventoryByPropertyIdError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

  }, [accommodationForm, getInventoryByPropertyIdError, getInventoryByPropertyIdSuccess, selectedInventory]);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    if (selectedInventory) {
      const data = {
        propertyInventory: (selectedInventory?.key) ? selectedInventory?.key : selectedInventory?._id,
        stayDateFrom: moment(firstDay).format('YYYY-MM-DD'),
        stayDateTo: moment(lastDay).format('YYYY-MM-DD')
      }
      getAvailabilityList(data);
    }
  }, [selectedInventory, selectedProperty]);

  //////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAvailabilityListSuccess) {
      const availability = getAvailabilityListSuccess.data;
      setAvailabilityList(availability);
    }

    if (getAvailabilityListError) {
      message.success(getAvailabilityListError.message);
    }

  }, [getAvailabilityListSuccess, getAvailabilityListError]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const getAvailabilityData = useCallback((values) => {

    const date = new Date(values);
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const data = {
      propertyInventory: (selectedInventory?.key) ? selectedInventory?.key : selectedInventory?._id,
      stayDateFrom: moment(firstDay).format('YYYY-MM-DD'),
      stayDateTo: moment(lastDay).format('YYYY-MM-DD')
    }
    getAvailabilityList(data);
  }, [selectedInventory]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createAvailabilitySuccess) {
      // message.success('availability updated successfully');
      toast.success("Availability updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });

      if (selectedDateCustom) {
        const date: any = moment(new Date(selectedDateCustom));
        if (mode === 'year') {
          setMode('month');

          /**
           * get range of data
           */
          const data = {
            propertyInventory: (selectedInventory?.key) ? selectedInventory?.key : selectedInventory?._id,
            stayDateFrom: moment(selectedDate).clone().startOf('month').format('YYYY-MM-DD'),
            stayDateTo: moment(selectedDate).clone().endOf('month').format('YYYY-MM-DD')
          }
          getAvailabilityList(data);
        } else {
          getAvailabilityData(date._d);
        }
      } else {
        const date: any = moment(new Date(selectedDate));
        getAvailabilityData(date._d);
      }
    }

    if (createAvailabilityError) {
      // message.error(createAvailabilityError.message);
      toast.error(createAvailabilityError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
    // setLoading(false);
  }, [createAvailabilityError, createAvailabilitySuccess]);


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const getDateRangeList = useCallback((startDate, endDate) => {
    const initialDate = new Date(startDate)
      , endingDate = new Date(endDate)
    const arrTime: any = [];
    for (let q = initialDate; q <= endingDate; q.setDate(q.getDate() + 1)) {
      arrTime.push({ stayDate: moment(q).format('YYYY-MM-DD') });
    }
    setData(arrTime);
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // const renderAccommodation = useCallback(() => {
  //   if (inventoryList) {
  //     return inventoryList.map((acc: any, key: number) => {
  //       return (
  //         <Option key={key} value={`${acc._id}`} >{acc.accommodationDetail}</Option>
  //       )
  //     });
  //   }
  // }, [inventoryList]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSelect = useCallback((value) => {
    setShowUpdate(true);
    setShowDate(false);
    if (mode === 'year') {
      setSelectedDate(moment(value).format('YYYY-MM'));
      const date = new Date(value);
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      getDateRangeList(startDate, endDate);

    } else {
      setSelectedDate(moment(value).format('YYYY-MM-DD'));
      let listData: any = [];
      getAvailabilityData(value._d);
      listData = _.filter(availabilityList, list => (moment(list.stayDate).format('YYYY-MM-DD') === moment(value).format('YYYY-MM-DD')));
      if (listData.length > 0) {
        _.map(listData, element => {
          form.setFieldsValue({
            availableApartment: (element.availableApartments)
          })
          setAvailabilityId(element._id);
        });
      } else {
        form.setFieldsValue({
          availableApartment: (0)
        })
      }
    }



  }, [mode, getDateRangeList, getAvailabilityData, availabilityList, form]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const onPanelChange = useCallback((value, mode) => {
    setShowUpdate(false);
    setMode(mode);
    if (mode === 'custom') {
      setShowDate(true);
    } else {
      setShowDate(false);
    }
    if (value) {
      setSelectedDateCustom(value._d);
      getAvailabilityData(value._d);
    }

  }, [getAvailabilityData]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const dateCellRender = useCallback((value) => {
    let data: any = [];
    data = _.filter(availabilityList, list => (moment(list.stayDate).format('YYYY-MM-DD') === moment(value).format('YYYY-MM-DD')));
    if (data.length > 0) {
      return data.map((acc: any, key: number) => {
        return (
          <div key={key} className="available absolute top-2 right-2">
            <div className="date-available">{acc.availableApartments}</div>
            <div className="date-booked">{acc.bookedApartments}</div>
          </div>
        );
      });
    } else {
      return (
        <div className="available absolute top-2 right-2">
          <div className="date-available">{0}</div>
          <div className="date-booked">{0}</div>
        </div>
      );
    }
  }, [availabilityList]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const headerRender = useCallback(({ value, type, onChange, onTypeChange }) => {
    const start = 0;
    const end = 12;
    const monthOptions: any = [];
    const current: any = value.clone();
    const month = value.month();
    const localeData: any = value.localeData();
    const months: any = [];
    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.months(current));
    }
    for (let index = start; index < end; index++) {
      monthOptions.push(
        <Select.Option className="month-item" key={`${index}`} value={`${index}`} >
          {months[index]}
        </Select.Option>,
      );
    }
    const year = value.year();
    const currentYear = new Date().getFullYear();
    const options: any = [];
    for (let i = currentYear; i < currentYear + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>
      );
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
      <div style={{ padding: 8 }}>
        <Row gutter={8} className="absolute -top-4 right-1 ">

          <Col >
            <Select
              size="small"
              dropdownMatchSelectWidth={false}
              className="my-year-select"
              onChange={newYear => {
                const now = value.clone().year(newYear);
                getAvailabilityData(now._d);
                onChange(now);
              }}
              value={String(year)}
            >
              {options}
            </Select>
          </Col>
          <Col>
            <Select
              size="small"
              dropdownMatchSelectWidth={false}
              value={String(month)}
              onChange={selectedMonth => {
                const newValue = value.clone();
                newValue.month(parseInt(selectedMonth, 10));
                getAvailabilityData(newValue._d);
                onChange(newValue);
              }}
            >
              {monthOptions}
            </Select>
          </Col>

          <Col>
            <Radio.Group size="small" className="font-medium" onChange={e => onTypeChange(e.target.value)} value={type}>
              <Radio.Button value="month">Date</Radio.Button>
              <Radio.Button value="year">Month</Radio.Button>
              <Radio.Button value="custom">Custom</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>

      </div>
    );

  }, [getAvailabilityData])

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onToDateChange = useCallback((date, dateString) => {
    setSelectedDate(null);
    setShowUpdate(true);
    if (date) {
      const startDate = dateString[0];
      const endDate = dateString[1];
      getDateRangeList(startDate, endDate);
    }

  }, [getDateRangeList]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = useCallback((values) => {
    // setLoading(true);
    if (mode === 'custom' || mode === 'year') {
      const newData: any = [];
      const inventoryId = (selectedInventory?.key) ? selectedInventory?.key : selectedInventory?._id
      _.each(data, element => {
        let availabilityId: any = null;
        if (availabilityList) {
          availabilityId = availabilityList.filter((avaElement) => moment(avaElement.stayDate).format('YYYY-MM-DD') === moment(element.stayDate).format('YYYY-MM-DD'))[0];
        }
        if (availabilityId) {
          newData.push({ _id: availabilityId?._id ? availabilityId?._id : '', stayDate: element.stayDate, propertyId: selectedProperty._id, propertyInventory: inventoryId, availableApartments: values.availableApartment, bookedApartments: 0 })
        } else {
          newData.push({ stayDate: element.stayDate, propertyId: selectedProperty._id, propertyInventory: inventoryId, availableApartments: values.availableApartment, bookedApartments: 0 })
        }

      }
      );
      createAvailability(newData);
    } else {
      if (availabilityId) {
        const data = [
          {
            _id: availabilityId,
            propertyId: selectedProperty._id,
            propertyInventory: (selectedInventory?.key) ? selectedInventory?.key : selectedInventory?._id,
            stayDate: selectedDate,
            availableApartments: values.availableApartment,
            bookedApartments: 0
          }]
        createAvailability(data);
      } else {
        const data = [
          {
            propertyId: selectedProperty._id,
            propertyInventory: (selectedInventory?.key) ? selectedInventory?.key : selectedInventory?._id,
            stayDate: selectedDate,
            availableApartments: values.availableApartment,
            bookedApartments: 0
          }]
        createAvailability(data);
      }
    }

  }, [availabilityId, availabilityList, createAvailability, data, mode, selectedDate, selectedInventory, selectedProperty]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div >
      {/* <Row >
        <Form
          layout="inline"
          form={accommodationForm}
        >
          <Form.Item
            label="Accommodation"
            name="accommodation"
          >
            <Select style={{ width: 250 }} disabled={selectedInventory}>
              {renderAccommodation()}
            </Select>
          </Form.Item>
        </Form>
      </Row> */}
      <Row className="mt-5" >
        <div className="float-left ">
          <Badge status="success" size="default" text="Available" className="ml-1" />

          <Badge status="error" size="default" text="Booked" />
        </div>

      </Row>
      <Row  >
        <Col span={16} >
          <div className="site-calendar-customize-header-wrapper">
            <Calendar
              headerRender={headerRender}
              onSelect={onSelect}
              onPanelChange={onPanelChange}
              dateCellRender={dateCellRender}
              mode={mode}
            />
          </div>
        </Col>
        <Col span={8}>
          <div>
            <p className="text-xl mb-10 ml-14 text-green-600 text-center">Available apartments</p>
          </div>
          <Col>
            <div hidden={!showDate} className="ml-14 mb-4">
              <Form
                layout="vertical"
              >
                <Row>
                  <RangePicker
                    onCalendarChange={onToDateChange}
                  />
                </Row>
              </Form>
            </div>
          </Col>
          <Col>
            <div hidden={!showUpdate} className="ml-14">
              <Form
                layout="inline"
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  label={selectedDate ? selectedDate : 'Available count'}
                  name="availableApartment"
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item >
                  {/* <Button loading={loading} type="primary" htmlType="submit">
                    {'Update'}
                  </Button> */}
                  <Button type="submit" id='approve-button' style={formButton} >Update</Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Col>
      </Row>
    </div>
  )
};

export default AvailabilityList;