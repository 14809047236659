
import React, { useCallback } from 'react';
import {
   Container,
   Form,
   Row,
   Col,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "antd";

import Select from "react-select";
import BookMarkIcon from '../../../../images/icons/svg/bookmark.svg';
import FilterIcon from '../../../../images/icons/svg/filter.svg';
import CalenderIcon from '../../../../images/icons/svg/calender.svg';
import moment from 'moment';
import { BookingForm } from '../../../../common/interfaces';
import { BookMarkStyles, RoomTypeStyles } from '../../../../common/components-style';

const { RangePicker } = DatePicker;
const TravelerHeaderPage: React.FC<any> = ({ bookingParams, location }): JSX.Element => {
   const {
      formState: { errors },
      control,
   } = useForm<BookingForm>();
   const minDate = moment(new Date());




   ////////////////////////////////////////////////////////////////////////////////////////////////////







   ///////////////////////////////////////////////////////////////////////////////////////////////////////////
   const disabledFromDate = useCallback(
      (d) => {
         if (d == null) {
            return null;
         }
         return (
            minDate != null && !d.isAfter(minDate) && d.isBefore(minDate, "day")
         );
      },
      [minDate]
   );


   //////////////////////////////////////////////////////////////////////////////////////////////////////////
   return (
      <div >
         <Container style={{ maxWidth: "1675px" }}>
            <div>
               {/* <Row
                  style={{
                     position: "fixed",
                     left: "6.9vw",
                     top: "70px",
                     width: "100%",
                     padding: '5vh 1.5vw',
                     zIndex: 20,
                     backgroundColor: "#fff",
                  }}
               > */}
               {/* <Row style={{
                    marginTop:'80px'
                }} > */}
               <Row>

                  <Form>
                     <Row className="formSpace">
                        <Col
                           md={
                              bookingParams?.state?.query?.isProgramme === "MYO Plus" ||
                                 bookingParams?.state?.query?.isProgramme === "Specified only"
                                 ? 3
                                 : 2
                           }
                        >
                           {(bookingParams?.state?.query?.isProgramme === "MYO Plus" ||
                              bookingParams?.state?.query?.isProgramme === "Specified only") && (
                                 <Controller
                                    name="location"
                                    defaultValue={bookingParams?.state?.query?.locationId}
                                    control={control}
                                    render={({ field }) => (
                                       <div
                                          style={{
                                             width: "321px",
                                             height: "44px",
                                          }}
                                       >
                                          <Select
                                             components={{ DropdownIndicator: () => null }}
                                             isSearchable
                                             isDisabled={true}
                                             placeholder={
                                                <div className="flex justify-start ">
                                                   <div style={{ marginRight: "6%" }}>
                                                      <img
                                                         style={{ width: "21px", height: "26px" }}
                                                         src={BookMarkIcon}
                                                         alt="book mark "
                                                      />
                                                   </div>
                                                   <div className="font-light">
                                                      Select company location
                                                   </div>
                                                </div>
                                             }
                                             // options={renderCorporateLocationNew()}
                                             defaultValue={{
                                                value: bookingParams?.state?.query?.locationId,
                                                label: bookingParams?.state?.query?.locationName,
                                             }}
                                             className={`${errors.location ? "is-invalid" : ""
                                                } font-light`}
                                             // onChange={onLocationChange}
                                             styles={BookMarkStyles}
                                          />
                                       </div>
                                    )}
                                 />
                              )}

                           {bookingParams?.state?.query?.isProgramme === "MYO" && (
                              <Controller
                                 name="country"
                                 control={control}
                                 defaultValue={bookingParams?.state?.query?.countryCode}
                                 render={({ field }) => (
                                    <div
                                       style={{
                                          width: "221px",
                                          height: "44px",
                                          // marginLeft: '240px',
                                       }}
                                    >
                                       <Select
                                          components={{ DropdownIndicator: () => null }}
                                          // defaultValue={bookingParams?.countryCode}
                                          defaultValue={{
                                             value: bookingParams?.state?.query?.countryCode,

                                             label: bookingParams?.state?.query?.country?.country,
                                          }}
                                          isSearchable
                                          isDisabled={true}
                                          placeholder={
                                             <div className="flex justify-start ">
                                                <div style={{ marginRight: "6%" }}>
                                                   <img
                                                      style={{ width: "21px", height: "26px" }}
                                                      src={BookMarkIcon}
                                                      alt="book mark "
                                                   />
                                                </div>
                                                <div className="font-light">Select Country</div>
                                             </div>
                                          }
                                          // options={renderCountry()}
                                          // onChange={changeHandler}
                                          className={`${errors.country ? "is-invalid" : ""
                                             } font-light`}
                                          styles={BookMarkStyles}
                                       />
                                    </div>
                                 )}
                              />
                           )}
                      
                        </Col>
                        {(bookingParams?.state?.query?.isProgramme === "MYO") && (
                           <Col md={2}>
                              <Controller
                                 name="city"
                                 defaultValue={bookingParams?.state?.query?.placeId}
                                 control={control}
                                 render={({ field }) => (
                                    <div
                                       style={{
                                          width: "221px",
                                          height: "44px",
                                          // marginLeft: '240px',
                                       }}
                                    >
                                       <Select
                                          components={{ DropdownIndicator: () => null }}
                                          defaultValue={{
                                             value: bookingParams?.state?.query?.placeId,
                                             label: bookingParams?.state?.query?.placeId,
                                          }}
                                          isSearchable
                                          isDisabled={true}
                                          placeholder={
                                             <div className="flex justify-start ">
                                                <div style={{ marginRight: "6%" }}>
                                                   <img
                                                      style={{ width: "21px", height: "26px" }}
                                                      src={BookMarkIcon}
                                                      alt="book mark "
                                                   />
                                                </div>
                                                <div className="font-light">Select city</div>
                                             </div>
                                          }
                                          // options={renderCity()}
                                          className={`${errors.city ? "is-invalid" : ""
                                             } font-light`}
                                          // onChange={onCityChange}
                                          styles={BookMarkStyles}
                                       />
                                    </div>
                                 )}
                              />
                           </Col>
                        )}

                        {/* style={{ marginLeft: isManualBooking ? '-200px' : '-500px' }} */}
                        <Col
                           md={
                              bookingParams?.state?.query?.isProgramme === "MYO Plus" ||
                                 bookingParams?.state?.query?.isProgramme === "Specified only"
                                 ? 3
                                 : 2
                           }
                        >
                           {(bookingParams?.state?.query?.isProgramme === "MYO Plus" ||
                              bookingParams?.state?.query?.isProgramme === "Specified only" ||
                              bookingParams?.state?.query?.isProgramme === "MYO") && (
                                 <Controller
                                    name="fromDate"
                                    defaultValue={[
                                       bookingParams?.state?.query?.from
                                       ,
                                       bookingParams?.state?.query?.end,
                                    ]}
                                    control={control}
                                    render={({ field }) => (
                                       <div
                                          style={{
                                             borderRadius: "11px",
                                             // marginLeft: '280px',
                                             width: "271px",
                                             height: "44px",
                                             display: "flex",
                                             alignItems: "center",
                                             border: "1px solid hsl(0, 0%, 70%)",
                                             paddingLeft: "10px",
                                          }}
                                       >
                                          <div>
                                             <img
                                                style={{ width: "26px", height: "25.32px" }}
                                                src={CalenderIcon}
                                                alt=""
                                             />
                                          </div>
                                          <div>
                                             <RangePicker
                                                // autoFocus={false}
                                                bordered={false}
                                                className="font-light"
                                                suffixIcon={<div></div>}
                                                style={{
                                                   border: "none",
                                                   fontWeight: 300,
                                                   fontSize: "20px",
                                                }}
                                                placeholder={["Check-in", "Check-out"]}
                                                // onChange={onFromDateChange}
                                                disabledDate={disabledFromDate}
                                                disabled={true}
                                                defaultValue={[
                                                   moment(
                                                      new Date(
                                                         bookingParams?.state?.query?.from

                                                      ),
                                                      "YYYY-MM-DD"
                                                   ),
                                                   moment(
                                                      new Date(bookingParams?.state?.query?.end

                                                      ),
                                                      "YYYY-MM-DD"
                                                   ),
                                                ]}
                                             />
                                          </div>
                                       </div>
                                    )}
                                 />
                              )}
                        </Col>
                        <Col md={3}>
                           {(bookingParams?.state?.query?.isProgramme === "MYO Plus" ||
                              bookingParams?.state?.query?.isProgramme === "Specified only" ||
                              bookingParams?.state?.query?.isProgramme === "MYO") && (
                                 <Controller
                                    name="accommodation"
                                    defaultValue={""}
                                    control={control}
                                    render={({ field }) => (
                                       <div
                                          style={{
                                             marginLeft: "50px",
                                             width: "271px",
                                          }}
                                       >
                                          <Select
                                             styles={RoomTypeStyles}
                                             className={`${errors.accommodation ? "is-invalid " : ""
                                                } font-light`}
                                             // options={bedList}
                                             // onChange={onAccommodationChange}
                                             isSearchable={true}
                                             isDisabled={true}
                                             placeholder={
                                                <div className="flex justify-start ">
                                                   <div style={{ marginRight: "6%" }}>
                                                      <img
                                                         style={{ width: "21px", height: "26px" }}
                                                         src={FilterIcon}
                                                         alt="book mark "
                                                      />
                                                   </div>
                                                   <div className="font-light">
                                                      Accommodation Type
                                                   </div>
                                                </div>
                                             }
                                             components={{ DropdownIndicator: () => null }}
                                          />
                                       </div>
                                    )}
                                 />
                              )}
                        </Col>
                     </Row>
                  </Form>
               </Row>
            </div>
         </Container>
      </div>

   )
};

export default TravelerHeaderPage;