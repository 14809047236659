import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from "react-select";
import { useStoreActions, useStoreState } from "easy-peasy";
import { QuestionTypes } from '../../../../../common/constants';
import MYOQAddress from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQAddress';
import MYOQContactList from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQContactList';
import MYOQCurrency from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQCurrency';
import MYOQDate from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQDate';
import MYOQdocument from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQdocument';
import MYOQImage from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQImage';
import MYOQManyOf from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQManyOf';
import MYOQNumber from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQNumber';
import MYOQOneOf from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQOneOf';
import MYOQRate from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQRate';
import MYOQReadDoc from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQReadDoc';
import MYOQText from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQText';
import MYOQUserDefinedList from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQUserDefinedList';
import MYOQVideo from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQVideo';
import MYOQYesNo from '../../../brandRfp-management/myo-questions/questions/myoaudit/MYOQYesNo';
// import { Console } from 'console';

const QuestionSubmitted: React.FC<any> = ({ surveys, templateId }): JSX.Element => {

    const [isCompleted, setIsCompleted] = useState(false);
    const [selectedSection, setSelectedSection] = useState<any>(null);
    const [surveySections, setSurveySections] = useState([]);
    const [surveyOptions, setSurveyOptions] = useState([]);
    const [defaultSurvey, setDefaultSurvey] = useState(null);
    const [defaultSection, setDefaultSection] = useState(null);

    ///////////////////////////////// Redux CALL's ///////////////////////////////////////

    const {
        getSubmittedSurvey,
    } = useStoreActions<any>((actions) => ({
        getSubmittedSurvey: actions.myoManagementModel.getSubmittedSurvey,
    }));

    //////// global state ///////
    const {
        getSubmittedSurveySuccess
    } = useStoreState<any>((state) => ({
        getSubmittedSurveySuccess: state.myoManagementModel.getSubmittedSurveySuccess,
    }));

    ///////////////////////////////// dropdown style ///////////////////////////////////////
    const selectStyles = {
        control: (base, state) => ({
            ...base,
            height: "47px",
            paddingLeft: "10px",
            color: "#212121",
            borderRadius: 15,
            boxShadow: " 0px 0px 8px 2px rgba(0, 0, 0, 0.22)",
            "&:hover": {
                border: 0,
            },
        }),
        menu: (base) => ({
            ...base,
            hyphens: "auto",
            marginTop: 0,
            borderRadius: "14px",
            textAlign: "left",
            zIndex: 3
        }),
        menuList: (base) => ({
            ...base,
            padding: "10px",
            color: "#000000",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#000000"
        })
    };

    // ==================== Get Survey Data ====================
    useEffect(() => {
        if (surveys.length > 0) {    
            const updatedOptions = surveys.map(survey => ({
                value: survey.surveyID,
                label: survey.surveyName
            }));

            setDefaultSurvey(updatedOptions[0]);
            getAllSection(updatedOptions[0].value);
            setSurveyOptions(updatedOptions);
        }
    }, [surveys]);

    // ==================== Get Section Data ==================== 
    useEffect(() => {
        if(getSubmittedSurveySuccess?.certificateSurvey?.survey.sections && getSubmittedSurveySuccess.certificateSurvey.survey.sections.length > 0) {
            setIsCompleted(getSubmittedSurveySuccess.certificateSurvey.isCompleted);
            let surveysSection = getSubmittedSurveySuccess.certificateSurvey.survey.sections;
            const updatedOptions = surveysSection.map((section) => ({
                value: section.id,
                label: section.name
            }));
            setDefaultSection(updatedOptions[0]);
            setSelectedSection(surveysSection[0]);
            setSurveySections(updatedOptions);
        }
    }, [getSubmittedSurveySuccess])
    // ==================== Get Question Data ==================== 
    
    const handleSurvey = (selectedOption) => {
        setDefaultSurvey(selectedOption);
        if(selectedOption) {
            getAllSection(selectedOption.value);
        }
    }

    const handleSection = (selectedOption) => {
        setDefaultSection(selectedOption);
        let sectionData = getSubmittedSurveySuccess?.certificateSurvey?.survey?.sections;
        if(sectionData && sectionData.length > 0) {
            const filteredSection = sectionData.find((section) => section.id === selectedOption.value);
            setSelectedSection(filteredSection);
        }
    }

    const getAllSection = (surveyID) => {
        getSubmittedSurvey({
            security: {
              apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
              user: "superadmin@mysa.global"
            },
            RFPId: templateId,
            SurveyId: surveyID
        });
    }

    return (
        <>
            <Row className="mb-6">
                <Col md={6}>
                    <Select
                        // placeholder={<b>All Surveys</b>}
                        isClearable={true}
                        styles={selectStyles}
                        value={defaultSurvey}
                        options={surveyOptions}
                        onChange={handleSurvey}
                    />
                </Col>
                <Col md={6}>
                    <Select
                        isClearable={true}
                        styles={selectStyles}
                        value={defaultSection}
                        options={surveySections}
                        onChange={handleSection}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="bg-blue-200 w-full p-5 rounded-4">
                        {/* <Completed width="36px" height="36px" fill="#05d1c1" className="float-left" /> */}
                        {/* <p className="pt-2 pl-10 text-gray-400 font-bold">COMPLETED</p> */}
                        {selectedSection && 
                            <div>
                                {
                                    selectedSection.questions.map((q, key) => {
                                        if (q.answer) {
                                            switch (q.type) {
                                                case QuestionTypes.yes_no: 
                                                    return <div key={key}>
                                                        <MYOQYesNo data={q} completed={isCompleted} />
                                                    </div>
                                                case QuestionTypes.text:
                                                    return <div key={key}>
                                                        <MYOQText data={q} completed={isCompleted} />
                                                    </div>
                                                case QuestionTypes.number:
                                                    return <div key={key}>
                                                        <MYOQNumber data={q} completed={isCompleted} />
                                                    </div>
                                                case QuestionTypes.currency:
                                                    return <div key={key}>
                                                        <MYOQCurrency data={q} signUpStatus={''} />
                                                    </div>
                                                case QuestionTypes.date:
                                                    return <div key={key}>
                                                        <MYOQDate data={q} signUpStatus={''} />
                                                    </div>
                                                case QuestionTypes.one_of:
                                                    return <div key={key}>
                                                        <MYOQOneOf data={q} signUpStatus={''} />
                                                    </div>
                                                case QuestionTypes.many_of:
                                                    return <div key={key}>
                                                        <MYOQManyOf data={q} completed={isCompleted} />
                                                    </div>
                                                case QuestionTypes.rate:
                                                    return <div key={key}>
                                                        <MYOQRate data={q} signUpStatus={''} />
                                                    </div>
                                                case QuestionTypes.read_doc:
                                                    return <div key={key}>
                                                        <MYOQReadDoc data={q} />
                                                    </div>
                                                case QuestionTypes.contact_list:
                                                    return <div key={key}>
                                                        <MYOQContactList data={q} />
                                                    </div>
                                                case QuestionTypes.user_defined_list:
                                                    return <div key={key}>
                                                        <MYOQUserDefinedList data={q} completed={isCompleted} />
                                                    </div>
                                                case QuestionTypes.image:
                                                    return <div key={key}>
                                                        <MYOQImage data={q} />
                                                    </div>
                                                case QuestionTypes.video:
                                                    return <div key={key}>
                                                        <MYOQVideo data={q} />
                                                    </div>
                                                case QuestionTypes.document:
                                                    return <div key={key}>
                                                        <MYOQdocument data={q} />
                                                    </div>
                                                case QuestionTypes.address:
                                                    return <div key={key}>
                                                        <MYOQAddress data={q} completed={isCompleted} />
                                                    </div>
                                                default:
                                                    return <div key={key}></div>;
                                            }
                                        }else {
                                            return (<></>)
                                        }
                                    })
                                }
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default QuestionSubmitted;