import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import { Button} from 'react-bootstrap';
import {
  tableStyles,
} from "../../../common/components-style";
import moment from "moment";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";

const BrandInvitationList: React.FC<any> = (): JSX.Element => {
  const history = useHistory();
  const [brandInvitationData, setBrandInvitationData] = useState<any>([]);
  const [visibleData, setVisibleData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(7);
  const [isExpanded, setIsExpanded] = useState(false);

  const { onUpdateBrandSignup } = useStoreActions<any>((state) => ({
        onUpdateBrandSignup: state.brand.onUpdateBrandSignup,
}));
  useEffect(() => {
    if (brandInvitationData && brandInvitationData?.length > 0) {
    setVisibleData(brandInvitationData.slice(0,7)); 
    }
    else
    {
      setVisibleData([]);
    } 
  }, [brandInvitationData]);


const loadMoreData = () => {
  if (brandInvitationData && brandInvitationData?.length > 0) {
  const newIndex = currentIndex + 7;
  setVisibleData(brandInvitationData.slice(0, newIndex));
  setCurrentIndex(newIndex);
  }
};

const brandInvitationHandler = () => {
  if (brandInvitationData && brandInvitationData?.length > 0) {
  if (isExpanded) {
    setVisibleData(brandInvitationData.slice(0, 7));
    setCurrentIndex(7);
  } else {
    setVisibleData(brandInvitationData);
    setCurrentIndex(brandInvitationData.length);
  }
  setIsExpanded(!isExpanded);
}
};

  const { superAdminDashboardSuccessOnboarding, superAdminDashboardErrorOnboarding} = useStoreState<any>(
    (state) => {
      return {
        superAdminDashboardSuccessOnboarding: state.DashboardModel.superAdminDashboardSuccessOnboarding,
        superAdminDashboardErrorOnboarding: state.DashboardModel.superAdminDashboardErrorOnboarding,
      }
    }
  );
  useEffect(() => {
    if (superAdminDashboardErrorOnboarding) {
      toast.error(superAdminDashboardErrorOnboarding.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    if (superAdminDashboardSuccessOnboarding) {
      setBrandInvitationData(superAdminDashboardSuccessOnboarding?.data?.brandInvitationCount?.outstandingData);
    }
  }, [superAdminDashboardErrorOnboarding, superAdminDashboardSuccessOnboarding]);

  const handleOpen = useCallback(
    (data) => {
      let path = "invitations";
      setTimeout(() => {
        history.push({
          pathname: `/admin/brand-management`,
          state: { 
            tabKey: path  
          }
        });
      }, 500);
    },
    [history]
  );

  const columns = [
    {
      name: "Name",
      cell: (row) => (
          row.brandName
      ),
    },
    {
      name: 'Sent',
      selector: (row) => moment(row.createdAt).format("D MMMM YYYY"),
  },
    {
      name: "Contact",
      cell: (row) => row.name,
    },
    {
      name: "Email",
      cell: (row) =>  <div className="outstanding-email">
      {row.email}
    </div>
    },
    {
      name: "Latest reminder",
      selector: (row) => moment(row.createdAt).format("D MMMM YYYY"),
    },
    {
      name: 'Action',
      button: false,
      cell: (row: any) => {
              return (
              <>
            <img
              className="iconSizes mr-2"
              src={ViewEdit}
              alt=""
              onClick={() => {
                handleOpen(row);
              }}
            />
                  <Button onClick={() => {
                      onUpdateBrandSignup({ id: row._id, signUpStatus: 'sent' });
                      toast.success(`Brand invite resent`, {
                          position: toast.POSITION.BOTTOM_RIGHT,
                          className: 'foo-bar'
                      });
                  }}  className="resend_button" >Resend</Button>
              </>)
      },
  },
  ];
    return (
    <>
      <div className="tbl_accr_itm mysa-blue superadmin-table">
        <div className="tbl_hdr mysa-blue">
          <h5 className="m-0 fw-bold text-white">Outstanding Invites</h5>
          <button className="text-white pointer" onClick={brandInvitationHandler}>
            Expand
            <span>
              <img src={isExpanded  ? minus : plus} alt="icon" />
            </span>
          </button>
        </div>
        <div className="acrd_tbl">
          <DataTable
            columns={columns}
            data={visibleData}
            striped={true}
            customStyles={tableStyles}
            theme="mysaTable"
            fixedHeader={true}
            noDataComponent={"Updating records"}
            conditionalRowStyles={[
              {
                when: (row) => true,
                classNames: ['row-item'],
              },
            ]}
          />
          {currentIndex < brandInvitationData?.length && (
            <div className="more-data-section">
              <Button onClick={loadMoreData} className="more_button">More</Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BrandInvitationList;
