import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useStoreState } from "easy-peasy";
import _ from "lodash";
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import copy from "../../../images/dashboard/copy.svg";
import cancel from "../../../images/dashboard/cancel.svg";
import { Button} from 'react-bootstrap';
import {
  tableStyles
} from "../../../common/components-style";

import moment from "moment";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";
interface RowData {
  name: string;
  userStatus: string;
  image: string;
  mainContact: string;
  responsiblePersonEmail: string;
  allowedDomains: any;
  userProfile: string;
  brandStatus: string;
  financeInfo?: any;
  sustainabilityInfo?: any;

}
const IncompleteBrandsList: React.FC<any> = ({ loadForm }): JSX.Element => {
  const history = useHistory();
  const [incompleteBrandData, setIncompleteBrandData] = useState<any>([]);
  const [visibleData, setVisibleData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(7);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loadModel, setLoadModel] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<RowData | null>(null);

  const { superAdminDashboardSuccessOnboarding, superAdminDashboardErrorOnboarding } = useStoreState<any>(
    (state) => {
      return {
        superAdminDashboardSuccessOnboarding: state.DashboardModel.superAdminDashboardSuccessOnboarding,
        superAdminDashboardErrorOnboarding: state.DashboardModel.superAdminDashboardErrorOnboarding,
      }
    }
  );
  useEffect(() => {
    if (superAdminDashboardErrorOnboarding) {
      toast.error(superAdminDashboardErrorOnboarding.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    if (superAdminDashboardSuccessOnboarding) {
      setIncompleteBrandData(superAdminDashboardSuccessOnboarding?.data?.getAllBrandCount?.incompleteData);
    }
  }, [superAdminDashboardErrorOnboarding, superAdminDashboardSuccessOnboarding]);

  useEffect(() => {
    if (incompleteBrandData && incompleteBrandData?.length > 0) {
    setVisibleData(incompleteBrandData.slice(0, 7));
    }
    else
    {
    setVisibleData([]);
    }
  }, [incompleteBrandData]);

  const loadMoreData = () => {
    if (incompleteBrandData && incompleteBrandData?.length > 0) {
    const newIndex = currentIndex + 7;
    setVisibleData(incompleteBrandData.slice(0, newIndex));
    setCurrentIndex(newIndex);
    }
  };

  const handleRowClick = (row) => {
    setSelectedRowData(row);
    setLoadModel(true);
  };

  const incompleteBrandHandler = () => {
    if (incompleteBrandData && incompleteBrandData?.length > 0) {
    if (isExpanded) {
      setVisibleData(incompleteBrandData.slice(0, 7));
      setCurrentIndex(7);
    } else {
      setVisibleData(incompleteBrandData);
      setCurrentIndex(incompleteBrandData.length);
    }
    setIsExpanded(!isExpanded);
  }
  };

  const handleOpen = useCallback((data) => {
    setTimeout(() => {
      history.push('/admin/brand-management', {
        defaultKey: 'details',
        brandId: data?._id,
        formOpen: true,
        brandInvite: false
      })
    }, 500);
  },
    [history]);

  const columns = [
    {
      name: "Name",
      cell: (row) => (
        row.name
      ),
    },
    {
      name: 'Created',
      selector: (row) => moment(row.createdAt).format("D MMMM YYYY"),
    },
    {
      name: "Contact",
      cell: (row) => row.lastName ? `${row.firstName} ${row.lastName}` : row.firstName
    },
    {
      name: "Email",
      cell: (row) => <div className="outstanding-email">
        {row.firstUserEmail}
      </div>
    },
    {
      name: "Properties",
      selector: (row) => row.propertyCount,
    },
    {
      name: 'Action',
      button: false,
      cell: (row: any) => {
        return (
          <>
            <img className="mr-4 copy-icons" src={copy} alt="icon" onClick={() => {
              handleRowClick(row)
            }} />

            <img
              className="iconSizes"
              src={ViewEdit}
              alt=""
              onClick={() => {
                handleOpen(row);
              }}
            />
          </>)
      },
    },
  ];
  return (
    <>
      <div className={`superadmin-table ${loadModel ? `show ${visibleData?.length < 4 ? 'superadmin-height' : ''}` : ''}`}>
        <div className="tbl_accr_itm">
          <div className="tbl_hdr mysa-green">
            <h5 className="m-0 fw-bold text-white">Incomplete Brands</h5>
            <button className="text-white pointer" onClick={incompleteBrandHandler}>
              Expand
              <span>
                <img src={isExpanded ? minus : plus} alt="icon" />
              </span>
            </button>
          </div>
          <div className="acrd_tbl">
            <DataTable
              columns={columns}
              data={visibleData}
              striped={true}
              customStyles={tableStyles}
              theme="mysaTable"
              fixedHeader={true}
              conditionalRowStyles={[
                {
                  when: (row) => true,
                  classNames: ['row-item'],
                },
              ]} noDataComponent={"Updating records"}

            />
            {currentIndex < incompleteBrandData?.length && (
              <div className="more-data-section">
                <Button onClick={loadMoreData} className="more_button">More</Button>
              </div>
            )}
          </div>
        </div>
        <div className="brand-modal">
          <div className="brand-dialog">
            <div className="brand_content">
              <div className="brand_modal">
                <div className="brand_header mysa-green">
                  <h3>{selectedRowData?.name}</h3>
                  <img className="cancel-icon" src={cancel} alt="icon" onClick={() => {
                    setLoadModel(false);
                }}/>
                        </div>
                        <div className="brand_body">
                            <ul>
                                <li>Lead contact logged in <div className={`point-circle ${selectedRowData?.userStatus === 'CONFIRMED' ? 'mysa-green' : ''} ${selectedRowData?.userStatus === 'FORCE_CHANGE_PASSWORD' ? 'mysa-pink' : ''}`}></div></li>
                                <li>Lead contact profile <div className={`point-circle ${selectedRowData?.userProfile === 'COMPLETED' ? 'mysa-green' : ''} ${selectedRowData?.userProfile === 'INCOMPLETE' ? 'mysa-pink' : ''}`}></div></li>
                                <li>Brand details
                                          <div
                                            className={`point-circle
                                              ${
                                                (selectedRowData?.name &&
                                                selectedRowData?.image &&
                                                selectedRowData?.mainContact &&
                                                selectedRowData?.responsiblePersonEmail &&
                                                selectedRowData?.allowedDomains?.length > 0 &&
                                                selectedRowData?.brandStatus)
                                                  ? 'mysa-green'
                                                  : 
                                                  (selectedRowData?.brandStatus &&
                                                  selectedRowData?.name)
                                                    ? 'mysa-pink'
                                                    : ''
                                              }`}
                                          ></div>
                                        </li>
                                <li>Brand sustainability <div className={`${selectedRowData?.sustainabilityInfo? 'mysa-green' : 'mysa-pink'} point-circle`}></div></li>
                                <li>Brand finance <div className={`${selectedRowData?.financeInfo ? 'mysa-green' : 'mysa-pink'} point-circle`}></div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default IncompleteBrandsList;
