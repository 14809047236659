import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Menu } from "antd";
import ClientProgrammePropertyList from "./views/ClientProgrammePropertyList";
import Select from "react-select";
import icon from "../../../../images/icons/maps-and-flags.png";
import MysaReview from "./views/ClientMysaReview";
import { useStoreActions, useStoreState } from "easy-peasy";
import { getStorage, setStorage } from "../../../../common/storage";
import { Col, Row } from "react-bootstrap";
import CostComparison from "./views/ClientCostComparison";
import { RfpProcessStatus, UserGroups } from "../../../../common/constants";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { getLoggedUserType } from "../../../../common/functions";

///////////////////////////////// dropdown style ///////////////////////////////////////

const selectStyles = {
    control: (base, state) => ({
        ...base,
        background: "#FBC91D",
        height: "50px",
        paddingLeft: "10px",
        color: "#212121",
        borderRadius: 15,
        borderColor: "#F0F0F0",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: "#212121",
        },
    }),
    menu: (base) => ({
        ...base,
        hyphens: "auto",
        marginTop: 0,
        borderRadius: "14px",
        textAlign: "left",
        zIndex: 3
    }),
    menuList: (base) => ({
        ...base,
        padding: "10px",
        color: "#000000",
    }),
    dropdownIndicator: base => ({
        ...base,
        color: "#000000"
    })
};

const PropertyAssessment: React.FC<any> = ({ programmeId, programmeName, clientName, startDate }): JSX.Element => {
    // url manage
    const location = useLocation<any>();
    const [selectedFilterClient] = useState<any>(getStorage('selectedFilterClient'));
    const [selectedProgramsOption] = useState<any>(getStorage('selectedProgramsOption'));

    ///////////////////////////////////////// local state manage /////////////////////////////////////////
    const [menuKey, setMenuKey] = useState("properties");
    const [rfpProcess, setRfpProcess] = useState<any>();
    const [locationOption, setLocationOption] = useState<any>([]);
    const [defaultLocationOption] = useState<any>(location.state?.cityName ? {
        value: location.state?.cityName,
        label: (
            <div className="flex">
                <img src={icon} className="mr-3" alt="" />{" "}
                <b>
                    {location.state?.cityName}
                </b>
            </div>
        ),
    } : []);
    const [selectCityName, setSelectCityName] = useState<any>();
    const [resetSelect, setResetSelect] = useState<any>();



    ///////////////////////////////////// global action manage /////////////////////////////////////////
    const { getProcessByProgrammeIdAndStatus } = useStoreActions<any>((actions) => ({
        getProcessByProgrammeIdAndStatus: actions.rfpProcess.rfpProcessByProgrammeIdAndStatus
    }));

    /////////////////////////////////// global state manage ////////////////////////////////////////////
    const { rfpProcessByProgramIdAndStatusSuccess } = useStoreState<any>(
        (state) => ({
            rfpProcessByProgramIdAndStatusSuccess:
                state.rfpProcess.rfpProcessByProgramIdAndStatusSuccess,
        })
    );


    /////////////////////////////////////////////////
    useEffect(() => {
        // const selectedProgram = getStorage('selectedProgramsOption');
        // console.log(selectedProgram, 'selectedProgram');
        const payload = {
            programmeId: selectedProgramsOption?.value ? selectedProgramsOption?.value : programmeId,
            RFPStatus: RfpProcessStatus.INPROGRESS,
            UserType : getLoggedUserType() === UserGroups.super_admin ? UserGroups.super_admin : UserGroups.corporate_admin
        }

        getProcessByProgrammeIdAndStatus(payload);
    }, [getProcessByProgrammeIdAndStatus, programmeId, selectedProgramsOption?.value])

    useEffect(() => {
        if (location.state?.cityName) {
            const city = {
                value: location.state?.cityName,
                label: (
                    <div className="flex">
                        <img src={icon} className="mr-3" alt="" />{" "}
                        <b>
                            {location.state?.cityName}
                        </b>
                    </div>
                ),
            };
            setLocationOption(city);
        }
    }, [location, location.state?.cityName])

    ///////////////////////// menu change ///////////////////////////////////
    const menuChange = useCallback((value) => {
        setMenuKey(value.key);
    }, []);

    const onChangeToSelect = useCallback((value) => {
        setSelectCityName(value.value);
        setResetSelect(value.value);
    }, []);

    useEffect(() => {
        if (rfpProcessByProgramIdAndStatusSuccess) {
            if (rfpProcessByProgramIdAndStatusSuccess.data.length !== 0) {
                let locations: any = [];
                const rfp = rfpProcessByProgramIdAndStatusSuccess.data[0];
                rfp?.locations.forEach((location) => {
                    location?.cities?.forEach(element => {
                        let data = {
                            value: element.cityName,
                            label: (
                                <div className="flex">
                                    <img src={icon} className="mr-3" alt="" />{" "}
                                    <b>
                                        {element.cityName}
                                    </b>
                                </div>
                            ),
                        };
                        locations.push(data);
                    });
                });
                setLocationOption(locations);
                setStorage(
                    "selectedRFPProcess",
                    rfpProcessByProgramIdAndStatusSuccess.data[0]
                );
                setRfpProcess(rfpProcessByProgramIdAndStatusSuccess.data[0]);
            }
        } else {

            if (!rfpProcess) {
                setRfpProcess(getStorage("selectedRFPProcess"));
            }
            let locations: any = [];
            rfpProcess?.locations.forEach((location) => {
                location?.cities?.forEach(element => {
                    let data = {
                        value: element.cityName,
                        label: (
                            <div className="flex">
                                <img src={icon} className="mr-3" alt="" />{" "}
                                <b>
                                    {element.cityName}
                                </b>
                            </div>
                        ),
                    };
                    locations.push(data);
                });
            });
            setLocationOption(locations);


        }
    }, [rfpProcess, rfpProcess?.locations, rfpProcessByProgramIdAndStatusSuccess]);

    const selectRender = useMemo(() => {
        return (
            <>
                {resetSelect ?
                    <Select
                        styles={selectStyles}
                        options={locationOption}
                        onChange={onChangeToSelect}
                        className="new_drpdwn"
                    /> : <Select
                        value={defaultLocationOption.length !== 0 ? defaultLocationOption : locationOption[0]}
                        styles={selectStyles}
                        options={locationOption}
                        onChange={onChangeToSelect}
                        className="new_drpdwn"
                    />}

            </>
        )
    }, [defaultLocationOption, locationOption, onChangeToSelect, resetSelect])

    return (
        <>
            <Row >
                <Col md={12}>
                    <div className='content-title mysa-blue-text'>
                        <Link to='/admin/client-proposal-management' className="fw-sb mysa-blue-text">{`${selectedFilterClient?.label ? selectedFilterClient?.label : clientName}`}</Link> {` - ${selectedProgramsOption?.label ? selectedProgramsOption?.label : programmeName} - RFP ${moment(rfpProcess?.startDate ? rfpProcess?.startDate : startDate).format('ll')}`}
                    </div>
                </Col>
            </Row>
            <Row className="" style={{ marginTop: "12px" }}>
                <Col md={6}>
                    <div className="flex-bx">
                        <div className="fw-bold fz-32">
                            Properties to assess in
                        </div>
                        <div>
                            {selectRender}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className=''>
                <Col>
                    <Menu
                        onClick={menuChange}
                        mode="horizontal"
                        className="w-full new_tab"
                        selectedKeys={[menuKey]}
                    >
                        <Menu.Item key="properties">
                            <span>
                                Properties
                            </span>
                        </Menu.Item>
                        <Menu.Item key="MYSAReview">
                            <span>
                                Area Review
                            </span>
                        </Menu.Item>
                        <Menu.Item key="costComparison">
                            <span>
                                Cost Comparison
                            </span>
                        </Menu.Item>
                    </Menu>
                    <div className="new_tab_ctnt">
                        {menuKey === "properties" && (
                            <ClientProgrammePropertyList rfpProcess={rfpProcess} selectCityName={selectCityName !== undefined ? selectCityName : location.state?.cityName ? location.state?.cityName : locationOption[0]?.value}
                            />
                        )}
                        {menuKey === "MYSAReview" && <MysaReview rfpProcess={rfpProcess} selectCityName={selectCityName !== undefined ? selectCityName : location.state?.cityName ? location.state?.cityName : locationOption[0]?.value} />}
                        {menuKey === "costComparison" && <CostComparison rfpProcess={rfpProcess} selectCityName={selectCityName !== undefined ? selectCityName : location.state?.cityName ? location.state?.cityName : locationOption[0]?.value} />}
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default PropertyAssessment;
