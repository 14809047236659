import React, { useEffect, useState } from "react";
import { navContentStyle, navStyle, tabDivStyle } from "../../../common/components-style";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { brandUser, corporateUser } from "../../../common/functions";
import AdminOperatorUser from "./OperatorUser";
import AdminCorporateUser from "./AdminCorporateUser";

const AdminUserManagementHome: React.FunctionComponent = () => {

    /**
     * local state
     */
    const [tabKey, setTabKey] = useState<any>('operatorUser');
    const [operatorUser, setOperatorUser] = useState<Array<any>>([]);
    const [corporateUsers, setCorporateUsers] = useState<Array<any>>([]);

    /**
     * global action
     */
    const { getUserMapping } = useStoreActions<any>((actions) => ({
        getUserMapping: actions.user.getUserMapping,
    }));

    /**
     * global state
     */
    const { getUserMappingSuccess } = useStoreState<any>((state) => ({
        getUserMappingSuccess: state.user.getUserMappingSuccess,
    }));

    useEffect(() => {
        getUserMapping({});
    }, [getUserMapping]);

    useEffect(() => {

        if (getUserMappingSuccess) {
            const { result, poolUsers } = getUserMappingSuccess.data;

            /**
             * brand users
             */
            const brandUserList = brandUser(result, poolUsers);
            setOperatorUser(brandUserList);

            /**
             * corporate users
             */
            const corporateUserList = corporateUser(result, poolUsers);
            setCorporateUsers(corporateUserList);
        }

    }, [getUserMappingSuccess]);

    return (<>
        <div style={tabDivStyle}>
            <Tab.Container defaultActiveKey="operatorUser" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                <Row >
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                            <div style={{ marginTop: '0%' }}>
                                <Nav.Item><Nav.Link eventKey="operatorUser">Operator Users</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="corporateUser">Corporate Users</Nav.Link></Nav.Item>
                            </div>
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content >
                            <Tab.Pane eventKey="operatorUser" >
                                <div style={navContentStyle}>
                                    <AdminOperatorUser operatorUser={operatorUser} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="corporateUser">
                                <div style={navContentStyle}>
                                    <AdminCorporateUser corporateUsers={corporateUsers} />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    </>);
};

export default AdminUserManagementHome;