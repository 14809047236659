import React, { useMemo, useState } from "react";
import Select from "react-select";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { $mysaRed, $mysaTeal, $mysaYellow, overlayTriggerDelay, tableStyles } from "../../../../common/components-style";
import DataTable from "react-data-table-component";
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import Archive from '../../../../images/icons/svg/outside/Icon-Function-Archive.svg';


import { BrandRfpPropertyEvaluationStatus } from "../../../../common/constants";


import CircleIcon from '../../../../images/icons/svg/circle.svg';
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import ProgrammePropertyDetailsToAccess from "./common-views/ProgrammePropertyDetails";

import { setStorage } from "../../../../common/storage";
import { isBooker, isProgramAdmin, isTraveller } from "../../../../common/functions";


///////////////////////////////// dropdown style ///////////////////////////////////////

const selectStyles = {
    control: (base, state) => ({
        ...base,
        background: "#2DBDB6",
        height: "50px",
        paddingLeft: "10px",
        color: "#212121",
        borderRadius: 15,
        borderColor: "#2DBDB6",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: "#2DBDB6",
        },
    }),
    menu: (base) => ({
        ...base,
        hyphens: "auto",
        marginTop: 0,
        borderRadius: "14px",
        textAlign: "left",
        zIndex: 3
    }),
    menuList: (base) => ({
        ...base,
        padding: "10px",
        color: "#000000",
    }),
    dropdownIndicator: base => ({
        ...base,
        color: "#000000"
    })
};



const MyPropertiesHome: React.FC<any> = ({ location,activateProperty, archiveProperty, tableData, rfpProcess, changeCountryName, changeCityName, countryOption, locationOption, programmeData }): JSX.Element => {



    const [archivePopup, setArchivePopup] = useState<boolean>(false);
    const [dataList, setDataList] = useState<any>();
    const [activatePopup, setActivatePopup] = useState<boolean>(false);
    const [status, setStatus] = useState<any>([]);

    const [showView, setShowView] = useState<boolean>(false);

    const selectCountryRender = useMemo(() => {
        return (
            <>

                <Select
                    styles={selectStyles}
                    options={countryOption}

                    placeholder="All Countries"
                    onChange={changeCountryName}
                    // value={countryOption[0] ? countryOption[0] : ''}
                    className="new_drpdwn2"
                />

            </>
        )
    }, [changeCountryName, countryOption])



    const selectCityRender = useMemo(() => {
        return (
            <>

                <Select
                    styles={selectStyles}
                    options={locationOption}
                    className="new_drpdwn2"
                    placeholder="All Cities"
                    onChange={changeCityName}
                // value={defaultLocationOption.length !== 0 ? defaultLocationOption : locationOption[0]}
                />

            </>
        )
    }, [changeCityName, locationOption])

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const columns = [
        {
            name: 'Country',
            selector: row => row.property?.country,
        },
        {
            name: 'City',
            selector: row => row.property?.city,
        },
        {
            name: 'Property',
            selector: row => row.property?.propertyName,
        },
        {
            name: 'Brand',
            selector: row => row.property?.brandId?.name ? row.property?.brandId?.name : row.property?.brand?.name,
        },

        {
            name: 'Status',
            // wrap: true,
            cell: (row: any) => {
                let color = "";

                let text = "";
                if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) {
                    color = $mysaYellow;
                    text = "Offline";
                }
                if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACTIVATED) {
                    text = 'Live';
                    color = $mysaTeal;
                }

                if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.ARCHIVE) {
                    text = 'Archive';
                    color = $mysaRed;
                }

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize' >{text}</span>

                )
            }
        },
        {
            name: 'Action',
            button: true,
            cell: (row: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
                    >
                        <img className='iconSizes' style={(row.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) ? { marginLeft: '0px' } : { marginLeft: '-35px' } ? (isBooker() && isTraveller()) ? { marginLeft: '-35px' } : { marginLeft: '0px' } : { marginLeft: '0px' }} src={ViewEdit} alt="" onClick={() => {
                            // history.push({
                            //     pathname: `/admin/corporate-programme-management/property/${row.property?._id}/${rfpProcess?._id}`
                            // });
                            setShowView(true);
                            setDataList(row);


                            let text = "";
                            if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) {

                                text = "Offline";
                            }
                            if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACTIVATED) {
                                text = 'Live';

                            }

                            if (row.evaluationStatus === BrandRfpPropertyEvaluationStatus.ARCHIVE) {
                                text = 'Archive';

                            }
                            const type = {
                                value: row.evaluationStatus,
                                label: (<b>{text}</b>)
                            }
                            setStatus(type);

                            setStorage('viewData', true);
                        }} />
                    </OverlayTrigger>

                    {(isProgramAdmin()) && <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
                    >
                        <img className='iconSizes' src={Archive} alt="" onClick={() => { setArchivePopup(true); setDataList(row) }} />
                    </OverlayTrigger>}
                    {(row.evaluationStatus !== BrandRfpPropertyEvaluationStatus.ACTIVATED && ( isProgramAdmin())) &&

                        <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-table-top'>{'Activate'}</Tooltip>}
                        >
                            <img className='mr-2 iconSizes2' src={CircleIcon} alt=""
                                onClick={() => {
                                    setActivatePopup(true);
                                    setDataList(row)
                                }}
                            />
                        </OverlayTrigger>}

                </>)
            },
        },

    ];

    return (
        <>
            {!showView && tableData?.length > 0 && <Row>
                {/* <Col md={10}> */}
                <Row style={{ marginTop: "-30px", marginLeft: "7px" }}>
                    <p style={{ fontSize: '25px', fontWeight: 'bold' }}>{`${programmeData?.clientId?.companyName?programmeData?.clientId?.companyName:location?.state?.selectedClient?.companyName}`}
                         {` - ${programmeData?.programmeName?programmeData?.programmeName:location?.state?.selectedProgramme?.programmeName} - RFP ${moment(rfpProcess?.startDate).format('ll')}`}</p>

                </Row>

                <Row style={{ marginLeft: "7px", marginTop: '15px' }}>

                    <Col md={2}>
                        <div style={{ marginTop: '3%' }}>
                            {selectCountryRender}
                        </div>
                    </Col>

                    <Col md={2}>
                        <div style={{ marginTop: '3%', marginLeft: '200px' }}>
                            {selectCityRender}
                        </div>
                    </Col>
                </Row>

                <div className='mt-5'>
                    <DataTable
                        columns={columns}
                        data={tableData}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme='mysaTable'
                        fixedHeader={true}
                        noDataComponent={'Updating records'}
                        fixedHeaderScrollHeight='600px'
                    />
                </div>


            </Row>}


            {!showView && tableData?.length === 0 &&
                <div className='mt-5'>
                    <h1 className='text-center'>This programme is still in development</h1>
                </div>
            }

            <SweetAlert
                show={archivePopup}
                warning
                showCancel={true}
                focusCancelBtn={true}
                confirmBtnText="Yes archive it!"
                confirmBtnBsStyle="danger"
                title="Are you sure you wish to archive this property?"
                onConfirm={() => {
                    archiveProperty(dataList);

                    setArchivePopup(false);
                }}
                onCancel={() => {
                    setArchivePopup(false);
                }}
            >
            </SweetAlert>

            <SweetAlert
                show={activatePopup}
                warning
                showCancel={true}
                focusCancelBtn={true}
                confirmBtnText="Yes activate it!"
                confirmBtnBsStyle="danger"
                title="Are you sure you wish to activate this property"
                onConfirm={() => {
                    activateProperty(dataList);
                    // onApprovalBrand(brandArchive);
                    setActivatePopup(false);
                }}
                onCancel={() => {
                    setActivatePopup(false);
                }}
            >
            </SweetAlert>


            {showView && <ProgrammePropertyDetailsToAccess propertyId={dataList?.property?._id} rfpId={rfpProcess?._id} data={status} tableData={tableData}></ProgrammePropertyDetailsToAccess>}

        </>
    );
};

export default MyPropertiesHome;
