import { message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { formButton } from '../../../../../common/components-style';
import { UserAccess, UserGroups } from '../../../../../common/constants';
import { canCorporateProgrammeModuleWrite, setCompanyPermission } from '../../../../../common/functions';
import { BrandUserFormInput } from '../../../../../common/interfaces';
import DebounceSearch from '../../../../common/DebounceSearch';

const TravelerUserForm: React.FC<any> = ({ clientId, selectedUser, close, reload, programmeId, companyPermissions }): JSX.Element => {
  const { register, handleSubmit, setValue, reset, formState: { errors }, control } = useForm<BrandUserFormInput>();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const updateUser = useStoreActions<any>((actions) => actions.user.updateUser);
  const createUser = useStoreActions<any>((actions) => actions.user.createUser);
  const updateAccess = useStoreActions<any>((actions) => actions.user.updateAccess);
  const resetUser = useStoreActions<any>((actions) => actions.user.resetUser);
  const createUserMapping = useStoreActions<any>((actions) => actions.user.createUserMapping);
  const updateUserMapping = useStoreActions<any>((actions) => actions.user.updateUserMapping);

  const userUpdate = useStoreState<any>((state) => state.user.userUpdate);
  const userCreate = useStoreState<any>((state) => state.user.userCreate);
  const userCreateError = useStoreState<any>((state) => state.user.userCreateError);
  const userUpdateError = useStoreState<any>((state) => state.user.userUpdateError);
  const updateAccessSuccess = useStoreState<any>((state) => state.user.updateAccessSuccess);
  const createUserMappingSuccess = useStoreState<any>((state) => state.user.createUserMappingSuccess);
  const updateUserMappingSuccess = useStoreState<any>((state) => state.user.updateUserMappingSuccess);


  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    //user update success
    if (userUpdate) {
      updateAccess({
        id: selectedUser.arrId,
        userId: selectedUser.userId,
        role: UserGroups.traveler,
        accessType: "programmeRole"
      });
      // form.resetFields();
      // reload();
      reset();
      setLoading(false);
      message.success("User updated successfully");
      resetUser();
    }
    // user create success
    if (userCreate) {
      if (userCreate.data.data) {
        // create mapping
        createUserMapping({
          userId: userCreate.data.user._id,
          email: userCreate.data.user.email,
          clientId,
          programmeId,
          role: UserGroups.traveler
        })
      } else {
        //update mapping
        updateUserMapping({
          userId: userCreate.data.user._id,
          client: {
            clientId,
          },
          corporateProgramme: {
            programmeId,
            role: UserGroups.traveler
          }

        })
      }
      // form.resetFields();
      reset();
      resetUser();
      setLoading(false);
      // reload();
    }

    // update user mapping success 
    if (updateUserMappingSuccess || createUserMappingSuccess) {
      const corporateProgramme = _.filter(updateUserMappingSuccess?.data?.clients || createUserMappingSuccess?.data?.clients, (a) => a.clientId === clientId).shift()?.corporateProgramme;
      const arrId = _.filter(corporateProgramme, (a) => a.programmeId === programmeId).shift()?._id || ''
      const id = arrId;
      const userId = createUserMappingSuccess?.data.userId || updateUserMappingSuccess?.data?.userId;
      updateAccess(setCompanyPermission(id, userId, companyPermissions?.travelerPermissions));
      message.success("User created successfully");
      resetUser();
      reload();
    }

    // user create error
    if (userCreateError) {
      message.error(userCreateError.message);
      resetUser();
      setLoading(false);
    }

    // access update success
    if (updateAccessSuccess) {
      reload();
      resetUser();
      setLoading(false)
      message.success('Permission updated successfully');
    }

    // user update error
    if (userUpdateError) {
      message.error(userUpdateError.message);
      setLoading(false);
      resetUser();
      setLoading(false);
    }

  }, [reload, userUpdate, setLoading, userUpdateError, updateAccessSuccess, selectedUser, updateAccess, resetUser, userCreate, userCreateError, updateUserMapping, updateUserMappingSuccess, createUserMappingSuccess, createUserMapping, clientId, programmeId, reset, companyPermissions?.travelerPermissions]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSubmit = useCallback((values) => {
    setLoading(true);
    values.email = email;
    values.userGroup = UserGroups.user
    if (selectedUser) {
      updateUser(values);
    } else {
      values.telephone = values.phone;
      delete values.phone;
      createUser(values);
    }
  }, [email, selectedUser, updateUser, createUser]);

  async function fetchUserList(email) {
    const param = email === '' ? null : email;
    return fetch(`${process.env.REACT_APP_API_URL}user-sv/users/search/byClient?search=${param}&clientId=${clientId}`)
      .then((response: any) => response.json())
      .then((body) =>
        body.data.map((user) => ({
          label: user.email,
          value: user.email,
          user
        })),
      );
  }

  return (
    <>
      <Container>
        <div className="jumbotron" >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={1}></Col>
              <Col md={10} style={{marginLeft:'-200px'}}>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label><span className='requiredMark'>*</span> Email</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={selectedUser?.email}
                      rules={{
                        required: email ? false : true
                      }}
                      render={({ field }) =>

                        <DebounceSearch
                          value={email}
                          placeholder="search users"
                          fetchOptions={fetchUserList}
                          onChange={(newValue) => {
                            setEmail(newValue);
                            setValue("firstName", "");
                            setValue("lastName", "");
                          }}
                          disabled={selectedUser ? true : false}
                          onSelect={(newValue, options) => {
                            setEmail(newValue);
                            setValue("firstName", options.user.firstName);
                            setValue("lastName", options.user.lastName);
                          }}
                          style={{
                            width: '100%',
                          }}
                          containerClass={`${errors.email ? 'is-invalid' : ''}`}
                        />
                      }
                    />
                    {errors.email && <div className="invalid-feedback-custom">Please input email!</div>}
                  </Col>
                </Row>

                {/* <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label><span className='requiredMark'>*</span> Email</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        <Controller
                                            name="email"
                                            control={control}
                                            defaultValue={selectedUser?.email}
                                            rules={{
                                                required: email ? false : true
                                            }}
                                            render={({ field }) =>

                                                <DebounceSearch
                                                    value={email}
                                                    placeholder="search users"
                                                    fetchOptions={fetchUserList}
                                                    onChange={(newValue) => {
                                                        setEmail(newValue);
                                                        setValue("firstName", "");
                                                        setValue("lastName", "");
                                                    }}
                                                    disabled={selectedUser ? true : false}
                                                    onSelect={(newValue, options) => {
                                                        setEmail(newValue);
                                                        setValue("firstName", options.user.firstName);
                                                        setValue("lastName", options.user.lastName);
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                    }}

                                                    containerClass={`${errors.email ? 'is-invalid' : ''}`}
                                                />
                                            }
                                        />
                                        {errors.email && <div className="invalid-feedback-custom">Please input email!</div>}
                                    </Col>
                                </Row> */}
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label><span className='requiredMark'>*</span> First name</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <Form.Control className={`${errors.firstName ? 'is-invalid' : ''}`} defaultValue={selectedUser?.firstName} {...register("firstName", { required: true })} />
                    <div className="invalid-feedback">Please input first name!</div>
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label> <span className='requiredMark'>*</span> Last name</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <Form.Control className={`${errors.lastName ? 'is-invalid' : ''}`} defaultValue={selectedUser?.lastName} {...register("lastName", { required: true })} />
                    <div className="invalid-feedback">Please input last name!</div>
                  </Col>
                </Row>


                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!loading && canCorporateProgrammeModuleWrite(clientId, programmeId, UserAccess.programme_access.programmeUsers.code) &&
                      <Button type="submit" id='approve-button' style={formButton} >Create</Button>

                    }
                    {!loading &&
                      <Button id='edit-button' style={formButton} onClick={() => { close(); }}>Close</Button>
                    }
                    {
                      loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                    }
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Form>
        </div>
      </Container>
    </>
    // <div className="programmeForm w-1/2">
    //     {
    //         (getLoggedUserEmail() === selectedUser?.email) ?
    //             <Form
    //                 {...layout}
    //                 onFinish={onFinish}
    //                 form={form}
    //             >

    //                 <Form.Item
    //                     label="Email"
    //                     name="email"
    //                     rules={[
    //                         { required: true, message: 'Please input email!' },
    //                         { type: 'email', message: 'Please input valid email!' },
    //                     ]}
    //                 >
    //                     <DebounceSearch
    //                         value={email}
    //                         placeholder="search users"
    //                         fetchOptions={fetchUserList}
    //                         onChange={(newValue) => {
    //                             setEmail(newValue);
    //                             form.setFieldsValue({ firstName: '', lastName: '' })
    //                         }}
    //                         disabled={selectedUser ? true : false}
    //                         onSelect={(newValue, options) => {
    //                             setEmail(newValue);
    //                             form.setFieldsValue({ firstName: options.user.firstName, lastName: options.user.lastName })
    //                         }}
    //                         style={{
    //                             width: '100%',
    //                         }}
    //                     />
    //                 </Form.Item>
    //                 <Form.Item
    //                     label="First name"
    //                     name="firstName"
    //                     rules={[{ required: true, message: 'Please input first name!' }]}
    //                 >
    //                     <Input />
    //                 </Form.Item>
    //                 <Form.Item
    //                     label="Last name"
    //                     name="lastName"
    //                     rules={[{ required: true, message: 'Please input last name!' }]}
    //                 >
    //                     <Input />
    //                 </Form.Item>
    //                 <Form.Item
    //                     label="Job title"
    //                     name="position"
    //                     rules={[{ required: true, message: 'Please input position!' }]}
    //                 >
    //                     <Input />
    //                 </Form.Item>
    //                 <Form.Item
    //                     label="Phone"
    //                     name="phone"
    //                     rules={[{ required: true, message: 'Please input phone!' }]}
    //                 >
    //                     <PhoneInput
    //                         country='gb'
    //                     // value={phone}
    //                     />
    //                 </Form.Item>
    //                 <Form.Item
    //                     label="Mobile"
    //                     name="mobile"
    //                 >
    //                     <PhoneInput
    //                         country='gb'
    //                     // value={mobile}
    //                     />
    //                 </Form.Item>
    //                 <Form.Item {...tailLayout}>
    //                     {canCorporateProgrammeModuleWrite(clientId, programmeId, UserAccess.programme_access.programmeUsers.code) &&
    //                         <Button type="primary" loading={loading} htmlType="submit">
    //                             {selectedUser ? 'Update' : 'Create'}
    //                         </Button>
    //                     }
    //                     {!(canCorporateProgrammeModuleWrite(clientId, programmeId, UserAccess.programme_access.programmeUsers.code) && getLoggedUserEmail() === selectedUser?.email) ?
    //                         <Button type="primary" loading={loading} htmlType="submit">
    //                             {selectedUser ? 'Update' : 'Create'}
    //                         </Button> : ''
    //                     }
    //                     <Button className="ml-5" onClick={() => {
    //                         form.resetFields();
    //                         close();
    //                     }}>
    //                         Close
    //                     </Button>
    //                 </Form.Item>
    //             </Form>
    //             :

    //             <Form
    //                 {...layout}
    //                 onFinish={onFinish}
    //                 form={form}
    //             >
    //                 <Form.Item
    //                     label="Email"
    //                     name="email"
    //                     rules={[
    //                         { required: true, message: 'Please input email!' },
    //                         { type: 'email', message: 'Please input valid email!' },
    //                     ]}
    //                 >
    //                     <DebounceSearch
    //                         value={email}
    //                         placeholder="search users"
    //                         fetchOptions={fetchUserList}
    //                         onChange={(newValue) => {
    //                             setEmail(newValue);
    //                             form.setFieldsValue({ firstName: '', lastName: '' })
    //                         }}
    //                         disabled={selectedUser ? true : false}
    //                         onSelect={(newValue, options) => {
    //                             setEmail(newValue);
    //                             form.setFieldsValue({ firstName: options.user.firstName, lastName: options.user.lastName })
    //                         }}
    //                         style={{
    //                             width: '100%',
    //                         }}
    //                     />
    //                 </Form.Item>
    //                 <Form.Item
    //                     label="First name"
    //                     name="firstName"
    //                     rules={[{ required: true, message: 'Please input first name!' }]}
    //                 >
    //                     <Input />
    //                 </Form.Item>
    //                 <Form.Item
    //                     label="Last name"
    //                     name="lastName"
    //                     rules={[{ required: true, message: 'Please input last name!' }]}
    //                 >
    //                     <Input />
    //                 </Form.Item>

    //                 <Form.Item {...tailLayout}>
    //                     {canCorporateProgrammeModuleWrite(clientId, programmeId, UserAccess.programme_access.programmeUsers.code) &&
    //                         <Button type="primary" loading={loading} htmlType="submit">
    //                             {selectedUser ? 'Update' : 'Create'}
    //                         </Button>
    //                     }
    //                     <Button className="ml-5" onClick={() => {
    //                         form.resetFields();
    //                         close();
    //                     }}>
    //                         Close
    //                     </Button>
    //                 </Form.Item>
    //             </Form>
    //     }
    // </div >
  )
}

export default TravelerUserForm;