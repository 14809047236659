import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import TreeNode from '../../../../common/TreeNode'
import { UserAccess, UserGroups } from '../../../../../common/constants';
import { getCheckedStatus, setCheckedValues } from '../../../../../common/functions'
import { useStoreActions } from 'easy-peasy';
import _ from 'lodash';
import { formButton } from '../../../../../common/components-style';
import { Button, Modal } from 'react-bootstrap';
import { Tree } from 'antd';

// const initTreeData = [

// ];

const ProgrammePermissionModel: React.FC<any> = ({ hideUpdate = true, clientId, programmeId, userMappingData, corporateProgrammes, onClose, selectedUser }): JSX.Element => {
	const [treeData, setTreeData] = useState();
	const [checkArr, setCheckArr] = useState<any>([]);
	const [checkedKeys, setCheckedKeys] = useState<any>([]);

	const updateAccess = useStoreActions<any>((actions) => actions.user.updateAccess);

	useEffect(() => {
		if (corporateProgrammes.length) {
			// 	const tmpArr: any = [...initTreeData,
			// 		 {
			// 		title: <p className="font-bold">{corporateProgrammes[0]?.clientId.companyName}</p>,
			// 		key: 'CA',
			// 		children: []
			// 	}

			// ];
			const propArr: any = [];

			_.each(corporateProgrammes, (a) => {
				// const { programmeName, _id } = a;
				propArr.push({
					// title: <TreeNode hide text={programmeName} code={_id} />,
					// key: `${_id}`,
					// children: [
					// {
					title: <TreeNode hide text={UserAccess.programme_access.superAdmin.text} code={`${UserAccess.programme_access.superAdmin.code}`} />,
					key: `${UserAccess.programme_access.superAdmin.code}`,
					children: [
						{
							title: <TreeNode text={UserAccess.programme_access.programmeAdmin.text} code={`${UserAccess.programme_access.programmeAdmin.code}`} />,
							key: `${UserAccess.programme_access.programmeAdmin.code}`
						},
						{
							title: <TreeNode text={UserAccess.programme_access.programmeUsers.text} code={`${UserAccess.programme_access.programmeUsers.code}`} />,
							key: `${UserAccess.programme_access.programmeUsers.code}`
						},
						{
							title: <TreeNode text={UserAccess.programme_access.reporting.text} code={`${UserAccess.programme_access.reporting.code}`} />,
							key: `${UserAccess.programme_access.reporting.code}`
						},
						{
							title: <TreeNode text={UserAccess.programme_access.companyBooker.text} code={`${UserAccess.programme_access.companyBooker.code}`} />,
							key: `${UserAccess.programme_access.companyBooker.code}`
						},
						{
							title: <TreeNode text={UserAccess.programme_access.traveller.text} code={`${UserAccess.programme_access.traveller.code}`} />,
							key: `${UserAccess.programme_access.traveller.code}`
						},
					]
					// },

					// ]
				}
				)
			});
			// _.each(propArr, (a) => {
			// 	tmpArr[0].children.push(a)
			// })



			setTreeData(propArr);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	///////////////////////////////////////////////////////////////////////////////////////////
	const close = useCallback(() => {
		const tmpArr: any = [];
		tmpArr[0]?.children.pop();
	}, []);

	///////////////////////////////////////////////////////////////////////////////////////////
	const onUpdateUserRole = useCallback((data) => {
		if (data) {
			updateAccess({
				id: selectedUser.arrId,
				userId: selectedUser.userId,
				role: _.includes(data, 'PG_CORPORATE_SUPER_ADMIN') ? UserGroups.programme_admin : UserGroups.user,
				accessType: "programmeRole"
			});
		}


	}, [selectedUser, updateAccess]);

	///////////////////////////////////////////////////////////////////////////////////////////////////
	const onUpdate = useCallback(() => {


		updateAccess({
			id: selectedUser.arrId,
			userId: selectedUser.userId,
			accessType: "programme",
			access: getCheckedStatus(checkArr)
		});
		setTimeout(() => {
			onUpdateUserRole(checkArr);
			onClose();
			close();
		}, 1000);

	}, [checkArr, close, onClose, onUpdateUserRole, selectedUser.arrId, selectedUser.userId, updateAccess])

	////////////////////////////////////////////////////////////////////////////////////////////////
	useLayoutEffect(() => {
		if (userMappingData) {
			const arr: any = [];
			const userMapping = _.filter(userMappingData, a => a.userId === selectedUser.userId);

			const programme = _.filter(userMapping[0].clients, (a) => a.clientId === clientId).shift()?.corporateProgramme || [];
			const access = _.filter(programme, (a) => a.programmeId === programmeId).shift()?.access || [];
			_.each(access, a => {
				setCheckedValues(a);
				arr.push(a.module);
			});
			setCheckedKeys(arr);
			setCheckArr(arr);
		}
	}, [clientId, programmeId, selectedUser, userMappingData])

	///////////////////////////////////////////////////////////////////////////////////////////////////////
	const onExpand = useCallback(() => {
		setTimeout(() => {
			if (userMappingData) {
				const userMapping = _.filter(userMappingData, a => a.userId === selectedUser.userId);
				const programme = _.filter(userMapping[0].clients, (a) => a.clientId === clientId).shift()?.corporateProgramme || [];
				// get only particular programme Id wise
				const filterAccess = _.filter(programme, (a) => a.programmeId === programmeId).shift()?.access || [];

				const filterModule = filterAccess.filter(val => val.module.startsWith('PG'));
				_.each(filterModule, a => {
					setCheckedValues(a)
				}
				);
			}
		}, 1000)

	}, [clientId, programmeId, selectedUser, userMappingData]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	const onCheckAccess = useCallback((data) => {
		setCheckArr(data);
		setCheckedKeys(data);

	}, [])

	///////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div>
			<Modal
				show={true}
				onHide={() => { onClose(); close() }}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				title="User Permissions"
				style={{ top: 20 }}
				visible={true}
			>

				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						User Permissions
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p className="font-bold mb-2">{corporateProgrammes[0]?.clientId?.companyName}</p>
					<p className="font-bold mb-2 ml-3">{corporateProgrammes[0]?.programmeName}</p>
					<Tree
						checkable
						defaultExpandAll
						checkedKeys={checkedKeys}
						onExpand={onExpand}
						onCheck={(data, opt) => { onCheckAccess(data) }}
						treeData={treeData}
					/>
				</Modal.Body>
				{
					hideUpdate && <Modal.Footer>
						<Button type="submit" id='approve-button' style={formButton} onClick={() => onUpdate()}>Update</Button>
						<Button id='edit-button' style={formButton} onClick={() => { onClose(); close() }}>Close</Button>
					</Modal.Footer>
				}

			</Modal>
		</div>
	)
}

export default ProgrammePermissionModel
