/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import { call } from '../common/axios';


export default {
  getClientListSuccess: null,
  getClientListError: null,
  getClientDataSuccess: null,
  getClientDataError: null,
  createClientSuccess: null,
  createClientError: null,
  updateClientSuccess: null,
  updateClientError: null,
  getClientLocationDetailsSuccess: null,
  getClientLocationDetailsError: null,
  createClientLocationSuccess: null,
  createClientLocationError: null,
  updateClientLocationSuccess: null,
  updateClientLocationError: null,
  getClientLocationByIdSuccess: null,
  getClientLocationByIdError: null,
  removeClientLocationSuccess: null,
  getPermissionSuccess: null,
  getPermissionError: null,
  savePermissionSuccess: null,
  savePermissionError: null,
  reset: false,
  createCorporateProgrammeSuccess: null,
  createCorporateProgrammeError: null,
  updateCorporateProgrammeSuccess: null,
  updateCorporateProgrammeError: null,

  activateCorporateProgrammeSuccess: null,
  activateCorporateProgrammeError: null,
  getCorporateProgrammeSuccess: null,
  getCorporateProgrammeError: null,
  getCorporateProgrammeByClientSuccess: null,
  getCorporateProgrammeByClientError: null,
  getProgrammeTypeSuccess: null,
  getProgrammeTypeError: null,
  getCorporateProgrammeByIdSuccess: null,
  getCorporateProgrammeByIdError: null,
  getClientLocationByProgrammeIdSuccess: null,
  getClientLocationByProgrammeIdError: null,
  createCompanyPermissionSuccess: null,
  createCompanyPermissionError: null,
  updateCompanyPermissionSuccess: null,
  updateCompanyPermissionError: null,
  getCompanyPermissionByProgrammeIdSuccess: null,
  getCompanyPermissionByProgrammeIdError: null,

  createPaymentPermissionSuccess: null,
  createPaymentPermissionError: null,
  updatePaymentPermissionSuccess: null,
  updatePaymentPermissionError: null,
  getPaymentPermissionByProgrammeIdSuccess: null,
  getPaymentPermissionByProgrammeIdError: null,

  getClientDataByStatusSuccess: null,
  getClientDataByStatusError: null,

  /////////////////////////////////////// corporate property management ///////////////////////
  programmesByClientIdSuccess: null,
  programmesByClientIdError: null,

  programmeApprovalSuccess: null,
  programmeApprovalError: null,

  getClientRfpOnlySuccess: null,
  sendProgrammeAdminWelcomeEmailSuccess: null,
  sendProgrammeAdminWelcomeEmailError: null,
  sendTravelerAdminWelcomeEmailSuccess: null,
  sendTravelerAdminWelcomeEmailError: null,

  sendBookerAdminWelcomeEmailSuccess: null,
  sendBookerAdminWelcomeEmailError: null,

  getBookerInformationSuccess: null,
  getBookerInformationError: null,

  createBookerInformationSuccess: null,
  createBookerInformationError: null,

  getTravelerInformationSuccess: null,
  getTravelerInformationError: null,

  createTravelerInformationSuccess: null,
  createTravelerInformationError: null,

  // actions
  setProgrammeApprovalSuccess: action((state, payload) => ({ programmeApprovalSuccess: payload })),
  setClientRfpOnlySuccess: action((state, payload) => ({ getClientRfpOnlySuccess: payload })),
  setProgrammeApprovalError: action((state, payload) => ({ programmeApprovalError: payload })),
  setGetClientList: action((state, payload) => ({ getClientListSuccess: payload })),
  setGetClientListError: action((state, payload) => ({ getClientListError: payload })),
  setGetClientData: action((state, payload) => ({ getClientDataSuccess: payload })),
  setGetClientDataError: action((state, payload) => ({ getClientDataError: payload })),
  setCreateClient: action((state, payload) => ({ createClientSuccess: payload })),
  setCreateClientError: action((state, payload) => ({ createClientError: payload })),
  setUpdateClient: action((state, payload) => ({ updateClientSuccess: payload })),
  setUpdateClientError: action((state, payload) => ({ updateClientError: payload })),
  setClientLocationDetails: action((state, payload) => ({ getClientLocationDetailsSuccess: payload })),
  setClientLocationDetailsError: action((state, payload) => ({ getClientLocationDetailsError: payload })),
  setCreateClientLocation: action((state, payload) => ({ createClientLocationSuccess: payload })),
  setCreateClientLocationError: action((state, payload) => ({ createClientLocationError: payload })),
  setUpdateClientLocation: action((state, payload) => ({ updateClientLocationSuccess: payload })),
  setUpdateClientLocationError: action((state, payload) => ({ updateClientLocationError: payload })),
  setGetClientLocationById: action((state, payload) => ({ getClientLocationByIdSuccess: payload })),
  setGetClientLocationByIdError: action((state, payload) => ({ getClientLocationByIdError: payload })),
  setRemoveClientLocation: action((state, payload) => ({ removeClientLocationSuccess: payload })),
  resetClient: action((state, payload) => ({ reset: true })),
  setSavePermissionSuccess: action((state, payload) => ({ savePermissionSuccess: payload })),
  setSavePermissionError: action((state, payload) => ({ savePermissionError: payload })),
  setGetPermissionSuccess: action((state, payload) => ({ getPermissionSuccess: payload })),
  setGetPermissionError: action((state, payload) => ({ getPermissionError: payload })),
  setCreateCorporateProgramme: action((state, payload) => ({ createCorporateProgrammeSuccess: payload })),
  setCreateCorporateProgrammeError: action((state, payload) => ({ createCorporateProgrammeError: payload })),
  setUpdateCorporateProgramme: action((state, payload) => ({ updateCorporateProgrammeSuccess: payload })),
  setUpdateCorporateProgrammeError: action((state, payload) => ({ updateCorporateProgrammeError: payload })),
  setActivateCorporateProgramme: action((state, payload) => ({ activateCorporateProgrammeSuccess: payload })),
  setActivateCorporateProgrammeError: action((state, payload) => ({ activateCorporateProgrammeError: payload })),
  setGetCorporateProgrammeSuccess: action((state, payload) => ({ getCorporateProgrammeSuccess: payload })),
  setGetCorporateProgrammeError: action((state, payload) => ({ getCorporateProgrammeError: payload })),
  setGetCorporateProgrammeByClientSuccess: action((state, payload) => ({ getCorporateProgrammeByClientSuccess: payload })),
  setGetCorporateProgrammeByClientError: action((state, payload) => ({ getCorporateProgrammeByClientError: payload })),
  setGetProgrammeTypeSuccess: action((state, payload) => ({ getProgrammeTypeSuccess: payload })),
  setProgrammeTypeError: action((state, payload) => ({ getProgrammeTypeError: payload })),
  setGetCorporateProgrammeByIdSuccess: action((state, payload) => ({ getCorporateProgrammeByIdSuccess: payload })),
  setGetCorporateProgrammeByIdError: action((state, payload) => ({ getCorporateProgrammeByIdError: payload })),
  setGetClientLocationByProgrammeId: action((state, payload) => ({ getClientLocationByProgrammeIdSuccess: payload })),
  seGetClientLocationByProgrammeIdError: action((state, payload) => ({ getClientLocationByProgrammeIdError: payload })),


  setGetClientDataByStatus: action((state, payload) => ({ getClientDataByStatusSuccess: payload })),
  setGetClientDataByStatusError: action((state, payload) => ({ getClientDataByStatusError: payload })),

  setCreateCompanyPermission: action((state, payload) => ({ createCompanyPermissionSuccess: payload })),
  setCreateCompanyPermissionError: action((state, payload) => ({ createCompanyPermissionError: payload })),
  setUpdateCompanyPermission: action((state, payload) => ({ updateCompanyPermissionSuccess: payload })),
  setUpdateCompanyPermissionError: action((state, payload) => ({ updateCompanyPermissionError: payload })),
  setGetCompanyPermissionByProgrammeIdSuccess: action((state, payload) => ({ getCompanyPermissionByProgrammeIdSuccess: payload })),
  setGetCompanyPermissionByProgrammeIdError: action((state, payload) => ({ getCompanyPermissionByProgrammeIdError: payload })),


  setCreatePaymentPermission: action((state, payload) => ({ createPaymentPermissionSuccess: payload })),
  setCreatePaymentPermissionError: action((state, payload) => ({ createPaymentPermissionError: payload })),
  setUpdatePaymentPermission: action((state, payload) => ({ updatePaymentPermissionSuccess: payload })),
  setUpdatePaymentPermissionError: action((state, payload) => ({ updatePaymentPermissionError: payload })),
  setGetPaymentPermissionByProgrammeIdSuccess: action((state, payload) => ({ getPaymentPermissionByProgrammeIdSuccess: payload })),
  setGetPaymentPermissionByProgrammeIdError: action((state, payload) => ({ getPaymentPermissionByProgrammeIdError: payload })),

  ///////////////////////////////////////// corporate property management ///////////////////////
  getProgrammesByClientIdSuccess: action((state, payload) => ({ programmesByClientIdSuccess: payload })),
  getProgrammesByClientIdError: action((state, payload) => ({ programmesByClientIdError: payload })),

  setSendProgrammeAdminWelcomeEmailSuccess: action((state, payload) => ({ sendProgrammeAdminWelcomeEmailSuccess: payload })),
  setSendProgrammeAdminWelcomeEmailError: action((state, payload) => ({ sendProgrammeAdminWelcomeEmailError: payload })),

  setSendTravelerAdminWelcomeEmailSuccess: action((state, payload) => ({ sendTravelerAdminWelcomeEmailSuccess: payload })),
  setSendTravelerAdminWelcomeEmailError: action((state, payload) => ({ sendTravelerAdminWelcomeEmailError: payload })),

  setSendBookerAdminWelcomeEmailSuccess: action((state, payload) => ({ sendBookerAdminWelcomeEmailSuccess: payload })),
  setSendBookerAdminWelcomeEmailError: action((state, payload) => ({ sendBookerAdminWelcomeEmailError: payload })),


  setCreateBookerInformationSuccess: action((state, payload) => ({ createBookerInformationSuccess: payload })),
  setCreateBookerInformationError: action((state, payload) => ({ createBookerInformationError: payload })),

  setGetBookerInformationSuccess: action((state, payload) => ({ getBookerInformationSuccess: payload })),
  setGetBookerInformationError: action((state, payload) => ({ getBookerInformationError: payload })),

  setCreateTravelerInformationSuccess: action((state, payload) => ({ createTravelerInformationSuccess: payload })),
  setCreateTravelerInformationError: action((state, payload) => ({ createTravelerInformationError: payload })),

  setGetTravelerInformationSuccess: action((state, payload) => ({ getTravelerInformationSuccess: payload })),
  setGetTravelerInformationError: action((state, payload) => ({ getTravelerInformationError: payload })),

  getTravelerInformation: thunk(async (actions: any, clientId: any) => {
    try {
      const result = await call('get', `/client-sv/client/travelerInformation/${clientId}`, null, null);
      actions.setGetTravelerInformationSuccess(result.data);
    } catch (error: any) {
      actions.setGetTravelerInformationError(error.response.data);
    }
  }),


  createTravelerInformation: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', `/client-sv/client/createTravelerInformation`, payload, null);
      actions.setCreateTravelerInformationSuccess(result.data);
    } catch (error: any) {
      actions.setCreateTravelerInformationError(error.response.data);
    }
  }),
 
  getBookerInformation: thunk(async (actions: any, clientId: any) => {
    try {
      const result = await call('get', `/client-sv/client/bookerInformation/${clientId}`, null, null);
      actions.setGetBookerInformationSuccess(result.data);
    } catch (error: any) {
      actions.setGetBookerInformationError(error.response.data);
    }
  }),


  createBookerInformation: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', `/client-sv/client/createBookerInformation`, payload, null);
      actions.setCreateBookerInformationSuccess(result.data);
    } catch (error: any) {
      actions.setCreateBookerInformationError(error.response.data);
    }
  }),

  // send  email
  sendProgrammeAdminWelcomeEmail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', `/client-sv/client/sendProgrammeAdminWelcomeEmail`, payload, null);
      actions.setSendProgrammeAdminWelcomeEmailSuccess(result.data);
    } catch (error: any) {
      actions.setSendProgrammeAdminWelcomeEmailError(error.response.data);
    }
  }),



  // send email
  sendTravelerAdminWelcomeEmail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', `/client-sv/client/sendTravelerAdminWelcomeEmail`, payload, null);
      actions.setSendTravelerAdminWelcomeEmailSuccess(result.data);
    } catch (error: any) {
      actions.setSendTravelerAdminWelcomeEmailError(error.response.data);
    }
  }),

    // send email
    sendBookerAdminWelcomeEmail: thunk(async (actions: any, payload: any) => {
      try {
        const result = await call('post', `/client-sv/client/sendBookerAdminWelcomeEmail`, payload, null);
        actions.setSendBookerAdminWelcomeEmailSuccess(result.data);
      } catch (error: any) {
        actions.setSendBookerAdminWelcomeEmailError(error.response.data);
      }
    }),


  // create programme
  createCorporateProgramme: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/client-sv/client/corporateProgramme', payload, null);
      actions.setCreateCorporateProgramme(result.data);
    } catch (error: any) {
      actions.setCreateCorporateProgrammeError(error.response.data);
    }
  }),

  clientRfpOnly: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', '/client-sv/rfp-client-only', null, null);
      actions.setClientRfpOnlySuccess(result.data);
    } catch (error: any) {

    }
  }),

  // update programme
  updateCorporateProgramme: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/client-sv/client/corporateProgramme', payload, null);
      actions.setUpdateCorporateProgramme(result.data);
    } catch (error: any) {
      actions.setUpdateCorporateProgrammeError(error.response.data);
    }
  }),


  activateCorporateProgramme: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/client-sv/client/corporateProgramme', payload, null);
      actions.setActivateCorporateProgramme(result.data);
    } catch (error: any) {
      actions.setActivateCorporateProgrammeError(error.response.data);
    }
  }),

  // get programme
  getCorporateProgramme: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', '/client-sv/client/corporateProgramme', payload, null);
      actions.setGetCorporateProgrammeSuccess(result.data);
    } catch (error: any) {
      actions.setGetCorporateProgrammeError(error.response.data);
    }
  }),

  //   getProgrammeType
  getProgrammeType: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', '/client-sv/client/programmeType', payload, null);
      actions.setGetProgrammeTypeSuccess(result.data);
    } catch (error: any) {
      actions.setProgrammeTypeError(error.response.data);
    }
  }),


  //get CorporateProgramme ByClient
  getCorporateProgrammeByClient: thunk(async (actions: any, params: any) => {
    try {
      const result = await call('get', '/client-sv/client/corporateProgramme/byClient', null, params);
      actions.setGetCorporateProgrammeByClientSuccess(result.data);
    } catch (error: any) {
      actions.setGetCorporateProgrammeByClientError(error.response.data);
    }
  }),
  // get client list
  getCorporateProgrammeById: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/client-sv/client/corporateProgramme/${id}`, null, null);
      actions.setGetCorporateProgrammeByIdSuccess(result.data);
    } catch (error: any) {
      actions.setGetCorporateProgrammeByIdError(error.response.data);
    }
  }),

  // get client list
  getClientList: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/client-sv/client`, null, null);
      actions.setGetClientList(result.data);
    } catch (error: any) {
      actions.setGetClientListError(error.response.data);
    }
  }),

  // get client list
  getClientData: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/client-sv/client/${id}`, null, null);
      actions.setGetClientData(result.data);
    } catch (error: any) {
      actions.setGetClientDataError(error.response.data);
    }
  }),

  // create client
  createClient: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/client-sv/client', payload, null);
      actions.setCreateClient(result.data);
    } catch (error: any) {
      actions.setCreateClientError(error.response.data);
    }
  }),

  // update client
  updateClient: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/client-sv/client', payload, null);
      actions.setUpdateClient(result.data);
    } catch (error: any) {
      actions.setUpdateClientError(error.response.data);
    }
  }),

  //get client location details
  getClientLocationDetails: thunk(async (actions: any, clientId: any) => {
    try {
      const result = await call('get', `/client-sv/client/location/byClient/${clientId}`, null, null);
      actions.setClientLocationDetails(result.data);
    } catch (error: any) {
      actions.setClientLocationDetailsError(error.response.data);
    }
  }),


  // create client location
  createClientLocation: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/client-sv/client/location', payload, null);
      actions.setCreateClientLocation(result.data);
    } catch (error: any) {
      actions.setCreateClientLocationError(error.response.data);
    }
  }),

  // update client location
  updateClientLocation: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/client-sv/client/location', payload, null);
      if (payload.isDeleted) {
        actions.setRemoveClientLocation(result.data);
      }
      actions.setUpdateClientLocation(result.data);
    } catch (error: any) {
      actions.setUpdateClientLocationError(error.response.data);
    }
  }),

  //get client location details
  getClientLocationById: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/client-sv/client/location/${id}`, null, null);
      actions.setGetClientLocationById(result.data);
    } catch (error: any) {
      actions.setGetClientLocationByIdError(error.response.data);
    }
  }),

  getClientLocationByProgrammeId: thunk(async (actions: any, programmeId: any) => {
    try {
      const result = await call('get', `/client-sv/client/location/byProgramme/${programmeId}`, null, null);
      actions.setGetClientLocationByProgrammeId(result.data);
    } catch (error: any) {
      actions.setGetClientLocationByProgrammeIdError(error.response.data);
    }
  }),

  // save permission
  savePermission: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/client-sv/client/permission', payload, null);
      actions.setSavePermissionSuccess(result.data);
    } catch (error: any) {
      actions.setSavePermissionError(error.response.data);
    }
  }),

  // get permission by email
  getPermission: thunk(async (actions: any, email: any) => {
    try {
      const result = await call('get', `/client-sv/client/permission/byEmail/${email}`, null, null);
      actions.setGetPermissionSuccess(result.data);
    } catch (error: any) {
      actions.setGetPermissionError(error.response.data);
    }
  }),


  ///////////////////////////////////////// corporate property management ///////////////////////
  programmesByClientId: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/client-sv/client/corporateProgramme/byClient?clientId=${payload.clientId}&status=${payload.status}`, null, null);
      actions.getProgrammesByClientIdSuccess(result.data);
    } catch (error: any) {
      actions.getProgrammesByClientIdError(error.response.data);
    }
  }
  ),

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getCompanyPermissionByProgrammeId: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/client-sv/client/companyPermission/byProgramme/${payload.clientId}/${payload.programmeId}`, null, null);
      actions.setGetCompanyPermissionByProgrammeIdSuccess(result.data);
    } catch (error: any) {
      actions.setGetCompanyPermissionByProgrammeIdError(error.response.data);
    }
  }),

  // create CompanyPermission
  createCompanyPermission: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/client-sv/client/companyPermission', payload, null);
      actions.setCreateCompanyPermission(result.data);
    } catch (error: any) {
      actions.setCreateCompanyPermissionError(error.response.data);
    }
  }),

  // update CompanyPermission
  updateCompanyPermission: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/client-sv/client/companyPermission', payload, null);
      actions.setUpdateCompanyPermission(result.data);
    } catch (error: any) {
      actions.setUpdateCompanyPermissionError(error.response.data);
    }
  }),


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getPaymentPermissionByProgrammeId: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/client-sv/client/paymentPermission/byProgramme/${payload.clientId}/${payload.programmeId}`, null, null);
      actions.setGetPaymentPermissionByProgrammeIdSuccess(result.data);
    } catch (error: any) {
      actions.setGetPaymentPermissionByProgrammeIdError(error.response.data);
    }
  }),

  // create Payment Permission
  createPaymentPermission: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/client-sv/client/paymentPermission', payload, null);
      actions.setCreatePaymentPermission(result.data);
    } catch (error: any) {
      actions.setCreatePaymentPermissionError(error.response.data);
    }
  }),

  // update Payment Permission
  updatePaymentPermission: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/client-sv/client/paymentPermission', payload, null);
      actions.setUpdatePaymentPermission(result.data);
    } catch (error: any) {
      actions.setUpdatePaymentPermissionError(error.response.data);
    }
  }),


  // approval 
  programmeApproval: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/client-sv/client/corporateProgramme/approved', payload, null);
      actions.setProgrammeApprovalSuccess(result.data);
    } catch (error: any) {
      actions.setProgrammeApprovalError(error.response.data);
    }
  }),


  getClientDataByStatus: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/client-sv/client/status?status=${payload?.status}`, null, null);
      actions.setGetClientDataByStatus(result.data);
    } catch (error: any) {
      actions.setGetClientDataByStatusError(error.response.data);
    }
  }),

}