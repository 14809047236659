

import React, { useCallback, useEffect, useState } from 'react';
import { DirectionsRenderer, GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs } from "react-google-maps";



const BookPropertyMap: React.FC<any> = ({ location, building, travelMode, onResponse }): JSX.Element => {



  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [buildingLat, setBuildingLat] = useState<number>(0);
  const [buildingLng, setBuildingLng] = useState<number>(0);
  const [directions, setDirections] = useState<any>({});
  const [activeMarker, setActiveMarker] = useState(null);
  const DirectionsService = new google.maps.DirectionsService();

  const destination = { lat: buildingLat, lng: buildingLng };

  const origin = { lat: lat, lng: lng };
  DirectionsService.route(
    {
      origin: origin,
      destination: destination,
      travelMode: travelMode
    },
    (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        setDirections(result);
        onResponse(result)
      }
    }
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {

    if (location) {
      setLat(location?.lat);
      setLng(location?.lng);
      setBuildingLat(building?.lat);
      setBuildingLng(building?.lng);
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }

  }, [building?.lat, building?.lng, location]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onDragEnd = useCallback(({ latLng }) => {
    setLat(latLng.lat());
    setLng(latLng.lng());
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="google-map">
      <GoogleMap
        key={1000000000}
        defaultZoom={12}
        defaultCenter={{ lat, lng }}
      >
        <Marker draggable onDragEnd={onDragEnd} position={{ lat, lng }}
        //  icon={icon} 
        />
        <Marker draggable onDragEnd={onDragEnd}
          position={{ lat: buildingLat, lng: buildingLng }}
          onClick={() => handleActiveMarker(building?.propertyId)}
        // icon={buildingIcon}
        >
          {activeMarker === building?.propertyId ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                <p>{building?.buildingName}</p>
                <p>{building?.address}</p>
              </div>

            </InfoWindow>
          ) : null}
        </Marker>
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              strokeOpacity: 1,
              strokeColor: '#ffffff',
            },
          }}
        />
      </GoogleMap>
    </div>
  )
};

export default withScriptjs(withGoogleMap(BookPropertyMap));


