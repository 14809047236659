import { CheckOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { getLoggedUserType } from '../../../../../../common/functions';

const QAddress: React.FC<any> = ({ data, onAnswer, signUpStatus, completed }): JSX.Element => {
	const [answer, setAnswer] = useState<string>('');
	const [userRole, setUserRole] = useState<any>();
	const [loading, setLoading] = useState(false);
	const [disable, setDisable] = useState(true);
	const [dataObj, setDataObj] = useState({
		address: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		country: '',
	});



	useEffect(() => {
		// console.log(getLoggedUserType(), 'getLoggedUserType111111111');
		if (getLoggedUserType() !== null) {
			setUserRole(getLoggedUserType());
		}

		setAnswer(data.answer?.answer)

		if (data.answer?.answer) {
			const split = data.answer?.answer.split('\n');
			setDataObj({
				address: split[0],
				address2: split[1],
				city: split[2],
				state: split[3],
				zip: split[4],
				country: split[5],
			})

			if (split[0] && split[2] && split[4] && split[5]) {
				setDisable(false);
			} else {
				setDisable(true);
			}

		}

	}, [data, setDataObj]);

	const answerQuestion = useCallback(() => {
		const disabled = (userRole === 'super_admin') ? false : signUpStatus === 'accepted' ? true : completed ? true : disable

		if (disabled) return false;

		const answer = `${dataObj.address}\n${dataObj.address2}\n${dataObj.city}\n${dataObj.state}\n${dataObj.zip}\n${dataObj.country}`
		onAnswer(answer, data.id);

		setLoading(true);
		setTimeout(() => setLoading(false), 3000);
	}, [userRole, signUpStatus, completed, disable, dataObj, onAnswer, data]);

	const onChange = useCallback((e) => {
		const name = e.target.name
		const value = e.target.value
		const dataTemp = { ...dataObj };
		dataTemp[name] = value;

		if (dataTemp.address && dataTemp.city && dataTemp.zip && dataTemp.country) {
			setDisable(false);
		} else {
			setDisable(true);
		}

		setDataObj(dataTemp);
	}, [dataObj])




	return (
		<div className="inline-block  m-5 w-full pr-10">
			<div className="bg-white p-5 rounded-lg">
				<p className="text-lg mb-3">{data?.description}</p>
				<Input className="mb-3" name="address" value={dataObj?.address} onChange={onChange} placeholder="Address #1" readOnly={(userRole === 'super_admin') ? false : completed ? true : false} />
				<Input className="mb-3" name="address2" value={dataObj?.address2} onChange={onChange} placeholder="Address #2 (Optional)" readOnly={(userRole === 'super_admin') ? false : completed ? true : false} />
				<Input className="mb-3" name="city" value={dataObj?.city} onChange={onChange} placeholder="City" readOnly={(userRole === 'super_admin') ? false : completed ? true : false} />
				<Input className="mb-3" name="state" value={dataObj?.state} onChange={onChange} placeholder="State (Optional)" readOnly={(userRole === 'super_admin') ? false : completed ? true : false} />
				<Input className="mb-3" name="zip" value={dataObj?.zip} onChange={onChange} placeholder="Zip/Postal code" readOnly={(userRole === 'super_admin') ? false : completed ? true : false} />
				<Input className="mb-3" name="country" value={dataObj?.country} onChange={onChange} placeholder="Country" readOnly={(userRole === 'super_admin') ? false : completed ? true : false} />
				<Button type="primary" ghost={answer ? false : true} block onClick={answerQuestion} icon={<CheckOutlined />} className="rounded-xl mt-3" loading={loading}>
					{data.answer?.answer ? 'Update' : 'Submit'}
				</Button>
			</div>
		</div>
	)
};

export default QAddress;