import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';

const ConsumableView: React.FC<any> = ({ propertyId }): JSX.Element => {
    const [consumableData, setConsumableData] = useState<any>([]);

    /////////////////////////// global state manage (actions)//////////////////////////////////////////////////////
    const { getPropertySustainabilityByPropertyId } = useStoreActions<any>((actions) => ({
        getPropertySustainabilityByPropertyId: actions.property.getPropertySustainabilityByPropertyId,
    }));

    /////////////////////////// global state manage (state)//////////////////////////////////////////////////////
    const {
        getPropertySustainabilityByPropertyIdSuccess,
    } = useStoreState<any>((state) => ({
        getPropertySustainabilityByPropertyIdSuccess: state.property.getPropertySustainabilityByPropertyIdSuccess,
    }));


    //////////////////////////////// call apis using actions //////////////////////////////////////////////////////
    useEffect(() => {
        if (propertyId) {
            getPropertySustainabilityByPropertyId(propertyId);
        }

    }, [getPropertySustainabilityByPropertyId, propertyId]);


    //////////////////////////////// get api response using state //////////////////////////////////////////////////////
    useEffect(() => {


        if (getPropertySustainabilityByPropertyIdSuccess) {
            if (getPropertySustainabilityByPropertyIdSuccess.data) {
                const data = getPropertySustainabilityByPropertyIdSuccess?.data;
                const energyListTemp: any = [
                    { label: 'Consumable products in alternative packaging to plastic', value: data?.doYouDisplayRemindersBeforeTheyLeave, number: "odd" },
                    { label: 'Sustainable tea and coffee options', value: data?.doYouProvideDigitalMeansForGuest, number: "even" },
                    { label: 'Natural and organic free-trade soaps and shampoos from certified green manufacturers in biodegradable packaging', value: data?.doYouHaveMotionSensing, number: "odd" },
                    { label: 'Natural fibre sheets and furniture', value: data?.pleaseProvideDetailsForMotion, number: "even" },
                    { label: 'Keyless system with plastic cards?', value: data?.isYourEnergySuppliedFromSources, number: "odd" },
                  
                ];
                setConsumableData(energyListTemp);

            }
        }



    }, [getPropertySustainabilityByPropertyId, getPropertySustainabilityByPropertyIdSuccess]);

    return (
        <>
            {/* <ul className="">
                <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}><p >Consumable products in alternative packaging to plastic </p>
                    <span>Yes</span></li>
                <li className="list-group-item d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Sustainable tea and coffee options
                    <span>Yes</span>
                </li>
                <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Natural and organic free-trade soaps and shampoos from certified green manufacturers in biodegradable packaging
                    <span>Yes</span></li>
                <li className="list-group-item d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Natural fibre sheets and furniture
                    <span>Yes</span></li>
                <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Keyless system with plastic cards?
                    <span>Yes</span></li>
            </ul> */}

            <ul className=''>
                {
                    consumableData.map((data, index) => {
                        return <li className={data?.number === 'odd' ? "list-group-item list-group-item-dark d-flex justify-content-between align-items-center" : "list-group-item d-flex justify-content-between align-items-center"} style={{ height: "50px", padding: "1rem" }}>{data?.label}
                            <span>{data?.value}</span></li>
                    })}
            </ul>
        </>


    );

}

export default ConsumableView;