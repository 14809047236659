import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useStoreState } from "easy-peasy";
import _ from "lodash";
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import { Button} from 'react-bootstrap';
import {
  tableStyles,
} from "../../../common/components-style";
import moment from "moment";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";

const RequestBookingSuperAdminList: React.FC<any> = (): JSX.Element => {
  const history = useHistory();
  const [requestBookingData, setRequestBookingData] = useState<any>([]);
  const [visibleData, setVisibleData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(7);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (requestBookingData && requestBookingData?.length > 0) {
    setVisibleData(requestBookingData.slice(0,7)); 
    }
    else
    {
      setVisibleData([]);
    }
  }, [requestBookingData]);


const loadMoreData = () => {
  if (requestBookingData && requestBookingData?.length > 0) {
  const newIndex = currentIndex + 7;
  setVisibleData(requestBookingData.slice(0, newIndex));
  setCurrentIndex(newIndex);
  }
};

const RequestBookingHandler = () => {
  if (requestBookingData && requestBookingData?.length > 0) {
  if (isExpanded) {
    setVisibleData(requestBookingData.slice(0, 7));
    setCurrentIndex(7);
  } else {
    setVisibleData(requestBookingData);
    setCurrentIndex(requestBookingData.length);
  }
  setIsExpanded(!isExpanded);
}
};

  const { superAdminDashboardSuccessBookingManagement, superAdminDashboardErrorBookingManagement} = useStoreState<any>(
    (state) => {
      return {
        superAdminDashboardSuccessBookingManagement: state.DashboardModel.superAdminDashboardSuccessBookingManagement,
        superAdminDashboardErrorBookingManagement: state.DashboardModel.superAdminDashboardErrorBookingManagement,
      }
    }
  );
  useEffect(() => {
    if (superAdminDashboardErrorBookingManagement) {
      toast.error(superAdminDashboardErrorBookingManagement.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    if (superAdminDashboardSuccessBookingManagement) {
      setRequestBookingData(superAdminDashboardSuccessBookingManagement?.data?.getRequestBookingCount?.pendingDetails);
    }
  }, [superAdminDashboardErrorBookingManagement, superAdminDashboardSuccessBookingManagement]);

  const handleOpen = useCallback(
    (data) => {
      setTimeout(() => {
        history.push({
          pathname: `admin/super-admin-booking-reports`,
          state: { 
            tabKey: 'requestBooking',
            _id: data?._id,
            _reqGroupId: data?._reqGroupId,
            _reqBrandId: data?._reqBrandId,
            _reqPropertyId: data?.property?._reqPropertyId,
            _reqInventoryId: data?.inventory?._reqInventoryId,
          }
        });
      }, 500);
    },
    [history]
  );

  const columns = [
    {
      name: "Client",
      cell: (row) => (
        row.clientName
      ),
    },
    {
      name: "Programme",
      cell: (row) => row.programmeName
    },
    {
        name: 'Brand',
        selector: (row) => row.brandName,
    },
    {
      name: "Property",
      cell: (row) => row.property?.propertyName
    },
    {
        name: "Location",
        cell: (row) =>  row.location?.locationName
      },
      {
        name: "Date Rec'd",
        selector: row => moment(row.requestedDate).format('D MMM YYYY'),
      },
      {
        name: "Time",
        cell: (row) => {
          const requestDate = moment.utc(row.requestedDate);
          const now = moment.utc();
          const duration = moment.duration(now.diff(requestDate));
          const hours = duration.asHours();
          const minutes = duration.minutes();
         
          let timeStyle = {};
          if (hours >= 2) {
            timeStyle = { color: 'red', fontWeight: '700'  };
          } else if (hours >= 2 && minutes >= 30) {
            timeStyle = { color: ' #E80F8B' , fontWeight: '700'  };
          }
          return (
            <>
              <span style={timeStyle}>
                {requestDate.format('HH:mm')}
              </span>
            </>
          );
        }
      },
    {
      name: 'Action',
      button: false,
      cell: (row: any) => {
              return (
              <>
            <img
              className="iconSizes mr-2"
              src={ViewEdit}
              alt=""
              onClick={() => {
                handleOpen(row);
              }}
            />
              </>)
      },
  },
  ];

  const callCustomClass = (row) => {
    const requestDate = moment.utc(row?.requestedDate);
    const now = moment.utc();
    const duration = moment.duration(now.diff(requestDate));
    const hours = duration?.asHours();
    if (hours >= 2) {
      return { color: 'red' };
    }else{
      return {};
    }
  }
  
    return (
    <>
      <div className="tbl_accr_itm mysa-blue superadmin-table">
        <div className="tbl_hdr mysa-blue">
          <h5 className="m-0 fw-bold text-white">On Request Enquiries</h5>
          <button className="text-white pointer" onClick={RequestBookingHandler}>
            Expand
            <span>
              <img src={isExpanded  ? minus : plus} alt="icon" />
            </span>
          </button>
        </div>
        <div className="acrd_tbl">
          <DataTable
            columns={columns}
            data={visibleData}
            striped={true}
            customStyles={tableStyles}
            theme="mysaTable"
            fixedHeader={true}
            noDataComponent={"Updating records"}
            conditionalRowStyles={[
              {
                when: (row) => true,
                classNames: ['row-item'],
              },
              {
                when: (row) => true,
                style: (row)=> callCustomClass(row),
              },
            ]}
          />
          {currentIndex < requestBookingData?.length && (
            <div className="more-data-section">
              <Button onClick={loadMoreData} className="more_button">More</Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestBookingSuperAdminList;
