import { Select, Switch, Tooltip as ToolAntd } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import ImageUpload from '../../../common/imageUpload';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Button, Col, Container, Form, Row, Image, Spinner, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { InventoryImageFormInput } from '../../../../common/interfaces';
import { toast } from 'react-toastify';
import { formButton, overlayTriggerDelay, Settings, slideImageStyle } from '../../../../common/components-style';
import Slider from "react-slick";
import Gallery from 'react-grid-gallery';
import { canBrandPropertyModuleWrite } from '../../../../common/functions';
import { UserAccess } from '../../../../common/constants';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
import Fancybox from '../../../common/fancyBox';

const { Option } = Select;

const InventoryImages: React.FC<any> = ({ allInOnePropertyBreadCrumb, setFormOpen, inventoryId, propertyId, selectedBrand, selectedProperty }): JSX.Element => {
  ///////////////////// initialize form ////////////////////////////////////// 
  const { register, setValue, reset, handleSubmit, formState: { errors }, control } = useForm<InventoryImageFormInput>();

  const [updateEnable, setUpdateEnable] = useState<boolean>(false);
  const [editEnable, setEditEnable] = useState<boolean>(true);
  const [imageData, setImageData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [imageList, setImageList] = useState<Array<any>>([]);
  const [image, setImage] = useState<any>();
  const [imageUpdated, setImageUpdated] = useState(false);
  const [tagsList, setTagList] = useState<Array<Object>>([]);
  const [tags, setTags] = useState<Array<Object>>([]);
  const [tagsSelect, setTagsSelect] = useState<any>();
  const [imageId, setImageId] = useState<string>();
  const [status, setStatus] = useState<string>('active');
  const [checkValue, setCheckValue] = useState<boolean>(true);
  const [show, setShow] = useState(false);
  const [imagesGallery, setImagesGallery] = useState<Array<any>>([]);
  const [imageVisible, setImageVisible] = useState(true);
  const [inventoryData, setInventoryData] = useState<any>();

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { uploadInventoryImage, getInventoryImage, resetInventoryImage, getInventoryImageTag, getInventoryById } = useStoreActions<any>((actions) => ({
    uploadInventoryImage: actions.inventory.uploadInventoryImage,
    getInventoryImage: actions.inventory.getInventoryImage,
    resetInventoryImage: actions.inventory.resetInventoryImage,
    getInventoryImageTag: actions.inventory.getInventoryImageTag,
    getInventoryById: actions.inventory.getInventoryById,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    uploadInventoryImageSuccess,
    uploadInventoryImageError,
    getInventoryImageSuccess,
    getInventoryImageError,
    updateInventoryImageSuccess,
    removeInventoryImageSuccess,
    getInventoryImageTagSuccess,
    getInventoryImageTagError,
    getInventoryByIdSuccess

  } = useStoreState<any>((state) => ({
    uploadInventoryImageSuccess: state.inventory.uploadInventoryImageSuccess,
    uploadInventoryImageError: state.inventory.uploadInventoryImageError,
    getInventoryImageSuccess: state.inventory.getInventoryImageSuccess,
    getInventoryImageError: state.inventory.getInventoryImageError,
    updateInventoryImageSuccess: state.inventory.updateInventoryImageSuccess,
    removeInventoryImageSuccess: state.inventory.removeInventoryImageSuccess,
    getInventoryImageTagSuccess: state.inventory.getInventoryImageTagSuccess,
    getInventoryImageTagError: state.inventory.getInventoryImageTagError,
    getInventoryByIdSuccess: state.inventory.getInventoryByIdSuccess,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (inventoryId) {
      getInventoryImage(inventoryId);
    }

  }, [getInventoryImage, inventoryId]);

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (inventoryId) {
      getInventoryById(inventoryId);
    }

  }, [getInventoryById, inventoryId]);


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getInventoryByIdSuccess) {

      setInventoryData(getInventoryByIdSuccess.data);
    }

  }, [getInventoryByIdSuccess]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getInventoryImageSuccess) {
      if (getInventoryImageSuccess.data.length !== 0) {
        setImageData(getInventoryImageSuccess.data[0].image);
        const inventories = (getInventoryImageSuccess.data[0]?.moderateImage && getInventoryImageSuccess.data[0]?.moderateImage?.length !== 0) ? getInventoryImageSuccess.data[0]?.moderateImage : getInventoryImageSuccess.data[0].image;
        const imageListTemp: Array<any> = [];
        const imageGallery: Array<Object> = [];
        _.map(inventories, (element) => {
          imageListTemp.push(element);
          imageGallery.push(
            {
              src: element.url,
              thumbnail: element.url,
              thumbnailWidth: 300,
              thumbnailHeight: 200,
              caption: element.caption
            }
          );
        }
        );
        setImageList(imageListTemp);
        setImagesGallery(imageGallery);
      }
    }
    if (getInventoryImageError) {
      toast.error(getInventoryImageError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [getInventoryImageError, getInventoryImageSuccess]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (selectedProperty?.brandId?._id) {
      getInventoryImageTag(selectedProperty?.brandId?._id);
    }

  }, [getInventoryImageTag, selectedProperty]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getInventoryImageTagSuccess) {
      const tagList = getInventoryImageTagSuccess.data;
      if (tagList) {
        setTags(tagList.tags);
      }
    }

    if (getInventoryImageTagError) {
      toast.error(getInventoryImageTagError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [getInventoryImageTagError, getInventoryImageTagSuccess]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (uploadInventoryImageSuccess) {
      setLoading(false);
      resetInventoryImage();
      toast.success("Inventory Image uploaded successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      getInventoryImage(uploadInventoryImageSuccess.data.inventoryId);
      reset();
      setTagsSelect('');
      setUpdateEnable(false);
      setImageId('');
      setImage('');
      setImageUpdated(false);
      setImageVisible(false);
    }

    if (updateInventoryImageSuccess) {
      setLoading(false);
      resetInventoryImage();
      reset();
      setTagsSelect('');
      setUpdateEnable(false);
      toast.success("Inventory Image updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      getInventoryImage(updateInventoryImageSuccess.data.inventoryId);
      setImageUpdated(false);
      setImage('');
      setImageVisible(false);
    }

    if (removeInventoryImageSuccess) {
      setLoading(false);
      resetInventoryImage();
      // toast.success("Inventory Image removed successfully", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      //   className: 'foo-bar'
      // });
      getInventoryImage(removeInventoryImageSuccess.data.inventoryId);
      reset();
      setTagsSelect('');
      setUpdateEnable(false);
      setImageId('');
      setImage('');
      setImageUpdated(false);
      setImageVisible(false);
    }
    if (uploadInventoryImageError) {
      setLoading(false);
      toast.error(uploadInventoryImageError, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [uploadInventoryImageSuccess, uploadInventoryImageError, resetInventoryImage, removeInventoryImageSuccess, updateInventoryImageSuccess, getInventoryImage, reset]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const getImageBlob = useCallback((imageBlob) => {
    setImage(imageBlob);
    setImageUpdated(true);
    setImageVisible(true);
  }, []);


  //////////////////////////////////////////////////////////////////////////////////////////////
  const tagChange = useCallback((value) => {
    setTagsSelect(value);
    setTagList(value);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const statusChange = useCallback((value) => {
    setCheckValue(value);
    if (value) {
      setStatus('active');
    }
    else {
      setStatus('inactive');
    }
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////////
  const imageClick = useCallback((value) => {
    setCheckValue((value.status === 'active') ? true : false);
    setImageId(value._id);
    setStatus(value.status)
    reset();
    setUpdateEnable(true);
    setValue('caption', value.caption);
    setValue('description', value.description);
    setValue('image', value.url);
    setValue('tags', value.tags);
    setTagsSelect(value.tags)
    setValue('status', checkValue);
    setImageVisible(true);
  }, [checkValue, reset, setValue]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  const removeImage = useCallback((value) => {
    const data = {
      inventoryId: inventoryId,
      imageId: value._id,
      isDeleted: true,
      brandId: selectedProperty?.brandId?._id,
    }
    uploadInventoryImage(data);
  }, [inventoryId, selectedProperty?.brandId, uploadInventoryImage]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderTag = useCallback(() => {
    if (tags) {
      return tags.map((tg: any, key: number) => {
        return (
          <Option key={key} value={`${tg}`} >{tg}</Option>
        )
      });
    }
  }, [tags]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = (values) => {
    setLoading(true);
    if (imageId) {
      values.imageId = imageId;
      const data = {
        inventoryId: inventoryId,
        brandId: selectedProperty?.brandId?._id,
        imageId: imageId,
        image: imageUpdated ? image : values.image,
        tags: values.tags,
        caption: values.caption,
        description: values.description,
        isDeleted: false,
        status: values.status ? status : 'inactive'
      }
      uploadInventoryImage(data);

    } else {
      values.inventoryId = inventoryId;
      values.imageId = '';
      values.image = image;
      values.tags = tagsList;
      values.status = status;
      values.brandId = selectedProperty?.brandId?._id;
      uploadInventoryImage(values);
    }

  };

  const changeFormEditable = useCallback(() => {
    if (editEnable) {
      setEditEnable(false);
    } else {
      setEditEnable(true);
    }
  }, [editEnable]);

  const handleCloseHide = (value) => setShow(value);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
       <div className="content-title fixed_title">
      <BreadCrumbPage allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} isPageName="Inventory" selectedProperty={selectedProperty} selectedBrand={selectedBrand} inventoryData={inventoryData} setFormOpen={setFormOpen} inventoryId={inventoryId}></BreadCrumbPage>
    
    </div>
      {
        canBrandPropertyModuleWrite(selectedBrand?.key, propertyId, UserAccess.property_access.details.code) &&
        <div>
          <div className="jumbotron">
            <div style={{ fontSize: 22, marginBottom: '3%' }}>
              <span style={{ color: '#000000', fontWeight: 'bold' }}>Note : </span> <span className='requiredMark'>Images should be minimum two</span>
            </div>
            <Form onSubmit={handleSubmit(onFinish)}>
              <Row>
                <Col md={1}></Col>
                <Col md={7}>
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>A short factual description that will appear below the image e.g. external building image or communal reception area, max 3 words.</Tooltip>}
                      >
                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Caption</Form.Label>
                      </OverlayTrigger>

                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {!editEnable && <p className="lead">{imageData?.caption}</p>}
                      {editEnable &&
                        <OverlayTrigger
                          placement="top"
                          delay={overlayTriggerDelay}
                          overlay={<Tooltip id='tooltip-table-top'>A short factual description that will appear below the image e.g. external building image or communal reception area, max 3 words.</Tooltip>}
                        >
                          <Form.Control className={`${errors.caption ? 'is-invalid' : ''}`} {...register("caption", { required: true })} />
                        </OverlayTrigger>


                      }
                      {editEnable && <div className="invalid-feedback">Please input caption!</div>}
                    </Col>
                  </Row>
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>Short overview of the subject/focus of the image</Tooltip>}
                      >
                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Description</Form.Label>
                      </OverlayTrigger>

                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {!editEnable && <p className="lead">{imageData?.description}</p>}
                      {editEnable &&

                        <OverlayTrigger
                          placement="top"
                          delay={overlayTriggerDelay}
                          overlay={<Tooltip id='tooltip-table-top'>Short overview of the subject/focus of the image</Tooltip>}
                        >
                          <Form.Control as="textarea" rows={5} className={`${errors.description ? 'is-invalid' : ''}`} {...register("description", { required: true })} />
                        </OverlayTrigger>
                      }
                      {editEnable && <div className="invalid-feedback">Please input description!</div>}
                    </Col>
                  </Row>
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Image</Form.Label>
                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {!editEnable && <Image width={100} src={String(image)} thumbnail={true} />}
                      {editEnable &&
                        <Controller
                          name="image"
                          defaultValue={image}
                          control={control}
                          rules={{
                            required: image ? false : true
                          }}
                          render={({ field }) =>
                            <ImageUpload crop={false} getImageBlob={getImageBlob} type="image" value={field.value} disabled={false} visible={imageVisible} />
                          }
                        />}

                      {editEnable && errors.image && <div className="invalid-feedback-custom">Please select image!</div>}
                    </Col>
                  </Row>
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>Tags are used to aid search. Select an existing tag or create your own to help categorise the image</Tooltip>}
                      >
                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Tags</Form.Label>
                      </OverlayTrigger>

                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {!editEnable && <p className="lead">{imageData?.tags}</p>}
                      {editEnable &&
                        <Controller
                          name="tags"
                          defaultValue={tagsSelect}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) =>

                            <ToolAntd color={`#000000`} placement="top" title={`Tags are used to aid search. Select an existing tag or create your own to help categorise the image`}>
                              {tagsSelect ? <Select key={`tags${String(tagsSelect)}`} mode="tags" style={{ width: '100%' }} value={tagsSelect} defaultValue={tagsSelect} onChange={(value) => {
                                tagChange(value);
                                field.onChange(value);
                              }}>
                                {renderTag()}
                              </Select> : <Select mode="tags" style={{ width: '100%' }} onChange={(value) => {
                                tagChange(value);
                                field.onChange(value);
                              }}>
                                {renderTag()}
                              </Select>}
                            </ToolAntd>
                          }
                        />}
                      {editEnable && errors.tags && <div className="invalid-feedback-custom">Please input tags!</div>}
                    </Col>
                  </Row>
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Status</Form.Label>
                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {!editEnable && <p className="lead">{imageData?.status}</p>}
                      {editEnable &&
                        <Controller
                          name="status"
                          defaultValue={imageData?.status}
                          control={control}
                          render={({ field }) =>
                            <Switch size="small" defaultChecked={checkValue} checked={checkValue} onChange={(value) => {
                              statusChange(value);
                              field.onChange(value);
                            }} />
                          }
                        />}
                    </Col>
                  </Row>
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {!loading && !updateEnable && editEnable && inventoryId &&
                        <Button type="submit" id='approve-button' style={formButton}>Create</Button>
                      }
                      {!loading && updateEnable && editEnable && inventoryId &&
                        <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                      }
                      {
                        !loading && !editEnable && inventoryId && <Button id='edit-button' style={formButton} onClick={changeFormEditable} >Edit</Button>
                      }
                      {
                        loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                      }
                    </Col>
                  </Row>
                </Col>
                <Col md={4}></Col>
              </Row>
            </Form>
          </div>
        </div>
      }

      {canBrandPropertyModuleWrite(selectedBrand?.key, propertyId, UserAccess.property_access.details.code) &&
        (imageList.length > 0) &&
        <Container>
          <div className="jumbotron Fancy_wrapper">
            <Row>
              <Slider {...Settings}>
                {imageList.map((img: any, index) => (
                  <div key={index} className='relative text-center text-white'>
                    <Image width={300} src={img.url} alt="avatar" thumbnail={true} style={slideImageStyle}>
                    </Image>
                    <div className='inset' >
                      <EditOutlined className='p-1'  style={{ color: '#E7008C', fontSize:'40px'}} onClick={() => imageClick(img)} />
                      <DeleteOutlined   style={{ color: '#E7008C', fontSize:'40px'}} onClick={() => removeImage(img)} />
                    </div>
                  </div>
                ))}
              </Slider>
            </Row>
            <div className=''>
              <Fancybox>
                <p>
                  {
                    imageList[0] ?
                      (
                        <a
                          className="fancyBtn"
                          data-fancybox="gallery"
                          href={imageList[0]?.url}
                        >
                          <img alt="Property Images" src={imageList[0]?.url} style={{
                            display: "none"
                          }} />
                          {/* <span>
                  <img src={searchIcon} className="icon" alt="" />
                </span> */}
                          Preview
                        </a>
                      ) : (
                        <a
                          className="fancyBtn"
                          data-fancybox="gallery"
                          href={imageList[0]?.url}
                        >
                          <img alt="Property Images" src={imageList[0]?.url} style={{
                            display: "none"
                          }} />
                          {/* <span>
                  <img src={searchIcon} className="icon" alt="" />
                </span> */}
                          Preview
                        </a>
                      )
                  }
                  {imageList?.map((curElemImg, index) => (
                    <>
                      {index !== 0 && (
                        <a
                          key={index}
                          data-fancybox="gallery"
                          href={curElemImg.url}
                          style={{ display: "none" }}
                        >
                          <img alt="Inventary Images" src={curElemImg.url} />
                        </a>
                      )}
                    </>
                  ))}
                  {/* {imageList?.map((curElemPropImg, index) => (
                    <>
                      {imageList[0] ? (
                        <a
                          key={index}
                          data-fancybox="gallery"
                          href={curElemPropImg.url}
                          style={{ display: "none" }}
                        >
                          <img alt="Property Images" src={curElemPropImg.url} />
                        </a>
                      ) : (
                        <>
                          {index !== 0 && (
                            <a
                              key={index}
                              data-fancybox="gallery"
                              href={curElemPropImg.url}
                              style={{ display: "none" }}
                            >
                              <img alt="Inventary Images" src={curElemPropImg.url} />
                            </a>
                          )}
                        </>
                      )
                      }
                    </>
                  ))} */}
                </p>
              </Fancybox>
            </div>

            {/* <Row>
              <div style={{ textAlign: 'center', marginTop: '25px' }}>
                <Button id='edit-button' style={formButton} onClick={() => { handleCloseHide(true) }} ><b>Preview</b></Button>
              </div>
            </Row> */}
          </div>
          <Modal size='xl' show={show} onHide={() => { handleCloseHide(false) }}>
            <Modal.Header closeButton>
              <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Gallery images={imagesGallery} />
            </Modal.Body>
          </Modal>
        </Container>
      }
      {
        !canBrandPropertyModuleWrite(selectedBrand?.key, propertyId, UserAccess.property_access.details.code) &&
        <Container>
          <Gallery margin={20} images={imagesGallery} />
        </Container>
      }
    </>
  )
};

export default InventoryImages;
