import React from "react";
import { Button, Spinner } from "react-bootstrap";

type TButtonC = {
    loading: boolean;
    id: string;
    label: string;
    onClick?: () => void;
};

const ButtonC: React.FunctionComponent<TButtonC> = ({ loading, id, label, onClick }) => {

    return (<>
        {
            !loading ? <Button onClick={onClick} id={id} type="submit" style={{ width: '190px', marginLeft: '15px' }} >
                <b>{label}</b>
            </Button> : <Spinner animation="border" variant="warning" />
        }

    </>);
}

export default ButtonC;