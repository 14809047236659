import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import { useHistory, useLocation } from 'react-router-dom';
import { mysaTableTheme, tableStyles } from '../../../../../common/components-style';
import { BrandRfpPropertyEvaluationStatus } from '../../../../../common/constants';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

/////////////////////////// table button style ///////////////////////////////////////////

const tableButtonStyle = {
  background: '#FBC91D',
  border: '1px solid #FBC91D',
  borderRadius: 15,
  height: '35px !important',
  paddingLeft: '20px',
  paddingRight: '20px',
  color: '#212121',
}

const ClientProgrammePropertyList: React.FC<any> = ({ rfpProcess, selectCityName }): JSX.Element => {
  //////////////////// local state management 
  const [tableData, setTableData] = useState<any>();
  const [oldCity, setOldCity] = useState<string>();

  ////////// routing ///////
  const history = useHistory();
  const location = useLocation<any>();

  /////////////////////////////////// global action manage ////////////////////////////////////////////
  const { programmePropertyList } = useStoreActions<any>(
    (actions) => ({
      programmePropertyList:
        actions.rfpProcess.programmePropertyList,
    })
  );

  /////////////////////////////////// global state manage ////////////////////////////////////////////
  const { getProgrammePropertyListSuccess } = useStoreState<any>(
    (state) => ({
      getProgrammePropertyListSuccess:
        state.rfpProcess.getProgrammePropertyListSuccess,
      getProgrammePropertyListError:
        state.rfpProcess.getProgrammePropertyListError,
    })
  );

  // load from state from url
  useEffect(() => {
    if (location.state?.cityName && location.state?.rfpId) {
      const payload = {
        rfp_id: location.state?.rfpId,
        city_name: location.state?.cityName
      };
      programmePropertyList(payload);
      setOldCity(location.state?.cityName);
    }
  }, [location.state?.cityName, location.state?.rfpId, programmePropertyList]);
  // load initialize
  useEffect(() => {

    if (selectCityName) {
      const payload = {
        rfp_id: location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id,
        city_name: selectCityName
      };
      if (selectCityName !== oldCity) {
        programmePropertyList(payload);
        setOldCity(selectCityName);
      }
    }
  }, [location.state?.rfpId, oldCity, programmePropertyList, rfpProcess?._id, selectCityName]);

  // load initialize
  useEffect(() => {
    if (getProgrammePropertyListSuccess) {
      const data = _.filter(getProgrammePropertyListSuccess?.data, list => ((list.evaluationStatus === BrandRfpPropertyEvaluationStatus.COMPLETE) && (list?.property?.propertyStatus !== 'archived')));
      setTableData(data);
    }
  }, [getProgrammePropertyListSuccess]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      name: 'Property',
      selector: row => row.property?.propertyName,
    },
    {
      name: 'Brand',
      selector: row => row.property?.brandId.name,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <>
            <Button className='btn-viewProperty' onClick={() => {
              history.push({
                pathname: `/admin/client-proposal-management/property/${row.property?._id}/${location.state?.rfpId ? location.state?.rfpId : rfpProcess?._id}`
              }, { query: selectCityName });
            }}><b>View Property</b></Button>
          </>
        );
      }
    }
  ];

  return (
    <>
      <div className='mt-3'>
        <DataTable
          columns={columns}
          data={tableData}
          pagination
          striped={true}
          customStyles={tableStyles}
          theme='mysaTable'
          fixedHeader={true}
          noDataComponent={'Updating records'}
        />
      </div>
    </>
  );
}

export default ClientProgrammePropertyList;