import { Breadcrumb, Divider } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import AuditSection from '../../../admin/property-management/form/audit/AuditSection';
import QuestionsHome from '../../../admin/property-management/form/audit/questions/QuestionsHome';
import ActionsButtons from './ActionsButtons';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignUpFormHome: React.FC<any> = (): JSX.Element => {
  const query = useQuery();
  // const certificateId = query.get('certificateMyoManagementID');
  const certificateMyoManagementID = query.get('certificateMyoManagementID');
  const signUpId = query.get('_id');
  const signUpStatus = query.get('signUpStatus');
  const brandName = query.get('brand');
  const [surveyId, setSurveyId] = useState<any>(null);
  const [sectionId, setSectionId] = useState<any>(null);
  const [sections, setSections] = useState<any>([]);
  const [emptyQuestions, setEmptyQuestions] = useState<any>(true);
  const [showSubmit, setShowSubmit] = useState<boolean>(true);
  const [surveys, setSurveys] = useState<any>([]);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const { getAuditCertificate, getAuditSurveys, } = useStoreActions<any>((actions) => ({
    getAuditCertificate: actions.myoManagementModel.getAuditCertificate,
    getAuditSurveys: actions.myoManagementModel.getAuditSurveys,
  }));

  const { getAuditCertificateSuccess, getAuditSurveysSuccess } = useStoreState<any>((state) => ({
    getAuditCertificateSuccess: state.myoManagementModel.getAuditCertificateSuccess,
    getAuditSurveysSuccess: state.myoManagementModel.getAuditSurveysSuccess,
  }));

  const loadCertificate = useCallback(() => {
    getAuditCertificate({
      security:
      {
        APIKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
        user: "superadmin@mysa.global"
      },
      certificateid: certificateMyoManagementID
    })
  }, [certificateMyoManagementID, getAuditCertificate])

  useEffect(() => {
    loadCertificate()
  }, [loadCertificate]);

  useEffect(() => {
    if (getAuditCertificateSuccess?.certificate) {
      setSurveyId(getAuditCertificateSuccess?.certificate?.surveys[0].id);
      setSections(getAuditCertificateSuccess?.certificate?.surveys[0].survey.sections);
    }
  }, [getAuditCertificateSuccess]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSectionClick = useCallback((section) => {
    setEmptyQuestions(false);
    setSectionId(section.id);
    setIsSelected(true);
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadSections = useMemo(() => {
    return sections.map((val, key) => {
      return <AuditSection onSectionClick={onSectionClick} surveyId={surveyId} values={val} index={key} selected={isSelected} sectionId={sectionId} />
    })
  }, [isSelected, onSectionClick, sectionId, sections, surveyId]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (surveyId) {
      getAuditSurveys({
        security: {
          apiKey: process.env.REACT_APP_AUDIT_API_KEY,
          user: "superadmin@mysa.global"
        },
        certificatesurveyid: surveyId
      });
    }

  }, [getAuditSurveys, surveyId])

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAuditSurveysSuccess && getAuditSurveysSuccess.status.errorCode === 0) {
      setSurveys(getAuditSurveysSuccess.certificateSurvey);
      if (getAuditSurveysSuccess.certificate.issued || getAuditSurveysSuccess.certificateSurvey.isCompleted) {
        setShowSubmit(false);
      } else {
        setShowSubmit(true);
      }

    }
  }, [getAuditSurveysSuccess, sections])


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <div className="pt-10">
          <Breadcrumb className="mb-4">
          <Breadcrumb.Item>
            <Link to={{ pathname: "/admin/brand-management" }} >Brand Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item> <Link to={{ pathname: "/admin/brand-management", state: {brandInvitation: true} }} >Brand Invitation</Link></Breadcrumb.Item>
          <Breadcrumb.Item>{brandName}</Breadcrumb.Item>
        </Breadcrumb>
      <div className='row'>
        
        <div className='col-lg-9 col-md-8'>
        <div className="sections-section">
          <div className="">
            {loadSections}
          </div>
        </div>
        </div>
        <div className='col-lg-3 col-md-4'>
        
            <div className='action-btn' style={{height: '100%',display: 'flex', alignItems: 'center'}}>
                 { signUpStatus !== 'rejected' && <ActionsButtons surveys={surveys} howButtons buttonData={{ signUpId, certificateMyoManagementID}} />}
            </div>
         
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
        <div className="questions-section">
          <Divider plain></Divider>
          <div className="">
            {surveyId && !emptyQuestions && < QuestionsHome signUpStatus={signUpStatus} emptyQuestions={emptyQuestions} sectionId={sectionId} surveyId={surveyId} showButtons buttonData={{ signUpId, certificateMyoManagementID }} completed={!showSubmit} />}
          </div>
        </div>
        </div>
      </div>
{/* 
       {isSelected && <div className="sections-section">
        <Divider plain></Divider>
          <div className="">
            {loadSections}
          </div>
        </div>} */}
      </div>
    </div>
  )
}

export default SignUpFormHome;
