import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import DataTable, { createTheme } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { corporateUser, isProgramAdmin } from '../../../common/functions';
import { UserGroups, UserProfile } from '../../../common/constants';
import { $mysaMegenta, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, resendButton, routingButton, tableUserStyles } from '../../../common/components-style';
import ProgrammePermissionModel from '../programme-management/form/programme-users/ProgrammePermissionModel';
import _ from 'lodash';
import UserForm from './Form/UserForm';
import AdminUserView from './Form/UserView';
// import { HelpModal } from '../../../../common/HelpModal';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const AdminCorporatePage: React.FunctionComponent<any> = ({ programme }) => {
    const [selectedUser, setSelectedUser] = useState<any>();
    const [loadModel, setLoadModel] = useState(false)

    const [userMappingData, setUserMappingData] = useState<Array<any>>([]);
    const [showForm, setShowForm] = useState(false);
    const [showView, setShowView] = useState(false);



    const { onResendPassword, getUserMapping, resetAuth, updateAccess } = useStoreActions<any>((actions) => ({
        resetAuth: actions.auth.resetAuth,
        onResendPassword: actions.auth.onResendPassword,
        getUserMapping: actions.user.getUserMapping,
        updateAccess: actions.user.updateAccess,

    }));

    const { resendPassword, resendPasswordError, getUserMappingSuccess, updateAccessSuccess } = useStoreState<any>((state) => ({
        resendPassword: state.auth.resendPassword,
        resendPasswordError: state.auth.resendPasswordError,
        getUserMappingSuccess: state.user.getUserMappingSuccess,
        updateAccessSuccess: state.user.updateAccessSuccess,

    }));

    useEffect(() => {
        getUserMapping({});
    }, [getUserMapping]);

    useEffect(() => {
        // console.log(programme, 'programme');
        if (getUserMappingSuccess) {
            const { result, poolUsers } = getUserMappingSuccess.data;

            /**
             * corporate users
             */
            const corporateUserList = corporateUser(result, poolUsers);
            setUserMappingData(corporateUserList);
        }

    }, [getUserMappingSuccess]);

    useEffect(() => {
        if (resendPassword) {
            // message.success('password resend successful, Please check the email');
            toast.success("password resend successful, Please check the email", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            })
            resetAuth();
        }

        if (resendPasswordError) {
            // message.error(resendPasswordError.message);
            toast.error(resendPasswordError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetAuth();
        }

        // if (updateAccessSuccess) {
        //     // resetUser();
        //     toast.success("disable user successfully", {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         className: 'foo-bar'
        //     });

        // }


    }, [resendPassword, resetAuth, resendPasswordError, updateAccessSuccess]);


    const reload = useCallback(() => {
        setTimeout(() => getUserMapping({ clientId: programme?.clientId?._id }), 1000);
        setShowForm(false);
    }, [getUserMapping, programme?.clientId?._id])

    const data: any = useMemo(() => {
        // filterData = _.filter(filterData, (a) => a.corporateProgrammeId === programmeId && a.role !== UserGroups.traveler && a.role !== UserGroups.booker);
        // return filterData;
        let filterData: any = [];
        filterData = _.filter(userMappingData, (a) => a.corporateProgrammeId === programme?._id && (a.role === UserGroups.programme_admin || a.role === UserGroups.CHIEF_PROGRAMME_ADMIN));
        return filterData;

    }, [programme?._id, userMappingData])


    const columns = [


        { name: 'First name', selector: row => row.firstName,
        //  maxWidth: '180px'
         },
        { name: 'Last name', selector: row => row.lastName,
        //  maxWidth: '180px'
         },
        { name: 'Email', selector: row => row.email,
        //  maxWidth: '250px', minWidth: '220px' 
        },

        {
            name: 'User type',
            // minWidth: '200px', maxWidth: '200px',
            // button: true,
            cell: (row) => {
                let text = "";
                if (row.role === UserGroups.programme_admin) text = 'Administrator';
                if (row.role === UserGroups.CHIEF_PROGRAMME_ADMIN) text = 'Chief Programme Admin';

                return (
                    <span style={{ textTransform: 'capitalize',fontSize:"16px" }} className='text-capitalize'>{text}</span>
                );

            }
        },
        {
            name: 'Status',
            // minWidth: '150px',
            // minWidth: '180px',
            // maxWidth: '250px',
            cell: (row) => {
                let color = "";
                if (row.userStatus !== 'CONFIRMED') color = $mysaYellow;
                if (row.userStatus === 'CONFIRMED') color = $mysaTeal;
                if (row.userStatus === 'FORCE_CHANGE_PASSWORD') color = $mysaMegenta;
                if (row.status === 'inactive') color = $mysaMegenta;

                return (

                    <span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>
                        {
                            (row.status === 'inactive') && 'DISABLED'
                        }
                        {
                            (row.status !== 'inactive') && `${row.userStatus}`
                        }
                        {row.status !== 'inactive' && row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => onResendPassword({ email: row.email })} className="ml-2 mt-1" >Resend</Button>}
                    </span>
                    // <span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} >{row.userStatus} {row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button onClick={() => onResendPassword({ email: row.email })} style={resendButton} id='resend-button' >Resend</Button>}</span>
                    // <>
                    // 	<span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>{row.userStatus}</span>
                    // 	{row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => onResendPassword({ email: row.email })} className="ml-5" >Resend</Button>}
                    // </>
                )
            }
        },
        {
            name: 'User profile',
            // minWidth: '150px',
            // button: true,
            cell: (row) => {
                let color = "";
                if (row.userProfile !== UserProfile.COMPLETED) color = $mysaMegenta;
                if (row.userProfile === UserProfile.COMPLETED) color = $mysaTeal;

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.userProfile}</span>
                );

            }
        },
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                <>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
                    >
                        <img

                            className='iconSizes' src={ViewEdit} alt="" onClick={() => { setSelectedUser(row); setShowView(true) }} />
                    </OverlayTrigger>

                    {/* {
                        isProgramAdmin() && <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
                        >
                            <img className='iconSizes' src={Archive} alt="" onClick={() => {
                                const data = {
                                    id: selectedUser.arrId,
                                    userId: selectedUser.userId,
                                    isUserDisable: true
                                }

                                updateAccess(data);
                            }} />
                        </OverlayTrigger>
                    } */}
                    {/* {row.role !== UserGroups.CHIEF_PROGRAMME_ADMIN && <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>Permission</Tooltip>}

                    >
                        <img className='iconSizes' src={Permission} alt="" onClick={() => { setLoadModel(true); setSelectedUser(row) }} />
                    </OverlayTrigger>} */}
                </>
            )

        },
    ];

    return (
        <div style={{ marginTop: "-2%", paddingTop: "4px" }}>
            <Row>
                <Col xs={9}>{programme?.programmeName && <div className="content-title">{programme?.programmeName}</div>}</Col>
                <Col xs={3} className="text-right">
                    <div className='d-flex align-items-center justify-content-end gx-2'>

                        {isProgramAdmin() && <Button style={routingButton} id='routing-button' hidden={showForm || showView} className='float-right' onClick={() => { setSelectedUser(null); setShowForm(true) }}><b>New Admin</b></Button>}
                    </div>

                </Col>
            </Row>


            {/* <Button type="primary" hidden={!showForm} className='float-right' onClick={() => setShowForm(false)}>User List</Button> */}
            {showForm && <UserForm selectedUser={selectedUser} clientId={programme?.clientId?._id} programmeId={programme?._id} programmeName={programme?.programmeName} close={() => setShowForm(false)} reload={reload} />}
            {showView && <AdminUserView selectedUser={selectedUser} close={() => setShowView(false)} reload={reload} />}
            {showForm || showView ||
                <div className='mt-4 brandUser_table'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        striped={true}
                        customStyles={tableUserStyles}
                        theme='mysaTable'
                        fixedHeader={true}
                        noDataComponent={'Updating records'}
                    />
                </div>
            }
            {loadModel && <ProgrammePermissionModel clientId={selectedUser?.clientId} programmeId={selectedUser?.corporateProgrammeId} userMappingData={userMappingData} corporateProgrammes={selectedUser?.corporateProgramme} selectedUser={selectedUser} onClose={() => { setLoadModel(false); }} />}
        </div>
    )
}

export default AdminCorporatePage
