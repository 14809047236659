import { Menu } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getStorage } from '../../../../common/storage';

// const { Text } = Typography;

const ExecutiveReview: React.FC<any> = ({ rfpId, programmeName, clientName, startDate }): JSX.Element => {

    ///////////////////////////////////////// local state manage /////////////////////////////////////////
    const [menuKey, setMenuKey] = useState("introduction");
    const [rfpProcess] = useState<any>(getStorage('selectedRFPProcess'));
    const [htmlData, setHtmlData] = useState<any>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFilterClient] = useState<any>(getStorage('selectedFilterClient'));
    const [selectedProgramsOption] = useState<any>(getStorage('selectedProgramsOption'));
    const [startedDate] = useState<any>(startDate ? startDate : rfpProcess?.startDate);

    //////////////// global state and action manage /////////////////
    const {
        getRFPAdminFilterReview
    } = useStoreActions<any>((actions) => ({
        getRFPAdminFilterReview: actions.rfpProcess.getRFPAdminFilterReview
    }));


    const {
        getRFPAdminExReviewSuccess
    } = useStoreState<any>((state) => ({
        getRFPAdminExReviewSuccess: state.rfpProcess.getRFPAdminExReviewSuccess
    }));


    ///////////////////////// menu change ///////////////////////////////////
    const menuChange = useCallback((value) => {
        setMenuKey(value.key);
    }, []);

    useEffect(() => {
        const payload = {
            rfpProcessId: rfpId ? rfpId : rfpProcess?._id,
            reviewType: menuKey,
            locationName: ''
        }
        getRFPAdminFilterReview(payload);
        setLoading(true);
    }, [getRFPAdminFilterReview, menuKey, rfpProcess, rfpId])

    useEffect(() => {
        if (getRFPAdminExReviewSuccess) {
            setLoading(false);
            setHtmlData(getRFPAdminExReviewSuccess?.data);
        }
    }, [getRFPAdminExReviewSuccess]);


    return (<>
        <Row >
            <Col md={12}>
                <div className='content-title mysa-blue-text'>
                    <Link to='/admin/client-proposal-management ' className="fw-sb mysa-blue-text">{`${selectedFilterClient?.label ? selectedFilterClient?.label : clientName}`}</Link> {` - ${selectedProgramsOption?.label ? selectedProgramsOption?.label : programmeName} - RFP ${moment(startedDate).format('ll')}`}</div>
            </Col>
        </Row>
        <Row className='new_tab_wpr'>
            <Col md={12}>
                <Menu
                    onClick={menuChange}
                    mode="horizontal"
                    className="w-full new_tab"
                    selectedKeys={[menuKey]}
                >
                    <Menu.Item key="introduction">
                        <span>
                            Introduction
                        </span>
                    </Menu.Item>
                    <Menu.Item key="aims">
                        <span>
                            Objective
                        </span>
                    </Menu.Item>
                    <Menu.Item key="out_come_of_rfp">
                        <span >
                            Outcome
                        </span>
                    </Menu.Item>
                    <Menu.Item key="conclusion">
                        <span >
                            Considerations
                        </span>
                    </Menu.Item>
                </Menu>
                {
                    loading && <div className="new_tab_ctnt"><div className='text-center'><Spinner animation="border" variant="warning" /></div></div>
                }
                {
                    !loading && <div className="new_tab_ctnt">
                        {menuKey === "introduction" && <>

                            <div className="new_tab_ctnt_inr" dangerouslySetInnerHTML={{ __html: htmlData?.description ? htmlData?.description : '' }} />
                        </>}
                        {menuKey === "aims" && <>
                            <div className="new_tab_ctnt_inr" dangerouslySetInnerHTML={{ __html: htmlData?.description ? htmlData?.description : '' }} />
                        </>}
                        {menuKey === "out_come_of_rfp" && <>
                            <div className="new_tab_ctnt_inr" dangerouslySetInnerHTML={{ __html: htmlData?.description ? htmlData?.description : '' }} />
                        </>}
                        {menuKey === "conclusion" && <>
                            <div dangerouslySetInnerHTML={{ __html: htmlData?.description ? htmlData?.description : '' }} />
                        </>}
                    </div>
                }
            </Col>
        </Row>
    </>);
}

export default ExecutiveReview;