

import React, { useCallback, useEffect, useState } from 'react';

import { Button, Col, Nav, Row, Tab } from 'react-bootstrap';

import { formButton3, navContentStyle, navStyleNew, tabDivStyle } from '../../../../common/components-style';
import MyPropertiesHome from './MyPropertiesHome';
import { Checkbox } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getStorage, setStorage } from '../../../../common/storage';
import { BrandRfpPropertyEvaluationStatus, RfpProcessStatus, UserGroups } from '../../../../common/constants';
import icon from "../../../../images/icons/maps-and-flags.png";
import _ from 'lodash';
import { toast } from 'react-toastify';
import ProgrammeOperatorHome from './OperatorHome';
import { useLocation } from 'react-router-dom';
const statusList = [{ label: (<b>{`Live`}</b>), value: 'activated' },
{ label: (<b>{`Offline`}</b>), value: 'accepted', },
{ label: (<b>{`Archive`}</b>), value: 'archive', },
]



const ProgrammeHomePage: React.FC = (): JSX.Element => {


	const [tabKey, setTabKey] = useState<any>('properties');
	const { control } = useForm();
	const [rfpProcess, setRfpProcess] = useState<any>();
	const [selectCountryCode, setSelectCountryCode] = useState<any>();
	const [selectCityName, setSelectCityName] = useState<any>();
	const [countryOption, setCountryOption] = useState<any>([]);
	const [locationOption, setLocationOption] = useState<any>([]);
	const [propertyTypeIds, setPropertyTypeIds] = useState<any>([]);
	const [roomTypeIds, setRoomTypeIds] = useState<any>([]);
	const [propertyTypes, setPropertyTypes] = useState<any>([]);
	const [checkIn, setCheckIn] = useState<any>([]);
	const [roomTypeList, setRoomTypeList] = useState<any>([]);
	const [checkIns, setCheckIns] = useState<any>([]);
	const [status, setStatus] = useState<any>(['activated', 'accepted']);
	const [tableData, setTableData] = useState<any>();
	const [operatorData, setOperatorData] = useState<any>();
	const location = useLocation<any>();

	const { acceptPropertyForRFP, getPropertyTypes, getCheckInProcessDetail, getRoomTypeList, getCorporateProgrammePropertyByCity, getProcessByProgrammeIdAndStatus } = useStoreActions<any>((actions) => ({
		getPropertyTypes: actions.property.getPropertyTypes,
		getCheckInProcessDetail: actions.property.getCheckInProcessDetail,
		getRoomTypeList: actions.inventory.getRoomTypeList,
		getCorporateProgrammePropertyByCity: actions.rfpProcess.getCorporateProgrammePropertyByCity,
		getProcessByProgrammeIdAndStatus: actions.rfpProcess.rfpProcessByProgrammeIdAndStatus,
		acceptPropertyForRFP: actions.rfpProcess.acceptPropertyForRFP,
	}));

	const {
		getPropertyTypesSuccess,
		getCheckInProcessListSuccess,
		getRoomTypeListSuccess,
		getCorporateProgrammePropertyByCitySuccess,
		rfpProcessByProgramIdAndStatusSuccess,
		acceptPropertyForRFPSuccess
	} = useStoreState<any>((state) => ({
		getPropertyTypesSuccess: state.property.getPropertyTypesSuccess,
		getCheckInProcessListSuccess: state.property.getCheckInProcessListSuccess,
		getRoomTypeListSuccess: state.inventory.getRoomTypeListSuccess,
		getCorporateProgrammePropertyByCitySuccess: state.rfpProcess.getCorporateProgrammePropertyByCitySuccess,
		rfpProcessByProgramIdAndStatusSuccess: state.rfpProcess.rfpProcessByProgramIdAndStatusSuccess,
		acceptPropertyForRFPSuccess: state.rfpProcess.acceptPropertyForRFPSuccess,
	}));


	//////////////// initialize 
	useEffect(() => {
		getPropertyTypes();
		getCheckInProcessDetail();
		getRoomTypeList();
	}, [getCheckInProcessDetail, getPropertyTypes, getRoomTypeList]);


	useEffect(() => {
		setStorage('viewData', false);
		if (location?.state?.defaultActiveKey) {
			setTabKey(location?.state?.defaultActiveKey)
		} else {
			setTabKey('properties');
		}

		// if(tabKey==='properties'){
		// 	setStorage('viewData', false);
		// }
	}, [location]);



	//////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getRoomTypeListSuccess) {
			const roomsConf: any = [];
			for (const rooms of getRoomTypeListSuccess.data) {
				if (rooms.name.trim() === `Studio/Open plan apartment`) {
					const room = {
						label: (<b>{`Studio/Open`}</b>),
						value: rooms._id,
						id: 1
					};
					roomsConf.push(room);
				}

				if (rooms.name.trim() === 'One bedroom') {
					const room = {
						label: (<b>{`1 Bedroom`}</b>),
						value: rooms._id,
						id: 2
					};
					roomsConf.push(room);
				}

				if (rooms.name.trim() === 'Two bedroom') {
					const room = {
						label: (<b>{`2 Bedroom`}</b>),
						value: rooms._id,
						id: 3
					};
					roomsConf.push(room);
				}

				if (rooms.name.trim() === 'Three bedroom') {
					const room = {
						label: (<b>{`3 Bedroom`}</b>),
						value: rooms._id,
						id: 4
					};
					roomsConf.push(room);
				}

				if (rooms.name.trim() === `More than 3 bedrooms`) {
					const room = {
						label: (<b>{`3+ Bedroom`}</b>),
						value: rooms._id,
						id: 5
					};
					roomsConf.push(room);
				}
			}

			const roomList = roomsConf.sort((a, b) => {
				if (a.id < b.id) {
					return -1;
				}
				if (a.id > b.id) {
					return 1;
				}
				return 0;
			});
			setRoomTypeList(roomList);
		}

	}, [getRoomTypeListSuccess]);

	////////////// initialize property results
	useEffect(() => {

		if (getPropertyTypesSuccess) {
			const types = getPropertyTypesSuccess.data;
			const proType: any = []
			const ids: any = [];

			types.forEach((propertyType) => {
				if (propertyType.name.trim() === 'Aparthotel') {
					const type = {
						value: propertyType._id,
						label: (<b>{propertyType.name}</b>)
					}
					proType.push(type);
				}
				if (propertyType.name.trim() === 'Apartments - building fully managed by our brand') {
					// if (ids?.length === 0) {
					const type = {
						value: propertyType._id,
						label: (<b>{`Fully Managed`}</b>)
					}
					proType.push(type);
					// }
					//
				}
				if (propertyType.name.trim() === 'Apartments - building managed by a third party') {
					// if (ids?.length === 0) {
					const type = {
						value: propertyType._id,
						label: (<b>{`3rd Party Managed`}</b>)
					}
					proType.push(type);
					// }
					// ids.push(propertyType._id);
				}


				if (propertyType.name.trim() === 'Self contained single building') {
					const type = {
						value: propertyType._id,
						label: (<b>{`Self Contained`}</b>)
					}
					proType.push(type);
				}
				ids.push(propertyType._id);
			});
			// setPropertyTypeIds(ids);
			setPropertyTypes(proType);
		}

		if (getCheckInProcessListSuccess) {
			const checkIn = getCheckInProcessListSuccess.data;
			const check: any = [];
			checkIn.forEach((checkValue) => {
				if (checkValue.name.trim() === '24 hour reception') {
					const type = {
						value: checkValue._id,
						label: (<b>{`24 Hr Reception`}</b>)
					}
					check.push(type);
				}
				if (checkValue.name.trim() === 'Daytime only reception (not 24 hrs)') {
					// if (ids?.length === 0) {
					const type = {
						value: checkValue._id,
						label: (<b>{`Daytime Reception`}</b>)
					}
					check.push(type);
					// }
					//
				}
				if (checkValue.name.trim() === 'Digital check-in') {
					// if (ids?.length === 0) {
					const type = {
						value: checkValue._id,
						label: (<b>{`Digital`}</b>)
					}
					check.push(type);
					// }
					// ids.push(propertyType._id);
				}


				if (checkValue.name.trim() === 'Key collected from different property') {
					const type = {
						value: checkValue._id,
						label: (<b>{`Key at Property`}</b>)
					}
					check.push(type);
				}

				if (checkValue.name.trim() === 'Meet & Greet') {
					const type = {
						value: checkValue._id,
						label: (<b>{`Meet & Greet`}</b>)
					}
					check.push(type);
				}

				if (checkValue.name.trim() === 'Self check-in at property') {
					const type = {
						value: checkValue._id,
						label: (<b>{`Self check-in`}</b>)
					}
					check.push(type);
				}


			});
			setCheckIn(check);
		}

	}, [getPropertyTypesSuccess, getCheckInProcessListSuccess]);

	useEffect(() => {

		const selectedProgram = getStorage('selectedProgramsOption')
		// const programmeId = getStorage('programmeId');

		// const selectedClient = getStorage('programmeData')?.clientId;
		if (selectedProgram) {
			const payload = {
				programmeId: selectedProgram?.value,
				RFPStatus: RfpProcessStatus.INPROGRESS,
				UserType: UserGroups.corporate_admin
			}

			getProcessByProgrammeIdAndStatus(payload);
		}

		if (getStorage('programmeId')) {
			const payload = {
				programmeId: getStorage('programmeId'),
				RFPStatus: RfpProcessStatus.INPROGRESS,
				UserType: UserGroups.corporate_admin
			}

			getProcessByProgrammeIdAndStatus(payload);
		}


		if (location?.state?.programmeId) {
			const payload = {
				programmeId: location?.state?.programmeId,
				RFPStatus: RfpProcessStatus.INPROGRESS,
				UserType: UserGroups.corporate_admin
			}

			getProcessByProgrammeIdAndStatus(payload);
		}


	}, [getProcessByProgrammeIdAndStatus, location?.state?.programmeId])

	useEffect(() => {
		if (rfpProcessByProgramIdAndStatusSuccess) {
			if (rfpProcessByProgramIdAndStatusSuccess.data.length !== 0) {
				let locations: any = [];
				let countries: any = [];
				const rfp = rfpProcessByProgramIdAndStatusSuccess.data[0];
				rfp?.locations.forEach((location) => {
					location?.cities?.forEach(element => {
						let data = {
							value: element.cityName,
							label: (
								<div className="flex">
									<img src={icon} className="mr-3" alt="" />{" "}
									<b>
										{element.cityName}
									</b>
								</div>
							),
						};
						locations.push(data);
					});

					let data = {
						value: location.countryCode,
						label: (
							<div className="flex">
								<img src={icon} className="mr-3" alt="" />{" "}
								<b>
									{location.countryName}
								</b>
							</div>
						),
					};

					countries.push(data);


				});
				setCountryOption(countries);
				setLocationOption(locations);
				setStorage(
					"selectedRFPProcess",
					rfpProcessByProgramIdAndStatusSuccess.data[0]
				);
				setRfpProcess(rfpProcessByProgramIdAndStatusSuccess.data[0]);
			}
		} else {

			if (!rfpProcess) {
				setRfpProcess(getStorage("selectedRFPProcess"));
			}
			let locations: any = [];
			let countries: any = [];
			rfpProcess?.locations.forEach((location) => {
				location?.cities?.forEach(element => {
					let data = {
						value: element.cityName,
						label: (
							<div className="flex">
								<img src={icon} className="mr-3" alt="" />{" "}
								<b>
									{element.cityName}
								</b>
							</div>
						),
					};
					locations.push(data);
				});

				let data = {
					value: location.countryCode,
					label: (
						<div className="flex">
							<img src={icon} className="mr-3" alt="" />{" "}
							<b>
								{location.countryName}
							</b>
						</div>
					),
				};
				countries.push(data);
			});

			setCountryOption(countries);
			setLocationOption(locations);


		}
	}, [rfpProcess, rfpProcessByProgramIdAndStatusSuccess]);



	useEffect(() => {
		if (rfpProcess) {

			// if (selectCountryCode) {
			const payload = {
				rfp_id: rfpProcess?._id,
				countryCode: selectCountryCode ? selectCountryCode : '',
				cityName: selectCityName ? selectCityName : '',
				checkIns: (checkIns) ? checkIns : '',
				propertyTypeIds: (propertyTypeIds) ? propertyTypeIds : '',
				// checkIn: '',
				// propertyType: '',
				// roomTypeId: '',
			};
			getCorporateProgrammePropertyByCity(payload);
			// }
		}
	}, [checkIns, getCorporateProgrammePropertyByCity, propertyTypeIds, rfpProcess, roomTypeIds, selectCityName, selectCountryCode]);


	const redirectProperty = useCallback(() => {
		if (rfpProcess) {

			// if (selectCountryCode) {
			const payload = {
				rfp_id: rfpProcess?._id,
				countryCode: selectCountryCode ? selectCountryCode : '',
				cityName: selectCityName ? selectCityName : '',
				checkIns: (checkIns) ? checkIns : '',

				propertyTypeIds: (propertyTypeIds) ? propertyTypeIds : '',
			};
			getCorporateProgrammePropertyByCity(payload);
			// }
		}
	}, [checkIns, getCorporateProgrammePropertyByCity, propertyTypeIds, rfpProcess, selectCityName, selectCountryCode]);


	useEffect(() => {

		if (acceptPropertyForRFPSuccess) {
			toast.success("Property status updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			redirectProperty();
		}

	}, [acceptPropertyForRFPSuccess, redirectProperty]);


	useEffect(() => {

		if (getCorporateProgrammePropertyByCitySuccess) {
			const operator = _.filter(getCorporateProgrammePropertyByCitySuccess?.data, list => ((list.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED)));
			setOperatorData(operator);
			const data = _.filter(getCorporateProgrammePropertyByCitySuccess?.data, list => ((list.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) || (list.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACTIVATED)));
			let filteredData: any;
			if (roomTypeIds.length !== 0) {
				filteredData = data.filter(item => {
					return item.property.inventories.some(inventory => roomTypeIds.includes(inventory.accommodationConfiguration));
				});
				setTableData(filteredData);
			} else if (status.length !== 0) {
				const data = _.filter(getCorporateProgrammePropertyByCitySuccess?.data, list => status.includes(list.evaluationStatus));
				setTableData(data);
			}
			else {
				setTableData(data);
			}
		}

	}, [getCorporateProgrammePropertyByCitySuccess, roomTypeIds, status, status.length]);



	const changeCityName = (value) => {
		setSelectCityName(value.value);
	}

	const changeCountryName = (value) => {
		setSelectCountryCode(value.value);
	}

	const renderProperty = useCallback(() => {
		if (propertyTypes) {

			return propertyTypes.map((at: any, key: number) => {
				return (
					<Row className="mt-3" key={key}>

						{/* <Col md={10}> */}
						<div style={{ marginLeft: '0%' }}>
							<Checkbox value={`${at.value}`}></Checkbox><span style={{ marginLeft: '15%' }}>{at.label}</span>
						</div>
						{/* </Col> */}
					</Row>
				);
			});
		}
	}, [propertyTypes]);

	const renderStatus = useCallback(() => {
		if (statusList) {

			return statusList.map((at: any, key: number) => {
				return (
					<Row className="mt-3" key={key}>

						{/* <Col md={10}> */}
						<div style={{ marginLeft: '0%' }}>
							<Checkbox value={`${at.value}`}></Checkbox><span style={{ marginLeft: '15%' }}>{at.label}</span>
						</div>
						{/* </Col> */}
					</Row>
				);
			});
		}
	}, []);


	const renderInventory = useCallback(() => {
		if (roomTypeList) {

			return roomTypeList.map((at: any, key: number) => {
				return (
					<Row className="mt-3" key={key}>

						{/* <Col md={10}> */}
						<div style={{ marginLeft: '0%' }}>
							<Checkbox value={`${at.value}`}></Checkbox><span style={{ marginLeft: '15%' }}>{at.label}</span>
						</div>
						{/* </Col> */}
					</Row>
				);
			});
		}
	}, [roomTypeList]);



	const renderCheckIn = useCallback(() => {
		if (checkIn) {

			return checkIn.map((at: any, key: number) => {
				return (
					<Row className="mt-3" key={key}>

						{/* <Col md={10}> */}
						<div style={{ marginLeft: '0%' }}>
							<Checkbox value={`${at.value}`}></Checkbox><span style={{ marginLeft: '15%' }}>{at.label}</span>
						</div>
						{/* </Col> */}
					</Row>
				);
			});
		}
	}, [checkIn]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onCheckPropertyChange = useCallback((list) => {
		setPropertyTypeIds(list);
	}, []);


	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onCheckInventoryChange = useCallback((list) => {
		setRoomTypeIds(list);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onCheckInChange = useCallback((list) => {
		setCheckIns(list);
	}, []);


	const onCheckStatusChange = useCallback((list) => {
		setStatus(list);
	}, []);

	const onRest = useCallback(() => {
		setCheckIns([]);
		setPropertyTypeIds([]);
		setRoomTypeIds([]);

		const data = ['activated', 'accepted'];
		setStatus(data);
	}, []);

	// const onUpdate = useCallback(() => {
	// 	if (rfpProcess) {

	// 		// if (selectCountryCode) {
	// 		const payload = {
	// 			rfp_id: rfpProcess?._id,
	// 			countryCode: selectCountryCode ? selectCountryCode : '',
	// 			cityName: selectCityName ? selectCityName : '',
	// 			checkIns: (checkIns) ? checkIns : '',
	// 			roomTypeIds: (roomTypeIds) ? roomTypeIds : '',
	// 			propertyTypeIds: (propertyTypeIds) ? propertyTypeIds : '',
	// 		};
	// 		getCorporateProgrammePropertyByCity(payload);
	// 		// }
	// 	}
	// }, [checkIns, getCorporateProgrammePropertyByCity, propertyTypeIds, rfpProcess, roomTypeIds, selectCityName, selectCountryCode]);

	const activateProperty = useCallback((data) => {
		const payload = {
			rfpId: rfpProcess?._id,
			brandId: data?.property?.brandId,
			propertyId: data?.propertyId,
			propertyStatus: BrandRfpPropertyEvaluationStatus.ACTIVATED,
			cityName: data?.property?.city,

		}
		acceptPropertyForRFP(payload);
	}, [acceptPropertyForRFP, rfpProcess?._id]);

	const archiveProperty = useCallback((data) => {
		const payload = {
			rfpId: rfpProcess?._id,
			brandId: data?.property?.brandId,
			propertyId: data?.propertyId,
			propertyStatus: BrandRfpPropertyEvaluationStatus.ARCHIVE,
			cityName: data?.property?.city,

		}
		acceptPropertyForRFP(payload);
	}, [acceptPropertyForRFP, rfpProcess?._id]);

	const onChangeTab = useCallback((tab) => {
		setTabKey(tab)

		if (tab === 'properties') {
			setStorage('viewData', false);
		}
	}, []);

	return (
		<>

			<div style={tabDivStyle}>
				<Tab.Container defaultActiveKey="properties" key={tabKey} activeKey={tabKey} onSelect={(tab) => onChangeTab(tab)}>
					<Row >
						<Col sm={2}>
							<Nav variant="pills" className="flex-column secondry-menu" style={navStyleNew}>
								<div style={{ marginTop: '0%' }}>
									<Nav.Item>
										<Nav.Link eventKey="properties">Properties</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="operators">Operators</Nav.Link>
									</Nav.Item>
									{/* <Nav.Item>
										<Nav.Link eventKey="companyLocations">Company Locations</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="locationRates">Location Rates</Nav.Link>
									</Nav.Item> */}
								</div>
							</Nav>
						</Col>


						<Col sm={10}>
							<Tab.Content style={{ position: getStorage('viewData') ? 'relative' : 'fixed' }}>
								<Tab.Pane eventKey="properties" >
									<div style={navContentStyle}>

										{<MyPropertiesHome activateProperty={activateProperty} archiveProperty={archiveProperty} tableData={tableData} rfpProcess={rfpProcess} changeCityName={changeCityName} changeCountryName={changeCountryName} countryOption={countryOption} locationOption={locationOption} programmeData={getStorage('programmeData')} location={location} />}
									</div>
								</Tab.Pane>

								<Tab.Pane eventKey="operators" >
									<div style={navContentStyle}>
										{<ProgrammeOperatorHome activateProperty={activateProperty} archiveProperty={archiveProperty} operatorData={operatorData} rfpProcess={rfpProcess} changeCityName={changeCityName} changeCountryName={changeCountryName} countryOption={countryOption} locationOption={locationOption} programmeData={getStorage('programmeData')} location={location} />}
									</div>
								</Tab.Pane>

								{/* <Tab.Pane eventKey="companyLocations" >
									<div style={navContentStyle}>

									</div>
								</Tab.Pane> */}
								{/* <Tab.Pane eventKey="locationRates" >
									<div style={navContentStyle}>

									</div>
								</Tab.Pane> */}
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</div>

			{(getStorage('viewData') === false) &&
				<div style={{
					marginTop: '165px', marginLeft: "-30px",
				}}>
					<Col sm={2}>
						<div className="col-sm-6" >
							<div className="" style={{
								marginTop: '2px',
								width: '240px',
								height: '175px',
								borderRadius: '44px', opacity: '0px', borderColor: '#2DBDB6', borderWidth: '2px', boxShadow: '0px 3px 10px #2DBDB6'
								// position:'fixed', zIndex:999
							}} >
								<div className="text-left mt-3 ml-5" >
									<div style={{ marginLeft: '0px', fontWeight: 700, fontSize: '14px' }}>Status</div>

									<Row className='formSpace'>

										<Col md={8} style={{ textAlign: 'left', marginTop: '-30px' }}>

											{
												<Controller
													name="status"
													control={control}
													rules={{
														required: true,
													}}
													render={({ field }) =>
														<Checkbox.Group style={{ width: '150%' }} onChange={(value) => {
															onCheckStatusChange(value);
															field.onChange(value);
														}}
															value={status}
														>
															{renderStatus()}
														</Checkbox.Group>
													}
												/>}

										</Col>
									</Row>
								</div>
							</div>
						</div>
						<div className="col-sm-6" >
							<div className="" style={{
								marginTop: '20px',
								width: '240px',
								height: '215px',
								borderRadius: '44px', opacity: '0px', borderColor: '#2DBDB6', borderWidth: '2px', boxShadow: '0px 3px 10px #2DBDB6'
							}}>
								<div className="text-left mt-3 ml-5" >
									<div style={{ marginLeft: '0px', fontWeight: 700, fontSize: '14px' }}>Property</div>

									<Row className='formSpace'>

										<Col md={8} style={{ textAlign: 'left', marginTop: '-30px' }}>

											{
												<Controller
													name="property"
													control={control}
													rules={{
														required: true,
													}}
													render={({ field }) =>
														<Checkbox.Group style={{ width: '150%' }} onChange={(value) => {
															onCheckPropertyChange(value);
															field.onChange(value);
														}}
															value={propertyTypeIds}
														>
															{renderProperty()}
														</Checkbox.Group>
													}
												/>}

										</Col>
									</Row>
								</div>
							</div>
						</div>

						<div className="col-sm-6" >
							<div className="" style={{
								marginTop: '20px',
								width: '240px',
								height: '250px',
								borderRadius: '44px', opacity: '0px', borderColor: '#2DBDB6', borderWidth: '2px', boxShadow: '0px 3px 10px #2DBDB6'

							}} >
								<div className="text-left mt-3 ml-5" >
									<div style={{ marginLeft: '0px', fontWeight: 700, fontSize: '14px' }}>Inventory</div>

									<Row className='formSpace'>

										<Col md={8} style={{ textAlign: 'left', marginTop: '-30px' }}>

											{
												<Controller
													name="inventory"
													control={control}
													rules={{
														required: true,
													}}
													render={({ field }) =>
														<Checkbox.Group style={{ width: '150%' }} onChange={(value) => {
															onCheckInventoryChange(value);
															field.onChange(value);
														}}
															value={roomTypeIds}
														>
															{renderInventory()}
														</Checkbox.Group>
													}
												/>}

										</Col>
									</Row>
								</div>
							</div>
						</div>


						<div className="col-sm-6" >
							<div className="" style={{
								marginTop: '20px',
								width: '240px',
								height: '290px',
								borderRadius: '44px', opacity: '0px', borderColor: '#2DBDB6', borderWidth: '2px', boxShadow: '0px 3px 10px #2DBDB6'
							}}>
								<div className="text-left mt-3 ml-5" >
									<div style={{ marginLeft: '0px', fontWeight: 700, fontSize: '14px' }}>Check-in</div>

									<Row className='formSpace'>

										<Col md={8} style={{ textAlign: 'left', marginTop: '-30px' }}>

											{
												<Controller
													name="checkIn"
													control={control}
													rules={{
														required: true,
													}}
													render={({ field }) =>
														<Checkbox.Group style={{ width: '150%' }} onChange={(value) => {
															onCheckInChange(value);
															field.onChange(value);
														}}
															value={checkIns}
														>
															{renderCheckIn()}
														</Checkbox.Group>
													}
												/>}

										</Col>
									</Row>
								</div>
							</div>
						</div>


						{/* <div className="col-sm-6 mt-5"  >

					<Button
						type="submit"
						id="approve-button"
						style={formButton3}
						onClick={onUpdate}
					>
						Update
					</Button>

				</div> */}
						<div className="col-sm-6  mt-5" >

							<Button
								// type="submit"
								id="edit-button"
								style={formButton3}
								onClick={onRest}
							>
								Reset
							</Button>

						</div>

					</Col>

					<Col sm={10}>
					</Col>

				</div>}
		</>
	)
};

export default ProgrammeHomePage;