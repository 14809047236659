import { Select, Switch, Tooltip as ToolAntd } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import ImageUpload from '../../../common/imageUpload';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { canBrandPropertyModuleWrite } from '../../../../common/functions';
import { UserAccess } from '../../../../common/constants';
import { Controller, useForm } from 'react-hook-form';
import { PropertyImageFormInput } from '../../../../common/interfaces';
import { Col, Container, Form, Row, Button, Spinner, Image, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formButton, overlayTriggerDelay, Settings, slideImageStyle } from '../../../../common/components-style';
import { toast } from 'react-toastify';
import Slider from "react-slick";
import Gallery from 'react-grid-gallery';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
import Fancybox from '../../../common/fancyBox';

const { Option } = Select;

const BuildingImages: React.FC<any> = ({ allInOnePropertyBreadCrumb, propertyId, setFormOpen, brandId, selectedBrand }): JSX.Element => {
	///////////////////// initialize form ////////////////////////////////////// 
	const { register, setValue, reset, handleSubmit, formState: { errors }, control } = useForm<PropertyImageFormInput>();

	///////////////////////// local state manage ////////////////////////////////////
	const [editEnable, setEditEnable] = useState<boolean>(true);
	const [updateEnable, setUpdateEnable] = useState<boolean>(false);
	const [propertyData, setPropertyData] = useState<any>();
	const [imageData, setImageData] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);
	const [imageList, setImageList] = useState<any>([]);
	const [image, setImage] = useState<any>();
	const [imageUpdated, setImageUpdated] = useState(false);
	const [tagsList, setTagList] = useState<Array<Object>>([]);
	const [tags, setTags] = useState<Array<Object>>([]);
	const [tagsSelect, setTagsSelect] = useState<any>();
	const [imageId, setImageId] = useState<string>();
	const [status, setStatus] = useState<string>('active');
	const [checkValue, setCheckValue] = useState<boolean>(true);
	const [show, setShow] = useState(false);
	const [imageVisible, setImageVisible] = useState(true);
	const [imagesGallery, setImagesGallery] = useState<Array<any>>([]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { uploadPropertyImage, getPropertyImage, resetPropertyImage, getPropertyImageTag, getProperty, propertyPrimaryImage, tab } = useStoreActions<any>((actions) => ({
		uploadPropertyImage: actions.property.uploadPropertyImage,
		getPropertyImage: actions.property.getPropertyImage,
		resetPropertyImage: actions.property.resetPropertyImage,
		getPropertyImageTag: actions.property.getPropertyImageTag,
		getProperty: actions.property.getProperty,
		propertyPrimaryImage: actions.property.propertyPrimaryImage,

	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { getPropertyPrimaryImageError, getPropertyPrimaryImageSuccess, uploadPropertyImageSuccess, uploadPropertyImageError, getPropertyImageSuccess, getPropertyImageError, updatePropertyImageSuccess, removePropertyImageSuccess, getPropertyImageTagSuccess, getPropertyImageTagError, getPropertySuccess } = useStoreState<any>((state) => ({
		uploadPropertyImageSuccess: state.property.uploadPropertyImageSuccess,
		uploadPropertyImageError: state.property.uploadPropertyImageError,
		getPropertyImageSuccess: state.property.getPropertyImageSuccess,
		getPropertyImageError: state.property.getPropertyImageError,
		updatePropertyImageSuccess: state.property.updatePropertyImageSuccess,
		removePropertyImageSuccess: state.property.removePropertyImageSuccess,
		getPropertyImageTagSuccess: state.property.getPropertyImageTagSuccess,
		getPropertyImageTagError: state.property.getPropertyImageTagError,
		getPropertySuccess: state.property.getPropertySuccess,
		getPropertyPrimaryImageSuccess: state.property.getPropertyPrimaryImageSuccess,
		getPropertyPrimaryImageError: state.property.getPropertyPrimaryImageError
	}));

	////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (propertyId) {
			getPropertyImage(propertyId);
			getProperty(propertyId);
		}

	}, [getProperty, getPropertyImage, propertyId]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getPropertyImageSuccess) {
			if (getPropertyImageSuccess.data.length !== 0) {
				setImageData(getPropertyImageSuccess.data[0].image);
				const properties = (getPropertyImageSuccess.data[0]?.moderateImage && getPropertyImageSuccess.data[0]?.moderateImage?.length !== 0) ? getPropertyImageSuccess.data[0]?.moderateImage : getPropertyImageSuccess.data[0].image;
				const imageListTemp: Array<Object> = [];
				const imageGallery: Array<Object> = [];
				_.map(properties, (element: any) => {
					imageListTemp.push(element);
					imageGallery.push(
						{
							id: element._id,
							src: element.url,
							thumbnail: element.url,
							thumbnailWidth: 300,
							thumbnailHeight: 200,
							caption: element.caption
						}
					);
				}
				);
				setImageList(imageListTemp);
				setImagesGallery(imageGallery);
			}
		}
		if (getPropertyImageError) {
			toast.error(getPropertyImageError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (getPropertySuccess) {
			setPropertyData(getPropertySuccess.data);
			// setTitle(getPropertySuccess.data?.propertyName);
		}

		if (getPropertyPrimaryImageSuccess) {
			toast.success(`Primary image set successfully`, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			getPropertyImage(propertyId);
		}

		if (getPropertyPrimaryImageError) {
			toast.error(getPropertyImageError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

	}, [getPropertyImage, getPropertyImageError, getPropertyImageSuccess, getPropertyPrimaryImageError, getPropertyPrimaryImageSuccess, getPropertySuccess, propertyId]);

	////////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (brandId) {
			getPropertyImageTag(brandId);
		}

	}, [brandId, getPropertyImageTag]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getPropertyImageTagSuccess) {
			const tagList = getPropertyImageTagSuccess.data;
			if (tagList) {
				setTags(tagList.tags);
			}
		}

		if (getPropertyImageTagError) {
			toast.error(getPropertyImageTagError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
	}, [getPropertyImageTagError, getPropertyImageTagSuccess, tags]);

	////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (uploadPropertyImageSuccess) {
			setLoading(false);
			setImageVisible(false);
			toast.success('Property Image uploaded successfully', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetPropertyImage();
			getPropertyImage(uploadPropertyImageSuccess.data.propertyId);
			reset();
			setTagsSelect([]);
			setUpdateEnable(false);
			setImageId('');
			setImageUpdated(false);
		}

		if (updatePropertyImageSuccess) {
			setLoading(false);
			setImageVisible(false);
			toast.success('Property Image updated successfully', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			reset();
			setTagsSelect([]);
			setUpdateEnable(false);
			resetPropertyImage();
			getPropertyImage(updatePropertyImageSuccess.data.propertyId);
			setImageUpdated(false);
		}

		if (removePropertyImageSuccess) {
			setLoading(false);
			setImageVisible(false);
			// toast.success('Property Image removed successfully', {
			// 	position: toast.POSITION.BOTTOM_RIGHT,
			// 	className: 'foo-bar'
			// });
			resetPropertyImage();
			getPropertyImage(removePropertyImageSuccess.data.propertyId);
			reset();
			setTagsSelect([]);
			setUpdateEnable(false);
			setImageId('');
			setImageUpdated(false);
		}
		if (uploadPropertyImageError) {
			setLoading(false);
			toast.error(uploadPropertyImageError, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
	}, [uploadPropertyImageSuccess, uploadPropertyImageError, resetPropertyImage, removePropertyImageSuccess, updatePropertyImageSuccess, getPropertyImage, reset, tagsSelect, image, setValue]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const getImageBlob = useCallback((imageBlob) => {
		setImage(imageBlob);
		setValue('image', imageBlob);
		setImageUpdated(true);
		setImageVisible(true);
	}, [setValue]);

	//////////////////////////////////////////////////////////////////////////////////////////////
	const tagChange = useCallback((value) => {
		setTagList(value);
		setTagsSelect(value);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const statusChange = useCallback((value) => {
		setCheckValue(value);
		if (value) {
			setStatus('active');
		}
		else {
			setStatus('inactive');
		}
	}, []);

	//////////////////////////////////////////////////////////////////////////////////////////////////
	const imageClick = useCallback((value) => {
		reset();
		setTagsSelect([]);
		setUpdateEnable(true);
		setCheckValue((value.status === 'active') ? true : false);
		setImageId(value._id);
		setStatus(value.status);
		setValue('caption', value.caption);
		setValue('description', value.description);
		setValue('image', value.url);
		setValue('tags', value.tags);
		setTagsSelect(value.tags)
		setValue('status', checkValue);
		setImageVisible(true);
	}, [checkValue, reset, setValue]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	const removeImage = useCallback((value) => {
		const data = {
			propertyId: propertyId,
			imageId: value._id,
			isDeleted: true,
			brandId: brandId,
		}
		setLoading(true);
		uploadPropertyImage(data);
	}, [brandId, propertyId, uploadPropertyImage]);

	////////////////////////////////////////////////////////////////////////////////////////////////////

	const renderTag = useCallback(() => {
		if (tags) {
			return tags.map((tg: any, key: number) => {
				return (
					<Option key={key} value={`${tg}`} >{tg}</Option>
				)
			});
		}
	}, [tags]);

	const changeFormEditable = useCallback(() => {
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////

	const onFinish = useCallback((values) => {
		setLoading(true);
		if (imageId) {
			values.imageId = imageId;
			const data = {
				propertyId: propertyId,
				brandId: brandId,
				imageId: imageId,
				image: imageUpdated ? image : values.image,
				tags: values.tags,
				caption: values.caption,
				description: values.description,
				isDeleted: false,
				status: values.status ? status : 'inactive'
			}
			uploadPropertyImage(data)

		} else {
			values.propertyId = propertyId;
			values.imageId = '';
			values.image = image;
			values.tags = tagsList;
			values.status = status;
			values.brandId = brandId;
			uploadPropertyImage(values)
		}

	}, [brandId, image, imageId, imageUpdated, propertyId, status, tagsList, uploadPropertyImage]);

	const handleCloseHide = (value) => setShow(value);

	///////////////////////////////////////////////////////////////////////////////////////////////////////

	const setPrimaryImageToDisplay = useCallback((image) => {

		const payload = {
			propertyId: propertyId,
			imageId: image?._id
		};

		propertyPrimaryImage(payload);
	}, [propertyId, propertyPrimaryImage]);

	return (

		<>
		<div className="content-title fixed_title">
			{
				// title && <Row>
				// 	<Col xs={12}><div className="content-title">{title} - {propertyData?.city}</div></Col>
				// </Row>

				<BreadCrumbPage tab={tab} allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} isPageName="Property" selectedBrand={selectedBrand} propertyData={propertyData} setFormOpen={setFormOpen} ></BreadCrumbPage>
			}
			</div>
			{
				canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) &&
				<div>
					<div className="jumbotron">
						<div style={{ fontSize: 22, marginBottom: '3%' }}>
							{/* <span style={{ color: '#000000', fontWeight: 'bold' }}>Note : </span>  */}
							<span className='requiredMark'>Please upload at least one property image. The first image must be of the frontal exterior of the building</span>
						</div>
						<Form onSubmit={handleSubmit(onFinish)}>
							<Row>
								<Col md={1}></Col>
								<Col md={7}>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<OverlayTrigger
												placement="top"
												delay={overlayTriggerDelay}
												overlay={<Tooltip id='tooltip-table-top'>A short factual description that will appear below the image e.g. external building image or communal reception area, max 3 words.</Tooltip>}
											>
												<Form.Label>{editEnable && <span className='requiredMark'>*</span>}Caption</Form.Label>
											</OverlayTrigger>

										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{imageData?.caption}</p>}
											{editEnable &&
												<OverlayTrigger
													placement="top"
													delay={overlayTriggerDelay}
													overlay={<Tooltip id='tooltip-table-top'>A short factual description that will appear below the image e.g. external building image or communal reception area, max 3 words.</Tooltip>}
												>
													<Form.Control className={`${errors.caption ? 'is-invalid' : ''}`} {...register("caption", { required: true })} />
												</OverlayTrigger>

											}
											{editEnable && <div className="invalid-feedback">Please input caption!</div>}
										</Col>
									</Row>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<OverlayTrigger
												placement="top"
												delay={overlayTriggerDelay}
												overlay={<Tooltip id='tooltip-table-top'>Short overview of the subject/focus of the image</Tooltip>}
											>
												<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Description</Form.Label>
											</OverlayTrigger>

										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{imageData?.description}</p>}
											{editEnable &&
												<OverlayTrigger
													placement="top"
													delay={overlayTriggerDelay}
													overlay={<Tooltip id='tooltip-table-top'>Short overview of the subject/focus of the image</Tooltip>}
												>
													<Form.Control as="textarea" rows={5} className={`${errors.description ? 'is-invalid' : ''}`} {...register("description", { required: true })} />
												</OverlayTrigger>

											}
											{editEnable && <div className="invalid-feedback">Please input description!</div>}
										</Col>
									</Row>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Image</Form.Label>
										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <Image width={100} src={image} thumbnail={true} />}
											{editEnable &&
												<Controller
													name="image"
													control={control}
													rules={{
														required: image ? false : true
													}}
													render={({ field }) =>
														<ImageUpload crop={false} getImageBlob={getImageBlob} type="image" value={field.value} disabled={false} visible={imageVisible} />
													}
												/>}

											{editEnable && errors.image && <div className="invalid-feedback-custom">Please select image!</div>}
										</Col>
									</Row>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<OverlayTrigger
												placement="top"
												delay={overlayTriggerDelay}
												overlay={<Tooltip id='tooltip-table-top'>Tags are used to aid search. Select an existing tag or create your own to help categorise the image</Tooltip>}
											>
												<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Tags</Form.Label>
											</OverlayTrigger>

										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{imageData?.tags}</p>}
											{editEnable &&
												<Controller
													name="tags"
													defaultValue={tagsSelect}
													control={control}
													rules={{
														required: true,
													}}
													render={({ field }) =>
														<ToolAntd color={`#000000`} placement="top" title={`Tags are used to aid search. Select an existing tag or create your own to help categorise the image`}>
															{
																tagsSelect ? <Select key={tagsSelect ? `tags${String(tagsSelect)}` : 'empty'} mode="tags" style={{ width: '100%' }} value={tagsSelect} defaultValue={tagsSelect} onChange={(value) => {
																	tagChange(value);
																	field.onChange(value);
																}}>
																	{renderTag()}
																</Select> : <Select mode="tags" style={{ width: '100%' }} onChange={(value) => {
																	tagChange(value);
																	field.onChange(value);
																}}>
																	{renderTag()}
																</Select>
															}
														</ToolAntd>
													}
												/>}
											{editEnable && errors.tags && <div className="invalid-feedback-custom">Please input tags!</div>}
										</Col>
									</Row>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Status</Form.Label>
										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{imageData?.status}</p>}
											{editEnable &&
												<Controller
													name="status"
													defaultValue={imageData?.status}
													control={control}
													render={({ field }) =>
														<Switch size="small" defaultChecked={checkValue} checked={checkValue} onChange={(value) => {
															statusChange(value);
															field.onChange(value);
														}} />
													}
												/>}
										</Col>
									</Row>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!loading && !updateEnable && editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) &&
												<Button type="submit" id='approve-button' style={formButton}>Create</Button>
											}
											{!loading && updateEnable && editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) &&
												<Button type="submit" id='approve-button' style={formButton}>Update</Button>
											}
											{
												!loading && !editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) && <Button id='edit-button' style={formButton} onClick={changeFormEditable} >Edit</Button>
											}
											{
												loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
											}
										</Col>
									</Row>
								</Col>
								<Col md={4}></Col>
							</Row>
						</Form>
					</div>
				</div>
			}

			{canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) && (imageList.length > 0) &&
				<Container>
					<div className="jumbotron Fancy_wrapper">
						<p className='mb-4 requiredMark' style={{ fontSize: '20px' }}>Please choose a primary image. This most be a frontal view of the property</p>
						<Row>
							<Slider {...Settings}>
								{imageList.map((img: any, index) => (
									<>
										<div key={index} className='relative text-center text-white'>
											<Image width={300} src={img.url} alt="avatar" thumbnail={true} style={slideImageStyle}>
											</Image>
											<div className='inset' >
												<EditOutlined   className='p-1 ' style={{ color: '#E7008C', fontSize:'40px'}} onClick={() => imageClick(img)} />
												<DeleteOutlined style={{ color: '#E7008C', fontSize:'40px' }} onClick={() => removeImage(img)} />
											</div>
										</div>
										<div className='d-flex justify-content-center mt-3 mb-3' >
											<Form.Check
												name="image"
												type={`radio`}
												checked={img?.primaryImage}
												onChange={() => {
													setPrimaryImageToDisplay(img);
												}}
											/>
										</div>
									</>
								))}
							</Slider>
						</Row>
						<div className='mt-5'>
							<Fancybox>
								<p>
									{
										imageList[0] ?
											(
												<a
													className="fancyBtn"
													data-fancybox="gallery"
													href={imageList[0]?.url}
												>
													<img alt="Property Images" src={imageList[0]?.url} style={{
														display: "none"
													}} />
													Preview
												</a>
											) : (
												<a
													className="fancyBtn"
													data-fancybox="gallery"
													href={imageList[0]?.url}
												>
													<img alt="Property Images" src={imageList[0]?.url} style={{
														display: "none"
													}} />
													Preview
												</a>
											)
									}
									{imageList?.map((curElemImg, index) => (
										<>
											{index !== 0 && (
												<a
													key={index}
													data-fancybox="gallery"
													href={curElemImg.url}
													style={{ display: "none" }}
												>
													<img alt="Inventary Images" src={curElemImg.url} />
												</a>
											)}
										</>
									))}
								</p>
							</Fancybox>
						</div>

						{/* <Row>
              <div style={{ textAlign: 'center', marginTop: '25px' }}>
                <Button id='edit-button' style={formButton} onClick={() => { handleCloseHide(true) }} ><b>Preview</b></Button>
              </div>
            </Row> */}
					</div>
					<Modal size='xl' show={show} onHide={() => { handleCloseHide(false) }}>
						<Modal.Header closeButton>
							<Modal.Title>Preview</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Gallery images={imagesGallery} />
						</Modal.Body>
					</Modal>
				</Container>
			}
			{
				!canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) && <>
					<Container>
						<Gallery margin={20} images={imagesGallery} />
					</Container>
				</>
			}
		</>

	)
};

export default BuildingImages;
