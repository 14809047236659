import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from "react-router-dom";

import DataTable from 'react-data-table-component';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { REQUEST_BOOKING_STATUS, UserGroups } from '../../../common/constants';
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import { tableStyles, $mysaMegenta, $mysaYellow, $mysaRed, $mysaTeal } from '../../../common/components-style';
import moment from 'moment';
import { setStorage } from "../../../common/storage";
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { getLoggedUserType, getUserAccess } from '../../../common/functions';

const RequestBookingList: React.FC<any> = ({ bkgHandler, booking }): JSX.Element => {
    const history = useHistory();
    const [requestBookingList, setRequestBookingList] = useState<any>();

    const { getRequestBookingListByBrandSuccess } = useStoreState<any>((state) => ({
        getRequestBookingListByBrandSuccess: state.booking.getRequestBookingListByBrandSuccess
    }));


    const {getRequestBookingListByBrand } = useStoreActions<any>((actions) => ({
		getRequestBookingListByBrand: actions.booking.getRequestBookingListByBrand
	}));
    

    useEffect(() => {
		const brands = getUserAccess("brand");
		if (brands?.length > 0) {
			const myoSelectedBrand = brands[0]?.brandId;
			if (myoSelectedBrand) {
			
				if (getLoggedUserType() !== UserGroups.super_admin) {
					getRequestBookingListByBrand({ brandId: myoSelectedBrand })
				}
			}
		}
	}, [getRequestBookingListByBrand]);

    useEffect(() => {
  

        if (getRequestBookingListByBrandSuccess) {
          setRequestBookingList(getRequestBookingListByBrandSuccess.data)
        }
    }, [getRequestBookingListByBrandSuccess]);

    const handleOpen = useCallback((data) => {
        setTimeout(() => {
            history.push({
                pathname: `/admin/external-booker-management`,
                state: data,
            });
        }, 500);

        // updateBrandRFPData({ ...reduxBrandDetail, showHomePage: false, myRfpFormDetail: data, formData: data, showMyRfpBrandEvaluationPage: true, showMyRfpHomePage: false });
    }, [history]);

    const columns = [
        {
            name: '',
            cell: (row: any) => {

                    return (<>
                        <a
                          href={`${process.env.REACT_APP_MYO_CORPORATE_URL}admin/external-booker-management`}
                        >
                          <img className="iconSizes" src={ViewEdit} alt="" />
                        </a>
                    </>);
                }
        },
        {
            name: 'Client',
            cell: (row) => row.clientName
        },
        {
            name: 'Property',
            selector: row => row.property?.propertyName,
        },
        {
            name: 'City',
            selector: row => row.location?.locationName,
        },
        {
			name: 'Received',
			selector: row => moment(row.requestedDate).format('D MMM YYYY'),
		},
		{
			name: 'Time',
			selector: row => moment(row.requestedDate).format('h:mm'),
		},
    	{
			name: 'Status',
			cell: (row) => {
				let color = "";
				if (row.requestStatus === REQUEST_BOOKING_STATUS.PENDING) color = $mysaYellow;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.NOT_AVAILABLE) color = $mysaRed;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.AVAILABLE) color = $mysaTeal;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.OFFERED) color = $mysaMegenta;
				if (row.requestStatus === REQUEST_BOOKING_STATUS.DECLINED) color = $mysaRed;

				return (
					<span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.requestStatus}</span>
				)
			}
		},
    ];

    return (
        <>
            <div className='tbl_accr_itm' style={{ backgroundColor: "#FBC91D" }}>
                <div className='tbl_hdr' style={{ backgroundColor: "#FBC91D" }}>
                    <h5 className='m-0 fw-bold text-white'>Request Bookings</h5>
                    <button className='text-white pointer' onClick={bkgHandler}>Expand
                        <span>
                        <img src={booking ? minus : plus} alt="icon" />
                        </span>
                    </button>
                </div>
                <div className='acrd_tbl'>
                    <DataTable
                        columns={columns}
                        data={requestBookingList}
                        striped={true}
                        customStyles={tableStyles}
                        theme='mysaTable'
                        fixedHeader={true}
                        noDataComponent={'Updating records'}
                    />
                </div>
            </div>
        </>
    )
}

export default RequestBookingList;