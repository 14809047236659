import { message, DatePicker } from 'antd';
import Select from 'react-select'
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import { Container, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";

import BookMarkIcon from '../../../../images/icons/svg/bookmark.svg';
import CalenderIcon from '../../../../images/icons/svg/calender.svg';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';

import searchIcon from "../../../../images/icons/svg/outside/search.svg";
import FilterIcon from "../../../../images/icons/svg/filter.svg";
import { getStorage, setStorage } from '../../../../common/storage';
import { BookMarkStyles, RoomTypeStyles, searchButton } from '../../../../common/components-style';
import { BookingForm } from '../../../../common/interfaces';

const { RangePicker } = DatePicker;

const MyoCorporatePage: React.FC<any> = ({ programmeId, loadData, myoParams }): JSX.Element => {
    const { formState: { errors }, control, watch, handleSubmit } = useForm<BookingForm>();
    const [locationList, setLocationList] = useState<any>([]);
    const minDate = moment(new Date());
    const [checkInDate, setCheckInDate] = useState<any>();
    const [checkOutDate, setCheckOutDate] = useState<any>();
    const [cityList, setCityList] = useState<any>([]);
    const [cityName, setCityName] = useState<any>();
    const [countryCode, SetCountryCode] = useState<any>();
    const [countryName, SetCountryName] = useState<any>();
    const [countryList, setCountryList] = useState<any>([]);
    const [loading] = useState<boolean>(false);
    const [roomTypeList, setRoomTypeList] = useState<any>([]);
    const [roomType, setRoomType] = useState<any>();

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getClientLocationByProgrammeId,
        getAllPropertyCities,
        getAllPropertyCountries,
        getRoomTypeList
    } = useStoreActions<any>((actions) => ({
        getClientLocationByProgrammeId: actions.client.getClientLocationByProgrammeId,
        getAllPropertyCities: actions.property.getAllPropertyCities,
        getAllPropertyCountries: actions.property.getAllPropertyCountries,
        getRoomTypeList: actions.inventory.getRoomTypeList,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getClientLocationByProgrammeIdSuccess,
        getClientLocationByProgrammeIdError,
        getAllPropertyCitiesSuccess,
        getAllPropertyCitiesError,
        getAllPropertyCountriesSuccess,
        getAllPropertyCountriesError,
        getRoomTypeListSuccess,
        getRoomTypeListError
    } = useStoreState<any>((state) => ({
        getClientLocationByProgrammeIdSuccess: state.client.getClientLocationByProgrammeIdSuccess,
        getClientLocationByProgrammeIdError: state.client.getClientLocationByProgrammeIdError,
        getAllPropertyCitiesSuccess: state.property.getAllPropertyCitiesSuccess,
        getAllPropertyCitiesError: state.property.getAllPropertyCitiesError,
        getAllPropertyCountriesSuccess: state.property.getAllPropertyCountriesSuccess,
        getAllPropertyCountriesError: state.property.getAllPropertyCountriesError,
        getRoomTypeListSuccess: state.inventory.getRoomTypeListSuccess,
        getRoomTypeListError: state.inventory.getRoomTypeListError,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        getAllPropertyCountries();
    }, [getAllPropertyCountries]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        getRoomTypeList();
    }, [getRoomTypeList]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (programmeId) {
            getClientLocationByProgrammeId(programmeId);
            setLocationList([]);
        }
    }, [programmeId, getClientLocationByProgrammeId]);

    //////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getRoomTypeListSuccess) {
            const roomsConf = [{ label: "All", value: 0 },];
            for (const rooms of getRoomTypeListSuccess.data) {
                if (rooms.name.trim() !== `Studio/Open plan apartment`) {
                    const room = {
                        label: rooms.name,
                        value: rooms.name.trim() === 'One bedroom' ? 1 : rooms.name.trim() === 'Two bedroom' ? 2 : rooms.name.trim() === 'Three bedroom' ? 3 : rooms.name.trim() === 'Studio/Open plan apartment' ? 100 : 4
                    };
                    roomsConf.push(room);
                }
            }

            const roomList = roomsConf.sort((a, b) => {
                if (a.value < b.value) {
                    return -1;
                }
                if (a.value > b.value) {
                    return 1;
                }
                return 0;
            });
            setRoomTypeList(roomList);
        }
        if (getRoomTypeListError) {
            message.error(getRoomTypeListError.message);
        }
    }, [getRoomTypeList, getRoomTypeListError, getRoomTypeListSuccess]);

    ///////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getClientLocationByProgrammeIdSuccess) {
            if (getClientLocationByProgrammeIdSuccess.data === null) {
                message.warning("No Data Found");
            } else {
                const location = getClientLocationByProgrammeIdSuccess.data;
                const locationListTemp: any = locationList;
                _.map(location, (element) =>
                    locationListTemp.push(element)
                );
                setLocationList(locationListTemp);
                locationList.push({ _id: null, locationName: '- Select other location -' });
            }
        }
        if (getClientLocationByProgrammeIdError) {
            message.error(getClientLocationByProgrammeIdError.message);
        }
    }, [getClientLocationByProgrammeIdSuccess, getClientLocationByProgrammeIdError, programmeId, locationList]);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getAllPropertyCountriesSuccess) {
            const country = getAllPropertyCountriesSuccess.data.sort((a, b) => {
                if (a._id.country < b._id.country) {
                    return -1;
                }
                if (a._id.country > b._id.country) {
                    return 1;
                }
                return 0;
            });
            setCountryList(country);

        }
        if (getAllPropertyCountriesError) {
            toast.error(getAllPropertyCountriesError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [getAllPropertyCountriesError, getAllPropertyCountriesSuccess]);


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getAllPropertyCitiesSuccess) {
            const city = getAllPropertyCitiesSuccess.data.sort((a, b) => {
                if (a._id < b._id) {
                    return -1;
                }
                if (a._id > b._id) {
                    return 1;
                }
                return 0;
            })
            setCityList(city);

        }
        if (getAllPropertyCitiesError) {
            toast.error(getAllPropertyCitiesError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [getAllPropertyCitiesError, getAllPropertyCitiesSuccess]);


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onAccommodationChange = useCallback((acc) => {
        if (acc) {
            if (acc.value === 100) {
                acc.value = 1;
            }

            setRoomType(acc);
        }
    }, []);


    const renderCity = useCallback(() => {
        if (cityList) {
            return cityList.map((acc: any, key: number) => {
                return ({
                    key: key,
                    value: `${acc._id}`,
                    label: acc._id,
                    city: acc
                })
            });
        }

    }, [cityList]);

    const renderCountry = useCallback(() => {
        if (countryList) {
            return countryList.map((acc: any, key: number) => {
                return ({
                    key: key,
                    value: `${acc._id?.countryCode}`,
                    label: acc._id?.country,
                    country: acc._id
                })
            });
        }
    }, [countryList]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onFromDateChange = useCallback((dates, dateStrings) => {
        setCheckInDate(moment(new Date(dateStrings[0])));
        setCheckOutDate(moment(new Date(dateStrings[1])));
        setStorage('makeBookingCheckInDate', moment(new Date(dateStrings[0])));
        setStorage('makeBookingCheckOutDate', moment(new Date(dateStrings[1])));

    }, []);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    const disabledFromDate = useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            (minDate != null && !d.isAfter(minDate) && d.isBefore(minDate, 'day'))
        );
    }, [minDate]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onselectCompanyLocation = useCallback(() => {
        // setIsManualBooking(false);
        const params: any = {};
        params.isKeyLocation = true;
        loadData(params);
    }, [loadData]);


    const changeHandler = useCallback((val) => {
        SetCountryName(val.country);
        if (val) {
            SetCountryCode(val.value);
            if (val.value) {
                getAllPropertyCities(val.value);
            }
        }
    }, [getAllPropertyCities]);


    const onCityChange = useCallback((val) => {
        setCityName(val.value);
    }, [])



    const onSearchResult = useCallback((value) => {
        const params: any = {};
        const date: any = watch('fromDate');
        if (cityName) {
            params.placeId = cityName;
            params.checkInDate = date[0] ? moment(date[0]).format('YYYY-MM-DD') : moment(checkInDate).format('YYYY-MM-DD');
            params.checkOutDate = date[1] ? moment(date[1]).format('YYYY-MM-DD') : moment(checkOutDate).format('YYYY-MM-DD');
            params.countryCode = countryCode;
            params.center_latitude = 0;
            params.center_longitude = 0;
            params.myoCorporate = true;
            params.country = countryName;
            params.roomType = roomType
            setStorage('makeBookingProgramSearch', params);
        }
        loadData(params);
    }, [checkInDate, checkOutDate, cityName, countryCode, countryName, loadData, roomType, watch]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div className="booking-form text-center">

            <Container style={{ maxWidth: '1675px' }} >
                <div >
                    <Row
                        //  style={{
                        // 	position: 'fixed',
                        // 	left: '6vw',
                        // 	top: '15vh',
                        // 	width: '100%',
                        // 	paddingTop: '5vh',
                        // 	paddingLeft: '1.5vw',
                        // 	zIndex: 20,
                        // 	backgroundColor: '#fff',
                        // }}
                        style={{
                            // position: "fixed",
                            // left: "6.9vw",
                            top: "70px",
                            width: "100%",
                            padding: '5vh 1.5vw',
                            // zIndex: 20,
                            backgroundColor: "#fff",
                            marginLeft: '-80px'
                        }}

                    >
                        <Form onSubmit={handleSubmit(onSearchResult)} >

                            {/* <Row>
								<p style={{ fontWeight: 'bold', fontSize: '32px', textAlign: 'left' }}>Welcome to the booking portal for {getStorage('programmeData')?.programmeName ? getStorage('programmeData')?.programmeNam : getStorage('makeBookingProgram')?.label} Programme.</p>
							</Row> */}
                            <Row>
                                <p style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'left' }}>Hello {getStorage('myo_logged_user')?.userData?.firstName}</p>
                            </Row>

                            <Row style={{ marginTop: "40px" }}>
                                <p style={{ fontSize: '20px', textAlign: 'left', fontWeight: 'bold' }}>Where would you like to book?</p>
                            </Row>
                            <Row style={{
                                width: "1580px",
                                height: "64px",
                                gap: " 0px ",
                                borderRadius: "25px 25px 25px 25px",
                                opacity: "0px ",
                                backgroundColor: "#35BDB4"
                            }} >
                                <Col md={2} style={{ width: '50%', marginLeft: '10px' }}>
                                    <Controller
                                        name="country"
                                        rules={
                                            { required: true }
                                        }
                                        control={control}
                                        render={({ field }) =>
                                            <div style={{
                                                width: '321px',
                                                height: '44px',
                                                marginTop: '10px'
                                            }}>
                                                <Select
                                                    components={{ DropdownIndicator: () => null }}
                                                    isSearchable
                                                    placeholder={<div className='flex justify-start '>
                                                        <div style={{ marginRight: '6%' }} >
                                                            <img style={{ width: '21px', height: '26px' }} src={BookMarkIcon} alt='book mark ' />
                                                        </div>
                                                        <div className="font-light">
                                                            Select Country
                                                        </div>
                                                    </div>}

                                                    options={renderCountry()}
                                                    onChange={(value) => {
                                                        changeHandler(value);
                                                        field.onChange(value);
                                                    }}

                                                    className={`${errors.country ? 'is-invalid' : ''} font-light`}
                                                    styles={BookMarkStyles}
                                                />
                                            </div>
                                        }
                                    />

                                    <ErrorMessage
                                        errors={errors}
                                        name="country"
                                        render={({ message }) => <div style={{ marginLeft: '-200px', marginTop: '10px' }} className="invalid-feedback-custom">Please select country!</div>} />
                                </Col>

                                <Col md={2} style={{ width: '50%', marginLeft: '-450px' }}>
                                    <Controller
                                        name="city"
                                        rules={
                                            { required: true }
                                        }
                                        control={control}
                                        render={({ field }) =>
                                            <div style={{
                                                width: '321px',
                                                height: '44px',
                                                marginTop: '10px'
                                            }}>
                                                <Select
                                                    components={{ DropdownIndicator: () => null }}
                                                    isSearchable
                                                    placeholder={<div className='flex justify-start '>
                                                        <div style={{ marginRight: '6%' }} >
                                                            <img style={{ width: '21px', height: '26px' }} src={BookMarkIcon} alt='book mark ' />
                                                        </div>
                                                        <div className="font-light">
                                                            Select city
                                                        </div>
                                                    </div>}
                                                    options={renderCity()}
                                                    className={`${errors.city ? 'is-invalid' : ''} font-light`}

                                                    onChange={(value) => {
                                                        onCityChange(value);
                                                        field.onChange(value);
                                                    }}
                                                    styles={BookMarkStyles}
                                                />
                                            </div>
                                        }
                                    />

                                    <ErrorMessage
                                        errors={errors}
                                        name="city"
                                        render={({ message }) => <div style={{ marginLeft: '-200px', marginTop: '10px' }} className="invalid-feedback-custom">Please select city!</div>} />
                                </Col>

                                <Col md={3} style={{ marginLeft: '-670px' }}>
                                    <Controller
                                        name="fromDate"
                                        defaultValue={[checkInDate, checkOutDate]}
                                        control={control}
                                        rules={
                                            { required: true }
                                        }
                                        render={({ field }) =>
                                            <div style={{
                                                borderRadius: '11px',
                                                marginLeft: '220px',
                                                width: '321px',
                                                height: '44px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                border: '1px solid hsl(0, 0%, 70%)',
                                                paddingLeft: '10px',
                                                marginTop: '10px',
                                                backgroundColor: '#FFFFFF',

                                            }}>
                                                <div>
                                                    <img style={{ width: '26px', height: '25.32px' }} src={CalenderIcon} alt="" />
                                                </div>
                                                <div>
                                                    <RangePicker
                                                        autoFocus={false}
                                                        bordered={false}
                                                        className="font-light"
                                                        suffixIcon={<div></div>}
                                                        style={{
                                                            border: 'none',
                                                            fontWeight: 300,
                                                            fontSize: '20px',
                                                        }}
                                                        placeholder={["Check-in", "Check-out"]}
                                                        onChange={onFromDateChange}
                                                        disabledDate={disabledFromDate}
                                                        defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD')]}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </Col>
                                <Col md={2}>
                                    {(
                                        <Controller
                                            name="accommodation"
                                            defaultValue={""}
                                            control={control}
                                            render={({ field }) => (
                                                <div
                                                    style={{
                                                        marginLeft: "165px",
                                                        width: "271px",
                                                        marginTop: "10px"

                                                    }}
                                                >
                                                    <Select
                                                        styles={RoomTypeStyles}
                                                        className={`${errors.accommodation ? "is-invalid " : ""
                                                            } font-light`}
                                                        options={roomTypeList}
                                                        onChange={onAccommodationChange}
                                                        isSearchable={true}
                                                        placeholder={
                                                            <div className="flex justify-start ">
                                                                <div style={{ marginRight: "6%" }}>
                                                                    <img
                                                                        style={{ width: "21px", height: "26px" }}
                                                                        src={FilterIcon}
                                                                        alt="book mark "
                                                                    />
                                                                </div>
                                                                <div className="font-light">
                                                                    Accommodation Type
                                                                </div>
                                                            </div>
                                                        }
                                                        components={{ DropdownIndicator: () => null }}
                                                    />
                                                </div>
                                            )}
                                        />
                                    )}
                                </Col>
                                <Col md={2} style={{

                                    marginTop: '10px',
                                    marginLeft: '188px'
                                }}>
                                    {
                                        !loading && <Button type="submit" style={searchButton} id='search-button' >
                                            <div className="flex justify-center" style={{
                                                marginTop: '-10px',

                                            }}>
                                                <div >
                                                    <img src={searchIcon} className="searchIcon" alt="" />
                                                </div>
                                                <div style={{ marginTop: '15px' }}>Search</div>
                                            </div>

                                        </Button>
                                    }
                                    {
                                        loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                    }
                                </Col>
                            </Row>

                        </Form>

                        {/* {myoParams?.myoPlusProgramme === 'MYO Plus' && <div style={{ marginTop: '-25px' }}>
							<Row className=''>
								<Col md={6} >
									<Button variant="link" className="text-right text-blue-400" onClick={() => onselectCompanyLocation()} style={{ marginRight: '195px' }}>Select key location</Button>
								</Col>
								<Col md={6} style={{ textAlign: 'left' }}>
								</Col>
							</Row>
						</div>} */}
                    </Row>
                </div>
            </Container >
        </div >
    )
};

export default MyoCorporatePage;