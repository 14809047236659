import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Image, Spinner } from 'react-bootstrap';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, convertFromHTML, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Controller, useForm } from "react-hook-form";
import ImageUpload from '../../../../../common/imageUpload';
import { ErrorMessage } from "@hookform/error-message";
import { toast } from 'react-toastify';
import { FormInputs, SectionContent } from "../../../../../../common/interfaces";

/////////////// images ////////////
import ADD from '../../../../../../images/plus.png';
import MINUS from '../../../../../../images/minus.png';
////////// import css ////////////////////
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

////// component state ///////
type InputState = {
    handleClose: () => void,
    hideForm: () => void,
    content?: FormInputs
};


const SectionContentCreateForm: React.FunctionComponent<InputState> = ({ content, handleClose, hideForm }) => {

    ////////////// form manage ///////////////
    const { register, handleSubmit, formState: { errors }, control } = useForm<FormInputs>();

    ///////////////// global  action ////////////////////////
    const { section, sectionContentCreate, sectionContentUpdate } = useStoreActions<any>((actions) => ({
        section: actions.DashboardModel.section,
        sectionContentCreate: actions.DashboardModel.sectionContentCreate,
        sectionContentUpdate: actions.DashboardModel.sectionContentUpdate,
    }));

    /////////// global status ///////////////////
    const {
        getSectionSuccess,
        getSectionContentCreateSuccess,
        getSectionContentCreateError,
        getSectionContentUpdateSuccess,
        getSectionContentUpdateError
    } = useStoreState<any>((state) => ({
        getSectionSuccess: state.DashboardModel.getSectionSuccess,
        getSectionContentCreateSuccess: state.DashboardModel.getSectionContentCreateSuccess,
        getSectionContentCreateError: state.DashboardModel.getSectionContentCreateError,
        getSectionContentUpdateSuccess: state.DashboardModel.getSectionContentUpdateSuccess,
        getSectionContentUpdateError: state.DashboardModel.getSectionContentUpdateError
    }));

    /////////////// local state management /////////////////
    const [sectionFormManage, setSectionFormManage] = useState<Array<SectionContent>>([{
        description: '',
        ImageUrl: ''
    }]);
    const [sections, setSections] = useState<Array<any>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [formEditable, setFormEditable] = useState<boolean>(false);

    ///////////////////////////////////
    useEffect(() => {
        section();

        if (content?._id) {
            const getOldData = [...content.sectionContent];
            setSectionFormManage(getOldData);
        } else {
            setFormEditable(true);
        }
    }, [content, section]);

    //////////////////// response handle ///////////
    useEffect(() => {
        if (getSectionSuccess) {
            setSections(getSectionSuccess.data);
        }

        if (getSectionContentCreateSuccess) {
            setLoading(false);
            handleClose();
        }

        if (getSectionContentUpdateSuccess) {
            setLoading(false);
            handleClose();
        }

        if (getSectionContentCreateError) {
            setLoading(false);
            toast.error(getSectionContentCreateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (getSectionContentUpdateError) {
            setLoading(false);
            toast.error(getSectionContentUpdateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [content, getSectionContentCreateError, getSectionContentCreateSuccess, getSectionContentUpdateError, getSectionContentUpdateSuccess, getSectionSuccess, handleClose]);

    ////////////////////////////image handle///////////////////////////////////////
    const getFileBlob = useCallback((fileBlob, index) => {
        sectionFormManage[index].ImageUrl = fileBlob;
        setSectionFormManage(sectionFormManage);
    }, [sectionFormManage]);

    ////////////////////////////textHandle handle///////////////////////////////////////
    const getText = useCallback((htmlText, index) => {
        sectionFormManage[index].description = htmlText;
        setSectionFormManage(sectionFormManage);
    }, [sectionFormManage]);

    ///////////// section add ///////
    const addSection = useCallback(() => {
        const initStructure = {
            description: '',
            ImageUrl: ''
        };
        const getOldData = [...sectionFormManage, initStructure];
        setSectionFormManage(getOldData);
    }, [sectionFormManage]);

    ////////// remove section
    const removeItem = useCallback((index) => {
        if (index > 0) {
            sectionFormManage.splice(index, index);
        } else {
            sectionFormManage.shift();
        }
        const getNewData = [...sectionFormManage];
        setSectionFormManage(getNewData);
    }, [sectionFormManage]);

    //////////// form change to edit mode /////////
    const changeEditMode = () => {
        setFormEditable(true);
    }

    ////////////// on submit ///////
    const onSubmit = (values) => {
        setLoading(true);

        if (content?._id) {
            /////////// update 
            const payload = {
                _id: content?._id,
                sectionOfHelpId: values.sectionOfHelpId,
                title: values.title,
                sectionContent: sectionFormManage
            }

            sectionContentUpdate(payload);
        } else {
            //////// create
            const payload = {
                sectionOfHelpId: values.sectionOfHelpId,
                title: values.title,
                sectionContent: sectionFormManage
            }

            sectionContentCreate(payload);
        }

    }

    return (
        <>
            <Container fluid className='p-0'>
                <div className="jumbotron">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className='formSpace'>
                            <Col md={2} style={{ textAlign: 'right' }}>
                                <Form.Label>{formEditable && <span className='requiredMark'>*</span>}  Section Name</Form.Label>
                            </Col>
                            <Col md={5} style={{ textAlign: 'left' }}>
                                {
                                    !formEditable && <p>{content?.sectionName}</p>
                                }
                                {
                                    formEditable && <>
                                        <Form.Select defaultValue={content?.sectionOfHelpId} {...register("sectionOfHelpId", { required: true })} className={`${errors.sectionOfHelpId ? 'is-invalid' : ''}`}>
                                            <option value={``}>Select section</option>
                                            {
                                                sections?.map((data, key) => {
                                                    return (
                                                        <option key={key} value={data._id}>{data.name}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                        <ErrorMessage
                                            errors={errors}
                                            name="sectionOfHelpId"
                                            render={({ message }) => <div className="invalid-feedback-custom">Please select a section.!</div>}
                                        />
                                    </>
                                }


                            </Col>
                            <Col md={5} style={{ textAlign: 'right', marginTop: '-30px' }}>
                                {
                                    formEditable && <Button id='edit-button' variant="primary" onClick={hideForm}>
                                        Add New Section
                                    </Button>
                                }
                                {
                                    !formEditable && <Button id='edit-button' variant="primary" onClick={changeEditMode}>
                                        Edit
                                    </Button>
                                }
                            </Col>
                        </Row>
                        <Row className='formSpace'>
                            <Col md={2} style={{ textAlign: 'right' }}>
                                <Form.Label>{formEditable && <span className='requiredMark'>*</span>}  Title</Form.Label>
                            </Col>
                            <Col md={10} style={{ textAlign: 'left' }}>
                                {
                                    !formEditable && <p>{content?.title}</p>
                                }

                                {
                                    formEditable && <>
                                        <Form.Control defaultValue={content?.title} {...register("title", { required: true })} className={`${errors.title ? 'is-invalid' : ''}`} />
                                        <ErrorMessage
                                            errors={errors}
                                            name="title"
                                            render={({ message }) => <div className="invalid-feedback-custom">Please input title.!</div>}
                                        />
                                    </>
                                }

                            </Col>
                        </Row>
                        <Row className='formSpace'>
                            <Col md={2} style={{ textAlign: 'right' }}>
                            </Col>
                            <Col md={10} style={{ textAlign: 'left' }}>
                                <h4>Section Contents</h4>
                            </Col>
                        </Row>
                        {
                            sectionFormManage.map((aboutSection, key) => {
                                return (
                                    <div key={key}>
                                        <Row className='formSpace'>
                                            <Col md={2} style={{ textAlign: 'right' }}>
                                                <Form.Label>{formEditable && <span className='requiredMark'>*</span>}  Description</Form.Label>
                                            </Col>
                                            <Col md={10} style={{ textAlign: 'left' }}>
                                                {
                                                    !formEditable && <div dangerouslySetInnerHTML={{ __html: sectionFormManage[key].description }} />
                                                }
                                                {
                                                    formEditable && <>
                                                        <Controller
                                                            name={`sectionContent.${key}.description`}
                                                            control={control}
                                                            defaultValue={sectionFormManage[key].description}
                                                            rules={{
                                                                required: true,
                                                            }}
                                                            render={({ field }) =>
                                                                <>
                                                                    <Editor
                                                                        editorClassName="demo-editor"
                                                                        toolbar={{
                                                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'remove', 'history'],
                                                                            inline: { inDropdown: true },
                                                                            list: { inDropdown: true },
                                                                            textAlign: { inDropdown: true },
                                                                            history: { inDropdown: true }
                                                                        }}
                                                                        defaultEditorState={EditorState.createWithContent(
                                                                            ContentState.createFromBlockArray(
                                                                                convertFromHTML(sectionFormManage[key].description)
                                                                            )
                                                                        )}
                                                                        onEditorStateChange={(value) => {
                                                                            if(draftToHtml(convertToRaw(value.getCurrentContent())).trim().toString() === '<p></p>'){
                                                                                field.onChange(null);
                                                                            }else{
                                                                                field.onChange(draftToHtml(convertToRaw(value.getCurrentContent())));
                                                                                getText(draftToHtml(convertToRaw(value.getCurrentContent())), key)
                                                                            }
                                                                        }}
                                                                    />
                                                                </>
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={`sectionContent.${key}.description`}
                                                            render={({ message }) => <div className="invalid-feedback-custom">Please input description.!</div>} />
                                                    </>
                                                }

                                            </Col>
                                        </Row>
                                        <Row className='formSpace'>
                                            <Col md={2} style={{ textAlign: 'right' }}>
                                                <Form.Label>{formEditable && <span className='requiredMark'>*</span>}  Logo</Form.Label>
                                            </Col>
                                            <Col md={10} style={{ textAlign: 'left' }}>
                                                {!formEditable && <ImageUpload crop={false} getImageBlob={(value) => { getFileBlob(value, key) }} type="image" value={String(`${sectionFormManage[key].ImageUrl}`)} disabled={true} newUpload={false} />}

                                                {
                                                    formEditable && <>
                                                        <Controller
                                                            name={`sectionContent.${key}.ImageUrl`}
                                                            control={control}
                                                            defaultValue={sectionFormManage[key].ImageUrl}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) =>
                                                                <ImageUpload crop={false} getImageBlob={(value) => { 
                                                                    field.onChange(value);
                                                                    getFileBlob(value, key);
                                                                }} type="image" value={String(`${sectionFormManage[key].ImageUrl}`)} disabled={false} />
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={`sectionContent.${key}.ImageUrl`}
                                                            render={({ message }) => <div className="invalid-feedback-custom">Please select a image.!</div>} />
                                                    </>
                                                }


                                            </Col>
                                        </Row>
                                        {
                                            formEditable && <Row>
                                                <Col md={10}></Col>
                                                <Col md={2} > <Image onClick={addSection} src={ADD} height={20} width={20} style={{ display: 'inline', marginRight: '30px', cursor: 'pointer' }} /> {sectionFormManage.length !== 1 && <Image onClick={() => { removeItem(key) }} src={MINUS} height={20} width={20} style={{ cursor: 'pointer', display: 'inline' }} />}</Col>
                                            </Row>
                                        }

                                    </div>
                                )
                            })
                        }
                        <Row className='formSpace'>
                            <Col md={12} style={{ textAlign: 'right' }}>
                                {
                                    !loading && formEditable && <>
                                        <Button id='edit-button' onClick={handleClose} style={{ marginRight: '2%', width: '100px' }}>
                                            Close
                                        </Button>
                                        <Button variant="primary" type="submit" style={{ width: '100px' }}>
                                            {formEditable ? 'Update' : 'Submit'}
                                        </Button>
                                    </>
                                }

                                {loading && formEditable && <Spinner animation="border" variant="warning" />}
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </>
    )
};

export default SectionContentCreateForm;