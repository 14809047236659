import React, { useCallback, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable, { createTheme } from 'react-data-table-component';
import { mysaTableTheme, overlayTriggerDelay, tableStyles } from "../../../../../common/components-style";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SectionCreate from "./SectionCreate";
import ViewEdit from '../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { FormInputs } from "../../../../../common/interfaces";

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const SectionContent: React.FunctionComponent = () => {
    const [sectionContentData, setSectionContentData] = useState<any>('');
    const [show, setShow] = useState(false);
    const [content, setContent] = useState<FormInputs>();

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { sectionContent } = useStoreActions<any>((actions) => ({
        sectionContent: actions.DashboardModel.sectionContent,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getSectionContentSuccess
    } = useStoreState<any>((state) => ({
        getSectionContentSuccess: state.DashboardModel.getSectionContentSuccess
    }));

    // action call
    useEffect(() => {
        sectionContent();
    }, [sectionContent]);

    // response manage
    useEffect(() => {
        if (getSectionContentSuccess) {
            setSectionContentData(getSectionContentSuccess.data);
        }
    }, [getSectionContentSuccess]);

    ///////////////////////// model handler //////////
    const handleClose = useCallback(() => {
        setShow(false);
        sectionContent();

    }, [sectionContent]);

    /////////// model show /////////
    const handleShowFromEdit = (data) => {

        const contents: any = [];
        for (const content of data?.sectionContent) {
            const prepareData = {
                description: content.description,
                ImageUrl: content.ImageUrl
            };

            contents.push(prepareData);
        }

        const prepareToComponentData = {
            _id: data._id,
            sectionOfHelpId: data.sectionOfHelpId._id,
            sectionName: data.sectionOfHelpId.name,
            title: data.title,
            sectionContent: contents
        }
        setContent(prepareToComponentData);
        setShow(true);

    };
    const handleShowFromCreate = () => {
        const prepareToComponentData = {
            _id: '',
            sectionOfHelpId: '',
            title: '',
            sectionName: '',
            sectionContent: [
                {
                    description: '',
                    ImageUrl: ''
                }
            ]
        }
        setContent(prepareToComponentData);
        setShow(true);
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const columns = [
        {
            name: 'Content ID',
            cell: (row: any) => {
                return (<>
                    <b>{row._id}</b>
                </>)
            },
        },
        {
            name: 'Section Name',
            selector: row => row.sectionOfHelpId.name,
        },
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Action',
            button: true,
            cell: (row: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
                    >
                        <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                            handleShowFromEdit(row);
                        }} />
                    </OverlayTrigger>
                </>)
            },
        },
    ];

    return (<>
        <Row>
            <Col md={8}></Col>
            <Col md={4} style={{ textAlign: 'right' }}>
                <Button style={{ textAlign: 'right' }} onClick={handleShowFromCreate} className="border-none" >Create New Section</Button>
            </Col>
        </Row>
        <div className='brnd_tbl' style={{ marginTop: '50px' }}>
            <DataTable
                columns={columns}
                data={sectionContentData}
                pagination
                striped={true}
                customStyles={tableStyles}
                theme='mysaTable'
                fixedHeader={true}
                noDataComponent={'Updating records'}
            />
        </div>
        <SectionCreate show={show} handleClose={handleClose} content={content} />
    </>)
}

export default SectionContent;