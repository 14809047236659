import React, { useCallback, useEffect, useState } from 'react';
import { canBrandPropertyModuleWrite } from '../../../../common/functions';
import { UserAccess, CommonAnswer } from '../../../../common/constants';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Controller, useForm } from 'react-hook-form';
import { TransportFormInput } from '../../../../common/interfaces';
import { Row, Col, Container, Form, Button, Spinner } from 'react-bootstrap';
import { formButton } from '../../../../common/components-style';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
// import { HelpModal } from '../../../common/HelpModal';

const TransportDetailForm: React.FC<any> = ({ allInOnePropertyBreadCrumb, propertyId, setFormOpen, selectedBrand, tab }): JSX.Element => {
    ///////////////////// initialize form ////////////////////////////////////// 
    const { register, handleSubmit, formState: { errors }, control } = useForm<TransportFormInput>();

    ///////////////////////// local state manage ////////////////////////////////////
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [propertyData, setPropertyData] = useState<any>();
    const [transportData, setTransportData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [transportId, setTransportId] = useState<boolean>();
    const [doYouProvideOnSiteCarParking, setDoYouProvideOnSiteCarParking] = useState<boolean>(false);
    const [includedInTheRate, setIncludedInTheRate] = useState<boolean>(false);
    const [currency, setCurrency] = useState<any>();

    /////////////////////////// global state manage (actions)//////////////////////////////////////////////////////
    const { createTransport, getTransport, updateTransport, getProperty } = useStoreActions<any>((actions) => ({
        createTransport: actions.property.createTransport,
        getTransport: actions.property.getTransport,
        updateTransport: actions.property.updateTransport,
        getProperty: actions.property.getProperty,
    }));

    /////////////////////////// global state manage (state)//////////////////////////////////////////////////////
    const { createTransportSuccess, createTransportError, getTransportSuccess, updateTransportSuccess, updateTransportError, getPropertySuccess } = useStoreState<any>((state) => ({
        createTransportSuccess: state.property.createTransportSuccess,
        createTransportError: state.property.createTransportError,
        getTransportSuccess: state.property.getTransportSuccess,
        updateTransportSuccess: state.property.updateTransportSuccess,
        updateTransportError: state.property.updateTransportError,
        getPropertySuccess: state.property.getPropertySuccess
    }));

    //////////////////////////////// call apis using actions //////////////////////////////////////////////////////
    useEffect(() => {
        getTransport(propertyId);
        getProperty(propertyId);
    }, [getProperty, getTransport, propertyId]);

    //////////////////////////////// get api response using state //////////////////////////////////////////////////////
    useEffect(() => {
        if (getTransportSuccess) {
            if (getTransportSuccess.data) {
                setTransportId(getTransportSuccess.data._id);
                setTransportData(getTransportSuccess.data);

                if (getTransportSuccess.data?.doYouProvideOnSiteCarParking === CommonAnswer.YES) {
                    setDoYouProvideOnSiteCarParking(true);
                } else {
                    setDoYouProvideOnSiteCarParking(false);
                }

                if (getTransportSuccess.data.includedInTheRate === CommonAnswer.NO) {
                    setIncludedInTheRate(true);
                } else {
                    setIncludedInTheRate(false);
                }
            }
        }

        if (createTransportSuccess) {
            setLoading(false);
            toast.success("Transport created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            getTransport(propertyId);
        }

        if (createTransportError) {
            setLoading(false);
            toast.error(createTransportError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (updateTransportSuccess) {
            setLoading(false);
            toast.success("Transport update successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            getTransport(propertyId);
        }

        if (updateTransportError) {
            setLoading(false);
            toast.error(updateTransportError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (getPropertySuccess) {
            const data = getPropertySuccess.data;
            setCurrency(data.currency);
            setPropertyData(getPropertySuccess.data);
            // setTitle(getPropertySuccess.data?.propertyName);
        }
    }, [createTransportError, createTransportSuccess, getPropertySuccess, getTransport, getTransportSuccess, propertyId, updateTransportError, updateTransportSuccess]);

    /////////////////////////includedInTheRate validation manage /////////////////////////////////////
    const onChangeDoYouProvideOnSiteCarParking = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setDoYouProvideOnSiteCarParking(true);
        } else {
            setDoYouProvideOnSiteCarParking(false);
        }
    }, []);

    ///////////////////////////costOfCarParking validation manage /////////////////////////////////////
    const onChangeIncludedInTheRate = useCallback((value) => {
        if (value === CommonAnswer.NO) {
            setIncludedInTheRate(true);
        } else {
            setIncludedInTheRate(false);
        }
    }, [])

    const onFinish = (values) => {
        if (!propertyId) {
            toast.error("Please create an property", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        setLoading(true);
        const createUpdateValues = {
            propertyId: propertyId,
            doYouProvideOnSiteCarParking: values.doYouProvideOnSiteCarParking,
            includedInTheRate: values.includedInTheRate,
            costOfCarParking: values.costOfCarParking,
            ifYouDoNotProvideOnSiteCarParking: values.ifYouDoNotProvideOnSiteCarParking,
            nearestAirport: values.nearestAirport,
            distanceToTheAirport: values.distanceToTheAirport
        }

        if (transportId) {
            Object.assign(createUpdateValues, { transportId });
            delete createUpdateValues.propertyId;
            updateTransport(createUpdateValues)
        } else {
            createTransport(createUpdateValues);
        }


    };

    const changeFormEditable = useCallback(() => {
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);

    return (
        <>
            <div className="content-title fixed_title">
                {
                    <BreadCrumbPage allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} tab={tab} isPageName="Property" selectedBrand={selectedBrand} propertyData={propertyData} setFormOpen={setFormOpen} ></BreadCrumbPage>
                }
                <div className='edit_btn_wpr'>
                    <div className='d-flex align-items-center justify-content-end gx-2'>
                        {/* <HelpModal /> */}
                        {
                            !loading && !editEnable && propertyId && canBrandPropertyModuleWrite(selectedBrand.key, propertyId, UserAccess.property_access.details.code) && <Button id='edit-button' className='mb-0' style={formButton} onClick={changeFormEditable} >Edit</Button>
                        }
                    </div>
                </div>
            </div>
            <Container fluid className='p-0'>
                <div className="jumbotron">
                    <Form onSubmit={handleSubmit(onFinish)}>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you provide on-site car parking?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{transportData?.doYouProvideOnSiteCarParking}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouProvideOnSiteCarParking"
                                                defaultValue={transportData?.doYouProvideOnSiteCarParking}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            transportData?.doYouProvideOnSiteCarParking === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideOnSiteCarParking"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouProvideOnSiteCarParking(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideOnSiteCarParking"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouProvideOnSiteCarParking(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            transportData?.doYouProvideOnSiteCarParking === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideOnSiteCarParking"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouProvideOnSiteCarParking(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideOnSiteCarParking"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouProvideOnSiteCarParking(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouProvideOnSiteCarParking"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                {
                                    doYouProvideOnSiteCarParking &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Is this included in the rate?</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{transportData?.includedInTheRate}</p>}
                                            {editEnable &&
                                                <Controller
                                                    name="includedInTheRate"
                                                    defaultValue={transportData?.includedInTheRate}
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field }) =>
                                                        <>
                                                            {
                                                                transportData?.includedInTheRate === 'Yes'
                                                                    ?
                                                                    <Form.Check
                                                                        inline
                                                                        value={'Yes'}
                                                                        name="includedInTheRate"
                                                                        label={'Yes'}
                                                                        type="radio"
                                                                        defaultChecked
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIncludedInTheRate(value.currentTarget.value); }}
                                                                    />
                                                                    :
                                                                    <Form.Check
                                                                        inline
                                                                        value={'Yes'}
                                                                        name="includedInTheRate"
                                                                        label={'Yes'}
                                                                        type="radio"
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIncludedInTheRate(value.currentTarget.value); }}
                                                                    />
                                                            }

                                                            {
                                                                transportData?.includedInTheRate === 'No'
                                                                    ?
                                                                    <Form.Check
                                                                        inline
                                                                        value={'No'}
                                                                        name="includedInTheRate"
                                                                        label={'No'}
                                                                        type="radio"
                                                                        defaultChecked
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIncludedInTheRate(value.currentTarget.value); }}
                                                                    />
                                                                    :
                                                                    <Form.Check
                                                                        inline
                                                                        value={'No'}
                                                                        name="includedInTheRate"
                                                                        label={'No'}
                                                                        type="radio"
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIncludedInTheRate(value.currentTarget.value); }}
                                                                    />
                                                            }

                                                        </>
                                                    }
                                                />}
                                            {editEnable &&
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="includedInTheRate"
                                                    render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                        </Col>
                                    </Row>
                                }
                                {
                                    doYouProvideOnSiteCarParking && includedInTheRate &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} {'What is the nightly cost of car parking (' + currency + ')?'}</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{transportData?.costOfCarParking} {transportData?.costOfCarParking && <span>{currency}</span>} </p>}
                                            {editEnable && <Form.Control type='number' min={0} className={`${errors.costOfCarParking ? 'is-invalid' : ''}`} defaultValue={transportData?.costOfCarParking} {...register("costOfCarParking", { required: true })} />}
                                            {editEnable && <div className="invalid-feedback">Please select your answer!</div>}
                                        </Col>
                                    </Row>
                                }
                                {
                                    !doYouProvideOnSiteCarParking &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} How far is the nearest car park (mins)?</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{transportData?.ifYouDoNotProvideOnSiteCarParking} {transportData?.ifYouDoNotProvideOnSiteCarParking && <span>minutes</span>} </p>}
                                            {editEnable && <Form.Control type='number' min={0} className={`${errors.ifYouDoNotProvideOnSiteCarParking ? 'is-invalid' : ''}`} defaultValue={transportData?.ifYouDoNotProvideOnSiteCarParking} {...register("ifYouDoNotProvideOnSiteCarParking", { required: true })} />}
                                            {editEnable && <div className="invalid-feedback">Please select your answer!</div>}
                                        </Col>
                                    </Row>
                                }
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} What is the nearest major airport?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{transportData?.nearestAirport}</p>}
                                        {editEnable && <Form.Control className={`${errors.nearestAirport ? 'is-invalid' : ''}`} defaultValue={transportData?.nearestAirport} {...register("nearestAirport", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please select your answer!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} What is the distance to the airport (km)?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{transportData?.distanceToTheAirport} {transportData?.distanceToTheAirport && <span>km</span>} </p>}
                                        {editEnable && <Form.Control type='number' min={0} className={`${errors.distanceToTheAirport ? 'is-invalid' : ''}`} defaultValue={transportData?.distanceToTheAirport} {...register("distanceToTheAirport", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please select your answer!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!loading && !transportData && editEnable && propertyId && canBrandPropertyModuleWrite(selectedBrand.key, propertyId, UserAccess.property_access.details.code) &&
                                            <Button type="submit" id='approve-button' style={formButton}>Create</Button>
                                        }
                                        {!loading && transportData && editEnable && propertyId && canBrandPropertyModuleWrite(selectedBrand.key, propertyId, UserAccess.property_access.details.code) &&
                                            <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                                        }

                                        {
                                            loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Form>
                </div>
            </Container>

        </>
    );
};

export default TransportDetailForm;