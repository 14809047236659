import { Checkbox } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { formButton } from '../../../../common/components-style';
import { UserAccess } from '../../../../common/constants';
import { canBrandPropertyModuleWrite, formatPhoneNumber, getMailDomain } from '../../../../common/functions';
import { PropertyFinanceFormInput } from '../../../../common/interfaces';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
import { PhoneNumberUtil } from 'google-libphonenumber';
// import { HelpModal } from '../../../common/HelpModal';

const PropertyFinance: React.FC<any> = ({ allInOnePropertyBreadCrumb, brandId, setFormOpen, propertyId, selectedBrand,tab }): JSX.Element => {
	///////////////////// initialize form ////////////////////////////////////// 
	const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<PropertyFinanceFormInput>();

	///////////////////////// local state manage ////////////////////////////////////
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const [propertyData, setPropertyData] = useState<any>();
	const [financeData, setFinanceData] = useState<any>({});
	const [loading, setLoading] = useState<boolean>(false);
	const [propertyFinanceId, setPropertyFinanceId] = useState<string>();
	const [phone, setPhone] = useState<string>('');
	const [invoiceIssuing, setInvoiceIssuing] = useState<Array<string>>([]);
	const [isPropertyDirectly, setIsPropertyDirectly] = useState<boolean>(false);
	const [isHeadOffice, setIsHeadOffice] = useState<boolean>(false);
	const [isProperty, setIsProperty] = useState<boolean>(false);
	const [brandDomains, setBrandDomains] = useState<Array<string>>([]);

	const plainOptions = [{ label: 'Head Office', value: 'Head Office', disabled: isHeadOffice ? false : true },
	{ label: 'Property Directly', value: 'Property Directly', disabled: isProperty ? false : true },
	]

	const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { onloadBrand, createPropertyFinanceDetail, updatePropertyFinanceDetail, getPropertyFinanceDetailById, resetProperty, getProperty, getBrandFinanceDetailByBrandId } = useStoreActions<any>((actions) => ({
		createPropertyFinanceDetail: actions.property.createPropertyFinanceDetail,
		updatePropertyFinanceDetail: actions.property.updatePropertyFinanceDetail,
		getPropertyFinanceDetailById: actions.property.getPropertyFinanceDetailById,
		resetProperty: actions.property.resetProperty,
		getProperty: actions.property.getProperty,
		getBrandFinanceDetailByBrandId: actions.brand.getBrandFinanceDetailByBrandId,
		onloadBrand: actions.brand.onloadBrand,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {
		createPropertyFinanceSuccess,
		createPropertyFinanceError,
		updatePropertyFinanceSuccess,
		updatePropertyFinanceError,
		getPropertyFinanceDetailSuccess,
		getPropertyFinanceDetailError,
		getPropertySuccess,
		getBrandFinanceDetailSuccess,
		getBrandFinanceDetailError,
		loadBrandSuccess
	} = useStoreState<any>((state) => ({
		createPropertyFinanceSuccess: state.property.createPropertyFinanceSuccess,
		createPropertyFinanceError: state.property.createPropertyFinanceError,
		updatePropertyFinanceSuccess: state.property.updatePropertyFinanceSuccess,
		updatePropertyFinanceError: state.property.updatePropertyFinanceError,
		getPropertyFinanceDetailSuccess: state.property.getPropertyFinanceDetailSuccess,
		getPropertyFinanceDetailError: state.property.getPropertyFinanceDetailError,
		getPropertySuccess: state.property.getPropertySuccess,
		getBrandFinanceDetailSuccess: state.brand.getBrandFinanceDetailSuccess,
		getBrandFinanceDetailError: state.brand.getBrandFinanceDetailError,
		loadBrandSuccess: state.brand.loadBrandSuccess,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (propertyId) {
			getPropertyFinanceDetailById(propertyId);
			getProperty(propertyId);
		}
	}, [propertyId, getPropertyFinanceDetailById, getProperty]);

	useEffect(() => {
		if (loadBrandSuccess) {
			setBrandDomains(loadBrandSuccess.data?.allowedDomains);
		}
	}, [loadBrandSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (brandId) {
			getBrandFinanceDetailByBrandId(brandId);
		}
	}, [brandId, getBrandFinanceDetailByBrandId]);

	//////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getPropertyFinanceDetailSuccess) {
			const propertyFinance = getPropertyFinanceDetailSuccess.data;
			if (propertyFinance) {

				const params: any = {};
				params.emailAddressForSubmission = propertyFinance.emailAddressForSubmission || '';
				params.nameOfContact = propertyFinance.nameOfContact || '';
				params.phone = propertyFinance.phone || '';
				params.invoiceIssuing = propertyFinance.invoiceIssuing;
				setFinanceData(params);
				setPropertyFinanceId(propertyFinance._id);
				setInvoiceIssuing(propertyFinance.invoiceIssuing);
				let includeList: Array<string> = ['Property Directly'];
				const isFilter = _.first(_.filter(propertyFinance.invoiceIssuing, value => _.includes(includeList, value)));
				if (isFilter) {
					setIsPropertyDirectly(true);
				} else {
					setIsPropertyDirectly(false);
				}
			}
		}
		if (getPropertyFinanceDetailError) {
			toast.error(getPropertyFinanceDetailError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (getPropertySuccess) {
			setPropertyData(getPropertySuccess.data);
			onloadBrand(getPropertySuccess.data?.brandId?._id);
			// setTitle(getPropertySuccess.data?.propertyName);
		}

	}, [getPropertyFinanceDetailError, getPropertyFinanceDetailSuccess, getPropertySuccess, onloadBrand]);


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getBrandFinanceDetailSuccess) {
			const brandFinance = getBrandFinanceDetailSuccess.data;
			if (brandFinance) {
				let includeList: Array<string> = ['Head Office'];
				let includeData: Array<string> = ['Individual Properties'];
				const isFilter = _.first(_.filter(brandFinance.invoiceIssuing, value => _.includes(includeList, value)));
				if (isFilter) {
					setIsHeadOffice(true);
					setInvoiceIssuing(includeList);
				} else {
					setIsHeadOffice(false);
				}
				const isData = _.first(_.filter(brandFinance.invoiceIssuing, value => _.includes(includeData, value)));
				if (isData && !isHeadOffice) {
					setIsProperty(true);
					setInvoiceIssuing([]);
				} else if (isData && isHeadOffice) {
					setIsProperty(true);
				} else {
					setIsProperty(false);
				}

			}
		}
		if (getBrandFinanceDetailError) {
			toast.error(getBrandFinanceDetailError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
	}, [brandId, getBrandFinanceDetailError, getBrandFinanceDetailSuccess, isHeadOffice]);




	///////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (createPropertyFinanceSuccess) {
			toast.success("Property finance created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetProperty();
			setLoading(false);
			setEditEnable(false);
			getPropertyFinanceDetailById(propertyId);
		}

		if (updatePropertyFinanceSuccess) {
			toast.success("Property finance updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetProperty();
			setLoading(false);
			setEditEnable(false);
			getPropertyFinanceDetailById(propertyId);
		}

		if (createPropertyFinanceError) {
			toast.error(createPropertyFinanceError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetProperty();
			setLoading(false);
		}

		if (updatePropertyFinanceError) {
			toast.error(updatePropertyFinanceError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetProperty();
			setLoading(false);
		}

	}, [createPropertyFinanceError, createPropertyFinanceSuccess, getPropertyFinanceDetailById, propertyId, resetProperty, updatePropertyFinanceError, updatePropertyFinanceSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onCheckChange = useCallback((list) => {
		let includeList: Array<string> = ['Property Directly'];
		const isFilter = _.first(_.filter(list, value => _.includes(includeList, value)));
		if (isFilter) {
			setIsPropertyDirectly(true);
			setValue("emailAddressForSubmission", financeData ? financeData.emailAddressForSubmission : '');
			setValue("nameOfContact", financeData ? financeData.nameOfContact : '');
			setValue("phone", financeData?.phone ? financeData.phone : '');
		} else {
			setIsPropertyDirectly(false);
			setValue("emailAddressForSubmission", '');
			setValue("nameOfContact", '');
			setValue("phone", '');
			setPhone('');
		}
		setInvoiceIssuing(list);
	}, [financeData, setValue]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const onFinish = (values) => {
		if (isPropertyDirectly) {
			if (!validPhoneNumber) {
				toast.error('Please enter valid phone number!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}
		}
		if (values.emailAddressForSubmission) {
			// check domain
			const getDomainValidation = getMailDomain(values.emailAddressForSubmission, brandDomains);
			if (!getDomainValidation) {
				toast.error('Invalid brand domain. please check your e-mail address domain with brand domains', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}
		}
		setLoading(true);
		if (propertyFinanceId) {
			values.id = propertyFinanceId;
			if (isPropertyDirectly) {
				if (!validatePhoneNumber(values?.phone)) {
					toast.error('Please enter valid phone number!', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'foo-bar'
					});
					return;
				}
			}
			const data = {
				id: propertyFinanceId,
				invoiceIssuing: values.invoiceIssuing,
				emailAddressForSubmission: values.emailAddressForSubmission,
				nameOfContact: values.nameOfContact,
				phone: values.phone

			}
			updatePropertyFinanceDetail(data);
		} else {
			const data = {
				propertyId: propertyId,
				invoiceIssuing: invoiceIssuing,
				emailAddressForSubmission: values.emailAddressForSubmission,
				nameOfContact: values.nameOfContact,
				phone: phone
			}
			createPropertyFinanceDetail(data);
		}
	};

	const changeFormEditable = useCallback(() => {
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);


	const validatePhoneNumber = (inputNumber: string) => {
		let number = formatPhoneNumber(inputNumber)

		let valid = false;
		let isPossible = false;
		try {
			const phoneUtil = PhoneNumberUtil.getInstance();
			isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number))
			valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
		} catch (e) {
			valid = false;
		}

		if (isPossible && valid) {

			setValidPhoneNumber(true);
			return true;
		} else {
			setValidPhoneNumber(false);
			return false;
		}
	};

	////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<>
		 <div className="content-title fixed_title">
			{
				<BreadCrumbPage allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} tab={tab} isPageName="Property" selectedBrand={selectedBrand} propertyData={propertyData} setFormOpen={setFormOpen} ></BreadCrumbPage>
			}
			<div className='edit_btn_wpr'>
				<div className='d-flex align-items-center justify-content-end gx-2'>
					{/* <HelpModal /> */}
					{
						!loading && !editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) && <Button id='edit-button' className='mb-0' hidden={isHeadOffice && !isProperty} style={formButton} onClick={changeFormEditable} >Edit</Button>
					}
				</div>
			</div>
			</div>
			<div>
				<div className="jumbotron">
					<Form onSubmit={handleSubmit(onFinish)}>
						<Row>
							<Col md={1}></Col>
							<Col md={10}>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Invoice issuing (Payment of booking fees)</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{/* {!editEnable && financeData?.invoiceIssuing &&
											<Checkbox.Group value={invoiceIssuing} options={plainOptions}>
											</Checkbox.Group>
										} */}

										{!editEnable && <p className="lead">
											<Checkbox.Group value={invoiceIssuing} options={plainOptions}>
											</Checkbox.Group>
										</p>}
										{editEnable &&
											<Controller
												name="invoiceIssuing"
												defaultValue={financeData?.invoiceIssuing}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<Checkbox.Group onChange={(value) => {
														onCheckChange(value);
														field.onChange(value);
													}} value={invoiceIssuing} options={plainOptions}>
													</Checkbox.Group>
												}
											/>}
										{editEnable &&
											<ErrorMessage
												errors={errors}
												name="invoiceIssuing"
												render={({ message }) => <div className="invalid-feedback-custom">Please select invoice issuing!</div>} />}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && isPropertyDirectly && <span className='requiredMark'>*</span>} Email address for submission</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{financeData?.emailAddressForSubmission}</p>}
										{editEnable && <Form.Control type='email' className={`${errors.emailAddressForSubmission ? 'is-invalid' : ''}`} defaultValue={financeData?.emailAddressForSubmission} {...register("emailAddressForSubmission", {
											required: (isPropertyDirectly) ? true : false,
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
												message: "Please input correct email address for submission!"
											}
										})} />}
										{editEnable && <div className="invalid-feedback">Please input email address for submission!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && isPropertyDirectly && <span className='requiredMark'>*</span>} Name of contact</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{financeData?.nameOfContact}</p>}
										{editEnable && <Form.Control className={`${errors.nameOfContact ? 'is-invalid' : ''}`} defaultValue={financeData?.nameOfContact} {...register("nameOfContact", { required: (isPropertyDirectly) ? true : false })} />}
										{editEnable && <div className="invalid-feedback">Please input name of contact!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && isPropertyDirectly && <span className='requiredMark'>*</span>} Phone</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && financeData?.phone && <p className="lead">{formatPhoneNumber(financeData?.phone)}</p>}
										{editEnable &&
											<Controller
												name="phone"
												control={control}
												defaultValue={financeData?.phone}
												rules={{
													required: (isPropertyDirectly) ? true : false,
													// minLength: {
													// 	value: length,
													// 	message: "Minimum length is " + length + "."
													// },
													// validate: () => {
													// 	if (isPropertyDirectly) {
													// 		if (validPhoneNumber) {
													// 			return true;
													// 		}
													// 		return Promise.reject(toast.error('Please enter valid phone number!', {
													// 			position: toast.POSITION.BOTTOM_RIGHT,
													// 			className: 'foo-bar'
													// 		}));
													// 	}
													// 	return;
													// }
												}}
												render={({ field }) =>
													<PhoneInput
														placeholder=''
														isValid={(inputNumber: string) => {
															if (isPropertyDirectly) {
																return isPropertyDirectly ? validatePhoneNumber(inputNumber) : false;
															}
															return true;
														}}
														containerClass={`${errors.phone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); setPhone(phone); }} />}
											/>
										}
										{editEnable && <div className="invalid-feedback">Please input phone number!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!loading && !propertyFinanceId && editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) &&
											<Button type="submit" id='approve-button' style={formButton}>Create</Button>
										}
										{!loading && propertyFinanceId && editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) &&
											<Button type="submit" id='approve-button' style={formButton}>Update</Button>
										}

										{
											!loading && isHeadOffice && !isProperty && !editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) && <Button type="submit" id='approve-button' style={formButton} hidden={propertyFinanceId ? true : false} >Confirm</Button>
										}
										{
											!loading && isHeadOffice && !isProperty && propertyFinanceId && !editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) && <Button type="submit" id='approve-button' style={formButton} >Confirmed</Button>
										}
										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={1}></Col>
						</Row>
					</Form>
				</div>
			</div>
		</>

	)
};

export default PropertyFinance;