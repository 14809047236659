import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Row, Container, Image } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import bookingdetails from "../../../../../../../images/icons/svg/bookingdetails.svg";

import { daysBetween, isBooker } from "../../../../../../../common/functions";
import CheckAndRadioBtn from "../../../../components/CheckAndRadioBtn";
import ButtonC from "../../../../components/ButtonC";
import { CommonAnswer, LIVE_BOOKING_STATUS } from "../../../../../../../common/constants";
import { toast } from "react-toastify";
import { DatePicker } from "antd";
import { slideImageStyle } from "../../../../../../../common/components-style";
import TextAreaC from "../../../../components/TextAreaC";
import moment from "moment";
import momentTimezone from "moment-timezone";
const { RangePicker } = DatePicker;

const BookingSIngleView: React.FunctionComponent = () => {

    /**
     * redirect hooks
     */
    const { push } = useHistory();

    /**
     * get param 
     */
    const { id }: any = useParams();

    /**
     * moment
     */
    const minDate = moment(new Date());

    /**
     * global actions
     */
    const { reinstateBookingRequest, amendBookingCancelWithCharge, sendCancelledBookingWithChargeEmail, getBookingById, bookingStatus, bookingCancelWithCharge, acceptAmendBookingRequest, sendCancelledBookingNoChargeEmail, sendAcceptAmendBookingEmail, sendNoAcceptAmendBookingEmail } = useStoreActions<any>((actions) => ({
        getBookingById: actions.booking.getBookingById,
        bookingStatus: actions.booking.bookingStatus,
        bookingCancelWithCharge: actions.booking.bookingCancelWithCharge,
        acceptAmendBookingRequest: actions.booking.acceptAmendBookingRequest,
        sendAcceptAmendBookingEmail: actions.booking.sendAcceptAmendBookingEmail,
        sendNoAcceptAmendBookingEmail: actions.booking.sendNoAcceptAmendBookingEmail,
        sendCancelledBookingNoChargeEmail: actions.booking.sendCancelledBookingNoChargeEmail,
        sendCancelledBookingWithChargeEmail: actions.booking.sendCancelledBookingWithChargeEmail,
        amendBookingCancelWithCharge: actions.booking.amendBookingCancelWithCharge,
        reinstateBookingRequest: actions.booking.reinstateBookingRequest
    }));

    /**
     * global status
     */
    const { getBookingByIdSuccess, getBookingStatusSuccess, getBookingStatusError, getBookingCancelWithChargeSuccess, getBookingCancelWithChargeError,
        getAcceptAmendBookingRequestSuccess,
        getAcceptAmendBookingRequestError,
        sendAcceptAmendBookingEmailError,
        sendNoAcceptAmendBookingEmailError,
        amendBookingCancelWithChargeSuccess,
        amendBookingCancelWithChargeError,
        reinstateBookingRequestSuccess,
        reinstateBookingRequestError
    } = useStoreState<any>((state) => ({
        getBookingByIdSuccess: state.booking.getBookingByIdSuccess,
        getBookingStatusSuccess: state.booking.getBookingStatusSuccess,
        getBookingStatusError: state.booking.getBookingStatusError,
        getBookingCancelWithChargeSuccess: state.booking.getBookingCancelWithChargeSuccess,
        getBookingCancelWithChargeError: state.booking.getBookingCancelWithChargeError,
        getAcceptAmendBookingRequestSuccess: state.booking.getAcceptAmendBookingRequestSuccess,
        getAcceptAmendBookingRequestError: state.booking.getAcceptAmendBookingRequestError,
        sendNoAcceptAmendBookingEmailSuccess: state.booking.sendNoAcceptAmendBookingEmailSuccess,
        sendNoAcceptAmendBookingEmailError: state.booking.sendNoAcceptAmendBookingEmailError,
        amendBookingCancelWithChargeSuccess: state.booking.amendBookingCancelWithChargeSuccess,
        amendBookingCancelWithChargeError: state.booking.amendBookingCancelWithChargeError,
        reinstateBookingRequestSuccess: state.booking.reinstateBookingRequestSuccess,
        reinstateBookingRequestError: state.booking.reinstateBookingRequestError
    }));

    /**
     * local state management
     */
    const [singleBooking, setSingleBooking] = useState<any>('');
    const [cancellationPolicy, setCancellationPolicy] = useState<String>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [reloading, setReloading] = useState<boolean>(false);
    const [yesNoHide, setYesNoHide] = useState<boolean>(false);
    const [checkInCharge, setCheckInCharge] = useState<string>('');
    const [checkOutCharge, setCheckOutCharge] = useState<string>('');
    const [amendCheckInCharge, setAmendCheckInCharge] = useState<string>('');
    const [amendCheckOutCharge, setAmendCheckOutCharge] = useState<string>('');
    const [confirmNumberOfNight, setConfirmNumberOfNight] = useState<number>();
    const [amendNumberOfNight, setAmendNumberOfNight] = useState<number>();
    const [showAccept, setShowAccept] = useState<boolean>(true);
    const [acceptChange, setAcceptChange] = useState<string>('');
    const [doesChangeIncurCancellationCharge, setDoesChangeIncurCancellationCharge] = useState<string>('');
    const [acceptChangeFeedback, setAcceptChangeFeedback] = useState<string>('');
    const [isAvailable, setIsAvailable] = useState<string>('');
    const [showAmendCancel, setShowAmendCancel] = useState<boolean>(false);
    const [showButton, setShowButton] = useState<boolean>(false);

    /**
     * react life cycle 
     */
    useEffect(() => {
        if (id) {
            getBookingById({ bookingID: id });
        }
    }, [getBookingById, id]);

    /**
       * back to the list page
       */
    const BackToList = useCallback(() => {
        push(`/admin/brand-property-booking-reports`);
    }, [push])

    /**
     * response booking manage
     */
    useEffect(() => {
        if (getBookingByIdSuccess?.data) {
            setSingleBooking(getBookingByIdSuccess.data);
            setCheckInCharge(getBookingByIdSuccess.data?.checkInDate);
            setCheckOutCharge(getBookingByIdSuccess.data?.checkOutDate);
            setAmendCheckInCharge(getBookingByIdSuccess.data?.amendCheckInChargeDate);
            setAmendCheckOutCharge(getBookingByIdSuccess.data?.amendCheckOutChargeDate);
        }

        if (getBookingStatusSuccess?.data) {
            setLoading(false);
            BackToList();
        }

        if (getBookingStatusError) {
            setLoading(false);
            toast.error(getBookingStatusError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }

        if (getBookingCancelWithChargeSuccess?.data) {
            setLoading(false);
            BackToList();
        }


        if (amendBookingCancelWithChargeSuccess?.data) {
            setLoading(false);
            BackToList();
        }

        if (reinstateBookingRequestSuccess?.data) {
            setReloading(false);
            BackToList();
        }
        if (getBookingCancelWithChargeError) {
            setLoading(false);
            toast.error(getBookingCancelWithChargeError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }


        const toDate = new Date();
        const cancelDate = new Date(singleBooking?.checkOutDate);
        const endDates = moment(cancelDate);
        const today = moment(toDate);
        const diffInDays = today.diff(endDates, 'days');


        if (typeof singleBooking?.numberOfNightWithCharge === 'undefined' && diffInDays >= 3) {
            setShowButton(true);

        }
    }, [getBookingByIdSuccess, getBookingStatusError, getBookingStatusSuccess, getBookingCancelWithChargeSuccess, getBookingCancelWithChargeError, BackToList, amendBookingCancelWithChargeSuccess?.data, reinstateBookingRequestSuccess?.data, singleBooking.cancelledDate, singleBooking?.numberOfNightWithCharge, singleBooking?.checkOutDate]);



    /**
     * cancel
     */
    const cancel = () => {
        /**
         * check checkbox value is exist
         */
        if (cancellationPolicy.length === 0) {
            toast.warning(`Please select a cancellation charge`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            return 0;
        }

        if (cancellationPolicy === 'no') {
            /**
             * without charge
             */

            const payload = {
                clientId: singleBooking?.clientId?._id,
                programmeId: singleBooking?.programmeId?._id,
                brandId: singleBooking?.brandId?._id,
                // inventoryId: singleBooking?.inventoryId?._id,
                propertyId: singleBooking?.propertyId?._id,
                bookerId: singleBooking?.bookerUserId?._id,
                bookingStatus: LIVE_BOOKING_STATUS.CANCELLED,
                id: singleBooking?.liveBookingId,
                brandName: singleBooking?.brandId?.name,
                propertyName: singleBooking?.propertyId?.propertyName,
                travelerName: singleBooking?.travelerId?.firstName + ' ' + singleBooking?.travelerId?.lastName,
                cancelledDate: new Date()

            }
            setLoading(true);
            bookingStatus(payload);



            const payload2 = {
                travelerFirstName: singleBooking?.travelerId?.firstName,
                travelerLastName: singleBooking?.travelerId?.lastName,
                toEmail: singleBooking?.bookerUserId?.email,
                bookerFirstName: singleBooking?.bookerUserId?.firstName,
                bookerLastName: singleBooking?.bookerUserId?.lastName,
                propertyName: singleBooking?.propertyId?.propertyName,
                brandName: singleBooking?.brandId?.name,

            }
            sendCancelledBookingNoChargeEmail(payload2);
        }

        if (cancellationPolicy === 'yes') {
            /**
             * with charge
             */

            if (!checkInCharge && !checkOutCharge) {
                toast.warning(`Please provide the dates for which there will be a cancellation charge`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                return 0;
            }

            if (!confirmNumberOfNight) {
                toast.warning(`Please confirm the number of nights being charged`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                return 0;
            }

            /**
             * check & compare number of night
             */
            const numberOfNight = daysBetween(new Date(checkInCharge), new Date(checkOutCharge));

            if (numberOfNight !== confirmNumberOfNight) {
                toast.error(`Confirm the number of nights is not match with cancellation charge dates. Please check confirm the number of nights`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                return 0;
            }

            const payload = {
                clientId: singleBooking?.clientId?._id,
                programmeId: singleBooking?.programmeId?._id,
                brandId: singleBooking?.brandId?._id,
                inventoryId: singleBooking?.liveBookingId,
                propertyId: singleBooking?.propertyId?._id,
                bookerId: singleBooking?.bookerUserId?._id,
                bookingStatus: LIVE_BOOKING_STATUS.CANCELLED,
                checkInChargeDate: checkInCharge,
                checkOutChargeDate: checkOutCharge,
                numberOfNightWithCharge: confirmNumberOfNight,
                brandName: singleBooking?.brandId?.name,
                propertyName: singleBooking?.propertyId?.propertyName,
                travelerName: singleBooking?.travelerId?.firstName + ' ' + singleBooking?.travelerId?.lastName,
                cancelledDate: new Date()
            }
            setLoading(true);
            bookingCancelWithCharge(payload);


            const payload2 = {
                travelerFirstName: singleBooking?.travelerId?.firstName,
                travelerLastName: singleBooking?.travelerId?.lastName,
                toEmail: singleBooking?.bookerUserId?.email,
                bookerFirstName: singleBooking?.bookerUserId?.firstName,
                bookerLastName: singleBooking?.bookerUserId?.lastName,
                propertyName: singleBooking?.propertyId?.propertyName,
                brandName: singleBooking?.brandId?.name,

            }
            sendCancelledBookingWithChargeEmail(payload2);
        }

    };

    useEffect(() => {
        if (getAcceptAmendBookingRequestSuccess?.data) {
            setLoading(false);
            if (acceptChange === CommonAnswer.YES) {
                if (singleBooking?.bookerUserId?.email === singleBooking?.travelerId?.email) {
                    const payload = {
                        firstName: singleBooking?.bookerUserId?.firstName,
                        lastName: singleBooking?.bookerUserId?.lastName,
                        propertyName: singleBooking?.propertyId?.propertyName,
                        email: singleBooking?.bookerUserId?.email,
                        travelerFirstName: singleBooking?.travelerId?.firstName,
                        travelerLastName: singleBooking?.travelerId?.lastName
                    }

                    sendAcceptAmendBookingEmail(payload);

                } else {
                    const payload = {
                        firstName: singleBooking?.travelerId?.firstName,
                        lastName: singleBooking?.travelerId?.lastName,
                        propertyName: singleBooking?.propertyId?.propertyName,
                        email: singleBooking?.travelerId?.email,
                        travelerFirstName: singleBooking?.travelerId?.firstName,
                        travelerLastName: singleBooking?.travelerId?.lastName
                    }

                    sendAcceptAmendBookingEmail(payload);

                    if (isBooker()) {
                        const payload = {
                            firstName: singleBooking?.bookerUserId?.firstName,
                            lastName: singleBooking?.bookerUserId?.lastName,
                            propertyName: singleBooking?.propertyId?.propertyName,
                            email: singleBooking?.bookerUserId?.email,
                            travelerFirstName: singleBooking?.travelerId?.firstName,
                            travelerLastName: singleBooking?.travelerId?.lastName
                        }

                        sendAcceptAmendBookingEmail(payload);
                    }
                    if (!isBooker()) {
                        const payload = {
                            firstName: singleBooking?.bookerUserId?.firstName,
                            lastName: singleBooking?.bookerUserId?.lastName,
                            propertyName: singleBooking?.propertyId?.propertyName,
                            email: singleBooking?.bookerUserId?.email,
                            travelerFirstName: singleBooking?.travelerId?.firstName,
                            travelerLastName: singleBooking?.travelerId?.lastName
                        }

                        sendAcceptAmendBookingEmail(payload);
                    }


                }
            } else {
                if (singleBooking?.bookerUserId?.email === singleBooking?.travelerId?.email) {
                    const payload = {
                        firstName: singleBooking?.bookerUserId?.firstName,
                        lastName: singleBooking?.bookerUserId?.lastName,
                        propertyName: singleBooking?.propertyId?.propertyName,
                        email: singleBooking?.bookerUserId?.email,
                        travelerFirstName: singleBooking?.travelerId?.firstName,
                        travelerLastName: singleBooking?.travelerId?.lastName
                    }

                    sendNoAcceptAmendBookingEmail(payload);

                } else {
                    const payload = {
                        firstName: singleBooking?.travelerId?.firstName,
                        lastName: singleBooking?.travelerId?.lastName,
                        propertyName: singleBooking?.propertyId?.propertyName,
                        email: singleBooking?.travelerId?.email,
                        travelerFirstName: singleBooking?.travelerId?.firstName,
                        travelerLastName: singleBooking?.travelerId?.lastName
                    }

                    sendNoAcceptAmendBookingEmail(payload);
                    if (isBooker()) {
                        const payload = {
                            firstName: singleBooking?.bookerUserId?.firstName,
                            lastName: singleBooking?.bookerUserId?.lastName,
                            propertyName: singleBooking?.propertyId?.propertyName,
                            email: singleBooking?.bookerUserId?.email,
                            travelerFirstName: singleBooking?.travelerId?.firstName,
                            travelerLastName: singleBooking?.travelerId?.lastName
                        }

                        sendNoAcceptAmendBookingEmail(payload);
                    }
                    if (!isBooker()) {
                        const payload = {
                            firstName: singleBooking?.bookerUserId?.firstName,
                            lastName: singleBooking?.bookerUserId?.lastName,
                            propertyName: singleBooking?.propertyId?.propertyName,
                            email: singleBooking?.bookerUserId?.email,
                            travelerFirstName: singleBooking?.travelerId?.firstName,
                            travelerLastName: singleBooking?.travelerId?.lastName
                        }

                        sendNoAcceptAmendBookingEmail(payload);
                    }


                }
            }
            // if (acceptChange === CommonAnswer.YES) {
            //     if (singleBooking?.bookerUserId?.email === singleBooking?.travelerId?.email) {
            //         const payload = {
            //             firstName: singleBooking?.bookerUserId?.firstName,
            //             lastName: singleBooking?.bookerUserId?.lastName,
            //             propertyName: singleBooking?.propertyId?.propertyName,
            //             email: singleBooking?.bookerUserId?.email
            //         }

            //         sendAcceptAmendBookingEmail(payload);

            //     } else {
            //         const payload = {
            //             firstName: singleBooking?.bookerUserId?.firstName,
            //             lastName: singleBooking?.bookerUserId?.lastName,
            //             propertyName: singleBooking?.propertyId?.propertyName,
            //             email: singleBooking?.travelerId?.email
            //         }

            //         sendAcceptAmendBookingEmail(payload);
            //         if (isBooker()) {
            //             const payload = {
            //                 firstName: singleBooking?.bookerUserId?.firstName,
            //                 lastName: singleBooking?.bookerUserId?.lastName,
            //                 propertyName: singleBooking?.propertyId?.propertyName,
            //                 email: singleBooking?.bookerUserId?.email
            //             }

            //             sendAcceptAmendBookingEmail(payload);
            //         }
            //         if (!isBooker()) {
            //             const payload = {
            //                 firstName: singleBooking?.bookerUserId?.firstName,
            //                 lastName: singleBooking?.bookerUserId?.lastName,
            //                 propertyName: singleBooking?.propertyId?.propertyName,
            //                 email: singleBooking?.bookerUserId?.email
            //             }

            //             sendAcceptAmendBookingEmail(payload);
            //         }


            //     }
            // } else {
            //     if (singleBooking?.bookerUserId?.email === singleBooking?.travelerId?.email) {
            //         const payload = {
            //             firstName: singleBooking?.bookerUserId?.firstName,
            //             lastName: singleBooking?.bookerUserId?.lastName,
            //             propertyName: singleBooking?.propertyId?.propertyName,
            //             email: singleBooking?.bookerUserId?.email
            //         }

            //         sendNoAcceptAmendBookingEmail(payload);

            //     } else {
            //         const payload = {
            //             firstName: singleBooking?.travelerId?.firstName,
            //             lastName: singleBooking?.travelerId?.lastName,
            //             propertyName: singleBooking?.propertyId?.propertyName,
            //             email: singleBooking?.travelerId?.email
            //         }

            //         sendNoAcceptAmendBookingEmail(payload);
            //         if (isBooker()) {
            //             const payload = {
            //                 firstName: singleBooking?.bookerUserId?.firstName,
            //                 lastName: singleBooking?.bookerUserId?.lastName,
            //                 propertyName: singleBooking?.propertyId?.propertyName,
            //                 email: singleBooking?.bookerUserId?.email
            //             }

            //             sendNoAcceptAmendBookingEmail(payload);
            //         }
            //         if (!isBooker()) {
            //             const payload = {
            //                 firstName: singleBooking?.bookerUserId?.firstName,
            //                 lastName: singleBooking?.bookerUserId?.lastName,
            //                 propertyName: singleBooking?.propertyId?.propertyName,
            //                 email: singleBooking?.bookerUserId?.email
            //             }

            //             sendNoAcceptAmendBookingEmail(payload);
            //         }


            //     }
            // }

            BackToList();

        }

        if (getAcceptAmendBookingRequestError) {
            setLoading(false);
            toast.error(getAcceptAmendBookingRequestError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }

        if (sendAcceptAmendBookingEmailError) {
            setLoading(false);
            toast.error(sendAcceptAmendBookingEmailError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }

        if (sendNoAcceptAmendBookingEmailError) {
            setLoading(false);
            toast.error(sendNoAcceptAmendBookingEmailError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }


        if (amendBookingCancelWithChargeError?.data) {
            setLoading(false);
            toast.error(amendBookingCancelWithChargeError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }, [BackToList, acceptChange, amendBookingCancelWithChargeError?.data, amendBookingCancelWithChargeError?.message, getAcceptAmendBookingRequestError, getAcceptAmendBookingRequestSuccess?.data, sendAcceptAmendBookingEmail, sendAcceptAmendBookingEmailError, sendNoAcceptAmendBookingEmail, sendNoAcceptAmendBookingEmailError, singleBooking?.bookerUserId?.email, singleBooking?.bookerUserId?.firstName, singleBooking?.bookerUserId?.lastName, singleBooking?.propertyId?.propertyName, singleBooking?.travelerId?.email, singleBooking?.travelerId?.firstName, singleBooking?.travelerId?.lastName])


    /**
     * @description get changed value
     * @param e 
     */
    const onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'yes') {
            setYesNoHide(true);
        } else {
            setYesNoHide(false);
        }
        setCancellationPolicy(e.target.value);
    };


    const disabledFromDate = useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            (minDate != null && !d.isAfter(minDate) && d.isBefore(minDate, 'day'))
        );
    }, [minDate]);

    /**
     * get date range
     */
    const onFromDateChange = useCallback((dates, dateStrings) => {
        setCheckInCharge(moment(new Date(dateStrings[0])).format());
        setCheckOutCharge(moment(new Date(dateStrings[1])).format());
    }, []);


    const onFromAmendDateChange = useCallback((dates, dateStrings) => {
        setAmendCheckInCharge(moment(new Date(dateStrings[0])).format());
        setAmendCheckOutCharge(moment(new Date(dateStrings[1])).format());
    }, []);
    /**
     * onChangeConfirmChargeInput
     */
    const onChangeConfirmChargeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmNumberOfNight(Number(e.target.value));
    }, []);



    const onChangeConfirmAmendChargeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setAmendNumberOfNight(Number(e.target.value));
    }, []);


    const onAcceptChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'yes') {
            setAcceptChange(CommonAnswer.YES);
        } else {
            setAcceptChange(CommonAnswer.NO);
        }
    };



    const onChargeChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'yes') {
            setDoesChangeIncurCancellationCharge(CommonAnswer.YES);
        } else {
            setDoesChangeIncurCancellationCharge(CommonAnswer.NO);
        }
    };

    const onAvailableChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'yes') {
            setIsAvailable(CommonAnswer.YES);
        } else {
            setIsAvailable(CommonAnswer.NO);
        }
    };

    const onChangeFeedback = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAcceptChangeFeedback(e.target.value);
    };



    const acceptAmendBookingWithCharge = () => {
        if (doesChangeIncurCancellationCharge === CommonAnswer.YES) {
            if (!checkInCharge && !checkOutCharge) {
                toast.warning(`Please provide the dates for which there will be a cancellation charge`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                return 0;
            }

            if (!confirmNumberOfNight) {
                toast.warning(`Please confirm the number of nights being charged`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                return 0;
            }

            /**
             * check & compare number of night
             */
            const numberOfNight = daysBetween(new Date(checkInCharge), new Date(checkOutCharge));

            if (numberOfNight !== confirmNumberOfNight) {
                toast.error(`Confirm the number of nights is not match with cancellation charge dates. Please check confirm the number of nights`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                return 0;
            }
            const payload = {
                clientId: singleBooking?.clientId?._id,
                programmeId: singleBooking?.programmeId?._id,
                brandId: singleBooking?.brandId?._id,
                inventoryId: singleBooking?.liveBookingId,
                propertyId: singleBooking?.propertyId?._id,
                bookerId: singleBooking?.bookerUserId?._id,
                bookingStatus: LIVE_BOOKING_STATUS.AMEND_PENDING,
                acceptChange: acceptChange,
                doesChangeIncurCancellationCharge: doesChangeIncurCancellationCharge,
                checkInChargeDate: checkInCharge,
                checkOutChargeDate: checkOutCharge,
                numberOfNightWithCharge: confirmNumberOfNight
            }

            setLoading(true);
            bookingCancelWithCharge(payload);
        }
    };


    const acceptAmendBooking = () => {

        const payload = {
            clientId: singleBooking?.clientId?._id,
            programmeId: singleBooking?.programmeId?._id,
            brandId: singleBooking?.brandId?._id,
            inventoryId: singleBooking?.liveBookingId,
            propertyId: singleBooking?.propertyId?._id,
            propertyName: singleBooking?.propertyId?.propertyName,
            city: singleBooking?.propertyId?.city,
            bookerId: singleBooking?.bookerUserId?._id,
            bookingStatus: LIVE_BOOKING_STATUS.UP_COMING,
            acceptChange: acceptChange,
            acceptChangeFeedback: acceptChangeFeedback,
            doesChangeIncurCancellationCharge: doesChangeIncurCancellationCharge,
        }


        setLoading(true);
        acceptAmendBookingRequest(payload);

    };


    const confirm = () => {

        const payload = {
            clientId: singleBooking?.clientId?._id,
            programmeId: singleBooking?.programmeId?._id,
            brandId: singleBooking?.brandId?._id,
            // inventoryId: singleBooking?.inventoryId?._id,
            propertyId: singleBooking?.propertyId?._id,
            bookerId: singleBooking?.bookerUserId?._id,
            bookingStatus: LIVE_BOOKING_STATUS.UP_COMING,
            isAcceptAmendmentByOperator: true,
            id: singleBooking?.liveBookingId
        }


        setLoading(true);
        bookingStatus(payload);

    };


    // console.log(singleBooking, 'singleBooking')


    const amendCancelWithCharge = () => {
        /**
         * with charge
         */

        if (!amendCheckInCharge && !amendCheckOutCharge) {
            toast.warning(`Please provide the dates for which there will be a cancellation charge`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            return 0;
        }

        if (!amendNumberOfNight) {
            toast.warning(`Please confirm the number of nights being charged`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            return 0;
        }

        /**
         * check & compare number of night
         */
        const numberOfNight = daysBetween(new Date(amendCheckInCharge), new Date(amendCheckOutCharge));

        if (numberOfNight !== amendNumberOfNight) {
            toast.error(`Confirm the number of nights is not match with cancellation charge dates. Please check confirm the number of nights`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            return 0;
        }

        const payload = {
            clientId: singleBooking?.clientId?._id,
            programmeId: singleBooking?.programmeId?._id,
            brandId: singleBooking?.brandId?._id,
            inventoryId: singleBooking?.liveBookingId,
            propertyId: singleBooking?.propertyId?._id,
            bookerId: singleBooking?.bookerUserId?._id,
            bookingStatus: LIVE_BOOKING_STATUS.CANCELLED,
            amendCheckInChargeDate: amendCheckInCharge,
            amendCheckOutChargeDate: amendCheckOutCharge,
            numberOfNightWithCharge: singleBooking?.numberOfNightWithCharge,
            amendNumberOfNightWithCharge: amendNumberOfNight,
            brandName: singleBooking?.brandId?.name,
            propertyName: singleBooking?.propertyId?.propertyName,
            travelerName: singleBooking?.travelerId?.firstName + ' ' + singleBooking?.travelerId?.lastName,
            travelerFirstName: singleBooking?.travelerId?.firstName,
            travelerLastName: singleBooking?.travelerId?.lastName,
            toEmail: singleBooking?.bookerUserId?.email,
            bookerFirstName: singleBooking?.bookerUserId?.firstName,
            bookerLastName: singleBooking?.bookerUserId?.lastName,
        }
        setLoading(true);
        // console.log(payload, 'payload')
        amendBookingCancelWithCharge(payload);



        // }

    };



    const reinstateBooking = () => {


        const payload = {
            clientId: singleBooking?.clientId?._id,
            programmeId: singleBooking?.programmeId?._id,
            brandId: singleBooking?.brandId?._id,
            inventoryId: singleBooking?.liveBookingId,
            propertyId: singleBooking?.propertyId?._id,
            bookerId: singleBooking?.bookerUserId?._id,
            bookingStatus: LIVE_BOOKING_STATUS.UP_COMING,

            brandName: singleBooking?.brandId?.name,
            propertyName: singleBooking?.propertyId?.propertyName,
            travelerName: singleBooking?.travelerId?.firstName + ' ' + singleBooking?.travelerId?.lastName,
            travelerFirstName: singleBooking?.travelerId?.firstName,
            travelerLastName: singleBooking?.travelerId?.lastName,
            toEmail: singleBooking?.bookerUserId?.email,
            bookerFirstName: singleBooking?.bookerUserId?.firstName,
            bookerLastName: singleBooking?.bookerUserId?.lastName,
        }
        setReloading(true);

        reinstateBookingRequest(payload);



        // }

    };

    return (<>
        <Row >
            <Col md={6}>

                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-property-booking-reports" }} >Booking Detail</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{singleBooking?.propertyId?.propertyName}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col md={6}>
                <Button id='blue-button' style={{ width: '190px', marginLeft: '325px' }} >
                    <b>Message Traveller</b>
                </Button>
                <Button id='megenta-button' className='edit_btn_wpr2' style={{ width: '190px', marginLeft: '15px' }} >
                    <b>Upload Invoice</b>
                </Button>
            </Col>
        </Row>
        <Row className="mt-5">
            <Col md={3} xs={12} className="text-center">
                <Image width={300} src={singleBooking?.inventoryInfo?.inventoryImage} alt={singleBooking?.inventoryInfo?.inventoryImage} style={slideImageStyle}>
                </Image>
                {/* <img width={'100%'} src={singleBooking?.inventoryInfo?.inventoryImage} alt={singleBooking?.inventoryInfo?.inventoryImage} style={{ borderRadius: 40, height: '70%' }} /> */}
            </Col>
            <Col md={6} xs={12}>
                <div className="">
                    <p style={{ fontSize: '25px' }}>Booking Ref. <strong>{singleBooking?.liveBookingId}</strong></p>
                    <p style={{ fontSize: '30px' }}><strong>{singleBooking?.propertyId?.propertyName}</strong> <span style={{ fontSize: '20px' }}>by {singleBooking?.brandId?.name}</span></p>
                    <p style={{ fontSize: '22px' }}><strong>{singleBooking?.propertyInfo?.propertyType}</strong> <span style={{ fontSize: '20px' }}>{`${singleBooking?.propertyId?.address1} ${singleBooking?.propertyId?.address2}`}</span></p>
                    <p style={{ fontSize: '20px' }}>{`${singleBooking?.inventoryInfo?.accommodationConfiguration}`}</p>
                </div>
            </Col>
        </Row>

        {/* cancelation charge request when status changed as an cancel pending */}
        {
            (singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCEL_PENDING && !singleBooking?.checkInChargeDate) && <Row style={{ marginBottom: 100 }}>
                <Container fluid className="p-0">
                    <div className="jumbotron" style={{ marginTop: "50px" }}>
                        <div style={{ fontSize: '26px', }}>

                            <Row>
                                <Col md={2}></Col>
                                <Col md={6}>
                                    <strong>Cancellation Request - received <span> {moment(singleBooking?.cancelRequestDate).format('DD MMMM')}</span><span> {momentTimezone(singleBooking?.cancelRequestDate).tz(singleBooking?.propertyId?.timezone).format('HH:mm')} </span> </strong>
                                </Col>
                                <Col md={4}>
                                </Col>
                            </Row>
                            <div style={{ fontSize: '26px', marginTop: 35 }}>
                                <Row>
                                    <Col md={2}></Col>
                                    <Col md={4}>
                                        <strong>
                                            Is there a cancellation charge?
                                        </strong>
                                    </Col>
                                    <Col md={2}>
                                        <CheckAndRadioBtn type='radio' label="Yes" name="cancelCheckbox" value={`yes`} onChange={onChangeCheckBox} loading={false} />
                                        <CheckAndRadioBtn type='radio' label="No" name="cancelCheckbox" value={`no`} onChange={onChangeCheckBox} loading={false} />
                                    </Col>
                                    <Col md={4}></Col>
                                </Row>
                                {
                                    yesNoHide && <>
                                        <Row style={{ marginTop: '2%', fontSize: '22px', textAlign: 'left' }}>
                                            <Col md={12}>
                                                <p> Please provide the dates for which there will be a cancellation charge </p>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left' }}>
                                            <Col md={12}>
                                                <RangePicker className="font-light" suffixIcon={<div></div>} style={{
                                                    backgroundColor: 'white', borderRadius: '11px', paddingLeft: '50px',
                                                    height: '46px'
                                                }}
                                                    autoFocus={true}
                                                    placeholder={["Check-in charge", "Check-out charge"]}
                                                    disabledDate={disabledFromDate}
                                                    onChange={onFromDateChange}
                                                    defaultValue={[moment(new Date(singleBooking?.checkInDate), 'YYYY-MM-DD'), moment(new Date(singleBooking?.checkOutDate), 'YYYY-MM-DD')]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '2%', textAlign: 'left' }}>
                                            <Col md={5}>
                                                <p style={{ fontSize: '22px' }}>Please confirm the number of nights being charged</p>
                                            </Col>
                                            <Col md={6}>
                                                <input style={{
                                                    borderRadius: '11px',
                                                    paddingLeft: '50px',
                                                    height: '46px',
                                                    width: '150px'
                                                }}
                                                    onChange={onChangeConfirmChargeInput}
                                                    type="number"
                                                    min={0}
                                                    className="form-control" />
                                            </Col>
                                            <Col md={1}></Col>
                                        </Row>
                                    </>
                                }

                                <Row>
                                    <Col md={8}></Col>
                                    <Col md={4}>
                                        <ButtonC loading={loading} id="green-button" label={`Confirm Cancel`} onClick={cancel} />
                                    </Col>
                                </Row>

                                <div>

                                </div>
                            </div>
                        </div>

                    </div>
                </Container>
            </Row>
        }

        {/* cancel when status changed as an cancel */}
        {/* {
            singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED && singleBooking?.amendNumberOfNightWithCharge && <Row className="mt-5">
                <Container fluid className="p-0">
                    <div className="jumbotron" style={{ marginTop: "0px" }}>
                        <div className="text-center" style={{ fontSize: '26px', }}>
                            <Row>
                                <Col md={2}></Col>
                                <Col md={8}>
                                    <strong>Booking Cancelled </strong>
                                </Col>
                                <Col md={2}>
                                </Col>
                            </Row>
                            <div className="text-center" style={{ fontSize: '26px', marginTop: 35 }}>
                                <Row>
                                    <Col md={2}></Col>
                                    <Col md={8}>
                                        <strong>
                                            Nights charged {(typeof singleBooking?.amendNumberOfNightWithCharge === 'undefined' ? '0' : `${singleBooking?.amendNumberOfNightWithCharge}`)}
                                        </strong>
                                    </Col>
                                    <Col md={2}></Col>
                                </Row>

                                <div>

                                </div>
                            </div>
                        </div>

                    </div>
                </Container>
            </Row>
        } */}

        {
            (singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED) && <Row className="mt-5">
                <Container fluid className="p-0">
                    <div className="jumbotron" >
                        <div style={{ fontSize: '26px', }} >
                            <Row style={{ textAlign: 'center', marginBottom: '50px' }}>
                                <Col md={4}></Col>
                                <Col md={4}>
                                    <strong>Cancelled</strong>
                                </Col>
                                <Col md={4}></Col>

                            </Row>

                            <Row >

                                <Col md={8} style={{ fontSize: '20px', }}>
                                    <strong>
                                        No. of nights Charged:  {(typeof singleBooking?.numberOfNightWithCharge === 'undefined' ? '0' : `${singleBooking?.numberOfNightWithCharge}`)}
                                    </strong>

                                </Col>
                                <Col md={2} style={{ marginLeft: '-600px', marginTop: '-26px' }}>
                                    {!showButton && <ButtonC loading={loading} id='edit-button' label={`Amend`} onClick={() => { setShowAmendCancel(true) }} />}


                                </Col>
                                <Col md={2} style={{ marginLeft: '-15px', marginTop: '-26px' }}>

                                    {!showButton && <ButtonC loading={loading} id="green-button" label={`Reinstate Booking`} onClick={reinstateBooking} />}

                                </Col>

                            </Row>
                            <div>


                                <div>

                                </div>
                            </div>
                        </div>

                        {showAmendCancel && <div style={{ fontSize: '26px', }}>


                            <div style={{ fontSize: '26px', marginTop: 35 }}>

                                {
                                    <>
                                        <Row style={{ marginTop: '2%', fontSize: '22px', textAlign: 'left' }}>
                                            <Col md={12}>
                                                <p> Please provide the dates for which there will be a cancellation charge </p>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: 'left' }}>
                                            <Col md={12}>
                                                <RangePicker className="font-light" suffixIcon={<div></div>} style={{
                                                    backgroundColor: 'white', borderRadius: '11px', paddingLeft: '50px',
                                                    height: '46px'
                                                }}
                                                    autoFocus={true}
                                                    placeholder={["Check-in charge", "Check-out charge"]}
                                                    disabledDate={disabledFromDate}
                                                    onChange={onFromAmendDateChange}
                                                    defaultValue={[moment(new Date(singleBooking?.checkInChargeDate), 'YYYY-MM-DD'), moment(new Date(singleBooking?.checkOutChargeDate), 'YYYY-MM-DD')]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '2%', textAlign: 'left' }}>
                                            <Col md={5}>
                                                <p style={{ fontSize: '22px' }}>Please confirm the number of nights being charged</p>
                                            </Col>
                                            <Col md={6}>
                                                <input style={{
                                                    borderRadius: '11px',
                                                    paddingLeft: '50px',
                                                    height: '46px',
                                                    width: '150px'
                                                }}
                                                    onChange={onChangeConfirmAmendChargeInput}
                                                    type="number"
                                                    min={0}
                                                    className="form-control" />
                                            </Col>
                                            <Col md={1}></Col>
                                        </Row>
                                    </>
                                }

                                <Row>
                                    <Col md={8}></Col>
                                    <Col md={4}>
                                        <ButtonC loading={loading} id="green-button" label={`Confirm`} onClick={amendCancelWithCharge} />
                                    </Col>
                                </Row>

                                <div>

                                </div>
                            </div>
                        </div>}

                    </div>
                </Container>
            </Row>
        }



        {singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.AMEND_PENDING && showAccept && !singleBooking?.doesChangeIncurCancellationCharge && <Row style={{}}>
            <Container fluid className="p-0">
                <div className="jumbotron" style={{ marginTop: "" }}>
                    <div className="">
                        <h3>Booking Amendment Request - Pending</h3>
                        <div style={{ fontSize: '20px', marginTop: 35 }}>


                            <Row className="mt-5">
                                {/* <Col md={2}></Col> */}
                                <Col md={6}>
                                    <p>
                                        New Check-in: {singleBooking?.newCheckInAmendDate ? moment(singleBooking?.newCheckInAmendDate).format('D MMM YYYY') : ''}</p>
                                </Col>
                                <Col md={6}>
                                    <p>
                                        Original Check-in: {moment(singleBooking?.checkInDate).format('D MMM YYYY')}</p>
                                </Col>

                            </Row>
                            <Row >
                                {/* <Col md={2}></Col> */}
                                <Col md={6}>
                                    <p>
                                        New Check-out: {singleBooking?.newCheckOutAmendDate ? moment(singleBooking?.newCheckOutAmendDate).format('D MMM YYYY') : ''}</p>
                                </Col>
                                <Col md={6}>
                                    <p>
                                        Original Check-out: {moment(singleBooking?.checkOutDate).format('D MMM YYYY')}</p>
                                </Col>

                            </Row>

                            <Row>

                                <Col md={4}>
                                    <p>
                                        Accept change
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <CheckAndRadioBtn type='radio' label="Yes" name="acceptCheckbox" value={`yes`} onChange={onAcceptChangeCheckBox} loading={false} />
                                    <CheckAndRadioBtn type='radio' label="No" name="acceptCheckbox" value={`no`} onChange={onAcceptChangeCheckBox} loading={false} />
                                </Col>
                                <Col md={4}></Col>
                            </Row>

                            {acceptChange === CommonAnswer.NO && <Row>
                                <Col md={6}>
                                    <TextAreaC name={"acceptChangeFeedback"} onChange={onChangeFeedback}></TextAreaC>
                                </Col>
                            </Row>}

                            {acceptChange === CommonAnswer.YES &&
                                <Row>

                                    <Col md={4}>
                                        <p>
                                            Does the change incur a cancellation charge
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <CheckAndRadioBtn type='radio' label="Yes" name="chargeCheckbox" value={`yes`} onChange={onChargeChangeCheckBox} loading={false} />
                                        <CheckAndRadioBtn type='radio' label="No" name="chargeCheckbox" value={`no`} onChange={onChargeChangeCheckBox} loading={false} />
                                    </Col>
                                    <Col md={4}></Col>
                                </Row>}


                            {
                                doesChangeIncurCancellationCharge === CommonAnswer.YES && <>
                                    <Row style={{ marginTop: '2%', fontSize: '22px', textAlign: 'left' }}>
                                        <Col md={12}>
                                            <p> Please provide the dates for which there will be a cancellation charge </p>
                                        </Col>
                                    </Row>
                                    <Row style={{ textAlign: 'left' }}>
                                        <Col md={12}>
                                            <RangePicker className="font-light" suffixIcon={<div></div>} style={{
                                                backgroundColor: 'white', borderRadius: '11px', paddingLeft: '50px',
                                                height: '46px'
                                            }}
                                                autoFocus={true}
                                                placeholder={["Check-in charge", "Check-out charge"]}
                                                disabledDate={disabledFromDate}
                                                onChange={onFromDateChange}
                                                defaultValue={[moment(new Date(singleBooking?.checkInDate), 'YYYY-MM-DD'), moment(new Date(singleBooking?.checkOutDate), 'YYYY-MM-DD')]}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '2%', textAlign: 'left' }}>
                                        <Col md={5}>
                                            <p style={{ fontSize: '22px' }}>Please confirm the number of nights being charged</p>
                                        </Col>
                                        <Col md={6}>
                                            <input style={{
                                                borderRadius: '11px',
                                                paddingLeft: '50px',
                                                height: '46px',
                                                width: '150px'
                                            }}
                                                onChange={onChangeConfirmChargeInput}
                                                type="number"
                                                min={0}
                                                className="form-control" />
                                        </Col>
                                        <Col md={1}></Col>
                                    </Row>
                                </>
                            }


                            <Row>

                                <Col md={4}>
                                    {(doesChangeIncurCancellationCharge === CommonAnswer.NO || acceptChange === CommonAnswer.NO) && <ButtonC loading={loading} id="green-button" label={`Submit`} onClick={acceptAmendBooking} />}
                                    {doesChangeIncurCancellationCharge === CommonAnswer.YES && <ButtonC loading={loading} id="green-button" label={`Submit`} onClick={acceptAmendBookingWithCharge} />}
                                    <Button id='edit-button' style={{ width: '190px', marginLeft: '15px' }} onClick={() => { setShowAccept(false) }} >
                                        <b>Close</b>
                                    </Button>
                                </Col>

                            </Row>

                        </div>
                    </div>

                </div>
            </Container>
        </Row>}


        {singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.AMEND_PENDING && showAccept && singleBooking?.isAcceptAmendmentTerm && <Row style={{}}>
            <Container fluid className="p-0">
                <div className="jumbotron" style={{ marginTop: "" }}>
                    <div className="">
                        <h3>Amendment accepted</h3>
                        <div style={{ fontSize: '20px', marginTop: 35 }}>


                            <Row className="mt-5">
                                <p>The traveller has accepted the terms of the amendment.</p>

                            </Row>


                            {
                                <Row>

                                    <Col md={4}>
                                        <p>
                                            Is the amendment still available
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <CheckAndRadioBtn type='radio' label="Yes" name="chargeCheckbox" value={`yes`} onChange={onAvailableChangeCheckBox} loading={false} />
                                        <CheckAndRadioBtn type='radio' label="No" name="chargeCheckbox" value={`no`} onChange={onAvailableChangeCheckBox} loading={false} />
                                    </Col>
                                    <Col md={4}></Col>
                                </Row>}





                            <Row>

                                <Col md={4}>
                                    <ButtonC loading={loading} id="green-button" label={`Confirm`} onClick={confirm} />

                                    {/* <Button id='edit-button' style={{ width: '190px', marginLeft: '15px' }} onClick={() => { setShowAccept(false) }} >
                                        <b>Close</b>
                                    </Button> */}
                                </Col>

                            </Row>

                        </div>
                    </div>

                </div>
            </Container>
        </Row>}

        <Row className="mt-5">
            <Container fluid className="p-0">
                <div className="jumbotron" style={{ marginTop: "-20px" }}>
                    <div className="d-flex flex-row">
                        <div className="p-2">  <img width={'100%'} src={bookingdetails} alt={`booking details`} /></div>
                        <div className="p-2"> <h3> Booking Detail</h3></div>
                    </div>
                    <div className="mt-3">
                        <Row>
                            <Col md={6} xs={6}>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>First Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.travelerId?.firstName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Second Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.travelerId?.lastName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Email Address</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.travelerId?.email}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Booker First Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookerUserId?.firstName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Booker Second Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookerUserId?.lastName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Booker Email</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookerUserId?.email}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Date Booked</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{moment(singleBooking?.bookingDate).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Company</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.clientId?.companyName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Programme</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.programmeId?.programmeName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Accommodation</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.inventoryInfo?.accommodationDetail}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Number of Beds</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.inventoryInfo?.noOfBedrooms}</Col>
                                </Row>
                            </Col>

                            <Col md={6} xs={6}>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>No of Nights</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{daysBetween(new Date(singleBooking.checkInDate), new Date(singleBooking.checkOutDate))}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Average Nightly Rate (ex Tax)</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{`${singleBooking?.bookingRateAndPolicy?.dailyRate[0]?.rate} (${singleBooking?.propertyId?.currency})`}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Total Rate (ex Tax)</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{`${singleBooking?.bookingRateAndPolicy?.finalRate} (${singleBooking?.propertyId?.currency})`}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED ? `${LIVE_BOOKING_STATUS.CANCELLED}` :
                                        singleBooking?.acceptChange ? moment(singleBooking?.newCheckInAmendDate).format('D MMM YYYY') : moment(singleBooking?.checkInDate).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in Charge Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED ? `${LIVE_BOOKING_STATUS.CANCELLED}` : singleBooking?.checkInChargeDate ? moment(singleBooking?.checkInChargeDate).format('D MMM YYYY') : '-'}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-out Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED ? `${LIVE_BOOKING_STATUS.CANCELLED}` :
                                        singleBooking?.acceptChange ? moment(singleBooking?.newCheckOutAmendDate).format('D MMM YYYY') : moment(singleBooking?.checkOutDate).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-out Charge Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED ? `${LIVE_BOOKING_STATUS.CANCELLED}` : singleBooking?.checkOutChargeDate ? moment(singleBooking?.checkOutChargeDate).format('D MMM YYYY') : '-'}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Payment Method</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>Not Yet</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Type of Booking</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingType?.replace('_', " ")}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <Row style={{ marginTop: '10px' }}>
                <Col md={6}>
                    <Button id='edit-button' style={{ width: '190px', marginLeft: '15px' }} onClick={BackToList} >
                        <b>Close</b>
                    </Button>
                </Col>
                <Col md={6}>
                </Col>
            </Row>
        </Row >
    </>)

};

export default BookingSIngleView;