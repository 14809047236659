
import { useStoreActions, useStoreState } from "easy-peasy";
import { FC, useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import { setStorage } from "../../../../common/storage";
import { toast } from "react-toastify";

const SubmittedRfpVerticallyCenteredModal: FC<any> = ({
    setSelectedCity,
    setRefreshPropertiesList,
    filterBrandProperties,
    onHide,
    setShowLocations,
    selectedCity,
    cityList,
    // reduxBrandDetail,
    setIsLoadProperty,
    isLoadProperty,
    setComponentName,
    rfpData,
    selectedCityDetails,
    setDefaultTab,
    ...otherProps }) => {



    const [brandRfpProcessSelectedLocations, setBrandRfpProcessSelectedLocations] = useState<any>([]);
    const [brandRfpProcessSelectedCities, setBrandRfpProcessSelectedCities] = useState<any>([]);
    const [selectedProperties, setSelectedProperties] = useState<any>([]);
    const [brandPropertiesLocal, setBrandPropertiesLocal] = useState<any>([]);
    const [cityLocal, setCityLocal] = useState<any>([]);
    const [selectedCities, setSelectedCities] = useState<any>([]);
    // const history = useHistory();
    //actions
    const {
        getBrandRfpProcessSelectedLocationsById,
        // updateBrandRFPData,
        updateBrandRfpProcess,
        propertyCheckByLocation
    } = useStoreActions<any>((actions) => ({
        getBrandRfpProcessSelectedLocationsById: actions.rfpProcess.getBrandRfpProcessSelectedLocationsById,
        updateBrandRfpProcess: actions.rfpProcess.updateBrandRfpProcess,
        propertyCheckByLocation: actions.rfpProcess.propertyCheckByLocation
        // updateBrandRFPData: actions.rfpProcess.setBrandRfpDetails,
    }));

    //state
    const {
        // brandRfpDetails,
        brandRfpProcessSelectedLocationsByIdSuccess,
        propertyCheckByLocationSuccess
    } = useStoreState<any>((state) => ({
        propertyCheckByLocationSuccess: state.rfpProcess.propertyCheckByLocationSuccess,
        // brandRfpDetails: state.rfpProcess.brandRfpDetails,
        brandRfpProcessSelectedLocationsByIdSuccess: state.rfpProcess.brandRfpProcessSelectedLocationsByIdSuccess,

    }));
    const [brandRfpId] = useState<any>(rfpData?.key);
    const [rfpProcessId] = useState<any>(rfpData?.rfpId);

    const [selectedCountry] = useState<any>(selectedCityDetails?.selectedMyRfpCountry);

    const [locationObject] = useState<any>(rfpData?.locationsDetails);



    useEffect(() => {
        if (filterBrandProperties) {
            setBrandPropertiesLocal(filterBrandProperties);
        }
        if (cityList) {
            setCityLocal(cityList);
        }
    }, [cityList, filterBrandProperties]);
    //, setIsLoadProperty


    useEffect(() => {
        if (rfpData?.key) {
            getBrandRfpProcessSelectedLocationsById({
                id: rfpData?.key,
                rfpId: rfpData?.rfpId,
                country: selectedCityDetails?.selectedMyRfpCountry,
                city: selectedCityDetails?.rfpDetailSelectedCity,

            });
        }
    }, [getBrandRfpProcessSelectedLocationsById, rfpData?.key, rfpData?.rfpId, selectedCityDetails?.rfpDetailSelectedCity, selectedCityDetails?.selectedMyRfpCountry]);

    useEffect(() => {
        if (brandRfpProcessSelectedLocationsByIdSuccess) {
            if (brandRfpProcessSelectedLocationsByIdSuccess.data) {
                const data = brandRfpProcessSelectedLocationsByIdSuccess.data.map((item: any) => {
                    return item?.propertyId?._id
                });
                const city = brandRfpProcessSelectedLocationsByIdSuccess.data.map((item: any) => {
                    return item?.propertyId?.city
                });

                setBrandRfpProcessSelectedLocations(data);
                setBrandRfpProcessSelectedCities(city);

                setSelectedProperties(data);
                setSelectedCities(city);
            }
        }
    }, [brandRfpProcessSelectedLocationsByIdSuccess])


    useEffect(() => {
        if (brandRfpProcessSelectedLocations.length > 0) {
            const filteredProperties = filterBrandProperties;
            const addSelectedCheckToProperties = filteredProperties?.map((property: any) => {
                property.selected = brandRfpProcessSelectedLocations?.includes(property._id);

                return property;
            }
            );
            setBrandPropertiesLocal(addSelectedCheckToProperties);
        }

        if (brandRfpProcessSelectedCities.length > 0) {
            const filteredCities = cityList;
            const addSelectedCheckToCities = filteredCities?.map((acc: any) => {
                acc.selected = brandRfpProcessSelectedCities?.includes(acc._id);
                return acc;
            }
            );
            setCityLocal(addSelectedCheckToCities);

        }

    }, [brandRfpProcessSelectedCities, brandRfpProcessSelectedLocations, cityList, filterBrandProperties])


    useEffect(() => {
        if (propertyCheckByLocationSuccess) {
            if (propertyCheckByLocationSuccess?.data === null) {
                updateBrandRfpProcess({
                    id: brandRfpId,
                    propertiesToAdd: selectedProperties,
                    selectedCity: selectedCityDetails?.rfpDetailSelectedCity,
                    selectedCountry,
                    locationObject,
                    rfpProcessId,
                });
            } else {
                toast.warning("Property can only be added to one location for the purpose of the client’s appraisal", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
        }

    }, [brandRfpId, locationObject, propertyCheckByLocationSuccess, rfpProcessId, selectedCityDetails?.rfpDetailSelectedCity, selectedCountry, selectedProperties, updateBrandRfpProcess])

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleUpdateRfpProcess = useCallback(async () => {
        if (selectedProperties) {
            propertyCheckByLocation({
                id: brandRfpId,
                propertiesToAdd: selectedProperties,
                rfpProcessId,
                locationId: '63e8b6314c3e410017f99c38',
                cityId: selectedCityDetails?.rfpDetailSelectedCityDetails[0]?._id
            })

        }
        // setSelectedProperties([]);
        // setSelectedCities([]);

    }, [selectedProperties, selectedCityDetails, propertyCheckByLocation, brandRfpId, rfpProcessId])

    const handlePropertySelection = useCallback((event) => {
        const { name, checked } = event.target;
        if (checked) {
            setSelectedProperties([...selectedProperties, name]);
            const updateBrandProperty = brandPropertiesLocal.map(property => {
                return property._id === name ? { ...property, selected: true } : property;
            })
            setBrandPropertiesLocal([...updateBrandProperty]);
        } else {
            setSelectedProperties(selectedProperties.filter(property => property !== name));
            const updateBrandProperty = brandPropertiesLocal.map(property => {
                return property._id === name ? { ...property, selected: false } : property;
            })
            setBrandPropertiesLocal([...updateBrandProperty]);
        }

    }, [brandPropertiesLocal, selectedProperties]);

 
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleCitySelection = useCallback((event) => {
        const { name, checked } = event.target;
        if (checked) {
            setSelectedCities([...selectedCities, name]);
            const updateCity = cityLocal.map(city => {
                return city._id === name ? { ...city, selected: true } : city;
            })
            setCityLocal([...updateCity]);
        } else {
            setSelectedCities(selectedCities.filter(city => city !== name));
            const updateCity = cityLocal.map(city => {
                return city._id === name ? { ...city, selected: false } : city;
            })


            setCityLocal([...updateCity]);
        }

    }, [cityLocal, selectedCities]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const loadLocations = useCallback(() => {

        if (cityLocal?.length > 0) {
            const locations = cityLocal.map(
                (location) => (
                    <Row key={location._id}>
                        <Col>
                            <Form.Group key={location._id} className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check checked={location.selected} name={location._id} onChange={handleCitySelection} type="checkbox" label={location._id} />
                            </Form.Group>
                        </Col>
                        <Col>
                            {location.propertyName}
                        </Col>
                    </Row>

                )
            );
            return locations;
        }
    }, [cityLocal, handleCitySelection])


    const brandLocations = useCallback(() => {
        if (brandPropertiesLocal?.length > 0) {
            const locations = brandPropertiesLocal.map(
                (location) => (
                    <Row key={location._id}>
                        <Col>
                            <Form.Group key={location._id} className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check checked={location.selected} name={location._id} onChange={handlePropertySelection} type="checkbox" label={location.city} />
                            </Form.Group>
                        </Col>
                        <Col>
                            {location.propertyName}
                        </Col>
                    </Row>

                )
            );
            return locations;
        }

        return <div style={{
            marginTop: '50px',
            fontSize: '18px',
            textAlign: 'center',
        }} >Loading ....</div>;
    }, [brandPropertiesLocal, handlePropertySelection])

    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleClick = useCallback(() => {
        onHide();
        handleUpdateRfpProcess();
        setShowLocations(false)
        setIsLoadProperty(false);
        setRefreshPropertiesList(true);
        setTimeout(
            () => {
                setSelectedCity("");
            }, 3000
        )


    }, [handleUpdateRfpProcess, onHide, setIsLoadProperty, setRefreshPropertiesList, setSelectedCity, setShowLocations])


    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleLoadProperties = useCallback(() => {
        setIsLoadProperty(true);
        setStorage("filtered_Cities", selectedCities);
        setComponentName('singleMyRfp');
        setDefaultTab('properties');
        // updateBrandRFPData({
        //     ...reduxBrandDetail,
        //     showMyRfpBrandEvaluationPage: true,

        // })
        window.scrollTo(0, 0);

    }, [selectedCities, setComponentName, setDefaultTab, setIsLoadProperty])

    return (
        <Modal
            {...otherProps}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {isLoadProperty && brandPropertiesLocal ?
                <Modal.Body className="mdl" >
                    <h3 style={{ textAlign: 'center', marginTop: '10px', marginBottom: '30px', fontSize: '30px', fontWeight: 'bold' }} >Select Properties</h3>
                    <Row >
                        <Col className="mx-auto" style={{

                            height: '300px',
                            scrollBehavior: 'smooth',
                            overflow: 'auto',
                        }} md={6} >
                            <Form>
                                {
                                    brandLocations()
                                }
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ margin: '80px auto', display: 'flex', justifyContent: 'space-between' }} md={6}  >
                            <Button id='edit-button' onClick={handleClick} >Update</Button>
                            <Button id='edit-button' variant="secondary" onClick={onHide}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body> :
                <Modal.Body className="mdl" >
                    <h3 style={{ textAlign: 'center', marginTop: '10px', marginBottom: '30px', fontSize: '30px', fontWeight: 'bold' }} >Please choose the city that you wish to add properties for location {selectedCity}</h3>
                    <Row >
                        <Col className="mx-auto" style={{

                            height: '300px',
                            scrollBehavior: 'smooth',
                            overflow: 'auto',
                        }} md={6} >
                            <Form>
                                {
                                    cityLocal.length > 0 ? loadLocations() :

                                        <p style={{ textAlign: 'center', marginTop: '10px', marginBottom: '30px' }} >Your brand does not have properties for this client location</p>
                                }

                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ margin: '80px auto', display: 'flex', justifyContent: 'space-between' }} md={6}  >
                            <Button id='edit-button' disabled={selectedCities.length === 0 ? true : false} onClick={handleLoadProperties} >Load Properties</Button>
                            <Button id='edit-button' variant="secondary" onClick={onHide}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>

            }
        </Modal>
    );
}

export default SubmittedRfpVerticallyCenteredModal;