import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from 'react-data-table-component';

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { useStoreActions, useStoreState } from "easy-peasy";


import { $mysaRed, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, tableStyles } from "../../../../common/components-style";

import { getStorage } from "../../../../common/storage";
import moment from "moment";
import _ from "lodash";

import TravelerOptionsTravelerView from "./TravelerOptionsTravelerView";

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

/**
 * form type
 */


const TravelerOptionsTraveler: React.FunctionComponent<any> = ({ locationData }) => {




    /**
     * global actions
     */
    const { getTravelerOptionByTravelerId } = useStoreActions<any>((actions) => ({

        getTravelerOptionByTravelerId: actions.booking.getTravelerOptionByTravelerId
    }));

    /**
     * global status
     */
    const { getTravelerOptionByTravelerIdSuccess } = useStoreState<any>((state) => ({

        getTravelerOptionByTravelerIdSuccess: state.booking.getTravelerOptionByTravelerIdSuccess
    }));



    const [data, setData] = useState<any[]>([]);

    const [showView, setShowView] = useState<boolean>(locationData?.state?.isShowPage ? locationData?.state?.isShowPage : false);

    const [viewData, setViewData] = useState<any>(null);

    /**
     * side effect react life cycle
     */
    useEffect(() => {
        if (getStorage('myo_logged_user')?.userData?._id) {
            getTravelerOptionByTravelerId({ travelerId: getStorage('myo_logged_user')?.userData?._id });
        }


    }, [getTravelerOptionByTravelerId]);

   
    /**
     * response manage using react life cycle
     */
    useEffect(() => {
        const buildingData: any = [];
        if (getTravelerOptionByTravelerIdSuccess?.data) {
            _.map(getTravelerOptionByTravelerIdSuccess?.data, (data: any) => {

                data.selectedOptions?.forEach(element => {

                    const date1 = new Date(moment(new Date(element?.checkInDate)).format('YYYY-MM-DD'));
                    const date2 = new Date(moment(new Date(element?.checkOutDate)).format('YYYY-MM-DD'));
                    const Difference_In_Time = date2.getTime() - date1.getTime();

                    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

                    buildingData.push({
                        key: data?._id,
                        checkInDate: element?.checkInDate,
                        checkOutDate: element?.checkOutDate,
                        status: element?.status,
                        city: element?.city,
                        country: element?.country,
                        propertyName: element?.propertyId?.propertyName,
                        brand: element?.propertyId?.brandId?.name,
                        selectOptionId: element?._id,
                        propertyId: element?.propertyId,
                        inventoryId: element?.propertyInventoryId,
                        travelerId: data?.travelerId,
                        createdAt: data?.createdAt,
                        cacheKey: element?.cacheKey,
                        hashGet: element?.hashGet,
                        noOfNights: Difference_In_Days
                    })
                })

            })
            //table data


            const uniqueData = buildingData.filter((item, index, self) =>
                index === self.findIndex((obj) => obj.selectOptionId === item.selectOptionId)
            );

            setData(uniqueData);
            //table data

        }

    }, [getTravelerOptionByTravelerIdSuccess?.data]);

    const columns = [
        {
            name: 'Country',
            selector: row => row?.country,
        },
        {
            name: 'city',
            selector: row => row?.city,
        },
        {
            name: 'Brand',
            selector: row => row?.propertyId?.brandId?.name,
        },

        {
            name: 'Property',
            selector: row => row?.propertyId?.propertyName,
        },
        {
            name: 'Check in',
            selector: row => moment(row?.checkInDate).format('D MMM YY'),
        },
        {
            name: 'Check out',
            selector: row => moment(row?.checkOutDate).format('D MMM YY'),
        },

        {
            name: 'Status',
            cell: (row) => {
                let color = "";
                let status = "";
                if (row.status === 'pending') { color = $mysaYellow; status = 'Pending' };
                if (row.status === 'approved') { color = $mysaTeal; status = 'Approved' };
                if (row.status === 'declined') { color = $mysaRed; status = 'Declined' };
                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{status}</span>
                )
            }
        },
        {
            name: 'Action',
            // button: true,
            cell: (row: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                    >
                        <>
                            <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                                setShowView(true);
                                setViewData(row);
                                // handleClick(row);
                                // push(`/admin/booker-booking-report/${row.bookingReference}`);
                            }} />



                        </>
                    </OverlayTrigger>

                </>)
            },
        },
    ];

    return (
        <>

            {!showView && <div className='brnd_tbl' style={{ marginTop: '10px' }}>

                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    striped={true}
                    customStyles={tableStyles}
                    theme='mysaTable'
                    fixedHeader={true}
                    noDataComponent={'Updating records'}
                />
            </div>}
            {showView &&
                <div>
                    <TravelerOptionsTravelerView details={viewData} />
                </div>
            }
        </>
    );
};

export default TravelerOptionsTraveler;