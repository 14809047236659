import { Tabs } from 'antd';
import React, { useState } from 'react';
import AccommodationList from './form/AccommodationList';
import AmenitiesList from './form/AmenitiesList';
import BedTypeList from './form/BedTypeList';
import CheckInProcessList from './form/CheckInProcessList';
import RoomTypeList from './form/RoomTypeList';
import SectionContent from './form/sectionContent/SectionContent';
import PropertyManagementList from './form/PropertyManagementList';
import ChannelManagerList from './form/ChannelManagerList';
const { TabPane } = Tabs;


const SettingHome: React.FC = (): JSX.Element => {
  const [tabKey, setTabKey] = useState('details');

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="property-form">
      <Tabs tabPosition="left" className="mysa-sub-menu" onChange={(tab) => setTabKey(tab)} defaultActiveKey="details" key={tabKey} activeKey={tabKey} >
        <TabPane tab="Amenity Details" key="details">
          <AmenitiesList />
        </TabPane>
        <TabPane tab="Accommodation Details" key="accommodation">
          <AccommodationList />
        </TabPane>
        <TabPane tab="Bed Type Details" key="bed">
          <BedTypeList />
        </TabPane>
        <TabPane tab="Room Type Details" key="room">
          <RoomTypeList />
        </TabPane>
        <TabPane tab="Check-in Process Details" key="checkIn">
          <CheckInProcessList />
        </TabPane>
        <TabPane tab="Help" key="help">
          <SectionContent />
        </TabPane>
        <TabPane tab="Property Management System" key="propertyManagement">
          <PropertyManagementList />
        </TabPane>
        <TabPane tab="Channel Manager" key="channelManager">
          <ChannelManagerList />
        </TabPane>
      </Tabs>
    </div>
  )
};

export default SettingHome;