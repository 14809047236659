import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChannelManagerForm from './ChannelManagerForm';


const ChannelManagerList: React.FC<any> = (): JSX.Element => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [channelManagerList, setChannelManagerList] = useState<any>([]);
  const [channelManagerDetails, setChannelManagerDetails] = useState<any>(null);
  const [channelManagerId, settChannelManagerId] = useState<number | null>(null);

  ////////////////////////////////////////////////////////////////////////////////////////////
  const loadForm = useCallback((data) => {
    setFormOpen(true);
    setChannelManagerDetails(data);
    if (data !== null) {
      settChannelManagerId(data.key);
    } else {
      settChannelManagerId(null);
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getChannelManagerList, updateChannelManager } = useStoreActions<any>((actions) => ({
    getChannelManagerList: actions.property.getChannelManagerList,
    updateChannelManager: actions.property.updateChannelManager,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    getChannelManagerListSuccess,
    getChannelManagerListError,
    updateChannelManagerError,
    removeChannelManagerSuccess
  } = useStoreState<any>((state) => ({
    getChannelManagerListSuccess: state.property.getChannelManagerListSuccess,
    getChannelManagerListError: state.property.getChannelManagerListError,
    updateChannelManagerError: state.property.updateChannelManagerError,
    removeChannelManagerSuccess: state.property.removeChannelManagerSuccess,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getChannelManagerList();
  }, [getChannelManagerList]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getChannelManagerListSuccess) {
      setChannelManagerList(getChannelManagerListSuccess.data);
    }
    if (getChannelManagerListError) {
      message.error(getChannelManagerListError.message)
    }

    if (removeChannelManagerSuccess) {
      message.success('Property management removed successfully');
      getChannelManagerList();
    }

    if (updateChannelManagerError) {
      message.error(updateChannelManagerError.message)
    }

  }, [getChannelManagerList, getChannelManagerListError, getChannelManagerListSuccess, removeChannelManagerSuccess, updateChannelManagerError]);
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const removeData = useCallback((values) => {
    const data = {
      id: values.key,
      isDeleted: true
    }
    updateChannelManager(data);

  }, [updateChannelManager]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name', outerWidth: '75px' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Status',
      dataIndex: '',
      key: 'status  ',
      render: (data: any) => {

        return (<div className={`${data.status === 'active' ? 'text-green-500' : 'text-red-500'}`}>
          {data.status}
        </div>)
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'status',
      render: (data: any) => {
        return (<div>
          <Button onClick={() => loadForm(data)} className="border-none" ><EditOutlined /></Button>
          <Button onClick={() => removeData(data)} className="border-none" ><DeleteOutlined /></Button>
        </div>)
      },
    },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const data = useMemo(() => {
    return _.map(channelManagerList, channel => {
      return {
        key: channel._id,
        name: channel.name,
        description: channel.description,
        status: channel.status
      }
    })
  }, [channelManagerList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      <div className="flex justify-between">
        <p className="text-2xl mb-10">ChannelManager Details</p>
        <Button hidden={formOpen} onClick={() => { loadForm(null) }} className="btn_design m-1 text-white">Create New</Button>
        <Button hidden={!formOpen} onClick={() => setFormOpen(false)} className="btn_design m-1 text-white">Show List</Button>
      </div>
      {formOpen && < ChannelManagerForm setFormOpen={setFormOpen} channelManagerDetails={channelManagerDetails} channelManagerId={channelManagerId} />}
      <div className="mb-1" hidden={formOpen}>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  )
};

export default ChannelManagerList;