import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {  useStoreState } from 'easy-peasy';
import _ from 'lodash';
import { Link } from "react-router-dom";

const StatsCardsBrand: React.FC<any> = (): JSX.Element => {
    const [newRequestBookingCount, setNewRequestBookingCount] = useState<number>(0);
    const [requireDecisionBookingCount, setRequireDecisionBookingCount] = useState<number>(0);
    const [amendNewRequestCount, setAmendNewRequestCount] = useState<number>(0);
    const [amendRequireDecisionCount, setAmendRequireDecisionCount] = useState<number>(0);
    const [cancelNewRequestCount, setCancelNewRequestCount] = useState<number>(0);

    const { brandDashboardSuccess, brandDashboardError, getRequestBookingListByBrandSuccess } = useStoreState<any>((state) => ({
        brandDashboardSuccess: state.DashboardModel.brandDashboardSuccess,
        brandDashboardError: state.DashboardModel.brandDashboardError,
        getRequestBookingListByBrandSuccess: state.booking.getRequestBookingListByBrandSuccess
    }));

    useEffect(() => {
        if (brandDashboardError) {
            toast.error(brandDashboardError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (brandDashboardSuccess) {
            setNewRequestBookingCount(brandDashboardSuccess?.data?.requestBooking?.newRequestBookingCount);
            setRequireDecisionBookingCount(brandDashboardSuccess?.data?.requestBooking?.requireDecisionBookingCount);
            setAmendNewRequestCount(brandDashboardSuccess?.data?.liveBooking?.amendNewRequestCount);
            setAmendRequireDecisionCount(brandDashboardSuccess?.data?.liveBooking?.amendRequireDecisionCount);
            setCancelNewRequestCount(brandDashboardSuccess?.data?.liveBooking?.cancelNewRequestCount);
        }
    }, [brandDashboardError, brandDashboardSuccess, getRequestBookingListByBrandSuccess]);

    return (
        <>
            <div className='row'>
                <div className='col-md-12 col-lg-9'>
                    <div className='gtr-3 mt-5 brand_card_sec card_box_wrapper'>
                        <div className="card_box_sec" >
                            <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/brand-property-booking-reports", state : {tabKey : 'requestBooking'} }} >
                                    <h6>Request Bookings</h6>
                                    <div className="stat_ctnt">
                                        <ul className="booking_request_sec">
                                            <li className={newRequestBookingCount > 0 ? 'mysa-pink-text' : ''}><span className={newRequestBookingCount > 0 ? 'mysa-pink-text' : ''}>{newRequestBookingCount}</span> New Requests </li>
                                            <li className={requireDecisionBookingCount > 0 ? 'mysa-pink-text' : ''}><span className={requireDecisionBookingCount > 0 ? 'mysa-pink-text' : ''}>{requireDecisionBookingCount}</span> Require Decision </li>
                                        </ul>
                                    </div>
                                    
                            </Link>
                        </div>
                        <div className="card_box_sec">
                            <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/brand-property-booking-reports", state : {tabKey : 'propertyBookings'} }} >
                                <h6>Amendment Requests</h6>
                                <div className="stat_ctnt">
                                    <ul className="booking_request_sec">
                                        <li className={amendNewRequestCount > 0 ? 'mysa-pink-text' : ''}><span className={amendNewRequestCount > 0 ? 'mysa-pink-text' : ''}>{amendNewRequestCount}</span> New Requests </li>
                                        <li className={amendRequireDecisionCount > 0 ? 'mysa-pink-text' : ''}><span className={amendRequireDecisionCount > 0 ? 'mysa-pink-text' : ''}>{amendRequireDecisionCount}</span> Require Decision </li>
                                    </ul>
                                </div>
                            </Link>
                        </div>
                        <div className="card_box_sec">
                            <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/brand-property-booking-reports", state : {tabKey : 'propertyBookings'} }} >
                                    <h6>Cancellation Requests</h6>
                                    <div className="stat_ctnt">
                                        <ul className="booking_request_sec">
                                            <li className={cancelNewRequestCount > 0 ? 'mysa-pink-text' : ''}><span className={cancelNewRequestCount > 0 ? 'mysa-pink-text' : ''}>{cancelNewRequestCount}</span> New Requests </li>
                                        </ul>
                                    </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StatsCardsBrand;