import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import buisness from "../../../images/dashboard/business.svg";
import _ from 'lodash';
import { Link } from "react-router-dom";

const BrandCart: React.FC<any> = (): JSX.Element => {
    const [incompleteRfpsCount, setIncompleteRfpsCount] = useState<number>(0);
    const [submittedRfpsCount, setSubmittedRfpsCount] = useState<number>(0);
    const [rfpsReviewsCount, setrfpsReviewsCount] = useState<number>(0);
    const [rfpsAvaillableCount, setRfpsAvaillableCount] = useState<number>(0);

    const { brandDashboardSuccess, brandDashboardError, getRequestBookingListByBrandSuccess } = useStoreState<any>((state) => ({
        brandDashboardSuccess: state.DashboardModel.brandDashboardSuccess,
        brandDashboardError: state.DashboardModel.brandDashboardError,
        getRequestBookingListByBrandSuccess: state.booking.getRequestBookingListByBrandSuccess
    }));


    useEffect(() => {
        if (brandDashboardError) {
            toast.error(brandDashboardError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (brandDashboardSuccess) {
            setRfpsAvaillableCount(brandDashboardSuccess?.data?.rfpList?.rfpsCount?.totalAvailableCount?brandDashboardSuccess?.data?.rfpList?.rfpsCount?.totalAvailableCount:0);
            setIncompleteRfpsCount(brandDashboardSuccess?.data?.rfpCount?.activeRfpCount);
            setSubmittedRfpsCount(brandDashboardSuccess?.data?.rfpCount?.submittedRfpCount);
            setrfpsReviewsCount(brandDashboardSuccess?.data?.rfpCount?.reviewedCount);
        }
    }, [brandDashboardError, brandDashboardSuccess, getRequestBookingListByBrandSuccess]);

    return (
        <>
        <div className='row gtr-3 mt-4 brand_card_sec'>
            <div className="col-md-12 col-sm-12">
                <Link className="stat_crd stat_prg stat_crd_blue" to={{ pathname: "/admin/brand-rfp-management", state : {defaultKey : 'corporateRFPS'} }} >
                    <img src={buisness} alt='icon' />
                        <div className="stat_ctnt">
                            <div className="nmbr nmbr_height">{rfpsAvaillableCount}</div><h6>RFPs Available</h6>
                        </div>
                </Link>
            </div>
            <div className="col-md-12 col-sm-12">
                <Link className="stat_crd stat_prg stat_crd_yellow" to={{ pathname: "/admin/brand-rfp-management",state: {defaultKey: 'myRFPs' } }} >
                <img src={buisness} alt='icon' />
                    <div className="stat_ctnt">
                        <div className="nmbr nmbr_height">{incompleteRfpsCount}</div><h6>Incomplete RFPs</h6>
                    </div>
                </Link>
            </div>
            <div className="col-md-12 col-sm-12">
                <Link className="stat_crd stat_prg stat_crd_green" to={{ pathname: "/admin/brand-rfp-management", state : {defaultKey : 'submittedRFPs'} }} >
                    <img src={buisness} alt='icon' />
                        <div className="stat_ctnt">
                            <div className="nmbr nmbr_height">{submittedRfpsCount}</div><h6>Submitted RFPs</h6>
                        </div>
                </Link>
            </div>
            <div className="col-md-12 col-sm-12">
                <Link className="stat_crd stat_prg stat_crd_pink" to={{ pathname: "/admin/brand-rfp-management", state : {defaultKey : 'submittedRFPs'} }} >
                    <img src={buisness} alt='icon' />
                        <div className="stat_ctnt">
                            <div className="nmbr nmbr_height">{rfpsReviewsCount}</div><h6>RFP Reviews</h6>
                        </div>
                </Link>
            </div>
        </div>
        </>
    )
}

export default BrandCart;