import React, { useMemo } from 'react';
import _ from 'lodash';
import { setStorage } from '../../../common/storage';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { overlayTriggerDelay, tableStyles } from '../../../common/components-style';
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';

const LocationList: React.FC<any> = ({ loadForm, locations, onLocationSelect }): JSX.Element => {

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const columns = [
		{ name: 'Name of location ', selector: row => row.locationName},
		{ name: 'Country', selector: row => row.country},
		{ name: 'City', selector: row => row.city},
		{ name: 'Address1', selector: row => row.address1},
		{ name: 'Postal/Zip code', selector: row => row.postcode},
		{
			name: 'Action',
			dataIndex: '',
			key: 'x',
			cell: (row: any) => {
				return (<>
				  <OverlayTrigger
					placement="top"
					delay={overlayTriggerDelay}
					overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
				  >
					<img className='iconSizes' style={{ marginRight: '5%' }} src={ViewEdit} alt="" onClick={() => { loadForm(row); setStorage('myo_selected_location', row); onLocationSelect(row) }} />
				  </OverlayTrigger>
				</>)
			  },
		}
	];

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const data = useMemo(() => {
		return _.map(locations, prog => {
			return {
				key: prog._id,
				locationName: prog.locationName,
				country: prog.country,
				city: prog.city,
				address1: prog.address1,
				postcode: prog.postcode,
				programmeId: prog.corporateProgrammeId,
				clientId: prog.clientId
			}
		})
	}, [locations]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className="location-list">
			<DataTable
				columns={columns}
				data={data}
				pagination
				striped={true}
				customStyles={tableStyles}
				theme='mysaTable'
				fixedHeader={true}
				noDataComponent={'Updating records'}
			/>
		</div>
	)
};

export default LocationList;