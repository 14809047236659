import Form from 'react-bootstrap/Form';
import React from 'react';
import { Spinner } from 'react-bootstrap';

enum ECheck {
    checkbox = 'checkbox',
    radio = 'radio'
}

type TECheck = keyof typeof ECheck;

type TCheckAndRadioBtnProps = {
    type: TECheck;
    label: string;
    name: string;
    value: any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    loading: boolean
}

const CheckAndRadioBtn: React.FunctionComponent<TCheckAndRadioBtnProps> = ({ loading, type, label, name, value, onChange }) => {

    return (<>
        {
            !loading ? <Form.Check
                inline
                value={value}
                name={name}
                label={label}
                type={type}
                onChange={onChange}
            /> : <Spinner animation="border" variant="warning" />
        }

    </>);
};

export default CheckAndRadioBtn;