import React from "react";
import { Form } from "react-bootstrap";


type TSelectC = {
    name: string;
    register?: any;
    defaultValue?: any;
    data: Array<{
        value: string;
        label: string;
    }>;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
};

const SelectC: React.FunctionComponent<TSelectC> = ({ register, name, data, onChange, defaultValue }) => {
    return (<>
        <Form.Select aria-label={`select ${name}`} {...register && { ...register(`${name}`, { required: false }) }} value={defaultValue?._id} onChange={onChange}>
            <option value={``}>All {name}</option>
            {
                data.map((singleData, index) => {
                    return (<option key={index} value={`${singleData.value ? singleData.value : defaultValue?._id}`}>{`${singleData.label ? singleData.label : defaultValue?.programmeName}`}</option>)
                })
            }
        </Form.Select>
    </>);
}

export default SelectC;