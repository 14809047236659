/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import { call } from '../common/axios';


export default {
  getAccommodationListSuccess: null,
  getAccommodationListError: null,
  createAccommodationSuccess: null,
  createAccommodationError: null,
  updateAccommodationSuccess: null,
  updateAccommodationError: null,
  removeAccommodationSuccess: null,
  getBedTypeListSuccess: null,
  getBedTypeListError: null,
  createBedTypeSuccess: null,
  createBedTypeError: null,
  updateBedTypeSuccess: null,
  updateBedTypeError: null,
  removeBedTypeSuccess: null,
  getRoomTypeListSuccess: null,
  getRoomTypeListError: null,
  createRoomTypeSuccess: null,
  createRoomTypeError: null,
  updateRoomTypeSuccess: null,
  updateRoomTypeError: null,
  removeRoomTypeSuccess: null,
  reset: false,
  getInventoryAmenitiesSuccess: null,
  getInventoryAmenitiesError: null,
  assignInventoryAmenitySuccess: null,
  assignInventoryAmenityError: null,
  uploadInventoryImageSuccess: null,
  uploadInventoryImageError: null,
  getInventoryImageSuccess: null,
  getInventoryImageError: null,
  updateInventoryImageSuccess: null,
  removeInventoryImageSuccess: null,
  getInventoryImageTagSuccess: null,
  getInventoryImageTagError: null,
  createInventorySuccess: null,
  createInventoryError: null,
  updateInventorySuccess: null,
  updateInventoryError: null,
  getInventoryListSuccess: null,
  getInventoryListError: null,
  getInventoryByIdSuccess: null,
  getInventoryByIdError: null,
  getInventoryByPropertyIdSuccess: null,
  getInventoryByPropertyIdError: null,
  removeInventorySuccess: null,
  getAvailabilityListSuccess: null,
  getAvailabilityListError: null,
  createAvailabilitySuccess: null,
  createAvailabilityError: null,
  inventoryApprovalSuccess: null,
  inventoryApprovalError: null,
  inventoryReviewSuccess: null,
  inventoryReviewError: null,
  getInventorySummarySuccess: null,
  getInventorySummaryError: null,

  // actions
  setInventorySummarySuccess: action((state, payload) => ({ getInventorySummarySuccess: payload })),
  setInventorySummaryError: action((state, payload) => ({ getInventorySummaryError: payload })),
  setGetAccommodationList: action((state, payload) => ({ getAccommodationListSuccess: payload })),
  setGetAccommodationLisError: action((state, payload) => ({ getAccommodationListError: payload })),
  setCreateAccommodation: action((state, payload) => ({ createAccommodationSuccess: payload })),
  setCreateAccommodationError: action((state, payload) => ({ createAccommodationError: payload })),
  setUpdateAccommodation: action((state, payload) => ({ updateAccommodationSuccess: payload })),
  setUpdateAccommodationError: action((state, payload) => ({ updateAccommodationError: payload })),
  setRemoveAccommodation: action((state, payload) => ({ removeAccommodationSuccess: payload })),
  setGetBedTypeList: action((state, payload) => ({ getBedTypeListSuccess: payload })),
  setGetBedTypeLisError: action((state, payload) => ({ getBedTypeListError: payload })),
  setCreateBedType: action((state, payload) => ({ createBedTypeSuccess: payload })),
  setCreateBedTypeError: action((state, payload) => ({ createBedTypeError: payload })),
  setUpdateBedType: action((state, payload) => ({ updateBedTypeSuccess: payload })),
  setUpdateBedTypeError: action((state, payload) => ({ updateBedTypeError: payload })),
  setRemoveBedType: action((state, payload) => ({ removeBedTypeSuccess: payload })),
  resetInventory: action((state, payload) => ({ reset: true })),
  setGetInventoryAmenities: action((state, payload) => ({ getInventoryAmenitiesSuccess: payload })),
  setGetInventoryAmenitiesError: action((state, payload) => ({ getInventoryAmenitiesError: payload })),
  setAssignInventoryAmenity: action((state, payload) => ({ assignInventoryAmenitySuccess: payload })),
  setAssignInventoryAmenityError: action((state, payload) => ({ assignInventoryAmenityError: payload })),
  setUploadInventoryImage: action((state, payload) => ({ uploadInventoryImageSuccess: payload })),
  setUploadInventoryImageError: action((state, payload) => ({ uploadInventoryImageError: payload })),
  setGetInventoryImage: action((state, payload) => ({ getInventoryImageSuccess: payload })),
  setGetInventoryImageError: action((state, payload) => ({ getInventoryImageError: payload })),
  resetInventoryImage: action((state, payload) => ({ reset: true })),
  setUpdateInventoryImage: action((state, payload) => ({ updateInventoryImageSuccess: payload })),
  setRemoveInventoryImage: action((state, payload) => ({ removeInventoryImageSuccess: payload })),
  setGetInventoryImageTag: action((state, payload) => ({ getInventoryImageTagSuccess: payload })),
  setGetInventoryImageTagError: action((state, payload) => ({ getInventoryImageTagError: payload })),
  setGetRoomTypeList: action((state, payload) => ({ getRoomTypeListSuccess: payload })),
  setGetRoomTypeLisError: action((state, payload) => ({ getRoomTypeListError: payload })),
  setCreateRoomType: action((state, payload) => ({ createRoomTypeSuccess: payload })),
  setCreateRoomTypeError: action((state, payload) => ({ createRoomTypeError: payload })),
  setUpdateRoomType: action((state, payload) => ({ updateRoomTypeSuccess: payload })),
  setUpdateRoomTypeError: action((state, payload) => ({ updateRoomTypeError: payload })),
  setRemoveRoomType: action((state, payload) => ({ removeRoomTypeSuccess: payload })),
  setCreateInventory: action((state, payload) => ({ createInventorySuccess: payload })),
  setCreateInventoryError: action((state, payload) => ({ createInventoryError: payload })),
  setUpdateInventory: action((state, payload) => ({ updateInventorySuccess: payload })),
  setUpdateInventoryError: action((state, payload) => ({ updateInventoryError: payload })),
  setGetInventoryList: action((state, payload) => ({ getInventoryListSuccess: payload })),
  setGetInventoryListError: action((state, payload) => ({ getInventoryListError: payload })),
  setGetInventoryById: action((state, payload) => ({ getInventoryByIdSuccess: payload })),
  setGetInventoryByIdError: action((state, payload) => ({ getInventoryByIdError: payload })),
  setGetInventoryByPropertyId: action((state, payload) => ({ getInventoryByPropertyIdSuccess: payload })),
  setGetInventoryByPropertyIdError: action((state, payload) => ({ getInventoryByPropertyIdError: payload })),
  setRemoveInventory: action((state, payload) => ({ removeInventorySuccess: payload })),
  setGetAvailabilityList: action((state, payload) => ({ getAvailabilityListSuccess: payload })),
  setGetAvailabilityListError: action((state, payload) => ({ getAvailabilityListError: payload })),
  setCreateAvailability: action((state, payload) => ({ createAvailabilitySuccess: payload })),
  setCreateAvailabilityError: action((state, payload) => ({ createAvailabilityError: payload })),
  setInventoryApprovalSuccess: action((state, payload) => ({ inventoryApprovalSuccess: payload })),
  setInventoryApprovalError: action((state, payload) => ({ inventoryApprovalError: payload })),
  setInventoryReviewSuccess: action((state, payload) => ({ inventoryReviewSuccess: payload })),
  setInventoryReviewError: action((state, payload) => ({ inventoryReviewError: payload })),


  inventorySummary: thunk(async (actions: any, propertyId : string) => {
    try {
      const result = await call('get', `/inventory-sv/inventory/inventory_summary/${propertyId}`, null, null);
      actions.setInventorySummarySuccess(result.data);
    } catch (error: any) {
      actions.getInventorySummaryError(error.response.data);
    }
  }),

  // get Accommodation list
  getAccommodationList: thunk(async (actions: any) => {
    try {
      const result = await call('get', `/inventory-sv/inventory/accommodation`, null, null);
      actions.setGetAccommodationList(result.data);
    } catch (error: any) {
      actions.setGetAccommodationListError(error.response.data);
    }
  }),

  // create Accommodation
  createAccommodation: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/inventory-sv/inventory/accommodation', payload, null);
      actions.setCreateAccommodation(result.data);
    } catch (error: any) {
      actions.setCreateAccommodationError(error.response.data);
    }
  }),

  // update Accommodation
  updateAccommodation: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/inventory-sv/inventory/accommodation', payload, null);
      if (payload.isDeleted) {
        actions.setRemoveAccommodation(result.data);
      } else {
        actions.setUpdateAccommodation(result.data);
      }
    } catch (error: any) {
      actions.setUpdateAccommodationError(error.response.data);
    }
  }),



  // get BedType list
  getBedTypeList: thunk(async (actions: any) => {
    try {
      const result = await call('get', `/inventory-sv/inventory/bedType`, null, null);
      actions.setGetBedTypeList(result.data);
    } catch (error: any) {
      actions.setGetBedTypeError(error.response.data);
    }
  }),

  // create BedType
  createBedType: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/inventory-sv/inventory/bedType', payload, null);
      actions.setCreateBedType(result.data);
    } catch (error: any) {
      actions.setCreateBedTypeError(error.response.data);
    }
  }),

  // update BedType
  updateBedType: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/inventory-sv/inventory/bedType', payload, null);
      if (payload.isDeleted) {
        actions.setRemoveBedType(result.data);
      } else {
        actions.setUpdateBedType(result.data);
      }
    } catch (error: any) {
      actions.setUpdateBedTypeError(error.response.data);
    }
  }),

  // get Inventory amenities
  getInventoryAmenities: thunk(async (actions: any, inventoryId: any) => {
    try {
      const result = await call('get', `/inventory-sv/inventory/assignAmenity/${inventoryId}`, null, null);
      actions.setGetInventoryAmenities(result.data);
    } catch (error: any) {
      actions.setGetInventoryAmenitiesError(error.response.data);
    }
  }),

  //create and update Inventory amenity
  assignInventoryAmenity: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/inventory-sv/inventory/assignAmenity', payload, null);
      actions.setAssignInventoryAmenity(result.data);
    } catch (error: any) {
      actions.setAssignInventoryAmenityError(error.response.data);
    }
  }),

  //upload Inventory image
  uploadInventoryImage: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/inventory-sv/inventory/image', payload, null);
      if (payload.isDeleted) {
        actions.setRemoveInventoryImage(result.data);
      }
      else if (payload.imageId !== '' && payload.isDeleted === false) {
        actions.setUpdateInventoryImage(result.data);
      }
      else {
        actions.setUploadInventoryImage(result.data);
      }
    } catch (error: any) {
      actions.setUploadInventoryImageError(error.response.statusText);
    }
  }),

  // get Inventory image list
  getInventoryImage: thunk(async (actions: any, inventoryId: any) => {
    try {
      const result = await call('get', `/inventory-sv/inventory/image/${inventoryId}`, null, null);
      actions.setGetInventoryImage(result.data);
    } catch (error: any) {
      actions.setGetInventoryImageError(error.response.data);
    }
  }),

  // get Inventory image tag
  getInventoryImageTag: thunk(async (actions: any, brandId: any) => {
    try {
      const result = await call('get', `/inventory-sv/inventory/image/tag/${brandId}`, null, null);
      actions.setGetInventoryImageTag(result.data);
    } catch (error: any) {
      actions.setGetInventoryImageTagError(error.response.data);
    }
  }),

  // get RoomType list
  getRoomTypeList: thunk(async (actions: any) => {
    try {
      const result = await call('get', `/inventory-sv/inventory/roomType`, null, null);
      actions.setGetRoomTypeList(result.data);
    } catch (error: any) {
      actions.setGetRoomTypeError(error.response.data);
    }
  }),


  // create RoomType
  createRoomType: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/inventory-sv/inventory/roomType', payload, null);
      actions.setCreateRoomType(result.data);
    } catch (error: any) {
      actions.setCreateRoomTypeError(error.response.data);
    }
  }),

  // update RoomType
  updateRoomType: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/inventory-sv/inventory/roomType', payload, null);
      if (payload.isDeleted) {
        actions.setRemoveRoomType(result.data);
      } else {
        actions.setUpdateRoomType(result.data);
      }
    } catch (error: any) {
      actions.setUpdateRoomTypeError(error.response.data);
    }
  }),

  // create Inventory
  createInventory: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/inventory-sv/inventory', payload, null);
      actions.setCreateInventory(result.data);
    } catch (error: any) {
      actions.setCreateInventoryError(error.response.data);
    }
  }),
  // update Inventory
  updateInventory: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/inventory-sv/inventory', payload, null);
      if (payload.isDeleted) {
        actions.setRemoveInventory(result.data);
      } else {
        actions.setUpdateInventory(result.data);
      }

    } catch (error: any) {
      actions.setUpdateInventoryError(error.response.data);
    }
  }),

  // get inventory by propertyId
  getInventoryByPropertyId: thunk(async (actions: any, propertyId: any) => {
    try {
      const result = await call('get', `/inventory-sv/inventory/list/${propertyId}`, null, null);
      actions.setGetInventoryByPropertyId(result.data);
    } catch (error: any) {
      actions.setGetInventoryByPropertyIdError(error.response.data);
    }
  }),

  // get inventory by id
  getInventoryById: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/inventory-sv/inventory/${id}`, null, null);
      actions.setGetInventoryById(result.data);
    } catch (error: any) {
      actions.setGetInventoryByIdError(error.response.data);
    }
  }),

  // get inventory list
  getInventoryList: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/inventory-sv/inventory/list`, null, payload);
      actions.setGetInventoryList(result.data);
    } catch (error: any) {
      actions.setGetInventoryListError(error.response.data);
    }
  }),

  // get availability list
  getAvailabilityList: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', `/inventory-sv/availability/list`, payload, null);
      actions.setGetAvailabilityList(result.data);
    } catch (error: any) {
      actions.setGetAvailabilityListError(error.response.data);
    }
  }),

  // create Availability
  createAvailability: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/inventory-sv/availability', payload, null);
      actions.setCreateAvailability(result.data);
    } catch (error: any) {
      actions.setCreateAvailabilityError(error.response.data);
    }
  }),

  // approval 
  inventoryApproval: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/inventory-sv/inventory/approved', payload, null);
      actions.setInventoryApprovalSuccess(result.data);
    } catch (error: any) {
      actions.setInventoryApprovalError(error.response.data);
    }
  }),

  inventoryReview: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/inventory-sv/inventory/reviewed', payload, null);
      actions.setInventoryReviewSuccess(result.data);
    } catch (error: any) {
      actions.setInventoryReviewError(error.response.data);
    }
  }),
}