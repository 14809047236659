import { Select } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import { FC, useCallback, useEffect, useState } from "react";
import { Row, Tab, Col, Nav } from "react-bootstrap";
import { tabDivStyle, navContentStyle, navStyle } from "../../../common/components-style";
import { getUserAccess } from "../../../common/functions";
import { setStorage } from "../../../common/storage";

// import BrandAdminCorporateRfpList from "../corpaorateRfp-managemnet/BrandAdminCorporateRfpList";
// import BrandAdminMyRfpProcessList from "../corpaorateRfp-managemnet/BrandAdminMyRFPprocessList";

import BrandMyRfpProcessList from "./BrandAdminMyRFPprocessList";
import BrandSubmittedRfpProcessList from "./BrandAdminSubmittedRFPprocessList";
import BrandRFPCorporateList from "./BrandRFPCorporateList";
import BrandAdminRfpLocationForm from "./MyRfpDetails/BrandAdminRfpCityViewForm";
import BrandAdminRfpForm from "./MyRfpDetails/BrandAdminRfpForm";
// import BrandAdminRfpLocationForm from "../corpaorateRfp-managemnet/form/BrandAdminRfpCityViewForm";
// import BrandAdminRfpForm from "../corpaorateRfp-managemnet/form/BrandAdminRfpForm";

const { Option } = Select;

const BrandRFPAdminHomePage: FC<any> = ({ location }) => {

    const [isLoggedInUserBrandAdmin, setIsLoggedInUserBrandAdmin] = useState<boolean>(false);
    const [showBrandSelectDropDown, setShowBrandSelectDropDown] = useState<boolean>(true);
    const [brandListId, setBrandListId] = useState<any>([]);
    const [brandList, setBrandList] = useState<any>([]);
    const [filteredBrandList, setFilteredBrandList] = useState<any>([]);
    const [shouldDataReload, setShouldDataReload] = useState<boolean>(false);
    const [showHomePage, setShowHomePage] = useState<boolean>(false);
    const [defaultTab, setDefaultTab] = useState<any>('corporateRFPS');
    const [showLocationPage, setShowLocationPage] = useState<boolean>(false);
    const [showLocationForm, setShowLocationForm] = useState<boolean>(false);
    const [corporateRfpData, setCorporateRfpData] = useState<any>([]);

    //actions ///////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getAllTheCorporateRfpProcessList,
        getBrandList,
    } = useStoreActions<any>((actions) => ({
        getAllTheCorporateRfpProcessList: actions.rfpProcess.getRfpProcessList,
        getBrandList: actions.brand.onGetBrandList,
    }));

    //state ///////////////////////////////////////////////////////////////////////////////////////////////
    const {
        brandListSuccess,
    } = useStoreState<any>((state) => ({

        myRfpProcessListSuccess: state.rfpProcess.myRfpProcessListSuccess,
        myRfpProcessListError: state.rfpProcess.myRfpProcessListError,
        myRfpProcessListLoading: state.rfpProcess.myRfpProcessListLoading,
        brandListSuccess: state.brand.brandListSuccess,
        brandListError: state.brand.brandListError,
        loadBrandSuccess: state.brand.loadBrandSuccess,
    }));



    // call get the brand list   //////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (location) {
            setDefaultTab(location?.state?.defaultKey);
            setShowLocationForm(location?.state?.query?.showLocationForm);
            setShowLocationPage(location?.state?.showLocationPage ? location?.state?.showLocationPage : location?.state?.query?.showLocationPage);
            setCorporateRfpData(location?.state?.data ? location?.state?.data : location?.state?.query?.data?.data);
        } else {
            setShowLocationForm(false);
            setShowLocationPage(false);
        }

        getBrandList();
    }, [getBrandList, location]);

    // setting the brandListId to the local state   ///////////////////////////////////////////////////////
    useEffect(() => {
        if (brandListSuccess) {
            setBrandList(brandListSuccess?.data);
        }
    }, [brandListSuccess]);

    // if the logged in user a brand admin //////////////////////////////////////////////
    useEffect(() => {
        //call get the brand list
        getBrandList();

        const brands = getUserAccess("brand");
        if (brands?.length > 0) {
            setBrandListId(brands);
            setIsLoggedInUserBrandAdmin(true);
        }
    }, [getBrandList]);

    ///// get rfp process list //////////////////////////////////////////////////////////////
    useEffect(() => {
        if (isLoggedInUserBrandAdmin) {
            getAllTheCorporateRfpProcessList();
        }
    }, [getAllTheCorporateRfpProcessList, isLoggedInUserBrandAdmin]);



    ///////////////////////////////////////////////////////////////////////////////////
    const filterBrands = useCallback(() => {
        let filteredBrands: any[] = [];
        if (brandList.length > 0) {
            brandListId.forEach(element => {
                const filteredItem = brandList.filter(brand => brand._id === element?.brandId);
                if(filteredItem.length !== 0){
                    setStorage('domains', filteredItem[0].allowedDomains);
                    filteredBrands.push(filteredItem[0]);
                }
            });
        }
        if (filteredBrands.length === 1 && showBrandSelectDropDown) {
            setShowBrandSelectDropDown(false);
            setShowHomePage(true);
            setStorage("myo_selected_my_rfp_brand", filteredBrands[0]?._id);
        }
    }, [brandList, brandListId, showBrandSelectDropDown]);

    ///////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        filterBrands();
    }, [filterBrands])

    //////////////////////////////////////////////////////////////////////////////////
    function handleChange(value) {
        const findSelectedBrandDetails = filteredBrandList.filter(item => item.name === value)
        if (findSelectedBrandDetails?.length > 0) {
            setShowBrandSelectDropDown(false);
            setShowHomePage(true);
            setStorage("myo_selected_my_rfp_brand", findSelectedBrandDetails[0]?._id);

        } else {

        }
    }

    //  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleRfpProcessClick = useCallback((data) => {
        if (data) {
            setShowLocationPage(true);
            setCorporateRfpData(data);
        }
        // updateBrandRFPData({ ...reduxBrandDetail, showRfpDetailFormFirstPage: true, formData: data, showAvailableRfpList: false });
    }, [])


    ///////////////////////////////////////////////////////////////////////////////////
    const renderBrands = useCallback(() => {
        if (filteredBrandList?.length > 0) {
            return filteredBrandList && filteredBrandList.map(item => (
                <Option key={item?._id} value={item?.name}>{item?.name}</Option>))
        }

    }, [filteredBrandList]);

    return (

        <div className="brand-rfp-process-form" >
            {
                showBrandSelectDropDown && <div className=" inline-block m-1 text-justify items-center shadow-lg p-3 rounded-2xl bordered" style={{ width: '100%' }}>
                    <p style={{ marginBottom: '15px' }} className='font-medium text-lg'>Select a Brand</p>
                    {
                        <Select size="large" defaultValue="please select a brand" style={{ width: 220 }} onChange={handleChange}>
                            {renderBrands()}
                        </Select>
                    }
                </div>
            }
            {/* key={tabKey} activeKey={tabKey} onSelect={(tab) => { setTabKey(tab); console.log(tab, 'tab') }} */}
            {
                showHomePage && <div style={tabDivStyle} >
                    <Tab.Container defaultActiveKey={defaultTab || 'corporateRFPS'} key={defaultTab} activeKey={defaultTab} onSelect={(tab) => { setDefaultTab(tab) }} >
                        <Row>
                            <Col sm={2} >
                                <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                                    <Nav.Item  >
                                        <Nav.Link eventKey="corporateRFPS">RFP Invites</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item >
                                        <Nav.Link eventKey="myRFPs">Active RFPS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item >
                                        <Nav.Link eventKey="submittedRFPs">Submitted RFPS</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={10} >
                                <Tab.Content>
                                    <Tab.Pane eventKey="corporateRFPS" >
                                        <div style={navContentStyle} >

                                            {(!showLocationForm && !showLocationPage) && <BrandRFPCorporateList
                                                shouldDataReload={shouldDataReload}
                                                setShouldDataReload={setShouldDataReload}
                                                isLoggedInUserBrandAdmin={isLoggedInUserBrandAdmin}
                                                filteredBrandList={filteredBrandList}
                                                handleRfpProcessClick={handleRfpProcessClick}

                                            />}

                                            {showLocationPage && <BrandAdminRfpForm defaultTab={defaultTab} data={corporateRfpData} setDefaultTab={setDefaultTab} />}

                                            {
                                                showLocationForm && <BrandAdminRfpLocationForm defaultTab={defaultTab} locationData={location?.state?.query} setDefaultTab={setDefaultTab} />
                                            }
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="myRFPs" >
                                        <div style={navContentStyle}>
                                            {
                                                // reduxBrandDetail?.showMyRfpHomePage &&
                                                // location?.state?.selectedBrandId && <BrandMyRfpProcessList shouldDataReload={shouldDataReload} setShouldDataReload={setShouldDataReload} filteredBrandList={filteredBrandList} selectedBrandId={location?.state?.selectedBrandId} />
                                                <BrandMyRfpProcessList shouldDataReload={shouldDataReload} setShouldDataReload={setShouldDataReload} filteredBrandList={filteredBrandList} selectedBrandId={location?.state?.selectedBrandId} />
                                            }
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="submittedRFPs" >
                                        <div style={navContentStyle}>
                                            {
                                                // reduxBrandDetail?.showMyRfpHomePage &&
                                                // location?.state?.selectedBrandId && <BrandMyRfpProcessList shouldDataReload={shouldDataReload} setShouldDataReload={setShouldDataReload} filteredBrandList={filteredBrandList} selectedBrandId={location?.state?.selectedBrandId} />
                                                <BrandSubmittedRfpProcessList shouldDataReload={shouldDataReload} setShouldDataReload={setShouldDataReload} filteredBrandList={filteredBrandList} selectedBrandId={location?.state?.selectedBrandId} />
                                            }
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            }
            {/* {
                reduxBrandDetail?.showMyRfpBrandEvaluationPage && <BrandAdminMyRfpDetails />
            } */}
        </div >
    )
};

export default BrandRFPAdminHomePage;