import React, { useEffect, useMemo, useState, } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import DataTable from 'react-data-table-component';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { overlayTriggerDelay, tableStyles } from '../../../../common/components-style';
import { BrandRfpPropertyEvaluationStatus, brandRfpStatus } from '../../../../common/constants';
import { useHistory } from 'react-router-dom';
import { setStorage } from '../../../../common/storage';



const ProposalManagementPage: React.FC<any> = (): JSX.Element => {
    const history = useHistory();
    const [myRfpProcessesList, setMyRfpProcessesList] = useState<any>([]);
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getAllSubmittedBrandRfpProcessList
    } = useStoreActions<any>((actions) => ({
        getAllSubmittedBrandRfpProcessList: actions.rfpProcess.getAllSubmittedBrandRfpProcessList

    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getAllSubmittedBrandRfpProcessListSuccess
    } = useStoreState<any>((state) => ({

        getAllSubmittedBrandRfpProcessListSuccess: state.rfpProcess.getAllSubmittedBrandRfpProcessListSuccess
    }));


    ////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        getAllSubmittedBrandRfpProcessList();
    }, [getAllSubmittedBrandRfpProcessList]);

    ////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (getAllSubmittedBrandRfpProcessListSuccess) {
            setMyRfpProcessesList(getAllSubmittedBrandRfpProcessListSuccess?.data);
        }

    }, [getAllSubmittedBrandRfpProcessListSuccess]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const data = useMemo(() => {
        return myRfpProcessesList?.map((rfpData) => {
            const { _id, endDate, locations, clientId, programmeId, overviewForInterview, startDate, questionSubmitDate } = rfpData?.rfpId;
            const brandSpecificLocations = rfpData?.locations;
            const cities = locations.map((item: any) => item.cities)
            const citiesArray = [].concat.apply([], cities);
            const cityNames = citiesArray.map((item: any) => item.cityName);

            return {
                key: myRfpProcessesList?._id,
                endDate,
                locations: cityNames?.length,
                locationsDetails: locations,
                companyName: clientId?.companyName,
                clientId: clientId?._id,
                programmeName: programmeId?.programmeName,
                programmeId: programmeId?._id,
                overviewForInterview,
                startDate,
                questionSubmitDate,
                brandId: myRfpProcessesList?.brandId,
                rfpId: _id,
                brandSpecificLocations: brandSpecificLocations,
                brandRfpStatus: rfpData?.brandRfpStatus,
                brandRfpId: rfpData?._id,
                certificateIdMYOManagement: rfpData?.certificateIdMYOManagement,
                rfpSubmission: rfpData?.rfpSubmission,
                brandSurveyDetailSubmission: rfpData?.brandSurveyDetailSubmission,
                propertySurveyDetailSubmission: rfpData?.propertySurveyDetailSubmission,
                propertyCount: rfpData?.properties,
                released: rfpData?.releasedDate,
                accepted: rfpData?.accepted,
                declined: rfpData?.decline,
                negotiate: rfpData?.negotiate,
            }
        });

    }, [myRfpProcessesList]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const columns = [
        {
            name: 'Company',
            selector: row => row.companyName,
        },
        {
            name: 'Programme',
            selector: row => {
                return (
                    <OverlayTrigger

                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>{row.programmeName}</Tooltip>}
                    >
                        <p style={{ marginBottom: '0px' }} >{String(row.programmeName).slice(0, 10) + "..."}</p>
                    </OverlayTrigger>
                )
            }
        },
        {
            name: 'Released',
            selector: row => row.released ? moment(row.released).format("D MMM YYYY") : '',
        },

        {
            name: 'Properties',
            selector: row => row.propertyCount,
        },
        {
            name: 'Accepted',
            selector: row => row.accepted,
        },
        {
            name: 'Negotiate',
            selector: row => row.negotiate,
        },
        {
            name: 'Declined',
            selector: row => row.declined,
        },

        {
            name: 'Action',
            wrap: true,
            cell: (data: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View/Edit</Tooltip>}
                    >
                        <img className='iconSizes mr-2' src={ViewEdit} alt="" onClick={() => {
                            setStorage('selectedProgramsOption', {
                                label
                                    :
                                    data?.programmeName,
                                value
                                    :
                                    data?.programmeId
                            });
                            setStorage('selectedFilterClient', {
                                label
                                    :
                                    data?.companyName,
                                value
                                    :
                                    data?.clientId
                            });
                            history.push('/admin/client-proposal-management', { filterProgramManage: true, defaultActiveKey: 'executiveReview', rfpProcessId: data?.rfpId, startDate: data?.startDate, programmeName: data?.programmeName, clientName: data?.companyName, programmeId: data?.programmeId });
                        }}


                        />
                    </OverlayTrigger>
                </>)
            }
        },

    ];


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <div className="property-list ">
                <div className=''>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme='mysaTable'
                        fixedHeader={true}

                    />
                </div>
            </div>
        </>
    )
};

export default ProposalManagementPage;