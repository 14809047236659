import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Col, OverlayTrigger, Row, Tooltip, Image } from "react-bootstrap";
import { overlayTriggerDelay, tableStyles } from "../../../../common/components-style";
import DataTable from "react-data-table-component";
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

import _ from "lodash";
import OperatorView from "./common-views/OperatorView";
import { formatPhoneNumber } from "../../../../common/functions";



const fallbackUrl = 'https://res.cloudinary.com/mysa/image/upload/v1613451891/common/no-image-1771002-1505134_sqlz99.webp';
///////////////////////////////// dropdown style ///////////////////////////////////////

const selectStyles = {
    control: (base, state) => ({
        ...base,
        background: "#FBC91D",
        height: "50px",
        paddingLeft: "10px",
        color: "#212121",
        borderRadius: 15,
        borderColor: "#F0F0F0",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: "#212121",
        },
    }),
    menu: (base) => ({
        ...base,
        hyphens: "auto",
        marginTop: 0,
        borderRadius: "14px",
        textAlign: "left",
        zIndex: 3
    }),
    menuList: (base) => ({
        ...base,
        padding: "10px",
        color: "#000000",
    }),
    dropdownIndicator: base => ({
        ...base,
        color: "#000000"
    })
};



const ProgrammeOperatorHome: React.FC<any> = ({ location, activateProperty, archiveProperty, operatorData, rfpProcess, programmeData }): JSX.Element => {
    const [archivePopup, setArchivePopup] = useState<boolean>(false);
    const [dataList, setDataList] = useState<any>();
    const [activatePopup, setActivatePopup] = useState<boolean>(false);
    const [tableData, setTableData] = useState<any>([]);
    const [brandOption, setBrandOption] = useState<any>([]);
    const [showView, setShowView] = useState<boolean>(false);
    const [brandId, setBrandId] = useState<any>();
    const [data, setData] = useState<any>([]);


    const changeBrandName = useCallback((value) => {
        setBrandId(value.value);
    }, []);

    const selectBrandRender = useMemo(() => {
        return (
            <>

                <Select
                    styles={selectStyles}
                    options={brandOption}

                    placeholder="Brand"
                    onChange={changeBrandName}
                    // value={countryOption[0]}
                    className="new_drpdwn"
                />

            </>
        )
    }, [changeBrandName, brandOption])



    useEffect(() => {
        let brandListTemp: any = [];

        if (operatorData) {

            _.map(operatorData, (element) => {
                brandListTemp.push(element?.property)

            });
        }

        const uniqueRecords = {};
        brandListTemp.forEach(property => {
            uniqueRecords[property?.brand._id] = property;
        });
        setTableData(Object.values(uniqueRecords));
        setData(Object.values(uniqueRecords));
    }, [operatorData]);



    useEffect(() => {
        let brands: any = [];
        data.forEach(record => {
            let data = {
                value: record?.brand?._id,
                label: record?.brand?.name,
            };
            brands.push(data);
        });
        setBrandOption(brands);

    }, [data]);


    useEffect(() => {
        if (brandId) {
            const list = _.filter(data, list => list?.brand?._id === brandId);
            setTableData(list)
        } else {

        }
    }, [brandId, data]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const columns = [
        {
            name: 'Logo',
            cell: (row) => (
                <Image width={60} height={60} src={row?.brand?.image} onError={(e: any) => { e.target.src = fallbackUrl; }} />
            ),
        },
        {
            name: 'Name',
            selector: row => row?.brand?.name,
        },
        {
            name: 'Account Manager',
            selector: row => row?.brand?.mainContact,
        },
        {
            name: 'Contact No.',
            selector: row => formatPhoneNumber(row?.brand?.mainContactTelephone),
        },
        {
            name: 'Email',
            selector: row => row?.brand?.responsiblePersonEmail,
        },

        {
            name: 'Action',
            button: true,
            cell: (row: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
                    >
                        <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                            // history.push({
                            //     pathname: `/admin/corporate-programme-management/property/${row.property?._id}/${rfpProcess?._id}`
                            // });
                            setShowView(true);
                            setDataList(row);
                        }} />
                    </OverlayTrigger>


                </>)
            },
        },

    ];

    return (
        <>
            {!showView && <Row>
                {/* <Col md={10}> */}
                <Row style={{ marginTop: "-30px", marginLeft: "7px" }}>
                    <p style={{ fontSize: '25px', fontWeight: 'bold' }}>{`${programmeData?.clientId?.companyName ? programmeData?.clientId?.companyName : location?.state?.selectedClient?.companyName}`}
                        {` - ${programmeData?.programmeName ? programmeData?.programmeName : location?.state?.selectedProgramme?.programmeName} - RFP ${moment(rfpProcess?.startDate).format('ll')}`}</p>

                </Row>

                <Row style={{ marginLeft: "7px", marginTop: '15px' }}>

                    <Col md={2}>
                        <div style={{ marginTop: '3%' }}>
                            {selectBrandRender}
                        </div>
                    </Col>


                </Row>

                <div className='mt-5'>
                    <DataTable
                        columns={columns}
                        data={tableData}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme='mysaTable'
                        fixedHeader={true}
                        noDataComponent={'Updating records'}
                    />
                </div>


            </Row>}

            <SweetAlert
                show={archivePopup}
                warning
                showCancel={true}
                focusCancelBtn={true}
                confirmBtnText="Yes archive it!"
                confirmBtnBsStyle="danger"
                title="Are you sure you wish to archive this property?"
                onConfirm={() => {
                    archiveProperty(dataList);

                    setArchivePopup(false);
                }}
                onCancel={() => {
                    setArchivePopup(false);
                }}
            >
            </SweetAlert>

            <SweetAlert
                show={activatePopup}
                warning
                showCancel={true}
                focusCancelBtn={true}
                confirmBtnText="Yes activate it!"
                confirmBtnBsStyle="danger"
                title="Are you sure you wish to activate this property"
                onConfirm={() => {
                    activateProperty(dataList);
                    // onApprovalBrand(brandArchive);
                    setActivatePopup(false);
                }}
                onCancel={() => {
                    setActivatePopup(false);
                }}
            >
            </SweetAlert>


            {showView && <OperatorView propertyId={dataList?._id} rfpId={rfpProcess?._id}></OperatorView>}

        </>
    );
};

export default ProgrammeOperatorHome;
