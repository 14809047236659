import React, { useCallback, useEffect, useState } from "react";
import { Navbar, OverlayTrigger, Popover, Card, ListGroup, Modal, Row, Col } from 'react-bootstrap';
import logo from "../../../images/MYO.png";
import { getStorage, emptyStorage, setStorage } from "../../../common/storage";
import { capitalizeRole, getLoggedUserType, getLoggedUserEmail, getUserAccess } from "../../../common/functions";
import { UserGroups } from "../../../common/constants";
import { useStoreActions, useStoreState } from "easy-peasy";
import userProfileIcon from "../../../images/icons/svg/outside/Icon-Function-User-Profile.svg";
import { useHistory, useLocation } from "react-router-dom";
import MyProfile from "./settings/myProfile";
import { $mysaBlack } from "../../../common/components-style";
import { toast } from "react-toastify";
import SelectC from "../booking-report/components/SelectC";
import _ from "lodash";

const Header: React.FC = (): JSX.Element => {

  const [fName, setFName] = useState(null);
  const [lName, setLName] = useState(null);
  const [group, setGroup] = useState("");
  const [programmeData, setProgrammeData] = useState<any>([]);
  const [myProfileModalVisible, setMyProfileModalVisible] = useState(false);
  const [programmes, setProgrammes] = useState<any>([]);
  const { push } = useHistory();
  const { logoutAction, resetUser } = useStoreActions((actions: any) => ({
    logoutAction: actions.auth.setLogout,
    resetUser: actions.user.resetUser,
  }));

  const showProfileModal = () => {
    setMyProfileModalVisible(true);
  };

  const hideProfileModal = () => {
    setMyProfileModalVisible(false);
  };
  const history = useHistory();
  ///////////////////////////////////////Global state manage///////////////////////////////////////////////////
  const { getHeaderUserMapping, getCorporateProgrammeByClient } = useStoreActions<any>((actions) => ({
    getHeaderUserMapping: actions.user.getUserMapping,
    getCorporateProgrammeByClient: actions.client.getCorporateProgrammeByClient,
  }));

  const { getHeaderUserMappingSuccess, getCorporateProgrammeByClientSuccess } = useStoreState<any>((state) => ({
    getHeaderUserMappingSuccess: state.user.getUserMappingSuccess,
    getCorporateProgrammeByClientSuccess: state.client.getCorporateProgrammeByClientSuccess,
  }));
  //////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const { userData } = getStorage("myo_logged_user");
    setFName(userData.firstName);
    setLName(userData.lastName);
  }, []);

  //////////////////////////Refresh local state/////////////////////////////////////
  const refreshLocalState = useCallback(() => {
    const { userData } = getStorage("myo_logged_user");
    setFName(userData.firstName);
    setLName(userData.lastName);
  }, []);

  /////////////////////////////Check is brand user///////////////////////////////////////////////////////////////////
  useEffect(() => {
    const { userData } = getStorage("myo_logged_user");
    const userId = {
      userId: userData._id,
    };
    getHeaderUserMapping(userId);
  }, [getHeaderUserMapping]);

  useEffect(() => {
    if (getHeaderUserMappingSuccess) {
      if (getHeaderUserMappingSuccess.data.result.length > 0) {
        getHeaderUserMappingSuccess.data.result[0].brands.forEach((brand) => {
          if (getLoggedUserEmail() === getHeaderUserMappingSuccess.data.result[0].email && brand.role === UserGroups.brand_admin) {
            setGroup(brand.role);
            setStorage('isBrand', brand.role);
            return;
          }
        });
      }
    }
  }, [getHeaderUserMappingSuccess]);
  //////////////////////////////////////////////////////////////////////////

  const logout = useCallback(() => {
    emptyStorage();
    logoutAction();
    resetUser();
    push("/");
    // message.success("User logout successfully");
    toast.success("User logout successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'foo-bar'
    });
  }, [logoutAction, push, resetUser]);

  // Scroll
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    onTop();
  }, [routePath]);


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {

    const params: any = {};
    const clients = getUserAccess("clients");
    if (clients) {
      setProgrammes(clients[0]?.corporateProgramme);
      const selectedClientId = clients[0]?.clientId;
      params.clientId = selectedClientId;

      getCorporateProgrammeByClient(params);
    }


  }, [getCorporateProgrammeByClient]);
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getCorporateProgrammeByClientSuccess) {
      const programmeIds = _.map(programmes, (a) => a.programmeId);
      const mappedData = _.map(getCorporateProgrammeByClientSuccess?.data, (val) => {
        return {
          label: val.programmeName,
          value: val._id,
          data: val
        }
      });

      let filteredData = _.filter(mappedData, (a) => programmeIds.includes(a.value));

      setProgrammeData(filteredData);
    }

  }, [getCorporateProgrammeByClientSuccess, programmes])


  const programmeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value) {

      setStorage('moreThanOneProgramme', true);
      setStorage('programmeId', e.currentTarget.value);
      setStorage('showDashBoard', true);
      // setShowDashboard(true);
      history.push('/admin');
    }
  }, [history])

  return (

    <>
      <Navbar bg="light" expand="lg" fixed="top" className="navbar-inner">
        <Navbar.Brand href="/admin" style={{ marginLeft: '4%' }}><img width="170" src={logo} alt="logo" /></Navbar.Brand>
        <Navbar.Toggle style={{ marginRight: '4%' }} />
        <Navbar.Collapse className="justify-content-end" style={{ marginRight: "18px" }}>
          {(programmes?.length > 1 && getLoggedUserType() !== UserGroups.super_admin) && <div style={{ marginRight: "24px" }}>

            <Row>

              <Col md={4} style={{ width: '100%' }}>
                {/* vvbbc */}
                <SelectC name={'programme'} data={programmeData} onChange={programmeChange} defaultValue={getStorage('programmeData')}></SelectC>
              </Col>

            </Row>

          </div>}
          <Navbar.Text>
            Welcome, <span style={{ color: $mysaBlack }}><b>{fName} {lName}</b></span>
            <div className="text-right">
              {getLoggedUserType() === UserGroups.super_admin && (
                <span >{capitalizeRole(getLoggedUserType())}</span>
              )}
              {getLoggedUserType() !== UserGroups.super_admin &&
                group === UserGroups.brand_admin && (
                  <span >{capitalizeRole(group)}</span>
                )}
            </div>
          </Navbar.Text>
          <Navbar.Brand>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              rootClose
              overlay={
                <Popover placement="bottom">
                  <Card >
                    <Card.Body>
                      <Card.Text>
                        <ListGroup>
                          <ListGroup.Item>
                            <b>
                              <div onClick={() => showProfileModal()} style={{ textDecoration: 'none', color: $mysaBlack, cursor: 'pointer' }}>
                                My Profile
                              </div>
                            </b>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <b>
                              <div onClick={() => logout()} style={{ textDecoration: 'none', color: $mysaBlack, cursor: 'pointer' }}>
                                Logout
                              </div>
                            </b>
                          </ListGroup.Item>
                        </ListGroup>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Popover>
              }
            >
              <div style={{ cursor: 'pointer' }}>
                <img width="50" src={userProfileIcon} alt="userProfileIcon" />
              </div>
            </OverlayTrigger>
          </Navbar.Brand>
        </Navbar.Collapse>
      </Navbar>

      <Modal
        show={myProfileModalVisible}
        onHide={hideProfileModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            My Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MyProfile
            refreshLocalState={refreshLocalState}
            hideProfileModal={hideProfileModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
