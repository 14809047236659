import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import rate from '../../../../../images/icons/svg/rate-table-header-icon.svg';
import ViewEdit from '../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';

import _ from 'lodash';
import { $mysaBlue, $mysaMegenta, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, tableStyles } from '../../../../../common/components-style';
import { rfpPropertyStatusToInventory } from '../../../../../common/constants';
////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const ProgrammeRatesListView: React.FC<any> = ({ setEnableRatesScreen, setRates, inventoryRates, property }): JSX.Element => {

  ////////////////// url manage ///////////
  // let { propertyId, rfpId } = useParams<any>();

  ///////////////////////////////////////// local state manage /////////////////////////////////////////
  const [tableData, setTableData] = useState<any>([]);

  // /////////////////////////////////// global action manage ////////////////////////////////////////////
  // const { propertyInventoryRates } = useStoreActions<any>(
  //   (actions) => ({
  //     propertyInventoryRates:
  //       actions.rfpProcess.propertyInventoryRates
  //   })
  // );

  // /////////////////////////////////// global state manage ////////////////////////////////////////////
  // const { getPropertyInventoryRatesSuccess } = useStoreState<any>(
  //   (state) => ({
  //     getPropertyInventoryRatesSuccess:
  //       state.rfpProcess.getPropertyInventoryRatesSuccess
  //   })
  // );

  // useEffect(()=>{
  //   const payload = {
  //     rfp_id : rfpId,
  //     propertyId : propertyId
  //   }
  //   propertyInventoryRates(payload);
  // },[propertyId, propertyInventoryRates, rfpId])

  useEffect(() => {
    const data = _.filter(inventoryRates, list => ((list.rfpPropertyRateStatus === rfpPropertyStatusToInventory.ACCEPTED)));
    setTableData(data);
  }, [inventoryRates])


  const secondColumn = () => {
    return (<>
      1 - 7 <img src={rate} alt="rate" />
    </>)
  };

  const thirdColumn = () => {
    return (<>
      8-14 <img src={rate} alt="rate" />
    </>)
  };

  const forthColumn = () => {
    return (<>
      15-28 <img src={rate} alt="rate" />
    </>)
  };

  const fifthColumn = () => {
    return (<>
      29-90 <img src={rate} alt="rate" />
    </>)
  };

  const sixthColumn = () => {
    return (<>
      91+ <img src={rate} alt="rate" />
    </>)
  };

  const columns = [
    {
      name: '',
      selector: row => row.inventoryDetails[0].room_type.name,
    },

    {
      name: secondColumn(),
      cell: (row) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>{property.currency} {row.rateRFP.corporateRate.standardRate.oneTo7}</div>
            <div style={{ fontSize: '12px' }}>{property.currency} 120</div>
          </div>
        )
      }
    },
    {
      name: thirdColumn(),
      cell: (row) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>{property.currency} {row.rateRFP.corporateRate.standardRate.eightTo14}</div>
            <div style={{ fontSize: '12px' }}>{property.currency} 120</div>
          </div>
        )
      }
    },
    {
      name: forthColumn(),
      cell: (row) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>{property.currency} {row.rateRFP.corporateRate.standardRate.fifteenTo28}</div>
            <div style={{ fontSize: '12px' }}>{property.currency} 120</div>
          </div>
        )
      }
    },
    {
      name: fifthColumn(),
      cell: (row) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>{property.currency}{row.rateRFP.corporateRate.standardRate.twentyNineTo90}</div>
            <div style={{ fontSize: '12px' }}>{property.currency} 120</div>
          </div>
        )
      }
    },
    {
      name: sixthColumn(),
      cell: (row) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>{property.currency} {row.rateRFP.corporateRate.standardRate.ninetyOnePlus}</div>
            <div style={{ fontSize: '12px' }}>{property.currency} 120</div>
          </div>
        )
      }
    },
    {
      name: 'Status',
      cell: (row) => {
        let color = "";
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.PROPOSED) color = $mysaYellow;
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.ACCEPTED) color = $mysaTeal;
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.DECLINED) color = $mysaMegenta;
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.REVIEW) color = $mysaYellow;
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.REVIEWED) color = $mysaBlue;
        if (row.rfpPropertyRateStatus === rfpPropertyStatusToInventory.NEGOTIATE) color = $mysaYellow;
        return (
          <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.rfpPropertyRateStatus}</span>
        )
      }
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <>
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
            >
              <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                setRates(row);
                setEnableRatesScreen(true);
              }} />
            </OverlayTrigger>
          </>
        );
      }
    }
  ];

  return (
    <>
      <div className='mt-3' >
        <DataTable
          columns={columns}
          data={tableData}
          pagination
          striped={true}
          customStyles={tableStyles}
          theme='mysaTable'
          fixedHeader={true}
          noDataComponent={'Updating records'}
        />
        <div style={{ marginTop: "110px", opacity: '0.5' }}>Rates displayed against the average of all properties submitted</div>
      </div>
    </>
  );
}

export default ProgrammeRatesListView;