import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Button, Breadcrumb, Tab, Nav, Form, Modal } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { EnvironmentOutlined } from "@ant-design/icons";
import ClientSinglePropertyView from './views/ClientSinglePropertyView';
import { formButton, navStyle, tabDivStyle } from '../../../../../common/components-style';
import { useHistory, useParams } from 'react-router-dom';
import { Menu } from 'antd';
import ClientRatesListView from './views/ClientRatesListView';
import ClientAuditView from './views/ClientAuditView';
import ClientPropertyRateDetails from './views/ClientPropertyRateDetails';
import { CommonAnswer, PropertyInventoryStatus, BrandRfpPropertyEvaluationStatus } from '../../../../../common/constants';
import { toast } from 'react-toastify';
import SustainabilityView from './views/SustainabilityView';


const ClientNegotiationPropertyDetailsToAccess: React.FC<any> = (): JSX.Element => {

    ///////////////////////////////////////// local state manage /////////////////////////////////////////
    const [menuKey, setMenuKey] = useState('property');
    const [property, setProperty] = useState<any>();
    const [amenitiesList, setAmenitiesList] = useState<any>();
    const [enableRatesScreen, setEnableRatesScreen] = useState<boolean>(false);
    const [rates, setRates] = useState<any>('');
    const [inventoryRates, setInventoryRates] = useState<any>([]);
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [showOption, setShowOption] = useState<boolean>(false);
    const [doYouWishDeclineProperty, setDoYouWishDeclineProperty] = useState<any>();
    const [isAcceptancePropertyBasedClarification, setIsAcceptancePropertyBasedClarification] = useState<any>();
    const [isAllInventoryAccepted, setIsAllInventoryAccepted] = useState<boolean>(false);
    const [doYouWishOpenNegotiationProperty, setDoYouWishOpenNegotiationProperty] = useState<any>();
    // const [showAcceptanceClarification, setShowAcceptanceClarification] = useState<boolean>(false);
    const [declineComment, setDeclineComment] = useState<any>(null);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [negotiationComment, setNegotiationComment] = useState<any>();
    // const [declinedComment, setDeclineComment] = useState<any>();
    const [clarificationComment, setClarificationComment] = useState<any>();
    const [rfpProperty, setRfpProperty] = useState<any>();
    const [showAcceptanceMessage, setShowAcceptanceMessage] = useState<boolean>(false);
    const [showDeclineMessage, setShowDeclineMessage] = useState<boolean>(false);
    const [propertyAcceptInformation, setPropertyAcceptInformation] = useState<any>();
    const [showAmendedMessage, setShowAmendedMessage] = useState<boolean>(false);
    const [respondProperty, setRespondProperty] = useState<string>('');
    const [showComment, setShowComment] = useState<boolean>(false);
    const [propertyRespondedComment, setPropertyRespondedComment] = useState<any>();
    const [isAllAcceptedInventory, setIsAllAcceptedInventory] = useState<boolean>(false);
    const [inventoryAmendedRateComment, setInventoryAmendedRateComment] = useState<any>();
    const [inventoryAmendedSecondRateComment, setInventoryAmendedSecondRateComment] = useState<any>();
    const [inventoryMessage, setInventoryMessage] = useState<any>([]);

    ////////////////// url manage ///////////
    let { propertyId, rfpId } = useParams<any>();
    let history = useHistory();

    /////////////////////////////////// global action manage ////////////////////////////////////////////
    const { getPropertyAcceptDeclineOfRFPByPropertyId, negotiatePropertyRateForRFP, propertyDetailed, getAmenityList, acceptPropertyRateForRFP, declinePropertyRateForRFP,
        getPropertyInventoryRatesForRFP, declinePropertyForRFP, acceptCreateUpdatePropertyAcceptDeclineOfRFP, declineCreateUpdatePropertyAcceptDeclineOfRFP,
        reviewCreateUpdatePropertyAcceptDeclineOfRFP, respondCreateUpdatePropertyAcceptDeclineOfRFP, acceptPropertyForRFP, reviewPropertyForRFP, partAcceptedPropertyForRFP, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP } = useStoreActions<any>(
            (actions) => ({
                negotiatePropertyRateForRFP: actions.rfpProcess.negotiatePropertyRateForRFP,
                propertyDetailed: actions.property.propertyDetailed,
                getAmenityList: actions.property.getAmenityList,
                acceptPropertyRateForRFP: actions.rfpProcess.acceptPropertyRateForRFP,
                declinePropertyRateForRFP: actions.rfpProcess.declinePropertyRateForRFP,
                // propertyInventoryRates: actions.rfpProcess.propertyInventoryRates,
                getPropertyInventoryRatesForRFP: actions.rfpProcess.getPropertyInventoryRatesForRFP,
                declinePropertyForRFP: actions.rfpProcess.declinePropertyForRFP,
                acceptPropertyForRFP: actions.rfpProcess.acceptPropertyForRFP,
                reviewPropertyForRFP: actions.rfpProcess.reviewPropertyForRFP,
                partAcceptedPropertyForRFP: actions.rfpProcess.partAcceptedPropertyForRFP,
                acceptCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFP,
                declineCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.declineCreateUpdatePropertyAcceptDeclineOfRFP,
                reviewCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFP,
                partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP,
                getPropertyAcceptDeclineOfRFPByPropertyId: actions.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyId,
                respondCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.respondCreateUpdatePropertyAcceptDeclineOfRFP,
                //  reviewPropertyForRFP: actions.rfpProcess.reviewPropertyForRFP
            })
        );

    /////////////////////////////////// global state manage ////////////////////////////////////////////
    const { getPropertyDetailedSuccess, getAmenityListSuccess, acceptPropertyRateForRFPSuccess, acceptPropertyRateForRFPError,
        declinePropertyRateForRFPSuccess, declinePropertyRateForRFPError, getPropertyInventoryRatesForRFPSuccess,
        declinePropertyForRFPSuccess, declinePropertyForRFPError, reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPError, acceptPropertyForRFPSuccess, acceptPropertyForRFPError,
        reviewPropertyForRFPSuccess, reviewPropertyForRFPError,
        acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess, acceptCreateUpdatePropertyAcceptDeclineOfRFPError,
        declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess, declineCreateUpdatePropertyAcceptDeclineOfRFPError,
        partAcceptedPropertyForRFPSuccess, partAcceptedPropertyForRFPError,
        partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError,
        getPropertyAcceptDeclineOfRFPByPropertyIdSuccess, respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess, respondCreateUpdatePropertyAcceptDeclineOfRFPError
    } = useStoreState<any>(
        (state) => ({
            getPropertyDetailedSuccess: state.property.getPropertyDetailedSuccess,
            getAmenityListSuccess: state.property.getAmenityListSuccess,

            acceptPropertyRateForRFPSuccess: state.rfpProcess.acceptPropertyRateForRFPSuccess,
            acceptPropertyRateForRFPError: state.rfpProcess.acceptPropertyRateForRFPError,

            declinePropertyRateForRFPSuccess: state.rfpProcess.declinePropertyRateForRFPSuccess,
            declinePropertyRateForRFPError: state.rfpProcess.declinePropertyRateForRFPError,

            // getPropertyInventoryRatesSuccess: state.rfpProcess.getPropertyInventoryRatesSuccess,
            getPropertyInventoryRatesForRFPSuccess: state.rfpProcess.getPropertyInventoryRatesForRFPSuccess,

            declinePropertyForRFPSuccess: state.rfpProcess.declinePropertyForRFPSuccess,
            declinePropertyForRFPError: state.rfpProcess.declinePropertyForRFPError,

            reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            reviewCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPError,

            acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            acceptCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFPError,

            declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            declineCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.declineCreateUpdatePropertyAcceptDeclineOfRFPError,

            acceptPropertyForRFPSuccess: state.rfpProcess.acceptPropertyForRFPSuccess,
            acceptPropertyForRFPError: state.rfpProcess.acceptPropertyForRFPError,

            reviewPropertyForRFPSuccess: state.rfpProcess.reviewPropertyForRFPSuccess,
            reviewPropertyForRFPError: state.rfpProcess.reviewPropertyForRFPError,

            partAcceptedPropertyForRFPSuccess: state.rfpProcess.partAcceptedPropertyForRFPSuccess,
            partAcceptedPropertyForRFPError: state.rfpProcess.partAcceptedPropertyForRFPError,

            partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError,
            getPropertyAcceptDeclineOfRFPByPropertyIdSuccess: state.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,
            respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            respondCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.respondCreateUpdatePropertyAcceptDeclineOfRFPError,
        })
    );

    const redirectProperty = useCallback(() => {
        history.push({
            pathname: `/admin/client-proposal-management`,
            state: {
                cityName: property?.city,
                rfpId: rfpId,
                filterProgramManage: true,
                defaultActiveKey: 'negotiations'
            }
        })
        // history.push('/admin/property-management', { brandId: brandId })
    }, [history, property?.city, rfpId])

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (propertyId) {
            const payload = {
                propertyId: propertyId
            }
            propertyDetailed(payload);
            getAmenityList();
        }
    }, [getAmenityList, propertyDetailed, propertyId]);

    ////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        // const payload = {
        //     rfp_id: rfpId,
        //     propertyId: propertyId
        // }
        // propertyInventoryRates(payload);
        const params = {
            brandId: property?.brandId,
            propertyId: propertyId,
            rfpProcessId: rfpId,
            cityName: property?.city
        }
        if (property?.brandId) {
            getPropertyInventoryRatesForRFP(params);
        }


    }, [getPropertyInventoryRatesForRFP, property?.brandId, property?.city, propertyId, rfpId])

    useEffect(() => {

        const payload = {
            rfpId: rfpId,
            propertyId: propertyId
        }
        if (propertyId) {
            getPropertyAcceptDeclineOfRFPByPropertyId(payload);
        }
    }, [getPropertyAcceptDeclineOfRFPByPropertyId, propertyId, rfpId]);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const callApi = useCallback(() => {
        setShowAmendedMessage(true);
        const payload = {
            rfpId: rfpId,
            propertyId: propertyId
        }
        if (propertyId) {
            getPropertyAcceptDeclineOfRFPByPropertyId(payload);
        }
    }, [getPropertyAcceptDeclineOfRFPByPropertyId, propertyId, rfpId]);

    //////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertyAcceptDeclineOfRFPByPropertyIdSuccess) {
            setPropertyAcceptInformation(getPropertyAcceptDeclineOfRFPByPropertyIdSuccess?.data);
        }
    }, [getPropertyAcceptDeclineOfRFPByPropertyIdSuccess])


    useEffect(() => {
        if (propertyAcceptInformation) {
            propertyAcceptInformation?.inventories?.forEach((inv) => {
                if (inv.inventoryId === rates?.inventory) {
                    setNegotiationComment(inv?.negotiationComment);
                    setDeclineComment(inv?.inventoryDeclineComment);
                    setInventoryAmendedRateComment(inv?.inventoryAmendedRateComment);
                    setInventoryAmendedSecondRateComment(inv?.inventoryAmendedSecondRateComment);
                    setInventoryMessage(inv?.inventoryMessageThread);
                }

            })

        }
    }, [propertyAcceptInformation, rates?.inventory])

    useEffect(() => {
        // if (getPropertyInventoryRatesSuccess) {
        //     setInventoryRates(getPropertyInventoryRatesSuccess.data?.property?.inventory);
        // }
        if (getPropertyInventoryRatesForRFPSuccess) {
            setInventoryRates(getPropertyInventoryRatesForRFPSuccess.data?.inventories);
            setRfpProperty(getPropertyInventoryRatesForRFPSuccess.data);
        }
    }, [getPropertyInventoryRatesForRFPSuccess])

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertyDetailedSuccess) {

            setProperty(getPropertyDetailedSuccess.data);
        }

        if (getAmenityListSuccess) {
            setAmenitiesList(getAmenityListSuccess.data);
        }

    }, [getAmenityListSuccess, getPropertyDetailedSuccess]);

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (acceptPropertyRateForRFPSuccess) {
            toast.success("Rate accepted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEnableRatesScreen(false);
            const params = {
                brandId: property?.brandId,
                propertyId: propertyId,
                rfpProcessId: rfpId,
                cityName: property?.city
            }
            if (rfpId) {
                getPropertyInventoryRatesForRFP(params);
            }

            if (showAcceptanceMessage && acceptPropertyRateForRFPSuccess && isAcceptancePropertyBasedClarification === CommonAnswer.NO) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    propertyStatus: BrandRfpPropertyEvaluationStatus.ACCEPTED,
                    cityName: property?.city,
                    isClient: true
                }
                acceptPropertyForRFP(payload);
            }

            if (showAcceptanceMessage && acceptPropertyRateForRFPSuccess && isAcceptancePropertyBasedClarification === CommonAnswer.YES) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
                    cityName: property?.city,
                    isAllInventoryAccepted: isAllInventoryAccepted,
                    isNegotiation: false,
                    isNegotiateProperty: false,
                    isClient: true
                }
                reviewPropertyForRFP(payload);
            }


        }

        if (declinePropertyRateForRFPSuccess) {

            if (declinePropertyRateForRFPSuccess && doYouWishDeclineProperty === CommonAnswer.YES) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    inventoryId: rates?.inventory,
                    doYouWishDeclineProperty: doYouWishDeclineProperty,
                    // declineComment: declineComment,
                    inventoryDeclineComment: declineComment
                }
                declineCreateUpdatePropertyAcceptDeclineOfRFP(payload);
            }
            toast.success("Rate declined successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            const params = {
                brandId: property?.brandId,
                propertyId: propertyId,
                rfpProcessId: rfpId,
                cityName: property?.city
            }
            if (rfpId) {
                getPropertyInventoryRatesForRFP(params);
            }
            setEnableRatesScreen(false);

            if (showDeclineMessage && declinePropertyRateForRFPSuccess && doYouWishDeclineProperty === CommonAnswer.YES && inventoryRates?.length === 1) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    propertyStatus: BrandRfpPropertyEvaluationStatus.DECLINED,
                    cityName: property?.city,
                    isClient: true

                }
                declinePropertyForRFP(payload);
            }
        }

        // if (proposedPropertyRateForRFPSuccess) {
        //     toast.success("Rate proposed successfully", {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         className: 'foo-bar'
        //     });
        //     const params = {
        //         brandId: property?.brandId,
        //         propertyId: propertyId,
        //         rfpProcessId: rfpId,
        //         cityName: property?.city
        //     }
        //     if (rfpId) {
        //         getPropertyInventoryRatesForRFP(params);
        //     }
        //     setEnableRatesScreen(false);
        // }

        // if (negotiatePropertyRateForRFPSuccess) {
        //     toast.success("Rate negotiate successfully", {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         className: 'foo-bar'
        //     });
        //     const params = {
        //         brandId: property?.brandId,
        //         propertyId: propertyId,
        //         rfpProcessId: rfpId,
        //         cityName: property?.city
        //     }
        //     if (rfpId) {
        //         getPropertyInventoryRatesForRFP(params);
        //     }
        //     if (doYouWishNegotiation === CommonAnswer.YES && negotiatePropertyRateForRFPSuccess) {
        //         const payload = {
        //             rfpId: rfpId,
        //             brandId: property?.brandId,
        //             propertyId: propertyId,
        //             propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
        //             cityName: property?.city,

        //         }
        //         reviewPropertyForRFP(payload);
        //     }
        // }
        if (acceptPropertyRateForRFPError) {

            toast.error(acceptPropertyRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEnableRatesScreen(false);
        }

        if (declinePropertyRateForRFPError) {
            toast.error(declinePropertyRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEnableRatesScreen(false);
        }

        // if (proposedPropertyRateForRFPError) {
        //     toast.error(proposedPropertyRateForRFPError.message, {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         className: 'foo-bar'
        //     });
        //     setEnableRatesScreen(false);
        // }

        // if (negotiatePropertyRateForRFPError) {
        //     toast.error(negotiatePropertyRateForRFPError.message, {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         className: 'foo-bar'
        //     });
        //     setEnableRatesScreen(false);
        //     setShowNegotiationMessage(false);
        // }


    }, [acceptPropertyForRFP, acceptPropertyRateForRFPError, acceptPropertyRateForRFPSuccess, declineComment, declineCreateUpdatePropertyAcceptDeclineOfRFP, declinePropertyForRFP, declinePropertyRateForRFPError, declinePropertyRateForRFPSuccess, doYouWishDeclineProperty, getPropertyInventoryRatesForRFP, inventoryRates?.length, isAcceptancePropertyBasedClarification, isAllInventoryAccepted, property?.brandId, property?.city, propertyId, rates?.inventory, reviewPropertyForRFP, rfpId, showAcceptanceMessage, showDeclineMessage]);


    //// decline property//////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (declinePropertyForRFPSuccess) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                doYouWishDeclineProperty: doYouWishDeclineProperty,
                declineComment: declineComment,
            }

            declineCreateUpdatePropertyAcceptDeclineOfRFP(payload);

        }
        if (declinePropertyForRFPError) {
            toast.error(declinePropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (declineCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(declineCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            if (inventoryRates?.length === 1) {
                toast.success("Property declined successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                redirectProperty();
            } else {
                setEnableRatesScreen(false);
                setShowDeclineMessage(false);

            }
            setShowOption(false);
            setShowMessage(false);
        }


    }, [declineComment, declineCreateUpdatePropertyAcceptDeclineOfRFP, declineCreateUpdatePropertyAcceptDeclineOfRFPError, declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess, declinePropertyForRFPError, declinePropertyForRFPSuccess, doYouWishDeclineProperty, inventoryRates?.length, property?.brandId, propertyId, redirectProperty, rfpId]);

    /////////////////////////accept property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (acceptPropertyForRFPSuccess) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                doYouWishDeclineProperty: doYouWishDeclineProperty,
                isAcceptancePropertyBasedClarification: isAcceptancePropertyBasedClarification,
            }
            acceptCreateUpdatePropertyAcceptDeclineOfRFP(payload);
        }

        if (acceptPropertyForRFPError) {
            toast.error(acceptPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (acceptCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(acceptCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property accepted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            redirectProperty();
        }
    }, [acceptCreateUpdatePropertyAcceptDeclineOfRFP, acceptCreateUpdatePropertyAcceptDeclineOfRFPError, acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess, acceptPropertyForRFPError, acceptPropertyForRFPSuccess, doYouWishDeclineProperty, isAcceptancePropertyBasedClarification, property?.brandId, propertyId, redirectProperty, rfpId]);


    /////////////////////////review property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (reviewPropertyForRFPSuccess) {
            if (doYouWishDeclineProperty === CommonAnswer.NO && isAllAcceptedInventory && rfpProperty?.inventories?.length > 1 && isAcceptancePropertyBasedClarification === CommonAnswer.YES) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    doYouWishDeclineProperty: doYouWishDeclineProperty,
                    isAcceptancePropertyBasedClarification: isAcceptancePropertyBasedClarification,
                    clarificationComment: clarificationComment,
                    // doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission: doYouWishOpenNegotiationProperty,
                    // negotiationComment: negotiationComment,
                }

                reviewCreateUpdatePropertyAcceptDeclineOfRFP(payload);
            } else {
                toast.success("Property review successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                setShowAmendedMessage(false);
                setShowMessage(false);
                //   callApiList();
                redirectProperty();
            }


        }
        // for ticket 742 
        // if (reviewPropertyForRFPSuccess) {
        //     toast.success("Property review successfully", {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         className: 'foo-bar'
        //     });
        //     setShowAmendedMessage(false);
        //     setShowMessage(false);
        //     //   callApiList();
        //     redirectProperty();
        // }





        if (reviewPropertyForRFPError) {
            toast.error(reviewPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(reviewCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property review successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            redirectProperty();
        }


    }, [clarificationComment, doYouWishDeclineProperty, doYouWishOpenNegotiationProperty, isAcceptancePropertyBasedClarification, isAllAcceptedInventory, property?.brandId, propertyId, redirectProperty, reviewCreateUpdatePropertyAcceptDeclineOfRFP, reviewCreateUpdatePropertyAcceptDeclineOfRFPError, reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess, reviewPropertyForRFPError, reviewPropertyForRFPSuccess, rfpId, rfpProperty?.inventories?.length]);


    /////////////////////////part accepted property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (partAcceptedPropertyForRFPSuccess) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                doYouWishDeclineProperty: doYouWishDeclineProperty,
                doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission: doYouWishOpenNegotiationProperty,
                negotiationComment: negotiationComment,
                isAcceptancePropertyBasedClarification: isAcceptancePropertyBasedClarification,
            }

            partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP(payload);

        }

        if (partAcceptedPropertyForRFPError) {
            toast.error(partAcceptedPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            if (doYouWishOpenNegotiationProperty === CommonAnswer.NO && isAcceptancePropertyBasedClarification === CommonAnswer.NO) {
                toast.success("Property part accepted successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            } else {
                toast.success("Property review successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
            redirectProperty();
        }


    }, [doYouWishDeclineProperty, doYouWishOpenNegotiationProperty, isAcceptancePropertyBasedClarification, negotiationComment, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess, partAcceptedPropertyForRFPError, partAcceptedPropertyForRFPSuccess, property?.brandId, propertyId, redirectProperty, rfpId]);




    ///////////////////////// menu change ///////////////////////////////////
    const menuChange = useCallback((value) => {
        setMenuKey(value.key);
    }, []);

    const acceptOneInventoryProperty = useCallback(() => {
        if (showAcceptanceMessage && isAcceptancePropertyBasedClarification === CommonAnswer.NO) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyInventoryId: rates?.inventory,
                propertyInventoryStatus: PropertyInventoryStatus.ACCEPTED,
                cityName: property?.city,

            }

            acceptPropertyRateForRFP(payload);
        }
        if (showAcceptanceMessage && isAcceptancePropertyBasedClarification === CommonAnswer.YES) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                doYouWishToRespondProperty: respondProperty,
                // respondPropertyComment: propertyRespondedComment
                clientRespondComment: {
                    messageText: propertyRespondedComment,
                    messageTime: new Date()
                }
            }
            respondCreateUpdatePropertyAcceptDeclineOfRFP(payload);
        }



    }, [showAcceptanceMessage, isAcceptancePropertyBasedClarification, rfpId, property?.brandId, property?.city, propertyId, rates?.inventory, acceptPropertyRateForRFP, respondProperty, propertyRespondedComment, respondCreateUpdatePropertyAcceptDeclineOfRFP])


    /////////////////////////accept rate ///////////////////////////////////
    const onAcceptRate = useCallback(() => {
        if (inventoryRates?.length === 1) {
            setShowAcceptanceMessage(true);

        } else {
            setShowAcceptanceMessage(false);

            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyInventoryId: rates?.inventory,
                propertyInventoryStatus: PropertyInventoryStatus.ACCEPTED,
                cityName: property?.city,

            }
            acceptPropertyRateForRFP(payload);
        }
    }, [acceptPropertyRateForRFP, inventoryRates?.length, property?.brandId, property?.city, propertyId, rates?.inventory, rfpId]);

    ///////////////////////// decline rate ///////////////////////////////////
    const onDeclineRate = useCallback(() => {
        if (inventoryRates?.length === 1) {
            setShowDeclineMessage(true);

        } else if (inventoryRates?.length > 1 && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEWED) {
            setShowDeclineMessage(true);
        } else {
            setShowDeclineMessage(false);
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyInventoryId: rates?.inventory,
                propertyInventoryStatus: PropertyInventoryStatus.DECLINED,
                cityName: property?.city,

            }
            declinePropertyRateForRFP(payload);
        }

    }, [declinePropertyRateForRFP, inventoryRates?.length, property?.brandId, property?.city, propertyId, rates?.inventory, rates?.rfpPropertyRateStatus, rfpId]);
    //////////// accept or decline //////////
    const onChangePropertyOption = useCallback((value) => {
        if (value === CommonAnswer.NO) {
            setShowDeclineMessage(false);
            setEnableRatesScreen(false);
        }

        setDoYouWishDeclineProperty(value);
    }, []);

    ////////////  //////////
    const onSubmitClarification = useCallback((value) => {
        setIsAcceptancePropertyBasedClarification(value);
    }, []);

    // //////////// accept or decline //////////
    // const onOpenNegotiation = useCallback((value) => {
    //     setDoYouWishOpenNegotiationProperty(value);
    // }, []);


    //////////// declineProperty //////////
    const declineProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyStatus: BrandRfpPropertyEvaluationStatus.DECLINED,
            cityName: property?.city,
            isAllInventoryAccepted: isAllInventoryAccepted,
            isNegotiateProperty: false,
            isClient: true
        }
        declinePropertyForRFP(payload);
    }, [declinePropertyForRFP, isAllInventoryAccepted, property?.brandId, property?.city, propertyId, rfpId]);

    //////////// accept Property //////////
    const acceptProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyStatus: BrandRfpPropertyEvaluationStatus.ACCEPTED,
            cityName: property?.city,
            isNegotiateProperty: false,
            isClient: true
        }
        acceptPropertyForRFP(payload);
    }, [acceptPropertyForRFP, property?.brandId, property?.city, propertyId, rfpId]);

    //////////// reviewProperty //////////
    const onReviewProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
            cityName: property?.city,
            isAllInventoryAccepted: isAllInventoryAccepted,
            isNegotiation: false,
            isNegotiateProperty: false
        }
        reviewPropertyForRFP(payload);
    }, [isAllInventoryAccepted, property?.brandId, property?.city, propertyId, reviewPropertyForRFP, rfpId]);

    // //////////// part accepted //////////
    // const onSubmitNegotiation = useCallback(() => {
    //     setShowAcceptanceClarification(true);
    // }, []);


    //////////// part accept Property //////////
    const partAcceptProperty = useCallback(() => {
        if (doYouWishOpenNegotiationProperty === CommonAnswer.NO && isAcceptancePropertyBasedClarification === CommonAnswer.NO) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyStatus: BrandRfpPropertyEvaluationStatus.PART_ACCEPTED,
                cityName: property?.city,
                isAllInventoryAccepted: isAllInventoryAccepted,
                isNegotiation: false

            }
            partAcceptedPropertyForRFP(payload);
        } else {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
                cityName: property?.city,
                isAllInventoryAccepted: isAllInventoryAccepted,
                isNegotiation: false

            }
            partAcceptedPropertyForRFP(payload);
        }

    }, [doYouWishOpenNegotiationProperty, isAcceptancePropertyBasedClarification, isAllInventoryAccepted, partAcceptedPropertyForRFP, property?.brandId, property?.city, propertyId, rfpId]);




    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const filteredRfpProcessList = inventoryRates?.filter(process => process.rfpPropertyRateStatus === PropertyInventoryStatus.PROPOSED);
        if (filteredRfpProcessList?.length > 0) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }

        const filteredDeclineInventoryList = inventoryRates?.filter(process => process.rfpPropertyRateStatus === PropertyInventoryStatus.DECLINED);

        if (filteredDeclineInventoryList?.length > 0) {
            setIsAllInventoryAccepted(false);
        } else {
            setIsAllInventoryAccepted(true);
        }

        const filteredAcceptMultipleInventoryList = inventoryRates?.filter(process => ((process.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEWED) || (process.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEW)));
        if (filteredAcceptMultipleInventoryList?.length >= 1) {
            setIsAllAcceptedInventory(false);
        } else {
            setIsAllAcceptedInventory(true);
        }

    }, [inventoryRates]);



    const declineOneInventoryOfProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyInventoryId: rates?.inventory,
            propertyInventoryStatus: PropertyInventoryStatus.DECLINED,
            cityName: property?.city,

        }
        declinePropertyRateForRFP(payload);
    }, [declinePropertyRateForRFP, property?.brandId, property?.city, propertyId, rates?.inventory, rfpId]);


    /////////////////////////add review comment//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (respondCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(respondCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property respond added successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            callApi();
            /**
             * change again review mood rate
             */

            if (showAcceptanceMessage && isAcceptancePropertyBasedClarification === CommonAnswer.YES && respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    propertyInventoryId: rates?.inventory,
                    propertyInventoryStatus: PropertyInventoryStatus.ACCEPTED,
                    cityName: property?.city,

                }
                acceptPropertyRateForRFP(payload);
            } else {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    propertyInventoryId: rates?.inventory,
                    propertyInventoryStatus: PropertyInventoryStatus.REVIEW,
                    cityName: property?.city,
                }

                negotiatePropertyRateForRFP(payload);
            }





            if (respondProperty === CommonAnswer.YES) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
                    cityName: property?.city,
                    isClient: true
                }
                reviewPropertyForRFP(payload);
            } else {
                setShowAmendedMessage(false);
            }


        }
    }, [acceptPropertyRateForRFP, callApi, isAcceptancePropertyBasedClarification, negotiatePropertyRateForRFP, property?.brandId, property?.city, propertyId, rates?.inventory, respondCreateUpdatePropertyAcceptDeclineOfRFPError, respondCreateUpdatePropertyAcceptDeclineOfRFPSuccess, respondProperty, reviewPropertyForRFP, rfpId, showAcceptanceMessage]);


    //////////////////////////////////////////////////////////////////////////////////////////////
    const onSubmitResponseOfProperty = useCallback(() => {

        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            doYouWishToRespondProperty: respondProperty,
            inventoryId: rates?.inventory,
            // respondPropertyComment: propertyRespondedComment
            clientRespondComment: {
                messageText: propertyRespondedComment,
                messageTime: new Date()
            }
        }
        respondCreateUpdatePropertyAcceptDeclineOfRFP(payload);
    }, [property?.brandId, propertyId, propertyRespondedComment, respondCreateUpdatePropertyAcceptDeclineOfRFP, respondProperty, rfpId, rates?.inventory]);

    const onSaveResponse = useCallback((value) => {
        // console.log(respondProperty, 'respondProperty')
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            doYouWishToRespondProperty: value,
        }
        respondCreateUpdatePropertyAcceptDeclineOfRFP(payload);
    }, [property?.brandId, propertyId, respondCreateUpdatePropertyAcceptDeclineOfRFP, rfpId])


    const onSubmitResponse = useCallback((value) => {
        setRespondProperty(value);

        if (value === CommonAnswer.NO) {
            // setShowMessage(false);
            setShowComment(false);
            onSaveResponse(value);
        } else {
            setShowComment(true);
        }
    }, [onSaveResponse]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <div style={tabDivStyle}>
                <Tab.Container defaultActiveKey="property" >
                    <Row >
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                                <div style={{ marginTop: '0%' }}>
                                    <Nav.Item><Nav.Link eventKey="property">Property</Nav.Link></Nav.Item>
                                    {/* <Nav.Item><Nav.Link eventKey="RFPSurvey">Property Survey  </Nav.Link></Nav.Item> */}
                                    <Nav.Item><Nav.Link eventKey="operator" onClick={() => {
                                        history.push({
                                            pathname: `/admin/client-proposal-management/operator/${propertyId}/${rfpId}`
                                        })
                                    }}>Operator</Nav.Link></Nav.Item>
                                </div>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content >
                                <Tab.Pane eventKey="property" >

                                    <Row>
                                        <Col md={7}>
                                            <Breadcrumb className='bc_unset'>
                                                <Breadcrumb.Item style={{ fontSize: '24px' }} onClick={() => {
                                                    history.push({
                                                        pathname: `/admin/client-proposal-management`,
                                                        state: {
                                                            cityName: property?.city,
                                                            rfpId: rfpId,
                                                            filterProgramManage: true,
                                                            defaultActiveKey: 'negotiations'
                                                        }
                                                    })
                                                }}>{property?.city}</Breadcrumb.Item>
                                                <Breadcrumb.Item style={{ fontSize: '24px', cursor: 'pointer' }} active onClick={() => {
                                                    setEnableRatesScreen(false);
                                                }}>{`${property?.propertyName} by ${property?.brand?.name}`}</Breadcrumb.Item>
                                                {enableRatesScreen && <Breadcrumb.Item style={{ fontSize: '24px', cursor: 'pointer' }} active >{`${rates?.inventoryDetails[0]?.accommodationDetail}`} </Breadcrumb.Item>}
                                            </Breadcrumb>
                                        </Col>

                                        <Col md={5}>

                                            {!enableRatesScreen && (rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.COMPLETE ||
                                                (rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && rfpProperty?.inventories[0]?.rfpPropertyRateStatus === PropertyInventoryStatus.ACCEPTED && rfpProperty?.inventories?.length === 1)
                                                || (rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && rfpProperty?.inventories[0]?.rfpPropertyRateStatus === PropertyInventoryStatus.ACCEPTED && rfpProperty?.inventories?.length === 1)
                                                || (rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && isAllAcceptedInventory && rfpProperty?.inventories?.length > 1) || (rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && isAllAcceptedInventory && rfpProperty?.inventories.length > 1)
                                                || (rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.INCOMPLETE)
                                                // || (!isAllInventoryAccepted && rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && rfpProperty?.inventories?.length > 1)
                                            ) &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-approval-property btn_design mr-0' hidden={disabledButton} onClick={() => { setShowOption(true); setShowMessage(true); }}><b>Approve/Decline Property</b></Button>
                                                    {/* <Button style={{ ...buttonStyle, background: '#4ABEB4', color: '#ffffff' }}><b>Decline <CloseOutlined /></b></Button> */}
                                                </div>
                                            }

                                            {(rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) && !enableRatesScreen &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-approval btn_design' ><b>Accepted Property </b> </Button>
                                                </div>
                                            }

                                            {(rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED) && !enableRatesScreen &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-decline btn_design'  ><b>Declined Property </b></Button>
                                                </div>
                                            }
                                            {/* {(rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW) && !enableRatesScreen &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-review btn_design'  ><b>Review Property </b></Button>
                                                </div>
                                            } */}
                                            <div className='d-flex flex-row' >
                                                {enableRatesScreen && <Button className='btn-comment mr-2' onClick={() => { callApi(); }} ><b>View Comments </b></Button>}
                                                {(enableRatesScreen && ((rates?.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEWED) || rates?.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEW)) && <Button className='btn-approval mr-2' onClick={() => { onAcceptRate() }}><b>Accept Rates </b> </Button>}
                                                {/* {(enableRatesScreen && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.PROPOSED) && <Button className='btn-negotiate btn_design mr-8' onClick={() => { setShowNegotiationMessage(true) }} ><b>Negotiate Rates </b></Button>} */}
                                                {(enableRatesScreen && ((rates?.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEWED) || rates?.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEW)) && <Button className='btn-decline ' onClick={() => { onDeclineRate(); }} ><b>Decline Rates </b></Button>}
                                                {(enableRatesScreen && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.ACCEPTED) &&
                                                    <Button className='btn-approval' ><b>Accepted </b> </Button>
                                                }
                                                {(enableRatesScreen && rates.rfpPropertyRateStatus === PropertyInventoryStatus.DECLINED) &&
                                                    <Button className='btn-decline'  ><b>Declined </b></Button>
                                                }
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={12}>
                                            <div className='icn_txt' style={{ marginTop: '-5px' }}><EnvironmentOutlined /> {`${property?.address1} ${property?.address2}, ${property?.city} - ${property?.postcode}`}</div>
                                        </Col>
                                    </Row>


                                    <Modal
                                        show={showMessage}
                                        onHide={() => { setShowMessage(false); setShowOption(false); setDoYouWishDeclineProperty(null); setIsAcceptancePropertyBasedClarification(null); setDoYouWishOpenNegotiationProperty(null); }}
                                        size="xl"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered={false}
                                    >
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>

                                            {showOption &&
                                                <Row className=''>
                                                    <div style={{ fontSize: '16px' }}>Do you wish to decline this property?
                                                        <span style={{ marginLeft: '2%' }}>
                                                            <Form.Check
                                                                inline
                                                                value={'Yes'}
                                                                label={'Yes'}
                                                                type="radio"
                                                                name='doYouWishDeclineProperty'
                                                                onChange={(value) => {
                                                                    onChangePropertyOption(value.target.value);
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value={'No'}
                                                                label={'No'}
                                                                type="radio"

                                                                name='doYouWishDeclineProperty'
                                                                onChange={(value) => {
                                                                    onChangePropertyOption(value.target.value);
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>}

                                            {doYouWishDeclineProperty === CommonAnswer.YES &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>
                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"
                                                                name='declineComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setDeclineComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' onClick={() => { declineProperty() }} ><b>Decline Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                            {/* {(doYouWishDeclineProperty === CommonAnswer.NO && !isAllInventoryAccepted) &&
                                                <Row className='mt-3'>
                                                    <div style={{ fontSize: '16px' }}>Do you wish to open up negotiation with the property in relation to the rate submission?
                                                        <span style={{ marginLeft: '2%' }}>
                                                            <Form.Check
                                                                inline
                                                                value={'Yes'}
                                                                label={'Yes'}
                                                                type="radio"
                                                                name='doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission'
                                                                onChange={(value) => {
                                                                    onOpenNegotiation(value.target.value);
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value={'No'}
                                                                label={'No'}
                                                                type="radio"

                                                                name='doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission'
                                                                onChange={(value) => {
                                                                    onOpenNegotiation(value.target.value);
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>} */}

                                            {/* {
                                                !isAllInventoryAccepted && (doYouWishOpenNegotiationProperty === CommonAnswer.NO || doYouWishOpenNegotiationProperty === CommonAnswer.YES) &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>
                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"

                                                                name='negotiationComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setNegotiationComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>
                                                    {!showAcceptanceClarification && <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { onSubmitNegotiation(); }} >Submit</Button>
                                                        </Col>
                                                    </Row>}
                                                </div>
                                            } */}


                                            {/* {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.YES &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>


                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please providededd"
                                                                name='clarificationComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setClarificationComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { onReviewProperty() }} >Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            } */}

                                            {/* {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && showAcceptanceClarification && !isAllInventoryAccepted && (doYouWishOpenNegotiationProperty === CommonAnswer.NO) &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <p>Click to accept the property for the inventories accepted above</p>
                                                    </Row>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-approval' onClick={() => { partAcceptProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            } */}

                                            {/* {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && showAcceptanceClarification && !isAllInventoryAccepted && (doYouWishOpenNegotiationProperty === CommonAnswer.YES) &&
                                                <div>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { partAcceptProperty() }} >Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            } */}

                                            {/* 
                                            {
                                                (doYouWishDeclineProperty === CommonAnswer.NO && rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW) &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' onClick={() => { acceptProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            } */}
                                            {/* 
                                            {
                                                (doYouWishDeclineProperty === CommonAnswer.NO && rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED) &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' onClick={() => { acceptProperty() }} ><b>Accept Propertysss </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            } */}



                                            {
                                                (doYouWishDeclineProperty === CommonAnswer.NO && isAllAcceptedInventory && rfpProperty?.inventories?.length > 1) &&
                                                <Row className='mt-3'>
                                                    <div style={{ fontSize: '16px' }}>Is acceptance of the property based on any factors or further clarification?
                                                        <span style={{ marginLeft: '2%' }}>
                                                            <Form.Check
                                                                inline
                                                                value={'Yes'}
                                                                label={'Yes'}
                                                                type="radio"
                                                                name='isAcceptancePropertyBasedClarification'
                                                                onChange={(value) => {
                                                                    onSubmitClarification(value.target.value);
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value={'No'}
                                                                label={'No'}
                                                                type="radio"

                                                                name='isAcceptancePropertyBasedClarification'
                                                                onChange={(value) => {
                                                                    onSubmitClarification(value.target.value);
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>}


                                            {
                                                (doYouWishDeclineProperty === CommonAnswer.NO && isAcceptancePropertyBasedClarification === CommonAnswer.YES) &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>


                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"
                                                                name='clarificationComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setClarificationComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { onReviewProperty() }} >Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }




                                            {
                                                (doYouWishDeclineProperty === CommonAnswer.NO && isAcceptancePropertyBasedClarification === CommonAnswer.NO) &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-approval' onClick={() => { acceptProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }


                                            {
                                                (doYouWishDeclineProperty === CommonAnswer.NO && rfpProperty?.inventories?.length === 1) &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-approval' onClick={() => { acceptProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </Modal.Body>
                                    </Modal>



                                    {/* 
                                    {
                                        (rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW && isAllAcceptedInventory && rfpProperty?.inventories?.length > 1) &&
                                        <Modal
                                            show={showMessage}
                                            onHide={() => { setShowMessage(false); setShowOption(false); setDoYouWishDeclineProperty(null); setIsAcceptancePropertyBasedClarification(null); setDoYouWishOpenNegotiationProperty(null); }}
                                            size="xl"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered={false}
                                        >
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <Modal.Body>

                                                {showOption &&
                                                    <Row className=''>
                                                        <div style={{ fontSize: '16px' }}>Do you wish to decline this property?
                                                            <span style={{ marginLeft: '2%' }}>
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    name='doYouWishDeclineProperty'
                                                                    onChange={(value) => {
                                                                        onChangePropertyOption(value.target.value);
                                                                    }}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    label={'No'}
                                                                    type="radio"

                                                                    name='doYouWishDeclineProperty'
                                                                    onChange={(value) => {
                                                                        onChangePropertyOption(value.target.value);
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </Row>}

                                                {doYouWishDeclineProperty === CommonAnswer.YES &&
                                                    <div>
                                                        <Row className='mt-3'>
                                                            <Col style={{ textAlign: 'left' }}>
                                                                <Form.Control as="textarea" rows={5}
                                                                    placeholder="please provide comments"
                                                                    name='declineComment'
                                                                    // value={''}
                                                                    onChange={(e) => {
                                                                        setDeclineComment(e.target.value);
                                                                    }}
                                                                />


                                                            </Col>
                                                        </Row>

                                                        <Row className='formSpace'>
                                                            <Col md={6} style={{ textAlign: 'left' }}>
                                                                <Button id='approve-button' onClick={() => { declineProperty() }} ><b>Decline Property </b></Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }

                                                {
                                                    doYouWishDeclineProperty === CommonAnswer.NO &&
                                                    <Row className='mt-3'>
                                                        <div style={{ fontSize: '16px' }}>Is acceptance of the property based on any factors or further clarification?
                                                            <span style={{ marginLeft: '2%' }}>
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    name='isAcceptancePropertyBasedClarification'
                                                                    onChange={(value) => {
                                                                        onSubmitClarification(value.target.value);
                                                                    }}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    label={'No'}
                                                                    type="radio"

                                                                    name='isAcceptancePropertyBasedClarification'
                                                                    onChange={(value) => {
                                                                        onSubmitClarification(value.target.value);
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </Row>}


                                                {
                                                    (doYouWishDeclineProperty === CommonAnswer.NO && isAcceptancePropertyBasedClarification === CommonAnswer.YES) &&
                                                    <div>
                                                        <Row className='mt-3'>
                                                            <Col style={{ textAlign: 'left' }}>


                                                                <Form.Control as="textarea" rows={5}
                                                                    placeholder="please provide comments"
                                                                    name='clarificationComment'
                                                                    // value={''}
                                                                    onChange={(e) => {
                                                                        setClarificationComment(e.target.value);
                                                                    }}
                                                                />


                                                            </Col>
                                                        </Row>

                                                        <Row className='formSpace'>
                                                            <Col md={6} style={{ textAlign: 'left' }}>
                                                                <Button id='approve-button' style={formButton} onClick={() => { onReviewProperty() }} >Submit</Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }




                                                {
                                                    (doYouWishDeclineProperty === CommonAnswer.NO && isAcceptancePropertyBasedClarification === CommonAnswer.NO) &&
                                                    <div>
                                                        <Row className='formSpace'>
                                                            <Col md={6} style={{ textAlign: 'left' }}>
                                                                <Button className='btn-approval' onClick={() => { acceptProperty() }} ><b>Accept Property </b></Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }



                                            </Modal.Body>
                                        </Modal>} */}



                                    <Modal
                                        show={showAcceptanceMessage}
                                        onHide={() => { setShowAcceptanceMessage(false); }}
                                        size="xl"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered={false}
                                    >
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Row className='mt-3'>
                                                <div style={{ fontSize: '16px' }}>Is acceptance of the property based on any factors or further clarification?
                                                    <span style={{ marginLeft: '2%' }}>
                                                        <Form.Check
                                                            inline
                                                            value={'Yes'}
                                                            label={'Yes'}
                                                            type="radio"
                                                            name='isAcceptancePropertyBasedClarification'
                                                            onChange={(value) => {
                                                                onSubmitClarification(value.target.value);
                                                            }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            value={'No'}
                                                            label={'No'}
                                                            type="radio"

                                                            name='isAcceptancePropertyBasedClarification'
                                                            onChange={(value) => {
                                                                onSubmitClarification(value.target.value);
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </Row>

                                            {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.YES &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>


                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="comment"
                                                                name='clarificationComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setPropertyRespondedComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { acceptOneInventoryProperty() }} >Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }

                                            {/* {
                                                (isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && !isAllInventoryAccepted) &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <p>Click to accept the property for the inventories accepted above</p>
                                                    </Row>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-approval' onClick={() => { partAcceptProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            } */}



                                            {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.NO &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-approval' onClick={() => { acceptOneInventoryProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </Modal.Body>
                                    </Modal>


                                    <Modal
                                        show={showDeclineMessage}
                                        onHide={() => { setShowDeclineMessage(false); }}
                                        size="xl"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered={false}
                                    >
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>


                                            <Row className='mt-3'>
                                                <div style={{ fontSize: '16px' }}>Do you wish to decline this property?
                                                    <span style={{ marginLeft: '2%' }}>
                                                        <Form.Check
                                                            inline
                                                            value={'Yes'}
                                                            label={'Yes'}
                                                            type="radio"
                                                            name='doYouWishDeclineProperty'
                                                            onChange={(value) => {
                                                                onChangePropertyOption(value.target.value);
                                                            }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            value={'No'}
                                                            label={'No'}
                                                            type="radio"

                                                            name='doYouWishDeclineProperty'
                                                            onChange={(value) => {
                                                                onChangePropertyOption(value.target.value);
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </Row>

                                            {doYouWishDeclineProperty === CommonAnswer.YES &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>
                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"
                                                                name='declineComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setDeclineComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { declineOneInventoryOfProperty() }} >Submit</Button>
                                                        </Col>
                                                        <Col md={6} style={{ textAlign: 'right' }}>
                                                            <Button style={formButton} onClick={() => { setShowDeclineMessage(false); }} id='edit-button' >Close</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }



                                        </Modal.Body>
                                    </Modal>


                                    {
                                        <Modal
                                            show={showAmendedMessage}
                                            // onHide={() => { setShowAmendedMessage(false); }}
                                            size="lg"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered={false}
                                        >
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {
                                                    (negotiationComment && (rates?.rfpPropertyRateStatus === PropertyInventoryStatus.DECLINED || rates?.rfpPropertyRateStatus === PropertyInventoryStatus.ACCEPTED || rates?.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEW || rates?.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEWED)) &&
                                                    <Row>
                                                        <Col md={10}><div style={{ textAlign: 'left' }}>

                                                            <Form.Control as="textarea" rows={5} disabled
                                                                // placeholder="please provide comments"
                                                                name='negotiationComment'
                                                                defaultValue={negotiationComment}
                                                            />
                                                        </div></Col>
                                                        <Col md={1}></Col>
                                                    </Row>}
                                                {
                                                    (declineComment && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.DECLINED) &&
                                                    <Row>
                                                        <Col md={10}><div style={{ textAlign: 'left' }}>

                                                            <Form.Control as="textarea" rows={5} disabled
                                                                // placeholder="please provide comments"
                                                                name='declinedComment'
                                                                defaultValue={declineComment}
                                                            />
                                                        </div></Col>
                                                        <Col md={1}></Col>
                                                    </Row>}


                                                {
                                                    inventoryAmendedRateComment &&
                                                    <Row>
                                                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                                            <Form.Control as="textarea" rows={5} disabled
                                                                // placeholder="please provide comments"
                                                                name='inventoryAmendedRateComment'
                                                                defaultValue={inventoryAmendedRateComment}
                                                            />
                                                        </div></Col>
                                                        <Col md={1}></Col>
                                                    </Row>}
                                                {
                                                    inventoryAmendedSecondRateComment &&
                                                    <Row>
                                                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                                            <Form.Control as="textarea" rows={5} disabled
                                                                // placeholder="please provide comments"
                                                                name='inventoryAmendedSecondRateComment'
                                                                defaultValue={inventoryAmendedSecondRateComment}
                                                            />
                                                        </div></Col>
                                                        <Col md={1}></Col>
                                                    </Row>}
                                                {/* {
                                                    propertyAcceptInformation?.respondPropertyComment &&
                                                    <Row>
                                                        <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                                            <Form.Control as="textarea" rows={5} disabled
                                                                // placeholder="please provide comments"
                                                                name='respondPropertyComment'
                                                                defaultValue={propertyAcceptInformation?.respondPropertyComment}
                                                            />
                                                        </div></Col>
                                                        <Col md={1}></Col>
                                                    </Row>} */}
                                                {
                                                    inventoryMessage?.length !== 0 && inventoryMessage?.map((values, key) => {
                                                        return (<div key={key}>
                                                            {/* <p style={{ marginBottom: '0px' }}>{(key === 0) ? (values?.direction === 'booker_to_property') ? 'Booker (original message)' : 'Property (original message)' : (values?.direction === 'booker_to_property') ? 'Booker (further message)' : 'Property (further message)'}</p> */}
                                                            {/* <p className="lead">{values?.messageText}</p> */}

                                                            {values?.clientRespondComment && <Row>
                                                                <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                                                    <Form.Control as="textarea" rows={5} disabled
                                                                        // placeholder="please provide comments"
                                                                        name='propertyResponseComment'
                                                                        defaultValue={values?.clientRespondComment?.messageText}
                                                                    />
                                                                </div></Col>
                                                                <Col md={1}></Col>
                                                            </Row>}


                                                            {values?.brandRespondComment && <Row>
                                                                <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                                                    <Form.Control as="textarea" rows={5} disabled
                                                                        // placeholder="please provide comments"
                                                                        name='brandRespondPropertyComment'
                                                                        defaultValue={values?.brandRespondComment?.messageText}
                                                                    />
                                                                </div></Col>
                                                                <Col md={1}></Col>
                                                            </Row>}

                                                        </div>)
                                                    })
                                                }


                                                {
                                                    ((rates?.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEWED && propertyAcceptInformation?.messageThread.length === 0) || (rates?.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEWED && propertyAcceptInformation?.messageThread[propertyAcceptInformation?.messageThread.length - 1]?.brandRespondComment?.messageText)) &&
                                                    <Row className='mt-3'>
                                                        <div style={{ fontSize: '16px' }}>Do you wish to respond to the property?
                                                            <span style={{ marginLeft: '2%' }}>

                                                                {
                                                                    propertyAcceptInformation?.doYouWishToRespondProperty === 'Yes'
                                                                        ?
                                                                        <Form.Check
                                                                            inline
                                                                            value={'Yes'}
                                                                            label={'Yes'}
                                                                            type="radio"
                                                                            name='wishToRespondProperty'
                                                                            onChange={(value) => {
                                                                                onSubmitResponse(value.target.value);
                                                                            }}
                                                                        />
                                                                        :
                                                                        <Form.Check
                                                                            inline
                                                                            value={'Yes'}
                                                                            label={'Yes'}
                                                                            type="radio"
                                                                            name='wishToRespondProperty'
                                                                            onChange={(value) => {
                                                                                onSubmitResponse(value.target.value);
                                                                            }}
                                                                        />
                                                                }

                                                                {
                                                                    propertyAcceptInformation?.doYouWishToRespondProperty === 'No'
                                                                        ?
                                                                        <Form.Check
                                                                            inline
                                                                            value={'No'}
                                                                            label={'No'}
                                                                            type="radio"
                                                                            name='wishToRespondProperty'
                                                                            onChange={(value) => {
                                                                                onSubmitResponse(value.target.value);
                                                                            }}
                                                                        />
                                                                        :
                                                                        <Form.Check
                                                                            inline
                                                                            value={'No'}
                                                                            label={'No'}
                                                                            type="radio"
                                                                            name='wishToRespondProperty'
                                                                            onChange={(value) => {
                                                                                onSubmitResponse(value.target.value);
                                                                            }}
                                                                        />
                                                                }

                                                            </span>
                                                        </div>
                                                    </Row>}



                                                {(showComment) && <Row>
                                                    <Col md={10}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                                        <Form.Control as="textarea" rows={5}
                                                            // placeholder="please provide comments"
                                                            name='propertyResponseComment'
                                                            // defaultValue={propertyAcceptInformation?.respondPropertyComment}
                                                            onChange={(e) => {
                                                                setPropertyRespondedComment(e.target.value);
                                                            }}
                                                        />
                                                    </div></Col>
                                                    <Col md={1}></Col>
                                                </Row>}

                                                {(showComment) &&
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { onSubmitResponseOfProperty() }} >Submit</Button>
                                                        </Col>
                                                        <Col md={6} style={{ textAlign: 'right' }}>
                                                            <Button style={formButton} onClick={() => { setShowAmendedMessage(false); }} id='edit-button' >Close</Button>
                                                        </Col>
                                                    </Row>
                                                }


                                                {(!showComment) &&
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            {/* <Button id='approve-button' style={formButton} onClick={() => { onSubmitResponseOfProperty() }} >Submit</Button> */}
                                                        </Col>
                                                        <Col md={6} style={{ textAlign: 'right' }}>
                                                            <Button style={formButton} onClick={() => { setShowAmendedMessage(false); }} id='edit-button' >Close</Button>
                                                        </Col>
                                                    </Row>
                                                }

                                            </Modal.Body>
                                        </Modal>}

                                    {/* {
                                        rates?.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEW &&
                                        <Modal
                                            show={showAmendedMessage}
                                            onHide={() => { setShowAmendedMessage(false); }}
                                            size="lg"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered={false}
                                        >
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {
                                                    negotiationComment &&
                                                    <Row>
                                                        <Col md={10}><div style={{ textAlign: 'left' }}>

                                                            <Form.Control as="textarea" rows={5} disabled
                                                                // placeholder="please provide comments"
                                                                name='negotiationComment'
                                                                defaultValue={negotiationComment}
                                                            />
                                                        </div></Col>
                                                        <Col md={1}></Col>
                                                    </Row>}


                                                {
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                          
                                                        </Col>
                                                        <Col md={6} style={{ textAlign: 'right' }}>
                                                            <Button style={formButton} onClick={() => { setShowAmendedMessage(false); }} id='edit-button' >Close</Button>
                                                        </Col>
                                                    </Row>
                                                }

                                            </Modal.Body>
                                        </Modal>} */}

                                    {
                                        !enableRatesScreen && <div>


                                            <div className='mt-4'>
                                                <Menu onClick={menuChange} mode="horizontal" selectedKeys={[menuKey]} className="w-full new_tab ">
                                                    <Menu.Item key="property" >
                                                        <span>Property</span>
                                                    </Menu.Item>
                                                    <Menu.Item key="rates" >
                                                        <span>Rates</span>
                                                    </Menu.Item>
                                                    <Menu.Item key="audit" >
                                                        <span>Audit</span>
                                                    </Menu.Item>
                                                    <Menu.Item key="sustainability" >
                                                        <span>Sustainability</span>
                                                    </Menu.Item>
                                                </Menu>
                                            </div>

                                            <div className="new_tab_ctnt new_tab_ctnt2" >
                                                <div className='w-full' >
                                                    {(menuKey === 'property') && <ClientSinglePropertyView property={property} amenitiesList={amenitiesList} />}
                                                    {(menuKey === 'rates') && <ClientRatesListView setRates={setRates} setEnableRatesScreen={setEnableRatesScreen} inventoryRates={inventoryRates} property={property} />}
                                                    {(menuKey === 'audit') && <ClientAuditView property={property} />}
                                                    {(menuKey === 'sustainability') && <SustainabilityView />}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        enableRatesScreen && <div>
                                            <Row>
                                                <Col md={12}>
                                                    <div className='mt-5  w-full'>
                                                        <ClientPropertyRateDetails rates={rates} property={property} inventoryMessage={inventoryMessage} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    }


                                </Tab.Pane>
                                {<Tab.Pane eventKey="RFPSurvey">
                                    <div>

                                    </div>
                                </Tab.Pane>}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>


        </>
    );
}

export default ClientNegotiationPropertyDetailsToAccess;