import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { $mysaBlack, $mysaYellow } from '../../../../common/components-style';
import { useForm, Controller } from 'react-hook-form';
import { SeasonalRateForm } from '../../../../common/interfaces';
import { toast } from 'react-toastify';
import { DatePicker } from 'antd';
import moment from 'moment';
import { CalendarOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
/////////////////////////////// details block style////////////////////////////////////////
const headStyle = { fontSize: '17px', fontWeight: 'bold', padding: '12%', marginTop: '5%', width: '200px' } as React.CSSProperties;
const headBorderStyle = { borderLeft: '2px solid #C4C4C4', marginRight: '3%' } as React.CSSProperties;
const bodyTextStyle = { fontSize: '18px', padding: '5%', textAlign: 'center' } as React.CSSProperties;
const bodyTextStyleOne = { fontSize: '18px', padding: '5%', textAlign: 'center', marginTop: '6%', paddingTop: '10%', marginBottom: '11%' } as React.CSSProperties;
const bodyTextInsideStyle = { marginTop: '15%' } as React.CSSProperties;

const formButton = {
    height: "50px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    marginRight: '20px',
    border: '1px solid #ffffff',
    background: $mysaYellow,
    color: $mysaBlack
} as React.CSSProperties;

const { RangePicker } = DatePicker;

const NegotiatedRateFormSeasonalForm: React.FC<any> = ({ propertyId, inventoryId, clientId, setFormOpen, rateId, seasonalRateId, seasonName, selectedInventory }): JSX.Element => {
    const { register, handleSubmit, formState: { errors }, control } = useForm<SeasonalRateForm>();
    const [currency, setCurrency] = useState<any>();
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dateUpdate, setDateUpdate] = useState<boolean>(false);
    const [myoCorporateDataSeasonal, setMYOCorporateDataSeasonal] = useState<any>(null);
    const [clientName, setClientName] = useState<any>("");
    const [corporateRateId, setCorporateRateId] = useState<any>("");
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getProperty, getSeasonalStandardRateByClientId, createCorporateSeasonalRate, updateCorporateSeasonalRate } = useStoreActions<any>((actions) => ({
        getProperty: actions.property.getProperty,
        getSeasonalStandardRateByClientId: actions.rate.getSeasonalStandardRateByClientId,
        updateGenericStandardRate: actions.rate.updateGenericStandardRate,
        createCorporateSeasonalRate: actions.rate.createCorporateSeasonalRate,
        updateCorporateSeasonalRate: actions.rate.updateCorporateSeasonalRate,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getPropertySuccess, seasonalStandardRateByClientIdSuccess, createCorporateSeasonalRateSuccess, createCorporateSeasonalRateError, updateCorporateSeasonalRateSuccess, updateCorporateSeasonalRateError } = useStoreState<any>((state) => ({
        getPropertySuccess: state.property.getPropertySuccess,
        seasonalStandardRateByClientIdSuccess: state.rate.seasonalStandardRateByClientIdSuccess,
        updateGenericStandardRateSuccess: state.rate.updateGenericStandardRateSuccess,
        updateGenericStandardRateError: state.rate.updateGenericStandardRateError,
        createCorporateSeasonalRateSuccess: state.rate.createCorporateSeasonalRateSuccess,
        createCorporateSeasonalRateError: state.rate.createCorporateSeasonalRateError,
        updateCorporateSeasonalRateSuccess: state.rate.updateCorporateSeasonalRateSuccess,
        updateCorporateSeasonalRateError: state.rate.updateCorporateSeasonalRateError,
    }));


    useEffect(() => {

        if (seasonalRateId) {
            setEditEnable(false);
            const params: any = {};
            params.propertyId = propertyId;
            params.inventoryId = inventoryId;
            params.clientId = clientId;
            getSeasonalStandardRateByClientId(params);
        } else {
            setEditEnable(true);
            const params: any = {};
            params.propertyId = propertyId;
            params.inventoryId = inventoryId;
            params.clientId = clientId;
            getSeasonalStandardRateByClientId(params);
        }

        (async function () {
            if (propertyId) {
                await getProperty(propertyId)
            }
        }())
    }, [getProperty, inventoryId, seasonalRateId, propertyId, rateId, clientId, getSeasonalStandardRateByClientId]);

    useEffect(() => {
        if (getPropertySuccess) {
            setCurrency(getPropertySuccess.data?.currency);
        }
        if (createCorporateSeasonalRateSuccess) {
            setLoading(false);
            toast.success("Negotiated seasonal rate created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setFormOpen(false);
        }
        if (createCorporateSeasonalRateError) {
            setLoading(false);
            toast.error(createCorporateSeasonalRateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (updateCorporateSeasonalRateSuccess) {
            setLoading(false);
            toast.success("Negotiated seasonal rate updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            const params: any = {};
            params.propertyId = propertyId;
            params.inventoryId = inventoryId;
            params.clientId = clientId;
            getSeasonalStandardRateByClientId(params);
        }

        if (updateCorporateSeasonalRateError) {
            setLoading(false);
            toast.error(updateCorporateSeasonalRateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (seasonalStandardRateByClientIdSuccess) {
            if (seasonalRateId) {
                seasonalStandardRateByClientIdSuccess.data?.corporateRate[0]?.seasonalRate.forEach(element => {
                    if (seasonalRateId === element._id) {
                        setMYOCorporateDataSeasonal(element);
                    }
                });
                setClientName(seasonalStandardRateByClientIdSuccess.data?.corporateRate[0]?.client?.companyName);
            } else {
                setClientName(seasonalStandardRateByClientIdSuccess.data?.corporateRate[0]?.client?.companyName);
            }

            setCorporateRateId(seasonalStandardRateByClientIdSuccess?.data?.corporateRate[0]?._id);

        }
    }, [clientId, createCorporateSeasonalRateError, createCorporateSeasonalRateSuccess, getPropertySuccess, getSeasonalStandardRateByClientId, inventoryId, propertyId, seasonalRateId, seasonalStandardRateByClientIdSuccess, setFormOpen, updateCorporateSeasonalRateError, updateCorporateSeasonalRateSuccess]);

    const changeFormEditable = useCallback(() => {
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);

    const onFinish = (value) => {

        if (myoCorporateDataSeasonal?._id) {
            const payload = {
                id: rateId,
                seasonalRateId: myoCorporateDataSeasonal._id,
                corporateRateId: corporateRateId,
                seasonalRate: {
                    fromDate: dateUpdate ? moment(value.fromToDate[0]._d).format('YYYY-MM-DD') : myoCorporateDataSeasonal.fromDate,
                    toDate: dateUpdate ? moment(value.fromToDate[1]._d).format('YYYY-MM-DD') : myoCorporateDataSeasonal.toDate,
                    oneTo7: value.oneTo7 ? value.oneTo7 : 0,
                    eightTo14: value.eightTo14 ? value.eightTo14 : 0,
                    fifteenTo28: value.fifteenTo28 ? value.fifteenTo28 : 0,
                    twentyNineTo90: value.twentyNineTo90 ? value.twentyNineTo90 : 0,
                    ninetyOnePlus: value.ninetyOnePlus,
                }
            }
            setLoading(true);
            updateCorporateSeasonalRate(payload);
        } else {
            const payload = {
                id: rateId,
                corporateClientId: clientId,
                seasonalRate: {
                    fromDate: dateUpdate ? moment(value.fromToDate[0]._d).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
                    toDate: dateUpdate ? moment(value.fromToDate[1]._d).format('YYYY-MM-DD') : moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)).format('YYYY-MM-DD'),
                    oneTo7: value.oneTo7 ? value.oneTo7 : 0,
                    eightTo14: value.eightTo14 ? value.eightTo14 : 0,
                    fifteenTo28: value.fifteenTo28 ? value.fifteenTo28 : 0,
                    twentyNineTo90: value.twentyNineTo90 ? value.twentyNineTo90 : 0,
                    ninetyOnePlus: value.ninetyOnePlus,
                }
            }
            setLoading(true);
            createCorporateSeasonalRate(payload);
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onFromDateChange = useCallback(() => {
        setDateUpdate(true);
    }, []);

    return (
        <>
            <Row className='mt-5'>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}> {clientName ? clientName : ""} Negotiated Rate {seasonName ? " - " + seasonName : ""} <span style={{ fontSize: '20px', fontWeight: 'normal' }}>{currency}</span></div>
            </Row>
            <div className='mt-5'>
                <Form onSubmit={handleSubmit(onFinish)}>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '5%' }}>Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}>
                                <span style={headBorderStyle}></span><span >1-7 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 8-14 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 15-28 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 29-89 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 90+ nights</span>
                            </div>
                        </Col>
                        <Col md={4} >
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3%', width: '200px', marginTop: '5px' }}><span style={headBorderStyle}></span>
                                <span > Dates</span>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '3%', marginBottom: '10px' }}>Standard Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{myoCorporateDataSeasonal?.oneTo7}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={myoCorporateDataSeasonal?.oneTo7} min={0} className={`${errors.oneTo7 ? 'is-invalid' : ''}`} {...register("oneTo7", { required: selectedInventory?.minimumStayed > 7 ? false : true })} disabled={selectedInventory?.minimumStayed > 7 ? true : false} />}

                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{myoCorporateDataSeasonal?.eightTo14}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={myoCorporateDataSeasonal?.eightTo14} min={0} className={`${errors.eightTo14 ? 'is-invalid' : ''}`} {...register("eightTo14", { required: selectedInventory?.minimumStayed > 14 ? false : true })} disabled={selectedInventory?.minimumStayed > 14 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{myoCorporateDataSeasonal?.fifteenTo28}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={myoCorporateDataSeasonal?.fifteenTo28} min={0} className={`${errors.fifteenTo28 ? 'is-invalid' : ''}`} {...register("fifteenTo28", { required: selectedInventory?.minimumStayed > 28 ? false : true })} disabled={selectedInventory?.minimumStayed > 28 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{myoCorporateDataSeasonal?.twentyNineTo90}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={myoCorporateDataSeasonal?.twentyNineTo90} min={0} className={`${errors.twentyNineTo90 ? 'is-invalid' : ''}`} {...register("twentyNineTo90", { required: selectedInventory?.minimumStayed > 90 ? false : true })} disabled={selectedInventory?.minimumStayed > 90 ? true : false} />}
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={editEnable ? bodyTextStyleOne : bodyTextStyle}>
                                {!editEnable && <p style={bodyTextInsideStyle}>{myoCorporateDataSeasonal?.ninetyOnePlus}</p>}
                                {editEnable && <Form.Control type="number" defaultValue={myoCorporateDataSeasonal?.ninetyOnePlus} min={0} className={`${errors.ninetyOnePlus ? 'is-invalid' : ''}`} {...register("ninetyOnePlus", { required: true })} />}
                            </div>
                        </Col>
                        <Col md={4} >
                            {
                                !editEnable && <div style={{ fontSize: '18px', marginLeft: '5%' }}>
                                    <p style={{ marginTop: '4%' }}><CalendarOutlined style={{ marginRight: '3%' }} /> {myoCorporateDataSeasonal?.fromDate.substring(0, 10)} <ArrowRightOutlined style={{ marginRight: '3%', marginLeft: '3%' }} /> {myoCorporateDataSeasonal?.toDate.substring(0, 10)}</p>
                                </div>
                            }
                            {
                                editEnable && (
                                    <Controller
                                        name="fromToDate"
                                        defaultValue={[moment(new Date(myoCorporateDataSeasonal?.fromDate), 'YYYY-MM-DD'), moment(new Date(new Date(myoCorporateDataSeasonal?.toDate).getFullYear(), new Date(myoCorporateDataSeasonal?.toDate).getMonth(), new Date(myoCorporateDataSeasonal?.toDate).getDate()), 'YYYY-MM-DD')]}
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field }) => (
                                            <>
                                                <div className='custom-form-control'>
                                                    <RangePicker
                                                        autoFocus={false}
                                                        bordered={false}
                                                        className="font-light"
                                                        suffixIcon={<div></div>}
                                                        style={{
                                                            border: 'none',
                                                            fontWeight: 300,
                                                            fontSize: '20px',
                                                        }}
                                                        placeholder={["Check-in", "Check-out"]}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                            onFromDateChange();
                                                        }}
                                                        defaultValue={myoCorporateDataSeasonal?.fromDate ? [moment(new Date(myoCorporateDataSeasonal?.fromDate), 'YYYY-MM-DD'), moment(new Date(new Date(myoCorporateDataSeasonal?.toDate).getFullYear(), new Date(myoCorporateDataSeasonal?.toDate).getMonth(), new Date(myoCorporateDataSeasonal?.toDate).getDate()), 'YYYY-MM-DD')] : [moment(new Date(), 'YYYY-MM-DD'), moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), 'YYYY-MM-DD')]}
                                                    />
                                                    {editEnable &&
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="fromToDate"
                                                            render={({ message }) => <div className="invalid-feedback-custom">Please select date!</div>} />}
                                                </div>
                                            </>
                                        )}
                                    />
                                )
                            }

                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col md={12}>
                            {!loading && editEnable && !seasonalRateId && <Button type="submit" style={formButton}><b>Save</b></Button>}
                            {!loading && editEnable && seasonalRateId && <Button type="submit" style={formButton}><b>Update</b></Button>}
                            {!loading && !editEnable && <Button style={formButton} onClick={changeFormEditable}><b>Edit</b></Button>}
                            {loading && <Spinner animation="border" variant="warning" />}
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default NegotiatedRateFormSeasonalForm;