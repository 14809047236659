import React, { useCallback, useState } from 'react'
import QrReader from 'react-qr-reader'

const Test: React.FC = (): JSX.Element => {
  const [res, setRes] = useState(null)
  const handleScan = useCallback((data) => {
    setRes(data)
  }, [])

  const handleError = useCallback((err) => {
  }, [])
  return (
    <div className="min-h-screen flex justify-center items-center bg-green-200">
      <QrReader
        className="w-64"
          delay={300}
          onError={handleError}
          onScan={handleScan}
        style={{ width: '50%' }}
        />
        <p>{res}</p>
    </div>
  )
}


export default Test;