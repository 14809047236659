import { Menu } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Col, Row, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { navContentStyle, tabDivStyle } from '../../../../../common/components-style';
import ProgrammePropertyListView from './ProgrammePropertyList';
import ProgrammeAboutDetails from './ProgrammeAbout';



// import ClientAboutDetails from './views/ClientAboutDetails';
// import ClientPropertyListView from './views/ClientPropertyListView';

// const { Text } = Typography;

const OperatorView: React.FC<any> = ({ propertyId, rfpId }): JSX.Element => {

    ////////////////// url manage ///////////
    // let { propertyId, rfpId } = useParams<any>();
    const history = useHistory();
    ///////////////////////////////////////// local state manage /////////////////////////////////////////
    const [menuKey, setMenuKey] = useState('about');
    const [defaultActiveKey] = useState('operator');
    const [property, setProperty] = useState<any>();
    const [brand, setBrand] = useState<any>();

    /////////////////////////////////// global action manage ////////////////////////////////////////////
    const { propertyDetailed, getBrandDetails } = useStoreActions<any>(
        (actions) => ({
            propertyDetailed:
                actions.property.propertyDetailed,
            getBrandDetails: actions.brand.getBrandDetails,
        })
    );

    /////////////////////////////////// global state manage ////////////////////////////////////////////
    const { getPropertyDetailedSuccess, getBrandDetailsSuccess } = useStoreState<any>(
        (state) => ({
            getPropertyDetailedSuccess: state.property.getPropertyDetailedSuccess,
            getBrandDetailsSuccess: state.brand.getBrandDetailsSuccess,
        })
    );

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertyDetailedSuccess) {
            setProperty(getPropertyDetailedSuccess.data);
            const payload = {
                brand_id: getPropertyDetailedSuccess.data?.brandId
            }
            getBrandDetails(payload);
        }

    }, [getBrandDetails, getPropertyDetailedSuccess]);

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getBrandDetailsSuccess) {
            setBrand(getBrandDetailsSuccess.data);
        }

    }, [getBrandDetailsSuccess]);

    ///////////////////////// menu change ///////////////////////////////////
    const menuChange = useCallback((value) => {
        setMenuKey(value.key);
    }, []);


    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (propertyId) {
            const payload = {
                propertyId: propertyId
            }
            propertyDetailed(payload);
        }
    }, [propertyDetailed, propertyId]);

    return (<>

        <div style={tabDivStyle}>
            <Tab.Container defaultActiveKey={defaultActiveKey} >
                <Row >
                    <Col sm={10}>
                        <Tab.Content >
                            <Tab.Pane eventKey="operator" >
                                <div >
                                    <Row className='mt-4'>
                                        <Col md={12}>
                                            <Breadcrumb >
                                                <Breadcrumb.Item style={{ fontSize: '24px' }} onClick={() => {
                                                    history.push({
                                                        pathname: `/admin/corporate-programme-management`,
                                                        state: {
                                                            cityName: property?.city,
                                                            rfpId: rfpId,
                                                            filterProgramme: true
                                                        }
                                                    })
                                                }}>{property?.city}</Breadcrumb.Item>
                                                <Breadcrumb.Item style={{ fontSize: '24px' }} onClick={() => {
                                                    history.push({
                                                        pathname: `/admin/corporate-programme-management/property/${propertyId}/${rfpId}`
                                                    })
                                                }}>{`${property?.propertyName}`}</Breadcrumb.Item>
                                                <Breadcrumb.Item style={{ fontSize: '24px' }} active>{`${property?.brand?.name}`}</Breadcrumb.Item>
                                            </Breadcrumb>
                                        </Col>
                                    </Row>
                                    <Row className=''>
                                        <Col md={12}>
                                            <div style={{ textAlign: 'right' }}>
                                                <Menu onClick={menuChange} mode="horizontal" className="w-full new_tab" selectedKeys={[menuKey]}>
                                                    <Menu.Item key="about" >
                                                        <span>About</span>
                                                    </Menu.Item>
                                                    <Menu.Item key="properties" >
                                                        <span>Properties</span>
                                                    </Menu.Item>
                                                </Menu>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='new_tab_ctnt' style={{ marginLeft: '7px' }}>
                                        <div className='new_tab_ctnt_inr'>
                                            {(menuKey === 'about') && <ProgrammeAboutDetails brand={brand} />}
                                            {(menuKey === 'properties') && <ProgrammePropertyListView brand={brand} />}
                                        </div>
                                    </div>

                                </div>
                            </Tab.Pane>
                            {<Tab.Pane eventKey="RFPSurvey">
                                <div style={navContentStyle}>

                                </div>
                            </Tab.Pane>}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    </>)

}

export default OperatorView;