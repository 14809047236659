import React, { useCallback, useState } from "react";
import { Modal} from 'react-bootstrap';
import { FormInputs } from "../../../../../common/interfaces";
import SectionContentCreateForm from "./forms/SectionContentCreateForm";
import SectionCreateForm from "./forms/SectionCreateForm";

////// component state ///////
type InputState = {
    content? : FormInputs,
    show: boolean,
    handleClose: () => void
};

const SectionCreate: React.FunctionComponent<InputState> = ({ content, show, handleClose }) => {

    /////////////// local state management /////////////////
    const [formHideAndShow, setFormHideAndShow] = useState<Boolean>(true);

    //////////// hide form ////////
    const hideForm = useCallback(()=>{
        setFormHideAndShow(false);
    },[]);

    //////////// show form ////////
    const showForm = useCallback(()=>{
        setFormHideAndShow(true);
    },[]);

    return (<>
        <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{content?._id ? 'Update Section Content' : 'Create Section Content'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    formHideAndShow && <SectionContentCreateForm  handleClose={handleClose} hideForm={hideForm} content={content}/>
                }

                {
                    !formHideAndShow && <SectionCreateForm showForm={showForm}/>
                }

            </Modal.Body>
        </Modal>
    </>);
}

export default SectionCreate;