import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from 'react-toastify';

////// component state ///////
type InputState = {
    showForm: () => void
};

/////// form inputs ////////

type FormInputs = {
    name: string,
    description: string
};

const SectionCreateForm: React.FunctionComponent<InputState> = ({ showForm }) => {

    ////////////// form manage ///////////////
    const { register, handleSubmit, formState: { errors } } = useForm<FormInputs>();

    /////////// local state ///////////////
    const [loading, setLoading] = useState<boolean>(false);

    ///////////////// global  action ////////////////////////
    const { sectionCreate } = useStoreActions<any>((actions) => ({
        sectionCreate: actions.DashboardModel.sectionCreate,
    }));

    /////////// global status ///////////////////
    const {
        getSectionCreateSuccess,
        getSectionCreateError
    } = useStoreState<any>((state) => ({
        getSectionCreateSuccess: state.DashboardModel.getSectionCreateSuccess,
        getSectionCreateError: state.DashboardModel.getSectionCreateError
    }));

    useEffect(() => {
        if (getSectionCreateSuccess) {
            setLoading(false);
            showForm();
        }

        if (getSectionCreateError) {
            setLoading(false);
            toast.error(getSectionCreateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [getSectionCreateError, getSectionCreateSuccess, showForm])

    const onSubmit = (values) => {
        setLoading(true);
        sectionCreate(values);
    }
    return (
        <>
            <Container fluid className='p-0'>
                <div className="jumbotron">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className='formSpace'>
                            <Col md={2} style={{ textAlign: 'right' }}>
                                <Form.Label>{<span className='requiredMark'>*</span>} Section Name</Form.Label>
                            </Col>
                            <Col md={5} style={{ textAlign: 'left' }}>
                                <Form.Control {...register("name", { required: true })} className={`${errors.name ? 'is-invalid' : ''}`} />
                                <ErrorMessage
                                    errors={errors}
                                    name="name"
                                    render={({ message }) => <div className="invalid-feedback-custom">Please input name.!</div>}
                                />

                            </Col>
                            <Col md={5} style={{ textAlign: 'right', marginTop: '-30px' }}>
                                <Button id='back-button' variant="primary" onClick={showForm} style={{ width: '100px' }}>
                                    Back
                                </Button>
                            </Col>
                        </Row>
                        <Row className='formSpace'>
                            <Col md={2} style={{ textAlign: 'right' }}>
                                <Form.Label>Description</Form.Label>
                            </Col>
                            <Col md={10} style={{ textAlign: 'left' }}>
                                {<Form.Control as={`textarea`} {...register("description", { required: false })} className={`${errors.description ? 'is-invalid' : ''}`} />}
                                <ErrorMessage
                                    errors={errors}
                                    name="description"
                                    render={({ message }) => <div className="invalid-feedback-custom">Please input description.!</div>}
                                />
                            </Col>
                        </Row>
                        <Row className='formSpace'>
                            <Col md={12} style={{ textAlign: 'right' }}>
                                {
                                    !loading && <Button variant="primary" type="submit" style={{ width: '100px' }}>
                                        Create
                                    </Button>
                                }

                                {loading && <Spinner animation="border" variant="warning" />}
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>

        </>
    )
};

export default SectionCreateForm;