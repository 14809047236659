import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select'
import { Button, Col, Row } from 'react-bootstrap';
import icon from "../../../../../../images/icons/maps-and-flags.png";
import bed from '../../../../../../images/icons/svg/outside/bed.svg';
import DataTable, { createTheme } from 'react-data-table-component';
import { mysaTableTheme, tableStyles } from '../../../../../../common/components-style';
import { useHistory, useParams } from 'react-router-dom';
import { forEach } from 'lodash';

createTheme('mysaTable', mysaTableTheme, 'light');

const tableButtonStyle = {
    background: '#FBC91D',
    border: '1px solid #FBC91D',
    borderRadius: 15,
    height: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    color: '#212121',
}

const ClientPropertyListView: React.FC<any> = ({ brand }): JSX.Element => {

    ////////////////// url manage ///////////
    const history = useHistory();
    let { rfpId } = useParams<any>();

    const [cities, setCities] = useState<any>([{ value: 'all', label: <div className='flex'><b>All Properties</b></div> }]);
    const [properties, setProperties] = useState<any>([]);

    useEffect(() => {
        if (brand?.properties) {
            setProperties(brand?.properties);
            const cityList: any = [{ value: 'all', label: <div className='flex'><b>All Properties</b></div> }];
            const cityListTemp: any = [];

            ///////// filter unique city
            brand?.properties.forEach(property => {
                if (!cityListTemp.includes(property.city)) {
                    cityListTemp.push(property.city);
                }

            });

            cityListTemp.forEach(cityName => {
                const city = {
                    value: cityName,
                    label: (
                        <div className="flex">
                            <img src={icon} className="mr-3" alt="" />{" "}
                            <b>
                                {cityName}
                            </b>
                        </div>
                    ),
                }
                if (!cityList.includes(city)) {
                    cityList.push(city);
                }

            });

            setCities(cityList);
        }

    }, [brand])

    const selectCity = useCallback((value) => {
        if(value.value === 'all'){
            setProperties(brand?.properties);
        }else{
            const temProperties = properties?.filter((property) => {
                return property.city?.trim() === value.value?.trim();
            });
            setProperties(temProperties);
        }
    },[brand, properties])


    const bedColumn = () => {
        return (<>
            1 <img src={bed} alt="bed" />
        </>)
    };

    const bedTwoColumn = () => {
        return (<>
            2 <img src={bed} alt="bed" />
        </>)
    };

    const bedThreePlusColumn = () => {
        return (<>
            3+ <img src={bed} alt="bed" />
        </>)
    };

    const studio = (inventories) => {
        let counting = 0;
        inventories?.forEach(value => {
            if ('Studio/Open plan apartment' === value?.room_type?.name.trim()) {
                counting += 1 * value.noOfApartments;
            }
        });

        return counting;
    };

    const bedRoomOne = (inventories) => {
        let counting = 0;
        inventories?.forEach(value => {
            if ('One bedroom' === value?.room_type?.name.trim()) {
                counting += 1 * value.noOfApartments;
            }
        });

        return counting;
    };

    const bedRoomTwo = (inventories) => {
        let counting = 0;
        inventories?.forEach(value => {
            if ('Two bedroom' === value?.room_type?.name.trim()) {
                counting += 1 * value.noOfApartments;
            }
        });

        return counting;
    };

    const bedRoomThreePlus = (inventories) => {
        let counting = 0;
        inventories?.forEach(value => {
            if ('Three bedroom' === value?.room_type?.name.trim()) {
                counting += 1 * value.noOfApartments;
            }
            if ('More than 3 bedrooms' === value?.room_type?.name.trim()) {
                counting += 1 * value.noOfApartments;
            }
        });

        return counting;
    };

    const managingProcess = (property_type) => {
        let type = '';
        if (property_type?.name.trim() === 'Apartments - building managed by a third party') {
            type = 'No';

        } else {
            type = 'Yes';
        }

        return type;
    }

    ////////////////////////////// Table column ////////////////////////////////////////////////////
    const columns = [
        {
            name: 'Property',
            selector: row => row.propertyName,
        },
        {
            name: 'City',
            selector: row => row.city,
        },
        {
            name: 'Apts',
            button: true,
            selector: row => {

                /**
                 * add number of apartment
                 */
                let numberOfInventories = 0;
                row.inventories?.forEach((inventory) => {
                    numberOfInventories += inventory?.noOfApartments;
                });

                return numberOfInventories
            },
        },
        {
            name: 'Studios',
            button: true,
            selector: row => studio(row.inventories),
        },
        {
            name: bedColumn(),
            button: true,
            cell: (row) => {
                return (
                    <div>
                        <div >{bedRoomOne(row.inventories)}</div>
                    </div>
                )
            }
        },
        {
            name: bedTwoColumn(),
            button: true,
            cell: (row) => {
                return (
                    <div>
                        <div >{bedRoomTwo(row.inventories)}</div>
                    </div>
                )
            }
        },
        {
            name: bedThreePlusColumn(),
            button: true,
            cell: (row) => {
                return (
                    <div>
                        <div >{bedRoomThreePlus(row.inventories)}</div>
                    </div>
                )
            }
        },
        {
            name: 'Check-in',
            selector: row => row.check_in?.check_in_process?.name,
        },
        {
            name: 'Fully managed?',
            selector: row => managingProcess(row.property_type),
        },
        {
            name: 'Review',
            cell: (row) => {
                return (
                    <>
                        <Button style={tableButtonStyle} onClick={() => {
                            history.push({
                                pathname: `/admin/client-proposal-management/property/${row._id}/${rfpId}`
                            });
                        }}><b>View Property</b></Button>
                    </>
                )
            }
        },
    ];

    const selectRender = useMemo(() => {
        return (
            <>
                <Select defaultValue={cities[0]} className="new_drpdwn " options={cities} onChange={selectCity} />
            </>
        )
    }, [cities, selectCity])

    return (
        <>
            <Row>
                <Col md={3}>
                    {selectRender}
                </Col>
                <Col md={9}>
                </Col>
            </Row>
            <Row className='mt-5'>
                <DataTable
                    columns={columns}
                    data={properties}
                    pagination
                    striped={true}
                    customStyles={tableStyles}
                    theme='mysaTable'
                    fixedHeader={true}
                    noDataComponent={'Updating records'}
                />
            </Row>
        </>
    );
}

export default ClientPropertyListView;