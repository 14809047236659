import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row, Image, Form, Button, Spinner } from 'react-bootstrap';
import shareIcon from '../../../images/icons/svg/outside/share-icon.svg';
import { $mysaYellow, bookingScreen, routingButton, selectStylesLittleRect } from '../../../common/components-style';
import Select from 'react-select';
import TravellerProfileForm from './TravelerProfileForm';
import TravelerHeaderPage from './TravelerHeaderPage';
import { useHistory } from 'react-router-dom';
import TravellerProfile from './TravellerProfile';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { capitalizeRole } from '../../../common/functions';
import { LIVE_BOOKING_STATUS, UserGroups } from '../../../common/constants';
import { getStorage } from '../../../common/storage';
import _ from 'lodash';
import { Controller, useForm } from "react-hook-form";
import { TravelerInput } from '../../../common/interfaces';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';

const button = {
    ...routingButton,
    width: '100%',
    height: '81px',
    borderRadius: 44,
    background: $mysaYellow,
    fontSize: '20px'
}

const TravellerProfileSearch: React.FC<any> = ({ location }): JSX.Element => {

    /**
     * global action
     */
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getUserMapping, secureAvailabilityAndRate, addTravelerToSecureRateKey } = useStoreActions<any>((actions) => ({
        getUserMapping: actions.user.getUserMapping,
        secureAvailabilityAndRate: actions.booking.secureAvailabilityAndRate,
        addTravelerToSecureRateKey: actions.booking.addTravelerToSecureRateKey,
    }));

    /**
     * global state
     */
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getUserMappingSuccess, getAddTravelerToSecureRateKeySuccess, getAddTravelerToSecureRateKeyError, getSecureAvailabilityAndRateSuccess } = useStoreState<any>((state) => ({
        getUserMappingSuccess: state.user.getUserMappingSuccess,
        getAddTravelerToSecureRateKeySuccess: state.booking.getAddTravelerToSecureRateKeySuccess,
        getAddTravelerToSecureRateKeyError: state.booking.getAddTravelerToSecureRateKeyError,
        getSecureAvailabilityAndRateSuccess: state.booking.getSecureAvailabilityAndRateSuccess
    }));

    /**
     * form 
     */

    const { handleSubmit, formState: { errors }, control } = useForm<TravelerInput>();

    /**
     * local state
     */
    const [showProfile, setShowProfile] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedTravellerInfo, setSelectedTravellerInfo] = useState<any>('');
    const [secureKey, setSecureKey] = useState<any>('');
    const { push } = useHistory();

    /**
     * storage
     */

    const clientId = getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('selectedFilterClient')?.value ? getStorage('selectedFilterClient')?.value : getStorage('programmeData')?.clientId._id;
    const programmeId = getStorage('makeBookingProgram')?.value ? getStorage('makeBookingProgram')?.value : getStorage('selectedProgramsOption')?.value ? getStorage('programmeId') : getStorage('programmeData')?._id;

    /**
     * @description show and hide
     */
    const showTravellerFormAndHide = (value: boolean) => {
        setShowProfile(value);
        const clientId = getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('selectedFilterClient')?.value ? getStorage('selectedFilterClient')?.value : getStorage('programmeData')?.clientId._id;
        if (clientId) {
            getUserMapping({ clientId: clientId });
        }

    };

    /**
     * react lifecycle side effect
     */

    useEffect(() => {

        const clientId = getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('selectedFilterClient')?.value ? getStorage('selectedFilterClient')?.value : getStorage('programmeData')?.clientId._id;
        const programmeId = getStorage('makeBookingProgram')?.value ? getStorage('makeBookingProgram')?.value : getStorage('selectedProgramsOption')?.value ? getStorage('programmeId') : getStorage('programmeData')?._id;
        const bookingUserId = getStorage('myo_logged_user').userData._id;

        const payload = {
            clientId: clientId,
            programmeId: programmeId,
            bookerUserId: bookingUserId,
            propertyId: location?.state?.query.propertyId,
            inventoryId: location?.state?.query.inventoryId,
            hashGet: location?.state?.query.hashGet,
            cacheKey: location?.state?.query.cacheKey,
            ...location?.state?.requestId && { requestId: location?.state?.requestId },
            bookingStatus: LIVE_BOOKING_STATUS.UP_COMING
        }

        secureAvailabilityAndRate(payload);

    }, [location?.state?.query, location?.state?.requestId, secureAvailabilityAndRate]);

    useEffect(() => {
        const clientId = getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('selectedFilterClient')?.value;
        if (clientId) {
            getUserMapping({ clientId: clientId });
        }

    }, [getUserMapping]);

    useEffect(() => {
        if (getAddTravelerToSecureRateKeySuccess?.data) {
            push('/admin/booking-payment', { query: location?.state?.query, selectTraveller: selectedTravellerInfo, secureRateKeyId: secureKey });
            setLoading(false);
        }

        if (getSecureAvailabilityAndRateSuccess?.data) {
            setSecureKey(getSecureAvailabilityAndRateSuccess?.data._id);
        }

        if (getAddTravelerToSecureRateKeyError) {
            setLoading(false);
            toast.error(getAddTravelerToSecureRateKeyError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [getAddTravelerToSecureRateKeyError, getAddTravelerToSecureRateKeySuccess?.data, getSecureAvailabilityAndRateSuccess?.data, location?.state?.query, push, secureKey, selectedTravellerInfo]);

    /**
     * user list
     */
    const data = useMemo(() => {
        if (getUserMappingSuccess) {
            if (getUserMappingSuccess.data) {
                const { result, poolUsers } = getUserMappingSuccess.data;

                const mappedData = _.map(result, (val) => {
                    const corporateProgramme = _.filter(val.clients, (a) => a.clientId === clientId).shift()?.corporateProgramme;
                    const userStatus = poolUsers[val?.user?.email]?.UserStatus
                    return {
                        key: val?.user?._id,
                        userId: val?.user?._id,
                        firstName: val?.user?.firstName,
                        lastName: val?.user?.lastName,
                        email: val?.user?.email,
                        phone: val?.user?.phone,
                        mobile: val?.user?.mobile,
                        position: val?.user?.position,
                        userProfile: val?.user?.userProfile,
                        userStatus: userStatus,
                        data: val,
                        corporateProgramme

                    }
                });

                let filterData: any = [];
                filterData = _.map(mappedData, (val) => {
                    const arrId = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?._id || ''
                    const access = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.access || ''
                    const role = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.role || ''
                    const isFirstUser = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.isFirstUser
                    const corporateProgrammeId = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.programmeId

                    return {
                        key: val.key,
                        userId: val.userId,
                        firstName: val.firstName,
                        lastName: val.lastName,
                        email: val.email,
                        phone: val.phone,
                        mobile: val.mobile,
                        position: val.position,
                        userProfile: val.userProfile,
                        userStatus: val.userStatus,
                        data: val.data,
                        role,
                        arrId,
                        access,
                        isFirstUser,
                        corporateProgramme: val.corporateProgramme,
                        corporateProgrammeId,
                        userType: capitalizeRole(role),

                    }
                })

                filterData = _.filter(filterData, (a) => a.corporateProgrammeId === programmeId && a.role === UserGroups.traveler);
                filterData.sort((a, b) => {
                    if (a.email < b.email) {
                        return -1;
                    } else if (a.email > b.email) {
                        return 1;
                    }
                    return 0;
                });

                return filterData;
            }
        }
        return []
    }, [clientId, getUserMappingSuccess, programmeId]);

    /**
     * generate options
     */
    const options: any = [];

    if (data) {
        for (const option of data) {
            // const lastName = (option.lastName !== undefined) ? option.lastName : '';
            // const label = { value: `${option.userId}`, label: `${option.firstName} ${lastName}` };
            const label = { value: `${option.userId}`, label: `${option.email}` };
            options.push(label);
        };
    }

    /**
     * submit data
     */
    const onSubmit = (values) => {
        setLoading(true);
        const payload = {
            secureRateKeyId: secureKey,
            travelerId: values.name.value ? values.name.value : getStorage('myo_logged_user')?.userData?._id
        }
        addTravelerToSecureRateKey(payload);
    }

    return (
        <>
            <Row>
                <TravelerHeaderPage bookingParams={location} />
            </Row>

            <Row style={{
                marginTop: '80px'
            }} >
                <hr style={{
                    width: '90vw'
                }} />
                <Col md={4}>
                    <TravellerProfile params={location} />
                </Col>
                <Col md={8}>
                    {
                        !showProfile && <Card style={bookingScreen}>
                            <Card.Body>
                                <Container style={{ marginLeft: '30px', marginRight: '30px' }}>
                                    <Card.Title style={{ fontSize: '30px', fontWeight: 'bold', marginTop: '30px' }}>
                                        <Row>
                                            <Col md={1}>
                                                <Image src={shareIcon} />
                                            </Col>
                                            <Col md={11} style={{ marginLeft: '-45px' }}>
                                                Traveller Details
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    <div style={{ marginTop: '4%' }}>
                                        <Card.Subtitle className="mb-2 text-muted"><span className='requiredMark'>*</span><span style={{ color: '#000000' }}>Search Travellers</span></Card.Subtitle>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col md={8}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Col >
                                                            <Controller
                                                                name="name"
                                                                control={control}
                                                                rules={{
                                                                    required: true,
                                                                }}
                                                                render={({ field }) =>
                                                                    <Select
                                                                        isClearable
                                                                        placeholder={<div>Select a Traveller</div>}
                                                                        options={options}
                                                                        styles={selectStylesLittleRect}
                                                                        className={`${errors.name ? 'is-invalid' : ''} font-light`}
                                                                        onChange={(data) => {                                               
                                                                            setSelectedTravellerInfo(data);
                                                                            field.onChange(data);
                                                                        }}
                                                                    />
                                                                }
                                                            />

                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="name"
                                                                render={({ message }) => <div style={{ marginLeft: '5px' }} className="invalid-feedback-custom">Please select a Traveller!</div>} />
                                                        </Col>

                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Card.Subtitle className="mb-2" style={{ marginTop: '5%' }}><span style={{ color: '#000000', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowProfile(true)}>+ Add a new traveller</span></Card.Subtitle>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10}>
                                                    {
                                                        loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                                    }

                                                    {
                                                        !loading && <Button type='submit' id='routing-button' style={button} ><b>Create</b></Button>
                                                    }

                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Container>
                            </Card.Body>
                        </Card>
                    }
                    {showProfile && < TravellerProfileForm showTravellerFormAndHide={showTravellerFormAndHide} />}
                </Col>
            </Row>
        </>
    );

}

export default TravellerProfileSearch;