import { Select, TimePicker } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { UserAccess, Status } from '../../../../common/constants';
import { canBrandPropertyModuleWrite } from '../../../../common/functions';
import { Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import { PropertyCheckInFormInput } from '../../../../common/interfaces';
import { Controller, useForm } from 'react-hook-form';
import { formButton } from '../../../../common/components-style';
import { toast } from 'react-toastify';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
// import { HelpModal } from '../../../common/HelpModal';

const { Option } = Select;

const format = 'HH:mm';

const PropertyCheckInProcess: React.FC<any> = ({ allInOnePropertyBreadCrumb, brandId, propertyId, setFormOpen, selectedBrand,tab }): JSX.Element => {
	///////////////////// initialize form ////////////////////////////////////// 
	const { register, handleSubmit, formState: { errors }, control } = useForm<PropertyCheckInFormInput>();
	// const [title, setTitle] = useState('');
	const [propertyData, setPropertyData] = useState<any>();
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const [checkInData, setCheckInData] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);
	const [alternativeCheckInProcess, setAlternativeCheckInProcess] = useState<Array<Object>>([]);
	const [receptionHoursCheckInProcess, setReceptionHoursCheckInProcess] = useState<boolean>(false);
	const [checkInId, setCheckInId] = useState<string>();
	const [checkInProcess, setCheckInProcess] = useState<Array<Object>>([]);
	const [checkIn, setCheckIn] = useState<any>('');
	const [checkOut, setCheckOut] = useState<any>('');
	const [alternativeCheckInProcessName, setAlternativeCheckInProcessName] = useState<String>('');
	const [receptionWorkingFrom, setReceptionWorkingFrom] = useState<String>('');
	const [receptionWorkingTo, setReceptionWorkingTo] = useState<String>('');

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { getProperty, getCheckInProcessDetail, getPropertyCheckInProcessDetailByPropertyId, createPropertyCheckInProcessDetail, updatePropertyCheckInProcessDetail, resetProperty } = useStoreActions<any>((actions) => ({
		getCheckInProcessDetail: actions.property.getCheckInProcessDetail,
		getPropertyCheckInProcessDetailByPropertyId: actions.property.getPropertyCheckInProcessDetailByPropertyId,
		createPropertyCheckInProcessDetail: actions.property.createPropertyCheckInProcessDetail,
		updatePropertyCheckInProcessDetail: actions.property.updatePropertyCheckInProcessDetail,
		resetProperty: actions.property.resetProperty,
		getProperty: actions.property.getProperty,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { getPropertySuccess, getCheckInProcessListSuccess, getPropertyCheckInProcessDetailByIdSuccess, updatePropertyCheckInProcessDetailSuccess, updatePropertyCheckInProcessDetailError,
		getPropertyCheckInProcessDetailByIdError, createPropertyCheckInProcessDetailSuccess, createPropertyCheckInProcessDetailError } = useStoreState<any>((state) => ({
			getCheckInProcessListSuccess: state.property.getCheckInProcessListSuccess,
			getPropertyCheckInProcessDetailByIdSuccess: state.property.getPropertyCheckInProcessDetailByIdSuccess,
			getPropertyCheckInProcessDetailByIdError: state.property.getPropertyCheckInProcessDetailByIdError,
			createPropertyCheckInProcessDetailSuccess: state.property.createPropertyCheckInProcessDetailSuccess,
			createPropertyCheckInProcessDetailError: state.property.createPropertyCheckInProcessDetailError,
			updatePropertyCheckInProcessDetailSuccess: state.property.updatePropertyCheckInProcessDetailSuccess,
			updatePropertyCheckInProcessDetailError: state.property.updatePropertyCheckInProcessDetailError,
			getPropertySuccess: state.property.getPropertySuccess

		}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (propertyId) {
			getPropertyCheckInProcessDetailByPropertyId(propertyId);
			getProperty(propertyId);
		}
		getCheckInProcessDetail();
	}, [getCheckInProcessDetail, getProperty, getPropertyCheckInProcessDetailByPropertyId, propertyId]);

	const alternativeCheckInProcessAnswer = useCallback(() => {
		if (checkInProcess) {
			checkInProcess.forEach((data: any) => {
				data.alternativeCheckInProcess.forEach(element => {
					if (element._id === checkInData?.alternativeCheckInProcess) {
						setAlternativeCheckInProcessName(element.name);
					}
				});
			});
		} else {
			return false;
		}

	}, [checkInData, checkInProcess])


	////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {

		if (getCheckInProcessListSuccess) {
			setCheckInProcess(getCheckInProcessListSuccess.data);
		}
		if (getPropertyCheckInProcessDetailByIdSuccess) {
			if (getPropertyCheckInProcessDetailByIdSuccess.data) {
				const checkIn: any = getPropertyCheckInProcessDetailByIdSuccess.data;
				setCheckInData(checkIn);
				setCheckIn(moment(checkInData?.checkInTime, format));
				setCheckOut(moment(checkInData?.checkOutTime, format));
				// form.setFieldsValue({
				// 	checkInProcess: checkIn.checkInProcess?._id,
				// 	alternativeCheckInProcess: checkIn.alternativeCheckInProcess || '',
				// 	additionalInformation: checkIn.additionalInformation || '',
				// 	checkInTime: moment(checkIn.checkInTime, format),
				// 	checkOutTime: moment(checkIn.checkOutTime, format),
				// 	receptionWorkingHoursFrom: checkIn.receptionWorkingHoursFrom ? moment(checkIn.receptionWorkingHoursFrom, format) : null,
				// 	receptionWorkingHoursTo: checkIn.receptionWorkingHoursTo ? moment(checkIn.receptionWorkingHoursTo, format) : null,
				// })
				setCheckInId(checkIn._id);
				setAlternativeCheckInProcess(checkIn.checkInProcess?.alternativeCheckInProcess || []);
				setReceptionHoursCheckInProcess((checkIn.checkInProcess.displayWorkingHours && checkIn.checkInProcess.displayWorkingHours === Status.ACTIVE) ? true : false);
			}

		}

		if (getPropertyCheckInProcessDetailByIdError) {
			toast.error(getPropertyCheckInProcessDetailByIdError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (getPropertySuccess) {
			setPropertyData(getPropertySuccess.data);
			// setTitle(getPropertySuccess.data?.propertyName);
		}

		alternativeCheckInProcessAnswer();
	}, [alternativeCheckInProcessAnswer, checkInData?.checkInTime, checkInData?.checkOutTime, createPropertyCheckInProcessDetailSuccess, getCheckInProcessDetail, getCheckInProcessListSuccess, getPropertyCheckInProcessDetailByIdError, getPropertyCheckInProcessDetailByIdSuccess, getPropertySuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (createPropertyCheckInProcessDetailSuccess) {
			setLoading(false);
			setEditEnable(false);
			toast.success("Property check-in process created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetProperty();
			getPropertyCheckInProcessDetailByPropertyId(propertyId);
		}

		if (createPropertyCheckInProcessDetailError) {
			setLoading(false);
			setEditEnable(false);
			toast.error(createPropertyCheckInProcessDetailError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetProperty();
			getPropertyCheckInProcessDetailByPropertyId(propertyId);
		}

		if (updatePropertyCheckInProcessDetailSuccess) {
			setLoading(false);
			setEditEnable(false);
			toast.success("Property check-in process updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetProperty();
			getPropertyCheckInProcessDetailByPropertyId(propertyId);
		}

		if (updatePropertyCheckInProcessDetailError) {
			setLoading(false);
			toast.error(updatePropertyCheckInProcessDetailError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetProperty();
		}

	}, [createPropertyCheckInProcessDetailError, createPropertyCheckInProcessDetailSuccess, getPropertyCheckInProcessDetailByPropertyId, propertyId, resetProperty, updatePropertyCheckInProcessDetailError, updatePropertyCheckInProcessDetailSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderCheckInProcess = useCallback(() => {
		if (checkInProcess) {
			return checkInProcess.map((val: any, key: number) => {
				return (
					<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
				)
			});
		}

	}, [checkInProcess]);


	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onTypeSelect = useCallback((selectedId) => {
		checkInProcess.forEach((data: any) => {
			if (data._id === selectedId) {
				setReceptionHoursCheckInProcess((data.displayWorkingHours && data.displayWorkingHours === Status.ACTIVE) ? true : false);
				setAlternativeCheckInProcess(data.alternativeCheckInProcess);
			}
		});
	}, [checkInProcess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderAlternativeCheckInProcess = useMemo(() => {
		return _.map(alternativeCheckInProcess, (val: any, key: number) => {
			return (
				<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
			)
		})
	}, [alternativeCheckInProcess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onCheckInTimeChange = useCallback((timeString) => {
		setCheckIn(timeString);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onCheckOutTimeChange = useCallback((timeString) => {
		setCheckOut(timeString);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onReceptionWorkingFromChange = useCallback((timeString) => {
		setReceptionWorkingFrom(timeString);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onReceptionWorkingToChange = useCallback((timeString) => {
		setReceptionWorkingTo(timeString);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onFinish = useCallback((values) => {
		setLoading(true);
		if (alternativeCheckInProcess.length === 0) {
			values.alternativeCheckInProcess = '';
		}
		if (checkInId) {
			values.id = checkInId;
			const data = {
				id: checkInId,
				checkInTime: moment(checkIn).format('HH:mm'),
				checkOutTime: moment(checkOut).format('HH:mm'),
				receptionWorkingHoursFrom: values.receptionWorkingHoursFrom ? moment(values.receptionWorkingHoursFrom._d).format('HH:mm') : receptionWorkingFrom,
				receptionWorkingHoursTo: values.receptionWorkingHoursTo ? moment(values.receptionWorkingHoursTo._d).format('HH:mm') : receptionWorkingTo,
				checkInProcess: values.checkInProcess,
				alternativeCheckInProcess: values.alternativeCheckInProcess,
				additionalInformation: values.additionalInformation,
			}
			updatePropertyCheckInProcessDetail(data);
		} else {
			const data = {
				propertyId: propertyId,
				checkInTime: values.checkInTime ? moment(values.checkInTime._d).format('HH:mm') : checkIn,
				checkOutTime: values.checkOutTime ? moment(values.checkOutTime._d).format('HH:mm') : checkOut,
				receptionWorkingHoursFrom: values.receptionWorkingHoursFrom ? moment(values.receptionWorkingHoursFrom._d).format('HH:mm') : receptionWorkingFrom,
				receptionWorkingHoursTo: values.receptionWorkingHoursTo ? moment(values.receptionWorkingHoursTo._d).format('HH:mm') : receptionWorkingTo,
				checkInProcess: values.checkInProcess,
				alternativeCheckInProcess: values.alternativeCheckInProcess,
				additionalInformation: values.additionalInformation,
			}
			createPropertyCheckInProcessDetail(data);
		}

	}, [alternativeCheckInProcess, checkIn, checkInId, checkOut, createPropertyCheckInProcessDetail, propertyId, receptionWorkingFrom, receptionWorkingTo, updatePropertyCheckInProcessDetail]);

	const changeFormEditable = useCallback(() => {
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);



	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<>
		 <div className="content-title fixed_title">
			{
				<BreadCrumbPage tab={tab} allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} isPageName="Property" selectedBrand={selectedBrand} propertyData={propertyData} setFormOpen={setFormOpen} ></BreadCrumbPage>
			}
			<div className='edit_btn_wpr'>
			<div className='d-flex align-items-center justify-content-end gx-2'>
			{/* <HelpModal /> */}
			{
				!loading && !editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) && <Button id='edit-button' className='mb-0' style={formButton} onClick={changeFormEditable} >Edit</Button>
			}
			</div>
			</div>
			</div>
			<div>
				<div className="jumbotron">
					<Form onSubmit={handleSubmit(onFinish)}>
						<Row>
							<Col md={1}></Col>
							<Col md={7}>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Check-in / out</Form.Label>
									</Col>
									<Col md={3} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{checkInData?.checkInTime}</p>}
										{editEnable &&
											<Controller
												name="checkInTime"
												defaultValue={String(checkInData?.checkInTime ? moment(checkInData?.checkInTime, format) : '')}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<>
														{
															checkInData?.checkInTime ?
																<TimePicker showNow={false} defaultValue={moment(checkInData?.checkInTime, format)} autoFocus={true} format={format} placeholder='check-in time' onChange={(value) => {
																	onCheckInTimeChange(value);
																	field.onChange(value);
																}} /> :
																<TimePicker showNow={false} autoFocus={true} format={format} placeholder='check-in time' onChange={(value) => {
																	onCheckInTimeChange(value);
																	field.onChange(value);
																}} />
														}
													</>
												}
											/>}
										{editEnable && errors.checkInTime && <div className="invalid-feedback-custom">Please select check-in time!</div>}
									</Col>
									<Col md={3} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{checkInData?.checkOutTime}</p>}
										{editEnable &&
											<Controller
												name="checkOutTime"
												defaultValue={String(checkInData?.checkOutTime ? moment(checkInData?.checkOutTime, format) : '')}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<>
														{
															checkInData?.checkOutTime ?
																<TimePicker showNow={false} defaultValue={moment(checkInData?.checkOutTime, format)} autoFocus={true} format={format} placeholder='check out time' onChange={(value) => {
																	onCheckOutTimeChange(value);
																	field.onChange(value);
																}} /> :
																<TimePicker showNow={false} autoFocus={true} format={format} placeholder='check out time' onChange={(value) => {
																	onCheckOutTimeChange(value);
																	field.onChange(value);
																}} />
														}

													</>
												}
											/>}
										{editEnable && errors.checkOutTime && <div className="invalid-feedback-custom">Please select  check out time!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Check-in process</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{checkInData?.checkInProcess.name}</p>}
										{editEnable &&
											<Controller
												name="checkInProcess"
												defaultValue={checkInData?.checkInProcess._id}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<Select defaultValue={checkInData?.checkInProcess._id} onSelect={(value) => {
														onTypeSelect(value);
														field.onChange(value);
													}} style={{ width: '100%' }}>
														{renderCheckInProcess()}
													</Select>
												}
											/>}
										{editEnable && errors.checkInProcess && <div className="invalid-feedback-custom">Please select a check-in process!</div>}
									</Col>
								</Row>
								{alternativeCheckInProcess.length === 0 ||
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Alternative check-in process</Form.Label>
										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{alternativeCheckInProcessName}</p>}
											{editEnable &&
												<Controller
													name="alternativeCheckInProcess"
													defaultValue={checkInData?.alternativeCheckInProcess}
													control={control}
													rules={{
														required: true,
													}}
													render={({ field }) =>
														<Select defaultValue={checkInData?.alternativeCheckInProcess} onChange={(value) => {
															field.onChange(value)
														}} style={{ width: '100%' }}>
															{renderAlternativeCheckInProcess}
														</Select>
													}
												/>}
											{editEnable && errors.alternativeCheckInProcess && <div className="invalid-feedback-custom">Please select a sub alternative check-in process!</div>}
										</Col>
									</Row>
								}
								{
									receptionHoursCheckInProcess &&
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Reception opening hours</Form.Label>
										</Col>
										<Col md={3} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{checkInData?.receptionWorkingHoursFrom}</p>}
											{editEnable &&
												<Controller
													name="receptionWorkingHoursFrom"
													defaultValue={String(checkInData?.receptionWorkingHoursFrom ? moment(checkInData?.receptionWorkingHoursFrom, format) : '')}
													control={control}
													render={({ field }) =>
														<>
															{checkInData?.receptionWorkingHoursFrom ?
																<TimePicker showNow={false} defaultValue={checkInData?.receptionWorkingHoursFrom ? moment(checkInData?.receptionWorkingHoursFrom, format) : moment(new Date(), format)} autoFocus={true} format={format} placeholder='reception working hours from' onChange={(value) => {
																	onReceptionWorkingFromChange(value);
																	field.onChange(value);
																}} /> :
																<TimePicker showNow={false} autoFocus={true} format={format} placeholder='reception working hours from' onChange={(value) => {
																	onReceptionWorkingFromChange(value);
																	field.onChange(value);
																}} />
															}
														</>
													}
												/>}
										</Col>
										<Col md={3} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{checkInData?.receptionWorkingHoursTo}</p>}
											{editEnable &&
												<Controller
													name="receptionWorkingHoursTo"
													defaultValue={String(checkInData?.receptionWorkingHoursTo ? moment(checkInData?.receptionWorkingHoursTo, format) : '')}
													control={control}
													render={({ field }) =>
														<>
															{
																checkInData?.receptionWorkingHoursTo ?
																	<TimePicker showNow={false} defaultValue={checkInData?.receptionWorkingHoursTo ? moment(checkInData?.receptionWorkingHoursTo, format) : moment(new Date(), format)} autoFocus={true} format={format} placeholder='reception working hours to' onChange={(value) => {
																		onReceptionWorkingToChange(value);
																		field.onChange(value);
																	}} /> :
																	<TimePicker showNow={false} autoFocus={true} format={format} placeholder='reception working hours to' onChange={(value) => {
																		onReceptionWorkingToChange(value);
																		field.onChange(value);
																	}} />
															}

														</>
													}
												/>}
										</Col>
									</Row>
								}
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>Additional Information</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{checkInData?.additionalInformation}</p>}
										{editEnable && <Form.Control as="textarea" rows={5} className={`${errors.additionalInformation ? 'is-invalid' : ''}`} defaultValue={checkInData?.additionalInformation} {...register("additionalInformation", { required: false })} />}
										{editEnable && <div className="invalid-feedback">Please input your additional information!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!loading && editEnable && propertyId && checkInData && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) &&
											<Button type="submit" id='approve-button' style={formButton}>Update</Button>
										}
										{!loading && editEnable && propertyId && !checkInData && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) &&
											<Button type="submit" id='approve-button' style={formButton}>Create</Button>
										}
										
										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={4}></Col>
						</Row>
					</Form>
				</div>
			</div>

		</>

	)
};

export default PropertyCheckInProcess;
