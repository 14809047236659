import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { $mysaBlack, $mysaYellow } from '../../../../common/components-style';
import { toast } from 'react-toastify';
import { getStorage } from '../../../../common/storage';
import { useForm } from 'react-hook-form';
import { GenericStandardRateForm } from '../../../../common/interfaces';
import RateSeasonalForm from './SubmittedRfpRateSeasonalForm';
import { BrandRfpPropertyEvaluationStatus, CommonAnswer, rfpPropertyStatusToInventory } from '../../../../common/constants';



/////////////////////////////// details block style////////////////////////////////////////
const headStyle = { fontSize: '17px', fontWeight: 'bold', padding: '12%', marginTop: '5%', width: '200px' } as React.CSSProperties;
const headBorderStyle = { borderLeft: '2px solid #C4C4C4', marginRight: '3%' } as React.CSSProperties;
const bodyTextStyleOne = { fontSize: '18px', padding: '5%', textAlign: 'center', marginTop: '6%', paddingTop: '10%', marginBottom: '11%' } as React.CSSProperties;

const formButton = {
    height: "50px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    marginRight: '20px',
    border: '1px solid #ffffff',
    background: $mysaYellow,
    color: $mysaBlack
} as React.CSSProperties;

const SubmittedRfpRateForm: React.FC<any> = ({ inventory, backToProperty, onReviewRate, onSubmit, property, inventoryMessage }): JSX.Element => {
    ///////////////// form initialize //////////
    const { register, handleSubmit, formState: { errors }, reset } = useForm<GenericStandardRateForm>();
    //////////// local state manage ///////////////////
    const [editEnable, setEditEnable] = useState<boolean>(true);
    const [editEnableAmendRate, setEditEnableAmendRate] = useState<boolean>(true);
    const [enableRateForm, setEnableRateForm] = useState<string>('');
    const [enableExtraSeasonalRateForm, setEnableExtraSeasonalRateForm] = useState<string>('');
    const [isAmendRate, setIsAmendRate] = useState<string>('');
    const [isAmendRateComment, setIsAmendRateComment] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [rateData, setRateData] = useState<any>([]);
    const [seasonalRateData, setSeasonalRateData] = useState<any>([]);
    const [isRateHere, setIsRateHere] = useState<boolean>(false);
    const [isSeasonalHere, setIsSeasonalHere] = useState<boolean>(false);
    const [isYesCheck, setIsYesCheck] = useState<boolean>(false);
    const [isNoCheck, setIsNoCheck] = useState<boolean>(false);
    const [currency, setCurrency] = useState<any>();
    const [inventoryAmendedRateComment, setInventoryAmendedRateComment] = useState<any>('');
    const [inventoryAmendedSecondRateComment, setInventoryAmendedSecondRateComment] = useState<any>('');
    const [showCommentQuestion, setShowCommentQuestion] = useState<boolean>(false);


    ////////////// local storage access ////////////////
    const myoSelectedBrand = getStorage("myo_selected_my_rfp_brand");
    const myoSelectedMyRfp = getStorage("myo_selected_my_rfp");
    const myoSelectedMyRfpProperty = getStorage("myo_selected_my_rfp_property");

    /////////////////////////// global action ////////////////////////
    const { getBrandRFPRateByInventoryId, createUpdatePropertyStandardRateForRFP, getProperty, reviewCreateUpdatePropertyAcceptDeclineOfRFP } = useStoreActions<any>((actions) => ({
        getBrandRFPRateByInventoryId: actions.rfpProcess.brandRFPRateByInventoryId,
        createUpdatePropertyStandardRateForRFP: actions.rfpProcess.createUpdatePropertyStandardRateForRFP,
        getProperty: actions.property.getProperty,
        reviewCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFP,
    }));

    /////////////////////////// global state ////////////////////////
    const { reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess, reviewCreateUpdatePropertyAcceptDeclineOfRFPError, getBrandRFPRateByInventoryIdSuccess, getPropertySuccess, getBrandRFPRateByInventoryIdError, getCreateUpdatePropertyStandardRateForRFPSuccess, getCreateUpdatePropertyStandardRateForRFPError } = useStoreState<any>((state) => ({
        getBrandRFPRateByInventoryIdSuccess: state.rfpProcess.getBrandRFPRateByInventoryIdSuccess,
        getBrandRFPRateByInventoryIdError: state.rfpProcess.getBrandRFPRateByInventoryIdError,
        getCreateUpdatePropertyStandardRateForRFPSuccess: state.rfpProcess.getCreateUpdatePropertyStandardRateForRFPSuccess,
        getCreateUpdatePropertyStandardRateForRFPError: state.rfpProcess.getCreateUpdatePropertyStandardRateForRFPError,
        getPropertySuccess: state.property.getPropertySuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPError,
    }));

    //////////////////////////////////
    const callRfps = useCallback(() => {
        const payload = {
            rfpProcessId: myoSelectedMyRfp.rfpId,
            brandId: myoSelectedBrand,
            propertyId: myoSelectedMyRfpProperty.id,
            propertyInventoryId: inventory?.key
        };

        getBrandRFPRateByInventoryId(payload);
    }, [getBrandRFPRateByInventoryId, inventory?.key, myoSelectedBrand, myoSelectedMyRfp.rfpId, myoSelectedMyRfpProperty.id])



    ///////////////// initialize api calls //////////////////
    useEffect(() => {
        setIsSeasonalHere(false);
        reset();
        callRfps();
    }, [callRfps, reset]);

    //////////// check apis responses /////////////////
    useEffect(() => {
        if (getBrandRFPRateByInventoryIdSuccess?.data) {
            setIsRateHere(true);
            setEditEnable(true);
            setEditEnableAmendRate(true);
            setRateData(getBrandRFPRateByInventoryIdSuccess?.data);

            ////////////////// seasonal component //////////
            if (rateData?.corporateRate?.seasonalRate) {
                const seasonalComponent = rateData?.corporateRate?.seasonalRate.map((val, index) => {
                    return (
                        <div key={index}>
                            <RateSeasonalForm seasonalRate={val} inventory={inventory} callRfps={callRfps} isSeasonalHere={isSeasonalHere} />
                        </div>
                    );
                });
                setSeasonalRateData(seasonalComponent);
            }
            setIsYesCheck(false);
            setIsNoCheck(false);
        }

        if (getBrandRFPRateByInventoryIdError) {
            setIsRateHere(false);
            setEditEnable(false);
            setEditEnableAmendRate(false);
        }

        if (getCreateUpdatePropertyStandardRateForRFPSuccess) {
            const payload = {
                rfpProcessId: myoSelectedMyRfp.rfpId,
                brandId: myoSelectedBrand,
                propertyId: myoSelectedMyRfpProperty.id,
                propertyInventoryId: inventory.key
            };
            getBrandRFPRateByInventoryId(payload);
            toast.success("Rate submitted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setShowCommentQuestion(true);
            if (inventory?.status === rfpPropertyStatusToInventory?.REVIEWED) {
                onReviewRate();
            }

            setIsYesCheck(false);
            setIsNoCheck(false);

            setLoading(false);
        }

        if (getCreateUpdatePropertyStandardRateForRFPError) {
            setLoading(false);
            toast.error(getCreateUpdatePropertyStandardRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [callRfps, getBrandRFPRateByInventoryId, getBrandRFPRateByInventoryIdError, getBrandRFPRateByInventoryIdSuccess?.data, getCreateUpdatePropertyStandardRateForRFPError, getCreateUpdatePropertyStandardRateForRFPSuccess, inventory, isSeasonalHere, myoSelectedBrand, myoSelectedMyRfp.rfpId, myoSelectedMyRfpProperty.id, onReviewRate, rateData?.corporateRate?.seasonalRate]);

    // console.log(inventory, 'inventory');
    ///////////////////// rate create and update form ///////////////
    const onFinish = (value) => {
        const payload = {
            rfpProcessId: myoSelectedMyRfp.rfpId,
            brandId: myoSelectedBrand,
            propertyId: myoSelectedMyRfpProperty.id,
            propertyInventoryId: inventory.key,
            brandRfpId: myoSelectedMyRfp?.key,
            cityName: myoSelectedMyRfpProperty?.city,
            corporateRate: {
                standardRate: {
                    oneTo7: Number(value.standardRateOneTo7),
                    eightTo14: Number(value.standardRateEightTo14),
                    fifteenTo28: Number(value.standardRateFifteenTo28),
                    twentyNineTo90: Number(value.standardRateTwentyNineTo90),
                    ninetyOnePlus: Number(value.standardRateNinetyOnePlus),
                },
                standardOldRate: {
                    oneTo7: Number(value.standardRateOneTo7),
                    eightTo14: Number(value.standardRateEightTo14),
                    fifteenTo28: Number(value.standardRateFifteenTo28),
                    twentyNineTo90: Number(value.standardRateTwentyNineTo90),
                    ninetyOnePlus: Number(value.standardRateNinetyOnePlus),
                },
                cancellationPolicy: {
                    oneTo7: {
                        cutOffDates: Number(value.cancellationPolicyOneTo7CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyOneTo7PenaltyDates),
                        cutOffDatesOld: Number(value.cancellationPolicyOneTo7CutOffDates),
                        penaltyDatesOld: Number(value.cancellationPolicyOneTo7PenaltyDates)
                    },
                    eightTo14: {
                        cutOffDates: Number(value.cancellationPolicyEightTo14CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyEightTo14PenaltyDates),
                        cutOffDatesOld: Number(value.cancellationPolicyEightTo14CutOffDates),
                        penaltyDatesOld: Number(value.cancellationPolicyEightTo14PenaltyDates)
                    },
                    fifteenTo28: {
                        cutOffDates: Number(value.cancellationPolicyFifteenTo28CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyFifteenTo28PenaltyDates),
                        cutOffDatesOld: Number(value.cancellationPolicyFifteenTo28CutOffDates),
                        penaltyDatesOld: Number(value.cancellationPolicyFifteenTo28PenaltyDates)
                    },
                    twentyNineTo90: {
                        cutOffDates: Number(value.cancellationPolicyTwentyNineTo90CutOffDates),
                        penaltyDates: Number(value.cancellationPolicyTwentyNineTo90PenaltyDates),
                        cutOffDatesOld: Number(value.cancellationPolicyTwentyNineTo90CutOffDates),
                        penaltyDatesOld: Number(value.cancellationPolicyTwentyNineTo90PenaltyDates)
                    },
                    ninetyOnePlus: {
                        cutOffDates: Number(value.cancellationPolicyNinetyOnePlusCutOffDates),
                        penaltyDates: Number(value.cancellationPolicyNinetyOnePlusPenaltyDates),
                        cutOffDatesOld: Number(value.cancellationPolicyNinetyOnePlusCutOffDates),
                        penaltyDatesOld: Number(value.cancellationPolicyNinetyOnePlusPenaltyDates)
                    }
                }
            }
        }

        createUpdatePropertyStandardRateForRFP(payload);
        setLoading(true);
    }


    ///////////////////// rate create and update form ///////////////
    const onFinishAmendedRate = (value) => {
        const payload = {
            rfpProcessId: myoSelectedMyRfp.rfpId,
            brandId: myoSelectedBrand,
            propertyId: myoSelectedMyRfpProperty.id,
            propertyInventoryId: inventory.key,
            brandRfpId: myoSelectedMyRfp?.key,
            cityName: myoSelectedMyRfpProperty?.city,
            corporateRate: {
                standardRate: {
                    oneTo7: Number(value.standardRateOneTo7 ? value.standardRateOneTo7 : rateData?.corporateRate?.standardRate?.oneTo7),
                    eightTo14: Number(value.standardRateEightTo14 ? value.standardRateEightTo14 : rateData?.corporateRate?.standardRate?.eightTo14),
                    fifteenTo28: Number(value.standardRateFifteenTo28 ? value.standardRateFifteenTo28 : rateData?.corporateRate?.standardOldRate?.fifteenTo28),
                    twentyNineTo90: Number(value.standardRateTwentyNineTo90 ? value.standardRateTwentyNineTo90 : rateData?.corporateRate?.standardOldRate?.twentyNineTo90),
                    ninetyOnePlus: Number(value.standardRateNinetyOnePlus ? value.standardRateNinetyOnePlus : rateData?.corporateRate?.standardOldRate?.ninetyOnePlus),
                },
                standardOldRate: {
                    oneTo7: Number(rateData?.corporateRate?.standardOldRate?.oneTo7),
                    eightTo14: Number(rateData?.corporateRate?.standardOldRate?.eightTo14),
                    fifteenTo28: Number(rateData?.corporateRate?.standardOldRate?.fifteenTo28),
                    twentyNineTo90: Number(rateData?.corporateRate?.standardOldRate?.twentyNineTo90),
                    ninetyOnePlus: Number(rateData?.corporateRate?.standardOldRate?.ninetyOnePlus),
                },

                cancellationPolicy: {
                    oneTo7: {
                        cutOffDates: Number(value.cancellationPolicyOneTo7CutOffDates ? value.cancellationPolicyOneTo7CutOffDates : rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDates),
                        penaltyDates: Number(value.cancellationPolicyOneTo7PenaltyDates ? value.cancellationPolicyOneTo7PenaltyDates : rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDatesOld)
                    },
                    eightTo14: {
                        cutOffDates: Number(value.cancellationPolicyEightTo14CutOffDates ? value.cancellationPolicyEightTo14CutOffDates : rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDates),
                        penaltyDates: Number(value.cancellationPolicyEightTo14PenaltyDates ? value.cancellationPolicyEightTo14PenaltyDates : rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDatesOld)
                    },
                    fifteenTo28: {
                        cutOffDates: Number(value.cancellationPolicyFifteenTo28CutOffDates ? value.cancellationPolicyFifteenTo28CutOffDates : rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDates),
                        penaltyDates: Number(value.cancellationPolicyFifteenTo28PenaltyDates ? value.cancellationPolicyFifteenTo28PenaltyDates : rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDatesOld)
                    },
                    twentyNineTo90: {
                        cutOffDates: Number(value.cancellationPolicyTwentyNineTo90CutOffDates ? value.cancellationPolicyTwentyNineTo90CutOffDates : rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates),
                        penaltyDates: Number(value.cancellationPolicyTwentyNineTo90PenaltyDates ? value.cancellationPolicyTwentyNineTo90PenaltyDates : rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDatesOld)
                    },
                    ninetyOnePlus: {
                        cutOffDates: Number(value.cancellationPolicyNinetyOnePlusCutOffDates ? value.cancellationPolicyNinetyOnePlusCutOffDates : rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates),
                        penaltyDates: Number(value.cancellationPolicyNinetyOnePlusPenaltyDates ? value.cancellationPolicyNinetyOnePlusPenaltyDates : rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDatesOld)
                    }
                }
            }
        }

        createUpdatePropertyStandardRateForRFP(payload);
        setLoading(true);
    }


    ///////////////////// rate create and update form ///////////////
    const onFinishAmendedRateAgain = (value) => {
        const payload = {
            rfpProcessId: myoSelectedMyRfp.rfpId,
            brandId: myoSelectedBrand,
            propertyId: myoSelectedMyRfpProperty.id,
            propertyInventoryId: inventory.key,
            brandRfpId: myoSelectedMyRfp?.key,
            cityName: myoSelectedMyRfpProperty?.city,
            corporateRate: {
                standardRate: {
                    oneTo7: Number(value.standardRateOneTo7 ? value.standardRateOneTo7 : rateData?.corporateRate?.standardRate?.oneTo7),
                    eightTo14: Number(value.standardRateEightTo14 ? value.standardRateEightTo14 : rateData?.corporateRate?.standardRate?.eightTo14),
                    fifteenTo28: Number(value.standardRateFifteenTo28 ? value.standardRateFifteenTo28 : rateData?.corporateRate?.standardOldRate?.fifteenTo28),
                    twentyNineTo90: Number(value.standardRateTwentyNineTo90 ? value.standardRateTwentyNineTo90 : rateData?.corporateRate?.standardOldRate?.twentyNineTo90),
                    ninetyOnePlus: Number(value.standardRateNinetyOnePlus ? value.standardRateNinetyOnePlus : rateData?.corporateRate?.standardOldRate?.ninetyOnePlus),
                },
                standardOldRate: {
                    oneTo7: Number(rateData?.corporateRate?.standardOldRate?.oneTo7),
                    eightTo14: Number(rateData?.corporateRate?.standardOldRate?.eightTo14),
                    fifteenTo28: Number(rateData?.corporateRate?.standardOldRate?.fifteenTo28),
                    twentyNineTo90: Number(rateData?.corporateRate?.standardOldRate?.twentyNineTo90),
                    ninetyOnePlus: Number(rateData?.corporateRate?.standardOldRate?.ninetyOnePlus),
                },

                cancellationPolicy: {
                    oneTo7: {
                        cutOffDates: Number(value.cancellationPolicyOneTo7CutOffDates ? value.cancellationPolicyOneTo7CutOffDates : rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDates),
                        penaltyDates: Number(value.cancellationPolicyOneTo7PenaltyDates ? value.cancellationPolicyOneTo7PenaltyDates : rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDatesOld)
                    },
                    eightTo14: {
                        cutOffDates: Number(value.cancellationPolicyEightTo14CutOffDates ? value.cancellationPolicyEightTo14CutOffDates : rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDates),
                        penaltyDates: Number(value.cancellationPolicyEightTo14PenaltyDates ? value.cancellationPolicyEightTo14PenaltyDates : rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDatesOld)
                    },
                    fifteenTo28: {
                        cutOffDates: Number(value.cancellationPolicyFifteenTo28CutOffDates ? value.cancellationPolicyFifteenTo28CutOffDates : rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDates),
                        penaltyDates: Number(value.cancellationPolicyFifteenTo28PenaltyDates ? value.cancellationPolicyFifteenTo28PenaltyDates : rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDatesOld)
                    },
                    twentyNineTo90: {
                        cutOffDates: Number(value.cancellationPolicyTwentyNineTo90CutOffDates ? value.cancellationPolicyTwentyNineTo90CutOffDates : rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates),
                        penaltyDates: Number(value.cancellationPolicyTwentyNineTo90PenaltyDates ? value.cancellationPolicyTwentyNineTo90PenaltyDates : rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDatesOld)
                    },
                    ninetyOnePlus: {
                        cutOffDates: Number(value.cancellationPolicyNinetyOnePlusCutOffDates ? value.cancellationPolicyNinetyOnePlusCutOffDates : rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates),
                        penaltyDates: Number(value.cancellationPolicyNinetyOnePlusPenaltyDates ? value.cancellationPolicyNinetyOnePlusPenaltyDates : rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates),
                        cutOffDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDatesOld),
                        penaltyDatesOld: Number(rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDatesOld)
                    }
                }
            }
        }

        createUpdatePropertyStandardRateForRFP(payload);
        setLoading(true);
    }

    //////////// seasonal form enable part //////////
    const enableSeasonalForm = useCallback((value) => {
        setEnableRateForm(value);
    }, []);

    //////////// extra seasonal form enable part //////////
    const extraEnableSeasonalForm = useCallback((value) => {
        setEnableExtraSeasonalRateForm(value);
    }, []);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onAmendRate = useCallback((value) => {
        if (value === CommonAnswer.NO) {
            setIsSeasonalHere(true);
        } else {
            setIsSeasonalHere(false);
        }
        setIsAmendRate(value);
    }, []);


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onAmendRateComment = useCallback((value) => {
        setIsAmendRateComment(value);
    }, []);
    /////////////////////////review inventory success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(reviewCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            onReviewRate(inventory);
        }

    }, [inventory, onReviewRate, reviewCreateUpdatePropertyAcceptDeclineOfRFPError, reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onSaveAmendRateComment = useCallback(() => {
        const payload = {
            rfpId: myoSelectedMyRfp?.rfpId.toString(),
            brandId: myoSelectedMyRfp?.brandId.toString(),
            propertyId: property?.id,
            inventoryId: inventory?.key,
            brandRespondComment: {
                messageText: inventoryAmendedRateComment,
                messageTime: new Date()
            },
        }
        reviewCreateUpdatePropertyAcceptDeclineOfRFP(payload);

    }, [inventory?.key, inventoryAmendedRateComment, myoSelectedMyRfp?.brandId, myoSelectedMyRfp?.rfpId, property?.id, reviewCreateUpdatePropertyAcceptDeclineOfRFP]);


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onSaveAmendSecondRateComment = useCallback(() => {
        if (inventoryAmendedSecondRateComment) {
            const payload = {
                rfpId: myoSelectedMyRfp?.rfpId.toString(),
                brandId: myoSelectedMyRfp?.brandId.toString(),
                propertyId: property?.id,
                inventoryId: inventory?.key,
                brandRespondComment: {
                    messageText: inventoryAmendedSecondRateComment,
                    messageTime: new Date()
                },
            }
            reviewCreateUpdatePropertyAcceptDeclineOfRFP(payload);
        } else {
            const payload = {
                rfpId: myoSelectedMyRfp?.rfpId.toString(),
                brandId: myoSelectedMyRfp?.brandId.toString(),
                propertyId: property?.id,
                inventoryId: inventory?.key,
                brandRespondComment: {
                    messageText: 'Rates Reviewed',
                    messageTime: new Date()
                },
            }
            reviewCreateUpdatePropertyAcceptDeclineOfRFP(payload);
        }



    }, [inventory?.key, inventoryAmendedSecondRateComment, myoSelectedMyRfp?.brandId, myoSelectedMyRfp?.rfpId, property?.id, reviewCreateUpdatePropertyAcceptDeclineOfRFP]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (property?.id) {
            getProperty(property?.id);
        }
    }, [getProperty, property?.id]);


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertySuccess) {
            const data = getPropertySuccess?.data;
            setCurrency(data?.currency);

        }
    }, [getPropertySuccess]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            {
                <Row className='mt-5'>
                    <div style={{ fontSize: '16px' }}> Submitted rates
                    </div>
                </Row>}
            {/* proposed rate */}
            <div className='mt-5'>
                <Form onSubmit={handleSubmit(onFinish)}>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Rate {'(' + currency + ')'}
                                <span style={{ marginLeft: '25px' }}>  Proposed </span> </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}>
                                <span style={headBorderStyle}></span><span >1-7 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 8-14 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 15-28 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 29-89 nights</span>
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={headStyle}><span style={headBorderStyle}></span>
                                <span > 90+ nights</span>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '4%', marginBottom: '10px' }}>Standard Rate</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardOldRate?.oneTo7} min={0} className={`${errors.standardOldRateOneTo7 ? 'is-invalid' : ''}`} {...register("standardOldRateOneTo7")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardOldRate?.eightTo14} min={0} className={`${errors.standardOldRateEightTo14 ? 'is-invalid' : ''}`} {...register("standardOldRateEightTo14")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardOldRate?.fifteenTo28} min={0} className={`${errors.standardOldRateFifteenTo28 ? 'is-invalid' : ''}`} {...register("standardOldRateFifteenTo28")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardOldRate?.twentyNineTo90} min={0} className={`${errors.standardOldRateTwentyNineTo90 ? 'is-invalid' : ''}`} {...register("standardOldRateTwentyNineTo90")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardOldRate?.ninetyOnePlus} min={0} className={`${errors.standardOldRateNinetyOnePlus ? 'is-invalid' : ''}`} {...register("standardOldRateNinetyOnePlus")} disabled={editEnable} />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation notice (nights)</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyOneTo7CutOffDatesOld ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7CutOffDatesOld")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyEightTo14CutOffDatesOld ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14CutOffDatesOld")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyFifteenTo28CutOffDatesOld ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28CutOffDatesOld")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyTwentyNineTo90CutOffDatesOld ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90CutOffDatesOld")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyNinetyOnePlusCutOffDatesOld ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusCutOffDatesOld")} disabled={editEnable} />
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3}>
                            <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation charge (nights)</div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyOneTo7PenaltyDatesOld ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7PenaltyDatesOld")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyEightTo14PenaltyDatesOld ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14PenaltyDatesOld")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyFifteenTo28PenaltyDatesOld ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28PenaltyDatesOld")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyTwentyNineTo90PenaltyDatesOld ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90PenaltyDatesOld")} disabled={editEnable} />
                            </div>
                        </Col>
                        <Col md={1} >
                            <div style={bodyTextStyleOne}>
                                <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyNinetyOnePlusPenaltyDatesOld ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusPenaltyDatesOld")} disabled={editEnable} />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>

            {isRateHere && rateData?.corporateRate?.seasonalRate.length === 0
                && enableRateForm === 'Yes' && inventory?.status !== 'review' && <RateSeasonalForm seasonalRate={''} inventory={inventory} callRfps={callRfps} isSeasonalHere={isSeasonalHere} />
            }

            {
                isRateHere && rateData?.corporateRate?.seasonalRate.length !== 0 && seasonalRateData

            }

            {isRateHere && rateData?.corporateRate?.seasonalRate.length !== 0
                && enableExtraSeasonalRateForm === 'Yes' && inventory?.status !== 'review' && <RateSeasonalForm seasonalRate={''} inventory={inventory} callRfps={callRfps} isSeasonalHere={isSeasonalHere} />
            }

            {(inventory?.status === 'review' && inventoryMessage?.length === 0) &&
                <Row className='mt-5'>
                    <div style={{ fontSize: '16px' }}>Do you wish to amend these rates?
                        <span style={{ marginLeft: '2%' }}>
                            <Form.Check
                                inline
                                value={'Yes'}
                                label={'Yes'}
                                type="radio"
                                defaultChecked={rateData?.corporateRate?.standardOldRate?.oneTo7 !== rateData?.corporateRate?.standardRate?.oneTo7 ? true : false}
                                name='amendRate'
                                onChange={(value) => {
                                    onAmendRate(value.target.value);
                                }}
                            />
                            <Form.Check
                                inline
                                value={'No'}
                                label={'No'}
                                type="radio"
                                name='amendRate'
                                onChange={(value) => {
                                    onAmendRate(value.target.value);
                                }}
                            />
                        </span>
                    </div>

                    {
                        isAmendRate === CommonAnswer.NO &&
                        <div>
                            <Row>
                                <Col md={6}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                    <Form.Control as="textarea" rows={5}
                                        placeholder="please provide comments"
                                        name='inventoryAmendedRateComment'
                                        // value={''}
                                        onChange={(e) => {
                                            setInventoryAmendedRateComment(e.target.value);
                                        }}
                                    />
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>

                            <Row className='formSpace'>
                                <Col md={6} style={{ textAlign: 'left' }}>
                                    <Button id='approve-button' style={formButton} onClick={() => { onSaveAmendRateComment() }} >Submit</Button>
                                </Col>
                                <Col md={6} style={{ textAlign: 'right' }}>
                                </Col>
                            </Row>
                        </div>
                    }
                </Row>
            }

            {
                (inventory?.status === 'review' && (inventoryMessage?.length === 0) && ((isAmendRate === CommonAnswer.YES) || (rateData?.corporateRate?.standardOldRate?.oneTo7 !== rateData?.corporateRate?.standardRate?.oneTo7)) && rateData?.corporateRate?.standardRate?.oneTo7) &&
                <Row>

                    <div className='mt-5'>
                        <Form onSubmit={handleSubmit(onFinishAmendedRate)}>
                            <Row style={{ background: '#F2F2F2' }}>
                                <Col md={3}>
                                    <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Rate
                                        {/* { propertyAcceptInformation?.inventories[0]?.inventoryMessageThread?.length !== 0  &&   <span style={{marginLeft:'25px'}}>  Re - submitted </span>} */}
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}>
                                        <span style={headBorderStyle}></span><span >1-7 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 8-14 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 15-28 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 29-89 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 90+ nights</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '4%', marginBottom: '10px' }}>Standard Rate</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.oneTo7 || rateData?.corporateRate?.standardOldRate?.oneTo7} min={0} className={`${errors.standardRateOneTo7 ? 'is-invalid' : ''}`} {...register("standardRateOneTo7")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.eightTo14 || rateData?.corporateRate?.standardOldRate?.eightTo14} min={0} className={`${errors.standardRateEightTo14 ? 'is-invalid' : ''}`} {...register("standardRateEightTo14")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.fifteenTo28 || rateData?.corporateRate?.standardOldRate?.fifteenTo28} min={0} className={`${errors.standardRateFifteenTo28 ? 'is-invalid' : ''}`} {...register("standardRateFifteenTo28")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.twentyNineTo90 || rateData?.corporateRate?.standardOldRate?.twentyNineTo90} min={0} className={`${errors.standardRateTwentyNineTo90 ? 'is-invalid' : ''}`} {...register("standardRateTwentyNineTo90")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.ninetyOnePlus || rateData?.corporateRate?.standardOldRate?.ninetyOnePlus} min={0} className={`${errors.standardRateNinetyOnePlus ? 'is-invalid' : ''}`} {...register("standardRateNinetyOnePlus")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ background: '#F2F2F2' }}>
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation notice (nights)</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyOneTo7CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyEightTo14CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyFifteenTo28CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyTwentyNineTo90CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyNinetyOnePlusCutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusCutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation charge (nights)</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyOneTo7PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7PenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyEightTo14PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14PenaltyDates",)} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyFifteenTo28PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28PenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyTwentyNineTo90PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90PenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyNinetyOnePlusPenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusPenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '20px' }}>
                                <Col md={12}>
                                    {!loading && !editEnableAmendRate && <Button type="submit" style={formButton} ><b>Save</b></Button>}
                                    {!loading && editEnableAmendRate && <Button id="edit-button" style={formButton} onClick={() => {
                                        setEditEnableAmendRate(false);
                                    }}><b>Edit</b></Button>}
                                    {!loading && (editEnableAmendRate && isAmendRate === CommonAnswer.YES && isAmendRateComment === CommonAnswer.NO) && <Button id='approve-button' style={formButton} onClick={() => { onSaveAmendSecondRateComment() }} ><b>Submit</b></Button>}
                                    {loading && <Spinner animation="border" variant="warning" />}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Row>}

            {/* first time no negotiate rate */}
            {
                (inventory?.status === 'review' && (inventoryMessage?.length !== 0) && ((isAmendRate === CommonAnswer.YES) && (rateData?.corporateRate?.standardOldRate?.oneTo7 === rateData?.corporateRate?.standardRate?.oneTo7)) && rateData?.corporateRate?.standardRate?.oneTo7) &&
                <Row>

                    <div className='mt-5'>
                        <Form onSubmit={handleSubmit(onFinishAmendedRate)}>
                            <Row style={{ background: '#F2F2F2' }}>
                                <Col md={3}>
                                    <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Rate
                                        {/* { propertyAcceptInformation?.inventories[0]?.inventoryMessageThread?.length !== 0  &&   <span style={{marginLeft:'25px'}}>  Re - submitted </span>} */}
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}>
                                        <span style={headBorderStyle}></span><span >1-7 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 8-14 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 15-28 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 29-89 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 90+ nights</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '4%', marginBottom: '10px' }}>Standard Rate</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.oneTo7 || rateData?.corporateRate?.standardOldRate?.oneTo7} min={0} className={`${errors.standardRateOneTo7 ? 'is-invalid' : ''}`} {...register("standardRateOneTo7")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.eightTo14 || rateData?.corporateRate?.standardOldRate?.eightTo14} min={0} className={`${errors.standardRateEightTo14 ? 'is-invalid' : ''}`} {...register("standardRateEightTo14")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.fifteenTo28 || rateData?.corporateRate?.standardOldRate?.fifteenTo28} min={0} className={`${errors.standardRateFifteenTo28 ? 'is-invalid' : ''}`} {...register("standardRateFifteenTo28")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.twentyNineTo90 || rateData?.corporateRate?.standardOldRate?.twentyNineTo90} min={0} className={`${errors.standardRateTwentyNineTo90 ? 'is-invalid' : ''}`} {...register("standardRateTwentyNineTo90")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.ninetyOnePlus || rateData?.corporateRate?.standardOldRate?.ninetyOnePlus} min={0} className={`${errors.standardRateNinetyOnePlus ? 'is-invalid' : ''}`} {...register("standardRateNinetyOnePlus")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ background: '#F2F2F2' }}>
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation notice (nights)</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyOneTo7CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyEightTo14CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyFifteenTo28CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyTwentyNineTo90CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyNinetyOnePlusCutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusCutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation charge (nights)</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyOneTo7PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7PenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyEightTo14PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14PenaltyDates",)} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyFifteenTo28PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28PenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyTwentyNineTo90PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90PenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyNinetyOnePlusPenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusPenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '20px' }}>
                                <Col md={12}>
                                    {!loading && !editEnableAmendRate && <Button type="submit" style={formButton} ><b>Save</b></Button>}
                                    {!loading && editEnableAmendRate && <Button id="edit-button" style={formButton} onClick={() => {
                                        setEditEnableAmendRate(false);
                                    }}><b>Edit</b></Button>}
                                    {!loading && (editEnableAmendRate && isAmendRate === CommonAnswer.YES && isAmendRateComment === CommonAnswer.NO) && <Button id='approve-button' style={formButton} onClick={() => { onSaveAmendSecondRateComment() }} ><b>Submit</b></Button>}
                                    {loading && <Spinner animation="border" variant="warning" />}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Row>}

            {/* ffgddg */}

            {
                (inventory?.status === 'review' && (inventoryMessage?.length !== 0) && (rateData?.corporateRate?.standardOldRate?.oneTo7 !== rateData?.corporateRate?.standardRate?.oneTo7) && rateData?.corporateRate?.standardRate?.oneTo7) &&
                <Row>

                    <div className='mt-5'>
                        <Form onSubmit={handleSubmit(onFinishAmendedRate)}>
                            <Row style={{ background: '#F2F2F2' }}>
                                <Col md={3}>
                                    <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Rate
                                        {inventoryMessage?.length !== 0 && <span style={{ marginLeft: '25px' }}>  Re - submitted </span>}
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}>
                                        <span style={headBorderStyle}></span><span >1-7 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 8-14 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 15-28 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 29-89 nights</span>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={headStyle}><span style={headBorderStyle}></span>
                                        <span > 90+ nights</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '4%', marginBottom: '10px' }}>Standard Rate</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.oneTo7 || rateData?.corporateRate?.standardOldRate?.oneTo7} min={0} className={`${errors.standardRateOneTo7 ? 'is-invalid' : ''}`} {...register("standardRateOneTo7")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.eightTo14 || rateData?.corporateRate?.standardOldRate?.eightTo14} min={0} className={`${errors.standardRateEightTo14 ? 'is-invalid' : ''}`} {...register("standardRateEightTo14")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.fifteenTo28 || rateData?.corporateRate?.standardOldRate?.fifteenTo28} min={0} className={`${errors.standardRateFifteenTo28 ? 'is-invalid' : ''}`} {...register("standardRateFifteenTo28")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.twentyNineTo90 || rateData?.corporateRate?.standardOldRate?.twentyNineTo90} min={0} className={`${errors.standardRateTwentyNineTo90 ? 'is-invalid' : ''}`} {...register("standardRateTwentyNineTo90")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.ninetyOnePlus || rateData?.corporateRate?.standardOldRate?.ninetyOnePlus} min={0} className={`${errors.standardRateNinetyOnePlus ? 'is-invalid' : ''}`} {...register("standardRateNinetyOnePlus")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ background: '#F2F2F2' }}>
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation notice (nights)</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyOneTo7CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyEightTo14CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyFifteenTo28CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyTwentyNineTo90CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90CutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates || rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDatesOld} min={0} className={`${errors.cancellationPolicyNinetyOnePlusCutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusCutOffDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={3}>
                                    <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation charge (nights)</div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyOneTo7PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7PenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyEightTo14PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14PenaltyDates",)} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyFifteenTo28PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28PenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyTwentyNineTo90PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90PenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div style={bodyTextStyleOne}>
                                        <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates || rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDatesOld} min={0} className={`${errors.cancellationPolicyNinetyOnePlusPenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusPenaltyDates")} disabled={editEnableAmendRate} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '20px' }}>
                                <Col md={12}>
                                    {!loading && !editEnableAmendRate && <Button type="submit" style={formButton} ><b>Save</b></Button>}
                                    {!loading && editEnableAmendRate && isAmendRate === CommonAnswer.YES && <Button id="edit-button" style={formButton} onClick={() => {
                                        setEditEnableAmendRate(false);
                                    }}><b>Edit</b></Button>}
                                    {!loading && (editEnableAmendRate && isAmendRate === CommonAnswer.YES && isAmendRateComment === CommonAnswer.NO) && <Button id='approve-button' style={formButton} onClick={() => { onSaveAmendSecondRateComment() }} ><b>Submit</b></Button>}
                                    {loading && <Spinner animation="border" variant="warning" />}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Row>}


            {(inventory?.status === 'review' && inventoryMessage?.length !== 0) &&
                <Row className='mt-5'>
                    <div style={{ fontSize: '16px' }}>Do you wish to amend these rates?
                        <span style={{ marginLeft: '2%' }}>
                            <Form.Check
                                inline
                                value={'Yes'}
                                label={'Yes'}
                                type="radio"
                                defaultChecked={rateData?.corporateRate?.standardOldRate?.oneTo7 !== rateData?.corporateRate?.standardRate?.oneTo7 ? true : false}
                                name='amendRate'
                                onChange={(value) => {
                                    onAmendRate(value.target.value);
                                }}
                            />
                            <Form.Check
                                inline
                                value={'No'}
                                label={'No'}
                                type="radio"
                                name='amendRate'
                                onChange={(value) => {
                                    onAmendRate(value.target.value);
                                }}
                            />
                        </span>
                    </div>

                    {
                        isAmendRate === CommonAnswer.NO &&
                        <div>
                            <Row>
                                <Col md={6}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                                    <Form.Control as="textarea" rows={5}
                                        placeholder="please provide comments"
                                        name='inventoryAmendedRateComment'
                                        // value={''}
                                        onChange={(e) => {
                                            setInventoryAmendedRateComment(e.target.value);
                                        }}
                                    />
                                </div></Col>
                                <Col md={1}></Col>
                            </Row>

                            <Row className='formSpace'>
                                <Col md={6} style={{ textAlign: 'left' }}>
                                    <Button id='approve-button' style={formButton} onClick={() => { onSaveAmendRateComment() }} >Submit</Button>
                                </Col>
                                <Col md={6} style={{ textAlign: 'right' }}>
                                </Col>
                            </Row>
                        </div>
                    }
                </Row>
            }

            {/* asdjasashhadshads */}

            {
                (isAmendRate === CommonAnswer.YES && showCommentQuestion) &&
                <div style={{ fontSize: '16px', marginTop: '25px' }}>Do you wish to provide a comment about these rates?
                    <span style={{ marginLeft: '2%' }}>
                        <Form.Check
                            inline
                            value={'Yes'}
                            label={'Yes'}
                            type="radio"
                            name='amendRateComment'
                            onChange={(value) => {
                                onAmendRateComment(value.target.value);
                            }}
                        />
                        <Form.Check
                            inline
                            value={'No'}
                            label={'No'}
                            type="radio"
                            name='amendRateComment'
                            onChange={(value) => {
                                onAmendRateComment(value.target.value);
                            }}
                        />
                    </span>
                </div>

            }
            {/* </Row> */}


            {
                property?.evaluationStatus !== BrandRfpPropertyEvaluationStatus.COMPLETE && ((inventory?.status === 'reviewed' && rateData?.corporateRate?.standardOldRate?.oneTo7 !== rateData?.corporateRate?.standardRate?.oneTo7) || (inventory?.status === 'accepted' && rateData?.corporateRate?.standardOldRate?.oneTo7 !== rateData?.corporateRate?.standardRate?.oneTo7)) &&
                <div className='mt-5'>
                    <Form onSubmit={handleSubmit(onFinishAmendedRateAgain)}>
                        <Row style={{ background: '#F2F2F2' }}>
                            <Col md={3}>
                                <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Rate
                                    <span style={{ marginLeft: '25px' }}>  Re - submitted </span>
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={headStyle}>
                                    <span style={headBorderStyle}></span><span >1-7 nights</span>
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={headStyle}><span style={headBorderStyle}></span>
                                    <span > 8-14 nights</span>
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={headStyle}><span style={headBorderStyle}></span>
                                    <span > 15-28 nights</span>
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={headStyle}><span style={headBorderStyle}></span>
                                    <span > 29-89 nights</span>
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={headStyle}><span style={headBorderStyle}></span>
                                    <span > 90+ nights</span>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={3}>
                                <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginTop: '4%', marginBottom: '10px' }}>Standard Rate</div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.oneTo7} min={0} className={`${errors.standardRateOneTo7 ? 'is-invalid' : ''}`} {...register("standardRateOneTo7")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.eightTo14} min={0} className={`${errors.standardRateEightTo14 ? 'is-invalid' : ''}`} {...register("standardRateEightTo14")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.fifteenTo28} min={0} className={`${errors.standardRateFifteenTo28 ? 'is-invalid' : ''}`} {...register("standardRateFifteenTo28")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.twentyNineTo90} min={0} className={`${errors.standardRateTwentyNineTo90 ? 'is-invalid' : ''}`} {...register("standardRateTwentyNineTo90")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.standardRate?.ninetyOnePlus} min={0} className={`${errors.standardRateNinetyOnePlus ? 'is-invalid' : ''}`} {...register("standardRateNinetyOnePlus")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ background: '#F2F2F2' }}>
                            <Col md={3}>
                                <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '3% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation notice (nights)</div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.cutOffDates} min={0} className={`${errors.cancellationPolicyOneTo7CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7CutOffDates")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.cutOffDates} min={0} className={`${errors.cancellationPolicyEightTo14CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14CutOffDates")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.cutOffDates} min={0} className={`${errors.cancellationPolicyFifteenTo28CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28CutOffDates")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.cutOffDates} min={0} className={`${errors.cancellationPolicyTwentyNineTo90CutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90CutOffDates")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.cutOffDates} min={0} className={`${errors.cancellationPolicyNinetyOnePlusCutOffDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusCutOffDates")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={3}>
                                <div style={{ fontSize: '17px', fontWeight: 'bold', padding: '2% 3% 3% 5%', marginRight: '-50%', marginTop: '3%', marginBottom: '10px' }}>Cancellation charge (nights)</div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.oneTo7?.penaltyDates} min={0} className={`${errors.cancellationPolicyOneTo7PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyOneTo7PenaltyDates")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.eightTo14?.penaltyDates} min={0} className={`${errors.cancellationPolicyEightTo14PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyEightTo14PenaltyDates")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.fifteenTo28?.penaltyDates} min={0} className={`${errors.cancellationPolicyFifteenTo28PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyFifteenTo28PenaltyDates")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.twentyNineTo90?.penaltyDates} min={0} className={`${errors.cancellationPolicyTwentyNineTo90PenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyTwentyNineTo90PenaltyDates")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                            <Col md={1} >
                                <div style={bodyTextStyleOne}>
                                    <Form.Control type="number" defaultValue={rateData?.corporateRate?.cancellationPolicy?.ninetyOnePlus?.penaltyDates} min={0} className={`${errors.cancellationPolicyNinetyOnePlusPenaltyDates ? 'is-invalid' : ''}`} {...register("cancellationPolicyNinetyOnePlusPenaltyDates")} disabled={editEnableAmendRate} />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col md={12}>
                                {!loading && !editEnableAmendRate && <Button type="submit" style={formButton} ><b>Save</b></Button>}
                                {!loading && editEnableAmendRate && (inventory?.status !== 'accepted') && <Button id="edit-button" style={formButton} onClick={() => {
                                    setEditEnableAmendRate(false);
                                }}><b>Edit</b></Button>}
                                {!loading && (editEnableAmendRate && isAmendRate === CommonAnswer.YES && isAmendRateComment === CommonAnswer.NO) && <Button id='approve-button' style={formButton} onClick={() => { onSaveAmendSecondRateComment() }} ><b>Submit</b></Button>}
                                {loading && <Spinner animation="border" variant="warning" />}
                            </Col>
                        </Row>
                    </Form>
                </div>
            }

            {
                (isRateHere && isAmendRate === 'Yes' && rateData?.corporateRate?.seasonalRate.length !== 0) && <Row className='mt-5'>
                    <div style={{ fontSize: '16px' }}>Do you wish to propose any further seasonal rates?
                        <span style={{ marginLeft: '2%' }}>
                            <Form.Check
                                inline
                                value={'Yes'}
                                label={'Yes'}
                                type="radio"
                                name='need_seasonal_rate'
                                onChange={(value) => {
                                    extraEnableSeasonalForm(value.target.value);
                                }}
                            />
                            <Form.Check
                                inline
                                value={'No'}
                                label={'No'}
                                type="radio"
                                name='need_seasonal_rate'
                                onChange={(value) => {
                                    extraEnableSeasonalForm(value.target.value);
                                    // backToProperty();
                                }}
                            />
                        </span>
                    </div>
                </Row>
            }
            {isRateHere && rateData?.corporateRate?.seasonalRate.length === 0
                && enableExtraSeasonalRateForm === 'Yes' && inventory?.status === 'review' && isAmendRate === 'Yes' && <RateSeasonalForm seasonalRate={''} inventory={inventory} callRfps={callRfps} isSeasonalHere={isSeasonalHere} />
            }

            {
                isRateHere && rateData?.corporateRate?.seasonalRate.length !== 0 && inventory?.status === 'review' && isAmendRate === 'Yes' && seasonalRateData

            }


            {
                isAmendRateComment === CommonAnswer.YES &&
                <div>
                    <Row>
                        <Col md={6}><div style={{ textAlign: 'left', marginTop: '15px' }}>

                            <Form.Control as="textarea" rows={5}
                                placeholder="please provide comment"
                                name='inventoryAmendedSecondRateComment'
                                onChange={(e) => {
                                    setInventoryAmendedSecondRateComment(e.target.value);
                                }}
                            />
                        </div></Col>
                        <Col md={1}></Col>
                    </Row>
                </div>
            }


            {((editEnableAmendRate && isAmendRate === CommonAnswer.YES && isAmendRateComment === CommonAnswer.YES)) &&
                <div>
                    <Row className='formSpace'>
                        <Col md={6} style={{ textAlign: 'left' }}>
                            <Button id='approve-button' style={formButton} onClick={() => { onSaveAmendSecondRateComment() }} ><b>Submit</b></Button>
                        </Col>
                        <Col md={6} style={{ textAlign: 'right' }}>
                        </Col>
                    </Row>
                </div>}
        </>
    )
}

export default SubmittedRfpRateForm;