import React from "react";
import { Container, Collapse, Spinner } from "react-bootstrap";
import CheckAndRadioBtn from "./CheckAndRadioBtn";

type TCancelBookingProps = {
    cancelBoxDisplay: boolean;
    loading: boolean;
    ActionNo: () => void;
    ActionYes: () => void;
};

type TCancelBookingState = {
    loading: boolean;
};

class CancelBookingWithoutCharge extends React.Component<TCancelBookingProps, TCancelBookingState> {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this)
    }

    /**
     * @description get changed value
     * @param e 
     */
    onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'no') {
            this.props.ActionNo();
        }

        if (e.target.value === 'yes') {
            this.props.ActionYes();
        }
    };

    render(): React.ReactNode {
        return (<>
            <Collapse in={this.props.cancelBoxDisplay} dimension="width">
                <>
                    {
                        this.props.cancelBoxDisplay && <Container fluid className="d-flex flex-row-reverse fixed-bottom">
                            <div className="jumbotron border border-danger rounded-5 font-weight-bold" >
                                <strong>
                                    Are you sure you wish to cancel this booking.?<br />
                                    This may result in a cancellation charge as per the<br />
                                    conditions of this booking.
                                </strong>
                                <div className="mt-3">
                                    <>
                                        {
                                            !this.props.loading ? <>
                                            <CheckAndRadioBtn type='radio' label="Yes" name="cancelCheckbox" value={`yes`} onChange={this.onChangeCheckBox} loading={this.state.loading} />
                                            <CheckAndRadioBtn type='radio' label="No" name="cancelCheckbox" value={`no`} onChange={this.onChangeCheckBox} loading={this.state.loading} />
                                            </> : <Spinner animation="border" variant="warning" />
                                        }
                                    </>

                                </div>
                            </div>
                        </Container>
                    }
                </>
            </Collapse>
        </>)
    }

};

export default CancelBookingWithoutCharge;