import React from "react";
import { Form } from "react-bootstrap";

type TTextAreaC = {
    name: string;
    register?: any;
    onKeyUp?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onChange
};

const TextAreaC: React.FunctionComponent<TTextAreaC> = ({ register, name, onKeyUp, onChange }) => {

    return (<>
        <Form.Control
            as="textarea" rows={5}
            placeholder="feedback"
            {...register && { ...register(`${name}`, { required: false }) }}
            onKeyUp={onKeyUp}
            onChange={onChange}
        />
    </>);
}

export default TextAreaC;