import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import Select from 'react-select';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getLoggedUserType, getUserAccess } from '../../../../../common/functions';
import { ProgrammeStatus, RfpProcessStatus, UserGroups } from '../../../../../common/constants';
import { clearStorage, getStorage, setStorage } from '../../../../../common/storage';
import _ from 'lodash';


const ClientFilterProgram: React.FC<any> = ({ programsSelectManageStatus, setFilterProgramme, searchType = '' }): JSX.Element => {
    ///////////////////////////////////// local state manage //////////////////////////////////////////
    const [clientOption, setClientOption] = useState([]);
    const [selectedClientOption, setSelectedClientOption] = useState<any>();
    const [programsOption, setProgramsOption] = useState([]);
    const [selectedProgramsOption, setSelectedProgramsOption] = useState<any>();
    const [programsSelectManage, setProgramsSelectManage] = useState(programsSelectManageStatus);
    const [rfpProcess, setRfpProcess] = useState<any>();

    ///////////////////////////////////// global action manage /////////////////////////////////////////
    const {getProgrammesByClientId, getProcessByProgrammeIdAndStatus, clientRfpOnly } = useStoreActions<any>((actions) => ({
        // getClientListFilterProgram: actions.client.getClientList,
        getProgrammesByClientId: actions.client.programmesByClientId,
        getProcessByProgrammeIdAndStatus: actions.rfpProcess.rfpProcessByProgrammeIdAndStatus,
        clientRfpOnly: actions.client.clientRfpOnly,
    }));

    /////////////////////////////////// global state manage ////////////////////////////////////////////
    const {programmesByClientIdSuccess, programmesByClientIdError, getClientRfpOnlySuccess,rfpProcessByProgramIdAndStatusSuccess } = useStoreState<any>((state) => ({
            // getClientListSuccessFilterProgram: state.client.getClientListSuccess,
            programmesByClientIdSuccess: state.client.programmesByClientIdSuccess,
            programmesByClientIdError: state.client.programmesByClientIdError,
            rfpProcessByProgramIdAndStatusSuccess: state.rfpProcess.rfpProcessByProgramIdAndStatusSuccess,
            // rfpProcessByProgramIdAndStatusError: state.rfpProcess.rfpProcessByProgramIdAndStatusError,
            getClientRfpOnlySuccess: state.client.getClientRfpOnlySuccess,

        }));


          //////////////////////////////////////// call actions /////////////////////////////////////////////
    useEffect(() => {
        clientRfpOnly();
    }, [clientRfpOnly]);

    // //////////////////////////////////////// call actions /////////////////////////////////////////////
    // useEffect(() => {
    //     getClientListFilterProgram();
    // }, [getClientListFilterProgram]);

    // ///////////////////////////////////////// use state //////////////////////////////////////////////
    // useEffect(() => {

    //     if (programsSelectManageStatus) {
    //         clearStorage('filterProgramClient');
    //         clearStorage('filterProgram');
    //         clearStorage('selectedRFPProcess');
    //     } else {
    //         setClientOption(getStorage('filterProgramClient'));
    //         setProgramsOption(getStorage('filterProgram'));
    //         setSelectedClientOption(getStorage('selectedFilterClient'));
    //         setSelectedProgramsOption(getStorage('selectedProgramsOption'));
    //     }

    //     if (getClientListSuccessFilterProgram) {

    //         let userData: any = [];
    //         const userClient = getUserAccess('client');
    //         const userClientIds = _.map(userClient, (a) => a.clientId);
    //         const mappedData = _.map(getClientListSuccessFilterProgram.data, (client) => {
    //             return {
    //                 key: client._id,
    //                 companyLogo: client.companyLogo,
    //                 companyName: client.companyName,
    //                 city: client.city,
    //                 country: client.country,
    //                 status: client.status,
    //                 clientAdmin: client.clientAdmin,
    //             }
    //         });
    //         let filteredData = _.filter(mappedData, (a) => userClientIds.includes(a.key));
    //         userData = getLoggedUserType() === UserGroups.super_admin ? mappedData : filteredData;

    //         let clients: any = [];
    //         userData.forEach((client: any) => {
    //             let data = { value: client.key, label: client.companyName };
    //             clients.push(data);
    //         });
    //         setClientOption(clients);
    //         setStorage('filterProgramClient', clients);

    //         if (getLoggedUserType() === UserGroups.user) {
    //             const payload = {
    //                 clientId: clients[0].value,
    //                 status: ProgrammeStatus.ACCEPTED
    //             }
    //             getProgrammesByClientId(payload);
    //             setStorage('selectedFilterClient', clients[0]);
    //             setProgramsSelectManage(false);
    //         }
    //     }

    //     if (programmesByClientIdSuccess) {
    //         let programmes: any = [];
    //         programmesByClientIdSuccess.data.forEach((program: any) => {
    //             let data = { value: program._id, label: program.programmeName };
    //             programmes.push(data);
    //         });
    //         setProgramsOption(programmes);
    //         setStorage('filterProgram', programmes);
    //         // setLoading(false);
    //     }

    //     if (programmesByClientIdError) {
    //         // setLoading(false);
    //     }


    // }, [getClientListSuccessFilterProgram, getProgrammesByClientId, programmesByClientIdError, programmesByClientIdSuccess, programsSelectManageStatus]);




    useEffect(() => {

        if (programsSelectManageStatus) {
            clearStorage('filterProgramClient');
            clearStorage('filterProgram');
            clearStorage('selectedRFPProcess');
        } else {
            setClientOption(getStorage('filterProgramClient'));
            setProgramsOption(getStorage('filterProgram'));
            setRfpProcess(getStorage('selectedRFPProcess'));
            setSelectedClientOption(getStorage('selectedFilterClient'));
            setSelectedProgramsOption(getStorage('selectedProgramsOption'));
        }

        if (getClientRfpOnlySuccess) {
            let userData: any = [];
            const userClient = getUserAccess('client');
            const userClientIds = _.map(userClient, (a) => a.clientId);
            const mappedData = _.map(getClientRfpOnlySuccess.data, (client) => {
                return {
                    key: client._id,
                    companyLogo: client.companyLogo,
                    companyName: client.companyName,
                    city: client.city,
                    country: client.country,
                    status: client.status,
                    clientAdmin: client.clientAdmin,
                }
            });

            let filteredData = _.filter(mappedData, (a) => userClientIds.includes(a.key));
            userData = getLoggedUserType() === UserGroups.super_admin ? mappedData : filteredData;

            let clients: any = [];
            userData.forEach((client: any) => {
                let data = { value: client.key, label: client.companyName };
                clients.push(data);
            });
            setClientOption(clients);
            setStorage('filterProgramClient', clients);

            if (getLoggedUserType() === UserGroups.user) {
                const payload = {
                    clientId: clients[0]?.value,
                    status: ProgrammeStatus.ACCEPTED
                }
                getProgrammesByClientId(payload);
                setStorage('selectedFilterClient', clients[0]);
                setProgramsSelectManage(false);
            }
        }

        if (programmesByClientIdSuccess) {
            let programmes: any = [];
            programmesByClientIdSuccess.data.forEach((program: any) => {
                let data = { value: program._id, label: program.programmeName };
                programmes.push(data);
            });
            setProgramsOption(programmes);
            setStorage('filterProgram', programmes);
            // setLoading(false);
        }

        if (programmesByClientIdError) {
            // setLoading(false);
        }

        if (rfpProcessByProgramIdAndStatusSuccess) {
            if (rfpProcessByProgramIdAndStatusSuccess.data.length !== 0) {
                setRfpProcess(rfpProcessByProgramIdAndStatusSuccess.data[0]);
                setStorage('selectedRFPProcess', rfpProcessByProgramIdAndStatusSuccess.data[0]);
                setFilterProgramme(true);
            } else {
                setFilterProgramme(true);

            }
            // setLoading(false);
            // setFilterProgramManage(true); // demo purpose
        }

        // if (rfpProcessByProgramIdAndStatusError) {
        //     // setLoading(false);
        // }

    }, [getClientRfpOnlySuccess, getProgrammesByClientId, programmesByClientIdError, programmesByClientIdSuccess, programsSelectManageStatus, rfpProcessByProgramIdAndStatusSuccess, setFilterProgramme]);


    ////////////////////////////////////////// select client id and call programs/////////////////////////////////////////
    const changeClient = (selectedClient) => {
        setProgramsOption([]);
        clearStorage('filterProgram');
        clearStorage('selectedRFPProcess');
        const payload = {
            clientId: selectedClient.value,
            status: ProgrammeStatus.ACCEPTED
        }
        getProgrammesByClientId(payload);
        setStorage('selectedFilterClient', selectedClient);
        setSelectedProgramsOption('');
        setStorage('selectedProgramsOption', '');
        setProgramsSelectManage(false);
    }

    // ////////////////////////////////////////// select program id and call rfp process /////////////////////////////////////////
    // const changePrograms = (selectedProgram) => {
    //     setStorage('selectedProgramsOption', selectedProgram);
    //     if (selectedProgram) {
    //         setFilterProgramme(true);
    //     }


    // }

    useEffect(()=>{
        if(getStorage('programmeId')){
            const payload = {
                programmeId: getStorage('programmeId'),
                RFPStatus: RfpProcessStatus.INPROGRESS,
                UserType : getLoggedUserType() === UserGroups.super_admin ? UserGroups.super_admin : UserGroups.corporate_admin
            }
            
            getProcessByProgrammeIdAndStatus(payload);
        }
    },[getProcessByProgrammeIdAndStatus])

    const changePrograms = (selectedProgram) => {
        const payload = {
            programmeId: selectedProgram.value,
            RFPStatus: !searchType ? RfpProcessStatus.INPROGRESS : '',
            UserType: getLoggedUserType() === UserGroups.super_admin ? UserGroups.super_admin : searchType ?  UserGroups.super_admin : UserGroups.corporate_admin,
            searchType : searchType
        }
        setStorage('selectedProgramsOption', selectedProgram);
        getProcessByProgrammeIdAndStatus(payload);
        // setLoading(true);
    }

    return (<>
        <div className="site-card-border-less-wrapper mb-10">
            <Card bordered={true} >
                {/* <Row>
                    <p>
                        Corporate Programme Management
                    </p>
                </Row> */}
                {
                    getLoggedUserType() === UserGroups.super_admin ?
                        <Row>
                            <Col span={6}>
                                <Select placeholder="Select client" value={selectedClientOption} options={clientOption} onChange={changeClient} />
                            </Col>
                            <Col span={1}></Col>
                            <Col span={6}>
                                <Select placeholder="Select programme" value={selectedProgramsOption} options={programsOption} onChange={changePrograms} isDisabled={programsSelectManage} />
                            </Col>
                            <Col span={1}></Col>


                        </Row>
                        :
                        <Row>
                            <Col span={6}>
                                <Select placeholder="Select programme" value={selectedProgramsOption} options={programsOption} onChange={changePrograms} isDisabled={programsSelectManage} />
                            </Col>
                            <Col span={1}></Col>

                        </Row>
                }
            </Card>
        </div>
        {/* {
            loading && <Spin size="large" style={{ marginTop: '10%', marginLeft: '50%' }} />
        } */}
    </>);
}

export default ClientFilterProgram;
