
import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs, } from "react-google-maps";
// import markerIcon from '../../images/icons/marker.png';
import markerIcon from '../../images/icons/svg/outside/Icon-Location-Finder-M.svg';
import propertyDirectIcon from '../../images/icons/svg/outside/Icon-Location-Finder-K.svg';

const OperatorGoogleMap: React.FC<any> = ({ location, }): JSX.Element => {
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [activeMarker, setActiveMarker] = useState(null);
  const [brandName, setBrandName] = useState(null);

  const icon = {
    url: markerIcon,
    scaledSize: new google.maps.Size(45, 45)
  };


  const buildingDirectIcon = {
    url: propertyDirectIcon,
    scaledSize: new google.maps.Size(45, 45)
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (location) {
      setLat(location?.lat);
      setLng(location?.lng);
      setBrandName(location?.brandName);
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }

  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onDragEnd = useCallback(({ latLng }) => {
    setLat(latLng.lat());
    setLng(latLng.lng());

  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="google-map">
      <GoogleMap
        key={Math.random()}
        defaultZoom={13}
        defaultCenter={{ lat, lng }}
        onClick={() => setActiveMarker(null)}
      >


        <Marker
          draggable onDragEnd={onDragEnd} position={{ lat, lng }} icon={icon}
        >

          <InfoWindow onCloseClick={() => setActiveMarker(null)}>
            <div>
              <p>{brandName}</p>
              {/* <p>{address}</p> */}
            </div>

          </InfoWindow>

        </Marker>


      </GoogleMap>
    </div>
  )
};

export default withScriptjs(withGoogleMap(OperatorGoogleMap));