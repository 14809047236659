import React, { useEffect, useMemo, useState } from 'react';

import DataTable from 'react-data-table-component';
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import { tableStyles } from '../../../common/components-style';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getStorage } from '../../../common/storage';


const LocationOverViewList: React.FC<any> = (): JSX.Element => {


    const [activeRfp, setActiveRfp] = useState(false);

    const [myRfpProcessesList, setMyRfpProcessesList] = useState<any>([]);
    const clientId = getStorage('programmeData')?.clientId?._id;
    const programmeId = getStorage('programmeData')?._id;

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getLocationOverViewList
    } = useStoreActions<any>((actions) => ({
        getLocationOverViewList: actions.rfpProcess.getLocationOverViewList

    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getLocationOverViewListSuccess
    } = useStoreState<any>((state) => ({

        getLocationOverViewListSuccess: state.rfpProcess.getLocationOverViewListSuccess
    }));


    ////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const params: any = {};
        params.clientId = clientId;
        params.programmeId = programmeId;

        getLocationOverViewList(params);
    }, [clientId, getLocationOverViewList, programmeId]);

    ////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (getLocationOverViewListSuccess) {
            setMyRfpProcessesList(getLocationOverViewListSuccess?.data[0].location)
        }

    }, [getLocationOverViewListSuccess]);

    const rfpHandler = () => {
        setActiveRfp(!activeRfp);
    }


    const data = useMemo(() => {
        return myRfpProcessesList?.map((rfpData) => {

            return {
                key: rfpData?.cityName,
                propertyCount: rfpData?.propertiesLength,
                accepted: rfpData?.acceptedLength,
                declined: rfpData?.declinedLength,
                negotiate: rfpData?.negotiateLength,
                countryName: rfpData?.countryName,
                cityName: rfpData?.cityName
            }
        });

    }, [myRfpProcessesList]);

    const columns: any = [

        {
            name: 'Country',
            cell: (row) => {
                return (
                    <span style={{ fontWeight: 'bold' }} className='text-capitalize'>{row.countryName}</span>)
            }

        },


        {
            name: 'City',
            selector: row => {
                return (
                    <span style={{ fontWeight: 'bold' }} className='text-capitalize'>{row.cityName}</span>)
            }
        },
        {
            name: 'Properties',
            selector: row => {
                return (
                    <span style={{ fontWeight: 'bold' }} className='text-capitalize'>{row.propertyCount}</span>)
            }

        },
        {
            name: 'Accepted',
            selector: row => {
                return (
                    <span style={{ fontWeight: 'bold' }} className='text-capitalize'>{row.accepted}</span>)
            }
        }, {
            name: 'Negotiate',
            selector: row => {
                return (
                    <span style={{ fontWeight: 'bold' }} className='text-capitalize'>{row.negotiate}</span>)
            }
        },
        {
            name: 'Declined',
            selector: row => {
                return (
                    <span style={{ fontWeight: 'bold' }} className='text-capitalize'>{row.declined}</span>)
            }
        },
    ];
    return (
        <>
            <div className='tbl_accr_itm' style={{ backgroundColor: "#52d1c2" }}>
                <div className='tbl_hdr' style={{ backgroundColor: "#52d1c2" }}>
                    <h5 className='m-0 fw-bold text-white'>Location Overview</h5>
                    <button className='text-white pointer' onClick={rfpHandler}>Expand
                        <span>
                            <img src={activeRfp ? minus : plus} alt="icon" />
                        </span>
                    </button>
                </div>
                <div className='acrd_tbl'>
                    <DataTable
                        columns={columns}
                        data={data}
                        //   striped={true}
                        customStyles={tableStyles}
                        theme='mysaTable'
                        fixedHeader={true}
                        noDataComponent={'Updating records'}
                    />
                </div>
            </div>
        </>
    )
}

export default LocationOverViewList;