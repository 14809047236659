import React, { useState } from "react";
import { Col, Row, Tab, Nav } from "react-bootstrap";
import { navStyle, tabDivStyle } from "../../../../../../common/components-style";
import BookingSIngleView from "./sub-components/BookingSIngleView";
import BookingConfirmationPage from "./sub-components/BookingConfirmationPage";


const BookingCorporateView: React.FunctionComponent = () => {

    //filter program
    const [tabKey, setTabKey] = useState<any>('bookingDetails');


    return (<>
        <div style={tabDivStyle}>

            <Tab.Container defaultActiveKey="bookingDetails" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                <Row >
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                            <div style={{ marginTop: '0%' }}>
                                <Nav.Item>
                                    <Nav.Link eventKey="bookingDetails">Booking Detail</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="confirmation">Confirmation</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="invoice">Invoice</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="messages">Messages</Nav.Link>
                                </Nav.Item>
                            </div>
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content >
                            <Tab.Pane eventKey="bookingDetails" >

                                <BookingSIngleView />
                            </Tab.Pane>
                            <Tab.Pane eventKey="confirmation" >
                            <BookingConfirmationPage/>

                            </Tab.Pane>
                            <Tab.Pane eventKey="invoice" >


                            </Tab.Pane>
                            <Tab.Pane eventKey="messages" >


                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>

    </>);
}

export default BookingCorporateView;