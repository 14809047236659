import React, { useEffect, useState } from 'react';
import QUserDefinedListComp from './MYOQUserDefinedListComp';

const MYOQUserDefinedList: React.FC<any> = ({ data, evidence, onAnswer, signUpStatus, completed }): JSX.Element => {
  const [options, setOptions] = useState<any>(null);
  const [answer, setAnswer] = useState<any>(null);

  useEffect(() => {
    setOptions(data.options);
    setAnswer(data.answer?.answer);
  }, [data.answer?.answer, data.options]);

  return (
    <div className="inline-block  m-5 w-full pr-10">
      <div className="bg-white p-5 flex flex-col rounded-lg">
        <p className="text-lg mb-3">{data?.description}</p>
        <QUserDefinedListComp options={options} onAnswer={onAnswer} data={data} answer={answer} signUpStatus={signUpStatus} completed={completed} />
      </div>
    </div>
  )
};

export default MYOQUserDefinedList;