import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser'; 

// const socket = io(process.env.REACT_APP_API_URL?? '');

const NotificationList: React.FC<any> = (): JSX.Element => {
    const [notification, setNotification] = useState<any>([]);
    // const [socketNotification, setSocketNotification] = useState<any>();
    const { brandDashboardSuccess, brandDashboardError } = useStoreState<any>((state) => ({
        brandDashboardSuccess: state.DashboardModel.brandDashboardSuccess,
        brandDashboardError: state.DashboardModel.brandDashboardError
    }));

    useEffect(() => {
        if (brandDashboardError) {
            toast.error(brandDashboardError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (brandDashboardSuccess) {
            setNotification(brandDashboardSuccess?.data?.notification)
        }
    }, [brandDashboardError, brandDashboardSuccess]);

    // useEffect(() => {
    //     socket.on("receive_message", (data) => {
    //         if(data !== undefined)
    //         setSocketNotification(data); 
    //       });

    //     return () => {
    //       socket.off("socket_id");
    //       socket.off("receive_message");
    //     };
    // }, []);


    // return (
    //     <>
    //    <ul className='ntf_list m-0'>
    //     {socketNotification ? (
    //           <>
    //          <li className='ntf_list_item'>
    //          <div>
    //          {ReactHtmlParser (socketNotification)}
    //          </div>
    //          <div>
    //          { moment().format('DD-MMM-YYYY') }
    //          </div>
    //       </li>
    //       </>
    // 					) : (
    // 						<></>
    //         )}
    //        {
    //         notification.map((notification,index) => {
    //         return <li key={index} className='ntf_list_item'>
    //         <div>
    //         {ReactHtmlParser (notification.text)}
    //         </div>
    //         <div>
    //         {notification.createdAt ? moment(notification.createdAt).format('DD-MMM-YYYY') : moment().format('DD-MMM-YYYY')}
    //         </div>
    //        </li>
    //          })}
    //     </ul>  
    //     </>

    // )

    // const columns = [
    //     {
    //         name: 'Notification',
    //         maxWidth: '1000px' ,
    //         cell: (row) => {


    //             return (
    //                 <div dangerouslySetInnerHTML={{ __html: row?.text }}></div>
    //             )
    //         }
    //     },
    //     {
    //         name: 'Created',
    //         maxWidth: '250px' ,
    //         selector: row => moment(row.createdAt).format('D MMM YYYY'),
    //     },
    // ];

    // return (
    //     <>
    //         <div className='tbl_accr_itm' style={{ backgroundColor: "#52d1c2" }}>
    //             <div className='tbl_hdr' style={{ backgroundColor: "#52d1c2" }}>
    //                 <h5 className='m-0 fw-bold text-white'>Notifications</h5>
    //                 <button className='text-white pointer' onClick={ntfHandler}>Expand
    //                     <span>
    //                         <img src={notification ? minus : plus} alt="icon" />
    //                     </span>
    //                 </button>
    //             </div>
    //             <div className='acrd_tbl'>
    //                 <DataTable
    //                     columns={columns}
    //                     data={notifications}
    //                     striped={true}
    //                     customStyles={tableStyles}
    //                     theme='mysaTable'
    //                     fixedHeader={true}
    //                     noDataComponent={'Updating records'}
    //                 />
    //             </div>
    //         </div>
    //     </>
    // )


    return (
        <>
       <ul className='ntf_list m-0'>
           {
            notification.map((notification,index) => {
            return <li key={index} className='ntf_list_item'>
            <div>
            {ReactHtmlParser (notification.text)}
            </div>
            <div>
            {notification.updatedAt ? moment(notification.updatedAt).format('DD-MMM-YYYY') : moment().format('DD-MMM-YYYY')}
            </div>
           </li>
             })}
        </ul>  
        </>

    )
}

export default NotificationList;