import { message } from 'antd';
import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { getStorage } from '../common/storage';
import Header from '../components/admin/layout/Header';
import SideMenu from '../components/admin/layout/SideMenu';


const ProtectedRoutes: React.FC<any> = ({ component: Component, ...rest }): JSX.Element => {
  const { replace } = useHistory();
  if (!getStorage('myo_logged_user')) {
    message.warn("Session expired!")
    replace('/');
  }


  return (
    <div className="App">
      <Header />
      <div style={{ marginTop: '80px' }}>
        <SideMenu />
        <div className="p-10 ml-20">
          <Route {...rest} render={(props) => (<Component {...props} />)} key={Math.random()} />
        </div>
      </div>
    </div>
  )
};

export default ProtectedRoutes;
