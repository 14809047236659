import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { Button, message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import { canCorporateProgrammeModuleWrite, capitalizeRole, getLoggedUserType } from '../../../../../common/functions';
import ViewEdit from '../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { UserAccess, UserGroups, UserProfile } from '../../../../../common/constants';

import { $mysaMegenta, $mysaTeal, $mysaYellow, overlayTriggerDelay, resendButton, routingButton, tableUserStyles } from '../../../../../common/components-style';
import DataTable from 'react-data-table-component';
import { Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import BookerUserForm from './BookerUserForm';
import BookerUserView from './BookerUserView';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

const BookerUsersList: React.FC<any> = ({ clientId, programmeId, selectedClient, selectedProgramme, setFormOpen, refreshProgrammes, setSelectedProgramme }): JSX.Element => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showView, setShowView] = useState<boolean>(false);
    const [showButton, setShowButton] = useState<boolean>(false);
    const [companyPermissions, setCompanyPermissions] = useState(null);
    const [corporateProgrammes, setCorporateProgrammes] = useState<any>([]);
    const [clientIds, setClientIds] = useState<any>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const [programmerUserDomains, setProgrammerUserDomains] = useState<Array<string>>([]);
    const [finalModal, setFinalModal] = useState<boolean>(false);
    const [errorRecord, setErrorRecord] = useState<Array<any>>([]);
    const [userData, setUserData] = useState<any>([]);
    const [bookerImport, setBookerImport] = useState<boolean>(false);

    const { onResendPassword, getUserMapping, resetAuth, getCorporateProgrammeById, getCompanyPermissionByProgrammeId,
        createBookerInformation,getBookerInformation,createBookerUser,createBookerUserMapping,updateBookerUserMapping,
        resetUser,sendBookerAdminWelcomeEmail
     } = useStoreActions<any>((actions) => ({
        resetAuth: actions.auth.resetAuth,
        onResendPassword: actions.auth.onResendPassword,
        getUserMapping: actions.user.getUserMapping,
        getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
        getCompanyPermissionByProgrammeId: actions.client.getCompanyPermissionByProgrammeId,
        createBookerInformation: actions.client.createBookerInformation,
        getBookerInformation: actions.client.getBookerInformation,
        createBookerUser: actions.user.createUser,
        createBookerUserMapping: actions.user.createUserMapping,
        updateBookerUserMapping: actions.user.updateUserMapping,
        resetUser: actions.user.resetUser,
        sendBookerAdminWelcomeEmail: actions.client.sendBookerAdminWelcomeEmail
    }));

    const { resendPassword, resendPasswordError, getUserMappingSuccess, getCorporateProgrammeByIdSuccess, getCompanyPermissionByProgrammeIdSuccess,
         getCompanyPermissionByProgrammeIdError,createBookerInformationSuccess,getBookerInformationSuccess,bookerUserCreate,userCreateError,createUserMappingSuccess
         ,updateUserMappingSuccess  } = useStoreState<any>((state) => ({
        resendPassword: state.auth.resendPassword,
        resendPasswordError: state.auth.resendPasswordError,
        getUserMappingSuccess: state.user.getUserMappingSuccess,
        getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
        getCompanyPermissionByProgrammeIdSuccess: state.client.getCompanyPermissionByProgrammeIdSuccess,
        getCompanyPermissionByProgrammeIdError: state.client.getCompanyPermissionByProgrammeIdError,

        createBookerInformationSuccess: state.client.createBookerInformationSuccess,

        getBookerInformationSuccess: state.client.getBookerInformationSuccess,
        bookerUserCreate: state.user.userCreate,
        userCreateError: state.user.userCreateError,
        createUserMappingSuccess: state.user.createUserMappingSuccess,
        updateUserMappingSuccess: state.user.updateUserMappingSuccess
    }));


    
    const callApi = useCallback((user) => {

        if (user) {

            let newData = user.map(obj => {
                let newObj = {};
                for (let key in obj) {
                    let newKey = key.replace(/\s+/g, '');
                    newObj[newKey] = obj[key];
                }
                return newObj;
            });
            const payload = {
                clientId: clientId,
                programmeId: programmeId,
                users: newData
            }
            setTimeout(() => createBookerInformation(payload), 1000)
        }

    }, [clientId, createBookerInformation, programmeId])



    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            const binaryStr = reader.result;

            if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                callApi(json);
            } else if (file.name.endsWith('.csv')) {
                Papa.parse(binaryStr, {
                    header: true,
                    complete: (results) => {

                        callApi(results.data);
                    }
                });

            } else {
                console.error('Unsupported file format');
            }


        };
        reader.readAsBinaryString(file);

    }, [callApi]);

    const { open } = useDropzone({ onDrop });

     ///////////////////////////////////////////////////////////////////////////////////////////////////////
     useEffect(() => {
      
        if (clientId) {
            getBookerInformation(clientId)
        }

    }, [clientId, getBookerInformation, getCorporateProgrammeById, programmeId]);

    useEffect(() => {
        if (getCorporateProgrammeByIdSuccess) {
            setProgrammerUserDomains(getCorporateProgrammeByIdSuccess.data?.bookerDomains);
        }
        if (getBookerInformationSuccess) {
            const data = getBookerInformationSuccess.data;
            const filterData = _.filter(data?.programme, (a) => a.programmeId === programmeId);
            setUserData(filterData[0]?.users);
        }
    }, [getBookerInformationSuccess, getCorporateProgrammeByIdSuccess, programmeId]);


    useEffect(() => {
        if (createBookerInformationSuccess) {
            if (createBookerInformationSuccess?.data) {
                if (clientId) {
                    getBookerInformation(clientId)
                }
                setOpenModal(false);
                setUploadModal(true);
            }
        }

    }, [createBookerInformationSuccess, getBookerInformation, clientId]);

    const reload = useCallback(() => {
        setTimeout(() => getUserMapping({ clientId: clientId }), 1000);
        setShowForm(false);
    }, [getUserMapping, clientId])


    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        // user create success
        if (bookerImport) {
            if (bookerUserCreate) {
                if (bookerUserCreate.data.data) {

                    // create mapping
                    createBookerUserMapping({
                        userId: bookerUserCreate.data.user._id,
                        email: bookerUserCreate.data.user.email,
                        clientId: clientId,
                        programmeId: programmeId,
                        role: UserGroups.booker,
                    })

                    const payload = {
                        firstName: bookerUserCreate.data.user?.firstName,
                        lastName: bookerUserCreate.data.user?.lastName,
                        programmeName: selectedProgramme?.programmeName,
                        clientName: selectedClient?.companyName,
                        email: bookerUserCreate.data.user.email
                    }
                    sendBookerAdminWelcomeEmail(payload);
                } else {
                    //update mapping
                    updateBookerUserMapping({
                        userId: bookerUserCreate.data.user._id,
                        client: {
                            clientId: clientId,
                        },
                        corporateProgramme: {
                            programmeId: programmeId,
                            role: UserGroups.booker,
                        }

                    })

                    const payload = {
                        firstName: bookerUserCreate.data.user?.firstName,
                        lastName: bookerUserCreate.data.user?.lastName,
                        clientName: selectedClient?.companyName,
                        programmeName: selectedProgramme?.programmeName,
                        email: bookerUserCreate.data.user.email
                    }
                    sendBookerAdminWelcomeEmail(payload);
                }

                resetUser();
                // setLoading(false);
                reload();
            }
        }

        // update user mapping success 
        if (updateUserMappingSuccess || createUserMappingSuccess) {

            resetUser();
        }

        // user create error
        if (userCreateError) {
            toast.error(userCreateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetUser();
            // setLoading(false);
        }
    }, [bookerImport, bookerUserCreate, clientId, createBookerUserMapping, createUserMappingSuccess, programmeId, reload, resetUser, selectedClient?.companyName, selectedProgramme?.programmeName, sendBookerAdminWelcomeEmail, updateBookerUserMapping, updateUserMappingSuccess, userCreateError]);


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const insertMultipleRecords = useCallback(async (records) => {

        for (let i = 0; i < records.length; i++) {
            await createBookerUser(records[i]);
        }
        setUploadModal(false);
        setFinalModal(true);

    }, [createBookerUser]);

    ////////////////////////////////////////////////////////////////
    const handleInsertRecords = useCallback(async (userList) => {
        const records: any = [];
        for (let i = 0; i < userList.length; i++) {
            records.push({ email: userList[i].email, firstName: userList[i].firstName, lastName: userList[i].lastName, userGroup: UserGroups.user });
        }

        await insertMultipleRecords(records);
    }, [insertMultipleRecords]);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    const startUpload = useCallback(() => {
        // setStorage('bookerImport', true);
        setBookerImport(true);
        const userDetails: any = [];
        const errorData: any = [];
        if (userData) {

            userData.forEach(record => {
                const emailDomain = record.email.split('@')[1];
                // console.log(domain)
                // let emailDomain = record?.email.substring(record?.email.indexOf('@') + 1);
                const isDomainMatch = programmerUserDomains.includes(emailDomain);
                const isNameEmpty = !record.firstName || !record.lastName;

                if (isDomainMatch && !isNameEmpty) {
                    userDetails.push(record);
                } else {
                    errorData.push(record);
                }
            });

        }

        handleInsertRecords(userDetails);
        setErrorRecord(errorData);
    }, [handleInsertRecords, programmerUserDomains, userData]);

    useEffect(() => {
        setClientIds(clientId?._id ? clientId?._id : clientId);
        if (clientId?._id ? clientId?._id : clientId) {
            const id = clientId?._id ? clientId?._id : clientId;
            getUserMapping({ clientId: id });
        }
        if (programmeId) {
            getCorporateProgrammeById(programmeId);
        }
    }, [clientId, getCorporateProgrammeById, getUserMapping, programmeId]);


    useEffect(() => {
        if (selectedClient?.key) {
            const params: any = {};
            params.clientId = selectedClient?.key;
            params.programmeId = programmeId
            getCompanyPermissionByProgrammeId(params);
        }
    }, [getCompanyPermissionByProgrammeId, getCorporateProgrammeById, programmeId, selectedClient?.key]);


    useEffect(() => {
        if (getCompanyPermissionByProgrammeIdSuccess) {
            setCompanyPermissions(getCompanyPermissionByProgrammeIdSuccess?.data);
            if (getCompanyPermissionByProgrammeIdSuccess?.data?.bookerPermissions)
                setShowButton(true);
        }
        if (getCompanyPermissionByProgrammeIdError) {
            toast.error(getCompanyPermissionByProgrammeIdError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

        }
    }, [getCompanyPermissionByProgrammeIdSuccess, getCompanyPermissionByProgrammeIdError]);

    useEffect(() => {
        if (resendPassword) {
            message.success('password resend successful, Please check the email');
            resetAuth();
        }

        if (resendPasswordError) {
            message.error(resendPasswordError.message);
            resetAuth();
        }

        if (getCorporateProgrammeByIdSuccess) {
            const programmeListTemp: Array<Object> = [];
            programmeListTemp.push(getCorporateProgrammeByIdSuccess.data);
            setCorporateProgrammes(programmeListTemp);
        }

    }, [resendPassword, resetAuth, resendPasswordError, getCorporateProgrammeByIdSuccess]);



    const data = useMemo(() => {
        if (getUserMappingSuccess) {
            if (getUserMappingSuccess.data) {
                const { result, poolUsers } = getUserMappingSuccess.data;
                // setUserMappingData(result);

                const mappedData = _.map(result, (val) => {
                    const corporateProgramme = _.filter(val.clients, (a) => a.clientId === clientIds).shift()?.corporateProgramme;
                    const userStatus = poolUsers[val?.user?.email]?.UserStatus
                    return {
                        key: val?.user?._id,
                        userId: val?.user?._id,
                        firstName: val?.user?.firstName,
                        lastName: val?.user?.lastName,
                        email: val?.user?.email,
                        phone: val?.user?.phone,
                        mobile: val?.user?.mobile,
                        position: val?.user?.position,
                        userProfile: val?.user?.userProfile,
                        userStatus: userStatus,
                        data: val,
                        corporateProgramme

                    }
                });

                let filterData: any = [];
                filterData = _.map(mappedData, (val) => {
                    const arrId = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?._id || ''
                    const access = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.access || ''
                    const role = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.role || ''
                    const isFirstUser = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.isFirstUser
                    const corporateProgrammeId = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.programmeId

                    return {
                        key: val.key,
                        userId: val.userId,
                        firstName: val.firstName,
                        lastName: val.lastName,
                        email: val.email,
                        phone: val.phone,
                        mobile: val.mobile,
                        position: val.position,
                        userProfile: val.userProfile,
                        userStatus: val.userStatus,
                        data: val.data,
                        role,
                        arrId,
                        access,
                        isFirstUser,
                        corporateProgramme: val.corporateProgramme,
                        corporateProgrammeId,
                        userType: capitalizeRole(role),

                    }
                })

                filterData = _.filter(filterData, (a) => a.corporateProgrammeId === programmeId && a.role === UserGroups.booker);
                return filterData;
            }
        }
        return []
    }, [clientIds, getUserMappingSuccess, programmeId])

    const columns = [

        { name: 'First name', selector: row => row.firstName, maxWidth: '110px' },
        { name: 'Last name', selector: row => row.lastName, maxWidth: '110px' },
        { name: 'Email', selector: row => row.email, minWidth: '110px' },
        { name: 'User type', selector: row => row.userType, minWidth: '110px' },
        {
            name: 'Status',
            // minWidth: '230px',
            cell: (row) => {
                let color = "";
                if (row.userStatus !== 'CONFIRMED') color = $mysaYellow;
                if (row.userStatus === 'CONFIRMED') color = $mysaTeal;
                if (row.userStatus === 'FORCE_CHANGE_PASSWORD') color = $mysaMegenta;

                return (

                    <span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>
                        {row.userStatus} {row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => onResendPassword({ email: row.email })} className="ml-2 mt-1" >Resend</Button>}
                    </span>
                )
            }
        },
        {
            name: 'User profile',

            cell: (row) => {
                let color = "";
                if (row.userProfile !== UserProfile.COMPLETED) color = $mysaMegenta;
                if (row.userProfile === UserProfile.COMPLETED) color = $mysaTeal;

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.userProfile}</span>
                );

            }
        },

        {
            name: 'Action',
            // button: true,
            cell: (row) => (
                <>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
                    >
                        <img

                            className='iconSizes' src={ViewEdit} alt="" onClick={() => { setSelectedUser(row); setShowView(true) }} />
                    </OverlayTrigger>
                </>
            )

        },
    ];

    const columns1 = [
        { name: 'First name', selector: row => row.firstName, },
        { name: 'Last name', selector: row => row.lastName, },
        { name: 'Email', selector: row => row.email, },


    ];
    return (
        <>
        
        <div>
            {/* <div className="ml-auto " style={{ position: "fixed", top: "110px", right: "22px", width: "100%", maxWidth: "80%", padding: "0 15px 0 24px", zIndex: 9999, backgroundColor: "#fff" }}>
                <Row>
                    <Col xs={9}>	{selectedClient && <Breadcrumb className="mb-10">
                        <Breadcrumb.Item>
                            <Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName} </Link>
                        </Breadcrumb.Item>
                        {selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
                    </Breadcrumb>} </Col>
                    <Col xs={3} className="text-right" >
                        <div className="text-right" style={{ position: "fixed", zIndex: 9999, top: "110px", right: "22px", width: "100%", maxWidth: "80%", padding: "0 15px" }}>
                            {
                                ((showButton || (getLoggedUserType() === UserGroups.programme_admin) || canCorporateProgrammeModuleWrite(clientId, programmeId, UserAccess.programme_access.companyBooker.code)) && corporateProgrammes[0]?.isActivated === true) ? <Button hidden={showForm || showView} onClick={() => { setSelectedUser(null); setShowForm(true) }} id='routing-button' style={routingButton} className='float-right mb-3'><b>New Booker</b></Button>
                                    : corporateProgrammes[0]?.isActivated === false ? <div style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold', color: '#fAC90E', marginLeft: '-100px' }} >The programme must be activated before users can be created  </div>
                                        : !showForm && <div style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold', color: '#fAC90E', marginLeft: '-100px' }} >You don't have permission to create the booker </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div> */}
            {/* <div style={{ marginTop: "60px" }}>
                {showForm && <BookerUserForm selectedUser={selectedUser} clientId={clientId} programmeId={programmeId} close={() => setShowForm(false)} reload={reload} companyPermissions={companyPermissions} />}
                {showView && <BookerUserView selectedUser={selectedUser} close={() => setShowView(false)} reload={reload} />}
                {showForm || showView || <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    striped={true}
                    customStyles={tableUserStyles}
                    theme='mysaTable'
                    fixedHeader={true}
                    noDataComponent={'Updating records'}
                />}
            </div> */}


            <div style={{ marginTop: "-2%", paddingTop: "4px" }}>
                <Row>

                    <Col xs={9}></Col>
                    <Col xs={3} className="ml-auto edit_btn_wpr">
                        <div className="text-right" >
                            {
                                ((showButton || (getLoggedUserType() === UserGroups.programme_admin) || canCorporateProgrammeModuleWrite(clientId, programmeId, UserAccess.programme_access.companyBooker.code)) && corporateProgrammes[0]?.isActivated === true) ? <Button hidden={showForm || showView} onClick={() => { setSelectedUser(null); setShowForm(true) }} id='routing-button' style={routingButton} className='float-right mb-3'><b>New Booker</b></Button>
                                    : corporateProgrammes[0]?.isActivated === false ? <div style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold', color: '#fAC90E', marginLeft: '-100px' }} >The programme must be activated before users can be created  </div>
                                        : !showForm && <div style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold', color: '#fAC90E', marginLeft: '-100px' }} >You don't have permission to create the booker </div>
                            }
                            {<Button hidden={!showForm} id="import-button"
                                style={{ marginRight: '175px', }}
                                className="import" onClick={() => setOpenModal(true)}>Import</Button>}
                        </div>
                    </Col>
                </Row>

                <div style={{ marginTop: "60px" }}>
                    {showForm && <BookerUserForm selectedUser={selectedUser} clientId={clientId} programmeId={programmeId} close={() => setShowForm(false)} reload={reload} companyPermissions={companyPermissions} />}
                    {showView && <BookerUserView selectedUser={selectedUser} close={() => setShowView(false)} reload={reload} />}
                    {showForm || showView || <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        striped={true}
                        customStyles={tableUserStyles}
                        theme='mysaTable'
                        fixedHeader={true}
                        noDataComponent={'Updating records'}
                    />}
                </div>

            </div>

        </div>
        <Modal
                show={openModal}
                onHide={() => { setOpenModal(false); }}
                width={750}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ textAlign: 'center' }}>
                        <h3>
                            Import file from computer
                        </h3>
                        <div>
                            <p>
                                Upload any CSV,XLS or XLSX files with first name, last name and email address.Email addresses must match the booker domains
                                registered in 'Programme Details'
                            </p>
                        </div>
                        <Col >

                            <Button style={routingButton} id='routing-button' onClick={open}><b>Upload file</b></Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>


            <Modal
                show={uploadModal}
                onHide={() => { setUploadModal(false); }}
                width={750}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ textAlign: 'center' }}>
                        <h3 >
                            Start Upload
                        </h3>
                        <div>
                            <p>
                                We will notify you when the upload is complete
                            </p>
                        </div>
                        <Col >

                            <Button style={routingButton} id='routing-button' onClick={() => { startUpload(); }} ><b>Start</b></Button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>


            <Modal
                show={finalModal}
                onHide={() => { setFinalModal(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {
                        errorRecord?.length === 0 ?
                            <Row style={{ textAlign: 'center' }}>
                                <h3 >
                                    Your upload is complete.
                                </h3>
                                <div>
                                    <p>
                                        There were 0 errors.
                                    </p>
                                </div>
                                <Col >

                                    <Button style={routingButton} id='routing-button' onClick={() => { setFinalModal(false); }} ><b>Close</b></Button>
                                </Col>
                            </Row> :
                            <Row style={{ textAlign: 'center' }}>
                                <h3 >
                                    Your upload is complete.
                                </h3>
                                <div>
                                    <p>
                                        There were {errorRecord.length} errors.
                                    </p>
                                </div>
                                <Col >

                                    <Button style={routingButton} id='routing-button' onClick={() => { setFinalModal(false); }} ><b>Close</b></Button>
                                </Col>


                                <div className='mt-4 brandUser_table'>
                                    <DataTable
                                        columns={columns1}
                                        data={errorRecord}
                                        pagination
                                        striped={true}
                                        customStyles={tableUserStyles}
                                        theme='mysaTable'
                                        fixedHeader={true}
                                        noDataComponent={'Updating records'}
                                    />
                                </div>
                            </Row>}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BookerUsersList