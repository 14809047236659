import { message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { QuestionTypes } from '../../../../../../common/constants';
import { ReactComponent as Completed } from '../../../../../../images/Approved.svg';
import { ReactComponent as Circle } from '../../../../../../images/Circle.svg';
import MYOQAddress from './MYOQAddress';
import MYOQContactList from './MYOQContactList';
import MYOQCurrency from './MYOQCurrency';
import MYOQDate from './MYOQDate';
import MYOQdocument from './MYOQdocument';
import MYOQImage from './MYOQImage';
import MYOQManyOf from './MYOQManyOf';
import MYOQNumber from './MYOQNumber';
import MYOQOneOf from './MYOQOneOf';
import MYOQRate from './MYOQRate';
import MYOQReadDoc from './MYOQReadDoc';
import MYOQText from './MYOQText';
import MYOQUserDefinedList from './MYOQUserDefinedList';
import MYOQVideo from './MYOQVideo';
import MYOQYesNo from './MYOQYesNo';


const MYOQuestionsHome: React.FC<any> = ({ surveyId, signUpStatus = '', sectionId, showButtons = false, completed }): JSX.Element => {
	const [surveys, setSurveys] = useState<any>([]);
	const [sectionsData, setSectionsData] = useState<any>(null);

	const { getMYOSurveys, answerQuestion, answerQuestionEvidence } = useStoreActions<any>((actions) => ({
		getMYOSurveys: actions.myoManagementModel.getMYOSurveys,
		answerQuestion: actions.myoManagementModel.answerQuestion,
		answerQuestionEvidence: actions.myoManagementModel.answerQuestionEvidence
	}));

	const { getMYOSurveysSuccess, myoAnswerSuccess, myoEvidenceSuccess } = useStoreState<any>((state) => ({
		getMYOSurveysSuccess: state.myoManagementModel.getMYOSurveysSuccess,
		myoAnswerSuccess: state.myoManagementModel.myoAnswerSuccess,
		myoEvidenceSuccess: state.myoManagementModel.myoEvidenceSuccess,
	}));

	const loadSurvey = useCallback(() => {
		getMYOSurveys({
			security: {
				apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
				user: "superadmin@mysa.global"
			},
			certificatesurveyid: surveyId
		});
	}, [getMYOSurveys, surveyId]);

	useEffect(() => {
		loadSurvey();
	}, [loadSurvey]);

	useEffect(() => {
		if (getMYOSurveysSuccess && getMYOSurveysSuccess.status.errorCode === 0) {
			setSurveys(getMYOSurveysSuccess.certificateSurvey);

		}
	}, [getMYOSurveysSuccess, showButtons]);

	useEffect(() => {
		if (sectionId) {
			const data = _.filter(surveys.survey?.sections, (survey) => survey.id === sectionId).shift();
			setSectionsData(data)
		}
	}, [sectionId, surveys]);

	useEffect(() => {
		if (myoAnswerSuccess) {
			const count = _.filter(sectionsData?.questions, (a) => !a.answer).length - 1;

			if (count === 0) {
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
			message.success('Answer submitted');
			loadSurvey();
		}

		if (myoEvidenceSuccess) {
			if (myoEvidenceSuccess.status.errorCode !== 0) {
				message.error(myoEvidenceSuccess.status.message)
			} else {
				const count = _.filter(sectionsData?.questions, (a) => !a.answer).length - 1;

				if (count === 0) {
					window.scrollTo({top: 0, behavior: 'smooth'});
				}
				message.success('Answer submitted');
				loadSurvey();
			}
		}

	}, [myoAnswerSuccess, myoEvidenceSuccess, loadSurvey, sectionsData?.questions]);

	const onAnswer = useCallback((answer, qId, na = null) => {
		answerQuestion({
			security: {
				apiKey: process.env.REACT_APP_AUDIT_API_KEY,
				user: "superadmin@mysa.global"
			},
			answer,
			certificatesurveyid: surveyId,
			questionID: qId,
			na,
		})

	}, [answerQuestion, surveyId]);


	const onEvidenceUpload = useCallback((file, qId) => {
		const formData = new FormData();
		const apiKey: any = process.env.REACT_APP_AUDIT_API_KEY;
		formData.append('APIKey', apiKey);
		formData.append('user', 'superadmin@mysa.global');
		formData.append('questionID', qId);
		formData.append('CertificateSurveyID', surveyId);
		formData.append('file', file);
		answerQuestionEvidence(formData);

	}, [answerQuestionEvidence, surveyId]);


	return (
		<div className="flex">
			<div className="bg-blue-100 w-full p-5">
				<div>
					<Circle width="36px" height="36px" fill="#05B3ED" className="float-left" />
					<p className="pt-2 pl-10 text-gray-400 font-bold">TO DO</p>
					{sectionsData && <div>
						{
							sectionsData.questions.map((q, key) => {
								if (!q.answer) {
									switch (q.type) {
										case QuestionTypes.yes_no:
											return <div key={key}>
												<MYOQYesNo data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.text:
											return <div key={key}>
												<MYOQText data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.number:
											return <div key={key}>
												<MYOQNumber data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.currency:
											return <div key={key}>
												<MYOQCurrency data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.date:
											return <div key={key}>
												<MYOQDate data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.one_of:
											return <div key={key}>
												<MYOQOneOf data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.many_of:
											return <div key={key}>
												<MYOQManyOf data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.rate:
											return <div key={key}>
												<MYOQRate data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.read_doc:
											return <div key={key}>
												<MYOQReadDoc data={q} onAnswer={onAnswer} />
											</div>
										// case QuestionTypes.sign:
										//   return <div key={key}>
										//     <QSign data={q} />
										//   </div>
										case QuestionTypes.contact_list:
											return <div key={key}>
												<MYOQContactList data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.user_defined_list:
											return <div key={key}>
												<MYOQUserDefinedList data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.image:
											return <div key={key}>
												<MYOQImage data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.video:
											return <div key={key}>
												<MYOQVideo data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.document:
											return <div key={key}>
												<MYOQdocument data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.address:
											return <div key={key}>
												<MYOQAddress data={q} onAnswer={onAnswer} />
											</div>
										default:
											return <div key={key}></div>;
									}
								}else{
									return(<></>)
								}
							})
						}
					</div>}
				</div>
			</div>
			<div className="bg-blue-200 w-full p-5">
				<div>
					<Completed width="36px" height="36px" fill="#05d1c1" className="float-left" />
					<p className="pt-2 pl-10 text-gray-400 font-bold">COMPLETED</p>
					{sectionsData && <div>
						{
							sectionsData.questions.map((q, key) => {
								if (q.answer) {
									switch (q.type) {
										case QuestionTypes.yes_no:
											return <div key={key}>
												<MYOQYesNo data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										case QuestionTypes.text:
											return <div key={key}>
												<MYOQText data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										case QuestionTypes.number:
											return <div key={key}>
												<MYOQNumber data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										case QuestionTypes.currency:
											return <div key={key}>
												<MYOQCurrency data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} />
											</div>
										case QuestionTypes.date:
											return <div key={key}>
												<MYOQDate data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} />
											</div>
										case QuestionTypes.one_of:
											return <div key={key}>
												<MYOQOneOf data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} />
											</div>
										case QuestionTypes.many_of:
											return <div key={key}>
												<MYOQManyOf data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										case QuestionTypes.rate:
											return <div key={key}>
												<MYOQRate data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} />
											</div>
										case QuestionTypes.read_doc:
											return <div key={key}>
												<MYOQReadDoc data={q} onAnswer={onAnswer} />
											</div>
										// case QuestionTypes.sign:
										//   return <div key={key}>
										//     <QSign data={q} />
										//   </div>
										case QuestionTypes.contact_list:
											return <div key={key}>
												<MYOQContactList data={q} onAnswer={onAnswer} />
											</div>
										case QuestionTypes.user_defined_list:
											return <div key={key}>
												<MYOQUserDefinedList data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										case QuestionTypes.image:
											return <div key={key}>
												<MYOQImage data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.video:
											return <div key={key}>
												<MYOQVideo data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.document:
											return <div key={key}>
												<MYOQdocument data={q} onEvidenceUpload={onEvidenceUpload} />
											</div>
										case QuestionTypes.address:
											return <div key={key}>
												<MYOQAddress data={q} onAnswer={onAnswer} signUpStatus={signUpStatus} completed={completed} />
											</div>
										default:
											return <div key={key}></div>;
									}
								}else{
									return(<></>)
								}
							})
						}
					</div>}
				</div>
			</div>


			{/* {showButtons && <ActionsButtons surveys={surveys} buttonData={buttonData} />} */}

		</div>
	)
};

export default MYOQuestionsHome;