import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { navStyle, tabDivStyle } from "../../../../common/components-style";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import ClientFilterProgram from "../../corporate-programme-management/common-views/client-filter-programme/ClientFilterProgram";
import { getStorage } from "../../../../common/storage";
import RequestBookingTravelerHome from "../request-booking/traveller/RequestBookingTravelerHome";
import BookingTraveler from "../bookings/traveler/BookingTraveler";

import TravelerOptionsTraveler from "../traveler-options/TravelerOptionsTraveler";


const TravelerBookingReports: React.FunctionComponent = () => {

    // url manage
    const location = useLocation<any>();

    ////////////////////////// local state manage ///////////////////////////////////////////////
    const history = useHistory();
    //storage 
    const client = getStorage('selectedFilterClient')?.label ? getStorage('selectedFilterClient')?.label : getStorage('programmeData')?.clientId?.companyName;
    const program = getStorage('selectedProgramsOption')?.label ? getStorage('selectedProgramsOption')?.label : getStorage('programmeData')?.programmeName;

    //filter program
    const [filterProgramme, setFilterProgramme] = useState(location.state?.filterProgramme ? location.state?.filterProgramme : false);
    const [tabKey, setTabKey] = useState<any>(location.state?.tabKey ? location.state?.tabKey : 'bookings');


    const redirectOptions = () => {
        history.push('/admin/traveler-options')
    }
    return (
        <>
            {
                (filterProgramme) ? <div style={tabDivStyle}>
                    <Tab.Container defaultActiveKey="bookings" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                        <Row >
                            <Col sm={2}>
                                <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                                    <div style={{ marginTop: '0%' }}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="bookings">Bookings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="requestBooking">Request Bookings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="options" >Options</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="awaitingApproval">Awaiting Approval</Nav.Link>
                                        </Nav.Item>
                                    </div>
                                </Nav>
                            </Col>
                            <Col sm={10}>
                                <Tab.Content >
                                    <Tab.Pane eventKey="bookings" >
                                        <Row >
                                            <Col md={12}>
                                                <div className='content-title mysa-blue-text'>
                                                    <Link to='/admin/traveler-booking-reports' className="fw-sb mysa-blue-text">{`${client ? client : ''} - Programme Management - ${program ? program : program}`}</Link>
                                                </div>
                                            </Col>
                                        </Row>
                                        <BookingTraveler />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="requestBooking" >
                                        <Row >
                                            <Col md={12}>
                                                <div className='content-title mysa-blue-text'>
                                                    <Link to='/admin/traveler-booking-reports' className="fw-sb mysa-blue-text">{`${client ? client : ''} - Programme Management - ${program ? program : program}`}</Link>
                                                </div>
                                            </Col>
                                        </Row>
                                        <RequestBookingTravelerHome />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="options" >
                                    <TravelerOptionsTraveler locationData={location}/>
                                        {/* <Row >
                                            <Col md={12}>
                                                <div className='content-title mysa-blue-text'>
                                                    <Link to='/admin/traveler-booking-reports' className="fw-sb mysa-blue-text">{`${client ? client : ''} - Programme Management - ${program ? program : program}`}</Link>
                                                </div>
                                            </Col>
                                        // </Row> */}
                                        {/* <Row onClick={redirectOptions}><TravelerOptionsBooker/></Row> */}
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div> : <ClientFilterProgram setFilterProgramme={setFilterProgramme} programsSelectManageStatus={true} searchType="bookingReport" ></ClientFilterProgram>
            }
        </>
    );

};

export default TravelerBookingReports;