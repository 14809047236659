import { CheckOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
const { Option } = Select;

const MYOQCurrency: React.FC<any> = ({ data, evidence, onAnswer, signUpStatus }): JSX.Element => {
	const [currency, setCurrency] = useState<string>('GBP');
	const [value, setValue] = useState<string>("0");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (data.answer?.answer) {
			const opt = data.answer?.answer.split(';');
			setCurrency(opt[0]);
			setValue(opt[1]);
		}

	}, [data.answer?.answer]);

	const answerQuestion = useCallback(() => {
		const disabled = signUpStatus === 'accepted' ? true : !isNaN(parseFloat(value)) ? false : true;
		if (disabled) return false;

		const ans = `${currency};${value}`
		onAnswer(ans, data.id);
		setLoading(true);
		setTimeout(() => setLoading(false), 3000);
	}, [currency, value, onAnswer, data, signUpStatus]);


	const selectBefore = (
		<Select className="w-24" value={currency} onChange={(cur) => setCurrency(cur)}>
			<Option value="GBP">(GBP) £</Option>
			<Option value="USD">(USD) $</Option>
			<Option value="EUR">(EUR) Ꞓ</Option>
			<Option value="YEN">(YEN) ¥</Option>
		</Select>
	);

	return (
		<div className="inline-block  m-5 w-full pr-10">
			<div className="bg-white p-5 rounded-lg">
				<p className="text-lg mb-3">{data?.description}</p>
				<Input addonBefore={selectBefore} value={value} onChange={(e: any) => setValue(e.target.value)} placeholder="0.00" />
				<Button type="primary" ghost={value ? false : true} block onClick={answerQuestion} icon={<CheckOutlined />} className="rounded-xl mt-3" loading={loading}>
					Submit
				</Button>
			</div>
		</div>
	)
};

export default MYOQCurrency;