import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs } from "react-google-maps";

const ClientPropertyGoogleMap: React.FC<any> = ({ location, setCoords, onShowCommute, building }): JSX.Element => {
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [activeMarker, setActiveMarker] = useState(null);

  
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {

    if (location) {
      setLat(location?.lat ? location.lat : 0);
      setLng(location?.lng ? location.lng : 0);
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  }, [location]);

  const onDragEnd = useCallback(({ latLng }) => {
    setLat(latLng.lat());
    setLng(latLng.lng());
    setCoords({ lat: latLng.lat(), lng: latLng.lng() })
  }, [setCoords]);

  return (
    <div className="google-map">
      <GoogleMap
        key={Math.random()}
        defaultZoom={16}
        defaultCenter={{ lat, lng }}
      >

        <Marker draggable onDragEnd={onDragEnd} position={{ lat, lng }}   onClick={() => handleActiveMarker(building?.property?._id)}>
          {activeMarker === building?.property?._id ? <InfoWindow onCloseClick={() => setActiveMarker(null)}>
            <div>
            <div>
                        <p>{building?.property?.propertyName}</p>
                        {/* <p>{building?.property?.address1}</p> */}
                     </div>
              {/* <div style={{ cursor: 'pointer', }} onClick={() => onShowCommute(building)}>
                <span style={{
                  color: "#000000",
                  textDecoration: "underline",
                  fontSize: "12px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  textDecorationColor: "#e80f8b",
                }}>Show commute</span>
              </div> */}
            </div>

          </InfoWindow> : null}
        </Marker>
      </GoogleMap>
    </div>
  )
};

export default withScriptjs(withGoogleMap(ClientPropertyGoogleMap));