import { getStorage } from './storage';
import _ from 'lodash'
import { RfpProcessStatus, UserGroups } from './constants';
import moment from 'moment';

export const capitalize = (str: string) => {
	return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

export const capitalizeRole = (str: string) => {
	const capitalize = str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
	return capitalize.split('_').join(' ');
}

export const getLoggedUserData = () => {
	const { userData } = getStorage('myo_logged_user');
	return userData;
}

export const getLoggedUserType = () => {
	if (getStorage('myo_logged_user')) {
		const { userData } = getStorage('myo_logged_user');
		// console.log(userData,'userData');
		if (userData) {
			return userData?.role;
		} else {
			return null;
		}
	}

}

export const getLoggedUserEmail = () => {
	const { userData } = getStorage('myo_logged_user');
	return userData.email;
}

export const getTokens = () => {
	const user = getStorage('myo_logged_user');
	if (user) {
		const { AccessToken, IdToken, RefreshToken } = user.AuthenticationResult;
		return { AccessToken, IdToken, RefreshToken }
	}
}

export const getCheckedValues = (code, isAdmin) => {
	const RadioEle: any = document.getElementsByName(`r_${code}`);
	const CheckboxEle: any = document.getElementById(`c_${code}`);
	let notification = true
	if (CheckboxEle?.value) {
		notification = CheckboxEle?.value;
	}
	let readWrite: any;
	if (code === 'PG_CORPORATE_SUPER_ADMIN' && RadioEle.length === 0) {
		readWrite = '1';
	}

	for (let i = 0; i < RadioEle.length; i++) {
		if (RadioEle[i].getAttribute('data-checked') === 'true') {
			readWrite = RadioEle[i]['value'];
		} else if ((RadioEle[i].getAttribute('data-checked') === null) && code.startsWith('PG') && isAdmin) {
			readWrite = '1';
		}
	}

	const read = readWrite === '0' ? true : false;
	const write = readWrite === '1' ? true : false;

	return { read, write, notification }
}

export const setCheckedValues = (access) => {
	const CheckboxEle: any = document.getElementById(`c_${access.module}`)?.parentElement;
	const RadioEle: any = document.getElementsByName(`r_${access.module}`);

	if (CheckboxEle) {

		if (access.notification) {
			CheckboxEle.classList.add('ant-checkbox-checked');
		} else {
			CheckboxEle.classList.remove('ant-checkbox-checked');
		}

		const readElm = RadioEle[0]?.parentElement;
		const writeElm = RadioEle[1]?.parentElement;
		const readElmChild = readElm.children[0];
		const writeElmChild = writeElm.children[0];

		if (access.read) {
			readElm.classList.add('ant-radio-checked');
			writeElm.classList.remove('ant-radio-checked');
			readElmChild.setAttribute("data-checked", true);
			writeElmChild.setAttribute("data-checked", false);
		} else if (access.write) {
			readElm.classList.remove('ant-radio-checked');
			writeElm.classList.add('ant-radio-checked');
			writeElmChild.setAttribute("data-checked", true);
			readElmChild.setAttribute("data-checked", false);
		}
	}

}

export const getCheckedStatus = (data) => {
	const isAdmin = _.includes(data, 'PG_CORPORATE_SUPER_ADMIN') ? true : false;
	const mappedData = _.map(data, (a) => {
		return {
			module: a,
			...getCheckedValues(a, isAdmin)
		}
	})
	return mappedData;

}

export const getUserAccess = (type: string) => {
	const { brands, clients } = getStorage('myo_logged_user_permission');
	if (type === 'brand') {
		return brands;
	} else {
		return clients;
	}

}

export const hasBrand = () => {
	const { brands } = getStorage('myo_logged_user_permission');
	return brands?.length ? true : false;
}


export const hasClient = () => {
	const { clients } = getStorage('myo_logged_user_permission');
	return clients?.length ? true : false;
}

export const isBrandAdmin = (brandId) => {
	const { brands } = getStorage('myo_logged_user_permission');
	const isAdmin = _.some(brands, (a) => a.brandId === brandId && a.role === UserGroups.brand_admin);
	// return getLoggedUserType() === UserGroups.super_admin ? true : isAdmin;
	return isAdmin;
}

export const isBrandModuleAvailable = (brandId, module) => {
	const { brands } = getStorage('myo_logged_user_permission');
	const isAdmin = _.some(brands, (a) => a.brandId === brandId && a.role === UserGroups.brand_admin);
	const access = _.filter(brands, (a) => a.brandId === brandId).shift()?.access || [];
	const hasAccess = _.some(access, (a) => a.module === module);

	return (getLoggedUserType() === UserGroups.super_admin || isAdmin) ? true : hasAccess;
}

export const canBrandModuleWrite = (brandId, module) => {
	const { brands } = getStorage('myo_logged_user_permission');
	const isAdmin = _.some(brands, (a) => a.brandId === brandId && a.role === UserGroups.brand_admin);
	const access = _.filter(brands, (a) => a.brandId === brandId).shift()?.access || [];
	const permission = _.filter(access, (a) => a.module === module).shift() || {};
	return (getLoggedUserType() === UserGroups.super_admin || isAdmin) ? true : permission.write;
}

export const isPropertyModuleAvailable = (brandId, propertyId, module) => {
	const { brands } = getStorage('myo_logged_user_permission');
	const isAdmin = _.some(brands, (a) => a.brandId === brandId && a.role === UserGroups.brand_admin);
	const access = _.filter(brands, (a) => a.brandId === brandId).shift()?.access || [];
	const tmpModule = `${propertyId}_${module}`;
	const hasAccess = _.some(access, (a) => a.module === tmpModule);

	return (getLoggedUserType() === UserGroups.super_admin || isAdmin) ? true : hasAccess;
}

export const canBrandPropertyModuleWrite = (brandId, propertyId, module) => {
	const { brands } = getStorage('myo_logged_user_permission');
	const isAdmin = _.some(brands, (a) => a.brandId === brandId && a.role === UserGroups.brand_admin);
	const access = _.filter(brands, (a) => a.brandId === brandId).shift()?.access || [];
	const tmpModule = `${propertyId}_${module}`;
	const permission = _.filter(access, (a) => a.module === tmpModule).shift() || {};

	return (getLoggedUserType() === UserGroups.super_admin || isAdmin) ? true : permission.write;
}

export const isCorporateProgrammeModuleAvailable = (clientId, programmeId, module) => {
	const { clients } = getStorage('myo_logged_user_permission');
	const programme = _.filter(clients, (a) => a.clientId === clientId).shift()?.corporateProgramme || [];
	const isAdmin = _.some(programme, (a) => a.programmeId === programmeId && a.role === UserGroups.programme_admin);
	const access = _.filter(programme, (a) => a.programmeId === programmeId).shift()?.access || [];
	const hasAccess = _.some(access, (a) => a.module === module);
	return (getLoggedUserType() === UserGroups.super_admin || isAdmin) ? true : hasAccess;
}

export const canCorporateProgrammeModuleWrite = (clientId, programmeId, module) => {
	const { clients } = getStorage('myo_logged_user_permission');
	const programme = _.filter(clients, (a) => a.clientId === clientId).shift()?.corporateProgramme || [];
	const isAdmin = _.some(programme, (a) => a.programmeId === programmeId && a.role === UserGroups.programme_admin);
	const access = _.filter(programme, (a) => a.programmeId === programmeId).shift()?.access || [];
	const permission = _.filter(access, (a) => a.module === module).shift() || {};
	return (getLoggedUserType() === UserGroups.super_admin || isAdmin) ? true : permission.write;

}

export const isBrandComplete = (brandData: any, userData: any, brandFinanceData: any) => {
	if ((brandData?.name !== '' && brandData?.yearCommencedTrading !== '' && brandData?.totalNumberOfPropertiesWithInventoryUnits !== '' && brandData?.totalNumberOfManagedInventoryUnits !== '' && brandData?.description !== '' && brandData?.image !== '' && brandData?.allowedDomains.toString() !== '')
		&& (userData?.email !== '' && userData?.firstName !== '' && userData?.lastName !== '' && userData?.phone !== '' && userData?.position !== '')
		&& (brandFinanceData?.invoiceIssuing?.length !== 0 && (brandFinanceData?.nameOfContact !== '' && brandFinanceData?.emailAddressForSubmission !== '' && brandFinanceData?.phone !== ''))) {
		return true;
	} else {
		return false;
	}
}

export const setAutoBrandReadAccess = (id, userId) => {
	const data: any = {
		id: id,
		userId: userId,
		accessType: "brand",
		access: [{ module: "BA_DETAILS", read: true, write: false, notification: "true" },
		{ module: "BA_USERS", read: true, write: false, notification: "true" },
		{ module: "BA_AUDIT", read: true, write: false, notification: "true" },
		{ module: "BA_TENDERS", read: true, write: false, notification: "true" },
		{ module: "BA_REPORTS", read: true, write: false, notification: "true" }
		]
	}
	return data;

}

export const setAutoProgrammeWriteAccess = (id, userId) => {
	const data: any = {
		id: id,
		userId: userId,
		accessType: "programme",
		access: [{ module: "PG_CORPORATE_SUPER_ADMIN", read: false, write: true, notification: "true" },
		{ module: "PG_ADMIN", read: false, write: true, notification: "true" },
		{ module: "PG_USERS", read: false, write: true, notification: "true" },
		{ module: "PG_REPORTS", read: false, write: true, notification: "true" },
		{ module: "CAN_BOOK_FOR_EVERYONE", read: false, write: true, notification: "true" },
		{ module: "CAN_VIEW_BOOKING", read: false, write: true, notification: "true" }
		]
	}
	return data;

}


export const setCompanyPermission = (id, userId, moduleName) => {
	const data: any = {
		id: id,
		userId: userId,
		accessType: "programme",
		access: [{ module: moduleName, read: false, write: true, notification: "true" }]
	}
	return data;

}

// get domain only from mail
export const getMailDomain = (mail: string, domainList: Array<string>) => {
	let domainValidate = false;

	// get domain from mail
	let getDomain = mail.substring(mail.indexOf('@') + 1);

	// get domain list 
	domainList.forEach(element => {
		if (getDomain === element) {
			domainValidate = true;
			return;
		}
	});

	return domainValidate;
}

export const isNameValidation = (firstName: string, lastName: string) => {
	if (!firstName || !lastName) {
		return false;
	} else {
		return true;
	}
}

export const isFirstUserSelectedBrand = () => {
	//get selected brand 
	const myoSelectedBrand = getStorage("myo_selected_brand");
	//get login user details with brands
	const myoLoggedUserPermission = getStorage("myo_logged_user_permission");
	//check is first user to selected brand
	if (myoLoggedUserPermission.brands) {
		return myoLoggedUserPermission.brands.some(brand => {
			if (brand.brandId === myoSelectedBrand.key) {
				return brand.isFirstUser;
			} else {
				return false;
			}
		});
	} else {
		return false;
	}
}

const today = moment(new Date());

export const changeRfpProcessStatusToInProgress = (rfpPRocess) => {

	const filteredProcess = rfpPRocess.filter(process => {
		const { startDate, endDate } = process;
		const startDateMoment = moment(startDate);
		const endDateMoment = moment(endDate);
		return startDateMoment.isSameOrBefore(today) && process?.rfpStatus === 'not_started' && today.isSameOrBefore(endDateMoment.add(1, 'days'));
	}).map(({ _id, startDate, endDate }) => ({
		id: _id,
		rfpStatus: RfpProcessStatus.INPROGRESS,
		startDate,
		endDate,
	}));
	return filteredProcess;
}

export const changeRfpProcessStatusToCompleted = (rfpPRocess) => {

	const completeStatusChange = rfpPRocess.filter(process => {
		const { endDate } = process;
		const endDateMoment = moment(endDate);
		return moment(today).isAfter(endDateMoment, 'date') && process?.rfpStatus !== 'completed';
	}).map(({ _id, startDate, endDate }) => ({
		id: _id,
		rfpStatus: "completed",
		startDate,
		endDate,
	}));

	return completeStatusChange;
};
export const formatPhoneNumber = (value) => {
	// if input value is falsy eg if the user deletes the input, then just return
	if (!value) return value;

	// clean the input for any non-digit values.
	const phoneNumber = value.replace(/[^\d]/g, '');

	// phoneNumberLength is used to know when to apply our formatting for the phone number
	const phoneNumberLength = phoneNumber.length;

	// we need to return the value with no formatting if its less then four digits
	// this is to avoid weird behavior that occurs if you  format the area code to early

	if (phoneNumberLength < 4) return phoneNumber;

	// if phoneNumberLength is greater than 4 and less the 7 we start to return
	// the formatted number
	// if (phoneNumberLength < 7) {
	//   return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3,6)} ${phoneNumber.slice(6,11)}`;
	// }

	// finally, if the phoneNumberLength is greater then seven, we add the last
	// bit of formatting and return it.
	return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
		2,
		6
	)} ${phoneNumber.slice(6, 15)}`;
}




// export const getValidNumber = (phoneNumber) => {
// 	const phoneUtil = PhoneNumberUtil.getInstance();
// 	const parsedNumber = phoneUtil.parse(phoneNumber);
// 	return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
// }

// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// export const validatePhoneNumber = (phoneNumber) => {

// 	let number = formatPhoneNumber(phoneNumber)
// 	/*
// 	Phone number validation using google-libphonenumber
// 	*/
// 	let valid = false;
// 	let isPossible = false;
// 	try {
// 		const phoneUtil = PhoneNumberUtil.getInstance();
// 		isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number))
// 		valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
// 	} catch (e) {
// 		valid = false;
// 	}
// 	if (isPossible && valid) {
// 		// setValidPhoneNumber(true);
// 		// return true;
// 		toast.success('Phone number ' + getValidNumber(number) + ' is valid', {
// 			position: toast.POSITION.BOTTOM_RIGHT,
// 			className: 'foo-bar'
// 		});
// 		// getValidNumber(phoneNumber);
// 		//   this.setState({
// 		// 	message:'Phone number '+this.getValidNumber(phoneNumber)+' is valid',
// 		// 	color:'green'
// 		//   });
// 	} else {
// 		// setValidPhoneNumber(false);
// 		// return false;
// 		toast.error('Phone number ' + number + ' is not valid', {
// 			position: toast.POSITION.BOTTOM_RIGHT,
// 			className: 'foo-bar'
// 		});
// 		//   this.setState({
// 		// 	message:'Phone number '+phoneNumber+' is not valid',
// 		// 	color:'red'
// 		//   });
// 	}
// }

//////// program admin
export const isProgramAdmin = () => {
	const { clients } = getStorage('myo_logged_user_permission');
	const isAdmin = _.some(clients, (client) => _.some(client, (programs) => _.some(programs, (program) => program.role === UserGroups.programme_admin || program.role === UserGroups.CHIEF_PROGRAMME_ADMIN)));
	return isAdmin;
}

export const isChiefProgramAdmin = () => {
	const { clients } = getStorage('myo_logged_user_permission');
	const isAdmin = _.some(clients, (client) => _.some(client, (programs) => _.some(programs, (program) => program.role === UserGroups.CHIEF_PROGRAMME_ADMIN)));
	return isAdmin;
}

export const isProgram = () => {
	const { clients } = getStorage('myo_logged_user_permission');
	const isAdmin = _.some(clients, (client) => _.some(client, (programs) => _.some(programs, (program) => program.role === UserGroups.programme_admin || program.role === UserGroups.CHIEF_PROGRAMME_ADMIN)));
	return isAdmin;
}


export const isBooker = () => {
	const { clients } = getStorage('myo_logged_user_permission');
	const isBooker = _.some(clients, (client) => _.some(client, (programs) => _.some(programs, (program) => program.role === UserGroups.booker)));
	return isBooker;
}

export const isTraveller = () => {
	const { clients } = getStorage('myo_logged_user_permission');
	const isTraveller = _.some(clients, (client) => _.some(client, (programs) => _.some(programs, (program) => program.role === UserGroups.traveler)));
	return isTraveller;
}

export const isSuperAdmin = () => {
	const { userData } = getStorage('myo_logged_user');

	return (userData.role === UserGroups.super_admin) ? true : false;

}


export const isProgrammeHome = () => {


	const { clients } = getStorage('myo_logged_user_permission');
	if (clients) {
		if (clients[0]?.corporateProgramme.length > 1) {
			if (getStorage('programmeId')) {
				const programme = clients[0]?.corporateProgramme;
				const isAdmin = _.some(programme, (a) => a.programmeId === getStorage('programmeId') && (a.role === UserGroups.programme_admin || a.role === UserGroups.CHIEF_PROGRAMME_ADMIN));
				//    const isTraveler = _.some(programme, (a) => a.programmeId === getStorage('programmeId') && (a.role === UserGroups.traveler));

				return isAdmin;

			}
		} else {
			const programme = clients[0]?.corporateProgramme;
			const programmeId = clients[0]?.corporateProgramme[0]?.programmeId;
			const isAdmin = _.some(programme, (a) => a.programmeId === programmeId && (a.role === UserGroups.programme_admin || a.role === UserGroups.CHIEF_PROGRAMME_ADMIN));
			//    const isTraveler = _.some(programme, (a) => a.programmeId === getStorage('programmeId') && (a.role === UserGroups.traveler));

			return isAdmin;
		}
	}

}

export const isTravelerHome = () => {

	const { clients } = getStorage('myo_logged_user_permission');
	if (clients) {


		if (clients[0]?.corporateProgramme.length > 1) {
			if (getStorage('programmeId')) {
				const programme = clients[0]?.corporateProgramme;
				// console.log(programme,'programme');
				const isAdmin = _.some(programme, (a) => a.programmeId === getStorage('programmeId') && (a.role === UserGroups.traveler));
				//    const isTraveler = _.some(programme, (a) => a.programmeId === getStorage('programmeId') && (a.role === UserGroups.traveler));

				return isAdmin;

			}
		} else {
			const programme = clients[0]?.corporateProgramme;
			const programmeId = clients[0]?.corporateProgramme[0]?.programmeId;
			const isAdmin = _.some(programme, (a) => a.programmeId === programmeId && (a.role === UserGroups.traveler));
			//    const isTraveler = _.some(programme, (a) => a.programmeId === getStorage('programmeId') && (a.role === UserGroups.traveler));

			return isAdmin;
		}
	}
}


export const isBookerHome = () => {

	const { clients } = getStorage('myo_logged_user_permission');
	if (clients) {


		if (clients[0]?.corporateProgramme.length > 1) {
			if (getStorage('programmeId')) {
				const programme = clients[0]?.corporateProgramme;
				// console.log(programme,'programme');
				const isAdmin = _.some(programme, (a) => a.programmeId === getStorage('programmeId') && (a.role === UserGroups.booker));
				//    const isTraveler = _.some(programme, (a) => a.programmeId === getStorage('programmeId') && (a.role === UserGroups.traveler));

				return isAdmin;

			}
		} else {
			const programme = clients[0]?.corporateProgramme;
			const programmeId = clients[0]?.corporateProgramme[0]?.programmeId;
			const isAdmin = _.some(programme, (a) => a.programmeId === programmeId && (a.role === UserGroups.booker));
			//    const isTraveler = _.some(programme, (a) => a.programmeId === getStorage('programmeId') && (a.role === UserGroups.traveler));

			return isAdmin;
		}
	}
}



/**
 * @description get client user permission
 */
export const isClientUserPermission = () => {
	const { clients } = getStorage('myo_logged_user_permission');

	for (const client of clients) {
		for (const programme of client.corporateProgramme) {
			for (const access of programme.access) {
				return access.module;
			}
		}
	}
}


export const daysBetween = (startDate: Date, endDate: Date) => {
	// The number of milliseconds in all UTC days (no DST)
	const oneDay = 1000 * 60 * 60 * 24;

	// A day in UTC always lasts 24 hours (unlike in other time formats)
	const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
	const end = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

	// so it's safe to divide by 24 hours
	return (start - end) / oneDay;

};

export const brandUser = (userList: Array<any>, poolUsers: Array<any>) => {
	/**
	 * get brand and property user list
	 */
	const getBrandUser = userList.filter((users) => users?.brands?.length !== 0);

	const detailedUser: Array<any> = [];

	for (const users of getBrandUser) {
		for (const brandData of users.brandData) {
			for (const brand of users.brands) {
				if (brandData._id === brand.brandId) {
					const basicUserData = {
						userId: users?.user?._id,
						firstName: users?.user?.firstName,
						lastName: users?.user?.lastName,
						email: users?.user?.email,
						phone: users?.user?.phone,
						mobile: users?.user?.mobile,
						position: users?.user?.position,
						userProfile: users?.user?.userProfile,
						userStatus: poolUsers[users.user?.email]?.UserStatus,
						userType: capitalizeRole(brand?.role),
						status: users?.status,
						data: users,
						role: brand?.role,
						brandId: brand?.brandId,
						brands: users.brands,
						brandName: brandData?.name,
						access: brand?.access,
						arrId: brand?._id,
						properties: brand?.properties,
						isFirstUser: brand?.isFirstUser
					}

					detailedUser.push(basicUserData);
				}
			}
		}
	}

	// detailedUser.sort((a, b) => {
	// 	if (a.brandName < b.brandName) {
	// 		return -1;
	// 	} else if (a.brandName > b.brandName) {
	// 		return 1;
	// 	}
	// 	return 0;
	// });

	return detailedUser;
};

export const corporateUser = (userList: Array<any>, poolUsers: Array<any>) => {
	/**
	 * get client, booker, traveler
	 */

	const getClientUser = userList.filter((users) => users?.clients?.length !== 0);

	const detailedUser: Array<any> = [];

	for (const users of getClientUser) {
		for (const clientData of users.clientData) {
			for (const client of users.clients) {
				if (clientData._id === client.clientId) {

					const basicUserData = {
						userId: users?.user?._id,
						firstName: users?.user?.firstName,
						lastName: users?.user?.lastName,
						email: users?.user?.email,
						phone: users?.user?.phone,
						mobile: users?.user?.mobile,
						position: users?.user?.position,
						userProfile: users?.user?.userProfile,
						userStatus: poolUsers[users.user?.email]?.UserStatus,
						userType: capitalizeRole(client?.corporateProgramme?.[0]?.role),
						status: users?.status,
						data: users,
						role: client?.corporateProgramme?.[0]?.role,
						clientId: client?.clientId,
						clients: users.clients,
						clientName: clientData?.companyName,
						access: client?.corporateProgramme?.[0]?.access,
						arrId: client?.corporateProgramme?.[0]?._id,
						corporateProgramme: client?.corporateProgramme,
						corporateProgrammeId: client?.corporateProgramme[0]?.programmeId,
					}

					/**
					 * ignore duplicate
					 */
					detailedUser.push(basicUserData);

				}
			}
		}
	}

	// detailedUser.sort((a, b) => {
	// 	if (a.clientName < b.clientName) {
	// 		return -1;
	// 	} else if (a.clientName > b.clientName) {
	// 		return 1;
	// 	}
	// 	return 0;
	// });

	return detailedUser;
};