import { message } from 'antd';
import axios, { Method } from 'axios';
import { getTokens } from './functions';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const call = (method: Method, url: string, data: object | null, params: object | null) => {
  const options = {
    method,
    url,
    params,
    data
  };


  if (getTokens()?.IdToken) {
    axios.defaults.headers = { "Authorization": getTokens()?.IdToken };
  }

  const request = axios(options);

  request.catch((error) => {
      
    if (error.response.status === 401) {
      message.warning('Session expired!');
      setTimeout(() => window.location.href = '/', 3000)
    }

  })


  return request;

};

const callAudit:any = (method: Method, url: string, data: object | null, params: object | null) => {
  const options = {
    baseURL: process.env.REACT_APP_AUDIT_API_URL,
    method,
    url,
    params,
    data,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json' 
    }
  };


  const request = axios(options);

  return request;

};

const callMYOManagement:any = (method: Method, url: string, data: object | null, params: object | null) => {
  const options = {
    baseURL: process.env.REACT_APP_MYO_MANAGEMENT_API_URL,
    method,
    url,
    params,
    data,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json' 
    }
  };


  const request = axios(options);

  return request;

};

export { call, callAudit, callMYOManagement };