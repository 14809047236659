import DataTable from 'react-data-table-component';
import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { setStorage } from '../../../common/storage';
import { $mysaMegenta, $mysaTeal, $mysaYellow, overlayTriggerDelay, tableStyles } from '../../../common/components-style';
import Archive from '../../../images/icons/svg/outside/Icon-Function-Archive.svg';
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ProgrammeStatus } from '../../../common/constants';
import StatusIcon from '../../../images/icons/svg/correct.svg';
import CircleIcon from '../../../images/icons/svg/circle.svg';
import SweetAlert from 'react-bootstrap-sweetalert';


const ProgrammeList: React.FC<any> = ({ loadForm, programmes, onProgrammeSelect, activateProgramme, archiveProgramme }): JSX.Element => {
  const [activatePopup, setActivatePopup] = useState<boolean>(false);
  const [activeData, setActiveData] = useState<any>();
  const [archivePopup, setArchivePopup] = useState<boolean>(false);
  const [archiveData, setArchiveData] = useState<any>();

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    { name: 'Name of Programme ', selector: row => row.programmeName },
    { name: 'Contact', selector: row => row.clientContact },
    { name: 'Contact Email', selector: row => row.email },
    { name: 'Contact Tel No. ', selector: row => row.telephone },
    { name: 'MYSA Owner', selector: row => row.mysaOwner },
    {
      name: 'Status',
      cell: (row) => {
        let color = $mysaMegenta;
        if (row.status === 'active') color = $mysaTeal;
        if (row.status === 'inactive') color = $mysaMegenta;


        return (
          <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.status}</span>
        )
      }
    },

    {
      name: 'Programme status',
      cell: (row) => {
        let color = "";
        if (row.programmeStatus === ProgrammeStatus.PENDING) color = $mysaYellow;
        if (row.programmeStatus === ProgrammeStatus.ACCEPTED) color = $mysaTeal;
        if (row.programmeStatus === ProgrammeStatus.INCOMPLETE) color = $mysaMegenta;

        return (
          <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.programmeStatus}</span>
        )
      }
    },
    {
      name: 'Action',
      cell: (data: any) => {
        return (<>
          <OverlayTrigger
            placement="top"
            delay={overlayTriggerDelay}
            overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
          >
            <img className='iconSizes' style={{ marginRight: '5%' }} src={ViewEdit} alt="" onClick={() => { loadForm(data); setStorage('myo_selected_programme', data); onProgrammeSelect(data) }} />
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={overlayTriggerDelay}
            overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
          >
            <img className='iconSizes' src={Archive} alt="" onClick={() => {
              setArchivePopup(true);
              setArchiveData(data);
            }} />
          </OverlayTrigger>

          {
            (((data?.programmeStatus === ProgrammeStatus.ACCEPTED) && (data?.isActivated === false)) || (data.status === 'inactive')) &&
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-table-top'>{'Activate'}</Tooltip>}
            >
              <img className='iconSizes2' src={CircleIcon} alt="" onClick={() => { setActivatePopup(true); setActiveData(data) }} />
            </OverlayTrigger>}

          {
            ((((data?.programmeStatus === ProgrammeStatus.ACCEPTED) && (data?.isActivated === true) && (data.status === 'active')))) &&
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-table-top'>{'Activated'}</Tooltip>}
            >
              <img className='iconSizes' style={{ marginLeft: '23px' }} src={StatusIcon} alt="" />
            </OverlayTrigger>}
        </>)
      },
    },
  ];

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const data = useMemo(() => {
    return _.map(programmes, prog => {
      return {
        key: prog._id,
        programmeName: prog.programmeName,
        clientContact: prog.clientContact,
        email: prog.email,
        telephone: prog.telephone,
        mysaOwner: prog.mysaOwner,
        clientId: prog.clientId._id,
        programmeStatus: prog.programmeStatus,
        isActivated: prog.isActivated,
        status: prog.status
      }
    })
  }, [programmes]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="property-list">
        <DataTable
          columns={columns}
          data={data}
          pagination
          striped={true}
          customStyles={tableStyles}
          theme='mysaTable'
          fixedHeader={true}
          noDataComponent={'Updating records'}
        />
      </div>

      <SweetAlert
        show={activatePopup}
        warning
        showCancel={true}
        focusCancelBtn={true}
        confirmBtnText="Yes"
        cancelBtnText="No"
        confirmBtnBsStyle="danger"
        title="Are you sure you want to activate this programme?"
        onConfirm={() => {
          activateProgramme(activeData)
          setActivatePopup(false);
          setStorage('activateProgramme', true);
        }}
        onCancel={() => {
          setActivatePopup(false);
        }}
      >
      </SweetAlert>

      <SweetAlert
        show={archivePopup}
        warning
        showCancel={true}
        focusCancelBtn={true}
        confirmBtnText="Yes"
        cancelBtnText="No"
        confirmBtnBsStyle="danger"
        title="Are you sure you want to archive this programme?"
        onConfirm={() => {
          archiveProgramme(archiveData);
          setArchivePopup(false);
        }}
        onCancel={() => {
          setArchivePopup(false);
        }}
      >
      </SweetAlert>
    </>

  )
};

export default ProgrammeList;