import { Row, Col, Container, Form, Dropdown, Breadcrumb, Button } from 'react-bootstrap';
import { FC, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { formButton } from '../../../../common/components-style';
import { Link, useHistory } from 'react-router-dom';

const BrandAdminRfpForm: FC<any> = ({ data, setShowLocations, setSelectedCity, fromPropertiesPage = false, setSelectedCityDetails, setDefaultTab }) => {
	const [selectedCountry, setSelectedCountry] = useState<string>("");
	const [selectedCountryCode, setSelectedCountryCode] = useState<string>("");
	const [isCountrySelected, setIsCountrySelected] = useState<boolean>(false);
	const [countries, setCountries] = useState<[]>([]);
	const [cities, setCities] = useState<[]>([]);
	const [rfpLocations, setRfpLocations] = useState<[]>([]);
	// const [selectedRfpLocations, setSelectedRfpLocations] = useState<[]>([]);
	const [isCityToggleOpen, setIsCityToggleOpen] = useState<boolean>(false);
	const history = useHistory();

	//actions

	// const [reduxBrandDetail] = useState<ReduxBrandDetail>(brandRfpDetails || data);

	// run when the country is selected
	useEffect(() => {
		if (isCountrySelected && rfpLocations?.length) {

			const citiesToShowInTheDropdown: any = rfpLocations.filter((location: any) => location.countryName === selectedCountry).map((country: any) => country.cities);

			// convert the array of arrays to a single array
			const citiesToShowInTheDropdownFlattened: any = [].concat.apply([], citiesToShowInTheDropdown);
			setCities(citiesToShowInTheDropdownFlattened);
		}
	}, [isCountrySelected, rfpLocations, selectedCountry])

	/////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (data) {
			if (data?.locationsDetails) {
				setRfpLocations(data?.locationsDetails);
				const countriesToAdd = data?.locationsDetails.map(location => location);

				setCountries(countriesToAdd);
			}
		}
	}, [data]);

	// /////////////////////////////////////////////////////////////////////////////////////////////////
	const handleCityChange = useCallback((eventKey) => {
		if (eventKey) {
			const selectedCountryLocations: any = rfpLocations.filter((location: any) => location.countryName === selectedCountry).map((country: any) => country.cities);
			const filter: any = [].concat.apply([], selectedCountryLocations);
			const selectedCityLocations: any = filter.filter(city => city.cityName === eventKey);
			// setSelectedRfpLocations(selectedCityLocations)

			if (fromPropertiesPage) {
				setShowLocations(false);
				setSelectedCity(eventKey);
				const params: any = {};
				params.selectedMyRfpCountryCode = selectedCountryCode;
				params.selectedMyRfpCountry = selectedCountry;
				params.rfpDetailSelectedCity = eventKey;
				params.rfpDetailSelectedCityDetails = selectedCityLocations;
				setSelectedCityDetails(params);
			} else {
				const param: any = {};
				param.showLocationForm = true;
				param.data = data;
				param.rfpDetailSelectedCity = eventKey;
				param.rfpDetailSelectedCityDetails = selectedCityLocations;
				// params.defaultKey = 'corporateRFPS';

				// setDefaultTab('corporateRFPS');
				// param.data = data;
				// param.rfpDetailSelectedCity = eventKey;
				// param.rfpDetailSelectedCityDetails = selectedCityLocations;
				//		if (defaultTab === 'corporateRFPS') {
				setTimeout(() => {
					history.push(`/admin/brand-rfp-management`, { query: param });
				}, 500);
				//		}
			}


			// const stateData = {
			// 	...reduxBrandDetail,
			// 	rfpDetailSelectedCity: eventKey,
			// 	rfpDetailSelectedCityDetails: selectedCityLocations,
			// 	showRfpDetailFormSecondPage: true,
			// 	showRfpDetailFormFirstPage: false,
			// 	showMyRfpLocationFormFirstPage: false,
			// 	showMyRfpLocationFormSecondPage: true,
			// 	selectedMyRfpCountry: selectedCountry,
			// 	selectedMyRfpCountryCode: selectedCountryCode
			// }

			// updateBrandRFPData({ ...stateData });
		}
	}, [data, fromPropertiesPage, history, rfpLocations, selectedCountry, selectedCountryCode, setSelectedCity, setSelectedCityDetails, setShowLocations]);

	//  /////////////////////////////////////////////////////////////////////////////////////////////////
	const handleCountryChange = useCallback((eventKey: any) => {
		if (eventKey) {
			setSelectedCountry(eventKey);
			setIsCountrySelected(true);
		}
	}, []);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const onChangeCountry = useCallback((acc) => {
		setSelectedCountryCode(acc.countryCode);
	}, []);

	/////////////////////////////////////////////////////////////////////////////////////////////////
	const renderCountries = useCallback(() => {
		if (countries) {
			return countries.map((acc: any, key: number) => {
				return (
					<Dropdown.Item eventKey={acc.countryName} name={acc.countryCode} key={key} onClick={() => onChangeCountry(acc)} >{acc.countryName}</Dropdown.Item>
				)
			});
		}
	}, [countries, onChangeCountry]);


	//  /////////////////////////////////////////////////////////////////////////////////////////////////
	const renderCities = useCallback(
		() => {
			if (cities.length > 0) {
				return cities.map((city: any, index) => <Dropdown.Item eventKey={city.cityName} name={city.cityName} key={index}>{city.cityName}</Dropdown.Item>)
			}

		}, [cities]);

	///////////////////////////////////////////////////////////////////////////////////////////////////
	const handleToggle = useCallback((isOpen, event) => {
		if (isOpen) {
			setIsCityToggleOpen(true);
		} else {
			setIsCityToggleOpen(false);
		}

	}, [])


	// need tp verify
	const onCloseLocation = useCallback(() => {
		if (fromPropertiesPage) {
			setSelectedCity("");
			setShowLocations(false);
		} else {
			const params: any = {};
			params.defaultKey = 'corporateRFPS';
			setDefaultTab('corporateRFPS');
			setTimeout(() => {
				history.push(`/admin/brand-rfp-management`, { query: params });
			}, 500);
		}

	}, [fromPropertiesPage, history, setDefaultTab, setSelectedCity, setShowLocations])

	return (

		<>
			<Container >
				<Row style={{
					marginBottom: '1.5rem',
					marginTop: '20px',
				}} >
					<Col xs={12}><div className="content-title">
						<Breadcrumb>
							{fromPropertiesPage ? <Breadcrumb.Item style={{

								fontWeight: 100,
							}} >
								<Link to={{ pathname: "/admin/brand-rfp-management", state: { selectedBrandId: data?.brandId, defaultKey: 'myRFPs' } }}>{'ActiveRFPs'}</Link>
							</Breadcrumb.Item  > :

								<Breadcrumb.Item style={{

									fontWeight: 100,
								}} >
									<Link to={{ pathname: "/admin/brand-rfp-management", state: { selectedBrandId: data?.brandId, defaultKey: 'corporateRFPS' } }}>{'RFPInvites'}</Link>
								</Breadcrumb.Item  >}
							<Breadcrumb.Item active>
								{data?.companyName}{' - '}{data?.programmeName} {' - '} {moment(data?.startDate).format('DD MMM YYYY')}
							</Breadcrumb.Item  >
							{/* <Breadcrumb.Item active>
                                Locations
                            </Breadcrumb.Item> */}
						</Breadcrumb>
					</div></Col>
				</Row>

				<div className="jumbotron">
					<Form>
						<Col md={1}></Col>
						<Col md={10}>
							<Row className='formSpace'>
								<Col md={3} style={{ textAlign: 'right' }}>
									<Form.Label>Introduction</Form.Label>
								</Col>
								<Col md={9} style={{ textAlign: 'left' }}>
									<p className="lead">{data && data?.overviewForInterview}</p>
								</Col>
							</Row>
							<Row className='formSpace'>
								<Col md={3} style={{ textAlign: 'right' }}>
									<Form.Label>Company name</Form.Label>
								</Col>
								<Col md={9} style={{ textAlign: 'left' }}>
									<p className="lead">{data && data?.companyName}</p>
								</Col>
							</Row>
							<Row className='formSpace'>
								<Col md={3} style={{ textAlign: 'right' }}>
									<Form.Label>Name of the programme</Form.Label>
								</Col>
								<Col md={9} style={{ textAlign: 'left' }}>
									<p className="lead">{data && data?.programmeName}</p>
								</Col>
							</Row>
							<Row className='formSpace'>
								<Col md={3} style={{ textAlign: 'right' }}>
									<Form.Label>Start Date</Form.Label>
								</Col>
								<Col md={9} style={{ textAlign: 'left' }}>
									<p className="lead">{data && moment(data.startDate).format("D MMM YYYY")}</p>
								</Col>
							</Row>
							<Row className='formSpace'>
								<Col md={3} style={{ textAlign: 'right' }}>
									<Form.Label>End Date</Form.Label>
								</Col>
								<Col md={9} style={{ textAlign: 'left' }}>
									<p className="lead">{data && moment(data?.endDate).format("D MMM YYYY")}</p>
								</Col>
							</Row>
							<Row className='formSpace'>
								<Col md={3} style={{ textAlign: 'right' }}>
									<Form.Label>Question submit date</Form.Label>
								</Col>
								<Col md={9} style={{ textAlign: 'left' }}>
									<p className="lead">{data && moment(data?.questionSubmitDate).format("D MMM YYYY")}</p>
								</Col>
							</Row>
							<Row className='formSpace'>
								<Col style={{ textAlign: 'right' }}>
									<Form.Label><span className='requiredMark'>*</span> Country</Form.Label>
								</Col>
								<Col style={{ textAlign: 'left' }}>
									<Dropdown onSelect={handleCountryChange}  drop="down" onToggle={handleToggle} >
										<Dropdown.Toggle className={isCityToggleOpen ? "toggle-selected mt-0" : "mt-0"} id="dropdown-brandRfp">
											{selectedCountry.length > 0 ? selectedCountry : 'Select'}
										</Dropdown.Toggle>
										<Dropdown.Menu  >

											{
												renderCountries()
											}
										</Dropdown.Menu>
									</Dropdown>
								</Col>
								<Col style={{ textAlign: 'right' }}>
									<Form.Label hidden={!isCountrySelected} ><span className='requiredMark'>*</span> City</Form.Label>
								</Col>
								<Col>
									<Dropdown hidden={!isCountrySelected} onSelect={handleCityChange} drop="down" onToggle={handleToggle} >
										<Dropdown.Toggle className={isCityToggleOpen ? "toggle-selected mt-0" : "mt-0"} id="dropdown-brandRfp">
											{data?.rfpDetailSelectedCity ? data?.rfpDetailSelectedCity : 'Select '}
										</Dropdown.Toggle>
										<Dropdown.Menu  >

											{
												renderCities()
											}
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>

							<Row className='formSpace'>
								<Col md={3} style={{ textAlign: 'right' }}>
								</Col>
								<Col md={9} style={{ textAlign: 'left' }}>
									<Button id='edit-button' style={formButton} onClick={() => { onCloseLocation() }} >Close</Button>
								</Col>
								{/* updateBrandRFPData({ ...reduxBrandDetail, showRfpDetailFormFirstPage: false, showRfpDetailFormSecondPage: false, showAvailableRfpList: false, showHomePage: true, showMyRfpBrandEvaluationPage: false }); */}
							</Row>
						</Col>
					</Form>
				</div>

			</Container>
		</>
	)
};

export default BrandAdminRfpForm;