// import { Select as AntSelect } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getLoggedUserType } from '../../../common/functions';
import { UserGroups } from '../../../common/constants';
import { getStorage, setStorage } from '../../../common/storage';
import { Container, Form, Row, Col } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { BookingForm } from '../../../common/interfaces';
import Select from 'react-select'
import { PriceStyles } from '../../../common/components-style';

// const { Option } = AntSelect;

// const layout = {
// 	labelCol: { span: 13 },
// 	wrapperCol: { span: 34 },
// };

const BookingPage: React.FC<any> = ({ loadForm }): JSX.Element => {

	// const [form] = Form.useForm();
	const { formState: { errors }, control } = useForm<BookingForm>();
	const [hideClient, setHideClient] = useState<boolean>(false);
	const [clientList, setClientList] = useState<any>([]);
	const [programmeList, setProgrammeList] = useState<any>([]);
	// const [programmeId, setProgrammeId] = useState<any>();
	const [clientId, setClientId] = useState<any>();
	const [clientData, setClientData] = useState<any>();

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { getClientList, getCorporateProgrammeByClient, getUserMapping } = useStoreActions<any>((actions) => ({
		getClientList: actions.client.getClientList,
		getCorporateProgrammeByClient: actions.client.getCorporateProgrammeByClient,
		getUserMapping: actions.user.getUserMapping,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {
		getClientListSuccess,
		getCorporateProgrammeByClientSuccess,
		getUserMappingSuccess
	} = useStoreState<any>((state) => ({
		getClientListSuccess: state.client.getClientListSuccess,
		getCorporateProgrammeByClientSuccess: state.client.getCorporateProgrammeByClientSuccess,
		getUserMappingSuccess: state.user.getUserMappingSuccess,
	}));
	////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		getClientList();
	}, [getClientList]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getLoggedUserType() === UserGroups.super_admin) {
			setHideClient(true);
		} else {
			setHideClient(false);
			const { userData } = getStorage("myo_logged_user");
			const userId = {
				userId: userData._id,
			};
			getUserMapping(userId);
		}
	}, [getUserMapping])


	useEffect(() => {
		if (getUserMappingSuccess) {
			const { result } = getUserMappingSuccess.data
			setClientId(result[0]?.clients[0]?.clientId);
			setStorage('makeBookingClient', result[0]?.clients[0]?.clientId);
		}
	}, [getUserMappingSuccess]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (clientId) {
			const params: any = {};
			params.clientId = clientId;
			getCorporateProgrammeByClient(params);
		}

	}, [clientId, getCorporateProgrammeByClient])

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getClientListSuccess) {
			setClientList(getClientListSuccess.data)
		}
	}, [getClientListSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getCorporateProgrammeByClientSuccess) {
			setProgrammeList(getCorporateProgrammeByClientSuccess.data)
		}
	}, [getClientListSuccess, getCorporateProgrammeByClientSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	// const renderClientList = useCallback(() => {
	// 	if (clientList) {
	// 		return clientList.map((client: any, key: number) => {
	// 			return (
	// 				<Option key={key} client={client} value={`${client._id}`} >{client.companyName}</Option>
	// 			)
	// 		});
	// 	}

	// }, [clientList]);

	const renderClientListNew = useCallback(() => {
		if (clientList) {
			return clientList.map((acc: any, key: number) => {
				return ({
					key: key,
					value: `${acc._id}`,
					label: acc.companyName,
					client: acc
				})
			});
		}

	}, [clientList]);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const onClientChange = useCallback((clientId, client) => {
		setClientData(client);
		if (clientId) {
			setClientId(clientId.value);
			setStorage('makeBookingClient', clientId.value);
			// setValue(programmeId,"")

		}

	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	// const renderProgrammeList = useCallback(() => {
	// 	if (programmeList) {
	// 		return programmeList.map((prog: any, key: number) => {
	// 			return (
	// 				<Option key={key} prog={prog} value={`${prog._id}`} >{prog.programmeName}</Option>
	// 			)
	// 		});
	// 	}

	// }, [programmeList]);

	const renderProgrammeListNew = useCallback(() => {
		if (programmeList) {
			return programmeList.map((acc: any, key: number) => {
				return ({
					key: key,
					value: `${acc._id}`,
					label: acc.programmeName,
					prog: acc
				})
			});
		}

	}, [programmeList]);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const onProgrammeChange = useCallback((val) => {

		if (val) {
			setStorage('makeBookingProgram', val);
			// setProgrammeId(value);
			loadForm(val, clientData);
		}

	}, [clientData, loadForm]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className="booking-form">
			<Container style={{ maxWidth: '1675px' }} >
				<div >
					{hideClient ? <Row style={{

						position: 'fixed',
						left: '6vw',
						top: "70px",
						width: "100%",
						padding: '5vh 1.5vw',
						zIndex: 20,
						backgroundColor: '#fff',
					}} >
						<Form   >
							<Col md={12} >
								<Row className='formSpace' style={{ marginBottom: '25px' }}>
									<Col md={2} style={{ marginLeft: '0px', marginTop: '5px' }}>
										<p style={{ fontWeight: 'bold' }}>Make a booking</p>
									</Col>
									<Col md={3}>
										<Controller
											name="location"
											//  defaultValue={bookingParams?.locationId}
											control={control}

											render={({ field }) =>
												<div style={{
													width: '321px',
													height: '44px',
													marginLeft: '0px',
												}}>
													<Select
														components={{ DropdownIndicator: () => null }}
														isSearchable
														placeholder={<div className='flex justify-start '>
															{/* <div style={{ marginRight: '6%' }} >
                                <img style={{ width: '21px', height: '26px' }} src={BookMarkIcon} alt='book mark ' />
                              </div> */}
															<div className="font-light">
																Select client
															</div>
														</div>}
														options={renderClientListNew()}

														//  value={{ value: bookingParams.locationId, label: bookingParams?.location?.children }}
														className={`${errors.clientId ? 'is-invalid' : ''} font-light`}
														onChange={onClientChange}
														styles={PriceStyles}
													/>
												</div>
											}
										/>

									</Col>
									<Col md={3}>
										<Controller
											name="programmeId"
											defaultValue={''}
											control={control}
											render={({ field }) =>
												<div
													style={{
														marginLeft: '-60px',
														width: '321px',
														height: '44px',
													}}
												>
													<Select

														styles={PriceStyles}
														className={`${errors.programmeId ? 'is-invalid ' : ''} font-light`}
														options={renderProgrammeListNew()}
														onChange={onProgrammeChange}
														isSearchable={true}

														placeholder={<div className='flex justify-start '>
															{/* <div style={{ marginRight: '6%' }} >
                                <img style={{ width: '21px', height: '26px' }} src={FilterIcon} alt='book mark ' />
                              </div> */}
															<div className="font-light" >
																Select programme
															</div>
														</div>}
														components={{ DropdownIndicator: () => null }}
													/>
												</div>
											}
										/>
									</Col>
									<Col md={2}>
									</Col>
								</Row>
							</Col>
						</Form>

					</Row> :
						<Row style={{

							position: 'fixed',
							left: '6vw',
							top: '15vh',
							width: '100%',
							paddingTop: '5vh',
							paddingLeft: '1.5vw',
							zIndex: 20,
							backgroundColor: '#fff',
						}}  >
							<Form   >
								<Col md={12} >
									<Row className='formSpace' style={{ marginBottom: '25px' }}>
										<Col md={2} style={{ marginLeft: '0px', marginTop: '5px' }}>
											<p style={{ fontWeight: 'bold' }}>Make a booking</p>
										</Col>

										<Col md={5} style={{ marginLeft: '-350px' }}>
											<Controller
												name="programmeId"
												defaultValue={''}
												control={control}
												render={({ field }) =>
													<div
														style={{
															marginLeft: '300px',
															width: '321px',
															height: '44px',
														}}
													>
														<Select
															styles={PriceStyles}
															className={`${errors.programmeId ? 'is-invalid ' : ''} font-light`}
															options={renderProgrammeListNew()}
															onChange={onProgrammeChange}
															isSearchable={true}

															placeholder={<div className='flex justify-start '>
																<div className="font-light" >
																	Select programme
																</div>
															</div>}
															components={{ DropdownIndicator: () => null }}
														/>
													</div>

												}
											/>
										</Col>
									</Row>
								</Col>
							</Form>

						</Row>}
				</div>

			</Container>
			{/* <Container style={{ maxWidth: '1675px' }}>
				<div >
					<Card id='booking-engine'>
						<Card.Body>
							{hideClient ? <Row>
								<Form >
									<Row>
										<Col md={2} style={{ marginLeft: '0px' }}>
											<p style={{ fontWeight: 'bold' }}>Make a booking</p>
										</Col>
										<Col md={5} >

											<Controller
												name="clientId"
												defaultValue={''}
												control={control}

												render={({ field }) =>
													<Select className={`${errors.clientId ? 'is-invalid' : ''}`} onChange={onClientChange} placeholder="Select client" style={{ width: '40%' }}  >
														{renderClientList()}
													</Select>}
											/>
										</Col>
										<Col md={5} style={{ marginLeft: '-250px' }}>

											<Controller
												name="programmeId"
												defaultValue={''}
												control={control}

												render={({ field }) =>
													<Select onChange={onProgrammeChange} className={`${errors.programmeId ? 'is-invalid' : ''}`} placeholder="Select programme" style={{ width: '50%' }} >
														{renderProgrammeList()}
													</Select>}
											/>
										</Col>
									</Row>

								</Form>
							</Row>

								: <Row>
									<Form >
										<Row >
											<Col md={2} style={{ marginLeft: '0px' }}>
												<p style={{ fontWeight: 'bold' }}>Make a booking</p>
											</Col>
											<Col md={4} style={{ marginLeft: '0px' }}>
												<Controller
													name="programmeId"
													defaultValue={''}
													control={control}

													render={({ field }) =>
														<Select onChange={onProgrammeChange} className={`${errors.programmeId ? 'is-invalid' : ''}`} style={{ width: '50%' }} placeholder="Select programme" >
															{renderProgrammeList()}
														</Select>}
												/>
											</Col>
										</Row>

									</Form>
								</Row>}
						</Card.Body>
					</Card>
				</div>
			</Container> */}


		</div>
	)
};

export default BookingPage;