import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Row, Container, Image, Card, Modal, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import bookingdetails from "../../../../../../../images/icons/svg/bookingdetails.svg";
import moment from "moment-timezone";
import { daysBetween } from "../../../../../../../common/functions";
import { toast } from "react-toastify";
import { LIVE_BOOKING_STATUS } from "../../../../../../../common/constants";
import { getStorage } from "../../../../../../../common/storage";
import { slideImageStyle } from "../../../../../../../common/components-style";
import CheckAndRadioBtn from "../../../../components/CheckAndRadioBtn";
import ButtonC from "../../../../components/ButtonC";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;
const BookingSIngleView: React.FunctionComponent = () => {

    /**
     * redirect hooks
     */
    const { push } = useHistory();



    /**
* moment
*/
    const minDate = moment(new Date());

    /**
     * get param 
     */
    const { id }: any = useParams();

    /**
     * global actions
     */
    const { sendCancelBookingRequestEmail, sendAmendBookingRequestEmail, getBookingById, bookingStatus, amendBookingRequest, sendAcceptAmendBookingEmail, sendNoAcceptAmendBookingEmail } = useStoreActions<any>((actions) => ({
        getBookingById: actions.booking.getBookingById,
        bookingStatus: actions.booking.bookingStatus,
        amendBookingRequest: actions.booking.amendBookingRequest,
        sendAcceptAmendBookingEmail: actions.booking.sendAcceptAmendBookingEmail,
        sendNoAcceptAmendBookingEmail: actions.booking.sendNoAcceptAmendBookingEmail,
        sendAmendBookingRequestEmail: actions.booking.sendAmendBookingRequestEmail,
        sendCancelBookingRequestEmail: actions.booking.sendCancelBookingRequestEmail

    }));

    /**
     * global status
     */
    const { getBookingByIdSuccess, getBookingStatusError, getBookingStatusSuccess, getAmendBookingRequestSuccess, getAmendBookingRequestError, sendNoAcceptAmendBookingEmailError, sendAcceptAmendBookingEmailError, } = useStoreState<any>((state) => ({
        getBookingByIdSuccess: state.booking.getBookingByIdSuccess,
        getBookingStatusSuccess: state.booking.getBookingStatusSuccess,
        getBookingStatusError: state.booking.getBookingStatusError,
        getAmendBookingRequestSuccess: state.booking.getAmendBookingRequestSuccess,
        getAmendBookingRequestError: state.booking.getAmendBookingRequestError,
        getAcceptAmendBookingRequestError: state.booking.getAcceptAmendBookingRequestError,
        sendAcceptAmendBookingEmailSuccess: state.booking.sendAcceptAmendBookingEmailSuccess,
        sendAcceptAmendBookingEmailError: state.booking.sendAcceptAmendBookingEmailError,
        sendNoAcceptAmendBookingEmailSuccess: state.booking.sendNoAcceptAmendBookingEmailSuccess,
        sendNoAcceptAmendBookingEmailError: state.booking.sendNoAcceptAmendBookingEmailError,
        sendAmendBookingRequestEmailSuccess: state.booking.sendAmendBookingRequestEmailSuccess,
        sendCancelBookingRequestEmailSuccess: state.booking.sendCancelBookingRequestEmailSuccess
    }));

    /**
     * local state management
     */
    const [singleBooking, setSingleBooking] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [showAccept, setShowAccept] = useState<boolean>(true);
    const [yesNoHide, setYesNoHide] = useState<boolean>(false);
    const [newCheckIn, setNewCheckIn] = useState<string>('');
    const [newCheckOut, setNewCheckOut] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    /**
     * react life cycle 
     */
    useEffect(() => {
        if (id) {
            getBookingById({ bookingID: id });
        }
    }, [getBookingById, id]);

    /**
         * back to the list page
         */
    const BackToList = useCallback(() => {
        push(`/admin/booker-booking-reports`);
    }, [push])

    /**
     * response booking manage
     */
    useEffect(() => {
        if (getBookingByIdSuccess?.data) {
            setSingleBooking(getBookingByIdSuccess.data);
        }

        if (getBookingStatusSuccess?.data) {
            setLoading(false);

            // BackToList();
            toast.success('Booking status updated successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });

            if (showModal) {
                setShowModal(false);
                BackToList();
            }
        }

        if (getBookingStatusError) {
            setLoading(false);
            toast.error(getBookingStatusError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }, [BackToList, getBookingByIdSuccess, getBookingStatusError, getBookingStatusSuccess, showModal]);


    /**
  * amend booking request
  */
    useEffect(() => {
        if (getAmendBookingRequestSuccess?.data) {
            setLoading(false);
            const payload = {
                brandId: singleBooking?.brandId?._id,
                city: singleBooking?.propertyId?.city,
                propertyName: singleBooking?.propertyId?.propertyName,
                toEmail: singleBooking?.propertyId?.systemNotificationEmail,
                propertyId: singleBooking?.propertyId?._id
            }

            sendAmendBookingRequestEmail(payload);
            BackToList();
        }

        if (getAmendBookingRequestError) {
            setLoading(false);
            toast.error(getAmendBookingRequestError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }, [BackToList, getAmendBookingRequestError, getAmendBookingRequestSuccess?.data, sendAmendBookingRequestEmail, singleBooking?.brandId?._id, singleBooking?.propertyId?._id, singleBooking?.propertyId?.city, singleBooking?.propertyId?.propertyName, singleBooking?.propertyId?.systemNotificationEmail])



    useEffect(() => {
        // if (getAcceptAmendBookingRequestSuccess?.data) {
        //     setLoading(false);

        //     if (acceptChange === CommonAnswer.YES) {
        //         if (singleBooking?.bookerUserId?.email === singleBooking?.travelerId?.email) {
        //             const payload = {
        //                 firstName: singleBooking?.bookerUserId?.firstName,
        //                 lastName: singleBooking?.bookerUserId?.lastName,
        //                 propertyName: singleBooking?.propertyId?.propertyName,
        //                 email: singleBooking?.bookerUserId?.email,
        //                 travelerFirstName: singleBooking?.travelerId?.firstName,
        //                 travelerLastName: singleBooking?.travelerId?.lastName
        //             }

        //             sendAcceptAmendBookingEmail(payload);

        //         } else {
        //             const payload = {
        //                 firstName: singleBooking?.travelerId?.firstName,
        //                 lastName: singleBooking?.travelerId?.lastName,
        //                 propertyName: singleBooking?.propertyId?.propertyName,
        //                 email: singleBooking?.travelerId?.email,
        //                 travelerFirstName: singleBooking?.travelerId?.firstName,
        //                 travelerLastName: singleBooking?.travelerId?.lastName
        //             }

        //             sendAcceptAmendBookingEmail(payload);
        //             if (isBooker()) {
        //                 const payload = {
        //                     firstName: singleBooking?.bookerUserId?.firstName,
        //                     lastName: singleBooking?.bookerUserId?.lastName,
        //                     propertyName: singleBooking?.propertyId?.propertyName,
        //                     email: singleBooking?.bookerUserId?.email,
        //                     travelerFirstName: singleBooking?.travelerId?.firstName,
        //                     travelerLastName: singleBooking?.travelerId?.lastName
        //                 }

        //                 sendAcceptAmendBookingEmail(payload);
        //             }
        //             if (!isBooker()) {
        //                 const payload = {
        //                     firstName: singleBooking?.bookerUserId?.firstName,
        //                     lastName: singleBooking?.bookerUserId?.lastName,
        //                     propertyName: singleBooking?.propertyId?.propertyName,
        //                     email: singleBooking?.bookerUserId?.email,
        //                     travelerFirstName: singleBooking?.travelerId?.firstName,
        //                     travelerLastName: singleBooking?.travelerId?.lastName
        //                 }

        //                 sendAcceptAmendBookingEmail(payload);
        //             }


        //         }
        //     } else {
        //         if (singleBooking?.bookerUserId?.email === singleBooking?.travelerId?.email) {
        //             const payload = {
        //                 firstName: singleBooking?.bookerUserId?.firstName,
        //                 lastName: singleBooking?.bookerUserId?.lastName,
        //                 propertyName: singleBooking?.propertyId?.propertyName,
        //                 email: singleBooking?.bookerUserId?.email,
        //                 travelerFirstName: singleBooking?.travelerId?.firstName,
        //                 travelerLastName: singleBooking?.travelerId?.lastName
        //             }

        //             sendNoAcceptAmendBookingEmail(payload);

        //         } else {
        //             const payload = {
        //                 firstName: singleBooking?.travelerId?.firstName,
        //                 lastName: singleBooking?.travelerId?.lastName,
        //                 propertyName: singleBooking?.propertyId?.propertyName,
        //                 email: singleBooking?.travelerId?.email,
        //                 travelerFirstName: singleBooking?.travelerId?.firstName,
        //                 travelerLastName: singleBooking?.travelerId?.lastName
        //             }

        //             sendNoAcceptAmendBookingEmail(payload);
        //             if (isBooker()) {
        //                 const payload = {
        //                     firstName: singleBooking?.bookerUserId?.firstName,
        //                     lastName: singleBooking?.bookerUserId?.lastName,
        //                     propertyName: singleBooking?.propertyId?.propertyName,
        //                     email: singleBooking?.bookerUserId?.email,
        //                     travelerFirstName: singleBooking?.travelerId?.firstName,
        //                     travelerLastName: singleBooking?.travelerId?.lastName
        //                 }

        //                 sendNoAcceptAmendBookingEmail(payload);
        //             }
        //             if (!isBooker()) {
        //                 const payload = {
        //                     firstName: singleBooking?.bookerUserId?.firstName,
        //                     lastName: singleBooking?.bookerUserId?.lastName,
        //                     propertyName: singleBooking?.propertyId?.propertyName,
        //                     email: singleBooking?.bookerUserId?.email,
        //                     travelerFirstName: singleBooking?.travelerId?.firstName,
        //                     travelerLastName: singleBooking?.travelerId?.lastName
        //                 }

        //                 sendNoAcceptAmendBookingEmail(payload);
        //             }


        //         }
        //     }

        //     BackToList();

        // }


        if (sendAcceptAmendBookingEmailError) {
            setLoading(false);
            toast.error(sendAcceptAmendBookingEmailError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }

        if (sendNoAcceptAmendBookingEmailError) {
            setLoading(false);
            toast.error(sendNoAcceptAmendBookingEmailError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }, [sendAcceptAmendBookingEmailError, sendNoAcceptAmendBookingEmailError])


    /**
     * cancel button show hide
     */
    const CancelBtnAct = () => {

        setShowModal(true);
    }

    /**
     * cancel pending
     */
    const cancelPending = () => {
        setLoading(true);

        const clientId = getStorage('selectedFilterClient')?.value ? getStorage('selectedFilterClient')?.value : getStorage('programmeData')?.clientId?._id;
        const programmeId = getStorage('selectedProgramsOption')?.value ? getStorage('selectedProgramsOption')?.value : getStorage('programmeData')?._id;


        const payload = {
            clientId: clientId,
            programmeId: programmeId,
            brandId: singleBooking?.brandId?._id,
            // inventoryId: singleBooking?.inventoryId?._id,
            propertyId: singleBooking?.propertyId?._id,
            bookerId: singleBooking?.bookerUserId?._id,
            bookingStatus: LIVE_BOOKING_STATUS.CANCEL_PENDING,
            id: singleBooking?.liveBookingId,
            cancelRequestDate: new Date()
        }

        bookingStatus(payload);

        const payload2 = {
            firstName: singleBooking?.travelerId?.firstName,
            lastName: singleBooking?.travelerId?.lastName,
            clientId: clientId,
            programmeId: programmeId,
            toEmail: singleBooking?.propertyId?.systemNotificationEmail,
            clientName: singleBooking?.clientId?.companyName,
            propertyName: singleBooking?.propertyId?.propertyName,
            city: singleBooking?.propertyId?.city,
            brandId: singleBooking?.brandId?._id,
            propertyId: singleBooking?.propertyId?._id,
        }
        sendCancelBookingRequestEmail(payload2);

    };


    const amendBooking = () => {
        if (!newCheckIn && !newCheckOut) {
            toast.warning(`Please provide check-in and check-out dates`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            return 0;
        }

        const payload = {
            clientId: singleBooking?.clientId?._id,
            programmeId: singleBooking?.programmeId?._id,
            brandId: singleBooking?.brandId?._id,
            inventoryId: singleBooking?.liveBookingId,
            propertyId: singleBooking?.propertyId?._id,
            bookerId: singleBooking?.bookerUserId?._id,
            bookingStatus: LIVE_BOOKING_STATUS.AMEND_PENDING,
            newCheckInAmendDate: newCheckIn,
            newCheckOutAmendDate: newCheckOut,
            travelerName: singleBooking?.travelerId?.firstName + '' + singleBooking?.travelerId?.lastName,
            clientName: singleBooking?.clientId?.companyName,
            email: singleBooking?.propertyId?.systemNotificationEmail,
            amendRequestDate: new Date(),
        }
        setLoading(true);
        amendBookingRequest(payload);


    };

    const onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'yes') {
            setYesNoHide(true);
        } else {
            setYesNoHide(false);
            setOpenModal(false);
        }
    };


    const disabledFromDate = useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            (minDate != null && !d.isAfter(minDate) && d.isBefore(minDate, 'day'))
        );
    }, [minDate]);

    /**
     * get date range
     */
    const onFromDateChange = useCallback((dates, dateStrings) => {
        setNewCheckIn(moment(new Date(dateStrings[0])).format());
        setNewCheckOut(moment(new Date(dateStrings[1])).format());
    }, []);


    const accept = () => {
        const payload = {
            clientId: singleBooking?.clientId?._id,
            programmeId: singleBooking?.programmeId?._id,
            brandId: singleBooking?.brandId?._id,
            // inventoryId: singleBooking?.inventoryId?._id,
            propertyId: singleBooking?.propertyId?._id,
            bookerId: singleBooking?.bookerUserId?._id,
            bookingStatus: LIVE_BOOKING_STATUS.AMEND_PENDING,
            isAcceptAmendmentTerm: true,
            id: singleBooking?.liveBookingId
        }
        setLoading(true);
        bookingStatus(payload);
    };
    return (<>
        <Row >
            <Col md={6}>

                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/corporate-booking-reports" }} >Booking Detail</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{singleBooking?.propertyId?.propertyName}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col md={6}>
                <Button id='blue-button' style={{ width: '190px', marginLeft: '110px' }} >
                    <b>Message Property</b>
                </Button>

                {
                    (singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.UP_COMING) &&

                    <Button id='edit-button' style={{ width: '190px', marginLeft: '20px' }} onClick={() => { setOpenModal(true); }}>
                        <b>Amend</b>
                    </Button>

                }
                {
                    singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.UP_COMING &&

                    <Button id='megenta-button' className='' style={{ width: '190px', marginLeft: '20px', textAlign: 'center', right: '38px', zIndex: '9999' }} onClick={CancelBtnAct}>
                        <b>Cancel</b>
                    </Button>

                }
            </Col>
        </Row>


        <Row className="mt-5">
            <Col md={6} xs={12} className="text-center">

                <Image width={300} src={singleBooking?.inventoryInfo?.inventoryImage} alt={singleBooking?.inventoryInfo?.inventoryImage} thumbnail={true} style={slideImageStyle}>
                </Image>


                {/* <img width={'100%'} src={singleBooking?.inventoryInfo?.inventoryImage} alt={singleBooking?.inventoryInfo?.inventoryImage} style={{ borderRadius: 40, height: '70%' }} /> */}
            </Col>
            <Col md={6} xs={12}>
                <div className="mt-5">
                    <p style={{ fontSize: '25px' }}>Booking Ref. <strong>{singleBooking?.liveBookingId}</strong></p>
                    <p style={{ fontSize: '30px' }}><strong>{singleBooking?.propertyId?.propertyName}</strong> <span style={{ fontSize: '20px' }}>by {singleBooking?.brandId?.name}</span></p>
                    <p style={{ fontSize: '22px' }}><strong>{singleBooking?.propertyInfo?.propertyType}</strong> <span style={{ fontSize: '20px' }}>{`${singleBooking?.propertyId?.address1} ${singleBooking?.propertyId?.address2}`}</span></p>
                    <p style={{ fontSize: '20px' }}>{`${singleBooking?.inventoryInfo?.accommodationConfiguration}`}</p>
                </div>
            </Col>
        </Row >

        {/* cancel when status changed as an cancel */}
        {
            singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED && <Row className="mt-5">
                <Container fluid className="p-0">
                    <div className="jumbotron" style={{ marginTop: "0px" }}>
                    <div className="text-center" style={{ fontSize: '26px', }}>
                        <Row>
                                <Col md={2}></Col>
                                <Col md={8}>
                                    <strong>Booking Cancelled </strong>
                                </Col>
                                <Col md={2}>
                                </Col>
                            </Row>
                            <div className="text-center" style={{ fontSize: '26px', marginTop: 35 }}>
                                <Row>
                                    <Col md={2}></Col>
                                    <Col md={8}>
                                        <strong>
                                         Number of nights to be charged {(typeof singleBooking?.numberOfNightWithCharge === 'undefined' ? '0' : `${singleBooking?.numberOfNightWithCharge}`)}
                                        </strong>
                                    </Col>
                                    <Col md={2}></Col>
                                </Row>

                                <div>

                                </div>
                            </div>
                        </div>

                    </div>
                </Container>
            </Row>
        }

        {singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.AMEND_PENDING && showAccept && !singleBooking?.doesChangeIncurCancellationCharge && <Row style={{}}>
            <Container fluid className="p-0">
                <div className="jumbotron" style={{ marginTop: "" }}>
                    <div className="">
                        <h3>Booking Amendment Request - Pending</h3>
                        <div style={{ fontSize: '20px', marginTop: 35 }}>


                            <Row className="mt-5">
                                {/* <Col md={2}></Col> */}
                                <Col md={6}>
                                    <p>
                                        New Check-in: {singleBooking?.newCheckInAmendDate ? moment(singleBooking?.newCheckInAmendDate).format('D MMM YYYY') : ''}</p>
                                </Col>
                                <Col md={6}>
                                    <p>
                                        Original Check-in: {moment(singleBooking?.checkInDate).format('D MMM YYYY')}</p>
                                </Col>

                            </Row>
                            <Row >
                                {/* <Col md={2}></Col> */}
                                <Col md={6}>
                                    <p>
                                        New Check-out: {singleBooking?.newCheckOutAmendDate ? moment(singleBooking?.newCheckOutAmendDate).format('D MMM YYYY') : ''}</p>
                                </Col>
                                <Col md={6}>
                                    <p>
                                        Original Check-out: {moment(singleBooking?.checkOutDate).format('D MMM YYYY')}</p>
                                </Col>

                            </Row>

                            {/* <Row>
                               
                                <Col md={4}>
                                    <p>
                                        Accept change
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <CheckAndRadioBtn type='radio' label="Yes" name="cancelCheckbox" value={`yes`} onChange={onAcceptChangeCheckBox} loading={false} />
                                    <CheckAndRadioBtn type='radio' label="No" name="cancelCheckbox" value={`no`} onChange={onAcceptChangeCheckBox} loading={false} />
                                </Col>
                                <Col md={4}></Col>
                            </Row> */}

                            {/* {acceptChange === CommonAnswer.NO && <Row>
                                <Col md={6}>
                                    <TextAreaC name={"acceptChangeFeedback"} onChange={onChangeFeedback}></TextAreaC>
                                </Col>
                            </Row>} */}

                            {/* {acceptChange === CommonAnswer.YES &&
                                <Row>
                                
                                    <Col md={4}>
                                        <p>
                                            Does change incur a cancellation charge
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <CheckAndRadioBtn type='radio' label="Yes" name="cancelCheckbox" value={`yes`} onChange={onChargeChangeCheckBox} loading={false} />
                                        <CheckAndRadioBtn type='radio' label="No" name="cancelCheckbox" value={`no`} onChange={onChargeChangeCheckBox} loading={false} />
                                    </Col>
                                    <Col md={4}></Col>
                                </Row>} */}
                            {/* <Row>
                          
                                <Col md={4}>
                                    <ButtonC loading={loading} id="green-button" label={`Submit`} onClick={acceptAmendBooking} />
                                    <Button id='edit-button' style={{ width: '190px', marginLeft: '15px' }} onClick={() => { setShowAccept(false) }} >
                                        <b>Close</b>
                                    </Button>
                                </Col>
                           
                            </Row> */}

                        </div>
                    </div>

                </div>
            </Container>
        </Row>}



        {singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.AMEND_PENDING && showAccept && singleBooking?.doesChangeIncurCancellationCharge && !singleBooking?.isAcceptAmendmentTerm && <Row style={{}}>
            <Container fluid className="p-0">
                <div className="jumbotron" style={{ marginTop: "" }}>
                    <div className="">
                        <h3>Booking Amendment Request - Pending</h3>
                        <div style={{ fontSize: '20px', marginTop: 35 }}>


                            <Row className="mt-5">
                                {/* <Col md={2}></Col> */}
                                <Col md={6}>
                                    <p>
                                        New Check-in: {singleBooking?.newCheckInAmendDate ? moment(singleBooking?.newCheckInAmendDate).format('D MMM YYYY') : ''}</p>
                                </Col>
                                <Col md={6}>
                                    <p>
                                        Original Check-in: {moment(singleBooking?.checkInDate).format('D MMM YYYY')}</p>
                                </Col>

                            </Row>
                            <Row >
                                {/* <Col md={2}></Col> */}
                                <Col md={6}>
                                    <p>
                                        New Check-out: {singleBooking?.newCheckOutAmendDate ? moment(singleBooking?.newCheckOutAmendDate).format('D MMM YYYY') : ''}</p>
                                </Col>
                                <Col md={6}>
                                    <p>
                                        Original Check-out: {moment(singleBooking?.checkOutDate).format('D MMM YYYY')}</p>
                                </Col>

                            </Row>

                            {
                                singleBooking?.numberOfNightWithCharge &&
                                <Row style={{ marginTop: "20px" }}>
                                    {/* <Col md={2}></Col> */}
                                    <Col md={6}>
                                        <strong>
                                            Cancellation charge  (Nights) {(typeof singleBooking?.numberOfNightWithCharge === 'undefined' ? '0' : `${singleBooking?.numberOfNightWithCharge}`)}
                                        </strong>
                                    </Col>
                                    <Col md={6}></Col>
                                </Row>}


                            {
                                singleBooking?.numberOfNightWithCharge &&
                                <Row>


                                    <Col md={4}>
                                        <Card style={{ borderRadius: "11px", marginTop: "20px", backgroundColor: 'lightGrey' }}>

                                            <div style={{ marginLeft: "10px", marginTop: "10px", marginBottom: "10px", fontWeight: "bold" }}>
                                                <p>unfortunately there will be a charge of {singleBooking?.numberOfNightWithCharge}</p>
                                                <p>
                                                    nights due to the fact we are already in the
                                                </p>
                                                <p>
                                                    cancellation charge period.
                                                </p>
                                            </div>
                                        </Card>

                                    </Col>
                                    <Col md={8}></Col>
                                </Row>}


                            <Row>

                                <Col md={2}>
                                    {<Button id="green-button" style={{ width: '190px' }} onClick={accept} >
                                        Accept
                                    </Button>}

                                </Col>
                                <Col md={2}>

                                    {<Button id="megenta-button" style={{ width: '190px', marginLeft: '15px' }} onClick={() => { setShowAccept(false) }}  >
                                        Cancel Amendment
                                    </Button>
                                    }

                                </Col>
                                <Col md={2}>

                                    <Button id='edit-button' style={{ width: '190px', marginLeft: '15px' }} onClick={() => { setShowAccept(false) }} >
                                        <b>Close</b>
                                    </Button>
                                </Col>
                            </Row>

                        </div>
                    </div>

                </div>
            </Container>
        </Row>}

        {openModal && <Row style={{}}>
            <Container fluid className="p-0">
                <div className="jumbotron" style={{ marginTop: "" }}>
                    <div className="" style={{ fontSize: '20px', marginTop: 35, fontWeight: 'bold' }}>
                        <p>Booking Amendment Request</p>
                        <div style={{ fontSize: '20px', marginTop: 35, fontWeight: 'bold' }}>
                            <Row>
                                {/* <Col md={2}></Col> */}
                                <Col md={4}>
                                    <p>
                                        Do you wish to change the dates?
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <CheckAndRadioBtn type='radio' label="Yes" name="cancelCheckbox" value={`yes`} onChange={onChangeCheckBox} loading={false} />
                                    <CheckAndRadioBtn type='radio' label="No" name="cancelCheckbox" value={`no`} onChange={onChangeCheckBox} loading={false} />
                                </Col>
                                <Col md={4}></Col>
                            </Row>
                            {
                                yesNoHide && <>
                                    <Row style={{ marginTop: '2%', }}>
                                        <Col md={6}>
                                            <p> Please provide the check-in and check-out dates </p>
                                        </Col>
                                    </Row>
                                    <Row style={{ textAlign: 'left' }}>
                                        <Col md={12}>
                                            <RangePicker className="font-light" suffixIcon={<div></div>} style={{
                                                backgroundColor: 'white', borderRadius: '11px', paddingLeft: '50px',
                                                height: '46px'
                                            }}
                                                autoFocus={true}
                                                placeholder={["Check-in charge", "Check-out charge"]}
                                                disabledDate={disabledFromDate}
                                                onChange={onFromDateChange}
                                                defaultValue={[moment(new Date(singleBooking?.checkInDate), 'YYYY-MM-DD'), moment(new Date(singleBooking?.checkOutDate), 'YYYY-MM-DD')]}
                                            />
                                        </Col>
                                    </Row>

                                </>
                            }
                            {
                                yesNoHide && <>
                                    <Row className="mt-5">
                                        {/* <Col md={2}></Col> */}
                                        <Col md={6}>
                                            <p>
                                                New Check-in: {newCheckIn ? moment(newCheckIn).format('D MMM YYYY') : ''}</p>
                                        </Col>
                                        <Col md={6}>
                                            <p>
                                                Original Check-in: {moment(singleBooking?.checkInDate).format('D MMM YYYY')}</p>
                                        </Col>

                                    </Row>
                                    <Row >
                                        {/* <Col md={2}></Col> */}
                                        <Col md={6}>
                                            <p>
                                                New Check-out: {newCheckOut ? moment(newCheckOut).format('D MMM YYYY') : ''}</p>
                                        </Col>
                                        <Col md={6}>
                                            <p>
                                                Original Check-out: {moment(singleBooking?.checkOutDate).format('D MMM YYYY')}</p>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col md={4}>
                                            <ButtonC loading={loading} id="green-button" label={`Submit`} onClick={amendBooking} />
                                            <Button id='edit-button' style={{ width: '190px', marginLeft: '15px' }} onClick={() => { setOpenModal(false) }} >
                                                <b>Close</b>
                                            </Button>
                                        </Col>

                                    </Row>
                                </>}

                            <div>

                            </div>
                        </div>
                    </div>

                </div>
            </Container>
        </Row>}


        <Row className="mt-5">
            <Container fluid className="p-0">
                <div className="jumbotron" style={{ marginTop: "-20px" }}>
                    <div className="d-flex flex-row">
                        <div className="p-2">  <img width={'100%'} src={bookingdetails} alt={`booking details`} /></div>
                        <div className="p-2" style={{ fontSize: '20px' }}> <strong> Booking Details</strong>
                            {(singleBooking?.bookingStatus === 'upcoming' && singleBooking?.acceptChange) && <strong style={{ marginLeft: "80px", color: '#E7008C' }}> Booking Amended</strong>}
                            {(singleBooking?.bookingStatus === 'cancel_pending' ) && <strong style={{ marginLeft: "80px", color: '#E7008C' }}> Cancel Pending</strong>}
                        </div>
                    </div>
                    <div className="mt-3">
                        <Row>
                            <Col md={6} xs={6}>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>First Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.travelerId?.firstName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Second Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.travelerId?.lastName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Email Address</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.travelerId?.email}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Booker First Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookerUserId?.firstName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Booker Second Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookerUserId?.lastName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Booker Email</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookerUserId?.email}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Date Booked</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{moment(singleBooking?.bookingDate).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Company</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.clientId?.companyName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Programme</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.programmeId?.programmeName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Accommodation</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.inventoryInfo?.accommodationDetail}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Number of Beds</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.inventoryInfo?.noOfBedrooms}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in Method</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.propertyInfo?.propertyCheckInProcess?.checkInProcess?.name}</Col>
                                </Row>
                            </Col>

                            <Col md={6} xs={6}>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>No of Nights</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{daysBetween(new Date(singleBooking.checkInDate), new Date(singleBooking.checkOutDate))}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Average Nightly Rate (ex Tax)</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{`${singleBooking?.bookingRateAndPolicy?.dailyRate[0]?.rate} (${singleBooking?.propertyId?.currency})`}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Total Rate (ex Tax)</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{`${singleBooking?.bookingRateAndPolicy?.finalRate} (${singleBooking?.propertyId?.currency})`}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED ? `${LIVE_BOOKING_STATUS.CANCELLED}` :
                                        singleBooking?.acceptChange ? moment(singleBooking?.newCheckInAmendDate).format('D MMM YYYY') : moment(singleBooking?.checkInDate).format('D MMM YYYY')
                                    }</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in Charge Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED && (typeof singleBooking?.checkInChargeDate === 'undefined') ? `${LIVE_BOOKING_STATUS.CANCELLED}` : singleBooking?.bookingStatus !== LIVE_BOOKING_STATUS.CANCELLED ? '-' : moment(singleBooking?.checkInChargeDate).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in Time</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking.propertyInfo?.propertyCheckInProcess?.checkInTime}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-out Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED ? `${LIVE_BOOKING_STATUS.CANCELLED}` :
                                        singleBooking?.acceptChange ? moment(singleBooking?.newCheckOutAmendDate).format('D MMM YYYY') : moment(singleBooking?.checkOutDate).format('D MMM YYYY')
                                    }</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-out Charge Date</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED && (typeof singleBooking?.checkOutChargeDate === 'undefined') ? `${LIVE_BOOKING_STATUS.CANCELLED}` : singleBooking?.bookingStatus !== LIVE_BOOKING_STATUS.CANCELLED ? '-' : moment(singleBooking?.checkOutChargeDate).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-out Time</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking.propertyInfo?.propertyCheckInProcess?.checkOutTime}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Payment Method</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>Not Yet</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Type of Booking</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{singleBooking?.bookingType?.replace('_', " ")}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <Row style={{ marginTop: '10px' }}>
                <Col md={6}>
                    <Button id='edit-button' style={{ width: '190px', marginLeft: '15px' }} onClick={BackToList} >
                        <b>Close</b>
                    </Button>
                </Col>
                <Col md={6}>
                </Col>
            </Row>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                width={750}

                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <strong>
                            Are you sure you wish to cancel this booking.?<br />
                            This may result in a cancellation charge as per the<br />
                            conditions of this booking.
                        </strong>
                    </div>

                    <div>
                        <Form.Check
                            inline
                            name="cancelCheckbox"
                            value={'Yes'}
                            label="Yes"
                            type="radio"
                            onChange={() => {

                                cancelPending();
                            }}
                        />
                        <Form.Check
                            inline
                            name="cancelCheckbox"
                            value={'No'}
                            label={'No'}
                            type="radio"
                            onChange={() => {
                                BackToList();
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
            {/* <CancelBookingWithoutCharge cancelBoxDisplay={cancelBoxDisplay} ActionNo={BackToList} ActionYes={cancelPending} loading={loading} /> */}
        </Row >
    </>)

};

export default BookingSIngleView;