 import React, { createContext, useState } from 'react';

 const MyoContext = createContext(null);
 
 export const MyoProvider = ({ children }) => {
	 const [selectedBrand, setSelectedBrand] = useState(null);
	 const [selectedProperty, setSelectedProperty] = useState(null);
 
		 const values:any = {
			 selectedBrand,
			 selectedProperty,
			 setSelectedBrand: (value) => { setSelectedBrand(value)},
			 setSelectedProperty: (value) => { setSelectedProperty(value)}
		 };
 
		 return <MyoContext.Provider value={values}>{children}</MyoContext.Provider>;
 };
 
 export default MyoContext;
 