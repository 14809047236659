import { Button, Form, Input, message, Switch } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const PropertyManagementForm: React.FC<any> = ({ setFormOpen, propertyManagementDetails, propertyManagementId }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [status, setStatus] = useState<string>('active');
  const [checkValue, setCheckValue] = useState<boolean>(true);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const statusChange = useCallback((value) => {
    setCheckValue(value);
    if (value) {
      setStatus('active');
    }
    else {
      setStatus('inactive');
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (propertyManagementId) {
      setCheckValue((propertyManagementDetails.status === 'active') ? true : false);
      setStatus(propertyManagementDetails.status)
      form.setFieldsValue({
        name: propertyManagementDetails.name,
        description: propertyManagementDetails.description || '',
        status: propertyManagementDetails.status,

      })
    } else {
      setStatus('active');
      setCheckValue(true);
    }
  }, [propertyManagementDetails, propertyManagementId, form]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { createPropertyManagement, updatePropertyManagement, getPropertyManagementList } = useStoreActions<any>((actions) => ({
    createPropertyManagement: actions.property.createPropertyManagement,
    updatePropertyManagement: actions.property.updatePropertyManagement,
    getPropertyManagementList: actions.property.getPropertyManagementList,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    createPropertyManagementError,
    createPropertyManagementSuccess,
    updatePropertyManagementError,
    updatePropertyManagementSuccess,
  } = useStoreState<any>((state) => ({
    createPropertyManagementSuccess: state.property.createPropertyManagementSuccess,
    createPropertyManagementError: state.property.createPropertyManagementError,
    updatePropertyManagementError: state.property.updatePropertyManagementError,
    updatePropertyManagementSuccess: state.property.updatePropertyManagementSuccess,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getPropertyManagementList();
  }, [getPropertyManagementList]);


  ///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createPropertyManagementSuccess) {
      message.success('PropertyManagement created successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getPropertyManagementList();
    }

    if (updatePropertyManagementSuccess) {
      message.success('PropertyManagement updated successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getPropertyManagementList();
    }

    if (createPropertyManagementError) {
      message.error(createPropertyManagementError.message)
    }

    if (updatePropertyManagementError) {
      message.error(updatePropertyManagementError.message)
    }
    setLoading(false);
  }, [createPropertyManagementError, createPropertyManagementSuccess, getPropertyManagementList, setFormOpen, updatePropertyManagementError, updatePropertyManagementSuccess]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = useCallback((values) => {
    setLoading(true);
    if (propertyManagementId) {
      values.id = propertyManagementId;
      const data = {
        id: propertyManagementId,
        name: values.name,
        description: values.description,
        status: values.status ? status : 'inactive'
      }
      updatePropertyManagement(data);

    } else {
      values.status = status;
      createPropertyManagement(values);
    }

  }, [createPropertyManagement, propertyManagementId, status, updatePropertyManagement]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="user-Form w-1/2">
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please input name' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status">
          <Switch size="small" checked={checkValue} onChange={statusChange} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={loading} type="primary" htmlType="submit">
            {propertyManagementId ? 'Update' : 'Create'}
          </Button>
          {/* <Button onClick={() => setFormOpen(false)} className="ml-5">
            Close
          </Button> */}
        </Form.Item>

      </Form>
    </div>
  )
};

export default PropertyManagementForm;