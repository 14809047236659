import { Tab, Row, Col, Nav } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { navContentStyle, navStyle, tabDivStyle } from '../../../common/components-style';
import AdminCorporatePage from './AdminPage';
import CompanyUserPage from './CompanyUserPage';
import BookerUserPage from './BookerUserPage';
import TravelerUserPage from './TravelerUserPage';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getStorage } from '../../../common/storage';

const HomePage: React.FC<any> = (): JSX.Element => {
    const [tabKey, setTabKey] = useState<any>('administrator');

    const [programme, setProgramme] = useState<any>();


    const { getCorporateProgrammeById } = useStoreActions<any>((actions) => ({
    
        getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
    }));

    const {getCorporateProgrammeByIdSuccess } = useStoreState<any>((state) => ({

        getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,

    }));

    
    useEffect(() => {
        if (getStorage('programmeId')) {
            const programmeId = getStorage('programmeId');
            if (programmeId) {
                getCorporateProgrammeById(programmeId);
            }

        }

    }, [getCorporateProgrammeById]);

    useEffect(() => {
        if (getCorporateProgrammeByIdSuccess) {
            const programme = getCorporateProgrammeByIdSuccess?.data;
            setProgramme(programme);
            // setStorage('programmeData', getCorporateProgrammeByIdSuccess?.data);
        }

    }, [getCorporateProgrammeByIdSuccess]);
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div style={tabDivStyle}>
            <Tab.Container defaultActiveKey="administrator" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                <Row >
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                            <div style={{ marginTop: '0%' }}>
                                <Nav.Item>
                                    <Nav.Link eventKey="administrator">Administrators</Nav.Link>
                                </Nav.Item>
                                { <Nav.Item><Nav.Link eventKey="companyUsers">Programme Users</Nav.Link></Nav.Item>}
                                {<Nav.Item><Nav.Link eventKey="bookers">Bookers</Nav.Link></Nav.Item>}
                                { <Nav.Item><Nav.Link eventKey="travelers">Travelers</Nav.Link></Nav.Item>}

                            </div>
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content >
                            <Tab.Pane eventKey="administrator" >
                                <div style={navContentStyle}>

                                     <AdminCorporatePage programme={programme}></AdminCorporatePage>
                                </div>
                            </Tab.Pane>
                            { <Tab.Pane eventKey="companyUsers">
                                <div style={navContentStyle}>
                                <CompanyUserPage programme={programme}></CompanyUserPage>
                                </div>
                            </Tab.Pane>}
                            { <Tab.Pane eventKey="bookers">
                                <div style={navContentStyle}>
                                <BookerUserPage programme={programme}></BookerUserPage>
                                </div>
                            </Tab.Pane>}
                            {<Tab.Pane eventKey="travelers">
                                <div style={navContentStyle}>
                                <TravelerUserPage programme={programme}></TravelerUserPage>
                                </div>
                            </Tab.Pane>}

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
};

export default HomePage;