/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Button, Drawer, Form, Input, Space, Table } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';


const MYOQContactListComp: React.FC<any> = ({ options, answer, data, onAnswer, signUpStatus }): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState([]);
  const [fields, setFields] = useState(null);
  const [columns, setColumns] = useState([]);
  const [form] = Form.useForm();
  const [selected, setSelected] = useState(false);
  const [rowIndex, setRowIndex] = useState<any>(null);

  useEffect(() => {
    if (answer) {
      const contacts = answer.split('\n');
      const arr: any = [];
      _.map(contacts, (o) => {
        arr.push({
          email: o.split(';')[0] === 'undefined' ? '' : o.split(';')[0],
          firstname: o.split(';')[1] === 'undefined' ? '' : o.split(';')[1],
          lastname: o.split(';')[2] === 'undefined' ? '' : o.split(';')[2],
          phone: o.split(';')[3] === 'undefined' ? '' : o.split(';')[3],
        })
      })

      setDataSource(arr);
    }

  }, [answer]);

  const onDelete = useCallback((e, i) => {
    e.stopPropagation();
    const newArr = [...dataSource];
    newArr.splice(i, 1)
    setDataSource(newArr);
  }, [dataSource]);

  useEffect(() => {
    const fieldsTemp: any = [<Form.Item
      label="Email Address"
      name="email"
      rules={[{ required: true, message: 'Please input your email!' }, { type: "email", message: 'Please input valid email!' }]}
    >
      <Input placeholder="Email" />
    </Form.Item>];

    const columnTemp: any = [{
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },]

    if (options) {

      const optionArr = options.split(';');
      _.some(optionArr, (o, index) => {
        if (o === '0') {
          columnTemp.push({
            title: 'First Name',
            dataIndex: 'firstname',
            key: 'fname',
          });


          fieldsTemp.push(
            <Form.Item
              label="First Name"
              name="firstname"
              rules={[{}]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          );
        }

        else if (o === '1') {
          columnTemp.push({
            title: 'Last Name',
            dataIndex: 'lastname',
            key: 'lname',
          });

          fieldsTemp.push(
            <Form.Item
              label="Last or Family Name"
              name="lastname"
              rules={[{}]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          );
        }

        else if (o === '2') {
          columnTemp.push({
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
          });

          fieldsTemp.push(
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[{}]}
            >
              <Input placeholder="(999) 9999 999 999" />
            </Form.Item>
          );

        }

      });
      columnTemp.push({
        title: 'Action',
        key: 'operation',
        fixed: 'right',
        width: 100,
        render: (d, i, index) => <Button onClick={(e) => onDelete(e, index)}>Delete</Button>,
      });

    };

    setFields(fieldsTemp);
    setColumns(columnTemp);
  }, [onDelete, options]);

  const onRowClick = useCallback((row, i) => {
    setSelected(true);
    setRowIndex(i);

    form.setFieldsValue({
      email: row.email || '',
      firstname: row.firstname || '',
      lastname: row.lastname || '',
      phone: row.phone || '',
    })
  }, [form]);

  const onFinish = useCallback((values: any) => {

    form.setFieldsValue({
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
    });

    if (selected) {
      let arr = [...dataSource];
      arr.splice(rowIndex, 1, values as never);
      setDataSource(arr);
    } else {
      let arr = [...dataSource];
      arr.push(values as never);
      setDataSource(arr);
    }
    setSelected(false)



  }, [dataSource, form, rowIndex, selected]);

  const avatars = useMemo(() => {
    if (answer) {
      const contacts = answer.split('\n');

      return _.map(contacts, o => {
        const i = o.split(';')[0].substr(0, 1)
        return <Avatar className="capitalize">{i}</Avatar>
      });
    } else {
      return <Avatar className="capitalize">+</Avatar>
    }

  }, [answer]);

  const answerQuestion = useCallback(() => {
    let res: any = _.map(dataSource, (o: any) => {
      return `${o.email};${o.firstname};${o.lastname};${o.phone}`
    });
    res = res.join("\n");

    onAnswer(res, data.id);
    setVisible(false);
  }, [data, dataSource, onAnswer]);


  return (
    <div>
      <div onClick={() => setVisible(true)}>
        <Avatar.Group maxCount={3} >
          {avatars}
        </Avatar.Group>
      </div>

      <Drawer
        title="Contact List"
        width={720}
        visible={visible}
        onClose={() => setVisible(false)}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right', }}>
            <Button onClick={() => setVisible(false)} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button disabled={signUpStatus === 'accepted'} onClick={answerQuestion} type="primary">
              Save
            </Button>
          </div>
        }
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
          layout="vertical"

        >
          {fields}
          <Form.Item>
            <Space>
              {selected && <Button type="primary" htmlType="submit">
                Update
              </Button>}
              {!selected && <Button type="primary" htmlType="submit">
                Add
              </Button>}
            </Space>
          </Form.Item>
        </Form>
        <Table
          key={Math.random()}
          onRow={(r, i): any => ({ onClick: () => onRowClick(r, i) })}
          dataSource={dataSource}
          columns={columns}
        />
      </Drawer>
    </div>
  )
};

export default MYOQContactListComp;