import { FC, useEffect, useMemo, useState } from "react";
import { Breadcrumb, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { formButton, selectStyles2 } from "../../../../common/components-style";
import { getStorage, setStorage } from "../../../../common/storage";
import moment from 'moment';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { RFPSubmission } from "../../../../common/interfaces";
// import { getLoggedUserType } from "../../../../common/functions";
import { UserGroups, brandRfpStatus } from "../../../../common/constants";
import { brandUser } from "../../../../common/functions";
import Select from "react-select";

const checkBoxStyle = { display: 'inline', top: '0px', marginLeft: '10px', position: 'relative' } as React.CSSProperties;

const SubmittedRfpSubmission: FC<any> = ({ myRfpsData }) => {
	//////////////// form initialize ///////////////////
	const { register, handleSubmit, formState: { errors } } = useForm<RFPSubmission>();
	////////////// local state ////////////////////////
	const [loading, setLoading] = useState<boolean>(false);
	const [brandDetailsConfirmationBtn] = useState<boolean>(true);
	const [brandEvaluationSubmission] = useState<boolean>(true);
	const [surveyIDS, setSurveyIDS] = useState<any>();
	const [btnEnable, setBtnEnable] = useState<boolean>(false);
	const [templateId, setTemplateId] = useState<any>();
	const [operatorUser, setOperatorUser] = useState<Array<any>>([]);
	const [userList, setUserList] = useState<Array<any>>([]);
	const [userListOptions, setUserListOptions] = useState<Array<any>>([]);
	const [accountManagerData, setAccountManagerData] = useState<any>();
	//////////// global action /////////////
	const {
		submitRFP,
		viewCertificate,
		SurveySubmission,
		getTemplate,
		getUserMapping,
		addAccountManagerForClient
	} = useStoreActions<any>((actions) => ({
		updateBrandRFPData: actions.rfpProcess.setBrandRfpDetails,
		submitRFP: actions.rfpProcess.submitRFP,
		viewCertificate: actions.myoManagementModel.viewCertificate,
		SurveySubmission: actions.myoManagementModel.SurveySubmission,
		getTemplate: actions.myoManagementModel.getTemplate,
		getUserMapping: actions.user.getUserMapping,
		addAccountManagerForClient: actions.rfpProcess.addAccountManagerForClient
	}));
	//////////////////// global state //////////////////
	const { getRFPSubmissionSuccess, addAccountManagerForClientSuccess, addAccountManagerForClientError, getRFPSubmissionError, getViewCertificateSuccess, getTemplateSuccess, getUserMappingSuccess } = useStoreState<any>((state) => ({
		getRFPSubmissionSuccess: state.rfpProcess.getRFPSubmissionSuccess,
		getRFPSubmissionError: state.rfpProcess.getRFPSubmissionError,
		getViewCertificateSuccess: state.myoManagementModel.getViewCertificateSuccess,
		getTemplateSuccess: state.myoManagementModel.getTemplateSuccess,
		getUserMappingSuccess: state.user.getUserMappingSuccess,
		addAccountManagerForClientSuccess: state.rfpProcess.addAccountManagerForClientSuccess,
		addAccountManagerForClientError: state.rfpProcess.addAccountManagerForClientError,
	}));
	////////////// local storage ////////////////////////
	const myoSelectedMyRfp = getStorage("myo_selected_my_rfp");
	const myoSelectedBrand = getStorage("myo_selected_my_rfp_brand");
	const myoSubmissionManagementTemp = getStorage("myo_submission_management_tem");
	const isBrand = getStorage('isBrand');

	// useEffect(() => {
	// 	if (myo_brand_evaluation_tem !== null || myoSelectedMyRfp?.brandSurveyDetailSubmission !== undefined) {
	// 		setBrandEvaluationSubmission(true);
	// 	} else {
	// 		if (myoSelectedMyRfp?.rfpSubmission) {
	// 			setBrandEvaluationSubmission(true);
	// 		} else {
	// 			setBrandEvaluationSubmission(false);
	// 		}
	// 	}
	// }, []);

	useEffect(() => {
		getUserMapping({});
	}, [getUserMapping]);

	useEffect(() => {

		if (getUserMappingSuccess) {
			const { result, poolUsers } = getUserMappingSuccess.data;

			/**
			 * brand users
			 */
			const brandUserList = brandUser(result, poolUsers);
			setOperatorUser(brandUserList);

		}

	}, [getUserMappingSuccess]);


	useEffect(() => {
		let filterUser: any = operatorUser;
		if (myoSelectedBrand) {
			filterUser = filterUser.filter((user) => user.brandId === myoSelectedBrand && user.status === 'active');
		}

		setUserList(filterUser);
	}, [myoSelectedBrand, operatorUser])

	////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getRFPSubmissionSuccess) {
			toast.success("RFP Submission submitted successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setLoading(false);
			const payload = getStorage('myo_submission_management_tem_payload');
			setStorage('myo_submission_management_tem', payload);
		}

		if (getRFPSubmissionError) {
			setLoading(false);
			toast.error(getRFPSubmissionError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
		if (addAccountManagerForClientSuccess) {
			toast.success("Account Manager submitted successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});

		}

		// if (addAccountManagerForClientSuccess) {
		// 	toast.success("Account Manager submitted successfully", {
		// 		position: toast.POSITION.BOTTOM_RIGHT,
		// 		className: 'foo-bar'
		// 	});

		// }

	}, [addAccountManagerForClientSuccess, getRFPSubmissionError, getRFPSubmissionSuccess]);

	//////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getViewCertificateSuccess) {
			// console.log(getViewCertificateSuccess, 'getViewCertificateSuccess')
			// check all sections are complete
			// let isComplete = false;

			setTemplateId(getViewCertificateSuccess?.certificate?.templateID);
			// const surveys: any = [];
			// if (getViewCertificateSuccess?.certificate?.surveys) {
			// 	getViewCertificateSuccess?.certificate?.surveys.forEach(element => {
			// 		if (element.isCompleted) {
			// 			// isComplete = true;
			// 			surveys.push(element.surveyID);
			// 		} else {
			// 			// isComplete = false;
			// 			return 0;
			// 		}
			// 	});
			// 	// setBrandDetailsConfirmationBtn(isComplete);
			// 	setSurveyIDS(surveys);
			// }
		}
	}, [getViewCertificateSuccess]);


	useEffect(() => {
		if (templateId) {
			getTemplate({
				security: {
					apiKey: process.env.REACT_APP_AUDIT_API_KEY,
					user: "superadmin@mysa.global"
				},
				id: templateId
			})
		}

	}, [getTemplate, templateId])


	useEffect(() => {
		if (getTemplateSuccess && getTemplateSuccess.template) {
			const surveys: any = [];


			getTemplateSuccess.template.surveys.forEach(element => {
				surveys.push(element.surveyID);
			});

			setSurveyIDS(surveys);

		}
	}, [getTemplateSuccess]);




	/////////////////////////////////////////////////////////////////////
	useEffect(() => {
		viewCertificate({
			security: {
				apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
				user: "superadmin@mysa.global"
			},
			id: myRfpsData?.certificateIdMYOManagement
		});
	}, [myRfpsData, myRfpsData?.certificateIdMYOManagement, viewCertificate]);

	////////////// form submission ///////////
	const onSubmit = (value) => {
		setLoading(true);
		if (moment(myoSelectedMyRfp.endDate).isSameOrBefore(moment(new Date()))) {
			toast.warning('Your RFP cannot be submitted as this RFP is now closed', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setLoading(false);
		} else {
			const payload = {
				type: 'RFP_SUBMISSION',
				rfpProcessId: myoSelectedMyRfp.rfpId,
				brandId: myoSelectedBrand,
				confirmedBy: value.confirmedBy,
				email: value.email,
			}
			setStorage('myo_submission_management_tem_payload', payload);
			submitRFP(payload);

			
			SurveySubmission({
				security: {
					apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
					user: "superadmin@mysa.global"
				},
				certificateID: myRfpsData?.certificateIdMYOManagement,
				templateID: templateId,
				surveyIDS: surveyIDS,
				brandId: myoSelectedBrand,
				submissionDetails: {
					confirmedBy: value.confirmedBy,
					email: value.email,
				},
				rfpSubmissionStatus: brandRfpStatus.SUBMITTED
			});
		}
	}


	const onSubmitData = () => {
		const payload = {

			rfpProcessId: myoSelectedMyRfp.rfpId,
			brandId: myoSelectedBrand,
			accountManager: accountManagerData?.label,

		}
		addAccountManagerForClient(payload);

	}

	useEffect(() => {
		let list: any = [];
		userList?.forEach(element => {
			let data = {
				value: element.userId,
				label: `${element.firstName} ${element.lastName}`
			};
			list.push(data);
		});

		setUserListOptions(list);
	},
		[userList]);

	const selectAccountManagerRender = useMemo(() => {
		return (
			<>

				<Select
					styles={selectStyles2}
					options={userListOptions}

					placeholder="Account Manger"
					// onChange={}
					onChange={(data) => {
						setAccountManagerData(data);

					}}
					// value={countryOption[0] ? countryOption[0] : ''}
					className="new_accDrpdDwn"
				/>

			</>
		)
	}, [userListOptions])

	return (<>
		<Row className="justify-content-between mb-2">
			<Col md={12}>
				<Breadcrumb>
					<Breadcrumb.Item onClick={() => {
						// updateBrandRFPData({
						// 	...reduxBrandDetail,
						// 	defaultActiveKey: "myRFPs",
						// 	showMyRfpBrandEvaluationPage: false,
						// 	showHomePage: true,
						// 	showMyRfpHomePage: true,
						// })
					}}>
						<Link to={{ pathname: "/admin/brand-rfp-management", state: { selectedBrandId: myRfpsData?.brandId, defaultKey: 'submittedRFPs' } }}>{'SubmittedRFPs'}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item onClick={() => {
					}} active>
						{myoSelectedMyRfp?.companyName}{' - '}{myoSelectedMyRfp?.programmeName} {' - '}{moment(myoSelectedMyRfp?.startDate).format('DD MMM YYYY')}
					</Breadcrumb.Item  >
				</Breadcrumb>
			</Col>

		</Row>
		<Container>
			<div className="jumbotron">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md={12}>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label> Company Name</Form.Label>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>
									<p className="lead">{myoSelectedMyRfp?.companyName}</p>
								</Col>
							</Row>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label> Name of programme</Form.Label>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>
									<p className="lead">{myoSelectedMyRfp?.programmeName}</p>
								</Col>
							</Row>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label> Start date</Form.Label>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>
									<p className="lead">{moment(myoSelectedMyRfp?.startDate).format('DD/MM/YYYY')}</p>
								</Col>
							</Row>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label> End date</Form.Label>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>
									<p className="lead">{moment(myoSelectedMyRfp?.endDate).format('DD/MM/YYYY')}</p>
								</Col>
							</Row>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label>Question submit date </Form.Label>
								</Col>
								<Col md={8} style={{ textAlign: 'left' }}>
									<p className="lead">{moment(myoSelectedMyRfp?.questionSubmitDate).format('DD/MM/YYYY')}</p>
								</Col>
							</Row>
							<Row className='formSpace'>
								<Col md={4} style={{ textAlign: 'right' }}>
									<Form.Label> Account Manager</Form.Label>
								</Col>
								{myoSelectedMyRfp?.accountManager && <Col md={4} style={{ textAlign: 'left' }}>
									<p className="lead">{myoSelectedMyRfp?.accountManager}</p>
								</Col>}
								{/* {!myoSelectedMyRfp?.accountManager &&
									<Col md={4} style={{ textAlign: 'left' }}>
										<div >
											{selectAccountManagerRender}
										</div>
										

									</Col>} */}
							</Row>

							{/* {!myoSelectedMyRfp?.accountManager && <Row className='formSpace' >
								<Col md={3} style={{ textAlign: 'right' }}>
								</Col>
								<Col md={5} style={{ textAlign: 'right' }}>
									{<Button id='approve-button' className="mr-btn" style={{ marginBottom: '30px', ...formButton }} onClick={() => onSubmitData()}>{'Save'} </Button>
									}

								</Col>
								<Col md={4}></Col>
							</Row>} */}



							<Row className='formSpace'>
								<Col md={2}></Col>
								<Col md={8}>
									<div style={{ background: '#e6e6e6', height: 'auto', borderRadius: '3%' }}>
										<p style={{ padding: '3%', fontWeight: 'bold' }}>We confirm we have completed all the required sections and now wish to
											submit for consideration by the client.
											<span>
												{(brandDetailsConfirmationBtn && brandEvaluationSubmission) ? <>
													{
														myoSubmissionManagementTemp !== null || myoSelectedMyRfp?.rfpSubmission !== undefined ?
															<Form.Check
																style={checkBoxStyle}
																type={`checkbox`}
																defaultChecked={true}
																disabled={true}
															/> :
															<Form.Check
																style={checkBoxStyle}
																type={`checkbox`}
																onChange={(value) => {
																	setBtnEnable(value.target.checked);
																}}
															/>
													}

												</> : <Form.Check
													style={checkBoxStyle}
													type={`checkbox`}
													disabled={true}
												/>}
											</span></p>
										<Row className='formSpace'>
											<Col md={3} style={{ textAlign: 'right' }}>
												<Form.Label>Confirmed by</Form.Label>
											</Col>
											<Col md={8} style={{ textAlign: 'left' }}>
												<Form.Control defaultValue={(myoSubmissionManagementTemp?.confirmedBy) ? `${myoSubmissionManagementTemp?.confirmedBy}` : (myoSelectedMyRfp?.rfpSubmission?.confirmedBy) ? `${myoSelectedMyRfp?.rfpSubmission?.confirmedBy}` : ``} className={`${errors.confirmedBy ? 'is-invalid' : ''}`}  {...register("confirmedBy", { required: true })} />
												<div className="invalid-feedback">Please input confirmedBy!</div>
											</Col>
											<Col md={1}></Col>
										</Row>
										<Row className='formSpace'>
											<Col md={3} style={{ textAlign: 'right' }}>
												<Form.Label>Email</Form.Label>
											</Col>
											<Col md={8} style={{ textAlign: 'left' }}>
												<Form.Control type="email" defaultValue={(myoSubmissionManagementTemp?.email) ? `${myoSubmissionManagementTemp?.email}` : (myoSelectedMyRfp?.rfpSubmission?.email) ? `${myoSelectedMyRfp?.rfpSubmission?.email}` : ``} className={`${errors.email ? 'is-invalid' : ''}`}  {...register("email", { required: true })} />
												<div className="invalid-feedback">Please input email!</div>
											</Col>
											<Col md={1}></Col>
										</Row>
										<Row className='formSpace' >
											<Col md={3} style={{ textAlign: 'right' }}>
											</Col>
											<Col md={8} style={{ textAlign: 'right' }}>
												{!loading && isBrand === UserGroups.brand_admin && <Button id='approve-button' className="mr-btn" type="submit" style={{ marginBottom: '30px', ...formButton }} disabled={!btnEnable} >{(myoSelectedMyRfp?.rfpSubmission || myoSubmissionManagementTemp) ? `Confirmed` : `Confirm`} </Button>
												}
												{
													loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
												}
											</Col>
											<Col md={1}></Col>
										</Row>
									</div>
								</Col>
								<Col md={2}></Col>
							</Row>

						</Col>
					</Row>
				</Form>
			</div>
		</Container>
	</>);
}

export default SubmittedRfpSubmission;