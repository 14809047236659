import { Button, Modal, Input} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { BrandStatus, UserGroups } from '../../../../common/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

let hideBtn = false;

const { TextArea } = Input;

function useQuery() {
	return new URLSearchParams(useLocation().search);
}


const ActionsButtons: React.FC<any> = ({ surveys, buttonData }): JSX.Element => {
	
	const [createdBrandId, setCreatedBrandId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingReject, setLoadingReject] = useState(false);
	const history = useHistory();
	const query = useQuery();
	const brandEmail = query.get('email');
	const [signUpStatus, setSignUpStatus] = useState(false);
	const [action, setAction] = useState(false);
	const [isRejectedReasonModalVisible, setIsRejectedReasonModalVisible] = useState(false);
	const [reasonForReject, setReasonForReject] = useState("");

	////////////////// get id /////////////
	const _id = query.get('_id');

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	const { onCreateBrand, onUpdateBrand, resetBrand, createProperty, onUpdateBrandSignup, onLoadSingleBrandSignup, createUserMapping, sendBrandApplicationReviewRejectedMail } = useStoreActions<any>((actions) => ({
		onCreateBrand: actions.brand.onCreateBrand,
		onUpdateBrand: actions.brand.onUpdateBrand,
		resetBrand: actions.brand.resetBrand,
		onUpdateBrandSignup: actions.brand.onUpdateBrandSignup,
		onLoadSingleBrandSignup: actions.brand.onLoadSingleBrandSignup,
		// createAuditClient: actions.audit.createAuditClient,
		// resetAudit: actions.audit.resetAudit,
		// reAssignCertificate: actions.audit.reAssignCertificate,
		createProperty: actions.property.createProperty,
		createUserMapping: actions.user.createUserMapping,
		sendBrandApplicationReviewRejectedMail : actions.brand.sendBrandApplicationReviewRejectedMail
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { createBrandSuccess, createBrandError, getSingleSignUpSuccess, updateBrandSignUpSuccess, sendBrandApplicationReviewRejectedMailSuccess, setSendBrandApplicationReviewRejectedMailError } = useStoreState<any>((state) => ({
		createBrandSuccess: state.brand.createBrandSuccess,
		createBrandError: state.brand.createBrandError,
		getSingleSignUpSuccess: state.brand.getSingleSignUpSuccess,
		// createAuditClientSuccess: state.audit.createAuditClientSuccess,
		updateBrandSignUpSuccess: state.brand.updateBrandSignUpSuccess,
		sendBrandApplicationReviewRejectedMailSuccess : state.brand.sendBrandApplicationReviewRejectedMailSuccess,
		setSendBrandApplicationReviewRejectedMailError : state.brand.setSendBrandApplicationReviewRejectedMailError
	}));

	const updateProperty = useCallback((brandId) => {
		let brandInfo: any = surveys.survey.sections[2];
		brandInfo = brandInfo.questions
		const answer = _.filter(brandInfo, { externalRef: "mysa_brand_properties" }).shift()?.answer?.answer;

		if (answer) {
			const contacts = answer.split('\n');
			const arr: any = _.map(contacts, (o) => {
				var fields = o.split(";");

				return fields;
			})
			_.each(arr, (val) => {
				const data = {
					country: val[0],
					city: val[1],
					propertyName: val[2],
					systemNotificationEmail: '',
					brandId
				}
				createProperty(data);
			});

		}
	}, [createProperty, surveys]);

	useEffect(() => {
		onLoadSingleBrandSignup(_id);
	}, [_id, onLoadSingleBrandSignup]);

	useEffect(() => {
		if (getSingleSignUpSuccess) {
			const status = getSingleSignUpSuccess.data.signUpStatus;
			if (status === 'accepted') {
				setSignUpStatus(false);
			} else {
				setSignUpStatus(true);
			}
		}

		if(updateBrandSignUpSuccess){


			if(!action){
				
				let brandInfo: any = _.first(surveys.survey.sections);
				brandInfo = brandInfo.questions;
				const brandName = _.filter(brandInfo, { externalRef: "mysa_brand_name" }).shift()?.answer?.answer;
				const fName = _.filter(brandInfo, { externalRef: "mysa_brand_main_contact_first_name" }).shift()?.answer?.answer;
				const lName = _.filter(brandInfo, { externalRef: "mysa_brand_main_contact_last_name" }).shift()?.answer?.answer;
				// const email = _.filter(brandInfo, { externalRef: "mysa_brand_main_contact_email" }).shift()?.answer?.answer;

				const data = {
					toEmail: brandEmail,
					name: `${fName} ${lName}`,
					brandName: brandName,
					comment: reasonForReject
				  }
			
				sendBrandApplicationReviewRejectedMail(data);
			}
		}

		if (sendBrandApplicationReviewRejectedMailSuccess) {
			setLoadingReject(false);
			setIsRejectedReasonModalVisible(false);
			toast.success("The rejection email sent", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setTimeout(() => {
				history.push({    // no need
					pathname: "/admin/brand-management",
					state: { brandInvitation: true }
				});
			}, 1000);
		  }
		  if (setSendBrandApplicationReviewRejectedMailError) {
			setLoadingReject(false);
			toast.error(setSendBrandApplicationReviewRejectedMailError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		  }

	}, [getSingleSignUpSuccess, updateBrandSignUpSuccess, action, surveys, sendBrandApplicationReviewRejectedMail, sendBrandApplicationReviewRejectedMailSuccess, setSendBrandApplicationReviewRejectedMailError, history, reasonForReject, brandEmail]);

	useEffect(() => {
		if (createBrandError) {
			toast.error(createBrandError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetBrand();
			setLoading(false);
		}

		if (createBrandSuccess) {

			if (createBrandSuccess.status === 400) {
				toast.error(createBrandSuccess.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				resetBrand();
				setLoading(false);
			}
			else {
				const id = query.get('_id');
				// let brandInfo: any = _.first(surveys.survey.sections);
				// brandInfo = brandInfo.questions;
				// const brandName = _.filter(brandInfo, { externalRef: "mysa_brand_name" }).shift()?.answer?.answer;
				// const fName = _.filter(brandInfo, { externalRef: "mysa_brand_main_contact_first_name" }).shift()?.answer?.answer;
				// const lName = _.filter(brandInfo, { externalRef: "mysa_brand_main_contact_last_name" }).shift()?.answer?.answer;
				// const email = _.filter(brandInfo, { externalRef: "mysa_brand_main_contact_email" }).shift()?.answer?.answer;
				// const phone = _.filter(brandInfo, { externalRef: "mysa_brand_main_contact_telephone" }).shift()?.answer?.answer;

				onUpdateBrandSignup({ id: buttonData.signUpId, signUpStatus: 'accepted' });
				updateProperty(createBrandSuccess.data.brand._id);
				setCreatedBrandId(createBrandSuccess.data.brand._id);
				onLoadSingleBrandSignup(id);
				resetBrand();
				createUserMapping({
					userId: createBrandSuccess.data.user.user._id,
					email: createBrandSuccess.data.user.user.email,
					brandId: createBrandSuccess.data.brand._id,
					role: UserGroups.brand_admin,
					isFirstUser: true
				})
				toast.success("Brand created successfully", {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				setLoading(false);
				setTimeout(() => {
					history.push({    // no need
						pathname: "/admin/brand-management",
						state: { brandInvitation: true }
					});
				}, 1000);

				// createAuditClient({
				// 	security: {
				// 		apiKey: process.env.REACT_APP_AUDIT_API_KEY,
				// 		user: "superadmin@mysa.global"
				// 	},
				// 	client: {
				// 		id: "",
				// 		parentID: "",
				// 		type: "Brand Client",
				// 		name: brandName,
				// 		email,
				// 		phone,
				// 		externalID: createBrandSuccess.data._id,
				// 		managedExternally: false,
				// 		contactInfo: {
				// 			name: `${fName} ${lName}`,
				// 			email,
				// 			id: "",
				// 			parentID: "",
				// 		}
				// 	}
				// });
			}

		}

		// if (createAuditClientSuccess) {
		// 	if (createAuditClientSuccess.clientID) {
		// 		onUpdateBrand({ id: createdBrandId, auditReference: createAuditClientSuccess.clientID });
		// 		reAssignCertificate({
		// 			ID: buttonData?.certificateId,
		// 			ClientID: createAuditClientSuccess.clientID,
		// 			security: {
		// 				apiKey: process.env.REACT_APP_AUDIT_API_KEY,
		// 				user: "superadmin@mysa.global"
		// 			},
		// 		});
		// 		resetAudit();
		// 		message.success('Brand created successfully');
		// 		setLoading(false);
		// 		setTimeout(() => {
		// 			history.push({    // no need
		// 				pathname: "/admin/brand-management",
		// 				state: { brandInvitation: true }
		// 			});
		// 		}, 1000);
		// 	}
		// }

	}, [buttonData, createBrandError, createBrandSuccess, createdBrandId, history, onLoadSingleBrandSignup, onUpdateBrand, onUpdateBrandSignup, query, resetBrand, surveys, updateProperty, createUserMapping]);

	const onAction = useCallback((isAccepted) => {
		setAction(isAccepted);
		let brandInfo: any = _.first(surveys.survey.sections);
		brandInfo = brandInfo.questions

		const brandName = _.filter(brandInfo, { externalRef: "mysa_brand_name" }).shift()?.answer?.answer;
		const fName = _.filter(brandInfo, { externalRef: "mysa_brand_main_contact_first_name" }).shift()?.answer?.answer;
		const lName = _.filter(brandInfo, { externalRef: "mysa_brand_main_contact_last_name" }).shift()?.answer?.answer;
		const email = _.filter(brandInfo, { externalRef: "mysa_brand_main_contact_email" }).shift()?.answer?.answer;

		if (isAccepted) {
			setLoading(true);
			const data = {
				email:email.trim(),
				name: brandName,
				description: "",
				telephone: "",
				mobile: "",
				userGroup: UserGroups.user,
				firstName: fName,
				lastName: lName,
				position: "",
				// certificateId: buttonData?.certificateId,
				certificateMyoManagementID: buttonData?.certificateMyoManagementID,
				brandStatus: BrandStatus.incomplete,

			}
			onCreateBrand(data);

		} else {
			setLoadingReject(true);
			onUpdateBrandSignup({ id: buttonData.signUpId, signUpStatus: 'rejected' });
		}

	}, [buttonData, onCreateBrand, onUpdateBrandSignup, surveys])

	useEffect(() => {
		hideBtn = _.some(surveys?.survey?.sections, x => x.qaPass === false) || !signUpStatus;
	}, [signUpStatus, surveys])

	const getReason = useCallback((event) => {
		setReasonForReject(event.target.defaultValue);
	}, []);

	return (
		<>
			<div className="flex justify-between mb-md-0 mb-3" >
				{hideBtn || <div className='text-center clear-both ' style={{ marginLeft: '0px' }}>
					<Button loading={loading} onClick={() => onAction(1)} type="primary" className="m-5" size="large">
						Accept
					</Button>
					<Button onClick={() => setIsRejectedReasonModalVisible(true)} type="primary" danger size="large">
						Reject
					</Button>
				</div>}
			</div>
			<Modal title="Reason for rejection" visible={isRejectedReasonModalVisible}
				footer={[
					<Button loading={loadingReject} onClick={() => onAction(0)} type="primary" className="m-5" size="large">
						Submit
					</Button>,
					<Button type="default" className="m-5" size="large" onClick={() => setIsRejectedReasonModalVisible(false)}>
						Close
					</Button>
				]}>
				<TextArea rows={4} placeholder="Reason for rejection..." onKeyPress={(e) => getReason(e)} />
			</Modal>
		</>
	)
}


export default ActionsButtons;