import { ErrorMessage } from "@hookform/error-message";
import { DatePicker, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { formButton } from "../../../../../common/components-style";
import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from "moment";
import { REQUEST_BOOKING_STATUS } from "../../../../../common/constants";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import { daysBetween } from "../../../../../common/functions";

type TAcceptBooking = {
    yesNo: String,
    offerExpires: String,
    time: String,
    altProperty: String
    offerReducer: String
    inventory: String
    reducedRate: String
};

type TAcceptBookingView = {
    data: any,
    _id: String | undefined,
    _reqGroupId: String | undefined,
    _reqBrandId: String | undefined,
    _reqPropertyId: String | undefined,
    _reqInventoryId: String | undefined,
    hideShowViewComponent: ((value: boolean) => void)
};

const AcceptBooking: React.FunctionComponent<TAcceptBookingView> = ({ _id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, hideShowViewComponent }) => {

    //////////// form handle ///////////////////////
    const { register, handleSubmit, formState: { errors }, control, reset, setValue } = useForm<TAcceptBooking>();

    ///////////////////////////global action///////////////////////////////////
    const { getRate, updateRequestStatus, setReset, getMessageThreadById, getInventoryList } = useStoreActions<any>((actions) => ({
        updateRequestStatus: actions.booking.updateRequestStatus,
        getMessageThreadById: actions.booking.getMessageThreadById,
        setReset: actions.booking.setReset,
        getInventoryList: actions.inventory.getInventoryList,
        getRate: actions.booking.getRate,
    }));

    /////////////////// global state ////////////////
    const { getRateSuccess, getUpdateRequestStatusSuccess, getUpdateRequestStatusError, getMessageThreadByIdSuccess, getInventoryListSuccess } = useStoreState<any>((state) => ({
        getMessageThreadByIdSuccess: state.booking.getMessageThreadByIdSuccess,
        getUpdateRequestStatusSuccess: state.booking.getUpdateRequestStatusSuccess,
        getUpdateRequestStatusError: state.booking.getUpdateRequestStatusError,
        getInventoryListSuccess: state.inventory.getInventoryListSuccess,
        getRateSuccess: state.booking.getRateSuccess,
    }));

    ////////////////// local state //////////////
    const [loading, setLoading] = useState<boolean>(false);
    const [hideShowView, setHideShowView] = useState<boolean>(false);
    const [hideShowInventories, setHideShowInventories] = useState<boolean>(false);
    const [hideShowOfferReducer, setHideShowOfferReducer] = useState<boolean>(false);
    const [hideShowAcceptAlternativeView, setHideShowAcceptAlternativeView] = useState<boolean>(false);
    const [rateTextShowHide, setRateTextShowHide] = useState<boolean>(false);
    const [data, setData] = useState<any>();
    const [getRateDynamically, setRateDynamically] = useState<string>();
    const [inventories, setInventories] = useState<any>();

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };

    useEffect(() => {
        setValue('offerExpires', String(moment(data?.expiryDetails?.offerExpiresAt).add(1, 'days')));
        setValue('time', String(data?.expiryDetails?.offerExpiresAt ? moment(data?.expiryDetails?.offerExpiresAt?.substring(11, 19), 'HH:mm') : moment()));
    }, [data, setValue]);

    useEffect(() => {
        if (_id && _reqBrandId && _reqGroupId && _reqInventoryId && _reqPropertyId) {
            const payload = {
                _id: _id,
                _reqBrandId: _reqBrandId,
                _reqGroupId: _reqGroupId,
                _reqInventoryId: _reqInventoryId,
                _reqPropertyId: _reqPropertyId
            }
            getMessageThreadById(payload);

        }
    }, [_id, _reqBrandId, _reqGroupId, _reqInventoryId, _reqPropertyId, getMessageThreadById])

    //////////////////////////////////
    useEffect(() => {
        if (getRateSuccess) {
            setRateDynamically(getRateSuccess.data.finalRate);
        }

        if (getMessageThreadByIdSuccess) {
            setData(getMessageThreadByIdSuccess.data);
            if (Boolean(data?.expiryDetails?.offerExtended)) {
                setHideShowView(true);
            };
            if (data?.expiryDetails?.offerExpiresAt) {
                setValue('offerExpires', String(moment(data?.expiryDetails?.offerExpiresAt).format('YYYY-MM-DD')));
                setValue('time', String(data?.expiryDetails?.offerExpiresAt?.substring(11, 19)));
            }
        }

        if (getUpdateRequestStatusSuccess) {
            setLoading(false);
            setReset();
            reset();
            toast.success("Accept booking data updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            hideShowViewComponent(false);
        }

        if (getUpdateRequestStatusError) {
            setLoading(false);
            setReset();
            toast.error(getUpdateRequestStatusError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [data, getRateSuccess, getMessageThreadByIdSuccess, getUpdateRequestStatusError, getUpdateRequestStatusSuccess, hideShowViewComponent, reset, setReset, setValue]);

    useEffect(() => {

        if (getInventoryListSuccess) {
            setInventories(getInventoryListSuccess.data.inventory);
        } else {
            if (data?.property?.propertyId) {
                const params: any = {};
                params.propertyId = data?.property?.propertyId
                getInventoryList(params);
            }
        }

    }, [getInventoryList, getInventoryListSuccess, data]);

    //////////////// submit /////////
    const onSubmit = (values) => {
        setLoading(true);
        const time = values?.time.length === 8 ? `${moment(values?.offerExpires).format('YYYY-MM-DD')}T${values?.time}.000Z` : `${moment(values?.offerExpires).format('YYYY-MM-DD')}T${moment(values?.time).format('HH:mm')}:00.000Z`


        /**
         * Accept booking YES
         */
        if (values.yesNo === 'Yes') {

            const isBefore = moment(time).isSameOrBefore(data?.checkInDate);
            //   const isAfter = moment(time).isAfter(data?.checkInDate);


            if (!isBefore) {
                toast.error('Expired date should to be less then check-in date', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                setLoading(false);
                return;
            }
            // else if (isAfter) {
            //     toast.error('Expired date should to be less then check-in date', {
            //         position: toast.POSITION.BOTTOM_RIGHT,
            //         className: 'foo-bar'
            //     });
            //     setLoading(false);
            //     return;
            // }
            const payload = {
                _id: _id,
                _reqGroupId: _reqGroupId,
                _reqBrandId: _reqBrandId,
                _reqPropertyId: _reqPropertyId,
                _reqInventoryId: _reqInventoryId,
                checkInDate: data?.checkInDate,
                checkOutDate: data?.checkOutDate,
                propertyId: data?.property?.propertyId,
                inventoryId: data?.inventory?.inventoryId,
                bookingStatus: REQUEST_BOOKING_STATUS.OFFERED,
                expiryDetails: {
                    offerExpiresAt: time,
                    offerExtended: true
                },

                requestUserId: data?.requestUserId
            }
            updateRequestStatus(payload);
        } else {
            /**
             * Accept booking NO
             * AND
             * Do you wish to offer an alternative in this property? NO
             * Do you wish to offer a reduced rate for this booking? NO
             */

            if (values.altProperty !== 'Yes' && values.offerReducer !== 'Yes') {
                const payload = {
                    _id: _id,
                    _reqGroupId: _reqGroupId,
                    _reqBrandId: _reqBrandId,
                    _reqPropertyId: _reqPropertyId,
                    _reqInventoryId: _reqInventoryId,
                    checkInDate: data?.checkInDate,
                    checkOutDate: data?.checkOutDate,
                    propertyId: data?.property?.propertyId,
                    inventoryId: data?.inventory?.inventoryId,
                    bookingStatus: REQUEST_BOOKING_STATUS.DECLINED,
                    expiryDetails: {
                        offerExpiresAt: time,
                        offerExtended: true
                    }
                }
                updateRequestStatus(payload);
            } else {
                /**
                 * Do you wish to offer an alternative in this property? YES
                 * Do you wish to offer a reduced rate for this booking? YES
                 */
                const isBefore = moment(time).isSameOrBefore(data?.checkInDate);
                // const isAfter = moment(time).isAfter(data?.checkInDate);

                if (!isBefore) {
                    toast.error('Expired date should to be less then check-in date', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                    setLoading(false);
                    return;
                }
                // else if (isAfter) {
                //     toast.error('Expired date should to be less then check-in date', {
                //         position: toast.POSITION.BOTTOM_RIGHT,
                //         className: 'foo-bar'
                //     });
                //     setLoading(false);
                //     return;
                // }
                if (values.altProperty === 'Yes' && values.offerReducer === 'Yes') {
                    const payload = {
                        _id: _id,
                        _reqGroupId: _reqGroupId,
                        _reqBrandId: _reqBrandId,
                        _reqPropertyId: _reqPropertyId,
                        _reqInventoryId: _reqInventoryId,
                        propertyId: data?.property?.propertyId,
                        bookingStatus: REQUEST_BOOKING_STATUS.ALT_OFFER,
                        expiryDetails: {
                            offerExpiresAt: time,
                            offerExtended: true
                        },
                        checkInDate: data?.checkInDate,
                        checkOutDate: data?.checkOutDate,
                        alternativeProperty: values.altProperty,
                        reduceRate: values.offerReducer,
                        inventoryId: values.inventory,
                        rateAndCurrency: {
                            currency: data?.rateAndCurrency?.currency,
                            rate: String((Number(getRateDynamically) / daysBetween(new Date(data?.checkInDate), new Date(data?.checkOutDate))))
                        },
                        reducedOrRequestedAccommodationRateAndCurrency: {
                            currency: data?.rateAndCurrency?.currency,
                            rate: String(values.reducedRate)
                        }
                    }
                    updateRequestStatus(payload);
                } else {
                    /**
                     * Do you wish to offer an alternative in this property? YES
                     * Do you wish to offer a reduced rate for this booking? NO
                     */
                    const isBefore = moment(time).isSameOrBefore(data?.checkInDate);
                    // const isAfter = moment(time).isAfter(data?.checkInDate);

                    if (!isBefore) {
                        toast.error('Expired date should to be less then check-in date', {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                        setLoading(false);
                        return;
                    }
                    //  else if (isAfter) {
                    //     toast.error('Expired date should to be less then check-in date', {
                    //         position: toast.POSITION.BOTTOM_RIGHT,
                    //         className: 'foo-bar'
                    //     });
                    //     setLoading(false);
                    //     return;
                    // }
                    if (values.altProperty === 'Yes' && values.offerReducer !== 'Yes') {
                        const payload = {
                            _id: _id,
                            _reqGroupId: _reqGroupId,
                            _reqBrandId: _reqBrandId,
                            _reqPropertyId: _reqPropertyId,
                            _reqInventoryId: _reqInventoryId,
                            propertyId: data?.property?.propertyId,
                            bookingStatus: REQUEST_BOOKING_STATUS.ALT_OFFER,
                            expiryDetails: {
                                offerExpiresAt: time,
                                offerExtended: true
                            },
                            checkInDate: data?.checkInDate,
                            checkOutDate: data?.checkOutDate,
                            alternativeProperty: values.altProperty,
                            reduceRate: values.offerReducer,
                            inventoryId: values.inventory,
                            rateAndCurrency: {
                                currency: data?.rateAndCurrency?.currency,
                                rate: String((Number(getRateDynamically) / daysBetween(new Date(data?.checkInDate), new Date(data?.checkOutDate))))
                            },
                            reducedOrRequestedAccommodationRateAndCurrency: {
                                currency: data?.rateAndCurrency?.currency,
                                rate: String((Number(getRateDynamically) / daysBetween(new Date(data?.checkInDate), new Date(data?.checkOutDate))))
                            }
                        }
                        updateRequestStatus(payload);
                    } else {
                        /**
                         * Do you wish to offer an alternative in this property? NO
                         * Do you wish to offer a reduced rate for this booking? YES
                         */
                        const payload = {
                            _id: _id,
                            _reqGroupId: _reqGroupId,
                            _reqBrandId: _reqBrandId,
                            _reqPropertyId: _reqPropertyId,
                            _reqInventoryId: _reqInventoryId,
                            propertyId: data?.property?.propertyId,
                            inventoryId: data?.inventory?.inventoryId,
                            bookingStatus: REQUEST_BOOKING_STATUS.ALT_OFFER,
                            expiryDetails: {
                                offerExpiresAt: time,
                                offerExtended: true
                            },
                            checkInDate: data?.checkInDate,
                            checkOutDate: data?.checkOutDate,
                            alternativeProperty: values.altProperty,
                            reduceRate: values.offerReducer,
                            reducedOrRequestedAccommodationRateAndCurrency: {
                                currency: data?.rateAndCurrency?.currency,
                                rate: String(values.reducedRate)
                            }
                        }
                        updateRequestStatus(payload);
                    }
                }
            }
        }
    }

    const getInventoryRate = (inventoryId: string) => {

        const payload = {
            propertyId: data.property.propertyId,
            propertyInventoryId: inventoryId,
            clientId: data.clientId,
            checkInDate: data.checkInDate,
            checkOutDate: data.checkOutDate
        };

        getRate(payload);
    }

    return (<>
        <Form >
            <Row className='formSpace'>
                <Col md={5} style={{ textAlign: 'right' }}></Col>
                <Col md={7} style={{ textAlign: 'left' }}>
                    <Row>
                        <Col md={4} >  <Form.Label>Accept booking</Form.Label></Col>
                        <Col md={8} >
                            <>
                                {
                                    data?.expiryDetails ? <>
                                        <Form.Check
                                            inline
                                            {...register("yesNo", { required: true })}
                                            value={'Yes'}
                                            label={'Yes'}
                                            type="radio"
                                            onChange={() => {
                                                setHideShowView(true);
                                                setHideShowAcceptAlternativeView(false);
                                            }}
                                            checked={hideShowView}
                                        />
                                        <Form.Check
                                            inline
                                            {...register("yesNo", { required: true })}
                                            value={'No'}
                                            label={'No'}
                                            type="radio"
                                            onChange={() => {
                                                setHideShowView(false);
                                                setHideShowAcceptAlternativeView(true);
                                            }}
                                            checked={!hideShowView}
                                        />
                                    </> : <>
                                        <Form.Check
                                            inline
                                            {...register("yesNo", { required: true })}
                                            value={'Yes'}
                                            label={'Yes'}
                                            type="radio"
                                            onChange={() => {
                                                setHideShowView(true);
                                                setHideShowAcceptAlternativeView(false);
                                            }}

                                        />
                                        <Form.Check
                                            inline
                                            {...register("yesNo", { required: true })}
                                            value={'No'}
                                            label={'No'}
                                            type="radio"
                                            onChange={() => {
                                                setHideShowView(false);
                                                setHideShowAcceptAlternativeView(true);
                                            }}
                                        />
                                    </>
                                }

                            </>
                            <ErrorMessage
                                errors={errors}
                                name="yesNo"
                                render={({ message }) => <div className="invalid-feedback-custom">Please choose one!</div>}
                            />
                        </Col>
                    </Row>
                    {
                        hideShowView && !hideShowAcceptAlternativeView && <>
                            <Row className='formSpace'>
                                <Col md={4} >  <Form.Label>Offer expires</Form.Label></Col>
                                <Col md={8} >
                                    <Controller
                                        name="offerExpires"
                                        control={control}
                                        rules={
                                            { required: true }
                                        }
                                        render={({ field }) =>
                                            <DatePicker defaultValue={moment(data?.expiryDetails?.offerExpiresAt).add(1, 'days')} format="YYYY-MM-DD" disabledDate={disabledDate} className={`form-control ${errors.offerExpires ? 'is-invalid' : ''}`} onChange={(date) => field.onChange(date)} />}
                                    />

                                    <ErrorMessage
                                        errors={errors}
                                        name="offerExpires"
                                        render={({ message }) => <div className="invalid-feedback-custom">Please select offer expires!</div>}
                                    />
                                </Col>
                            </Row>
                            <Row className='formSpace'>
                                <Col md={4} >  <Form.Label>Time</Form.Label></Col>
                                <Col md={8} >
                                    <Controller
                                        name="time"
                                        control={control}
                                        rules={
                                            { required: true }
                                        }
                                        render={({ field }) =>
                                            <TimePicker defaultValue={data?.expiryDetails?.offerExpiresAt ? moment(data?.expiryDetails?.offerExpiresAt?.substring(11, 19), 'HH:mm') : moment()} format="HH:mm" className={`form-control ${errors.offerExpires ? 'is-invalid' : ''}`} onChange={(time) => field.onChange(time)} />

                                        } />
                                    <ErrorMessage
                                        errors={errors}
                                        name="time"
                                        render={({ message }) => <div className="invalid-feedback-custom">Please select time!</div>}
                                    />
                                </Col>
                            </Row>
                        </>
                    }
                    {
                        hideShowAcceptAlternativeView &&
                        <Row className='formSpace'>
                            <Col md={9} >  <Form.Label>Do you wish to offer an alternative in this property?</Form.Label></Col>
                            <Col md={3} >
                                <>
                                    <Form.Check
                                        inline
                                        {...register("altProperty", { required: true })}
                                        value={'Yes'}
                                        label={'Yes'}
                                        type="radio"
                                        onChange={() => {
                                            setHideShowInventories(true);
                                        }}
                                    />
                                    <Form.Check
                                        inline
                                        {...register("altProperty", { required: true })}
                                        value={'No'}
                                        label={'No'}
                                        type="radio"
                                        onChange={() => {
                                            setHideShowInventories(false);
                                        }}
                                    />

                                </>
                                <ErrorMessage
                                    errors={errors}
                                    name="altProperty"
                                    render={({ message }) => <div className="invalid-feedback-custom">Please choose one!</div>}
                                />
                            </Col>
                        </Row>}

                    {
                        (hideShowAcceptAlternativeView && hideShowInventories) && <>
                            <Row className='formSpace'>
                                <Col md={12} >  <Form.Label>Which inventory do you wish to offer?</Form.Label></Col>
                                <Col md={12} >
                                    <>
                                        {
                                            inventories?.map((item, index) => {
                                                return (
                                                    <>
                                                        <Form.Check
                                                            inline
                                                            {...register("inventory", { required: true })}
                                                            value={`${item._id}`}
                                                            label={`${item.accommodationDetail}`}
                                                            type="radio"
                                                            onChange={(value: any) => {
                                                                getInventoryRate(value.target.value);
                                                                setRateTextShowHide(true);
                                                            }}
                                                            disabled={data?.inventory?.inventoryId === item._id}
                                                        />
                                                    </>
                                                )
                                            })
                                        }

                                    </>
                                    <ErrorMessage
                                        errors={errors}
                                        name="inventory"
                                        render={({ message }) => <div className="invalid-feedback-custom">Please choose one!</div>}
                                    />
                                </Col>
                            </Row>
                            {
                                rateTextShowHide && <Row className='formSpace'>
                                    <Col md={12} >  <Form.Label>The rate for this property is £{(Number(getRateDynamically) / daysBetween(new Date(data?.checkInDate), new Date(data?.checkOutDate)))} per night. The rate for the requested accommodation is £{data?.rateAndCurrency?.rate} per night. </Form.Label></Col>
                                </Row>
                            }

                        </>
                    }
                    {(hideShowAcceptAlternativeView && hideShowInventories) && <Row className='formSpace'>
                        <Col md={9} >  <Form.Label>Do you wish to offer a reduced rate for this booking?</Form.Label></Col>
                        <Col md={3} >
                            <>
                                <Form.Check
                                    inline
                                    {...register("offerReducer", { required: true })}
                                    value={'Yes'}
                                    label={'Yes'}
                                    type="radio"
                                    onChange={() => {
                                        setHideShowOfferReducer(true);
                                    }}
                                />
                                <Form.Check
                                    inline
                                    {...register("offerReducer", { required: true })}
                                    value={'No'}
                                    label={'No'}
                                    type="radio"
                                    onChange={() => {
                                        setHideShowOfferReducer(false);
                                    }}
                                />

                            </>
                            <ErrorMessage
                                errors={errors}
                                name="offerReducer"
                                render={({ message }) => <div className="invalid-feedback-custom">Please choose one!</div>}
                            />
                        </Col>
                    </Row>}

                    {
                        hideShowOfferReducer && <Row className='formSpace'>
                            <Col md={8} >  <Form.Label>What nightly rate would you like to offer?</Form.Label></Col>
                            <Col md={4} >
                                <Form.Control type="number" className={`form-control ${errors.reducedRate ? 'is-invalid' : ''}`} {...register("reducedRate", { required: true })} />
                                <ErrorMessage
                                    errors={errors}
                                    name="reducedRate"
                                    render={({ message }) => <div className="invalid-feedback-custom">Please enter your rate!</div>}
                                />
                            </Col>
                        </Row>
                    }
                    {hideShowAcceptAlternativeView && hideShowInventories && <Row className='formSpace'>
                        <Col md={4} >  <Form.Label>Offer expires</Form.Label></Col>
                        <Col md={8} >
                            <Controller
                                name="offerExpires"
                                control={control}
                                rules={
                                    { required: true }
                                }
                                render={({ field }) =>
                                    <DatePicker defaultValue={moment(data?.expiryDetails?.offerExpiresAt).add(1, 'days')} format="YYYY-MM-DD" disabledDate={disabledDate} className={`form-control ${errors.offerExpires ? 'is-invalid' : ''}`} onChange={(date) => field.onChange(date)} />}
                            />

                            <ErrorMessage
                                errors={errors}
                                name="offerExpires"
                                render={({ message }) => <div className="invalid-feedback-custom">Please select offer expires!</div>}
                            />
                        </Col>
                    </Row>}
                    {hideShowAcceptAlternativeView && hideShowInventories && <Row className='formSpace'>
                        <Col md={4} >  <Form.Label>Time</Form.Label></Col>
                        <Col md={8} >
                            <Controller
                                name="time"
                                control={control}
                                rules={
                                    { required: true }
                                }
                                render={({ field }) =>
                                    <TimePicker defaultValue={data?.expiryDetails?.offerExpiresAt ? moment(data?.expiryDetails?.offerExpiresAt?.substring(11, 19), 'HH:mm') : moment()} format="HH:mm" className={`form-control ${errors.offerExpires ? 'is-invalid' : ''}`} onChange={(time) => field.onChange(time)} />

                                } />
                            <ErrorMessage
                                errors={errors}
                                name="time"
                                render={({ message }) => <div className="invalid-feedback-custom">Please select time!</div>}
                            />
                        </Col>
                    </Row>}


                    <Row className='formSpace'>
                        <Col md={12} >
                            {!loading &&
                                <Button id='approve-button' style={formButton} onClick={() => handleSubmit(onSubmit)()}><b>Submit</b></Button>
                            }
                            {!loading && <Button id='edit-button' style={{ ...formButton, marginLeft: '15px' }} onClick={() => {
                                hideShowViewComponent(false);
                            }}><b>Close</b></Button>}

                            {
                                loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                            }
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Form>
    </>);
};

export default AcceptBooking;