import { Button, Divider, Modal } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from "react-router-dom";
import logo from '../../../../images/logoNew.png';
import AuditSection from '../../../admin/property-management/form/audit/AuditSection';
import QuestionsHome from '../../../admin/property-management/form/audit/questions/QuestionsHome';
import { toast } from 'react-toastify';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const SignUpFormHome: React.FC<any> = (): JSX.Element => {
  const query = useQuery();
  const certificateId = query.get('certificateMyoManagementID');
  const signUpId = query.get('_id');
  const [surveyId, setSurveyId] = useState<any>(null);
  const [sectionId, setSectionId] = useState<any>(null);
  const [sections, setSections] = useState<any>([]);
  const [emptyQuestions, setEmptyQuestions] = useState<any>(true);
  const [sectionLength, setSectionLength] = useState<number>(0);
  const [completedCount, setCompletedCount] = useState<number>(0);
  const [showSubmit, setShowSubmit] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [hideButton, setHideButton] = useState<boolean>(false);


  // const imgUrl = 'https://res.cloudinary.com/mysa/image/upload/v1635762186/Asset_3_300x_zqusvb.png';
  const { getAuditCertificate, getAuditSurveys, submitSurvey, resetAudit, sendBrandDetailSubmitSurveyEmail, onLoadSingleBrandSignup, onUpdateBrandSignup } = useStoreActions<any>((actions) => ({
    getAuditCertificate: actions.myoManagementModel.getAuditCertificate,
    getAuditSurveys: actions.myoManagementModel.getAuditSurveys,
    submitSurvey: actions.myoManagementModel.submitSurvey,
    resetAudit: actions.myoManagementModel.resetAudit,
    sendBrandDetailSubmitSurveyEmail: actions.brand.sendBrandDetailSubmitSurveyEmail,
    onLoadSingleBrandSignup: actions.brand.onLoadSingleBrandSignup,
    onUpdateBrandSignup: actions.brand.onUpdateBrandSignup,
  }));

  const { getAuditCertificateSuccess, getAuditSurveysSuccess, submitSurveySuccess, submitSurveyError, sendBrandDetailSubmitSurveyEmailSuccess, sendBrandDetailSubmitSurveyEmailError, getSingleSignUpSuccess } = useStoreState<any>((state) => ({
    getAuditCertificateSuccess: state.myoManagementModel.getAuditCertificateSuccess,
    getAuditSurveysSuccess: state.myoManagementModel.getAuditSurveysSuccess,
    submitSurveySuccess: state.myoManagementModel.submitSurveySuccess,
    submitSurveyError: state.myoManagementModel.submitSurveyError,
    sendBrandDetailSubmitSurveyEmailSuccess: state.brand.sendBrandDetailSubmitSurveyEmailSuccess,
    sendBrandDetailSubmitSurveyEmailError: state.brand.sendBrandDetailSubmitSurveyEmailError,
    getSingleSignUpSuccess: state.brand.getSingleSignUpSuccess,
  }));

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getAuditCertificate({
      security:
      {
        APIKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
        user: "superadmin@mysa.global"
      },
      certificateid: certificateId
    })
  }, [certificateId, getAuditCertificate]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAuditCertificateSuccess?.certificate) {
      setSurveyId(getAuditCertificateSuccess?.certificate?.surveys[0].id);
      setSections(getAuditCertificateSuccess?.certificate?.surveys[0].survey.sections);
      setSectionLength(getAuditCertificateSuccess?.certificate?.surveys[0].survey.sections.length)
    }
  }, [getAuditCertificateSuccess]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSectionClick = useCallback((section) => {
    setEmptyQuestions(false);
    setSectionId(section.id);
    setIsSelected(true);
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadSections = useMemo(() => {
    return sections.map((val, key) => {
      return <AuditSection onSectionClick={onSectionClick} surveyId={surveyId} values={val} index={key} selected={isSelected} sectionId={sectionId} />
    })
  }, [isSelected, onSectionClick, sectionId, sections, surveyId]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadSurvey = useCallback(() => {
    getAuditSurveys({
      security: {
        apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
        user: "superadmin@mysa.global"
      },
      certificatesurveyid: surveyId
    });
  }, [getAuditSurveys, surveyId]);

  /////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getSingleSignUpSuccess) {
      const signUp = getSingleSignUpSuccess.data;
      const data = {
        toEmail: signUp.email,
        name: signUp.name,
        brandName: signUp.brandName
      }

      sendBrandDetailSubmitSurveyEmail(data);
    }

  }, [getSingleSignUpSuccess, sendBrandDetailSubmitSurveyEmail]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (sendBrandDetailSubmitSurveyEmailSuccess) {
      toast.success("Your message has been sent successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
    if (sendBrandDetailSubmitSurveyEmailError) {
      toast.error(sendBrandDetailSubmitSurveyEmailError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [loadSurvey, resetAudit, sendBrandDetailSubmitSurveyEmailError, sendBrandDetailSubmitSurveyEmailSuccess, submitSurveyError, submitSurveySuccess])

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (submitSurveySuccess && submitSurveySuccess.status.errorCode === 0) {
      toast.success("Survey submitted Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      loadSurvey();
      onLoadSingleBrandSignup(signUpId);
      resetAudit();
    }
    if (submitSurveyError) {
      toast.error(submitSurveyError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [loadSurvey, onLoadSingleBrandSignup, resetAudit, signUpId, submitSurveyError, submitSurveySuccess])

  ////////////////////////////////////////////////////////////////////////////////////
  const submitSurveys = useCallback(() => {
    const post = {
      security: {
        apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
        user: "superadmin@mysa.global"
      },
      ID: surveyId,
    }
    submitSurvey(post);
    onUpdateBrandSignup({ id: signUpId, signUpStatus: 'pending' });
  }, [onUpdateBrandSignup, signUpId, submitSurvey, surveyId]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const closeModelAndBrowser = useCallback(() => {
    setOpenModal(false);
    window.close();
  }, []);


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (surveyId) {
      getAuditSurveys({
        security: {
          apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
          user: "superadmin@mysa.global"
        },
        certificatesurveyid: surveyId
      });
    }

  }, [getAuditSurveys, surveyId])

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {

    if (getAuditSurveysSuccess) {
      if (getAuditSurveysSuccess.certificate.issued || getAuditSurveysSuccess.certificateSurvey.isCompleted) {
        setShowSubmit(false);
        setOpenModal(true);
      } else {
        setShowSubmit(true);
      }
      let sectionCount: number = 0;
      sections.forEach((val, key) => {
        const sectionData = _.filter(getAuditSurveysSuccess.certificateSurvey.survey.sections, (survey) => survey.id === val.id).shift();
        let completed: boolean = true;
        sectionData.questions.forEach(q => {
          completed = !(q.answer === null) && completed;

        });
        if (completed) {
          ++sectionCount;
        }
      });
      setCompletedCount(sectionCount);
    }
  }, [getAuditSurveysSuccess, sections])

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if ((completedCount === 0 || null) || (sectionLength === 0 || null) || (completedCount !== sectionLength)) {
      setHideButton(true);
    } else{
      setHideButton(false);
    }

  }, [completedCount, sectionLength])

  ////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      <div className="fixed w-full z-10 bg-white" >
        <div className="h-20 flex items-center justify-between">
          <img width="120" src={logo} alt="logo" className="ml-10" />
          <div className="mr-5">
          </div>
        </div>
        <div className="flex flex-row justify-around">
          <div className="mysa-yellow w-full h-2"></div>
          <div className="mysa-green w-full"></div>
          <div className="mysa-blue w-full"></div>
        </div>
      </div>

      <div className="pt-20">
        <div className="survey-section">
          <div className="mt-5">
            <div className="flex justify-between">

              <div className=" inline-block m-5 text-justify items-center shadow-xl p-3 rounded-2xl" style={{ width: '65%' }}>
                <div className='mr-5'>
                  <p >
                    Thank you for applying to have your properties added to Myo, the direct corporate booking platform.
                    This application should take no longer than ten minutes to complete the brand information and no longer than two minutes per property.
                    Please ensure each question is answered correctly as this cannot be changed once submitted. You may part complete the application and return to it at a later stage, however,
                    your application will not be assessed until all questions are submitted.
                  </p>
                  <p style={{ marginTop: '15px' }}>
                    Please begin by completing the General Information section.
                  </p>
                  {/* <p style={{ marginTop: '15px' }}>
                    If you have any questions, please click here contact+brand-signup@mysa.global to contact us.
                  </p> */}
                </div>
              </div>
              <div className="m-5">
                {showSubmit && <Button onClick={() => hideButton ? '' : submitSurveys()}
                  className={!hideButton ? 'rounded-2xl text-center font-medium text-lg mysa-blue text-white' : 'text-black rounded-2xl text-center font-medium text-lg bg-gray-200 hover:bg-gray-200 hover:border-gray-200 hover:text-black  focus:text-black focus:border-gray-200  focus:bg-gray-200 hover:cursor-not-allowed focus:cursor-not-allowed  cursor-not-allowed'} style={{ height: '100px', marginTop: '15px', width: '335px' }}>
                  <div className="">
                    <div>
                      <p>Submit Survey <br></br> <span> Completed Sections ({completedCount}/{sectionLength})</span>
                      </p>
                    </div>
                  </div>
                </Button>}
              </div>
            </div>
          </div>
        </div>

        <div className="sections-section" style={{ marginLeft: "30px" }}>
          <div className="">
            {loadSections}
          </div>
        </div>
        <div className="questions-section">
          <Divider plain></Divider>
          <div className="">
            {surveyId && !emptyQuestions && < QuestionsHome emptyQuestions={emptyQuestions} buttonData={{ signUpId, certificateId }} sectionId={sectionId} surveyId={surveyId} completed={!showSubmit} />}
          </div>
        </div>

        {/* {isSelected && <div className="sections-section">
          <Divider plain></Divider>
          <div className="">
            {loadSections}
          </div>
        </div>} */}
        <>
          <Modal
            visible={openModal}
            width={750}
            closable={false}
            // onCancel={() => setOpenModal(false)}
            footer={[
              <Button key="submit" onClick={() => closeModelAndBrowser()}>
                Close
              </Button>,
            ]}

          >
            <div className="text-lg px-5 py-5 leading-loose" >
              <img width="100" src={logo} alt="message" />
              <p className='text-3xl mt-5 font-medium'>Thank you for completing the Brand Detail Survey </p>
              <p style={{ marginTop: '30px' }}>Warm regards,</p>
              <p style={{ padding: '0px' }}>The Mysa Team</p>
            </div>
          </Modal>
        </>
      </div>
    </div>
  )
}

export default SignUpFormHome;

