import { Button, Form, Input, message, Switch } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const ChannelManagerForm: React.FC<any> = ({ setFormOpen, channelManagerDetails, channelManagerId }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [status, setStatus] = useState<string>('active');
  const [checkValue, setCheckValue] = useState<boolean>(true);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const statusChange = useCallback((value) => {
    setCheckValue(value);
    if (value) {
      setStatus('active');
    }
    else {
      setStatus('inactive');
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (channelManagerId) {
      setCheckValue((channelManagerDetails.status === 'active') ? true : false);
      setStatus(channelManagerDetails.status)
      form.setFieldsValue({
        name: channelManagerDetails.name,
        description: channelManagerDetails.description || '',
        status: channelManagerDetails.status,

      })
    } else {
      setStatus('active');
      setCheckValue(true);
    }
  }, [channelManagerDetails, channelManagerId, form]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { createChannelManager, updateChannelManager, getChannelManagerList } = useStoreActions<any>((actions) => ({
    createChannelManager: actions.property.createChannelManager,
    updateChannelManager: actions.property.updateChannelManager,
    getChannelManagerList: actions.property.getChannelManagerList,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    createChannelManagerError,
    createChannelManagerSuccess,
    updateChannelManagerError,
    updateChannelManagerSuccess,

  } = useStoreState<any>((state) => ({
    createChannelManagerSuccess: state.property.createChannelManagerSuccess,
    createChannelManagerError: state.property.createChannelManagerError,
    updateChannelManagerError: state.property.updateChannelManagerError,
    updateChannelManagerSuccess: state.property.updateChannelManagerSuccess,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getChannelManagerList();
  }, [getChannelManagerList]);


  ///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createChannelManagerSuccess) {
      message.success('PropertyManagement created successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getChannelManagerList();
    }

    if (updateChannelManagerSuccess) {
      message.success('PropertyManagement updated successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getChannelManagerList();
    }

    if (createChannelManagerError) {
      message.error(createChannelManagerError.message)
    }

    if (updateChannelManagerError) {
      message.error(updateChannelManagerError.message)
    }
    setLoading(false);
  }, [createChannelManagerError, createChannelManagerSuccess, getChannelManagerList, setFormOpen, updateChannelManagerError, updateChannelManagerSuccess]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = useCallback((values) => {
    setLoading(true);
    if (channelManagerId) {
      values.id = channelManagerId;
      const data = {
        id: channelManagerId,
        name: values.name,
        description: values.description,
        status: values.status ? status : 'inactive'
      }
      updateChannelManager(data);

    } else {
      values.status = status;
      createChannelManager(values);
    }

  }, [createChannelManager, channelManagerId, status, updateChannelManager]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="user-Form w-1/2">
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please input name' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status">
          <Switch size="small" checked={checkValue} onChange={statusChange} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={loading} type="primary" htmlType="submit">
            {channelManagerId ? 'Update' : 'Create'}
          </Button>
          {/* <Button onClick={() => setFormOpen(false)} className="ml-5">
            Close
          </Button> */}
        </Form.Item>

      </Form>
    </div>
  )
};

export default ChannelManagerForm;