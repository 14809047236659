import React from 'react';
import { sideMenuStyle } from '../../../common/components-style';
import IconMenu from './IconMenu';

const SideMenu: React.FC = (props): JSX.Element => {

  return (
  <div style={sideMenuStyle}>
    <IconMenu setIsExpand={() => setTimeout(() => false, 300)} />
  </div>
  )
};

export default SideMenu;