import { FC, useCallback, useEffect, useState } from "react";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { overlayTriggerDelay, formButton, $mysaMegenta, $mysaTeal } from "../../../../common/components-style";
import { Col, OverlayTrigger, Row, Tooltip, Button } from "react-bootstrap";
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import ActivatedSvg from '../../../../images/icons/svg/activated.svg';
import DeactivatedSvg from '../../../../images/icons/svg/deactivated.svg';
import { useStoreActions, useStoreState } from "easy-peasy";
import MYSAReportTemplate from "./MYSAReportTamplate";
import { toast } from 'react-toastify';
import moment from "moment";

const customButtonStyle = {
    ...formButton,
    width: 'auto'
}

const MYSAReport: FC<any> = ({ disableMysaReportTemplate, programmeId, rfpProcessId, selectedClient, selectedProgramme, startDate, endDate }) => {

    /////////////////////// local state manage /////////////////
    const [selectedClientName, setSelectedClientName] = useState<string>("");
    const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");
    const [rfpLocation, setRfpLocation] = useState<Array<any>>([]);
    const [topLevelData, setTopLevelData] = useState<any>("");
    const [enableTemplate, setEnableTemplate] = useState<boolean>(false);
    const [singleData, setSingleData] = useState<any>("");
    const [releaseBtnDisable, setReleaseBtnDisable] = useState<boolean>(true);
    const [ifDataHas, setIfDataHas] = useState<boolean>(false);
    const [btnHide, setBtnHide] = useState<boolean>(true);
    const today = moment(new Date());

    //////////////// global state and action manage /////////////////

    const {
        getRFPAdminReview,
        RFPAdminReviewStatusUpdate,
        RFPAdminReviewReleaseUpdate,
        updateReportStatus
    } = useStoreActions<any>((actions) => ({
        getRFPAdminReview: actions.rfpProcess.getRFPAdminReview,
        RFPAdminReviewStatusUpdate: actions.rfpProcess.RFPAdminReviewStatusUpdate,
        RFPAdminReviewReleaseUpdate: actions.rfpProcess.RFPAdminReviewReleaseUpdate,
        updateReportStatus: actions.rfpProcess.updateReportStatus,
    }));


    const {
        getCorporateProgrammeByIdSuccess,
        getRFPAdminReviewSuccess,
        getRFPAdminReviewStatusUpdateSuccessMain,
        getRFPAdminReviewStatusUpdateErrorMain,
        getRFPAdminReviewReleaseUpdateSuccess,
        getRFPAdminReviewReleaseUpdateError
    } = useStoreState<any>((state) => ({
        getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
        getRFPAdminReviewSuccess: state.rfpProcess.getRFPAdminReviewSuccess,
        getRFPAdminReviewStatusUpdateSuccessMain: state.rfpProcess.getRFPAdminReviewStatusUpdateSuccess,
        getRFPAdminReviewStatusUpdateErrorMain: state.rfpProcess.getRFPAdminReviewStatusUpdateError,
        getRFPAdminReviewReleaseUpdateSuccess: state.rfpProcess.getRFPAdminReviewReleaseUpdateSuccess,
        getRFPAdminReviewReleaseUpdateError: state.rfpProcess.getRFPAdminReviewReleaseUpdateError
    }));

    ///////////// api request manage /////////////

    useEffect(() => {
        if (rfpProcessId) {
            const payload = {
                rfpProcessId: rfpProcessId
            }
            getRFPAdminReview(payload);
        }

    }, [getRFPAdminReview, rfpProcessId]);

    useEffect(() => {
        if (enableTemplate) {
            // setEnableTemplate(false);
            const payload = {
                rfpProcessId: rfpProcessId
            }
            getRFPAdminReview(payload);
        }
    }, [disableMysaReportTemplate, enableTemplate, getRFPAdminReview, rfpProcessId])

    //////////////////// enable template ///////////////

    const templateEnable = useCallback((value: boolean) => {
        setEnableTemplate(value);
        const payload = {
            rfpProcessId: rfpProcessId
        }
        getRFPAdminReview(payload);
    }, [getRFPAdminReview, rfpProcessId])

    /////////////////// update status //////////////
    const onStatusChange = useCallback((payload) => {
        RFPAdminReviewStatusUpdate(payload);
    }, [RFPAdminReviewStatusUpdate])

    //////////////// rfp review release ////////////
    const rfpRelease = () => {
        const payload = {
            rfpId: rfpProcessId,
            releaseStatus: true
        };
        RFPAdminReviewReleaseUpdate(payload);
    }

    ///////////// check activated status ///////////////
    const checkActivatedStatus = useCallback((value, reviewType, locationName) => {
        const payload = {
            rfpId: rfpProcessId,
            reviewType: reviewType,
            reviewStatus: 'activate',
            locationName: locationName
        }
        return (
            <>
                {value === 'activate' ? <img className='iconSizes' src={ActivatedSvg} alt="" /> : value === 'mark_as_complete' ? <img className='iconSizes' src={DeactivatedSvg} alt="" onClick={() => {
                    onStatusChange(payload);
                }} /> : <img className='iconSizes' src={DeactivatedSvg} alt="" />}
            </>
        )
    }, [onStatusChange, rfpProcessId])

    ////////////// api response manage ///////////////
    useEffect(() => {
        if (getCorporateProgrammeByIdSuccess?.data) {
            const { clientId, programmeName } = getCorporateProgrammeByIdSuccess?.data;
            setSelectedClientName(clientId.companyName);
            setSelectedProgrammeName(programmeName);
        }

        if (getRFPAdminReviewSuccess?.data) {
            setIfDataHas(true);
            /////////// check all reviews are activating //////////
            let rfpLocation: boolean = true;
            // getRFPAdminReviewSuccess?.data?.rfpLocations?.forEach((value) => {
            //     if (value?.reviewStatus === "activate" || value?.reviewStatus === "mark_as_complete") {
            //         rfpLocation = true;
            //     } else {
            //         rfpLocation = false;
            //     }
            // });

            // if (rfpLocation && (getRFPAdminReviewSuccess?.data?.introduction?.reviewStatus === 'activate' || getRFPAdminReviewSuccess?.data?.introduction?.reviewStatus === 'mark_as_complete') && (getRFPAdminReviewSuccess?.data?.aims?.reviewStatus === 'activate' || getRFPAdminReviewSuccess?.data?.aims?.reviewStatus === 'mark_as_complete')
            //     && (getRFPAdminReviewSuccess?.data?.outcomeOfRFP?.reviewStatus === 'activate' || getRFPAdminReviewSuccess?.data?.outcomeOfRFP?.reviewStatus === 'mark_as_complete') && (getRFPAdminReviewSuccess?.data?.conclusion?.reviewStatus === 'activate' || getRFPAdminReviewSuccess?.data?.conclusion?.reviewStatus === 'mark_as_complete')) {
            //     setReleaseBtnDisable(false);
            // } else {
            //     setReleaseBtnDisable(true);
            // }

            getRFPAdminReviewSuccess?.data?.rfpLocations?.forEach((value) => {
                if (rfpLocation) {
                    if (value?.reviewStatus === "activate") {
                        rfpLocation = true;
                    } else {
                        rfpLocation = false;
                    }
                }
            });
            const endDateMoment = moment(endDate);
            if (rfpLocation && getRFPAdminReviewSuccess?.data?.introduction?.reviewStatus === 'activate' && getRFPAdminReviewSuccess?.data?.aims?.reviewStatus === 'activate'
                && moment(today).isSameOrAfter(endDateMoment, 'date') && getRFPAdminReviewSuccess?.data?.outcomeOfRFP?.reviewStatus === 'activate' && getRFPAdminReviewSuccess?.data?.conclusion?.reviewStatus === 'activate') {
                setReleaseBtnDisable(false);
            } else {
                setReleaseBtnDisable(true);
            }

            if (getRFPAdminReviewSuccess?.data?.releaseStatus) {
                setBtnHide(true);
            } else {
                setBtnHide(false);
            }

        }
    }, [endDate, getCorporateProgrammeByIdSuccess?.data, getRFPAdminReviewSuccess?.data, today]);


    useEffect(() => {
        //////////// second level data ///////////
        if (getRFPAdminReviewSuccess?.data) {
            const options = getRFPAdminReviewSuccess?.data?.rfpLocations?.map((value, index) => {
                return (
                    <Row key={index} style={(index === 0 || index % 2 === 0) ? { background: 's' } : { background: '#F2F2F2' }}>
                        <Col md={3}>
                            <div style={{ fontSize: '17px', padding: '5%' }}>{value.locationName}</div>
                        </Col>
                        <Col md={3} >
                            <div style={{ padding: '5%' }}>
                                <OverlayTrigger
                                    placement="top"
                                    delay={overlayTriggerDelay}
                                    overlay={<Tooltip id='tooltip-table-top'>Create/View/Edit</Tooltip>}
                                >
                                    <img onClick={() => {
                                        const data = {
                                            name: `Location RFP - ${value.locationName.trim()}`,
                                            reviewType: 'rfp_locations',
                                            locationName: value.locationName.trim(),
                                        };
                                        setSingleData(data);
                                        templateEnable(true);
                                    }} className='iconSizes' src={ViewEdit} alt="" />
                                </OverlayTrigger>
                            </div>
                        </Col>
                        <Col md={3} >
                            <div style={{ fontSize: '17px', padding: '5%' }}>
                                {checkStatus(value.reviewStatus)}
                            </div>
                        </Col>
                        <Col md={3} >
                            <div style={{ fontSize: '17px', padding: '5%' }}>
                                {checkActivatedStatus(value.reviewStatus, 'rfp_locations', value.locationName)}
                            </div>
                        </Col>
                    </Row>
                );
            });
            setRfpLocation(options);
        }

    }, [checkActivatedStatus, getRFPAdminReviewSuccess?.data, templateEnable])

    useEffect(() => {
        if (getRFPAdminReviewStatusUpdateSuccessMain) {
            if (!enableTemplate) {
                toast.success("Activated successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                const payload = {
                    rfpProcessId: rfpProcessId
                }
                getRFPAdminReview(payload);
            }
        }

        if (getRFPAdminReviewStatusUpdateErrorMain) {
            if (!enableTemplate) {
                toast.error(getRFPAdminReviewStatusUpdateErrorMain.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
        }

        if (getRFPAdminReviewReleaseUpdateSuccess) {
            if (!enableTemplate) {
                toast.success("Released RFP to client successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                const payload = {
                    rfpProcessId: rfpProcessId
                }
                getRFPAdminReview(payload);
                const payload1 = {
                    rfpId: rfpProcessId
                }
                updateReportStatus(payload1);
            }
        }

        if (getRFPAdminReviewReleaseUpdateError) {
            if (!enableTemplate) {
                toast.error(getRFPAdminReviewReleaseUpdateError.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
        }
    }, [enableTemplate, getRFPAdminReview, getRFPAdminReviewReleaseUpdateError, getRFPAdminReviewReleaseUpdateSuccess, getRFPAdminReviewStatusUpdateErrorMain, getRFPAdminReviewStatusUpdateSuccessMain, rfpProcessId, updateReportStatus])



    useEffect(() => {
        //////////////////// first level data //////////////

        if (getRFPAdminReviewSuccess?.data) {
            const firstLevel = () => {
                return (
                    <>
                        <Row >
                            <Col md={3}>
                                <div style={{ fontSize: '17px', padding: '5%' }}>Introduction</div>
                            </Col>
                            <Col md={3} >
                                <div style={{ padding: '5%' }}>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={overlayTriggerDelay}
                                        overlay={<Tooltip id='tooltip-table-top'>Create/View/Edit</Tooltip>}
                                    >
                                        <img onClick={() => {
                                            const data = {
                                                name: 'Introduction',
                                                reviewType: 'introduction',
                                                locationName: null,
                                            };
                                            setSingleData(data);
                                            templateEnable(true);
                                        }} className='iconSizes' src={ViewEdit} alt="" />
                                    </OverlayTrigger>
                                </div>
                            </Col>
                            <Col md={3} >
                                <div style={{ fontSize: '17px', padding: '5%' }}>
                                    {checkStatus(getRFPAdminReviewSuccess?.data?.introduction?.reviewStatus)}
                                </div>
                            </Col>
                            <Col md={3} >
                                <div style={{ fontSize: '17px', padding: '5%' }}>
                                    {checkActivatedStatus(getRFPAdminReviewSuccess?.data?.introduction?.reviewStatus, 'introduction', null)}
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ background: '#F2F2F2' }}>
                            <Col md={3}>
                                <div style={{ fontSize: '17px', padding: '5%' }}>Objective</div>
                            </Col>
                            <Col md={3} >
                                <div style={{ padding: '5%' }}>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={overlayTriggerDelay}
                                        overlay={<Tooltip id='tooltip-table-top'>Create/View/Edit</Tooltip>}
                                    >
                                        <img onClick={() => {
                                            const data = {
                                                name: 'Aims',
                                                reviewType: 'aims',
                                                locationName: null,
                                            };
                                            setSingleData(data);
                                            templateEnable(true);
                                        }} className='iconSizes' src={ViewEdit} alt="" />
                                    </OverlayTrigger>
                                </div>
                            </Col>
                            <Col md={3} >
                                <div style={{ fontSize: '17px', padding: '5%' }}>
                                    {checkStatus(getRFPAdminReviewSuccess?.data?.aims?.reviewStatus)}
                                </div>
                            </Col>
                            <Col md={3} >
                                <div style={{ fontSize: '17px', padding: '5%' }}>
                                    {checkActivatedStatus(getRFPAdminReviewSuccess?.data?.aims?.reviewStatus, 'aims', null)}
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={3}>
                                <div style={{ fontSize: '17px', padding: '5%' }}>Outcome</div>
                            </Col>
                            <Col md={3} >
                                <div style={{ padding: '5%' }}>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={overlayTriggerDelay}
                                        overlay={<Tooltip id='tooltip-table-top'>Create/View/Edit</Tooltip>}
                                    >
                                        <img onClick={() => {
                                            const data = {
                                                name: 'Outcome of RFP',
                                                reviewType: 'out_come_of_rfp',
                                                locationName: null,
                                            };
                                            setSingleData(data);
                                            templateEnable(true);
                                        }} className='iconSizes' src={ViewEdit} alt="" />
                                    </OverlayTrigger>
                                </div>
                            </Col>
                            <Col md={3} >
                                <div style={{ fontSize: '17px', padding: '5%' }}>
                                    {checkStatus(getRFPAdminReviewSuccess?.data?.outcomeOfRFP?.reviewStatus)}
                                </div>
                            </Col>
                            <Col md={3} >
                                <div style={{ fontSize: '17px', padding: '5%' }}>
                                    {checkActivatedStatus(getRFPAdminReviewSuccess?.data?.outcomeOfRFP?.reviewStatus, 'out_come_of_rfp', null)}
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ background: '#F2F2F2' }}>
                            <Col md={3}>
                                <div style={{ fontSize: '17px', padding: '5%' }}>Considerations</div>
                            </Col>
                            <Col md={3} >
                                <div style={{ padding: '5%' }}>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={overlayTriggerDelay}
                                        overlay={<Tooltip id='tooltip-table-top'>Create/View/Edit</Tooltip>}
                                    >
                                        <img onClick={() => {
                                            const data = {
                                                name: 'Conclusion',
                                                reviewType: 'conclusion',
                                                locationName: null,
                                            };
                                            setSingleData(data);
                                            templateEnable(true);
                                        }} className='iconSizes' src={ViewEdit} alt="" />
                                    </OverlayTrigger>
                                </div>
                            </Col>
                            <Col md={3} >
                                <div style={{ fontSize: '17px', padding: '5%' }}>
                                    {checkStatus(getRFPAdminReviewSuccess?.data?.conclusion?.reviewStatus)}
                                </div>
                            </Col>
                            <Col md={3} >
                                <div style={{ fontSize: '17px', padding: '5%' }}>
                                    {checkActivatedStatus(getRFPAdminReviewSuccess?.data?.conclusion?.reviewStatus, 'conclusion', null)}
                                </div>
                            </Col>
                        </Row>
                    </>
                )
            }
            setTopLevelData(firstLevel);
        }

    }, [checkActivatedStatus, getRFPAdminReviewSuccess?.data, templateEnable])

    ////////// check status ////////////////
    const checkStatus = (value) => {
        let color = "";
        let text = "";
        if (value === 'pending') {
            color = $mysaMegenta;
            text = "Incomplete";
        };
        if (value === 'mark_as_complete') {
            color = $mysaTeal;
            text = "Complete";
        };
        if (value === 'activate') {
            color = $mysaTeal;
            text = "Complete"
        };

        return (
            <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{text}</span>
        )
    }

    return (
        <>
            {rfpProcessId &&
                <RFPBreadCrumbPage isPageName="RFP" isPageSub="RFPReview" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClientName} selectedProgrammeName={selectedProgrammeName} rfpProcessId={rfpProcessId} programmeId={programmeId} startDate={startDate} templateEnable={templateEnable}></RFPBreadCrumbPage>
            }

            {
                !enableTemplate && <div className='mt-3' style={{ paddingLeft: "15px" }}>
                    <Row style={{ background: '#F2F2F2', marginTop: "70px" }}>
                        <Col md={3}>
                            <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Location/Report</div>
                        </Col>
                        <Col md={3} >
                            <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Action</div>
                        </Col>
                        <Col md={3} >
                            <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Status</div>
                        </Col>
                        <Col mid={3} >
                            <div style={{ fontSize: '19px', fontWeight: 'bold', padding: '5%' }}>Approved</div>
                        </Col>
                    </Row>
                    {topLevelData}
                    {rfpLocation}

                    {
                        ifDataHas && !btnHide && <Row style={{ marginTop: '3%' }}>
                            <Col md={12}>
                                <Button id='approve-button' style={customButtonStyle} disabled={releaseBtnDisable} onClick={rfpRelease} > Release RFP to client </Button>
                            </Col>
                        </Row>
                    }

                    {
                        ifDataHas && btnHide && <Row style={{ marginTop: '3%' }}>
                            <Col md={12}>
                                <Button id='approve-button' style={customButtonStyle} disabled={true} > RFP released to client </Button>
                            </Col>
                        </Row>
                    }
                </div>
            }

            {
                enableTemplate && <div className='mt-4'><MYSAReportTemplate rfpReleasedToClient={btnHide} selectedClientName={selectedClientName} selectedProgramme={selectedProgramme} startDate={startDate} singleData={singleData} rfpProcessId={rfpProcessId} /></div>
            }

        </>
    );

}

export default MYSAReport