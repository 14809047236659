import React, { useCallback, useEffect, useState } from "react";
import DataTable, { createTheme } from 'react-data-table-component';

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { useStoreActions, useStoreState } from "easy-peasy";


import Archive from '../../../../images/icons/svg/outside/Icon-Function-Archive.svg';

import { $mysaRed, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, tableStyles } from "../../../../common/components-style";

import { getStorage } from "../../../../common/storage";
import moment from "moment";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import TravelerOptionsBookerView from "./TravelerOptionsBookerView";

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');


const TravelerOptionsBooker: React.FunctionComponent<any> = ({ locationData }) => {

    const [archivePopop, setArchivePopop] = useState<boolean>(false);
    const [showView, setShowView] = useState<boolean>(locationData?.state?.isShowPage ? locationData?.state?.isShowPage : false);

    const [archiveData, setArchiveData] = useState<any>();
    const [viewData, setViewData] = useState<any>(null);
    /**
     * global actions
     */
    const { getTravelerOptionByBookerId, deleteTravelerOptionBySelectedOptionsId } = useStoreActions<any>((actions) => ({

        getTravelerOptionByBookerId: actions.booking.getTravelerOptionByBookerId,
        deleteTravelerOptionBySelectedOptionsId: actions.booking.deleteTravelerOptionBySelectedOptionsId
    }));

    /**
     * global status
     */
    const { getTravelerOptionByBookerIdSuccess, deleteTravelerOptionBySelectedOptionsIdSuccess, deleteTravelerOptionBySelectedOptionsIdError } = useStoreState<any>((state) => ({
        deleteTravelerOptionBySelectedOptionsIdSuccess: state.booking.deleteTravelerOptionBySelectedOptionsIdSuccess,
        getTravelerOptionByBookerIdSuccess: state.booking.getTravelerOptionByBookerIdSuccess,
        deleteTravelerOptionBySelectedOptionsIdError: state.booking.deleteTravelerOptionBySelectedOptionsIdError
    }));



    const [data, setData] = useState<any>();



    /**
     * side effect react life cycle
     */
    useEffect(() => {
        if (getStorage('myo_logged_user')?.userData?._id) {
            getTravelerOptionByBookerId({ bookerId: getStorage('myo_logged_user')?.userData?._id });
        }


    }, [getTravelerOptionByBookerId]);


    /**
     * response manage using react life cycle
     */
    useEffect(() => {
        const buildingData: any = [];
        if (getTravelerOptionByBookerIdSuccess?.data) {
            _.map(getTravelerOptionByBookerIdSuccess?.data, (data: any) => {

                data.selectedOptions?.forEach(element => {

                    const date1 = new Date(moment(new Date(element?.checkInDate)).format('YYYY-MM-DD'));
                    const date2 = new Date(moment(new Date(element?.checkOutDate)).format('YYYY-MM-DD'));

                    const Difference_In_Time = date2.getTime() - date1.getTime();

                    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

                    buildingData.push({
                        key: data?._id,
                        checkInDate: element?.checkInDate,
                        checkOutDate: element?.checkOutDate,
                        status: element?.status,
                        city: element?.city,
                        country: element?.country,
                        propertyName: element?.propertyId?.propertyName,
                        brand: element?.propertyId?.brandId?.name,
                        selectOptionId: element?._id,
                        propertyId: element?.propertyId,
                        inventoryId: element?.propertyInventoryId,
                        travelerId: data?.travelerId,
                        createdAt: data?.createdAt,
                        cacheKey: element?.cacheKey,
                        hashGet: element?.hashGet,
                        requestData: element?.cacheAvailability,
                        query: element?.query,
                        noOfNights: Difference_In_Days

                    })
                })

            })
            //table data


            const uniqueData = buildingData.filter((item, index, self) =>
                index === self.findIndex((obj) => obj.selectOptionId === item.selectOptionId)
            );

            setData(uniqueData);
        }

    }, [getTravelerOptionByBookerIdSuccess?.data]);

    useEffect(() => {

        if (deleteTravelerOptionBySelectedOptionsIdSuccess) {

            toast.success('Traveler option deleted successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            if (getStorage('myo_logged_user')?.userData?._id) {
                getTravelerOptionByBookerId({ bookerId: getStorage('myo_logged_user')?.userData?._id });
            }

        }

        if (deleteTravelerOptionBySelectedOptionsIdError) {

            toast.error(deleteTravelerOptionBySelectedOptionsIdError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }

    }, [deleteTravelerOptionBySelectedOptionsIdSuccess, deleteTravelerOptionBySelectedOptionsIdError, getTravelerOptionByBookerId]);


    const onArchiveOption = useCallback((data: any) => {
        if (data) {
            deleteTravelerOptionBySelectedOptionsId(data);

        }


    }, [deleteTravelerOptionBySelectedOptionsId])

    const columns = [
        {
            name: 'First Name',
            selector: row => row?.travelerId?.firstName,
        },
        {
            name: 'Last Name',
            selector: row => row?.travelerId?.lastName,
        },
        {
            name: 'Country',
            selector: row => row?.country,
        },
        {
            name: 'city',
            selector: row => row?.city,
        },
        {
            name: 'Brand',
            selector: row => row?.brand,
        },

        {
            name: 'Property',
            selector: row => row?.propertyName,
        },
        {
            name: 'Check in',
            selector: row => moment(row?.checkInDate).format('D MMM YY'),
        },
        {
            name: 'Check out',
            selector: row => moment(row?.checkOutDate).format('D MMM YY'),
        },

        {
            name: 'Status',
            cell: (row) => {
                let color = "";
                let status = "";
                if (row.status === 'pending') { color = $mysaYellow; status = 'Pending' };
                if (row.status === 'approved') { color = $mysaTeal; status = 'Approved' };
                if (row.status === 'declined') { color = $mysaRed; status = 'Declined' };
                // if (row.bookingStatus === LIVE_BOOKING_STATUS.AMEND_PENDING) { color = $mysaMegenta; status = 'Amend Pending' };
                // if (row.bookingStatus === LIVE_BOOKING_STATUS.CANCELLED) { color = $mysaRed; status = 'Cancelled' };
                // if (row.bookingStatus === LIVE_BOOKING_STATUS.CANCEL_PENDING) { color = $mysaOrange; status = 'Cancel pending                    ' };
                // if (row.bookingStatus === LIVE_BOOKING_STATUS.COMPLETE) { color = $mysaBlue; status = 'Complete ' };

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{status}</span>
                )
            }
        },
        {
            name: 'Action',
            // button: true,
            cell: (row: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                    >
                        <>
                            <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                                setShowView(true);
                                setViewData(row);
                                // handleClick(row);
                                // push(`/admin/booker-booking-report/${row.bookingReference}`);
                            }} />



                        </>
                    </OverlayTrigger>

                    {
                        <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
                        >
                            <img className='iconSizes' src={Archive} alt="" onClick={() => {
                                const data = {
                                    _id: row?.key,
                                    selectedOptionsId: row?.selectOptionId,

                                }
                                setArchiveData(data);
                                setArchivePopop(true);
                            }} />
                        </OverlayTrigger>
                    }

                </>)
            },
        },
    ];




    return (
        <>

            {!showView && <div className='brnd_tbl' style={{ marginTop: '10px' }}>

                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    striped={true}
                    customStyles={tableStyles}
                    theme='mysaTable'
                    fixedHeader={true}
                    noDataComponent={'Updating records'}
                />
            </div>}
            {showView &&
                <div>
                    <TravelerOptionsBookerView details={viewData} />
                </div>
            }

            <SweetAlert
                show={archivePopop}
                warning
                showCancel={true}
                focusCancelBtn={true}
                confirmBtnText="yes"
                confirmBtnBsStyle="danger"
                title="Are you sure you wish to delete this options?"
                onConfirm={() => {
                    onArchiveOption(archiveData);
                    setArchivePopop(false);
                }}
                onCancel={() => {
                    setArchivePopop(false);
                }}
            >
            </SweetAlert>
        </>
    );
};

export default TravelerOptionsBooker;