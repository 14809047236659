import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import {  useStoreState } from "easy-peasy";
import _ from "lodash";
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import { Button} from 'react-bootstrap';
import {
  tableStyles,
} from "../../../common/components-style";
import moment from "moment";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";

const RFPInviteList: React.FC<any> = (): JSX.Element => {
  const history = useHistory();
  const [rfpInviteData, setRFPInviteData] = useState<any>([]);
  const [visibleData, setVisibleData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(7);
  const [isExpanded, setIsExpanded] = useState(false);


  useEffect(() => {
    if (rfpInviteData && rfpInviteData?.length > 0) {
    setVisibleData(rfpInviteData.slice(0,7));
    }
    else{
      setVisibleData([]);
    } 
  }, [rfpInviteData]);


const loadMoreData = () => {
  if (rfpInviteData && rfpInviteData?.length > 0) {
  const newIndex = currentIndex + 7;
  setVisibleData(rfpInviteData.slice(0, newIndex));
  setCurrentIndex(newIndex);
  }
};

const RFPInviteHandler = () => {
  if (rfpInviteData && rfpInviteData?.length > 0) {
  if (isExpanded) {
    setVisibleData(rfpInviteData.slice(0, 7));
    setCurrentIndex(7);
  } else {
    setVisibleData(rfpInviteData);
    setCurrentIndex(rfpInviteData.length);
  }
  setIsExpanded(!isExpanded);
}
};

  const { superAdminDashboardSuccessRfpManagement, superAdminDashboardErrorRfpManagement} = useStoreState<any>(
    (state) => {
      return {
        superAdminDashboardSuccessRfpManagement: state.DashboardModel.superAdminDashboardSuccessRfpManagement,
        superAdminDashboardErrorRfpManagement: state.DashboardModel.superAdminDashboardErrorRfpManagement,
      }
    }
  );
  useEffect(() => {
    if (superAdminDashboardErrorRfpManagement) {
      toast.error(superAdminDashboardErrorRfpManagement.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    if (superAdminDashboardSuccessRfpManagement) {
      setRFPInviteData(superAdminDashboardSuccessRfpManagement?.data?.getAllRFPInviteCount);
    }
  }, [superAdminDashboardErrorRfpManagement, superAdminDashboardSuccessRfpManagement]);

  const handleOpen = useCallback(
    (data) => {
      setTimeout(() => {
        history.push({
          pathname: `admin/rfp-management`,
          state: { 
            programmeId: data?.programmeId, 
            selectedClient: data?.selectedClient, 
            selectedProgramme: data?.programme
          }
        });
      }, 500);
    },
    [history]
  );

  const columns = [
    {
      name: "Client",
      cell: (row) => (
          row.client?.companyName
      ),
    },
    {
      name: "Programme",
      cell: (row) => row.programme?.programmeName,
    },
    {
      name: 'Start Date',
      cell: (row: any) => {
        const startDate = moment(row.startDate).startOf('day');
        const today = moment(new Date()).startOf('day');
        const diffInDays = startDate.diff(today, 'days');
        if (diffInDays === 0) {
          return (
            <strong className="mysa-pink-text">
              {startDate.format("D MMMM YYYY")}
            </strong>
          );
        } else if (diffInDays > 0 && diffInDays <= 3) {
          return (
            <strong className="mysa-green-text">
              {startDate.format("D MMMM YYYY")}
            </strong>
          );
        } else {
          return startDate.format("D MMMM YYYY");
        }
      },
    },
    {
        name: 'End Date',
        selector: (row) => moment(row.endDate).format("D MMMM YYYY"),
    },
    {
      name: "myo Surveys",
      cell: (row) =>  row.surveyStatus
    },
    {
      name: 'Action',
      button: false,
      cell: (row: any) => {
              return (
              <>
            <img
              className="iconSizes mr-2"
              src={ViewEdit}
              alt=""
              onClick={() => {
                handleOpen(row);
              }}
            />
              </>)
      },
  },
  ];
    return (
    <>
      <div className="tbl_accr_itm mysa-blue superadmin-table">
        <div className="tbl_hdr mysa-blue">
          <h5 className="m-0 fw-bold text-white">RFP Due For Release</h5>
          <button className="text-white pointer" onClick={RFPInviteHandler}>
            Expand
            <span>
              <img src={isExpanded  ? minus : plus} alt="icon" />
            </span>
          </button>
        </div>
        <div className="acrd_tbl">
          <DataTable
            columns={columns}
            data={visibleData}
            striped={true}
            customStyles={tableStyles}
            theme="mysaTable"
            fixedHeader={true}
            noDataComponent={"Updating records"}
            conditionalRowStyles={[
              {
                when: (row) => true,
                classNames: ['row-item'],
              },
            ]}
          />
          {currentIndex < rfpInviteData?.length && (
            <div className="more-data-section">
              <Button onClick={loadMoreData} className="more_button">More</Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RFPInviteList;
