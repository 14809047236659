import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Divider } from 'antd';
import AuditCertificate from './AuditCertificate';
import AuditSurveys from './AuditSurveys';
import AuditSection from './AuditSection';
import QuestionsHome from './questions/QuestionsHome';

const AuditHome: React.FC<any> = ({ extId=null, clientId=null }): JSX.Element => {
  const [data, setData] = useState<any>([]);
  const [surveys, setSurveys] = useState<any>([]);
  const [sections, setSections] = useState<any>([]);
  const [surveyId, setSurveyId] = useState<any>(null);
  const [sectionId, setSectionId] = useState<any>(null);
  const [emptyQuestions, setEmptyQuestions] = useState<any>(true);
  const [certIndex, setCertIndex] = useState(null);
  const [surveyIndex, setSurveyIndex] = useState(null);
  const [sectionIndex, setSectionIndex] = useState(null);
  
  const { getAuditCertificates } = useStoreActions<any>((actions) => ({
    getAuditCertificates: actions.audit.getAuditCertificates,
  }));

  const { getAuditCertificatesSuccess } = useStoreState<any>((state) => ({
    getAuditCertificatesSuccess: state.audit.getAuditCertificatesSuccess,
  }));

  useEffect(() => {
    getAuditCertificates({
      security: {
        apiKey: process.env.REACT_APP_AUDIT_API_KEY,
        user: "superadmin@mysa.global"
      },
      buildingID: extId,
      clientID: clientId,
      showExternal: false
    })
  }, [clientId, extId, getAuditCertificates]);

  useEffect(() => {
    if (getAuditCertificatesSuccess) {
      setData(getAuditCertificatesSuccess.certificates)
      
    }
  }, [getAuditCertificatesSuccess]);

  const onCertificateClick = useCallback((certificate, key) => {
    setCertIndex(key);
    setSurveyIndex(null);
    setSectionIndex(null);
    setSurveys(certificate.surveys);
    setSections([]);
    setEmptyQuestions(true);
  }, []);

  const onSurveyClick = useCallback((survey, key) => {    
    setSurveyId(survey.id);
    setSectionIndex(null);
    setSurveyIndex(key);
    setSections(survey.survey.sections)
  }, []);

  const onSectionClick = useCallback((section, key) => {
    setEmptyQuestions(false);
    setSectionIndex(key);
    setSectionId(section.id)
  }, []);

  const loadCertificates = useMemo(() => {
    return data.map((val, key) => {
      return <AuditCertificate onCertificateClick={onCertificateClick} className={certIndex === key ? 'bg-blue-100' : ''} values={val} index={key} key={key} />
    })
  }, [certIndex, data, onCertificateClick]);

  const loadSurveys = useMemo(() => {
    return surveys.map((val, key) => {
      return <AuditSurveys onSurveyClick={onSurveyClick} className={surveyIndex === key ? 'bg-blue-100' : ''} index={key} values={val} key={key} />
    })
  }, [onSurveyClick, surveyIndex, surveys]);

  const loadSections = useMemo(() => {
    
    return sections.map((val, key) => {
      return <AuditSection onSectionClick={onSectionClick} className={sectionIndex === key ? 'bg-blue-100' : ''} index={key} values={val} key={key} />
    })
  }, [onSectionClick, sectionIndex, sections]);

  return (
    <div>
      <div className="certificate-section">
        <Divider plain></Divider>
        <div className="">
          {loadCertificates}
        </div>
      </div>
        <div className="surveys-section">
          <Divider plain></Divider>
          <div className="">
            {loadSurveys}
          </div>
        </div>
        <div className="sections-section">
          <Divider plain></Divider>
          <div className="">
            {loadSections}
          </div>
        </div>
      <div className="questions-section">
        <Divider plain></Divider>
        <div className="">
        {surveyId && !emptyQuestions && < QuestionsHome emptyQuestions={emptyQuestions} sectionId={sectionId} surveyId={surveyId} />}
        </div>
      </div>
    </div>
  )
};

export default AuditHome;