import React from 'react';
import QEvidence from './MYOQEvidence';

const MYOQVideo: React.FC<any> = ({ data, onEvidenceUpload }): JSX.Element => {

  return (
    <div className="inline-block  m-5 w-full pr-10">
      <div className="bg-white p-5 rounded-lg">
        <p className="text-lg mb-3">{data?.description}</p>
        <QEvidence onEvidenceUpload={onEvidenceUpload} data={data} />.
      </div>
    </div>
  )
};

export default MYOQVideo;