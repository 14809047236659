import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState, useCallback } from "react";
import { Container, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { $mysaBlack, formButton } from "../../../../../common/components-style";
import { UserGroups } from "../../../../../common/constants";
import { formatPhoneNumber, getLoggedUserType } from "../../../../../common/functions";
import { getStorage } from "../../../../../common/storage";
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";

const PropertyUserView: React.FC<any> = ({
  selectedUser,
  close,
  reload,
}): JSX.Element => {
  const { handleSubmit } = useForm();

  ///////////////////////////////////Local State Manage///////////////////////////////////////////////////
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isBrand, setIsBrand] = useState("");

  ////////////////////////////Global State Manage//////////////////////////////////////////////////////////
  const updateAccess = useStoreActions<any>(
    (actions) => actions.user.updateAccess
  );

  const updateAccessSuccess = useStoreState<any>(
    (state) => state.user.updateAccessSuccess
  );

  /////////////////////////////////////Update Access Response Handle/////////////////////////////////////////////
  useEffect(() => {
    //user update success
    if (updateAccessSuccess) {
      setLoading(false);
      reload();
      toast.success("Permission updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      close();
    }
    const userRole = getStorage("isBrand");
    setIsBrand(userRole);
  }, [reload, updateAccessSuccess, close]);

  //////////////////////////////////////Form Submit////////////////////////////////////////////////////////////
  const onFinish = useCallback(
    (values) => {
      setLoading(true);
      updateAccess({
        id: selectedUser.arrId,
        userId: selectedUser.userId,
        role:
          isAdmin === true
            ? UserGroups.brand_admin
            : UserGroups.user,
        accessType: "brandRole",
      });
    },
    [isAdmin, selectedUser.arrId, selectedUser.userId, updateAccess]
  );

  return (
    <>
      <Container>
        <div className="jumbotron">
          <Form onSubmit={handleSubmit(onFinish)}>
            <Row>
              <Col md={8}>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>Email</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <p className="lead">{selectedUser.email}</p>
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>First name</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <p className="lead">{selectedUser.firstName}</p>
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>Last name</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <p className="lead">{selectedUser.lastName}</p>
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>Job title</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <p className="lead">{selectedUser.position}</p>
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>Phone</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <p className="lead">{formatPhoneNumber(selectedUser.phone)}</p>
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>Mobile</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <p className="lead">{formatPhoneNumber(selectedUser.mobile)}</p>
                  </Col>
                </Row>
                {isBrand === (UserGroups.brand_admin || getLoggedUserType() === UserGroups.super_admin) &&
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      <Form.Check type={`checkbox`}>
                        <Form.Check.Input type={`checkbox`} onChange={(value) => { setIsAdmin(value.target.checked); }} checked={(selectedUser.role === UserGroups.brand_admin) ? true : false} />
                        <Form.Check.Label style={{ color: $mysaBlack }}>{`Set as admin`}</Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                }
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!loading && (isBrand === UserGroups.brand_admin || getLoggedUserType() === UserGroups.super_admin) &&
                      <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                    }
                    {
                      !loading && <Button id='edit-button' style={formButton} onClick={() => { close(); }} >Close</Button>
                    }
                    {
                      loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                    }
                  </Col>
                </Row>
              </Col>
              <Col md={4}></Col>
            </Row>
          </Form>
        </div>
      </Container>
    </>
  );
};
export default PropertyUserView;
