import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import logo from '../../images/logoNew.png';
import { Card, Container, Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { LoginForm } from '../../common/interfaces';
import { loginButton } from '../../common/components-style';
import { toast } from 'react-toastify';


const ConfirmForgotPassword: React.FC<any> = ({ location }): JSX.Element => {
	const history = useHistory();
	const { register, watch, handleSubmit, formState: { errors } } = useForm<LoginForm>();

	const [loading, setLoading] = useState(false);

	const { onConfirmForgot } = useStoreActions<any>((actions) => ({
		onConfirmForgot: actions.auth.onConfirmForgot,
	}));

	const { confirmForgotSuccess, confirmForgotError } = useStoreState<any>((state) => ({
		confirmForgotSuccess: state.auth.confirmForgotSuccess,
		confirmForgotError: state.auth.confirmForgotError,
	}));

	useEffect(() => {
		setLoading(false);
		if (confirmForgotError) {
			// message.error(confirmForgotError.message.message);
			toast.error(confirmForgotError.message.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
		if (confirmForgotSuccess) {
			// message.success('Password reset success. Redirecting .....');
			toast.success("Password reset success. Redirecting .....", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setTimeout(() => {
				history.push('/');
			}, 500);
		}

	}, [history, confirmForgotError, confirmForgotSuccess]);

	const onFinish = useCallback((values) => {
		values.email = location.state.email;
		delete values.rePassword;

		setLoading(true);
		onConfirmForgot(values);

	}, [location.state.email, onConfirmForgot]);

	return (
		<div className="mysa-login flex-col flex h-screen items-center justify-center" >
			<Card className="px-5 shadow-2xl" style={{
				position: 'absolute',
				width: '458px',
				height: '411px',
				left: '731px',
				top: '338px',
				background: '#FFFFFF',
				// boxShadow: ' 0px 8px 8px rgba(0, 0, 0, 0.6)',
				borderRadius: '33px'
			}}>
				<div className="w-64 m-auto " style={{
					paddingBottom: '0rem',
					paddingTop: '0rem'
				}}>
					<img src={logo} alt="logo" />
				</div>
				<>

					<Container>
						<div >
							<Form onSubmit={handleSubmit(onFinish)} style={{
								paddingBottom: '4rem',
								paddingTop: '0rem'
							}}>
								<Row className='formSpaceLogin'>

									<Col md={12} style={{ textAlign: 'left' }}>
										<Form.Control type='number' placeholder="Verification code" className={`${errors.code ? 'is-invalid' : ''}`}  {...register("code", {
											required: true,
											pattern: {
												value: /^(?:\d*)$/,
												message: "Invalid verification code!"
											}
										})} />
										<div className="invalid-feedback">Please input your verification code!</div>
									</Col>
								</Row>

								<Row className='formSpaceLogin '>
									<Col md={12} style={{ textAlign: 'left' }} >
										<Form.Control type='password' defaultValue="" placeholder="New password" className={`${errors.password ? 'is-invalid' : ''}`}  {...register("password", {
											required: true,
											minLength: {
												value: 8,
												message: "Minimum length is 8"
											}


										})}
										onFocus={(e) => e.target.removeAttribute('readonly')}
										/>
										<div className="invalid-feedback" >Please input your Password!</div>
									</Col>
								</Row>
								<Row className='formSpaceLogin '>
									<Col md={12} style={{ textAlign: 'left' }} >
										<Form.Control type='password' placeholder="Confirm password" className={`${errors.rePassword ? 'is-invalid' : ''}`}  {...register("rePassword", {
											required: true,
											validate: (value) => {
												if (value === watch('password')) {
													return true;
												}
												return Promise.reject(toast.error('Passwords do not match!', {
													position: toast.POSITION.BOTTOM_RIGHT,
													className: 'foo-bar'
												}));
											}
										},
										)} />
										<div className="invalid-feedback" >Please input your confirm Password!</div>
									</Col>
								</Row>
								<Row className='formSpaceLogin '>

									<Col md={12} style={{ textAlign: 'center' }} >
										{!loading && <Button type="submit" id='login-button' style={loginButton} >Change Password</Button>}
									</Col>
									<a className="block" style={{ textAlign: 'right', marginLeft: '-15px', marginTop: '25px' }} href="/">Login</a>

									{
										loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
									}
								</Row>
							</Form>
						</div>
					</Container>
				</>
			</Card>
			{/* <Card className="px-5 shadow-2xl" style={{ width: 400 }}>
				<div className="w-64 m-auto py-3">
					<img src={logo} alt="logo" />
				</div>
				<>
					<Form onFinish={onFinish}>
						<Form.Item
							name="code"
							hasFeedback
							rules={[
								{ required: true, message: 'Please input your verification code!' },
								{ pattern: /^(?:\d*)$/, message: 'Invalid verification code!' },
							]}
						>
							<Input
								type="text"
								readOnly
								onFocus={(e) => e.target.removeAttribute('readonly')}
								placeholder="Verification code"
							/>
						</Form.Item>
						<Form.Item
							name="password"
							hasFeedback
							rules={[
								{ required: true, message: 'Please input your Password!' },
								{ min: 8, message: 'Minimum length is 8' }
							]}
						>
							<Input
								type="password"
								placeholder="New password"
							/>
						</Form.Item>
						<Form.Item
							name="rePassword"
							hasFeedback
							rules={[
								{ required: true, message: 'Please input your confirm Password!' },
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject('Passwords do not match!');
									},
								}),
							]}
						>
							<Input
								type="password"
								placeholder="Confirm password"
							/>
						</Form.Item>
						<Form.Item>
							<Button loading={loading} block type="primary" htmlType="submit" className="login-btn">
								Change Password
							</Button>
							<a className="block float-right mt-2 " href="/">Login</a>
						</Form.Item>
					</Form>
				</>
			</Card> */}
			{/* <div className="flex flex-row justify-around" style={{ width: 400 }}>
				<div className="mysa-yellow w-full h-1"></div>
				<div className="mysa-green w-full"></div>
				<div className="mysa-blue w-full"></div>
			</div>
			<span className="text-white text-xs mt-1">All right reserved @{new Date().getFullYear()} MYSA Global Limited</span> */}
		</div >
	)
};

export default ConfirmForgotPassword;