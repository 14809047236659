/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import { call } from '../common/axios';
// import { io } from "socket.io-client";
// const socket = io(process.env.REACT_APP_API_URL?? '');

export default {
  userList: null,
  userListError: null,
  userUpdate: null,
  userUpdateError: null,
  userCreate: null,
  userCreateError: null,
  reset: false,
  getUserMappingSuccess: null,
  getUserMappingError: null,
  createUserMappingSuccess: null,
  createUserMappingError: null,
  updateUserMappingSuccess: null,
  updateUserMappingError: null,
  updateAccessSuccess: null,
  updateAccessError: null,

  // actions
  setUserList: action((state, payload) => ({ userList: payload })),
  setUserListError: action((state, payload) => ({ userListError: payload })),
  setUserUpdate: action((state, payload) => ({ userUpdate: payload })),
  setUserUpdateError: action((state, payload) => ({ userUpdateError: payload })),
  setUserCreate: action((state, payload) => ({ userCreate: payload })),
  setUserCreateError: action((state, payload) => ({ userCreateError: payload })),
  resetUser: action((state, payload) => ({ reset: true })),
  setCreateUserMappingSuccess: action((state, payload) => ({ createUserMappingSuccess: payload })),
  setCreateUserMappingError: action((state, payload) => ({ createUserMappingError: payload })),
  setUpdateUserMappingSuccess: action((state, payload) => ({ updateUserMappingSuccess: payload })),
  setUpdateUserMappingError: action((state, payload) => ({ updateUserMappingError: payload })),
  setGetUserMappingSuccess: action((state, payload) => ({ getUserMappingSuccess: payload })),
  setGetUserMappingError: action((state, payload) => ({ getUserMappingError: payload })),
  setUpdateAccessSuccess: action((state, payload) => ({ updateAccessSuccess: payload })),
  setUpdateAccessError: action((state, payload) => ({ updateAccessError: payload })),
  // thunks
  loadUsers: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', '/user-sv/users', null, null);
      actions.setUserList(result.data);
    } catch (error: any) {
      actions.setUserListError(error.response.data);
    }
  }),

  updateUser: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/user-sv/users', payload, null);
      actions.setUserUpdate(result.data);
    } catch (error: any) {
      actions.setUserUpdateError(error.response.data);
    }
  }),

  createUser: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/user-sv/users', payload, null);
      actions.setUserCreate(result.data);
    } catch (error: any) {
      actions.setUserCreateError(error.response.data);
    }
  }),

 createUserMapping: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/user-sv/users/user-mapping', payload, null);
      actions.setCreateUserMappingSuccess(result.data);
    
      //socket Notification
      if (payload?.brandId && result.data !== undefined && result.data !== null) {
        let roleTitle;
        if(payload?.role === 'user')
        {
        roleTitle = 'Brand User';
        }
        else if(payload?.role === 'brand_admin')
        {
        roleTitle = 'Brand Admin';
        }
        let notificationData = `<b> ${result.data?.firstName} ${result.data?.lastName} </b> has been created as a <b> ${roleTitle} </b>`;
        // await socket.emit("send_message", notificationData);
      }
      else if (payload?.property && result.data !== undefined && result.data !== null) {
        let roleTitle;
        if(payload?.role === 'user')
        {
        roleTitle = 'Property User';
        }
        else if(payload?.role === 'property_admin')
        {
        roleTitle = 'Property Admin';
        }
        let notificationData = `<b> ${result.data?.firstName} ${result.data?.lastName} </b> has been created as a <b> ${roleTitle} </b>`;
        // await socket.emit("send_message", notificationData);
      }
    } catch (error: any) {
      actions.setCreateUserMappingError(error.response.data);
    }
  }),
   

  updateUserMapping: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/user-sv/users/user-mapping', payload, null);
      actions.setUpdateUserMappingSuccess(result.data);
    } catch (error: any) {
      actions.setUpdateUserMappingError(error.response.data);
    }
  }),

  getUserMapping: thunk(async (actions: any, params: any) => {
    try {
      if(params.clientIds){
        Object.assign(params, {clientId : params.clientIds});
        delete  params.clientIds
      }
      const result = await call('get', '/user-sv/users/user-mapping', null, params);
      actions.setGetUserMappingSuccess(result.data);
    } catch (error: any) {
      actions.setGetUserMappingError(error.response.data);
    }
  }),

  updateAccess: thunk(async (actions: any, payload: any) => {
    try {
      let newPayload = payload;
      if(typeof(payload?.isUserDisable) === 'undefined'){
        newPayload = {...payload, isUserDisable: false};
      }
      const result = await call('patch', '/user-sv/users/update-access', newPayload, null);
      actions.setUpdateAccessSuccess(result.data);
    } catch (error: any) {
      actions.setUpdateAccessError(error.response.data);
    }
  }),

}
