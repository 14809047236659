import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useStoreState } from "easy-peasy";
import _ from "lodash";
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import { Button} from 'react-bootstrap';
import {
  tableStyles,
} from "../../../common/components-style";
import moment from "moment";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";

const BookingAmendList: React.FC<any> = (): JSX.Element => {
  const [bookingAmendData, setBookingAmendData] = useState<any>([]);
  const [visibleData, setVisibleData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(7);
  const [isExpanded, setIsExpanded] = useState(false);
  const { push } = useHistory();

  useEffect(() => {
    if (bookingAmendData && bookingAmendData?.length > 0) {
    setVisibleData(bookingAmendData.slice(0,7)); 
    }
    else
    {
    setVisibleData([]);
    }
  }, [bookingAmendData]);


const loadMoreData = () => {
  if (bookingAmendData && bookingAmendData?.length > 0) {
  const newIndex = currentIndex + 7;
  setVisibleData(bookingAmendData.slice(0, newIndex));
  setCurrentIndex(newIndex);
  }
};

const BookingAmendHandler = () => {
  if (bookingAmendData && bookingAmendData?.length > 0) {
  if (isExpanded) {
    setVisibleData(bookingAmendData.slice(0, 7));
    setCurrentIndex(7);
  } else {
    setVisibleData(bookingAmendData);
    setCurrentIndex(bookingAmendData.length);
  }
  setIsExpanded(!isExpanded);
}
};

  const { superAdminDashboardSuccessBookingManagement, superAdminDashboardErrorBookingManagement} = useStoreState<any>(
    (state) => {
      return {
        superAdminDashboardSuccessBookingManagement: state.DashboardModel.superAdminDashboardSuccessBookingManagement,
        superAdminDashboardErrorBookingManagement: state.DashboardModel.superAdminDashboardErrorBookingManagement,
      }
    }
  );
  useEffect(() => {
    if (superAdminDashboardErrorBookingManagement) {
      toast.error(superAdminDashboardErrorBookingManagement.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    if (superAdminDashboardSuccessBookingManagement) {
      setBookingAmendData(superAdminDashboardSuccessBookingManagement?.data?.getAmendmentsBookingCount?.bookingData);
    }
  }, [superAdminDashboardErrorBookingManagement, superAdminDashboardSuccessBookingManagement]);


  const columns = [

    {
      name: "Client",
      cell: (row) => (
        row.clientName
      ),
    },
    {
      name: "Programme",
      cell: (row) => row.programmeName
    },
    {
        name: 'Brand',
        selector: (row) => row.brandName,
    },
    {
      name: "Property",
      cell: (row) => row.properties?.propertyInfo?.propertyName
    },
    {
        name: "First Name",
        cell: (row) =>  row.properties?.inventories?.bookerUserData?.firstName
      },
      {
        name: "Second Name",
        cell: (row) => row.properties?.inventories?.bookerUserData?.lastName,
      },
      {
        name: "Date Rec'd",
        cell: (row) => {
          const amendRequestDate = moment.utc(row.inventory?.amendRequestDate);
          const now = moment.utc();
          const duration = moment.duration(now.diff(amendRequestDate));
          const hours = duration.asHours();

          let timeStyle = {};
          if (hours > 24) {
            timeStyle = { color: 'red', fontWeight: '700' };
          }
          return (
            <>
              <span style={timeStyle}>
                {amendRequestDate.format('D MMM YYYY')}
              </span>
            </>
          );
        }
      },
    {
      name: 'Action',
      button: false,
      cell: (row: any) => {
              return (
              <>
            <img
              className="iconSizes mr-2"
              src={ViewEdit}
              alt=""
              onClick={() => {
                push(`/admin/super-admin-booking-report/${row.properties?.inventories?._id}`);
            }}
            />
              </>)
      },
  },
  ];

  const callCustomClass = (row) => {
    const amendRequestDate = moment.utc(row?.inventory?.amendRequestDate);
    const now = moment.utc();
    const duration = moment.duration(now.diff(amendRequestDate));
    const hours = duration?.asHours();
    if (hours > 24) {
      return { color: 'red' };
    }else{
      return {};
    }
  }
    return (
    <>
      <div className="tbl_accr_itm mysa-green superadmin-table">
        <div className="tbl_hdr mysa-green">
          <h5 className="m-0 fw-bold text-white">Pending Booking Amendments</h5>
          <button className="text-white pointer" onClick={BookingAmendHandler}>
            Expand
            <span>
              <img src={isExpanded  ? minus : plus} alt="icon" />
            </span>
          </button>
        </div>
        <div className="acrd_tbl">
          <DataTable
            columns={columns}
            data={visibleData}
            striped={true}
            customStyles={tableStyles}
            theme="mysaTable"
            fixedHeader={true}
            noDataComponent={"Updating records"}
            conditionalRowStyles={[
              {
                when: (row) => true,
                classNames: ['row-item'],
              },
              {
                when: (row) => true,
                style: (row)=> callCustomClass(row),
              },
            ]}
          />
          {currentIndex < bookingAmendData?.length && (
            <div className="more-data-section">
              <Button onClick={loadMoreData} className="more_button">More</Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BookingAmendList;
