import { action, thunk } from 'easy-peasy';
import { call } from '../common/axios';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    //state
    getSectionContentSuccess: null,
    getSectionContentError: null,
    getSectionSuccess: null,
    getSectionError: null,
    getSectionCreateSuccess: null,
    getSectionCreateError: null,
    getSectionContentCreateSuccess: null,
    getSectionContentCreateError: null,
    getSectionContentUpdateSuccess: null,
    getSectionContentUpdateError: null,
    getByIDSectionContentSuccess: null,
    getByIDSectionContentError: null,
    brandDashboardSuccess: null,
    brandDashboardError: null,
    superAdminDashboardSuccessOnboarding: null,
    superAdminDashboardErrorOnboarding: null,
    superAdminDashboardSuccessRfpManagement: null,
    superAdminDashboardErrorRfpManagement: null,
    superAdminDashboardSuccessProposalManagement: null,
    superAdminDashboardErrorProposalManagement: null,
    superAdminDashboardSuccessBookingManagement: null,
    superAdminDashboardErrorBookingManagement: null,
    programmeDashboardSuccess: null,
    programmeDashboardError: null,

    //actions
    setSectionContentSuccess: action((state, payload) => ({ getSectionContentSuccess: payload })),
    setSectionContentError: action((state, payload) => ({ getSectionContentError: payload })),
    setSectionSuccess: action((state, payload) => ({ getSectionSuccess: payload })),
    setSectionError: action((state, payload) => ({ getSectionError: payload })),
    setSectionCreateSuccess: action((state, payload) => ({ getSectionCreateSuccess: payload })),
    setSectionCreateError: action((state, payload) => ({ getSectionCreateError: payload })),
    setSectionContentCreateSuccess: action((state, payload) => ({ getSectionContentCreateSuccess: payload })),
    setSectionContentCreateError: action((state, payload) => ({ getSectionContentCreateError: payload })),
    setSectionContentUpdateSuccess: action((state, payload) => ({ getSectionContentUpdateSuccess: payload })),
    setSectionContentUpdateError: action((state, payload) => ({ getSectionContentUpdateError: payload })),
    setByIDSectionContentSuccess: action((state, payload) => ({ getByIDSectionContentSuccess: payload })),
    setByIDSectionContentError: action((state, payload) => ({ getByIDSectionContentError: payload })),
    setBrandDashboardStatus: action((state, payload) => ({ brandDashboardSuccess: payload })),
    setBrandDashboardStatusError: action((state, payload) => ({ brandDashboardError: payload })),
    setSuperadminDashboardStatusOnboarding : action((state, payload) => ({ superAdminDashboardSuccessOnboarding: payload })),
    setSuperadminDashboardStatusOnboardingError: action((state, payload) => ({ superAdminDashboardErrorOnboarding: payload })),
    setSuperadminDashboardStatusRfpManagement : action((state, payload) => ({ superAdminDashboardSuccessRfpManagement: payload })),
    setSuperadminDashboardStatusRfpManagementError: action((state, payload) => ({ superAdminDashboardErrorRfpManagement: payload })),
    setSuperadminDashboardStatusProposalManagement : action((state, payload) => ({ superAdminDashboardSuccessProposalManagement: payload })),
    setSuperadminDashboardStatusProposalManagementError: action((state, payload) => ({ superAdminDashboardErrorProposalManagement: payload })),
    setSuperadminDashboardStatusBookingManagement : action((state, payload) => ({ superAdminDashboardSuccessBookingManagement: payload })),
    setSuperadminDashboardStatusBookingManagementError: action((state, payload) => ({ superAdminDashboardErrorBookingManagement: payload })),
    setProgrammeDashboardStatus: action((state, payload) => ({ programmeDashboardSuccess: payload })),
    setProgrammeDashboardStatusError: action((state, payload) => ({ programmeDashboardError: payload })),


    //get section-content
    sectionContent: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', '/dashboard-sv/section-content', null, null);
            actions.setSectionContentSuccess(result.data);
        } catch (error: any) {
            actions.setSectionContentError(error.response);
        }

    }),

    //get section
    section: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', '/dashboard-sv/section', null, null);
            actions.setSectionSuccess(result.data);
        } catch (error: any) {
            actions.setSectionError(error.response);
        }
    }),

    sectionCreate: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', '/dashboard-sv/section', payload, null);
            actions.setSectionCreateSuccess(result.data);
        } catch (error: any) {
            actions.setSectionCreateError(error.response);
        }
    }),

    sectionContentCreate: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', '/dashboard-sv/section-content', payload, null);
            actions.setSectionContentCreateSuccess(result.data);
        } catch (error: any) {
            actions.setSectionContentCreateError(error.response);
        }
    }),

    sectionContentUpdate: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/dashboard-sv/section-content/${payload._id}`, payload, null);
            actions.setSectionContentUpdateSuccess(result.data);
        } catch (error: any) {
            actions.setSectionContentUpdateError(error.response);
        }
    }),

    getByIdSectionContent: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', `/dashboard-sv/section-content/${payload._id}`, payload, null);
            actions.setByIDSectionContentSuccess(result.data);
        } catch (error: any) {
            actions.setByIDSectionContentError(error.response);
        }
    }),

    brandDashboardDetails: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', '/dashboard-sv/brand-dashboard-data', payload, null);
            actions.setBrandDashboardStatus(result.data);
        } catch (error: any) {
            actions.setBrandDashboardStatusError(error.response.data);
        }
    }),

    superadminDashboardDetailsOnboarding: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', '/dashboard-sv/superadmin-dashboard-data-onboarding', null, null);
            actions.setSuperadminDashboardStatusOnboarding(result.data);
        } catch (error: any) {
            actions.setSuperadminDashboardStatusOnboardingError(error.response.data);
        }
    }),

    superadminDashboardDetailsRfpManagement: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', '/dashboard-sv/superadmin-dashboard-data-rfp-management', null, null);
            actions.setSuperadminDashboardStatusRfpManagement(result.data);
        } catch (error: any) {
            actions.setSuperadminDashboardStatusRfpManagementError(error.response.data);
        }
    }),

    superadminDashboardDetailsProposalManagement: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', '/dashboard-sv/superadmin-dashboard-data-proposal-management', null, null);
            actions.setSuperadminDashboardStatusProposalManagement(result.data);
        } catch (error: any) {
            actions.setSuperadminDashboardStatusProposalManagementError(error.response.data);
        }
    }),

    superadminDashboardDetailsBookingManagement: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', '/dashboard-sv/superadmin-dashboard-data-booking-management', null, null);
            actions.setSuperadminDashboardStatusBookingManagement(result.data);
        } catch (error: any) {
            actions.setSuperadminDashboardStatusBookingManagementError(error.response.data);
        }
    }),

    
    programmeDashboardDetails: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', '/dashboard-sv/programme-dashboard-data', payload, null);
            actions.setProgrammeDashboardStatus(result.data);
        } catch (error: any) {
            actions.setProgrammeDashboardStatusError(error.response.data);
        }
    }),
}

