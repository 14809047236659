/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import { call } from '../common/axios';
// import { io } from "socket.io-client";
// const socket = io(process.env.REACT_APP_API_URL?? '');

export default {
  createPropertySuccess: null,
  createPropertyError: null,
  updatePropertyError: null,
  updatePropertySuccess: null,
  getPropertiesSuccess: null,
  getPropertiesError: null,
  getPropertySuccess: null,
  getPropertyError: null,
  getPropertyTypesSuccess: null,
  getPropertyTypesError: null,
  getBrandPropertiesSuccess: null,
  getBrandPropertiesError: null,
  reset: false,
  uploadPropertyImageSuccess: null,
  uploadPropertyImageError: null,
  getPropertyImageSuccess: null,
  getPropertyImageError: null,
  updatePropertyImageSuccess: null,
  removePropertyImageSuccess: null,
  getPropertyImageTagSuccess: null,
  getPropertyImageTagError: null,
  getPropertyAmenitiesSuccess: null,
  getPropertyAmenitiesError: null,
  getAmenityListSuccess: null,
  getAmenityListError: null,
  createAmenitySuccess: null,
  createAmenityError: null,
  updateAmenitySuccess: null,
  updateAmenityError: null,
  removeAmenitySuccess: null,
  assignPropertyAmenitySuccess: null,
  assignPropertyAmenityError: null,
  getAllPropertyNameListSuccess: null,
  getAllPropertyNameListError: null,
  getCheckInProcessListSuccess: null,
  getCheckInProcessListError: null,
  createCheckInProcessSuccess: null,
  createCheckInProcessError: null,
  updateCheckInProcessSuccess: null,
  updateCheckInProcessError: null,
  removeCheckInProcessSuccess: null,
  getPropertyCheckInProcessDetailByIdSuccess: null,
  getPropertyCheckInProcessDetailByIdError: null,
  createPropertyCheckInProcessDetailSuccess: null,
  createPropertyCheckInProcessDetailError: null,
  updatePropertyCheckInProcessDetailSuccess: null,
  updatePropertyCheckInProcessDetailError: null,
  createPropertyFinanceSuccess: null,
  createPropertyFinanceError: null,
  updatePropertyFinanceSuccess: null,
  updatePropertyFinanceError: null,
  getPropertyFinanceDetailSuccess: null,
  getPropertyFinanceDetailError: null,
  getAllPropertyCitiesSuccess: null,
  getAllPropertyCitiesError: null,
  getAllPropertyCountriesSuccess: null,
  getAllPropertyCountriesError: null,

  getPropertySustainabilityByPropertyIdSuccess: null,
	getPropertySustainabilityByPropertyIdError: null,
	createPropertySustainabilitySuccess: null,
	createPropertySustainabilityError: null,
	updatePropertySustainabilitySuccess: null,
	updatePropertySustainabilityError: null,

  /////////////////////////////////transport model /////////////////////////////////////
  getTransportSuccess: null,
  getTransportError: null,
  createTransportSuccess: null,
  createTransportError: null,
  updateTransportSuccess: null,
  updateTransportError: null,
  /////////////////////////////////guest comfort model /////////////////////////////////////
  getGuestComfortSuccess: null,
  getGuestComfortError: null,
  createGuestComfortSuccess: null,
  createGuestComfortError: null,
  updateGuestComfortSuccess: null,
  updateGuestComfortError: null,
  getAllPropertyCitiesByCountryNBrandSuccess: null,
  getAllPropertyCitiesByCountryNBrandError: null,

  propertyApprovalSuccess: null,
  propertyApprovalError: null,

  
  propertyReviewSuccess: null,
  propertyReviewError: null,

  getPropertyDetailedSuccess: null,
  getPropertyDetailedError: null,

  getPropertyPrimaryImageSuccess: null,
  getPropertyPrimaryImageError: null,

  getAllPropertiesSuccess: null,
  getAllPropertiesListSuccess: null,


  getPropertyManagementListSuccess: null,
  getPropertyManagementListError: null,
  createPropertyManagementSuccess: null,
  createPropertyManagementError: null,
  updatePropertyManagementSuccess: null,
  updatePropertyManagementError: null,
  removePropertyManagementSuccess: null,

  getChannelManagerListSuccess: null,
  getChannelManagerListError: null,
  createChannelManagerSuccess: null,
  createChannelManagerError: null,
  updateChannelManagerSuccess: null,
  updateChannelManagerError: null,
  removeChannelManagerSuccess: null,

  // actions

  setGetPropertyManagementList: action((state, payload) => ({ getPropertyManagementListSuccess: payload })),
  setGetPropertyManagementListError: action((state, payload) => ({ getPropertyManagementListError: payload })),
  setCreatePropertyManagement: action((state, payload) => ({ createPropertyManagementSuccess: payload })),
  setCreatePropertyManagementError: action((state, payload) => ({ createPropertyManagementError: payload })),
  setUpdatePropertyManagement: action((state, payload) => ({ updatePropertyManagementSuccess: payload })),
  setUpdatePropertyManagementError: action((state, payload) => ({ updatePropertyManagementError: payload })),
  setRemovePropertyManagement: action((state, payload) => ({ removePropertyManagementSuccess: payload })),

  setGetChannelManagerList: action((state, payload) => ({ getChannelManagerListSuccess: payload })),
  setGetChannelManagerListError: action((state, payload) => ({ getChannelManagerListError: payload })),
  setCreateChannelManager: action((state, payload) => ({ createChannelManagerSuccess: payload })),
  setCreateChannelManagerError: action((state, payload) => ({ createChannelManagerError: payload })),
  setUpdateChannelManager: action((state, payload) => ({ updateChannelManagerSuccess: payload })),
  setUpdateChannelManagerError: action((state, payload) => ({ updateChannelManagerError: payload })),
  setRemoveChannelManager: action((state, payload) => ({ removeChannelManagerSuccess: payload })),



  setAllPropertiesSuccess: action((state, payload) => ({ getAllPropertiesSuccess: payload })),
  setAllPropertiesListSuccess: action((state, payload) => ({ getAllPropertiesListSuccess: payload })),
  setPropertyPrimaryImageSuccess: action((state, payload) => ({ getPropertyPrimaryImageSuccess: payload })),
  setPropertyPrimaryImageError: action((state, payload) => ({ getPropertyPrimaryImageError: payload })),
  setPropertyReviewSuccess: action((state, payload) => ({ propertyReviewSuccess: payload })),
  setPropertyReviewError: action((state, payload) => ({ propertyReviewError: payload })),
  setPropertyApprovalSuccess: action((state, payload) => ({ propertyApprovalSuccess: payload })),
  setPropertyApprovalError: action((state, payload) => ({ propertyApprovalError: payload })),
  setCreateProperty: action((state, payload) => ({ createPropertySuccess: payload })),
  setCreatePropertyError: action((state, payload) => ({ createPropertyError: payload })),
  setUpdateProperty: action((state, payload) => ({ updatePropertySuccess: payload })),
  setUpdatePropertyError: action((state, payload) => ({ updatePropertyError: payload })),
  setGetProperties: action((state, payload) => ({ getPropertiesSuccess: payload, })),
  setGetPropertiesError: action((state, payload) => ({ getPropertiesError: payload })),
  setGetProperty: action((state, payload) => ({ getPropertySuccess: payload })),
  setGetPropertyError: action((state, payload) => ({ getPropertyError: payload })),
  setGetPropertyTypes: action((state, payload) => ({ getPropertyTypesSuccess: payload })),
  setGetPropertyTypesError: action((state, payload) => ({ getPropertyTypesError: payload })),
  setGetBrandProperties: action((state, payload) => ({ getBrandPropertiesSuccess: payload })),
  setGetBrandPropertiesError: action((state, payload) => ({ getBrandPropertiesError: payload })),
  resetProperty: action((state, payload) => ({ reset: true })),
  setUploadPropertyImage: action((state, payload) => ({ uploadPropertyImageSuccess: payload })),
  setUploadPropertyImageError: action((state, payload) => ({ uploadPropertyImageError: payload })),
  setGetPropertyImage: action((state, payload) => ({ getPropertyImageSuccess: payload })),
  setGetPropertyImageError: action((state, payload) => ({ getPropertyImageError: payload })),
  resetPropertyImage: action((state, payload) => ({ reset: true })),
  setUpdatePropertyImage: action((state, payload) => ({ updatePropertyImageSuccess: payload })),
  setRemovePropertyImage: action((state, payload) => ({ removePropertyImageSuccess: payload })),
  setGetPropertyImageTag: action((state, payload) => ({ getPropertyImageTagSuccess: payload })),
  setGetPropertyImageTagError: action((state, payload) => ({ getPropertyImageTagError: payload })),
  setGetPropertyAmenities: action((state, payload) => ({ getPropertyAmenitiesSuccess: payload })),
  setGetPropertyAmenitiesError: action((state, payload) => ({ getPropertyAmenitiesError: payload })),
  setGetAmenityList: action((state, payload) => ({ getAmenityListSuccess: payload })),
  setGetAmenityLisError: action((state, payload) => ({ getAmenityListError: payload })),
  setCreateAmenity: action((state, payload) => ({ createAmenitySuccess: payload })),
  setCreateAmenityError: action((state, payload) => ({ createAmenityError: payload })),
  setUpdateAmenity: action((state, payload) => ({ updateAmenitySuccess: payload })),
  setUpdateAmenityError: action((state, payload) => ({ updateAmenityError: payload })),
  setRemoveAmenity: action((state, payload) => ({ removeAmenitySuccess: payload })),
  setAssignPropertyAmenity: action((state, payload) => ({ assignPropertyAmenitySuccess: payload })),
  setAssignPropertyAmenityError: action((state, payload) => ({ assignPropertyAmenityError: payload })),
  setGetAllPropertyNameList: action((state, payload) => ({ getAllPropertyNameListSuccess: payload })),
  setGetAllPropertyNameListError: action((state, payload) => ({ getAllPropertyNameListError: payload })),
  setGetCheckInProcessList: action((state, payload) => ({ getCheckInProcessListSuccess: payload })),
  setGetCheckInProcessListError: action((state, payload) => ({ getCheckInProcessListError: payload })),
  setCreateCheckInProcess: action((state, payload) => ({ createCheckInProcessSuccess: payload })),
  setCreateCheckInProcessError: action((state, payload) => ({ createCheckInProcessError: payload })),
  setUpdateCheckInProcess: action((state, payload) => ({ updateCheckInProcessSuccess: payload })),
  setUpdateCheckInProcessError: action((state, payload) => ({ updateCheckInProcessError: payload })),
  setRemoveCheckInProcess: action((state, payload) => ({ removeCheckInProcessSuccess: payload })),
  setGetPropertyCheckInProcessDetailById: action((state, payload) => ({ getPropertyCheckInProcessDetailByIdSuccess: payload })),
  setGetPropertyCheckInProcessDetailByIdError: action((state, payload) => ({ getPropertyCheckInProcessDetailByIdError: payload })),
  setCreatePropertyCheckInProcessDetail: action((state, payload) => ({ createPropertyCheckInProcessDetailSuccess: payload })),
  setCreatePropertyCheckInProcessDetailError: action((state, payload) => ({ createPropertyCheckInProcessDetailError: payload })),
  setUpdatePropertyCheckInProcessDetail: action((state, payload) => ({ updatePropertyCheckInProcessDetailSuccess: payload })),
  setUpdatePropertyCheckInProcessDetailError: action((state, payload) => ({ updatePropertyCheckInProcessDetailError: payload })),
  setCreatePropertyFinance: action((state, payload) => ({ createPropertyFinanceSuccess: payload })),
  setCreatePropertyFinanceError: action((state, payload) => ({ createPropertyFinanceError: payload })),
  setUpdatePropertyFinance: action((state, payload) => ({ updatePropertyFinanceSuccess: payload })),
  setUpdatePropertyFinanceError: action((state, payload) => ({ updatePropertyFinanceError: payload })),
  setGetPropertyFinanceDetail: action((state, payload) => ({ getPropertyFinanceDetailSuccess: payload })),
  setGetPropertyFinanceDetailError: action((state, payload) => ({ getPropertyFinanceDetailError: payload })),
  setGetAllPropertyCitiesSuccess: action((state, payload) => ({ getAllPropertyCitiesSuccess: payload })),
  setGetAllPropertyCitiesError: action((state, payload) => ({ getAllPropertyCitiesError: payload })),
  setGetAllPropertyCountriesSuccess: action((state, payload) => ({ getAllPropertyCountriesSuccess: payload })),
  setGetAllPropertyCountriesError: action((state, payload) => ({ getAllPropertyCountriesError: payload })),
  setGetAllPropertyCitiesByCountryNBrandSuccess: action((state, payload) => ({ getAllPropertyCitiesByCountryNBrandSuccess: payload })),
  setGetAllPropertyCitiesByCountryNBrandError: action((state, payload) => ({ getAllPropertyCitiesByCountryNBrandError: payload })),



  /////////////////////////////////transport model /////////////////////////////////////
  setCreateTransportSuccess: action((state, payload) => ({ createTransportSuccess: payload })),
  setCreateTransportError: action((state, payload) => ({ createTransportError: payload })),
  setGetTransportSuccess: action((state, payload) => ({ getTransportSuccess: payload })),
  setGetTransportError: action((state, payload) => ({ getTransportError: payload })),
  setUpdateTransportSuccess: action((state, payload) => ({ updateTransportSuccess: payload })),
  setUpdateTransportError: action((state, payload) => ({ updateTransportError: payload })),
  /////////////////////////////////guest comfort model /////////////////////////////////////
  setCreateGuestComfortSuccess: action((state, payload) => ({ createGuestComfortSuccess: payload })),
  setCreateGuestComfortError: action((state, payload) => ({ createGuestComfortError: payload })),
  setGetGuestComfortSuccess: action((state, payload) => ({ getGuestComfortSuccess: payload })),
  setGetGuestComfortError: action((state, payload) => ({ getGuestComfortError: payload })),
  setUpdateGuestComfortSuccess: action((state, payload) => ({ updateGuestComfortSuccess: payload })),
  setUpdateGuestComfortError: action((state, payload) => ({ updateGuestComfortError: payload })),

  setPropertyDetailedSuccess: action((state, payload) => ({ getPropertyDetailedSuccess: payload })),
  setPropertyDetailedError: action((state, payload) => ({ getPropertyDetailedError: payload })),



  setGetPropertySustainabilityByPropertyId: action((state, payload) => ({ getPropertySustainabilityByPropertyIdSuccess: payload })),
	setGetPropertySustainabilityByPropertyIdError: action((state, payload) => ({ getPropertySustainabilityByPropertyIdError: payload })),
	setCreatePropertySustainability: action((state, payload) => ({ createPropertySustainabilitySuccess: payload })),
	setCreatePropertySustainabilityError: action((state, payload) => ({ createPropertySustainabilityError: payload })),
	setUpdatePropertySustainability: action((state, payload) => ({ updatePropertySustainabilitySuccess: payload })),
	setUpdatePropertySustainabilityError: action((state, payload) => ({ updatePropertySustainabilityError: payload })),

  // thunks


    // get PropertyManagement list
    getPropertyManagementList: thunk(async (actions: any) => {
      try {
        const result = await call('get', `/property-sv/property/propertyManagement`, null, null);
        actions.setGetPropertyManagementList(result.data);
      } catch (error: any) {
        actions.setGetPropertyManagementListError(error.response.data);
      }
    }),
  
    // create PropertyManagement
    createPropertyManagement: thunk(async (actions: any, payload: any) => {
      try {
        const result = await call('post', '/property-sv/property/propertyManagement', payload, null);
        actions.setCreatePropertyManagement(result.data);
      } catch (error: any) {
        actions.setCreatePropertyManagementError(error.response.data);
      }
    }),
  
    // update PropertyManagement
    updatePropertyManagement: thunk(async (actions: any, payload: any) => {
      try {
        const result = await call('patch', '/property-sv/property/propertyManagement', payload, null);
        if (payload.isDeleted) {
          actions.setRemovePropertyManagement(result.data);
        } else {
          actions.setUpdatePropertyManagement(result.data);
        }
      } catch (error: any) {
        actions.setUpdatePropertyManagementError(error.response.data);
      }
    }),

      // get ChannelManager list
      getChannelManagerList: thunk(async (actions: any) => {
        try {
          const result = await call('get', `/property-sv/property/channelManager`, null, null);
          actions.setGetChannelManagerList(result.data);
        } catch (error: any) {
          actions.setGetChannelManagerListError(error.response.data);
        }
      }),
    
      // create ChannelManager
      createChannelManager: thunk(async (actions: any, payload: any) => {
        try {
          const result = await call('post', '/property-sv/property/channelManager', payload, null);
          actions.setCreateChannelManager(result.data);
        } catch (error: any) {
          actions.setCreateChannelManagerError(error.response.data);
        }
      }),
    
      // update ChannelManager
      updateChannelManager: thunk(async (actions: any, payload: any) => {
        try {
          const result = await call('patch', '/property-sv/property/channelManager', payload, null);
          if (payload.isDeleted) {
            actions.setRemoveChannelManager(result.data);
          } else {
            actions.setUpdateChannelManager(result.data);
          }
        } catch (error: any) {
          actions.setUpdateChannelManagerError(error.response.data);
        }
      }),


  getAllProperties: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/property-sv/properties?brand=${payload?.brand}&city=${payload?.city}&status=${payload?.status}&country=${payload?.country}&page=${payload?.page}&perPage=${payload?.perPage}`, null, null);
      actions.setAllPropertiesSuccess(result.data);
    } catch (error: any) {
      // actions.setGetPropertiesError(error.response.data);
    }
  }),

  getAllPropertiesList: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/property-sv/properties?brand=${payload?.brand}&city=${payload?.city}&status=${payload?.status}&country=${payload?.country}&page=${payload?.page}&perPage=${payload?.perPage}`, null, null);
      actions.setAllPropertiesListSuccess(result.data);
    } catch (error: any) {
      // actions.setGetPropertiesError(error.response.data);
    }
  }),


  propertyPrimaryImage: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property/select-primary-image', payload, null);
      actions.setPropertyPrimaryImageSuccess(result.data);
    } catch (error: any) {
      actions.setPropertyPrimaryImageError(error.response.data);
    }
  }),
  createProperty: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property', payload, null);
      actions.setCreateProperty(result.data);
    } catch (error: any) {
      actions.setCreatePropertyError(error.response.data);
    }
  }),

  updateProperty: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/property-sv/property', payload, null);
      actions.setUpdateProperty(result.data);
    } catch (error: any) {
      actions.setUpdatePropertyError(error.response.data);
    }
  }),

  updatePropertyAuditAndMYOPurpose: thunk(async (actions: any, payload: any) => {
    await call('patch', '/property-sv/property', payload, null);
  }),

  getProperties: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', '/property-sv/property', null, payload);
      actions.setGetProperties(result.data);
    } catch (error: any) {
      actions.setGetPropertiesError(error.response.data);
    }
  }),

  getProperty: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/property-sv/property/${id}`, null, null);
      actions.setGetProperty(result.data);
    } catch (error: any) {
      actions.setGetPropertyError(error.response.data);
    }
  }),

  getBrandProperties: thunk(async (actions: any, brandId: any) => {
    try {
      const result = await call('get', `/property-sv/property/brand/${brandId}`, null, null);
      actions.setGetBrandProperties(result.data);
    } catch (error: any) {
      actions.setGetBrandPropertiesError(error.response.data);
    }
  }),

  getPropertyTypes: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/property-sv/property/type/list`, null, null);
      actions.setGetPropertyTypes(result.data);
    } catch (error: any) {
      actions.setGetPropertyTypesError(error.response.data);
    }
  }),

  //upload property image
  uploadPropertyImage: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property/image', payload, null);
      if (payload.isDeleted) {
        actions.setRemovePropertyImage(result.data);
      }
      else if (payload.imageId !== '' && payload.isDeleted === false) {
        actions.setUpdatePropertyImage(result.data);
      }
      else {
        actions.setUploadPropertyImage(result.data);
      }
    } catch (error: any) {
      actions.setUploadPropertyImageError(error.response.statusText);
    }
  }),

  // get property image list
  getPropertyImage: thunk(async (actions: any, propertyId: any) => {
    try {
      const result = await call('get', `/property-sv/property/image/${propertyId}`, null, null);
      actions.setGetPropertyImage(result.data);
    } catch (error: any) {
      actions.setGetPropertyImageError(error.response.data);
    }
  }),

  // get property image tag
  getPropertyImageTag: thunk(async (actions: any, brandId: any) => {
    try {
      const result = await call('get', `/property-sv/property/image/tag/${brandId}`, null, null);
      actions.setGetPropertyImageTag(result.data);
    } catch (error: any) {
      actions.setGetPropertyImageTagError(error.response.data);
    }
  }),

  // get property amenities
  getPropertyAmenities: thunk(async (actions: any, propertyId: any) => {
    try {
      const result = await call('get', `/property-sv/property/assignAmenity/${propertyId}`, null, null);
      actions.setGetPropertyAmenities(result.data);
    } catch (error: any) {
      actions.setGetPropertyAmenitiesError(error.response.data);
    }
  }),

  // get amenity list
  getAmenityList: thunk(async (actions: any) => {
    try {
      const result = await call('get', `/property-sv/property/amenity`, null, null);
      actions.setGetAmenityList(result.data);
    } catch (error: any) {
      actions.setGetAmenityListError(error.response.data);
    }
  }),

  // create amenity
  createAmenity: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property/amenity', payload, null);
      actions.setCreateAmenity(result.data);
    } catch (error: any) {
      actions.setCreateAmenityError(error.response.data);
    }
  }),

  // update amenity
  updateAmenity: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/property-sv/property/amenity', payload, null);
      if (payload.isDeleted) {
        actions.setRemoveAmenity(result.data);
      }
      actions.setUpdateAmenity(result.data);
    } catch (error: any) {
      actions.setUpdateAmenityError(error.response.data);
    }
  }),


  //create and update property amenity
  assignPropertyAmenity: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property/assignAmenity', payload, null);
      actions.setAssignPropertyAmenity(result.data);
    } catch (error: any) {
      actions.setAssignPropertyAmenityError(error.response.data);
    }
  }),

  // get all propertyName list
  getAllPropertyNameList: thunk(async (actions: any) => {
    try {
      const result = await call('get', `/property-sv/property/name/list`, null, null);
      actions.setGetAllPropertyNameList(result.data);
    } catch (error: any) {
      actions.setGetAllPropertyNameListError(error.response.data);
    }
  }),

  // get check in process list
  getCheckInProcessDetail: thunk(async (actions: any) => {
    try {
      const result = await call('get', `/property-sv/property/checkInProcess/list`, null, null);
      actions.setGetCheckInProcessList(result.data);
    } catch (error: any) {
      actions.setGetCheckInProcessListError(error.response.data);
    }
  }),

  // create check in process
  createCheckInProcessDetail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property/checkInProcess', payload, null);
      actions.setCreateCheckInProcess(result.data);
    } catch (error: any) {
      actions.setCreateCheckInProcessError(error.response.data);
    }
  }),

  // update check in process
  updateCheckInProcessDetail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/property-sv/property/checkInProcess', payload, null);
      if (payload.isDeleted) {
        actions.setRemoveCheckInProcess(result.data);
      } else {
        actions.setUpdateCheckInProcess(result.data);
      }
    } catch (error: any) {
      actions.setUpdateCheckInProcessError(error.response.data);
    }
  }),

  // get property check in process list
  getPropertyCheckInProcessDetailByPropertyId: thunk(async (actions: any, propertyId: any) => {
    try {
      const result = await call('get', `/property-sv/property/propertyCheckInProcess/list/${propertyId}`, null, null);
      actions.setGetPropertyCheckInProcessDetailById(result.data);
    } catch (error: any) {
      actions.setGetPropertyCheckInProcessDetailByIdError(error.response.data);
    }
  }),

  // create property check in process
  createPropertyCheckInProcessDetail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property/propertyCheckInProcess', payload, null);
      actions.setCreatePropertyCheckInProcessDetail(result.data);
    } catch (error: any) {
      actions.setCreatePropertyCheckInProcessDetailError(error.response.data);
    }
  }),

  // update property check in process
  updatePropertyCheckInProcessDetail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/property-sv/property/propertyCheckInProcess', payload, null);
      actions.setUpdatePropertyCheckInProcessDetail(result.data);
    } catch (error: any) {
      actions.setUpdatePropertyCheckInProcessDetailError(error.response.data);
    }
  }),

  // get property finance details
  getPropertyFinanceDetailById: thunk(async (actions: any, propertyId: any) => {
    try {
      const result = await call('get', `/property-sv/property/propertyFinance/${propertyId}`, null, null);
      actions.setGetPropertyFinanceDetail(result.data);
    } catch (error: any) {
      actions.setGetPropertyFinanceDetailError(error.response.data);
    }
  }),

  // create new property finance
  createPropertyFinanceDetail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property/propertyFinance', payload, null);
      actions.setCreatePropertyFinance(result.data);
    } catch (error: any) {
      actions.setCreatePropertyFinanceError(error.response.data);
    }
  }),

  // update property finance
  updatePropertyFinanceDetail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/property-sv/property/propertyFinance', payload, null);
      actions.setUpdatePropertyFinance(result.data);
    } catch (error: any) {
      actions.setUpdatePropertyFinanceError(error.response.data);
    }
  }),

  // //////////////////////////////// Transport get by property id ////////////////////////////////////////
  getTransport: thunk(async (actions: any, propertyId: any) => {
    try {
      const result = await call('get', `/property-sv/property/transport/${propertyId}`, null, null);
      actions.setGetTransportSuccess(result.data);
    } catch (error: any) {
      actions.setGetTransportError(error.response.data);
    }
  }),

  // //////////////////////////////// Transport create ////////////////////////////////////////
  createTransport: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property/transport', payload, null);
      actions.setCreateTransportSuccess(result.data);
    } catch (error: any) {
      actions.setCreateTransportError(error.response.data);
    }
  }),

  // //////////////////////////////// Transport update ////////////////////////////////////////
  updateTransport: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', `/property-sv/property/transport`, payload, null);
      actions.setUpdateTransportSuccess(result.data);
    } catch (error: any) {
      actions.setUpdateTransportError(error.response.data);
    }
  }),

  // //////////////////////////////// GuestComfort get by property id ////////////////////////////////////////
  getGuestComfort: thunk(async (actions: any, propertyId: any) => {
    try {
      const result = await call('get', `/property-sv/property/guest-comfort/${propertyId}`, null, null);
      actions.setGetGuestComfortSuccess(result.data);
    } catch (error: any) {
      actions.setGetGuestComfortError(error.response.data);
    }
  }),

  // //////////////////////////////// GuestComfort create ////////////////////////////////////////
  createGuestComfort: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property/guest-comfort', payload, null);
      actions.setCreateGuestComfortSuccess(result.data);
    } catch (error: any) {
      actions.setCreateGuestComfortError(error.response.data);
    }
  }),

  // //////////////////////////////// GuestComfort update ////////////////////////////////////////
  updateGuestComfort: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', `/property-sv/property/guest-comfort`, payload, null);
      actions.setUpdateGuestComfortSuccess(result.data);
    } catch (error: any) {
      actions.setUpdateGuestComfortError(error.response.data);
    }
  }),


  getAllPropertyCities: thunk(async (actions: any, countryCode: any) => {
    try {
      const result = await call('get', `/property-sv/property/list/allCities/${countryCode}`, null, null);
      actions.setGetAllPropertyCitiesSuccess(result.data);
    } catch (error: any) {
      actions.setGetAllPropertyCitiesError(error.response.data);
    }
  }),


  getAllPropertyCountries: thunk(async (actions: any) => {
    try {
      const result = await call('get', `/property-sv/property/list/allCountries`, null, null);
      actions.setGetAllPropertyCountriesSuccess(result.data);
    } catch (error: any) {
      actions.setGetAllPropertyCountriesError(error.response.data);
    }
  }),

  getAllPropertyCitiesByCountryNBrand: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/property-sv/property/list/brandCities/${payload.countryCode}/${payload.brandId}`, null, null);
      actions.setGetAllPropertyCitiesByCountryNBrandSuccess(result.data);
    } catch (error: any) {
      actions.setGetAllPropertyCitiesByCountryNBrandError(error.response.data);
    }
  }),

  // approval 
  propertyApproval: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property/approved', payload, null);
      actions.setPropertyApprovalSuccess(result.data);

      //socket Notification
      // if (result.data !== undefined && result.data !== null) {
      //   let notificationData = `<b> ${result.data?.propertyName} </b>  was updated to <b> ${result.data?.propertyStatus} </b>`;
      //   // await socket.emit("send_message", notificationData);
      // }
    } catch (error: any) {
      actions.setPropertyApprovalError(error.response.data);
    }
  }),

  propertyReviewed: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/property-sv/property/reviewed', payload, null);
      actions.setPropertyReviewSuccess(result.data);

      //socket Notification
      // if (result.data !== undefined && result.data !== null) {
      //   // let notificationData = `<b> ${result.data?.propertyName} </b>  was updated to <b> ${result.data?.propertyStatus} </b>`;
      //   // await socket.emit("send_message", notificationData);
      // }
    } catch (error: any) {
      actions.setPropertyReviewError(error.response.data);
    }
  }),

  // detailed property 
  propertyDetailed: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', '/property-sv/property-details/' + payload.propertyId, null, null);
      actions.setPropertyDetailedSuccess(result.data);

    } catch (error: any) {
      actions.setPropertyDetailedError(error.response.data);
    }
  }),


  getPropertySustainabilityByPropertyId: thunk(async (actions: any, propertyId: any) => {
		try {
			const result = await call('get', `/property-sv/property/propertySustainability/${propertyId}`, null, null);
			actions.setGetPropertySustainabilityByPropertyId(result.data);
		} catch (error: any) {
			actions.setGetPropertySustainabilityByPropertyIdError(error.response.data);
		}
	}),

	createPropertySustainability: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/property-sv/property/propertySustainability`, payload, null);
			actions.setCreatePropertySustainability(result.data);
		} catch (error: any) {
			actions.setCreatePropertySustainabilityError(error.response.data);
		}
	}),

	updatePropertySustainability: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('patch', `/property-sv/property/propertySustainability`, payload, null);
			actions.setUpdatePropertySustainability(result.data);
		} catch (error: any) {
			actions.setUpdatePropertySustainabilityError(error.response.data);
		}
	}),
}