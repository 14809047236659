import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Row, Container, Image, Card, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";

import bookingdetails from "../../../../images/icons/svg/bookingdetails.svg";
import moment from "moment-timezone";

import momentTimezone from "moment-timezone";

import { daysBetween, isBooker } from "../../../../common/functions";
import { $mysaTeal, $mysaYellow, slideImageStyle } from "../../../../common/components-style";
import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";
import { getStorage } from "../../../../common/storage";
import { travelerOptionForm } from "../../../../common/interfaces";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

type requestedInventoryType = {
    brandId: String,
    propertyId: String,
    inventoryId: String
};

type requestedBookingPayloadType = {
    clientId: String,
    programmeId: String,
    requestUserId: String,
    travelerName: String,
    checkInDate: String,
    checkOutDate: String,
    messageText: String,
    requestedInventory: Array<requestedInventoryType>,
    location: {
        locationId: String,
        countryISO: String,
        countryName: String,
        locationName: String,
        programmeType: String
    }
};
const TravelerOptionsBookerView: React.FC<any> = ({ details }) => {

    /**
     * redirect hooks
     */
    const { push } = useHistory();


    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue,
        register
    } = useForm<travelerOptionForm>();
    /**
* moment
*/



    /**
     * local state management
     */
    const history = useHistory();
    const clientID = getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('programmeData')?.clientId?._id;
    const program = getStorage('programmeData') ? getStorage('programmeData') : getStorage('makeBookingProgram') ? getStorage('makeBookingProgram')?.prog : getStorage('programmeData');
    const bookingProgramSearch = getStorage('makeBookingProgramSearch');
    const checkInDate = getStorage('makeBookingCheckInDate');
    const checkOutDate = getStorage('makeBookingCheckOutDate');
    const userId = getStorage('myo_logged_user').userData._id;
    const [noOfNights, setNoOfNights] = useState<any>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [bookingList, setBookingList] = useState<any>();
    const [imageList, setImageList] = useState<any>();

    /**
     * react life cycle 
     */



    const {
        createBookingRequest,
        getFreshAvailabilityAndRateByPropertyIdAndInventoryId,
    } = useStoreActions<any>((actions) => ({
        getFreshAvailabilityAndRateByPropertyIdAndInventoryId: actions.booking.getFreshAvailabilityAndRateByPropertyIdAndInventoryId,
        createBookingRequest: actions.booking.createBookingRequest,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess,
        getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError,
        getBookingRequestResponseSuccess,
        getBookingRequestResponseError

    } = useStoreState<any>((state) => ({
        getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess: state.booking.getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess,
        getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError: state.booking.getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError,
        getBookingRequestResponseSuccess: state.booking.getBookingRequestResponseSuccess,
        getBookingRequestResponseError: state.booking.getBookingRequestResponseError

    }));

    const BackToList = useCallback(() => {
        history.push(isBooker() ? '/admin/booker-booking-reports' : '/admin/corporate-booking-reports', { tabKey: 'travelerOptions' })

    }, [history])



    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (details) {
            const date1 = new Date(details?.checkInDate);
            const date2 = new Date(details?.checkOutDate);
            const Difference_In_Time = date2.getTime() - date1.getTime();

            const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            setNoOfNights(Difference_In_Days);
            const data: any = {};

            data.mPropertyId = details?.propertyId?._id;
            data.inventoryId = details?.inventoryId?._id;
            data.checkInDate = moment(new Date(details?.checkInDate)).format('YYYY-MM-DD');
            data.checkOutDate = moment(new Date(details?.checkOutDate)).format('YYYY-MM-DD');

            getFreshAvailabilityAndRateByPropertyIdAndInventoryId(data);
        }


    }, [details, getFreshAvailabilityAndRateByPropertyIdAndInventoryId]);


    useEffect(() => {
        if (getBookingRequestResponseSuccess) {
            setLoading(false);
            toast.success("Booking requested successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            BackToList();
        }

        if (getBookingRequestResponseError) {
            setLoading(false);
            toast.error(getBookingRequestResponseError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [BackToList, getBookingRequestResponseError, getBookingRequestResponseSuccess])

    //////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess) {
            if (getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess?.data) {
                const book: Array<object> = [];
                const image: Array<object> = [];
                const bed: Array<object> = [];
                const amenity: Array<object> = [];
                book.push(getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess?.data);

                _.map(getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyImage.image, element => {
                    image.push(element)
                })
                _.map(getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyInventory.bedType, element => {
                    bed.push(element.name)
                });
                if (getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyAmenity) {
                    _.map(getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess.data.propertyAmenity.amenity, element => {
                        amenity.push(element)
                        // setShowAmenity(true);
                        // setAmenityList(amenity);
                    });
                }

                setBookingList(getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess?.data);
                setImageList(image);
            }
        }
        if (getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError) {
            //message.error(getAvailabilityAndRateByPropertyIdAndInventoryIdError.message);
        }
    }, [getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess, getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError]);

    const onSubmit = useCallback(() => {
        const requestedInventory: any = [];
        const queryData = JSON.parse(details?.query);
        const data = {

            brandId: details?.requestData.property.brandId._id,
            propertyId: details?.requestData.property._id,
            inventoryId: details?.requestData.propertyInventory._id,
            rateAndCurrency: {
                currency: details?.requestData.property?.currency,
                rate: (Number(details?.requestData.rateAndPolicy?.finalRate) / noOfNights).toFixed(0),
            },
            query: JSON.parse(details?.query)
        };
        requestedInventory.push(data);
        const message: any = watch('message');

        const payload: requestedBookingPayloadType = {
            clientId: clientID,
            programmeId: program?._id,
            requestUserId: userId,
            travelerName: details?.travelerId?.firstName + '' + details?.travelerId?.lastName,
            messageText: message,
            checkInDate: moment(new Date(details?.checkInDate)).format('YYYY-MM-DD'),
            checkOutDate: moment(new Date(details?.checkOutDate)).format('YYYY-MM-DD'),
            requestedInventory: requestedInventory,
            location: {
                programmeType: program?.programmeType?.name,
                locationId: bookingProgramSearch?.locationId ? bookingProgramSearch?.locationId : queryData?.placeId,
                countryISO: bookingProgramSearch?.location?.countryCode ? bookingProgramSearch?.location?.countryCode : queryData?.country?.countryCode,
                countryName: bookingProgramSearch?.location?.country ? bookingProgramSearch?.location?.country : queryData?.country?.country,
                locationName: bookingProgramSearch?.location?.locationName ? bookingProgramSearch?.location?.locationName : queryData?.placeId
            }
        }

        setLoading(true);
        createBookingRequest(payload);
    }, [bookingProgramSearch?.location?.country, bookingProgramSearch?.location?.countryCode, bookingProgramSearch?.location?.locationName, bookingProgramSearch?.locationId, clientID, createBookingRequest, details?.checkInDate, details?.checkOutDate, details?.query, details?.requestData.property._id, details?.requestData.property.brandId._id, details?.requestData.property?.currency, details?.requestData.propertyInventory._id, details?.requestData.rateAndPolicy?.finalRate, details?.travelerId?.firstName, details?.travelerId?.lastName, noOfNights, program?._id, program?.programmeType?.name, userId, watch]);

    // console.log(details, 'details')

    return (<>
        <Row >
            <Col md={6}>

                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={{ pathname: isBooker() ? "/admin/booker-booking-reports" : "/admin/corporate-booking-reports", state: { tabKey: 'travelerOptions' } }} >Options</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{bookingList?.property?.propertyName} by {bookingList?.property?.brandId?.name}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>

        </Row>xn
        <Row className="mt-5">
            <Col md={6} xs={12} className="text-center">

                <Image width={300} src={bookingList?.propertyImage?.image[0]?.url} alt={bookingList?.propertyImage?.image[0]?.url} thumbnail={true} style={slideImageStyle}>
                </Image>
            </Col>

        </Row >
        <Row className="mt-5">
            <Col md={6} xs={6} >
                <div>
                    <p style={{ fontSize: '26px', fontWeight: 'bold' }}>{bookingList?.property?.propertyName} {'by'}<Button id='approve-button' style={{ width: '190px', marginLeft: '15px' }} onClick={() => {
                        const param = {
                            data: bookingList,
                            image: imageList,
                            details: details,
                            isBookerPage: true
                        };


                        push("/admin/traveler-property-page", { query: param });
                    }} ><b>View Property</b>   </Button> </p>

                </div>
                <div>
                    <p style={{ fontSize: '22px', fontWeight: 'bold' }}>{bookingList?.property?.brandId?.name} </p>
                </div>
                <div>
                    <p style={{ fontSize: '20px' }}>{bookingList?.property?.address1} </p>
                </div>
                <div>
                    <p style={{ fontSize: '20px' }}>{details?.inventoryId?.accommodationDetail} </p>
                </div>
            </Col>

            {bookingList?.isAvailable === false &&
                <Col md={6} xs={6} style={{ marginLeft: '-320px', marginTop: '100px' }}>
                    <Col md={6} style={{ textAlign: 'left' }}>
                        <Form.Control as="textarea"
                            rows={3} placeholder="Message"   {...register("message")} />
                        <div className="invalid-feedback">Please add message!</div>
                    </Col>
                </Col>}


        </Row>

        <Row className="mt-5">
            <Col md={6} xs={6} >
                <div>
                    <p style={{ fontSize: '26px', fontWeight: 'bold' }}>{'Current Property Status:'} <span style={{ fontSize: '26px', fontWeight: 'bold', color: bookingList?.isAvailable === true ? $mysaTeal : $mysaYellow }}>{bookingList?.isAvailable === true ? 'Live Booking' : 'On Request'} </span>
                        {bookingList?.isAvailable === true && <Button id='approve-button' style={{ width: '190px', marginLeft: '15px' }} onClick={() => {
                            const param = {
                                data: bookingList,
                                details: details
                            };


                            push("/admin/booking-property-page", { query: param });
                        }} ><b>Book</b>   </Button>}

                        {bookingList?.isAvailable === false && <Button id='edit-button' style={{ width: '200px', marginLeft: '40px' }} onClick={() => {
                            onSubmit();
                        }} ><b>Request availability</b>   </Button>}
                    </p>
                </div>

            </Col>

        </Row >

        <Row className="mt-5">
            <Container fluid className="p-0">
                <div className="jumbotron" style={{ marginTop: "-20px" }}>
                    <div className="d-flex flex-row">
                        <div className="p-2">  <img width={'100%'} src={bookingdetails} alt={`option details`} /></div>
                        <div className="p-2" style={{ fontSize: '20px' }}> <strong> Option Details</strong>
                            {details?.status !== 'pending' && <strong style={{ marginLeft: "80px", color: '#2DBDB6' }}> {details?.status === 'approved' ? 'Approved' : details?.status === 'declined' ? 'Declined' : ''}</strong>}
                        </div>
                    </div>
                    <div className="mt-3">
                        <Row>
                            <Col md={6} xs={6}>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>First Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{details?.travelerId?.firstName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Second Name</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{details?.travelerId?.lastName}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Email Address</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{details?.travelerId?.email}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Date sent</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{moment(details?.createdAt).format('D MMM YYYY')}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Time sent</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{momentTimezone(details?.createdAt).format('HH:mm')}</Col>
                                </Row>
                            </Col>

                            <Col md={6} xs={6}>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-in </strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{
                                        moment(details?.checkInDate).format('D MMM YYYY')
                                    }</Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Check-out </strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{moment(details?.checkOutDate).format('D MMM YYYY')
                                    }</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>No of Nights</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{daysBetween(new Date(bookingList?.checkIn), new Date(bookingList?.checkOut))}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Average Nightly Rate (ex Tax)</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{`${bookingList?.rateAndPolicy?.dailyRate[0]?.rate} (${bookingList?.property?.currency})`}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}><strong>Total Rate (ex Tax)</strong> </Col>
                                    <Col md={6} xs={6} style={{ fontSize: '20px' }}>{`${bookingList?.rateAndPolicy?.finalRate} (${bookingList?.property?.currency})`}</Col>
                                </Row>





                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <Row style={{ marginTop: '10px' }}>
                <Col md={6}>
                    <Button id='edit-button' style={{ width: '190px', marginLeft: '15px' }} onClick={BackToList} >
                        <b>Close</b>
                    </Button>
                </Col>
                <Col md={6}>
                </Col>
            </Row>

            {/* <CancelBookingWithoutCharge cancelBoxDisplay={cancelBoxDisplay} ActionNo={BackToList} ActionYes={cancelPending} loading={loading} /> */}
        </Row >
    </>)

};

export default TravelerOptionsBookerView;