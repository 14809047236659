import React, { useCallback, useLayoutEffect, useState } from 'react'
import TreeNode from '../../../../common/TreeNode'
import { UserAccess } from '../../../../../common/constants';
import { getCheckedStatus, setCheckedValues } from '../../../../../common/functions'
import { useStoreActions } from 'easy-peasy';
import _ from 'lodash';
import { formButton } from '../../../../../common/components-style';
import { Button, Modal } from 'react-bootstrap';
import { Tree } from 'antd';

const initTreeData = [
	{
		title: <p className="font-bold">Company user management</p>,
		key: 'CU',
		children: [
			{
				title: <TreeNode text={UserAccess.company_access.booker.text} code={UserAccess.company_access.booker.code} isCompany={true} />,
				key: `${UserAccess.company_access.booker.code}`
			},
			{
				title: <TreeNode text={UserAccess.company_access.traveler.text} code={UserAccess.company_access.traveler.code} isCompany={true}/>,
				key: `${UserAccess.company_access.traveler.code}`
			},
			{
				title: <TreeNode text={UserAccess.company_access.booking.text} code={UserAccess.company_access.booking.code} isCompany={true} />,
				key: `${UserAccess.company_access.booking.code}`
			},
			{
				title: <TreeNode text={UserAccess.company_access.location.text} code={UserAccess.company_access.location.code} isCompany={true} />,
				key: `${UserAccess.company_access.location.code}`
			},
			{
				title: <TreeNode text={UserAccess.company_access.payment.text} code={UserAccess.company_access.payment.code} isCompany={true} />,
				key: `${UserAccess.company_access.payment.code}`
			},
			{
				title: <TreeNode text={UserAccess.company_access.proposal.text} code={UserAccess.company_access.proposal.code} isCompany={true} />,
				key: `${UserAccess.company_access.proposal.code}`
			},
			{
				title: <TreeNode text={UserAccess.company_access.programme.text} code={UserAccess.company_access.programme.code} isCompany={true} />,
				key: `${UserAccess.company_access.programme.code}`
			},
			{
				title: <TreeNode text={UserAccess.company_access.permission.text} code={UserAccess.company_access.permission.code} isCompany={true} />,
				key: `${UserAccess.company_access.permission.code}`
			},
		]
	},
];

const CompanyUserPermissionModel: React.FC<any> = ({ hideUpdate = true, clientId, programmeId, userMappingData, corporateProgrammes, onClose, selectedUser }): JSX.Element => {
	const [treeData, setTreeData] = useState(initTreeData);
	const [checkArr, setCheckArr] = useState<any>([]);
	const [checkedKeys, setCheckedKeys] = useState<any>([]);

	const updateAccess = useStoreActions<any>((actions) => actions.user.updateAccess);




	///////////////////////////////////////////////////////////////////////////////////////////
	const close = useCallback(() => {
		const tmpArr: any = [];
		tmpArr[0]?.children.pop();
	}, []);


	

	///////////////////////////////////////////////////////////////////////////////////////////////////
	const onUpdate = useCallback(() => {
		updateAccess({
			id: selectedUser.arrId,
			userId: selectedUser.userId,
			accessType: "programme",
			access: getCheckedStatus(checkArr)
		});
		// setTimeout(() => {
		// 	onUpdateUserRole(checkArr);
		// 	onClose();
		// 	close();
		// }, 1000);

	}, [checkArr, selectedUser?.arrId, selectedUser?.userId, updateAccess])

	////////////////////////////////////////////////////////////////////////////////////////////////
	useLayoutEffect(() => {
		if (userMappingData) {
			const arr: any = [];
			const userMapping = _.filter(userMappingData, a => a.userId === selectedUser?.userId);

			const programme = _.filter(userMapping[0].clients, (a) => a.clientId === clientId).shift()?.corporateProgramme || [];
			const access = _.filter(programme, (a) => a.programmeId === programmeId).shift()?.access || [];
			_.each(access, a => {
				setCheckedValues(a);
				arr.push(a.module);
			});
			setCheckedKeys(arr);
			setCheckArr(arr);
		}
	}, [clientId, programmeId, selectedUser, userMappingData])

	///////////////////////////////////////////////////////////////////////////////////////////////////////
	const onExpand = useCallback(() => {
		setTimeout(() => {
			if (userMappingData) {
				const userMapping = _.filter(userMappingData, a => a.userId === selectedUser.userId);
				const programme = _.filter(userMapping[0].clients, (a) => a.clientId === clientId).shift()?.corporateProgramme || [];
				// get only particular programme Id wise
				const filterAccess = _.filter(programme, (a) => a.programmeId === programmeId).shift()?.access || [];

				const filterModule = filterAccess.filter(val => val.module.startsWith('CU'));
				_.each(filterModule, a => {
					setCheckedValues(a)
				}
				);
			}
		}, 1000)

	}, [clientId, programmeId, selectedUser, userMappingData]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	const onCheckAccess = useCallback((data) => {
		setCheckArr(data);
		setCheckedKeys(data);

	}, [])

	///////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div>
			{/* <Modal
				show={true}
				onHide={() => { onClose(); close() }}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				title="User Permissions"
				style={{ top: 20 }}
				visible={true}
			>

				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						User Permissions
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p className="font-bold mb-2">{corporateProgrammes[0]?.clientId?.companyName}</p>
					<p className="font-bold mb-2 ml-3">{corporateProgrammes[0]?.programmeName}</p>
					<Tree
						checkable
						defaultExpandAll
						checkedKeys={checkedKeys}
						onExpand={onExpand}
						onCheck={(data, opt) => { onCheckAccess(data) }}
						treeData={treeData}
					/>
				</Modal.Body>
				{
					hideUpdate && <Modal.Footer>
						<Button type="submit" id='approve-button' style={formButton} onClick={() => onUpdate()}>Update</Button>
						<Button id='edit-button' style={formButton} onClick={() => { onClose(); close() }}>Close</Button>
					</Modal.Footer>
				}

			</Modal> */}


			<Modal
				show={true}
				onHide={() => { onClose(); close() }}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						User Permissions
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Tree
						checkable
						defaultExpandAll
						checkedKeys={checkedKeys}
						onExpand={onExpand}
						onCheck={(data, opt) => { onCheckAccess(data); }}
						treeData={treeData}
					/>
				</Modal.Body>
				{
					hideUpdate && <Modal.Footer>
						<Button type="submit" id='approve-button' style={formButton} onClick={() => onUpdate()}>Send Invite</Button>
						<Button id='edit-button' style={formButton} onClick={() => { onClose(); close() }}>Close</Button>
					</Modal.Footer>
				}

			</Modal>
		</div>
	)
}

export default CompanyUserPermissionModel
