import { message, Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';


import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import { formButton } from '../../../../common/components-style';
import { ErrorMessage } from '@hookform/error-message';
import { PaymentPermissions } from '../../../../common/constants';
import { PaymentPermissionForm } from '../../../../common/interfaces';

const { Option } = Select;
const PaymentPermission: React.FC<any> = ({ programmeId, selectedClient, }): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [programmeTypeList, setProgrammeTypeList] = useState<any>();
    const { register, handleSubmit, formState: { errors }, control } = useForm<PaymentPermissionForm>();
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [paymentPermissions, setPaymentPermissions] = useState<any>();
    const [paymentData, setPaymentData] = useState<any>();
    const [selectedClientName, setSelectedClientName] = useState<string>("");
    const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");
    // const [isProcessDeleted, setIsProcessDeleted] = useState<boolean>(false);
    // const [selectedProgrammeId, setSelectedProgrammeId] = useState<string>("");
    const [clientId, setClientId] = useState<string>("");
    const [programmeType, setProgrammeType] = useState<any>();
    const [paymentPermissionId, setPaymentPermissionId] = useState<string>("");

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getClientList, resetClient, getProgrammeType, getCorporateProgrammeById, createPaymentPermission, updatePaymentPermission, getPaymentPermissionByProgrammeId } = useStoreActions<any>((actions) => ({
        getProgrammeType: actions.client.getProgrammeType,
        getClientList: actions.client.getClientList,
        getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
        resetClient: actions.client.resetClient,
        createPaymentPermission: actions.client.createPaymentPermission,
        updatePaymentPermission: actions.client.updatePaymentPermission,
        getPaymentPermissionByProgrammeId: actions.client.getPaymentPermissionByProgrammeId
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {

        getProgrammeTypeSuccess,
        createPaymentPermissionSuccess,
        createPaymentPermissionError,
        updatePaymentPermissionSuccess,
        updatePaymentPermissionError,
        getPaymentPermissionByProgrammeIdSuccess,
        getPaymentPermissionByProgrammeIdError,
        getCorporateProgrammeByIdSuccess,
        getCorporateProgrammeByIdError
    } = useStoreState<any>((state) => ({

        getProgrammeTypeSuccess: state.client.getProgrammeTypeSuccess,
        createPaymentPermissionSuccess: state.client.createPaymentPermissionSuccess,
        createPaymentPermissionError: state.client.createPaymentPermissionError,
        updatePaymentPermissionSuccess: state.client.updatePaymentPermissionSuccess,
        updatePaymentPermissionError: state.client.updatePaymentPermissionError,
        getPaymentPermissionByProgrammeIdSuccess: state.client.getPaymentPermissionByProgrammeIdSuccess,
        getPaymentPermissionByProgrammeIdError: state.client.getPaymentPermissionByProgrammeIdError,

        getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
        getCorporateProgrammeByIdError: state.client.getCorporateProgrammeByIdError,

    }));


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        // getClientList();
        getProgrammeType();

    }, [getClientList, getProgrammeType, programmeId, selectedClient]);

    // ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (programmeId) {
            getCorporateProgrammeById(programmeId);
        }
    }, [getCorporateProgrammeById, programmeId]);


    useEffect(() => {
        const params: any = {};
        params.clientId = selectedClient?.key;
        params.programmeId = programmeId

        if (selectedClient) {
            getPaymentPermissionByProgrammeId(params);
        }
    }, [getPaymentPermissionByProgrammeId, getCorporateProgrammeById, programmeId, selectedClient]);


    const callList = useCallback(() => {
        const params: any = {};
        params.clientId = selectedClient?.key;
        params.programmeId = programmeId
        if (programmeId) {
            getPaymentPermissionByProgrammeId(params);
        }
    }, [getPaymentPermissionByProgrammeId, programmeId, selectedClient?.key])
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (getProgrammeTypeSuccess) {
            setProgrammeTypeList(getProgrammeTypeSuccess.data);
        }
    }, [getProgrammeTypeSuccess]);

    useEffect(() => {
        if (getCorporateProgrammeByIdSuccess?.data) {
            const { clientId, programmeName, programmeType } = getCorporateProgrammeByIdSuccess.data;
            // setClientDetails(clientId);
            setClientId(clientId._id);
            setSelectedClientName(clientId.companyName);
            // setSelectedProgrammeId(_id);
            setSelectedProgrammeName(programmeName);
            setProgrammeType(programmeType);
        }
        if (getCorporateProgrammeByIdError) {
            toast.error(getCorporateProgrammeByIdError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

        }
    }, [getCorporateProgrammeByIdSuccess, getCorporateProgrammeByIdError]);

    useEffect(() => {
        if (getPaymentPermissionByProgrammeIdSuccess) {
            setPaymentData(getPaymentPermissionByProgrammeIdSuccess.data);
            setPaymentPermissionId(getPaymentPermissionByProgrammeIdSuccess?.data?._id);
        }
        if (getPaymentPermissionByProgrammeIdError) {
            toast.error(getPaymentPermissionByProgrammeIdError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

        }
    }, [getPaymentPermissionByProgrammeIdSuccess, getPaymentPermissionByProgrammeIdError]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (createPaymentPermissionSuccess) {
            setLoading(false);
            toast.success("Payment permission created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetClient();
            callList();
            setEditEnable(false);
        }
        if (createPaymentPermissionError) {
            setLoading(false);
            toast.error(createPaymentPermissionError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetClient();
            callList();
            setEditEnable(false);
        }
        if (updatePaymentPermissionSuccess) {
            setLoading(false);
            toast.success("Payment permission updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetClient();
            callList();
            setEditEnable(false);
        }
        if (updatePaymentPermissionError) {
            setLoading(false);
            message.error(updatePaymentPermissionError.message);
            resetClient();
            callList();
            setEditEnable(false);
        }

    }, [callList, createPaymentPermissionError, createPaymentPermissionSuccess, resetClient, updatePaymentPermissionError, updatePaymentPermissionSuccess]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onSubmit = useCallback((values) => {
        setLoading(true);
        delete values.programmeType;
        if (paymentPermissionId) {
            delete values.clientId;
            delete values.programmeId;
            // values.clientId = clientId;
            values.id = paymentPermissionId;
            updatePaymentPermission(values);
        } else {
            values.clientId = clientId;
            values.programmeId = programmeId;

            values.paymentPermissions = paymentPermissions;

            createPaymentPermission(values);
        }

    }, [paymentPermissionId, updatePaymentPermission, clientId, programmeId, paymentPermissions, createPaymentPermission]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const changeFormEditable = useCallback(() => {
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);


    //////////// extra seasonal form enable part //////////
    const onChangePaymentPermission = useCallback((value) => {
        setPaymentPermissions(value);
    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderProgrammeTypeList = useCallback(() => {
        if (programmeTypeList) {
            return programmeTypeList.map((prog: any, key: number) => {
                return (
                    <Option key={key} prog={prog} value={`${prog._id}`} >{prog.name}</Option>
                )
            });
        }
    }, [programmeTypeList]);


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>

            {/* <div className="text-right" style={{position: "fixed", top:"110px",right:"22px",width:"100%", maxWidth: "80%",padding: "0 15px",zIndex: 9999, }}>
         {
                !loading && !editEnable && <Button id='edit-button' style={formButton} onClick={changeFormEditable}>Edit</Button>
            }
         </div> */}



            <div>

                <div className="ml-auto edit_btn_wpr">
                    {
                        !loading && !editEnable && <Button id='edit-button' style={formButton} onClick={changeFormEditable}>Edit</Button>
                    }
                </div>
                <div className="jumbotron" style={{marginTop:"70px"}}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Company name</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{selectedClientName}</p>}
                                        {editEnable && <Form.Control className={`${errors.clientId ? 'is-invalid' : ''}`} defaultValue={selectedClientName} {...register("clientId", { required: true })} readOnly={true} />}
                                        {editEnable && <div className="invalid-feedback">Please input company name!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Name of programme</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{selectedProgrammeName}</p>}
                                        {editEnable && <Form.Control className={`${errors.programmeId ? 'is-invalid' : ''}`} defaultValue={selectedProgrammeName} {...register("programmeId", { required: true })} readOnly={true} />}
                                        {editEnable && <div className="invalid-feedback">Please input programme name!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Type of programme</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{programmeType?.name}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="programmeType"
                                                defaultValue={programmeType?._id}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Select defaultValue={programmeType?._id} className={`${errors.programmeType ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => field.onChange(value)} disabled={true}>
                                                        {renderProgrammeTypeList()}
                                                    </Select>
                                                }
                                            />}
                                        {editEnable && <div className="invalid-feedback">Please select a programmeType!</div>}
                                    </Col>
                                </Row>


                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Payment Permissions</Form.Label>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{paymentData?.paymentPermissions}</p>}

                                        {editEnable &&
                                            <Controller
                                                name="paymentPermissions"
                                                defaultValue={paymentData?.paymentPermissions}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            paymentData?.paymentPermissions === PaymentPermissions.CREATE_CARD_FOR_BOOKING
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={PaymentPermissions.CREATE_CARD_FOR_BOOKING}
                                                                    name='paymentPermissions'
                                                                    label={'Credit card for all bookings'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePaymentPermission(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={PaymentPermissions.CREATE_CARD_FOR_BOOKING}
                                                                    name='paymentPermissions'
                                                                    label={'Credit card for all bookings'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePaymentPermission(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            paymentData?.paymentPermissions === PaymentPermissions.INVOICE
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={PaymentPermissions.INVOICE}
                                                                    name='paymentPermissions'
                                                                    label={'Invoice'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePaymentPermission(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={PaymentPermissions.INVOICE}
                                                                    name='paymentPermissions'
                                                                    label={'Invoice'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangePaymentPermission(value.currentTarget.value); }}
                                                                />
                                                        }
                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="paymentPermissions"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select payment permissions!</div>} />}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={3} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={9} style={{ textAlign: 'left' }}>

                                        {!loading && editEnable &&
                                            <Button type="submit" id='approve-button' style={formButton} >Update</Button>
                                        }


                                        {
                                            loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
};

export default PaymentPermission;