import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";

const GooglMap: React.FC<any> = ({ location, setCoords }): JSX.Element => {
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);

  useEffect(() => {

    if (location) {
      setLat(location?.lat ? location.lat : 0);
      setLng(location?.lng ? location.lng : 0);
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  }, [location]);

  const onDragEnd = useCallback(({ latLng }) => {
    setLat(latLng.lat());
    setLng(latLng.lng());
    setCoords({ lat: latLng.lat(), lng: latLng.lng() })
  }, [setCoords]);

  return (
    <div className="google-map">
      <GoogleMap
        key={Math.random()}
        defaultZoom={16}
        defaultCenter={{ lat, lng }}
      >

        <Marker draggable onDragEnd={onDragEnd} position={{ lat, lng }} />
      </GoogleMap>
    </div>
  )
};

export default withScriptjs(withGoogleMap(GooglMap));