import { Button, Form, Input, message, Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { UserGroups } from '../../../common/constants';
import { getLoggedUserType, getLoggedUserEmail } from '../../../common/functions';

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const UserForm: React.FC<any> = ({ setFormOpen, user, brandList }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [phone, setPhone] = useState<string>();
  const [mobile, setMobile] = useState<string>();
  const [hideBuilding, setHideBuilding] = useState<boolean>(false);
  const [userType, setUserType] = useState<string | null>(null);
  const [propertyList, setPropertyList] = useState<any>([]);


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { updateUser, createUser, onUpdateBrand, getBrandProperties } = useStoreActions<any>((actions) => ({
    updateUser: actions.user.updateUser,
    createUser: actions.user.createUser,
    onUpdateBrand: actions.brand.onUpdateBrand,
    getBrandProperties: actions.property.getBrandProperties,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { userUpdate, userCreate, userCreateError, userUpdateError, getBrandPropertiesSuccess } = useStoreState<any>((state) => ({
    userUpdate: state.user.userUpdate,
    userUpdateError: state.user.userUpdateError,
    userCreate: state.user.userCreate,
    userCreateError: state.user.userCreateError,
    getBrandPropertiesSuccess: state.property.getBrandPropertiesSuccess,
  }));


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userUpdate) {
      message.success('User updated successfully');
      setLoading(false);
    }

    if (userUpdateError) {
      message.error(userUpdateError.message);
      setLoading(false);
    }

    if (userCreate) {
      message.success('User created successfully');
      setLoading(false);
      setFormOpen(false);
    }

    if (userCreateError) {
      message.error(userCreateError.message);
      setLoading(false);
    }
  }, [setFormOpen, userCreate, userCreateError, userUpdate, userUpdateError]);


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {


    if (getBrandPropertiesSuccess) {
      const properties = getBrandPropertiesSuccess?.data;
      let list: any = [];

      if (user) {
        const ids: Array<number> = [];
        if (userType === UserGroups.chief_property_admin) {
          list = _.filter(properties, (prop) => _.isEmpty(prop.propertyUsers) || _.find(prop.propertyUsers, user => user.role === UserGroups.chief_property_admin))

          _.each(list, val => {
            const id = _.filter(val.propertyUsers, user => user.role === UserGroups.chief_property_admin);
            if (!_.isEmpty(id)) { ids.push(val._id) }
          })
        }

        if (userType === UserGroups.property_admin) {
          list = _.filter(properties, (prop) => _.isEmpty(prop.propertyUsers) || _.find(prop.propertyUsers, user => user.role === UserGroups.property_admin))

          _.each(list, val => {
            const id = _.filter(val.propertyUsers, user => user.role === UserGroups.property_admin);
            if (!_.isEmpty(id)) { ids.push(val._id) }
          })
        }

        form.setFieldsValue({ building: ids })


      } else {
        if (userType === UserGroups.chief_property_admin) {
          list = _.filter(properties, (prop) => _.isEmpty(prop.propertyUsers) || _.find(prop.propertyUsers, user => user.role !== UserGroups.chief_property_admin))
        }

        if (userType === UserGroups.property_admin) {
          list = _.filter(properties, (prop) => _.isEmpty(prop.propertyUsers) || _.find(prop.propertyUsers, user => user.role !== UserGroups.property_admin))
        }

      }
      setPropertyList(list);
    }

  }, [form, getBrandPropertiesSuccess, user, userType]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadBrand = useCallback((email: string) => {
    if (brandList) {
      return _.filter(brandList.data, branVal => _.find(branVal.brandUsers, value => value.email === email)).shift()?.name

    }
  }, [brandList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadBrandId = useCallback((email: string) => {
    if (brandList) {
      return _.filter(brandList.data, branVal => _.find(branVal.brandUsers, value => value.email === email)).shift()?._id

    }
  }, [brandList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        email: user.email,
        userGroup: user['custom:userGroup'],
        firstName: user['custom:firstName'],
        lastName: user['custom:lastName'],
        telephone: user['custom:telephone'],
        mobile: user['custom:mobile'],
        position: user['custom:position'],
        brand: loadBrandId(user.email),
      })
      getBrandProperties(loadBrandId(user.email));
      setUserType(user['custom:userGroup'])
    } else {
      if (getLoggedUserType() !== UserGroups.super_admin) {
        const filterBrand = loadBrandId(getLoggedUserEmail());

        form.setFieldsValue({
          brand: filterBrand,
        });
        getBrandProperties(filterBrand)
      }
    }

  }, [brandList, form, getBrandProperties, loadBrand, loadBrandId, user]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onUserTypeChange = useCallback((value) => {
    setHideBuilding(UserGroups.brand_admin === value);
    setUserType(value)
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderUserTypes = useCallback((user) => {

    if (getLoggedUserType() === UserGroups.super_admin) {
      return <Select disabled={user} onSelect={onUserTypeChange}>
        <Option value="chief_property_admin">Chief Property Admin</Option>
      </Select>
    }
    if (getLoggedUserType() === UserGroups.chief_brand_admin) {
      return <Select disabled={user} onSelect={onUserTypeChange}>
        <Option value="brand_admin">Brand Admin</Option>
        <Option value="chief_property_admin">Chief Property Admin</Option>
      </Select>
    }
    if (getLoggedUserType() === UserGroups.chief_property_admin) {
      return <Select disabled={user} onSelect={onUserTypeChange}>
        <Option value="property_admin">Property Admin</Option>
      </Select>
    }
  }, [onUserTypeChange]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderBrandList = useCallback(() => {
    if (brandList) {
      return brandList.data.map((brand: any, key: number) => {
        return (
          <Option key={key} value={`${brand._id}`} >{brand.name}</Option>
        )
      });
    }

  }, [brandList]);


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onBrandSelect = useCallback((id) => {
    getBrandProperties(id);
  }, [getBrandProperties]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = useCallback((values) => {
    if (user) {
      delete values.brand;
      setLoading(true);
      updateUser(values);
    } else {
      const brand = _.filter(brandList.data, brandVal => brandVal._id === values.brand).shift();
      const newBrandUsers = [...brand.brandUsers, { email: values.email, role: values.userGroup }];

      const brandUser = _.filter(brandList.data, branVal => _.find(branVal.brandUsers, value => value.email === values.email)).shift()?.name;

      if (brandUser) {
        message.error(`${values.email} is already exists!`)
      } else {
        setLoading(true);
        onUpdateBrand({ id: brand._id, brandUsers: newBrandUsers });
        delete values.brand;
        createUser(values);
      }
    }

  }, [brandList.data, createUser, onUpdateBrand, updateUser, user]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (user) {
      setHideBuilding(_.includes([UserGroups.chief_brand_admin, UserGroups.brand_admin], user['custom:userGroup']))
    }
  }, [user]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="user-Form w-1/2">
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="User type"
          name="userGroup"
          rules={[
            { required: true, message: 'Please select a user type!' },
          ]}
        >
          {renderUserTypes(user)}
        </Form.Item>
        <Form.Item
          hidden={getLoggedUserType() !== UserGroups.super_admin}
          label="Brand"
          name="brand"
          rules={[
            { required: true, message: 'Please select a brand!' },
          ]}
        >
          <Select disabled={user} onSelect={onBrandSelect}>
            {renderBrandList()}
          </Select>
        </Form.Item>
        <Form.Item
          hidden={hideBuilding}
          label="Building"
          name="building"
        >
          <Select mode="multiple">
            {
              propertyList.map((property: any, key: number) => {
                return (
                  <Option key={key} value={`${property._id}`} >{property.propertyName}</Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input chief brand admin email!' },
            { type: 'email', message: 'Please input valid email!' },
          ]}
        >
          <Input readOnly={user} />
        </Form.Item>
        <Form.Item
          label="First name"
          name="firstName"
          rules={[{ required: true, message: 'Please input chief brand admin first name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          rules={[{ required: true, message: 'Please input chief brand admin last name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Position"
          name="position"
          rules={[{ required: true, message: 'Please input chief brand admin position!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="telephone"
        >
          <PhoneInput
            placeholder=''
            country='gb'
            value={phone}
            onChange={(phone) => { setPhone(phone);  }}
          />
        </Form.Item>
        <Form.Item
          label="Mobile"
          name="mobile"
        >
          <PhoneInput
            placeholder=''
            country='gb'
            value={mobile}
            onChange={(phone) => { setMobile(phone);}}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button loading={loading} type="primary" htmlType="submit">
            {user ? 'Update' : 'Create'}
          </Button>
          <Button onClick={() => setFormOpen(false)} className="ml-5">
            Close
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
};

export default UserForm;