import React, { useEffect, useState, useRef } from 'react';
import Select from "react-select";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Col, Modal, Row, Image } from 'react-bootstrap';
import moment from "moment";
import Slider from "react-slick";
import PdfImage from '../../../../../images/pdf-img.jpg';
import VideoImage from '../../../../../images/video-img.jpg';
import BreadCrumbPage from '../../../../common/BreadCrumbPage';

var Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  vertical: false,
  verticalSwiping: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const AuditQuestions: React.FC<any> = ({ extId = null, clientId = null, allInOnePropertyBreadCrumb, setFormOpen, selectedBrand }): JSX.Element => {
  const [data, setData] = useState<any>([]);
  const [sectionData, setSectionData] = useState<any>([]);
  const [propertyData, setPropertyData] = useState<any>([]);
  const [selectedSection, setSelectedSection] = useState<any>(null);
  const [subSectionOptions, setSubSectionOptions] = useState<{ label: string; value: string }[]>([]);
  const [selectedSubSection, setSelectedSubSection] = useState<any>(null);
  const [userModelShow, setUserModelShow] = useState<boolean>(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<{ imageUrl: string; title: string; type: number }>({ imageUrl: '', title: '', type: 0 });
  const sliderRef = useRef<any>(null);

  useEffect(() => {
    const initializeSlider = () => {
      sliderRef.current.slickPrev();
    };
    initializeSlider();

  }, [data]);

  const { getAuditPropertyCertificates } = useStoreActions<any>((actions) => ({
    getAuditPropertyCertificates: actions.audit.getAuditPropertyCertificates,
  }));

  const { getAuditPropertyCertificatesSuccess } = useStoreState<any>((state) => ({
    getAuditPropertyCertificatesSuccess: state.audit.getAuditPropertyCertificatesSuccess,
  }));

  const { getAuditBuilding } = useStoreActions<any>((actions) => ({
    getAuditBuilding: actions.audit.getAuditBuilding,
  }));

  const { getAuditBuildingSuccess } = useStoreState<any>((state) => ({
    getAuditBuildingSuccess: state.audit.getAuditBuildingSuccess,
  }));

  const { getSection } = useStoreActions<any>((actions) => ({
    getSection: actions.audit.getSection,
  }));

  const { getSectionSuccess } = useStoreState<any>((state) => ({
    getSectionSuccess: state.audit.getSectionSuccess,
  }));


  const handleImageClick = (imageUrl: string, title: string, type: number) => {
    setSelectedImageUrl({ imageUrl, title, type });
    setUserModelShow(true);
  };

  useEffect(() => {
    if (extId && selectedSection) {
      const requestData = {
        security: {
          apiKey: process.env.REACT_APP_AUDIT_API_KEY,
          user: "superadmin@mysa.global"
        },
        buildingID: extId,
        showExternal: false,
        section: selectedSection.value,
        subsection: selectedSubSection?.value || null
      };

      getAuditPropertyCertificates(requestData);
    }
  }, [extId, selectedSection, selectedSubSection]);


  useEffect(() => {
    if (getAuditPropertyCertificatesSuccess) {
      setData(getAuditPropertyCertificatesSuccess.surveys)
    }
  }, [getAuditPropertyCertificatesSuccess]);

  useEffect(() => {
    getAuditBuilding({
      security: {
        apiKey: process.env.REACT_APP_AUDIT_API_KEY,
        user: "superadmin@mysa.global"
      },
      id: extId
    })
  }, [extId, getAuditBuilding]);

  useEffect(() => {
    if (getAuditBuildingSuccess) {
      setPropertyData(getAuditBuildingSuccess.building)
    }
  }, [getAuditBuildingSuccess]);

  useEffect(() => {
    getSection({
      security: {
        apiKey: process.env.REACT_APP_AUDIT_API_KEY,
        user: "superadmin@mysa.global"
      },
    })
  }, [getSection]);

  useEffect(() => {
    if (getSectionSuccess) {
      setSectionData(getSectionSuccess.section)
    }
  }, [getSectionSuccess]);

  const filteredSections = sectionData.filter((section: any) => section.isProperty);

  const sectionOptions = filteredSections.map((section: any) => ({
    label: section.name,
    value: section.name
  }));

  useEffect(() => {
    if (propertyData) {
      propertyData.propertyName = propertyData.buildingName;
    }
    if (filteredSections.length > 0) {
      setSelectedSection({ label: filteredSections[0].name, value: filteredSections[0].name });
      setSubSectionOptions(filteredSections[0].subsection.map(subsection => ({ label: subsection.name, value: subsection.name })));
      if (propertyData && propertyData.propertyType && propertyData.propertyType.toLowerCase() === 'single contained') {
        setSelectedSubSection(null);
      }
      else {
        if (filteredSections[0].subsection.length > 0) {
          setSelectedSubSection({ label: filteredSections[0].subsection[0].name, value: filteredSections[0].subsection[0].name });
        }
      }
    }
  }, [sectionData, propertyData]);

  const handleSectionChange = (selectedOption: { label: string; value: string } | null) => {
    if (selectedOption) {
      setSelectedSection(selectedOption);
      const selectedSectionData = filteredSections.find(section => section.name === selectedOption.value);
      if (selectedSectionData && propertyData && propertyData.propertyType && propertyData.propertyType.toLowerCase() !== 'single contained') {
        const subsections = selectedSectionData.subsection;
        if (subsections.length > 0) {
          const defaultSubSection = { label: subsections[0].name, value: subsections[0].name };
          setSubSectionOptions(subsections.map(subsection => ({ label: subsection.name, value: subsection.name })));
          setSelectedSubSection(defaultSubSection);
        } else {
          setSubSectionOptions([]);
          setSelectedSubSection(null);
        }
      } else {
        setSubSectionOptions([]);
        setSelectedSubSection(null);
      }
    } else {
      setSelectedSection(null);
      setSubSectionOptions([]);
      setSelectedSubSection(null);
    }
  };
  return (
    <>
      <div className="content-title fixed_title">
        {
          <BreadCrumbPage allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} isPageName="Property" selectedBrand={selectedBrand} propertyData={propertyData} setFormOpen={setFormOpen} ></BreadCrumbPage>
        }
      </div>
      <div className="new_tab_ctnt new_tab_ctnt2">
        <Row className='mb-4 dropdown-property'>
          <Col md={4}>
            <Select
              isClearable={true}
              options={sectionOptions}
              onChange={handleSectionChange}
              value={selectedSection}
              className="new_drpdwn"
            />
          </Col>
          {propertyData && propertyData.propertyType && propertyData.propertyType.toLowerCase() !== 'single contained' ? (
            <Col md={4}>
              <Select
                isClearable={true}
                options={subSectionOptions}
                onChange={(selectedOption) => setSelectedSubSection(selectedOption)}
                value={selectedSubSection}
                className="new_drpdwn"
              />
            </Col>
          ) : null}
        </Row>

        <Modal className='modal-popup'
          show={userModelShow}
          onHide={() => setUserModelShow(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {selectedImageUrl.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedImageUrl.type === 12 && (
              <Image src={selectedImageUrl.imageUrl} className='modal-full-img ' />
            )}
            {selectedImageUrl.type === 13 && (
              <video src={selectedImageUrl.imageUrl} controls className="w-100" />
            )}
            {selectedImageUrl.type === 14 && (
              <iframe src={selectedImageUrl.imageUrl} title="PDF" className="w-100" />
            )}
          </Modal.Body>
        </Modal>

        <div className="listing-group-section">
          {data && data.length > 0 ? (
            data.map((surveyItem, index) => (
              surveyItem.survey.sections.map((section, sectionIndex) => (
                <div key={index + "-" + sectionIndex} className="list-group-item list-group-item-dark" style={{ padding: "1rem" }}>
                  <ul>
                    {section.questions
                      .filter(question =>
                        (question.type === 0 || question.type === 1 || question.type === 2 || question.type === 4 || question.type === 5 || question.type === 6)
                      )
                      .map((question, questionIndex) => {
                        const answers = question?.answer;
                        const isPositive = answers.answer === 'True';
                        const answerExists = answers && answers.answer && (isPositive ? question.positiveDisplay : question.negativeDisplay);

                        if (question.type === 0 && (!answerExists || answers.answer.trim() === '')) {
                          return null;
                        }

                        return (
                          <li key={questionIndex} className={`list-group-item d-flex justify-content-between align-items-center`}>
                            {(() => {
                              switch (question.type) {
                                case 0:
                                  let currentClassColor = ''
                                  if (isPositive) {
                                    currentClassColor = question.color;
                                  }
                                  else {
                                    currentClassColor = question.negativeColor;
                                  }
                                  return answers.answer ? (
                                    <span className={`answer ${currentClassColor === 'Positive' ? 'positive-ans' : currentClassColor === 'Negative' ? 'negative-ans' : ''}`}>{isPositive ? question.positiveDisplay : question.negativeDisplay}</span>
                                  ) : null;
                                case 1:
                                  return answers.answer ? (
                                    <span className={`answer ${question.color === "Positive" ? "positive-ans" : question.color === "Negative" ? "negative-ans" : ""}`}>
                                      {question.introductionToTextAnswer + ' - ' + answers.answer}
                                    </span>
                                  ) : null;
                                case 2:
                                  return answers.answer ? (
                                    <span className={`answer ${question.color === 'Positive' ? 'positive-ans' : question.color === 'Negative' ? 'negative-ans' : ''}`}>{question.numberMyoDescription?.replace('[number]', answers.answer)}</span>
                                  ) : null;
                                case 4:
                                  return answers.answer ? (
                                    <span className={`answer ${question.color === 'Positive' ? 'positive-ans' : question.color === 'Negative' ? 'negative-ans' : ''}`}>{`${question.dateMyoDescription} ${moment(answers.answer).format('D MMMM YYYY')}`}</span>
                                  ) : null;
                                case 5:
                                  const answerIndex = parseInt(answers?.answer || '', 10);
                                  const oneOfMyoDescription = Array.isArray(question.oneOfMyoDescription) && question.oneOfMyoDescription[answerIndex]
                                    ? question.oneOfMyoDescription[answerIndex][`oneOfMyoDescription${answerIndex}`]
                                    : null;
                                  const oneOfMyoDescriptionColor = Array.isArray(question.oneOfMyoDescription) && question.oneOfMyoDescription[answerIndex]
                                    ? question.oneOfMyoDescription[answerIndex][`color${answerIndex}`]
                                    : null;
                                  return oneOfMyoDescription ? (
                                    <span className={`answer ${oneOfMyoDescriptionColor === 'Positive' ? 'positive-ans' : oneOfMyoDescriptionColor === 'Negative' ? 'negative-ans' : ''}`}>{oneOfMyoDescription}</span>
                                  ) : null;
                                case 6:
                                  return (
                                    <div className={`checkbox_wrapper answer ${question.color === 'Positive' ? 'positive-ans' : question.color === 'Negative' ? 'negative-ans' : ''}`}>
                                      <h6>{question.manyOfMyoDescription}</h6>
                                      {question.options?.split(';').map((option, optionIndex) => {
                                        const selectedIndices: string[] = answers.answer && answers.answer.split(';');
                                        const isChecked: boolean = selectedIndices.includes(optionIndex.toString());
                                        return (
                                          <label key={optionIndex} className="checkcontainer">
                                            {option}
                                            <input type="checkbox" checked={isChecked} />
                                            <span className="checkmark"></span>
                                          </label>
                                        );
                                      })}
                                    </div>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              ))
            ))
          ) : (
            <div className="no-records-found">No records found</div>
          )}
        </div>
        <div className='custom-slider-wrapper'>
          <Slider className='custom-slick-slider' ref={sliderRef}  {...Settings}>
            {data.map((surveyItem, index) =>
              surveyItem.survey.sections.map((section, sectionIndex) =>
                section.questions
                  .filter(question => question.section === selectedSection?.value && (!selectedSubSection || question.subSection === selectedSubSection?.value) && question.isDisplayMyoCorporate && (question.type === 12 || question.type === 13 || question.type === 14))
                  .map((question, questionIndex) => {
                    const answers = surveyItem.answers && surveyItem.answers.length > 0 ? surveyItem.answers.find(answer => answer.questionID === question.id) : null;
                    return (
                      answers &&
                      answers.answer && (
                        <div key={questionIndex} className='custom_item'>
                          {question.type === 12 && (
                            <>
                              <Image
                                src={answers?.evidence?.url}
                                thumbnail={true}
                                className='custom_img'
                                onClick={() => handleImageClick(answers?.evidence?.url, question.imageMyoDescription, question.type)}
                              />
                              <h6>{question.imageMyoDescription}</h6>
                            </>
                          )}
                          {question.type === 13 && (
                            <>
                              <Image
                                src={VideoImage}
                                thumbnail={true}
                                className='custom_img'
                                onClick={() => handleImageClick(answers?.evidence?.url, question.videoMyoDescription, question.type)}
                              />
                              <h6>{question.videoMyoDescription}</h6>
                            </>
                          )}
                          {question.type === 14 && (
                            <>
                              <Image
                                src={PdfImage}
                                thumbnail={true}
                                className='custom_img'
                                onClick={() => handleImageClick(answers?.evidence?.url, question.documentMyoDescription, question.type)}
                              />
                              <h6>{question.documentMyoDescription}</h6>
                            </>
                          )}
                        </div>
                      )
                    );
                  })
              )
            )}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default AuditQuestions;
