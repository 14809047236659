/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import { call } from '../common/axios';


export default {
  getRateListSuccess: null,
  getRateListError: null,
  getRateByPropertyIdSuccess: null,
  getRateByPropertyIdError: null,
  getRateByPropertyNInventoryIdSuccess: null,
  getRateByPropertyNInventoryIdError: null,
  createGenericStandardRateSuccess: null,
  createGenericStandardRateError: null,
  createGenericSeasonalRateSuccess: null,
  createGenericSeasonalRateError: null,
  createCorporateStandardRateSuccess: null,
  createCorporateStandardRateError: null,
  createCorporateSeasonalRateSuccess: null,
  createCorporateSeasonalRateError: null,
  updateGenericStandardRateSuccess: null,
  updateGenericStandardRateError: null,
  updateGenericSeasonalRateSuccess: null,
  updateGenericSeasonalRateError: null,
  updateCorporateStandardRateSuccess: null,
  updateCorporateStandardRateError: null,
  updateCorporateSeasonalRateSuccess: null,
  updateCorporateSeasonalRateError: null,
  deleteGenericSeasonalRateSuccess: null,
  deleteGenericSeasonalRateError: null,
  deleteCorporateStandardRateSuccess: null,
  deleteCorporateStandardRateError: null,
  deleteCorporateSeasonalRateSuccess: null,
  deleteCorporateSeasonalRateError: null,
  updateGenericRateStatusSuccess: null,
  updateGenericRateStatusError: null,
  updateCorporateRateStatusSuccess: null,
  updateCorporateRateStatusError: null,
  getRateGridListSuccess: null,
  getRateGridListError: null,
  createRateSuccess: null,
  createRateError: null,
  reset: false,
  seasonalStandardRateByClientIdSuccess: null,
  seasonalStandardRateByClientIdError: null,


  // actions
  setGetRateList: action((state, payload) => ({ getRateListSuccess: payload })),
  setGetRateListError: action((state, payload) => ({ getRateListError: payload })),
  setGetRateByPropertyNInventoryId: action((state, payload) => ({ getRateByPropertyNInventoryIdSuccess: payload })),
  setGetRateByPropertyNInventoryIdError: action((state, payload) => ({ getRateByPropertyNInventoryIdError: payload })),
  setGetRateByPropertyId: action((state, payload) => ({ getRateByPropertyIdSuccess: payload })),
  setGetRateByPropertyIdError: action((state, payload) => ({ getRateByPropertyIdError: payload })),
  setCreateGenericStandardRate: action((state, payload) => ({ createGenericStandardRateSuccess: payload })),
  setCreateGenericStandardRateError: action((state, payload) => ({ createGenericStandardRateError: payload })),
  setCreateGenericSeasonalRate: action((state, payload) => ({ createGenericSeasonalRateSuccess: payload })),
  setCreateGenericSeasonalRateError: action((state, payload) => ({ createGenericSeasonalRateError: payload })),
  setCreateCorporateStandardRate: action((state, payload) => ({ createCorporateStandardRateSuccess: payload })),
  setCreateCorporateStandardRateError: action((state, payload) => ({ createCorporateStandardRateError: payload })),
  setCreateCorporateSeasonalRate: action((state, payload) => ({ createCorporateSeasonalRateSuccess: payload })),
  setCreateCorporateSeasonalRateError: action((state, payload) => ({ createCorporateSeasonalRateError: payload })),
  setUpdateGenericStandardRate: action((state, payload) => ({ updateGenericStandardRateSuccess: payload })),
  setUpdateGenericStandardRateError: action((state, payload) => ({ updateGenericStandardRateError: payload })),
  setUpdateGenericSeasonalRate: action((state, payload) => ({ updateGenericSeasonalRateSuccess: payload })),
  setUpdateGenericSeasonalRateError: action((state, payload) => ({ updateGenericSeasonalRateError: payload })),
  setUpdateCorporateStandardRate: action((state, payload) => ({ updateCorporateStandardRateSuccess: payload })),
  setUpdateCorporateStandardRateError: action((state, payload) => ({ updateCorporateStandardRateError: payload })),
  setUpdateCorporateSeasonalRate: action((state, payload) => ({ updateCorporateSeasonalRateSuccess: payload })),
  setUpdateCorporateSeasonalRateError: action((state, payload) => ({ updateCorporateSeasonalRateError: payload })),
  setDeleteGenericSeasonalRate: action((state, payload) => ({ deleteGenericSeasonalRateSuccess: payload })),
  setDeleteGenericSeasonalRateError: action((state, payload) => ({ deleteGenericSeasonalRateError: payload })),
  setDeleteCorporateStandardRate: action((state, payload) => ({ deleteCorporateStandardRateSuccess: payload })),
  setDeleteCorporateStandardRateError: action((state, payload) => ({ deleteCorporateStandardRateError: payload })),
  setDeleteCorporateSeasonalRate: action((state, payload) => ({ deleteCorporateSeasonalRateSuccess: payload })),
  setDeleteCorporateSeasonalRateError: action((state, payload) => ({ deleteCorporateSeasonalRateError: payload })),
  setUpdateGenericRateStatus: action((state, payload) => ({ updateGenericRateStatusSuccess: payload })),
  setUpdateGenericRateStatusError: action((state, payload) => ({ updateGenericRateStatusError: payload })),
  setUpdateCorporateRateStatus: action((state, payload) => ({ updateCorporateRateStatusSuccess: payload })),
  setUpdateCorporateRateStatusError: action((state, payload) => ({ updateCorporateRateStatusError: payload })),
  setRateGridList: action((state, payload) => ({ getRateGridListSuccess: payload })),
  setRateGridListError: action((state, payload) => ({ getRateGridListError: payload })),
  setCreateRateSuccess: action((state, payload) => ({ createRateSuccess: payload })),
  setCreateRateError: action((state, payload) => ({ createRateError: payload })),
  setSeasonalStandardRateByClientIdSuccess: action((state, payload) => ({ seasonalStandardRateByClientIdSuccess: payload })),
  setSeasonalStandardRateByClientIdError: action((state, payload) => ({ seasonalStandardRateByClientIdError: payload })),

  resetRate: action((state, payload) => ({ reset: true })),

  // thunks
  getRateList: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', '/rate-sv/rate/getRateList', payload, null);
      actions.setGetRateList(result.data);
    } catch (error: any) {
      actions.setGetRateListError(error.response.data);
    }
  }),

  // create generic standard rate
  createGenericStandardRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/rate-sv/rate/genericStandardRate', payload, null);
      actions.setCreateGenericStandardRate(result.data);
    } catch (error: any) {
      actions.setCreateGenericStandardRateError(error.response.data);
    }
  }),

  // create generic seasonal rate
  createGenericSeasonalRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/rate-sv/rate/genericSeasonalRate', payload, null);
      actions.setCreateGenericSeasonalRate(result.data);
    } catch (error: any) {
      actions.setCreateGenericSeasonalRateError(error.response.data);
    }
  }),

  // create corporate standard rate
  createCorporateStandardRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/rate-sv/rate/corporateStandardRate', payload, null);
      actions.setCreateCorporateStandardRate(result.data);
    } catch (error: any) {
      actions.setCreateCorporateStandardRateError(error.response.data);
    }
  }),

  // create corporate seasonal rate
  createCorporateSeasonalRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/rate-sv/rate/corporateSeasonalRate', payload, null);
      actions.setCreateCorporateSeasonalRate(result.data);
    } catch (error: any) {
      actions.setCreateCorporateSeasonalRateError(error.response.data);
    }
  }),

  // get rate by propertyId 
  getRateByPropertyId: thunk(async (actions: any, propertyId: any) => {
    try {
      const result = await call('get', `/rate-sv/rate/list/${propertyId}`, null, null);
      actions.setGetRateByPropertyId(result.data);
    } catch (error: any) {
      actions.setGetRateByPropertyIdError(error.response.data);
    }
  }),

  //get rates by Property Id and/or Inventory Id
  getRateByPropertyNInventoryId: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/rate-sv/rate/list/${payload.propertyId}/${payload.inventoryId}`, null, null);
      actions.setGetRateByPropertyNInventoryId(result.data);
    } catch (error: any) {
      actions.setGetRateByPropertyNInventoryIdError(error.response.data);
    }
  }),

  // update generic standard rate
  updateGenericStandardRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/rate-sv/rate/genericStandardRate', payload, null);
      actions.setUpdateGenericStandardRate(result.data);
    } catch (error: any) {
      actions.setUpdateGenericStandardRateError(error.response.data);
    }
  }),

  // update generic seasonal rate
  updateGenericSeasonalRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/rate-sv/rate/genericSeasonalRate', payload, null);
      actions.setUpdateGenericSeasonalRate(result.data);
    } catch (error: any) {
      actions.setUpdateGenericSeasonalRateError(error.response.data);
    }
  }),

  // update corporate standard rate
  updateCorporateStandardRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/rate-sv/rate/corporateStandardRate', payload, null);
      actions.setUpdateCorporateStandardRate(result.data);
    } catch (error: any) {
      actions.setUpdateCorporateStandardRateError(error.response.data);
    }
  }),

  // update corporate seasonal rate
  updateCorporateSeasonalRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/rate-sv/rate/corporateSeasonalRate', payload, null);
      actions.setUpdateCorporateSeasonalRate(result.data);
    } catch (error: any) {
      actions.setUpdateCorporateSeasonalRateError(error.response.data);
    }
  }),

  // delete generic seasonal rate
  deleteGenericSeasonalRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('delete', '/rate-sv/rate/genericSeasonalRate', payload, null);
      actions.setDeleteGenericSeasonalRate(result.data);
    } catch (error: any) {
      actions.setDeleteGenericSeasonalRateError(error.response.data);
    }
  }),

  // delete corporate standard rate
  deleteCorporateStandardRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('delete', '/rate-sv/rate/corporateStandardRate', payload, null);
      actions.setDeleteCorporateStandardRate(result.data);
    } catch (error: any) {
      actions.setDeleteCorporateStandardRateError(error.response.data);
    }
  }),

  // delete corporate seasonal rate
  deleteCorporateSeasonalRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('delete', '/rate-sv/rate/corporateSeasonalRate', payload, null);
      actions.setDeleteCorporateSeasonalRate(result.data);
    } catch (error: any) {
      actions.setDeleteCorporateSeasonalRateError(error.response.data);
    }
  }),


  // update generic rate status
  updateGenericRateStatus: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/rate-sv/rate/genericRateStatus', payload, null);
      actions.setUpdateGenericRateStatus(result.data);
    } catch (error: any) {
      actions.setUpdateGenericRateStatusError(error.response.data);
    }
  }),

  // update corporate rate status
  updateCorporateRateStatus: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/rate-sv/rate/corporateRateStatus', payload, null);
      actions.setUpdateCorporateRateStatus(result.data);
    } catch (error: any) {
      actions.setUpdateCorporateRateStatusError(error.response.data);
    }
  }),

  // get rate grid 
  getRateGrid: thunk(async (actions: any, params: any) => {
    try {
      const result = await call('get', `/rate-sv/rate/grid/${params.propertyId}/${params.inventoryId}`, null, null);
      actions.setRateGridList(result.data);
    } catch (error: any) {
      actions.setRateGridListError(error.response.data);
    }
  }),

  // create generic rate
  createGenericRate: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/rate-sv/rate/genericStandardRate', payload, null);
      actions.setCreateRateSuccess(result.data);
    } catch (error: any) {
      actions.setCreateRateError(error.response.data);
    }
  }),

  // get seasonal and standard rate by client id
  getSeasonalStandardRateByClientId: thunk(async (actions: any, params: any) => {
    try {
      const result = await call('get', `/rate-sv/rate/list/${params.propertyId}/${params.inventoryId}/${params.clientId}`,null, null);
      actions.setSeasonalStandardRateByClientIdSuccess(result.data);
    } catch (error: any) {
      actions.setSeasonalStandardRateByClientIdError(error.response.data);
    }
  }),
}