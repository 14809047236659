import { Button, Card, Form, Input } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import jwt from 'jsonwebtoken';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import logo from '../../../images/logoNew.png';

const BrandSignUp: React.FC = (): JSX.Element => {
  const history = useHistory();
  // const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const { onCreateBrandSignup } = useStoreActions<any>((actions) => ({
    onCreateBrandSignup: actions.brand.onCreateBrandSignup,
  }));

  const { createBrandSignupSuccess, createBrandSignupError } = useStoreState<any>((state) => ({
    createBrandSignupSuccess: state.brand.createBrandSignupSuccess,
    createBrandSignupError: state.brand.createBrandSignupError,
  }));

  useEffect(() => {
    if (createBrandSignupSuccess) {
      const { token, _id, certificateMyoManagementID } = createBrandSignupSuccess.data;
      const query = `?token=${token}&_id=${_id}&certificateMyoManagementID=${certificateMyoManagementID}`
      toast.success("Redirecting to the form", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      setLoading(false);
      setTimeout(() => {
        history.push('/brand-signup/public-form' + query);
      }, 2000);

    }

    if (createBrandSignupError) {
      toast.error(createBrandSignupError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      setLoading(false);
    }
  }, [createBrandSignupError, createBrandSignupSuccess, history]);

  const onFinish = useCallback((values) => {
    setLoading(true);
    const jwtToken = jwt.sign(values, 'myo');
    // setToken(jwtToken)
    onCreateBrandSignup({ ...values, token: jwtToken });
  }, [onCreateBrandSignup]);


  return (
    <div className="mysa-login flex-col flex h-screen items-center justify-center" >

      <Card className="px-5 shadow-2xl" style={{ width: 400 }}>
        <div className="w-64 m-auto py-3">
          <img src={logo} alt="logo" />
        </div>
        <>
          <Form onFinish={onFinish} >
            <Form.Item
              className="mb-2"
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: "email", message: 'Please input valid email!' }
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              className="mb-2"
              name="name"
              rules={[
                { required: true, message: 'Please input your name!' },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item>
              <Button block loading={loading} type="primary" htmlType="submit" className="login-btn">
                Submit and Continue
              </Button>
            </Form.Item>
          </Form>
        </>
      </Card>
      <div className="flex flex-row justify-around" style={{ width: 400 }}>
        <div className="mysa-yellow w-full h-1"></div>
        <div className="mysa-green w-full"></div>
        <div className="mysa-blue w-full"></div>
      </div>
      <span className="text-white text-xs mt-1">All right reserved @{new Date().getFullYear()} MYSA Global Limited</span>
    </div>
  )
};

export default BrandSignUp;