import { Button, Form, Input, message, Switch } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const AccommodationForm: React.FC<any> = ({ setFormOpen, accommodationDetails, accommodationId }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [status, setStatus] = useState<string>('active');
  const [checkValue, setCheckValue] = useState<boolean>(true);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const statusChange = useCallback((value) => {
    setCheckValue(value);
    if (value) {
      setStatus('active');
    }
    else {
      setStatus('inactive');
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (accommodationId) {
      setCheckValue((accommodationDetails.status === 'active') ? true : false);
      setStatus(accommodationDetails.status)
      form.setFieldsValue({
        name: accommodationDetails.name,
        description: accommodationDetails.description || '',
        status: accommodationDetails.status,

      })
    } else {
      setStatus('active');
      setCheckValue(true);
    }
  }, [accommodationDetails, accommodationId, form]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { createAccommodation, updateAccommodation, getAccommodationList } = useStoreActions<any>((actions) => ({
    createAccommodation: actions.inventory.createAccommodation,
    updateAccommodation: actions.inventory.updateAccommodation,
    getAccommodationList: actions.inventory.getAccommodationList,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    createAccommodationError,
    createAccommodationSuccess,
    updateAccommodationError,
    updateAccommodationSuccess,
    getAccommodationListSuccess,
    getAccommodationListError
  } = useStoreState<any>((state) => ({
    createAccommodationSuccess: state.inventory.createAccommodationSuccess,
    createAccommodationError: state.inventory.createAccommodationError,
    updateAccommodationError: state.inventory.updateAccommodationError,
    updateAccommodationSuccess: state.inventory.updateAccommodationSuccess,
    getAccommodationListSuccess: state.inventory.getAccommodationListSuccess,
    getAccommodationListError: state.inventory.getAccommodationListError,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getAccommodationList();
  }, [getAccommodationList]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAccommodationListSuccess) {

    }
    if (getAccommodationListError) {
      message.error(getAccommodationListError.message)
    }
  }, [getAccommodationListError, getAccommodationListSuccess]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createAccommodationSuccess) {
      message.success('accommodation created successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getAccommodationList();
    }

    if (updateAccommodationSuccess) {
      message.success('accommodation updated successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getAccommodationList();
    }

    if (createAccommodationError) {
      message.error(createAccommodationError.message)
    }

    if (updateAccommodationError) {
      message.error(updateAccommodationError.message)
    }
    setLoading(false);
  }, [createAccommodationSuccess, createAccommodationError, updateAccommodationSuccess, updateAccommodationError, getAccommodationList, setFormOpen]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = useCallback((values) => {
    setLoading(true);
    if (accommodationId) {
      values.id = accommodationId;
      const data = {
        id: accommodationId,
        name: values.name,
        description: values.description,
        status: values.status ? status : 'inactive'
      }
      updateAccommodation(data);

    } else {
      values.status = status;
      createAccommodation(values);
    }

  }, [accommodationId, createAccommodation, status, updateAccommodation]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="user-Form w-1/2">
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please input name' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status">
          <Switch size="small" checked={checkValue} onChange={statusChange} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={loading} type="primary" htmlType="submit">
            {accommodationId ? 'Update' : 'Create'}
          </Button>
          {/* <Button onClick={() => setFormOpen(false)} className="ml-5">
            Close
          </Button> */}
        </Form.Item>

      </Form>
    </div>
  )
};

export default AccommodationForm;