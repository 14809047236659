import { FC, useEffect, useState } from "react";
import { Col, Row, Button, Form, Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { $mysaMegenta } from "../../../../common/components-style";
import { MYSAReviewTemplateSubmission } from "../../../../common/interfaces";
import { useStoreActions, useStoreState } from "easy-peasy";
import { CopyFilled } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ErrorMessage } from "@hookform/error-message";

const MYSAReportTemplate: FC<any> = ({ rfpReleasedToClient, selectedClientName, selectedProgramme, startDate, singleData, rfpProcessId }) => {
    /////////////////// form manage ////////////
    const { handleSubmit, formState: { errors }, control, setValue } = useForm<MYSAReviewTemplateSubmission>();

    ///////////// local state manage ////////////
    const [modalVisible, setModalVisible] = useState(false);
    const [singleFilterData, setSingleFilterData] = useState<any>("");
    const [editable, setEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [statusBtn, setStatusBtn] = useState(false);
    const [statusLoadingBtn, setStatusLoadingBtn] = useState(false);

    ///////////// model hide /////////////
    const hideModal = () => {
        setModalVisible(false);
    };

    //////////////// global state and action manage /////////////////

    const {
        getRFPAdminFilterReview,
        RFPAdminReviewSubmit,
        RFPAdminReviewStatusUpdate
    } = useStoreActions<any>((actions) => ({
        getRFPAdminFilterReview: actions.rfpProcess.getRFPAdminFilterReview,
        RFPAdminReviewSubmit: actions.rfpProcess.RFPAdminReviewSubmit,
        RFPAdminReviewStatusUpdate: actions.rfpProcess.RFPAdminReviewStatusUpdate,
    }));


    const {
        getRFPAdminFilterReviewSuccess,
        getRFPAdminReviewSubmitSuccess,
        setRFPAdminReviewSubmitError,
        getRFPAdminReviewStatusUpdateSuccess,
        getRFPAdminReviewStatusUpdateError
    } = useStoreState<any>((state) => ({
        getRFPAdminFilterReviewSuccess: state.rfpProcess.getRFPAdminFilterReviewSuccess,
        getRFPAdminReviewSubmitSuccess: state.rfpProcess.getRFPAdminReviewSubmitSuccess,
        setRFPAdminReviewSubmitError: state.rfpProcess.setRFPAdminReviewSubmitError,
        getRFPAdminReviewStatusUpdateSuccess: state.rfpProcess.getRFPAdminReviewStatusUpdateSuccess,
        getRFPAdminReviewStatusUpdateError: state.rfpProcess.getRFPAdminReviewStatusUpdateError,
    }));

    ///////////// api request manage /////////////

    useEffect(() => {
        const payload = {
            rfpProcessId: rfpProcessId,
            reviewType: singleData?.reviewType,
            locationName: singleData?.locationName ? singleData?.locationName.trim() : '',
        }
        getRFPAdminFilterReview(payload);
    }, [getRFPAdminFilterReview, rfpProcessId, singleData]);

    ////////////// api response manage ///////////////

    useEffect(() => {
        if (getRFPAdminFilterReviewSuccess?.data) {
            setSingleFilterData(getRFPAdminFilterReviewSuccess?.data);
            if (getRFPAdminFilterReviewSuccess?.data?.description) {
                setEditable(false);
                if(getRFPAdminFilterReviewSuccess?.data?.moderateDescription){
                    setValue("reviewDescription", getRFPAdminFilterReviewSuccess?.data?.moderateDescriptions);
                }else{
                    setValue("reviewDescription", getRFPAdminFilterReviewSuccess?.data?.description);
                }

            } else {
                setEditable(true);
            }
            

            if (getRFPAdminFilterReviewSuccess?.data?.reviewStatus === 'pending') {
                setStatusBtn(true);
            } else {
                setStatusBtn(false);
            }
        }

        if (getRFPAdminReviewSubmitSuccess) {
            setLoading(false);
            setEditable(false);
            toast.success("MYSA Review created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            const payload = {
                rfpProcessId: rfpProcessId,
                reviewType: singleData?.reviewType,
                locationName: singleData?.locationName ? singleData?.locationName.trim() : '',
            }
            getRFPAdminFilterReview(payload);
            // if(rfpReleasedToClient){
            //     onStatusChange();
            // }
        }

        if (setRFPAdminReviewSubmitError) {
            setLoading(false);
            toast.error(setRFPAdminReviewSubmitError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }


        if (getRFPAdminReviewStatusUpdateSuccess) {
            setStatusLoadingBtn(false);
            toast.success("Report marked as complete", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            const payload = {
                rfpProcessId: rfpProcessId,
                reviewType: singleData?.reviewType,
                locationName: singleData?.locationName ? singleData?.locationName.trim() : '',
            }
            getRFPAdminFilterReview(payload);
        }

        if (getRFPAdminReviewStatusUpdateError) {
            setStatusLoadingBtn(false);
            toast.error(getRFPAdminReviewStatusUpdateError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [getRFPAdminFilterReview, getRFPAdminFilterReviewSuccess, getRFPAdminReviewStatusUpdateError, getRFPAdminReviewStatusUpdateSuccess, getRFPAdminReviewSubmitSuccess, rfpProcessId, setRFPAdminReviewSubmitError, setValue, singleData]);

    ///////////////////// clipboard manage ///////////////
    const clipboard = () => {
        toast.success("Copied.....", {
            position: toast.POSITION.TOP_CENTER,
            className: 'foo-bar',
            autoClose: 300,
        });
    }

    //////////////// change form edit mode ////////////
    const changeEditable = (value: boolean) => {
        setEditable(value);
    }

    const onSubmit = (value) => {
        let payload;
        if(rfpReleasedToClient){
            if (singleData?.reviewType === 'rfp_locations') {
                payload = {
                    rfpId: rfpProcessId,
                    reviewType: singleData?.reviewType,
                    reviewDescription: singleFilterData?.description,
                    moderateDescription: value.reviewDescription,
                    locationName: singleData?.locationName ? singleData?.locationName.trim() : '',
                }
            } else {
                payload = {
                    rfpId: rfpProcessId,
                    reviewType: singleData?.reviewType,
                    reviewDescription: singleFilterData?.description,
                    moderateDescription: value.reviewDescription,
                    locationName: null
                }
            }
        }else{
            if (singleData?.reviewType === 'rfp_locations') {
                payload = {
                    rfpId: rfpProcessId,
                    reviewType: singleData?.reviewType,
                    reviewDescription: value.reviewDescription,
                    locationName: singleData?.locationName ? singleData?.locationName.trim() : '',
                }
            } else {
                payload = {
                    rfpId: rfpProcessId,
                    reviewType: singleData?.reviewType,
                    reviewDescription: value.reviewDescription,
                    locationName: null
                }
            }
        }

        RFPAdminReviewSubmit(payload);
        setLoading(true);
    };

    /////////////////// update status //////////////
    const onStatusChange = () => {
        let payload;
        if (singleData?.reviewType === 'rfp_locations') {
            payload = {
                rfpId: rfpProcessId,
                reviewType: singleData?.reviewType,
                reviewStatus: 'mark_as_complete',
                locationName: singleData?.locationName ? singleData?.locationName.trim() : '',
            }
        } else {
            payload = {
                rfpId: rfpProcessId,
                reviewType: singleData?.reviewType,
                reviewStatus: 'mark_as_complete',
                locationName: null
            }
        }

        RFPAdminReviewStatusUpdate(payload);
        setStatusLoadingBtn(true);
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <p style={{ fontSize: 20 }}>To release RFP to the client please complete the following. <span style={{ color: $mysaMegenta, fontWeight: 'bold' }}>Please note. All sections must be completed</span></p>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            onClick={() => {
                                setModalVisible(true);
                            }}
                            id='edit-button'
                            className="h-10 text-white" ><b>View Templates</b></Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div style={{ textAlign: 'right', fontSize: 18, marginTop: '1%' }}>
                        <p>{selectedClientName} {selectedProgramme?.programmeName} {startDate}</p>
                    </div>
                </Col>
            </Row>
            <Row style={{ background: '#F2F2F2', marginTop: '3%' }}>
                <Col md={12}>
                    <div style={{ fontSize: 25, fontWeight: 'bold', padding: '1%' }}>{singleData?.name} - Client Overview</div>
                </Col>
            </Row>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row style={{ marginTop: '1%' }}>
                    <Col md={12}>
                        <Controller
                            name="reviewDescription"
                            defaultValue={singleFilterData?.moderateDescription ? singleFilterData?.moderateDescription : singleFilterData?.description}
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) =>
                                <>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={singleFilterData?.moderateDescription ? singleFilterData?.moderateDescription : singleFilterData?.description}
                                        config={ {
                                            toolbar: {
                                                items: [
                                                    'heading', '|',
                                                    'fontfamily', 'fontsize', '|',
                                                    'alignment', '|',
                                                    'fontColor', 'fontBackgroundColor', '|',
                                                    'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                                    'link', '|',
                                                    'outdent', 'indent', '|',
                                                    'bulletedList', 'numberedList', 'todoList', '|',
                                                    'code', 'codeBlock', '|',
                                                    'blockQuote', '|',
                                                    'undo', 'redo'
                                                ]
                                            }
                                        } }
                                        disabled={!editable}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            field.onChange(data)
                                        }}
                                    />
                                </>
                            }
                        />
                        <ErrorMessage
                            errors={errors}
                            name="reviewDescription"
                            render={({ message }) => <div className="invalid-feedback-custom">Please enter your overview!</div>} />
                    </Col>
                </Row>
                <Row style={{ marginTop: '1%' }}>
                    <Col md={6}>
                        <div style={{ textAlign: 'left' }}>
                            {!loading && editable && <Button type="submit" id='edit-button' className="h-10 text-white" ><b>Save</b></Button>}
                            {!loading && !editable && <Button onClick={() => { changeEditable(true); }} id='edit-button' className="h-10 text-white" ><b>Edit</b></Button>}
                        </div>
                        {
                            loading && <div style={{ textAlign: 'left' }}>

                                <Spinner animation="border" variant="warning" />

                            </div>
                        }

                    </Col>
                    <Col md={6}>
                        <div style={{ textAlign: 'right' }}>
                            {
                                !editable && statusBtn && !statusLoadingBtn && <Button
                                    style={{ borderRadius: 30 }}
                                    onClick={onStatusChange}
                                    id='approve-button'
                                    className="h-10 text-white" ><b>Mark as Complete</b></Button>
                            }
                            {
                                statusLoadingBtn && <Spinner animation="border" variant="success" />
                            }
                        </div>
                    </Col>
                </Row>
            </Form>
            <Modal
                show={modalVisible}
                onHide={hideModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {singleData?.name} - Template
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ padding: '5%' }}>
                        <div><b>Review Template :</b> 
                        <div className="customStyle" dangerouslySetInnerHTML={{ __html: singleFilterData?.reviewTemplate }} /> <CopyToClipboard text={singleFilterData?.reviewTemplate}
                            onCopy={() => {
                                clipboard();
                            }}>
                            <CopyFilled />
                        </CopyToClipboard></div>
                        <div style={{ marginTop: '2%' }}></div>
                        {/* <p><b>Review Template :</b> {singleFilterData?.reviewTemplate} <CopyToClipboard text={singleFilterData?.reviewTemplate}
                            onCopy={() => {
                                clipboard();
                            }}>
                            <CopyFilled />
                        </CopyToClipboard></p> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );

}

export default MYSAReportTemplate