/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import {call} from '../common/axios';

export default {
  loginSuccess: null,
  loginError: null,
  resetSuccess: null,
  restError: null,
  forgotSuccess: null,
  forgotError: null,
  confirmForgotSuccess: null,
  confirmForgotError: null,
  resendPassword: null,
  resendPasswordError: null,
  reset: false,

  // actions
  setLogin: action((state, payload) => ({ loginSuccess: payload })),
  setLogout: action((state, payload) => ({ loginSuccess: null })),
  setLoginError: action((state, payload) => ({ loginError: payload })),
  setReset: action((state, payload) => ({ resetSuccess: payload })),
  setResetError: action((state, payload) => ({ resetError: payload })),
  setForgot: action((state, payload) => ({ forgotSuccess: payload })),
  setForgotError: action((state, payload) => ({ forgotError: payload })),
  setConfirmForgot: action((state, payload) => ({ confirmForgotSuccess: payload })),
  setConfirmForgotError: action((state, payload) => ({ confirmForgotError: payload })),
  setResendPassword: action((state, payload) => ({ resendPassword: payload })),
  setResendPasswordError: action((state, payload) => ({ resendPasswordError: payload })),
  resetAuth: action((state, payload) => ({ reset: true })),

  // thunks
  onLogin: thunk(async (actions: any, payload:any) => {
    try {
      const result = await call('post', '/user-sv/login', payload, null);
      actions.setLogin(result.data);
    } catch (error:any) {
      actions.setLoginError(error.response.data);
    }
  }),

  onReset: thunk(async (actions: any, payload:any) => {
    try {
      const result = await call('post', '/user-sv/reset-password', payload, null);
      actions.setReset(result.data);
    } catch (error:any) {
      actions.setResetError(error.response.data);
    }
  }),

  onForgot: thunk(async (actions: any, payload:any) => {
    try {
      const result = await call('post', '/user-sv/forgot-password', payload, null);
      actions.setForgot(result.data);
    } catch (error:any) {
      actions.setForgotError(error.response.data);
    }
  }),

  onConfirmForgot: thunk(async (actions: any, payload:any) => {
    try {
      const result = await call('post', '/user-sv/confirm-forgot-password', payload, null);
      actions.setConfirmForgot(result.data);
    } catch (error:any) {
      actions.setConfirmForgotError(error.response.data);
    }
  }),
  
  onResendPassword: thunk(async (actions: any, payload:any) => {
    try {
      const result = await call('post', '/user-sv/users/resend-password', payload, null);
      actions.setResendPassword(result.data);
    } catch (error:any) {
      actions.setResendPasswordError(error.response.data);
    }
  }),
};
